import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Input from "../../../../common/Input";
import Button from "react-bootstrap/Button";
import {getCurrentUser, isEmpty} from "../../../../../_helpers/commonFunctions";
import {Badge} from "react-bootstrap";
import BetterCheckBox from "../../../../common/BetterCheckBox";
import TextArea from "../../../../common/TextArea";
import {toast} from "react-toastify";
import "../../../../primer/POPUP-COMPONENTS/modalWidths.css";
import {
    formatPath,
    getFilename, greekMonths,
    isValidPageInput,
    popExtension,
    registerOrSortToOptions,
    registerOrSortToOptionsDD,
    whatMonth
} from "./constants";
import Alert from "react-bootstrap/Alert";
import {useSelector} from "react-redux";
import moment from "moment";
import Dropdown from "../../../../primer/Dropdown";

const ContextMenu = ({hasCopiedFile, show, posX, posY, fileSelections, folderSelections, locked,
                         onNewFolder, onFileDelete, onFolderDelete, onFileRename, onFolderRename, setSomeModalOpen,
                         onFileDownload, onFileCopy, onFilePaste, onFileHistory, onPDFToImages, onImagesToPDF,
                         onMultipleDownloads, onPDFConcat, onMultipleDelete, onPDFToPDFs, howManyPages,
                         onEmronDelete, onEmronMultipleDelete, onEmronUnlock, onEmronSort,
                         onEmronRestore, onEmronRegister, onEmronMove, specialCode, isEmron,
    }) => {
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const currentPath = useSelector((state) => state.CLOUD.currentPath);
    const [newFolderModal, setNewFolderModal] = useState(false);
    const [newFolderName, setNewFolderName] = useState("");

    const [showRenameFile, setShowRenameFile] = useState(false);
    const [renameFileName, setRenameFileName] = useState("");
    const [renameFileExtension, setRenameFileExtension] = useState("");

    const [showRenameFolder, setShowRenameFolder] = useState(false);
    const [renameFolderName, setRenameFolderName] = useState("");

    const [showDeleteFile, setShowDeleteFile] = useState(false);
    const [deleteCause, setDeleteCause] = useState("");

    const [showDeleteFolder, setShowDeleteFolder] = useState(false);

    const [showDeleteMultiple, setShowDeleteMultiple] = useState(false);

    const [showEmronUnlock, setShowEmronUnlock] = useState(false);
    const [emronUnlockDays, setEmronUnlockDays] = useState("2");

    const [pdfConversionModal, setPDFConversionModal] = useState(false); // For pdf to image conversion
    const [imagesConversionModal, setImagesConversionModal] = useState(false); // For images to pdf conversion
    const [pdfConcatModal, setPDFConcatModal] = useState(false); // For pdf concat
    const [deleteAfterConversion, setDeleteAfterConversion] = useState(false);
    const [newPDFName, setNewPDFName] = useState("");

    const [pdfExportModal, setPDFExportModal] = useState(false); // For pdf to pdfs
    const pdfExportDefaultObject = {
        id: "1",
        fileName: "",
        selection: "",
    }
    const [pdfExportData, setPDFExportData] = useState([{
        ...pdfExportDefaultObject
    }]);

    const [showRegisterMany, setShowRegisterMany] = useState(false);
    const [registerManyTo, setRegisterManyTo] = useState("Πωλήσεις");

    const [showSortMany, setShowSortMany] = useState(false);
    const [sortManyTo, setSortManyTo] = useState("Πωλήσεις");

    const [showUnSortMany, setShowUnSortMany] = useState(false);
    const [showUnRegisterMany, setShowUnRegisterMany] = useState(false);

    const [showMoveMany, setShowMoveMany] = useState(false);
    const [showMoveManyTo, setShowMoveManyTo] = useState(false);
    const [showMoveManyMonthTo, setShowMoveManyMonthTo] = useState("Ιανουάριος");

    const [showRestoreMany, setShowRestoreMany] = useState(false);

    const isUniqueSelection = (fileSelections.length === 1 && folderSelections.length === 0) || (fileSelections.length === 0 && folderSelections.length === 1);
    const uniqueSelectionType = fileSelections.length === 1 ? "file" : folderSelections.length === 1 ? "folder" : (fileSelections.length === 0 && folderSelections.length === 0) ? "parent" : null;
    const uniqueSelection = fileSelections.length === 1 ? fileSelections[0] : folderSelections.length === 1 ? folderSelections[0] : null;

    const [fileSelectionOrder, setFileSelectionOrder] = useState([]);

    const contextMenuStyle = {
        display: show ? "" : "none",
        opacity: show ? 1 : 0,
        position: "absolute",
        left: `${posX}px`,
        top: `${posY}px`,
        zIndex: "1000",
        border: "1px solid lightgray",
        backgroundColor: "white",
        width: "120px",
        fontSize: "12px",
        userSelect: "none",
    }

    useEffect(() => {
        setSomeModalOpen(showRenameFolder || showRenameFile || newFolderModal || showDeleteFile || showDeleteFolder
            || showEmronUnlock || pdfConversionModal || imagesConversionModal || pdfConcatModal || showDeleteMultiple
            || showRegisterMany || showSortMany || showUnSortMany || showUnRegisterMany || showMoveMany || showRestoreMany);
    }, [showRenameFolder, showRenameFile, newFolderModal, showDeleteFile, showDeleteFolder, showEmronUnlock, pdfConversionModal,
        imagesConversionModal, pdfConcatModal, showDeleteMultiple, showRegisterMany, showSortMany, showUnSortMany, showUnRegisterMany, showMoveMany, showRestoreMany])

    useEffect(() => {
        if (imagesConversionModal || pdfConcatModal) {
            let arr = [];
            for (let i = 0; i < fileSelections.length; i++) arr.push(i + 1);
            setFileSelectionOrder(arr);
        }
    }, [imagesConversionModal, pdfConcatModal])

    useEffect(() => {
        if (showRenameFile) {
            const fExt = getFileNameAndExtension(uniqueSelection);
            setRenameFileName(fExt.name);
            setRenameFileExtension(fExt.extension);
        }
    }, [showRenameFile])

    useEffect(() => {
        if (showRenameFolder) setRenameFolderName(uniqueSelection);
    }, [showRenameFolder])

    const getFileNameAndExtension = (fileName) => {
        const lastDotIndex = fileName.lastIndexOf('.');
        if (lastDotIndex === -1) {
            return {name: fileName, extension: null}; // No extension found
        }
        const name = fileName.substring(0, lastDotIndex);
        const extension = fileName.substring(lastDotIndex + 1);

        return {name, extension}
    }

    const handleFileDelete = () => {
        if (isEmpty(deleteCause)) return toast.error("Παρακαλώ συμπληρώστε αιτιολογία.");

        if (specialCode === "1") {
            onEmronDelete(uniqueSelection, deleteCause);
            setShowDeleteFile(false);
            setDeleteCause("");
        } else {
            onFileDelete(uniqueSelection, deleteCause);
            setShowDeleteFile(false);
            setDeleteCause("");
        }
    }

    const handleMultipleDelete = () => {
        if (isEmpty(deleteCause)) return toast.error("Παρακαλώ συμπληρώστε αιτιολογία.");

        if (specialCode === "1") {
            onEmronMultipleDelete({files: fileSelections, folders: folderSelections}, deleteCause);
            setShowDeleteMultiple(false);
            setDeleteCause("");
        } else {
            onMultipleDelete({files: fileSelections, folders: folderSelections}, deleteCause);
            setShowDeleteMultiple(false);
            setDeleteCause("");
        }
    }

    const handleFolderDelete = () => {
        onFolderDelete(uniqueSelection, deleteCause);
        setShowDeleteFolder(false);
        setDeleteCause("");
    }

    const handleFileRename = () => {
        if (!isEmpty(renameFileName)) {
            if (onFileRename(uniqueSelection, renameFileName + "." + renameFileExtension)) {
                setRenameFileName("");
                setShowRenameFile(false);
            }
        }
    }

    const handleFolderRename = () => {
        if (!isEmpty(renameFolderName)) {
            if (onFolderRename(uniqueSelection, renameFolderName)) {
                setRenameFolderName("");
                setShowRenameFolder(false);
            }
        }
    }

    const handleNewFolder = () => {
        if (!isEmpty(newFolderName)) {
            if (onNewFolder(newFolderName)) {
                setNewFolderName("");
                setNewFolderModal(false);
            }
        }
    }

    const invalidRenameFileName = () => {
        return uniqueSelection === renameFileName;
    }

    const invalidRenameFolderName = () => {
        return uniqueSelection === renameFolderName;
    }

    const handleFileDownload = () => {
        onFileDownload(null, uniqueSelection, true, true);
    }

    const handleFileCopy = (isCut = false) => {
        onFileCopy(uniqueSelection, isCut);
    }

    const handleFilePaste = () => {
        onFilePaste();
    }

    const handleEmronUnlock = () => {
        onEmronUnlock(uniqueSelection, emronUnlockDays);
        setShowEmronUnlock(false);
    }

    const handlePDFToImages = () => {
        setPDFConversionModal(true);
    }

    const handlePDFToPDFs = () => {
        setPDFExportModal(true);
        howManyPages(formatPath(currentPath + "/" + fileSelections[0])).then((pages) => {
            let arr = [];
            const ext = popExtension(fileSelections[0]);
            for (let i = 1; i <= pages; i++) {
                arr.push({
                    id: String(i),
                    fileName: getFilename(fileSelections[0]) + `-p${i}` + "." + ext,
                    selection: String(i),
                })
            }
            setPDFExportData(arr);
        })
    }

    const handleSaveConversionOfPDF = () => {
        onPDFToImages(uniqueSelection, deleteAfterConversion);
        setPDFConversionModal(false);
    }

    const handleImagesToPDF = () => {
        setImagesConversionModal(true);
    }

    const handleSaveImagesToPDF = () => {
        for (let i = 1; i <= fileSelectionOrder.length; i++) {
            if (!fileSelectionOrder.includes(i)) return toast.error("Δεν είναι σωστή η δεκτή των στοιχείων.");
        }
        if (isEmpty(newPDFName)) return toast.error("Παρακαλώ συμπληρώστε όνομα αρχείου.");
        let tmpOrder = [];
        for (let userIdx of fileSelectionOrder) tmpOrder.push(fileSelections[userIdx - 1]);

        onImagesToPDF(tmpOrder, deleteAfterConversion, `${newPDFName}.pdf`);
        setImagesConversionModal(false);
        setNewPDFName("");
    }

    const handleManyDownload = () => {
        onMultipleDownloads({
            files: fileSelections,
            folders: folderSelections,
        })
    }

    const handleConcatPDFs = () => {
        setPDFConcatModal(true);
    }

    const handleSaveConcatPDFs = () => {
        for (let i = 1; i <= fileSelectionOrder.length; i++) {
            if (!fileSelectionOrder.includes(i)) return toast.error("Δεν είναι σωστή η δεκτή των στοιχείων.");
        }
        if (isEmpty(newPDFName)) return toast.error("Παρακαλώ συμπληρώστε όνομα αρχείου.");
        let tmpOrder = [];
        for (let userIdx of fileSelectionOrder) tmpOrder.push(fileSelections[userIdx - 1]);

        onPDFConcat(tmpOrder, deleteAfterConversion, `${newPDFName}.pdf`);
        setPDFConcatModal(false);
        setNewPDFName("");
    }

    const handleSavePDFtoPDFs = () => {
        let idx = 0;
        for (let d of pdfExportData) {
            if (!isValidPageInput(d.selection)) {
                return toast.error(`Σφάλμα στην γραμμή ${d.id}. Δεν έγινε αναγνώριση σελιδών προς εξαγωγή`, {autoClose: 6000});
            } else if (isEmpty(d.fileName)) {
                return toast.error(`Σφάλμα στην γραμμή ${d.id}. Παρακαλώ συμπληρώστε όνομα αρχείου`, {autoClose: 6000});
            } else if (pdfExportData.some((el, elIdx) => el.fileName === d.fileName && idx !== elIdx)) {
                return toast.error(`Σφάλμα στην γραμμή ${d.id}. Υπάρχει ήδη αυτό το όνομα αρχείου`, {autoClose: 6000});
            }
            idx++;
        }
        onPDFToPDFs(fileSelections[0], pdfExportData, deleteAfterConversion);
        setPDFExportModal(false);
        setPDFExportData([{...pdfExportDefaultObject}]);
    }

    const handleRegisterMany = () => {
        let cnt = 1;
        for (let fileName of fileSelections) {
            onEmronRegister(formatPath(currentPath + "/" + fileName), registerManyTo, false, cnt === fileSelections.length);
            cnt++;
        }
        setShowRegisterMany(false);
        toast.success("Επιτυχής ενέργεια.");
    }

    const handleSortMany = () => {
        let cnt = 1;
        for (let fileName of fileSelections) {
            onEmronSort(formatPath(currentPath + "/" + fileName), sortManyTo, false, cnt === fileSelections.length);
            cnt++;
        }
        setShowSortMany(false);
        toast.success("Επιτυχής ενέργεια.");
    }

    const handleUnSortMany = () => {
        let cnt = 1;
        for (let fileName of fileSelections) {
            onEmronSort(formatPath(currentPath + "/" + fileName), null, false, cnt === fileSelections.length);
            cnt++;
        }
        setShowUnSortMany(false);
        toast.success("Επιτυχής ενέργεια.");
    }

    const handleUnRegisterMany = () => {
        let cnt = 1;
        for (let fileName of fileSelections) {
            onEmronRegister(formatPath(currentPath + "/" + fileName), null, false, cnt === fileSelections.length);
            cnt++;
        }
        setShowUnRegisterMany(false);
        toast.success("Επιτυχής ενέργεια.");
    }

    const handleMoveManyTo = () => {
        let cnt = 1;
        for (let fileName of fileSelections) {
            onEmronMove(formatPath(currentPath + "/" + fileName), showMoveManyTo, showMoveManyMonthTo, false, cnt === fileSelections.length);
            cnt++;
        }
        setShowMoveMany(false);
        toast.success("Επιτυχής ενέργεια.");
    }

    const handleRestoreMany = () => {
        let cnt = 1;
        for (let fileName of fileSelections) {
            onEmronRestore(formatPath(currentPath + "/" + fileName), false, cnt === fileSelections.length);
            cnt++;
        }
        setShowRestoreMany(false);
        toast.success("Επιτυχής ενέργεια.");
    }

    const canChangeEmronUnlockDays = getCurrentUser()?.userType === "Admin";
    const isPDF = uniqueSelectionType === "file" && String(uniqueSelection).toLocaleLowerCase().endsWith(".pdf");
    const isImage = uniqueSelectionType === "file" && (String(uniqueSelection).toLocaleLowerCase().endsWith(".jpg") || String(uniqueSelection).toLocaleLowerCase().endsWith(".png"));

    const canUnlock = Number(company.year) < Number(moment().format("YYYY"))
        || (Number(company.year) === Number(moment().format("YYYY")) && Number(moment().format("MM")) >= whatMonth(currentPath + "-" + folderSelections[0]));

    const isInUnsorted = String(currentPath).includes("/Αταξινόμητα/");
    const isInSorted = String(currentPath).includes("/Ταξινομημένα/");
    const isInRegistered = String(currentPath).includes("/Καταχωρημένα/");
    const isInDeleted = String(currentPath).includes("/Διαγραμμένα/");

    const monthsLock = +company.year === +moment().format("YYYY");
    const greekMonthOptions = greekMonths.map((opt, idx) => {
        if (idx + 1 <= +moment().format("MM") || !monthsLock) {
            return {
                value: opt,
                label: opt,
            }
        }
    }).filter(Boolean);

    return (
        <React.Fragment>
            <div style={contextMenuStyle} className={"contextMenu"}>
                {uniqueSelectionType === "parent" ? (
                    <React.Fragment>
                        <div className={`contextMenuOption ${specialCode === "1" ? "locked" : ""}`} onClick={() => {
                            if (specialCode !== "1") setNewFolderModal(true)
                        }}>Δημιουργία φακέλου
                        </div>
                        {hasCopiedFile && (
                            <div className={"contextMenuOption"} onClick={() => handleFilePaste()}>Επικόλληση</div>
                        )}
                    </React.Fragment>
                ) : isUniqueSelection ? (
                    <React.Fragment>
                        {uniqueSelectionType === "file" && (
                            <React.Fragment>
                                <div style={{borderBottom: "1px solid lightgray"}} className={"contextMenuOption"}
                                     onClick={(e) => {
                                         e.stopPropagation();
                                         e.preventDefault();
                                         setShowRenameFile(true);
                                     }}>Μετανομασία
                                </div>
                                <div style={{borderBottom: "1px solid lightgray"}} className={"contextMenuOption"}
                                     onClick={() => setShowDeleteFile(true)}>Διαγραφή
                                </div>
                                <div style={{borderBottom: "1px solid lightgray"}} className={"contextMenuOption"}
                                     onClick={() => handleFileDownload()}>Λήψη
                                </div>
                                {isPDF && (
                                    <React.Fragment>
                                        <div style={{borderBottom: "1px solid lightgray"}}
                                             className={"contextMenuOption"}
                                             onClick={() => handlePDFToImages()}>Μετατροπή σε εικόνες
                                        </div>
                                        <div style={{borderBottom: "1px solid lightgray"}}
                                             className={"contextMenuOption"}
                                             onClick={() => handlePDFToPDFs()}>Διάσπαση σε .PDFs
                                        </div>
                                    </React.Fragment>
                                )}
                                {specialCode !== "1" && (
                                    <React.Fragment>
                                        <div style={{borderBottom: "1px solid lightgray"}}
                                             className={"contextMenuOption"}
                                             onClick={() => handleFileCopy(false)}>Αντιγραφή
                                        </div>
                                        <div style={{borderBottom: "1px solid lightgray"}}
                                             className={"contextMenuOption"}
                                             onClick={() => handleFileCopy(true)}>Αποκοπή
                                        </div>
                                    </React.Fragment>
                                )}
                                {isImage && (
                                    <div className={"contextMenuOption"} style={{borderBottom: "1px solid lightgray"}} onClick={() => handleImagesToPDF()}>
                                        Μετατροπή σε .PDF
                                    </div>
                                )}
                                <div className={"contextMenuOption"}
                                     onClick={() => onFileHistory(uniqueSelection, false)}>Ιστορικό
                                </div>
                            </React.Fragment>
                        )}
                        {uniqueSelectionType === "folder" && (
                            <React.Fragment>
                                {specialCode === "1" ? (
                                    <React.Fragment>
                                        {locked && (
                                            <div className={`contextMenuOption ${!canUnlock ? "locked" : ""}`} onClick={() => {
                                                if (canUnlock) setShowEmronUnlock(true)
                                            }}>Ξεκλείδωμα φακέλου</div>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <div style={{borderBottom: "1px solid lightgray"}}
                                             className={"contextMenuOption"}
                                             onClick={(e) => {
                                                 e.stopPropagation();
                                                 e.preventDefault();
                                                 setShowRenameFolder(true);
                                             }}>Μετανομασία
                                        </div>
                                        <div style={{borderBottom: "1px solid lightgray"}}
                                             className={"contextMenuOption"}
                                             onClick={() => setShowDeleteFolder(true)}>Διαγραφή
                                        </div>
                                        <div className={"contextMenuOption"}
                                             onClick={() => onFileHistory(uniqueSelection, true)}>Ιστορικό
                                        </div>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className={"contextMenuOption"} onClick={() => handleManyDownload()}>Λήψη</div>
                        {(folderSelections.length === 0 && fileSelections.every((f) => String(f).toLowerCase().endsWith(".jpg") || String(f).toLowerCase().endsWith(".png"))) && (
                            <div className={"contextMenuOption"} style={{borderTop: "1px solid lightgray"}} onClick={() => handleImagesToPDF()}>
                                Μετατροπή σε .PDF
                            </div>
                        )}
                        {(folderSelections.length === 0 && fileSelections.length >= 1 && fileSelections.every((f) => String(f).toLowerCase().endsWith(".pdf"))) && (
                            <div className={"contextMenuOption"} style={{borderTop: "1px solid lightgray"}} onClick={() => handleConcatPDFs()}>
                                Συγχώνευση σε .PDF
                            </div>
                        )}
                        {(folderSelections.length >= 1 || fileSelections.length >= 1) && !(folderSelections.length > 0 && fileSelections.length === 0 && specialCode === "1") && (
                            <div className={"contextMenuOption"} style={{borderTop: "1px solid lightgray"}} onClick={() => setShowDeleteMultiple(true)}>
                                Διαγραφή
                            </div>
                        )}
                    </React.Fragment>
                )}
                {(isEmron && specialCode === "1") && (
                    <React.Fragment>
                        {(folderSelections.length === 0 && (isInUnsorted || isInSorted)) && (
                            <div className={"contextMenuOption"} style={{borderTop: "1px solid lightgray"}} onClick={() => {
                                setRegisterManyTo(registerOrSortToOptions.find((el) => String(currentPath).includes(el)));
                                setShowRegisterMany(true);
                            }}>
                                Καταχώρηση σε
                            </div>
                        )}
                        {(folderSelections.length === 0 && isInUnsorted) && (
                            <div className={"contextMenuOption"} style={{borderTop: "1px solid lightgray"}} onClick={() => {
                                setSortManyTo(registerOrSortToOptions.find((el) => String(currentPath).includes(el)));
                                setShowSortMany(true);
                            }}>
                                Ταξινόμηση σε
                            </div>
                        )}
                        {(folderSelections.length === 0 && isInUnsorted) && (
                            <div className={"contextMenuOption"} style={{borderTop: "1px solid lightgray"}} onClick={() => {
                                setShowMoveManyTo(registerOrSortToOptions.find((el) => String(currentPath).includes(el)));
                                setShowMoveManyMonthTo(greekMonths.find((el) => String(currentPath).includes(el)));
                                setShowMoveMany(true);
                            }}>
                                Μεταφορά σε
                            </div>
                        )}
                        {(folderSelections.length === 0 && isInSorted) && (
                            <div className={"contextMenuOption"} style={{borderTop: "1px solid lightgray"}} onClick={() => setShowUnSortMany(true)}>
                                Αναίρεση Ταξινόμησης
                            </div>
                        )}
                        {(folderSelections.length === 0 && isInRegistered) && (
                            <div className={"contextMenuOption"} style={{borderTop: "1px solid lightgray"}} onClick={() => setShowUnRegisterMany(true)}>
                                Αναίρεση Καταχώρησης
                            </div>
                        )}
                        {(folderSelections.length === 0 && isInDeleted) && (
                            <div className={"contextMenuOption"} style={{borderTop: "1px solid lightgray"}} onClick={() => setShowRestoreMany(true)}>
                                Επαναφορά
                            </div>
                        )}
                    </React.Fragment>
                )}
            </div>

            <Modal backdrop={"static"} show={newFolderModal} onHide={() => setNewFolderModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Δημιουργία φακέλου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        name={"folderName"}
                        label={"Όνομα Φακέλου"}
                        value={newFolderName}
                        autoComplete={"off"}
                        onChange={(e) => setNewFolderName(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setNewFolderModal(false)}>
                        Κλείσιμο
                    </Button>
                    <Button variant="primary" onClick={() => handleNewFolder()}>
                        Δημιουργία
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop={"static"} show={showRenameFile} onHide={() => setShowRenameFile(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Μετανομασία αρχείου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        name={"oldFileName"}
                        label={"Παλαιό όνομα αρχείου"}
                        value={uniqueSelection}
                        disabled={true}
                        className={"mb-1"}
                    />
                    <Input
                        name={"renameFileName"}
                        label={"Νέο όνομα αρχείου"}
                        value={renameFileName}
                        autoComplete={"off"}
                        onChange={(e) => setRenameFileName(e.target.value)}
                    />
                    <Badge bg={"dark"} style={{
                        position: "absolute",
                        right: "23px",
                        bottom: "21px"
                    }}>{"." + renameFileExtension}</Badge>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleFileRename()} disabled={invalidRenameFileName()}>
                        Μετανομασία
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop={"static"} show={showRenameFolder} onHide={() => setShowRenameFolder(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Μετανομασία φακέλου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        name={"oldFileName"}
                        label={"Παλαιό όνομα αρχείου"}
                        value={uniqueSelection}
                        disabled={true}
                        className={"mb-1"}
                    />
                    <Input
                        name={"renameFolderName"}
                        label={"Νέο όνομα φακέλου"}
                        value={renameFolderName}
                        autoComplete={"off"}
                        onChange={(e) => setRenameFolderName(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleFolderRename()} disabled={invalidRenameFolderName()}>
                        Μετανομασία
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop={"static"} show={showDeleteFile} onHide={() => setShowDeleteFile(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Διαγραφή αρχείου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Είσαστε σίγουροι ότι θέλετε να διαγράψετε το αρχείο <strong>{uniqueSelection}</strong>;
                    <div style={{borderTop: "1px solid lightgray"}} className={"mt-3 mb-1"}></div>
                    <TextArea
                        rows={3}
                        value={deleteCause}
                        label={"Αιτιολογία"}
                        autoComplete={"off"}
                        onChange={(e) => setDeleteCause(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleFileDelete()}>
                        Ναι, διαγραφή
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop={"static"} show={showDeleteMultiple} onHide={() => setShowDeleteMultiple(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Διαγραφή πολλαπλών αρχείων</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ΠΡΟΣΟΧΗ! Είσαστε σίγουροι ότι θέλετε να διαγράψετε τα αρχεία που επιλέξατε;
                    <div style={{borderTop: "1px solid lightgray"}} className={"mt-3 mb-1"}></div>
                    <TextArea
                        rows={3}
                        value={deleteCause}
                        label={"Αιτιολογία"}
                        autoComplete={"off"}
                        onChange={(e) => setDeleteCause(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleMultipleDelete()}>
                        Ναι, διαγραφή
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop={"static"} show={showDeleteFolder} onHide={() => setShowDeleteFolder(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Διαγραφή φακέλου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Είσαστε σίγουροι ότι θέλετε να διαγράψετε τον φάκελο <strong>{uniqueSelection}</strong>;
                    <br/>Θα διαγραφούν ΌΛΑ τα αρχεία/φάκελοι που περιέχει.
                    <div style={{borderTop: "1px solid lightgray"}} className={"mt-3 mb-1"}></div>
                    <TextArea
                        rows={3}
                        value={deleteCause}
                        label={"Αιτιολογία"}
                        autoComplete={"off"}
                        onChange={(e) => setDeleteCause(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleFolderDelete()}>
                        Ναι, διαγραφή
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop={"static"} show={showEmronUnlock} onHide={() => setShowEmronUnlock(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Ξεκλείδωμα φακέλου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        name={"emronUnlockDays"}
                        type={"number"}
                        label={"Ξεκλείδωμα για (μέρες)"}
                        disabled={!canChangeEmronUnlockDays}
                        value={emronUnlockDays}
                        onChange={(e) => {
                            if (canChangeEmronUnlockDays) setEmronUnlockDays(e.target.value)
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleEmronUnlock()}>
                        Ξεκλείδωμα
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop={"static"} show={pdfConversionModal} onHide={() => setPDFConversionModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Μετατροπή PDF σε εικόνες</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Είσαστε σίγουροι ότι θέλετε να μετατρέψετε αυτό το pdf σε εικόνες;<br/>
                    Θα φτιαχτεί ένα αρχείο για κάθε σελίδα. Τα ονόματα των αρχείων θα είναι<br/>
                    {String(uniqueSelection).replace(".pdf", "")}-IMG1.png<br/>
                    {String(uniqueSelection).replace(".pdf", "")}-IMG2.png<br/>
                    ...
                    <BetterCheckBox
                        name={"deleteAfterConversion"}
                        className={"mt-3 mb-0"}
                        text={"Διαγραφή αρχείου μετά από την μετατροπή"}
                        checked={deleteAfterConversion}
                        onChange={(e) => setDeleteAfterConversion(e.target.checked)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleSaveConversionOfPDF()}>
                        Μετατροπή
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop={"static"} show={imagesConversionModal} onHide={() => setImagesConversionModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Μετατροπή εικόνων σε PDF</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Είσαστε σίγουροι ότι θέλετε να μετατρέψετε αυτές τις εικόνες σε pdf;<br/>
                    Για κάθε εικόνα θα παραχθεί μία σελίδα που θα την περιέχει. Επιλέξτε την σειρά που επιθημείτε παρακάτω<br/>
                    <table className={"simpleClassicTable mt-1"}>
                        <colgroup>
                            <col span={1} style={{width: "80%"}}></col>
                            <col span={1} style={{width: "20%"}}></col>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>Φωτογραφία</th>
                            <th>Σελίδα</th>
                        </tr>
                        </thead>
                        <tbody>
                        {fileSelections.map((fName, idx) => (
                            <tr key={`imagesConversion${idx}`}>
                                <td>{fName}</td>
                                <td><input style={{width: "100%"}} defaultValue={fileSelectionOrder[idx]} type={"number"} onBlur={(e) => {
                                    let copyArr = [...fileSelectionOrder];
                                    copyArr[idx] = parseFloat(e.target.value);
                                    setFileSelectionOrder(copyArr);
                                }} /></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    <div style={{borderTop: "1px solid lightgray"}} className={"mt-2 mb-1"}></div>
                    <div style={{position: "relative"}}>
                        <Input
                            name={"newPDFName"}
                            label={"Όνομα αρχείου"}
                            value={newPDFName}
                            autoComplete={"off"}
                            required={true}
                            onChange={(e) => setNewPDFName(e.target.value)}
                        />
                        <Badge bg={"dark"} style={{
                            position: "absolute",
                            right: "8px",
                            bottom: "6px"
                        }}>{".pdf"}</Badge>
                    </div>
                    <BetterCheckBox
                        name={"deleteAfterConversion"}
                        className={"mt-3 mb-0"}
                        text={"Διαγραφή εικόνων μετά από την μετατροπή"}
                        checked={deleteAfterConversion}
                        onChange={(e) => setDeleteAfterConversion(e.target.checked)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleSaveImagesToPDF()}>
                        Μετατροπή
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop={"static"} show={pdfConcatModal} onHide={() => setPDFConcatModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Συγχώνευση αρχείων PDF</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Είσαστε σίγουροι ότι θέλετε να συγχωνέψετε αυτά τα pdf σε ένα;<br/>
                    Για κάθε εικόνα θα παραχθεί μία σελίδα που θα την περιέχει. Επιλέξτε την σειρά που επιθημείτε παρακάτω<br/>
                    <table className={"simpleClassicTable mt-1"}>
                        <colgroup>
                            <col span={1} style={{width: "80%"}}></col>
                            <col span={1} style={{width: "20%"}}></col>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>Φωτογραφία</th>
                            <th>Σελίδα</th>
                        </tr>
                        </thead>
                        <tbody>
                        {fileSelections.map((fName, idx) => (
                            <tr key={`imagesConversion${idx}`}>
                                <td>{fName}</td>
                                <td><input style={{width: "100%"}} defaultValue={fileSelectionOrder[idx]} type={"number"} onBlur={(e) => {
                                    let copyArr = [...fileSelectionOrder];
                                    copyArr[idx] = parseFloat(e.target.value);
                                    setFileSelectionOrder(copyArr);
                                }} /></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div style={{borderTop: "1px solid lightgray"}} className={"mt-2 mb-1"}></div>
                    <div style={{position: "relative"}}>
                        <Input
                            name={"newPDFName"}
                            label={"Όνομα νέου αρχείου"}
                            value={newPDFName}
                            autoComplete={"off"}
                            required={true}
                            onChange={(e) => setNewPDFName(e.target.value)}
                        />
                        <Badge bg={"dark"} style={{
                            position: "absolute",
                            right: "8px",
                            bottom: "6px"
                        }}>{".pdf"}</Badge>
                    </div>
                    <BetterCheckBox
                        name={"deleteAfterConversion"}
                        className={"mt-3 mb-0"}
                        text={"Διαγραφή εικόνων μετά από την συγχώνευση"}
                        checked={deleteAfterConversion}
                        onChange={(e) => setDeleteAfterConversion(e.target.checked)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleSaveConcatPDFs()}>
                        Συγχώνευση
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop={"static"} show={pdfExportModal} onHide={() => setPDFExportModal(false)} dialogClassName={"modal42PercentWidth"}>
                <Modal.Header closeButton>
                    <Modal.Title>Διάσπαση αρχείου PDF</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Παρακαλώ συμπληρώστε τον παρακάτω πίνακα για τον τρόπο διάσπασης του αρχείου.

                    <Badge bg={"success"} style={{cursor: "pointer", fontSize: "20px", float: "right"}} className={"pl-1 pr-1 pt-0 pb-0"}
                           onClick={() => {
                               let prepObj = {...pdfExportDefaultObject};
                               prepObj.id = String(pdfExportData.length + 1);
                               setPDFExportData([...pdfExportData, prepObj]);
                           }}
                    title={"Προσθήκη γραμμής"}>+</Badge>
                    <table className={"simpleClassicTable mt-2 mb-2"}>
                        <colgroup>
                            <col style={{width: "10%"}}></col>
                            <col style={{width: "50%"}}></col>
                            <col style={{width: "30%"}}></col>
                            <col style={{width: "10%"}}></col>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>Αρ.</th>
                            <th>Όνομα Αρχείου που θα δημιουργηθεί</th>
                            <th>Σελίδες προς εξαγωγή</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {pdfExportData.length > 0 && pdfExportData.map((row, idx) => (
                            <tr key={`pdfToPdfs-${idx}`}>
                                <td>{row["id"]}</td>
                                <td>
                                    <input style={{width: "100%"}} key={row.fileName} defaultValue={row.fileName} onBlur={(e) => {
                                        const clone = structuredClone(pdfExportData);
                                        clone[idx].fileName = e.target.value;
                                        setPDFExportData(clone);
                                    }} />
                                </td>
                                <td>
                                    <input style={{width: "100%"}} key={row.selection} defaultValue={row.selection} onBlur={(e) => {
                                        const clone = structuredClone(pdfExportData);
                                        clone[idx].selection = e.target.value;
                                        setPDFExportData(clone);
                                    }}/>
                                </td>
                                <td><Badge bg={"danger"} style={{cursor: "pointer"}} onClick={() => {
                                    let clone = structuredClone(pdfExportData);
                                    clone.splice(idx, 1);
                                    clone.forEach((r, idx) => r.id = String(idx + 1));
                                    setPDFExportData(clone);
                                }} title={"Διαγραφή γραμμής"}><i className="fa-solid fa-trash"></i></Badge></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <Alert variant={"secondary"}>
                        (*) Διαχωρίστε τις σελίδες μεμονωμένα με κόμμα (,) και αν επιθυμήτε φάσμα σελιδών χρησιμοποιήστε την παύλα (-). π.χ.<br/>
                        - 1, 2, 3, 10<br/>
                        - 1-5, 5, 6, 15-20, 100
                    </Alert>
                    <div style={{borderTop: "1px solid lightgray"}} className={"mt-2 mb-1"}></div>
                    <BetterCheckBox
                        name={"deleteAfterConversion"}
                        className={"mt-3 mb-0"}
                        text={"Διαγραφή αρχείου μετά από την διάσπαση"}
                        checked={deleteAfterConversion}
                        onChange={(e) => setDeleteAfterConversion(e.target.checked)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleSavePDFtoPDFs()}>
                        Διάσπαση
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showRegisterMany} onHide={() => setShowRegisterMany(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Καταχώρηση</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showRegisterMany && (
                        <React.Fragment>
                            Παρακαλώ επιλέξτε την κατηγορία που θέλετε να καταχωρήσετε τα αρχεία:
                            {fileSelections.map((name, idx) => (
                                <span key={`regMany-${idx}`}><br/>- <strong>{name}</strong></span>
                            ))}
                            <Dropdown
                                name={"Κατηγορία"}
                                label={"Κατηγορία"}
                                className={"mt-2"}
                                options={registerOrSortToOptionsDD}
                                key={Math.random()}
                                defaultValue={registerOrSortToOptionsDD.find((el) => el.value === registerManyTo)}
                                onChange={(e) => setRegisterManyTo(e.value)}
                            />
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleRegisterMany()}>
                        Καταχώρηση
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSortMany} onHide={() => setShowSortMany(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Ταξινόμηση</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showSortMany && (
                        <React.Fragment>
                            Παρακαλώ επιλέξτε την κατηγορία που θέλετε να ταξινομήσετε τα αρχεία:
                            {fileSelections.map((name, idx) => (
                                <span key={`regMany-${idx}`}><br/>- <strong>{name}</strong></span>
                            ))}
                            <Dropdown
                                name={"Κατηγορία"}
                                label={"Κατηγορία"}
                                className={"mt-2"}
                                options={registerOrSortToOptionsDD}
                                key={Math.random()}
                                defaultValue={registerOrSortToOptionsDD.find((el) => el.value === sortManyTo)}
                                onChange={(e) => setSortManyTo(e.value)}
                            />
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleSortMany()}>
                        Ταξινόμηση
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showUnSortMany} onHide={() => setShowUnSortMany(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Αναίρεση ταξινόμησης</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showUnSortMany && (
                        <React.Fragment>
                            Είσαστε σίγουροι ότι θέλετε να αναιρέσετε την ταξινόμηση των αρχείων:
                            {fileSelections.map((name, idx) => (
                                <span key={`regMany-${idx}`}><br/>- <strong>{name}</strong></span>
                            ))}
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleUnSortMany()}>
                        Αναίρεση ταξινόμησης
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showUnRegisterMany} onHide={() => setShowUnRegisterMany(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Αναίρεση καταχώρησης</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showUnRegisterMany && (
                        <React.Fragment>
                            Είσαστε σίγουροι ότι θέλετε να αναιρέσετε την καταχώρηση των αρχείων:
                            {fileSelections.map((name, idx) => (
                                <span key={`regMany-${idx}`}><br/>- <strong>{name}</strong></span>
                            ))}
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleUnRegisterMany()}>
                        Αναίρεση καταχώρησης
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showMoveMany} onHide={() => setShowMoveMany(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Μεταφορά</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showMoveMany && (
                        <React.Fragment>
                            Παρακαλώ επιλέξτε την κατηγορία που θέλετε να μεταφέρετε τα αρχεία:
                            {fileSelections.map((name, idx) => (
                                <span key={`regMany-${idx}`}><br/>- <strong>{name}</strong></span>
                            ))}
                            <Dropdown
                                name={"Κατηγορία"}
                                label={"Κατηγορία"}
                                className={"mt-2"}
                                options={registerOrSortToOptionsDD}
                                key={Math.random()}
                                defaultValue={registerOrSortToOptionsDD.find((el) => el.value === showMoveManyTo)}
                                onChange={(e) => setShowMoveManyTo(e.value)}
                            />
                            <Dropdown
                                name={"Μήνας"}
                                label={"Μήνας"}
                                className={"mt-2"}
                                options={greekMonthOptions}
                                key={Math.random()}
                                defaultValue={greekMonthOptions.find((el) => el.label === showMoveManyMonthTo)}
                                onChange={(e) => setShowMoveManyMonthTo(e.label)}
                            />
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleMoveManyTo()}>
                        Μεταφορά
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showRestoreMany} onHide={() => setShowRestoreMany(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Επαναφορά</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showRestoreMany && (
                        <React.Fragment>
                            Είσαστε σίγουροι ότι θέλετε να επαναφέρετε τα εξής αρχεία:
                            {fileSelections.map((name, idx) => (
                                <span key={`regMany-${idx}`}><br/>- <strong>{name}</strong></span>
                            ))}
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleRestoreMany()}>
                        Επαναφορά
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default ContextMenu;
