import React, {useRef, useState} from "react";
import Tabs from "../../layouts/Tabs";
import {Button, Row, Col, Modal, Badge, OverlayTrigger, Tooltip} from "react-bootstrap";
import Dropdown from "../../primer/Dropdown";
import {
    checkPermission,
    companyYearOptions,
    escapeRegex,
    getCurrentUser,
    isEmpty
} from "../../../_helpers/commonFunctions";
import "./index.css";
import Input from "../../common/Input";
import {useDispatch, useSelector} from "react-redux";
import {setCompany} from "../../../_reducers/companySlice";
import {useEffect} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {logout} from "../../../_reducers/Login";
import {useTranslation} from "react-i18next";
import {
    fetchPrimerMyDataCredentials
} from "../../../_apis/api";
import CallCenterLive from "../data-panel/CRM/callcenter-live";
import * as moment from "moment";
import "../../primer/POPUP-COMPONENTS/modalWidths.css";
import {useMediaQuery} from "@mui/material";
import {setUpgradeEmployeeLimitModalData} from "../../../_reducers/DataPanel/Payroll/payrollSlice";
import {addTab, updateActiveMainTab} from "../../../_reducers/TabsSlice";
import EmronClientData from "./emronClientData";
import AdvancedCombobox from "../../primer/AdvancedCombobox";
import {setShowPrimerMyDataToExpireModal} from "../../../_reducers/activitySlice";

const apiLoginURL = process.env.REACT_APP_LOGIN_URL;
const errorAuth = "You are not Authorized!";

const ViewWrapper = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const [companyData, setCompanyData] = useState([]);
    const [companyFilterInput, setCompanyFilterInput] = useState("");
    const [show, setShow] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [tableData, setTableData] = useState(companyData);
    const [selectedData, setSelectedData] = useState({year: new Date().getFullYear(), _id: ""});
    const permissions = localStorage.getItem("permissions");
    const permissionsData = JSON.parse(permissions);
    const [incrKey, setIncrKey] = useState(0);
    const [prefillCompany, setPrefillCompany] = useState(true);
    const [doubleClicked, setDoubleClicked] = useState(false);
    const [showPasswordsModal, setShowPasswordsModal] = useState(false);

    const companyNameAndYear = `${company.name} - ${company.year}`;

    const selectCompanyRef = useRef();

    const emronCompanyInvalid = useSelector((state) => state.ACTIVITY.emronCompanyInvalid);
    const canBypassInvalidCompany = useSelector((state) => state.ACTIVITY.canBypassInvalidCompany);
    const emronCompanyInvalidDays = useSelector((state) => state.ACTIVITY.emronCompanyInvalidDays);
    const isEmronClient = useSelector((state) => state.ACTIVITY.isEmronClient);
    const emronClientData = useSelector((state) => state.ACTIVITY.emronClientData);
    const [showEmronCompanyInvalidModal, setShowEmronCompanyInvalidModal] = useState(false);

    const upgradeEmployeeLimitModalData = useSelector((state) => state.PAYROLL.upgradeEmployeeLimitModalData);

    const isMobileScreen = useMediaQuery("(max-width: 980px)");

    const isEmron = useSelector((state) => state.ACTIVITY.isEmron);
    const [showEmronModal, setShowEmronModal] = useState(false);
    const [companyAccess, setCompanyAccess] = useState([]);
    const [companyAccessFilter, setCompanyAccessFilter] = useState([]);
    const [emronModalSelectedCompany, setEmronModalSelectedCompany] = useState({});
    const [emronModalSelectedCompanyDisplay, setEmronModalSelectedCompanyDisplay] = useState("");
    const [emronModalSelectedCompanyData, setEmronModalSelectedCompanyData] = useState({});

    const isEmronConnectFileERP = useSelector((state) => state.ACTIVITY.isEmronConnectFileERP);
    const primerMyDataToExpireModal = useSelector((state) => state.ACTIVITY.primerMyDataToExpireModal);
    const primerMyDataToExpireModalLink = useSelector((state) => state.ACTIVITY.primerMyDataToExpireModalLink);
    const primerMyDataExpirationModalMessage = useSelector((state) => state.ACTIVITY.primerMyDataExpirationModalMessage);

    useEffect(() => {
        if (doubleClicked) {
            setDoubleClicked(false);
            handleSubmit();
        }
    }, [doubleClicked])

    useEffect(() => {
        if (show) {
            getCompanies(selectedData);
        }
    }, [show, selectedData.year])

    useEffect(() => {
        filterCompanies();
        setIncrKey(incrKey + 1);
    }, [companyFilterInput, companyData])

    useEffect(() => {
        if (emronCompanyInvalidDays > 0) {
            setShowEmronCompanyInvalidModal(true);
        }
    }, [emronCompanyInvalid, emronCompanyInvalidDays])

    useEffect(() => {
        if (prefillCompany) {
            const current = localStorage.getItem("company");
            if (current) {
                const companyObject = JSON.parse(current);
                if (companyObject) {
                    setSelectedData({year: companyObject.year, name: companyObject.name, _id: companyObject.id, vatNumber: companyObject.vatNumber});
                    setShowButton(true);
                    setPrefillCompany(false);
                }
            }
        }
    }, [prefillCompany])

    const handleFooterModalClose = () => {
        setCompanyFilterInput("");
        setTableData(companyData);
        setShow(false);
    }

    const handleFooterModalOpen = () => {
        setTableData([]);
        setShow(true);
    }

    const getCompanies = (selectedData) => {
        const user = JSON.parse(localStorage.getItem('user'));
        let uType = "user";
        if (user?.userType === "Admin") uType = "admin";
        axios.get(process.env.REACT_APP_LOGIN_BACKEND_URL + `/api/${uType}/company/list?year=${selectedData.year.toString()}`, {
            headers: {authorization: `Bearer ${localStorage.getItem("token")}`},
        }).then((res) => {
            if (res.status === 200) {
                setCompanyData(res.data.data);
                setTableData(res.data.data);
                setTimeout(() => {
                    if (selectCompanyRef && selectCompanyRef?.current) {
                        selectCompanyRef.current?.scrollIntoView({
                            behavior: "instant",
                            block: "center",
                            inline: "start"
                        })
                    }
                }, 50)
            } else if (res.status === 401) {
                localStorage.clear();
                window.location.replace(`${apiLoginURL}?redirect=ERP&error=${errorAuth}`)
            }
        }).catch((err) => {
            if (err) {
                let message = 'Service is unavailable for the moment. Please try again later.'
                localStorage.clear();
                dispatch(logout());
                window.location.replace(`${apiLoginURL}?redirect=ERP&error=${message}`);
            }
        })
    }

    const handleYearChange = (e) => {
        setSelectedData({...selectedData, year: e.value});
        //axios.get(apiURL + `/company/list?year=${e.value.toString()}`)
        const user = JSON.parse(localStorage.getItem('user'));
        let uType = "user";
        if (user?.userType === "Admin") uType = "admin";
        axios.get(process.env.REACT_APP_LOGIN_BACKEND_URL + `/api/${uType}/company/list?year=${e.value.toString()}`, {
            headers: {authorization: `Bearer ${localStorage.getItem("token")}`},
        }).then((res) => {
            console.log(res.data);
            if (res.data.status === 200) {
                setCompanyData(res.data.data);
                if (res.data.data?.find((el) => el.vatNumber === selectedData.vatNumber) && !isEmpty(selectedData.vatNumber)) {
                    setSelectedData({...selectedData, year: e.value});
                }
            } else if (res.data.status === "401") {
                localStorage.clear();
                window.location.replace(`${apiLoginURL}?redirect=ERP&error=${errorAuth}`)
            }
        }).catch((err) => {
            if (err) {
                let message = 'Service is unavailable for the moment. Please try again later.'
                localStorage.clear();
                dispatch(logout());
                window.location.replace(`${apiLoginURL}?redirect=ERP&error=${message}`);
            }
        })
    }

    const filterCompanies = () => {
        if (String(companyFilterInput).length > 0) {
            let filteredData = [];
            let value = companyFilterInput.toString().toLowerCase();
            const pattern = new RegExp(value, "gi");
            if (companyData)
                filteredData = companyData.filter((item) => {
                    let returnType = false;
                    for (const key in item) {
                        if (key === "name" || key === "vatNumber") {
                            let string = item[key].replace(/\s/g, '')
                            if (pattern.test(string)) {
                                returnType = true;
                            }
                        }
                    }
                    return returnType;
                });
            setTableData(filteredData);
        } else {
            setTableData(companyData);
        }
    }

    const handleSubmit = () => {
        let erpURL = "";
        if (String(selectedData._id) === String(company.id) && String(selectedData.year) === String(company.year)) {
            handleFooterModalClose();
        } else if (selectedData._id) {
            let companyD = {
                id: selectedData._id,
                name: selectedData.name,
                vatNumber: selectedData.vatNumber,
                year: selectedData.year,
            };
            dispatch(setCompany(companyD));
            localStorage.setItem("company", JSON.stringify(companyD));
            companyData.forEach((c) => {
                c.years.forEach((y) => {
                    if (String(y.year) === String(selectedData.year) && selectedData._id === c._id) {
                        erpURL = y.erp_ip;
                    }
                })
            })
            toast.success("Company Changed successfully.");
            handleFooterModalClose();
            dispatch(fetchPrimerMyDataCredentials(companyD.id));
            window.location.replace(`${erpURL}?token=${localStorage.getItem("token")}&company=${JSON.stringify(selectedData)}&device_id=${localStorage.getItem("deviceId")}&erp_ip=${erpURL}`);
        }
    }

    const handleCompanySelection = (company, doubleClick = false) => {
        if (!doubleClick) {
            setShowButton(true);
            setSelectedData({...selectedData, ...company});
        } else {
            setShowButton(false);
            setSelectedData({...selectedData, ...company});
            setDoubleClicked(true);
        }
    }

    const handleEmronClientPayNow = () => {
        axios.post(process.env.REACT_APP_API_URL2 + "/emron/emron-pay-now-link", {
            company: company.id,
            vatNumber: company.vatNumber,
        }).then((res) => {
            if (res.data.status === "200") {
                window.open(res.data.linkURL, '_blank').focus();
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const isSelected = (vatNumber) => {
        return vatNumber === selectedData.vatNumber;
    }

    const tdStyle = {
        padding: "7px",
        borderLeft: "1px solid lightgray",
        borderTop: "1px solid lightgray",
        borderRight: "1px solid lightgray",
        borderBottom: "1px solid black",
    }

    const showEmronPayNowButton = (emronCompanyInvalidDays > 0 && isEmronClient);

    const handleOpenCloudTab = () => {
        const findMatchingTab = TABS_DATA.findIndex((item) => item === "cloud");
        if (findMatchingTab === -1) {
            dispatch(addTab("cloud"));
            dispatch(updateActiveMainTab(TABS_DATA.length));
        } else {
            dispatch(updateActiveMainTab(findMatchingTab));
        }
    }

    useEffect(() => {
        if (isEmron) getCompanyAccess();
    }, [isEmron]);

    useEffect(() => {
        if (isEmron && !isEmpty(emronModalSelectedCompany.value) && !isEmpty(emronModalSelectedCompany.label)) {
            setEmronModalSelectedCompanyDisplay(emronModalSelectedCompany.label);
            axios.get(`${process.env.REACT_APP_API_URL2}/emron/get-client-data`, {
                headers: { 'Content-Type': 'application/json' },
                params: {
                    selection: emronModalSelectedCompany.value,
                }
            }).then((res) => {
                if (res.data.status === "200") {
                    setEmronModalSelectedCompanyData(res.data.data);
                } else {
                    setEmronModalSelectedCompanyData({});
                    toast.error(res.data.message);
                }
            }).catch((err) => {
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.")
            })
        }
    }, [emronModalSelectedCompany])

    const getCompanyAccess = () => {
        axios.get(`${process.env.REACT_APP_API_URL2}/company/get-user-companies-access`, {
            headers: {"Content-Type": "application/json"},
            params: {
                userId: getCurrentUser()?._id,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                let acc = [];
                for (let comp of res.data.data) {

                    acc.push({
                        label: `${comp.name} : ${comp.vatNumber}`,
                        value: comp._id, name: comp.name,
                        vatNumber: comp.vatNumber,
                        phone: comp.contactPoints ? `${comp.phone}${comp.contactPoints.map((el) => !isEmpty(el.mobile) ? `,${el.mobile}` : "")}` : comp.phone,
                        contactPoints: comp.contactPoints ? comp.contactPoints.map((el) => el.name)?.join(", ") : "",
                    });
                }
                setCompanyAccess(acc);
                setCompanyAccessFilter(acc);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const onChangeSearch = (keyword) => {
        setEmronModalSelectedCompanyDisplay(keyword);
        if (keyword.length >= 2) {
            keyword = escapeRegex(keyword);
            const regex = new RegExp(keyword, "i");
            const filtered = companyAccess.filter((el) => (regex.test(el.name) || regex.test(el.vatNumber) || regex.test(el.phone) || regex.test(el.contactPoints ?? "")));
            setCompanyAccessFilter(filtered);
        } else {
            setCompanyAccessFilter(companyAccess);
        }
    }

    const handleOnClear = () => {
        setEmronModalSelectedCompanyDisplay("");
        setEmronModalSelectedCompany({...emronModalSelectedCompany, label: ""});
        setCompanyAccessFilter(companyAccess);
    }

    const companyColumns = [
        {
            label: "Όνομα",
            name: "name",
        }, {
            label: "Α.Φ.Μ.",
            name: "vatNumber",
        }, {
            label: "Τηλέφωνο",
            name: "phone",
        }, {
            label: "Επαφές",
            name: "contactPoints",
        }
    ];

    return (
        <div className="main-panel fixedPanel">
            {(emronCompanyInvalid && emronCompanyInvalidDays > 40 && !canBypassInvalidCompany) && (
                <div hidden={!emronCompanyInvalid} style={{paddingLeft: "20px", paddingTop: "20px"}}>
                    Η παρούσα εταιρεία δεν είναι προσβάσιμη επειδή έχει λήξει η μηνιαία χρέωση του πελάτη.
                </div>
            )}
            <div
                className="content-wrapper"
                key={"view-wrapper-static"}
                hidden={emronCompanyInvalid && emronCompanyInvalidDays > 40 && !canBypassInvalidCompany}
                style={{marginTop: isMobileScreen ? "35px" : ""}}
            >
                <Tabs/>
            </div>

            <Modal className="themeModal" show={show} onHide={() => handleFooterModalClose()}
                   dialogClassName={"modal42PercentWidth"}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('CompanyTable.selectCompanyAndYear')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="pb-3">
                        <Col md={9}>
                            <Input
                                type="text"
                                name="search"
                                label={"Αναζήτηση"}
                                autoComplete={"off"}
                                placeholder={t('CompanyTable.placeholder')}
                                onChange={(e) => setCompanyFilterInput(e.target.value)}
                            />
                        </Col>
                        <Col md={3}>
                            <Dropdown
                                label={"Έτος"}
                                defaultValue={{
                                    label: selectedData.year,
                                    value: selectedData.year,
                                }}
                                options={companyYearOptions()}
                                classes="w-100"
                                onChange={(e) => handleYearChange(e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div style={{maxHeight: "55vh", overflowY: "auto"}}>
                                <table style={{width: "100%"}}>
                                    <colgroup>
                                        <col span={1} style={{width: "80%"}}></col>
                                        <col span={1} style={{width: "20%"}}></col>
                                    </colgroup>
                                    <thead style={{textAlign: "center", backgroundColor: "var(--light-skyblue)", position: "sticky", top: "-1px"}}>
                                    <tr>
                                        <th style={tdStyle}>{t('CompanyTable.company')}</th>
                                        <th style={tdStyle}>{t("General.vatNumber")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tableData?.length > 0 ? (
                                        <React.Fragment>
                                            {tableData.map((company, idx) => (
                                                <tr key={`companyModalTable-${idx}`}
                                                    onClick={() => handleCompanySelection(company, false)}
                                                    onDoubleClick={() => handleCompanySelection(company, true)}
                                                    style={{backgroundColor: isSelected(company.vatNumber) ? "var(--light-skyblue)" : "", border: isSelected(company.vatNumber) ? "2px solid black" : ""}}
                                                    ref={isSelected(company.vatNumber) ? selectCompanyRef : null}>
                                                    <td style={tdStyle}>{company.name}</td>
                                                    <td style={{
                                                        ...tdStyle,
                                                        textAlign: "center"
                                                    }}>{company.vatNumber}</td>
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                    ) : (
                                        <tr>
                                            <td colSpan={2}>Δεν βρέθηκαν δεδομένα.</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {showButton === true &&
                        <Button variant="primary" onClick={() => handleSubmit()}>{t('CompanyTable.select')}</Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={showEmronCompanyInvalidModal} backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title>Ενημέρωση</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(emronCompanyInvalidDays > 0 && emronCompanyInvalidDays <= 40) && (
                        <React.Fragment>
                            {isEmronClient ? (
                                <React.Fragment>
                                    {emronCompanyInvalidDays === 38 ? (
                                        <span>
                                            Υπενθυμίζουμε ότι η μηνιαία σας συνδρομή για τις λογιστικές υπηρεσίες παραμένει ανεξόφλητη και σε 48 ώρες θα λήξει οριστικά,
                                            διακόπτοντας την πρόσβαση του λογιστηρίου στην εταιρεία σας
                                            (δεν θα μπορούν να αποσταλούν οι μηνιαίες φορολογικές σας υποχρεώσεις (π.χ. ΦΠΑ, μισθοδοσία, κ.λ.π.).
                                            Παρακαλούμε φροντίστε άμεσα για την εξόφληση, κάνοντας χρήση του παρακάτω συνδέσμου (Πληρωμή Τώρα).
                                        </span>
                                    ) : (emronCompanyInvalidDays === 39 || emronCompanyInvalidDays === 40) ? (
                                        <span>
                                            Υπενθυμίζουμε ότι η μηνιαία σας συνδρομή για τις λογιστικές υπηρεσίες παραμένει ανεξόφλητη και σε 24 ώρες θα λήξει οριστικά,
                                            διακόπτοντας την πρόσβαση του λογιστηρίου στην εταιρεία σας
                                            (δεν θα μπορούν να αποσταλούν οι μηνιαίες φορολογικές σας υποχρεώσεις (π.χ. ΦΠΑ, μισθοδοσία, κ.λ.π.).
                                            Παρακαλούμε φροντίστε άμεσα για την εξόφληση, κάνοντας χρήση του παρακάτω συνδέσμου (Πληρωμή Τώρα).
                                        </span>
                                    ) : (
                                        <span>
                                            Η πληρωμή της μηνιαίας σας συνδρομής για τις λογιστικές υπηρεσίες του τρέχοντα μήνα απέτυχε.
                                            Παρακαλούμε για την άμεση εξόφληση κάνοντας χρήση της επιλογής "Πληρωμή Τώρα" ώστε να εξασφαλιστεί η απρόσκοπτη παροχή των λογιστικών υπηρεσιών.
                                        </span>
                                    )}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    Η ανανέωση της μηνιαίας συνδρομής λογιστικών υπηρεσιών του πελάτη απέτυχε, λόγω μη εξόφλησης.
                                    Παρακαλούμε επικοινωνήστε μαζί του ώστε να φροντίσει για την τακτοποίησή της.
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                    {emronCompanyInvalidDays > 40 && (
                        <React.Fragment>
                            {isEmronClient ? (
                                <React.Fragment>
                                    Η μηνιαία συνδρομή σας για τις λογιστικές υπηρεσίες έχει λήξει οριστικά λόγω ανεξόφλητου υπολοίπου και η πρόσβαση του λογιστηρίου στην εταιρεία σας έχει διακοπεί.
                                    Το λογιστήριο δεν μπορεί να αποστείλει τις μηνιαίες φορολογικές υποχρεώσεις
                                    σας (π.χ. ΦΠΑ, μισθοδοσία, κ.λ.π.). Παρακαλούμε φροντίστε άμεσα για την εξόφληση, κάνοντας χρήση του παρακάτω συνδέσμου (Πληρωμή Τώρα),
                                    ώστε να γίνει ενεργοποίηση της συνδρομής σας.
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {!canBypassInvalidCompany ? (
                                        <React.Fragment>
                                            Η πρόσβασή σας έχει διακοπεί στην παρούσα εταιρεία, καθώς η συνδρομή του πελάτη έχει λήξει λόγω μη εξόφλησης.
                                            Παρακαλούμε επικοινωνήστε μαζί του ώστε να φροντίσει άμεσα για την τακτοποίησή της.
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            Η ανανέωση της μηνιαίας συνδρομής λογιστικών υπηρεσιών του πελάτη απέτυχε, λόγω μη εξόφλησης.
                                            Παρακαλούμε επικοινωνήστε μαζί του ώστε να φροντίσει για την τακτοποίησή της.
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowEmronCompanyInvalidModal(false)}>
                        Εντάξει
                    </Button>
                    {isEmronClient && (
                        <Button variant={"outline-primary"} onClick={() => handleEmronClientPayNow()}>
                            Πληρωμή τώρα
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>

            {emronClientData && (
                <Modal show={showPasswordsModal} onHide={() => setShowPasswordsModal(false)} dialogClassName={"modal42PercentWidth"}>
                    <Modal.Header closeButton>
                        <Modal.Title>Κωδικοί Πελάτη</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {emronClientData?.emronPasswords?.length > 0 ? (
                            <div>
                                <table className={"simpleClassicTable"}>
                                    <colgroup>
                                        <col span={1} style={{width: "10%"}}></col>
                                        <col span={1} style={{width: "20%"}}></col>
                                        <col span={1} style={{width: "20%"}}></col>
                                        <col span={1} style={{width: "20%"}}></col>
                                        <col span={1} style={{width: "30%"}}></col>
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        <th>Αρ.</th>
                                        <th>Τύπος</th>
                                        <th>Όνομα Χρήστη</th>
                                        <th>Κωδικός</th>
                                        <th>Σημειώσεις</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {emronClientData?.emronPasswords.map((row, idx) => (
                                        <tr key={`table-eP${idx}`} style={{wordBreak: "break-all"}}>
                                            <td>{row["no"]}</td>
                                            <td>{row["type"]}</td>
                                            <td>{row["username"]}</td>
                                            <td>{row["password"]}</td>
                                            <td>{row["notes"]}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div>
                                Δεν βρέθηκαν κωδικοί πελάτη
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setShowPasswordsModal(false)}>
                            Κλείσιμο
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            <Modal show={upgradeEmployeeLimitModalData.show} backdrop={"static"} style={{zIndex: 2000}}>
                <Modal.Header>
                    <Modal.Title>Αναβάθμιση πακέτου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {upgradeEmployeeLimitModalData.text}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => dispatch(setUpgradeEmployeeLimitModalData({show: false, text: ""}))}>
                        Κλείσιμο
                    </Button>
                    <Button variant="primary" onClick={() => dispatch(setUpgradeEmployeeLimitModalData({show: false, text: ""}))}>
                        Αναβάθμιση
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop={"static"} show={showEmronModal} onHide={() => setShowEmronModal(false)} dialogClassName={"modal65PercentWidth"}>
                <Modal.Header closeButton>
                    <Modal.Title>Αναζήτηση πελάτη</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={5}>
                            <AdvancedCombobox
                                className="w-200 ac-select-dropdown"
                                inputFieldClasses="form-control"
                                defaultValue={emronModalSelectedCompanyDisplay}
                                onChange={(e) => setEmronModalSelectedCompany(e.row)}
                                onChangeSearch={onChangeSearch}
                                columns={companyColumns}
                                excludeColumns={[]}
                                searchableDropdownEnable={false}
                                showTableOptionOnSelect={true}
                                editEnable={false}
                                deleteEnable={false}
                                searchTableEnable={false}
                                label={"Εταιρείες"}
                                placeholder={"Επιλέξτε εταιρεία"}
                                data={companyAccessFilter}
                                resetFilter={handleOnClear}
                            />
                        </Col>
                    </Row>
                    {Object.keys(emronModalSelectedCompanyData).length > 0 && (
                        <Row>
                            <Col md={12}>
                                <EmronClientData
                                    clientData={emronModalSelectedCompanyData}
                                />
                            </Col>
                        </Row>
                    )}
                </Modal.Body>
            </Modal>

            <Modal show={primerMyDataToExpireModal} backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title>Απαιτείται Προσοχή</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {primerMyDataExpirationModalMessage}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"outline-primary"} onClick={() => dispatch(setShowPrimerMyDataToExpireModal(false))}>
                        Εντάξει, κλείσιμο
                    </Button>
                    <Button variant="primary" onClick={() => window.open(primerMyDataToExpireModalLink, "_blank")}>
                        Πληρωμή τώρα
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Footer */}
            <footer className="footer d-flex hide-scrollbar" style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                backgroundColor: showEmronPayNowButton ? "#d52323" : "",
                color: showEmronPayNowButton ? "white" : "",
                maxHeight: "10vh",
                pointerEvents: isEmronConnectFileERP ? "none" : "",
                overflowX: "visible"
            }}>
                {checkPermission("custom-callcenter", permissionsData) && (company.vatNumber === "800566476" || company.vatNumber === "801866110" || company.vatNumber === "800434990") && <CallCenterLive/>}
                {isMobileScreen ?
                <span className="text-white mr-1 text-small" style={{whiteSpace: "nowrap"}}>
                    © Primer Software {moment().format("YYYY")}
                </span>
                    :
                <span className="text-white ml-1">{" "}
                    Primer ERP Copyright © Primer Software P.C. {moment().format("YYYY")} Version 3.0.0
                    {(company.companyExpireDate && moment(company.companyExpireDate).diff(moment(), "days") < 30 && moment().toDate() < moment(company.companyExpireDate).toDate()) && (
                        <React.Fragment>
                            <br/>
                            <span className={"text-danger"}>Η συνδρομή σας λήγει σε {moment(company.companyExpireDate).diff(moment(), "days")} ημέρες. Παρακαλώ επικοινωνήστε με την Primer Software.</span>
                        </React.Fragment>
                    )}
                    {emronCompanyInvalidDays > 0 && (
                        <React.Fragment>
                            <br/>
                            <span className={!showEmronPayNowButton ? "text-danger" : ""}>
                                Η πληρωμή της μηνιαίας σας συνδρομής για τις λογιστικές υπηρεσίες του τρέχοντα μήνα απέτυχε.
                            </span>
                        </React.Fragment>
                    )}
                </span>}
                {showEmronPayNowButton && (
                    <Button size={"sm"} className={"ml-3 payNowDangerButton"} onClick={() => handleEmronClientPayNow()}>Πληρωμή τώρα</Button>
                )}
                {!permissionsData?.find((el) => el.permissionName === "custom-epay" || el.permissionName === "custom-peiraios" || el.permissionName === "custom-daras" || el.permissionName === "custom-cardlink") && (
                    <div style={{marginLeft: "auto", display: "inline-flex"}}>
                        {(checkPermission("custom-logistirio", permissionsData) === true && isEmron) && (
                            <Badge bg={"primary"} className={"mr-2"} style={{cursor: "pointer", maxHeight: "36px", padding: 0}}
                                   onClick={() => setShowEmronModal(true)}>
                                <img src={"assets/images/emron.png"} style={{height: "36px", width: "36px"}} alt={"Emron"} />
                            </Badge>
                        )}
                        {checkPermission("custom-cloud", permissionsData) === true && (
                            <Badge bg={"primary"} className={"mr-2 d-flex align-items-center"}
                                   style={{cursor: "pointer", maxHeight: "36px"}}
                                   onClick={() => handleOpenCloudTab()}>
                                {isEmron && (
                                    <span style={{fontSize: "14px"}} className={"mr-2"}>
                                        <span style={{color: "white", backgroundColor: "red", borderRadius: "20px", padding: "3px", fontWeight: "700"}}>ΝΕΟ!</span> PRIMER CONNECT</span>
                                )}
                                <img src={"assets/images/cloud.png"} alt={"Cloud"}/>
                            </Badge>
                        )}
                        {(emronClientData && emronClientData?.emronPasswords?.length > 0 && checkPermission("custom-logistirio", permissionsData)) && (
                            <OverlayTrigger key={"passwords"} placement={"top"} overlay={<Tooltip id={`tooltip-top`}>Κωδικοί Πελάτη</Tooltip>}>
                                <Badge style={{cursor: "pointer", position: "relative", borderRadius: "4px", maxHeight: "36px"}} className={"mr-2"} bg={"primary"} onClick={() => setShowPasswordsModal(true)}>
                                    <img src={"assets/images/key.png"} alt={"Key"}/>
                                </Badge>
                            </OverlayTrigger>
                        )}
                        <Button className={`${showEmronPayNowButton ? "payNowDangerButton" : ""}`} style={isMobileScreen ? {padding: "0"} : {}} variant="primary" size="sm" onClick={() => handleFooterModalOpen()}>
                            {isMobileScreen ? <span style={{fontSize: "10px"}}>{companyNameAndYear.length > 18 ? `${companyNameAndYear.slice(0, 15)}....` : companyNameAndYear}</span> : companyNameAndYear}
                        </Button>
                    </div>
                )}
            </footer>
        </div>
    )
}

export default ViewWrapper;
