import {combineReducers} from "redux";
import {
    TabsSlice,
    ActiveMainTabSlice,
    collapsedSideMenu,
    ActiveMainLinkSlice
} from "./TabsSlice";
import {LoginSlice} from "./Login";
import {MobileSidebarSlice} from "./MobileSidebar";
import {CompanySlice} from "./companySlice";
import {ProductsSlice} from "./DataPanel/Products/productsSlice";
import {ProductCategorySlice} from "./DataPanel/Products/productCategorySlice";
import {SearchTableSlice} from "./SearchTableSlice";
import {NavbarSlice} from "./Navbar";
import {ActivitySlice} from "./activitySlice";
import {SettingsSlice} from "./settingsSlice";
import {ClientsSlice} from "./DataPanel/Clients/clientSlice";
import {SuppliersSlice} from "./DataPanel/Suppliers/supplierSlice";
import {CashSlice} from "./DataPanel/Cash/cashSlice";
import {SalesSettingsSlice} from "./DataPanel/Sales/salesSettingsSlice";
import {SalesSlice} from "./DataPanel/Sales/salesSlice";
import {BuysSlice} from "./DataPanel/Buys/buysSlice";
import {BuysSettingsSlice} from "./DataPanel/Buys/buysSettingsSlice";
import {AccountingSlice} from "./DataPanel/Accounting/accountingSlice";
import {SubscriptionsSlice} from "./subscriptionsSlice";
import {CRMSlice} from "./crmSlice";
import {PayrollSlice} from "./DataPanel/Payroll/payrollSlice";
import {CloudSlice} from "./cloudSlice";
import {PrescriptionSlice} from "./DataPanel/Prescription/prescriptionSlice";

const rootReducer = combineReducers({
    ACTIVITY: ActivitySlice.reducer,
    TABS_REDUCER: TabsSlice.reducer,
    ACTIVE_MAIN_TAB: ActiveMainTabSlice.reducer,
    ACTIVE_MAIN_LINK: ActiveMainLinkSlice.reducer,
    COLLAPSED_SIDE_MENU: collapsedSideMenu.reducer,
    // Login reducer _______________________________________
    LOGIN_STATE: LoginSlice.reducer,
    // Mobil Toggle Sidebar ________________________________
    MOBILE_SIDEBAR_TOGGLE: MobileSidebarSlice.reducer,
    COMPANY_DATA: CompanySlice.reducer,
    PRODUCTS_DATA: ProductsSlice.reducer,
    CLIENTS_DATA: ClientsSlice.reducer,
    ACCOUNTING_DATA: AccountingSlice.reducer,
    SUPPLIERS_DATA: SuppliersSlice.reducer,
    PRODUCTS_CATEGORY: ProductCategorySlice.reducer,
    // SEARCH TABLE POPULATE TABLE DATA ___________________
    SEARCH_TABLE: SearchTableSlice.reducer,

    // Navbar
    ACTIVE_PATH: NavbarSlice.reducer,
    SETTINGS: SettingsSlice.reducer,
    CASH_DATA: CashSlice.reducer,

    // Sales
    SALES: SalesSlice.reducer,
    SALES_SETTINGS: SalesSettingsSlice.reducer,

    // Buys
    BUYS: BuysSlice.reducer,
    BUYS_SETTINGS: BuysSettingsSlice.reducer,

    SUBSCRIPTIONS: SubscriptionsSlice.reducer,
    CRM: CRMSlice.reducer,

    PAYROLL: PayrollSlice.reducer,

    CLOUD: CloudSlice.reducer,

    PRESCRIPTION: PrescriptionSlice.reducer,
})

export default rootReducer;
