import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import "./../NewBuyModal/mydata-report.css";
import {getICCategoryOptions, getICTypeOptions} from "../../../../_helpers/sharedmydata";
import Dropdown from "../../Dropdown";
import {
    getDocumentLabel,
    getDOYOptions,
    getGreekCurrencyFormat,
    getOptionsByData,
    isEmpty
} from "../../../../_helpers/commonFunctions";
import Input from "../../../common/Input";
import DateBox from "../../DateBox";
import moment from "moment";
import {salesPaymentTypeData} from "../../../../_data/sales/sales-payment-types";
import {vatCategoryData} from "../../../../_data/mydata/vatCategory";
import {measurementUnitsData} from "../../../../_data/mydata/measurementUnits";
import {getTaxes} from "../../../../_helpers/product-buys-calculations";
import {getSalesTaxesData, getSalesTotals} from "../../../../_helpers/product-sales-calculations";
import axios from "axios";
import {toast} from "react-toastify";
import EditableTable from "../../Editable-Table";
import BetterCheckBox from "../../../common/BetterCheckBox";

const NewSaleModal = ({show, setShow, classificationsRowData, refreshFunction}) => {
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const isEmronConnectFileERP = useSelector((state) => state.ACTIVITY.isEmronConnectFileERP);
    const SALES = useSelector((state) => state.SALES);
    const SALES_SETTINGS = useSelector((state) => state.SALES_SETTINGS);
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const [newSale, setNewSale] = useState({...SALES.newSalesInitialValue});

    const [autoloadVatDetails, setAutoloadVatDetails] = useState(false);
    const [autoloadProducts, setAutoloadProducts] = useState(false);

    const [salesProductList, setSalesProductList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingClient, setLoadingClient] = useState(false);
    const [clientFound, setClientFound] = useState(false);

    const icCategory = getICCategoryOptions();
    const icType = getICTypeOptions();

    const calculateTaxesETC = (cloneSale) => {
        let newInvLevelTax = {};
        let totalTaxes = 0;
        let totalWithheldTaxes = 0;
        if (classificationsRowData?.taxesTotals && classificationsRowData?.taxesTotals?.length > 0) {
            for (let tax of classificationsRowData.taxesTotals) {
                if (tax.taxType === "1") { // Withhold
                    cloneSale.extraData.withHoldTax = tax.taxCategory;
                    if (!isEmpty(cloneSale.extraData.withHoldTaxEuro)) {
                        cloneSale.extraData.withHoldTaxEuro = Number(parseFloat(cloneSale.extraData.withHoldTaxEuro) + parseFloat(tax.taxAmount)).toFixed(2);
                        newInvLevelTax.withholdTaxes = Number(parseFloat(newInvLevelTax.withholdTaxes) + parseFloat(tax.taxAmount)).toFixed(2);
                    } else {
                        cloneSale.extraData.withHoldTaxEuro = tax.taxAmount;
                        newInvLevelTax.withholdTaxes = tax.taxAmount;
                    }
                    totalWithheldTaxes += Number(tax.taxAmount);
                } else if (tax.taxType === "2") { // Fee
                    cloneSale.extraData.fee = tax.taxCategory;
                    if (!isEmpty(cloneSale.extraData.feeEuro)) {
                        cloneSale.extraData.feeEuro = Number(parseFloat(cloneSale.extraData.feeEuro) + parseFloat(tax.taxAmount)).toFixed(2);
                        newInvLevelTax.fees = Number(parseFloat(newInvLevelTax.fees) + parseFloat(tax.taxAmount)).toFixed(2);
                    } else {
                        cloneSale.extraData.feeEuro = tax.taxAmount;
                        newInvLevelTax.fees = tax.taxAmount;
                    }
                    totalTaxes += Number(tax.taxAmount);
                } else if (tax.taxType === "3") { // Other Tax
                    cloneSale.extraData.otherTax = tax.taxCategory;
                    if (!isEmpty(cloneSale.extraData.otherTaxEuro)) {
                        cloneSale.extraData.otherTaxEuro = Number(parseFloat(cloneSale.extraData.otherTaxEuro) + parseFloat(tax.taxAmount)).toFixed(2);
                        newInvLevelTax.otherTaxes = Number(parseFloat(newInvLevelTax.otherTaxes) + parseFloat(tax.taxAmount)).toFixed(2);
                    } else {
                        cloneSale.extraData.otherTaxEuro = tax.taxAmount;
                        newInvLevelTax.otherTaxes = tax.taxAmount;
                    }
                    totalTaxes += Number(tax.taxAmount);
                } else if (tax.taxType === "4") { // Stamp
                    cloneSale.extraData.stamp = tax.taxCategory;
                    if (!isEmpty(cloneSale.extraData.stampEuro)) {
                        cloneSale.extraData.stampEuro = Number(parseFloat(cloneSale.extraData.stampEuro) + parseFloat(tax.taxAmount)).toFixed(2);
                        newInvLevelTax.stamp = Number(parseFloat(newInvLevelTax.stamp) + parseFloat(tax.taxAmount)).toFixed(2);
                    } else {
                        cloneSale.extraData.stampEuro = tax.taxAmount;
                        newInvLevelTax.stamp = tax.taxAmount;
                    }
                    totalTaxes += Number(tax.taxAmount);
                }
            }
        }
        let productTotals = getSalesTotals(cloneSale.products, SETTINGS);
        let productBuysTaxes = getSalesTaxesData(cloneSale.products, cloneSale.extraData, productTotals, SETTINGS);
        for (let obj of productBuysTaxes) {
            if (obj.vatEuro && typeof obj.vatEuro === "number") {
                obj.vatEuro = Number(Number(obj.vatEuro).toFixed(2));
            }
        }
        let findInvLevelTax = productBuysTaxes.find((el) => el.vatPercentage === "Invoice Level Tax");
        for (let key in newInvLevelTax) {
            findInvLevelTax[key] = newInvLevelTax[key];
        }
        // Correct Totals
        let findTotals = productBuysTaxes.find((el) => el.vatPercentage === "Totals");
        if (findTotals) {
            let totalFee = 0;
            let totalWithhold = 0;
            let totalOtherTax = 0;
            let totalStamp = 0;
            for (let prod of cloneSale.products) {
                if (prod.fee) {
                    totalFee += prod.feeEuro;
                    totalTaxes += prod.feeEuro;
                }
                if (prod.stamp) {
                    totalStamp += prod.stampEuro;
                    totalTaxes += prod.stampEuro;
                }
                if (prod.withHoldTax) {
                    totalWithhold += prod.witholdTaxEuro;
                    totalWithheldTaxes += prod.witholdTaxEuro;
                }
                if (prod.otherTax) {
                    totalOtherTax += prod.otherTaxEuro;
                    totalTaxes += prod.otherTaxEuro;
                }
            }
            if (findInvLevelTax) {
                totalFee += Number(findInvLevelTax.fees);
                totalWithhold += Number(findInvLevelTax.withholdTaxes);
                totalOtherTax += Number(findInvLevelTax.otherTaxes);
                totalStamp += Number(findInvLevelTax.stamp);
            }
            findTotals.withholdTaxes = getGreekCurrencyFormat(totalWithhold);
            findTotals.otherTaxes = getGreekCurrencyFormat(totalOtherTax);
            findTotals.fees = getGreekCurrencyFormat(totalFee);
            findTotals.stamp = getGreekCurrencyFormat(totalStamp);
        }
        totalTaxes = Number(Number(totalTaxes).toFixed(2));
        totalWithheldTaxes = Number(Number(totalWithheldTaxes).toFixed(2));

        if (totalTaxes > 0 || totalWithheldTaxes > 0) {
            productTotals.withHoldTaxes = Number(Number(totalWithheldTaxes).toFixed(2));
            productTotals.totalTaxesAmount = Number(Number(totalTaxes).toFixed(2));
            productTotals.paymentAmount = Number(Number(productTotals.totalAmount) + totalTaxes - totalWithheldTaxes).toFixed(2);
            productTotals.totalAmount = Number(Number(productTotals.totalAmount) + totalTaxes).toFixed(2);
        }
        cloneSale = {...cloneSale, salesTaxes: productBuysTaxes, ...productTotals};

        return cloneSale;
    }

    useEffect(() => {
       if (show === true) {
           console.log(classificationsRowData);
           let newSaleFields = { extraData: {...SALES.newSalesInitialValue.extraData}, products: [], classifiedDataType: classificationsRowData.myDataType };
           newSaleFields.number = classificationsRowData.number;
           newSaleFields.preNumber = classificationsRowData.preNumber;
           newSaleFields.myDataType = classificationsRowData.myDataType;
           newSaleFields.company = company.id;
           newSaleFields.year = String(company.year);
           newSaleFields.vat = classificationsRowData.vat;
           newSaleFields.installation = "0";
           newSaleFields.mark = classificationsRowData.mark;
           newSaleFields.uid = classificationsRowData.uid;
           if (classificationsRowData.authCode) newSaleFields.authCode = classificationsRowData.authCode;
           newSaleFields.date = moment(classificationsRowData.date, "DD/MM/YYYY").toDate();
           newSaleFields.time = moment().format("HH:mm");
           if(classificationsRowData?.selfPricing === "true") {
               newSaleFields.selfPricing = "true";
           }
           if (getNewSaleDocumentTypes().length >= 1) {
               newSaleFields.documentType = getNewSaleDocumentTypes()[0].value;
               newSaleFields.documentTypeName = getNewSaleDocumentTypes()[0].label;
           }
           if (getPaymentTypes().length >= 1) {
               newSaleFields.paymentType = getPaymentTypes()[0].value;
               newSaleFields.paymentTypeName = getPaymentTypes()[0].label;
               newSaleFields.paymentTypeType = salesPaymentTypeData.find((el) => String(el.myDataId) === classificationsRowData.myDataPaymentMethod)?.value;
               newSaleFields.myDataPaymentId = classificationsRowData.myDataPaymentMethod;
           }
           if (classificationsRowData?.classifications.length > 0) {
               for (let classi of classificationsRowData.classifications) {
                   let newProd = {...SALES.newSalesNewProductInitialValue};
                   newProd.no = classi.lineNumber;
                   newProd.id = classi.lineNumber;
                   newProd.unitPrice = classi.netPrice;
                   newProd.vatId = classi.vatCategory;
                   newProd.vatPercentage = vatCategoryData.find((el) => String(el.code) === String(classi.vatCategory))?.percentage;
                   if (String(classi.vatCategory) === "8") {
                       newProd.vat = 0;
                       newProd.vatPercentage = 0;
                   } else if(String(classi.vatCategory) === "7") {
                       newProd.vat = 0;
                       newProd.vatPercentage = 0;
                       newProd.vatReason = String(classi.vatExemptionCategory);
                   } else {
                       newProd.vat = classi.vatAmount;
                   }
                   newProd.netValue = classi.netPrice;
                   newProd.totalValue = Number(Number(classi.netPrice) + Number(classi.vatAmount)).toFixed(2);
                   newProd.discount = 0;
                   newProd.isPerDiscount = false;
                   newProd.isDiscountAmount = true;
                   if (classi.quantity) newProd.quantity = Number(classi.quantity);
                   if (classi.measurementUnit)  {
                       newProd.mu = classi.measurementUnit;
                   } else {
                       newProd.mu = "4";
                   }
                   newProd.muLabel = measurementUnitsData.find((el) => el.code === newProd.mu)?.name;
                   newProd.muLabelShort = measurementUnitsData.find((el) => el.code === newProd.mu)?.shortName;

                   // Other stuff
                   classi?.withheldPercentCategory ? newProd.withHoldTax = String(classi?.withheldPercentCategory) : newProd.withHoldTax = null;
                   classi?.withheldAmount ? newProd.witholdTaxEuro = Number(classi?.withheldAmount) : newProd.witholdTaxEuro = 0;

                   classi?.feesPercentCategory ? newProd.fee = String(classi?.feesPercentCategory) : newProd.fee = null;
                   classi?.feesAmount ? newProd.feeEuro = Number(classi?.feesAmount) : newProd.feeEuro = 0;

                   classi?.stampDutyPercentCategory ? newProd.stamp = String(classi?.stampDutyPercentCategory) : newProd.stamp = null;
                   classi?.stampDutyAmount ? newProd.stampEuro = Number(classi?.stampDutyAmount) : newProd.stampEuro = 0;

                   classi?.otherTaxesPercentCategory ? newProd.otherTax = String(classi?.otherTaxesPercentCategory) : newProd.otherTax = null;
                   classi?.otherTaxesAmount ? newProd.otherTaxEuro = Number(classi?.otherTaxesAmount) : newProd.otherTaxEuro = 0;

                   if (!isEmpty(classi.classificationType)) {
                       newProd.prefilled = true; // Case it was classified but no buy exists
                       newProd.classificationType = classi.classificationType;
                   }
                   if (!isEmpty(classi.classificationCategory)) newProd.classificationCategory = classi.classificationCategory;

                   newProd.taxes = "0.00";
                   newProd.taxes = getTaxes(newProd, SETTINGS);
                   newSaleFields.products.push(newProd);
               }
           }
           newSaleFields = {...calculateTaxesETC(newSaleFields)};
           setNewSale({...newSale, ...newSaleFields});
           setAutoloadProducts(true);
       } else {
           // For modal smooth closing
           setTimeout(() => {
               setNewSale({...SALES.newSalesInitialValue});
               setClientFound(false);
               setLoading(false);
               setSalesProductList([]);
           }, 400)
       }
    }, [show])

    useEffect(() => {
        if (!isEmpty(newSale.vat) && autoloadVatDetails === true) {
            setAutoloadVatDetails(false);
            fetchVatDetails(false, newSale.vat);
        }
    }, [autoloadVatDetails])

    useEffect(() => {
        if (autoloadProducts === true) {
            setAutoloadProducts(false);
            loadProductListData(classificationsRowData.myDataType, null, null, true);
        }
    }, [autoloadProducts])

    const handleCloseNewSaleModal = () => {
        setShow(false);
    }

    const getNewSaleDocumentTypes = () => {
        if (SETTINGS.settings.advancedTypesSales) {
            return getOptionsByData(SETTINGS.settings.advancedTypesSales?.filter((el) => el.myDataType === classificationsRowData.myDataType), "_id", "name");
        } else {
            return [];
        }
    }

    const getPaymentTypes = () => {
        if (SALES_SETTINGS.salesGeneralSettings.paymentTypes) {
            let searchMyDataId = classificationsRowData.myDataPaymentMethod;
            if (String(searchMyDataId) === "2" || String(searchMyDataId) === "6") {
                searchMyDataId = "1";
            }
            return getOptionsByData(SALES_SETTINGS.salesGeneralSettings.paymentTypes?.filter((el) => String(el.myDataPaymentId) === searchMyDataId), "_id", "name");
        } else {
            return [];
        }
    }

    const handleNewSaleChange = (e, type, name) => {
        if (type === "dropdown") {
            if (name === "documentType") {
                setNewSale({...newSale, documentType: e.value, documentTypeName: e.label});
            } else if (name === "paymentType") {
                setNewSale({...newSale,
                    paymentType: e.value,
                    paymentTypeName: e.label,
                })
            } else if (name === "bankAccount") {
                setNewSale({...newSale,
                    bankAccount: e.value,
                    bankAccountName: e.label,
                })
            } else {
                setNewSale({...newSale, [name] : e.value})
            }
        }
    }

    const onProductSearch = (cellName, keyword, setData) => {
        if (keyword && String(keyword).length >= 2) {
            loadProductListData(classificationsRowData.myDataType, keyword, setData);
        }
    }

    const fetchVatDetails = async (fromButton = false, forceVAT) => {
        setLoadingClient(true);
        try {
            let vatToCheck = newSale.vat;
            if (forceVAT) vatToCheck = forceVAT;
            if (vatToCheck && String(vatToCheck).length === 9) {
                const apiResponse = await axios.get(process.env.REACT_APP_API_URL2 + "/client/get-client-by-vat", {
                    headers: {'Content-Type': 'application/json'},
                    params: {
                        company: company.id,
                        vat: vatToCheck,
                        classifiedDataType: newSale.classifiedDataType,
                    }
                });
                if (apiResponse?.data?.status === "200" && apiResponse?.data?.data) {
                    const vatData = apiResponse.data.data;
                    let cloneSale = {...newSale};

                    cloneSale = {...cloneSale,
                        client: vatData._id,
                        clientName: vatData.name,
                        smallName: vatData.smallName,
                        address: vatData.address,
                        city: vatData.city,
                        activity: vatData.activity,
                        pc: vatData.postalCode,
                        doy: vatData.DOY,
                        clientInstallationType: "Κεντρικό",
                        clientCode: vatData.systemCode,
                        email: vatData.email
                    }
                    if (apiResponse?.data?.productData) {
                        let prefillProd = apiResponse.data.productData;
                        for (let prod of cloneSale.products) {
                            prod._id = prefillProd._id;
                            prod.code = prefillProd.code;
                            prod.name = prefillProd.name;
                        }
                    }
                    setClientFound(true);
                    setLoadingClient(false);
                    setNewSale(cloneSale);
                } else {
                    axios.post(process.env.REACT_APP_API_URL2 + "/client/fetch-vat-details", {
                        vatNumber: vatToCheck,
                        onlyAADE: "true"
                    }, {
                        headers: {'Content-Type': 'application/json'}
                    }).then((res) => {
                        if (res.data && res.data.status === "200") {
                            const vatData = res.data.data;
                            setNewSale({
                                ...newSale,
                                clientName: vatData?.name ? vatData.name : "",
                                smallName: vatData?.smallName ? vatData.smallName : "",
                                activity: vatData?.activity ? vatData.activity : "",
                                address: vatData?.address ? vatData.address : "",
                                doy: vatData?.doyCode ? vatData.doyCode : "",
                                city: vatData?.city ? vatData.city : "",
                                pc: vatData?.tk ? vatData.tk : "",
                                clientInstallationType: "Κεντρικό",
                                clientType: "New",
                            })
                            if (fromButton) toast.success(res.data.message);
                        } else {
                            if (fromButton) toast.error(res.data.message);
                        }
                        setLoadingClient(false);
                    }).catch((err) => {
                        if (fromButton) toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
                        console.log(err);
                        setLoadingClient(false);
                    })
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const loadProductListData = (myDataType, keyword, setData, fromFirstLoad = false) => {
        let data = {
            myDataType: myDataType,
            company : company.id
        }
        if (!isEmpty(keyword)) data.keyword = keyword;
        axios.get(process.env.REACT_APP_API_URL2 + `/sale/product-list-by-mydatatype`,{
            params: data
        }).then(res => {
            if (res.data && res.data.status === '200') {
                if (res.data.data) {
                    if (setData) {
                        setData(res.data.data);
                    } else {
                        setSalesProductList(res.data.data);
                    }
                    if (fromFirstLoad && (res.data.data?.length === 0 || res.data.data?.length > 1)) {
                        setAutoloadVatDetails(true);
                    }
                    if (fromFirstLoad && res.data.data?.length === 1) {
                        const thatOneProduct = res.data.data[0];
                        setTimeout(() => {
                            let cloneSale = {...newSale};
                            let cloneProducts = [...cloneSale.products];
                            cloneProducts = cloneProducts.map((o) => Object.assign({}, o));
                            for (let prod of cloneProducts) {
                                prod._id = thatOneProduct._id;
                                prod.name = thatOneProduct.name;
                                prod.code = thatOneProduct.code;
                                if (thatOneProduct.mydata && thatOneProduct.mydata?.length > 0) {
                                    const findMatching = thatOneProduct.mydata.find((el) => (isEmpty(el.rowType) || el.rowType === "income") && String(el.invoiceType) === String(classificationsRowData.myDataType));
                                    if (findMatching && !prod.prefilled) {
                                        prod.classificationCategory = findMatching.category;
                                        prod.classificationType = findMatching.type;
                                        prod.vatExpenseType = findMatching.vatExpenseType;
                                    }
                                }
                            }
                            setNewSale(cloneSale);
                            setAutoloadVatDetails(true);
                        }, 100)
                    }
                } else {
                    if (setData) {
                        setData([]);
                    } else {
                        setSalesProductList([]);
                    }
                }
            } else {
                if (setData) {
                    setData([]);
                } else {
                    setSalesProductList([]);
                }
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const productTableFunctions = {
        update: (data, id, rowIndex) => {
            if (data.name) {
                data = {...data.name};
                if (data && Object.keys(data).length > 0) {
                    let cloneProducts = [...newSale.products];
                    cloneProducts = cloneProducts.map((o) => Object.assign({}, o));
                    cloneProducts[rowIndex]._id = data._id;
                    cloneProducts[rowIndex].name = data.name;
                    cloneProducts[rowIndex].code = data.code;
                    if (data.mydata && data.mydata?.length > 0) {
                        const findMatching = data.mydata.find((el) => (isEmpty(el.rowType) || el.rowType === "income") && String(el.invoiceType) === String(classificationsRowData.myDataType));
                        if (findMatching) {
                            cloneProducts[rowIndex].classificationCategory = findMatching.category;
                            cloneProducts[rowIndex].classificationType = findMatching.type;
                            cloneProducts[rowIndex].vatExpenseType = findMatching.vatExpenseType;
                        }
                    }
                    setNewSale({...newSale, products: cloneProducts});
                }
            } else if(data?.classificationCategory) {
                let cloneProducts = [...newSale.products];
                cloneProducts[rowIndex].classificationCategory = data.classificationCategory;
                cloneProducts[rowIndex].classificationType = "";
                setNewSale({...newSale, products: cloneProducts});
            } else if(data?.classificationType) {
                let cloneProducts = [...newSale.products];
                cloneProducts[rowIndex].classificationType = data.classificationType;
                setNewSale({...newSale, products: cloneProducts});
            }
        },
        selected: () => {}
    }

    const handleSaveNewSale = () => {
        setLoading(true);
        let cloneSale = {...newSale};
        let classificationsRequestData = {};
        let incomeClassificationsDetails = [];
        if(classificationsRowData?.selfPricing === "true") {
            if(cloneSale?.products?.length > 0){
                for(const classification of cloneSale?.products){
                    incomeClassificationsDetails.push({
                        "icls:lineNumber": classification.no,
                        "icls:incomeClassificationDetailData": {
                            "icls:classificationType": classification.classificationType,
                            "icls:classificationCategory" : classification.classificationCategory,
                            "icls:amount": classification.netValue
                        }});
                }
            }
            classificationsRequestData = {
                company: company.id,
                "icls:invoiceMark": cloneSale.mark,
                "icls:invoicesIncomeClassificationDetails": incomeClassificationsDetails,
                reportType: "sales",
            }
        }
        console.log(classificationsRequestData)
        cloneSale.date = moment(cloneSale.date).set({hour: 10, minute: 0}).toDate();
        axios.post(process.env.REACT_APP_API_URL2 + "/sale/add-sale-from-mydatareport", {sale: cloneSale, classifications: classificationsRequestData}, {
            headers: {'Content-Type': 'application/json'}
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                if (isEmronConnectFileERP) {
                    localStorage.setItem(`${isEmronConnectFileERP}-status`, `200|${res.data.data._id}|${getDocumentLabel(res.data.data)}`);
                    return window.close();
                }
                toast.success(res.data.message);
                handleCloseNewSaleModal();
                refreshFunction();
            } else {
                toast.error(res.data.message, {autoClose: 7000});
            }
        }).catch((err) => {
            setLoading(false);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            console.log(err);
        })
    }

    const saleIsInvalid = () => {
        return isEmpty(newSale.documentType) || isEmpty(newSale.paymentType) || isEmpty(newSale.vat)
            || newSale.products.find((el) => isEmpty(el.name) && isEmpty(el._id))
            || newSale.products.find((el) => isEmpty(el.classificationCategory) || isEmpty(el.classificationType))
            || Number(Number(newSale.paymentAmount).toFixed(2)) !== Number(Number(classificationsRowData.totalAmount).toFixed(2))
            || !vatIsValid();
    }

    const handleApplyProductToAll = (rowData) => {
        if(!isEmpty(rowData.classificationType) && !isEmpty(rowData.classificationCategory)) {
            let cloneSale = structuredClone(newSale);
            for (let prod of cloneSale.products) {
                let cloneKeys = ["_id", "name", "code", "classificationType", "classificationCategory"]
                for (let key of cloneKeys) {
                    prod[key] = rowData[key];
                }
            }
            setNewSale(cloneSale);
        } else {
            toast.error("Παρακαλώ επιλέξτε κατηγορία ΚΑΙ τύπο MyData.");
        }
    }

    const getTotalVAT = () => {
        let sum = 0;
        if (newSale.salesTaxes) {
            for (let line of newSale.salesTaxes) {
                if (line.vatPercentage !== "Totals" && !isNaN(line.vatEuro)) {
                    sum += Number(line.vatEuro);
                }
            }
            sum = Number(Number(sum).toFixed(2));
            return Number(sum).toFixed(2);
        }
        return 0;
    }

    const vatIsValid = () => {
        return Number(newSale?.totalVatValue) >= Number(getTotalVAT()) - 1 && Number(newSale?.totalVatValue) <= Number(getTotalVAT()) + 1 || ["5.1", "5.2", "11.4"].includes(classificationsRowData.myDataType);
    }

    const salesTaxesColumns = [
        {
            name: "Ποσοστό Φ.Π.Α. %",
            field: "vatPercentage",
            editable: false,
        },
        {
            name: "Καθαρή Αξία €",
            field: "netValue",
            editable: false,
        },
        {
            name: "Αξία Φ.Π.Α. €",
            field: "vatEuro",
            editable: false,
        },
        {
            name: "Παρακρατούμενοι Φόροι",
            field: "withholdTaxes",
            editable: false,
        },
        {
            name: "Λοιποί Φόροι",
            field: "otherTaxes",
            editable: false,
        },
        {
            name: "Τέλη",
            field: "fees",
            editable: false,
        },
        {
            name: "Χαρτόσημο",
            field: "stamp",
            editable: false,
        }
    ];

    const productCodeColumns = [
        {
            label: "Κωδικός",
            name: "code",
        },
        {
            label: "Εναλλακτικός Κωδικός",
            name: "alternateCode",
        },
        {
            label: "Όνομα Είδους",
            name: "name",
        },
        {
            label: "Εναλλακτικό Όνομα",
            name: "alternateName",
        },
    ];

    const productTableColumns = [
        {
            name: "Αρ.",
            field: "no",
            width: "5%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Είδος",
            field: "name",
            width: "20%",
            editable: true,
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: productCodeColumns,
                    data: salesProductList,
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: 'Επιλέξτε είδος',
                    onChangeSearchCell: onProductSearch,
                },
                type: 'advanced-combobox',
            }
        },
        {
            name: "Ποσότητα",
            field: "quantity",
            editable: false,
            width: "5%",
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Μον. Μέτρησης",
            field: "muLabelShort",
            editable: false,
            width: "5%",
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Φ.Π.Α. %",
            field: "vatPercentage",
            editable: false,
            width: "5%",
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Καθαρή Αξία",
            field: "netValue",
            editable: false,
            width: "10%",
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 7
                }
            }
        },
        {
            name: "Αξία Φ.Π.Α.",
            field: "vat",
            editable: false,
            width: "10%",
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 7
                }
            }
        },
        {
            name: "Τελική Αξία",
            field: "totalValue",
            editable: false,
            width: "10%",
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 7
                }
            }
        },
        {
            name: "Κατηγορία MyData",
            field: "classificationCategory",
            editable: true,
            width: "15%",
            inputType: {
                type: 'select',
                options: icCategory,
                config: {
                    disabled: classificationsRowData?.selfPricing !== "true",
                }
            }
        },
        {
            name: "Τύπος MyData",
            field: "classificationType",
            editable: true,
            width: "15%",
            inputType: {
                type: 'select',
                options: icType,
                config: {
                    disabled: classificationsRowData?.selfPricing !== "true",
                }
            }
        },
        {
            name: "Ενέργειες",
            field: "buttonActions",
            editable: true,
            width: "6%",
            inputType: {
                config: {
                    disabled: false,
                    onClick: handleApplyProductToAll,
                    text: "Όμοιο παντού"
                },
                type: 'button-sm',
            }
        },
    ]

    return (
        <Modal backdrop={"static"} dialogClassName={"buysModalBig"} show={show} onHide={() => handleCloseNewSaleModal()}>
            <Modal.Header closeButton>
                <Modal.Title>Δημιουργία Πώλησης</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {Object.keys(classificationsRowData).length > 0 && (
                    <React.Fragment>
                        <Row>
                            <Col md={3}>
                                <Dropdown
                                    name={"documentType"}
                                    label={"Τύπος Παραστατικού"}
                                    options={getNewSaleDocumentTypes()}
                                    defaultValue={getNewSaleDocumentTypes().find((el) => el.value === newSale.documentType) ? getNewSaleDocumentTypes().find((el) => el.value === newSale.documentType) : ""}
                                    key={Math.random()}
                                    required={true}
                                    onChange={(e) => handleNewSaleChange(e, "dropdown", "documentType")}
                                />
                            </Col>
                            <Col md={2}>
                                <Input
                                    name={"preNumber"}
                                    label={"Σειρά"}
                                    value={newSale.preNumber === "noprefix" ? "Κενή" : newSale.preNumber}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={2}>
                                <Input
                                    name={"number"}
                                    label={"Αριθμός"}
                                    value={newSale.number}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={2}>
                                <DateBox
                                    label={"Ημερομηνία"}
                                    name={"date"}
                                    selected={new Date(newSale.date)}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={3}>
                                <Input
                                    name={"time"}
                                    label={"Ώρα"}
                                    value={newSale.time}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <Input
                                    name={"vat"}
                                    label={`ΑΦΜ Πελάτη`}
                                    value={newSale.vat}
                                    disabled={true}
                                />
                                {loadingClient && <Spinner style={{position: "absolute", right: "30px", top: "35px"}} size={"sm"} animation="border" variant="dark" />}
                            </Col>
                            <Col md={3}>
                                <Input
                                    name={"name"}
                                    label={"Όνομα"}
                                    value={newSale.clientName}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={6}>
                                <Input
                                    name={"activity"}
                                    label={"Δραστηριότητα"}
                                    value={newSale.activity}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-3"}>
                            <Col md={3}>
                                <Input
                                    name={"address"}
                                    label={"Διεύθυνση"}
                                    value={newSale.address}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={3}>
                                <Input
                                    name={"doy"}
                                    label={"ΔΟΥ"}
                                    value={getDOYOptions().find((el) => String(el.value) === newSale.doy) ? getDOYOptions().find((el) => String(el.value) === newSale.doy)?.label : newSale.doy}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={3}>
                                <Input
                                    name={"city"}
                                    label={"Πόλη"}
                                    value={newSale.city}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={3}>
                                <Input
                                    name={"pc"}
                                    label={"Ταχυδρομικός Κωδικός"}
                                    value={newSale.pc}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-3"}>
                            <Col md={3}>
                                <Dropdown
                                    name={"paymentType"}
                                    label={"Τρόπος Πληρωμής"}
                                    options={getPaymentTypes()}
                                    defaultValue={getPaymentTypes().find((el) => el.value === newSale.paymentType) ? getPaymentTypes().find((el) => el.value === newSale.paymentType) : ""}
                                    key={Math.random()}
                                    required={true}
                                    onChange={(e) => handleNewSaleChange(e, "dropdown", "paymentType")}
                                />
                            </Col>
                            <Col md={3} className={"mt-3 ml-2"}>
                                <BetterCheckBox
                                    name={"selfPricing"}
                                    text={"Είναι Αυτοτιμολόγηση"}
                                    className="mt-3"
                                    disabled={true}
                                    checked={classificationsRowData?.selfPricing === "true"}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-3"}>
                            <EditableTable
                                tableName={"Sales Modal Products List"}
                                invoiceType={newSale.myDataType}
                                key={Math.random()}
                                actionButtons={{
                                    print: false,
                                    delete: false,
                                    export: false,
                                    edit: false,
                                }}
                                columns={productTableColumns}
                                onUpdate={productTableFunctions}
                                data={newSale.products}
                                allowInsertRow={false}
                                allowActions={false}
                                allowToggleColumns={false}
                                allowPrintResults={false}
                            />
                        </Row>
                        <Row className="d-flex justify-content-end">
                            <Col md={8} style={{position: "inherit"}}>
                                <EditableTable
                                    className="buys-taxes-calculations"
                                    allowActions={false}
                                    key={Math.random()}
                                    data={newSale.salesTaxes}
                                    columns={salesTaxesColumns}
                                    actionButtons={{
                                        print: false,
                                        delete: false,
                                        export: false,
                                        edit: false,
                                    }}
                                />
                            </Col>
                            <Col md={4}>
                                <table className="table table-bordered mb-3">
                                    <tbody>
                                    <tr>
                                        <td className="fw-bold">Συνολική Ποσότητα</td>
                                        <td>{newSale.totalQuantity}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">Συνολική Καθαρή Αξία</td>
                                        <td>{getGreekCurrencyFormat(newSale.totalNetValue)}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">Συνολική Αξία Φ.Π.Α.</td>
                                        <td>{getGreekCurrencyFormat(newSale.totalVatValue)}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">Σύνολο Παρακρατούμενων Φόρων</td>
                                        <td>{getGreekCurrencyFormat(newSale.withHoldTaxes)}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">Σύνολο Φόρων</td>
                                        <td>{getGreekCurrencyFormat(newSale.totalTaxesAmount)}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">Συνολική Αξία</td>
                                        <td>{getGreekCurrencyFormat(newSale.totalAmount)}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">Πληρωτέο Ποσό</td>
                                        <td>{getGreekCurrencyFormat(newSale.paymentAmount)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-end">
                            <Col md={4}>
                                {clientFound === false && <span className={"mt-2"}>Ο πελάτης δεν βρέθηκε και θα δημιουργηθεί αυτόματα κατά την αποθήκευση</span>}
                            </Col>
                        </Row>
                        {Number(Number(newSale.paymentAmount).toFixed(2)) !== Number(Number(classificationsRowData.totalAmount).toFixed(2)) && (
                            <Row className="d-flex justify-content-end mt-2">
                                <Col md={4} style={{textAlign: "center", fontWeight: "700", fontSize: "18px"}}>
                                    <div style={{color: "red"}}>Προσοχή! Τα νούμερα δεν συμφωνούν με το My Data! Επικοινωνήστε με την Primer Software.</div>
                                </Col>
                            </Row>
                        )}
                        {!vatIsValid() && (
                            <Row className="d-flex justify-content-end mt-2">
                                <Col md={4} style={{textAlign: "center", fontWeight: "700", fontSize: "18px"}}>
                                    <div style={{color: "red"}}>Προσοχή! Τα ποσά του Φ.Π.Α. δεν συμφωνούν. Το άθροισμα τους είναι διαφορετικό από το σύνολο που παρουσιάζει το My Data. Παρακαλώ επικοινωνήστε με τον λογιστή σας.</div>
                                </Col>
                            </Row>
                        )}
                    </React.Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={() => handleCloseNewSaleModal()}>Κλείσιμο</Button>
                <Button variant="primary" onClick={() => handleSaveNewSale()} disabled={loadingClient || loading || saleIsInvalid()}>
                    {classificationsRowData?.selfPricing === "true" ? "Χαρακτηρισμός & Αποθήκευση Πώλησης" : "Αποθήκευση Πώλησης"} {loading && <Spinner animation="border" variant="dark" />}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default NewSaleModal
