import React, { useEffect, useState } from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import getMyData, {
    getECCategoryOptions,
    getECTypeOptions,
    getICCategoryOptions,
    getICTypeOptions, getVatExpenseClassificationType
} from "../../../_helpers/sharedmydata";
import {useDispatch, useSelector} from "react-redux";
import Dropdown from "../Dropdown";
import axios from "axios";
import {setNewSales} from "../../../_reducers/DataPanel/Sales/salesSlice";
import {toast} from "react-toastify";
import Alert from "react-bootstrap/Alert";
import {vatCategoryData} from "../../../_data/mydata/vatCategory";
import {isEmpty} from "../../../_helpers/commonFunctions";
import Input from "../../common/Input";
import InternationalMUModal from "../POPUP-COMPONENTS/InternationalMUModal";
import {validInternationalCodeUnit} from "../../../_data/international_codes/international_codes";

const SalesProductQuickEdit = (props) => {
    //props.data for table row data
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const SALES = useSelector(state => state.SALES);
    const zeroVatReasonOptions = getMyData({ type: 'zerovatreason' });
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const ACTIVE_MAIN_TAB = useSelector((state) => state.ACTIVE_MAIN_TAB);

    const [productData, setProductData] = useState(props.data);
    const [myData, setMyData] = useState([]);
    const isInView = TABS_DATA[ACTIVE_MAIN_TAB] === "view-sales";

    const icCategory = isInView ? getICCategoryOptions() : getICCategoryOptions().filter((el) => myData.findIndex(md => md.category === el.value) !== -1);
    const icType = isInView ? getICTypeOptions() : getICTypeOptions().filter((el) => myData.findIndex(md => md.type === el.value && md.category === productData.classificationCategory) !== -1);
    const ecCategory = isInView ? getECCategoryOptions() : getECCategoryOptions().filter((el) => myData.findIndex(md => md.category === el.value) !== -1);
    const ecType = isInView ? getECTypeOptions() : getECTypeOptions().filter((el) => myData.findIndex(md => md.type === el.value && md.category === productData.classificationCategory) !== -1);
    const vatExpenseType = [{label: "Κενό", value: ""}].concat(getVatExpenseClassificationType());
    const [showPeppolMUHelp, setShowPeppolMUHelp] = useState(false);

    useEffect(() => {
        if(show === true) {
            axios.get(process.env.REACT_APP_API_URL2 + `/category/get-product-by-id/${productData._id}`)
                .then((res) => {
                    if (TABS_DATA[ACTIVE_MAIN_TAB] === "view-sales") {
                        setMyData(res.data.data.mydata.filter((el) => String(el.invoiceType) === String(SALES.editSales.myDataType)));
                    } else if (TABS_DATA[ACTIVE_MAIN_TAB] === "sales-new") {
                        setMyData(res.data.data.mydata.filter((el) => String(el.invoiceType) === String(SALES.newSales.myDataType)));
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [show])

    const getSaleObject = () => {
        if (TABS_DATA[ACTIVE_MAIN_TAB] === "sales-new") {
            return SALES.newSales;
        } else if (TABS_DATA[ACTIVE_MAIN_TAB] === "view-sales") {
            return SALES.editSales;
        }
    }

    const saveClicked = () => {
        if(props.tableName === "Sales Products List") {
            if (getSaleObject()?.enablePeppol === "active" && !validInternationalCodeUnit(productData.peppolMeasurementUnit)) {
                toast.error(`Ο κωδικός μονάδας μέτρησης ${productData.peppolMeasurementUnit} δεν υπάρχει στην παγκόσμια λίστα μονάδων μέτρησης.`);
                return;
            }
            let allProducts = [...SALES.newSales.products];
            allProducts.map((a) => Object.assign({}, a));
            let idx = allProducts.findIndex((el) => el._id === productData._id);
            if(idx === -1) {
                toast.error("Σφάλμα κατά την αποθήκευση, η γραμμή δεν υπάρχει.");
            } else {
                if (props.data.vatPercentage !== productData.vatPercentage) {
                    allProducts[idx] = {...allProducts[idx], ...productData, quantity: 1, unitPrice: 0, discount: 0, vat: 0, netValue: 0, totalValue: 0};
                } else {
                    allProducts[idx] = {...allProducts[idx], ...productData};
                }
                allProducts[idx].quickEdit = true;
                if (allProducts[idx]?.showAlternativeNameOnPrint === "true" && isEmpty(allProducts[idx]?.alternativeName)) {
                    allProducts[idx].showAlternativeNameOnPrint = "false";
                }
                setShow(false);
                toast.success("Το είδος ενημερώθηκε.", { autoClose: 1500 });
                setTimeout(() => {
                    dispatch(setNewSales({...SALES.newSales, products: allProducts}));
                }, 200)
            }
        }
    }
    const handleOnChange = (e, type, name) => {
        if(type === "dropdown") {
            if (name === "vatPercentage") {
                if (e.value === "8") {
                    setProductData({...productData, vatPercentage: "0", vatId: "8"});
                } else {
                    const findPercentage = vatCategoryData.find((el) => el.id === String(e.value))?.percentage;
                    if (findPercentage !== "0") {
                        setProductData({...productData, vatPercentage: findPercentage, vatId: e.value});
                    } else {
                        setProductData({...productData, vatPercentage: findPercentage, vatReason: "", vatId: e.value});
                    }
                }
            } else if (name === "vatReason") {
                setProductData({...productData, vatReason: e.value});
            } else if (name === "classificationCategory") {
                setProductData({...productData, classificationCategory: e.value, classificationType: ""});
            } else if (name === "classificationType") {
                setProductData({...productData, classificationType: e.value});
            } else if (name === "vatExpenseType") {
                setProductData({...productData, vatExpenseType: e.value});
            }
        } else if (type === "input") {
            if (name === "name") {
                let newVal = e.target.value;
                if (String(newVal) === String(productData.name)) {
                    setProductData({...productData, showAlternativeNameOnPrint: "false"});
                } else {
                    setProductData({...productData, showAlternativeNameOnPrint: "true", alternativeName: newVal});
                }
            } else if (name === "peppolMeasurementUnit") {
                setProductData({...productData, peppolMeasurementUnit: e.target.value});
            }
        } else if (type === "acb") {
            if (name === "deductionsEuro") {
                let val = 0;
                if (!isNaN(e.target.value)) {
                    val = Number(parseFloat(e.target.value).toFixed(2));
                }
                setProductData({...productData, deductionsEuro: val});
            }
        }
    }
    const invalidProduct = () => {
        return isEmpty(productData.vatPercentage)
            || (String(productData.vatId) === "7" && isEmpty(productData.vatReason))
            || (isEmpty(productData.classificationCategory) && !isEmpty(getSaleObject()?.myDataType))
            || (isEmpty(productData.classificationType) && !isEmpty(getSaleObject()?.myDataType));
    }
    const getVatOptions = () => {
        let opt = [];
        for (let vatObj of vatCategoryData) {
            opt.push({label: vatObj.description, value: vatObj.id});
        }
        return opt;
    }
    return (
        <React.Fragment>
            <Button style={{pointerEvents: "auto"}} size={"sm"} variant={"default"} title={props.title ? props.title : "Quick Edit"} onClick={() => {setShow(true)}}>
                <i id={props.id} className="far fa-edit"></i>
            </Button>
            <Modal backdrop={"static"} show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{isInView ? "Προβολή" : "Τροποποίηση"} Είδους Γραμμής</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        name={"quickEditInput"}
                        label={"Όνομα είδους"}
                        className={"mb-2"}
                        value={productData?.showAlternativeNameOnPrint === "true" ? productData.alternativeName : productData.name}
                        disabled={isInView}
                        onChange={(e) => handleOnChange(e, "input", "name")}
                    />
                    <Dropdown
                        name={"quickEditVat"}
                        label={"Φ.Π.Α. %"}
                        options={getVatOptions()}
                        required={true}
                        disabled={isInView}
                        key={Math.random()}
                        defaultValue={getVatOptions().find((el) => String(el.value) === String(productData.vatId))}
                        onChange={(e) => handleOnChange(e, "dropdown", "vatPercentage")}
                    />
                    {String(productData.vatId) === "7" && (
                        <Dropdown
                            name={"quickEditVatReason"}
                            label={"Λόγος Εξαίρεσης Φ.Π.Α."}
                            options={zeroVatReasonOptions}
                            required={true}
                            key={Math.random()}
                            disabled={isInView}
                            defaultValue={zeroVatReasonOptions.filter((el) => el.value === productData.vatReason).length > 0 ? zeroVatReasonOptions.filter((el) => el.value === productData.vatReason)[0] : ""}
                            onChange={(e) => handleOnChange(e, "dropdown", "vatReason")}
                        />
                    )}
                    <Dropdown
                        name={"quickEditCategory"}
                        label={"Κατηγορία My Data"}
                        options={(productData?.invoiceDetailType === "1") ? ecCategory : icCategory}
                        required={!isEmpty(getSaleObject()?.myDataType)}
                        key={Math.random()}
                        disabled={isInView}
                        defaultValue={((productData?.invoiceDetailType === "1") ? ecCategory : icCategory).filter((el) => el.value === productData.classificationCategory).length > 0 ? ((productData?.invoiceDetailType === "1") ? ecCategory : icCategory).filter((el) => el.value === productData.classificationCategory)[0] : ""}
                        onChange={(e) => handleOnChange(e, "dropdown", "classificationCategory")}
                    />
                    <Dropdown
                        name={"quickEditType"}
                        label={"Τύπος My Data"}
                        options={(productData?.invoiceDetailType === "1") ? ecType : icType}
                        required={!isEmpty(getSaleObject()?.myDataType)}
                        key={Math.random()}
                        disabled={isInView}
                        defaultValue={((productData?.invoiceDetailType === "1") ? ecType : icType).filter((el) => el.value === productData.classificationType).length > 0 ? ((productData?.invoiceDetailType === "1") ? ecType : icType).filter((el) => el.value === productData.classificationType)[0] : ""}
                        onChange={(e) => handleOnChange(e, "dropdown", "classificationType")}
                    />
                    {productData?.classificationCategory.startsWith("category2_") && (
                        <Dropdown
                            name={"quickEditVatExpenseType"}
                            label={"Τύπος Εξόδων για Φ.Π.Α."}
                            options={vatExpenseType}
                            required={!isEmpty(getSaleObject()?.myDataType)}
                            disabled={isInView}
                            key={Math.random()}
                            defaultValue={vatExpenseType.filter((el) => el.value === productData.vatExpenseType).length > 0 ? vatExpenseType.filter((el) => el.value === productData.vatExpenseType)[0] : ""}
                            onChange={(e) => handleOnChange(e, "dropdown", "vatExpenseType")}
                        />
                    )}
                    {/*
                    {!isEmpty(productData.deductionsEuro) && (
                        <React.Fragment>
                            <label>{"Ποσό κρατήσεων"}</label>
                            <AccountingBox
                                name={"deductionsEuro"}
                                defaultValue={productData.deductionsEuro}
                                disabled={isInView}
                                onBlur={(e) => handleOnChange(e, "acb", "deductionsEuro")}
                            />
                        </React.Fragment>
                    )}
                    */}
                    {getSaleObject()?.enablePeppol === "active" && (
                        <React.Fragment>
                            <Row>
                            <Col md={12}>
                                    <Input
                                        name={"quickEditPeppolMeasurementUnit"}
                                        label={"Κωδικός μονάδας μέτρησης τιμολογηθείσας ποσότητας (για δημόσιες συμβάσεις)"}
                                        disabled={isInView}
                                        value={productData.peppolMeasurementUnit}
                                        onChange={(e) => handleOnChange(e, "input", "peppolMeasurementUnit")}
                                    />
                                    <Button size={"sm"} title={"Βοήθεια"} style={{position: "absolute", right: "15px", top: "45px", padding: "5px"}} onClick={() => setShowPeppolMUHelp(!showPeppolMUHelp)}><i className="fas fa-circle-question"></i></Button>
                                    {showPeppolMUHelp && (
                                        <React.Fragment>
                                            <Alert variant={"secondary"} className={"mt-2"}>
                                                Παρακάτω μπορείτε να αναζητήσετε ή να δείτε όλες τις πιθανές μονάδες
                                                μέτρησης που υπάρχουν παγκοσμίως. Οι κυριότερες είναι:<br/>
                                                - Τεμάχια (H87)<br/>
                                                - Κιλά (KGM)<br/>
                                                - Λίτρα (LTR)<br/>
                                                - Υπηρεσίες (E48)<br/>
                                                - Μέτρα (MTR)<br/>
                                                - Τετ. Μέτρα (MTK)<br/>
                                                - Κυβ. Μέτρα (MTQ)<br/>
                                                <div style={{textAlign: "center"}} className={"mt-2"}>
                                                    <InternationalMUModal/>
                                                </div>
                                            </Alert>
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                    {props.data.vatPercentage !== productData.vatPercentage && (
                        <Alert key={Math.random()} variant={"info"}>Αλλάζοντας τον Φ.Π.Α. θα μηδενιστούν οι τιμές του είδους στην πώληση.</Alert>
                    )}
                    {isEmpty(productData.vatPercentage) && <div style={{"color" : "red"}}>* Πρέπει να συμπληρώσετε Φ.Π.Α. %</div>}
                    {(String(productData.vatId) === "7" && isEmpty(productData.vatReason)) && <div style={{"color" : "red"}}>* Πρέπει να συμπληρώσετε λόγο εξαίρεσης Φ.Π.Α.</div>}
                    {(isEmpty(productData.classificationCategory) && !isEmpty(getSaleObject()?.myDataType)) && <div style={{"color" : "red"}}>* Πρέπει να συμπληρώσετε κατηγορία MyData</div>}
                    {(isEmpty(productData.classificationType) && !isEmpty(getSaleObject()?.myDataType)) && <div style={{"color" : "red"}}>* Πρέπει να συμπληρώσετε τύπο MyData</div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShow(false)}>Κλείσιμο</Button>
                    {!isInView && (<Button variant="primary" onClick={() => saveClicked()} disabled={invalidProduct()}>Αποθήκευση</Button>)}
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default SalesProductQuickEdit;
