import React, {useEffect} from "react";
import SearchTable from "../../../primer/Search-Table";
import {useSelector, useDispatch} from "react-redux";
import {getOptionsByData} from '../../../../_helpers/commonFunctions';
import {getObjectFromArrayOfObjects} from '../../../../_helpers/helperFunctions';
import {
    fetchAdvancedTypes,
    fetchBuysGeneralSettings,
} from "../../../../_apis/api";
import {useTranslation} from "react-i18next";
import {classicStyleBelowNavbar} from "../Statics";

function SupplierPaymentsSearch() {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const BUYS_SETTINGS = useSelector((state) => state.BUYS_SETTINGS);
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const allDocumentTypes = SETTINGS.settings.advancedTypes;
    const supplierPaymentsDT = getObjectFromArrayOfObjects(allDocumentTypes, 'Supplier Payments', 'section');
    const documentTypesOptions = getOptionsByData(supplierPaymentsDT, '_id', 'name')
    const paymentTypeOptions = getOptionsByData(BUYS_SETTINGS.buysGeneralSettings.paymentTypes, "_id", "name");

    useEffect(() => {
        dispatch(fetchAdvancedTypes({ company: company.id }));
        dispatch(fetchBuysGeneralSettings({ company: company.id }))
    }, [dispatch]);

    const ssColumns = [
        {
            name: t("General.date"),
            field: "date",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.supplier"),
            field: "supplierName",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.documentType"),
            field: "documentTypeName",
            width: "10%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesClientPaymentsSearch.table.preNumberAndNumber'),
            field: "preNumberAndNumber",
            width: "10%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.paymentType"),
            field: "paymentTypeName",
            width: "10%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.amount"),
            field: "amount",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
    ]

    const searchBuyFilters = {
        filters: [
            {
                name: "documentType",
                label: t("General.documentType"),
                type: "multiselect",
                options: documentTypesOptions
            },
            {
                name: "supplierName",
                label: t("General.supplierNameVAT"),
                type: "input",
            },
            {
                name: "paymentTypes",
                label: t("General.paymentType"),
                type: "multiselect",
                options: paymentTypeOptions
            },
            {
                name: "amount_from",
                label: t("General.amountFrom"),
                type: "input",
            },
            {
                name: "amount_to",
                label: t("General.amountTo"),
                type: "input",
            },
            {
                name: "numberFrom",
                label: t('General.numberFrom'),
                type: "number",
            },
            {
                name: "numberTo",
                label: t('General.numberTo'),
                type: "number",
            },
        ],
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName="Supplier Payments Search"
                key={"Supplier Payments Search"}
                allowPagination={true}
                hideYearFilter={false}
                columns={ssColumns}
                data={[]}
                filtersConfig={searchBuyFilters}
            />
        </div>
    )
}

export default SupplierPaymentsSearch;
