import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Chart from "react-apexcharts";
import {getGreekCurrencyFormat} from "../_helpers/commonFunctions";

export const OverviewChart2 = () => {
    const dashboardData = useSelector(state => state.COMPANY_DATA.dashboardData);
    const [chartOptions, setChartOptions] = useState({});
    const [series, setSeries] = useState([]);

    useEffect(() => {
        if (dashboardData?.monthlyData) {
            let co = {
                chart: {
                    toolbar: {
                        show: true,
                    },
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    labels: {
                        formatter: (value) => {
                            return `${value} €`
                        }
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    y: {
                        formatter: (value) => {
                            return getGreekCurrencyFormat(value);
                        }
                    }
                },
                colors: ["#d95f02", "#1b9e77"]
            }
            let s = [
                {
                    name: "Πωλήσεις",
                    data: []
                },
                {
                    name: "Εισπράξεις",
                    data: []
                }
            ];
            for (let d of dashboardData.monthlyData) {
                co.xaxis.categories.push(d.month);
                s[0].data.push(d.totalSales);
                s[1].data.push(d.totalUnits);
            }
            setChartOptions(co);
            setSeries(s);
        }
    }, [dashboardData])

    return (
        <div style={{
            borderRadius: "10px",
            border: "1px solid lightgray",
            padding: "10px",
            height: "100%",
            width: "100%",
            boxShadow: "0px 2px 4px -1px lightgray",
            minHeight: "350px",
        }}>
            <div style={{textAlign: "center", fontSize: "20px", fontWeight: "600"}}>Γραφική Αναφορά Πωλήσεων - Εισπράξεων</div>
            <Chart
                options={chartOptions}
                series={series}
                type="area"
                height={"94%"}
                width={"100%"}
            />
        </div>
    )
}