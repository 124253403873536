import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import axios from "axios";
import {isEmpty} from "../../../../_helpers/commonFunctions";
import {toast} from "react-toastify";
import Modal from "react-bootstrap/Modal";
import AdvancedCombobox from "../../AdvancedCombobox";
import Button from "react-bootstrap/Button";
import {Spinner} from "react-bootstrap";

const MassBuysModal = ({show, setShow, filters, successfulCallback}) => {
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const [loading, setLoading] = useState(false);
    const [consoleLines, setConsoleLines] = useState([]);

    const [mappedProductId, setMappedProductId] = useState("");
    const [mappedProductName, setMappedProductName] = useState("");
    const [productListData, setProductListData] = useState([]);

    useEffect(() => {
        onProductSearch( null, setProductListData);
    }, [])

    useEffect(() => {
        if (!show) {
            setConsoleLines([]);
            setMappedProductId("");
            setMappedProductName("");
        }
    }, [show])

    const onProductSearch = (keyword, setData) => {
        let reqData = {
            company: company.id,
        }
        if (keyword) reqData.keyword = keyword;
        axios.get(`${process.env.REACT_APP_API_URL2}/category/get-product-list`, {
            headers: {'Content-Type': 'application/json'},
            params: reqData
        }).then((res) => {
            if (res.data.status === "200") {
                setData(res.data.data);
            } else {
                setData([]);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const massFetch = () => {
        if (isEmpty(mappedProductId)) return toast.error("Παρακαλώ επιλέξτε βασικό είδος.");
        setLoading(true);
        let invTypes = [];
        if(filters?.invoiceType?.length > 0) {
            filters.invoiceType.forEach((obj) => {
                invTypes.push(obj.value);
            })
        }
        axios.post(`${process.env.REACT_APP_API_URL2}/buy/mass-fetch-buys-md`, {
            defaultProduct: mappedProductId,
            filters: {...filters, invoiceType: invTypes},
            company: company.id,
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                toast.success("Επιτυχής άντληση.");
                setConsoleLines(res.data.consoleLines);
                if (typeof successfulCallback === "function") successfulCallback();
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleHide = () => {
        if (loading) {
            toast.info("Παρακαλώ περιμένετε μέχρι να τελειώσει η εργασία.");
        } else {
            setShow(false);
        }
    }

    const onChangeSearchProduct = (keyword, setData) => {
        if (String(keyword).trim().length >= 2) onProductSearch(keyword, setData);
    }

    return (
        <Modal show={show} onHide={() => handleHide()} backdrop={"static"} dialogClassName={"modal42PercentWidth"}>
            <Modal.Header closeButton>
                <Modal.Title>Μαζική Άντληση Αγορών από το MyData</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"mb-2"}>
                    Είσαστε σίγουροι ότι θέλετε να αντλήσετε τις αγορές που υπάρχουν στο MyData από {filters.date_from} έως {filters.date_to};
                    Δεν θα γίνει η δημιουργία διπλότυπων παραστατικών αν υπάρχουν ήδη. Η διαδικασία μπορεί να διαρκέσει κάποια λεπτά και εκτελεί τα παρακάτω βήματα:<br/>
                    - Άντληση των παραστατικών από το My Data<br/>
                    - Διαχωρισμός μεταξύ ήδη υπάρχων και μη<br/>
                    - Κατάλληλη δημιουργία βασικών και γενικών παραστατικών ανάλογα με τον τύπο My Data σε περίπτωση που δεν υπάρχουν<br/>
                    - Δημιουργία προμηθευτών που δεν υπάρχουν<br/>
                    Τα είδη που θα τοποθετηθούν στην αγορά θα είναι αυτά που έχουν αγοραστεί από τον προμηθευτή προγενέστερα και σε περίπτωση που δεν βρεθεί θα τοποθετηθεί το είδος παρακάτω.
                    Για την ορθή καταχώρηση θα γίνει αυτόματος χαρακτηρισμός στα παραστατικά που πρέπει να χαρακτηριστούν ως έξοδα με βάση τις προγενέστερες αγορές.
                    Στην περίπτωση που δεν γίνεται, θα παραλειφθούν.
                </div>
                <AdvancedCombobox
                    searchableDropdownEnable={false}
                    label={"Βασικό είδος αποθήκης"}
                    defaultValue={mappedProductName}
                    data={productListData}
                    onChange={(e) => {
                        setMappedProductId(e.row._id);
                        setMappedProductName(e.row.name);
                    }}
                    excludeColumns={[]}
                    columns={[
                        {label: "Όνομα είδους", name: "name"}
                    ]}
                    editEnable={false}
                    deleteEnable={false}
                    searchTableEnable={false}
                    showTableOptionOnSelect={true}
                    key={Math.random()}
                    onChangeSearch={onChangeSearchProduct}
                />
                {consoleLines.length > 0 && (
                    <React.Fragment>
                        <h5 style={{textAlign: "center"}}>Κονσόλα:</h5>
                        <div style={{height: "300px", overflowY: "scroll", border: "1px solid gray", padding: "5px"}}
                             className={"mb-3"}>
                            {consoleLines.length > 0 && consoleLines.map((line) => (
                                <span>{line}<br/></span>
                            ))}
                        </div>
                    </React.Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => massFetch()} disabled={loading}>
                    Εκτέλεση διαδικασίας {loading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default MassBuysModal
