import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {Badge, Col, Row} from "react-bootstrap";
import DateBox from "../../../primer/DateBox";
import Button from "react-bootstrap/Button";
import moment from "moment";
import {useTranslation} from "react-i18next";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import axios from "axios";
import "jspdf-autotable";
import {
    checkPermission,
    getOptionsByData,
    isEmpty,
    paymentStatusOptions,
    providerChannelOptions,
    salesMyDataStatus
} from "../../../../_helpers/commonFunctions";
import Dropdown from "../../../primer/Dropdown";
import {getInvoiceTypesOptionList} from "../../../../_helpers/sharedmydata";
import EditableTable from "../../../primer/Editable-Table";
import {fetchSalesGeneralSettings} from "../../../../_apis/api";
import {setSalesReportData, setSalesReportRequestData} from "../../../../_reducers/DataPanel/Sales/salesSlice";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import {classicStyleBelowNavbar} from "../Statics";
import Input from "../../../common/Input";

const SalesBalanceSheet = () => {
    const [t, i18n] = useTranslation('common');
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const SALES_SETTINGS = useSelector((state) => state.SALES_SETTINGS);
    const reportData = useSelector((state) => state.SALES.salesBalanceReportData);
    const requestData = useSelector((state) => state.SALES.salesBalanceReportRequestData);
    const carNumbersOptions = useMemo(() => getOptionsByData(SALES_SETTINGS.salesGeneralSettings.carNumbers, "_id", "number"), [SALES_SETTINGS.salesGeneralSettings.carNumbers]);
    const salesMyDataStatusOptions = salesMyDataStatus();
    const paymentStatusOptionsDropdown = paymentStatusOptions();
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const [loading, setLoading] = useState(false);
    const [incrKey, setIncrKey] = useState(0);
    const [pageChanged, setPageChanged] = useState(false);

    const columns = [
        {
            name: "No",
            field: "no",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesBalanceSheet.table.date'),
            field: "date",
            editable: false,
            hiddenColumn: false,
            width: "9%",
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesBalanceSheet.table.client'),
            field: "clientName",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesBalanceSheet.table.clientVat'),
            field: "vat",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.phone"),
            field: "phone",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesBalanceSheet.table.invoiceType'),
            field: "documentTypeName",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesBalanceSheet.table.number'),
            field: "number",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesBalanceSheet.table.netValue'),
            field: "totalNetValue",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesBalanceSheet.table.vatAmount'),
            field: "vatAmount",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesBalanceSheet.table.totalValue'),
            field: "totalAmount",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesBalanceSheet.table.paymentType'),
            field: "paymentTypeName",
            editable: false,
            hiddenColumn: true,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesBalanceSheet.table.vehicleNumber'),
            field: "vehNumber",
            editable: false,
            hiddenColumn: true,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesBalanceSheet.table.providerChannel'),
            field: "providerChannel",
            editable: false,
            hiddenColumn: true,
            inputType: {
                type: "text",
            },
        },
        {
            name: "Περιέχοντα είδη",
            field: "productNamesBalanceSheet",
            editable: false,
            hiddenColumn: true,
            inputType: {
                type: "text",
            }
        },
        {
            name: t('General.paymentAmount'),
            field: "paymentAmount",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
    ];

    useEffect(() => {
        dispatch(fetchSalesGeneralSettings({ company: company.id }));
        loadInitialClientsListData()
        getInvoiceTypesOption();
        if(isEmpty(requestData.date_to) && isEmpty(requestData.date_from)) {
            const currentYear = Number(moment().format("YYYY"));
            if (Number(company.year) < currentYear) {
                dispatch(setSalesReportRequestData({...requestData, date_from: `01/01/${company.year}`, date_to: `31/12/${company.year}`}));
            } else {
                dispatch(setSalesReportRequestData({...requestData, date_from: moment().startOf("month").format("DD/MM/YYYY"), date_to: moment().endOf("month").format("DD/MM/YYYY")}));
            }
        }
    }, []);

    useEffect(() => {
        setTableColumns(columns.slice());
    }, [i18n.language])

    useEffect(() => {
        if (pageChanged) {
            setPageChanged(false);
            fetchReport(true);
        }
    }, [pageChanged])

    const [clientsList, setClientsList] = useState([]);
    let paymentTypeOptions = getOptionsByData(SALES_SETTINGS.salesGeneralSettings.paymentTypes, "_id", "name");
    paymentTypeOptions = [...paymentTypeOptions]
    const [invoiceTypes, setInvoiceTypes] = useState([]);
    const providerChannelOptionsDropdown = providerChannelOptions();
    providerChannelOptionsDropdown.shift();
    const [tableColumns, setTableColumns] = useState(columns.slice());

    const onChangeSearch = (keyword, setData) => {
        if (String(keyword).length >= 2) {
            loadClientsListData(keyword, setData);
        }
    }
    const getInvoiceTypesOption = () => {
        axios.post(process.env.REACT_APP_API_URL2 + `/settings/list-setting-advance-type-sale`, {company: company.id}, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                let invoiceList = [];
                if (res.data.data) {
                    invoiceList = getInvoiceTypesOptionList(res.data.data);
                    invoiceList = [...invoiceList]
                    setInvoiceTypes(invoiceList);
                }
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const loadInitialClientsListData = () => {
        axios.post(process.env.REACT_APP_API_URL2 + `/client/list-client`, {company: company.id}, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                if (res.data.data) {
                    let filteredData = res.data.data;
                    setClientsList(filteredData);
                } else {
                    setClientsList([]);
                }
            } else {
                setClientsList([]);
            }
        }).catch((e) => {
            console.log(e);
        })
    }
    const loadClientsListData = (name, setData) => {
        axios.post(process.env.REACT_APP_API_URL2 + `/client/list-client`, {name: name, company: company.id}, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                if (res.data.data) {
                    setData(res.data.data);
                } else {
                    setData([]);
                }
            } else {
                setData([]);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const clientDropdownColumns = [
        {
            label: t('SalesBalanceSheet.table3.clientName'),
            name: "name",
        },
        {
            label: t('SalesBalanceSheet.table3.vatNumber'),
            name: "vatNumber",
        },
        {
            label: t('SalesBalanceSheet.table3.phoneNumber'),
            name: "phone",
        },
    ]

    const handleOnChange = (e, type, name) => {
        if (type === "datebox") {
            if (moment(e).isValid()) {
                dispatch(setSalesReportRequestData({...requestData, [name] : moment(e).format("DD/MM/YYYY")}))
            } else {
                dispatch(setSalesReportRequestData({...requestData, [name] : ""}));
            }
        } else if (type === "dropdown") {
            dispatch(setSalesReportRequestData({...requestData, [name] : e}));
        } else if (type === "acdd") {
            dispatch(setSalesReportRequestData({...requestData, [name] : e.row.name}));
        } else if (type === "input") {
            dispatch(setSalesReportRequestData({...requestData, [name] : e.target.value}));
        }
    }

    const fetchReport = (fromPagination = false) => {
        setLoading(true);
        let newData = {
            ...requestData,
            company: company.id,
            year: company.year,
            invoiceType: requestData.invoiceType.map(el => el.value),
            paymentType: requestData.paymentType.map(el => el.value),
            carNumber: requestData.carNumber.map(el => el.value),
            providerChannel: requestData.providerChannel.map(el => el.value),
            myDataStatus: requestData.myDataStatus.map(el => el.value),
            paymentStatus: requestData.paymentStatus.map(el => el.value),
        }
        if (!fromPagination) newData.page = 1;
        axios.post(process.env.REACT_APP_API_URL2 + `/report/sales-balance-report`, newData, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                if (res.data.data.length === 0) {
                    toast.info("Δεν βρέθηκαν αποτελέσματα.");
                    dispatch(setSalesReportData([]));
                } else {
                    let dR = [];
                    if (res.data.data.data.length > 0) {
                        res.data.data.data.forEach((obj, i) => {
                            obj.no = 20*Number(res.data.data.currentPage - 1) + i + 1;
                            if (obj.preNumber) {
                                if (obj.preNumber !== "noprefix") {
                                    obj.number = obj.preNumber + obj.number;
                                }
                            }
                            obj.vehNumber = obj.extraData[0]?.carNumber;
                            obj.totalNetValue = Number(Number(obj.totalNetValue).toFixed(2));
                            obj.vatAmount = Number(Number(obj.totalVatValue).toFixed(2));
                            obj.totalAmount = Number(Number(obj.totalAmount));
                        })
                        dispatch(setSalesReportRequestData({
                            ...requestData,
                            page: res.data.data.currentPage,
                            totalPages: res.data.data.totalPages,
                            totalVatValue: res.data.totalVatValue,
                            totalNetValue: res.data.totalNetValue,
                            totalValue: res.data.totalValue,
                            totalPayment: res.data.totalPayment,
                        }));
                        dR = res.data.data.data;
                        dispatch(setSalesReportData([...dR]));
                        setIncrKey(incrKey + 1);
                        if (!fromPagination) toast.success("Τα δεδομένα ανανεώθηκαν.", {autoClose: 1000});
                    }
                }
            } else {
                toast.error("Σφάλμα κατά την αποστολή αιτήματος");
                console.log(res.data);
            }
        }).catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    const toFirstPage = () => {
        dispatch(setSalesReportRequestData({...requestData, page: 1}));
        setPageChanged(true);
    }
    const toNextPage = () => {
        dispatch(setSalesReportRequestData({...requestData, page: requestData.page + 1}));
        setPageChanged(true);
    }
    const toPreviousPage = () => {
        dispatch(setSalesReportRequestData({...requestData, page: requestData.page - 1}));
        setPageChanged(true);
    }
    const toLastPage = () => {
        dispatch(setSalesReportRequestData({...requestData, page: requestData.totalPages}));
        setPageChanged(true);
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row className={"mb-2"}>
                <Col md={2}>
                    <DateHelper
                        dateFromAndToFunction={(from, to) => {
                            dispatch(setSalesReportRequestData({...requestData, date_from: from, date_to: to}));
                        }}
                    />
                </Col>
                <Col md={1}>
                    <DateBox
                        name="date_from"
                        selected={isEmpty(requestData.date_from) ? moment().toDate() : moment(requestData.date_from, "DD/MM/YYYY").toDate()}
                        label={t('SalesBalanceSheet.dateFrom')}
                        onChange={(e) => handleOnChange(e, "datebox", "date_from")}
                    />
                </Col>
                <Col md={1}>
                    <DateBox
                        name="date_to"
                        selected={isEmpty(requestData.date_to) ? moment().toDate() : moment(requestData.date_to, "DD/MM/YYYY").toDate()}
                        label={t('SalesBalanceSheet.dateTo')}
                        onChange={(e) => handleOnChange(e, "datebox", "date_to")}
                    />
                </Col>
                <Col md={4}>
                    <AdvancedCombobox
                        name="clientName"
                        ACName="Sales Client" // do not change name
                        className="w-100 ac-select-dropdown"
                        searchableDropdownEnable={false}
                        defaultValue={requestData.clientName}
                        label={t("General.client")}
                        data={clientsList}
                        onChange={(e) => handleOnChange(e, "acdd", "clientName")}
                        resetFilter={() => dispatch(setSalesReportRequestData({...requestData, clientName: ""}))}
                        onChangeSearch={onChangeSearch}
                        excludeColumns={[]}
                        columns={clientDropdownColumns}
                        editEnable={false}
                        deleteEnable={false}
                        searchTableEnable={false}
                        showTableOptionOnSelect={true}
                        placeholder={t("Placeholders.selectClient")}
                        required={true}
                        showInsertAddButton={true}
                        hideSaveButton={true}
                        hideInsertNewRow={true}
                        inputFieldClasses="form-control"
                    />
                </Col>
                <Col md={4}>
                    <Input
                        label={t('SalesSearch.table2.productName')}
                        name={"productName"}
                        placeholder={t('SalesSearch.table2.productName')}
                        type={"text"}
                        value={requestData.productName}
                        onChange={(e) => handleOnChange(e, "input", "productName")}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <Dropdown
                        label={t('SalesBalanceSheet.invoiceType')}
                        name="invoiceType"
                        width="30%"
                        options={invoiceTypes}
                        key={Math.random()}
                        onChange={(e) => handleOnChange(e, "dropdown", "invoiceType")}
                        multiSelect={true}
                        defaultValue={requestData.invoiceType}
                    />
                </Col>
                <Col md={3}>
                    <Dropdown
                        label={t('SalesBalanceSheet.paymentType')}
                        options={paymentTypeOptions}
                        defaultValue={requestData.paymentType}
                        enabledValue={true}
                        multiSelect={true}
                        name="paymentType"
                        searchEnable={true}
                        onChange={(e) => handleOnChange(e, "dropdown", "paymentType")}
                    />
                </Col>
                <Col md={2}>
                    <Dropdown
                        name="carNumber"
                        label={t('SalesBalanceSheet.vehicleNumber')}
                        options={carNumbersOptions}
                        enabledValue={true}
                        multiSelect={true}
                        onChange={(e) => handleOnChange(e, "dropdown", "carNumber")}
                        defaultValue={requestData.carNumber}
                    />
                </Col>
                <Col md={2}>
                    <Dropdown
                        label={t('SalesBalanceSheet.providerChannel')}
                        name="providerChannel"
                        width="30%"
                        options={providerChannelOptionsDropdown}
                        enabledValue={true}
                        multiSelect={true}
                        onChange={(e) => handleOnChange(e, "dropdown", "providerChannel")}
                        defaultValue={requestData.providerChannel}
                    />
                </Col>
                <Col md={1}>
                    <Input
                        label={t('General.sumFrom')}
                        name={"totalSum_from"}
                        placeholder={t('General.sumFrom')}
                        type={"number"}
                        value={requestData.totalSum_from}
                        onChange={(e) => handleOnChange(e, "input", "totalSum_from")}
                    />
                </Col>
                <Col md={1}>
                    <Input
                        label={t('General.sumTo')}
                        name={"totalSum_to"}
                        placeholder={t('General.sumTo')}
                        type={"number"}
                        value={requestData.totalSum_to}
                        onChange={(e) => handleOnChange(e, "input", "totalSum_to")}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={1}>
                    <Input
                        label={t('SalesSearch.table2.preNumberFrom')}
                        name={"preNumber_from"}
                        placeholder={t('SalesSearch.table2.preNumberFrom')}
                        type={"text"}
                        value={requestData.preNumber_from}
                        onChange={(e) => handleOnChange(e, "input", "preNumber_from")}
                    />
                </Col>
                <Col md={1}>
                    <Input
                        label={t('SalesSearch.table2.preNumberTo')}
                        name={"preNumber_to"}
                        placeholder={t('SalesSearch.table2.preNumberTo')}
                        type={"text"}
                        value={requestData.preNumber_to}
                        onChange={(e) => handleOnChange(e, "input", "preNumber_to")}
                    />
                </Col>
                <Col md={1}>
                    <Input
                        label={t('General.numberFrom')}
                        name={"numberFrom"}
                        placeholder={t('General.numberFrom')}
                        type={"number"}
                        value={requestData.numberFrom}
                        onChange={(e) => handleOnChange(e, "input", "numberFrom")}
                    />
                </Col>
                <Col md={1}>
                    <Input
                        label={t('General.numberTo')}
                        name={"numberTo"}
                        placeholder={t('General.numberTo')}
                        type={"number"}
                        value={requestData.numberTo}
                        onChange={(e) => handleOnChange(e, "input", "numberTo")}
                    />
                </Col>
                <Col md={2}>
                    <Dropdown
                        label={t('General.myDataStatus')}
                        name={"myDataStatus"}
                        options={salesMyDataStatusOptions}
                        multiSelect={true}
                        placeholder={t('General.myDataStatus')}
                        defaultValue={requestData.myDataStatus}
                        onChange={(e) => handleOnChange(e, "dropdown", "myDataStatus")}
                    />
                </Col>
                <Col md={3}>
                    <Dropdown
                        label={t('SalesSearch.table2.paymentStatus')}
                        name={"paymentStatus"}
                        options={paymentStatusOptionsDropdown}
                        multiSelect={true}
                        placeholder={t('SalesSearch.table2.paymentStatus')}
                        defaultValue={requestData.paymentStatus}
                        onChange={(e) => handleOnChange(e, "dropdown", "paymentStatus")}
                    />
                </Col>
                <Col md={3}>
                    <Input
                        label={t('General.notes')}
                        name={"notes"}
                        placeholder={t('General.notes')}
                        type={"text"}
                        value={requestData.notes}
                        onChange={(e) => handleOnChange(e, "input", "notes")}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12} className={"ml-auto"}>
                    <Button onClick={() => fetchReport()} className="btn btn-primary float-right" disabled={loading}>
                        {t('General.applyFilter')}
                    </Button>
                </Col>
            </Row>
            <hr style={{color: "gray", height: 1}}/>
            {reportData.length > 0 ? (
                <Row>
                    <Row>
                        <h3
                            style={{
                                textAlign: "center",
                                alignItems: "center",
                                fontWeight: "bold",
                            }}
                        >
                            {t('SalesBalanceSheet.salesBalance')}
                        </h3>
                    </Row>
                    {requestData.totalPages > 0 && (
                        <>
                            <div className="d-flex justify-content-end mb-2">
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => toFirstPage()}
                                    disabled={requestData.page === 1 || loading}
                                >
                                    {t('SalesBalanceSheet.first')}
                                </Button>
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => toPreviousPage()}
                                    disabled={requestData.page === 1 || loading}
                                >
                                    {t('SalesBalanceSheet.previous')}
                                </Button>
                                <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1"
                                       bg="primary">{t('SalesBalanceSheet.page')} {requestData.page} / {requestData.totalPages}</Badge>
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => toNextPage()}
                                    disabled={requestData.page === requestData.totalPages || loading}
                                >
                                    {t('SalesBalanceSheet.next')}
                                </Button>
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => toLastPage()}
                                    disabled={requestData.page === requestData.totalPages || loading}
                                >
                                    {t('SalesBalanceSheet.last')}
                                </Button>
                            </div>
                        </>
                    )}
                    <EditableTable
                        key={"Sales Balance Sheet" + incrKey}
                        id="salesBalanceReport"
                        tableName="Sales Balance Sheet"
                        data={reportData}
                        allowPagination={false}
                        filters={requestData}
                        allowToggleColumns={true}
                        allowInsertRow={false}
                        enableNewRowCheckbox={false}
                        allowBulkActions={true}
                        pageTotals={true}
                        editRedirectAllowed={true}
                        reportExcelPrint={true}
                        company={company}
                        totalNetValueTrue={true}
                        totalVatAmountTrue={true}
                        totalValueTrue={true}
                        totalPaymentAmountTrue={true}
                        totalNetValue={requestData.totalNetValue}
                        totalVatAmount={requestData.totalVatValue}
                        totalValue={requestData.totalValue}
                        totalPaymentAmount={requestData.totalPayment}
                        columns={tableColumns}
                        allowActions={true}
                        allowPrintResults={checkPermission("sales-reports/salesbalancesheet-balancesheet-print", permissionsData) === true}
                    />
                </Row>
            ) : (
                <Row>
                    <h3 style={{textAlign: "center", alignItems: "center"}}>
                        {t('SalesBalanceSheet.message')}
                    </h3>
                </Row>
            )}
        </div>
    )
}

export default SalesBalanceSheet;
