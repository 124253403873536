import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-bootstrap/Modal";
import {Badge, Col, Row, Spinner} from "react-bootstrap";
import Input from "../../../../common/Input";
import moment from "moment/moment";
import Dropdown from "../../../../primer/Dropdown";
import BetterCheckBox from "../../../../common/BetterCheckBox";
import {
    getMultiselectICD10sOptions, getNoInnCasesOptions,
    getZeroParticipationReasonOptions, prescriptionDoseAmountOptions,
    prescriptionDrugAmountOptions, prescriptionFrequencyOptions,
    prescriptionParticipationPercentageOptions
} from "../constants";
import Button from "react-bootstrap/Button";
import TextArea from "../../../../common/TextArea";
import {isEmpty} from "../../../../../_helpers/commonFunctions";
import {toast} from "react-toastify";
import axios from "axios";
import {
    resetNewPrescription,
    setNewPrescription,
} from "../../../../../_reducers/DataPanel/Prescription/prescriptionSlice";
import ICD10Modal from "./ICD10Modal";

const NewPrescriptionModal = ({show, setShow, successCallback}) => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const newPrescription = useSelector((state) => state.PRESCRIPTION.newPrescription);

    const [loading, setLoading] = useState(false);
    const [lastError, setLastError] = useState("");

    const [icd10modal, setICD10Modal] = useState(false);

    const [drugs, setDrugs] = useState([]);
    const [drugFilter, setDrugFilter] = useState("");
    const [loadingDrugs, setLoadingDrugs] = useState(false);

    const [showChooseDrugModal, setShowChooseDrugModal] = useState(false);

    const [extraInfoDrugs, setExtraInfoDrugs] = useState([]); // ids of drugs that has more info open

    const prescriptionTypes = [
        { value: "normal", label: "Συνταγογράφηση φαρμάκων" },
        { value: "ifet", label: "Συνταγογράφηση φαρμάκων εξωτερικού μέσω ΙΦΕΤ" },
        { value: "gal", label: "Συνταγογράφηση γαληνικών σκευασμάτων" },
    ]

    const prescriptionRepeatOptions = [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
    ]

    const prescriptionRepeatPeriodObj = {
        "1" : [],
        "2" : [
            {label: "2 μήνες (60 ημέρες)", value: "3"}
        ],
        "3" : [
            {label: "1 μήνας (30 ημέρες)", value: "1"},
            {label: "4 εβδομάδες (28 ημέρες)", value: "2"},
            {label: "2 μήνες (60 ημέρες)", value: "3"},
        ],
        "4" : [
            {label: "1 μήνας (30 ημέρες)", value: "1"},
            {label: "4 εβδομάδες (28 ημέρες)", value: "2"},
        ],
        "5" : [
            {label: "1 μήνας (30 ημέρες)", value: "1"},
            {label: "4 εβδομάδες (28 ημέρες)", value: "2"},
        ],
        "6" : [
            {label: "1 μήνας (30 ημέρες)", value: "1"},
            {label: "4 εβδομάδες (28 ημέρες)", value: "2"},
        ],
    }

    const prescriptionRepeatPeriodOptions = prescriptionRepeatPeriodObj[newPrescription.repeat];

    const dAmount = { // Based on repeatPeriod
        "1": [
            { value: "1", label: "1 ημέρα" },
            ...Array.from({length: 29}, (_, i) => {
                return {
                    value: String(i + 2),
                    label: `${i + 2} ημέρες`
                }
            })
        ],
        "2": [
            { value: "1", label: "1 ημέρα" },
            ...Array.from({length: 27}, (_, i) => {
                return {
                    value: String(i + 2),
                    label: `${i + 2} ημέρες`
                }
            })
        ],
        "3": [
            { value: "1", label: "1 ημέρα" },
            ...Array.from({length: 59}, (_, i) => {
                return {
                    value: String(i + 2),
                    label: `${i + 2} ημέρες`
                }
            })
        ]
    }

    const daysAmount = dAmount[newPrescription.repeatPeriod] || dAmount["1"];

    useEffect(() => {
        if (show) {
            dispatch(setNewPrescription({
                ...newPrescription,
                date: moment().toDate(),
            }))
        } else {
            dispatch(resetNewPrescription());
        }
    }, [show]);

    const handleSavePrescription = () => {
        // Validation
        if (newPrescription.prescriptionWithCommercialName && isEmpty(newPrescription.prescriptionWithCommercialNameCause)) {
            return toast.error("Παρακαλώ επιλέξτε αιτιολογία στην συνταγογράφηση με εμπορική ονομασία.");
        }
        if (newPrescription.diagnoses.length === 0) return toast.error("Παρακαλώ συμπληρώστε τουλάχιστον μία διάγνωση.");
        if (newPrescription.drugs.length === 0) return toast.error("Παρακαλώ συμπληρώστε τουλάχιστον ένα φάρμακο.");
        if (newPrescription.drugs.some((el) => isEmpty(el.erp_drugAmount) || isEmpty(el.erp_participationPercentage)
            || isEmpty(el.erp_doseAmount) || isEmpty(el.erp_frequency) || isEmpty(el.erp_days) || isEmpty(el.erp_notes)
            || isEmpty(el.erp_correlated_icd10s) || el?.erp_correlated_icd10s?.length === 0)) {
            return toast.error("Παρακαλώ συμπληρώστε όλα τα απαραίτητα στοιχεία σε κάθε φάρμακο.");
        }
        // Post to API
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/prescription/new-prescription`, {...newPrescription, company: company.id}, {
            headers: { 'Content-Type': 'application/json' },
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                toast.success("Επιτυχής καταχώρηση συνταγής.");
                setShow(false);
                dispatch(resetNewPrescription());
                setLastError("");
                successCallback();
            } else {
                toast.error(res.data.message);
                setLastError(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleOnChange = (e, type, name) => {
        let prepChange = {};
        if (type === "dd") {
            if (name === "repeat") {
                prepChange.repeat = e.value;
                prepChange.repeatPeriod = prescriptionRepeatPeriodObj[e.value][0] ? prescriptionRepeatPeriodObj[e.value][0].value : "";
                if (+e.value >= 2) {
                    prepChange.monthlyTreatment = false;
                    prepChange.bimonthlyTreatment = false;
                    prepChange.chronicIllness = true;
                } else {
                    prepChange.monthlyTreatment = false;
                    prepChange.bimonthlyTreatment = false;
                    prepChange.chronicIllness = false;
                }
            } else {
                prepChange[name] = e.value;
            }
        } else if (type === "cb") {
            if (name === "monthlyTreatment" || name === "bimonthlyTreatment") {
                prepChange.chronicIllness = true;
            }
            if (name === "prescriptionWithCommercialName" && !e.target.checked) {
                prepChange.prescriptionWithCommercialNameCause = "";
                prepChange.prescriptionWithCommercialNameNotes = "";
            }
            prepChange[name] = e.target.checked;
        } else if (type === "input") {
            prepChange[name] = e.target.value;
        }
        dispatch(setNewPrescription({
            ...newPrescription,
            ...prepChange,
        }));
    }

    const fetchDrugs = () => {
        setLoadingDrugs(true);
        axios.get(`${process.env.REACT_APP_API_URL2}/prescription/drugs`, {
            headers: { 'Content-Type': 'application/json' },
            params: {
                company: company.id,
                filter: drugFilter,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                setDrugs(res.data.data);
            } else {
                toast.error(res.data.message);
            }
            setLoadingDrugs(false);
        }).catch((err) => {
            setLoadingDrugs(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleICD10Check = (e, icd10) => {
        if (e.target.checked) {
            dispatch(setNewPrescription({...newPrescription, diagnoses: [...newPrescription.diagnoses, icd10]}));
        } else {
            dispatch(setNewPrescription({...newPrescription, diagnoses: newPrescription.diagnoses.filter((el) => el.id !== icd10.id)}));
        }
    }

    const handleDrugCheck = (checked, drug) => {
        if (checked) {
            setShowChooseDrugModal(false);
            setExtraInfoDrugs([...extraInfoDrugs, drug.id]);
            dispatch(setNewPrescription({...newPrescription, drugs: [...newPrescription.drugs, drug]}));
        } else {
            dispatch(setNewPrescription({...newPrescription, drugs: newPrescription.drugs.filter((el) => el.id !== drug.id)}));
        }
    }

    const getActiveSubstance = (drug) => {
        let val = "";
        if (drug.activeSubstances.activeSubstance) {
            if (Array.isArray(drug.activeSubstances.activeSubstance)) {
                val = drug.activeSubstances.activeSubstance.find((el) => el.mainActiveSubstance === "true")?.activeSubstance?.description;
            } else {
                val = drug.activeSubstances.activeSubstance.activeSubstance.description;
            }
        }
        return val;
    }

    const handleOnDrugChange = (e, type, name, rowIdx) => {
        if (type === "dd") {
            const copyDrugs = structuredClone(newPrescription.drugs);
            copyDrugs[rowIdx][name] = e.value;
            dispatch(setNewPrescription({...newPrescription, drugs: copyDrugs}));
        } else if (type === "input") {
            const copyDrugs = structuredClone(newPrescription.drugs);
            copyDrugs[rowIdx][name] = e.target.value;
            dispatch(setNewPrescription({...newPrescription, drugs: copyDrugs}));
        } else if (type === "msdd") {
            if (name === "erp_correlated_icd10s") {
                const copyDrugs = structuredClone(newPrescription.drugs);
                copyDrugs[rowIdx][name] = e.flatMap((o) => o.value);
                dispatch(setNewPrescription({...newPrescription, drugs: copyDrugs}));
            }
        }
    }

    return (
        <React.Fragment>
            {/* New Prescription Modal */}
            <Modal show={show} onHide={() => setShow(false)} backdrop={"static"} dialogClassName={"modal95PercentWidth"}>
                <Modal.Header closeButton>
                    <Modal.Title>Νέα συνταγή</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"borderedContainer mb-2"}>
                        <Row>
                            <Col md={2}>
                                <Input
                                    label={"Ημ/νια έκδοσης συνταγής"}
                                    value={moment(newPrescription.date).format("DD/MM/YYYY")}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={3}>
                                <Dropdown
                                    name={"type"}
                                    label={"Είδος Συνταγής"}
                                    options={prescriptionTypes}
                                    defaultValue={prescriptionTypes.find((el) => el.value === newPrescription.type)}
                                    key={Math.random()}
                                    onChange={(e) => handleOnChange(e, "dd", "type")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2}>
                                <Dropdown
                                    name={"repeat"}
                                    label={"Επαναλήψεις"}
                                    options={prescriptionRepeatOptions}
                                    defaultValue={prescriptionRepeatOptions.find((el) => el.value === newPrescription.repeat)}
                                    key={Math.random()}
                                    onChange={(e) => handleOnChange(e, "dd", "repeat")}
                                />
                            </Col>
                            <Col md={2}>
                                <Dropdown
                                    name={"repeatPeriod"}
                                    label={"Περίοδος Επανάληψης"}
                                    options={prescriptionRepeatPeriodOptions}
                                    defaultValue={prescriptionRepeatPeriodOptions.find((el) => el.value === newPrescription.repeatPeriod)}
                                    key={Math.random()}
                                    disabled={newPrescription.repeat === "1"}
                                    onChange={(e) => handleOnChange(e, "dd", "repeatPeriod")}
                                />
                            </Col>
                            <Col md={2}>
                                <div style={{marginTop: "30px"}}>
                                    <BetterCheckBox
                                        name={"monthlyTreatment"}
                                        text={"Θεραπεία μηνός"}
                                        checked={newPrescription.monthlyTreatment || (newPrescription.repeat === "1" && newPrescription.bimonthlyTreatment)}
                                        onChange={(e) => handleOnChange(e, "cb", "monthlyTreatment")}
                                    />
                                </div>
                            </Col>
                            <Col md={2}>
                                <div style={{marginTop: "30px"}}>
                                    <BetterCheckBox
                                        name={"bimonthlyTreatment"}
                                        text={"Δίμηνη Θεραπεία"}
                                        checked={newPrescription.bimonthlyTreatment}
                                        disabled={+newPrescription.repeat >= 2 || (newPrescription.repeat === "1" && newPrescription.monthlyTreatment)}
                                        onChange={(e) => handleOnChange(e, "cb", "bimonthlyTreatment")}
                                    />
                                </div>
                            </Col>
                            <Col md={2}>
                                <div style={{marginTop: "30px"}}>
                                    <BetterCheckBox
                                        name={"chronicIllness"}
                                        text={"Χρόνια Πάθηση"}
                                        checked={newPrescription.chronicIllness}
                                        disabled={+newPrescription.repeat >= 2 || (newPrescription.repeat === "1" && newPrescription.monthlyTreatment) || (newPrescription.repeat === "2" && newPrescription.bimonthlyTreatment)}
                                        onChange={(e) => handleOnChange(e, "cb", "chronicIllness")}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className={"mb-2"}>
                            <Col md={3}>
                                <Dropdown
                                    className={"m-0"}
                                    name={"zeroParticipationReason"}
                                    label={"Περιπτώσεις μηδενικής συμμετοχής για όλη τη συνταγή"}
                                    options={getZeroParticipationReasonOptions()}
                                    defaultValue={getZeroParticipationReasonOptions().find((el) => el.value === newPrescription.zeroParticipationReason)}
                                    key={Math.random()}
                                    onChange={(e) => handleOnChange(e, "dd", "zeroParticipationReason")}
                                />
                                {!isEmpty(newPrescription.zeroParticipationReason) && (
                                    <div><img height={"16px"} src={"assets/images/warning.png"} alt={"Warning"} /> Η συνταγή θα πρέπει να περιλαμβάνει μόνο φάρμακα για την ασθένεια με μηδενική συμμετοχή που επιλέξατε</div>
                                )}
                            </Col>
                            <Col md={3}>
                                <div style={{marginTop: "30px"}}>
                                    <BetterCheckBox
                                        name={"oneDose"}
                                        text={"Μονοδοσικά"}
                                        checked={newPrescription.oneDose}
                                        onChange={(e) => handleOnChange(e, "cb", "oneDose")}
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className={newPrescription.prescriptionWithCommercialName ? "borderedContainer" : ""} style={{marginTop: "30px"}}>
                                    <BetterCheckBox
                                        name={"prescriptionWithCommercialName"}
                                        text={"Συνταγογράφηση με εμπορική ονομασία"}
                                        checked={newPrescription.prescriptionWithCommercialName}
                                        onChange={(e) => handleOnChange(e, "cb", "prescriptionWithCommercialName")}
                                    />
                                    {newPrescription.prescriptionWithCommercialName && (
                                        <React.Fragment>
                                            <Dropdown
                                                className={"mt-0 mb-1"}
                                                name={"prescriptionWithCommercialNameCause"}
                                                label={"Αιτιολογία"}
                                                key={Math.random()}
                                                options={getNoInnCasesOptions()}
                                                defaultValue={getNoInnCasesOptions().find((el) => el.value === newPrescription.prescriptionWithCommercialNameCause)}
                                                onChange={(e) => handleOnChange(e, "dd", "prescriptionWithCommercialNameCause")}
                                            />
                                            <TextArea
                                                name={"prescriptionWithCommercialNameNotes"}
                                                label={"Σχόλια"}
                                                rows={3}
                                                value={newPrescription.prescriptionWithCommercialNameNotes}
                                                onChange={(e) => handleOnChange(e, "input", "prescriptionWithCommercialNameNotes")}
                                            />
                                        </React.Fragment>
                                    )}
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className={newPrescription.medicalOpinion ? "borderedContainer" : ""} style={{marginTop: "30px"}}>
                                    <BetterCheckBox
                                        name={"medicalOpinion"}
                                        text={"Ιατρική γνωμάτευση"}
                                        checked={newPrescription.medicalOpinion}
                                        onChange={(e) => handleOnChange(e, "cb", "medicalOpinion")}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col md={12}>
                            <div className={"borderedContainer"}>
                                <div style={{display: "flex", textAlign: "center"}}>
                                    <div className={"mb-1"} style={{fontSize: "20px"}}>Διαγνώσεις</div>
                                    <Button size={"sm"} onClick={() => setICD10Modal(true)} className={"ml-2"}>Εισαγωγή Διαγνώσεων ICD10</Button>
                                </div>
                                {newPrescription.diagnoses.length > 0 && (
                                    <React.Fragment>
                                        <table className={"simpleClassicTable mt-1"}>
                                            <colgroup>
                                                <col span={1} style={{width: "20%"}}></col>
                                                <col span={1} style={{width: "70%"}}></col>
                                                <col span={1} style={{width: "10%"}}></col>
                                            </colgroup>
                                            <thead>
                                            <tr>
                                                <th>Κωδικός</th>
                                                <th>Τίτλος</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {newPrescription.diagnoses.map((diag, idx) => (
                                                <tr key={`diagnoses-${idx}`}>
                                                    <td>{diag.code}</td>
                                                    <td>{diag.title}</td>
                                                    <td><Badge bg={"danger"}
                                                               title={"Διαγραφή"}
                                                               style={{cursor: "pointer"}}
                                                               onClick={() => handleICD10Check({target: {checked: false}}, diag)}>
                                                        <img title="Διαγραφή" src={"assets/images/callcenter/bin.png"} alt={"Bin"} />
                                                    </Badge></td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </React.Fragment>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row className={"mt-2"}>
                        <Col md={12}>
                            <div className={"borderedContainer"}>
                                <div style={{display: "flex", textAlign: "center"}}>
                                    <div className={"mb-1"} style={{fontSize: "20px"}}>Θεραπείες συνταγής</div>
                                    <Button size={"sm"} onClick={() => setShowChooseDrugModal(true)} className={"ml-2"}>Εισαγωγή Φαρμάκων</Button>
                                </div>
                                {newPrescription.drugs.length > 0 && (
                                    <React.Fragment>
                                        <table className={"simpleClassicTable mt-1"}>
                                            <colgroup>
                                                <col span={1} style={{width: "3%"}}></col>
                                                <col span={1} style={{width: "17%"}}></col>
                                                <col span={1} style={{width: "17%"}}></col>
                                                <col span={1} style={{width: "10%"}}></col>
                                                <col span={1} style={{width: "10%"}}></col>
                                                <col span={1} style={{width: "10%"}}></col>
                                                <col span={1} style={{width: "10%"}}></col>
                                                <col span={1} style={{width: "10%"}}></col>
                                                <col span={1} style={{width: "10%"}}></col>
                                                <col span={1} style={{width: "3%"}}></col>
                                            </colgroup>
                                            <thead>
                                            <tr>
                                                <th></th>
                                                <th>Δραστική Ουσία</th>
                                                <th>Προτεινόμενη Θεραπεία</th>
                                                <th>Ποσότητα</th>
                                                <th>Συμμετοχή (%)</th>
                                                <th>Ποσό Δόσης</th>
                                                <th>Μονάδα Δόσης</th>
                                                <th>Συχνότητα</th>
                                                <th>Ημέρες</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {newPrescription.drugs.map((drug, idx) => (
                                                <React.Fragment>
                                                    <tr key={`drugsTable-${idx}`}>
                                                        {extraInfoDrugs.includes(drug.id) ? (
                                                            <td><Badge bg={"dark"} style={{cursor: "pointer"}} onClick={() => setExtraInfoDrugs(extraInfoDrugs.filter((el) => el !== drug.id))}>-</Badge></td>
                                                        ) : (
                                                            <td><Badge bg={"dark"} style={{cursor: "pointer"}} onClick={() => setExtraInfoDrugs([...extraInfoDrugs, drug.id])}>+</Badge></td>
                                                        )}
                                                        <td>{getActiveSubstance(drug)}</td>
                                                        <td>{drug.formCode} x {drug.content} x {drug.packageForm}</td>
                                                        <td>
                                                            <Dropdown
                                                                name={"erp_drugAmount"}
                                                                className={"m-0"}
                                                                options={prescriptionDrugAmountOptions}
                                                                key={Math.random()}
                                                                defaultValue={prescriptionDrugAmountOptions.find((el) => el.value === drug.erp_drugAmount)}
                                                                onChange={(e) => handleOnDrugChange(e, "dd", "erp_drugAmount", idx)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Dropdown
                                                                name={"erp_participationPercentage"}
                                                                className={"m-0"}
                                                                options={prescriptionParticipationPercentageOptions}
                                                                key={Math.random()}
                                                                defaultValue={prescriptionParticipationPercentageOptions.find((el) => el.value === drug.erp_participationPercentage)}
                                                                onChange={(e) => handleOnDrugChange(e, "dd", "erp_participationPercentage", idx)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Dropdown
                                                                name={"erp_doseAmount"}
                                                                className={"m-0"}
                                                                options={prescriptionDoseAmountOptions}
                                                                key={Math.random()}
                                                                defaultValue={prescriptionDoseAmountOptions.find((el) => el.value === drug.erp_doseAmount)}
                                                                onChange={(e) => handleOnDrugChange(e, "dd", "erp_doseAmount", idx)}
                                                            />
                                                        </td>
                                                        <td>
                                                            {drug.doseUnit}
                                                        </td>
                                                        <td>
                                                            <Dropdown
                                                                name={"erp_frequency"}
                                                                className={"m-0"}
                                                                options={prescriptionFrequencyOptions}
                                                                key={Math.random()}
                                                                defaultValue={prescriptionFrequencyOptions.find((el) => el.value === drug.erp_frequency)}
                                                                onChange={(e) => handleOnDrugChange(e, "dd", "erp_frequency", idx)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Dropdown
                                                                name={"erp_days"}
                                                                className={"m-0"}
                                                                options={daysAmount}
                                                                key={Math.random()}
                                                                defaultValue={daysAmount?.find((el) => el.value === drug.erp_days)}
                                                                onChange={(e) => handleOnDrugChange(e, "dd", "erp_days", idx)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Badge bg={"danger"}
                                                                   title={"Διαγραφή"}
                                                                   style={{cursor: "pointer"}}
                                                                   onClick={() => handleDrugCheck(false, drug)}>
                                                                <img title="Διαγραφή" src={"assets/images/callcenter/bin.png"} alt={"Bin"} />
                                                            </Badge>
                                                        </td>
                                                    </tr>
                                                    {extraInfoDrugs.includes(drug.id) && (
                                                        <tr key={`drugsTable-${idx}-ei`} style={{textAlign: "left"}}>
                                                            <td colSpan={10} style={{backgroundColor: "#f5f5f5"}} className={"p-1"}>
                                                                <Row>
                                                                    <Col md={4}>
                                                                        <TextArea
                                                                            name={"erp_notes"}
                                                                            label={"Σχόλια"}
                                                                            rows={3}
                                                                            value={drug.erp_notes}
                                                                            limit={100}
                                                                            required={true}
                                                                            onChange={(e) => handleOnDrugChange(e, "input", "erp_notes", idx)}
                                                                        />
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <Dropdown
                                                                            name={"erp_correlated_icd10s"}
                                                                            label={"Συσχέτιση με διαγνώσεις"}
                                                                            key={Math.random()}
                                                                            multiSelect={true}
                                                                            required={true}
                                                                            options={getMultiselectICD10sOptions(newPrescription.diagnoses)}
                                                                            defaultValue={getMultiselectICD10sOptions(newPrescription.diagnoses).filter((el) => drug.erp_correlated_icd10s?.includes(el.value))}
                                                                            onChange={(e) => handleOnDrugChange(e, "msdd", "erp_correlated_icd10s", idx)}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                            </tbody>
                                        </table>
                                    </React.Fragment>
                                )}
                            </div>
                        </Col>
                    </Row>
                    {!isEmpty(lastError) && (
                        <Row className={"mt-2"}>
                            <Col md={12} style={{fontSize: "16px", fontWeight: "600"}}>
                                <div className={"text-danger"}>Τελευταίο μήνυμα σφάλματος:</div>
                                <div className={"text-danger"}>{lastError}</div>
                            </Col>
                        </Row>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleSavePrescription()} disabled={loading}>
                        Ολοκλήρωση {loading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Prescription Choose Drug Modal */}
            <Modal show={showChooseDrugModal} onHide={() => setShowChooseDrugModal(false)} dialogClassName={"modal95PercentWidth"}>
                <Modal.Header closeButton>
                    <Modal.Title>Αναζήτηση Φαρμάκων</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <div className={"text-muted mb-1"}><i>Αναζήτηση Φαρμάκων</i></div>
                            <div style={{display: "flex", gap: "10px", width: "40%"}} onKeyDown={(e) => {
                                if (e.key === "Enter") fetchDrugs();
                            }}>
                                <div style={{ flex: "6" }}>
                                    <Input
                                        name={"drugFilter"}
                                        unlabbled={true}
                                        className={"m-0"}
                                        placeholder={"Αναζήτηση Φαρμάκου"}
                                        value={drugFilter}
                                        onChange={(e) => setDrugFilter(e.target.value)}
                                    />
                                </div>
                                <Button size={"sm"} onClick={() => fetchDrugs()} style={{ flex: "4" }} disabled={loadingDrugs}>
                                    Αναζήτηση
                                    {loadingDrugs && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                                </Button>
                            </div>
                            {drugs.length > 0 && (
                                <React.Fragment>
                                    <table className={"simpleClassicTable mt-1"}>
                                        <thead>
                                        <tr>
                                            <th>Ενέργειες</th>
                                            <th>Barcode</th>
                                            <th>Εμπορική Ονομασία</th>
                                            <th>Μορφή</th>
                                            <th>Περιεκτηκότητα</th>
                                            <th>Συσκευασία</th>
                                            <th>Τύπος</th>
                                            <th>ATC</th>
                                            <th>Δραστική Ουσία</th>
                                            <th>Τιμή Λιανικής</th>
                                            <th>Τιμή Αποζ. Ασφ.</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {drugs.map((item, idx) => (
                                            <tr key={`drugsTableSearch-${idx}`}>
                                                <td>
                                                    {newPrescription.drugs.some((el) => el.id === item.id) ? (
                                                        <Badge bg={"danger"} title={"Αφαίρεση"} style={{padding: "6px", paddingTop: "2px", paddingBottom: "2px", cursor: "pointer", fontSize: "20px"}}
                                                               onClick={() => handleDrugCheck(false, item)}>-</Badge>
                                                    ) : (
                                                        <Badge bg={"success"} title={"Προσθήκη"} style={{padding: "6px", paddingTop: "2px", paddingBottom: "2px", cursor: "pointer", fontSize: "20px"}}
                                                               onClick={() => handleDrugCheck(true, item)}>+</Badge>
                                                    )}
                                                </td>
                                                <td>{item.barcode}</td>
                                                <td>{item.commercialName}</td>
                                                <td>{item.formCode}</td>
                                                <td>{item.contentInternal}</td>
                                                <td>{item.packageForm}</td>
                                                <td>{item.medicineTypeAll}</td>
                                                <td>{item.atcCode}</td>
                                                <td>{getActiveSubstance(item)}</td>
                                                <td>{item.retailPrice}</td>
                                                <td>{item.referencePrice}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </React.Fragment>
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <ICD10Modal
                show={icd10modal}
                setShow={setICD10Modal}
                diagnoses={newPrescription.diagnoses}
                handleCheck={(e, item) => handleICD10Check(e, item)}
                backButtonMessage={"Πίσω στην συνταγή"}
            />
        </React.Fragment>
    )
}

export default NewPrescriptionModal;
