import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Input from "../../../../common/Input";
import Button from "react-bootstrap/Button";
import {useSelector} from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";
import {Badge, Spinner} from "react-bootstrap";

const ExamsModal = ({show, setShow, examType, handleAdd, backButtonMessage}) => {
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const [exams, setExams] = useState(false);
    const [filter, setFilter] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (show) fetchExams();
    }, [show])

    const fetchExams = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL2}/prescription/examinations`, {
            headers: { 'Content-Type': 'application/json' },
            params: {
                company: company.id,
                filter: filter,
                examinationGroupId: examType,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                const sorted = res.data.data.sort((a, b) => String(a.description).localeCompare(b.description, "el")) || [];
                for (let item of sorted) item.erp_amount = "";
                setExams(sorted);
            } else {
                toast.error(res.data.message);
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    return (
        <React.Fragment>
            {/* Exams Modal */}
            <Modal show={show} onHide={() => setShow(false)} dialogClassName={"modal42PercentWidth"}>
                <Modal.Header closeButton>
                    <Modal.Title>Εισαγωγή εξετάσεων</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{display: "flex", gap: "10px"}} onKeyDown={(e) => {
                        if (e.key === "Enter") fetchExams();
                    }}>
                        <div style={{ flex: "6" }}>
                            <Input
                                name={"filter"}
                                unlabbled={true}
                                value={filter}
                                placeholder={"Αναζήτηση εξετάσεων"}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                        </div>
                        <Button style={{ flex: "4" }} size={"sm"} disabled={loading} onClick={() => fetchExams()}>
                            Αναζήτηση {loading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                        </Button>
                    </div>
                    {exams.length > 0 && (
                        <div style={{maxHeight: "60vh", overflowY: "auto"}} className={"mt-2"}>
                            <table className={"simpleClassicTable"}>
                                <colgroup>
                                    <col span={1} style={{width: "65%"}}></col>
                                    <col span={1} style={{width: "10%"}}></col>
                                    <col span={1} style={{width: "15%"}}></col>
                                    <col span={1} style={{width: "10%"}}></col>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th>Εξέταση</th>
                                    <th>Τιμή</th>
                                    <th>Ποσότητα</th>
                                    <th>&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                {exams.map((item, idx) => (
                                    <tr key={`examsModal-${idx}`}>
                                        <td>{item.description}</td>
                                        <td>{item.price}</td>
                                        <td><input type={"number"} onChange={(e) => {
                                            const cloneExams = structuredClone(exams);
                                            cloneExams[idx].erp_amount = +e.target.value;
                                            setExams(cloneExams);
                                        }} style={{display: "flex", width: "100%"}} /></td>
                                        <td>
                                            <Badge bg={"success"} title={"Προσθήκη"} style={{padding: "6px", paddingTop: "2px", paddingBottom: "2px", cursor: "pointer", fontSize: "20px"}}
                                                   onClick={() => {
                                                       let val = 1;
                                                       if (+item.erp_amount) val = +item.erp_amount;
                                                       handleAdd(item, val)
                                                   }}>+</Badge>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" size={"sm"} onClick={() => setShow(false)}>
                        {backButtonMessage}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default ExamsModal
