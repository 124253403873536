import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

const currentTime = moment().format("HH:mm");

const newBuysInitialValue = {
    documentType: "",
    documentTypeName: "",
    myDataType: "",
    date: null,
    time: currentTime,
    customTime: "false",
    installation: "",
    preNumber: "",
    numberingMode: "manual",
    number: "",
    corelatedDocument: "",
    supplier: "",
    supplierName: "",
    employee: "",
    employeeName: "",
    supplierInstallationId: 0,
    supplierInstallationType: "",
    supplierCode: "",
    newSupplier: "",
    email: "",
    phone: "",
    supplierType: "Existing", // Existing or New
    vat: "",
    address: "",
    doy: "",
    city: "",
    pc: "",
    paymentType: "",
    paymentTypeName: "",
    myDataPaymentId: "",
    bankAccount: "",
    buyPurpose: "",
    products: [],
    extraData: {
        wayOfSendingGoods: "",
        wayOfSendingGoodsName: "",
        carNumber: "",
        carNumberName: "",
        goodsSendDate: "",
        otherTax: "",
        otherTaxEuro: "",
        withHoldTax: "",
        withHoldTaxEuro: "",
        fee: "",
        feeEuro: "",
        stamp: "",
        stampEuro: "",
        deliveryLocation: "companyLocation",
        locationAddress: "",
        locationCity: "",
        locationCountry: "",
        locationZip: "",
        cashRegister: "",
    },
    notes: "",
    buysTaxes: [],
    totalQuantity: 0,
    totalBeforeDiscount: 0,
    totalDiscount: 0,
    totalNetValue: 0,
    totalVatValue: 0,
    totalAmount: 0,
    paymentAmount: 0,
    sendMailOnSave: "inactive",
    myDataStatus: "not_sent",
    transmissionFailure: "",
    documentCategory: "Initial Document"
}

const newBuysNewProductInitialValue = {
    no: "",
    id: 0,
    code: "",
    name: "",
    quantity: 1,
    mu: "",
    unitPrice: "",
    discount: "",
    isPerDiscount: "false",
    isDiscountAmount: "true",
    fee: "",
    feeEuro: "",
    otherTax: "",
    otherTaxEuro: "",
    stamp: "",
    stampEuro: "",
    withHoldTax: "",
    withHoldTaxEuro: "",
    taxes: "",
    vatPercentage: "",
    vatId: "",
    vatReason: "",
    vatType: "",
    vat: "",
    netValue: "",
    totalValue: "",
    _id: ""
}

const initialState = {
    buys: [],
    searchBuys: [],
    totalItemsBS: 0,
    perPageBS: 0,
    buysTemplates: [],
    newBuysInitialValue: newBuysInitialValue,
    newBuys: newBuysInitialValue,
    newBuysNewProductInitialValue: newBuysNewProductInitialValue,
    newBuysNewProduct: newBuysNewProductInitialValue,
    editBuys: newBuysInitialValue,
    editBuysNewProductInitialValue: newBuysNewProductInitialValue,
    editBuysNewProduct: newBuysNewProductInitialValue,
    corelatedDocumentsList: [],

    newBuySupplierCustomData: {
        supplierVatCategory: "Normal VAT",
        supplierVatReason: "",
        supplierCustomVat: 0,
    },

    editBuySupplierCustomData: {
        supplierVatCategory: "Normal VAT",
        supplierVatReason: "",
        supplierCustomVat: 0,
    },

    // Buys Balance Report
    buysBalanceReportRequestData: {
        firstLoad: true,
        page: 1,
        date_from: "",
        date_to: "",
        supplierName: "",
        invoiceType: [],
        paymentType: [],
        specialFilter: [],
        carNumber: [],
        amountFrom: "",
        amountTo: "",
        preNumberFrom: "",
        preNumberTo: "",
        numberFrom: "",
        numberTo: "",
        notes: "",

        totalPages: 0,
        totalVatValue: 0,
        totalNetValue: 0,
        totalValue: 0,
        totalNonDeductibleVAT: 0,
    },
    buysBalanceReportData: [],

    buysMonthlyTurnoverReportRequestData: {
        page: 1,
        date_from: "",
        date_to: "",
        supplierName: "",
    },
    buysMonthlyTurnoverReportData: {
        totalPages: 0,
        data: [],
        totalPaymentAmount: 0,
    },

    // Buys My Data Report
    myDataReportRequestData: {
        date_from: moment().startOf("month").format("DD/MM/YYYY"),
        date_to: moment().endOf("month").format("DD/MM/YYYY"),
        supplierVAT: "",
        number_from: "",
        number_to: "",
        invoiceType: [],
        totalSum_from: "",
        totalSum_to: "",
        reportType: "buys",
        page: 1,
        totalPages: 0,
        lastUpdate: "",
        classified: "",
    },
    myDataReportTotals: {
        totalNetValue: 0,
        totalVatValue: 0,
        totalValue: 0,
        totalCount: 0,
        totalNetValueMyData: 0,
        totalVatValueMyData: 0,
        totalValueMyData: 0,
        totalCountMyData: 0,
    },

    // Supplier Payments Report
    supplierPaymentsReportRequestData: {
        firstLoad: true,
        date_from: "",
        date_to: "",
        amountFrom: "",
        amountTo: "",
        page: 1,
        supplierName: "",
        invoiceType: [],
        paymentType: [],
    },
    supplierPaymentsReportResponseData: {
        totalPages: 0,
        data: [],
        totalPaymentAmount: 0,
    },

    // Buys Graphic Report
    buysGraphicReportRequestData: {
        firstLoad: true, // For date setup
        date_from: "",
        date_to: "",
        supplierId: "",
        supplierName: "",
        paymentType: [],
    },
    buysGraphicReportResponseData: {
        data: [],
    }
}

export const BuysSlice = createSlice({
    name: "Buys",
    initialState,
    reducers: {
        setNewBuys: (state, {payload}) => {
            state.newBuys = payload;
        },
        setNewBuysNewProduct: (state, {payload}) => {
            state.newBuysNewProduct = payload;
        },
        setNewBuysExtraData: (state, {payload}) => {
            state.newBuys.extraData = {...state.newBuys.extraData, ...payload};
        },
        resetNewBuys: (state) => {
            state.newBuys = state.newBuysInitialValue;
        },
        setBuysTemplates: (state, {payload}) => {
            state.buysTemplates = payload;
        },
        setEditBuys: (state, {payload}) => {
            state.editBuys = payload;
        },
        setEditBuysNewProduct: (state, {payload}) => {
            state.editBuysNewProduct = payload;
        },
        setSearchBuys: (state, {payload}) => {
            state.searchBuys = payload;
        },
        setTotalItemsBS: (state, {payload}) => {
            state.totalItemsBS = payload;
        },
        setPerPageBS: (state, {payload}) => {
            state.perPageBS = payload;
        },
        setCorelatedDocumentsList: (state, {payload}) => {
            state.corelatedDocumentsList = payload;
        },
        setNewBuySupplierCustomData: (state, {payload}) => {
            state.newBuySupplierCustomData = payload;
        },
        setEditBuySupplierCustomData: (state, {payload}) => {
            state.editBuySupplierCustomData = payload;
        },
        setBuysMonthlyTurnoverReportData: (state, {payload}) => {
            state.buysMonthlyTurnoverReportData = payload;
        },
        setBuysMonthlyTurnoverReportRequestData: (state, {payload}) => {
            state.buysMonthlyTurnoverReportRequestData = payload;
        },

        // Buys Balance Sheet
        setBuysReportRequestData: (state, {payload}) => {
            state.buysBalanceReportRequestData = payload;
        },
        setBuysReportData: (state, {payload}) => {
            state.buysBalanceReportData = payload;
        },

        // Buys My Data Report
        setBuysMyDataReportRequestData: (state, {payload}) => {
            state.myDataReportRequestData = payload;
        },
        setBuysMyDataReportTotals: (state, {payload}) => {
            state.myDataReportTotals = payload;
        },

        // Supplier Payments Report
        setSupplierPaymentsReportRequestData: (state, {payload}) => {
            state.supplierPaymentsReportRequestData = payload;
        },
        setSupplierPaymentsReportResponseData: (state, {payload}) => {
            state.supplierPaymentsReportResponseData = payload;
        },

        // Buys Graphic Report
        setBuysGraphicReportRequestData: (state, {payload}) => {
            state.buysGraphicReportRequestData = payload;
        },
        setBuysGraphicReportResponseData: (state, {payload}) => {
            state.buysGraphicReportResponseData = payload;
        }
    },
});

export const {
    setNewBuys,
    setBuysTemplates,
    resetNewBuys,
    setNewBuysExtraData,
    setNewBuysNewProduct,
    setSearchBuys,
    setPerPageBS,
    setTotalItemsBS,
    setEditBuys,
    setEditBuysNewProduct,
    setNewBuySupplierCustomData,
    setEditBuySupplierCustomData,

    setBuysMonthlyTurnoverReportData,
    setBuysMonthlyTurnoverReportRequestData,

    // Buys Balance sheet
    setBuysReportRequestData,
    setBuysReportData,

    // Buys My Data Report
    setBuysMyDataReportRequestData,
    setBuysMyDataReportTotals,

    // Supplier Payments Report
    setSupplierPaymentsReportRequestData,
    setSupplierPaymentsReportResponseData,

    // Buys Graphic Report
    setBuysGraphicReportRequestData,
    setBuysGraphicReportResponseData
} = BuysSlice.actions;
