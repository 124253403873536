import React, {useEffect, useState} from "react";
import {Badge, Button, CloseButton, Col, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import "./callcenter-style.css";
import moment from "moment";
import {endTelephoneSupport} from "../../../../_apis/api";
import {toast} from "react-toastify";
import {setEditClient, setNewClient} from "../../../../_reducers/DataPanel/Clients/clientSlice";
import {addTab, updateActiveMainLink, updateActiveMainTab} from "../../../../_reducers/TabsSlice";
import Input from "../../../common/Input";
import Dropdown from "../../../primer/Dropdown";
import {checkPermission, getOptionsByCommaString, isEmpty} from "../../../../_helpers/commonFunctions";
import TextArea from "../../../common/TextArea";
import {Popconfirm} from "antd";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import "./../../../primer/POPUP-COMPONENTS/modalWidths.css";

export const CallCenterLive = () => {
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const dispatch = useDispatch();
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const CLIENTS_DATA = useSelector( (state) => state.CLIENTS_DATA);
    const isEmronConnectFileERP = useSelector((state) => state.ACTIVITY.isEmronConnectFileERP);
    const telephoneId = JSON.parse(localStorage.getItem("user"))?.telephoneId;
    const userid = JSON.parse(localStorage.getItem("user"))?._id;
    const [showSubscriptions, setShowSubscriptions] = useState(false);
    const [subscriptionClientData, setSubscriptionClientData] = useState({});
    const [disableRefreshSubscriptionClientData, setDisableRefreshSubscriptionClientData] = useState(false);
    const [hideSubsRefresh, setHideSubRefresh] = useState(false);
    const permissions = localStorage.getItem("permissions");
    const permissionsData = JSON.parse(permissions);

    const [activeCalls, setActiveCalls] = useState([]);
    const [oldCalls, setOldCalls] = useState([]);
    const [endpoints, setEndpoints] = useState([]);
    const [mailbox, setMailbox] = useState([]);

    const [showClientCommunicationModal, setShowClientCommunicationModal] = useState(false);
    const [clientCommunicationData, setClientCommunicationData] = useState({}); // For the modal

    const [subjectOptions, setSubjectOptions] = useState([]);
    const [requestSubjectAndCommNo, setRequestSubjectsAndCommNo] = useState(false);

    const [show, setShow] = useState(false);
    const [isSupporting, setIsSupporting] = useState(false);
    const [supportStartTime, setSupportStartTime] = useState(null); //moment
    const [supportingClient, setSupportingClient] = useState(null); // object with 2 keys: client (whole obj) subIndex: the index of subscriptions subarray
    const [supportEndTime, setSupportEndTime] = useState(null); //moment
    const [showSupportEndModal, setShowSupportEndModal] = useState(false);

    const [callCenterAutoOpen, setCallCenterAutoOpen] = useState(true);

    const [enabled, setEnabled] = useState(true);
    const [intervalKey, setIntervalKey] = useState(0);

    const [showCorrelate, setShowCorrelate] = useState(false);
    const [selectedClient, setSelectedClient] = useState({
        _id: "",
        name: "",
    })
    const [clientsListData, setClientsListData] = useState([]);
    const defaultCP = {
        name: "",
        mobile: "",
        landline: "",
        otherPhone: "",
        email: "",
        contactNotes: "",
        acceptsNotifications: "false",
    }
    const [newContactPoint, setNewContactPoint] = useState({...defaultCP});

    const clientDropdownColumns = [
        {
            label: "Όνομα",
            name: "name",
        },
        {
            label: "ΑΦΜ",
            name: "vatNumber",
        },
        {
            label: "Τηλέφωνο",
            name: "phone",
        },
    ];

    useEffect(() => {
        if (showCorrelate) {
            loadClientsListData(null, setClientsListData);
        } else {
            setSelectedClient({_id: "", name: ""});
            setNewContactPoint({...defaultCP});
        }
    }, [showCorrelate]);

    useEffect(() => {
        if (!isEmronConnectFileERP) {
            if (enabled) {
                if (!intervalKey) {
                    const key = setInterval(() => {
                        fetchCalls();
                    }, 900)
                    setIntervalKey(key);
                }
            } else {
                if (intervalKey) {
                    clearInterval(intervalKey);
                    setIntervalKey(0);
                }
            }
        }
    }, [enabled])

    useEffect(() => {
        if(!isTalking() && telephoneId) {
            if(isSupporting) {
                setIsSupporting(false);
                endSupport();
            }
            setShowSubscriptions(false);
        }
    })

    useEffect(() => {
        if(requestSubjectAndCommNo) {
            setRequestSubjectsAndCommNo(false);
            requestSubjectsAndCommNo();
        }
    }, [clientCommunicationData])

    useEffect(() => {
        if(isRingingToAgent() && !show && callCenterAutoOpen) {
            setCallCenterAutoOpen(false);
            setShow(true);
        }
    })

    useEffect(() => {
        if(!isRingingToAgent() && !callCenterAutoOpen) {
            setCallCenterAutoOpen(true);
        }
    });

    useEffect(() => {
        if (!showCorrelate) {
            setNewContactPoint({...defaultCP});
        }
    }, [showCorrelate])

    const callcenterClicked = () => {
        setShow(!show);
        if (show) setShowSubscriptions(false);
    }

    const subscriptionsClicked = (clientData) => {
        if(!showSubscriptions) {
            setShowSubscriptions(true);
        }
        setSubscriptionClientData(clientData);
    }

    const loadClientsListData = (name, setData) => {
        let reqData = {
            company: company.id,
        }
        if (!isEmpty(name)) reqData.name = name;

        axios.post(process.env.REACT_APP_API_URL2 + `/client/list-client`, reqData, {
            headers: { "Content-Type": "application/json" }
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                if (res.data.data) {
                    let filteredData = res.data.data;
                    if (setData) setData(filteredData);
                } else {
                    if (setData) setData([]);
                }
            } else {
                if (setData) setData([]);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const onChangeSearch = (keyword) => {
        if (keyword.length >= 2) loadClientsListData(keyword, setClientsListData);
    }

    const refreshClientSubscriptionData = (showToast) => {
        if(subscriptionClientData._id) {
            setDisableRefreshSubscriptionClientData(true);
            axios.get(process.env.REACT_APP_API_URL2 + "/callcenter/get-client-by-id", {
                headers: {"Content-Type": "application/json"},
                params: {
                    clientId: subscriptionClientData._id
                }
            }).then((res) => {
                if(res.data.status === "200") {
                    setSubscriptionClientData(res.data.data);
                    if(showToast) toast.success("Επιτυχής ανανέωση");
                    setDisableRefreshSubscriptionClientData(false);
                } else {
                    if(showToast) toast.error("Σφάλμα κατά την ανανέωση.");
                    setDisableRefreshSubscriptionClientData(false);
                }
            }).catch(() => {
                if(showToast) toast.error("Σφάλμα κατά την ανανέωση.");
                setDisableRefreshSubscriptionClientData(false);
            })
        }
    }

    const fetchCalls = () => {
        axios.post(process.env.REACT_APP_API_URL2 + "/callcenter/conn", {
            company: company.id,
            userid: userid
        }, {
            headers: {"Content-Type": "application/json"}
        }).then((response) => {
            if (response.data.status === "200") {
                setActiveCalls(response.data.activeCalls);
                setOldCalls(response.data.oldCalls);
                setEndpoints(response.data.endpoints);
                setMailbox(response.data?.mailbox);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const formatStatus = (status) => {
        if(status === "Dialing") {
            return <Badge className="mt-1" bg="primary">Χτυπάει...</Badge>
        } else if (status === "Hold") {
            return <Badge className="mt-1" bg="secondary">Σε αναμονή</Badge>
        } else if (status === "Pickup") {
            return <Badge className="mt-1" bg="success">Επικοινωνεί</Badge>
        } else if (status === "Hangup") {
            return <Badge className="mt-1" bg="danger">Τελείωσε</Badge>
        } else if (status === "No answer") {
            return <Badge className="mt-1" bg="danger">Δεν απάντησε</Badge>
        } else if (status === "Failed") {
            return <Badge className="mt-1" bg="danger">Απέτυχε</Badge>
        } else if (status === "Forwarded") {
            return <Badge className="mt-1" bg="danger">Προωθήθηκε</Badge>
        } else {
            return <Badge className="mt-1" bg="primary">{status}</Badge>
        }
    }

    const getStatusIcon = (tel, type) => {
        if(type === "live") {
            const isOutgoing = endpoints.includes(String(tel));
            if (isOutgoing) {
                return <img className="ml-1" src={"assets/images/callcenter/call_outgoing.png"} alt={"Outgoing call"} />
            } else {
                return <img className="ml-1" src={"assets/images/callcenter/call_incoming.png"} alt={"Incoming cal"}/>
            }
        } else if (type === "old") {
            return <img className="ml-1" src={"assets/images/callcenter/call_ended.png"} alt={"Call ended"} />
        }
    }

    const formatPhone = (phone) => {
        if(String(phone).length === 3) {
            return phone;
        } else {
            if(String(phone).startsWith("0") || String(phone).startsWith("1")) {
                return phone.slice(1);
            } else {
                return phone;
            }
        }
    }

    const formatCallerName = (name) => {
        if(!isNaN(name)) {
            return <span></span>
        } else {
            if(name?.includes("CID") || name?.includes("<unknown>")) {
                return <span></span>
            } else {
                return <span>({name})</span>
            }
        }
    }

    const toMinutesSeconds = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds - 60*minutes;
        return <>{minutes > 0 ? `${minutes} ${minutes === 1 ? "λεπτό" : "λεπτά"} και ` : ""}{seconds === 1 ? "1 δευτερόλεπτο" : ` ${seconds} δευτερόλεπτα`}</>
    }

    const timeAgo = (time) => {
        const totalMinutes = moment().diff(time, "minutes");
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes - 60*hours;
        return <>{hours > 0 ? `${hours} ${hours === 1 ? "ώρα" : "ώρες"} και ` : ""}{minutes === 1 ? "1 λεπτό πριν" : ` ${minutes} λεπτά πριν`}</>
    }

    const secondsToMinutesSeconds = (seconds) => {
        const totalMinutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds - 60*totalMinutes;
        if(String(remainingSeconds).length === 1) {
            return <>{totalMinutes}{":"}{"0" + remainingSeconds}</>
        } else {
            return <>{totalMinutes}{":"}{remainingSeconds}</>
        }
    }

    const startSupport = (client, sub) => {
        setIsSupporting(true);
        setSupportStartTime(moment());
        setSupportingClient({
            client: client,
            sub: sub,
        })
    }

    const endSupport = () => {
        setShowSupportEndModal(true);
        const endTime = moment();
        setSupportEndTime(endTime);
    }

    const endSupportFinal = (save) => {
        setShowSupportEndModal(false);
        setIsSupporting(false);
        if(save) {
            const supportTime = supportEndTime.diff(supportStartTime, "seconds");
            dispatch(endTelephoneSupport(company.id, supportingClient?.client, supportingClient?.sub, supportTime));
        } else {
            toast.success("Η υποστήριξη έλειξε αλλά η χρονοχρέωση ΔΕΝ αποθηκεύτηκε.");
        }
        setSupportStartTime(null);
        setSupportEndTime(null);
        setSupportingClient(null);
        if(showSubscriptions) {
            refreshClientSubscriptionData(false);
        }
    }

    const getContactPoint = (client, tel1, tel2) => { // returns object with companyName, name, email (if found), vatNumber
        let returnObj = {};
        let toSearch = whoIsClient(tel1, tel2);
        if(toSearch) {
            returnObj.companyName = client.name;
            returnObj.vatNumber = client.vatNumber;
            let foundClient = false;
            client.contactPoints?.forEach((cPoint) => {
                if (String(cPoint.landline).includes(toSearch) || String(cPoint.mobile).includes(toSearch) || String(cPoint.otherPhone).includes(toSearch)) {
                    returnObj.name = cPoint.name;
                    returnObj.email = cPoint.email;
                    foundClient = true;
                }
            })
            if (!foundClient) {
                if (client.phone === toSearch) {
                    returnObj.email = client.email;
                }
            }
        }
        return returnObj;
    }

    const isExpired = (subObj) => {
        return moment(subObj.endDate) < moment();
    }

    const getRemainingSupportTime = (clientSubscriptions, sub) => {
        let totalTime = 0;
        if(clientSubscriptions && clientSubscriptions?.length > 0) {
            clientSubscriptions.filter((el) => el.type === "timeCharge"
                && el.mappedProductForCharging === sub.mappedProduct
                && moment() < moment(el.endDate)).forEach((timeCharge) => {

                totalTime += Number(timeCharge.minutesTime);
            })
        }
        return String(totalTime);
    }

    const refreshSubscriptionsClicked = () => {
        if (company.vatNumber === "800566476") {
            setHideSubRefresh(true);
            toast.info("Γίνεται αποστολή αιτήματος ανανέωσης συνδρομών...");
            axios.post(process.env.REACT_APP_API_URL2 + "/callcenter/initcronjob", {company: company.id, userid: userid}, {
                headers: {"Content-Type": "application/json"}
            }).then((response) => {
                if(response.data.status === "200") {
                    toast.success("Η ανανέωση συνδρομών έγινε επιτυχώς.");
                    setHideSubRefresh(false);
                } else if (response.data.status === "423") {
                    toast.info("Η ανανέωση συνδρομών τρέχει ήδη αυτήν την στιγμή, δοκιμάστε αργότερα.");
                    setHideSubRefresh(false);
                }
            }).catch((error) => {
                toast.error("Σφάλμα αιτήματος.");
                console.log(error);
                setHideSubRefresh(false);
            })
        } else if (company.vatNumber === "800434990") {
            setHideSubRefresh(true);
            toast.info("Γίνεται αποστολή αιτήματος ανανέωσης συνδρομών...");
            axios.post(process.env.REACT_APP_API_URL2 + '/emron/getEmronWooOrders', {userid: userid}, {
                headers: {"Content-Type": "application/json"}
            }).then((response) => {
                if(response.data.status === "200") {
                    toast.success("Η ανανέωση συνδρομών έγινε επιτυχώς.");
                    setHideSubRefresh(false);
                } else if (response.data.status === "423") {
                    toast.info("Η ανανέωση συνδρομών τρέχει ήδη αυτήν την στιγμή, δοκιμάστε αργότερα.");
                    setHideSubRefresh(false);
                } else {
                    toast.error(`Σφάλμα: ${response.data.message}`);
                    setHideSubRefresh(false);
                }
            }).catch((error) => {
                toast.error("Σφάλμα αιτήματος.");
                console.log(error);
                setHideSubRefresh(false);
            })
        }
    }

    const callIsBetweenAgents = (tel1, tel2) => {
        return endpoints.includes(String(tel1)) && endpoints.includes(String(tel2))
    }

    const isTalking = () => {
        if(telephoneId) {
            const splitted = String(telephoneId).split(",")
            return activeCalls.filter((el) => (splitted.includes(el.CallerIDNum) || splitted.includes(el.ConnectedLineNum)) && (el.status === "Pickup" || el.status === "Hold")).length > 0;
        } else {
            return false
        }
    }

    const isRinging = () => {
        if(telephoneId) {
            const splitted = String(telephoneId).split(",")
            return activeCalls.filter((el) => (splitted.includes(el.CallerIDNum) || splitted.includes(el.ConnectedLineNum)) && el.status === "Dialing").length > 0;
        } else {
            return false;
        }
    }

    const isRingingToAgent = () => {
        if(telephoneId) {
            const splitted = String(telephoneId).split(",")
            return activeCalls.filter((el) => splitted.includes(el.ConnectedLineNum) && el.status === "Dialing").length > 0;
        } else {
            return false;
        }
    }

    const whoIsClient = (tel1, tel2) => {
        if(!endpoints.includes(String(tel1))) {
            return String(tel1);
        } else if (!endpoints.includes(String(tel2))) {
            return String(tel2);
        } else {
            return null;
        }
    }

    const clientCardClicked = (clientObj, goToCommunication) => {
        setShow(false);
        dispatch(setEditClient(clientObj));
        if(showSubscriptions) setShowSubscriptions(false);
        const idx = TABS_DATA.findIndex((item) => "edit-client" === item);
        if(idx === -1) {
            dispatch(addTab("edit-client"));
            dispatch(updateActiveMainTab(TABS_DATA.length));
        } else {
            dispatch(updateActiveMainTab(idx));
        }
        if(goToCommunication === true) dispatch(updateActiveMainLink(2));
    }

    const clientCommunicationClicked = (client) => {
        setShowClientCommunicationModal(true);
        setClientCommunicationData({
            client: client,
            //no, id will be ignored
            date: moment(),
            time: moment().format("HH:mm"),
            followUpDate: moment(), // will be ignored
            details: "",
            createCalendarEvent: "false",
            done: "false", // will be ignored
        })
        setRequestSubjectsAndCommNo(true);
    }

    const closeClientCommunicationModal = () => {
        setShowClientCommunicationModal(false);
        setClientCommunicationData({});
    }

    const saveClientCommunication = () => {
        axios.post(process.env.REACT_APP_API_URL2 + "/settings/save-quick-communication", {company: company.id, comm: clientCommunicationData},
            {headers: {"Content-Type": "application/json"}}
        ).then((res) => {
            if(res.data.status === "200") {
                toast.success("Η επικοινωνία αποθηκεύτηκε.");
            } else {
                toast.error("Παρουσιάστηκε σφάλμα κατά την αποθήκευση.")
            }
        }).catch(() => {
            toast.error("Παρουσιάστηκε σφάλμα κατά την αποθήκευση.")
        })
        closeClientCommunicationModal();
    }

    const requestSubjectsAndCommNo = () => {
        axios.post(process.env.REACT_APP_API_URL2 + "/settings/get-subjects-and-no", {company: company.id, clientId: clientCommunicationData.client._id},
            {headers: {"Content-Type": "application/json"}}
        ).then((res) => {
            if(res.data.status === "200") {
                setSubjectOptions(getOptionsByCommaString(res.data.subjects));
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const handleChangeCommModal = (e, type, name) => {
        if(type === "dropdown") {
            if(name === "subject") {
                setClientCommunicationData({...clientCommunicationData, subject: e.value});
            }
        } else if (type === "textarea") {
            if(name === "details") {
                setClientCommunicationData({...clientCommunicationData, details: e.target.value});
            }
        } else if (type === "datebox") {
            if(name === "followUpDate") {
                setClientCommunicationData({...clientCommunicationData, followUpDate: e});
            }
        } else if (type === "checkbox") {
            if(name === "done") {
                if(e.target.checked === true) {
                    setClientCommunicationData({...clientCommunicationData, done: "true"});
                } else {
                    setClientCommunicationData({...clientCommunicationData, done: "false"});
                }
            }
        }
    }

    const communicationValid = () => {
        function isEmpty(o) {
            return o === null || o === undefined || String(o).trim() === "";
        }
        return !isEmpty(clientCommunicationData.date)
            && !isEmpty(clientCommunicationData.time) && !isEmpty(clientCommunicationData.subject) && !isEmpty(clientCommunicationData.details)
            && !isEmpty(clientCommunicationData.followUpDate)
    }

    const handleClientCreation = (tel) => {
        if (tel) {
            setShow(false);
            if(showSubscriptions) setShowSubscriptions(false);
            dispatch(setNewClient({...CLIENTS_DATA.newClientInitialValue, phone: tel, contactPoints: [{
                    no: 1,
                    id: 1,
                    name: "",
                    mobile: tel,
                    landline: "",
                    otherPhone: "",
                    email: "",
                    contactNotes: ""
                }]}))
            const idx = TABS_DATA.findIndex((item) => "new-client" === item);
            if(idx === -1) {
                dispatch(addTab("new-client"));
                dispatch(updateActiveMainTab(TABS_DATA.length));
            } else {
                dispatch(updateActiveMainTab(idx));
            }
        } else {
            toast.error("Πρόβλημα στην αναγνώριση τηλεφώνου πελάτη.")
        }
    }

    const handleCorrelateClient = (tel) => {
        if (tel) {
            setShowCorrelate(true);
            if (String(tel).startsWith("69")) {
                setNewContactPoint({...newContactPoint, mobile: tel});
            } else {
                setNewContactPoint({...newContactPoint, landline: tel});
            }
        }
    }

    const handleSaveCorrelation = () => {
        if (isEmpty(newContactPoint.name)) {
            return toast.error("Παρακαλώ συμπληρώστε όνομα.");
        } else {
            const keysToValidate = ['phone', 'mobile', 'otherPhone', 'landline'];
            if (!keysToValidate.some((key) => !isEmpty(newContactPoint[key]))) {
                return toast.error("Παρακαλώ συμπληρώστε κάποιο τηλέφωνο.");
            }
        }
        setShowCorrelate(false);
        axios.post(`${process.env.REACT_APP_API_URL2}/client/new-contact-point`, {
            ...newContactPoint,
            clientId: selectedClient._id,
            company: company.id,
        }, {
            headers: { 'Content-Type': 'application/json' },
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής καταχώρηση.");
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const getMailboxTooltip = (mailbox) => {
        let textLines = [];
        let totalMessages = 0;
        if(mailbox.length > 0) {
            mailbox.forEach((mb) => {
                totalMessages += Number(mb.response.NewMessages);
                textLines.push(`${mb.response.NewMessages} νέα μηνύματα στην γραμμή ${mb.response.Mailbox}`);
            })
            if (totalMessages === 0 || textLines.length === 0) {
                return <Tooltip id={`tooltip-top`}> Δεν έχετε κάποιο μήνυμα </Tooltip>
            } else {
                return <Tooltip id={`tooltip-top`}>{textLines.map((line) => (
                    <React.Fragment>
                        <span>{line}</span>
                        <br/>
                    </React.Fragment>
                ))}</Tooltip>
            }
        } else {
            return <Tooltip id={`tooltip-top`}> Δεν έχετε κάποιο μήνυμα </Tooltip>
        }
    }

    const getMailboxSize = (mailbox) => {
        let totalMessages = 0;
        if(mailbox.length > 0) {
            mailbox.forEach((mb) => {
                totalMessages += Number(mb.response.NewMessages);
            })
        }
        return totalMessages;
    }

    const handleCallDeletion = (id) => {
        axios.post(process.env.REACT_APP_API_URL2 + "/callcenter/delete-active-call", {id: id},
            {headers: {"Content-Type": "application/json"}}
        ).then((res) => {
            if(res.data.status === "200") {
                toast.success("Η κλήση διαγράφθηκε.");
            } else {
                toast.error("Σφάλμα κατά την διαγραφή της κλήσης.");
            }
        }).catch((error) => {
            console.log(error);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const getSubscriptionId = (obj) => {
        if (obj?.link_subscription_id) {
            return obj.link_subscription_id;
        } else {
            return obj.subscription_id;
        }
    }

    const openCDRViewer = () => {
        setShow(false);
        const idx = TABS_DATA.findIndex((item) => "cdr-viewer" === item);
        if(idx === -1) {
            dispatch(addTab("cdr-viewer"));
            dispatch(updateActiveMainTab(TABS_DATA.length));
        } else {
            dispatch(updateActiveMainTab(idx));
        }
    }

    return (
        <React.Fragment key={"callcenterLive"}>
            {telephoneId ? (
                <Button className="btn-sm mr-2" onClick={() => callcenterClicked()} style={{
                    borderColor: (isTalking() || isRinging()) ? "#38d138" : "#ed4242",
                    backgroundColor: (isTalking() || isRinging()) ? "#38d138" : "#ed4242"
                }}>
                    <img className="mr-1 shakeshake" src={"assets/images/callcenter/call_ended.png"} alt={"Call Shaking"} hidden={!isRinging()}/>
                    <img className="mr-1" src={"assets/images/callcenter/call_telephone.png"} alt={"Telephone"} hidden={!isTalking()}/>
                    <img className="mr-1" src={"assets/images/callcenter/call_ended.png"} alt={"Call Ended"} hidden={isTalking() || isRinging()}/>
                    Τηλ. Κέντρο
                </Button>
            ) : (
                <Button className="btn-sm mr-2" onClick={() => callcenterClicked()}>Τηλ. Κέντρο</Button>
            )}
            {mailbox &&
                <OverlayTrigger key={getMailboxSize(mailbox)} placement={"top"} overlay={getMailboxTooltip(mailbox)}>
                    <Badge style={{cursor: "pointer"}} className="mr-1" bg={getMailboxSize(mailbox) === 0 ? "primary" : "success"}>
                        <img src={"assets/images/callcenter/bell.png"} alt={"Bell"} />
                    </Badge>
                </OverlayTrigger>
            }
            {supportingClient !== null && (
                <Badge bg="danger" className="ml-2 mr-2" style={{cursor: "pointer"}} onClick={() => endSupport()}>Λήξη Υποστήριξης
                    {supportEndTime !== null ? (
                        <><br/>{secondsToMinutesSeconds(supportEndTime.diff(supportStartTime, "seconds"))}</>
                    ) : (
                        <><br/>{secondsToMinutesSeconds(moment().diff(supportStartTime, "seconds"))}</>
                    )}
                </Badge>
            )}
            <div style={{
                visibility: show === false ? "hidden" : "visible",
                opacity: show === false ? "0" : "1",
                transition: show === false ? "" : "all 0.2s linear"
            }} className="callcenter-container">
                <div style={{padding: "7px"}}>
                    {(company.vatNumber === "800566476" || company.vatNumber === "800434990") &&
                        <Badge bg="primary" style={{position: "absolute", cursor: "pointer", top: "13px", left: "5px"}} onClick={() => refreshSubscriptionsClicked()} hidden={hideSubsRefresh}>Ανανέωση Συνδρομών</Badge>
                    }
                    {checkPermission("custom-cdrviewer", permissionsData) === true && (
                        <Badge bg={"primary"} style={{position: "absolute", cursor: "pointer", top: "13px", right: "40px"}} onClick={() => openCDRViewer()}>Αναφορά Τηλεφωνικού</Badge>
                    )}
                    <h4 className="callcenter-h4">
                        Τηλεφωνικό κέντρο {telephoneId && <span>({telephoneId})</span>}
                    </h4>
                    <CloseButton className="closeButtonStyle" onClick={() => callcenterClicked()}/>
                    <hr/>
                    <div style={{paddingBottom: "5px", height: "420px", overflowY: "scroll"}}>
                        {activeCalls?.length > 0 ? activeCalls?.map((call) => (
                            <div className={isRinging() ? "callcenter-box-live alertRinging" : "callcenter-box-live"} key={call.id}>
                                <span style={{fontWeight: "600"}}>{formatPhone(call.CallerIDNum)} {formatCallerName(call.CallerIDName)} <img style={{height: "10px"}} className="ml-1 mr-1 mb-1" src={"assets/images/callcenter/call_to.png"} alt={"Call To"} /> {formatPhone(call.ConnectedLineNum)} {formatCallerName(call.ConnectedLineName)}</span>
                                {call.client && call.client !== "notFound" && Object.keys(getContactPoint(call.client, call.CallerIDNum, call.ConnectedLineNum)).length > 0 && (
                                    <>
                                        {getContactPoint(call.client, call.CallerIDNum, call.ConnectedLineNum).companyName ? (
                                            <span><br/><span style={{fontWeight: "600"}}>Πελάτης: </span>{getContactPoint(call.client, call.CallerIDNum, call.ConnectedLineNum).companyName}</span>
                                        ) : ("")}
                                        {getContactPoint(call.client, call.CallerIDNum, call.ConnectedLineNum).vatNumber ? (
                                            <span><br/><span style={{fontWeight: "600"}}>ΑΦΜ: </span>{getContactPoint(call.client, call.CallerIDNum, call.ConnectedLineNum).vatNumber}</span>
                                        ) : ("")}
                                        {getContactPoint(call.client, call.CallerIDNum, call.ConnectedLineNum).name ? (
                                            <span><br/><span style={{fontWeight: "600"}}>Όνομα επαφής: </span>{getContactPoint(call.client, call.CallerIDNum, call.ConnectedLineNum).name}</span>
                                        ) : ("")}
                                        {getContactPoint(call.client, call.CallerIDNum, call.ConnectedLineNum).email ? (
                                            <span><br/><span style={{fontWeight: "600"}}>Email επαφής: </span>{getContactPoint(call.client, call.CallerIDNum, call.ConnectedLineNum).email}</span>
                                        ) : ("")}
                                    </>
                                )}
                                {call.dialTime && !call.pickupTime && (
                                    <><br/>Χτυπά για {toMinutesSeconds(moment().diff(call.dialTime, "seconds"))}</>
                                )}
                                {call.pickupTime && (
                                    <><br/>Επικοινωνεί για  {toMinutesSeconds(moment().diff(call.pickupTime, "seconds"))}</>
                                )}
                                {call.client && call.client !== "notFound" && (
                                    <>
                                        <br/>
                                        <Badge className="mt-1" bg="primary" style={{cursor: "pointer"}} onClick={() => clientCardClicked(call.client)}>Καρτέλα πελάτη</Badge>
                                        <Badge className="ml-1" bg="primary" style={{cursor: "pointer"}} onClick={() => clientCardClicked(call.client, true)}>Ιστ. Επικοινωνίας</Badge>
                                        <Badge className="ml-1" bg="primary" style={{cursor: "pointer"}} onClick={() => clientCommunicationClicked(call.client)}>Γρήγορη αποθ. επικοινωνίας</Badge>
                                    </>
                                )}
                                {(call.client && call.client === "notFound" && !callIsBetweenAgents(call.CallerIDNum, call.ConnectedLineNum)) && (
                                    <>
                                        <br/>
                                        <Badge bg="primary" className="mt-1" style={{cursor: "pointer"}} onClick={() => handleClientCreation(whoIsClient(call.CallerIDNum, call.ConnectedLineNum))}>Δημιουργία Πελάτη</Badge>
                                        <Badge bg="primary" className="mt-1 ml-1" style={{cursor: "pointer"}} onClick={() => handleCorrelateClient(whoIsClient(call.CallerIDNum, call.ConnectedLineNum))}>Συσχέτιση Πελάτη</Badge>
                                    </>
                                )}
                                {call.status && (
                                    <>
                                        <br/>
                                        {formatStatus(call.status)} {getStatusIcon(call.CallerIDNum, "live")}
                                    </>
                                )}
                                {!telephoneId && (
                                    <>
                                        <Popconfirm
                                            title={"Διαγραφή κλήσης"}
                                            description={"Είσαστε σίγουροι ότι θέλετε να διαγράψετε αυτήν την κλήση;"}
                                            onConfirm={() => handleCallDeletion(call.id)}
                                            okText="Ναι"
                                            cancelText="Όχι"
                                        >
                                            <Button style={{height: "20px", fontSize: "11px", padding: "4px"}} size={"sm"} className="ml-2">Διαγραφή κλήσης</Button>
                                        </Popconfirm>
                                    </>
                                )}
                                {call.client && call.client !== "notFound" && (
                                    <div style={{position: "absolute", top: "5px", right: "5px"}}>
                                        <Badge bg="primary" style={{cursor: "pointer"}} onClick={() => subscriptionsClicked(call.client)}>Συνδρομές</Badge>
                                    </div>
                                )}
                            </div>
                        )) : (
                            <>Δεν υπάρχει ενεργή κλήση αυτήν την στιγμή</>
                        )}
                        {oldCalls?.length > 0 && oldCalls?.map((call) => (
                            <div className="callcenter-box-old" key={call.id}>
                                <span style={{fontWeight: "600"}}>{formatPhone(call.CallerIDNum)} {formatCallerName(call.CallerIDName)} <img style={{height: "10px"}} className="ml-1 mr-1 mb-1" src={"assets/images/callcenter/call_to.png"} alt={"Call To"} /> {formatPhone(call.ConnectedLineNum)} {formatCallerName(call.ConnectedLineName)}</span>
                                {call.client && call.client !== "notFound" && Object.keys(getContactPoint(call.client, call.CallerIDNum, call.ConnectedLineNum)).length > 0 && (
                                    <>
                                        {getContactPoint(call.client, call.CallerIDNum, call.ConnectedLineNum).companyName ? (
                                            <span><br/><span style={{fontWeight: "600"}}>Πελάτης: </span>{getContactPoint(call.client, call.CallerIDNum, call.ConnectedLineNum).companyName}</span>
                                        ) : ("")}
                                        {getContactPoint(call.client, call.CallerIDNum, call.ConnectedLineNum).vatNumber ? (
                                            <span><br/><span style={{fontWeight: "600"}}>ΑΦΜ: </span>{getContactPoint(call.client, call.CallerIDNum, call.ConnectedLineNum).vatNumber}</span>
                                        ) : ("")}
                                        {getContactPoint(call.client, call.CallerIDNum, call.ConnectedLineNum).name ? (
                                            <span><br/><span style={{fontWeight: "600"}}>Όνομα επαφής: </span>{getContactPoint(call.client, call.CallerIDNum, call.ConnectedLineNum).name}</span>
                                        ) : ("")}
                                        {getContactPoint(call.client, call.CallerIDNum, call.ConnectedLineNum).email ? (
                                            <span><br/><span style={{fontWeight: "600"}}>Email επαφής: </span>{getContactPoint(call.client, call.CallerIDNum, call.ConnectedLineNum).email}</span>
                                        ) : ("")}
                                    </>
                                )}
                                {call.talkTime && call.talkTime !== "0" ? (
                                    <span><br/>Η επικοινωνία διήρκησε {toMinutesSeconds(call.talkTime)}</span>
                                ) : ""}
                                {call.client && call.client !== "notFound" && (
                                    <>
                                        <br/>
                                        <Badge className="mt-1" bg="primary" style={{cursor: "pointer"}} onClick={() => clientCardClicked(call.client)}>Καρτέλα πελάτη</Badge>
                                        <Badge className="ml-1" bg="primary" style={{cursor: "pointer"}} onClick={() => clientCardClicked(call.client, true)}>Ιστ. Επικοινωνίας</Badge>
                                        <Badge className="ml-1" bg="primary" style={{cursor: "pointer"}} onClick={() => clientCommunicationClicked(call.client)}>Γρήγορη αποθ. επικοινωνίας</Badge>
                                    </>
                                )}
                                {(call.client && call.client === "notFound" && !callIsBetweenAgents(call.CallerIDNum, call.ConnectedLineNum)) && (
                                    <>
                                        <br/>
                                        <Badge bg="primary" className="mt-1" style={{cursor: "pointer"}} onClick={() => handleClientCreation(whoIsClient(call.CallerIDNum, call.ConnectedLineNum))}>Δημιουργία Πελάτη</Badge>
                                        <Badge bg="primary" className="mt-1 ml-1" style={{cursor: "pointer"}} onClick={() => handleCorrelateClient(whoIsClient(call.CallerIDNum, call.ConnectedLineNum))}>Συσχέτιση Πελάτη</Badge>
                                    </>
                                )}
                                {call.status && (
                                    <>
                                        <br/>
                                        {formatStatus(call.status)} {getStatusIcon(call.CallerIDNum, "old")}
                                    </>
                                )}
                                {call.time && (
                                    <span style={{position: "absolute", bottom: "5px", right: "5px", color: "gray", fontSize: "11px"}}>{timeAgo(call.time)}</span>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="callcenter-subscription-container" style={{visibility: showSubscriptions === true ? "visible" : "hidden"}}>
                <h4 className="callcenter-h4 mt-1">
                    Συνδρομές
                    <Badge bg="primary" className="ml-2" style={{padding: "3px", cursor: "pointer"}} onClick={() => {refreshClientSubscriptionData(true)}} hidden={disableRefreshSubscriptionClientData}>
                        <img src={"assets/images/callcenter/refresh.png"} alt={"Refresh"} />
                    </Badge>
                </h4>
                <CloseButton className="closeButtonStyle" onClick={() => setShowSubscriptions(false)}/>
                <p style={{marginLeft: "5px", marginRight: "5px", textAlign: "center", fontSize: "12px"}}>του πελάτη {subscriptionClientData.name}</p>
                <hr className="mb-1" />
                <div style={{marginLeft: "7px", marginRight: "7px", marginTop: "7px", marginBottom: "10px", height: "400px", overflowY: "scroll"}}>
                    {subscriptionClientData?.subscriptions?.filter((el) => el.type === "subscription").length > 0
                        && subscriptionClientData?.subscriptions?.filter((el) => el.type === "subscription").map((sub, ind) => (
                        <React.Fragment key={ind}>
                            <><span style={{fontWeight: "600"}}>Όνομα: </span>{sub.subscriptionName}<br/></>
                            {sub.sku && (<><span style={{fontWeight: "600"}}>WP Edition: </span>{sub.sku}<br/></>)}
                            {sub.productName && (<><span style={{fontWeight: "600"}}>Option: </span>{sub.productName}<br/></>)}
                            <><span style={{fontWeight: "600"}}>Ημερομηνία Έναρξης: </span>{moment(sub.startDate).format("DD-MM-YYYY")}<br/></>
                            <><span style={{fontWeight: "600"}}>Ημερομηνία Λήξης: </span>{moment(sub.endDate).format("DD-MM-YYYY")}<br/></>
                            <><span style={{fontWeight: "600"}}>Υπολειπόμενος χρόνος υποστήριξης (λεπτά): </span>{getRemainingSupportTime(subscriptionClientData.subscriptions, sub)}<br/></>
                            {sub.serialNumber && (<><span style={{fontWeight: "600"}}>Σειριακός Κωδικός: </span>{sub.serialNumber}<br/></>)}
                            {sub.isEtameiaki === true && sub?.contractString !== "Μη ενεργοποιημένο" && (
                                <>
                                    <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_MASTER_SOFTWARE + "?serialNumber=" + sub.serialNumber}>
                                        <Badge className="mt-1" bg="primary">Άνοιγμα Υποστήριξης Ταμειακής</Badge>
                                    </a>
                                </>
                            )}
                            {sub.username && sub.password && sub.subscription_id && (
                                <>
                                    <a target="_blank" rel="noreferrer" href={`https://primer.gr/my-account/view-subscription/${getSubscriptionId(sub)}/?username=${sub.username}&password=${sub.password}`}>
                                        <Badge className="ml-1 mt-1" bg="primary">Άνοιγμα συνδρομής στην ιστοσελίδα</Badge>
                                    </a>
                                </>
                            )}
                            {supportingClient === null && !isExpired(sub) && getRemainingSupportTime(subscriptionClientData.subscriptions, sub) !== "0" && (
                                <>
                                    {(sub.isEtameiaki === true || (sub.username && sub.password && sub.subscription_id)) && (<br/>)}
                                    <Badge className="mt-1" bg="primary" style={{cursor: "pointer"}} onClick={() => startSupport(subscriptionClientData, sub)}>Έναρξη Υποστήριξης</Badge>
                                </>
                            )}
                            {supportingClient !== null && !isExpired(sub) && supportingClient?.sub?.sub_id === sub.sub_id && (
                                <>
                                    <br/>
                                    <Badge bg="danger" className="mt-1" style={{cursor: "pointer"}} onClick={() => endSupport()}>Λήξη Υποστήριξης
                                        {supportEndTime !== null ? (
                                            <><br/>{secondsToMinutesSeconds(supportEndTime.diff(supportStartTime, "seconds"))}</>
                                        ) : (
                                            <><br/>{secondsToMinutesSeconds(moment().diff(supportStartTime, "seconds"))}</>
                                        )}
                                    </Badge>
                                </>
                            )}
                            {isExpired(sub) && (
                                <div>
                                    <Badge style={{marginTop: "3px"}} bg="danger">Η συνδρομή αυτή έχει λήξει</Badge>
                                </div>
                            )}
                            <hr className="mt-1 mb-1" style={{marginLeft: "auto", marginRight: "auto", width: "95%"}}/>
                        </React.Fragment>
                    ))}
                    {(!subscriptionClientData?.subscriptions || subscriptionClientData?.subscriptions?.filter((el) => el.type === "subscription").length === 0) && (
                        <span>Δεν υπάρχουν ενεργές συνδρομές.</span>
                    )}
                </div>
            </div>
            <Modal show={showSupportEndModal} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Λήξη υποστήριξης</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Η υποστήριξη έλειξε καθώς διήρκησε {toMinutesSeconds(supportEndTime?.diff(supportStartTime, "seconds"))}. Θέλετε να αποθηκεύσετε την χρονοχρέωση;
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => endSupportFinal(false)}>
                        Όχι
                    </Button>
                    <Button variant="primary" onClick={() => endSupportFinal(true)}>
                        Ναι
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showClientCommunicationModal} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Γρήγορη αποθήκευση επικοινωνίας</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input className="mb-1" name="quickCommDate" label="Ημερομηνία" disabled={true} defaultValue={clientCommunicationData.date ? clientCommunicationData.date.format("DD-MM-YYYY") : ""} />
                    <Input className="mb-1" name="quickCommTime" label="Ώρα" disabled={true} defaultValue={clientCommunicationData.time} />
                    <Dropdown
                        className="mb-1"
                        name="quickCommSubject"
                        label="Θέμα"
                        options={subjectOptions}
                        value={clientCommunicationData.subject ? subjectOptions.filter((el) => el.value === clientCommunicationData.subject)[0] : {}}
                        onChange={(e) => handleChangeCommModal(e, "dropdown", "subject")}
                    />
                    <TextArea
                        placeholder="Λεπτομέρειες"
                        label="Λεπτομέρειες"
                        type="text"
                        rows="3"
                        value={clientCommunicationData?.details}
                        onChange={(e) => handleChangeCommModal(e, "textarea", "details")}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => closeClientCommunicationModal()}>
                        Ακύρωση
                    </Button>
                    <Button variant="primary" onClick={() => saveClientCommunication()} disabled={!communicationValid()}>
                        Αποθήκευση
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop={"static"} show={showCorrelate} backdrop={"static"} onHide={() => setShowCorrelate(false)} dialogClassName={"modal42PercentWidth"}>
                <Modal.Header closeButton>
                    <Modal.Title>Συσχέτιση πελάτη</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={isEmpty(selectedClient._id) ? 12 : 11}>
                            <AdvancedCombobox
                                ACName="Sales Client" // do not change name
                                className="w-100 ac-select-dropdown"
                                searchableDropdownEnable={false}
                                label={"Όνομα πελάτη"}
                                data={clientsListData}
                                defaultValue={selectedClient.name}
                                key={selectedClient.name}
                                onChange={(e) => {
                                    setSelectedClient({
                                        _id: e.row._id,
                                        name: e.row.name,
                                    })
                                }}
                                resetFilter={() => {
                                    setSelectedClient({
                                        _id: "",
                                        name: "",
                                    })
                                }}
                                onChangeSearch={(keyword) => onChangeSearch(keyword)}
                                excludeColumns={[]}
                                columns={clientDropdownColumns}
                                editEnable={false}
                                deleteEnable={false}
                                searchTableEnable={false}
                                showTableOptionOnSelect={true}
                                placeholder={"Επιλέξτε πελάτη"}
                                required={true}
                                name="clientName"
                                showInsertAddButton={true}
                                hideSaveButton={true}
                                hideInsertNewRow={true}
                                inputFieldClasses="form-control"
                                disabled={String(selectedClient._id).length === 24}
                            />
                        </Col>
                        {!isEmpty(selectedClient._id) && (
                            <Col md={1}>
                                <button
                                    style={{paddingTop: "35px"}}
                                    className="btn btn-sm"
                                    title={"Καθαρισμός"}
                                    onClick={() => setSelectedClient({_id: "", name: ""})}>
                                    <i className="fas fa-xmark"></i>
                                </button>
                            </Col>
                        )}
                    </Row>
                    {!isEmpty(selectedClient._id) && (
                        <div className={"mt-2"}>
                            <span className={"text-muted"}><i>Νέα επαφή</i></span>
                            <Row>
                                <Col md={6}>
                                    <Input
                                        name={"name"}
                                        className={"mb-2"}
                                        value={newContactPoint.name}
                                        label={"Όνομα επαφής"}
                                        onChange={(e) => setNewContactPoint({...newContactPoint, name: e.target.value})}
                                        required={true}
                                        autoComplete={"off"}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input
                                        name={"mobile"}
                                        className={"mb-2"}
                                        value={newContactPoint.mobile}
                                        label={"Κινητό τηλέφωνο"}
                                        onChange={(e) => setNewContactPoint({...newContactPoint, mobile: e.target.value})}
                                        autoComplete={"off"}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input
                                        name={"landline"}
                                        className={"mb-2"}
                                        value={newContactPoint.landline}
                                        label={"Σταθερό τηλέφωνο"}
                                        onChange={(e) => setNewContactPoint({...newContactPoint, landline: e.target.value})}
                                        autoComplete={"off"}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input
                                        name={"otherPhone"}
                                        className={"mb-2"}
                                        value={newContactPoint.otherPhone}
                                        label={"Άλλο τηλέφωνο"}
                                        onChange={(e) => setNewContactPoint({...newContactPoint, otherPhone: e.target.value})}
                                        autoComplete={"off"}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input
                                        name={"email"}
                                        className={"mb-2"}
                                        value={newContactPoint.email}
                                        label={"E-Mail"}
                                        onChange={(e) => setNewContactPoint({...newContactPoint, email: e.target.value})}
                                        autoComplete={"off"}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input
                                        name={"contactNotes"}
                                        className={"mb-2"}
                                        value={newContactPoint.contactNotes}
                                        label={"Σημειώσεις επαφής"}
                                        onChange={(e) => setNewContactPoint({...newContactPoint, contactNotes: e.target.value})}
                                        autoComplete={"off"}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleSaveCorrelation()}>
                        Αποθήκευση
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}
export default CallCenterLive