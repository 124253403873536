import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import {Provider} from "react-redux";
import store from "./store";
import axios from "axios";
import {getCurrentUser, getToken} from "./_helpers/commonFunctions";
import i18n from 'i18next';
import en from '../src/translations/en.json';
import el from '../src/translations/el.json';
import {initReactI18next} from 'react-i18next';
import {StorageController} from "./_helpers/storage-controller";

const currentUser = getCurrentUser();
let selectedLanguage = 'el';

if (currentUser) {
    selectedLanguage = currentUser.lang;
}
axios.interceptors.request.use(
    (request) => {
        const token = getToken();
        if (token) {
            request.headers['Authorization'] = 'Bearer ' + token
        }
        /*
        const company = localStorage.getItem("company");
        if (company) {
            request.headers["Company"] = btoa(encodeURIComponent(company));
        }
         */
        return request
    },
    (error) => {
        Promise.reject(error)
    }
)

axios.interceptors.response.use(
    (response) => {
        if(response && response.data && response.data.status === "401") {
            localStorage.clear();
            window.location.replace(`${process.env.REACT_APP_LOGIN_URL}?redirect=ERP&error=${"Login timed out"}`);
        } else if (response && response.data && response.data.status === "503") {
            if (StorageController.getKey("maintenanceMode") === false) {
                alert("Το ERP βρίσκεται υπό αναβάθμιση, παρακαλώ ανανεώστε σε λίγο την σελίδα σας.");
                StorageController.setKey("maintenanceMode", true);
            }
            console.log("Το ERP είναι υπό αναβάθμιση, παρακαλώ δοκιμάστε πάλι σε λίγο.");
            return response;
        } else {
            if (StorageController.getKey("maintenanceMode") === true) {
                StorageController.setKey("maintenanceMode", false);
            }
            return response;
        }
        /*if (response && response.status === 502) {
            window.location.reload();
        }*/
    }
)

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // set options
    .init({
        compatibilityJSON: 'v3',
        lng: selectedLanguage,
        resources: {
            en: {
                common: en               // 'common' is our custom namespace
            },
            el: {
                common: el
            },
        },
        react: {
            useSuspense: false,
        },
        interpolation: {
            escapeValue: false,
        },
    });

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <App/>
    </Provider>
)
