import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Badge, Col, Row, Spinner} from "react-bootstrap";
import Input from "../../../common/Input";
import {getSexOptions, isEmpty} from "../../../../_helpers/commonFunctions";
import TextArea from "../../../common/TextArea";
import Button from "react-bootstrap/Button";
import "./../../../primer/POPUP-COMPONENTS/modalWidths.css";
import {
    setActiveVisit,
    setActiveVisitFirstLoad,
    setActiveVisitPrescriptions, setActiveVisitReferrals, setActiveVisitTreatmentProtocols, setDoctorTreatmentProtocols,
} from "../../../../_reducers/DataPanel/Prescription/prescriptionSlice";
import moment from "moment";
import "./styles.css";
import axios from "axios";
import NewPrescriptionModal from "./Components/newPrescriptionModal";
import NewTreatmentProtocolModal from "./Components/newTreatmentProtocolModal";
import NewReferralModal from "./Components/newReferralModal";
import {Popover} from "antd";
import {setSaveActiveVisit} from "../../../../_reducers/activitySlice";
import BetterCheckBox from "../../../common/BetterCheckBox";
import {toast} from "react-toastify";
import BiometricsModal from "./Components/biometricsModal";

const VisitActive = () => {
    const dispatch = useDispatch();
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const activeVisit = useSelector((state) => state.PRESCRIPTION.activeVisit);
    const activeVisitFirstLoad = useSelector((state) => state.PRESCRIPTION.activeVisitFirstLoad);
    const activeVisitPrescriptions = useSelector((state) => state.PRESCRIPTION.activeVisitPrescriptions);
    const activeVisitTreatmentProtocols = useSelector((state) => state.PRESCRIPTION.activeVisitTreatmentProtocols);
    const activeVisitReferrals = useSelector((state) => state.PRESCRIPTION.activeVisitReferrals);

    const doctorTreatmentProtocols = useSelector((state) => state.PRESCRIPTION.doctorTreatmentProtocols);

    const [showBiometrics, setShowBiometrics] = useState(false);
    const [showNewPrescription, setShowNewPrescription] = useState(false);
    const [showNewTreatmentProtocol, setShowNewTreatmentProtocol] = useState(false);
    const [showNewReferral, setShowNewReferral] = useState(false);

    const [rememberCauseNotes, setRememberCauseNotes] = useState({
        visitCause: "",
        visitNotes: "",
    })
    const [editCauseNotes, setEditCauseNotes] = useState(false);
    const [loadingSaveCauseNotes, setLoadingSaveCauseNotes] = useState(false);

    const [loadingPrintReferrals, setLoadingPrintReferrals] = useState(false);

    useEffect(() => {
        if (activeVisitFirstLoad) {
            dispatch(setSaveActiveVisit(true));
            dispatch(setActiveVisitFirstLoad(false));
            fetchActiveVisitData();
            fetchPatientTreatmentProtocols();
        }
    }, [activeVisitFirstLoad]);

    useEffect(() => {
        if (doctorTreatmentProtocols.length === 0) fetchDoctorTreatmentProtocols();
    }, [activeVisit.visitId]);

    const fetchActiveVisitData = () => {
        axios.get(`${process.env.REACT_APP_API_URL2}/prescription/fetch-visit`, {
            headers: { 'Content-Type': 'application/json' },
            params: {
                company: company.id,
                id: activeVisit.visitId,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                dispatch(setActiveVisitReferrals(res.data.data.referrals));
                dispatch(setActiveVisitPrescriptions(res.data.data.prescriptions));
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const fetchPatientTreatmentProtocols = () => {
        axios.get(`${process.env.REACT_APP_API_URL2}/prescription/patient-treatment-protocols`, {
            headers: { 'Content-Type': 'application/json' },
            params: {
                company: company.id,
                amka: activeVisit.amka,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                dispatch(setActiveVisitTreatmentProtocols(res.data.data));
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const fetchDoctorTreatmentProtocols = () => {
        axios.get(`${process.env.REACT_APP_API_URL2}/prescription/doctor-treatment-protocols`, {
            headers: { 'Content-Type': 'application/json' },
            params: {
                company: company.id,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                dispatch(setDoctorTreatmentProtocols(res.data.data));
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const bigTextComponent = (text) => {
        const bigTextVal = 80;
        const isBig = String(text).length >= bigTextVal;
        if (isBig) {
            return (
                <Popover placement={"top"} title={
                    <div style={{textAlign: "center"}}><u>Περιγραφή Θεραπευτικού Πρωτοκόλλου</u></div>
                } content={
                    <div style={{maxWidth: "500px", maxHeight: "300px", overflowY: "auto"}} dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br />') }}>
                    </div>
                }>
                    <span>{String(text).slice(0, bigTextVal)}...</span>
                </Popover>
            )
        } else {
            return <span>{text}</span>
        }
    }

    const onNewPrescription = () => {
        if (!ACTIVITY.saveActiveVisit) dispatch(setSaveActiveVisit(true));
        fetchActiveVisitData();
    }

    const onNewTreatmentProtocol = () => {
        if (!ACTIVITY.saveActiveVisit) dispatch(setSaveActiveVisit(true));
        fetchPatientTreatmentProtocols();
    }

    const onNewReferral = () => {
        if (!ACTIVITY.saveActiveVisit) dispatch(setSaveActiveVisit(true));
        fetchActiveVisitData();
    }

    const handleOnChange = (e, type, name) => {
        const prepChange = {};
        if (type === "cb") {
            if (name === "inQuota") {
                prepChange["inQuota"] = String(e.target.checked);
            }
        } else if (type === "input") {
            prepChange[name] = e.target.value;
        }
        dispatch(setActiveVisit({...activeVisit, ...prepChange}));
    }

    const handleSaveNewCauseNotes = () => {
        if (isEmpty(activeVisit.visitCause)) return toast.error("Παρακαλώ συμπληρώστε λόγο επίσκεψης.");

        setLoadingSaveCauseNotes(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/prescription/update-visit`, {
            company: company.id,
            visitId: activeVisit.visitId,
            visitCause: activeVisit.visitCause,
            visitNotes: activeVisit.visitNotes,
        }).then((res) => {
            if (res.data.status === "200") {
                setEditCauseNotes(false);
                toast.success("Επιτυχής αποθήκευση.");
            } else {
                toast.error(res.data.message);
            }
            setLoadingSaveCauseNotes(false);
        }).catch((err) => {
            setLoadingSaveCauseNotes(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handlePrintReferrals = () => {
        setLoadingPrintReferrals(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/prescription/print-visit-referrals`, {
            company: company.id,
            visitId: activeVisit.visitId,
        }).then((res) => {
            if (res.data.status === "200") {
                const binaryString = atob(res.data.data);
                const byteArray = new Uint8Array(binaryString.split('').map((char) => char.charCodeAt(0)));
                const blob = new Blob([byteArray], { type: "application/pdf" });
                let blobUrl = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = `Παραπεμπτικά επίσκεψης ${activeVisit.visitId}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);
            } else {
                toast.error(res.data.message);
            }
            setLoadingPrintReferrals(false);
        }).catch((err) => {
            setLoadingPrintReferrals(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    return (
        <React.Fragment>
            <div style={{textAlign: "center"}}>
                <h5>Ενεργή Επίσκεψη : <strong>{activeVisit.visitId}</strong></h5>
            </div>
            <Row className={"mb-3"}>
                <Col md={5}>
                    <Row>
                        <Col md={11}>
                            <Input
                                label={"Ασθενής"}
                                name={"clientName"}
                                value={`${activeVisit.clientName} (${activeVisit.amka})`}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className={"mb-3 borderedContainer"}>
                <h5 style={{fontWeight: "600"}}>Πληροφορίες Ασθενή</h5>
                <Row className={"mb-2"}>
                    <Col md={3}>
                        <Input
                            label={"Ημερομηνία Γέννησης"}
                            value={moment(activeVisit.birthDate, "YYYYMMDD").format("DD/MM/YYYY")}
                            disabled={true}
                        />
                    </Col>
                    <Col md={3}>
                        <Input
                            label={"Οδός/Αριθμός"}
                            value={activeVisit.address}
                            disabled={true}
                        />
                    </Col>
                    <Col md={3}>
                        <Input
                            label={"Πόλη"}
                            value={activeVisit.city}
                            disabled={true}
                        />
                    </Col>
                    <Col md={3}>
                        <Input
                            label={"Ταχ. Κώδικας"}
                            value={activeVisit.postalCode}
                            disabled={true}
                        />
                    </Col>
                </Row>
                <Row className={"mb-2"}>
                    <Col md={3}>
                        <Input
                            label={"Τηλέφωνο"}
                            value={activeVisit.telephone}
                            disabled={true}
                        />
                    </Col>
                    <Col md={3}>
                        <Input
                            label={"Φύλο"}
                            value={getSexOptions().find((el) => el.value === activeVisit.sex)?.label}
                            disabled={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Button size={"sm"} onClick={() => setShowBiometrics(true)}>Βιομετρικά</Button>
                    </Col>
                </Row>
            </div>

            <div className={"mb-3 borderedContainer"}>
                <h5 style={{fontWeight: "600"}}>Πληροφορίες Επίσκεψης</h5>
                <Row>
                    <Col md={6}>
                        <TextArea
                            name={"visitCause"}
                            label={"Λόγος Επίσκεψης"}
                            value={activeVisit.visitCause}
                            required={true}
                            disabled={!editCauseNotes || loadingSaveCauseNotes}
                            rows={4}
                            limit={2000}
                            onChange={(e) => handleOnChange(e, "input", "visitCause")}
                        />
                    </Col>
                    <Col md={6}>
                        <TextArea
                            name={"visitNotes"}
                            label={"Σημειώσεις"}
                            value={activeVisit.visitNotes}
                            disabled={!editCauseNotes || loadingSaveCauseNotes}
                            rows={4}
                            limit={2000}
                            onChange={(e) => handleOnChange(e, "input", "visitNotes")}
                        />
                    </Col>
                </Row>
                <Row className={"d-flex justify-content-end"}>
                    <Col md={12} className={"d-flex justify-content-end"}>
                        {!editCauseNotes ? (
                            <Button size={"sm"} onClick={() => {
                                setEditCauseNotes(true);
                                setRememberCauseNotes({
                                    visitCause: activeVisit.visitCause,
                                    visitNotes: activeVisit.visitNotes
                                })
                            }}>Τροποποίηση</Button>
                        ) : (
                            <React.Fragment>
                                <Button size={"sm"} className={"mr-1"} onClick={() => {
                                    setEditCauseNotes(false);
                                    dispatch(setActiveVisit({...activeVisit, visitCause: rememberCauseNotes.visitCause, visitNotes: rememberCauseNotes.visitNotes}));
                                }} disabled={loadingSaveCauseNotes}>Ακύρωση</Button>
                                <Button size={"sm"} onClick={() => handleSaveNewCauseNotes()} disabled={loadingSaveCauseNotes}>
                                    Αποθήκευση {loadingSaveCauseNotes && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                                </Button>
                            </React.Fragment>
                        )}
                    </Col>
                </Row>
            </div>

            <div className={"mb-3 borderedContainer"}>
                <h5 style={{fontWeight: "600"}}>Ενέργειες στα πλαίσια της επίσκεψης</h5>
                <Button size={"sm"} onClick={() => setShowNewPrescription(true)}>+ Νέα συνταγή</Button>
                <Button size={"sm"} className={"ml-1"} onClick={() => setShowNewReferral(true)}>+ Νέο παραπεμπτικό</Button>
                <Button size={"sm"} className={"ml-1"} onClick={() => setShowNewTreatmentProtocol(true)}>+ Νέο θεραπευτικό πρωτόκολλο</Button>
            </div>

            <div className={"mb-3 borderedContainer"}>
                <h5 style={{fontWeight: "600"}}>Θεραπευτικά Πρωτόκολλα Ασθενή</h5>
                {activeVisitTreatmentProtocols.length > 0 && (
                    <table className={"simpleClassicTable"}>
                        <thead>
                        <tr>
                            <th>Θεραπευτικό Πρωτόκολλο</th>
                            <th>Ημ/νία Ένταξης στο Πρωτόκολλο</th>
                            <th>MDC</th>
                            <th>Περιγραφή</th>
                            <th>Ημ/νία Τελευταίας Συνταγ/σης</th>
                            <th>Κατάσταση</th>
                        </tr>
                        </thead>
                        <tbody>
                        {activeVisitTreatmentProtocols.map((tp, idx) => (
                            <tr key={`treatmentProtocols-${idx}`}>
                                <td>{tp?.treatmentProtocol?.code} - {tp?.treatmentProtocol?.title}</td>
                                <td>{moment(tp?.insertDate, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}</td>
                                <td>{tp?.treatmentProtocol?.mdc.description}</td>
                                <td>{bigTextComponent(tp?.treatmentProtocol?.description)}</td>
                                <td>{tp?.lastUsedDate ? moment(tp?.lastUsedDate, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : "-"}</td>
                                <td>{tp.active === "true" ? <Badge bg={"success"}>Ενεργό</Badge> : <Badge bg={"danger"}>Ανενεργό</Badge>}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </div>

            <div className={"mb-3 borderedContainer"}>
                <h5 style={{fontWeight: "600"}}>Συνταγές</h5>
                {activeVisitPrescriptions.length > 0 && (
                    <table className={"simpleClassicTable"}>
                        <thead>
                        <tr>
                            <th>Αρ. Συνταγής</th>
                            <th>Ημερομηνία Έκδοσης</th>
                            <th>Ημερομηνία Λήξης</th>
                            <th>Κατάσταση</th>
                        </tr>
                        </thead>
                        <tbody>
                        {activeVisitPrescriptions.map((prescription, idx) => (
                            <tr key={`prescriptions-${idx}`}>
                                <td>{prescription.prescriptionId}</td>
                                <td>{prescription.startDate}</td>
                                <td>{prescription.endDate}</td>
                                <td>{prescription.status}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </div>

            <div className={"mb-3 borderedContainer"}>
                <h5 style={{fontWeight: "600"}}>Παραπεμπτικά</h5>
                {activeVisitReferrals.length > 0 && (
                    <React.Fragment>
                        <table className={"simpleClassicTable"}>
                            <thead>
                            <tr>
                                <th>Αρ. Παραπεμπτικού</th>
                                <th>Ημερομηνία Έκδοσης</th>
                                <th>Ημερομηνία Λήξης</th>
                                <th>Κατάσταση</th>
                            </tr>
                            </thead>
                            <tbody>
                            {activeVisitReferrals.map((referral, idx) => (
                                <tr key={`referrals-${idx}`}>
                                    <td>{referral.referralId}</td>
                                    <td>{referral.startDate}</td>
                                    <td>{referral.endDate}</td>
                                    <td>{referral.status}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <Button size={"sm"} className={"mt-2"} onClick={() => handlePrintReferrals()} disabled={loadingPrintReferrals}>
                            Λήψη παραπεμπτικών σε PDF
                            {loadingPrintReferrals && (
                                <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />
                            )}
                        </Button>
                    </React.Fragment>
                )}
            </div>

            <div className={"mb-3 borderedContainer"}>
                <h5 style={{fontWeight: "600"}}>Λοιπά</h5>
                <Row>
                    <Col md={3}>
                        <BetterCheckBox
                            name={"inQuota"}
                            text={"Να υπολογιστεί η επίσκεψη στο όριο επισκέψεων"}
                            checked={activeVisit.inQuota === "true"}
                            onChange={(e) => handleOnChange(e, "cb", "inQuota")}
                        />
                    </Col>
                </Row>
            </div>

            <NewPrescriptionModal
                show={showNewPrescription}
                setShow={setShowNewPrescription}
                successCallback={() => onNewPrescription()}
            />
            <NewTreatmentProtocolModal
                show={showNewTreatmentProtocol}
                setShow={setShowNewTreatmentProtocol}
                successCallback={() => onNewTreatmentProtocol()}
            />
            <NewReferralModal
                show={showNewReferral}
                setShow={setShowNewReferral}
                successCallback={() => onNewReferral()}
            />
            <BiometricsModal
                show={showBiometrics}
                setShow={setShowBiometrics}
                amka={activeVisit.amka}
            />
        </React.Fragment>
    )
}

export default VisitActive
