import React, {useRef, useState} from "react";
import Select from "react-select";
import './dropdown.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {getOptionsByData} from "../../../_helpers/commonFunctions";

const Dropdown = (props) => {
  const colorStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      marginTop: props?.fromEditableTable === true ? "0" : "",
      paddingTop: props?.fromEditableTable === true ? "0" : "",
      minHeight: props?.fromEditableTable === true ? "35px" : "",
      maxHeight: props?.fromEditableTable === true ? "35px" : "",
    }),
    valueContainer: (styles) => ({
      ...styles,
      marginTop: props?.fromEditableTable === true ? "0" : "",
      paddingTop: props?.fromEditableTable === true ? "0" : "",
      minHeight: props?.fromEditableTable === true ? "35px" : "",
      maxHeight: props?.fromEditableTable === true ? "35px" : "",
    }),
    input: (styles) => ({
      ...styles,
      marginTop: props?.fromEditableTable === true ? "0" : "",
      paddingTop: props?.fromEditableTable === true ? "0" : "",
      minHeight: props?.fromEditableTable === true ? "35px" : "",
      maxHeight: props?.fromEditableTable === true ? "35px" : "",
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      marginTop: props?.fromEditableTable === true ? "0" : "",
      paddingTop: props?.fromEditableTable === true ? "0" : "",
      minHeight: props?.fromEditableTable === true ? "35px" : "",
      maxHeight: props?.fromEditableTable === true ? "35px" : "",
    }),
    option: (styles, { data, isSelected, isDisabled, isFocused }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
            ? null
            : isSelected
                ? "#76AEB7"
                : isFocused
                    ? "#fff"
                    : null,
        color: isDisabled
            ? "#ccc"
            : isSelected
                ? "#fff"
                    ? "white"
                    : "black"
                : "#76AEB7",
        cursor: isDisabled ? "not-allowed" : "default",
        ":hover": {
          color: "white",
          backgroundColor: "#76AEB7",
        },
        ":active": {
          ...styles[":active"],
          backgroundColor:
              !isDisabled && (isSelected ? "#76AEB7" : "#76AEB7"),
        },
      };
    },
    menuPortal: base => ({ ...base, zIndex: "9999 !important" }),
    menu: provided => ({ ...provided, zIndex: "9999 !important" }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#fff",
      backgroundColor: "#76AEB7",
      borderRadius: 0,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "#fff",
      backgroundColor: "#76AEB7",
      borderRadius: 0,
      ":hover": {
        backgroundColor: "#49868f",
        color: "white",
      },
    }),
  };

  let classes = props.classes ? props.classes : props.className ? props.className : 'mb-3 w-100';
  let defaultValue = props.defaultValue ? props.defaultValue : null;
  const [options, setOptions] = useState(props.options);
  const company = useSelector(state => state.COMPANY_DATA.company);
  const [t] = useTranslation("common");
  const ddRef = useRef(null);
  if((defaultValue !== null) && (typeof defaultValue === 'object')) {
    //defaultValue.color = '#76AEB7';
  }
    if(props.isCLientCategory){
      props.options.forEach( option => {
        if(option.label === "Retail"){
          option.label = "Λιανικής";
        } else if(option.label === "Patient"){
          option.label = "Ασθενής";
        } else if(option.label === "Freelancer"){
          option.label = "Ελεύθερος Επαγγελματίας";
        } else if(option.label === "Non-Profit Organization"){
          option.label = "Μη κερδοσκοπική οργάνωση";
        } else if(option.label === "Client within E.U."){
          option.label = "Πελάτης εντός Ε.Ε.";
        } else if(option.label === "Client outside E.U."){
          option.label = "Πελάτης εκτός Ε.Ε.";
        } else if(option.label === "Supplier within E.U."){
          option.label = "Προμηθευτής εντός Ε.Ε.";
        } else if(option.label === "Supplier outside E.U."){
          option.label = "Προμηθευτής εκτός Ε.Ε.";
        } else if(option.label === "Wholesale Others"){
          option.label = "Λοιποί Χονδρικής";
        } else if(option.label === "E.P.E."){
          option.label = "Ε.Π.Ε.";
        }
      })
      if(defaultValue.label === "Retail"){
        defaultValue.label = "Λιανικής";
      } else if(defaultValue.label === "Patient"){
        defaultValue.label = "Ασθενής";
      } else if(defaultValue.label === "Freelancer"){
        defaultValue.label = "Ελεύθερος Επαγγελματίας";
      } else if(defaultValue.label === "Non-Profit Organization"){
        defaultValue.label = "Μη κερδοσκοπική οργάνωση";
      } else if(defaultValue.label === "Client within E.U."){
        defaultValue.label = "Πελάτης εντός Ε.Ε.";
      } else if(defaultValue.label === "Client outside E.U."){
        defaultValue.label = "Πελάτης εκτός Ε.Ε.";
      } else if(defaultValue.label === "Supplier within E.U."){
        defaultValue.label = "Προμηθευτής εντός Ε.Ε.";
      } else if(defaultValue.label === "Supplier outside E.U."){
        defaultValue.label = "Προμηθευτής εκτός Ε.Ε.";
      } else if(defaultValue.label === "Wholesale Others"){
        defaultValue.label = "Λοιποί Χονδρικής";
      } else if(defaultValue.label === "E.P.E."){
        defaultValue.label = "Ε.Π.Ε.";
      }
    }

  const handleOnInputChange =  (inputString) => {
    if(inputString && props.name === "subCategory") {
      axios
          .post(process.env.REACT_APP_API_URL2 + `/accounting/accounting-record-subcategories-list`, {
            name: inputString,
            company: company.id
          }, {
            headers: {"Content-Type": "application/json"},
          })
          .then((res) => {
            if (res.data && res.data.status === "200" && res.data?.data.length > 0) {
              setOptions(getOptionsByData(res.data.data, "_id", "name"));
            }
          })
          .catch((error) => {
            toast.error("Κάτι πήγε λάθος κατά την ανάκτηση υποκατηγοριών");
            console.log(error);
          });
    }
    return inputString;
  }

  const scrollToBottom = () => {
    if(!ddRef.current.children[0].className.includes("disabled") && props.autoscroll !== false) {
      ddRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  return (
    <React.Fragment>
      <div className={classes} ref={ddRef} onClick={scrollToBottom}>
        { props.label &&
          <label htmlFor={props.id} className="mr-1">
            {props.label}
            {props.required && <span className="text-danger"> *</span>}
          </label>
        }

        {
          props.enabledValue ?
          <Select
            name = {props.name ? props.name : ''}
            closeMenuOnSelect={true}
            classNamePrefix={`${props.multiSelect === true ? "MULTI_SELECT_DO_NOT_TOUCH " : ""}scrollable-dropdown`}
            placeholder={t("SelectPlaceholder")}
            value={props.value ? props.value : defaultValue}
            defaultValue={ defaultValue }
            isMulti={props.multiSelect}
            options={props.name === "subCategory" ? options : props.options}
            styles={colorStyles}
            isSearchable={props.searchEnable}
            id={props.id}
            onChange={props.onChange}
            onInputChange={handleOnInputChange}
            inputValue={props.inputValue}
            isDisabled={props.disabled}
            menuPlacement={props.position ? props.position : 'bottom'}
            noOptionsMessage={() =>  "Δεν υπάρχουν επιλογές για αυτό το πεδίο"}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#76AEB7',
                primary: '#76AEB7',
              },
            })}
            menuPosition="fixed"
          />
        :
          <Select
            menuPlacement={props.position ? props.position : 'bottom'}
            name = {props.name ? props.name : ''}
            closeMenuOnSelect={true}
            classNamePrefix={`${props.multiSelect === true ? "MULTI_SELECT_DO_NOT_TOUCH " : ""}scrollable-dropdown`}
            placeholder={t("SelectPlaceholder")}
            defaultValue={defaultValue}
            isMulti={props.multiSelect}
            options={props.name === "subCategory" ? options : props.options}
            styles={colorStyles}
            inputValue={props.inputValue}
            onInputChange={handleOnInputChange}
            isSearchable={props.searchEnable}
            id={props.id}
            onChange={props.onChange}
            isDisabled={props.disabled}
            noOptionsMessage={() =>  "Δεν υπάρχουν επιλογές για αυτό το πεδίο"}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#76AEB7',
                primary: '#76AEB7',
              },
            })}
            menuPosition="fixed"
          />
        }
      </div>
    </React.Fragment>
  );
};

export default Dropdown;
