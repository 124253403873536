export const salesPurposeData = [
    {
        "id": "1",
        "code": "1",
        "name": "Πώληση",
        "english": "Sale",
        "isActive": "true"
    },
    {
        "id": "2",
        "code": "2",
        "name": "Πώληση για Λογαριασμό Τρίτων",
        "english": "Third Party Sale",
        "isActive": "true"
    },
    {
        "id": "3",
        "code": "3",
        "name": "Δειγματισμός",
        "english": "Sampling",
        "isActive": "true"
    },
    {
        "id": "4",
        "code": "4",
        "name": "Έκθεση",
        "english": "Exhibition",
        "isActive": "true"
    },
    {
        "id": "5",
        "code": "5",
        "name": "Επιστροφή",
        "english": "Return",
        "isActive": "true"
    },
    {
        "id": "6",
        "code": "6",
        "name": "Φύλαξη",
        "english": "Storage",
        "isActive": "true"
    },
    {
        "id": "7",
        "code": "7",
        "name": "Επεξεργασία Συναρμολόγηση",
        "english": "Processing / Assembly",
        "isActive": "true"
    },
    {
        "id": "8",
        "code": "8",
        "name": "Μεταξύ Εγκαταστάσεων Οντότητας",
        "english": "Between Entity's Facilities",
        "isActive": "true"
    },
    {
        "id": "9",
        "code": "9",
        "name": "Αγορά",
        "english": "Purchase",
        "isActive": "true"
    },
    {
        "id": "10",
        "code": "10",
        "name": "Εφοδιασμός πλοίων και αεροσκαφών",
        "english": "Ship and Aircraft Supply",
        "isActive": "true"
    },
    {
        "id": "11",
        "code": "11",
        "name": "Δωρεάν διάθεση",
        "english": "Free Distribution",
        "isActive": "true"
    },
    {
        "id": "12",
        "code": "12",
        "name": "Εγγύηση",
        "english": "Warranty",
        "isActive": "true"
    },
    {
        "id": "13",
        "code": "13",
        "name": "Χρησιδανεισμός",
        "english": "Loan for Use",
        "isActive": "true"
    },
    {
        "id": "14",
        "code": "14",
        "name": "Αποθήκευση σε Τρίτους",
        "english": "Storage with Third Parties",
        "isActive": "true"
    },
    {
        "id": "15",
        "code": "15",
        "name": "Επιστροφή από Φύλαξη",
        "english": "Return from Storage",
        "isActive": "true"
    },
    {
        "id": "16",
        "code": "16",
        "name": "Ανακύκλωση",
        "english": "Recycling",
        "isActive": "true"
    },
    {
        "id": "17",
        "code": "17",
        "name": "Καταστροφή άχρηστου υλικού",
        "english": "Destruction of Unused Materials",
        "isActive": "true"
    },
    {
        "id": "18",
        "code": "18",
        "name": "Διακίνηση Παγίων (Ενδοδιακίνηση)",
        "english": "Movement of Fixed Assets (Internal Transfer)",
        "isActive": "true"
    },
    {
        "id": "19",
        "code": "19",
        "name": "Λοιπές Διακινήσεις",
        "english": "Miscellaneous Movements",
        "isActive": "true"
    },
]