import React from "react";
import {addTab, updateActiveMainTab, updateCollapsedMenuId} from "../../../../_reducers/TabsSlice";
import {useDispatch, useSelector} from "react-redux";
import {getIndexByArrayValue} from '../../../../_helpers/helperFunctions';
import {useTranslation} from "react-i18next";
import {setSettingExportTab, setSettingImportTab} from "../../../../_reducers/settingsSlice";
import {mobileSidebarToggle} from "../../../../_reducers/MobileSidebar";
import {checkPermission} from "../../../../_helpers/commonFunctions";
import {toast} from "react-toastify";

const SideBarLink = ({title, linksInfo, id}) => {
    const permissions = localStorage.getItem("permissions");
    const permissionsData = JSON.parse(permissions);
    const dispatch = useDispatch();
    const [t] = useTranslation('common');
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);

    const handleToggle = (id) => {
        if (linksInfo.length === 0) {
            if (TABS_DATA?.includes("working-program-employee")) {
                return toast.info("Παρακαλώ τελειώστε πρώτα με την επεξεργασία προγράμματος του εργαζομένου."); // Prevent user from changing tabs when in working-program-employee
            }
            const specialIds = ["working-program", "ergani-submissions-report", "payroll-qr-scanner", "payroll-hour-report"]
            if (specialIds.includes(id)) {
                const findMatchingTab = TABS_DATA.findIndex((item) => item === id);
                if (findMatchingTab === -1) {
                    dispatch(addTab(id));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(findMatchingTab));
                }
                dispatch(mobileSidebarToggle())
            }
        } else {
            dispatch(updateCollapsedMenuId(id));
        }
    }
    const handleLeftClick = (e, id) => {
        if (TABS_DATA?.includes("working-program-employee")) {
            return toast.info("Παρακαλώ τελειώστε πρώτα με την επεξεργασία προγράμματος του εργαζομένου."); // Prevent user from changing tabs when in working-program-employee
        }
        dispatch(setSettingImportTab(0));
        dispatch(setSettingExportTab(0));
        if (!TABS_DATA.includes(id)) {
            dispatch(addTab(id))
            dispatch(updateActiveMainTab(TABS_DATA.length))
            dispatch(mobileSidebarToggle())
        } else {
            dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
        }
    }

    return (
        <React.Fragment>
            <li onClick={() => handleToggle(id)} style={{cursor: "pointer"}} className="nav-item border-bottom-1-gray">
                <a className="nav-link" data-toggle="collapse" href={`#${id}`} aria-expanded={"false"} aria-controls={id}>
                    <span className="menu-title">{title}</span>
                    {linksInfo.length > 0 && <i className="menu-arrow"></i>}
                </a>
            </li>

            {linksInfo.length > 0 && (
                <div key={id} className="collapse" id={id}>
                    <ul className="nav flex-column sub-menu border-bottom-1-gray">
                        {linksInfo.map((info) => {
                            if (checkPermission(info.permission, permissionsData)) {
                                return (
                                    <li style={{cursor: "pointer"}} key={info.id} className="nav-item">
                                        <div id={info.id} onClick={(e) => handleLeftClick(e, info.id)}
                                             className="nav-link"
                                        >
                                            {t(info.name)}
                                        </div>
                                    </li>
                                )
                            }
                            return false;
                        })}
                    </ul>
                </div>
            )}
        </React.Fragment>
    )
}

export default SideBarLink;
