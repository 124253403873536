export const vatZeroPer1Data = [
    {
        "id": "1",
        "code": "1",
        "english": "Without VAT - Article 2 and 3 of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 2 και 3 του κώδικα ΦΠΑ"
    },
    {
        "id": "2",
        "code": "2",
        "english": "Without VAT - Article 5 of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 5 του Κώδικα ΦΠΑ"
    },
    {
        "id": "3",
        "code": "3",
        "english": "Without VAT - Article 13 of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 13 του Κώδικα ΦΠΑ"
    },
    {
        "id": "4",
        "code": "4",
        "english": "Without VAT - Article 14 of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 14 του Κώδικα ΦΠΑ"
    },
    {
        "id": "5",
        "code": "5",
        "english": "Without VAT - Article 16 of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 16 του Κώδικα ΦΠΑ"
    },
    {
        "id": "6",
        "code": "6",
        "english": "Without VAT - Article 19 of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 19 του Κώδικα ΦΠΑ"
    },
    {
        "id": "7",
        "code": "7",
        "english": "Without VAT - Article 22 of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 22 του Κώδικα ΦΠΑ"
    },
    {
        "id": "8",
        "code": "8",
        "english": "Without VAT - Article 24 of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 24 του Κώδικα ΦΠΑ"
    },
    {
        "id": "9",
        "code": "9",
        "english": "Without VAT - Article 25 of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 25 του Κώδικα ΦΠΑ"
    },
    {
        "id": "10",
        "code": "10",
        "english": "Without VAT - Article 26 of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 26 του Κώδικα ΦΠΑ"
    },
    {
        "id": "11",
        "code": "11",
        "english": "Without VAT - Article 27 of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 27 του Κώδικα ΦΠΑ"
    },
    {
        "id": "12",
        "code": "12",
        "english": "Without VAT - Article 27 of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 27 -Πλοία Ανοικτής Θαλάσσης του Κώδικα ΦΠΑ"
    }
]

export const vatZeroPer2Data = [
    {
        "id": "13",
        "code": "13",
        "english": "Without VAT - Article 27.1.γ -Open Sea Vessels of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 27.1.γ -Πλοία Ανοικτής Θαλάσσης του Κώδικα ΦΠΑ"
    },
    {
        "id": "14",
        "code": "14",
        "english": "Without VAT - Article 28 of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 28 του Κώδικα ΦΠΑ"
    },
    {
        "id": "15",
        "code": "15",
        "english": "Without VAT - Article 39 of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 39 του Κώδικα ΦΠΑ"
    },
    {
        "id": "16",
        "code": "16",
        "english": "Without VAT - Article 39α of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 39α του Κώδικα ΦΠΑ"
    },
    {
        "id": "17",
        "code": "17",
        "english": "Without VAT - Article 40 of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 40 του Κώδικα ΦΠΑ"
    },
    {
        "id": "18",
        "code": "18",
        "english": "Without VAT - Article 41 of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 41 του Κώδικα ΦΠΑ"
    },
    {
        "id": "19",
        "code": "19",
        "english": "Without VAT - Article 47 of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 47 του Κώδικα ΦΠΑ"
    },
    {
        "id": "20",
        "code": "20",
        "english": "VAT Included - Article 43 of the VAT Code",
        "description": "ΦΠΑ Εμπεριεχόμενος - άρθρο 43 του Κώδικα ΦΠΑ"
    },
    {
        "id": "21",
        "code": "21",
        "english": "VAT Included - Article 44 of the VAT Code",
        "description": "ΦΠΑ Εμπεριεχόμενος - άρθρο 44 του Κώδικα ΦΠΑ"
    },
    {
        "id": "22",
        "code": "22",
        "english": "VAT Included - Article 45 of the VAT Code",
        "description": "ΦΠΑ Εμπεριεχόμενος - άρθρο 45 του Κώδικα ΦΠΑ"
    },
    {
        "id": "23",
        "code": "23",
        "english": "VAT Included - Article 46 of the VAT Code",
        "description": "ΦΠΑ Εμπεριεχόμενος - άρθρο 46 του Κώδικα ΦΠΑ"
    },
    {
        "id": "24",
        "code": "24",
        "english": "Without VAT - Article 6 of the VAT Code",
        "description": "Χωρίς ΦΠΑ- άρθρο 6 του Κώδικα ΦΠΑ"
    },
    {
        "id": "25",
        "code": "25",
        "english": "Without VAT - POL.1029/1995",
        "description": "Χωρίς ΦΠΑ - ΠΟΛ.1029/1995"
    },
    {
        "id": "26",
        "code": "26",
        "english": "Without VAT - POL.1167/2015",
        "description": "Χωρίς ΦΠΑ - ΠΟΛ.1167/2015"
    },
    {
        "id": "27",
        "code": "27",
        "english": "Other VAT Exemptions",
        "description": "Λοιπές Εξαιρέσεις ΦΠΑ"
    },
    {
        "id": "28",
        "code": "28",
        "english": "Without VAT – Article 24, case β', paragraph 1 of the VAT Code (Tax Free)",
        "description": "Χωρίς ΦΠΑ – άρθρο 24 περ. β' παρ.1 του Κώδικα ΦΠΑ, (Tax Free)"
    },
    {
        "id": "29",
        "code": "29",
        "english": "Without VAT – Article 47β of the VAT Code (OSS Non-Union Scheme)",
        "description": "Χωρίς ΦΠΑ – άρθρο 47β, του Κώδικα ΦΠΑ (OSS μη ενωσιακό καθεστώς)"
    },
    {
        "id": "30",
        "code": "30",
        "english": "Without VAT – Article 47γ of the VAT Code (OSS Union Scheme)",
        "description": "Χωρίς ΦΠΑ – άρθρο 47γ, του Κώδικα ΦΠΑ (OSS ενωσιακό καθεστώς)"
    },
    {
        "id": "31",
        "code": "31",
        "english": "Without VAT – Article 47δ of the VAT Code (IOSS)",
        "description": "Χωρίς ΦΠΑ – άρθρο 47δ του Κώδικα ΦΠΑ (IOSS)"
    }
]

export const vatZeroPerData = [...vatZeroPer1Data, ...vatZeroPer2Data];