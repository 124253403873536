import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector, useDispatch} from "react-redux";
import { deleteObjectFromArrayOfObjects } from "../../../../_helpers/helperFunctions";
import { getOptionsByCommaString, getCurrentUser } from "../../../../_helpers/commonFunctions";
import EditableTable from "../../../primer/Editable-Table";
import { toast } from "react-toastify";
import { setSaveNewSupplier } from "../../../../_reducers/activitySlice";
import { resetNewCommunicationHistory, setSuppliersSettings, setNewSupplier, setNewCommunicationHistory } from "../../../../_reducers/DataPanel/Suppliers/supplierSlice";
import {useTranslation} from "react-i18next";
const apiURL2 = process.env.REACT_APP_API_URL2;

function NewSupplierCommunicationHistory() {
  const [t] = useTranslation('common');
  const company = useSelector(state => state.COMPANY_DATA.company)
  const ACTIVITY = useSelector( (state) => state.ACTIVITY );
  const SUPPLIERS_DATA = useSelector( (state) => state.SUPPLIERS_DATA );
  const supplierSubjects = SUPPLIERS_DATA.suppliersSettings.supplierCommunicationHistory;
  const subjectsOptionsInit = getOptionsByCommaString(supplierSubjects);
  const [subjectsOptions, setSubjectsOptions] = useState(subjectsOptionsInit);
  let chData = SUPPLIERS_DATA.newSupplier.communicationHistory.slice();
  const dispatch = useDispatch();
  let chColumns = [
    {
      name: t("General.no"),
      field: "no",
      width: "15px",
      editable: false,
    },
    {
      name: t("General.date"),
      field: "date",
      width: "80px",
      editable: true,
      inputType: {
        type: 'datebox',
      }
    },
    {
      name: t("General.time"),
      field: "time",
      width: "50px",
      editable:true,
      inputType: {
        type: 'time',
      }
    },
    {
      name: t("General.subject"),
      field: "subject",
      width: "100px",
      editable: true,
      inputType: {
        type: 'dropdown',
        options: subjectsOptions
      }
    },
    {
      name: t("General.details"),
      field: "details",
      editable: true,
      width: "400px",
      inputType: {
        type: 'textarea',
      }
    },
    {
      name: t("General.followUpDate"),
      field: "followUpDate",
      editable: true,
      width: "100px",
      inputType: {
        type: 'datebox',
      }
    },
    {
      name: t("General.done"),
      field: "done",
      editable: true,
      width: "90px",
      inputType: {
        type: 'checkbox',
        options: [{
          value: true,
          label: 'Yes'
        }, {
          value: false,
          label: 'No'
        }]
      }
    },
  ]
  const [tableColumns, setTableColumns] = useState(chColumns.slice());
  const currentUser = getCurrentUser();
  useEffect(() => {
    axios.post(apiURL2 + `/supplier/list-supplier-setting`, JSON.stringify({
      company:company.id,
      year:company.year.toString(),
      isActive:"active",
      userId: currentUser.id
    }), { headers: { 'Content-Type': 'application/json'}})
    .then(res => {
      if (res.data &&  res.data.status === '200') {
        if (res.data.data.length > 0) {
          dispatch(setSuppliersSettings(res.data.data[res.data.data.length - 1]));
          sessionStorage.setItem('suppliersSettingsData', JSON.stringify(res.data.data[res.data.data.length - 1]));
          const subjectsOptionsUp = getOptionsByCommaString(res.data.data[res.data.data.length - 1]?.supplierCommunicationHistory);
          setSubjectsOptions(subjectsOptionsUp);
        }
      }
    }).catch((err) => {
      console.log(err);
    });
    setTableColumns(chColumns); // set options
  }, [company.id, company.year, dispatch]);

  const tableActions = {
    insert: (e, data) => {
      // Set if editing the form
      if (!ACTIVITY.saveNewSupplier) {
        dispatch(setSaveNewSupplier(true));
      }
      let tempCommunicationHistory = Object.assign({},  SUPPLIERS_DATA.newCommunicationHistory);
      tempCommunicationHistory[data.field] = data.value;
      dispatch(setNewCommunicationHistory(tempCommunicationHistory)); // Update redux
        // Save Data
      if (tempCommunicationHistory && tempCommunicationHistory.subject !== '' && tempCommunicationHistory.details !== "" && tempCommunicationHistory.date !== '' && tempCommunicationHistory.time !== '') {
          let UpdatedComHist = Object.assign({},  tempCommunicationHistory);
          let oldComHist = SUPPLIERS_DATA.newSupplier.communicationHistory;
          UpdatedComHist.no = oldComHist.length + 1;
          UpdatedComHist.id = oldComHist.length + 1;
          dispatch(setNewSupplier({...SUPPLIERS_DATA.newSupplier, communicationHistory: [UpdatedComHist, ...oldComHist]}));
          dispatch(resetNewCommunicationHistory()); // Reset
        }

    },
    update: (data, id) => {
      if (!ACTIVITY.saveNewSupplier) {
        dispatch(setSaveNewSupplier(true));
      }
      if (data.date && (data.date === "")) {
        toast.error("Date is mandatory field.");
      } else if (data.time && (data.time === "")) {
        toast.error("Time  is mandatory field.");
      } else {
        let communicationHistory = SUPPLIERS_DATA.newSupplier.communicationHistory.slice();
        const index = communicationHistory.findIndex(item => item.id === id);
        if (index !== -1) {
          // Update Code
          communicationHistory[index] = {...communicationHistory[index], ...data};
          dispatch(setNewSupplier({...SUPPLIERS_DATA.newSupplier, communicationHistory: communicationHistory})); // update data
        }
      }
    },
    selected: () => {},
    delete: (id) => {
      // Set if editing the form
      if (!ACTIVITY.saveNewSupplier) {
        dispatch(setSaveNewSupplier(true));
      }

      let communicationHistory = SUPPLIERS_DATA.newSupplier.communicationHistory.slice();

      if (id !== '' && Number.isInteger(id)) {
        communicationHistory = deleteObjectFromArrayOfObjects(communicationHistory, id, 'id');
        dispatch(setNewSupplier({...SUPPLIERS_DATA.newSupplier, communicationHistory: communicationHistory }))
      }
      if (id !== '' && !Number.isInteger(id)) {
        communicationHistory = deleteObjectFromArrayOfObjects(communicationHistory, id, '_id');
        dispatch(setNewSupplier({...SUPPLIERS_DATA.newSupplier, communicationHistory: communicationHistory }))
      }

    }
  }

  return (
    <div className="search-products">
      <EditableTable
        className = "supplier-communication-history"
        tableName = "Supplier Communication History"
        allowActions = {true}
        key = {Math.random()}
        actionButtons = {{
          edit: false,
          print: false,
          export: false,
          delete: true,
        }}
        allowFilter = {true}
        allowPagination={false}
        allowBulkActions={true}
        allowPrintResults={true}
        columns={ tableColumns }
        allowInsertRowFromTop={true}
        allowFilterTable={false}
        data={ chData }
        onUpdate={tableActions}
        enableNewRowCheckbox = {true}
      />
    </div>
  )
}
export default NewSupplierCommunicationHistory;
