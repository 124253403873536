import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Input from "../../../common/Input";
import {useTranslation} from "react-i18next";
import Dropdown from "../../../primer/Dropdown";
import {
    colorMapping,
    decimalToHourMinutesFormat,
    delayReasonOptions,
    getWorkType,
    movementTypeOptions
} from "./Components/constants";
import HourPicker from "../../../primer/HourPicker";
import moment from "moment/moment";
import TextArea from "../../../common/TextArea";
import Alert from "react-bootstrap/Alert";

const EditWorkMovement = () => {
    const [t] = useTranslation('common');
    const workMovement = useSelector((state) => state.PAYROLL.editWorkMovement);
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);

    const [installationOptions, setInstallationOptions] = useState([]);

    useEffect(() => {
        if (companyInstallations.length > 0) {
            let options = [];
            companyInstallations.forEach((inst) => {
                options.push({ value: inst.installationMasterId, label: inst.type + " " + inst.address + " " + inst.city });
            })
            setInstallationOptions(options);
        }
    }, [])

    const isWorkMovementDelayed = () => {
        let diff = moment().diff(moment(workMovement.date).set({hour: Number(String(workMovement.time).split(":")[0]), minute: Number(String(workMovement.time).split(":")[1])}), "minute")
        diff = Math.abs(diff);

        return diff > 15;
    }

    return (
        <React.Fragment>
            <Alert variant={"secondary"}>
                Η κίνηση δεν είναι επεξεργάσιμη επειδή έχει σταλθεί στην Εργάνη.
            </Alert>
            <Row className={"mb-3"}>
                <Col md={5}>
                    <Input
                        label={t("General.employee")}
                        disabled={true}
                        value={String(workMovement.employeeName).replace(/\|/g, " ")}
                    />
                    <Dropdown
                        name={"installationMasterId"}
                        label={t("General.installation")}
                        options={installationOptions}
                        defaultValue={installationOptions.find((el) => el.value === workMovement.employeeProgramData.installationMasterId)}
                        key={Math.random()}
                        disabled={true}
                        className={"mt-2"}
                    />
                </Col>
                <Col md={2}>
                    <Input
                        name={"date"}
                        label={"Ημερομηνία"}
                        value={moment(workMovement.date).format("DD-MM-YYYY")}
                        disabled={true}
                    />
                </Col>
                {Object.keys(workMovement.employeeProgramData).length > 0 && (
                    <Col md={5}>
                        <div style={{
                            height: "100%",
                            width: "100%",
                            backgroundColor: colorMapping[workMovement.employeeProgramData.workType],
                            margin: "4px",
                            padding: "4px",
                            borderRadius: "8px",
                            textAlign: "center"
                        }}>
                            {getWorkType(workMovement.employeeProgramData)}<br/>
                            {workMovement.employeeProgramData.allDay ? (
                                <React.Fragment>
                                    Όλη μέρα<br/>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {decimalToHourMinutesFormat(workMovement.employeeProgramData.hourFrom)} - {decimalToHourMinutesFormat(workMovement.employeeProgramData.hourTo)}<br/>
                                </React.Fragment>
                            )}
                            Εγκατάσταση: {workMovement.employeeProgramData.installationMasterId}
                        </div>
                    </Col>
                )}
            </Row>
            {Object.keys(workMovement.employeeProgramData).length > 0 && (
                <React.Fragment>
                    <hr className={"mt-3 mb-2"}/>
                    <Row className={"mb-3"}>
                        <Col md={3}>
                            <Dropdown
                                name={"movementType"}
                                label={"Τύπος Κίνησης"}
                                options={movementTypeOptions}
                                key={Math.random()}
                                disabled={true}
                                defaultValue={movementTypeOptions.find((el) => el.value === workMovement.movementType)}
                            />
                        </Col>
                        <Col md={2}>
                            <HourPicker
                                label={"Ώρα"}
                                name={"time"}
                                timeIntervals={3}
                                selected={moment(workMovement.time, "HH:mm").toDate()}
                                disabled={true}
                            />
                        </Col>
                        {isWorkMovementDelayed() && (
                            <Col md={3}>
                                <Dropdown
                                    name={"delayReason"}
                                    label={"Λόγος καθυστέρησης"}
                                    options={delayReasonOptions}
                                    key={Math.random()}
                                    disabled={true}
                                    defaultValue={delayReasonOptions.find((el) => el.value === workMovement.delayReason)}
                                />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col md={12}>
                            <TextArea
                                name={"notes"}
                                label={t("General.notes")}
                                rows={4}
                                value={workMovement.notes}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default EditWorkMovement
