// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.showassetsbtn {
  font-size: 12px !important;
  margin-top: 14px;
  padding: 12px 10px;
}

.productAssetsfileinput {
  max-width: 100px;
  height: auto;
}

.productAssetsfileinput::before {
  max-width: 100px;
  font-weight: normal;
}

.product-form {
  .overlay_w {
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    cursor: pointer;
    font-size: 20px;
    text-align:center;
    display: flex;
    justify-content: center;
    padding-top: 200px;
    color: #76AEB7; 
  }
  .showWithOverlay {
    z-index: 4;
    position: relative;
  }

  .active-user-alert {
    z-index: 5;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    pointer-events: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/_components/layouts/data-panel/ProductsNew/index.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE;IACE,oCAAoC;IACpC,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,OAAO;IACP,SAAS;IACT,UAAU;IACV,eAAe;IACf,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,cAAc;EAChB;EACA;IACE,UAAU;IACV,kBAAkB;EACpB;;EAEA;IACE,UAAU;IACV,2BAA2B,EAAE,eAAe;IAC5C,yBAAyB,EAAE,WAAW,EACd,4BAA4B,EAC7B,2BAA2B;IAClD,iBAAiB,EAAE,iFAAiF;IACpG,oBAAoB;EACtB;AACF","sourcesContent":[".showassetsbtn {\r\n  font-size: 12px !important;\r\n  margin-top: 14px;\r\n  padding: 12px 10px;\r\n}\r\n\r\n.productAssetsfileinput {\r\n  max-width: 100px;\r\n  height: auto;\r\n}\r\n\r\n.productAssetsfileinput::before {\r\n  max-width: 100px;\r\n  font-weight: normal;\r\n}\r\n\r\n.product-form {\r\n  .overlay_w {\r\n    background: rgba(255, 255, 255, 0.7);\r\n    position: absolute;\r\n    top: 0;\r\n    right: 0;\r\n    left: 0;\r\n    bottom: 0;\r\n    z-index: 3;\r\n    cursor: pointer;\r\n    font-size: 20px;\r\n    text-align:center;\r\n    display: flex;\r\n    justify-content: center;\r\n    padding-top: 200px;\r\n    color: #76AEB7; \r\n  }\r\n  .showWithOverlay {\r\n    z-index: 4;\r\n    position: relative;\r\n  }\r\n\r\n  .active-user-alert {\r\n    z-index: 5;\r\n    -webkit-touch-callout: none; /* iOS Safari */\r\n    -webkit-user-select: none; /* Safari */\r\n    -moz-user-select: none; /* Old versions of Firefox */\r\n    -ms-user-select: none; /* Internet Explorer/Edge */\r\n    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */\r\n    pointer-events: none;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
