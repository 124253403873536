import React, {useEffect, useMemo, useRef, useState} from "react";
import {Badge, Button, Col, Modal, Row, Spinner, Stack} from "react-bootstrap";
import Dropdown from "../../../primer/Dropdown";
import Input from "../../../common/Input";
import DateBox from "../../../primer/DateBox";
import {
  fetchAdvancedTypesSales,
  fetchBankAccounts, fetchCashRegisters,
  fetchClients,
  fetchPOS,
  fetchSalesGeneralSettings,
  sendInvoiceToMyData,
} from "../../../../_apis/api";
import EditableTable from "../../../primer/Editable-Table";
import {useDispatch, useSelector} from "react-redux";
import {
  getCountryOptions,
  getCurrentUser,
  getDefaultOptionByValue,
  getGreekCurrencyFormat,
  getOptionsByData,
  getSalesPrintData,
  isEmpty,
  removeDuplicateObjects,
  salesMyDataStatus,
} from "../../../../_helpers/commonFunctions";
import {useTranslation} from 'react-i18next';
import {setSaveSales} from "../../../../_reducers/activitySlice";
import {
  getLabelByValue,
  getObjectFromArrayOfObjects
} from "../../../../_helpers/helperFunctions";
import {
  setEditSales,
  setViewSalePeppolEdit, setViewSalePeppolEditOldData
} from "../../../../_reducers/DataPanel/Sales/salesSlice";
import TextArea from "../../../common/TextArea";
import {toast} from "react-toastify";
import getMyData from "../../../../_helpers/sharedmydata";
import axios from "axios";
import SendEmail from "../../../primer/POPUP-COMPONENTS/Send-Email";
import {getSalesTotals} from "../../../../_helpers/product-sales-calculations";
import SalesPrintTemplateGreek1 from "../../PrintLayout/sales-print-template1-greek";
import {salesLocationData} from "../../../../_data/sales/sales-payment-types";
import SalesPrintA5Greek from "../../PrintLayout/sales-print-a5-greek";
import SalesPrintA5DoubleGreek from "../../PrintLayout/sales-print-a5-double-greek";
import SalesPrintTemplateEnglish1 from "../../PrintLayout/sales-print-template1-english";
import SalesPrintA5English from "../../PrintLayout/sales-print-a5-english";
import SalesPrintA5DoubleEnglish from "../../PrintLayout/sales-print-a5-double-english";
import {addTab, updateActiveMainTab} from "../../../../_reducers/TabsSlice";
import {setEditClientPayment} from "../../../../_reducers/DataPanel/Clients/clientSlice";
import Alert from "react-bootstrap/Alert";
import SendPayment from "../../../primer/POPUP-COMPONENTS/SendPayment/SendPayment";
import BetterCheckBox from "../../../common/BetterCheckBox";
import {AccountingView} from "../../../primer/AccountingView/AccountingView";
import moment from "moment";
import {Popover} from "antd";
import SelectAnathetousaModal from "../../../primer/POPUP-COMPONENTS/SelectAnathetousaModal";
import "../../../primer/POPUP-COMPONENTS/modalWidths.css";
import AccountingBox from "../../../primer/AccountingBox";
import LogTable from "../../../primer/LogTable";
import {isDeliveryNoteTypes} from "../../../../_data/mydata/isDeliveryNoteTypes";
import DateTimeBox from "../../../primer/DateTimeBox";
import CloudHooksModal from "../Cloud/Modal Components/CloudHooksModal";

const ViewSales = () => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const ACTIVITY = useSelector((state) => state.ACTIVITY);
  const SALES = useSelector((state) => state.SALES);
  const company = useSelector((state) => state.COMPANY_DATA.company);
  const SETTINGS = useSelector((state) => state.SETTINGS);
  const SALES_SETTINGS = useSelector((state) => state.SALES_SETTINGS);
  const CASH_DATA = useSelector((state) => state.CASH_DATA);
  const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
  const requestData = {
    company: company.id,
    year: company.year.toString(),
  };
  const currentUser = getCurrentUser();
  const requestData2 = {
    company: company.id,
    year: company.year.toString(),
    isActive: "active",
    userId: currentUser.id
  }

  const productCodeColumns = [
    {
      label: t('SalesNewView.productCodeTable.code'),
      name: "code",
    },
    {
      label: t('SalesNewView.productCodeTable.productName'),
      name: "name",
    },
    {
      label: t('SalesNewView.productCodeTable.alternativeName'),
      name: "alternateName",
    },
  ];
  const [activeTab, setActiveTab] = useState(0);
  const [showMyDataErrorModal, setShowMyDataErrorModal] = useState(false);
  const [loadingSendToMyData, setLoadingSendToMyData] = useState(false);
  const [showLinkedClientPayments, setShowLinkedClientPayments] = useState(false);

  const canHaveOtherCorrelations = !isEmpty(SALES.editSales.myDataType) && SALES.editSales.myDataType !== "1.6" && SALES.editSales.myDataType !== "2.4" && SALES.editSales.myDataType !== "5.1";

  let countryData = getCountryOptions('all');

  useEffect(() => {
    if (SALES.editSales.enablePeppol === "active" && SALES.viewSalePeppolEdit === true) {
      cancelPeppolEdit();
    }
    dispatch(fetchClients(requestData));
    dispatch(fetchAdvancedTypesSales(requestData));
    dispatch(fetchSalesGeneralSettings(requestData2))
    dispatch(fetchPOS(requestData2));
    dispatch(fetchBankAccounts(requestData2));
    dispatch(fetchCashRegisters(requestData2));

    if (SALES.editSales.corelatedDocument && isEmpty(SALES.editSales.corelatedDocumentName))  {
      axios.post(process.env.REACT_APP_API_URL2 + "/sale/get-correlated-name", {company: company.id, saleId: SALES.editSales.corelatedDocument}, {
        headers: { 'Content-Type': 'application/json' }
      }).then((res) => {
        if (res.data.status === "200") {
          dispatch(setEditSales({...SALES.editSales, corelatedDocumentName: res.data.data}));
        }
        if (SALES.editSales.enablePeppol === "active") {
          getPeppolStatus();
        }
      }).catch((err) => {
        console.log(err);
      })
    } else if (SALES.editSales.enablePeppol === "active") {
      getPeppolStatus();
    }
  }, [dispatch]);

  // Options for Document Type Dropdown
  let pMDDSettings = SETTINGS.primerMyDataData;
  const COMPANY_DATA = useSelector((state) => state.COMPANY_DATA);
  const waysOfSendingGoodsOptions = getOptionsByData(SALES_SETTINGS.salesGeneralSettings.sendingGoods, "_id", "name");
  const carNumbersOptions = getOptionsByData(SALES_SETTINGS.salesGeneralSettings.carNumbers, "_id", "number");
  let otherTaxesOptions = useMemo(() => getOptionsByData(pMDDSettings?.otherTaxes !== undefined && pMDDSettings?.otherTaxes !== '' ? pMDDSettings.otherTaxes.filter(otherTax => otherTax.taxLevel === 1) : SETTINGS.otherHoldTaxes, 'code', 'name'), [pMDDSettings, SETTINGS.otherHoldTaxes]);
  let withHoldTaxesOptions = useMemo(() => getOptionsByData(pMDDSettings?.withHoldTaxes !== undefined && pMDDSettings?.withHoldTaxes !== '' ? pMDDSettings.withHoldTaxes.filter(withHoldTax => withHoldTax.taxLevel === 1) : SETTINGS.withHoldTaxes, 'code', 'name'), [pMDDSettings, SETTINGS.withHoldTaxes]);
  let stampOptions = useMemo(() => getOptionsByData(pMDDSettings?.stamp !== undefined && pMDDSettings?.stamp !== '' ? pMDDSettings.stamp.filter(stamp => stamp.taxLevel === 1) : SETTINGS.stamp, 'code', 'name'), [pMDDSettings, SETTINGS.stamp]);
  let feesOptions = useMemo(() => getOptionsByData(pMDDSettings?.fees !== undefined && pMDDSettings?.fees !== '' ? pMDDSettings.fees.filter(fee => fee.taxLevel === 1) : SETTINGS.fees, 'code', 'name'), [pMDDSettings, SETTINGS.fees]);
  otherTaxesOptions = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...otherTaxesOptions]
  withHoldTaxesOptions = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...withHoldTaxesOptions]
  stampOptions = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...stampOptions]
  feesOptions = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...feesOptions]
  const salesPurposeOptions = getMyData({type: 'salePurpose', settings: SETTINGS});
  const salesMyDataStatusOptions = salesMyDataStatus();
  const myDataStatusDefault = getDefaultOptionByValue(salesMyDataStatusOptions, SALES.editSales.myDataStatus ? SALES.editSales.myDataStatus : 'not_sent', 'value', 'label', 'value');

  let selectedDocumentTypeData = [];
  let dtInstallationsOptions = [];
  let printTemplateData = getSalesPrintData("editSales", SALES, COMPANY_DATA, null, SALES.printLayout);
  const componentRef = useRef();
  const componentRefA5 = useRef();
  const componentRefA5En = useRef();
  const componentRefDA5 = useRef();
  const componentRefDA5En = useRef();
  const componentRefEn = useRef();

  let disabledFields = false;
  if (SALES.editSales.documentType === undefined || SALES.editSales.documentType === '') {
    disabledFields = true;
  } else {
    let dtData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, SALES.editSales.documentType, '_id');
    if (dtData.length > 0) {
      selectedDocumentTypeData = dtData['0'];
      dtInstallationsOptions = getOptionsByData(selectedDocumentTypeData['documentNumbering'], "installationMasterId", "installation");
      dtInstallationsOptions = removeDuplicateObjects(dtInstallationsOptions, 'value');
    }
  }
  useEffect(() => {
    printTemplateData = getSalesPrintData("editSales", SALES, COMPANY_DATA, null, SALES.printLayout);
  }, [SALES.printLayout])

  const clientProductsColumns = [
    {
      name: "No",
      field: "no",
      width: "40px",
      editable: false,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('SalesNewView.productTable.code'),
      field: "code",
      width: "120px",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: productCodeColumns,
          data: [],
          editEnable: false,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('ProductsListNew.table.placeholderCode')
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t('SalesNewView.productTable.name'),
      field: "name",
      width: "400px",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: productCodeColumns,
          data: [],
          editEnable: false,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('ProductsListNew.table.placeholderName')
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t('SalesNewView.productTable.quantity'),
      field: "quantity",
      width: "90px",
      editable: SALES_SETTINGS.salesGeneralSettings?.forbitChange !== "active",
      inputType: {
        type: 'accounting',
        config: {
          maxLength: 10 // Given in the wireframe
        }
      }
    },
    {
      name: t('SalesNewView.productTable.mu'),
      field: "muLabel",
      editable: false,
      width: "60px",
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('SalesNewView.productTable.unitPrice'),
      field: "unitPrice",
      editable: SALES_SETTINGS.salesGeneralSettings?.forbitChange !== "active",
      width: "130px",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          // maxLength: 5
          maxLength: 10 // Given in the wireframe
        }
      }
    },
    {
      name: t('SalesNewView.productTable.discount'),
      field: "discount",
      editable: SALES_SETTINGS.salesGeneralSettings?.forbitChange !== "active",
      width: "80px",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 10
        }
      }
    },
    {
      name: "%",
      field: "isPerDiscount",
      editable: SALES_SETTINGS.salesGeneralSettings?.forbitChange !== "active",
      inputType: {
        type: 'radio',
        name: 'discountType',
        radioLogic: 'oneperrow',
        options: [{
          value: true,
          label: 'True'
        }, {
          value: false,
          label: 'False'
        }]
      }
    },
    {
      name: "€",
      field: "isDiscountAmount",
      editable: SALES_SETTINGS.salesGeneralSettings?.forbitChange !== "active",
      inputType: {
        type: 'radio',
        radioLogic: 'oneperrow',
        name: 'discountType',
        options: [{
          value: true,
          label: 'True'
        }, {
          value: false,
          label: 'False'
        }]
      }
    },
    {
      name: t('SalesNewView.productTable.vat'),
      field: "vatPercentage",
      editable: SALES_SETTINGS.salesGeneralSettings?.forbitChange !== "active",
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('SalesNewView.productTable.netValue'),
      field: "netValue",
      editable: SALES_SETTINGS.salesGeneralSettings?.forbitChange !== "active",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 10
        }
      }
    },
    {
      name: t('SalesNew.productTable.vatValue'),
      field: "vat",
      width: "90px",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 7
        }
      }
    },
    {
      name: t('SalesNewView.productTable.taxes'),
      field: "taxes",
      editable: false,
      width: "90px",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 10
        }
      }
    },
    {
      name: t('SalesNewView.productTable.totalValue'),
      field: "totalValue",
      editable: false,
      width: "150px",
      inputType: {
        otherInfo: "view-sales-totalValue",
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 10
        }
      }
    },
  ]

  const otherCorrelatedInvoicesTableColumns = [
    {
      name: "Παραστατικό",
      field: "docInfo",
      width: "40%",
      inputType: {
        type: 'text',
      }
    },
    {
      name: "MARK",
      field: "docMark",
      width: "30%",
      inputType: {
        type: 'text',
      }
    },
    {
      name: "Επιπλέον πληροφορίες",
      field: "docNotes",
      width: "30%",
      inputType: {
        type: 'text',
      }
    },
  ]

  const handleOnChange = async (e, type = "default", ddname = '') => {
    // Set if editing the form
    if (!ACTIVITY.saveSales) dispatch(setSaveSales(true));
    let name;
    let value;
    if (type === 'dd' || type === 'ac') {
      value = e.value;
      name = ddname;
    } else if (type === 'dp') {
      value = e;
      name = ddname;
    } else if (type === 'acdd') {
      e = e.row;
      value = e._id;
      name = ddname;
    } else if (type === 'tp') {
      value = e;
      name = ddname;
    } else {
      value = e.target.value;
      name = e.target.name;
    }

    let salesData = Object.assign({}, SALES);
    let editSalesData = {};
    editSalesData[name] = value;

    dispatch(setEditSales({ ...salesData.editSales, ...editSalesData }));
  }

  const sendToMyData = () => {
    let data = {
      companyId: company.id,
      year: company.year.toString(),
      saleId: SALES.editSales._id
    }
    dispatch(sendInvoiceToMyData(data, null, setLoadingSendToMyData));
  }

  const notEditableFields = true;

  const salesProductListActions = {
    insert: () => {},
    update: () => {},
    selected: () => {},
    delete: () => {}
  }
  let tableWrClasses = "product-form sales-products-list position-relative disabled-page";
  if (SALES.editSales.client !== '') {
    tableWrClasses += " normalIndex";
  }

  let cashRegistersData = CASH_DATA.cashRegisters;
  let cashRegisterOptions = [{value: "", label: t("General.empty")}, ...getOptionsByData(cashRegistersData, "_id", "name")];

  const editSalesTab = [
    {
      tabName: t('SalesNewView.productTable.title'),
      tabData: (SALES.editSales.extraData.length > 0 && (
          <React.Fragment>
            <Row className={tableWrClasses} style={{pointerEvents: "none"}}>
              <Col md={12}>
                {(SALES.editSales.client === '' && SALES.editSales.newClient === '') && <div className="overlay_w">{t('SalesNewView.message2')}</div>}
                {SALES.editSales?.products && SALES.editSales?.products?.length === 0 && <span className="text-danger">{t('SalesNewView.messageProductList')}</span>}
                <EditableTable
                    tableName="Sales Products List"
                    key={"Sales Products List"}
                    actionButtons={{
                      print: false,
                      delete: false,
                      export: false,
                      edit: true,
                    }}
                    columns={clientProductsColumns}
                    data={SALES.editSales.products}
                    allowInsertRow={false}
                    onUpdate={salesProductListActions}
                    allowActions={true}
                    allowToggleColumns={false}
                    allowPrintResults={false}
                />
              </Col>
            </Row>
            {SALES.editSales?.discountAsLine === true && (
                <Row>
                  <Col md={12} className={"d-flex justify-content-end"}>
                    <div className={"mt-1 mr-3 text-muted"}>(*) Η έκπτωση θα προβληθεί ως γραμμή παραστατικού με περιγραφή: {SALES.editSales?.discountAsLineText}</div>
                  </Col>
                </Row>
            )}
          </React.Fragment>
          )
      ),
    },
    {
      tabName: t('SalesNewView.extraDataTable.title'),
      tabData: (
        <>
          <Row className="disabled-page">
            <Col sm={12} md={3} className="mb-2">
              {SALES.editSales.myDataType !== "2.1" && SALES.editSales.myDataType !== "2.2" && SALES.editSales.myDataType !== "2.3" && SALES.editSales.myDataType !== "2.4" && SALES.editSales.myDataType !== "11.2" && SALES.editSales.myDataType !== "7.1" && SALES.editSales.myDataType !== "8.1" && SALES.editSales.myDataType !== "8.2" && (
                  <Dropdown
                      label={t('SalesNewView.extraDataTable.locationDelivery')}
                      key={Math.random()}
                      defaultValue={getDefaultOptionByValue(salesLocationData, SALES.editSales?.extraData[0]?.deliveryLocation, 'value', 'label', 'value')}
                      name="deliveryLocation"
                      options={salesLocationData}
                  />
              )}
            </Col>
            {SALES.editSales?.extraData[0]?.deliveryLocation === "custom" && (
                <Row className="border-bottom mb-2">
                  <Col sm={12} md={3} className="mb-2">
                    <Dropdown
                        label={t('SalesNewView.extraDataTable.locationCountry.locationCountry')}
                        key={Math.random()}
                        defaultValue={getDefaultOptionByValue(countryData, SALES.editSales.extraData[0].locationCountry, 'value', 'label', 'value')}
                        name="locationCountry"
                        required={true}
                        options={countryData}
                    />
                  </Col>
                  <Col sm={12} md={3} className="mb-2">
                    <Input
                        label={t('SalesNewView.extraDataTable.locationCity.locationCity')}
                        value={SALES.editSales.extraData[0].locationCity}
                        name="locationCity"
                        type="text"
                    />
                  </Col>
                  <Col sm={12} md={3} className="mb-2">
                    <Input
                        label={t('SalesNewView.extraDataTable.locationAddress.locationAddress')}
                        value={SALES.editSales.extraData[0].locationAddress}
                        name="locationAddress"
                        type="text"
                    />
                  </Col>
                  <Col sm={12} md={3} className="mb-2">
                    <Input
                        label={t('SalesNewView.extraDataTable.locationPostalCode.locationPostalCode')}
                        type="text"
                        value={SALES.editSales.extraData[0].locationZip}
                        name="locationZip"
                    />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Input
                        label={"Α.Φ.Μ. Παραλήπτη"}
                        type={"text"}
                        value={SALES.editSales.extraData[0]?.locationVatNumber}
                        name={"locationVatNumber"}
                    />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Input
                        label={"Ονομασία Εταιρείας Παράδοσης"}
                        type={"text"}
                        value={SALES.editSales.extraData[0]?.locationCompanyName}
                        name={"locationCompanyName"}
                    />
                  </Col>
                </Row>
            )}
            <Col sm={12} md={3} className="mb-2">
              <Dropdown
                label={t('SalesNewView.extraDataTable.wayOfSendingGoods')}
                className="mb-2 w-100"
                name="wayOfSendingGoods"
                options={waysOfSendingGoodsOptions}
                key={Math.random()}
                defaultValue={getDefaultOptionByValue(waysOfSendingGoodsOptions, SALES.editSales.extraData[0].wayOfSendingGoods, 'value', 'label', 'value')}
                disabled={notEditableFields}
              />
            </Col>
            {SALES.editSales.extraData[0].deliveryLocation === "custom" && (
                <Col></Col>
            )}
            <Col sm={12} md={3} className="mb-2">
              {SALES.editSales.extraData[0]?.otherTaxAsLine === "true" ? (
                  <Row>
                    <Col md={7}>
                      <Dropdown
                          label={t('SalesNewView.extraDataTable.otherTaxes')}
                          key={Math.random()}
                          defaultValue={getDefaultOptionByValue(otherTaxesOptions, SALES.editSales.extraData[0].otherTax, 'value', 'label', 'value')}
                          name="otherTax"
                          options={otherTaxesOptions}
                      />
                    </Col>
                    <Col md={5}>
                      <label>{"Ποσό λοιπόν φόρων"}</label>
                      <AccountingBox
                          name={"otherTaxEuro"}
                          defaultValue={SALES.editSales.extraData[0].otherTaxEuro}
                          disabled={true}
                      />
                    </Col>
                    <Col md={12}>
                      <BetterCheckBox
                          name={"otherTaxAsLine"}
                          text={"Προβολή ως γραμμή παραστατικού"}
                          checked={SALES.editSales.extraData[0]?.otherTaxAsLine === "true"}
                          disabled={true}
                      />
                    </Col>
                    <Col md={12}>
                      <Input
                          name={"otherTaxAsLineText"}
                          label={"Όνομα γραμμής λοιπού φόρου"}
                          value={isEmpty(SALES.editSales.extraData[0]?.otherTaxAsLineText) ? "" : SALES.editSales.extraData[0]?.otherTaxAsLineText}
                          disabled={true}
                      />
                    </Col>
                  </Row>
              ) : (
                  <Dropdown
                      label={t('SalesNewView.extraDataTable.otherTaxes')}
                      key={Math.random()}
                      defaultValue={getDefaultOptionByValue(otherTaxesOptions, SALES.editSales.extraData[0].otherTax, 'value', 'label', 'value')}
                      name="otherTax"
                      options={otherTaxesOptions}
                  />
              )}
            </Col>
            <Col sm={12} md={3} className="mb-2">
              {SALES.editSales.extraData[0]?.withHoldTaxAsLine === "true" ? (
                  <Row>
                    <Col md={7}>
                      <Dropdown
                          label={t('SalesNewView.extraDataTable.withHoldTaxes')}
                          key={Math.random()}
                          defaultValue={getDefaultOptionByValue(withHoldTaxesOptions, SALES.editSales.extraData[0].withHoldTax, 'value', 'label', 'value')}
                          name="withHoldTax"
                          options={withHoldTaxesOptions}
                      />
                    </Col>
                    <Col md={5}>
                      <label>{"Ποσό παρακράτησης"}</label>
                      <AccountingBox
                          name={"witholdTaxEuro"}
                          defaultValue={SALES.editSales.extraData[0]?.withHoldTaxEuro}
                          disabled={true}
                      />
                    </Col>
                    <Col md={12}>
                      <BetterCheckBox
                          name={"withHoldTaxAsLine"}
                          text={"Προβολή ως γραμμή παραστατικού"}
                          checked={SALES.editSales.extraData[0]?.withHoldTaxAsLine === "true"}
                          disabled={true}
                      />
                    </Col>
                    <Col md={12}>
                      <Input
                          name={"withHoldTaxAsLineText"}
                          label={"Όνομα γραμμής παρακρατούμενου"}
                          value={isEmpty(SALES.editSales.extraData[0]?.withHoldTaxAsLineText) ? "" : SALES.editSales.extraData[0]?.withHoldTaxAsLineText}
                          disabled={true}
                      />
                    </Col>
                  </Row>
              ) : (
                  <Dropdown
                      label={t('SalesNewView.extraDataTable.withHoldTaxes')}
                      key={Math.random()}
                      defaultValue={getDefaultOptionByValue(withHoldTaxesOptions, SALES.editSales.extraData[0].withHoldTax, 'value', 'label', 'value')}
                      name="withHoldTax"
                      options={withHoldTaxesOptions}
                  />
              )}
            </Col>
          </Row>
          <Row className="disabled-page">
            <Col sm={12} md={3}>
              <DateTimeBox
                classes="w-100 mb-2"
                label={t('SalesNewView.extraDataTable.goodsSendDate')}
                name="goodsSendDate"
                selected={SALES.editSales.extraData[0].goodsSendDate}
                disabled={notEditableFields}
              />
            </Col>
            <Col sm={12} md={3} className="mb-2">
              <Dropdown
                  label={t('SalesNewView.extraDataTable.carNumber')}
                  key={Math.random()}
                  defaultValue={getDefaultOptionByValue(carNumbersOptions, SALES.editSales.extraData[0].carNumber, 'value', 'label', 'value')}
                  name="carNumber"
                  options={carNumbersOptions}
              />
            </Col>
            <Col sm={12} md={3} className="mb-2">
              {SALES.editSales.extraData[0]?.feeAsLine === "true" ? (
                  <Row>
                    <Col md={7}>
                      <Dropdown
                          label={t('SalesNewView.extraDataTable.fees')}
                          key={Math.random()}
                          defaultValue={getDefaultOptionByValue(feesOptions, SALES.editSales.extraData[0].fee, 'value', 'label', 'value')}
                          name="fee"
                          options={feesOptions}
                      />
                    </Col>
                    <Col md={5}>
                      <label>{"Ποσό Τέλους"}</label>
                      <AccountingBox
                          name={"feeEuro"}
                          defaultValue={SALES.editSales.extraData[0]?.feeEuro}
                          disabled={true}
                      />
                    </Col>
                    <Col md={12}>
                      <BetterCheckBox
                          name={"feeAsLine"}
                          text={"Προβολή ως γραμμή παραστατικού"}
                          checked={SALES.editSales.extraData[0]?.feeAsLine === "true"}
                          disabled={true}
                      />
                    </Col>
                    <Col md={12}>
                      <Input
                          name={"feeAsLineText"}
                          label={"Όνομα γραμμής τέλους"}
                          value={isEmpty(SALES.editSales.extraData[0]?.feeAsLineText) ? "" : SALES.editSales.extraData[0]?.feeAsLineText}
                          disabled={true}
                      />
                    </Col>
                  </Row>
              ) : (
                  <Dropdown
                      label={t('SalesNewView.extraDataTable.fees')}
                      key={Math.random()}
                      defaultValue={getDefaultOptionByValue(feesOptions, SALES.editSales.extraData[0].fee, 'value', 'label', 'value')}
                      name="fee"
                      options={feesOptions}
                  />
              )}
            </Col>
            <Col sm={12} md={3} className="mb-2">
              {SALES.editSales.extraData[0]?.stampAsLine === "true" ? (
                  <Row>
                    <Col md={7}>
                      <Dropdown
                          label={t('SalesNewView.extraDataTable.stampDuty')}
                          key={Math.random()}
                          defaultValue={getDefaultOptionByValue(stampOptions, SALES.editSales.extraData[0].stamp, 'value', 'label', 'value')}
                          name="stamp"
                          options={stampOptions}
                      />
                    </Col>
                    <Col md={5}>
                      <label>{"Ποσό χαρτοσήμου"}</label>
                      <AccountingBox
                          name={"stampEuro"}
                          defaultValue={SALES.editSales.extraData[0]?.stampEuro}
                          disabled={true}
                      />
                    </Col>
                    <Col md={12}>
                      <BetterCheckBox
                          name={"stampAsLine"}
                          text={"Προβολή ως γραμμή παραστατικού"}
                          checked={SALES.editSales.extraData[0]?.stampAsLine === "true"}
                          disabled={true}
                      />
                    </Col>
                    <Col md={12}>
                      <Input
                          name={"stampAsLineText"}
                          label={"Όνομα γραμμής χαρτοσήμου"}
                          value={isEmpty(SALES.editSales.extraData[0]?.stampAsLineText) ? "" : SALES.editSales.extraData[0]?.stampAsLineText}
                          disabled={true}
                      />
                    </Col>
                  </Row>
              ) : (
                <Dropdown
                    label={t('SalesNewView.extraDataTable.stampDuty')}
                    key={Math.random()}
                    defaultValue={getDefaultOptionByValue(stampOptions, SALES.editSales.extraData[0].stamp, 'value', 'label', 'value')}
                    name="stamp"
                    options={stampOptions}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Dropdown
                  name={"cashRegister"}
                  label={t("General.cashRegister")}
                  key={Math.random()}
                  defaultValue={cashRegisterOptions.find((el) => el.value === SALES.editSales.extraData[0]?.cashRegister) ? cashRegisterOptions.find((el) => el.value === SALES.editSales.extraData[0]?.cashRegister) : ""}
                  options={cashRegisterOptions}
                  disabled={true}
              />
            </Col>
            <Col md={3}>
            </Col>
            <Col md={3}>
              <label>{"Ποσό κρατήσεων"}</label>
              <AccountingBox
                  name={"deductionsEuro"}
                  disabled={true}
                  defaultValue={SALES.editSales.extraData[0].deductionsEuro}
              />
              {SALES.editSales.extraData[0].deductionsEuro && parseFloat(SALES.editSales.extraData[0].deductionsEuro) > 0 && (
                  <React.Fragment>
                    <BetterCheckBox
                        name={"deductionsAsLine"}
                        text={"Προβολή ως γραμμή παραστατικού"}
                        disabled={true}
                        checked={SALES.editSales.extraData[0]?.deductionsAsLine === "true"}
                    />
                    {SALES.editSales.extraData[0]?.deductionsAsLine === "true" && (
                        <Input
                            name={"deductionsAsLineText"}
                            label={"Όνομα γραμμής κράτησης"}
                            disabled={true}
                            value={isEmpty(SALES.editSales.extraData[0]?.deductionsAsLineText) ? "" : SALES.editSales.extraData[0]?.deductionsAsLineText}
                        />
                    )}
                  </React.Fragment>
              )}
            </Col>
          </Row>
        </>
      ),
    },
  ];

  let posData = CASH_DATA.posList;
  let bankData = CASH_DATA.bankAccounts;
  let posOptions = getOptionsByData(posData, '_id', 'name');
  let bankOptions = getOptionsByData(bankData, '_id', 'name');
  let hidePosBankField = false;

  // This will update the list of pos / bank on type selection
  let paymentTypesData = getObjectFromArrayOfObjects(SALES_SETTINGS.salesGeneralSettings.paymentTypes, SALES.editSales.paymentType, '_id');
  if (paymentTypesData && paymentTypesData.length > 0) {
    if (paymentTypesData['0']?.type === "pos") {
      bankOptions = posOptions;
    }

    if (paymentTypesData['0']?.type === "cash" || paymentTypesData['0']?.type === "credit" || paymentTypesData['0']?.type === "cheque" || paymentTypesData['0']?.type === "iris") {
      bankOptions = [];
      hidePosBankField = true;
    }
  }

  let salesTaxesColumns = [
    {
      name: t('SalesNew.salesTaxesTables.vat%'),
      field: "vatPercentage",
      width: "15%",
      editable: false,
    },
    {
      name: t('SalesNew.salesTaxesTables.netValue'),
      field: "netValue",
      width: "15%",
      editable: false,
    },
    {
      name: t('SalesNew.salesTaxesTables.vat'),
      field: "vatEuro",
      width: "15%",
      editable: false,
    },
    {
      name: t('SalesNew.salesTaxesTables.withHoldTaxes'),
      field: "withholdTaxes",
      width: "15%",
      editable: false,
    },
    {
      name: t('SalesNew.salesTaxesTables.otherTaxes'),
      field: "otherTaxes",
      width: "10%",
      editable: false,
    },
    {
      name: t('SalesNew.salesTaxesTables.fees'),
      field: "fees",
      width: "10%",
      editable: false,
    },
    {
      name: t('SalesNew.salesTaxesTables.stampDuty'),
      field: "stamp",
      width: "10%",
      editable: false,
    },
    {
      name: "Κρατήσεις",
      field: "deductions",
      width: "10%",
      editable: false,
    }
  ];

  let totalTaxes = 0;
  let withHoldTaxes = 0;
  let deductions = 0;
  let productTotals = useMemo(() => getSalesTotals(SALES.editSales.products, SETTINGS), [SALES.editSales.products]);
  let salesTaxesData = SALES.editSales.salesTaxes;
  if(salesTaxesData.length > 0 && salesTaxesData[0].vatPercentage === "Invoice Level Tax"){
    totalTaxes = parseFloat( (Number(salesTaxesData[0].otherTaxes) + Number(salesTaxesData[0].fees) + Number(salesTaxesData[0].stamp)).toString());
    withHoldTaxes = parseFloat(salesTaxesData[0].withholdTaxes).toFixed(2);
    deductions = parseFloat(salesTaxesData[0].deductions).toFixed(2);
  }
  let totalQuantity = productTotals.totalQuantity;
  let totalBeforeDiscount = productTotals.totalBeforeDiscount;
  let totalDiscount = productTotals.totalDiscount;
  let totalNetValue = productTotals.totalNetValue;
  let totalAmount = productTotals.totalAmount;
  let paymentAmount = productTotals.paymentAmount;
  let totalVatAmount = productTotals.totalVatValue;
  if(+totalTaxes > 0 || +withHoldTaxes > 0 || +deductions > 0){
    totalAmount = parseFloat(totalAmount) + parseFloat(totalTaxes);
    paymentAmount = (parseFloat(paymentAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes) - parseFloat(deductions)).toFixed(2);
  }
  totalTaxes += Number(productTotals.totalTaxesAmount);
  withHoldTaxes = parseFloat(productTotals.withHoldTaxes) + parseFloat(withHoldTaxes);

  const getPaymentRemainderStatus = () => {
    if(Object.keys(SALES.editSales).includes("paymentRemainder")) {
      if(SALES.editSales.paymentRemainder === SALES.editSales.paymentAmount) {
        return "unpaid"
      } else if (SALES.editSales.paymentRemainder === 0) {
        return "paid"
      } else {
        return "semipaid"
      }
    } else {
      return "unpaid"
    }
  }
  const fixSalesTaxesDisplay = () => {
    if(Array.isArray(SALES.editSales.salesTaxes)) {
      let newArr = [];
      for (let obj of SALES.editSales.salesTaxes) {
        let prepObj = {...obj};
        if (obj.vatPercentage !== "Totals") {
          prepObj.fees = Number(obj.fees).toFixed(2).replace(".", ",") + " €";
          prepObj.netValue = Number(obj.netValue).toFixed(2).replace(".", ",") + " €";
          prepObj.otherTaxes = Number(obj.otherTaxes).toFixed(2).replace(".", ",") + " €";
          prepObj.stamp = Number(obj.stamp).toFixed(2).replace(".", ",") + " €";
          prepObj.vatEuro = Number(obj.vatEuro).toFixed(2).replace(".", ",") + " €";
          prepObj.withholdTaxes = Number(obj.withholdTaxes).toFixed(2).replace(".", ",") + " €";
          if (isNaN(obj.deductions)) {
            prepObj.deductions = "0,00 €";
          } else {
            prepObj.deductions = Number(obj.deductions).toFixed(2).replace(".", ",") + " €";
          }
        } else {
          if (isEmpty(prepObj.deductions)) {
            prepObj.deductions = "0,00 €";
          }
        }
        newArr.push(prepObj);
      }
      return newArr;
    } else {
      return SALES.editSales.salesTaxes;
    }
  }
  const handleOpenClientPayment = (cId) => {
    axios.get(process.env.REACT_APP_API_URL2 + `/client/get-client-payment`, {
      headers: {"Content-Type": "application/json"},
      params: {
        cId: cId
      }
    }).then((res) => {
      if(res.data.status === "200") {
        dispatch(setEditClientPayment(res.data.data));
        const idx = TABS_DATA.findIndex((item) => "edit-client-payments" === item);
        if(idx === -1) {
          dispatch(addTab("edit-client-payments"));
          dispatch(updateActiveMainTab(TABS_DATA.length));
        } else {
          dispatch(updateActiveMainTab(idx));
        }
      } else {
        toast.error("Πρόβλημα κατά το άνοιγμα της είσπραξης.")
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  const [loadingPeppolRequest, setLoadingPeppolRequest] = useState(false);
  const [showPeppolHistory, setShowPeppolHistory] = useState(false);

  const handleOnPeppolChange = (e, type, name) => {
    let obj = {};
    if (SALES.editSales?.peppol) obj = {...SALES.editSales.peppol}
    if (type === "input") {
      obj[name] = e.target.value;
      dispatch(setEditSales({...SALES.editSales, peppol: obj}));
    } else if (type === "datebox") {
      if (moment(e).isValid()) {
        obj[name] = moment(e).format("YYYY-MM-DD")
      } else {
        obj[name] = ""
      }
      dispatch(setEditSales({...SALES.editSales, peppol: obj}));
    } else if (type === "anathetousaModal") {
      // name argument is object here from select anathetousa modal
      toast.success("Η επιλογή έγινε επιτυχώς", {autoClose: 1000});
      obj["partyIdentification"] = name.anathetousaLabelId;
      dispatch(setEditSales({...SALES.editSales, peppol: obj}));
    }
  }

  const enablePeppolEdit = () => {
    dispatch(setViewSalePeppolEdit(true));
    dispatch(setViewSalePeppolEditOldData({
      projectReference: SALES.editSales.peppol.projectReference,
      partyIdentification: SALES.editSales.peppol.partyIdentification,
      contractDocumentReference: SALES.editSales.peppol.contractDocumentReference,
      dueDate: SALES.editSales.peppol.dueDate,
    }));
  }
  const savePeppolEdit = () => {
    setLoadingPeppolRequest(true);
    axios.post(process.env.REACT_APP_API_URL2 + "/sale/peppol-save-and-resend", {...SALES.editSales}, {
      headers: { 'Content-Type': 'application/json' }
    }).then((res) => {
      setLoadingPeppolRequest(false);
      if (res.data.status === "200") {
        toast.success("Η αποστολή νέων δεδομένων έγινε επιτυχώς.");
        dispatch(setViewSalePeppolEdit(false));
        dispatch(setViewSalePeppolEditOldData({}));
        dispatch(setEditSales({...SALES.editSales, peppol: {...SALES.editSales.peppol, status: "waiting"}}))
      } else {
        toast.error(res.data.message);
      }
    }).catch((error) => {
      setLoadingPeppolRequest(false);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
      console.log(error);
    })
  }
  const cancelPeppolEdit = () => {
    dispatch(setViewSalePeppolEdit(false));
    dispatch(setEditSales({...SALES.editSales, peppol: {...SALES.editSales.peppol, ...SALES.viewSalePeppolEditOldData}}));
  }
  const getPeppolStatus = (showToast, forceRefresh = false) => {
    axios.get(process.env.REACT_APP_API_URL2 + "/sale/get-peppol-status", {
      headers: { 'Content-Type': 'application/json' },
      params: {
        company: company.id,
        mark: SALES.editSales.mark,
        forceRefresh: forceRefresh,
      }
    }).then((res) => {
      if (res.data.status === "200") {
        if (showToast) toast.success("Η κατάσταση ανανεώθηκε.");
        dispatch(setEditSales({...SALES.editSales, peppol: {...SALES.editSales.peppol, ...res.data.peppolStatus}}))
      } else {
        if (showToast) toast.error(res.data.message);
      }
    }).catch((err) => {
      if (showToast) toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
      console.log(err);
    })
  }
  const [loadingCheckPeppolFields, setLoadingCheckPeppolFields] = useState(false);
  const handleCheckPeppolFields = () => {
    if (isEmpty(SALES.editSales?.vat)) {
      toast.error("Παρακαλώ επιλέξτε πελάτη.");
    } else if (isEmpty(SALES.editSales?.peppol?.projectReference) && SALES.editSales.myDataType !== "5.1") {
      toast.error("Παρακαλώ συμπληρώστε Α.Δ.Α. ή Ενάριθμο.");
    } else if (isEmpty(SALES.editSales?.peppol?.partyIdentification)) {
      toast.error("Παρακαλώ συμπληρώστε κωδικό αναθέτουσας αρχής.");
    } else {
      setLoadingCheckPeppolFields(true);
      axios.post(process.env.REACT_APP_API_URL2 + "/sale/check-peppol-fields", {
        projectReferenceId: SALES.editSales?.peppol?.projectReference,
        buyerPartyId: SALES.editSales?.peppol?.partyIdentification,
        buyerTaxNumber: SALES.editSales?.vat,
        contractReferenceId: SALES.editSales?.peppol?.contractDocumentReference ? SALES.editSales?.peppol?.contractDocumentReference : "0",
        buyerReference: SALES.editSales?.peppol?.buyerReference ? SALES.editSales.peppol.buyerReference : "",
        company: company.id
      }, {
        headers: { 'Content-Type': 'application/json'}
      }).then((res) => {
        setLoadingCheckPeppolFields(false);
        if (res.data.status === "200") {
          toast.success("Τα πεδία είναι έγκυρα.");
        } else {
          if (res.data.nonValidFields) {
            let prepMessage = `Τα παρακάτω πεδία δεν είναι έγκυρα: ${res.data.nonValidFields.join(", ")}`;
            toast.error(prepMessage, {autoClose: 7000});
          } else {
            toast.error(res.data.message);
          }
        }
      }).catch((err) => {
        setLoadingCheckPeppolFields(false);
        console.log(err);
        toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
      })
    }
  }

  const getGreekPeppolResponseFromCode = (code) => {
    if (code === "AB") {
      return "Αναγνώριση";
    } else if (code === "IP") {
      return "Λήψη από ΙΤ";
    } else if (code === "AP") {
      return "Αποδοχή";
    } else if (code === "PD") {
      return "Πληρωμή/Μερική Πληρωμή";
    } else if (code === "UQ") {
      return "Υπό αμφισβήτηση";
    } else if (code === "RE") {
      return "Απόρριψη";
    } else {
      return code;
    }
  }

  return (
    <div className="new-sales">
      <div className="client-form product-form position-relative">
        {disabledFields && <div className="overlay_w">{t('SalesNewView.message')}</div>}
        <AccountingView document={SALES.editSales} />
        {(SALES.editSales.myDataStatus === "sent" || SALES.editSales?.fromMyDataReport === "true") && (
            <Alert variant={"secondary"}>
              {SALES.editSales?.fromMyDataReport === "true" ? (
                  <span>Η πώληση δεν είναι επεξεργάσιμη διότι έχει προέλθει από το My Data Report.</span>
              ) : (
                  <span>Η πώληση δεν είναι επεξεργάσιμη διότι έχει σταλεί στο My Data.</span>
              )}
              {SALES.editSales.mark && (
                  <React.Fragment>
                    <br/>
                    <span style={{fontWeight: "600"}}>MARK: </span><span>{SALES.editSales.mark}</span>
                    <br/>
                    <span style={{fontWeight: "600"}}>UID: </span><span>{SALES.editSales.uid}</span>
                    {SALES.editSales.authCode && (
                        <React.Fragment>
                          <br/>
                          <span style={{fontWeight: "600"}}>AUTH CODE: </span><span>{SALES.editSales.authCode}</span>
                        </React.Fragment>
                    )}
                  </React.Fragment>
              )}
            </Alert>
        )}
        <Row className="border-bottom mb-2 disabled-page">
          <Col sm={12} md={4} className="showWithOverlay">
            <Input
              className="mb-2 w-100"
              label={t('SalesNewView.DocumentType')}
              name="documentType"
              value={SALES.editSales.documentTypeName}
              disabled={notEditableFields}
            />
          </Col>
          {isDeliveryNoteTypes.includes(SALES.editSales.myDataType) ? (
              <Col md={2} style={{paddingTop: "33px"}}>
                <BetterCheckBox
                    name={"isDeliveryNote"}
                    checked={(SALES.editSales.isDeliveryNote !== null) ? SALES.editSales.isDeliveryNote : true}
                    required={false}
                    disabled={true}
                    text={"Αποτελεί δελτίο αποστολής"}
                />
              </Col>
          ) : (
              <Col md={2}></Col>
          )}
          <Col sm={12} md={6}>
            <div className="d-flex justify-content-end">
              <SendPayment
                  posData={posData}
                  posOptions={getOptionsByData(posData, '_id', 'name')}
                  bankOptions={getOptionsByData(bankData, '_id', 'name')}
                  paymentTypes={SALES_SETTINGS.salesGeneralSettings.paymentTypes}
                  saleId={SALES.editSales._id}
                  paymentRemainder={SALES.editSales.paymentRemainder}
                  printRef={componentRef}
              />
              <CloudHooksModal
                  documentId={SALES.editSales._id}
                  section={"Sales"}
                  className={"mr-2 mt-2"}
              />
              <div className="mydata-status">
                <Dropdown
                  label={t('SalesNewView.myDataStatus')}
                  key={Math.random()}
                  disabled={true}
                  options={salesMyDataStatusOptions}
                  defaultValue={myDataStatusDefault}
                  name="myDataStatus"
                  onChange={(e) => handleOnChange(e, 'dd', 'myDataStatus')}
                />
                <div className="mb-2" style={{textAlign: "center"}}>
                  {getPaymentRemainderStatus() === "unpaid" && (
                      <Badge className="mb-1" bg="primary">Μη Εξοφλημένη</Badge>
                  )}
                  {getPaymentRemainderStatus() === "paid" && (
                      <Badge className="mb-1" bg="primary" style={{cursor: "pointer", pointerEvents: "auto"}} onClick={() => setShowLinkedClientPayments(!showLinkedClientPayments)}>
                        Εξοφλημένη <strong>{showLinkedClientPayments === true ? "-" : "+"}</strong></Badge>
                  )}
                  {getPaymentRemainderStatus() === "semipaid" && (
                      <Badge className="mb-1" bg="primary" style={{cursor: "pointer", pointerEvents: "auto"}} onClick={() => setShowLinkedClientPayments(!showLinkedClientPayments)}>
                        Μερικώς Εξοφλημένη. Υπόλοιπο: {String(Number(SALES.editSales?.paymentRemainder).toFixed(2)).replace(".", ",")} € <strong>{showLinkedClientPayments === true ? "-" : "+"}</strong>
                      </Badge>
                  )}
                  {getPaymentRemainderStatus() !== "unpaid" && showLinkedClientPayments === true && (
                      <Stack gap={1} key={"cpStack"}>
                        {!SALES.editSales.creditDocument && SALES.editSales?.linkedClientPayments && SALES.editSales?.linkedClientPayments?.length > 0 && SALES.editSales.linkedClientPayments.map((cp, i) => (
                            <Badge key={i} bg="primary" style={{cursor: "pointer", pointerEvents: "auto"}} onClick={() => handleOpenClientPayment(cp.clientPaymentId)}>
                              Από την είσπραξη  {cp.displayString} : {String(Number(cp.amount).toFixed(2)).replace(".", ",")} €</Badge>
                        ))}
                        {SALES.editSales.creditDocument && (
                            <Badge bg="primary" key={SALES.editSales.creditDocument}>Λόγω έκδοσης πιστωτικού</Badge>
                        )}
                        {SALES.editSales.canBePaidOff === "inactive" && (
                            <Badge bg="primary" key={SALES.editSales.creditDocument}>Λόγω ρύθμισης του γενικού παραστατικού</Badge>
                        )}
                      </Stack>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm="12" md="3">
            {/* If "MyData rules" is enabled in "Settings-Document Types-Advanced Types", then FIELD IS READ ONLY. Select current date only. */}
            <div className="d-flex disabled-page">
              <DateBox
                classes="w-100 mb-2"
                label={t('SalesNewView.Date')}
                name="date"
                selected={moment(SALES.editSales.date).isValid() ? moment(SALES.editSales.date).toDate() : ""}
                readOnly={true}
                disabled={notEditableFields}
              />
            </div>
          </Col>
          <Col sm="12" md="3">
            <Input
                name="time"
                label={t('SalesNewView.time')}
                value={SALES.editSales.time}
                disabled={true}
            />
          </Col>
          <Col
            sm="12"
            md="6"
            className="text-right d-flex align-items-center justify-content-end"
          >
            {SALES.editSales?.myDataError !== "" && SALES.editSales?.myDataError !== undefined && (
            <Button className="view-sales-buttons" disabled = {SALES.editSales.myDataStatus === "sent"} onClick={() => setShowMyDataErrorModal(true)}>{t('SalesNewView.myDataErrors')}</Button>
            )}
            <Modal backdrop={"static"} className="themeModal" show={showMyDataErrorModal}>
              <Modal.Header>
                <Modal.Title>Warning!</Modal.Title>
              </Modal.Header>
              <Modal.Body>The document failed to be sent to MyData! <br /> The Error is: <br /> {SALES.editSales?.myDataError}</Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={() => setShowMyDataErrorModal(false)}>OK</Button>
              </Modal.Footer>
            </Modal>
             <Button className="view-sales-buttons" disabled = {SALES.editSales.myDataStatus === "sent" || loadingSendToMyData || isEmpty(SALES.editSales?.myDataType)} onClick={() => sendToMyData()}>
               {loadingSendToMyData && (
                   <span
                       className="spinner-border spinner-border-sm"
                       role="status"
                       aria-hidden="true"
                   ></span>
               )}
               {t('SalesNewView.sendToMyData')}</Button>


            {SALES.printLayout === "sales-print-a5-greek" && (<SendEmail className="view-sales-buttons" clientId={SALES.editSales.client} modalContent="Do you want to send invoice mail to the client?" mailTo={SALES.editSales.email} mailSubject="Your Invoice" mailContent="Here is your invoice" documentType="sale" documentId={SALES.editSales._id} pdf={componentRefA5.current} />)}
            {SALES.printLayout === "sales-print-a5-english" && (<SendEmail className="view-sales-buttons" clientId={SALES.editSales.client} modalContent="Do you want to send invoice mail to the client?" mailTo={SALES.editSales.email} mailSubject="Your Invoice" mailContent="Here is your invoice" documentType="sale" documentId={SALES.editSales._id} pdf={componentRefA5En.current} />)}
            {SALES.printLayout === "sales-print-a5-double-greek" && (<SendEmail className="view-sales-buttons" clientId={SALES.editSales.client} modalContent="Do you want to send invoice mail to the client?" mailTo={SALES.editSales.email} mailSubject="Your Invoice" mailContent="Here is your invoice" documentType="sale" documentId={SALES.editSales._id} pdf={componentRefDA5.current} />)}
            {SALES.printLayout === "sales-print-a5-double-english" && (<SendEmail className="view-sales-buttons" clientId={SALES.editSales.client} modalContent="Do you want to send invoice mail to the client?" mailTo={SALES.editSales.email} mailSubject="Your Invoice" mailContent="Here is your invoice" documentType="sale" documentId={SALES.editSales._id} pdf={componentRefDA5En.current} />)}
            {(SALES.printLayout === "sales-print-template1-greek" || !SALES.printLayout) && (<SendEmail className="view-sales-buttons" clientId={SALES.editSales.client} modalContent="Do you want to send invoice mail to the client?" mailTo={SALES.editSales.email} mailSubject="Your Invoice" mailContent="Here is your invoice" documentType="sale" documentId={SALES.editSales._id} pdf={componentRef.current} />)}
            {SALES.printLayout === "sales-print-template1-english" && (<SendEmail className="view-sales-buttons" clientId={SALES.editSales.client} modalContent="Do you want to send invoice mail to the client?" mailTo={SALES.editSales.email} mailSubject="Your Invoice" mailContent="Here is your invoice" documentType="sale" documentId={SALES.editSales._id} pdf={componentRefEn.current} />)}
          </Col>
        </Row>
        <Row className="disabled-page">
          <Col sm="12" md="3">
            <div className="mb-2">
              <Input
                className="mb-2 w-100"
                label={t('SalesNewView.installation')}
                name="installation"
                value={getDefaultOptionByValue(dtInstallationsOptions, SALES.editSales.installation, 'value', 'label', 'value')?.label}
                disabled={notEditableFields}
              />
            </div>
          </Col>
          <Col sm="12" md="3">
            <div className="mb-2">
              <Input
                className="mb-2 w-100"
                label={t('SalesNewView.preNumber.preNumber')}
                name="preNumber"
                value={SALES.editSales.preNumber !== "noprefix" ? SALES.editSales.preNumber : "Κενή"}
                disabled={notEditableFields}
              />
            </div>
          </Col>
          <Col sm="12" md="3">
            <div className="d-flex">
              <Input
                className="mb-2 w-100"
                label={t('SalesNewView.number.number')}
                name="number"
                value={SALES.editSales.number.replace(SALES.editSales.preNumber,'')}
                disabled={notEditableFields}
              />
            </div>
          </Col>
        </Row>
        {(SALES.editSales.corelatedDocumentName) && (
            <Row className="disabled-page">
              <Col sm="12" md="4">
                <div className="d-flex">
                  <Input
                      className="mb-2 w-100"
                      label={t('SalesNewView.corelatedConvertDocument')}
                      name="number"
                      value={SALES.editSales.corelatedDocumentName}
                      disabled={notEditableFields}
                  />
                </div>
              </Col>
            </Row>
        )}
        <Row>
          {SALES.editSales.clientType === 'Existing' &&
          <Col sm="30" md="6">
            <div className="mb-2">
              <Input
                  className="w-100"
                  label={t('SalesNewView.client.client')}
                  name="clientName"
                  value={SALES.editSales.clientName}
                  disabled={notEditableFields}
              />
            </div>
            {ACTIVITY.saveSales && SALES.editSales.clientType === 'New' && SALES.editSales.newClient === "" && <div className="text-danger">{t('SalesNewView.client.required')}</div>}
            {ACTIVITY.saveSales && SALES.editSales.clientType === 'Existing' && SALES.editSales.client === "" && <div className="text-danger">{t('SalesNewView.client.required')}</div>}
          </Col>
          }
          {SALES.editSales.clientType === 'New' &&
          <Col sm="30" md="5">
            <div className="mb-2">
                  <Input
                      className="w-100 newclient-field"
                      label={t('SalesNewView.newRetailClient.newRetailClient')}
                      name="newClient"
                      value={SALES.editSales.newClient}
                      disabled={notEditableFields}
                      placeholder="Add new retail client name"
                      onChange={(e) => handleOnChange(e)}
                  />
            </div>
          </Col>
          }
          {SALES.editSales.clientType === 'Existing' &&
          <Col sm="12" md="2">
            <div className="d-flex">
                  <Input
                      className="w-100"
                      label={t('SalesNewView.clientInstallation.clientInstallation')}
                      name="clientInstallation"
                      value={SALES.editSales.clientInstallationType}
                      disabled={notEditableFields}
                  />
            </div>
          </Col>
          }
          {SALES.editSales.clientType === 'Existing' &&
              <Col sm="12" md="2">
            <div className="mb-2">
              <Input
                  className="w-100"
                  label={t('SalesNewView.vat')}
                  name="vat"
                  value={SALES.editSales.vat}
                  onChange={(e) => handleOnChange(e)}
                  disabled={true}
                  readonly
              />
            </div>
          </Col>
            }
          {SALES.editSales.clientType === 'Existing' &&
              <Col sm="12" md="2">
                <Input
                    className="w-100"
                    label={t('SalesNewView.doy')}
                    name="doy"
                    value={SALES.editSales.doy}
                    disabled={notEditableFields}
                    readonly
                />
              </Col>
          }
          {/* Show if new client */}
          {SALES.editSales.clientType === 'New' &&
              <Col sm="12" md="2">
                <div className="mb-2 ">
                  <Input
                      className="w-100"
                      label={t('SalesNewView.email.email')}
                      name="email"
                      required={SALES.editSales.clientType === 'New' && SALES.editSales.phone === ''}
                      value={SALES.editSales.email}
                      disabled={notEditableFields}
                      onChange={(e) => handleOnChange(e)}
                  />
                  {ACTIVITY.saveSales && SALES.editSales.clientType === 'New' && SALES.editSales.email === "" && SALES.editSales.phone === "" && <div className="text-danger">{t('SalesNewView.email.required')}</div>}
                </div>
              </Col>
          }
          {/* Show if new client */}
          {SALES.editSales.clientType === 'New' &&
              <Col sm="12" md="2">
                <div className="mb-2 ">
                  <Input
                      className="w-100"
                      label={t('SalesNewView.phone.phone')}
                      name="phone"
                      required={SALES.editSales.clientType === 'New' && SALES.editSales.email === ''}
                      disabled={notEditableFields}
                      value={SALES.editSales.phone}
                      onChange={(e) => handleOnChange(e)}
                  />
                </div>
              </Col>
          }
        </Row>
        <Row>
          <Col sm="12" md="2" className={"mb-2"}>
            <Input
              className="w-100"
              label={t('SalesNewView.city')}
              name="city"
              value={SALES.editSales.city}
              disabled={notEditableFields}
              readonly
            />
          </Col>
          <Col sm="12" md="2" className={"mb-2"}>
            <Input
                className="w-100"
                label={t('SalesNewView.address')}
                name="address"
                value={SALES.editSales.address}
                disabled={notEditableFields}
                onChange={(e) => handleOnChange(e)}
            />
          </Col>
          <Col sm="12" md="2" className={"mb-2"}>
            <Input
              className="w-100"
              label={t("General.postalCode")}
              name="pc"
              value={SALES.editSales.pc}
              disabled={notEditableFields}
              readonly
            />
          </Col>
          <Col md={6} className={"mb-2"}>
            <Input
                className="w-100"
                label={t('General.activity')}
                name="activity"
                value={SALES.editSales.activity}
                disabled={SALES.editSales.clientType !== 'New'}
                readonly
            />
          </Col>
        </Row>

        <Row className="disabled-page">
          <Col sm="12" md="4">
            <Input
              className="mb-2 w-100"
              label={t('SalesNewView.paymentType.paymentType')}
              name="paymentType"
              value={SALES.editSales?.paymentTypeName}
              disabled={notEditableFields}
            />
          </Col>
          {!hidePosBankField &&
            <Col sm="12" md="4">
              <Input
                className="mb-2 w-100"
                label={t('SalesNewView.posBankAccount')}
                name="bankAccount"
                value={getLabelByValue(bankOptions, SALES.editSales?.bankAccount, 'value')}
                disabled={notEditableFields}
              />
            </Col>
          }
          <Col sm="12" md="4">
            <Input
              className="mb-2 w-100"
              label={t('SalesNewView.salePurpose')}
              name="salePurpose"
              value={getDefaultOptionByValue(salesPurposeOptions, SALES.editSales.salePurpose, 'value', 'label', 'value')?.label}
              disabled={notEditableFields}
            />
          </Col>
        </Row>
        {(SALES.editSales?.myDataType === "1.1" || SALES.editSales?.myDataType === "2.1" || SALES.editSales?.myDataType === "5.1" || SALES.editSales?.myDataType === "5.2")
            && SETTINGS.settings.advancedTypesSales.find((doc) => doc._id === SALES.editSales.documentType)?.mydataProvider === "active"
            && SALES.editSales?.enablePeppol === "active"
            && (
                <Row className={"mb-2"}>
                  <Col md={3} style={{pointerEvents: "none"}}>
                    <BetterCheckBox
                        name={"enablePeppol"}
                        text={"Αφορά δημόσια σύμβαση"}
                        className={"mt-1 mb-1"}
                        checked={SALES.editSales?.enablePeppol === "active"}
                        disabled={true}
                    />
                  </Col>
                  {(SALES.editSales?.enablePeppol === "active") && (
                      <Col md={12}>
                        <div style={{borderRadius: "5px", border: "1px solid #ecf0f5", padding: "10px"}}>
                          <Row>
                            {SALES.editSales?.myDataType !== "5.1" && (
                                <Col md={3}>
                                  <Input
                                      name={"projectReference"}
                                      label={"Α.Δ.Α. ή Ενάριθμος (BT-11)"}
                                      value={SALES.editSales?.peppol?.projectReference ? SALES.editSales.peppol.projectReference : ""}
                                      disabled={!SALES.viewSalePeppolEdit || loadingPeppolRequest}
                                      required={true}
                                      onChange={(e) => handleOnPeppolChange(e, "input", "projectReference")}
                                  />
                                </Col>
                            )}
                            <Col md={3}>
                              {SALES.viewSalePeppolEdit ? (
                                <Row>
                                  <Col md={10}>
                                    <Input
                                        name={"partyIdentification"}
                                        label={"Κωδικός Αναθέτουσας Αρχής"}
                                        value={SALES.editSales?.peppol?.partyIdentification ? SALES.editSales.peppol.partyIdentification : ""}
                                        required={true}
                                        disabled={true}
                                    />
                                  </Col>
                                  <Col md={2}>
                                    <SelectAnathetousaModal
                                        data={SALES.editSales}
                                        selectFunction={(obj) => handleOnPeppolChange(null, "anathetousaModal", obj)}
                                    />
                                  </Col>
                                </Row>
                              ) : (
                                <Input
                                    name={"partyIdentification"}
                                    label={"Κωδικός Αναθέτουσας Αρχής (BT-46)"}
                                    value={SALES.editSales?.peppol?.partyIdentification ? SALES.editSales.peppol.partyIdentification : ""}
                                    required={true}
                                    disabled={true}
                                />
                              )}
                            </Col>
                            <Col md={3}>
                              <Input
                                  name={"contractDocumentReference"}
                                  label={"Α.Δ.Α.Μ. (BT-12)"}
                                  value={SALES.editSales?.peppol?.contractDocumentReference ? SALES.editSales.peppol.contractDocumentReference : ""}
                                  disabled={!SALES.viewSalePeppolEdit || loadingPeppolRequest}
                                  onChange={(e) => handleOnPeppolChange(e, "input", "contractDocumentReference")}
                              />
                            </Col>
                            <Col md={2}>
                              <DateBox
                                  name={"dueDate"}
                                  label={"Ημερομηνία"}
                                  selected={moment(SALES.editSales?.peppol?.dueDate, "YYYY-MM-DD").isValid() ? moment(SALES.editSales?.peppol?.dueDate, "YYYY-MM-DD").toDate() : ""}
                                  //disabled={!SALES.viewSalePeppolEdit || loadingPeppolRequest}
                                  disabled={true}
                                  onChange={(e) => handleOnPeppolChange(e, "datebox", "dueDate")}
                              />
                            </Col>
                            <Col md={1} style={{textAlign: "center", marginTop: "15px", fontWeight: "600"}}>
                              {SALES.editSales?.peppol?.status === "waiting" && (
                                  <div>Κατάσταση: <span style={{color: "orange"}}>Εκκρεμεί απάντηση από το ΚΕ.Δ.</span></div>
                              )}
                              {SALES.editSales?.peppol?.status === "error" && (
                                  <Popover placement={"leftTop"} content={
                                    <div>
                                      {SALES.editSales?.peppol.statusReason && <span>Status Reason: {SALES.editSales?.peppol.statusReason}</span>}
                                      <hr style={{margin: "2px"}}/>
                                      {SALES.editSales?.peppol.statusReasonCode && <span>Status Reason Code: {SALES.editSales?.peppol.statusReasonCode}</span>}
                                      {SALES.editSales?.peppol.errorIdentification && <span> <br/> Identification: {SALES.editSales?.peppol.errorIdentification}</span>}
                                      <hr style={{margin: "2px"}}/>
                                      {SALES.editSales?.peppol.responseCode && <span>Response Code: {SALES.editSales?.peppol.responseCode}</span>}

                                    </div>
                                  } title={<div style={{textAlign: "center"}}>Προβολή Σφάλματος</div>}>
                                    <div>Κατάσταση: <span style={{color: "red"}}>Σφάλμα</span></div>
                                  </Popover>
                              )}
                              {SALES.editSales?.peppol?.status === "sent" && (
                                  <div>Κατάσταση: <span style={{color: "green"}}>Εστάλη</span></div>
                              )}
                              {(SALES.editSales?.peppol?.responseHistory?.length > 0) && (
                                  <Badge bg={"primary"} onClick={() => setShowPeppolHistory(true)} style={{cursor: "pointer"}}>Ιστορικό</Badge>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col md={3}>
                              <Input
                                  name={"paymentMethodInfo"}
                                  label={"Όροι πληρωμής (ΒΤ-20)"}
                                  placeholder={"Προσθέστε όρους πληρωμής"}
                                  value={SALES.editSales?.peppol?.paymentMethodInfo ? SALES.editSales.peppol.paymentMethodInfo : ""}
                                  disabled={true}
                              />
                            </Col>
                            <Col md={3}>
                              <Input
                                  name={"buyerReference"}
                                  label={"Στοιχείο Αναφοράς Αγοραστή (ΒΤ-10)"}
                                  placeholder={"Προσθέστε στοιχείο αναφοράς αγοραστή"}
                                  value={SALES.editSales?.peppol?.buyerReference ? SALES.editSales.peppol.buyerReference : ""}
                                  disabled={!SALES.viewSalePeppolEdit || loadingPeppolRequest}
                                  onChange={(e) => handleOnPeppolChange(e, "input", "buyerReference")}
                              />
                            </Col>
                            <Col md={3}>
                              <Input
                                  name={"purchaseOrderReference"}
                                  label={"Αναφορά Εντολής Αγοράς (BT-13)"}
                                  placeholder={"Προσθέστε αναφορά εντολή αγοράς"}
                                  value={SALES.editSales?.peppol?.purchaseOrderReference ? SALES.editSales.peppol.purchaseOrderReference : ""}
                                  disabled={!SALES.viewSalePeppolEdit || loadingPeppolRequest}
                                  onChange={(e) => handleOnPeppolChange(e, "input", "purchaseOrderReference")}
                              />
                            </Col>
                            <Col md={3}>
                              <Input
                                  name={"salesOrderReference"}
                                  label={"Στοιχείο αναφοράς εντολής πώλησης (BT-14)"}
                                  placeholder={"Προσθέστε στοιχείο αναφοράς εντολής πώλησης"}
                                  value={SALES.editSales?.peppol?.salesOrderReference ? SALES.editSales.peppol.salesOrderReference : ""}
                                  disabled={true}
                                  onChange={(e) => handleOnPeppolChange(e, "input", "salesOrderReference")}
                              />
                            </Col>
                          </Row>
                          <Row className={"mt-2"}>
                            {!SALES.viewSalePeppolEdit && (
                                <Col md={12}>
                                  <Button size={"sm"} className={"mr-1"} onClick={() => enablePeppolEdit()}>Τροποποίηση</Button>
                                  <Button size={"sm"} style={{float: "right"}} onClick={() => getPeppolStatus(true, true)}>Ανανέωση κατάστασης</Button>
                                  <Button size={"sm"} style={{float: "right"}} className={"mr-1"} onClick={() => handleCheckPeppolFields()} disabled={loadingCheckPeppolFields}>Έλεγχος εγκυρότητας πεδίων {loadingCheckPeppolFields && <Spinner className={"ml-2"} animation="border" variant="dark" />}</Button>
                                </Col>
                            )}
                            {SALES.viewSalePeppolEdit && (
                                <Col md={12}>
                                  <Button size={"sm"} className={"mr-2"} onClick={() => savePeppolEdit()} disabled={loadingPeppolRequest}>
                                    Αποθήκευση & επαναποστολή
                                    {loadingPeppolRequest && <Spinner className={"ml-2"} animation="border" variant="dark" />}
                                  </Button>
                                  <Button size={"sm"} onClick={() => cancelPeppolEdit()} disabled={loadingPeppolRequest}>Ακύρωση</Button>
                                </Col>
                            )}
                          </Row>
                        </div>
                      </Col>
                  )}
                </Row>
            )}
        <Row>
          <Col md="12" className="mb-2">
            <ul className="react-tabs__tab-list tab-style tab-min">
              {editSalesTab.map((tab, i) => (
                <li key={i} className="react-tabs__tab">
                  <button
                    type="button"
                    onClick={() => setActiveTab(i)}
                    className={`customNavBtn ${activeTab === i && "btnActive"}`}
                  >
                    <span className="textWrapper">{tab.tabName}</span>
                  </button>
                </li>
              ))}
            </ul>
            <div className="innerTabPanel">{editSalesTab[activeTab].tabData}</div>
          </Col>
        </Row>

        <Row className="disabled-page">
          <Col sm="12" md="8">
            <EditableTable
              className="sales-taxes-calculations"
              allowActions={false}
              key={"sales-taxes-calculations"}
              data={fixSalesTaxesDisplay()}
              columns={salesTaxesColumns}
              actionButtons={{
                print: false,
                delete: false,
                export: false,
                edit: false,
              }}
            />
            {(canHaveOtherCorrelations && SALES.editSales?.otherCorrelatedInvoices?.length > 0) && (
                  <React.Fragment>
                    <div className="text-muted mb-2 mt-2"><i>Συσχέτιση Δελτίων Αποστολής/Προεισπράξεων</i></div>
                    <EditableTable
                        name={"New Sale Other Correlated Invoices"}
                        columns={otherCorrelatedInvoicesTableColumns}
                        key={"View Sales Other Correlated"}
                        data={SALES.editSales?.otherCorrelatedInvoices ? SALES.editSales?.otherCorrelatedInvoices : []}
                        allowActions={false}
                    />
                    {SALES.newSales.otherCorrelatedInvoices?.find((el) => el.docMyDataType === "8.4") && (
                        <span className={"text-muted"}>(*) Ο έλεγχος των ποσών των προεισπράξεων σε σχέση με το πληρωτέο ποσό, γίνεται κατά την διάρκεια της αποθήκευσης.</span>
                    )}
                  </React.Fragment>
              )}
          </Col>
          <Col sm="12" md="4" className="d-flex justify-content-end">
            <span className="mr-3 fw-bold">{t('SalesNewView.totalsTable.title')}</span>
            <table className="table table-bordered mb-2">
              <tbody>
              <tr>
                <td className="fw-bold">{t('SalesNewView.totalsTable.totalQuantity')}</td>
                <td>{totalQuantity}</td>
              </tr>
              <tr>
                <td className="fw-bold">{t('SalesNewView.totalsTable.totalBeforeDiscount')}</td>
                <td>{getGreekCurrencyFormat(totalBeforeDiscount)}</td>
              </tr>
              <tr>
                <td className="fw-bold">{t('SalesNewView.totalsTable.totalDiscount')}</td>
                <td>{getGreekCurrencyFormat(totalDiscount)}</td>
              </tr>
              <tr>
                <td className="fw-bold">{t('SalesNewView.totalsTable.totalNetValue')}</td>
                <td>{getGreekCurrencyFormat(totalNetValue)}</td>
              </tr>
              <tr>
                <td className="fw-bold">{t('SalesNewView.totalsTable.totalVatAmount')}</td>
                <td>{getGreekCurrencyFormat(totalVatAmount)}</td>
              </tr>
              <tr>
                <td className="fw-bold">{t('SalesNewView.totalsTable.totalWithHoldTaxesAmount')}</td>
                <td>{getGreekCurrencyFormat(withHoldTaxes)}</td>
              </tr>
              <tr>
                <td className="fw-bold">{t('SalesNewView.totalsTable.totalTaxesAmount')}</td>
                <td>{getGreekCurrencyFormat(totalTaxes)}</td>
              </tr>
              <tr>
                <td className="fw-bold">{t('SalesNewView.totalsTable.totalAmount')}</td>
                <td>{getGreekCurrencyFormat(totalAmount)}</td>
              </tr>
              {(SALES.editSales?.transactionDetails?.tipAmountString) && (
                  <tr>
                    <td className="fw-bold">Φιλοδώρημα</td>
                    <td>{getGreekCurrencyFormat(Number(String(SALES.editSales?.transactionDetails?.tipAmountString).replaceAll(",", ".")))}</td>
                  </tr>
              )}
              <tr>
                <td className="fw-bold">{t('SalesNew.totalsTable.paymentAmount')}</td>
                {(!isEmpty(SALES.editSales?.transactionDetails?.tipAmountString) ? (
                    <td>{getGreekCurrencyFormat((Number(paymentAmount) + Number(String(SALES.editSales?.transactionDetails?.tipAmountString).replaceAll(",", "."))).toFixed(2))}</td>
                ) : (
                    <td>{getGreekCurrencyFormat(paymentAmount)}</td>
                ))}
              </tr>
              </tbody>
            </table>
          </Col>
        </Row>

        <Row className="disabled-page">
          <div style={{width: "100%"}}>
            <div className="text-muted"><i>{t('SalesNewView.salesNotes')}</i></div>
            <TextArea
                rows="4"
                name="notes"
                value={SALES.editSales.notes}
                disabled={notEditableFields}
            />
          </div>
        </Row>
        {SALES.editSales?.enablePeppol === "active" && (
            <Modal backdrop={"static"} show={showPeppolHistory} onHide={() => setShowPeppolHistory(false)} dialogClassName={"modal40PercentWidth"}>
              <Modal.Header closeButton>
                <Modal.Title>Ιστορικό Απαντήσεων από το Κ.Ε.Δ.</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {SALES.editSales?.peppol?.responseHistory?.length > 0 ? (
                    <table style={{tableLayout: "fixed", width: "100%", textAlign: "center", border: "1px solid gray"}}>
                      <thead>
                      <tr>
                        <th style={{border: "1px solid gray"}}>Status Reason</th>
                        <th style={{border: "1px solid gray"}}>Status Reason Code</th>
                        <th style={{border: "1px solid gray"}}>Ημερομηνία</th>
                        <th style={{border: "1px solid gray"}}>Response Code</th>
                        <th style={{border: "1px solid gray"}}>Απάντηση</th>
                      </tr>
                      </thead>
                      <tbody>
                      {SALES.editSales?.peppol?.responseHistory.map((row, idx) => (
                          <tr key={`peppolHistory${idx}`}>
                            <td style={{border: "1px solid gray"}}>{row["statusReason"]}</td>
                            <td style={{border: "1px solid gray"}}>{row["statusReasonCode"]}</td>
                            <td style={{border: "1px solid gray"}}>{isEmpty(row["responseTimestamp"]) ? String(row["effectiveDate"]).substring(0, 10) : row["responseTimestamp"]}</td>
                            <td style={{border: "1px solid gray"}}>{row["responseCode"]}</td>
                            <td style={{border: "1px solid gray"}}>{getGreekPeppolResponseFromCode(row["responseCode"])}</td>
                          </tr>
                      ))}
                      </tbody>
                    </table>
                ) : (
                    <>Δεν βρέθηκε ιστορικό.</>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={() => setShowPeppolHistory(false)}>
                  Κλείσιμο
                </Button>
              </Modal.Footer>
            </Modal>
        )}
      </div>

      <SalesPrintA5Greek ref={componentRefA5} data={printTemplateData}/>
      <SalesPrintA5English ref={componentRefA5En} data={printTemplateData}/>
      <SalesPrintA5DoubleGreek ref={componentRefDA5} data={printTemplateData}/>
      <SalesPrintA5DoubleEnglish ref={componentRefDA5En} data={printTemplateData}/>
      <SalesPrintTemplateGreek1 ref={componentRef} data={printTemplateData}/>
      <SalesPrintTemplateEnglish1 ref={componentRefEn} data={printTemplateData} />
    </div>
  )
}

export const SalesEditLog = () => {
  const { t } = useTranslation('common');
  const SALES = useSelector((state) => state.SALES);

  return (
      <React.Fragment>
        <div className="text-muted mb-2"><i>{t('Logs.saleLog')}</i></div>
        <LogTable itemId={SALES.editSales._id}/>
      </React.Fragment>
  )
};

export default ViewSales;
