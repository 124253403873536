import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Row, Spinner} from "react-bootstrap";
import DateBox from "../../../primer/DateBox";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import axios from "axios";
import {jsPDF} from "jspdf";
import "jspdf-autotable";
import {
    checkPermission,
    getOptionsByData, isEmpty
} from "../../../../_helpers/commonFunctions";
import Dropdown from "../../../primer/Dropdown";
import DropDown from "react-bootstrap/Dropdown";
import {
    fetchSalesGeneralSettings
} from "../../../../_apis/api";
import html2canvas from 'html2canvas';
import {
    setSalesGraphicReportRequestData, setSalesGraphicReportResponseData
} from "../../../../_reducers/DataPanel/Sales/salesSlice";
import {useTranslation} from "react-i18next";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import moment from "moment/moment";
import {classicStyleBelowNavbar} from "../Statics";
import {toast} from "react-toastify";
import Chart from "react-apexcharts";

const SalesGraphicReport = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const requestData = useSelector((state) => state.SALES.salesGraphicReportRequestData);
    const responseData = useSelector((state) => state.SALES.salesGraphicReportResponseData);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const SALES_SETTINGS = useSelector((state) => state.SALES_SETTINGS);
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));

    const [clientsList, setClientsList] = useState([]);
    const [loading, setLoading] = useState(false);

    let paymentTypeOptions = getOptionsByData(SALES_SETTINGS.salesGeneralSettings.paymentTypes, "_id", "name");

    const [chartOptions, setChartOptions] = useState({});
    const [chartSeries, setChartSeries] = useState([]);

    const [iframeData, setIFrameData] = useState("");
    const iframeRef = useRef();

    const monthLabels = [
        t("January"),
        t("February"),
        t("March"),
        t("April"),
        t("May"),
        t("June"),
        t("July"),
        t("August"),
        t("September"),
        t("October"),
        t("November"),
        t("December"),
    ];

    useEffect(() => {
        if (responseData?.data?.length > 0) {
            let co = {
                xaxis: {
                    categories: [],
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                yaxis: {
                    labels: {
                        formatter: (value) => {
                            return `${value} €`
                        }
                    }
                },
                colors: ["#d95f02", "#1b9e77", "#feb019"]
            }
            let s = [
                {
                    name: "Πωλήσεις",
                    data: []
                },
                {
                    name: "Εισπράξεις",
                    data: []
                },
                {
                    name: "Υπόλοιπο",
                    data: [],
                }
            ]
            for (let mo of monthLabels) {
                co.xaxis.categories.push(mo);
            }
            for (let monthlyData of responseData.data) {
                s[0].data.push(monthlyData.sales);
                s[1].data.push(monthlyData.payments);
                s[2].data.push(monthlyData.balance);
            }
            setChartOptions(co);
            setChartSeries(s);
        }
    }, [responseData.data])

    useEffect(() => {
        dispatch(fetchSalesGeneralSettings({ company: company.id }));
        loadClientsListData(null, setClientsList);

        if (requestData.firstLoad) {
            const currentYear = Number(moment().format("YYYY"));
            if (Number(company.year) < currentYear) {
                dispatch(setSalesGraphicReportRequestData({
                    ...requestData,
                    date_from: `01/01/${company.year}`,
                    date_to: `31/12/${company.year}`,
                    firstLoad: false
                }));
            } else {
                dispatch(setSalesGraphicReportRequestData({
                    ...requestData,
                    date_from: moment().startOf("year").format("DD/MM/YYYY"),
                    date_to: moment().endOf("year").format("DD/MM/YYYY"),
                    firstLoad: false
                }));
            }
        }
    }, []);

    const handleExport = (exportType) => {
        const input = document.getElementById('resultDiv');
        html2canvas(input).then((canvas) => {
            if (exportType === "PRINTER" || exportType === "PDF") {
                let imgWidth = 280;
                let imgHeight = canvas.height * imgWidth / canvas.width;
                const imgData = canvas.toDataURL('img/png');
                const pdf = new jsPDF('landscape', 'mm', 'a4');
                pdf.addImage(imgData, 'PNG', 3, 40, imgWidth, imgHeight);
                {
                    const imgData = 'assets/images/prrimer-logo.png';
                    const imgX = pdf.internal.pageSize.getWidth() - 45;
                    const imgY = 23;
                    pdf.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
                }
                if (exportType === "PDF") {
                    pdf.save("Γραφική Αναφορά Πωλήσεων.pdf");
                } else if (exportType === "PRINTER") {
                    setIFrameData(`${pdf.output('bloburl')}`);
                    setTimeout(() => {
                        const iframeWindow = iframeRef.current.contentWindow;
                        iframeWindow.print();
                    }, 200)
                }
            } else if (exportType === "PNG") {
                const dataURL = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = dataURL;
                link.download = 'Γραφική Ανάλυση Πωλήσεων.png';
                link.click();
            }
        })
    }

    const loadClientsListData = (keyword, setData) => {
        let reqData = {
            company: company.id,
        }
        if (!isEmpty(keyword)) reqData.name = keyword;
        axios.post(process.env.REACT_APP_API_URL2 + `/client/list-client`, reqData, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                if (res.data.data) {
                    if (setData) setData(res.data.data);
                } else {
                    if (setData) setData([]);
                }
            } else {
                if (setData) setData([]);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const clientDropdownColumns = [
        {
            label: t('SalesGraphicReport.table2.clientName'),
            name: "name",
        },
        {
            label: t('SalesGraphicReport.table2.vatNumber'),
            name: "vatNumber",
        },
        {
            label: t('SalesGraphicReport.table2.phoneNumber'),
            name: "phone",
        },
    ]

    const handleOnChange = (e, type, name) => {
        if (type === "datebox") {
            if (moment(e).isValid()) {
                dispatch(setSalesGraphicReportRequestData({...requestData, [name] : moment(e).format("DD/MM/YYYY")}));
            } else {
                dispatch(setSalesGraphicReportRequestData({...requestData, [name] : ""}));
            }
        } else if (type === "dropdown") {
            dispatch(setSalesGraphicReportRequestData({...requestData, [name] : e}));
        } else if (type === "acdd") {
            if (name === "clientName") {
                dispatch(setSalesGraphicReportRequestData({...requestData, clientId: e.row._id, clientName : e.row.name}));
            }
        }
    }

    const fetchReport = () => {
        setLoading(true);
        let reqData = {
            ...requestData,
            company: company.id,
            year: company.year,
            paymentType: requestData.paymentType.map(el => el.value),
        }
        axios.post(process.env.REACT_APP_API_URL2 + `/report/sales-graphic-report`, reqData, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                dispatch(setSalesGraphicReportResponseData({...responseData, data: res.data.data}));
                toast.success("Τα δεδομένα ανανεώθηκαν.", {autoClose: 1000});
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            setLoading(false);
            console.log(error);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row className={"mb-2"}>
                <Col md={3}>
                    <DateHelper
                        dateFromAndToFunction={(from, to) => dispatch(setSalesGraphicReportRequestData({...requestData, date_from: from, date_to: to}))}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        name="date_from"
                        selected={isEmpty(requestData.date_from) ? moment().toDate() : moment(requestData.date_from, "DD/MM/YYYY").toDate()}
                        label={t('SalesBalanceSheet.dateFrom')}
                        onChange={(e) => handleOnChange(e, "datebox", "date_from")}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        name="date_to"
                        selected={isEmpty(requestData.date_to) ? moment().toDate() : moment(requestData.date_to, "DD/MM/YYYY").toDate()}
                        label={t('SalesBalanceSheet.dateTo')}
                        onChange={(e) => handleOnChange(e, "datebox", "date_to")}
                    />
                </Col>
                <Col md={5}>
                    <AdvancedCombobox
                        ACName="Sales Client" // do not change name
                        className="w-100 ac-select-dropdown"
                        searchableDropdownEnable={false}
                        defaultValue={requestData.clientName}
                        label={t("General.client")}
                        data={clientsList}
                        onChange={(e) => handleOnChange(e, "acdd", "clientName")}
                        resetFilter={() => dispatch(setSalesGraphicReportRequestData({...requestData, clientId: "", clientName: ""}))}
                        onChangeSearch={(keyword, setData) => loadClientsListData(keyword, setData)}
                        excludeColumns={[]}
                        columns={clientDropdownColumns}
                        editEnable={false}
                        deleteEnable={false}
                        searchTableEnable={false}
                        showTableOptionOnSelect={true}
                        placeholder={t("Placeholders.selectClient")}
                        required={true}
                        name="clientName"
                        showInsertAddButton={true}
                        hideSaveButton={true}
                        hideInsertNewRow={true}
                        inputFieldClasses="form-control"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <Dropdown
                        name={"paymentType"}
                        label={t('SalesBalanceSheet.paymentType')}
                        options={paymentTypeOptions}
                        defaultValue={requestData.paymentType}
                        multiSelect={true}
                        key={Math.random()}
                        onChange={(e) => handleOnChange(e, "dropdown", "paymentType")}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12} className={"ml-auto"}>
                    <Button className="btn btn-primary float-right" onClick={() => fetchReport()} disabled={loading}>
                        {t('General.applyFilter')} {loading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                    </Button>
                </Col>
            </Row>

            {responseData?.data?.length > 0 && (
                <React.Fragment>
                    <hr style={{color: 'gray', height: 1}}/>
                    <div style={{display: "none"}} className="print-me" id="printReport">
                        <iframe ref={iframeRef} style={{width: "100%"}} src={iframeData} title={"Print Report"}></iframe>
                    </div>
                    <div id="resultDiv">
                        <Row className="mt-5">
                            <Col md={12}>
                                <h3
                                    style={{
                                        textAlign: "center",
                                        alignItems: "center",
                                        fontWeight: "bold",
                                        marginTop: "10px"
                                    }}
                                >
                                    {t("Headers.SalesGraphicReport")}
                                </h3>
                                <Chart
                                    options={chartOptions}
                                    series={chartSeries}
                                    type="bar"
                                    height={"380px"}
                                    width={"100%"}
                                />
                            </Col>
                        </Row>
                    </div>
                    {(checkPermission("sales-reports/salesgraphicreport-graphicreport-print", permissionsData) === true) && (
                        <div className="extra-features">
                            <div className="bulk-actions mt-3">
                                <DropDown className="mb-3">
                                    <DropDown.Toggle variant="primary" id="dropdown-basic">
                                        {t('SalesGraphicReport.printResults.printResults')}
                                    </DropDown.Toggle>
                                    <DropDown.Menu>
                                        <DropDown.Item onClick={() => handleExport("PRINTER")}>
                                            {t("General.printer")}
                                        </DropDown.Item>
                                        <DropDown.Item onClick={() => handleExport("PDF")}>
                                            {t("General.exportPDF")}
                                        </DropDown.Item>
                                        <DropDown.Item onClick={() => handleExport("PNG")}>
                                            {t("General.exportPNG")}
                                        </DropDown.Item>
                                    </DropDown.Menu>
                                </DropDown>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
        </div>
    )
}

export default SalesGraphicReport;
