import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {classicStyleBelowNavbar} from "../Statics";
import {Badge, Button, Col, Row, Spinner, Table} from "react-bootstrap";
import {
    setPrimerPaymentsAdminRequestData,
    setPrimerPaymentsAdminResponseData,
} from "../../../../_reducers/DataPanel/Clients/clientSlice";
import axios from "axios";
import {toast} from "react-toastify";
import moment from "moment";
import DateBox from "../../../primer/DateBox";
import Input from "../../../common/Input";
import {getCurrentUser, getGreekCurrencyFormat, isEmpty} from "../../../../_helpers/commonFunctions";
import Dropdown from "../../../primer/Dropdown";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import BootstrapDropdown from "react-bootstrap/Dropdown";
import {jsPDF} from "jspdf";
import {liberationSerif} from "../../../../fonts/liberationSerif";
import {dejavuSans} from "../../../../fonts/DejaVuSans-normal";
import {dejavuSansBold} from "../../../../fonts/DejaVuSans-Bold-bold";
import * as XLSX from "xlsx";
import {Popover} from "antd";
import Modal from "react-bootstrap/Modal";
import TextArea from "../../../common/TextArea";
import "./styles.css";
import "./../../../primer/POPUP-COMPONENTS/modalWidths.css";

const ReportsPrimerPaymentsAdmin = () => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const requestData = useSelector((state) => state.CLIENTS_DATA.reportPrimerPaymentsAdminRequestData);
    const responseData = useSelector((state) => state.CLIENTS_DATA.reportPrimerPaymentsAdminResponseData);
    const [subscriptionNames, setSubscriptionNames] = useState([]);
    const [incrKey, setIncrKey] = useState(0);
    const [pageChanged, setPageChanged] = useState(false);
    const [loading, setLoading] = useState(false);

    const permissionsData = JSON.parse(localStorage.getItem("user"))?.permissions;
    const isSales = permissionsData?.some((el) => el.permissionName === "custom-sales");

    const [showNewNoteModal, setShowNewNoteModal] = useState(false);
    const [noteFor, setNoteFor] = useState({
        subscription_id: "",
        order_id: "",
    })
    const [note, setNote] = useState("");
    const [loadingNewNote, setLoadingNewNote] = useState(false);

    const [notes, setNotes] = useState([]);
    const [showNotesModal, setShowNotesModal] = useState(false);

    useEffect(() => {
        getSubscriptionNames();
    }, [])

    useEffect(() => {
        if (pageChanged === true) {
            setPageChanged(false);
            fetchReport(false);
        }
    }, [pageChanged])

    const getSubscriptionNames = () => {
        axios.get(process.env.REACT_APP_API_URL2 + `/settings/get-subscription-names`, {
            headers: { "Content-Type": "application/json" },
            params: {
                company: company.id,
                user: JSON.parse(localStorage.getItem("user"))._id,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                let finalData = [];
                if(res.data.data && res.data.data?.length > 0) {
                    res.data.data.forEach((sub) => {
                        finalData.push({
                            label: sub,
                            value: sub,
                        })
                    })
                }
                setSubscriptionNames(finalData);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const fetchReport = (showMsg) => {
        if (!requestData.dateFrom || !requestData.dateTo) {
            return toast.error("Παρακαλώ επιλέξτε ημερομηνία από και έως.");
        }
        setLoading(true);
        let cloneReq = {...requestData};
        if (showMsg) cloneReq.page = 1;
        dispatch(setPrimerPaymentsAdminRequestData({...cloneReq}));
        axios.get(process.env.REACT_APP_API_URL2 + `/client/get-primer-payments-report-admin`, {
            headers: { "Content-Type": "application/json" },
            params: {
                ...cloneReq,
                status: cloneReq.status.flatMap((o) => o.value),
                cancel_reason : cloneReq?.cancel_reason?.flatMap((o) => o.value),
                company: company.id,
                user: JSON.parse(localStorage.getItem("user"))._id
            }
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                console.log(res.data.data)
                dispatch(setPrimerPaymentsAdminResponseData(res.data.data));
                setIncrKey(incrKey + 1);
                if(showMsg) toast.success("Τα δεδομένα ανανεώθηκαν.", {autoClose: 1000});
            }
        }).catch((err) => {
            setLoading(false);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            console.log(err);
        })
    }

    const handleOnChange = (e, type, name) => {
        if (type === "input") {
            dispatch(setPrimerPaymentsAdminRequestData({...requestData, [name] : e.target.value}));
        } else if (type === "datebox") {
            if (moment(e).isValid()) {
                dispatch(setPrimerPaymentsAdminRequestData({...requestData, [name] : moment(e).format("DD/MM/YYYY")}));
            } else {
                dispatch(setPrimerPaymentsAdminRequestData({...requestData, [name] : ""}));
            }
        } else if (type === "msdd") {
            dispatch(setPrimerPaymentsAdminRequestData({...requestData, [name] : e}));
        } else if (type === "dropdown") {
            dispatch(setPrimerPaymentsAdminRequestData({...requestData, [name] : e.value}));
        }
    }

    const firstPage = () => {
        dispatch(setPrimerPaymentsAdminRequestData({...requestData, page: 1}));
        setPageChanged(true);
    }
    const previousPage = () => {
        dispatch(setPrimerPaymentsAdminRequestData({...requestData, page: requestData.page - 1}));
        setPageChanged(true);
    }
    const nextPage = () => {
        dispatch(setPrimerPaymentsAdminRequestData({...requestData, page: requestData.page + 1}));
        setPageChanged(true);
    }
    const lastPage = () => {
        dispatch(setPrimerPaymentsAdminRequestData({...requestData, page: responseData.totalPages}));
        setPageChanged(true);
    }

    const statusOptions = isSales ? [
        {label: "Αποτυχία πληρωμής", value: "pending"},
        {label: "Ακύρωση συνδρομής", value: "cancelled"},
        {label: "Μελλοντική πληρωμή με λήξη κάρτας", value: "future-card-expire"},
    ] : [
        {label: "Επιτυχής πρώτη αγορά", value: "completed-parent"},
        {label: "Επιτυχής ανανέωση", value: "completed-renewal"},
        {label: "Επιτυχής ανανέωση μετά αποτυχίας", value: "completed-renewal-retries"},
        {label: "Επιτυχής αναβάθμιση", value: "completed-switch"},
        {label: "Αποτυχία πληρωμής", value: "pending"},
        {label: "Ακύρωση συνδρομής", value: "cancelled"},
        {label: "Ακύρωση συνδρομής με πληρωμή μέσα στην περίοδο", value: "cancelled-in-period"},
        {label: "Μελλοντική πληρωμή", value: "future"},
        {label: "Μελλοντική πληρωμή με λήξη κάρτας", value: "future-card-expire"},
    ]

    const cancelledReasons = [
        {label: "Το πρόγραμμα δεν ανταποκρίνεται στις ανάγκες μου", value: "Το πρόγραμμα δεν ανταποκρίνεται στις ανάγκες μου."},
        {label: "Αργή εξυπηρέτηση για επίλυση προβλήματος", value: "Αργή εξυπηρέτηση για επίλυση προβλήματος"},
        {label: "Θα προχωρήσω σε κλείσιμο της επιχείρησης", value: "Θα προχωρήσω σε κλείσιμο της επιχείρησης."},
        {label: "Το πρόγραμμα είχε αποκτηθεί για δοκιμή ή για κάλυψη προσωρινής ανάγκης", value: "Το πρόγραμμα είχε αποκτηθεί για δοκιμή ή για κάλυψη προσωρινής ανάγκης."},
        {label: "Το κόστος της συνδρομής είναι υψηλό", value: "Το κόστος της συνδρομής είναι υψηλό."},
        {label: "Θα προχωρήσω σε αγορά άλλου προϊόντος της Primer", value: "Θα προχωρήσω σε αγορά άλλου προϊόντος της Primer."},
        {label: "Άλλο", value: "Άλλο"},
    ]

    const getBackgroundColor = (row) => {
        const status = row.status;
        if (String(status).startsWith("completed")) {
            return "#b1fcb1";
        } else if (status === "pending") {
            return "#fff5bd";
        } else if (status === "cancelled") {
            return "#ffcfcf";
        } else if (String(status).startsWith("future")) {
            return "#b8cfff";
        }
        return "";
    }

    const getStatusText = (row, asComponent = false) => {
        const status = row.status;
        if (status === "completed-parent") {
            return "Επιτυχής πρώτη αγορά";
        } else if (status === "completed-renewal") {
            const retries = row.retries.length;
            if (asComponent && retries >= 1) {
                return (
                    <React.Fragment>
                        Επιτυχής ανανέωση{" "}
                        <Popover placement={"top"} title={<div style={{textAlign: "center"}}>Ιστορικό Προσπαθειών</div>} content={
                            <div style={{maxWidth: "220px"}}>
                                {row.retries.map((r) => (
                                    <div style={{textAlign: "center"}}>{moment(r.scheduled_time, "YYYY/MM/DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")}</div>
                                ))}
                            </div>
                        }><u>({retries} {retries === 1 ? "προσπάθεια" : "προσπάθειες"})</u></Popover>
                        {row.secondDate && (
                            <span><br/>Πληρωμή: {moment(row.secondDate).format("DD/MM/YYYY HH:mm:ss")}</span>
                        )}
                    </React.Fragment>
                )
            } else {
                let str = "Επιτυχής ανανέωση";
                if (retries > 1) str += ` (${retries} ${retries === 1 ? "προσπάθεια" : "προσπάθειες"})`
                return str;
            }
        } else if (status === "completed-switch") {
            return "Επιτυχής αναβάθμιση";
        } else if (status === "pending") {
            const retries = row.retries.length + 1;
            if (asComponent) {
                const clone = structuredClone(row.retries);
                clone.push({ scheduled_time: `${row.date}` })
                return (
                    <React.Fragment>
                        Αποτυχία πληρωμής {" "}
                        <Popover placement={"top"} title={<div style={{textAlign: "center"}}>Ιστορικό Προσπαθειών</div>} content={
                            <div style={{maxWidth: "220px"}}>
                                {clone.map((r) => (
                                    <div style={{textAlign: "center"}}>{moment(r.scheduled_time, "YYYY/MM/DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")}</div>
                                ))}
                            </div>
                        }><u>({retries} {retries === 1 ? "προσπάθεια" : "προσπάθειες"})</u></Popover>
                        {row.secondDate && (
                            <span><br/>Πληρωμή: {moment(row.secondDate).format("DD/MM/YYYY HH:mm:ss")}</span>
                        )}
                        {row.card_expire && (
                            <span><br/>Λήξη κάρτας: {row.card_expire}</span>
                        )}
                    </React.Fragment>
                )
            } else {
                return `Αποτυχία πληρωμής (${retries} ${retries === 1 ? "προσπάθεια" : "προσπάθειες"})`;
            }
        } else if (status === "cancelled") {
            if (!asComponent) {
                return `Ακύρωση συνδρομής`;
            } else {
                return (
                    <React.Fragment>
                        Ακύρωση συνδρομής
                        {!isEmpty(row.cancel_reason) && (
                            <span><br/>Αιτία: {row.cancel_reason}</span>
                        )}
                        {!isEmpty(row.next_cancelled_payment_date) && (
                            <span><br/>Υπ. Μελλοντική Πληρωμή: {moment(row.next_cancelled_payment_date).format("DD/MM/YYYY HH:mm:ss")}</span>
                        )}
                    </React.Fragment>
                )
            }
        } else if (status === "future") {
            return "Μελλοντική πληρωμή";
        } else if (status === "future-card-expire") {
            if (asComponent) {
                return (
                    <React.Fragment>
                        Μελλοντική πληρωμή <br/>
                        <img height={"16px"} src={"assets/images/warning.png"} alt={"Warning"} />
                        <span>(Λήξη κάρτας <strong>{row.card_expire}</strong>)</span>
                        <img height={"16px"} src={"assets/images/warning.png"} alt={"Warning"} />
                    </React.Fragment>
                )
            } else {
                return `Μελλοντική πληρωμή (Λήξη κάρτας ${row.card_expire})`;
            }
        }
        return "";
    }

    const handlePDFExport = async () => {
        try {
            const apiResponse = await axios.get(process.env.REACT_APP_API_URL2 + `/client/get-primer-payments-report-admin`, {
                headers: { "Content-Type": "application/json" },
                params: {
                    ...requestData,
                    status: requestData.status.flatMap((o) => o.value),
                    cancel_reason : requestData?.cancel_reason?.flatMap((o) => o.value),
                    company: company.id,
                    user: getCurrentUser()?._id,
                    page: -1,
                }
            })
            if (apiResponse.data.status === "200") {
                let rows = [];
                const headerColumns = ["Αρ.", "Αρ. Συνδρομής", "Όνομα Πελάτη", "ΑΦΜ Πελάτη", "Όνομα συνδρομής", "Ημερομηνία πράξης", "Πράξη", "Καθαρή Αξία"]
                for (let d of apiResponse.data.data.data) {
                    rows.push([d.no, d.subscription_id, d.clientName, d.clientVATNumber, d.subscriptionName, moment(d.date).format("DD/MM/YYYY HH:mm:ss"), getStatusText(d), getGreekCurrencyFormat(d.price)])
                }
                rows.push(["", "", "", "", "", "", "ΣΥΝΟΛΟ", getGreekCurrencyFormat(apiResponse.data.data.totalNetValue)]);

                const doc = new jsPDF('landscape');
                let y = 20;
                doc.addFileToVFS("arialuni.ttf", liberationSerif);
                doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
                doc.addFileToVFS("dejavusans.ttf", dejavuSans);
                doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
                doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
                doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
                doc.setFont("DejaVu Sans Bold");
                doc.setFontSize(10);
                const header = doc.splitTextToSize(`Πράξεις Συνδρομών`, 200);
                const headerString = header.join("\n");
                doc.text(headerString, doc.internal.pageSize.getWidth() / 4, 9, {alignment: "center"});
                doc.setFont("DejaVu Sans Normal");
                const imgData = 'assets/images/prrimer-logo.png';
                const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
                const imgY = 3; // adjust the position as needed
                doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
                doc.setFont("DejaVu Sans Normal");
                doc.autoTable({
                    startY: y,
                    head: [headerColumns],
                    body: rows,
                    rowPageBreak: "avoid",
                    styles: {
                        font: "DejaVu Sans Normal",
                        fontSize: 8, // set font size
                        halign: "center", // set alignment
                    },
                })
                const pageCount = doc.getNumberOfPages();
                // Add the pagination to each page
                for (let i = 1; i <= pageCount; i++) {
                    doc.setPage(i);
                    doc.setFontSize(10);
                    doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
                }
                doc.save("Πράξεις Συνδρομών.pdf");
            } else {
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            }
        } catch (error) {
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            console.log(error);
        }
    }

    const handleExcelExport = async () => {
        try {
            const apiResponse = await axios.get(process.env.REACT_APP_API_URL2 + `/client/get-primer-payments-report-admin`, {
                headers: { "Content-Type": "application/json" },
                params: {
                    ...requestData,
                    status: requestData.status.flatMap((o) => o.value),
                    cancel_reason : requestData?.cancel_reason?.flatMap((o) => o.value),
                    company: company.id,
                    user: getCurrentUser()?._id,
                    page: -1,
                }
            })
            if (apiResponse.data.status === "200") {
                let rows = [];
                const headerColumns = ["Αρ.", "Αρ. Συνδρομής", "Όνομα Πελάτη", "ΑΦΜ Πελάτη", "Όνομα συνδρομής", "Ημερομηνία πράξης", "Πράξη", "Καθαρή Αξία"]
                rows.push(headerColumns);
                for (let d of apiResponse.data.data.data) {
                    rows.push([d.no, d.subscription_id, d.clientName, d.clientVATNumber, d.subscriptionName, moment(d.date).format("DD/MM/YYYY HH:mm:ss"), getStatusText(d), +d.price])
                }
                const workSheet = XLSX.utils.aoa_to_sheet(rows);
                const workBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workBook, workSheet, "Πράξεις Συνδρομών");
                XLSX.writeFile(workBook, 'Πράξεις Συνδρομών.xlsx');
            } else {
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            }
        } catch (err) {
            setLoading(false);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            console.log(err);
        }
    }

    const handleSaveNewNote = () => {
        setLoadingNewNote(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/client/post-primer-report-note`, {
            ...noteFor,
            note: note,
            company: company.id,
        }, {
            headers: {"Content-Type": "application/json"},
        }).then((res) => {
            setLoadingNewNote(false);
            if (res.data.status === "200") {
                toast.success("Επιτυχής προσθήκη σημείωσης.");
                setNote("");
                setShowNewNoteModal(false);
                fetchReport(false);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoadingNewNote(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const formatBigNote = (str) => String(str).length > 60 ? String(str).slice(0, 60) + "..." : str;

    return (
        <div style={classicStyleBelowNavbar} onKeyDown={(e) => {
            if (e.key === "Enter") fetchReport(true)}
        }>
            <Row>
                <Col md={2}>
                    <DateHelper
                        dateFromAndToFunction={(from, to) => dispatch(setPrimerPaymentsAdminRequestData({...requestData, dateFrom: from, dateTo: to}))}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        label={"Ημ/νία από"}
                        allowOtherYears={true}
                        selected={moment(requestData.dateFrom, "DD/MM/YYYY").isValid() ? moment(requestData.dateFrom, "DD/MM/YYYY").toDate() : null}
                        onChange={(e) => handleOnChange(e, "datebox", "dateFrom")}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        label={"Ημ/νία έως"}
                        allowOtherYears={true}
                        selected={moment(requestData.dateTo, "DD/MM/YYYY").isValid() ? moment(requestData.dateTo, "DD/MM/YYYY").toDate() : null}
                        onChange={(e) => handleOnChange(e, "datebox", "dateTo")}
                    />
                </Col>
                <Col md={3}>
                    <Input
                        name="filterClientName"
                        label="Όνομα/ΑΦΜ Πελάτη"
                        autoComplete="off"
                        value={requestData.clientName}
                        onChange={(e) => handleOnChange(e, "input", "clientName")}
                    />
                </Col>
                <Col md={3}>
                    <Dropdown
                        name="filterSubscriptionNames"
                        label="Συνδρομές"
                        key={Math.random()}
                        multiSelect={true}
                        options={subscriptionNames}
                        defaultValue={requestData.subscriptionNames}
                        onChange={(e) => handleOnChange(e, "msdd", "subscriptionNames")}
                    />
                </Col>
                <Col md={4}>
                    <Dropdown
                        name={"status"}
                        label={"Πράξη"}
                        key={Math.random()}
                        options={statusOptions}
                        multiSelect={true}
                        defaultValue={requestData.status}
                        onChange={(e) => handleOnChange(e, "msdd", "status")}
                    />
                </Col>
                {requestData?.status?.some((s) => s.value ==="cancelled" || s.value ==="cancelled-in-period") && (
                    <Col md={4}>
                        <Dropdown
                            name={"cancel_reason"}
                            label="Λόγος Ακύρωσης Συνδρομής"
                            key={Math.random()}
                            options={cancelledReasons}
                            multiSelect={true}
                            defaultValue={requestData.cancel_reason}
                            onChange={(e) => handleOnChange(e, "msdd", "cancel_reason")}
                        />
                    </Col>
                )}
            </Row>
            <Row className="mb-3 mt-2 justify-content-end">
                <Col md={3} style={{display: "flex", justifyContent: "right"}}>
                    <Button onClick={() => fetchReport(true)} disabled={loading}>Εφαρμογή φίλτρων και αναζήτηση</Button>
                </Col>
            </Row>
            {responseData.totalPages > 0 ? (
                <React.Fragment>
                    <hr/>
                    <Row className="mb-3 justify-content-end">
                        <Col md={9} style={{display: "flex", justifyContent: "right"}}>
                            <Button size="sm" className="mr-1" onClick={() => firstPage()} disabled={requestData.page === 1 || loading}>{"<< Πρώτη"}</Button>
                            <Button size="sm" className="mr-1" onClick={() => previousPage()} disabled={requestData.page === 1 || loading}>{"< Προηγούμενη"}</Button>
                            <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1" bg="primary">Σελίδα {requestData.page} / {responseData.totalPages}</Badge>
                            <Button size="sm" className="mr-1" onClick={() => nextPage()} disabled={requestData.page === responseData.totalPages || loading}>{"Επόμενη >"}</Button>
                            <Button size="sm" className="mr-1" onClick={() => lastPage()} disabled={requestData.page === responseData.totalPages || loading}>{"Τελευταία >>"}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <table className={"simpleClassicTable mb-2"} style={{width: "100%"}}>
                                <colgroup>
                                    <col span={1} style={{width: "3%"}}></col>
                                    <col span={1} style={{width: "7%"}}></col>
                                    <col span={1} style={{width: "20%"}}></col>
                                    <col span={1} style={{width: "10%"}}></col>
                                    <col span={1} style={{width: "15%"}}></col>
                                    <col span={1} style={{width: "10%"}}></col>
                                    <col span={1} style={{width: "15%"}}></col>
                                    <col span={1} style={{width: "10%"}}></col>
                                    <col span={1} style={{width: "10%"}}></col>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th>Αρ.</th>
                                    <th>Αρ. Συνδρομής</th>
                                    <th>Όνομα πελάτη</th>
                                    <th>ΑΦΜ Πελάτη</th>
                                    <th>Όνομα συνδρομής</th>
                                    <th>Ημερομηνία πράξης</th>
                                    <th>Πράξη</th>
                                    <th>Καθαρή Αξία</th>
                                    <th>Σημειώσεις</th>
                                </tr>
                                </thead>
                                <tbody>
                                {responseData.data.map((row, idx) => (
                                    <tr key={`rppa-${idx}`} style={{backgroundColor: getBackgroundColor(row)}}>
                                        <td>{row.no}</td>
                                        <td>{row.subscription_id}</td>
                                        <td>{row.clientName}</td>
                                        <td>{row.clientVATNumber}</td>
                                        <td>{row.subscriptionName}</td>
                                        <td>{moment(row.date).format("DD/MM/YYYY HH:mm:ss")}</td>
                                        <td>{getStatusText(row, true)}</td>
                                        <td>{getGreekCurrencyFormat(row.price)}</td>
                                        <td>
                                            {(row.status === "cancelled" || row.status === "pending" || row.status === "completed" || String(row.status).startsWith("future")) && (
                                                <div className={"primerPaymentAdminNotes"} style={{
                                                    position: "relative",
                                                    cursor: row.notes?.length > 0 ? "pointer" : "",
                                                }} onClick={() => {
                                                    setNotes(row.notes);
                                                    setShowNotesModal(true);
                                                }}>
                                                    {(row.notes && row.notes?.length > 0) && (
                                                        <React.Fragment>
                                                            <span>{formatBigNote(row.notes[0]?.note)}</span>
                                                        </React.Fragment>
                                                    )}
                                                    {row.status !== "completed" && (
                                                        <Badge bg={"success"} style={{cursor: "pointer", fontSize: "20px", float: "right"}} className={`pl-1 pr-1 pt-0 pb-0 align-items-center d-flex`}
                                                               onClick={(e) => {
                                                                   e.stopPropagation();
                                                                   setNoteFor({
                                                                       subscription_id: row.subscription_id,
                                                                       order_id: row?.order_id ? row.order_id : "",
                                                                   })
                                                                   setShowNewNoteModal(true);
                                                               }}
                                                               title={"Προσθήκη σημείωσης"}><span style={{fontSize: "14px"}} className={"pr-1"}>{row.notes.length > 0 && row.notes.length}</span>+</Badge>
                                                    )}
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row className="mb-3 justify-content-end">
                        <Col md={6}>
                            <Table bordered>
                                <tbody>
                                <tr style={{textAlign: "center"}}>
                                    <td>&nbsp;</td>
                                    <td><strong>{responseData.head1}</strong></td>
                                    <td><strong>{responseData.head2}</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Συνολική καθαρή αξία (με ΦΠΑ)</strong></td>
                                    <td>{getGreekCurrencyFormat(responseData.totalNetValue)} ({getGreekCurrencyFormat(responseData.totalNetValue * 1.24)})</td>
                                    <td>{getGreekCurrencyFormat(responseData.prev_totalNetValue)} ({getGreekCurrencyFormat(responseData.prev_totalNetValue * 1.24)})</td>
                                </tr>
                                <tr>
                                    <td><strong>Συνολικά Έσοδα (με ΦΠΑ)</strong></td>
                                    <td>{getGreekCurrencyFormat(responseData.totalEarnings)} ({getGreekCurrencyFormat(responseData.totalEarnings * 1.24)})</td>
                                    <td>{getGreekCurrencyFormat(responseData.prev_totalEarnings)} ({getGreekCurrencyFormat(responseData.prev_totalEarnings * 1.24)})</td>
                                </tr>
                                <tr>
                                    <td><strong>Συνολικό Λούσιμο (με ΦΠΑ)</strong></td>
                                    <td>{getGreekCurrencyFormat(responseData.totalLoss)} ({getGreekCurrencyFormat(responseData.totalLoss * 1.24)})</td>
                                    <td>{getGreekCurrencyFormat(responseData.prev_totalLoss)} ({getGreekCurrencyFormat(responseData.prev_totalLoss * 1.24)})</td>
                                </tr>
                                <tr>
                                    <td><strong>Ποσοστό επιτυχημένων ανανεώσεων μετά από αποτυχία</strong></td>
                                    <td>{responseData.perc1} {responseData.perc1 !== "-" && `(${responseData.perc1a} / ${responseData.perc1p})`}</td>
                                    <td>{responseData.prev_perc1} {responseData.prev_perc1 !== "-" && `(${responseData.prev_perc1a} / ${responseData.prev_perc1p})`}</td>
                                </tr>
                                <tr>
                                    <td><strong>Ποσοστό αποτυχημένων ανανεώσεων</strong></td>
                                    <td>{responseData.perc2} {responseData.perc2 !== "-" && `(${responseData.perc2a} / ${responseData.perc2p})`}</td>
                                    <td>{responseData.prev_perc2} {responseData.prev_perc2 !== "-" && `(${responseData.prev_perc2a} / ${responseData.prev_perc2p})`}</td>
                                </tr>
                                <tr>
                                    <td><strong>Συνολικές ακυρώσεις</strong></td>
                                    <td>{responseData.cnt1}</td>
                                    <td>{responseData.prev_cnt1}</td>
                                </tr>
                                <tr>
                                    <td><strong>Συνολικές ακυρώσεις & Αποτυχίες πληρωμών</strong></td>
                                    <td>{responseData.cnt2}</td>
                                    <td>{responseData.prev_cnt2}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <BootstrapDropdown className="mb-3 mt-3">
                        <BootstrapDropdown.Toggle variant="primary" id="dropdown-basic">
                                Αποθήκευση αποτελεσμάτων
                            </BootstrapDropdown.Toggle>
                            <BootstrapDropdown.Menu>
                                <BootstrapDropdown.Item onClick={() => handlePDFExport()}>
                                    Αποθήκευση ως PDF
                                </BootstrapDropdown.Item>
                                <BootstrapDropdown.Item onClick={() => handleExcelExport()}>
                                    Αποθήκευση ως XLSX
                                </BootstrapDropdown.Item>
                            </BootstrapDropdown.Menu>
                        </BootstrapDropdown>
                    </Row>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <span></span>
                </React.Fragment>
            )}

            <Modal show={showNewNoteModal} onHide={() => setShowNewNoteModal(false)} backdrop={"static"} dialogClassName={"modal42PercentWidth"}>
                <Modal.Header closeButton>
                    <Modal.Title>Προσθήκη σημείωσης</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Προσθέστε την σημείωση που επιθυμείτε.
                    <TextArea
                        className={"mt-1"}
                        value={note}
                        rows={4}
                        onChange={(e) => setNote(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleSaveNewNote()} disabled={loadingNewNote}>
                        Προσθήκη {loadingNewNote && (
                            <Spinner className={"ml-2"} animation="border" variant="light" />
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNotesModal} onHide={() => setShowNotesModal(false)} dialogClassName={"modal65PercentWidth"}>
                <Modal.Header closeButton>
                    <Modal.Title>Προβολή Σημειώσεων</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className={"simpleClassicTable"}>
                        <colgroup>
                            <col span={1} style={{width: "10%"}}></col>
                            <col span={1} style={{width: "15%"}}></col>
                            <col span={1} style={{width: "15%"}}></col>
                            <col span={1} style={{width: "60%"}}></col>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>Αρ.</th>
                            <th>Ημερομηνία</th>
                            <th>Καταχωρητής</th>
                            <th>Σημείωση</th>
                        </tr>
                        </thead>
                        <tbody>
                        {notes.map((n, i) => (
                            <tr key={`notes-${i}`}>
                                <td>{i + 1}</td>
                                <td>{moment(n.date).format("DD/MM/YYYY HH:mm:ss")}</td>
                                <td>{n.user}</td>
                                <td>{n.note}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ReportsPrimerPaymentsAdmin
