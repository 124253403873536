// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tipButton {
  font-size: 12px;
  cursor: pointer;
  padding: 3px;
}
.tipButton:active {
  transform: scale(0.9);
}
.tipButtons {
  text-align: center;
  display: flex;
  align-content: center;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
}
.tipButtons .tipButton {
  flex: 1 1
}`, "",{"version":3,"sources":["webpack://./src/_components/primer/TipComponent/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,YAAY;AACd;AACA;EACE,qBAAqB;AACvB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,qBAAqB;EACrB,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE;AACF","sourcesContent":[".tipButton {\r\n  font-size: 12px;\r\n  cursor: pointer;\r\n  padding: 3px;\r\n}\r\n.tipButton:active {\r\n  transform: scale(0.9);\r\n}\r\n.tipButtons {\r\n  text-align: center;\r\n  display: flex;\r\n  align-content: center;\r\n  flex-direction: row;\r\n  margin-left: auto;\r\n  margin-right: auto;\r\n}\r\n.tipButtons .tipButton {\r\n  flex: 1\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
