import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../../primer/POPUP-COMPONENTS/Loader/PrimerLoader";
import {Badge, Button, Col, Row, Table} from "react-bootstrap";
import moment from "moment/moment";
import DateBox from "../../../primer/DateBox";
import Dropdown from "../../../primer/Dropdown";
import axios from "axios";
import {toast} from "react-toastify";
import {
    setIsozygioDisplayData,
    setIsozygioRequestData,
    setIsozygioResponseData
} from "../../../../_reducers/DataPanel/Accounting/accountingSlice";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import BetterCheckBox from "../../../common/BetterCheckBox";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import {isEmpty} from "../../../../_helpers/commonFunctions";
import {liberationSerif} from "../../../../fonts/liberationSerif";
import {dejavuSans} from "../../../../fonts/DejaVuSans-normal";
import {dejavuSansBold} from "../../../../fonts/DejaVuSans-Bold-bold";
import {StorageController} from "../../../../_helpers/storage-controller";
import {classicStyleBelowNavbar} from "../Statics";

const AccountingReportIsozygio = () => {
    const dispatch = useDispatch();
    const requestData = useSelector((state) => state.ACCOUNTING_DATA.isozygioRequestData);
    const responseData = useSelector((state) => state.ACCOUNTING_DATA.isozygioResponseData);
    const displayData = useSelector((state) => state.ACCOUNTING_DATA.isozygioDisplayData);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const companyDetails = useSelector((state) => state.COMPANY_DATA.companyDetails);
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const [installationOptions, setInstallationOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [incrKey, setIncrKey] = useState(0);
    const [render, setRender] = useState(false);

    const thStyle = { fontSize: `${displayData.fontSize}px`, width: "15%", paddingTop: "3px", paddingBottom: "3px" }
    const thStyle2 = { fontSize: `${displayData.fontSize}px`, width: "8.75%", paddingTop: "3px", paddingBottom: "3px" }
    const thStyle3 = { fontSize: `${displayData.fontSize}px`, width: "8.75%",  textAlign: "right", paddingTop: "3px", paddingBottom: "3px" }
    const thStyle4 = { fontSize: `${displayData.fontSize}px`, width: "8.75%",  textAlign: "right", paddingTop: "3px", paddingBottom: "3px", backgroundColor: "#bdfcce" }

    const pageRef = useRef();
    const [autoScrollRunning, setAutoscrollRunning] = useState(false);

    const degreeOptions = [
        { value: 1, label: "1ου βαθμού" },
        { value: 2, label: "Μέχρι και 2ου" },
        { value: 3, label: "Μέχρι και 3ου" },
        { value: 4, label: "Μέχρι και 4ου" }
    ]
    const analysisOptions = [
        { value: "all", label: "Ανάλυση Όλων" },
        { value: "installation", label: "Εγκατάσταση" },
        { value: "supplier", label: "Προμηθευτή" },
        { value: "client", label: "Πελάτη" },
        { value: "employee", label: "Εργαζόμενος" },
        { value: "bankAccount", label: "Τραπεζικός Λογαριασμός" },
        { value: "pos", label: "POS" },
        { value: "cashRegister", label: "Ταμειακή Μηχανή" },
        { value: "stockholder", label: "Μέτοχος" }
    ]

    useEffect(() => {
        if(companyInstallations.length > 0) {
            let options = [{ value: "", label: "Όλες"}];
            companyInstallations.forEach((inst) => {
                options.push({ value: inst.installationMasterId, label: inst.type + " " + inst.address + " " + inst.city });
            })
            setInstallationOptions(options);
        }
        setTimeout(() => {
            setRender(true);
        }, 100)
    }, [])

    useEffect(() => {
        if (render) {
            pageRef.current.scrollTo(0, StorageController.getKey("accountingIsozygioScroll"));
            if (!autoScrollRunning) scrollHolder();
            setAutoscrollRunning(true);
        }
    }, [render]);

    const scrollHolder = () => {
        if (pageRef && pageRef?.current && !isEmpty(pageRef?.current?.scrollTop) && pageRef?.current?.scrollTop !== StorageController.getKey("accountingIsozygioScroll")) {
            StorageController.setKey("accountingIsozygioScroll", pageRef.current.scrollTop);
        }
        setTimeout(() => {
            if (pageRef && pageRef?.current && !isEmpty(pageRef?.current?.scrollTop)) scrollHolder();
        }, 250)
    }

    const handleFilterChange = (e, type, name) => {
        if (type === "datebox") {
            if (moment(e).isValid()) {
                dispatch(setIsozygioRequestData({...requestData, [name] : moment(e).format("DD/MM/YYYY")}));
            } else {
                dispatch(setIsozygioRequestData({...requestData, [name] : ""}));
            }
        } else if (type === "dropdown") {
            dispatch(setIsozygioRequestData({...requestData, [name] : e.value}));
        } else if (type === "checkbox") {
            dispatch(setIsozygioRequestData({...requestData, [name] : e.target.checked}));
        } else if (type === "msdd") {
            let onlyValues = [];
            for (let c of e) {
                onlyValues.push(c.value);
            }
            dispatch(setIsozygioRequestData({...requestData, [name] : onlyValues}));
        }
    }

    const fetchReport = () => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL2 + "/report/accounting-isozygio", {...requestData, company: company.id, year: company.year}, {
            headers: { "Content-Type": "application/json" }
        }).then((res) => {
            setLoading(false);
            if(res.data.status === "200") {
                let resData = res.data.data;
                if(resData.lines && resData.lines?.length > 0) {
                    let maxLength = 0;
                    for(let acc of resData.lines) {
                        const length = String(acc.code).replaceAll(".", "").length;
                        if(length > maxLength) maxLength = length;
                    }
                    resData.lines = resData.lines.sort((a, b) => Number(String(a.code).replaceAll(".", "")) < Number(String(b.code).replaceAll(".", "")) ? -1 : 1);
                    resData.lines = resData.lines.sort((a, b) => {
                        let fixedA = String(a.code).replaceAll(".", "");
                        let fixedB = String(b.code).replaceAll(".", "");
                        fixedA += "0".repeat(maxLength - fixedA.length);
                        fixedB += "0".repeat(maxLength - fixedB.length);
                        if(Number(fixedA + "." + String(a).split(".").length) < Number(fixedB + "." + String(b).split(".").length)) {
                            return -1;
                        } else {
                            return 1;
                        }
                    })
                    let degreeTotals = [];
                    for(let i = 1; i <= requestData.degree; i++) {
                        const accounts_i_degree = resData.lines.filter((el) => el.code.split(".").length === i);
                        let prepTotals = {
                            censusDebit: 0,
                            censusCredit: 0,
                            moveDebit: 0,
                            moveCredit: 0,
                            progressivelyDebit: 0,
                            progressivelyCredit: 0,
                            balanceDebit: 0,
                            balanceCredit: 0,
                        }
                        for(let acc of accounts_i_degree) {
                            for(let key in prepTotals) {
                                prepTotals[key] += acc[key];
                            }
                        }
                        for(let key in prepTotals) {
                            prepTotals[key] = Number(prepTotals[key]).toFixed(2);
                        }
                        prepTotals.degree = i;
                        degreeTotals.push(prepTotals);
                    }
                    dispatch(setIsozygioDisplayData({...displayData, degreeTotals: degreeTotals}));
                }
                dispatch(setIsozygioResponseData(resData));
                setIncrKey(incrKey + 1);
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            setLoading(false);
            console.log(error);
            toast.error("Πρόβλημα κατά την αποστολή αιτήματος.");
        })
    }

    const handleEnter = (e) => {
        if(e.key === "Enter") fetchReport();
    }

    const handleExcelExport = () => {
        let dateFrom = requestData.date_from;
        let dateTo = requestData.date_to;
        if (isEmpty(dateFrom)) dateFrom = moment(company.year, "YYYY", true).startOf("year").format("DD/MM/YYYY");
        if (isEmpty(dateTo)) dateTo = moment(company.year, "YYYY", true).endOf("year").format("DD/MM/YYYY");
        const dateExported = moment().format("DD/MM/YYYY HH:mm:ss");

        let rows = [];
        rows.push(["Επωνυμία:", company.name]);
        rows.push(["ΑΦΜ:", company.vatNumber]);
        rows.push(["ΔΟΥ:", companyDetails.doy]);
        rows.push([`Εύρος ημερομηνίας: ${dateFrom} - ${dateTo}`]);
        rows.push([`Ημερομηνία Εξαγωγής: ${dateExported}`]);
        rows.push([]);

        rows.push(["", "", "Απογραφή", "Απογραφή", "Κίνηση", "Κίνηση", "Προοδευτικά", "Προοδευτικά", "Υπόλοιπα", "Υπόλοιπα"]);
        rows.push(["Κωδικός", "Όνομα", "Χρέωση", "Πίστωση", "Χρέωση", "Πίστωση", "Χρέωση", "Πίστωση", "Χρέωση", "Πίστωση"]);
        if(responseData.lines.length > 0) {
            responseData.lines.forEach((line) => {
                rows.push([
                    line.code,
                    line.name,
                    Number(line.censusDebit).toFixed(2),
                    Number(line.censusCredit).toFixed(2),
                    Number(line.moveDebit).toFixed(2),
                    Number(line.moveCredit).toFixed(2),
                    Number(line.progressivelyDebit).toFixed(2),
                    Number(line.progressivelyCredit).toFixed(2),
                    Number(line.balanceDebit).toFixed(2),
                    Number(line.balanceCredit).toFixed(2)
                ])
                if (line?.analysis && line?.analysis?.length > 0) {
                    line?.analysis.forEach((analysis) => {
                        rows.push([
                            "",
                            analysis.subAccount,
                            Number(analysis.censusDebit).toFixed(2),
                            Number(analysis.censusCredit).toFixed(2),
                            Number(analysis.moveDebit).toFixed(2),
                            Number(analysis.moveCredit).toFixed(2),
                            Number(analysis.progressivelyDebit).toFixed(2),
                            Number(analysis.progressivelyCredit).toFixed(2),
                            Number(analysis.balanceDebit).toFixed(2),
                            Number(analysis.balanceCredit).toFixed(2)
                        ])
                    })
                }
            })
        }
        if(displayData.degreeTotals.length > 0) {
            displayData.degreeTotals.slice(0).reverse().forEach((totals) => {
                rows.push([
                    `Σύνολα βαθμού ${totals.degree}`,
                    "",
                    Number(totals.censusDebit).toFixed(2),
                    Number(totals.censusCredit).toFixed(2),
                    Number(totals.moveDebit).toFixed(2),
                    Number(totals.moveCredit).toFixed(2),
                    Number(totals.progressivelyDebit).toFixed(2),
                    Number(totals.progressivelyCredit).toFixed(2),
                    Number(totals.balanceDebit).toFixed(2),
                    Number(totals.balanceCredit).toFixed(2)
                ])
            })
        }
        const workSheet = XLSX.utils.aoa_to_sheet(rows);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Ισοζύγιο");
        XLSX.writeFile(workBook, 'Ισοζύγιο.xlsx');
    }
    const handlePDFExport = () => {
        const doc = new jsPDF('landscape');
        let y = 23;
        doc.addFileToVFS("arialuni.ttf", liberationSerif);
        doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
        doc.addFileToVFS("dejavusans.ttf", dejavuSans);
        doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
        doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
        doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
        doc.setFont("DejaVu Sans Bold");
        doc.setFontSize(12);
        let dateFrom = requestData.date_from;
        let dateTo = requestData.date_to;
        if (isEmpty(dateFrom)) dateFrom = moment(company.year, "YYYY", true).startOf("year").format("DD/MM/YYYY");
        if (isEmpty(dateTo)) dateTo = moment(company.year, "YYYY", true).endOf("year").format("DD/MM/YYYY");
        const header = doc.splitTextToSize(`Ισοζύγιο περιόδου από ${dateFrom} έως ${dateTo}`, 200);
        const headerString = header.join("\n");
        doc.text(headerString, doc.internal.pageSize.getWidth() / 4, 9, {alignment: "center"});
        doc.setFont("DejaVu Sans Normal");
        doc.setFontSize(9);
        doc.text(`${company.name}, ΑΦΜ: ${company.vatNumber}`, 15, 17, {alignment: "center"});
        doc.text(`ΔΟΥ: ${companyDetails.doy}`, 15, 21, {alignment: "center"});
        doc.text(`Ημερομηνία Εξαγωγής: ${moment().format("DD/MM/YYYY")}`, 190, 21, {alignment: "center"});
        const imgData = 'assets/images/prrimer-logo.png';
        const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
        const imgY = 3; // adjust the position as needed
        doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
        doc.setFont("DejaVu Sans Normal");
        const columns1 = ["Κωδικός", "Όνομα", {content: "Απογραφή", colSpan: 2}, {content: "Κίνηση", colSpan: 2}, {content: "Προοδευτικά", colSpan: 2}, {content: "Υπόλοιπα", colSpan: 2}]
        const columns2 = ["", "", "Χρέωση", "Πίστωση", "Χρέωση", "Πίστωση", "Χρέωση", "Πίστωση", "Χρέωση", "Πίστωση"];
        let rows = [];
        if(responseData.lines.length > 0) {
            responseData.lines.forEach((line) => {
                rows.push([
                    {content: line.code, styles: {halign: "left"}},
                    line.name,
                    Number(line.censusDebit).toFixed(2),
                    {content: Number(line.censusCredit).toFixed(2), styles: {fillColor: "#bdfcce"}},
                    Number(line.moveDebit).toFixed(2),
                    {content: Number(line.moveCredit).toFixed(2), styles: {fillColor: "#bdfcce"}},
                    Number(line.progressivelyDebit).toFixed(2),
                    {content: Number(line.progressivelyCredit).toFixed(2), styles: {fillColor: "#bdfcce"}},
                    Number(line.balanceDebit).toFixed(2),
                    {content: Number(line.balanceCredit).toFixed(2), styles: {fillColor: "#bdfcce"}},
                ])
                if (line?.analysis && line?.analysis?.length > 0) {
                    line?.analysis.forEach((analysis) => {
                        rows.push([
                            "",
                            analysis.subAccount,
                            Number(analysis.censusDebit).toFixed(2),
                            {content: Number(analysis.censusCredit).toFixed(2), styles: {fillColor: "#bdfcce"}},
                            Number(analysis.moveDebit).toFixed(2),
                            {content: Number(analysis.moveCredit).toFixed(2), styles: {fillColor: "#bdfcce"}},
                            Number(analysis.progressivelyDebit).toFixed(2),
                            {content: Number(analysis.progressivelyCredit).toFixed(2), styles: {fillColor: "#bdfcce"}},
                            Number(analysis.balanceDebit).toFixed(2),
                            {content: Number(analysis.balanceCredit).toFixed(2), styles: {fillColor: "#bdfcce"}},
                        ])
                    })
                }
            })
        }
        if(displayData.degreeTotals.length > 0) {
            displayData.degreeTotals.slice(0).reverse().forEach((totals) => {
                rows.push([
                    `Σύνολα βαθμού ${totals.degree}`,
                    "",
                    Number(totals.censusDebit).toFixed(2),
                    {content: Number(totals.censusCredit).toFixed(2), styles: {fillColor: "#bdfcce"}},
                    Number(totals.moveDebit).toFixed(2),
                    {content: Number(totals.moveCredit).toFixed(2), styles: {fillColor: "#bdfcce"}},
                    Number(totals.progressivelyDebit).toFixed(2),
                    {content: Number(totals.progressivelyCredit).toFixed(2), styles: {fillColor: "#bdfcce"}},
                    Number(totals.balanceDebit).toFixed(2),
                    {content: Number(totals.balanceCredit).toFixed(2), styles: {fillColor: "#bdfcce"}},
                ])
            })
        }
        doc.autoTable({
            startY: y,
            head: [columns1, columns2],
            body: rows,
            rowPageBreak: "avoid",
            styles: {
                font: "DejaVu Sans Normal",
                fontSize: 8, // set font size
                halign: "center", // set alignment
            },
        })
        const pageCount = doc.getNumberOfPages();
        // Add the pagination to each page
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
        }
        doc.save("Ισοζύγιο.pdf");
    }

    return (
        <React.Fragment>
            {loading && (<Loader doNotShowSmallText={true} />)}
            <div hidden={loading} ref={pageRef} style={classicStyleBelowNavbar} onKeyDown={(e) => handleEnter(e)}>
                <Row>
                    <Col md={2}>
                        <DateHelper
                            dateFromAndToFunction={(from, to) => dispatch(setIsozygioRequestData({...requestData, date_from: from, date_to: to}))}
                        />
                    </Col>
                    <Col md={2}>
                        <DateBox
                            name="date_from"
                            selected={requestData.date_from !== "" ? moment(requestData.date_from, "DD-MM-YYYY").toDate() : ""}
                            label={"Ημερομηνία από"}
                            onChange={(e) => handleFilterChange(e, "datebox", "date_from")}
                        />
                    </Col>
                    <Col md={2}>
                        <DateBox
                            name="date_to"
                            selected={requestData.date_to !== "" ? moment(requestData.date_to, "DD-MM-YYYY").toDate() : ""}
                            label={"Ημερομηνία μέχρι"}
                            onChange={(e) => handleFilterChange(e, "datebox", "date_to")}
                        />
                    </Col>
                    <Col md={3}>
                        <Dropdown
                            name={"installation"}
                            label={"Εγκατάσταση"}
                            options={installationOptions}
                            key={Math.random()}
                            defaultValue={installationOptions.find((el) => el.value === requestData.installation) ? installationOptions.find((el) => el.value === requestData.installation) : ""}
                            onChange={(e) => handleFilterChange(e, "dropdown", "installation")}
                        />
                    </Col>
                    <Col md={3}>
                        <Dropdown
                            name={"degree"}
                            label={"Προβολή βαθμού"}
                            className={"mb-0"}
                            options={degreeOptions}
                            key={Math.random()}
                            defaultValue={degreeOptions.find((el) => el.value === requestData.degree) ? degreeOptions.find((el) => el.value ===requestData.degree) : ""}
                            onChange={(e) => handleFilterChange(e, "dropdown", "degree")}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <BetterCheckBox
                            name={"countClosure"}
                            className={"mt-4"}
                            text={"Συμπερίληψη Κλεισίματος"}
                            checked={requestData.countClosure}
                            onChange={(e) => handleFilterChange(e, "checkbox", "countClosure")}
                        />
                    </Col>
                    <Col md={2}>
                        <BetterCheckBox
                            name={"showNonMovementAcc"}
                            className={"mt-4"}
                            text={"Συμπερίληψη Λογαριασμών που δεν κινήθηκαν στην επιλεγμένη περίοδο"}
                            checked={requestData.showNonMovementAcc}
                            onChange={(e) => handleFilterChange(e, "checkbox", "showNonMovementAcc")}
                        />
                    </Col>
                    <Col md={3}>
                        <Dropdown
                            name={"analysis"}
                            label={"Ανάλυση Υπολογαριασμού"}
                            options={analysisOptions}
                            multiSelect={true}
                            key={Math.random()}
                            defaultValue={analysisOptions.filter((el) => requestData.analysis.includes(el.value))}
                            onChange={(e) => handleFilterChange(e, "msdd", "analysis")}
                        />
                    </Col>
                    <Col md={2}>
                        <Button onClick={() => fetchReport()} className={"mt-3"}>Ανανέωση</Button>
                    </Col>
                </Row>
                {responseData?.lines?.length > 0 ? (
                    <React.Fragment>
                        <Badge bg={"primary"} style={{userSelect: "none", cursor: "pointer"}} onClick={() => dispatch(setIsozygioDisplayData({...displayData, fontSize: displayData.fontSize + 1}))}>+</Badge>
                        <Badge bg={"primary"} style={{userSelect: "none"}} className={"ml-1"}>Μέγεθος: {displayData.fontSize}</Badge>
                        <Badge bg={"primary"} style={{userSelect: "none", cursor: "pointer"}} className={"ml-1 mt-2"} onClick={() => dispatch(setIsozygioDisplayData({...displayData, fontSize: displayData.fontSize - 1}))}>-</Badge>
                        <Badge bg={"primary"} style={{userSelect: "none", cursor: "pointer"}} className={"ml-4"} onClick={() => handleExcelExport()}>Εξαγωγή XLSX</Badge>
                        <Badge bg={"primary"} style={{userSelect: "none", cursor: "pointer"}} className={"ml-1"} onClick={() => handlePDFExport()}>Εξαγωγή PDF</Badge>
                    </React.Fragment>
                ): (
                    <h6 className={"mt-3"}>Δεν βρέθηκαν δεδομένα</h6>
                )}
                {!render && (
                    <h6 key={"text1"} className={"mt-3"}>Η σελίδα φορτώνει, παρακαλώ περιμένετε...</h6>
                )}
                {render && responseData?.lines?.length > 0 && (
                    <Table bordered hover className={"mb-3 mt-1"}>
                        <thead style={{textAlign: "center", position: "sticky", top: "-1px"}}>
                        <tr style={{backgroundColor: "#f1fdff", boxShadow: "0 0 2px 2px #f1fdff"}}>
                            <th style={thStyle}>Κωδικός</th>
                            <th style={thStyle}>Όνομα</th>
                            <th style={thStyle2} colSpan={2}>Απογραφή</th>
                            <th style={thStyle2} colSpan={2}>Κίνηση</th>
                            <th style={thStyle2} colSpan={2}>Προοδευτικά</th>
                            <th style={thStyle2} colSpan={2}>Υπόλοιπα</th>
                        </tr>
                        <tr style={{backgroundColor: "#f1fdff", boxShadow: "0 0 2px 2px #f1fdff"}}>
                            <th colSpan={2}></th>
                            <th style={thStyle2}>Χρέωση</th>
                            <th style={thStyle2}>Πίστωση</th>
                            <th style={thStyle2}>Χρέωση</th>
                            <th style={thStyle2}>Πίστωση</th>
                            <th style={thStyle2}>Χρέωση</th>
                            <th style={thStyle2}>Πίστωση</th>
                            <th style={thStyle2}>Χρέωση</th>
                            <th style={thStyle2}>Πίστωση</th>
                        </tr>
                        </thead>
                        <tbody>
                            {responseData.lines.map((line, idx) => (
                                <React.Fragment>
                                    <tr key={idx}>
                                        <td style={thStyle}>{line.code}</td>
                                        <td style={thStyle}>{line.name}</td>
                                        <td style={thStyle3}>{Number(line.censusDebit).toFixed(2)}</td>
                                        <td style={thStyle4}>{Number(line.censusCredit).toFixed(2)}</td>
                                        <td style={thStyle3}>{Number(line.moveDebit).toFixed(2)}</td>
                                        <td style={thStyle4}>{Number(line.moveCredit).toFixed(2)}</td>
                                        <td style={thStyle3}>{Number(line.progressivelyDebit).toFixed(2)}</td>
                                        <td style={thStyle4}>{Number(line.progressivelyCredit).toFixed(2)}</td>
                                        <td style={thStyle3}>{Number(line.balanceDebit).toFixed(2)}</td>
                                        <td style={thStyle4}>{Number(line.balanceCredit).toFixed(2)}</td>
                                    </tr>
                                    {line?.analysis && line?.analysis?.length > 0 && line?.analysis.map((analysis, idxAnalysis) => (
                                        <tr key={"analysis" + String(idx) + String(idxAnalysis)}>
                                            <td style={thStyle}>&nbsp;</td>
                                            <td style={thStyle}>{analysis.subAccount}</td>
                                            <td style={thStyle3}>{Number(analysis.censusDebit).toFixed(2)}</td>
                                            <td style={thStyle4}>{Number(analysis.censusCredit).toFixed(2)}</td>
                                            <td style={thStyle3}>{Number(analysis.moveDebit).toFixed(2)}</td>
                                            <td style={thStyle4}>{Number(analysis.moveCredit).toFixed(2)}</td>
                                            <td style={thStyle3}>{Number(analysis.progressivelyDebit).toFixed(2)}</td>
                                            <td style={thStyle4}>{Number(analysis.progressivelyCredit).toFixed(2)}</td>
                                            <td style={thStyle3}>{Number(analysis.balanceDebit).toFixed(2)}</td>
                                            <td style={thStyle4}>{Number(analysis.balanceCredit).toFixed(2)}</td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                        <tbody style={{
                            borderTop: "1px solid black",
                            fontWeight: "600",
                            textAlign: "center",
                            position: "sticky",
                            bottom: "-1px",
                            backgroundColor: "white"
                        }}>
                        {displayData.degreeTotals.length > 0 && displayData.degreeTotals.slice(0).reverse().map((totals, idx) => (
                            <tr style={{backgroundColor: "#e3e6e6", boxShadow: "0 0 2px 2px #e3e6e6"}} key={idx}>
                                <td style={{...thStyle, textAlign: "right"}} colSpan={2}>Σύνολα
                                    βαθμού {totals.degree}</td>
                                <td style={thStyle3}>{Number(totals.censusDebit).toFixed(2)}</td>
                                <td style={thStyle4}>{Number(totals.censusCredit).toFixed(2)}</td>
                                <td style={thStyle3}>{Number(totals.moveDebit).toFixed(2)}</td>
                                <td style={thStyle4}>{Number(totals.moveCredit).toFixed(2)}</td>
                                <td style={thStyle3}>{Number(totals.progressivelyDebit).toFixed(2)}</td>
                                <td style={thStyle4}>{Number(totals.progressivelyCredit).toFixed(2)}</td>
                                <td style={thStyle3}>{Number(totals.balanceDebit).toFixed(2)}</td>
                                <td style={thStyle4}>{Number(totals.balanceCredit).toFixed(2)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                )}
            </div>
        </React.Fragment>
    );
}

export default AccountingReportIsozygio
