// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accounting-field input {
  height: 38px;
}
.advanced-combobox-insert {
  background-color: #ffffcc;
}`, "",{"version":3,"sources":["webpack://./src/_components/primer/AccountingTable/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".accounting-field input {\r\n  height: 38px;\r\n}\r\n.advanced-combobox-insert {\r\n  background-color: #ffffcc;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
