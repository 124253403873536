import {createSlice} from "@reduxjs/toolkit";
import {clientsCategoryData} from "../../../_data/clients/clients-settings";
import * as moment from "moment";
import {getCurrentUser} from "../../../_helpers/commonFunctions";

const currentDate = moment().format('DD/MM/YYYY');
let date = new Date();
const currentTime = moment().format('H:mm');
const currentUser = getCurrentUser();
const cUserName = currentUser?.name;
const currentMonth = moment().format("MM");
const current = new Date();

const newInstallationInitialValue = {
    no: '',
    type: '',
    address: '',
    DOY: '',
    city: '',
    id: '',
    vatCategory: '',
    vatReason: '',
    customVat: ''
}

const newClientInitialValue = {
    category: "",
    categoryName: "",
    systemCode: "",
    manualCode: "",
    name: "",
    smallName: "",
    vatCategory: "Normal VAT",
    vatReason: "",
    customVat: "",
    vatNumber: "",
    DOY: "",
    activity: "",
    amka: "",
    country: "",
    address: "",
    city: "",
    postalCode: "",
    email: "",
    gemhNo: "",
    webpage: "",
    phone: "",
    contactPoints: [{
        no: 1,
        id: 1,
        name: "",
        mobile: "",
        landline: "",
        otherPhone: "",
        email: "",
        contactNotes: ""
    }],
    clientNotes: "",
    informationNotes: "",
    notesNotes: "",
    installations: [newInstallationInitialValue],
    communicationHistory: [],
    information: [],
    notes: [],
    emronPasswords: [],
    stockholders: [],
    emronPayments: [],
    accounting: "",
    bypassAccounting: "",
    clientsInfo: [],
    isActive: 'active',
    balance: 0,
    capital: 0,
    peppolPartyIdentification: [],
}

export const clientSettingsInitialValues = {
    categories: clientsCategoryData,
    googleCalendarApi: "",
    clientCommunicationHistory: "",
    clientsInfo: [],
    type: "clientsSettings",
    isActive: "active"
}

const clientMovementsInitialValues = {
    documentType: '',
    documentTypeName: '',
    document_file: '',
    number: '',
    date: date,
    reason: '',
    sum: 0,
    clients: [],
    notes: '',
}

const priceListInitialValues = {
    name: "",
    price: "",
    clientList: [],
    notes: "",
}

const newContactPointInitialValue = {
    no: 1,
    id: 1,
    name: "",
    mobile: "",
    landline: "",
    otherPhone: "",
    email: "",
    // acceptsNotifications: false,
    contactNotes: ""
}

const newInformationInitialValue = {
    no: '',
    description: '',
    details: '',
    date: currentDate.toString(),
    time: currentTime.toString(),
    lastEditedBy: cUserName,
}

const clientMovementsNewClientInitialValue = {
    no: 1,
    clientId: "",
    name: "",
    code: "",
    measurementUnit: "",
    quantity: 0,
}

const newCommunicationHistoryInitialValue = {
    no: 1,
    id: 1,
    date: new Date(),
    time: currentTime.toString(),
    subject: "",
    details: "",
    followUpDate: "",
    createCalenderEvent: false,
    done: false
}

const newClientPaymentInitialValue = {
    documentType: "",
    documentTypeName: "",
    installation: "",
    preNumber: "",
    number: "",
    numberingMode: "manual",
    date: null,
    paymentType: "",
    paymentTypeName: "",
    client: "",
    clientName: "",
    vatNumber: "",
    address: "",
    country: "",
    phone: "",
    email: "",
    reason: "",
    paymentNotes: "",
    amount: "0",
    posDetails: [{
        no: 1,
        id: 1,
        name: "",
        registrationNumber: "",
        amount: "0",
        notes: ""
    }],
    bankDetails: [{
        no: 1,
        id: 1,
        name: "",
        accountNumber: "",
        amount: "0",
        notes: ""
    }],
    chequeDetails: [{
        no: 1,
        id: 1,
        startDate: "",
        endDate: "",
        amount: "0",
        notes: ""
    }],
    returnPayment: "",
    returnPaymentLabel: "",
    tipAmount: 0, // Not saved the database
}

const newClientEmronPasswordInitialValue = {
    no: 1,
    id: 1,
    type: "",
    username: "",
    password: "",
    notes: ""
}
const newClientEmronStockholderInitialValue = {
    no: 1,
    id: 1,
    name: "",
    surname: "",
    vat: "",
    position: "",
    percentage: "",
    stockValue: 0,
    dateFrom: "",
    dateTo: ""
}

const newEmronPaymentInitialValue = {
    no: 1,
    id: 1,
    installationType: "",
    installation: "",
    month: "",
    monthTo: "",
    clientName: "",
    amount: 0,
    notes: "",
    difficulty: "",
    difficultyNotes: "",

}

const newCategoryInitialValue = {
    _id: 1,
    category: '',
    mask: 'CCM-0001',
    maskCode: '',
    maskCodePrefix: 'CCM',
    maskCodeCounter: 1,
    isActive: 'active',
    type: 'client',
}

const initialState = {
    clientSettingsInitialValues: clientSettingsInitialValues,
    newClientsSettings: clientSettingsInitialValues,
    clientsSettings: clientSettingsInitialValues,
    clientsListData: [],
    clientsPayment: [],
    clientPayments: [],
    newClientPaymentInitialValue: newClientPaymentInitialValue,
    newClientPayment: newClientPaymentInitialValue,
    editClientPayment: newClientPaymentInitialValue,
    loading: false,
    hasErrors: false,
    clients: [],
    installations: [],
    newClientInitialValue: newClientInitialValue,
    newClient: newClientInitialValue,
    newClientModal: newClientInitialValue,
    editClient: newClientInitialValue,
    newContactPointInitialValue: newContactPointInitialValue,
    newContactPoint: newContactPointInitialValue,
    editContactPoint: newContactPointInitialValue,
    newInstallationInitialValue: newInstallationInitialValue,
    newInstallation: newInstallationInitialValue,
    newInformationInitialValue: newInformationInitialValue,
    newInformation: newInformationInitialValue,
    newNotesInitialValue: newInformationInitialValue,
    newNotes: {},
    newCommunicationHistoryInitialValue: newCommunicationHistoryInitialValue,
    newCommunicationHistory: newCommunicationHistoryInitialValue,
    searchClients: [],
    totalItemsCS: 0,
    perPageCS: 0,
    searchClientMovements: [],
    totalItemsCMS: 0,
    perPageCMS: 0,
    searchClientPayments: [],
    perPageCPS: 0,
    totalItemsCPS: 0,
    searchClientsMovementsReport: [],
    searchCommunicationHistory: [],
    selectedClient: {},
    selectedClientTo: {},
    selectedClientFrom: {},
    selectedStartDateFrom: null,
    clientReportBalanceData: [],
    clientReportMovementsData: [],
    clientReportMovementsClientList: [],
    clientMovementsSearchByBalance: {},
    selectedDateBalance: current,
    selectedZero: false,
    selectedInactive: false,
    selectedZeroBalance: false,
    selectedInactiveBalance: false,
    selectedStartDateTo: null,
    selectedFilterBy: currentMonth,
    selectedBalanceClientFrom: {},
    selectedBalanceClientTo: {},
    selectedSalesClientFrom: {},
    selectedSalesClientTo: {},
    selectedEmronPaymentsFilters: null,
    selectedEmronPaymentsMonth: new Date(),
    selectedEmronPaymentsClientFrom: {},
    selectedEmronPaymentsClientTo: {},
    selectedEmronPaymentsInstallations: "active",
    selectedEmronPaymentsZero: "inactive",
    selectedMyDataReportClient: {},
    selectedSalesBalanceClientFrom: {},
    selectedSalesBalanceClientTo: {},
    selectedSalesGraphicClientFrom: {},
    selectedSalesGraphicClientTo: {},
    selectedClientMovement: {},
    clientTemplates: [],
    selectedClientTemplate: {},
    clientMovements: [],
    clientMovementsTemplates: [],
    selectedClientMovementsTemplate: {},
    clientMovementsNewClientInitialValue: clientMovementsNewClientInitialValue,
    clientMovementsNewClient: clientMovementsNewClientInitialValue,
    clientMovementsEditClientInitialValue: clientMovementsNewClientInitialValue,
    clientMovementsEditClient: clientMovementsNewClientInitialValue,
    clientPriceListTemplates: [],
    selectedClientPriceListTemplate: {},
    submitting: false,
    selectedCategoryData: {},
    clientMovementsInitialValues: clientMovementsInitialValues,
    clientMovementsClients: [],
    newClientMovements: clientMovementsInitialValues,
    editClientMovements: {},
    priceListInitialValues: priceListInitialValues,
    priceListClients: [],
    newPriceList: priceListInitialValues,
    newQuickClient: newClientInitialValue,
    clientEmron: {
        password: [],
        capital: '',
        stockholders: [],
        clientId: '',
    },
    newClientEmronPasswordInitialValue: newClientEmronPasswordInitialValue,
    newClientEmronPassword: newClientEmronPasswordInitialValue,
    editClientEmronPassword: newClientEmronPasswordInitialValue,
    newEmronPayment: newEmronPaymentInitialValue,
    newClientEmronStockholderInitialValue: newClientEmronStockholderInitialValue,
    newClientEmronStockholder: newClientEmronStockholderInitialValue,
    editClientEmronStockholder: newClientEmronStockholderInitialValue,
    clientPaymentsTemplates: [],
    selectedClientPaymentsTemplate: {},
    newCategoryInitialValue: newCategoryInitialValue,
    newCategory: newCategoryInitialValue,
    categories: clientsCategoryData,

    reportPrimerPaymentsRequestData: {
        page: 1,
        totalPages: 0,
        clientName: "",
        subscriptionNames: [],
        paymentDateFrom: "",
        paymentDateTo: "",
        startDateFrom: "",
        startDateTo: "",
        voucher: "both",
        status: "active",
        tid: "",
    },
    reportPrimerPaymentsResponseData: {
        totalPages: 0,
        data: [],
        totalNetValue: 0,
        totalFeeValue: 0,
    },

    reportPrimerPaymentsAdminRequestData: {
        page: 1,
        totalPages: 0,
        clientName: "",
        subscriptionNames: [],
        dateFrom: moment().startOf("month").format("DD/MM/YYYY"),
        dateTo: moment().endOf("month").format("DD/MM/YYYY"),
        status: [],
    },
    reportPrimerPaymentsAdminResponseData: {
        totalPages: 0,
        data: [],
        totalNetValue: 0,
    },

    // Client Balance Report Data
    clientBalanceReportRequestData: {
        showZeroBalance: "inactive",
        showInActive: "inactive",
        includeSettlements: "inactive",
        clientName: "",
        date_to: moment().format("DD/MM/YYYY"),
        page: 1,
    },
    clientBalanceReportResponseData: {
        totalPages: 0,
        data: [],
        totalBalance: 0,
        includeSettlements: "inactive",
    },

    // Client Movements Report
    clientMovementsReportRequestData: {
        page: 1,
        date_from: "",
        date_to: "",
        clientIds: [],
        client_zero: "inactive",
        client_inactive: "inactive",
        showNotes: "inactive",
    },
    clientMovementsReportResponseData: {
        totalPages: 0,
        pageData: [],
        pageClientData: {},
    },

    // Client Isozygio Report
    clientIsozygioReportRequestData: {
        firstLoad: true, // For date setup
        zeroBalanceClients: false,
        page: 1,
        date_from: "",
        date_to: "",
        clientId: "",
        clientName: "",
    },
    clientIsozygioReportResponseData: {
        totalPages: 0,
        data: [],
    },
    clientIsozygioReportTotals: [],

    // Emron Monthly Report
    emronMonthlyReportRequestData: {
        page: 1,
        month: moment().toDate(),
        clientName: "",
        year: "",
        cardPayments: "both", // "both", "card", "no_card"
        cardPaymentsStatus: "", // paid, pending, failed
        groupInstallations: "inactive",
        groupClients: "inactive",
        showZero: "inactive",
    },
    emronMonthlyReportResponseData: {
        data: [],
        months: [],
        totals: {},
        totalPages: 0,
    },

    // Emron Settlements Report
    emronSettlementsReportRequestData: {
        page: 1,
        date_from: "",
        date_to: "",
        clientId: "",
        clientName: "",
        showOverdue: false,
        doseAnalysis: false,
        showFullyPaid: false,
    },
    emronSettlementsReportResponseData: {
        data: [],
        doseAnalysis: false,
        totalPaidAmount: 0,
        totalSettledAmount: 0,
        totalRemainingAmount: 0,
        totalPages: 0,
    }
}

// Client Slice
export const ClientsSlice = createSlice({
    name: "client",
    initialState,
    reducers: {
        setClientNewCategory: (state, {payload}) => {
            state.newCategory = payload;
        },

        resetClientNewCategory: (state) => {
            state.newCategory = state.newCategoryInitialValue;
        },

        setClients: (state, {payload}) => {
            state.clients = payload;
            state.loading = false;
            state.hasErrors = false;
        },

        setNewClient: (state, {payload}) => {
            state.newClient = payload;
        },

        resetNewClient: (state) => {
            state.newClient = state.newClientInitialValue;
        },
        setNewClientModal: (state, {payload}) => {
            state.newClientModal = payload;
        },

        resetNewClientModal: (state) => {
            state.newClientModal = state.newClientInitialValue;
        },

        setNewClientStatus: (state, {payload}) => {
            state.newClient.isActive = payload;
        },

        setEditClient: (state, {payload}) => {
            state.editClient = payload;
        },

        setEditClientStatus: (state, {payload}) => {
            state.editClient.isActive = payload;
        },

        setNewContactPoint: (state, {payload}) => {
            state.newContactPoint = payload;
        },

        setEditContactPoint: (state, {payload}) => {
            state.editContactPoint = payload;
        },

        setNewInstallations: (state, {payload}) => {
            state.newInstallation = payload;
        },

        resetNewInstallations: (state) => {
            state.newInstallation = state.newInstallationInitialValue;
        },

        setNewInformation: (state, {payload}) => {
            state.newInformation = payload;
        },

        setNewNotes: (state, {payload}) => {
            state.newNotes = payload;
        },
        resetNewNotes: (state) => {
            state.newNotes = {};
        },
        setNewCommunicationHistory: (state, {payload}) => {
            state.newCommunicationHistory = payload;
        },

        resetNewCommunicationHistory: (state) => {
            state.newCommunicationHistory = state.newCommunicationHistoryInitialValue;
        },

        setSearchClients: (state, {payload}) => {
            state.searchClients = payload;
        },

        setSearchClientMovements: (state, {payload}) => {
            state.searchClientMovements = payload;
            state.loading = false;
            state.hasErrors = false;
        },

        setSelectedClient: (state, {payload}) => {
            state.selectedClient = payload;
        },

        setSelectedFilterBy: (state, {payload}) => {
            state.selectedFilterBy = payload;
        },
        setSelectedStartDateFrom: (state, {payload}) => {
            state.selectedStartDateFrom = payload;
        },
        setClientMovementsSearchByBalance: (state, {payload}) => {
            state.clientMovementsSearchByBalance = payload;
        },
        setSelectedStartDateTo: (state, {payload}) => {
            state.selectedStartDateTo = payload;
        },
        setSelectedDateBalance: (state, {payload}) => {
            state.selectedDateBalance = payload;
        },
        setSelectedZero: (state, {payload}) => {
            state.selectedZero = payload;
        },
        setSelectedInactive: (state, {payload}) => {
            state.selectedInactive = payload;
        },
        setSelectedZeroBalance: (state, {payload}) => {
            state.selectedZeroBalance = payload;
        },
        setSelectedInactiveBalance: (state, {payload}) => {
            state.selectedInactiveBalance = payload;
        },
        setSelectedEmronPaymentsFilters: (state, {payload}) => {
            state.selectedEmronPaymentsFilters = payload;
        },


        setSelectedClientMovement: (state, {payload}) => {
            state.selectedClientMovement = payload;
        },

        setClientTemplates: (state, {payload}) => {
            state.clientTemplates = payload;
            state.loading = false;
            state.hasErrors = false;
        },

        setSelectedClientTemplate: (state, {payload}) => {
            state.selectedClientTemplate = payload;
            state.loading = false;
            state.hasErrors = false;
        },

        setClientMovementsTemplates: (state, {payload}) => {
            state.clientMovementsTemplates = payload;
            state.loading = false;
            state.hasErrors = false;
        },

        setNewClientMovements: (state, {payload}) => {
            state.newClientMovements = payload;
        },
        resetNewClientMovements: (state) => {
            state.newClientMovements = state.clientMovementsInitialValues;
        },

        setEditClientMovements: (state, {payload}) => {
            state.editClientMovements = payload;
        },

        setSelectedClientMovementsTemplate: (state, {payload}) => {
            state.selectedClientMovementsTemplate = payload;
            state.loading = false;
            state.hasErrors = false;
        },

        setNewPriceList: (state, {payload}) => {
            state.newPriceList = payload;
        },

        setClientsSettings: (state, {payload}) => {
            state.clientsSettings = payload;
            state.categories = payload.categories;
        },

        setClientsPayment: (state, {payload}) => {
            state.clientsPayment = payload;
        },

        setNewClientPayment: (state, {payload}) => {
            state.newClientPayment = payload;
        },

        resetNewClientPayment: (state) => {
            state.newClientPayment = state.newClientPaymentInitialValue;
        },

        softResetNewClientPayment: (state, {payload}) => {
            state.newClientPayment = {...state.newClientPaymentInitialValue, ...payload};
        },

        setEditClientPayment: (state, {payload}) => {
            state.editClientPayment = payload;
        },

        setClientPaymentsTemplates: (state, {payload}) => {
            state.clientPaymentsTemplates = payload;
        },

        resetEditClientEmronStockholder: (state) => {
            state.editClientEmronStockholder = newClientEmronStockholderInitialValue;
        },

        setClientEmronStockholders: (state, {payload}) => {
            state.clientEmron.stockholders = payload;
        },

        setClientPaymentsSearch: (state, {payload}) => {
            state.searchClientPayments = payload;
        },

        setTotalItemsCPS: (state, {payload}) => {
            state.totalItemsCPS = payload;
        },

        setperPageCPS: (state, {payload}) => {
            state.perPageCPS = payload;
        },

        setSearchCommunicationHistory: (state, {payload}) => {
            state.searchCommunicationHistory = payload;
            state.editClient = {...state.editClient, communicationHistory: payload};
        },

        resetSearchCommunicationHistory: (state) => {
            state.searchCommunicationHistory = [];
            state.editClient = {...state.editClient, communicationHistory: []};
        },

        setTotalItemsCMS: (state, {payload}) => {
            state.totalItemsCMS = payload;
        },

        setperPageCMS: (state, {payload}) => {
            state.perPageCMS = payload;
        },
        setTotalItemsCS: (state, {payload}) => {
            state.totalItemsCS = payload;
        },

        setperPageCS: (state, {payload}) => {
            state.perPageCS = payload;
        },

        // Report Primer Payments
        setPrimerPaymentsRequestData: (state, {payload}) => {
            state.reportPrimerPaymentsRequestData = payload;
        },
        setPrimerPaymentsResponseData: (state, {payload}) => {
            state.reportPrimerPaymentsResponseData = payload;
        },

        // Report Primer Payments Admin
        setPrimerPaymentsAdminRequestData: (state, {payload}) => {
            state.reportPrimerPaymentsAdminRequestData = payload;
        },
        setPrimerPaymentsAdminResponseData: (state, {payload}) => {
            state.reportPrimerPaymentsAdminResponseData = payload;
        },

        // Client Balance Report
        setClientBalanceReportRequestData: (state, {payload}) => {
            state.clientBalanceReportRequestData = payload;
        },
        setClientBalanceReportResponseData: (state, {payload}) => {
            state.clientBalanceReportResponseData = payload;
        },

        // Client Movements Report
        setClientMovementsReportRequestData: (state, {payload}) => {
            state.clientMovementsReportRequestData = payload;
        },
        setClientMovementsReportResponseData: (state, {payload}) => {
            state.clientMovementsReportResponseData = payload;
        },

        // Client Isozygio Report
        setClientIsozygioReportRequestData: (state, {payload}) => {
            state.clientIsozygioReportRequestData = payload;
        },
        setClientIsozygioReportResponseData: (state, {payload}) => {
            state.clientIsozygioReportResponseData = payload;
        },
        setClientIsozygioReportTotals: (state, {payload}) => {
            state.clientIsozygioReportTotals = payload;
        },

        // Emron Monthly Report
        setEmronMonthlyReportRequestData: (state, {payload}) => {
            state.emronMonthlyReportRequestData = payload;
        },
        setEmronMonthlyReportResponseData: (state, {payload}) => {
            state.emronMonthlyReportResponseData = payload;
        },

        // Emron Settlements Report
        setEmronSettlementsReportRequestData: (state, {payload}) => {
            state.emronSettlementsReportRequestData = payload;
        },
        setEmronSettlementsReportResponseData: (state, {payload}) => {
            state.emronSettlementsReportResponseData = payload;
        }
    },
});

export const {
    setClients,
    setNewClient,
    resetNewClient,
    setNewClientModal,
    resetNewClientModal,
    setSearchClients,
    setSearchClientMovements,
    setSelectedClient,
    setClientMovementsSearchByBalance,
    setSelectedEmronPaymentsFilters,
    setSelectedClientMovement,
    setClientTemplates,
    setSelectedClientTemplate,
    setClientMovementsTemplates,
    setSelectedClientMovementsTemplate,
    setClientsSettings,
    setNewContactPoint,
    setEditContactPoint,
    setNewInstallations,
    resetNewInstallations,
    setNewNotes,
    resetNewNotes,
    setNewClientMovements,
    setNewCommunicationHistory,
    resetNewCommunicationHistory,
    setEditClientMovements,
    setEditClient,
    setClientsPayment,
    resetNewClientMovements,
    setNewClientPayment,
    resetNewClientPayment,
    setClientEmronStockholders,
    setNewClientStatus,
    setEditClientStatus,
    setClientPaymentsSearch,
    setTotalItemsCPS,
    setperPageCPS,
    setClientPaymentsTemplates,
    setEditClientPayment,
    resetEditClientEmronStockholder,
    setSearchCommunicationHistory,
    resetSearchCommunicationHistory,
    setClientNewCategory,
    resetClientNewCategory,
    setTotalItemsCMS,
    setperPageCMS,
    setperPageCS,
    setTotalItemsCS,
    softResetNewClientPayment,

    setPrimerPaymentsRequestData,
    setPrimerPaymentsResponseData,

    setPrimerPaymentsAdminRequestData,
    setPrimerPaymentsAdminResponseData,

    setClientBalanceReportRequestData,
    setClientBalanceReportResponseData,

    setClientMovementsReportRequestData,
    setClientMovementsReportResponseData,

    setClientIsozygioReportRequestData,
    setClientIsozygioReportResponseData,
    setClientIsozygioReportTotals,

    setEmronMonthlyReportRequestData,
    setEmronMonthlyReportResponseData,

    setEmronSettlementsReportRequestData,
    setEmronSettlementsReportResponseData,
} = ClientsSlice.actions;
