// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.borderedContainer {
    border: 1px solid #ebebeb;
    border-radius: 8px;
    padding: 10px;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(1) {
    z-index: 1125;
}

.modal-backdrop.show:nth-last-child(2) {
    z-index: 1100;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(3) {
    z-index: 1075;
}

.modal-backdrop.show:nth-last-child(4) {
    z-index: 1050;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(5) {
    z-index: 1025;
}

.modal-backdrop.show:nth-last-child(6) {
    z-index: 1000;
}
`, "",{"version":3,"sources":["webpack://./src/_components/layouts/data-panel/Prescriptions/styles.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".borderedContainer {\r\n    border: 1px solid #ebebeb;\r\n    border-radius: 8px;\r\n    padding: 10px;\r\n}\r\n\r\ndiv[role=\"dialog\"][aria-modal=\"true\"]:nth-last-child(1) {\r\n    z-index: 1125;\r\n}\r\n\r\n.modal-backdrop.show:nth-last-child(2) {\r\n    z-index: 1100;\r\n}\r\n\r\ndiv[role=\"dialog\"][aria-modal=\"true\"]:nth-last-child(3) {\r\n    z-index: 1075;\r\n}\r\n\r\n.modal-backdrop.show:nth-last-child(4) {\r\n    z-index: 1050;\r\n}\r\n\r\ndiv[role=\"dialog\"][aria-modal=\"true\"]:nth-last-child(5) {\r\n    z-index: 1025;\r\n}\r\n\r\n.modal-backdrop.show:nth-last-child(6) {\r\n    z-index: 1000;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
