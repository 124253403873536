import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Badge, Button, Col, Modal, Row, Spinner, Stack} from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import {toast} from "react-toastify";
import Input from "../../../../common/Input";
import DateBox from "../../../../primer/DateBox";
import {checkPermission, getGreekCurrencyFormat, isEmpty} from "../../../../../_helpers/commonFunctions";
import Dropdown from "../../../../primer/Dropdown";
import TextArea from "../../../../common/TextArea";
import Alert from "react-bootstrap/Alert";
import {setEditClientPayment} from "../../../../../_reducers/DataPanel/Clients/clientSlice";
import {addTab, updateActiveMainTab} from "../../../../../_reducers/TabsSlice";
import BetterCheckBox from "../../../../common/BetterCheckBox";
import {addToForceRefresh} from "../../../../../_reducers/SearchTableSlice";

const EditClientSubscriptions = () => {
    const dispatch = useDispatch();
    const CLIENTS_DATA = useSelector( (state) => state.CLIENTS_DATA);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const [showTimeCharges, setShowTimeCharges] = useState(false);
    const [timeChargeSub, setTimeChargeSub] = useState({});
    const [subscriptionsData, setSubscriptionsData] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const errorAuth = "You are not Authorized!";
    const [refreshDisabled, setRefreshDisabled] = useState(false);

    const [showEdit, setShowEdit] = useState(false);
    const [editingSub, setEditingSub] = useState({});

    const [popEditing, setPopEditing] = useState(false);
    const [popEditingT, setPopEditingT] = useState({}); // timecharge object

    const [openHistories, setOpenHistories] = useState([]); // array with timecharge ids
    const [loadingEditSub, setLoadingEditSub] = useState(false);

    const defaultCreateSubscription = {
        subscriptionName: `Διακανονισμός ${moment().format("DD/MM/YYYY")}`,
        price: CLIENTS_DATA.editClient.balance > 0 ? Number(CLIENTS_DATA.editClient.balance).toFixed(2) : "0.00",
        startDate: moment().add(1, "day").toDate(),
        endDate: moment().add(6, "months").toDate(),
        type: "settlement",
        frequency: "1",
        every: "month",
        notes: "",
    }
    const [showCreateSubscription, setShowCreateSubscription] = useState(false);
    const [createSubscription, setCreateSubscription] = useState({...defaultCreateSubscription});
    const [loadingCreateSubscription, setLoadingCreateSubscription] = useState(false);

    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const isEmron = company.vatNumber === "800434990";

    useEffect(() => {
        if(firstLoad) {
            setFirstLoad(false);
            fetchSubscriptions(false);
        }
    }, [])

    useEffect(() => {
        if (showCreateSubscription) {
            if (isEmron && createSubscription.type === "settlement") {
                calculateAndSetSettlementPayments();
            }
        }
    }, [showCreateSubscription])

    const fetchSubscriptions = (fromButton) => {
        setRefreshDisabled(true);
        axios.get(process.env.REACT_APP_API_URL2 + "/callcenter/get-client-subscriptions",  {
            headers: {"Content-Type": "application/json"},
            params: {
                clientId: CLIENTS_DATA.editClient?._id,
                company: company.id
            }
        })
        .then((response) => {
            if(response.data.status === "200") {
                setSubscriptionsData(response.data.data);
                setRefreshDisabled(false);
                if(fromButton) toast.success("Οι συνδρομές ανανεώθηκαν.");
            } else {
                toast.error("Υπήρξε κάποιο πρόβλημα, οι συνδρομές δεν ανανεώθηκαν.");
                setRefreshDisabled(false);
            }
        })
        .catch((error) => {
            console.log(error);
            setRefreshDisabled(false);
        })
    }
    const isExpired = (subObj) => {
        if (subObj?.hideEndDate) {
            return false;
        } else {
            return moment(subObj.endDate) < moment();
        }
    }
    /*
        Gets client subscriptions and a subscription, returns array with objects with time charges for specific subscription
     */
    const getTimeCharges = (clientSubscriptions, sub) => {
        let timeCharges = [];
        if(clientSubscriptions && clientSubscriptions?.length > 0) {
            clientSubscriptions.filter((el) => el.type === "timeCharge" && el.mappedProductForCharging === sub.mappedProduct).forEach((timeCharge) => {
                 timeCharges.push(timeCharge);
            })
        }
        return timeCharges;
    }
    const getRemainingSupportTime = (clientSubscriptions, sub) => {
        let totalTime = 0;
        if(clientSubscriptions && clientSubscriptions?.length > 0) {
            clientSubscriptions.filter((el) => el.type === "timeCharge"
                && el.mappedProductForCharging === sub?.mappedProduct
                && moment() < moment(el.endDate)).forEach((timeCharge) => {

                totalTime += Number(timeCharge.minutesTime);
            })
        }
        return String(totalTime);
    }
    const showAnalyticTimeCharges = (sub) => {
        setShowTimeCharges(true);
        setTimeChargeSub(sub);
    }
    const resetAnalyticTimeCharges = () => {
        setShowTimeCharges(false);
        setTimeChargeSub({});
        cancelPopEdit();
    }
    const editSubscriptionClicked = (sub) => {
        setShowEdit(true);
        if (isEmron && !isEmpty(sub.wooResponse)) {
            setEditingSub({...sub, wooResponse: JSON.parse(sub.wooResponse)});
        } else {
            setEditingSub(sub);
        }
    }
    const resetEditModal = () => {
        setShowEdit(false);
        setEditingSub({});
    }
    const saveEditedClicked = () => {
        setLoadingEditSub(true);
        if (isEmron) {
            if (calculateSettlementEditSum() !== Number(editingSub.price)) {
                toast.error("Τα ποσά δόσεων του διακανονισμού δεν συμφωνούν.")
            } else {
                axios.post(process.env.REACT_APP_API_URL2 + "/emron/edit-client-subscription", {company: company.id, client: CLIENTS_DATA?.editClient, sub: editingSub}, {
                    headers: {"Content-Type": "application/json"},
                }).then((res) => {
                    setLoadingEditSub(false);
                    if (res.data.status === "200") {
                        toast.success("Επιτυχής αποθήκευση.");
                        resetEditModal();
                        fetchSubscriptions();
                        dispatch(addToForceRefresh("Client Search"));
                    } else {
                        toast.error(res.data.message);
                    }
                }).catch((error) => {
                    setLoadingEditSub(false);
                    console.log(error);
                    toast.error("Σφάλμα κατά την αποθήκευση");
                })
            }
        } else {
            axios.post(process.env.REACT_APP_API_URL2 + "/settings/edit-client-subscription", {company: company.id, client: CLIENTS_DATA?.editClient, sub: editingSub}, {
                headers: {"Content-Type": "application/json"},
            })
                .then((res) => {
                    setLoadingEditSub(false);
                    if(res.data.status === "200") {
                        toast.success("Επιτυχής αποθήκευση");
                        fetchSubscriptions();
                        dispatch(addToForceRefresh("Client Search"));
                    } else {
                        toast.error("Σφάλμα κατά την αποθήκευση");
                    }
                })
                .catch((error) => {
                    setLoadingEditSub(false);
                    console.log(error);
                    toast.error("Σφάλμα κατά την αποθήκευση");
                })
            resetEditModal();
        }
    }
    const popEdit = (timeCharge) => {
        setPopEditing(true);
        setPopEditingT(timeCharge);
    }
    const savePopEdit = () => {
        if(!/^\d*$/.test(popEditingT.minutesTime)) {
            toast.error("Ο υπολειπόμενος χρόνος δεν είναι δεκτός ως αριθμός.");
        } else {
            axios.post(process.env.REACT_APP_API_URL2 + "/settings/save-pop-edit", {
                    company: company.id,
                    clientId: CLIENTS_DATA.editClient?._id,
                    timeCharge: popEditingT
                },
                {headers: {"Content-Type": "application/json"}})
                .then((res) => {
                    if (res.data.status === "200") {
                        toast.success("Επιτυχής αποθήκευση.");
                        fetchSubscriptions();
                    } else {
                        toast.error("Υπήρξε κάποιο πρόβλημα κατά την αποθήκευση.");
                    }
                }).catch((err) => {
                console.log(err);
                toast.error("Υπήρξε κάποιο πρόβλημα κατά την αποθήκευση.");
            })
            cancelPopEdit();
        }
    }
    const cancelPopEdit = () => {
        setPopEditing(false);
        setPopEditingT({});
    }
    const handlePopEditChange = (e) => {
        if(popEditing) {
            if(/^\d*$/.test(e.target.value)) setPopEditingT({...popEditingT, minutesTime: e.target.value});
        }
    }
    const handleOnEditChange = (e, name) => {
        if (name === "editStartDate") {
            if (moment(e) > moment(editingSub.endDate).subtract(1, "day")) {
                toast.error("Η ημερομηνία έναρξης πρέπει να απέχει τουλάχιστον 1 ώρα από την ημερομηνία λήξης.");
            } else {
                setEditingSub({...editingSub, startDate: moment(e).toDate()});
            }
        } else if (name === "editEndDate") {
            if(moment(e) < moment(editingSub.startDate)) {
                toast.error("Η ημερομηνία λήξης της συνδρομής δεν μπορεί να είναι πριν της έναρξης της.")
            } else {
                setEditingSub({...editingSub, endDate: moment(e).toDate()});
            }
        }
    }
    const getContractStatus = () => {
        let status =  null
        if(subscriptionsData.length > 0) {
            subscriptionsData.forEach((sub) => {
                if(sub?.contractString) {
                    status = sub?.contractString;
                }
            })
        }
        if(status) {
            return (
                <React.Fragment>
                    <Badge className="ml-2" bg="primary">Κατάσταση Συμβολαίου: {status}</Badge>
                    {status !== "Μη ενεργοποιημένο" && (
                        <a target="_blank" rel="noreferrer" href={`https://primer.gr/wp-admin/admin.php?page=lmfwc_licenses&vatnumber-id=${CLIENTS_DATA.editClient.vatNumber}`}>
                            <Badge className="ml-2" bg="primary">Διαχείριση Συμβολαίου</Badge>
                        </a>
                    )}
                </React.Fragment>
            )
        } else {
            return "";
        }
    }
    const toggleOpenHistory = (id) => {
        if(openHistories.includes(id)) {
            setOpenHistories(openHistories.filter((el) => el !== id));
        } else {
            setOpenHistories([...openHistories, id]);
        }
    }
    const getSubscriptionId = (obj) => {
        if (obj?.link_subscription_id) {
            return obj.link_subscription_id;
        } else {
            return obj.subscription_id;
        }
    }
    const handleCreateSubscriptionChange = (e, type, name) => {
        if (type === "input") {
            setCreateSubscription({...createSubscription, [name] : String(e.target.value).replace(/,/g, ".")});
        } else if (type === "datebox") {
            if (moment(e).isValid()) {
                setCreateSubscription({...createSubscription, [name] : e});
            } else {
                setCreateSubscription({...createSubscription, [name] : moment().toDate()});
            }
        } else if (type === "dropdown") {
            if (name === "type" && e.value === "subscription") {
                let clone = structuredClone(createSubscription);
                delete clone.settlements;
                clone[name] = e.value;
                setCreateSubscription(clone);
            } else {
                setCreateSubscription({...createSubscription, [name] : e.value});
            }
        }
    }
    const handleCreateSubscription = () => { // Settlements only for now
        if (isEmpty(createSubscription.subscriptionName)) {
            toast.error("Το όνομα δεν μπορεί να είναι άδειο.");
        } else if (isNaN(createSubscription.price)) {
            toast.error("Η τιμή πρέπει να είναι αριθμός.");
        } else if (!moment(createSubscription.startDate).isValid() || !moment(createSubscription.endDate).isValid()) {
            toast.error("Οι ημερομηνίες δεν είναι αποδεχτές.");
        } else if (moment(createSubscription.startDate) > moment(createSubscription.endDate).subtract(1, "day")) {
            toast.error("Η ημερομηνία λήξης πρέπει να είναι τουλάχιστον μία μέρα μετά από την ημερομηνία πρώτης πληρωμής");
        } else {
            let total = 0;
            for (let p of createSubscription.settlements) {
                total += p.amount;
            }
            if (Number(total).toFixed(2) !== Number(createSubscription.price).toFixed(2)) {
                return toast.error("Τα σύνολα των δόσεων δεν είναι αποδεκτά, παρακαλώ επαναυπολογίστε.");
            }
            setLoadingCreateSubscription(true);
            let requestData = {
                company: company.id,
                client: CLIENTS_DATA.editClient._id,
                ...createSubscription
            }
            if (requestData.type === "subscription") {
                delete requestData.frequency;
                delete requestData.every;
                delete requestData.settlements;
            }
            axios.post(`${process.env.REACT_APP_API_URL2}/client/create-client-subscription`, requestData, {
                headers: {"Content-Type": "application/json"},
            }).then((res) => {
                setLoadingCreateSubscription(false);
                if (res.data.status === "200") {
                    toast.success(`${createSubscription.type === "settlement" ? "Ο διακανονισμός " : "Η συνδρομή "} δημιουργήθηκε επιτυχώς.`);
                    fetchSubscriptions(false);
                    setShowCreateSubscription(false);
                    setCreateSubscription({...defaultCreateSubscription});
                    dispatch(addToForceRefresh("Client Search"));
                } else {
                    toast.error(res.data.message);
                }
            }).catch((error) => {
                setLoadingCreateSubscription(false);
                console.log(error);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        }
    }
    const createSubscriptionValid = () => {
        return !isEmpty(createSubscription.subscriptionName) && !isNaN(createSubscription.price) && moment(createSubscription.startDate).isValid() && moment(createSubscription.endDate).isValid();
    }

    const [frequencyOptions, setFrequencyOptions] = useState([]);
    useEffect(() => {
        if (frequencyOptions.length === 0) {
            let tmp = [];
            for (let i = 1; i <= 180; i++) {
                tmp.push({label: String(i), value: String(i)});
            }
            setFrequencyOptions(tmp);
        }
    }, [])
    const everyOptions = [
        {label: `μέρ${createSubscription.frequency === "1" ? "α" : "ες"}`, value: "day"},
        {label: `μήν${createSubscription.frequency === "1" ? "α" : "ες"}`, value: "month"},
        {label: `χρόν${createSubscription.frequency === "1" ? "ο" : "ια"}`, value: "year"},
    ]
    const createSubscriptionTypes = [
        {label: "Συνδρομή", value: "subscription"},
        {label: "Διακανονισμός", value: "settlement"}
    ]

    const getSettlementPayments = (startDate, endDate, frequency, every, amount) => {
        /*
            returns array of objects like :
            [{
                paymentDate: ... (Date),
                amount: ...
            }]
         */
        if (!moment(createSubscription.startDate).isValid() || !moment(createSubscription.endDate).isValid() || isEmpty(createSubscription.startDate) || isEmpty(createSubscription.endDate)) {
            toast.error("Οι ημερομηνίες δεν είναι αποδεχτές.");
            return [];
        }
        let spread;
        if (every === "month") {
            spread = moment(endDate).add(12, "hours").diff(moment(startDate), every);
        } else {
            spread = moment(endDate).diff(moment(startDate), every);
        }
        const installments = Math.ceil(Number(spread)/Number(frequency)) + 1;
        const installmentAmount = Number(Number(Number(amount)/Number(installments)).toFixed(2));
        let instArr = [];
        for (let i = 0; i < installments; i++) {
            instArr.push({
                date: moment(startDate).add(i*frequency, every).toDate(),
                amount: installmentAmount,
            })
            if (i + 1 === installments) {
                let balance = 0;
                for (let inst of instArr) {
                    balance += inst.amount;
                }
                const remaining = Number(Number(Number(amount) - balance).toFixed(2));
                instArr[instArr.length - 1].amount += remaining;
                instArr[instArr.length - 1].amount = Number(Number(instArr[instArr.length - 1].amount).toFixed(2));
            }
        }
        return instArr;
    }

    const calculateAndSetSettlementPayments = () => {
        setCreateSubscription({...createSubscription, settlements: getSettlementPayments(createSubscription.startDate, createSubscription.endDate, createSubscription.frequency, createSubscription.every, createSubscription.price)});
    }

    const calculateAndSetSettlementPaymentsOnEdit = () => {
        let paidAmount = 0;
        let firstDate = null;
        let firstIdx = 0;
        let idx = 0;
        for (let sp of editingSub.settlements) {
            if (getSettlementPaidStatus(sp, idx, editingSub?.orders)[0] === "none") {
                if (idx === 0) break; // Handle if there are no paid settlements
                if (!firstDate) firstDate = sp.date;
            } else {
                firstIdx++;
                paidAmount += sp.amount;
            }
            idx++;
        }
        paidAmount = Number(Number(paidAmount).toFixed(2));
        let remainingAmount = Number(Number(editingSub.price) - paidAmount);
        remainingAmount = Number(Number(remainingAmount).toFixed(2));

        if (!firstDate) firstDate = editingSub.startDate;

        const newSettlements = getSettlementPayments(firstDate, editingSub.endDate, editingSub.frequency, editingSub.every, remainingAmount);
        setEditingSub({...editingSub, settlements: [...editingSub.settlements.slice(0, firstIdx), ...newSettlements]})
    }

    const calculateSettlementSum = () => {
        let sum = 0;
        if (createSubscription?.settlements) {
            for (let set of createSubscription?.settlements) {
                sum += Number(set.amount);
            }
        }
        return Number(Number(sum).toFixed(2));
    }

    const calculateSettlementEditSum = () => {
        let sum = 0;
        if (editingSub?.settlements) {
            for (let set of editingSub?.settlements) {
                sum += Number(set.amount);
            }
        }
        return Number(Number(sum).toFixed(2));
    }

    const handleOnSettlementAmountChange = (e, idx) => {
        const cloneSettlements = structuredClone(createSubscription.settlements);
        let value = e.target.value;
        if (!isNaN(value)) {
            cloneSettlements[idx].amount = Number(value);
            setCreateSubscription({...createSubscription, settlements: [...cloneSettlements]});
        }
    }

    const handleOnSettlementAmountEditChange = (e, idx) => {
        const cloneSettlements = structuredClone(editingSub.settlements);
        let value = e.target.value;
        if (!isNaN(value)) {
            cloneSettlements[idx].amount = Number(value);
            setEditingSub({...editingSub, settlements: [...cloneSettlements]});
        }
    }

    const subscriptionFrontendData = () => {
        if (isEmron) {
            return subscriptionsData.filter((el) => el.type === "settlement");
        } else {
            return subscriptionsData.filter((el) => el.type === "subscription");
        }
    }

    const handleOpenEmronWooSub = (vatNumber) => {
        axios.post(`${process.env.REACT_APP_API_URL2}/emron/createLinkForWooSubscription`, {vatNumber: vatNumber}, {
            headers: {"Content-Type": "application/json"},
        }).then((res) => {
            if (res.data.status === "200") {
                //window.open(res.data.data, "_blank");
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            console.log(error);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const getSettlementPaidStatus = (settlement, settlementIdx, wooOrders) => {
        /* Statuses:
        - paid
        - overdue
        - none
        returns array with status at 0, color at 1
         */
        let successfulOnes = wooOrders?.filter((el) => !isEmpty(el.transaction_id))
        if (successfulOnes?.length > 0) {
            if (settlementIdx <= successfulOnes.length - 1) {
                return ["paid", "#c7ffb0"];
            }
        }
        if (Math.abs(moment().diff(settlement.date, "hours")) > 12 && moment() > moment(settlement.date)) {
            return ["overdue", "#ff9696"];
        }
        return ["none", ""];
    }

    const dateNiceFormat = (dateString) => {
        if (moment(dateString).isValid()) {
            return moment(dateString).format("DD/MM/YYYY HH:mm:ss");
        } else {
            return "-";
        }
    }

    const getBalanceString = (realTotal, currentTotal) => {
        let diff = Number(realTotal) - Number(currentTotal);
        if (diff > 0) {
            return `πλεόνασμα ${getGreekCurrencyFormat(diff)}`
        } else {
            diff = Math.abs(diff);
            return `έλλειμμα ${getGreekCurrencyFormat(diff)}`
        }
    }

    const handleOrderTransactionViewClicked = (transactionId) => {
        if (!isEmpty(transactionId)) {
            axios.get(process.env.REACT_APP_API_URL2 + "/emron/find-settlement-payment", {
                headers: { 'Content-Type': 'application/json' },
                params: {
                    company: company.id,
                    transactionId: transactionId,
                }
            }).then((res) => {
                if (res.data.status === "200") {
                    dispatch(setEditClientPayment(res.data.data));
                    const idx = TABS_DATA.findIndex((item) => "edit-client-payments" === item);
                    if(idx === -1) {
                        dispatch(addTab("edit-client-payments"));
                        dispatch(updateActiveMainTab(TABS_DATA.length));
                    } else {
                        dispatch(updateActiveMainTab(idx));
                    }
                } else {
                    toast.error(res.data.message);
                }
            }).catch((error) => {
                console.log(error);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        } else {
            toast.error("Δεν βρέθηκε η είσπραξη, παρακαλώ δοκιμάστε αργότερα.")
        }
    }

    return(
        <React.Fragment>
            <Button className="btn btn-sm btn-primary mb-3" onClick={() => fetchSubscriptions(true)} disabled={refreshDisabled}>Ανανέωση</Button>
            {isEmron && (
                <React.Fragment>
                    <Button className="btn btn-sm btn-primary mb-3 ml-2" onClick={() => setShowCreateSubscription(true)}>Δημιουργία {isEmron ? "διακανονισμού" : "συνδρομής"}</Button>
                    <Modal show={showCreateSubscription} onHide={() => setShowCreateSubscription(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Δημιουργία {isEmron ? "Διακανονισμού" : "Συνδρομής/Διακανονισμού"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md={12}>
                                    <Dropdown
                                        name={"type"}
                                        label={"Τύπος"}
                                        className={"mb-3"}
                                        required={true}
                                        key={Math.random()}
                                        disabled={true}
                                        defaultValue={createSubscriptionTypes.find((el) => el.value === createSubscription.type)}
                                        options={createSubscriptionTypes}
                                        onChange={(e) => handleCreateSubscriptionChange(e, "dropdown", "type")}
                                    />
                                </Col>
                                <Col md={12}>
                                    <Input
                                        name={"subscriptionName"}
                                        label={`Όνομα ${createSubscription.type === "subscription" ? "Συνδρομής" : "Διακανονισμού"}`}
                                        className={"mb-3"}
                                        autoComplete={"off"}
                                        required={true}
                                        value={createSubscription.subscriptionName}
                                        onChange={(e) => handleCreateSubscriptionChange(e, "input", "subscriptionName")}
                                    />
                                </Col>
                                <Col md={12}>
                                    <Input
                                        name={"price"}
                                        label={createSubscription.type === "settlement" ? "Ποσό διακανονισμού" : "Τιμή συνδρομής"}
                                        className={"mb-3"}
                                        autoComplete={"off"}
                                        required={true}
                                        value={createSubscription.price}
                                        onChange={(e) => handleCreateSubscriptionChange(e, "input", "price")}
                                    />
                                </Col>
                                <Col md={6}>
                                    <DateBox
                                        name={"startDate"}
                                        label={"Ημερομηνία πρώτης πληρωμής"}
                                        classes={"mb-1"}
                                        allowOtherYears={true}
                                        selected={createSubscription.startDate}
                                        minDate={moment().add(1, "day").toDate()}
                                        required={true}
                                        allowOlderDate={true}
                                        onChange={(e) => handleCreateSubscriptionChange(e, "datebox", "startDate")}
                                    />
                                </Col>
                                <Col md={6}>
                                    <DateBox
                                        name={"endDate"}
                                        label={"Ημερομηνία Λήξης"}
                                        classes={"mb-1"}
                                        allowOtherYears={true}
                                        selected={createSubscription.endDate}
                                        minDate={moment().add(2, "day").toDate()}
                                        required={true}
                                        allowOlderDate={true}
                                        onChange={(e) => handleCreateSubscriptionChange(e, "datebox", "endDate")}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Dropdown
                                        name={"frequency"}
                                        label={"Κάθε"}
                                        options={frequencyOptions}
                                        key={Math.random()}
                                        defaultValue={frequencyOptions.find((el) => el.value === createSubscription.frequency)}
                                        onChange={(e) => handleCreateSubscriptionChange(e, "dropdown", "frequency")}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Dropdown
                                        name={"every"}
                                        label={<>&nbsp;</>}
                                        options={everyOptions}
                                        key={Math.random()}
                                        defaultValue={everyOptions.find((el) => el.value === createSubscription.every)}
                                        onChange={(e) => handleCreateSubscriptionChange(e, "dropdown", "every")}
                                    />
                                </Col>
                                {createSubscription.type === "settlement" && (
                                    <Col md={12}>
                                        <Button size={"sm"} onClick={() => calculateAndSetSettlementPayments()}>Υπολογισμός δόσεων</Button>
                                        {createSubscription?.settlements?.length > 0 && (
                                            <table style={{tableLayout: "fixed", border: "1px solid gray", textAlign: "center", width: "100%"}} className={"mt-2"}>
                                                <colgroup>
                                                    <col style={{width: "20%"}}></col>
                                                    <col style={{width: "40%"}}></col>
                                                    <col style={{width: "40%"}}></col>
                                                </colgroup>
                                                <thead>
                                                <tr>
                                                    <th style={{border: "1px solid gray"}}>Αρ. δόσης</th>
                                                    <th style={{border: "1px solid gray"}}>Ημερομηνία</th>
                                                    <th style={{border: "1px solid gray"}}>Ποσό</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {createSubscription.settlements.map((settlement, idx) => (
                                                    <tr>
                                                        <td style={{border: "1px solid gray"}} key={`settlement${idx}ar`}>{idx + 1}</td>
                                                        <td style={{border: "1px solid gray"}} key={`settlement${idx}date`}>{moment(settlement.date).format("DD/MM/YYYY")}</td>
                                                        <td style={{border: "1px solid gray"}} key={`settlement${idx}am`}>
                                                            <input
                                                                type={"number"}
                                                                style={{width: "50%"}}
                                                                value={settlement.amount}
                                                                onChange={(e) => handleOnSettlementAmountChange(e, idx)}
                                                            />
                                                            {"   €"}
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td colSpan={2} style={{textAlign: "right", border: "1px solid gray", fontWeight: "700"}}>Σύνολο</td>
                                                    <td style={{border: "1px solid gray"}}>{getGreekCurrencyFormat(calculateSettlementSum())}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        )}
                                        {calculateSettlementSum() !== Number(createSubscription.price) && (
                                            <div style={{color: "red"}}>
                                                (*) Το άθροισμα των ποσών των δόσεων δεν είναι ίσο με το ποσό του διακανονισμού.<br/>
                                                (Υπάρχει {getBalanceString(calculateSettlementSum(), createSubscription.price)})
                                            </div>
                                        )}
                                    </Col>
                                )}
                                <Col md={12}>
                                    <TextArea
                                        name={"notes"}
                                        label={"Σημειώσεις"}
                                        value={createSubscription.notes}
                                        rows={4}
                                        onChange={(e) => handleCreateSubscriptionChange(e, "input", "notes")}
                                    />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={() => setShowCreateSubscription(false)}>Κλείσιμο</Button>
                            <Button variant="primary" onClick={() => handleCreateSubscription()} disabled={loadingCreateSubscription || !createSubscriptionValid() || calculateSettlementSum() !== Number(createSubscription.price)}>
                                Αποθήκευση {loadingCreateSubscription && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </React.Fragment>
            )}
            {getContractStatus()}
            {subscriptionsData.length > 0 ? (
                <Row className="mb-3">
                    {subscriptionFrontendData().map((sub) => (
                        <Col md={6} className="mb-3" key={Math.random()}>
                            <div style={{
                                border: isExpired(sub) ? "2px solid red" : "2px solid #6ea2a9",
                                borderRadius: "5px",
                                boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)"
                            }}>
                                <div style={{margin: "10px"}}>
                                    {isEmron && (
                                        <React.Fragment>
                                            <span style={{fontWeight: "600"}}>Τύπος: </span>{createSubscriptionTypes.find((el) => el.value === sub.type)?.label}<br/>
                                        </React.Fragment>
                                    )}
                                    <span style={{fontWeight: "600"}}>Όνομα: </span>{sub.subscriptionName}<br/>
                                    {isEmron && <><span style={{fontWeight: "600"}}>Τιμή: </span> {getGreekCurrencyFormat(sub.price)}<br/></>}
                                    {sub.sku && (<><span style={{fontWeight: "600"}}>WP Edition: </span>{sub.sku}<br/></>)}
                                    {sub.productName && (<><span style={{fontWeight: "600"}}>Option: </span>{sub.productName}<br/></>)}
                                    <span style={{fontWeight: "600"}}>{isEmron ? "Ημερομηνία Πρώτης Πληρωμής: " : "Ημερομηνία Έναρξης: "}</span>{moment(sub.startDate).format("DD-MM-YYYY")}<br/>
                                    {!sub?.hideEndDate &&
                                        <React.Fragment>
                                            <span style={{fontWeight: "600"}}>Ημερομηνία Λήξης: </span>{moment(sub.endDate).format("DD-MM-YYYY")}<br/>
                                        </React.Fragment>
                                    }
                                    {sub.activatedDate && (<><span style={{fontWeight: "600"}}>Ημερομηνία Ενεργοποίησης: </span>{moment(sub.activatedDate).format("DD-MM-YYYY")}<br/></>)}
                                    {sub.serialNumber && (<><span style={{fontWeight: "600"}}>Σειριακός Κωδικός: </span>{sub.serialNumber}<br/></>)}
                                    {!isEmron && (
                                        <React.Fragment>
                                            <span style={{fontWeight: "600"}}>Συνολικός υπολειπόμενος χρόνος υποστήριξης για αυτήν την συνδρομή (σε λεπτά):</span> {getRemainingSupportTime(subscriptionsData, sub)}
                                        </React.Fragment>
                                    )}
                                    {getTimeCharges(subscriptionsData, sub).length > 0 && (
                                        <Badge className="ml-3" bg="secondary" style={{cursor: "pointer"}} onClick={() => showAnalyticTimeCharges(sub)}>Αναλυτικά</Badge>
                                    )}
                                    {isExpired(sub) && (
                                        <Badge bg="danger" style={{position: "absolute", top: "6px", right: "18px"}}>Έχει λήξει</Badge>
                                    )}
                                    {(sub.isEtameiaki === true || (sub.username && sub.password && sub.subscription_id) || sub.serialNumber || checkPermission("clients-clients/edit-subscriptions-write", permissionsData)) && (
                                        <hr style={{marginTop: "4px", marginBottom: "4px"}}/>
                                    )}
                                    {checkPermission("clients-clients/edit-subscriptions-write", permissionsData) === true && (
                                        <Badge className="mt-1" bg="primary" style={{cursor: "pointer"}} onClick={() => editSubscriptionClicked(sub)}>{isEmron ? "Προβολή" : "Τροποποίηση"}</Badge>
                                    )}
                                    {sub.isEtameiaki === true && (
                                        <>
                                            <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_MASTER_SOFTWARE + "?serialNumber=" + sub.serialNumber}>
                                                <Badge className="mt-1 ml-1" bg="primary">Άνοιγμα Υποστήριξης Ταμειακής</Badge>
                                            </a>
                                        </>
                                    )}
                                    {sub.username && sub.password && sub.subscription_id && sub?.contractString !== "Μη ενεργοποιημένο" && (
                                        <>
                                            <a target="_blank" rel="noreferrer" href={`https://primer.gr/my-account/view-subscription/${getSubscriptionId(sub)}/?username=${sub.username}&password=${sub.password}`}>
                                                <Badge className="ml-1 mt-1" bg="primary">Άνοιγμα συνδρομής στην ιστοσελίδα</Badge>
                                            </a>
                                        </>
                                    )}
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            ) : (
                <div className={"mb-3"}>
                    <br/>
                    <span>Ο πελάτης δεν έχει καμία συνδρομή</span>
                </div>
            )}
            <Modal show={showTimeCharges} onHide={() => resetAnalyticTimeCharges()}>
                <Modal.Header closeButton>
                    <Modal.Title>Αναλυτικά πακέτα υποστήριξης</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {getTimeCharges(subscriptionsData, timeChargeSub).length > 0 && getTimeCharges(subscriptionsData, timeChargeSub).map((timeCharge, ind) => (
                        <>
                            <span style={{fontWeight: "600"}}>Ημερομηνία Έναρξης: </span>{moment(timeCharge.startDate).format("DD-MM-YYYY HH:mm")}<br/>
                            <span style={{fontWeight: "600"}}>Ημερομηνία Λήξης: </span>{moment(timeCharge.endDate).format("DD-MM-YYYY HH:mm")}<br/>
                            <span style={{fontWeight: "600"}}>Υπολειπόμενος χρόνος (σε λεπτά): </span>
                            <input
                                className="ml-1"
                                name="popEditInput"
                                type="number"
                                value={(popEditing && popEditingT._id === timeCharge._id) ? popEditingT.minutesTime : timeCharge.minutesTime}
                                defaultValue={timeCharge.minutesTime}
                                style={{height: "18px", fontSize: "12px", width: "15%"}}
                                disabled={!(popEditing && popEditingT._id === timeCharge._id)}
                                onChange={(e) => handlePopEditChange(e)}
                            />
                            {!popEditing && checkPermission("clients-clients/edit-subscriptions-write", permissionsData) === true && (
                                <Badge bg="secondary" className="ml-1" style={{cursor: "pointer", padding: "2px"}} onClick={() => popEdit(timeCharge)}>
                                    <img src={"assets/images/callcenter/pencil.png"} style={{width: "12px", height: "12px"}} alt={"Pencil"} />
                                </Badge>
                            )}
                            {popEditing && popEditingT._id === timeCharge._id && (
                                <>
                                    <Badge bg="success" className="ml-1" style={{cursor: "pointer", padding: "2px"}} onClick={() => savePopEdit(timeCharge)}>
                                        <img src={"assets/images/callcenter/save.png"} style={{width: "12px", height: "12px"}} alt={"Save"} />
                                    </Badge>
                                    <Badge bg="danger" className="ml-1" style={{cursor: "pointer", padding: "2px"}} onClick={() => cancelPopEdit(timeCharge)}>
                                        <img src={"assets/images/callcenter/cancel.png"} style={{width: "12px", height: "12px"}} alt={"Cancel"} />
                                    </Badge>
                                </>
                            )}
                            {timeCharge.timeChargeHistory && timeCharge.timeChargeHistory?.length > 0 && (
                                <React.Fragment>
                                    <br/>
                                    <Badge bg="primary" className="mt-1" onClick={() => toggleOpenHistory(timeCharge._id)} style={{userSelect: "none", cursor: "pointer"}}>
                                        Προβολή ιστορικού χρονοχρέωσης <strong>{openHistories.includes(timeCharge._id) ? "-" : "+"}</strong>
                                    </Badge>
                                    {openHistories.includes(timeCharge._id) && (
                                        <Stack className="mt-1" gap={1}>
                                            {timeCharge.timeChargeHistory.slice(0).reverse().map((hist) => (
                                                <Badge bg="primary">{moment(hist.date).format("DD-MM-YYYY HH:mm")} : {hist.minutesConsumed} {hist.minutesConsumed === "1" ? "λεπτό" : "λεπτά"}</Badge>
                                            ))}
                                        </Stack>
                                    )}
                                </React.Fragment>
                            )}
                            {ind < getTimeCharges(subscriptionsData, timeChargeSub).length - 1 && <hr />}
                        </>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => resetAnalyticTimeCharges()}>
                        Κλείσιμο
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showEdit} backdrop="static" onHide={() => resetEditModal()} dialogClassName={isEmron && editingSub.wooResponse ? "modal6OPercentWidth" : ""}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEmron ? "Προβολή διακανονισμού" : "Τροποποίηση συνδρομής"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={(isEmron && editingSub.wooResponse) ? 6 : 12}>
                            <Row>
                                {isEmron && (
                                    <React.Fragment>
                                        <Col md={6}>
                                            <Dropdown
                                                name={"type"}
                                                label={"Τύπος"}
                                                defaultValue={createSubscriptionTypes.find((el) => el.value === editingSub?.type)}
                                                disabled={true}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input
                                                label={"Ποσό διακανονισμού"}
                                                disabled={true}
                                                value={getGreekCurrencyFormat(editingSub.price)}
                                            />
                                        </Col>
                                    </React.Fragment>
                                )}
                                <Col md={12}>
                                    <Input name="editSubscriptionName" className="mb-1" label={`Όνομα ${isEmron ? "Διακανονισμού" : "Συνδρομής"}`} disabled={true} value={editingSub?.subscriptionName} />
                                </Col>
                                <Col md={6}>
                                    <DateBox
                                        name="editStartDate"
                                        className="mb-2"
                                        label="Ημερομηνία Έναρξης"
                                        disabled={!isEmron || (isEmron && editingSub?.settlements && moment(editingSub?.settlements[0]?.date) <= moment())}
                                        selected={moment(editingSub?.startDate).toDate()}
                                        minDate={moment().add(1, "day").toDate()}
                                        onChange={(e) => handleOnEditChange(e, "editStartDate")}
                                    />
                                </Col>
                                <Col md={6}>
                                    <DateBox
                                        className="mt-1"
                                        name="editEndDate"
                                        label="Ημερομηνία Λήξης"
                                        allowOtherYears={true}
                                        selected={moment(editingSub?.endDate).toDate()}
                                        minDate={moment(editingSub.startDate).add(1, "days").toDate()}
                                        onChange={(e) => handleOnEditChange(e, "editEndDate")}
                                    />
                                </Col>
                                <Col md={12}>
                                    {isEmron && editingSub?.type === "settlement" && editingSub?.settlements?.length > 0 && (
                                        <React.Fragment>
                                            <Row>
                                                <Col md={6}>
                                                    <Dropdown
                                                        name={"frequency"}
                                                        label={"Κάθε"}
                                                        options={frequencyOptions}
                                                        defaultValue={frequencyOptions.find((el) => el.value === editingSub.frequency)}
                                                        disabled={true}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <Dropdown
                                                        name={"every"}
                                                        label={<>&nbsp;</>}
                                                        options={everyOptions}
                                                        defaultValue={everyOptions.find((el) => el.value === editingSub.every)}
                                                        disabled={true}
                                                    />
                                                </Col>
                                            </Row>
                                            <Alert variant={"secondary"}>
                                                Οι πληρωμένες δόσεις αναγράφονται με πράσινο φόντο, οι δόσεις που έχει περάσει η ημερομηνία πληρωμής τους και δεν έχουν πληρωθεί με κόκκινο ενώ οι μελλοντικές με άσπρο.
                                            </Alert>
                                            <Col md={12} className={"mb-1"}>
                                                <Button size={"sm"} onClick={() => calculateAndSetSettlementPaymentsOnEdit()}>Επαναυπολογισμός Δόσεων</Button>
                                            </Col>
                                            <table style={{tableLayout: "fixed", border: "1px solid gray", textAlign: "center", width: "100%"}} className={"mt-2"}>
                                                <colgroup>
                                                    <col style={{width: "20%"}}></col>
                                                    <col style={{width: "40%"}}></col>
                                                    <col style={{width: "40%"}}></col>
                                                </colgroup>
                                                <thead>
                                                <tr>
                                                    <th style={{border: "1px solid gray"}}>Αρ. δόσης</th>
                                                    <th style={{border: "1px solid gray"}}>Ημερομηνία</th>
                                                    <th style={{border: "1px solid gray"}}>Ποσό</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {editingSub.settlements.map((settlement, idx) => {
                                                    const stat = getSettlementPaidStatus(settlement, idx, editingSub?.orders);

                                                    return (
                                                        <tr style={{backgroundColor: stat[1]}}>
                                                            <td style={{border: "1px solid gray"}} key={`settlement${idx}ar`}>{idx + 1}</td>
                                                            <td style={{border: "1px solid gray"}} key={`settlement${idx}date`}>{moment(settlement.date).format("DD/MM/YYYY")}</td>
                                                            {stat[0] === "none" ? (
                                                                <td style={{border: "1px solid gray"}} key={`settlement${idx}am`}>
                                                                    <input
                                                                        type={"number"}
                                                                        style={{width: "50%"}}
                                                                        value={settlement.amount}
                                                                        onChange={(e) => handleOnSettlementAmountEditChange(e, idx)}
                                                                    />
                                                                    {"   €"}
                                                                </td>
                                                            ) : (
                                                                <td style={{border: "1px solid gray"}} key={`settlement${idx}am`}>{getGreekCurrencyFormat(settlement.amount)}</td>
                                                            )}
                                                        </tr>
                                                    )
                                                })}
                                                <tr>
                                                    <td colSpan={2} style={{
                                                        textAlign: "right",
                                                        border: "1px solid gray",
                                                        fontWeight: "700"
                                                    }}>Σύνολο
                                                    </td>
                                                    <td style={{border: "1px solid gray"}}>{getGreekCurrencyFormat(calculateSettlementEditSum())}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            {calculateSettlementEditSum() !== Number(editingSub.price) && (
                                                <div style={{color: "red"}}>
                                                    (*) Το άθροισμα των ποσών των δόσεων δεν είναι ίσο με το ποσό του διακανονισμού.<br/>
                                                    (Υπάρχει {getBalanceString(calculateSettlementEditSum(), editingSub.price)})
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                        {(isEmron && editingSub.wooResponse) && (
                            <Col md={6} style={{borderLeft: "1px solid lightgray"}}>
                                <Row className={"mb-2"}>
                                    <Col md={12}>
                                        <div style={{textAlign: "center", fontSize: "15px"}}>Πληροφορίες Συνδρομής
                                            WooCommerce
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={"mb-2"}>
                                    <Col md={6}>
                                        <Input label={"Αρ. Συνδρομής"} disabled={true}
                                               value={editingSub.wooResponse.id}/>
                                    </Col>
                                    <Col md={6}>
                                        <Input label={"Κατάσταση"} disabled={true}
                                               value={editingSub.wooResponse.status}/>
                                    </Col>
                                </Row>
                                <Row className={"mb-2"}>
                                    <Col md={6}>
                                        <Input label={"Ημερομηνία Έναρξης"} disabled={true}
                                               value={dateNiceFormat(editingSub.wooResponse.start_date)}/>
                                    </Col>
                                    <Col md={6}>
                                        <Input label={"Ημερομηνία Λήξης"} disabled={true}
                                               value={dateNiceFormat(editingSub.wooResponse.end_date)}/>
                                    </Col>
                                </Row>
                                <Row className={"mb-2"}>
                                    <Col md={6}>
                                        <Input label={"Ημερομηνία Επόμενης Πληρωμής"} disabled={true}
                                               value={dateNiceFormat(editingSub.wooResponse.next_payment_date)}/>
                                    </Col>
                                    <Col md={6}>
                                        <Input label={"Ποσό Επόμενης Πληρωμής"} disabled={true}
                                               value={isEmpty(editingSub.wooResponse.total) ? "-" : editingSub.wooResponse.total} />
                                    </Col>
                                </Row>
                                {editingSub?.orders?.length > 0 && (
                                    <Row>
                                        <Col md={12}>
                                            <div style={{textAlign: "center"}}><h6>Ιστορικό παραγγελιών</h6></div>
                                            <table style={{tableLayout: "fixed", width: "100%", textAlign: "center"}}>
                                                <thead>
                                                <tr>
                                                    <th style={{border: "1px solid gray"}}>Ημ/νια πληρωμής</th>
                                                    <th style={{border: "1px solid gray"}}>Αρ. Παραγγελίας</th>
                                                    <th style={{border: "1px solid gray"}}>Transaction ID</th>
                                                    <th style={{border: "1px solid gray"}}>Ποσό πληρωμής</th>
                                                    <th style={{border: "1px solid gray", width: "25px"}}>&nbsp;</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {editingSub?.orders?.map((order, idx) => (
                                                    <tr key={`orderRowEP${idx}`} style={{backgroundColor: isEmpty(order.transaction_id) ? "#ff9696" : ""}}>
                                                        <td style={{border: "1px solid gray"}}>{isEmpty(order.date_paid) ? "-" : moment(order.date_paid).format("DD/MM/YYYY HH:mm:ss")}</td>
                                                        <td style={{border: "1px solid gray"}}>{order.id}</td>
                                                        <td style={{border: "1px solid gray"}}>{isEmpty(order.transaction_id) ? "Αποτυχία χρέωσης" : order.transaction_id}</td>
                                                        <td style={{border: "1px solid gray"}}>{getGreekCurrencyFormat(Number(order.total))}</td>
                                                        <td style={{border: "1px solid gray", width: "25px"}}>{!isEmpty(order.transaction_id) && (
                                                            <i className="fa-regular fa-file-lines" style={{padding: 0, cursor: "pointer"}} title={"Προβολή Είσπραξης"} onClick={() => handleOrderTransactionViewClicked(order.transaction_id)}></i>
                                                        )}
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                )}
                                <Row className={"mt-2 mb-2"}>
                                    <Col md={12}>
                                        <TextArea
                                            label={"Σημειώσεις"}
                                            rows={3}
                                            disabled={true}
                                            value={editingSub.notes}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mt-1"}>
                                    <Col md={12}>
                                        <BetterCheckBox
                                            name={"cancelSettlement"}
                                            text={"Ακύρωση διακανονισμού κατά την αποθήκευση"}
                                            className={"mb-0"}
                                            checked={editingSub?.cancelSettlement === "true"}
                                            disabled={["expired", "cancelled"].includes(editingSub.wooResponse.status)}
                                            onChange={(e) => setEditingSub({...editingSub, cancelSettlement: String(e.target.checked)})}
                                        />
                                        {editingSub?.cancelSettlement === "true" && (
                                            <div style={{color: "red", textAlign: "center"}}>(*) ΠΡΟΣΟΧΗ! Ο διακανονισμός θα ακυρωθεί κατά την αποθήκευση!</div>
                                        )}
                                    </Col>
                                </Row>
                                {/*
                                <Button size={"sm"} onClick={() => handleOpenEmronWooSub(CLIENTS_DATA.editClient.vatNumber)}>Άνοιγμα διακανονισμού στην ιστοσελίδα</Button>
                                */}
                            </Col>
                        )}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => resetEditModal()}>
                        Κλείσιμο
                    </Button>
                    <Button variant="primary" onClick={() => saveEditedClicked()} disabled={loadingEditSub || calculateSettlementEditSum() !== Number(editingSub.price)}>
                        Αποθήκευση {loadingEditSub && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default EditClientSubscriptions
