import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Accordion, Col, Row, Spinner} from "react-bootstrap";
import {
    setEditPrescription,
    setEditPrescriptionLoading,
    setEditPrescriptionReload
} from "../../../../_reducers/DataPanel/Prescription/prescriptionSlice";
import axios from "axios";
import {toast} from "react-toastify";
import "./styles.css";
import Input from "../../../common/Input";
import moment from "moment";
import Button from "react-bootstrap/Button";
import TextArea from "../../../common/TextArea";
import Dropdown from "../../../primer/Dropdown";
import {
    getNoInnCasesOptions,
    prescriptionDoseAmountOptions,
    prescriptionDrugAmountOptions, prescriptionFrequencyOptions,
    prescriptionParticipationPercentageOptions
} from "./constants";
import BetterCheckBox from "../../../common/BetterCheckBox";

const PrescriptionEdit = () => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const editPrescription = useSelector((state) => state.PRESCRIPTION.editPrescription);
    const editPrescriptionReload = useSelector((state) => state.PRESCRIPTION.editPrescriptionReload);
    const editPrescriptionLoading = useSelector((state) => state.PRESCRIPTION.editPrescriptionLoading);

    const [loadingPrint, setLoadingPrint] = useState(false);

    useEffect(() => {
        if (editPrescriptionReload) {
            dispatch(setEditPrescriptionReload(false));
            fetchEditPrescription();
        }
    }, [editPrescriptionReload]);

    const fetchEditPrescription = () => {
        dispatch(setEditPrescriptionLoading(true));
        axios.get(`${process.env.REACT_APP_API_URL2}/prescription/fetch-prescription`, {
            headers: { 'Content-Type': 'application/json' },
            params: {
                barcode: editPrescription.barcode,
                company: company.id
            }
        }).then((res) => {
            if (res.data.status === "200") {
                dispatch(setEditPrescription(res.data.data));
            } else {
                toast.error(res.data.message);
            }
            dispatch(setEditPrescriptionLoading(false));
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την άντληση συνταγής.");
            dispatch(setEditPrescriptionLoading(false));
        })
    }

    const handlePrint = () => {
        setLoadingPrint(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/prescription/print-prescription`, {
            company: company.id,
            barcode: editPrescription.barcode
        }).then((res) => {
            if (res.data.status === "200") {
                const binaryString = atob(res.data.data);
                const byteArray = new Uint8Array(binaryString.split('').map((char) => char.charCodeAt(0)));
                const blob = new Blob([byteArray], { type: "application/pdf" });
                let blobUrl = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = `Συνταγή ${editPrescription.barcode}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);
            } else {
                toast.error(res.data.message);
            }
            setLoadingPrint(false);
        }).catch((err) => {
            setLoadingPrint(false);
            console.log(err);
            toast.error("Σφάλμα κατά την άντληση.");
        })
    }

    const daysAmount = [
        { value: "1", label: "1 ημέρα" },
        ...Array.from({length: 59}, (_, i) => {
            return {
                value: String(i + 2),
                label: `${i + 2} ημέρες`
            }
        })
    ]

    return (
        <React.Fragment>
            {editPrescriptionLoading ? (
                <React.Fragment>
                    <Row className={"mb-2"}>
                        <Col md={12} className={"d-flex justify-content-center"}>
                            Παρακαλώ περιμένετε... <Spinner size={"sm"} className={"ml-2"} animation={"border"} variant={"dark"} />
                        </Col>
                    </Row>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Row className={"mb-2"}>
                        <Col md={12}>
                            <div style={{textAlign: "center"}}>
                                <h5>Συνταγή με barcode : <strong>{editPrescription.barcode}</strong></h5>
                            </div>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col md={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Πληροφορίες Ιατρού ({editPrescription.doctorName})</Accordion.Header>
                                    <Accordion.Body>
                                        <Row className={"mb-2"}>
                                            <Col md={6}>
                                                <Input
                                                    name={"doctorName"}
                                                    label={"Ονοματεπώνυμο"}
                                                    value={editPrescription.doctorName}
                                                    disabled={true}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <Input
                                                    name={"doctorEmail"}
                                                    label={"E-Mail"}
                                                    value={editPrescription.doctorEmail}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className={"mb-2"}>
                                            <Col md={6}>
                                                <Input
                                                    name={"doctorAMKA"}
                                                    label={"ΑΜΚΑ"}
                                                    value={editPrescription.doctorAMKA}
                                                    disabled={true}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <Input
                                                    name={"doctorAMETAA"}
                                                    label={"Α.Μ.Ε.Τ.Α.Α"}
                                                    value={editPrescription.doctorAMETAA}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <Input
                                                    name={"doctorSpecialty"}
                                                    label={"Ειδικότητα"}
                                                    value={editPrescription.doctorSpecialty}
                                                    disabled={true}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <Input
                                                    name={"prescriptionUnity"}
                                                    label={"Μονάδα Συνταγογράφησης"}
                                                    value={editPrescription.prescriptionUnity}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Πληροφορίες Ασθενή ({editPrescription.patientName})</Accordion.Header>
                                    <Accordion.Body>
                                        <Row className={"mb-2"}>
                                            <Col md={6}>
                                                <Input
                                                    name={"patientName"}
                                                    label={"Ονοματεπώνυμο"}
                                                    value={editPrescription.patientName}
                                                    disabled={true}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <Input
                                                    name={"patientAMKA"}
                                                    label={"ΑΜΚΑ"}
                                                    value={editPrescription.patientAMKA}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className={"mb-2"}>
                                            <Col md={6}>
                                                <Input
                                                    name={"patientSocialInsurance"}
                                                    label={"Φ.Κ.Α."}
                                                    value={editPrescription.patientSocialInsurance}
                                                    disabled={true}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <Input
                                                    name={"patientSocialInsuranceDate"}
                                                    label={"Ημ/νία Λήξης Ασφαλ. Ικανότητας"}
                                                    value={editPrescription.patientSocialInsuranceDate}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className={"mb-2"}>
                                            <Col md={4}>
                                                <Input
                                                    name={"patientBirthDate"}
                                                    label={"Ημερομηνία Γέννησης"}
                                                    value={editPrescription.patientBirthDate ? moment(editPrescription.patientBirthDate, "YYYYMMDD").format("DD/MM/YYYY") : ""}
                                                    disabled={true}
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <Input
                                                    name={"patientSex"}
                                                    label={"Φύλο"}
                                                    value={editPrescription.patientSex}
                                                    disabled={true}
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <Input
                                                    name={"patientAMA"}
                                                    label={"AMA"}
                                                    value={editPrescription.patientAMA}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className={"mb-2"}>
                                            <Col md={6}>
                                                <Input
                                                    name={"patientSocialInsuranceType"}
                                                    label={"Τύπος Ασφάλισης"}
                                                    value={editPrescription.patientSocialInsuranceType}
                                                    disabled={true}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <Input
                                                    name={"patientAddress"}
                                                    label={"Διεύθυνση"}
                                                    value={editPrescription.patientAddress}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className={"mb-2"}>
                                            <Col md={6}>
                                                <Input
                                                    name={"patientCity"}
                                                    label={"Πόλη"}
                                                    value={editPrescription.patientCity}
                                                    disabled={true}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <Input
                                                    name={"patientPostal"}
                                                    label={"Τ.Κ."}
                                                    value={editPrescription.patientPostal}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className={"mb-3 borderedContainer"}>
                                <h5 style={{fontWeight: "600"}}>Πληροφορίες Συνταγής</h5>
                                <table style={{width: "100%", fontSize: "16px"}}>
                                    <tbody>
                                    <tr>
                                        <td>Κατάσταση</td>
                                        <td style={{textAlign: "right"}}>{editPrescription.status}</td>
                                    </tr>
                                    <tr>
                                        <td>Ημ/νία Έκδοσης</td>
                                        <td style={{textAlign: "right"}}>{editPrescription.startDate}</td>
                                    </tr>
                                    <tr>
                                        <td>Ημ/νία Προθεσμίας Εκτέλεσης</td>
                                        <td style={{textAlign: "right"}}>{editPrescription.endDate}</td>
                                    </tr>
                                    <tr>
                                        <td>Επαναλήψεις Συνταγής</td>
                                        <td style={{textAlign: "right"}}></td>
                                    </tr>
                                    <tr>
                                        <td>Τύπος Συνταγής/Είδος Θεραπείας</td>
                                        <td style={{textAlign: "right"}}>Τυπική</td>
                                    </tr>
                                    <tr>
                                        <td>Επαναλήψεις Συνταγής</td>
                                        <td style={{textAlign: "right"}}></td>
                                    </tr>
                                    <tr>
                                        <td>Ιατρική Γνωμάτευση</td>
                                        <td style={{textAlign: "right"}}></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        <Col md={6}>
                            {editPrescription.diagnoses && (
                                <div className={"mb-3 borderedContainer"}>
                                    <h5 style={{fontWeight: "600"}}>Διαγνώσεις</h5>
                                    <table className={"simpleClassicTable"}>
                                        <colgroup>
                                            <col span={1} style={{width: "30%"}}></col>
                                            <col span={1} style={{width: "70%"}}></col>
                                        </colgroup>
                                        <thead>
                                        <tr>
                                            <th>Κωδικός ICD10</th>
                                            <th>Περιγραφή</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {editPrescription.diagnoses.map((diag) => (
                                            <tr>
                                                <td>{diag.code}</td>
                                                <td>{diag.displayName}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <div className={"mb-3 borderedContainer"}>
                        <h5 style={{fontWeight: "600"}}>Χαρακτηριστικά Συνταγής</h5>
                        <Row className={"mb-2 mt-1"}>
                            <Col md={3}>
                                <BetterCheckBox
                                    name={"monthlyTreatment"}
                                    text={"Θεραπεία μηνός"}
                                    checked={editPrescription.monthlyTreatment || (editPrescription.repeat === "1" && editPrescription.bimonthlyTreatment)}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={3}>
                                <BetterCheckBox
                                    name={"bimonthlyTreatment"}
                                    text={"Δίμηνη Θεραπεία"}
                                    checked={editPrescription.bimonthlyTreatment}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={3}>
                                <BetterCheckBox
                                    name={"oneDose"}
                                    text={"Μονοδοσικά"}
                                    checked={editPrescription.oneDose}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={3}>
                                <BetterCheckBox
                                    name={"chronicIllness"}
                                    text={"Χρόνια Πάθηση"}
                                    checked={editPrescription.chronicIllness}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <BetterCheckBox
                                    name={"prescriptionWithCommercialName"}
                                    text={"Συνταγογράφηση με εμπορική ονομασία"}
                                    checked={editPrescription.prescriptionWithCommercialName}
                                    disabled={true}
                                />
                            </Col>
                            {editPrescription.prescriptionWithCommercialName && (
                                <React.Fragment>
                                    <Col md={3}>
                                        <Dropdown
                                            className={"mt-0 mb-1"}
                                            name={"prescriptionWithCommercialNameCause"}
                                            label={"Αιτιολογία συντ/σης με εμπορική ονομασία"}
                                            key={Math.random()}
                                            options={getNoInnCasesOptions()}
                                            defaultValue={getNoInnCasesOptions().find((el) => el.value === editPrescription.prescriptionWithCommercialNameCause)}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <TextArea
                                            name={"prescriptionWithCommercialNameNotes"}
                                            label={"Σχόλια συντ/σης με εμπορική ονομασία"}
                                            rows={3}
                                            value={editPrescription.prescriptionWithCommercialNameNotes || ""}
                                        />
                                    </Col>
                                </React.Fragment>
                            )}
                        </Row>
                    </div>
                    <div className={"mb-3 borderedContainer"}>
                        <h5 style={{fontWeight: "600"}}>Θεραπείες/Φάρμακα Συνταγής</h5>
                        <Row>
                            <Col md={12}>
                                {editPrescription.drugs && (
                                    <table className={"simpleClassicTable"}>
                                        <colgroup>
                                            <col span={1} style={{width: "20%"}}></col>
                                            <col span={1} style={{width: "15%"}}></col>
                                            <col span={1} style={{width: "10%"}}></col>
                                            <col span={1} style={{width: "10%"}}></col>
                                            <col span={1} style={{width: "10%"}}></col>
                                            <col span={1} style={{width: "10%"}}></col>
                                            <col span={1} style={{width: "15%"}}></col>
                                            <col span={1} style={{width: "10%"}}></col>
                                        </colgroup>
                                        <thead>
                                        <tr>
                                            <th>Δραστική Ουσία</th>
                                            <th>Πληροφορίες</th>
                                            <th>Ποσότητα</th>
                                            <th>Συμμετοχή (%)</th>
                                            <th>Ποσό Δόσης</th>
                                            <th>Μονάδα Δόσης</th>
                                            <th>Συχνότητα</th>
                                            <th>Ημέρες</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {editPrescription.drugs.map((drug, idx) => (
                                            <React.Fragment>
                                                <tr key={`drugsTable-${idx}`}>
                                                    <td>{drug.mainActiveSubstance}</td>
                                                    <td>{drug.erp_displayName}</td>
                                                    <td>
                                                        <Dropdown
                                                            name={"erp_drugAmount"}
                                                            className={"m-0"}
                                                            options={prescriptionDrugAmountOptions}
                                                            key={Math.random()}
                                                            defaultValue={prescriptionDrugAmountOptions.find((el) => el.value === drug.erp_drugAmount)}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Dropdown
                                                            name={"erp_participationPercentage"}
                                                            className={"m-0"}
                                                            options={prescriptionParticipationPercentageOptions}
                                                            key={Math.random()}
                                                            defaultValue={prescriptionParticipationPercentageOptions.find((el) => el.value === drug.erp_participationPercentage)}
                                                            disabled={true}

                                                        />
                                                    </td>
                                                    <td>
                                                        <Dropdown
                                                            name={"erp_doseAmount"}
                                                            className={"m-0"}
                                                            options={prescriptionDoseAmountOptions}
                                                            key={Math.random()}
                                                            defaultValue={prescriptionDoseAmountOptions.find((el) => el.value === drug.erp_doseAmount)}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        {drug.doseUnit}
                                                    </td>
                                                    <td>
                                                        <Dropdown
                                                            name={"erp_frequency"}
                                                            className={"m-0"}
                                                            options={prescriptionFrequencyOptions}
                                                            key={Math.random()}
                                                            defaultValue={prescriptionFrequencyOptions.find((el) => el.value === drug.erp_frequency)}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Dropdown
                                                            name={"erp_days"}
                                                            className={"m-0"}
                                                            options={daysAmount}
                                                            key={Math.random()}
                                                            defaultValue={daysAmount?.find((el) => el.value === drug.erp_days)}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr key={`drugsTable-${idx}-ei`} style={{textAlign: "left"}}>
                                                    <td colSpan={10} style={{backgroundColor: "#f5f5f5"}} className={"p-1"}>
                                                        <Row>
                                                            <Col md={4}>
                                                                <TextArea
                                                                    name={"erp_notes"}
                                                                    label={"Σχόλια"}
                                                                    rows={3}
                                                                    value={drug.erp_notes || ""}
                                                                    limit={100}
                                                                />
                                                            </Col>
                                                            <Col md={4}>
                                                                <span><strong>Συσχετιζόμενες διαγνώσεις:</strong></span>
                                                                {drug.erp_correlated_icd10s.map((icd10code) => (
                                                                    <div style={{fontSize: "14px"}}>
                                                                        - {icd10code} : {editPrescription.diagnoses.find((el) => el.code === icd10code)?.displayName}
                                                                    </div>
                                                                ))}
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                        </tbody>
                                    </table>
                                )}
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col md={6}>
                            <div className={"mb-3 borderedContainer"}>
                                <h5 style={{fontWeight: "600"}}>Σχόλια Συνταγής</h5>
                                <TextArea
                                    rows={3}
                                    value={editPrescription.notes || ""}
                                />
                            </div>
                        </Col>
                        {["new", "completed"].includes(editPrescription.status) && (
                            <Col md={6}>
                                <div className={"mb-3 borderedContainer"}>
                                    <h5 style={{fontWeight: "600"}}>Λοιπά</h5>
                                    <Button size={"sm"} onClick={() => handlePrint()} disabled={loadingPrint}>
                                        Λήψη PDF συνταγής
                                        {loadingPrint && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                                    </Button>
                                </div>
                            </Col>
                        )}
                    </Row>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default PrescriptionEdit
