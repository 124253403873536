import React, {useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {classicStyleBelowNavbar} from "../Statics";
import {useDispatch, useSelector} from "react-redux";
import {
    setDailyProgram,
    setDailyProgramExtraData,
    setDailyProgramSortColumn,
    setDailyProgramSortMethod,
    setQRScannerRequestData
} from "../../../../_reducers/DataPanel/Payroll/payrollSlice";
import Dropdown from "../../../primer/Dropdown";
import axios from "axios";
import {toast} from "react-toastify";
import "./Components/tableStyle.css";
import {isEmpty} from "../../../../_helpers/commonFunctions";
import CameraAccess from "./cameraAccess";
import moment from "moment";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import Button from "react-bootstrap/Button";
import {colorMapping, dayTranslate, decimalToHourMinutesFormat, getWorkType} from "./Components/constants";

const PayrollQrScanner = () => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const requestData = useSelector((state) => state.PAYROLL.qrScannerRequestData);
    const payrollSettings = useSelector((state) => state.PAYROLL.payrollSettings);
    const dailyProgram = useSelector((state) => state.PAYROLL.dailyProgram);
    const dailyProgramExtraData = useSelector((state) => state.PAYROLL.dailyProgramExtraData);
    const dailyProgramSortColumn = useSelector((state) => state.PAYROLL.dailyProgramSortColumn);
    const dailyProgramSortMethod = useSelector((state) => state.PAYROLL.dailyProgramSortMethod);

    const [tableColumns, setTableColumns] = useState([]);

    const [installationOptions, setInstallationOptions] = useState([]);
    const [enableQRReader, setEnableQRReader] = useState(false);

    const [waitingResponse, setWaitingResponse] = useState(false);
    const [qrResult, setQRResult] = useState("");
    const [lastScan, setLastScan] = useState(moment().format("DD/MM/YYYY HH:mm:ss"));

    const [backgroundQuickColor, setBackgroundQuickColor] = useState("white");

    const [selectedCamera, setSelectedCamera] = useState("");
    const [cameraDevices, setCameraDevices] = useState([]);
    const [cameraIdx, setCameraIdx] = useState(0);

    const todayBorderStyle = "3px solid #68a0fc";

    useEffect(() => {
        if (companyInstallations.length > 0) {
            let options = [];
            companyInstallations.forEach((inst) => {
                options.push({ value: inst.installationMasterId, label: inst.type + " " + inst.address + " " + inst.city });
            })
            setInstallationOptions(options);
        }
    }, [])

    useEffect(() => {
        if (requestData.installationMasterId) {
            fetchDailyProgram();
        }
    }, [requestData.installationMasterId])

    useEffect(() => {
        if (!isEmpty(qrResult)) handleQRScan(qrResult);
    }, [qrResult])

    useEffect(() => {
        if (enableQRReader && cameraDevices.length === 0) {
            navigator.mediaDevices.enumerateDevices().then((devices) => {
                const videoDevices = devices.filter((device) => device.kind === "videoinput");
                setCameraDevices(videoDevices);
                if (videoDevices.length > 0) {
                    setSelectedCamera(videoDevices[0].deviceId); // Set the first camera by default
                    setCameraIdx(0);
                }
            });
        }
    }, [enableQRReader]);

    useEffect(() => {
        if (dailyProgram) {
            let tc = [{
                date: "",
                columnDisplay: "Εργαζόμενοι"
            }];
            const from = moment().subtract(1, "days");
            const to = moment();
            const diff = Number(to.diff(from, "days")) + 1;
            for (let i = 0; i < diff; i++) {
                let curMomentFormat = from.format("DD/MM/YYYY");
                let curMomentDay = from.format("dddd");

                tc.push({
                    date: curMomentFormat,
                    columnDisplay: <React.Fragment>
                        {dayTranslate[curMomentDay]}{moment().format("DD/MM/YYYY") === curMomentFormat && " (Σήμερα)"}<br/>{curMomentFormat}
                    </React.Fragment>,
                })
                from.add(1, "days");
            }
            setTableColumns(tc);
        }
    }, [dailyProgram])

    useEffect(() => {
        if (!isEmpty(dailyProgram)) {
            const dailyProgramCopy = structuredClone(dailyProgram);
            const dayIndex = dailyProgramSortColumn === 1 ? moment().day() - 1 : moment().day();
            dailyProgramCopy.sort((el1, el2) => {
                const program_1 = el1?.dailyCalendar.filter((el) => el?.day === dayIndex);
                const program_2 = el2?.dailyCalendar.filter((el) => el?.day === dayIndex);

                const earliestHour_1 = !isEmpty(program_1) ? program_1.reduce((min, el) => el.hourFrom < min?.hourFrom ? el : min).hourFrom : 0;
                const earliestHour_2 = !isEmpty(program_2) ? program_2?.reduce((min, el) => el.hourFrom < min?.hourFrom ? el : min).hourFrom : 0;

                return dailyProgramSortMethod === "DES" ? earliestHour_2 - earliestHour_1 : earliestHour_1 - earliestHour_2;
            });
            dispatch(setDailyProgram(dailyProgramCopy));
        }
    }, [dailyProgramSortColumn, dailyProgramSortMethod]);

    const renderEmployeeRow = (emp, lastRow = false) => {
        if (emp) {
            const sId = String(emp.employeeIdentifier).split("|");
            const firstName = sId[0];
            const lastName = sId[1];
            const vatNumber = sId[2];
            return (
                <tr style={{height: `50px`, position: "relative"}}>
                    <td style={{position: "relative", height: "100%", width: "100%"}}>
                        {firstName} {lastName}<br/>(ΑΦΜ: {vatNumber})
                        {emp?.persistent === "true" && (
                            <span><br/>(Σταθερό πρόγραμμα)</span>
                        )}
                    </td>
                    {tableColumns.toSpliced(0, 1).map((col, colIdx) => {
                        const day = colIdx === 0 ? moment().day() -1 : moment().day();
                        if (moment().format("DD/MM/YYYY") === col.date) {
                            return (
                                <td style={{borderLeft: todayBorderStyle, borderRight: todayBorderStyle, borderBottom: lastRow ? todayBorderStyle : ""}}>
                                    <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center"}}>
                                        {renderEmployeeCell(emp, day)}
                                    </div>
                                </td>
                            )
                        } else {
                            return (
                                <td><div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center"}}>{renderEmployeeCell(emp, day)}</div></td>
                        )
                        }
                    })}
                </tr>
            )
        } else {
            return <React.Fragment></React.Fragment>
        }
    }

    const renderEmployeeCell = (emp, day) => {
        const findMatchingDay = emp?.dailyCalendar?.filter((el) => el.day === day);

        if (findMatchingDay?.length > 0) {
            let maxHeight = 50;
            if (findMatchingDay.length > 1) maxHeight = 100;
            const height = maxHeight / findMatchingDay.length;
            let dayComponents = [];
            for (let dc of findMatchingDay) {
                dayComponents.push(
                    <div style={{position: "relative", flex: 1, width: "100%", height: `${height}px`, backgroundColor: colorMapping[dc.workType], margin: "4px", padding: "4px", borderRadius: "8px"}}>
                        {getWorkType(dc)}<br/>
                        {dc.allDay ? (
                            <React.Fragment>
                                Όλη μέρα<br/>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {decimalToHourMinutesFormat(dc.hourFrom)} - {decimalToHourMinutesFormat(dc.hourTo)}<br/>
                            </React.Fragment>
                        )}
                    </div>
                )
            }
            if (dailyProgramExtraData.erganiCards) {
                if (dailyProgramExtraData.erganiCards[emp.employeeId]) {
                    if (dailyProgramExtraData.erganiCards[emp.employeeId][String(day)]) {
                        const cardHits = dailyProgramExtraData.erganiCards[emp.employeeId][String(day)];
                        let displayValues = [];
                        for (let hit of cardHits) {
                            const values = String(hit).split("|");
                            displayValues.push(`${values[0] === "arrive" ? "Άφιξη" : "Αναχώρηση"} ${values[1]}`);
                        }
                        displayValues = displayValues.join(", ");
                        dayComponents.push(
                            <div style={{position: "relative", paddingLeft: "5px", paddingRight: "5px", width: "100%", margin: "4px", borderRadius: "4px", backgroundColor: "lightgray", color: "black", paddingTop: "3px", paddingBottom: "3px"}}>
                                {displayValues}
                            </div>
                        )
                    }
                }
            }
            return dayComponents;
        } else {
            return (
                <React.Fragment>

                </React.Fragment>
            )
        }
    }

    const fetchDailyProgram = () => {
        axios.get(`${process.env.REACT_APP_API_URL2}/payroll/qr-scanner-daily-data`, {
            headers: { "Content-Type": "application/json" },
            params: {
                company: company.id,
                installationMasterId: requestData.installationMasterId,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                dispatch(setDailyProgram(res.data.data));
                dispatch(setDailyProgramExtraData(res.data.extraData));
            } else {
                dispatch(setDailyProgram([]));
                dispatch(setDailyProgramExtraData([]));
                toast.error(res.data.message);
            }
            dispatch(setDailyProgramSortColumn(null))
            dispatch(setDailyProgramSortMethod(null))
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την άντληση ημερήσιου προγράμματος.");
        })
    }

    const sortTable = (idx) => {
        if (!isEmpty(dailyProgram)) {
            if (dailyProgramSortColumn === idx) {
                dispatch(setDailyProgramSortMethod(dailyProgramSortMethod === "DES" ? "ASC" : "DES"));
            } else {
                dispatch(setDailyProgramSortMethod("ASC"));
                dispatch(setDailyProgramSortColumn(idx));
            }
        }
    }

    const handleQRScan = (result) => {
        if (moment().diff(moment(lastScan, "DD/MM/YYYY HH:mm:ss"), "seconds") > 2 && !waitingResponse) {
            setLastScan(moment().format("DD/MM/YYYY HH:mm:ss"));
            setWaitingResponse(true);
            axios.post(`${process.env.REACT_APP_API_URL2}/payroll/post-qr-movement`, {
                company: company.id,
                qr: result,
                installationMasterId: requestData.installationMasterId
            }, {
                headers: { "Content-Type": "application/json" },
            }).then((res) => {
                setWaitingResponse(false);
                if (res.data.status === "200") {
                    toast.success("Η κάρτα πέρασε επιτυχώς.");
                    setBackgroundQuickColor("#a1ffa3");
                    setTimeout(() => {
                        setBackgroundQuickColor("white");
                    }, 1000)
                    fetchDailyProgram();
                } else {
                    toast.error(res.data.message);
                    setBackgroundQuickColor("#ff8b8b");
                    setTimeout(() => {
                        setBackgroundQuickColor("white");
                    }, 1000)
                }
                setQRResult("");
            }).catch((err) => {
                setQRResult("");
                setWaitingResponse(false);
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος, η κάρτα ΔΕΝ πέρασε.");
            })
        }
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row className={"mb-2"}>
                <Col md={4}>
                    <Dropdown
                        name={"installationMasterId"}
                        label={"Εγκατάσταση"}
                        defaultValue={installationOptions.find((el) => el.value === requestData.installationMasterId)}
                        key={Math.random()}
                        options={installationOptions}
                        onChange={(e) => dispatch(setQRScannerRequestData({...requestData, installationMasterId: e.value}))}
                    />
                </Col>
                {payrollSettings.erganiCardSimulation === true && (
                    <Col md={4}>
                        <div style={{marginTop: "25px"}}>
                            <h3><u>ΚΑΤΑΣΤΑΣΗ ΕΞΟΜΟΙΩΣΗΣ</u></h3>
                        </div>
                    </Col>
                )}
            </Row>
            <Row>
                <Col md={6} style={{position: "relative"}}>
                    <div style={{
                        position: "relative",
                        borderRadius: "12px",
                        border: `1px solid ${backgroundQuickColor === "white" ? "#6ea2a9" : backgroundQuickColor}`,
                        height: "100%",
                        backgroundColor: backgroundQuickColor
                    }}>
                        {!enableQRReader ? (
                            <CameraAccess accessGrantedFunction={(val) => setEnableQRReader(val)} />
                        ) : (
                            <React.Fragment>
                                <div style={{fontSize: "18px", textAlign: "center", margin: "2%"}}>
                                    {waitingResponse ? (
                                        <div>Γίνεται έλεγχος, παρακαλώ περιμένετε...</div>
                                    ) : (
                                        <div>Κάντε σκαν το QR</div>
                                    )}
                                </div>
                                <div style={{paddingLeft: "5%", paddingRight: "5%", margin: "2%"}}>
                                    <BarcodeScannerComponent
                                        delay={500}
                                        onUpdate={(error, result) => {
                                            if (result && !isEmpty(result?.getText())) {
                                                setQRResult(result.getText());
                                            } else {
                                                setQRResult("");
                                            }
                                        }}
                                        videoConstraints={{
                                            deviceId: { exact: selectedCamera },
                                        }}
                                    />
                                </div>
                                {cameraDevices.length > 1 && (
                                    <div style={{textAlign: "center"}}>
                                        <Button size={"sm"} onClick={() => {
                                            setEnableQRReader(false);
                                            setTimeout(() => {
                                                if (!cameraDevices[cameraIdx + 1]) {
                                                    setSelectedCamera(cameraDevices[0].deviceId);
                                                    setCameraIdx(0);
                                                } else {
                                                    setSelectedCamera(cameraDevices[cameraIdx + 1].deviceId);
                                                    setCameraIdx(cameraIdx + 1);
                                                }
                                                setTimeout(() => {
                                                    setEnableQRReader(true);
                                                }, 250);
                                            }, 250);
                                        }}>Εναλλαγή κάμερας</Button>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </Col>
                <Col md={6}>
                    <div style={{borderRadius: "12px", border: "1px solid #6ea2a9", padding: "12px", height: "100%"}}>
                        <div style={{textAlign: "center"}}>
                            <h1>Πρόγραμμα Εργαζομένων</h1>
                        </div>
                        {dailyProgram && (
                            <React.Fragment>
                                <Row>
                                    <Col md={12}>
                                        <table className={"employeeProgramTable programView mb-3"} style={{fontSize: "12px"}}>
                                            <thead style={{backgroundColor: "var(--light-skyblue)", fontSize: "14px", position: "sticky", zIndex: "200"}}>
                                            <tr>
                                                {tableColumns.map((col, idx) => {
                                                    if (moment().format("DD/MM/YYYY") === col.date) {
                                                        return (
                                                            <th key={`col-${idx}`} onClick={() => sortTable(idx)}
                                                                style={{
                                                                    outline: "1px solid #68a0fc",
                                                                    border: "0",
                                                                    borderRight: "1px solid #68a0fc",
                                                                    borderLeft: "1px solid #68a0fc",
                                                                    backgroundColor: "#68a0fc",
                                                                    position: "relative",
                                                                    color: "white",
                                                                    cursor: "pointer",
                                                                }}>
                                                                <div style={{display: "flex", justifySelf: "center", alignItems: "center"}}>
                                                                    <div>{col.columnDisplay}</div>
                                                                    <i style={{visibility: `${dailyProgramSortColumn !== idx ? "hidden" : "visible"}`}} className={`ml-1 fa-solid ${dailyProgramSortMethod === "DES" ? "fa-arrow-down" : "fa-arrow-up"}`}></i>
                                                                </div>
                                                            </th>
                                                        )
                                                    } else {
                                                        return (
                                                            idx === 0 ?
                                                            <th key={`col-${idx}`}>
                                                                {col.columnDisplay}
                                                            </th>
                                                                :
                                                            <th key={`col-${idx}`} style={{cursor: "pointer"}} onClick={() => sortTable(idx)}>
                                                                <div style={{ display: "flex", justifySelf: "center", alignItems: "center"}}>
                                                                    <div>{col.columnDisplay}</div>
                                                                    <i style={{visibility: `${dailyProgramSortColumn !== idx ? "hidden" : "visible"}`}} className={`ml-1 fa-solid ${dailyProgramSortMethod === "DES" ? "fa-arrow-down" : "fa-arrow-up"}`}></i>
                                                                </div>
                                                            </th>
                                                        )
                                                    }
                                                })}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {dailyProgram && dailyProgram.map((emp, rowIdx) => (
                                                <React.Fragment>
                                                    {renderEmployeeRow(emp, rowIdx + 1 === dailyProgram.length)}
                                                </React.Fragment>
                                            ))}
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )}
                        {!dailyProgram && (
                            <div>Η σελίδα φορτώνει, παρακαλώ περιμένετε...</div>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default PayrollQrScanner
