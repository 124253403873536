export const getOtherCorrelatedEntitiesTypes = [
    {
        label: "Φορολογικός Εκπρόσωπος",
        value: "1"
    },
    {
        label: "Διαμεσολαβητής",
        value: "2"
    },
    {
        label: "Μεταφορέας",
        value: "3"
    },
    {
        label: "Λήπτης του Αποστολέα (Πωλητή)",
        value: "4"
    },
    {
        label: "Αποστολέας (Πωλητής)",
        value: "5"
    },
    {
        label: "Λοιπές Συσχετιζόμενες Οντότητες",
        value: "6"
    }
]
