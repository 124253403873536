import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {
    setEditSupplierMovements,
    setEditSupplierPayment,
    setSupplierMovementsReportRequestData,
    setSupplierMovementsReportResponseData
} from "../../../../_reducers/DataPanel/Suppliers/supplierSlice";
import {checkPermission, getGreekCurrencyFormat, isEmpty} from "../../../../_helpers/commonFunctions";
import moment from "moment";
import {toast} from "react-toastify";
import {classicStyleBelowNavbar} from "../Statics";
import Loader from "../../../primer/POPUP-COMPONENTS/Loader/PrimerLoader";
import {Badge, Button, Col, Row, Spinner} from "react-bootstrap";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import DateBox from "../../../primer/DateBox";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import BetterCheckBox from "../../../common/BetterCheckBox";
import BootstrapDropdown from "react-bootstrap/Dropdown";
import "../Clients/tableStyle.css";
import {jsPDF} from "jspdf";
import {liberationSerif} from "../../../../fonts/liberationSerif";
import {dejavuSans} from "../../../../fonts/DejaVuSans-normal";
import {dejavuSansBold} from "../../../../fonts/DejaVuSans-Bold-bold";
import * as XLSX from "xlsx";
import {setEditBuys} from "../../../../_reducers/DataPanel/Buys/buysSlice";
import {addTab, updateActiveMainTab} from "../../../../_reducers/TabsSlice";

const SuppliersMovementsReport = () => {
    const [t] = useTranslation("common");
    const dispatch = useDispatch();
    const SUPPLIERS_DATA = useSelector((state) => state.SUPPLIERS_DATA);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const requestData = useSelector((state) => state.SUPPLIERS_DATA.supplierMovementsReportRequestData);
    const responseData = useSelector((state) => state.SUPPLIERS_DATA.supplierMovementsReportResponseData);
    const [pageChanged, setPageChanged] = useState(false);
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));

    const [suppliersListData, setSuppliersListData] = useState([]);
    const sourceRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [reportLoader, setReportLoader] = useState(false);

    const [iframeData, setIFrameData] = useState("");
    const iframeRef = useRef();

    useEffect(() => {
        loadSuppliersListData(null, setSuppliersListData);
    }, []);

    useEffect(() => {
        let supplierData = SUPPLIERS_DATA.supplierMovementsSearchByBalance;
        if (supplierData && supplierData?._id) {
            axios.get(`${process.env.REACT_APP_API_URL2}/supplier/get-supplier-id-by/${supplierData._id}`)
                .then((res) => {
                    if (res.data && res.data.status === "200") {
                        dispatch(setSupplierMovementsReportRequestData({...requestData, supplierName: supplierData?.supplierName, supplier_inactive: "active", supplier_zero: "active", page: 1}));
                        setPageChanged(true);
                    }
                }).catch((err) => {
                console.log(err);
            })
        }
    }, [SUPPLIERS_DATA.supplierMovementsSearchByBalance])

    useEffect(() => {
        if (pageChanged) {
            setPageChanged(false);
            fetchSupplierMovements(true);
        }
    }, [pageChanged])

    const loadSuppliersListData = (keyword, setData) => {
        let reqData = {
            company: company.id,
        }
        if (!isEmpty(keyword)) reqData.name = keyword;

        axios.post(`${process.env.REACT_APP_API_URL2}/supplier/list-supplier`, reqData, {
            headers: { "Content-Type": "application/json" }
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                if (res.data.data) {
                    let filteredData = res.data.data;
                    if (setData) setData(filteredData);
                } else {
                    if (setData) setData([]);
                }
            } else {
                if (setData) setData([]);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const supplierDropdownColumns = [
        {
            label: t("General.name"),
            name: "name",
        },
        {
            label: t("General.vatNumber"),
            name: "vatNumber",
        },
        {
            label: t("General.phone"),
            name: "phone",
        },
    ];

    const handleOnChange = (e, type = "default", name = "") => {
        if (type === "datebox") {
            if (moment(e).isValid()) {
                dispatch(setSupplierMovementsReportRequestData({...requestData, [name]: moment(e).format("DD/MM/YYYY")}));
            } else {
                dispatch(setSupplierMovementsReportRequestData({...requestData, [name]: ""}));
            }
        } else if (type === "acdd") {
            if (name === "supplierName") {
                dispatch(setSupplierMovementsReportRequestData({...requestData, supplierName: e.row.name}));
            }
        } else if (type === "checkbox") {
            dispatch(setSupplierMovementsReportRequestData({...requestData, [name]: e.target.checked === true ? "active" : "inactive"}));
        } else if (type === "input") {
            dispatch(setSupplierMovementsReportRequestData({...requestData, [name] : e.target.value}));
        }
    }

    const fetchSupplierMovements = (fromPagination = false) => {
        setLoading(true);
        let reqData = {
            ...requestData,
            company: company.id,
            year: company.year,
        }
        if (!fromPagination) {
            setReportLoader(true);
            dispatch(setSupplierMovementsReportRequestData({...requestData, page: 1}));
            reqData.page = 1;
        }
        sourceRef.current = axios.CancelToken.source();
        axios.post(`${process.env.REACT_APP_API_URL2}/report/supplier-movements-report`, reqData, {
            headers: {"Content-Type": "application/json"},
            cancelToken: sourceRef.current.token
        }).then((res) => {
            setLoading(false);
            setReportLoader(false);
            if (res.data.status === "200") {
                dispatch(setSupplierMovementsReportResponseData({...res.data.data}));
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            setLoading(false);
            setReportLoader(false);
            console.log(error);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const toFirstPage = () => {
        dispatch(setSupplierMovementsReportRequestData({...requestData, page: 1}));
        setPageChanged(true);
    }
    const toLastPage = () => {
        dispatch(setSupplierMovementsReportRequestData({...requestData, page: responseData.totalPages}));
        setPageChanged(true);
    }
    const toPreviousPage = () => {
        dispatch(setSupplierMovementsReportRequestData({...requestData, page: requestData.page - 1}));
        setPageChanged(true);
    }
    const toNextPage = () => {
        dispatch(setSupplierMovementsReportRequestData({...requestData, page: requestData.page + 1}));
        setPageChanged(true);
    }

    const onChangeSearch = (keyword, setData) => {
        if (keyword.length >= 2) {
            loadSuppliersListData(keyword, setData);
        }
    }
    const handleCancel = () => {
        if (sourceRef.current) {
            sourceRef.current.cancel('Request cancelled by user');
        }
        setReportLoader(false);
    }

    const handlePDFExport = (printer = false) => {
        let headers = ["Αρ.", "Ημερομηνία", "Τύπος Παραστατικού", "Αρίθμηση", "Αγορά", "Πληρωμή", "Υπόλοιπο"];
        if (requestData.showNotes === "active") {
            headers = ["Αρ.", "Ημερομηνία", "Τύπος Παραστατικού", "Αρίθμηση", "Σημειώσεις", "Αγορά", "Πληρωμή", "Υπόλοιπο"];
        }
        let dateFrom = requestData.date_from;
        let dateTo = requestData.date_to;
        if (isEmpty(dateFrom)) dateFrom = moment(company.year, "YYYY", true).startOf("year").format("DD/MM/YYYY");
        if (isEmpty(dateTo)) dateTo = moment(company.year, "YYYY", true).endOf("year").format("DD/MM/YYYY");
        const dateExported = moment().format("DD/MM/YYYY HH:mm:ss");

        // Get all data
        setReportLoader(true);
        let reqData = {
            ...requestData,
            company: company.id,
            year: company.year,
            page: -1,
        }
        axios.post(process.env.REACT_APP_API_URL2 + `/report/supplier-movements-report`, reqData, {
            headers: {"Content-Type": "application/json"},
            cancelToken: sourceRef?.current?.token
        }).then((res) => {
            setReportLoader(false);
            if (res.data.status === "200") {
                if (res.data.data?.length > 0) {
                    const doc = new jsPDF('landscape');
                    let y = 20;
                    doc.addFileToVFS("arialuni.ttf", liberationSerif);
                    doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
                    doc.addFileToVFS("dejavusans.ttf", dejavuSans);
                    doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
                    doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
                    doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
                    doc.setFont("DejaVu Sans Bold");
                    doc.setFontSize(12);
                    let cnt = 0;
                    for (let supplierData of res.data.data) {
                        if (supplierData.rows.length === 0) continue;
                        let rows = [];
                        if (cnt > 0) {
                            doc.addPage();
                            y = 20;
                        }
                        cnt++;
                        for (let dRow of supplierData.rows) {
                            let prepRow = [];
                            let varRows = ["no", "date", "documentTypeName", "numbering", "buy", "payment", "balance"];
                            if (requestData.showNotes === "active") {
                                varRows = ["no", "date", "documentTypeName", "numbering", "notes", "buy", "payment", "balance"];
                            }
                            if (dRow.type === "supplierTotals") {
                                for (let key of varRows) {
                                    let content = isEmpty(dRow[key]) ? "" : dRow[key];
                                    if (["buy", "payment", "balance"].includes(key)) content = getGreekCurrencyFormat(content);
                                    prepRow.push({content: content, styles: {fillColor: "#f1fdff"}});
                                }
                                rows.push(prepRow);
                            } else {
                                for (let key of varRows) {
                                    let content = isEmpty(dRow[key]) ? "" : dRow[key];
                                    if (["buy", "payment", "balance"].includes(key)) content = getGreekCurrencyFormat(content);
                                    prepRow.push({content: content});
                                }
                                rows.push(prepRow);
                            }
                        }
                        doc.setFont("DejaVu Sans Bold");
                        doc.setFontSize(17);
                        const header = doc.splitTextToSize("Καρτέλα Προμηθευτή", 200);
                        const headerString = header.join("\n"); // Join the array elements into a single string with line breaks
                        doc.text(headerString, doc.internal.pageSize.getWidth() / 2.5, y, {alignment: "center"});
                        y += 7;
                        doc.setFont("DejaVu Sans Normal");
                        const imgData = 'assets/images/prrimer-logo.png';
                        const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
                        const imgY = 23; // adjust the position as needed
                        doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
                        doc.setFontSize(10);
                        const supplierCodeLines = doc.splitTextToSize(`${supplierData.supplierCode} - ${supplierData.name}`, 200);
                        doc.text(supplierCodeLines, 15, y, {alignment: "center"});
                        y += 7;
                        const lines = doc.splitTextToSize(`${!isEmpty(supplierData.address) ? `Διεύθυνση ${supplierData.address}, ` : ""}ΑΦΜ: ${supplierData.vatNumber}`, 200);
                        doc.text(lines, 15, y, {alignment: 'center'});
                        y += 7;
                        const supplierDetailRange = doc.splitTextToSize(`Εύρος Ημερομηνίας: ${dateFrom} - ${dateTo}, Ημερομηνία Εξαγωγής: ${dateExported}`, 200);
                        doc.text(`${supplierDetailRange}`, 15, y, {alignment: "center"});
                        y += 4;
                        doc.autoTable({
                            startY: y,
                            head: [headers],
                            body: rows,
                            rowPageBreak: "avoid",
                            theme: "striped",
                            styles: {
                                font: "DejaVu Sans Normal",
                                fontSize: 10,
                                halign: "center",
                            },
                        })
                        y += rows.length * 10 + 10;
                        rows = [];
                    }
                    const pageCount = doc.getNumberOfPages();
                    // Add the pagination to each page
                    for (let i = 1; i <= pageCount; i++) {
                        doc.setPage(i);
                        doc.setFontSize(10);
                        doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
                    }
                    if (printer) {
                        setIFrameData(`${doc.output('bloburl')}`);
                        setTimeout(() => {
                            const iframeWindow = iframeRef.current.contentWindow;
                            iframeWindow.print();
                        }, 200)
                    } else {
                        doc.save("Καρτέλες Προμηθευτών.pdf");
                    }
                }
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            setReportLoader(false);
            console.log(error);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleExcelExport = () => {
        let rows = [];
        let headers = ["Αρ.", "Ημερομηνία", "Τύπος Παραστατικού", "Αρίθμηση", "Αγορά", "Πληρωμή", "Υπόλοιπο"];
        if (requestData.showNotes === "active") {
            headers = ["Αρ.", "Ημερομηνία", "Τύπος Παραστατικού", "Αρίθμηση", "Σημειώσεις", "Αγορά", "Πληρωμή", "Υπόλοιπο"];
        }
        let dateFrom = requestData.date_from;
        let dateTo = requestData.date_to;
        if (isEmpty(dateFrom)) dateFrom = moment(company.year, "YYYY", true).startOf("year").format("DD/MM/YYYY");
        if (isEmpty(dateTo)) dateTo = moment(company.year, "YYYY", true).endOf("year").format("DD/MM/YYYY");
        const dateExported = moment().format("DD/MM/YYYY HH:mm:ss");

        // Get all data
        setReportLoader(true);
        let reqData = {
            ...requestData,
            company: company.id,
            year: company.year,
            page: -1,
        }
        axios.post(process.env.REACT_APP_API_URL2 + `/report/supplier-movements-report`, reqData, {
            headers: {"Content-Type": "application/json"},
            cancelToken: sourceRef?.current?.token
        }).then((res) => {
            setReportLoader(false);
            if (res.data.status === "200") {
                if (res.data.data?.length > 0) {
                    const workBook = XLSX.utils.book_new();
                    for (let supplierData of res.data.data) {
                        rows.push([`Κωδικός: ${supplierData.supplierCode}`]);
                        rows.push([`Όνομα: ${supplierData.name}`]);
                        rows.push([`ΑΦΜ: ${supplierData.vatNumber}`]);
                        rows.push([`Εύρος ημερομηνίας: ${dateFrom} - ${dateTo}`]);
                        rows.push([`Ημερομηνία Εξαγωγής: ${dateExported}`]);
                        rows.push([]);
                        rows.push(headers);
                        for (let dRow of supplierData.rows) {
                            if (requestData.showNotes === "active") {
                                rows.push([dRow.no, dRow.date, dRow.documentTypeName, dRow.numbering, dRow.notes, dRow.buy, dRow.payment, dRow.balance]);
                            } else {
                                rows.push([dRow.no, dRow.date, dRow.documentTypeName, dRow.numbering, dRow.buy, dRow.payment, dRow.balance]);
                            }
                        }
                        const workSheet = XLSX.utils.aoa_to_sheet(rows);
                        XLSX.utils.book_append_sheet(workBook, workSheet, String(`${supplierData.supplierCode} ${supplierData.name}`).slice(0, 30));
                        rows = [];
                    }
                    XLSX.writeFile(workBook, 'Καρτέλες Προμηθευτών.xlsx');
                } else {
                    toast.info("Δεν βρέθηκαν κινήσεις με αυτά τα φίλτρα.");
                }
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            setReportLoader(false);
            console.log(error);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleRowDoubleClick = (row) => {
        if (row._id) {
            const docMapping = {
                "buy" : "Buys",
                "supplierPayment": "Supplier Payments",
                "supplierMovement": "Supplier Movements",
            }
            const tabMapping = {
                "buy": "view-buys",
                "supplierPayment": "edit-supplier-payments",
                "supplierMovement": "edit-supplier-movements",
            }
            const functionMapping = {
                "buy": setEditBuys,
                "supplierPayment": setEditSupplierPayment,
                "supplierMovement": setEditSupplierMovements,
            }
            if (row.type in docMapping) {
                axios.get(`${process.env.REACT_APP_API_URL2}/company/get-document-by-id`, {
                    headers: { "Content-Type": "application/json" },
                    params: {
                        company: company.id,
                        docId: row._id,
                        section: docMapping[row.type],
                    }
                }).then((res) => {
                    if (res.data.status === "200") {
                        dispatch(functionMapping[row.type](res.data.data));
                        const idx = TABS_DATA.findIndex((item) => tabMapping[row.type] === item);
                        if(idx === -1) {
                            dispatch(addTab(tabMapping[row.type]));
                            dispatch(updateActiveMainTab(TABS_DATA.length));
                        } else {
                            dispatch(updateActiveMainTab(idx));
                        }
                    } else {
                        toast.error(res.data.message);
                    }
                }).catch((error) => {
                    console.log(error);
                    toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
                })
            }
        }
    }

    return (
        <React.Fragment>
            <div style={classicStyleBelowNavbar}>
                {reportLoader ? <Loader cancel={handleCancel}/> :
                    <div>
                        <div className="no-print">
                            <Row>
                                <Col md={3}>
                                    <DateHelper
                                        dateFromAndToFunction={(from, to) => dispatch(setSupplierMovementsReportRequestData({...requestData, date_from: from, date_to: to}))}
                                    />
                                </Col>
                                <Col md={2}>
                                    <DateBox
                                        name="date_from"
                                        selected={moment(requestData.date_from, "DD/MM/YYYY").isValid() ? moment(requestData.date_from, "DD/MM/YYYY").toDate() : ""}
                                        label={t("General.dateFrom")}
                                        onChange={(e) => handleOnChange(e, "datebox", "date_from")}
                                    />
                                </Col>
                                <Col md={2}>
                                    <DateBox
                                        name="date_to"
                                        selected={moment(requestData.date_to, "DD/MM/YYYY").isValid() ? moment(requestData.date_to, "DD/MM/YYYY").toDate() : ""}
                                        label={t("General.dateTo")}
                                        onChange={(e) => handleOnChange(e, "datebox", "date_to")}
                                    />
                                </Col>
                                <Col md={5}>
                                    <AdvancedCombobox
                                        className="w-100 ac-select-dropdown"
                                        searchableDropdownEnable={false}
                                        defaultValue={requestData.supplierName}
                                        label={t("General.supplierName")}
                                        data={suppliersListData}
                                        onChange={(e) => handleOnChange(e, "acdd", "supplierName")}
                                        resetFilter={() => dispatch(setSupplierMovementsReportRequestData({...requestData, supplierName: ""}))}
                                        onChangeSearch={onChangeSearch}
                                        excludeColumns={[]}
                                        columns={supplierDropdownColumns}
                                        editEnable={false}
                                        deleteEnable={false}
                                        searchTableEnable={false}
                                        showTableOptionOnSelect={true}
                                        placeholder={t("Placeholders.selectSupplier")}
                                        required={true}
                                        name="supplierName"
                                        showInsertAddButton={true}
                                        hideSaveButton={true}
                                        hideInsertNewRow={true}
                                        inputFieldClasses="form-control"
                                    />
                                    {isEmpty(requestData.supplierName) && (
                                        <div className={"text-muted"}>(*) Για την γρηγορότερη προβολή αποτελεσμάτων, επιλέξτε συγκεκριμένο προμηθευτή</div>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <BetterCheckBox
                                        name={"supplier_zero"}
                                        text={t('General.includeZeroBalanceSuppliers')}
                                        className="mt-3"
                                        checked={requestData.supplier_zero === "active"}
                                        onChange={(e) => handleOnChange(e, "checkbox", "supplier_zero")}
                                    />
                                </Col>
                                <Col md={3}>
                                    <BetterCheckBox
                                        name={"supplier_inactive"}
                                        text={t('General.includeInactiveSuppliers')}
                                        className="mt-3"
                                        checked={requestData.supplier_inactive === "active"}
                                        onChange={(e) => handleOnChange(e, "checkbox", "supplier_inactive")}
                                    />
                                </Col>
                                <Col md={3}>
                                    <BetterCheckBox
                                        name={"showNotes"}
                                        text={"Προβολή σημειώσεων παραστατικών"}
                                        className={"mt-3"}
                                        checked={requestData.showNotes === "active"}
                                        onChange={(e) => handleOnChange(e, "checkbox", "showNotes")}
                                    />
                                </Col>
                            </Row>
                            {checkPermission("suppliers-reports/suppliersmovements-read", permissionsData) &&
                                <Row>
                                    <Col md={12}>
                                        <Button className="float-right" onClick={() => fetchSupplierMovements(false)} disabled={loading}>
                                            {t('General.applyFilter')}
                                        </Button>
                                    </Col>
                                </Row>
                            }
                            <hr style={{color: "gray", height: 1}} />
                        </div>
                        <div className="no-print">
                            {responseData.totalPages > 0 ? (
                                <React.Fragment>
                                    <Row>
                                        <h3 style={{
                                            textAlign: "center",
                                            alignItems: "center",
                                            fontWeight: "bold"
                                        }}>Κινήσεις Προμηθευτών</h3>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="mt-3">
                                                    <h5>{responseData.pageSupplierData.supplierCode} - {responseData.pageSupplierData.name}</h5>
                                                    <h5>ΑΦΜ: {responseData.pageSupplierData.vatNumber}</h5>
                                                    {!isEmpty(responseData.pageSupplierData.address) ? (
                                                        <h5>Διεύθυνση: {responseData.pageSupplierData.address}</h5>
                                                    ) : (
                                                        <h5>-</h5>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3 justify-content-end">
                                        <Col md={9} style={{display: "flex", justifyContent: "right"}}>
                                            {loading && <Spinner animation="border" variant="dark" className={"mr-2 mt-2"} size={"sm"} />}
                                            <Button size="sm" className="mr-1" onClick={() => toFirstPage()}
                                                    disabled={requestData.page === 1 || loading}>{"<< Πρώτη"}</Button>
                                            <Button size="sm" className="mr-1" onClick={() => toPreviousPage()}
                                                    disabled={requestData.page === 1 || loading}>{"< Προηγούμενη"}</Button>
                                            <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1"
                                                   bg="primary">Σελίδα {requestData.page} / {responseData.totalPages}</Badge>
                                            <Button size="sm" className="mr-1" onClick={() => toNextPage()}
                                                    disabled={requestData.page === responseData.totalPages || loading}>{"Επόμενη >"}</Button>
                                            <Button size="sm" className="mr-1" onClick={() => toLastPage()}
                                                    disabled={requestData.page === responseData.totalPages || loading}>{"Τελευταία >>"}</Button>
                                        </Col>
                                    </Row>
                                    <Row className={"mb-4"}>
                                        <Col md={12}>
                                            <table style={{
                                                tableLayout: "fixed",
                                                textAlign: "center",
                                                width: "100%",
                                                fontSize: "16px"
                                            }} className={"clientMovementsReportTable"}>
                                                <colgroup>
                                                    <col style={{width: "3%"}}></col>
                                                    <col style={{width: "15%"}}></col>
                                                    <col style={{width: "30%"}}></col>
                                                    <col style={{width: "10%"}}></col>
                                                    {requestData.showNotes === "active" && (
                                                        <col style={{width: "15%"}}></col>
                                                    )}
                                                    <col style={{width: "9%"}}></col>
                                                    <col style={{width: "9%"}}></col>
                                                    <col style={{width: "9%"}}></col>
                                                </colgroup>
                                                <thead>
                                                <tr style={{backgroundColor: "#f1fdff"}}>
                                                    <th>Αρ.</th>
                                                    <th>Ημερομηνία</th>
                                                    <th>Τύπος Παραστατικού</th>
                                                    <th>Αρίθμηση</th>
                                                    {requestData.showNotes === "active" && (
                                                        <th>Σημειώσεις Παραστατικού</th>
                                                    )}
                                                    <th colSpan={2}>Ποσό</th>
                                                    <th>Υπόλοιπο</th>
                                                </tr>
                                                <tr style={{backgroundColor: "#f1fdff"}}>
                                                    <th colSpan={requestData.showNotes === "active" ? 5 : 4}></th>
                                                    <th>Αγορά</th>
                                                    <th>Πληρωμή</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {responseData.pageData?.length > 0 && responseData.pageData.map((row, idx) => (
                                                    <tr key={`cmRow${idx}`}
                                                        style={{backgroundColor: ["transfer", "pageTotals", "supplierTotals"].includes(row["type"]) ? "#e1e3e1" : ""}}
                                                        onDoubleClick={() => handleRowDoubleClick(row)}>
                                                        <td>{row["no"]}</td>
                                                        <td>{row["date"]}</td>
                                                        <td>{["transfer", "pageTotals", "supplierTotals"].includes(row["type"]) ?
                                                            <i>***{" "}{row["documentTypeName"]}{" "}***</i> : row["documentTypeName"]}</td>
                                                        <td>{row["numbering"]}</td>
                                                        {requestData.showNotes === "active" && (
                                                            <td>{row["notes"]}</td>
                                                        )}
                                                        <td style={{fontWeight: "700"}}>{row["buy"] === 0 && ["buy", "supplierPayment", "supplierMovement", "transfer"].includes(row["type"]) ? "" : getGreekCurrencyFormat(row["buy"])}</td>
                                                        <td style={{fontWeight: "700"}}>{row["payment"] === 0 && ["buy", "supplierPayment", "supplierMovement", "transfer"].includes(row["type"]) ? "" : getGreekCurrencyFormat(row["payment"])}</td>
                                                        <td style={{fontWeight: "700"}}>{getGreekCurrencyFormat(row["balance"])}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                    <div style={{display: "none"}} className="print-me" id="printReport">
                                        <iframe ref={iframeRef} style={{width: "100%"}} src={iframeData} title={"Print Report"}></iframe>
                                    </div>
                                    {checkPermission("suppliers-reports/suppliersmovements-general-print", permissionsData) === true && (
                                        <Row>
                                            <BootstrapDropdown className="mb-3 mt-3">
                                                <BootstrapDropdown.Toggle variant="primary" id="dropdown-basic">
                                                    Αποθήκευση αποτελεσμάτων
                                                </BootstrapDropdown.Toggle>
                                                <BootstrapDropdown.Menu>
                                                    <BootstrapDropdown.Item onClick={() => handlePDFExport(true)}>
                                                        Εκτυπωτής
                                                    </BootstrapDropdown.Item>
                                                    <BootstrapDropdown.Item onClick={() => handlePDFExport()}>
                                                        Αποθήκευση ως PDF
                                                    </BootstrapDropdown.Item>
                                                    <BootstrapDropdown.Item onClick={() => handleExcelExport()}>
                                                        Αποθήκευση ως XLSX
                                                    </BootstrapDropdown.Item>
                                                </BootstrapDropdown.Menu>
                                            </BootstrapDropdown>
                                        </Row>
                                    )}
                                </React.Fragment>
                            ) : (
                                <Row>
                                    <h3
                                        style={{
                                            textAlign: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        {t('ClientsReportsClientMovement.pleaseSelectFiltersFirstAndPressApplyFilters')}
                                    </h3>
                                </Row>
                            )}
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default SuppliersMovementsReport;
