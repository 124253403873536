import React, {useEffect, useMemo, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import TextArea from "../../../common/TextArea";
import Input from "../../../common/Input";
import DateBox from "../../../primer/DateBox";
import EditableTable from '../../../primer/Editable-Table';
import Dropdown from "../../../primer/Dropdown";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {setSaveNewProductMovements, setSaveSales} from "../../../../_reducers/activitySlice";
import {fetchAdvancedTypes, fetchProducts} from "../../../../_apis/api";
import {
  filterNaNValue,
  getDefaultOptionByValue,
  getGreekCurrencyFormat,
  getModeByInstallation,
  getNumberByInstallation,
  getOptionsByData,
  getPreNumberByInstallation,
  getSelectedInstallation,
  getSelectedPreNumbers,
  isAlreadyExists,
  removeDuplicateObjects
} from "../../../../_helpers/commonFunctions";
import {
  setNewProductMovements,
  setProductMovementsNewProduct
} from "../../../../_reducers/DataPanel/Products/productsSlice";
import {
  deleteObjectFromArrayOfObjects,
  getLabelByValue,
  getObjectFromArrayOfObjects
} from "../../../../_helpers/helperFunctions";
import getMyData from "../../../../_helpers/sharedmydata";
import {preNumberGreek} from "../../../../_data/settings/advanced-types-search";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {
  getDiscountExclusiveVat,
  getNetValue,
  getNetValueByTotalValue,
  getPriceExclusiveVat,
  getSalesTotals,
  getTaxes,
  getTotalValue,
  getUnitPrice,
  getVatPercent,
  getVatValue,
  parseNumber,
  salesDuplicateProductCheck
} from "../../../../_helpers/product-sales-calculations";
import {vatCategoryData} from "../../../../_data/mydata/vatCategory";

export const ProductMovementsGeneral = () => {
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const ACTIVITY = useSelector( (state) => state.ACTIVITY );
  const PRODUCTS_DATA = useSelector( (state) => state.PRODUCTS_DATA );
  const SETTINGS = useSelector( (state) => state.SETTINGS );
  const company = useSelector(state => state.COMPANY_DATA.company);
  const [pMovementsProductList, setPMovementsProductList] = useState(PRODUCTS_DATA.products.slice());
  const [showProductTableSpinner, setProductTableSpinner] = useState('false');
  const measurementUnitOptions     = getMyData({type: 'mu', settings: SETTINGS});
  const [pRequestData, setPRequestData] = useState({});
  const installations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
  const [docIsCensus, setDocIsCensus] = useState(false);
  const [incrKey, setIncrKey] = useState(0);

  let possibleInnerMovements = [];
  if(installations.length > 0) {
    installations.forEach((inst) => {
      possibleInnerMovements.push({
        value: inst.installationMasterId,
        label: inst.type + " " + inst.address + " " + inst.city,
      })
    })
  }

  const productCodeColumns = [
    {
      label: t('ProductsMovementsNew.smallTable.code'),
      name: "code",
    },
    {
      label: t('ProductsMovementsNew.smallTable.productName'),
      name: "name",
    },
    {
      label: t('ProductsMovementsNew.smallTable.alternativeName'),
      name: "alternateName",
    },
  ];

  const productMovementColumns = [
    {
      name: "No",
      field: "no",
      editable: false,
    },
    {
      name: t('ProductsMovementsNew.table.productCode'),
      field: "code",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: productCodeColumns,
          data: pMovementsProductList,
          editEnable: false ,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('ProductsMovementsNew.table.productCodePlaceholder')
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t('ProductsMovementsNew.table.productName'),
      field: "name",
      editable:true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: productCodeColumns,
          data: pMovementsProductList,
          editEnable: false ,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('ProductsMovementsNew.table.productNamePlaceholder')
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t('ProductsMovementsNew.table.mu'),
      field: 'muLabel',
      editable: false,
    },
    {
      name: t('ProductsMovementsNew.table.quantity'),
      field: 'quantity',
      editable: true,
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "nosuffix",
          maxLength: 10
        }
      }
    },
    {
      name: t('SalesNew.productTable.vat'),
      field: "vatPercentage",
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('SalesNew.productTable.netValue'),
      field: "netValue",
      editable: true,
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 7
        }
      }
    },
    {
      name: t('SalesNew.productTable.vatValue'),
      field: "vat",
      editable: true,
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 7
        }
      }
    },
    {
      name: t('SalesNew.productTable.totalValue'),
      field: "totalValue",
      editable: true,
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 7
        }
      }
    },
  ];

  const requestData = {
    company:company.id,
    year:company.year.toString(),
  }
  // Options for Document Type Dropdown
  const allDocumentTypes = SETTINGS.settings.advancedTypes;
  const clientMovemnetDT = getObjectFromArrayOfObjects(allDocumentTypes, 'Product Movements', 'section');
  const documentTypesOptions =  getOptionsByData(clientMovemnetDT, '_id', 'name')
  const [preNumberGreekOptions, setPreNumberGreekOptions] = useState([]);
  let selectedDocumentTypeData = [];
  let dtInstallationsOptions = [];
  let disabledFields = false;
  if (PRODUCTS_DATA.newProductMovements.documentType === undefined || PRODUCTS_DATA.newProductMovements.documentType === '') {
    disabledFields = true;
  } else {
    let dtData = getObjectFromArrayOfObjects(clientMovemnetDT, PRODUCTS_DATA.newProductMovements.documentType, '_id');
    if (dtData.length > 0) {
      selectedDocumentTypeData  = dtData['0'];
      dtInstallationsOptions = getOptionsByData(selectedDocumentTypeData['documentNumbering'], "installationMasterId", "installation");
      dtInstallationsOptions = removeDuplicateObjects(dtInstallationsOptions, 'value');
    }
  }

  const loadProductListData = (value) => {
    let data = {
      id: value,
      name: '',
      isProductMovement: "true",
      company : company.id
    }
    setPRequestData({...data})
    axios.get(process.env.REACT_APP_API_URL2 + `/sale/product-list-by-advanced-type-id/${data.id}`,{params :data})
        .then(res => {
          if (res.data && res.data.status === '200') {
            if (res.data.data) {
              setPMovementsProductList(res.data.data);
            } else {
              setPMovementsProductList([]);
            }
          } else {
            setPMovementsProductList([]);
          }
        }).catch((err) => {
      console.log(err);
    })

  }

  const handleOnChange = async (e, type="default", ddname = '') => {
    // Set if editing the form
    if (!ACTIVITY.saveNewProductMovements) {
      dispatch(setSaveNewProductMovements(true));
    }
    let name;
    let value;
    if (type === 'dd' || type === 'ac' ) {
      value = e.value;
      name = ddname;
    } else if (type === 'dp' ) {
      value = e;
      name = ddname;
    } else {
      value = e.target.value;
      name = e.target.name;
    }
    let productData = Object.assign({}, PRODUCTS_DATA);
    let newProductMovementData = {};
    newProductMovementData[name] = value;
    if (name === 'documentType') {
      newProductMovementData.documentTypeName = getLabelByValue(documentTypesOptions, value, 'value');
      let dtData = getObjectFromArrayOfObjects(clientMovemnetDT, value, '_id');
      if (dtData.length > 0) {
        if(dtData['0']?.isCensus === "active") {
          setDocIsCensus(true);
          newProductMovementData.innerMovement = "";
        } else {
          setDocIsCensus(false);
        }
        selectedDocumentTypeData = dtData['0'];
        let selectedInstallation = getSelectedInstallation(selectedDocumentTypeData["documentNumbering"]);
        if (Object.keys(selectedInstallation).length > 0) {
          newProductMovementData.installation = selectedInstallation.installationMasterId;
          newProductMovementData.preNumber = selectedInstallation.preNumber;
          newProductMovementData.number = selectedInstallation.number + 1;
          newProductMovementData.installationMode = selectedInstallation.numberingMode;
          newProductMovementData.numberingMode = selectedInstallation.numberingMode;
          let newPreNumbers = getSelectedPreNumbers(selectedDocumentTypeData['documentNumbering'], preNumberGreek, newProductMovementData.installation);
          setPreNumberGreekOptions(newPreNumbers);
        }
      } else {
        newProductMovementData.installation = "";
        newProductMovementData.preNumber = "";
        newProductMovementData.number = "";
        newProductMovementData.installationMode = "";
      }
      loadProductListData();
    }
    // Update Installation Field Values on Change
    if (name === 'installation') {
      if(productData.newProductMovements?.innerMovement) {
        if(productData.newProductMovements?.innerMovement === value) {
          newProductMovementData.innerMovement = "";
        }
      }
      newProductMovementData.installationName = getLabelByValue(dtInstallationsOptions, value, 'value');
      newProductMovementData.preNumber = getPreNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId');
      newProductMovementData.installationMode = getModeByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId');
      newProductMovementData.numberingMode = getModeByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId');
      newProductMovementData.number = getNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId') + 1;
      // This will pre number given in document number table
      let newPreNumbers = getSelectedPreNumbers(selectedDocumentTypeData['documentNumbering'], preNumberGreek, value);
      setPreNumberGreekOptions(newPreNumbers);
    }
    dispatch(setNewProductMovements({...productData.newProductMovements, ...newProductMovementData}));
  }

  useEffect(() => {
    dispatch(fetchProducts(requestData))
    dispatch(fetchAdvancedTypes(requestData))
    // calculate sum of quantity
    const productQuantitySum = getSumOfDataValues(PRODUCTS_DATA.newProductMovements.products, 'quantity');
    dispatch(setNewProductMovements({...PRODUCTS_DATA.newProductMovements, sum: productQuantitySum}));
  }, [dispatch])

  useEffect(() => {
    if(PRODUCTS_DATA.newProductMovements.installation !== undefined && PRODUCTS_DATA.newProductMovements.installation !== ''){
      loadProductListData();
      let newPreNumbers = getSelectedPreNumbers(selectedDocumentTypeData['documentNumbering'], preNumberGreek, PRODUCTS_DATA.newProductMovements.installation);
      setPreNumberGreekOptions(newPreNumbers);
    }
  }, []);

  const getSumOfDataValues = (data, field = 'sum') => {
    let sum = 0;
    if (data && data.length > 0) {
      data.forEach(item => {
        sum += +item[field] ?? 0;
      })
    }
    return sum;
  }

  const parentMydataActions = {
    insert: async (e, data) => {
      let pPlroducts = PRODUCTS_DATA.newProductMovements.products;
      // Set if editing the form
      if (!ACTIVITY.saveSales) {
        dispatch(setSaveNewProductMovements(true));
      }
      let productData = null;
      // Get Product By Code
      if (data && data?.field === 'code') {
        await axios.get(process.env.REACT_APP_API_URL2 + `/category/get-product-by-code/${data.value}`,{params :company})
            .then(res => {
              if (res.data && res.data.data && (res.data.data?.length > 0) && res.data.status === '200') {
                productData = res.data.data['0'];
              }
            }).catch((err) => {
              console.log(err);
            })
      }
      // Get Product By Name
      if (data && data?.field === 'name') {
        await axios.get(process.env.REACT_APP_API_URL2 + `/category/get-product-by-name/${encodeURIComponent(data.value)}`,{params :company})
            .then(res => {
              if (res.data && res.data.data && (res.data.data?.length > 0) && res.data.status === '200') {
                productData = res.data.data['0'];
              }
            }).catch((err) => {
              console.log(err);
            })
      }
      console.log(productData)
      if (productData !== null && Object.keys(productData).length > 0) {

        let productExists = isAlreadyExists( PRODUCTS_DATA.newProductMovements.products, data.field, data.value);
        if (productExists) {
          toast.error('Product already added in the table.');
        } else {
          // Set Loading true
          setProductTableSpinner('true');
          // auto close in 2 seconds
          setTimeout(() => {
            setProductTableSpinner('false');
          }, 3000);
          if (e.key !== "Enter") {
            let tempNewProduct = Object.assign({}, PRODUCTS_DATA.newProductMovements.products);
            tempNewProduct.name = productData?.name;
            tempNewProduct.code = productData?.code;
            tempNewProduct.quantity = 1;
            tempNewProduct.mu = productData?.measurementUnit;
            tempNewProduct.muLabel = getLabelByValue(measurementUnitOptions, productData?.measurementUnit.toString(), 'value');
            tempNewProduct.discount = 0;
            tempNewProduct.isPerDiscount = "false";
            tempNewProduct.isDiscountAmount = "true";
            tempNewProduct.unitPrice = parseNumber(productData.price); // Direct price from the product preffered price
            tempNewProduct[data.field] = data.value; // Updates from table edit
            if (data.field === 'isPerDiscount' && (data.value === "on" || data.value === "true")) {
              tempNewProduct.isPerDiscount = "true";
              tempNewProduct.isDiscountAmount = "false";
            }
            if (data.field === 'isDiscountAmount' && (data.value === "on" || data.value === "true")) {
              tempNewProduct.isPerDiscount = "false";
              tempNewProduct.isDiscountAmount = "true";
            }
            // Get Current Product Details with the API if product and client are selected.
            if (tempNewProduct.code && tempNewProduct.code !== '') {
              await axios
                  .post(`${process.env.REACT_APP_API_URL2}/category/get-product-code-by-price`, { _id: productData._id, company: company.id })
                  .then((res) => {
                    if (res.data.status === "200") {
                      let pldata = res.data?.data;
                      tempNewProduct.vatType = pldata.vatType === '' ? 'true' : pldata.vatType.toString();
                      tempNewProduct.isPerDiscount = pldata.isPerDiscount;
                      tempNewProduct.isDiscountAmount = pldata.isDiscountAmount;
                      tempNewProduct.vatPercentage = pldata.vat;
                      tempNewProduct.discount = pldata.discount; // Discount from the price list table
                      tempNewProduct.unitPrice = filterNaNValue(pldata.productPrice); // Price from the price list table
                    }
                    setProductTableSpinner('false');
                  }).catch(error => {
                    setProductTableSpinner('false');
                    console.log(error);
                    console.log('Unable to fetch product price list.');
                  }).catch((err) => {
                    console.log(err);
                  })
            }
            // Fix for loading calculation on insert
            let discountType = 'euro';
            if (tempNewProduct.isPerDiscount === 'true') {
              discountType = 'per';
            }
            if(productData?.isTaxLine === "true"){
              tempNewProduct.vatPercentage  = getVatPercent('Normal VAT', productData.vat, 0);
              tempNewProduct.vatId = getDefaultOptionByValue(vatCategoryData, productData?.vat, 'percentage', 'code', 'percentage').label;
              tempNewProduct.vatReason      = productData?.vatReason;
              tempNewProduct.unitPrice      = getPriceExclusiveVat(tempNewProduct.unitPrice, tempNewProduct?.vatPercentage, tempNewProduct?.vatType);
              tempNewProduct.discount       = getDiscountExclusiveVat(tempNewProduct.discount, tempNewProduct?.vatPercentage, tempNewProduct?.vatType, discountType);
              tempNewProduct.netValue       = 0;
              tempNewProduct._id            = productData._id;
              tempNewProduct.vat            = getVatValue(tempNewProduct.taxes, tempNewProduct.vatPercentage);
              tempNewProduct.totalValue     = getTotalValue(tempNewProduct.netValue, tempNewProduct.vatPercentage, tempNewProduct.taxes);
            } else {
              tempNewProduct.vatPercentage = getVatPercent('Normal VAT', productData.vat, 0);
              tempNewProduct.vatId = getDefaultOptionByValue(vatCategoryData, productData?.vat, 'percentage', 'code', 'percentage').label;
              tempNewProduct.vatReason = productData?.vatReason;
              tempNewProduct.unitPrice = getPriceExclusiveVat(tempNewProduct.unitPrice, tempNewProduct?.vatPercentage, tempNewProduct?.vatType);
              tempNewProduct.discount = getDiscountExclusiveVat(tempNewProduct.discount, tempNewProduct?.vatPercentage, tempNewProduct?.vatType, discountType);
              tempNewProduct.netValue = getNetValue(tempNewProduct.unitPrice, tempNewProduct.quantity, tempNewProduct.discount, discountType);
              tempNewProduct._id = productData._id;
              tempNewProduct.vat = getVatValue(tempNewProduct.netValue, tempNewProduct.vatPercentage);
              console.log(tempNewProduct.vatPercentage)
              tempNewProduct.totalValue = getTotalValue(tempNewProduct.netValue, tempNewProduct.vatPercentage, tempNewProduct.taxes);
            }
            // If validated fields done add new row
            let oldSalesProducts = PRODUCTS_DATA.newProductMovements.products;
            let updatePriceListNewProduct = Object.assign({}, tempNewProduct);
            updatePriceListNewProduct.name = tempNewProduct.name;
            updatePriceListNewProduct.code = tempNewProduct.code;
            updatePriceListNewProduct.no = oldSalesProducts.length + 1;
            updatePriceListNewProduct.id = oldSalesProducts.length + 1;
            updatePriceListNewProduct._id = tempNewProduct._id
            dispatch(setProductMovementsNewProduct(tempNewProduct)); // Update redux
             pPlroducts = [...oldSalesProducts, updatePriceListNewProduct];
           }
          let productTotals = getSalesTotals(pPlroducts, SETTINGS);
          dispatch(setProductMovementsNewProduct(PRODUCTS_DATA.productMovementsNewProductInitialValue)); // Reset new category mydata
            dispatch(setNewProductMovements({ ...PRODUCTS_DATA.newProductMovements, products: pPlroducts, ...productTotals }));
          }
        }
      },
    update: async (data, id) => {
    // Set if editing the form
    if (!ACTIVITY.saveSales) {
      dispatch(setSaveSales(true));
    }
    // Set Loading true
    setProductTableSpinner('true');
    let foundProduct;
    let productData = null;
    let plProducts = PRODUCTS_DATA.newProductMovements.products.slice();
    // Quick Edit Product from the sales product table
    if (data.quickEdit) {
      let qeProduct = await getObjectFromArrayOfObjects(plProducts, id, '_id');
      if (qeProduct.length > 0) {
        data.code = qeProduct['0'].code;
      }
      foundProduct = plProducts.find(item => item._id === id);
    } else {
      foundProduct = plProducts.find(item => item.id === id);
    }
    if (data.isPerDiscount && (data.isPerDiscount === "on" || data.isPerDiscount === "true")) {
      data.isPerDiscount = "true";
      data.isDiscountAmount = "false";
    }
    if (data.isDiscountAmount && (data.isDiscountAmount === "on" || data.isDiscountAmount === "true")) {
      data.isPerDiscount = "false";
      data.isDiscountAmount = "true";
    }
    if (data.name && data.name === '') {
      toast.error("Name is mandatory field.");
    } else if (data.code && data.code === '') {
      toast.error("Code is mandatory field.");
    } else {
      if (foundProduct !== undefined) {
        let papiUrl = process.env.REACT_APP_API_URL2 + `/category/get-product-by-code/${encodeURIComponent(foundProduct.code)}`;
        if (data.code && data.code !== '') {
          papiUrl = process.env.REACT_APP_API_URL2 + `/category/get-product-by-code/${encodeURIComponent(data.code)}`;
        } else if (data.name && data.name !== '') {
          papiUrl = process.env.REACT_APP_API_URL2 + `/category/get-product-by-name/${encodeURIComponent(data.name)}`;
        }
        let loadingProduct = true;
        if (loadingProduct) {
          // Get product data from api.
          await axios.get(papiUrl, {params: company})
              .then(res => {
                if (res.data && res.data.data && (res.data.data?.length > 0) && res.data.status === '200') {
                  productData = res.data.data['0'];
                }
              }).catch((err) => {
                console.log(err);
              })
        }
        // Field auto population on change product
        if (((data.code && data.code !== '') || (data.name && data.name !== ''))) {
          // Get Current Product Details with the API if product and client are selected.
          if (productData.code && productData.code !== '') {
            await axios
                .post(`${process.env.REACT_APP_API_URL2}/category/get-product-code-by-price`, {
                  _id: productData._id,
                  company: company.id,
                })
                .then((res) => {
                  if (res.data.status === "200") {
                    let plProductData = res.data.data;
                    data._id = plProductData._id;
                    data.code = plProductData?.code;
                    data.vatType = plProductData?.vatIncluded === '' ? 'true' : plProductData?.vatIncluded.toString(); // Vat Type from price table
                    data.discount = plProductData?.discount;
                    data.isPerDiscount = plProductData?.isPerDiscount;
                    data.isDiscountAmount = plProductData?.isDiscountAmount;
                    data.name = plProductData?.name;
                    data.mu = plProductData?.measurementUnit;
                    data.muLabel = getLabelByValue(measurementUnitOptions, plProductData?.measurementUnit.toString(), 'value');
                    data.unitPrice = data.unitPrice === undefined || data.unitPrice === null ? plProductData?.price : data.unitPrice;
                    data.vatId = getDefaultOptionByValue(vatCategoryData, productData?.vat, 'percentage', 'code', 'percentage').label;
                    data.vatPercentage = getVatPercent('Normal VAT', productData.vat, 0);
                    data.vatReason = plProductData?.vatReason;
                    if (plProductData?.vatType === "true") {
                      data.totalValue = filterNaNValue(plProductData?.price);
                    } else {
                      data.unitPrice = filterNaNValue(plProductData?.price); // Price from the price list table
                    }
                  }
                }).catch((err) => {
                  console.log(err);
                })
          }
          data.withHoldTax = productData.withholdTax;
          data.otherTax = productData.otherTaxes;
          data.stamp = productData.stamp;
          data.fee = productData.fee;
        }
        // Updated selected product with updated field data.
        foundProduct = {...foundProduct, ...data}
        let discountType = 'euro';
        if (foundProduct.isPerDiscount === 'true') {
          discountType = 'per'
        }
        // Note: For inclusive vat the value is reducing by the vat percentage.
        if (data.unitPrice && data.unitPrice !== '') { // on Unit price field change
          //  foundProduct.unitPrice      = getPriceExclusiveVat(foundProduct.unitPrice, foundProduct?.vatPercentage, foundProduct?.vatType);
        }
        // Note: For inclusive vat the value is reducing by the vat percentage.
        if (data.discount && data.discount !== '') { // on discount field change
          foundProduct.discount = getDiscountExclusiveVat(foundProduct.discount, foundProduct?.vatPercentage, foundProduct?.vatType, discountType);
        }
        // Re Calculate Unit price if net value changed
        if (data.netValue && data.netValue !== '' && foundProduct.isTaxLine !== "true") {
          // If user enters value here, you calculate "Unit Price" automatically (based on previous axiom: "Net Value" has to equal: "Quantity" times "Unit Price" minus "Discount")
          foundProduct.unitPrice = getUnitPrice(data.netValue, foundProduct.quantity, foundProduct.discount, discountType);
        } else {
          if (foundProduct.isTaxLine === "true") {
            foundProduct.netValue = 0;
          } else {
            foundProduct.netValue = getNetValue(foundProduct.unitPrice, foundProduct.quantity, foundProduct.discount, discountType);
          }
        }
        if (foundProduct.isTaxLine === "true") {
          foundProduct.taxes = getNetValue(foundProduct.unitPrice, foundProduct.quantity, foundProduct.discount, discountType);
        } else {
          foundProduct.taxes = getTaxes(foundProduct, SETTINGS);
        }
        // Re Calculate Unit price and net value if total value changed
        if (data.totalValue && data.totalValue !== '') {
          if (foundProduct.isTaxLine === "true") {
            foundProduct.taxes = getNetValueByTotalValue(data.totalValue, foundProduct.vatPercentage, foundProduct.taxes);
            foundProduct.unitPrice = getUnitPrice(foundProduct.taxes, foundProduct.quantity, foundProduct.discount, discountType);
            foundProduct.netValue = 0;
          } else {
            // If user enters value here, you calculate "Unit Price" and "Net Value" automatically (based on axioms: "Net Value" has to equal: "Quantity" times "Unit Price" minus "Discount" and "Total Value" equals: "VAT %" times "Net Value" plus "Net Value")
            foundProduct.netValue = getNetValueByTotalValue(data.totalValue, foundProduct.vatPercentage, foundProduct.taxes);
            foundProduct.unitPrice = getUnitPrice(foundProduct.netValue, foundProduct.quantity, foundProduct.discount, discountType);
          }
        } else {
          foundProduct.totalValue = getTotalValue(foundProduct.netValue, foundProduct.vatPercentage, foundProduct.taxes);
        }
        if (foundProduct.isTaxLine === "true") {
          foundProduct.vat = getVatValue(foundProduct.taxes, foundProduct.vatPercentage);
        } else {
          foundProduct.vat = getVatValue(foundProduct.netValue, foundProduct.vatPercentage);
        }
        const updatedPmProducts = {...plProducts[foundProduct.no - 1], ...foundProduct}
        let filterFields = {
          code: data.code ? data.code : plProducts.code,
          name: data.name ? data.name : plProducts.name,
        }
        let productListData = salesDuplicateProductCheck(plProducts, filterFields, foundProduct.no - 1);
        // Logic to check duplicate product in the table.
        if (productListData && !data.quickEdit) {
          toast.error('Duplicate products not allowed.');
          let productTotals = getSalesTotals(plProducts, SETTINGS);
          dispatch(setNewProductMovements({...PRODUCTS_DATA.newProductMovements, products: plProducts, ...productTotals})); // update data
        } else {
          plProducts[foundProduct.no - 1] = updatedPmProducts;
          let productTotals = getSalesTotals(plProducts, SETTINGS);
          dispatch(setNewProductMovements({...PRODUCTS_DATA.newProductMovements, products: plProducts, ...productTotals})); // update data
        }
        setProductTableSpinner('false');

      }
    }
  },
    selected: () => {},
    delete: (id) => {
      // Set if editing the form
      if (!ACTIVITY.saveSales) {
        dispatch(setSaveSales(true));
      }

      let oldSalesProducts = PRODUCTS_DATA.newProductMovements.products.slice();

      if (id !== '' && Number.isInteger(id)) {
        const deletedSalesData = deleteObjectFromArrayOfObjects(oldSalesProducts, id, 'id');
        dispatch(setNewProductMovements({ ...PRODUCTS_DATA.newProductMovements, products: deletedSalesData }))
      }

      if (id !== '' && !Number.isInteger(id)) {
        const deletedSalesData = deleteObjectFromArrayOfObjects(oldSalesProducts, id, '_id');
        dispatch(setNewProductMovements({ ...PRODUCTS_DATA.newProductMovements, products: deletedSalesData }))
      }
    }
  }
  let productMovementsTableData = PRODUCTS_DATA.newProductMovements.products;
  const selectedDocumentType = getDefaultOptionByValue(documentTypesOptions, PRODUCTS_DATA.newProductMovements.documentType , 'value', 'label', 'value')
  const selectDocumentInstallation = getDefaultOptionByValue(dtInstallationsOptions, PRODUCTS_DATA.newProductMovements.installation, "value", "label", "value");
  let productTotals = useMemo(() => getSalesTotals(PRODUCTS_DATA.newProductMovements.products, SETTINGS), [PRODUCTS_DATA.newProductMovements.products]);
  let totalQuantity = productTotals.totalQuantity;
  let totalNetValue = productTotals.totalNetValue;
  let totalAmount = productTotals.totalAmount;
  let totalVatAmount = productTotals.totalVatValue;

  useEffect(() => {
    setIncrKey(incrKey + 1);
  }, [productMovementsTableData, pMovementsProductList])

  return (
    <React.Fragment>
      <div className="product-form position-relative">
      {disabledFields && <div className="overlay_w">{t('ProductsMovementsNew.message')}</div>}
      <Row>
        <Col sm={12} md={3} className="showWithOverlay">
          <Dropdown
            label={t('ProductsMovementsNew.documentTypes')}
            required= {true}
            name="documentType"
            value = {Object.keys(selectedDocumentType).length !== 0 ? selectedDocumentType : ''}
            enabledValue = {true}
            options={documentTypesOptions}
            onChange = {(e) => handleOnChange(e, 'dd', 'documentType')}
          />
        </Col>
        <Col sm={12} md={9} className="text-right">
          <Button disabled={true} size="small">{t('ProductsMovementsNew.files')}</Button>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={3}>
          <Dropdown
            label={t('ProductsMovementsNew.installation')}
            name="installation"
            value = {Object.keys(selectDocumentInstallation).length !== 0 ? selectDocumentInstallation : ''}
            enabledValue = {true}
            options={dtInstallationsOptions}
            onChange = {(e) => handleOnChange(e, 'dd', 'installation')}
          />
        </Col>
        <Col sm={12} md={2}>
          <Dropdown
            label={t('ProductsMovementsNew.preNumber')}
            name="preNumber"
            key={Math.random()}
            value = {getDefaultOptionByValue(preNumberGreekOptions, PRODUCTS_DATA.newProductMovements.preNumber , 'value', 'label', 'value')}
            enabledValue = {true}
            options={preNumberGreekOptions}
            onChange = {(e) => handleOnChange(e, 'dd', 'preNumber')}
          />
        </Col>
        <Col sm="12" md="3">
          <Input
            key = {Math.random()}
            className="mb-3 w-100"
            label={t('ProductsMovementsNew.number.number')}
            charLimit = {1000}
            name="number"
            type="number"
            autoComplete = "off"
            value = {PRODUCTS_DATA.newProductMovements.number}
            placeholder={t('ProductsMovementsNew.number.placeholder')}
            onChange = {(e) => handleOnChange(e)}
            disabled = {disabledFields || PRODUCTS_DATA.newProductMovements.installationMode !== 'manual'}
          />
          {/* {PRODUCTS_DATA.isNumberExists} */}
        </Col>
        <Col sm="12" md="4">
          <DateBox
            classes="w-100 mb-3"
            label={t('ProductsMovementsNew.date')}
            name="date"
            selected="today"
            onChange = {(e) => handleOnChange(e ,'dp', 'date')}
          />
        </Col>
      </Row>
        {(possibleInnerMovements.length > 1 && !docIsCensus) && (
          <Row>
            <Col md={3} className={"mb-3"}>
              <Dropdown
                  name="innerMovement"
                  className={"mb-0"}
                  label={t('ProductsMovementsNew.innerMovement')}
                  key={Math.random()}
                  defaultValue={possibleInnerMovements?.filter((el) => el.value === PRODUCTS_DATA.newProductMovements.innerMovement)[0]}
                  options={[{label: "Κενό", value: ""}].concat(possibleInnerMovements.filter((el) => el.value !== selectDocumentInstallation.value))}
                  onChange={(e) => handleOnChange(e, 'dd', 'innerMovement')}
              />
              <span className={"text-muted"} style={{margin: 0}}>{t('ProductsMovementsNew.innerMovementMessage')}</span>
            </Col>
          </Row>
        )}
      <Row>
        <Col sm="12">
          <Input
            className="mb-3 w-100"
            label={t('ProductsMovementsNew.reason.reason')}
            name="reason"
            autoComplete = "off"
            value = {PRODUCTS_DATA.newProductMovements.reason}
            onChange = {(e) => handleOnChange(e)}
            placeholder={t('ProductsMovementsNew.reason.placeholder')}
          />
        </Col>
      </Row>
      <hr className="mb-4" />
      <Row>
      <div className="et-table-ui">
        {
          ACTIVITY.saveNewProductMovements &&
          productMovementsTableData &&
          productMovementsTableData.length <=0 &&
          <div className="text-danger">{t('ProductsMovementsNew.table.required')}</div>
        }
        {showProductTableSpinner && showProductTableSpinner === 'true' && <div className="overlay_w"> <div className="spinner-border" role="status"> <span className="sr-only">{t("SalesNew.loading")}</span> </div> </div>}
        <EditableTable
          tableName = "Product Movements Products" // Do not change name
          key={"Product Movements Products" + incrKey}
          allowActions={true}
          onUpdate={parentMydataActions}
          allowInsertRow="true"
          columns={productMovementColumns}
          pRequestData={pRequestData}
          enableNewRowCheckbox = {true}
          data={ productMovementsTableData }
        />
      </div>
      </Row>
      <hr />
        <Row>
          <Col sm="12" md="8"></Col>
          <Col sm="12" md="4" className="d-flex justify-content-end">
            <span className="mr-3 fw-bold">{t('SalesNew.totalsTable.title')}</span>
            <table className="table table-bordered mb-3">
              <tbody>
              <tr><td className="fw-bold">{t('SalesNew.totalsTable.totalQuantity')}</td><td>{totalQuantity}</td></tr>
              <tr><td className="fw-bold">{t('SalesNew.totalsTable.totalNetValue')}</td><td>{getGreekCurrencyFormat(totalNetValue)}</td></tr>
              <tr><td className="fw-bold">{t('SalesNew.totalsTable.totalVatAmount')}</td><td>{getGreekCurrencyFormat(totalVatAmount)}</td></tr>
              <tr><td className="fw-bold">{t('SalesNew.totalsTable.totalAmount')}</td><td>{getGreekCurrencyFormat(totalAmount)}</td></tr>
              </tbody>
            </table>
          </Col>
        </Row>
      <Row>
        <div style={{ width: "100%" }}>
          <div className="text-muted mb-2"><i>{t('ProductsMovementsNew.movementsNotes')}</i></div>
          <TextArea
            rows="4"
            placeholder={t('ProductsMovementsNew.addMovementsNotes')}
            name="notes"
            autoComplete = "off"
            value = {PRODUCTS_DATA.newProductMovements.notes}
            onChange = {(e) => handleOnChange(e)}
          />
        </div>
      </Row>

      </div>
    </React.Fragment>
  );
};
