import React, {useEffect, useState} from "react";
import {Modal, Spinner} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";

const DeleteModal = (props) => {
    /* props that should be passed:
       - toBeDeleted : the id of document that is going to be deleted
       - showDelete : modal show boolean
       - tableName : from which search table the deletion will happen
       - showDeleteModal(boolean) : function for modal closure/reset
       - updateFunction : function to call when successful deletion
     */
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const defaultDeleteComponent = <>Παρακαλώ περιμένετε...</>;
    const [deleteComponent, setDeleteComponent] = useState(defaultDeleteComponent);
    const [hideDeleteButton, setHideDeleteButton] = useState(true);
    const [spinner, setSpinner] = useState(false);

    useEffect(() => {
        if(props.toBeDeleted !== "" && props.showDelete === true) {
            axios.get(process.env.REACT_APP_API_URL2 + `/delete/get-info`, {
                headers: {"Content-Type": "application/json"},
                params: {
                    docId: props.toBeDeleted,
                    isDeleting: props.tableName,
                    company: company.id,
                    year: String(company.year)
                }
            }).then((response) => {
                if (response.data.status === "200") {
                    fillDeleteComponent(response.data);
                    setHideDeleteButton(response.data.hideDeleteButton !== undefined ? response.data.hideDeleteButton : true);
                } else {
                    fillDeleteComponent(response.data);
                }
            }).catch((err) => console.log(err));
        }
    }, [props.toBeDeleted, props.showDelete]);

    const closeModal = () => {
        props.showDeleteModal(false);
        setHideDeleteButton(true);
        setDeleteComponent(defaultDeleteComponent);
    }

    const fillDeleteComponent = (responseData) => {
        let component;
        if(props.tableName === "Client Payments Search") {
            component =
                <>
                    {responseData.usages && responseData.usages?.length > 0 ? (
                        <React.Fragment>
                            Η είσπραξη αυτή είναι συνδεμένη με τις παρακάτω πωλήσεις.<br/>
                            {responseData.usages.map((use, ind) => (
                                <span key={ind}>● Εξοφλεί στην {use.saleString} ποσό {parseFloat(use.amount).toFixed(2).replace(".", ",")} € <br/></span>
                            ))}
                            Είσαστε σίγουροι ότι θέλετε να την διαγράψετε;
                        </React.Fragment>
                    ) : (
                        <span>{responseData.displayMessage ? responseData.displayMessage : "Είσαστε σίγουρος ότι θέλετε να διαγράψετε το παρόν αντικείμενο;"}</span>
                    )}
                </>
        } else {
            component =
                <React.Fragment>
                    {responseData.displayMessage ? responseData.displayMessage : "Είσαστε σίγουρος ότι θέλετε να διαγράψετε το παρόν αντικείμενο;"}
                </React.Fragment>
        }
        setDeleteComponent(component);
    }

    const finalDocDelete = () => {
        setSpinner(true);
        const toBeDeleted = props.toBeDeleted;
        axios.post(process.env.REACT_APP_API_URL2 + `/delete/delete-doc`,
            { docId: toBeDeleted, isDeleting: props.tableName, company: company.id, year: String(company.year) },
            {headers: {"Content-Type": "application/json"}})
            .then((response) => {
                if (response.data.status === "200") {
                    toast.success(response.data.message);
                    setSpinner(false);
                    closeModal();
                    if(props.updateFunction) props.updateFunction();
                } else {
                    setSpinner(false);
                    toast.error("Υπήρξε πρόβλημα κατά την διαγραφή. Το αντικείμενο που επιλέξατε έχει διαγραφεί ήδη.");
                }
            })
            .catch((err) => {
                setSpinner(false);
                console.log(err)
            });
    }

    return (
        <Modal show={props.showDelete} onHide={() => closeModal()}>
            <Modal.Header closeButton>
                <Modal.Title>Διαγραφή</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {deleteComponent}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={() => closeModal()}>
                    Κλείσιμο
                </Button>
                <Button variant="primary" onClick={() => finalDocDelete()} hidden={hideDeleteButton} disabled={spinner}>
                    Διαγραφή {spinner && (<Spinner className={"ml-2 mr-0"} animation="border" variant="light" />)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteModal