import React, {useState} from "react";
import AccountingBox from "../AccountingBox";
import AdvancedCombobox from "../AdvancedCombobox";
import axios from "axios";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import AccountingRecordWarning from "../QuickEdit/accounting-record-warning";
import AccountingRecordQuickEdit from "../QuickEdit/accounting-record-quick-edit";
import "./style.css";
import {Button} from "react-bootstrap";
import {isEmpty} from "../../../_helpers/commonFunctions";

const AccountingTable = ({columns, arrayOfObjData, functions, totalCredit, totalDebit, switchCreditAndDebit}) => {
    const [t] = useTranslation('common');
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const [incrKey, setIncrKey] = useState(0);

    const handleOnChange = (e, row, field, type) => {
        if (type === "input") {
            functions.onChange(row, field, e.target.value);
        } else if (type === "accounting") {
            if (isEmpty(e.target.value)) {
                functions.onChange(row, field, 0);
            } else {
                functions.onChange(row, field, e.target.value);
            }
        } else if (type === "combobox") {
            functions.onChange(row, field, e.row);
            setIncrKey(incrKey + 1);
        }
    }

    const handleOnChangeNewRow = (e, field, type) => {
        if (type === "combobox") {
            functions.onInsert(field, e.row);
        }
    }

    const handleDelete = (row, e) => {
        e.preventDefault();
        functions.onDelete(row);
    }

    const handleComboboxChangeSearch = (keyword, setData, field) => {
        if (field === "code" || field === "name") { // Backend searches for name and code at the same time
            axios.get(process.env.REACT_APP_API_URL2 + "/accounting/account-list-by-advanced-type-id", {
                headers: { "Content-Type": "application/json" },
                params: {
                    company: company.id,
                    code: keyword,
                }
            }).then((res) => {
                if (res.data && res.data.status === '200') {
                    if (res.data.data) {
                        setData(res.data.data);
                    } else {
                        setData([]);
                    }
                } else {
                    setData([]);
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    }

    const handleComboboxChangeSearchSubAccount = (cellName, keyword, setData, subAccountType, subAccountCategories) => {
        axios.get(process.env.REACT_APP_API_URL2 + `/accounting/get-subAccount-by-word`, {
            params: {
                company: company.id,
                subAccountType: subAccountType,
                isActive: "active",
                subAccountCategories: subAccountCategories,
                keyword: keyword,
            }
        }).then(res => {
            if (res.data && res.data.status === '200') {
                if (res.data.data) {
                    setData(res.data.data);
                } else {
                    setData([]);
                }
            } else {
                setData([]);
            }
        }).catch((err) => {
            console.log(err);
        })
    }


    const getSubAccountColumns = (subAccountType) => {
        let subAccountColumns = [];
        if (subAccountType === "client" || subAccountType === "supplier") {
            subAccountColumns = [
                { label: t('SalesNew.clientTable.code'), name: "systemCode" },
                { label: t('SalesNew.clientTable.name'), name: "name" },
                { label: t('SalesNew.clientTable.phone'), name: "phone" },
                { label: t('SalesNew.clientTable.email'), name: "email" },
                { label: t('SalesNew.clientTable.vatNumber'), name: "vatNumber" },
            ];
        } else if (subAccountType === "employee") {
            subAccountColumns = [
                { label: "Όνομα", name: "firstName" },
                { label: "Επώνυμο", name: "lastName" },
                { label: "Α.Φ.Μ.", name: "vatNumber" },
                { label: "Τηλέφωνο", name: "phone" }
            ];
        } else if (subAccountType === "installation") {
            subAccountColumns = [
                { label: "Τύπος", name: "type" },
                { label: "Διεύθυνση", name: "address" },
                { label: "Τ.Κ.", name: "postalCode" },
                { label: "Πόλη", name: "city" }
            ];
        } else if (subAccountType === "bankAccount") {
            subAccountColumns = [
                { label: "Όνομα", name: "name" },
                { label: "Τράπεζα", name: "bankName" },
                { label: "IBAN", name: "iban" }
            ];
        } else if (subAccountType === "pos" || subAccountType === "cashRegister") {
            subAccountColumns = [
                { label: "Όνομα", name: "name" },
                { label: "Σειριακός Αριθμός", name: "serialNumber" }
            ];
        } else if (subAccountType === "stockholder") {
            subAccountColumns = [
                { label: "Όνομα", name: "name" },
                { label: "Επώνυμο", name: "surname" },
                { label: "ΑΦΜ", name: "vat" },
                { label: "Ποσοστό", name: "percentage" }
            ];
        }
        return subAccountColumns;
    }
    const onChangeAdvancedComboboxCheckbox = (value, setAdvancedComboboxCheckbox, cellName, setData, searchKeyword, accountObj) => {
        if(accountObj.subAccountType) {
            if (value === "inactive") {
                setAdvancedComboboxCheckbox("active");
            } else {
                setAdvancedComboboxCheckbox("inactive");
            }
            let keyword = searchKeyword ? searchKeyword : "empty";
            let requestData = {company: company.id, isActive: value, subAccountType: accountObj.subAccountType, subAccountCategories: accountObj.subAccountCategories};
            axios.get(process.env.REACT_APP_API_URL2 + `/accounting/get-subAccount-by-word`, {
                params: {
                    ...requestData,
                    keyword: keyword,
                }
            }).then(res => {
                if (res.data && res.data.status === '200') {
                    if (res.data.data) {
                        setData(res.data.data);
                    } else {
                        setData([]);
                    }
                } else {
                    setData([]);
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    }

    return (
        <React.Fragment>
            <table style={{textAlign: "center", width: "98%", marginLeft: "1%", marginRight: "1%"}} className={"mb-3"} cellSpacing={0} cellPadding={0} onKeyDown={(e) => handleKeyDown(e)}>
                <colgroup>
                    {columns.map((col, idx) => (
                        <col key={"widthManager" + idx} span={1} style={{width: col.width ? col.width : 'auto'}}></col>
                    ))}
                </colgroup>
                <thead>
                    <tr key={"tableHead"} style={{backgroundColor: "#f1fdff"}}>
                        {columns && columns.length > 0 && columns.map((col) => (
                            <th style={{border: "1px solid #dee2e6"}}>{col.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                {arrayOfObjData && arrayOfObjData?.length > 0 && arrayOfObjData.map((obj, idx) => (
                    <tr key={idx}>
                        {columns && columns?.length > 0 && columns.map((col) => (
                            <td key={idx + col.field} style={{padding: "2px !important", border: "1px solid #dee2e6", height: "100%", verticalAlign: "top"}}>
                                {(() => {
                                    if (col.inputType.type === "accounting") {
                                        return (
                                            <AccountingBox
                                                disabled={(col.field === "debit" && obj.isDebit === "inactive") || (col.field === "credit" && obj.isCredit === "inactive")}
                                                fromAccountTable={true}
                                                inputFieldClasses={"form-control"}
                                                key={obj[col.field]}
                                                value={obj[col.field]}
                                                defaultValue={obj[col.field]}
                                                allowNegative={true}
                                                onBlur={(e) => handleOnChange(e, idx, col.field, "accounting")}
                                            />
                                        );
                                    } else if (col.inputType.type === "advanced-combobox" && col.field !== "subAccount") {
                                        return (
                                            <AdvancedCombobox
                                                {...col.inputType.config}
                                                inputFieldClasses={"form-control"}
                                                defaultValue={obj[col.field]}
                                                key={obj[col.field] + String(incrKey)}
                                                className="ac-select-dropdown"
                                                onChangeSearch={(keyword, setData) => handleComboboxChangeSearch(keyword, setData, col.field)}
                                                onChange={(e) => handleOnChange(e, idx, col.field, "combobox")}
                                            />
                                        )
                                    } else if (col.inputType.type === "advanced-combobox" && col.field === "subAccount") {
                                        return (
                                            <AdvancedCombobox
                                                {...col.inputType.config}
                                                disabled={obj.subAccountType === ""}
                                                inputFieldClasses={"form-control"}
                                                columns={getSubAccountColumns(obj.subAccountType)}
                                                onChangeAdvancedComboboxCheckbox={(value, setAdvancedComboboxCheckbox, cellName, setData, searchKeyword) => onChangeAdvancedComboboxCheckbox(value, setAdvancedComboboxCheckbox, cellName, setData, searchKeyword, obj)}
                                                data={obj?.subAccountData}
                                                showAdvancedComboboxCheckbox={obj.subAccountType !== "installation" && obj.subAccountType !== "pos" && obj.subAccountType !== "cashRegister"}
                                                defaultValue={obj[col.field]}
                                                key={obj[col.field]}
                                                className="ac-select-dropdown"
                                                onChangeSearchCell={(cellName, keyword, setData) => handleComboboxChangeSearchSubAccount(cellName, keyword, setData, obj.subAccountType, obj.subAccountCategories)}
                                                onChange={(e) => handleOnChange(e, idx, col.field, "combobox")}
                                            />
                                        )
                                    } else if (col.inputType.type === "actions") {
                                        const actions = [];
                                        if (col.actions.editClassifications) {
                                            actions.push(
                                                <AccountingRecordQuickEdit
                                                    title={"Επεξεργασία My Data"}
                                                    data={obj}
                                                />
                                            )
                                        }
                                        if (col.actions.warning && obj.isNetValue !== "false") {
                                            actions.push(
                                                <AccountingRecordWarning
                                                    data={obj}
                                                />
                                            )
                                        }
                                        if (col.actions.delete) {
                                            actions.push(
                                                <button style={{padding: "2px", margin: "8px"}} title="Διαγραφή" className="btn text-danger" onClick={(e) => handleDelete(idx, e)}>
                                                    <i className="fas fa-minus-circle"></i>
                                                </button>
                                            )
                                        }
                                        return actions;
                                    } else {
                                        return ( <div></div> );
                                    }
                                })()}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
                <tbody>
                <tr key={"newRow"} style={{backgroundColor: "#ffffcc"}}>
                    {columns && columns?.length > 0 && columns.map((col, idx) => (
                        <td key={"newRow" + idx + col.field} style={{border: "1px solid #dee2e6", padding: "2px !important", verticalAlign: "top"}}>
                            {(() => {
                                if (col.inputType.type === "accounting") {
                                    return ( <div></div> );
                                } else if (col.inputType.type === "advanced-combobox" && col.field !== "subAccount") {
                                    return (
                                        <AdvancedCombobox
                                            {...col.inputType.config}
                                            key={Math.random()}
                                            inputFieldClasses={"form-control advanced-combobox-insert"}
                                            className="ac-select-dropdown"
                                            onChangeSearch={(keyword, setData) => handleComboboxChangeSearch(keyword, setData, col.field)}
                                            onChange={(e) => handleOnChangeNewRow(e, col.field, "combobox")}
                                        />
                                    )
                                } else {
                                    return ( <div></div> );
                                }
                            })()}
                        </td>
                    ))}
                </tr>
                <tr key={"accountingTotals"} style={{textAlign: "right"}}>
                    <td style={{border: "1px solid #dee2e6", padding: "2px !important", verticalAlign: "middle", fontSize: "18px", fontWeight: "600"}} colSpan={3}>Σύνολα <span className={"mr-3"}/></td>
                    <td style={{border: "1px solid #dee2e6", padding: "2px !important", verticalAlign: "top"}}>
                        <AccountingBox fromAccountTable={true} defaultValue={totalDebit} value={totalDebit} disabled={true} />
                    </td>
                    <td style={{border: "1px solid #dee2e6", padding: "2px !important", verticalAlign: "top"}}>
                        <AccountingBox fromAccountTable={true} defaultValue={totalCredit} value={totalCredit} disabled={true} />
                    </td>
                    <td style={{border: "1px solid #dee2e6", padding: "2px !important", verticalAlign: "top", textAlign: "center"}}>
                        <Button style={{marginLeft: "auto", marginRight: "auto"}} size={"sm"} onClick={switchCreditAndDebit}><img src={"assets/images/xp.png"} alt={"Αλλαγή Χ-Π"} /></Button>
                    </td>
                </tr>
                </tbody>
            </table>
        </React.Fragment>
    )
}

export default AccountingTable