import axios from "axios";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import EditableTable from "../../../primer/Editable-Table";
import Checkbox from "../../../common/CheckBox";
import Dropdown from "..//..//..//primer/Dropdown";
import ModalInstructions from "../../../primer/POPUP-COMPONENTS/Instructions";
import {toast} from "react-toastify";
import {checkPermission, isEmpty} from "../../../../_helpers/commonFunctions";
import {
    setImport,
    setTooltip,
    setValues,
    setValuesChecks,
} from "../../../../_reducers/settingsSlice";
import TourForSection from "./settings-import-joyride";
import TourForImports from "./settings-import-joyride2";
import TourForImportArea from "./settings-import-joyride3";
import {excelOptions} from "../../../../_data/settings/doc-types";
import {useTranslation} from "react-i18next";
import {banksDummyData} from "../../../../_data/dummy_banks";
import {Row} from "react-bootstrap";
import LogTable from "../../../primer/LogTable";
import {setUpdateInnerTab} from "../../../../_reducers/activitySlice";

export const SettingsOfImport = () => {
    const dispatch = useDispatch();
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const [loader, setLoader] = useState(false);
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const [t] = useTranslation("common");
    const exportSectionOptions = [
        {value: "Products", label: t("SettingsImportExport.sectionProducts")},
        {value: "Product Movements", label: t("SettingsImportExport.sectionProductMovements")},
        {value: "Clients", label: t("SettingsImportExport.sectionClients")},
        {value: "Client Movements", label: t("SettingsImportExport.sectionClientMovements")},
        {value: "Supplier Movements", label: t("SettingsImportExport.sectionSupplierMovements")},
        {value: "Sales", label: t("SettingsImportExport.sectionSales")},
        {value: "Client Payments", label: t("SettingsImportExport.sectionClientPayments")},
        {value: "POS", label: t("SettingsImportExport.sectionPOS")},
        {value: "Cash Registers", label: t("SettingsImportExport.sectionCashRegisters")},
        {value: "Bank Accounts", label: t("SettingsImportExport.sectionBankAccounts")},
        {value: "Suppliers", label: t("SettingsImportExport.sectionSuppliers")},
        //{ value: "Buys", label: "Buys" },
        //{ value: "Supplier Payments", label: "Supplier Payments" },
        {value: "Accounting Accounts", label: t("SettingsImportExport.sectionAccountingAccounts")},
        //{ value: "Accounting Records", label: "Accounting Records" },
    ]
    const fieldsToCheck = [
        "myDataIncExp",
        "myDataRowType",
        "myDataRowCategory",
        "myDataRowInvoiceType",
        "myDataRowVatExpenseType",
        "contactAcceptNotifications",
        "contactNotes",
        "contactEmail",
        "contactLandline",
        "contactPhone2",
        "contactPhone",
        "contactName",
        "installationCustomVat",
        "installationVatReason",
        "installationVatCategory",
        "installationPostalCode",
        "installationCity",
        "installationDOY",
        "installationAddress",
        "installationNo",
        "chequeFirstDate",
        "chequeLastDate",
        "myDataUID",
        "myDataMark",
        "extraDataTaxType",
        "extraDataTaxCode",
        "extraDataTaxAmount",
        "isTaxLine",
        "productExtraTaxType",
        "productExtraTaxCode",
        "productExtraTaxAmount",
        "deliveryLocationAddress",
        "deliveryLocationCity",
        "deliveryLocationCountry",
        "deliveryLocationPostalCode"
    ];
    const handleOnChange = (e, type = "default", ddname = "", index) => {
        //let name = "";
        let value = "";
        if (type === "dd") {
            //name = ddname;
            value = e.value;
        } else {
            //name = e.target.name;
            value = e.target.value;
        }
        if (ddname === "section") {
            dispatch(setImport(value));
        } else {
            if (type === "dd") {
                const isUnique = SETTINGS.importExport.fields.every(
                    (obj) => obj["column"] !== value
                );
                if (!isUnique) {
                    toast.error(t("SettingsImportExport.toasts.alreadySelectedColumn"));
                } else {
                    dispatch(setValues({type, value, index}));
                }
            } else if (type === "cb" || type === "checkAll") { // Checkbox
                if ((ddname === "Delivery Location Address" || ddname === "Delivery Location City"
                        || ddname === "Delivery Location Country" || ddname === "Delivery Location Postal Code")
                    && SETTINGS.importExport.fields.filter((el) => el.fieldKey === "deliveryLocation")[0].checkBox === false
                    && SETTINGS.importExport.section === "Sales") {
                    toast.error(t("SettingsImportExport.toasts.deliveryLocationCheck"));
                } else if (ddname === "My Data Auth Code"
                    && SETTINGS.importExport.fields.filter((el) => el.fieldKey === "myDataMark")[0].checkBox === false
                    && SETTINGS.importExport.section === "Sales") {
                    toast.error(t("SettingsImportExport.toasts.myDataCheck"));
                } else {
                    dispatch(setValues({type, value, index}));
                }
            }
        }
    };
    const exportTable = [
        {
            name: t("SettingsImportExport.no"),
            field: "no",
            editable: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("SettingsImportExport.fieldName"),
            field: "fieldName",
            editable: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("SettingsImportExport.excelColumn"),
            field: "column",
            editable: false,
            inputType: {
                type: "text",
            },
        },
    ];

    const startImport = () => {
        setLoader(true);
        let bug = false;
        SETTINGS.importExport.fields.forEach((element) => {
            if (element.checkBox) {
                if (isEmpty(element.column)) {
                    toast.error(t("SettingsImportExport.toasts.pleaseSelectColumnFor") + " "
                        + t("SettingsImportExport." + SETTINGS.importExport.section + "." + element["fieldKey"]));
                    bug = true;
                }
            }
        });
        setLoader(false);

        if (!bug) dispatch(setUpdateInnerTab(1));
    }

    useEffect(() => {
        loadTooltips();
    }, [SETTINGS.importExport.section])

    const loadTooltips = () => {
        if (SETTINGS.importExport.section === "Products") {
            axios.post(process.env.REACT_APP_API_URL2 + `/import/productsToolTipCategories`, {company: company.id}, {
                headers: {"Content-Type": "application/json"},
            }).then((response) => {
                dispatch(setTooltip({key: "category", title: "Κατηγορίες Ειδών", content: response.data.message}));
            }).catch((error) => {
                console.log(error);
            });
        } else if (SETTINGS.importExport.section === "Product Movements") {
            axios.post(process.env.REACT_APP_API_URL2 + `/import/productMovementsToolTipDocumentTypes`, {company: company.id}, {
                headers: {"Content-Type": "application/json"},
            }).then((response) => {
                dispatch(setTooltip({key: "documentTypeName", title: "Γενικά Παραστατικά", content: response.data.message}));
            }).catch((error) => {
                console.log(error);
            });
            axios.post(process.env.REACT_APP_API_URL2 + `/import/clientPaymentsToolTipInstallations`, {company: company.id}, {
                headers: {"Content-Type": "application/json"},
            }).then((response) => {
                dispatch(setTooltip({key: "installationMasterID", title: "Εγκαταστάσεις", content: response.data.message}));
            }).catch((error) => {
                console.log(error);
            });
        } else if (SETTINGS.importExport.section === "Client Payments") {
            axios.post(process.env.REACT_APP_API_URL2 + `/import/clientPaymentsToolTipPaymentTypes`, {company: company.id}, {
                headers: {"Content-Type": "application/json"},
            }).then((response) => {
                dispatch(setTooltip({
                    key: "paymentType",
                    title: "Τρόποι Πληρωμής",
                    content: "Πρέπει να εκχωρήσετε τον αριθμό<br/><br/>" + response.data.message
                }));
            }).catch((error) => {
                console.log(error);
            });
            axios.post(process.env.REACT_APP_API_URL2 + `/import/clientPaymentsToolTipInstallations`, {company: company.id}, {
                headers: {"Content-Type": "application/json"},
            }).then((response) => {
                dispatch(setTooltip({key: "installation", title: "Εγκαταστάσεις", content: response.data.message}));
            }).catch((error) => {
                console.log(error);
            });
            axios.post(process.env.REACT_APP_API_URL2 + `/import/clientPaymentsToolTipDocumentTypes`, {company: company.id}, {
                headers: {"Content-Type": "application/json"},
            }).then((response) => {
                dispatch(setTooltip({
                    key: "documentTypeName",
                    title: "Τύποι Παραστατικών Εισπράξεων",
                    content: response.data.message
                }));
            }).catch((error) => {
                console.log(error);
            });
        } else if (SETTINGS.importExport.section === "Client Movements") {
            axios.post(process.env.REACT_APP_API_URL2 + `/import/clientPaymentsToolTipInstallations`, {company: company.id}, {
                headers: {"Content-Type": "application/json"},
            }).then((response) => {
                dispatch(setTooltip({
                    key: "installationMasterID",
                    title: "Εγκαταστάσεις",
                    content: response.data.message
                }))
            }).catch((error) => {
                console.log(error);
            });
            axios.post(process.env.REACT_APP_API_URL2 + `/import/clientMovementsToolTipDocumentTypes`, {company: company.id}, {
                headers: {"Content-Type": "application/json"},
            }).then((response) => {
                dispatch(setTooltip({
                    key: "documentTypeName",
                    title: "Τύποι Παραστατικών Κινήσεων",
                    content: response.data.message
                }));
            }).catch((error) => {
                console.log(error);
            });
        } else if (SETTINGS.importExport.section === "Supplier Movements") {
            axios.post(process.env.REACT_APP_API_URL2 + `/import/clientPaymentsToolTipInstallations`, {company: company.id}, {
                headers: {"Content-Type": "application/json"},
            }).then((response) => {
                dispatch(setTooltip({
                    key: "installationMasterID",
                    title: "Εγκαταστάσεις",
                    content: response.data.message
                }))
            }).catch((error) => {
                console.log(error);
            });
            axios.post(process.env.REACT_APP_API_URL2 + `/import/supplierMovementsToolTipDocumentTypes`, {company: company.id}, {
                headers: {"Content-Type": "application/json"},
            }).then((response) => {
                dispatch(setTooltip({
                    key: "documentTypeName",
                    title: "Τύποι Παραστατικών Κινήσεων",
                    content: response.data.message
                }));
            }).catch((error) => {
                console.log(error);
            });
        } else if (SETTINGS.importExport.section === "Sales") {
            axios.post(process.env.REACT_APP_API_URL2 + `/import/clientPaymentsToolTipInstallations`, {company: company.id}, {
                headers: {"Content-Type": "application/json"},
            }).then((response) => {
                dispatch(setTooltip({
                    key: "installationMasterID",
                    title: "Εγκαταστάσεις",
                    content: response.data.message
                }))
            }).catch((error) => {
                console.log(error);
            });
            axios.post(process.env.REACT_APP_API_URL2 + `/import/salesToolTipDocumentTypes`, {company: company.id}, {
                headers: {"Content-Type": "application/json"},
            }).then((response) => {
                dispatch(setTooltip({
                    key: "documentTypeName",
                    title: "Τύποι Παραστατικών Πωλήσεων",
                    content: response.data.message
                }));
            }).catch((error) => {
                console.log(error);
            });
            axios.post(process.env.REACT_APP_API_URL2 + `/import/clientPaymentsToolTipPaymentTypes`, {company: company.id}, {
                headers: {"Content-Type": "application/json"},
            }).then((response) => {
                dispatch(setTooltip({
                    key: "paymentType",
                    title: "Τρόποι Πληρωμής",
                    content: "Πρέπει να εκχωρήσετε τον αριθμό<br/><br/>" + response.data.message
                }));
            }).catch((error) => {
                console.log(error);
            });
            axios.post(process.env.REACT_APP_API_URL2 + `/import/salesToolTipCarNumbers`, {company: company.id}, {
                headers: {"Content-Type": "application/json"},
            }).then((response) => {
                dispatch(setTooltip({key: "carNumber", title: "Αριθμοί αυτοκινήτων", content: response.data.message}));
            }).catch((error) => {
                console.log(error);
            });
            axios.post(process.env.REACT_APP_API_URL2 + `/import/salesToolTipSendingGoods`, {company: company.id}, {
                headers: {"Content-Type": "application/json"},
            }).then((response) => {
                dispatch(setTooltip({
                    key: "wayOfSendingGoods",
                    title: "Τρόποι αποστολής εμπορευμάτων",
                    content: response.data.message
                }));
            }).catch((error) => {
                console.log(error);
            });
        } else if (SETTINGS.importExport.section === "Bank Accounts") {
            let prepContent = "<table className='table-bordered' style='text-align: center; margin-right: auto; margin-left: auto;'><tr><th>Swift</th><th>Όνομα Τράπεζας</th></tr>";
            for (let bank in banksDummyData) {
                prepContent += "<tr>";
                prepContent += "<td>" + banksDummyData[bank].swift + "</td>"
                prepContent += "<td>" + banksDummyData[bank].name + "</td>"
                prepContent += "</tr>";
            }
            prepContent += "</table>";
            dispatch(setTooltip({key: "bankSwift", title: "Swift Τραπεζών", content: prepContent}))
        } else if (SETTINGS.importExport.section === "Accounting Accounts") {
            axios.post(process.env.REACT_APP_API_URL2 + "/import/accountingAccountsSettings", {company: company.id}, {
                headers: {"Content-Type": "application/json"}
            }).then((response) => {
                dispatch(setTooltip({key: "category", title: "Πρότυπα Λογαριασμών", content: response.data.message}));
            }).catch((error) => {
                console.log(error);
            })
        }
    }

    return (
        <>
            <div className="import-settings">
                {!SETTINGS.importExport.section && <TourForSection/>}

                <div className="row">
                    <div className="col-12 col-md-4">
                        <Dropdown
                            label={t("SettingsImportExport.section")}
                            options={exportSectionOptions}
                            classes="sectionDropdown"
                            value={exportSectionOptions.filter((el) => el.value === SETTINGS.importExport.section)}
                            name="section"
                            enabledValue={true}
                            onChange={(e) => handleOnChange(e, "dd", "section")}
                        />
                    </div>
                </div>
                {SETTINGS.importExport.section !== "" && (
                    <>
                        <hr/>
                        <TourForImports/>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <label className="w-100">
                                    <strong>{t("SettingsImportExport.fieldName")}</strong>
                                </label>
                            </div>
                            <div className="col-12 col-md-3">
                                <label className="w-100">
                                    <strong>{t("SettingsImportExport.excelColumn")}</strong>
                                </label>
                            </div>
                            <div className="col-12 col-md-3">
                                <label className="w-100">
                                    <strong>{t("SettingsImportExport.fieldName")}</strong>
                                </label>
                            </div>
                            <div className="col-12 col-md-3">
                                <label className="w-100">
                                    <strong>{t("SettingsImportExport.excelColumn")}</strong>
                                </label>
                            </div>
                            <div className="row">
                                {SETTINGS.importExport.fields.map((field, i) => {
                                    return (
                                        <>
                                            {SETTINGS.importExport.section === "Sales" && field.fieldKey === "documentTypeName" &&
                                                <>
                                                    <div
                                                        className="text-muted">{t("SettingsImportExport.Sales.mutedBasicData")}</div>
                                                </>
                                            }
                                            {SETTINGS.importExport.section === "Sales" && field.fieldKey === "productName" &&
                                                <>
                                                    <hr/>
                                                    <div
                                                        className="text-muted">{t("SettingsImportExport.Sales.mutedBasicDataLine")}</div>
                                                </>
                                            }
                                            {SETTINGS.importExport.section === "Sales" && field.fieldKey === "myDataUID" &&
                                                <>
                                                    <hr/>
                                                    <div
                                                        className="text-muted">{t("SettingsImportExport.Sales.mutedMyData")}</div>
                                                </>
                                            }
                                            {SETTINGS.importExport.section === "Sales" && field.fieldKey === "extraDataTaxType" &&
                                                <>
                                                    <hr/>
                                                    <div
                                                        className="text-muted">{t("SettingsImportExport.Sales.mutedExtraData")}</div>
                                                </>
                                            }
                                            {SETTINGS.importExport.section === "Accounting Accounts" && field.fieldKey === "fee" &&
                                                <>
                                                    <hr/>
                                                    <div
                                                        className="text-muted">{t("SettingsImportExport.Accounting Accounts.mutedCaseTax")}</div>
                                                </>
                                            }
                                            <div className="col-12 col-md-3 forJoyReqField">
                                                <Checkbox
                                                    text={t("SettingsImportExport." + SETTINGS.importExport.section + "." + field["fieldKey"])}
                                                    required={field["required"]}
                                                    checked={field["checkBox"]}
                                                    defaultValue={field["checkBox"]}
                                                    value={field["checkBox"]}
                                                    disabled={field["disabled"]}
                                                    className="mb-1 mr-2 centered-checkbox"
                                                    onChange={(e) => {
                                                        if (fieldsToCheck.includes(field["fieldKey"])) {
                                                            handleOnChange(e, "checkAll", field["fieldName"], i);
                                                        } else {
                                                            handleOnChange(e, "cb", field["fieldName"], i);
                                                        }
                                                    }
                                                    }
                                                />
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <div className="col-md-8 forJoyReqColumn">
                                                        <Dropdown
                                                            options={excelOptions.filter((el) => SETTINGS.importExport.fields.every((obj) => obj.column !== el.value))}
                                                            disabled={!field["checkBox"]}
                                                            value={excelOptions.find((option) => {
                                                                return option.value === field["column"];
                                                            })}
                                                            onChange={(e) =>
                                                                handleOnChange(e, "dd", field["fieldName"], i)
                                                            }
                                                            enabledValue={true}
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        {field["tooltip"] && (
                                                            <div className="forJoyTooltip" style={{width: '35%'}}>
                                                                <ModalInstructions data={field["tooltip"]}/>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </>
                )}
                <hr/>
                <div className="row">
                    <div className="text-muted">{t("SettingsImportExport.mandatoryFields")}</div>

                    {SETTINGS.importExport.section !== "" && checkPermission("settings-importexport/import-write", permissionsData) && (
                        <div className="col-12 ml-auto ">
                            <button
                                type="button"
                                className="btn btn-primary float-right"
                                onClick={() => startImport()}
                            >
                                {t("SettingsImportExport.startImport")}
                                <span> </span>
                                {loader && (
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                )}
                            </button>
                        </div>
                    )}
                    <p>{t("SettingsImportExport.results")}</p>
                    <EditableTable
                        columns={exportTable}
                        data={SETTINGS.importExport.result}
                    />
                </div>
            </div>
        </>
    )
}

export const SettingsImport = () => {
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({});
    const [response, setResponse] = useState(null);
    const [checkDisabled, setCheckDisabled] = useState(false);
    const [insertDisabled, setInsertDisabled] = useState(true);
    const [loader, setLoader] = useState(false);
    const [loader2, setLoader2] = useState(false);
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const [t] = useTranslation("common");

    useEffect(() => {
        if (!SETTINGS.importExport.section) {
            toast.error(t("SettingsImportExport.toasts.selectionEmpty"));
            dispatch(setUpdateInnerTab(0));
        }

        if (SETTINGS.importExport.section) {
            let willReturn = false;
            SETTINGS.importExport.fields.forEach((element) => {
                if (element.checkBox) {
                    if (isEmpty(element.column)) {
                        willReturn = true;
                    }
                }
            });
            if (willReturn) {
                toast.error(t("SettingsImportExport.toasts.someExcelColumnsAreEmpty"));
                dispatch(setUpdateInnerTab(0));
            }
        }
    }, [])

    const handleOnChange = (e, type = "default", ddname = "") => {
        let name = "";
        let value = "";

        if (type === "dd") {
            name = ddname;
            value = e.value;
        } else if (type === "file") {
            const selected = e.target.files[0];
            setFormData({...formData, file: selected});
        } else if (type === "cb") {
            if (ddname === "insertNew") {
                value = !SETTINGS.importExport.insertNew;
                name = e.target.name;
            }
            if (ddname === "updateExisting") {
                value = !SETTINGS.importExport.updateExisting;
                name = e.target.name;
            }
            name = ddname;
            dispatch(setValuesChecks({type, name, value}));
        } else {
            name = ddname;
            value = e.target.value;
            dispatch(setValuesChecks({type, name, value}));
        }
        if (checkDisabled) setCheckDisabled(false);
    }

    const errorList =
        response && response.errors && response.errors.length > 0 ? (
            response.errors.map((error, index) => (
                <p className="text-danger" key={index}>
                    {error}
                </p>
            ))
        ) : (
            <p className="text-success">No error found</p>
        );

    const backtoPrevious = () => {
        dispatch(setUpdateInnerTab(0));
    }
    const checkInsertData = (dataType, name) => {
        let bug = false;

        if (name === "insertData") {
            setInsertDisabled(true);
        } else if (name === "checkData") {
            setCheckDisabled(true);
        }
        if (!formData.file) {
            bug = true;
            toast.error(t("SettingsImportExport.toasts.pleaseSelectAFileFirst"));
        }
        if (formData.file) {
            const allowedExtensions = ["xls", "xlsx"];
            const fileExtension = formData.file.name.split(".").pop().toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                bug = true;
                toast.error(t("SettingsImportExport.toasts.onlyXLSXValid"));
            }
        }

        if (
            SETTINGS.importExport.firstLine === undefined ||
            SETTINGS.importExport.firstLine === null ||
            SETTINGS.importExport.firstLine === ""
        ) {
            bug = true;
            toast.error(t("SettingsImportExport.toasts.firstLineIsEmpty"));
        }

        if (SETTINGS.importExport.checkData === false && name === "insertData") {
            toast.error(t("SettingsImportExport.toasts.pleaseCheckData"));
            bug = true;
        }
        if (name === "checkData" && !SETTINGS.importExport.checkData) {
            dispatch(setValuesChecks({type: "cb", name: "checkData", value: true}));
        }
        const importExport = SETTINGS.importExport;

        if (!importExport.insertNew && !importExport.updateExisting) {
            toast.error(t("SettingsImportExport.toasts.atLeastOneCheckbox"));
            bug = true;
        }
        if (bug === false) {
            if (dataType) {
                setLoader2(true);
            } else {
                setLoader(true);
            }

            const object = {
                company: company.id,
                year: company.year,
                section: importExport.section,
                insertNew: importExport.insertNew,
                updateExisting: importExport.updateExisting,
                insertData: dataType,
                firstLine: importExport.firstLine,
                userid: JSON.parse(localStorage.getItem("user"))._id,
            };
            const filteredArray = SETTINGS.importExport.fields.filter(
                (obj) => obj.checkBox
            );
            const obj = filteredArray.reduce((acc, cur) => {
                acc[cur.fieldKey] = cur.column.toString();
                return acc;
            }, {});
            let data = new FormData();

            object.columnMapping = obj;
            data.append("file", formData.file);
            for (let key in object) {
                data.append(
                    key,
                    typeof object[key] === "string" ||
                    typeof object[key] === "number" ||
                    typeof object[key] === "boolean"
                        ? object[key] === true
                            ? 1
                            : object[key] === false
                                ? 0
                                : object[key]
                        : JSON.stringify(object[key])
                )
            }

            let endpoint = "";
            if (SETTINGS.importExport.section === "Products") {
                endpoint = "products";
            } else if (SETTINGS.importExport.section === "Clients") {
                endpoint = "clients";
            } else if (SETTINGS.importExport.section === "Client Payments") {
                endpoint = "clientPayments";
            } else if (SETTINGS.importExport.section === "Client Movements") {
                endpoint = "clientMovements";
            } else if (SETTINGS.importExport.section === "Supplier Movements") {
                endpoint = "supplierMovements";
            } else if (SETTINGS.importExport.section === "Sales") {
                endpoint = "sales";
            } else if (SETTINGS.importExport.section === "POS") {
                endpoint = "pos";
            } else if (SETTINGS.importExport.section === "Cash Registers") {
                endpoint = "cashRegisters";
            } else if (SETTINGS.importExport.section === "Bank Accounts") {
                endpoint = "bankAccounts";
            } else if (SETTINGS.importExport.section === "Suppliers") {
                endpoint = "suppliers";
            } else if (SETTINGS.importExport.section === "Accounting Accounts") {
                endpoint = "accountingAccounts";
            } else if (SETTINGS.importExport.section === "Product Movements") {
                endpoint = "productMovements";
            }
            axios.post(process.env.REACT_APP_API_URL2 + `/import/${endpoint}`, data, {
                headers: {"Content-Type": "application/form-data"},
            }).then((response) => {
                if (response.data.status === "200") {
                    setTimeout(() => {
                        setResponse(response.data.data);
                        toast.success(response.data.message);
                        if (response.data.data.correctLines > 0 && name === "checkData") { // Enable the Insert Data button after Successful check.
                            setInsertDisabled(false);
                        }
                        if (dataType) {
                            setLoader2(false);
                        } else {
                            setLoader(false);
                        }
                        setCheckDisabled(false);
                    }, 2000);
                } else {
                    toast.error(response.data.message);
                    if (dataType) {
                        setLoader2(false);
                    } else {
                        setLoader(false);
                    }
                    setCheckDisabled(false);
                }
            }).catch((error) => {
                if (dataType) {
                    setLoader2(false);
                } else {
                    setLoader(false);
                }
                toast.error("Error, file changed. Please press back and re-upload.");
                console.error(error);
                setCheckDisabled(false);
            })
        }
    }
    return (
        <>
            <TourForImportArea/>
            <Row>
                <div className="mb-2 col-12 col-md-4 forJoyFile">
                    {t("SettingsImportExport.chooseFile")}
                    <input
                        type="file"
                        className="form-control"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={(e) => handleOnChange(e, "file", "file")}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-2 col-12 col-md-4 forJoyFirstLine">
                    {t("SettingsImportExport.firstLine")}
                    <input
                        type="number"
                        className="form-control"
                        value={SETTINGS.importExport.firstLine}
                        onChange={(e) => handleOnChange(e, "input", "firstLine")}
                        required={true}
                    />
                </div>

                <div className="mb-2 col-12 col-md-4">
                    <Checkbox
                        text={t("SettingsImportExport.insertNewRecords")}
                        value={SETTINGS.importExport.insertNew}
                        defaultValue={SETTINGS.importExport.insertNew}
                        className="mb-1 mr-2 centered-checkbox forJoyInsertNew"
                        onChange={(e) => handleOnChange(e, "cb", "insertNew")}
                    />
                </div>
                <div className="mb-2 col-12 col-md-4">
                    <Checkbox
                        text={t("SettingsImportExport.updateExistingRecords")}
                        value={SETTINGS.importExport.updateExisting}
                        defaultValue={SETTINGS.importExport.updateExisting}
                        className="mb-1 mr-2 centered-checkbox forJoyUpdateExisting"
                        onChange={(e) => handleOnChange(e, "cb", "updateExisting")}
                    />
                </div>
            </Row>
            <div className="row">
                <div className="mb-2 col-12 col-md-4">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => backtoPrevious()}
                    >
                        {t("SettingsImportExport.back")}
                    </button>
                </div>
                <div className="mb-2 col-12 col-md-4">
                    <button
                        type="button"
                        className="btn btn-primary forJoyCheckData"
                        disabled={checkDisabled}
                        onClick={() => checkInsertData(false, "checkData")}
                    >
                        {t("SettingsImportExport.checkData")}
                        {" "}
                        {loader && (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        )}
                    </button>
                </div>
                <div className="mb-2 col-12 col-md-4">
                    {checkPermission("settings-importexport/import-write", permissionsData) &&
                        <button
                            type="button"
                            className="btn btn-primary forJoyInsertData"
                            disabled={insertDisabled}
                            onClick={() => checkInsertData(true, "insertData")}
                        >
                            {t("SettingsImportExport.insertData")}
                            {" "}
                            {loader2 && (
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            )}
                        </button>
                    }
                </div>
            </div>

            <div className="row">
                <div className="mb-2 col-12">
                    <p>Console</p>
                    <div className="bg-light w-100 border p-3">
                        {loader === true || (loader2 === true && <p>Start Import....</p>)}
                        {response !== null && (loader === false || loader2 === false) && (
                            <>
                                <p>{response.linesFound} Lines Found.</p>
                                {errorList}
                                {response && response.totalUpdated && response.totalUpdated ? (
                                    <p className="text-success">
                                        {response.totalUpdated} {SETTINGS.importExport.section}{" "}
                                        Updated if differed
                                    </p>
                                ) : (
                                    ""
                                )}

                                {response &&
                                response.totalImported &&
                                response.totalImported > 0 ? (
                                    <p className="text-success">
                                        {response.totalImported} {SETTINGS.importExport.section}{" "}
                                        Imported
                                    </p>
                                ) : (
                                    ""
                                )}

                                {response && response.otherMessages && response.otherMessages.length > 0 ? (
                                    <>
                                        {response.otherMessages.map(item => (
                                                <p className="mb-0">
                                                    {item}
                                                </p>
                                            )
                                        )}
                                    </>
                                ) : (
                                    ""
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export const ImportLog = () => {
    const {t} = useTranslation('common');

    return (
        <React.Fragment>
            <div className="text-muted mb-2"><i>{t('Logs.importLog')}</i></div>
            <LogTable fromFile={true}/>
        </React.Fragment>
    )
}
