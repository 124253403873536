const zeroParticipationReasons = [
    {
        "id": "99",
        "name": "ΔΙΚΑΙΟΥΧΟΙ ΠΡΟΝΟΙΑΣ",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "36",
        "name": "test11",
        "active": "false",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "35",
        "name": "test2a",
        "active": "false",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "34",
        "name": "ΠΛΗΜΜΥΡΟΠΑΘΗΣ ΘΕΣΣΑΛΙΑΣ",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "33",
        "name": "ΤΥΠΕΤ - ΑΜΕΑ ΑΝΩ ΤΩΝ 32 ΕΤΩΝ",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "32",
        "name": "ΚΥΗΣΗ ΚΑΙ ΛΟΧΕΙΑ",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "31",
        "name": "ΤΕΛ. ΣΤΑΔ. ΧΡΟΝ. ΝΕΦΡΙΚΗΣ ΝΟΣΟΥ ΕΞΩΝΕΦΡΙΚΗ ΚΑΘΑΡΣΗ",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "30",
        "name": "ΥΠΕΡΦΑΙΝΥΛΑΛΑΝΙΝΑΙΜΙΑ",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "29",
        "name": "ΝΟΣΟΣ NIEMANN-PICK ΤΥΠΟΥ C",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "28",
        "name": "ΧΡΟΝΙΑ ΝΕΦΡΙΚΗ ΝΟΣΟΣ, ΣΤΑΔΙΟ 3 ΚΑΙ 4",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "27",
        "name": "ΠΝΕΥΜΟΝΙΚΗ ΥΠΕΡΤΑΣΗ",
        "active": "false",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "26",
        "name": "ΕΛΛΕΙΨΗ ΟΡΝΙΘΟ-ΚΑΡΒΑΜΥΛΟ-ΤΡΑΝΣΦΕΡΑΣΗΣ",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "25",
        "name": "ΝΟΣΟΣ GAUCHER",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "24",
        "name": "ΓΛΥΚΟΓΟΝΙΑΣΗ - ΓΛΥΚΟΓΟΝΙΑΣΗ ΤΥΠΟΥ ΙΒ",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "23",
        "name": "ΝΟΣΟΣ WILSON (ΗΠΑΤΟΦΑΚΟΕΙΔΗΣ ΕΚΦΥΛΙΣΗ)",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "22",
        "name": "ΧΡΟΝΙΑ ΗΠΑΤΙΤΙΔΑ B ΚΑΙ C",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "21",
        "name": "ΑΝΟΙΑ, ΝΟΣΟΣ ALZHEIMER ΚΑΙ ΝΟΣΟΣ CHARCOT",
        "active": "false",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "20",
        "name": "ΑΓΓΕΙΟΠΑΘΕΙΑ BURGER",
        "active": "false",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "19",
        "name": "ΚΥΣΤΙΚΗ ΙΝΩΣΗ (ΚΥΣΤΙΚΗ ΙΝΩΔΗΣ/ΙΝΟΚΥΣΤΙΚΗ ΝΟΣΟΣ)",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "18",
        "name": "ΥΠΟΦΥΣΙΟΓΕΝΗΣ ΝΑΝΙΣΜΟΣ (ΑΥΞΗΤΙΚΗ ΟΡΜΟΝΗ)",
        "active": "true",
        "filterMedicine": "true",
        "consumable": "false"
    },
    {
        "id": "17",
        "name": "ΑΙΜΟΡΡΟΦΙΛΙΑ (ΑΝΤΙΑΙΜΟΡΡΟΦΙΛΙΚΟΙ ΠΑΡΑΓΟΝΤΕΣ)",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "16",
        "name": "ΝΥΧΤΕΡΙΝΗ ΠΑΡΟΞΥΣΜΙΚΗ ΑΙΜΟΣΦΑΙΡΙΝΟΥΡΙΑ",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "15",
        "name": "ΙΔΙΟΠΑΘΗΣ ΑΙΜΟΛΥΤΙΚΗ ΑΝΑΙΜΙΑ-ΘΡΟΜΒΟΠΕΝΙΚΗ ΠΟΡΦΥΡΑ",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "14",
        "name": "ΕΠΙΛΗΨΙΑ ΚΑΙ ΛΟΙΠΕΣ ΕΠΙΛΗΠΤΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ",
        "active": "false",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "13",
        "name": "ΨΥΧΩΣΕΙΣ",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "12",
        "name": "ΣΑΚΧΑΡΩΔΗΣ ΔΙΑΒΗΤΗΣ ΤΥΠΟΥ Ι",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "true"
    },
    {
        "id": "11",
        "name": "ΝΕΟΠΛΑΣΜΑΤΑ-ΛΕΥΧΑΙΜΙΕΣ",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "10",
        "name": "ΔΡΕΠΑΝΟΚΥΤΤΑΡΙΚΗ ΑΝΑΙΜΙΑ",
        "active": "true",
        "filterMedicine": "true",
        "consumable": "false"
    },
    {
        "id": "9",
        "name": "ΜΕΣΟΓΕΙΑΚΗ ΑΝΑΙΜΙΑ",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "8",
        "name": "ΤΕΤΡΑΠΛΗΓΙΚΟΙ",
        "active": "true",
        "filterMedicine": "true",
        "consumable": "false"
    },
    {
        "id": "7",
        "name": "ΣΚΛΗΡΥΝΣΗ ΚΑΤΑ ΠΛΑΚΑΣ",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "6",
        "name": "ΠΑΡΑΠΛΗΓΙΚΟΙ",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "5",
        "name": "ΝΕΦΡΟΠΑΘΕΙΣ ΣΕ ΑΙΜΟΚΑΘΑΡΣΗ",
        "active": "true",
        "filterMedicine": "false",
        "consumable": "false"
    },
    {
        "id": "4",
        "name": "ΜΕΤΑΜΟΣΧΕΥΜΕΝΟΙ",
        "active": "true",
        "filterMedicine": "true",
        "consumable": "false"
    }
]

const noInnCases = [
    {
        "id": "1",
        "name": "Φάρμακα που προκαλούν αλλεργίες και αντιδράσεις",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "2",
        "name": "Φάρμακα μεταμοσχευμένων",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "3",
        "name": "Φάρμακα ανοσοκατασταλμένων",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "7",
        "name": "Βιοτεχνολογικά φάρμακα",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "6",
        "name": "Εμβόλια",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "5",
        "name": "Ινσουλίνες",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "4",
        "name": "Παράγωγα αίματος",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "8",
        "name": "Συνδυασμένα προϊόντα",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "17",
        "name": "Φάρμακα για άσθμα",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "20",
        "name": "Φάρμακα για ασθενείς με χρόνιες νόσους επαρκώς και αποτελεσματικώς ρυθμισμένους",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "14",
        "name": "Φάρμακα για επιληψία",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "16",
        "name": "Φάρμακα για σχιζοφρένεια",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "18",
        "name": "Φάρμακα για χρόνια εκφυλιστικά και αυτοάνοσα νοσήματα",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "15",
        "name": "Φάρμακα για ψύχωση",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "12",
        "name": "Φάρμακα με ειδική μεθοδολογία χορήγησης",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "13",
        "name": "Φάρμακα με υψηλή τοξικότητα",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "11",
        "name": "Φάρμακα που απαιτούν ειδικά μέτρα προστασίας",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "10",
        "name": "Φάρμακα που απαιτούν ιατρική επίβλεψη",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "19",
        "name": "Φάρμακα που χορηγούνται με συσκευές που απαιτούν εκπαίδευση των ασθενών",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "9",
        "name": "Φάρμακα στενού φαρμακευτικού εύρους",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "0",
        "name": "Χρόνιες νόσοι",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "false"
    },
    {
        "id": "22",
        "name": "Εμβόλια Απευαισθητοποίησης",
        "isNegativeList": "false",
        "isDesensitizationVaccine": "true"
    },
    {
        "id": "21",
        "name": "Μη αποζημιούμενο φάρμακο",
        "isNegativeList": "true",
        "isDesensitizationVaccine": "false"
    }
]

export const getNoInnCasesOptions = () => {
    let arr = [];
    for (let obj of noInnCases) {
        arr.push({
            label: obj["name"],
            value: obj["id"]
        })
    }
    arr.sort((a, b) => String(a.label).localeCompare(b.label, "el"));
    return [{value: "", label: "Κενό"}, ...arr];
}

export const getZeroParticipationReasonOptions = () => {
    let arr = [];
    for (let obj of zeroParticipationReasons.filter((el) => el["active"] === "true")) {
        arr.push({
            label: obj["name"],
            value: obj["id"]
        })
    }
    arr.sort((a, b) => String(a.label).localeCompare(b.label, "el"));
    return [{value: "", label: "Κενό"}, ...arr];
}

const examTypes = [
    {
        "id": "1201",
        "name": "hgfg",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "2",
        "name": "Υπέρηχοι",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "3",
        "name": "Triplex Καρδιάς",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "0",
        "name": "1ASSDAS",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "42",
        "name": "ΠΡΟΛΗΠΤΙΚΕΣ ΑΚΤΙΝΟΛΟΓΙΑΣ",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "4",
        "name": "Triplex Αγγείων",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "5",
        "name": "Σπινθηρογραφήματα",
        "active": "true",
        "showWideGroup": "true",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "6",
        "name": "Ακτινογραφίες",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "7",
        "name": "Εξετάσεις Βιολογικών Υλικών 1 (Βιοπαθολογίας) (Αιματολογικές, Βιοχημικές, Μικροβιολογικές)",
        "active": "true",
        "showWideGroup": "true",
        "notSelectable": "false",
        "midwifeEnabled": "true"
    },
    {
        "id": "8",
        "name": "Εξετάσεις Βιολογικών Υλικών 2 (Βιοπαθολογίας & Πυρηνικής Ιατρικής) (Ανοσολογικές, Ορμονολογικές)",
        "active": "true",
        "showWideGroup": "true",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "9",
        "name": "Φυσικοθεραπείες",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "10",
        "name": "Ακτινοθεραπείες",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "11",
        "name": "Μαγνητικές Τομογραφίες",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1",
        "name": "Αξονική Τομογραφία",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "12",
        "name": "Μέτρηση Οστικής Πυκνότητας (Ακτινολογία & Πυρηνικής Ιατρικής)",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "26",
        "name": "Καρδιολογία",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "15",
        "name": "Θεραπείες Πυρηνικής Ιατρικής",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "21",
        "name": "Γενικά",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "18",
        "name": "Αναισθησιολογία",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "30",
        "name": "Οδοντοτεχνικές Εργασίες",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "28",
        "name": "Νευρολογία-Ψυχιατρική",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "23",
        "name": "Τοξικολογικές",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "25",
        "name": "Θωρακοχειρουργική",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "24",
        "name": "Θεραπεία Στειρότητας",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "31",
        "name": "Ορθοπεδική",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "36",
        "name": "Ωτορινολαρυγγολογία",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "19",
        "name": "Αφροδισιολογία-Δερματολογία",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "32",
        "name": "Ουρολογία",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "17",
        "name": "Αγγειοχειρουργική",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "14",
        "name": "Παθολογοανατομικές - Ιστολογικές",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "16",
        "name": "Αγγειογραφίες",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "13",
        "name": "Κυτταρολογικές",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "33",
        "name": "Οφθαλμολογία",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "22",
        "name": "Γυναικολογία",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "20",
        "name": "Γαστρεντερολογία",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "29",
        "name": "Νεφρολογία",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "35",
        "name": "Ψυχιατρική",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "34",
        "name": "Πνευμονολογία-Φυματιολογία",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "27",
        "name": "Μαιευτική",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "37",
        "name": "Φυσιατρική-Φυσικοθεραπείες",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "38",
        "name": "Συνεδρίες Φυσικοθεραπείας (Συμμετοχή 0%)",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "39",
        "name": "Φυσιατρική-Νευρολογία",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "41",
        "name": "Προληπτική Ιατρική",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "43",
        "name": "ΠΡΟΛΗΠΤΙΚΕΣ ΒΙΟΛΟΓΙΚΩΝ ΥΛΙΚΩΝ - 1",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "44",
        "name": "ΠΡΟΛΗΠΤΙΚΕΣ ΒΙΟΛΟΓΙΚΩΝ ΥΛΙΚΩΝ - 2",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "45",
        "name": "ΠΡΟΛΗΠΤΙΚΕΣ  ΚΥΤΤΑΡΟΛΟΓΙΑΣ",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "46",
        "name": "ΠΡΟΛΗΠΤΙΚΕΣ  ΓΑΣΤΕΝΤΕΡΟΛΟΓΙΑΣ",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1000",
        "name": "test_category1",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1021",
        "name": "test_category2",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1027",
        "name": "a123",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1006",
        "name": "assdf",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1002",
        "name": "test_category3",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1127",
        "name": "test1",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1128",
        "name": "tst",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1131",
        "name": "test2",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1132",
        "name": "test3",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1133",
        "name": "test4",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1134",
        "name": "rrr",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1142",
        "name": "aasdsd",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "50",
        "name": "ΕΔΟΕΑΠ-Αξονική Τομογραφία",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "51",
        "name": "ΕΔΟΕΑΠ-Γαστρεντερολογία",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "52",
        "name": "ΕΔΟΕΑΠ-Εξετάσεις Βιολογικών Υλικών 1 (Βιοπαθολογίας) (Αιματολογικές, Βιοχημικές, Μικροβιολογικές)",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "54",
        "name": "ΕΔΟΕΑΠ-Καρδιολογία",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "55",
        "name": "ΕΔΟΕΑΠ-Υπέρηχοι",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "56",
        "name": "ΕΔΟΕΑΠ-Μαγνητικές Τομογραφίες",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "57",
        "name": "ΕΔΟΕΑΠ-Μαιευτική",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "58",
        "name": "ΕΔΟΕΑΠ-Ακτινογραφίες",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "59",
        "name": "ΕΔΟΕΑΠ-Κυτταρολογικές",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "60",
        "name": "ΕΔΟΕΑΠ-Οφθαλμολογία",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1260",
        "name": "Προληπτική εξέταση στο πλαίσιο του Εθνικού Προγράμματος Προσυμπτωματικού Ελέγχου (ΕΠΠΕ)",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "true",
        "midwifeEnabled": "false"
    },
    {
        "id": "47",
        "name": "ΒΙΟΔΕΙΚΤΕΣ",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "49",
        "name": "Γονιδιακές εξετάσεις για καρκίνο μαστού",
        "active": "true",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "48",
        "name": "ΠΡΟΛΗΠΤΙΚΕΣ ΠΡΟΓΕΝΝΗΤΙΚΟΥ ΕΛΕΓΧΟΥ",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "53",
        "name": "ΕΔΟΕΑΠ-Εξετάσεις Βιολογικών Υλικών 2 (Βιοπαθολογίας και Πυρηνικής Ιατρικής) (Ανοσολογικές, Ορμονολογικές)",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1220",
        "name": "ΤΥΠΕΤ-TRIPLEX",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1221",
        "name": "ΤΥΠΕΤ-Μ.Ο.Π",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1222",
        "name": "ΤΥΠΕΤ-MRI",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1223",
        "name": "ΤΥΠΕΤ-MRCP",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1224",
        "name": "ΤΥΠΕΤ-MRA",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1225",
        "name": "ΤΥΠΕΤ-MRV",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1226",
        "name": "ΤΥΠΕΤ-ΜΑΣΤΟΓΡΑΦΙΑ",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1227",
        "name": "ΤΥΠΕΤ-CT",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1228",
        "name": "ΤΥΠΕΤ-US",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1229",
        "name": "ΤΥΠΕΤ-ΣΠΙΝΘΗΡΟΓΡΑΦΗΜΑ",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1230",
        "name": "ΤΥΠΕΤ-AC ΑΝΩ ΑΚΡΟ",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1231",
        "name": "ΤΥΠΕΤ-AC ΕΙΔΙΚΕΣ ΑΚΤΙΝΟΓΡΑΦΙΕΣ",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1232",
        "name": "ΤΥΠΕΤ-AC ΘΩΡΑΚΑΣ",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1233",
        "name": "ΤΥΠΕΤ-AC ΚΑΤΩ ΑΚΡΟ",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1234",
        "name": "ΤΥΠΕΤ-AC ΚΡΑΝΙΟ",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1235",
        "name": "ΤΥΠΕΤ-AC ΠΥΕΛΙΚΗ ΖΩΝΗ",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1236",
        "name": "ΤΥΠΕΤ-AC ΣΣ",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1237",
        "name": "ΤΥΠΕΤ-AC ΣΣ ΑΜΣΣ",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1238",
        "name": "ΤΥΠΕΤ-AC ΣΣ ΘΜΣΣ",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1239",
        "name": "ΤΥΠΕΤ-AC ΣΣ ΟΜΣΣ",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1240",
        "name": "ΤΥΠΕΤ-AC ΩΜΟΥ",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1241",
        "name": "ΤΥΠΕΤ-AC ΣΣ ΘΟΜΣΣ",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1242",
        "name": "ΤΥΠΕΤ-AC ΚΟΙΛΙΑΣ",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    },
    {
        "id": "1243",
        "name": "ΤΥΠΕΤ-ΑΓΓΕΙΟΓΡΑΦΙΕΣ",
        "active": "false",
        "showWideGroup": "false",
        "notSelectable": "false",
        "midwifeEnabled": {}
    }
]

export const getExamTypesOptions = () => {
    let arr = [];
    for (let obj of examTypes.filter((el) => el["active"] === "true")) {
        arr.push({
            label: obj["name"],
            value: obj["id"]
        })
    }
    arr.sort((a, b) => String(a.label).localeCompare(b.label, "el"));
    return [{value: "", label: "Κενό"}, ...arr];
}

const zeroReferralParticipationReasons = [
    {
        "id": "64",
        "noParticipationCase": "ΠΙΣΤΟΠΟΙΗΜΕΝΗ ΑΝΑΠΗΡΙΑ 80% ΚΑΙ ΑΝΩ ΑΠΟ ΚΕΠΑ",
        "isActive": "true",
        "needsDecision": "true"
    },
    {
        "id": "62",
        "noParticipationCase": "ΑΚΡΩΤΗΡΙΑΣΜΕΝΟΙ ΠΟΥ ΛΑΜΒΑΝΟΥΝ ΤΟ ΕΞΩΙΔΡΥΜΑΤΙΚΟ ΕΠΙΔΟΜΑ ΜΕ ΠΟΣΟΣΤΟ ΑΝΑΠΗΡΙΑΣ 67% ΚΑΙ ΑΝΩ",
        "isActive": "true",
        "needsDecision": "true"
    },
    {
        "id": "61",
        "noParticipationCase": "ΣΑΚΧΑΡΩΔΗΣ ΔΙΑΒΗΤΗΣ ΤΥΠΟΥ 1",
        "isActive": "true",
        "needsDecision": "false"
    },
    {
        "id": "60",
        "noParticipationCase": "ΚΥΣΤΙΚΗ ΙΝΩΣΗ",
        "isActive": "true",
        "needsDecision": "false"
    },
    {
        "id": "59",
        "noParticipationCase": "ΧΡΟΝΙΑ ΝΕΦΡΙΚΗ ΑΝΕΠΑΡΚΕΙΑ ΤΕΛΙΚΟΥ ΣΤΑΔΙΟΥ (ΣΕ ΘΕΡΑΠΕΙΑ ΥΠΟΚΑΤΑΣΤΑΣΗΣ ΝΕΦΡΙΚΗΣ ΛΕΙΤΟΥΡΓΙΑΣ Ή ΠΕΡΙΤΟΝΑΙΚΗ ΚΑΘΑΡΣΗ Ή ΜΕ ΜΕΤΑΜΟΣΧΕΥΣΗ)",
        "isActive": "true",
        "needsDecision": "false"
    },
    {
        "id": "58",
        "noParticipationCase": "ΔΡΕΠΑΝΟΚΥΤΤΑΡΙΚΗ ΝΟΣΟΣ",
        "isActive": "true",
        "needsDecision": "false"
    },
    {
        "id": "57",
        "noParticipationCase": "ΜΕΣΟΓΕΙΑΚΗ ΑΝΑΙΜΙΑ",
        "isActive": "true",
        "needsDecision": "false"
    },
    {
        "id": "34",
        "noParticipationCase": "ΠΛΗΜΜΥΡΟΠΑΘΗΣ ΘΕΣΣΑΛΙΑΣ",
        "isActive": "true",
        "needsDecision": "false"
    },
    {
        "id": "3",
        "noParticipationCase": "TEST3",
        "isActive": "false",
        "needsDecision": "false"
    },
    {
        "id": "2",
        "noParticipationCase": "test1",
        "isActive": "false",
        "needsDecision": "false"
    },
    {
        "id": "1",
        "noParticipationCase": "test2",
        "isActive": "false",
        "needsDecision": "true"
    }
]

export const getZeroReferralParticipationReasonsOptions = () => {
    let arr = [];
    for (let obj of zeroReferralParticipationReasons.filter((el) => el["isActive"] === "true")) {
        arr.push({
            label: obj["noParticipationCase"],
            value: obj["id"],
            needsDecision: obj["needsDecision"],
        })
    }
    arr.sort((a, b) => String(a.label).localeCompare(b.label, "el"));
    return [{value: "", label: "Κενό"}, ...arr];
}

export const referralOverrides = [
    {
        "id": "7",
        "description": "Έλεγχος Μέσου Παραπεμπτικoύ",
        "parentId": ""
    },
    {
        "id": "103",
        "description": "Έλεγχος Διαγνώσεων εξέτασης - βήμα 3",
        "parentId": "1"
    },
    {
        "id": "102",
        "description": "Έλεγχος Διαγνώσεων εξέτασης - βήμα 2",
        "parentId": "1"
    },
    {
        "id": "1",
        "description": "Έλεγχος Διαγνώσεων εξέτασης",
        "parentId": ""
    },
    {
        "id": "101",
        "description": "Έλεγχος Διαγνώσεων εξέτασης - βήμα 1",
        "parentId": "1"
    },
    {
        "id": "104",
        "description": "Έλεγχος Διαγνώσεων εξέτασης - βήμα 4",
        "parentId": "1"
    },
    {
        "id": "2",
        "description": "Ακριβή Εξέταση",
        "parentId": ""
    },
    {
        "id": "5",
        "description": "Επίσκεψη ασθενή στην ίδια ειδικότητα",
        "parentId": ""
    },
    {
        "id": "6",
        "description": "Επίσκεψη ασθενή σε διαφορετικές ειδικότητες",
        "parentId": ""
    },
    {
        "id": "8",
        "description": "Επανάληψη εξέτασης ανά ΑΜΚΑ",
        "parentId": ""
    },
    {
        "id": "10",
        "description": "Επανασυνταγογράφηση δραστικής ουσίας",
        "parentId": ""
    },
    {
        "id": "801",
        "description": "Προληπτική Ιατρική - Δράση Πρόληψης του Καρκίνου του Παχέος Εντέρου",
        "parentId": ""
    },
    {
        "id": "4",
        "description": "Έλεγχος πακέτων εξετάσεων σε παραπεμπτικό",
        "parentId": ""
    },
    {
        "id": "501",
        "description": "Διαγνωστικό Πρωτόκολλο Δισλιπιδαιμίας",
        "parentId": ""
    },
    {
        "id": "510",
        "description": "Διαγνωστικό Πρωτόκολλο Οστεοπόρωσης",
        "parentId": ""
    },
    {
        "id": "511",
        "description": "Διαγνωστικό Πρωτόκολλο Οστεοπόρωσης",
        "parentId": "510"
    },
    {
        "id": "512",
        "description": "Διαγνωστικό Πρωτόκολλο Οστεοπόρωσης",
        "parentId": "510"
    },
    {
        "id": "513",
        "description": "Διαγνωστικό Πρωτόκολλο Οστεοπόρωσης",
        "parentId": "510"
    },
    {
        "id": "514",
        "description": "Διαγνωστικό Πρωτόκολλο Οστεοπόρωσης",
        "parentId": "510"
    },
    {
        "id": "515",
        "description": "Διαγνωστικό Πρωτόκολλο Οστεοπόρωσης",
        "parentId": "510"
    },
    {
        "id": "11",
        "description": "Έλεγχος παιδιάτρων",
        "parentId": ""
    },
    {
        "id": "9",
        "description": "Έλεγχος νοσηλείας ασθενή",
        "parentId": ""
    },
    {
        "id": "12",
        "description": "Έλεγχος Προληπτικών Εξετάσεων",
        "parentId": ""
    }
]

export const prescriptionDrugAmountOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
]

export const prescriptionParticipationPercentageOptions = [
    { value: "1", label: "0%" },
    { value: "10", label: "10%" },
    { value: "25", label: "25%" },
]

export const prescriptionDoseAmountOptions = [
    { value: "0.25", label: "0.25" },
    { value: "0.5", label: "0.5" },
    { value: "1", label: "1" },
    { value: "1.5", label: "1.5" },
    { value: "2", label: "2" },
    { value: "2.5", label: "2.5" },
    { value: "3", label: "3" },
    { value: "3.5", label: "3.5" },
    { value: "4", label: "4" },
    { value: "4.5", label: "4.5" },
    { value: "5", label: "5" },
    { value: "5.5", label: "5.5" },
    { value: "6", label: "6" },
    { value: "6.5", label: "6.5" },
    { value: "7", label: "7" },
    { value: "7.5", label: "7.5" },
    { value: "8", label: "8" },
    { value: "8.5", label: "8.5" },
    { value: "9", label: "9" },
    { value: "9.5", label: "9.5" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "19", label: "19" },
    { value: "20", label: "20" },
]

export const prescriptionFrequencyOptions = [ // Attention to backend matching (function prescriptionFrequency(...))
    { label: "1 φορά την ημέρα", value: "1perday" },
    { label: "2 φορές την ημέρα", value: "2perday" },
    { label: "3 φορές την ημέρα", value: "3perday" },
    { label: "4 φορές την ημέρα", value: "4perday" },

    { label: "1 φορά την εβδομάδα", value: "1perweek" },
    { label: "2 φορές την εβδομάδα", value: "2perweek" },
    { label: "3 φορές την εβδομάδα", value: "3perweek" },

    { label: "κάθε 2 εβδομάδες", value: "every2weeks" },
    { label: "κάθε 4 εβδομάδες", value: "every4weeks" },
    { label: "κάθε 8 εβδομάδες", value: "every8weeks" },
    { label: "κάθε 12 εβδομάδες", value: "every12weeks" },

    { label: "εφάπαξ", value: "forever" },
    { label: "επί πόνου", value: "pain" },
    { label: "επί δύσπνοιας", value: "breath" },
]

export const getMultiselectICD10sOptions = (icd10s = []) => {
    return [{value: "all", label: "Όλες"}, ...icd10s.map((o) => {
        return {
            value: o.code,
            label: `${o.code} ${o.title}`,
        }
    })]
}

export const asArray = (obj) => {
    if (obj) {
        if (Array.isArray(obj)) {
            return obj;
        } else {
            return [obj];
        }
    }
    return [];
}

export const referralTypeOptions = [
    {label: "Τυπικό", value: "1"},
    // {label: "Ελεύθερο", value: "2"}, // Unavailable
]
