import React, {useState} from "react";
import {isEmpty} from "../../../../../_helpers/commonFunctions";
import Button from "react-bootstrap/Button";
import {Badge, ButtonGroup, Col, Dropdown, DropdownButton, Row, Spinner} from "react-bootstrap";
import "./styles.css";
import TextArea from "../../../../common/TextArea";
import {addTab, updateActiveMainTab} from "../../../../../_reducers/TabsSlice";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {setEditProductMovements} from "../../../../../_reducers/DataPanel/Products/productsSlice";
import {setEditSales} from "../../../../../_reducers/DataPanel/Sales/salesSlice";
import {setEditClientMovements, setEditClientPayment} from "../../../../../_reducers/DataPanel/Clients/clientSlice";
import {setEditBuys} from "../../../../../_reducers/DataPanel/Buys/buysSlice";
import {
    setEditSupplierMovements,
    setEditSupplierPayment
} from "../../../../../_reducers/DataPanel/Suppliers/supplierSlice";
import {setEditAccountingRecord} from "../../../../../_reducers/DataPanel/Accounting/accountingSlice";
import {toast} from "react-toastify";
import Modal from "react-bootstrap/Modal";
import FilePreviewerContainer from "./FilePreviewerContainer";
import {greekMonths} from "./constants";
import Input from "../../../../common/Input";
import OurDropdown from "../../../../primer/Dropdown";
import moment from "moment";

const FilePreviewer = ({path, previewData, fileName, documentHooks, userNotes, loading,
                            onFileDownload, onPreviewNewTab, onResetPreview, onNotesSave, onDocumentDecorrelation, onEmronSort,
                            onEmronRestore, onEmronRegister, onEmronMove, specialCode, accountantNotes, isEmron}) => {
    const [tmpUserNotes, setTMPUserNotes] = useState(userNotes);
    const [tmpAccountantNotes, setTMPAccountantNotes] = useState(accountantNotes);

    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);

    const [decorModal, setDecorModal] = useState(false);
    const [decorModalData, setDecorModalData] = useState(null);

    const previewStyle = {
        borderTopRightRadius: "18px",
        borderBottomRightRadius: "18px",
        border: "2px solid lightgray",
        overflowY: "auto",
        width: "100%",
        padding: "10px",
    }

    const isInUnsorted = String(path).includes("/Αταξινόμητα/");
    const isRegister = !String(path).includes("/Καταχωρημένα/");
    const isSort = !String(path).includes("/Ταξινομημένα/");

    const registerOrSortToOptions = ["Αγορές & Δαπάνες", "Πωλήσεις", "Τράπεζες", "Λοιπά Αρχεία", "Πάγια", "Παρακρατούμενοι & Λοιποί Φόροι", "Ιδιωτικά Συμφωνητικά & Συμβάσεις"];

    const currentMonth = greekMonths.find((el) => String(path).includes(el));
    const [emronMoveOptionTo, setEmronMoveOptionTo] = useState(registerOrSortToOptions.find((el) => String(path).includes(el)));
    const [emronMoveMonthTo, setEmronMoveMonthTo] = useState(currentMonth);
    const [emronMoveModal, setEmronMoveModal] = useState(false);

    const monthsLock = +company.year === +moment().format("YYYY");
    const greekMonthOptions = greekMonths.map((opt, idx) => {
        if (idx + 1 <= +moment().format("MM") || !monthsLock) {
            return {
                value: opt,
                label: opt,
            }
        }
    }).filter(Boolean);

    const handleCorrelatedClick = (docId, section) => {
        const tabMapping = {
            "Product Movements": "edit-product-movements",
            "Sales": "view-sales",
            "Client Movements": "edit-client-movements",
            "Client Payments": "edit-client-payments",
            "Buys": "edit-buys",
            "Supplier Payments": "edit-supplier-payments",
            "Supplier Movements": "edit-supplier-movements",
            "Accounting Records": "accounting-record-edit",
        }
        axios.get(process.env.REACT_APP_API_URL2 + "/company/get-document-by-id", {
            headers: {"Content-Type": "application/json"},
            params: {company: company.id, docId: docId, section: section}
        }).then((res) => {
            if (res.data.status === "200") {
                const tabName = tabMapping[section];
                if (tabName === "edit-product-movements") {
                    dispatch(setEditProductMovements(res.data.data));
                } else if (tabName === "view-sales") {
                    dispatch(setEditSales(res.data.data));
                } else if (tabName === "edit-client-movements") {
                    dispatch(setEditClientMovements(res.data.data));
                } else if (tabName === "edit-client-payments") {
                    dispatch(setEditClientPayment(res.data.data));
                } else if (tabName === "edit-buys") {
                    dispatch(setEditBuys(res.data.data));
                } else if (tabName === "edit-supplier-payments") {
                    dispatch(setEditSupplierPayment(res.data.data));
                } else if (tabName === "edit-supplier-movements") {
                    dispatch(setEditSupplierMovements(res.data.data));
                } else if (tabName === "accounting-record-edit") {
                    dispatch(setEditAccountingRecord(res.data.data));
                }
                const idx = TABS_DATA.findIndex((item) => tabName === item);
                if (idx === -1) {
                    dispatch(addTab(tabName));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(idx));
                }
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleDecorrelateClick = (docId, section, label) => {
        setDecorModal(true);
        setDecorModalData({_id: docId, section: section, label: label});
    }

    const handleSaveDecorrelation = () => {
        onDocumentDecorrelation(path, decorModalData);
        setDecorModal(false);
        setDecorModalData({});
    }

    return (
        <div style={previewStyle}>
            {(isEmpty(previewData) && !loading) ? (
                <div style={{textAlign: "center"}}>
                    Πατήστε κάποιο αρχείο για προεπισκόπηση
                </div>
            ) : (
                <React.Fragment>
                    {loading ? (
                        <div style={{textAlign: "center"}}>
                            <div>
                                <Spinner animation={"border"} variant={"dark"} className={"mt-2"}/>
                            </div>
                            <div>
                                Η προεπισκόπηση φορτώνει παρακαλώ περιμένετε...
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                            <div style={{textAlign: "center"}} className={"mb-1"}>
                                Προεπισκόπηση αρχείου <strong>{fileName}</strong>
                            </div>
                            <div style={{display: "flex", width: "100%", height: "40px"}}>
                                <Button size={"sm"} className={"mb-1"} style={{flex: "1"}}
                                        onClick={() => onPreviewNewTab(path)}>Άνοιγμα σε νέα καρτέλα</Button>
                                <Button size={"sm"} className={"ml-1 mb-1"} style={{flex: "1"}}
                                        onClick={() => onFileDownload(previewData, fileName)}>Λήψη αρχείου</Button>
                                <Button size={"sm"} className={"ml-1 mb-1"} style={{flex: "0.1"}}
                                        onClick={() => onResetPreview()}><i className="fa-solid fa-xmark"></i></Button>
                            </div>
                            {isEmron && (
                                <React.Fragment>
                                    {specialCode === "1" && !String(path).includes("/Διαγραμμένα/") && (
                                        <div style={{display: "flex", width: "100%", height: "40px"}}
                                             className={"dropdownP0"}>
                                            {isRegister ? (
                                                <DropdownButton
                                                    as={ButtonGroup}
                                                    variant={"primary"}
                                                    title={"Καταχώρηση σε"}
                                                    style={{flex: "1"}}
                                                    className={"mb-1"}
                                                >
                                                    {registerOrSortToOptions.map((opt, idx) => (
                                                        <Dropdown.Item key={`registerTo-${idx}`}
                                                                       onClick={() => onEmronRegister(path, opt)}>{opt}</Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            ) : (
                                                <Button size={"sm"} className={"mb-1"} style={{flex: "1"}}
                                                        onClick={() => onEmronRegister(path)}>Αναίρεση
                                                    Καταχώρησης</Button>
                                            )}
                                            {isSort ? (
                                                <DropdownButton
                                                    as={ButtonGroup}
                                                    variant={"primary"}
                                                    title={"Ταξινόμηση σε"}
                                                    style={{flex: "1"}}
                                                    className={"ml-1 mb-1"}
                                                >
                                                    {registerOrSortToOptions.map((opt, idx) => (
                                                        <Dropdown.Item key={`registerTo-${idx}`}
                                                                       onClick={() => onEmronSort(path, opt)}>{opt}</Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            ) : (
                                                <Button size={"sm"} className={"ml-1 mb-1"} style={{flex: "1"}}
                                                        onClick={() => onEmronSort(path)}>Αναίρεση ταξινόμησης</Button>
                                            )}
                                            {isInUnsorted && (
                                                <DropdownButton
                                                    as={ButtonGroup}
                                                    variant={"primary"}
                                                    title={"Μεταφορά σε"}
                                                    style={{flex: "1"}}
                                                    className={"ml-1 mb-1"}
                                                >
                                                    {registerOrSortToOptions.map((opt, idx) => (
                                                        <Dropdown.Item key={`moveTo-${idx}`}
                                                                       onClick={() => {
                                                                           setEmronMoveOptionTo(opt);
                                                                           setEmronMoveMonthTo(currentMonth);
                                                                           setEmronMoveModal(true);
                                                                       }}>{opt}</Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            )}
                                        </div>
                                    )}
                                    {specialCode === "1" && String(path).includes("/Διαγραμμένα/") && (
                                        <div style={{display: "flex", width: "100%"}}>
                                            <Button size={"sm"} className={"mb-1"} style={{flex: "1 1"}}
                                                    onClick={() => onEmronRestore(path)}>Επαναφορά</Button>
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                            <FilePreviewerContainer
                                fileName={fileName}
                                previewData={previewData}
                                containerHeight={"60vh"}
                            />
                            <TextArea
                                className={"mt-1"}
                                label={"Σημειώσεις"}
                                value={tmpUserNotes}
                                onChange={(e) => setTMPUserNotes(e.target.value)}
                            />
                            {isEmron && (
                                <TextArea
                                    label={"Σημειώσεις λογιστή"}
                                    value={tmpAccountantNotes}
                                    onChange={(e) => setTMPAccountantNotes(e.target.value)}
                                />
                            )}
                            <div style={{textAlign: "right"}}>
                                <Button size={"sm"} onClick={() => onNotesSave(path, tmpUserNotes, tmpAccountantNotes)}>Αποθήκευση
                                    σημειώσεων</Button>
                            </div>
                            {documentHooks?.length > 0 && (
                                <div style={{borderTop: "1px solid lightgray"}} className={"mt-2"}>
                                    <div className={"text-muted"}><i>Συσχετιζόμενα παραστατικά</i></div>
                                    <Row style={{"--bs-gutter-x": "0px"}}>
                                        {documentHooks.map((doc, idx) => (
                                            <Col md={6} idx={`corr${idx}`} className={"pr-1 pl-1 pt-1"}>
                                                <Badge bg={"primary"} style={{width: "90%", cursor: "pointer", borderTopRightRadius: "0px", borderBottomRightRadius: "0px"}}
                                                       onClick={() => handleCorrelatedClick(doc._id, doc.section)}>{isEmpty(doc.label) ? "..." : doc.label}</Badge>
                                                <Badge bg={"danger"} style={{
                                                    width: "10%",
                                                    cursor: "pointer",
                                                    borderTopLeftRadius: "0px",
                                                    borderBottomLeftRadius: "0px",
                                                    maxHeight: "24px",
                                                    paddingTop: "5px"
                                                }} onClick={() => handleDecorrelateClick(doc._id, doc.section, doc.label)}><i className="fa-solid fa-trash"></i></Badge>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
            <Modal backdrop={"static"} show={decorModal} onHide={() => setDecorModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Αποσυσχέτιση εγγράφου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {decorModalData && (
                        <span>Είσαστε σίγουροι ότι θέλετε να αποσυσχετίσετε το παρόν έγγραφο ({decorModalData.label});</span>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => handleSaveDecorrelation()}>
                        Ναι, αποσυσχέτιση
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={emronMoveModal} onHide={() => setEmronMoveModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Μεταφορά αρχείου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        name={"optionTo"}
                        value={emronMoveOptionTo}
                        label={"Μεταφορά σε κατηγορία"}
                        disabled={true}
                    />
                    <OurDropdown
                        name={"monthTo"}
                        label={"Μεταφορά σε μήνα"}
                        options={greekMonthOptions}
                        key={Math.random()}
                        className={"mt-2"}
                        defaultValue={greekMonthOptions.find((el) => el.value === emronMoveMonthTo)}
                        onChange={(e) => setEmronMoveMonthTo(e.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        onEmronMove(path, emronMoveOptionTo, emronMoveMonthTo);
                        setEmronMoveModal(false);
                    }}>
                        Μεταφορά
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default FilePreviewer
