import React from "react";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import TableFooter from "./TableFooter";
import { toast } from "react-toastify";
import "./editableTable.scss";
import Pagination from "react-js-pagination";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import {
    getObjectFromArrayOfObjects,
} from "../../../_helpers/helperFunctions";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
    getCurrentUser, getGreekCurrencyFormat,
    getKeyByName,
    getSavedHiddenColumns, isEmpty,
} from "../../../_helpers/commonFunctions";
import * as XLSX from "xlsx";
import { liberationSerif } from "../../../fonts/liberationSerif";
import axios from "axios";
import { Col, Modal, Row } from "react-bootstrap";
import moment from "moment";
import {dejavuSans} from "../../../fonts/DejaVuSans-normal";
import {dejavuSansBold} from "../../../fonts/DejaVuSans-Bold-bold";
import DeleteModal from "../POPUP-COMPONENTS/Delete-Modal/deleteModal";
const apiLoginURL = process.env.REACT_APP_LOGIN_URL;
const errorAuth = "You are not Authorized!";

class EditableTable extends React.Component {
    constructor(props) {
        super(props);
        this.editableTableRef = React.createRef();
        this.iframeRef = React.createRef();

        this.state = {
            tableData: this.props.data,
            iframeData: "",
            tabCloseWarning: false,
            cancelTabCloseWarning: false,
            showResponseSentToMyData: false,
            showResponseCancelToMyData: false,
            responseSentToMyData: false,
            responseCancelToMyData: false,
            loadingSendSelected: false,
            reqName: this.props.reqName,
            pRequestData: this.props.pRequestData,
            tableName: this.props.tableName,
            filters: this.props.filters,
            filteredData: [],
            renderedData: [],
            loader: false,
            checkedRows: [],
            tableColumns: getSavedHiddenColumns(
                this.props.tableName,
                this.props.columns
            ),
            reportColumns: this.props.reportColumns,
            emronPaymentsMonth: this.props.emronPaymentsMonth,
            count: this.props.data ? this.props.totalItems : 0,
            activePage: 1,
            rowsPerPage: this.props.perPage,
            pageRange: 5,
            requestData: this.props.requestData,
            formData: this.props.formData,
            // For delete modal
            showDelete: false,
            toBeDeleted: "",
            allChecked: this.props.allChecked ?? false,
        };
    }

    componentDidMount = () => {
        // Action Buttons for the Table,
        // Default: Delete button is true
        let defaultButtons = {edit: false, print: false, export: false, delete: false};
        if (
            this.state.tableName !== "Product Balance Report" &&
            this.state.tableName !== "Sales Balance Sheet" &&
            this.state.tableName !== "Client Payments Report" &&
            this.state.tableName !== "Buys Balance Sheet" &&
            this.state.tableName !== "Supplier Payments Report" &&
            this.state.tableName !== "Product Movements Report" &&
            this.state.tableName !== "Client Balance Report" &&
            this.state.tableName !== "Supplier Balance Report"
        ) {
            defaultButtons.delete = true;
        }

        // This will replace the action buttons with props values.
        let actionButtons = defaultButtons;
        if (this.props.actionButtons) {
            actionButtons = this.props.actionButtons;
        }

        if (this.props.tableName === "Product Variants") {
            this.setState((state) => {
                return {checkedRows: this.props.selectedRows}
            });
        }

        // Selection Check box
        const selectionCheckboxColumn = {
            enabled: this.props.allowSelectionCheckbox
                ? this.props.allowSelectionCheckbox
                : false,
            field: "select_checkbox",
            name: "Select All",
            width: "40px",
            inputType: {
                type: "selection",
                select: (e, type, dataKey, allChecked = false) => {
                    switch (type) {
                        case "selectRow":
                            this.setState((state) => {
                                let selectedIndexes = state.checkedRows.slice();
                                const foundIndex = selectedIndexes?.indexOf(parseInt(dataKey));
                                if (foundIndex > -1) {
                                    selectedIndexes.splice(foundIndex, 1); // Remove from array
                                } else {
                                    selectedIndexes.push(parseInt(dataKey)); // Add to array
                                }
                                console.log("select row", dataKey, selectedIndexes);
                                return {
                                    checkedRows: selectedIndexes,
                                };
                            });

                            break;
                        case "selectAll":
                            this.setState(() => {
                                let selectedIndexes;
                                if (!allChecked) {
                                    selectedIndexes = [];
                                } else {
                                    selectedIndexes = [...Array(this.state.count).keys()];
                                }
                                console.log("select all rows.", selectedIndexes);
                                return {
                                    checkedRows: selectedIndexes,
                                };
                            });
                            break;
                        default:
                            console.log("nothing to do..");
                    }
                },
            },
        };
        // It will add the actions column in the Table
        const actionsColumns = {
            field: "actions",
            name: "",
            width: "80px",
            actionButtons: actionButtons,
            inputType: {
                type: "actions",
                callbackFn: (dataKey, type, rowIndex, rowData) => {
                    if (type === "delete") {
                        this.handleDelete(dataKey, rowIndex);
                    } else if (type === "edit") {
                        this.handleEdit(dataKey);
                    } else if (type === "select") {
                        this.handleSelect(rowIndex, rowData);
                    }
                },
            },
        }
        if (this.props.allowSelectionCheckbox && this.props.allowActions) {
            this.setState({tableColumns: [selectionCheckboxColumn, ...this.state.tableColumns, actionsColumns]});
        } else if (this.props.allowSelectionCheckbox) {
            this.setState({tableColumns: [selectionCheckboxColumn, ...this.state.tableColumns]});
        } else if (this.props.allowActions) {
            this.setState({tableColumns: [...this.state.tableColumns, actionsColumns]});
        }
        this.setState((state) => {
            if (state.tableData && state.tableData.length > 0) {
                return {
                    filteredData: state.tableData ? state.tableData.slice(0, state.rowsPerPage) : []
                }
            }
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({activePage: pageNumber, requestData: {...this.state.requestData, page: pageNumber}}, () => {
            if (this.props.tableName === "Client Communication History") {
                this.loadClientCommunicationHistory(this.state.requestData);
            }
            if (this.props.tableName === "Supplier Communication History") {
                this.loadSupplierCommunicationHistory(this.state.requestData);
            }
        })
    }

    loadClientCommunicationHistory = (data) => {
        axios
            .post(
                process.env.REACT_APP_API_URL2 + `/client/search-communication-history/`,
                JSON.stringify(data),
                { headers: { "Content-Type": "application/json" } }
            )
            .then((res) => {
                console.log(res.data)
                if (res.data.status === "401") {
                    localStorage.clear();
                    window.location.replace(`${apiLoginURL}?redirect=ERP&error=${errorAuth}`);
                }
                if (res.data && res.data.status === "200") {
                    this.setState({filteredData: res.data.data.data});
                    if (res.data.data && res.data.data.data.length === 0) {
                        toast.info("No records found.");
                    }
                } else {
                    toast.error(res.data.message);
                    this.setState({filteredData: []});
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    loadSupplierCommunicationHistory = (data) => {
        axios
            .post(
                process.env.REACT_APP_API_URL2 + `/supplier/search-communication-history/`,
                JSON.stringify(data),
                { headers: { "Content-Type": "application/json" } }
            )
            .then((res) => {
                console.log(res.data)
                if (res.data.status === "401") {
                    localStorage.clear();
                    window.location.replace(`${apiLoginURL}?redirect=ERP&error=${errorAuth}`);
                }
                if (res.data && res.data.status === "200") {
                    this.setState({filteredData: res.data.data.data});
                    if (res.data.data && res.data.data.data.length === 0) {
                        toast.info("No records found.");
                    }
                } else {
                    toast.error(res.data.message);
                    this.setState({filteredData: []});
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    // It will allow to edit the table data.
    handleEdit = (dataKey) => {
        // Quick Edit Case
        this.props.onUpdate &&
        this.props.onUpdate.update({quickEdit: "true"}, dataKey);
    };

    handleSelect = (rowIndex, rowData) => {
        this.props.onSelect(rowIndex, rowData);
    };

    // It will delete the selected row
    handleDelete = (id, rowIndex) => {
        const customDeletions = [
            "Search Products",
            "Search Product Movements",
            "Search Product Price List",
            "Product Category",
            "Sales Search",
            "Client Payments Search",
            "Sales Ways Of Sending Goods",
            "Sales Car Numbers",
            "Buys Search",
            "Supplier Payments Search",
            "Buys Ways Of Sending Goods",
            "Buys Car Numbers",
            "Client Search",
            "Client Movements Search",
            "Supplier Search",
            "Supplier Movements Search",
            "Cash Registers Search",
            "POS Search",
            "Bank Account Search",
            "Basic Types Search",
            "Advanced Types Search",
            "Employees Search",
            "Accounting Account Search",
            "Accounting Record Search",
            "Work Permits Search"
        ];
        if (customDeletions.includes(this.state.tableName)) {
            this.showDeleteModal(true);
            this.setState({toBeDeleted: id});
        } else {
            this.props.onUpdate && this.props.onUpdate.delete(id, rowIndex);
        }
    }

    showDeleteModal = (bool) => {
        if(bool === false) {
            this.setState({toBeDeleted: ""});
        }
        this.setState({showDelete: bool});
    }

    // It will insert a new Row to the Table Data.
    handleInsertNewRowToData = (e, data) => {
        this.props.onUpdate && this.props.onUpdate.insert(e, data);
        const {count} = this.state;
        if (data.value !== "" && data.field !== "") {
            this.setState({count: count + 1});
        }
    };

    // Update Row Data
    handleUpdateRowToData = (e, field, id, rowIndex) => {
        if ((this.props.tableName === "Sales Modal Products List" || this.props.tableName === "Buys Modal Products List") && field === "name") {
            this.props.onUpdate.update({name: e.row}, id, rowIndex);
            return;
        }

        let value;
        if (e?.target !== undefined) {
            value = e.target.value;
        } else if (e?.value !== undefined) {
            value = e.value;
        } else {
            value = e;
        }

        if (field !== "") {
            const newData = {
                [field]: value,
            };
            if (
                this.props.tableName === "Client Movements Clients" ||
                this.props.tableName === "Edit Client Movements Clients" ||
                this.props.tableName === "Supplier Movements Suppliers" ||
                this.props.tableName === "New Price List General"
            ) {
                this.props.onUpdate && this.props.onUpdate.update(newData, id, e);
            } else {
                this.props.onUpdate && this.props.onUpdate.update(newData, id, rowIndex, e);
            }
        }
    };
    handleIframeLoad = () => {
        // wait for the iframe content to load
        if (this.state.iframeData && this.iframeRef.current) {
            // access the iframe's window object
            const iframeWindow = this.iframeRef.current.contentWindow;
            // print the iframe content
            iframeWindow.print();
        }
    }
    handleExportToPdfReport = (ext = ".pdf", name = null, printer = false) => {
        this.setState({
            loader: true,
        });
        if (this.props.tableName === "Product Balance Report") {
            const tableColumns = this.state.tableColumns;
            const tableName = this.props.tableName;
            let requestData = {
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                columns: tableColumns,
                tableName: tableName,
                date_to: this.props.filters?.date_to ? this.props.filters?.date_to : "",
                product_inactive: this.props.filters.product_inactive,
                product_zero: this.props.filters.product_zero,
                installation: this.props.filters?.installation ? this.props.filters.installation.map(el => el.value) : [],
                productName: this.props.filters?.productName ? this.props.filters.productName.map(el => el.value) : [],
            };
            axios.post(process.env.REACT_APP_API_URL2 + `/report/export-report`, requestData, {
                headers: {"Content-Type": "application/json"}
            })
                .then((res) => {
                    let dR = [];
                    if (res.data && res.data.status === "200") {
                        if (res.data.data.length > 0) {
                            res.data.data.forEach((element, i) => {
                                let obj = element;
                                obj.no = i + 1;
                                obj.productName = element._id.productName;
                                dR.push(obj);
                            });
                        }
                    } else {
                        if (res.data && res.data.message) {
                            toast.error(res.data && res.data.message);
                        } else {
                            toast.error(res.data && res.data.error);
                        }
                    }
                    const doc = new jsPDF('landscape');
                    this.setState({loader: false});
                    let y = 20;
                    doc.addFileToVFS("arialuni.ttf", liberationSerif);
                    doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
                    doc.addFileToVFS("dejavusans.ttf", dejavuSans);
                    doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
                    doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
                    doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
                    doc.setFont("DejaVu Sans Bold");
                    doc.setFontSize(17);
                    const header = doc.splitTextToSize("Υπόλοιπο Ειδών", 200);
                    const headerString = header.join("\n"); // Join the array elements into a single string with line breaks
                    doc.text(headerString, doc.internal.pageSize.getWidth() / 2.5, 9, {alignment: "center"});
                    doc.setFont("DejaVu Sans Normal");
                    doc.setFontSize(11);
                    doc.text(`Ημερομηνία Εξαγωγής: ${moment().format("DD/MM/YYYY HH:mm:ss")}`, 15, 18, {alignment: "center"});
                    const imgData = 'assets/images/prrimer-logo.png';
                    const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
                    const imgY = 3; // adjust the position as needed
                    doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
                    doc.setFont("DejaVu Sans Normal");
                    const columns = ["Απόθεμα", "Κωδικός Προϊόντος", "Όνομα Προϊόντος", "Υπόλοιπο"];
                    let rows;
                    let totalBalance = 0;
                    if (dR.length > 0) {
                        rows = dR.map((movement) =>
                            {
                                totalBalance = totalBalance + Number(movement.balance);
                                return [
                                    movement.inventory.toLocaleString('de-DE', {minimumFractionDigits: 2, useGrouping: true, groupingSeparator: '.'}),
                                    movement.code,
                                    movement.productName,
                                    movement.balance.toLocaleString('de-DE', {minimumFractionDigits: 2, useGrouping: true, groupingSeparator: '.'}),
                                ]
                            }
                        );
                        rows.push([
                            "",
                            "Συνολικό Υπόλοιπο Ειδών",
                            "",
                            totalBalance.toLocaleString('de-DE', {minimumFractionDigits: 2, useGrouping: true, groupingSeparator: '.'})
                        ])
                    } else {
                        rows = [{no: "no record found"}];
                    }
                    doc.autoTable({
                        startY: y,
                        head: [columns],
                        body: rows,
                        theme: "striped",
                        rowPageBreak: "avoid",
                        styles: {
                            font: "DejaVu Sans Normal",
                            fontSize: 10, // set font size
                            halign: "center", // set alignment
                        },
                        didParseCell: (data) => {
                            // Check if the current row is a header row
                            if (data.row.raw === columns || data.row.index === rows.length - 1) {
                                data.cell.styles.fillColor = [241, 253, 255]; // set fill color for the header row
                                data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                                data.cell.styles.font = "DejaVu Sans Bold";
                            } else {
                                data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                            }
                        }
                    });
                    const pageCount = doc.getNumberOfPages();
                    // Add the pagination to each page
                    for (let i = 1; i <= pageCount; i++) {
                        doc.setPage(i);
                        doc.setFontSize(10);
                        doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
                    }
                    if (printer) {
                        this.setState({iframeData: `${doc.output('bloburl')}`});
                    } else {
                        doc.save("Product Balance Report.pdf");
                    }
                })
                .catch((error) => {
                    toast.error("Something went wrong. Please try again later!");
                    console.log(error);
                });
        } else if (this.props.tableName === "Sales Monthly Turnover") {
            const tableColumns = this.state.tableColumns;
            const tableName = this.props.tableName;
            let requestData = {
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                columns: tableColumns,
                tableName: tableName,
                date_from: this.props.filters?.date_from,
                date_to: this.props.filters?.date_to,
                clientName: this.props.filters?.clientName,
            }
            axios.post(process.env.REACT_APP_API_URL2 + `/report/export-report`, requestData, {
                headers: {"Content-Type": "application/json"}
            }).then((res) => {
                let dR = res.data.data;
                if (res.data && res.data.status === "200") {
                    const doc = new jsPDF('landscape');
                    this.setState({loader: false});
                    let y = 20;
                    doc.addFileToVFS("arialuni.ttf", liberationSerif);
                    doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
                    doc.addFileToVFS("dejavusans.ttf", dejavuSans);
                    doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
                    doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
                    doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
                    doc.setFont("DejaVu Sans Bold");
                    doc.setFontSize(17);
                    const header = doc.splitTextToSize("Τζίρος Πωλήσεων Ανά Περίοδο", 200);
                    const headerString = header.join("\n"); // Join the array elements into a single string with line breaks
                    doc.text(headerString, doc.internal.pageSize.getWidth() / 2.5, 9, {alignment: "center"});
                    doc.setFont("DejaVu Sans Normal");
                    doc.setFontSize(11);
                    doc.text(`Ημερομηνία Εξαγωγής: ${moment().format("DD/MM/YYYY HH:mm:ss")}`, 15, 18, {alignment: "center"});
                    const imgData = 'assets/images/prrimer-logo.png';
                    const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
                    const imgY = 3; // adjust the position as needed
                    doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
                    doc.setFont("DejaVu Sans Normal");
                    let columns = [];
                    tableColumns.forEach(column => {
                        if (column.field !== "actions" && column.field !== "no" && !column.hiddenColumn) {
                            columns.push(column);
                        }
                    })
                    let columnNames = columns.map(column => {
                        return column?.shortcut;
                    })
                    let rows;
                    let totalPaymentAmount = 0;
                    if (dR.length > 0) {
                        rows = dR.map((data) => {
                            totalPaymentAmount += data.cTotal;
                            data = Object.values(data).map(el => !isNaN(el) ? getGreekCurrencyFormat(el) : el);
                            return data;
                        });
                        rows.push(["Γενικό Σύνολο", "", "", "", "", "", "", "", "", "", "", "", "", getGreekCurrencyFormat(totalPaymentAmount)])
                    } else {
                        rows = [{no: "no record found"}];
                    }
                    doc.autoTable({
                        startY: y,
                        head: [columnNames],
                        body: rows,
                        theme: "striped",
                        rowPageBreak: "avoid",
                        styles: {
                            font: "DejaVu Sans Normal",
                            fontSize: 10, // set font size
                            halign: "center", // set alignment
                        },
                        didParseCell: (data) => {
                            // Check if the current row is a header row
                            if (data.row.raw === columnNames || data.row.index === rows.length - 1) {
                                data.cell.styles.fillColor = [241, 253, 255]; // set fill color for the header row
                                data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                                data.cell.styles.font = "DejaVu Sans Bold";
                            } else {
                                data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                            }
                        }
                    });
                    const pageCount = doc.getNumberOfPages();
                    // Add the pagination to each page
                    for (let i = 1; i <= pageCount; i++) {
                        doc.setPage(i);
                        doc.setFontSize(10);
                        doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
                    }
                    if (printer) {
                        this.setState({iframeData: `${doc.output('bloburl')}`});
                    } else {
                        doc.save("Αναφορά Τζίρου Πωλήσεων.pdf");
                    }
                } else if (res.data && res.data.status === "422") {
                    toast.error("Something went wrong. Please try again later!");
                }
            }).catch((error) => {
                console.log(error);
                toast.error("Something went wrong. Please try again later!");
            })
        } else if (this.props.tableName === "Buys Monthly Turnover") {
            const tableColumns = this.state.tableColumns;
            const tableName = this.props.tableName;
            let requestData = {
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                columns: tableColumns,
                tableName: tableName,
                date_from: this.props.filters?.date_from,
                date_to: this.props.filters?.date_to,
                supplierName: this.props.filters?.supplierName,
            }
            axios.post(process.env.REACT_APP_API_URL2 + `/report/export-report`, requestData, {
                headers: {"Content-Type": "application/json"}
            }).then((res) => {
                let dR = res.data.data;
                if (res.data && res.data.status === "200") {
                    const doc = new jsPDF('landscape');
                    this.setState({loader: false});
                    let y = 20;
                    doc.addFileToVFS("arialuni.ttf", liberationSerif);
                    doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
                    doc.addFileToVFS("dejavusans.ttf", dejavuSans);
                    doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
                    doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
                    doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
                    doc.setFont("DejaVu Sans Bold");
                    doc.setFontSize(17);
                    const header = doc.splitTextToSize("Τζίρος Αγορών Ανά Περίοδο", 200);
                    const headerString = header.join("\n"); // Join the array elements into a single string with line breaks
                    doc.text(headerString, doc.internal.pageSize.getWidth() / 2.5, 9, {alignment: "center"});
                    doc.setFont("DejaVu Sans Normal");
                    doc.setFontSize(11);
                    doc.text(`Ημερομηνία Εξαγωγής: ${moment().format("DD/MM/YYYY HH:mm:ss")}`, 15, 18, {alignment: "center"});
                    const imgData = 'assets/images/prrimer-logo.png';
                    const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
                    const imgY = 3; // adjust the position as needed
                    doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
                    doc.setFont("DejaVu Sans Normal");
                    let columns = [];
                    tableColumns.forEach(column => {
                        if (column.field !== "actions" && column.field !== "no" && !column.hiddenColumn) {
                            columns.push(column);
                        }
                    })
                    let columnNames = columns.map(column => {
                        return column?.shortcut;
                    })
                    let rows;
                    let totalPaymentAmount = 0;
                    if (dR.length > 0) {
                        rows = dR.map((data) => {
                            totalPaymentAmount += data.sTotal;
                            data = Object.values(data).map(el => !isNaN(el) ? getGreekCurrencyFormat(el) : el);
                            return data;
                        });
                        rows.push(["Γενικό Σύνολο", "", "", "", "", "", "", "", "", "", "", "", "", getGreekCurrencyFormat(totalPaymentAmount)])
                    } else {
                        rows = [{no: "no record found"}];
                    }
                    doc.autoTable({
                        startY: y,
                        head: [columnNames],
                        body: rows,
                        theme: "striped",
                        rowPageBreak: "avoid",
                        styles: {
                            font: "DejaVu Sans Normal",
                            fontSize: 10, // set font size
                            halign: "center", // set alignment
                        },
                        didParseCell: (data) => {
                            // Check if the current row is a header row
                            if (data.row.raw === columnNames || data.row.index === rows.length - 1) {
                                data.cell.styles.fillColor = [241, 253, 255]; // set fill color for the header row
                                data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                                data.cell.styles.font = "DejaVu Sans Bold";
                            } else {
                                data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                            }
                        }
                    });
                    const pageCount = doc.getNumberOfPages();
                    // Add the pagination to each page
                    for (let i = 1; i <= pageCount; i++) {
                        doc.setPage(i);
                        doc.setFontSize(10);
                        doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
                    }
                    if (printer) {
                        this.setState({iframeData: `${doc.output('bloburl')}`});
                    } else {
                        doc.save("Αναφορά Τζίρου Αγορών.pdf");
                    }
                } else if (res.data && res.data.status === "422") {
                    toast.error("Something went wrong. Please try again later!");
                }
            }).catch((error) => {
                console.log(error);
                toast.error("Something went wrong. Please try again later!");
            })
        } else if (this.props.tableName === "Sales Balance Sheet") {
            const tableColumns = this.state.tableColumns;
            const tableName = this.props.tableName;
            let requestData = {
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                columns: tableColumns,
                tableName: tableName,
                date_from: this.props.filters?.date_from,
                date_to: this.props.filters?.date_to,
                clientName: this.props.filters?.clientName,
                productName: this.props.filters?.productName,
                totalSum_from: this.props.filters?.totalSum_from,
                totalSum_to: this.props.filters?.totalSum_to,
                preNumber_from: this.props.filters?.preNumber_from,
                preNumber_to: this.props.filters?.preNumber_to,
                numberFrom: this.props.filters?.numberFrom,
                numberTo: this.props.filters?.numberTo,
                myDataStatus: this.props.filters?.myDataStatus,
                paymentStatus: this.props.filters?.paymentStatus,
                notes: this.props.filters?.notes,
                invoiceType: this.props.filters?.invoiceType.map(el => el.value),
                paymentType: this.props.filters?.paymentType.map(el => el.value),
                automaticSales: this.props.filters?.automaticSales,
                carNumber: this.props.filters?.carNumber.map(el => el.value),
                providerChannel: this.props.filters?.providerChannel.map(el => el.value),
            };
            axios.post(process.env.REACT_APP_API_URL2 + `/report/export-report`, requestData, {
                headers: {"Content-Type": "application/json"}
            })
                .then((res) => {
                    let dR = [];
                    if (res.data && res.data.status === "200") {
                        if (res.data.data.length > 0) {
                            res.data.data.forEach((element) => {
                                let obj = element;
                                obj.number = obj.number.replace("noprefix", "");
                                dR.push(obj);
                            });
                        }
                        const doc = new jsPDF('landscape');
                        this.setState({loader: false});
                        let y = 20;
                        doc.addFileToVFS("arialuni.ttf", liberationSerif);
                        doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
                        doc.addFileToVFS("dejavusans.ttf", dejavuSans);
                        doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
                        doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
                        doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
                        doc.setFont("DejaVu Sans Bold");
                        doc.setFontSize(17);
                        const header = doc.splitTextToSize("Ανάλυση Πωλήσεων", 200);
                        const headerString = header.join("\n"); // Join the array elements into a single string with line breaks
                        doc.text(headerString, doc.internal.pageSize.getWidth() / 2.5, 9, {alignment: "center"});
                        doc.setFont("DejaVu Sans Normal");
                        doc.setFontSize(11);
                        doc.text(`Ημερομηνία Εξαγωγής: ${moment().format("DD/MM/YYYY HH:mm:ss")}`, 15, 18, {alignment: "center"});
                        const imgData = 'assets/images/prrimer-logo.png';
                        const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
                        const imgY = 3; // adjust the position as needed
                        doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
                        doc.setFont("DejaVu Sans Normal");
                        const columns = tableColumns.map((column) => {
                            if (column.field !== "actions" && column.field !== "no" && !column.hiddenColumn) {
                                return column;
                            }
                        })
                        let columnNames = columns.map(column => {
                            return column?.name;
                        })
                        columnNames = columnNames.filter((element) => element !== undefined);
                        let rows;
                        let totalNetValue = 0;
                        let totalVatValue = 0;
                        let totalValue = 0;
                        let totalPaymentAmount = 0;
                        if (dR.length > 0) {
                            rows = dR.map((movement) => {
                                totalNetValue = totalNetValue + movement.totalNetValue;
                                totalVatValue = totalVatValue + movement.totalVatValue;
                                totalValue = totalValue + movement.totalAmount;
                                totalPaymentAmount = totalPaymentAmount + movement.paymentAmount;
                                let row = [
                                    moment(movement.date).format("DD/MM/YYYY"),
                                    columns.find((element) => element?.field === "clientName") ? movement.clientName : undefined,
                                    columns.find((element) => element?.field === "vat") ? movement.vat : undefined,
                                    columns.find((element) => element?.field === "phone") ? movement.phone : undefined,
                                    columns.find((element) => element?.field === "documentTypeName") ? movement.documentTypeName : undefined,
                                    columns.find((element) => element?.field === "number") ? movement.number : undefined,
                                    columns.find((element) => element?.field === "totalNetValue") ? getGreekCurrencyFormat(movement.totalNetValue) : undefined,
                                    columns.find((element) => element?.field === "vatAmount") ? getGreekCurrencyFormat(movement.totalVatValue) : undefined,
                                    columns.find((element) => element?.field === "totalAmount") ? getGreekCurrencyFormat(movement.totalAmount) : undefined,
                                    columns.find((element) => element?.field === "paymentTypeName") ? movement.paymentTypeName : undefined,
                                    columns.find((element) => element?.field === "vehNumber") ? movement.vehNumber : undefined,
                                    columns.find((element) => element?.field === "providerChannel") ? movement.providerChannel : undefined,
                                    columns.find((element) => element?.field === "productNamesBalanceSheet") ? movement.productNamesBalanceSheet : undefined,
                                    columns.find((element) => element?.field === "paymentAmount") ? getGreekCurrencyFormat(movement.paymentAmount) : undefined,
                                ]
                                return row.filter((item) => item !== undefined);
                            });
                            let row = [
                                "",
                                columns.find((element) => element?.field === "clientName") ? "Συνολικά Ποσά" : undefined,
                                columns.find((element) => element?.field === "vat") ? "" : undefined,
                                columns.find((element) => element?.field === "phone") ? "" : undefined,
                                columns.find((element) => element?.field === "documentTypeName") ? "" : undefined,
                                columns.find((element) => element?.field === "number") ? "" : undefined,
                                columns.find((element) => element?.field === "totalNetValue") ? getGreekCurrencyFormat(totalNetValue) : undefined,
                                columns.find((element) => element?.field === "vatAmount") ? getGreekCurrencyFormat(totalVatValue) : undefined,
                                columns.find((element) => element?.field === "totalAmount") ? getGreekCurrencyFormat(totalValue) : undefined,
                                columns.find((element) => element?.field === "paymentTypeName") ? "" : undefined,
                                columns.find((element) => element?.field === "vehNumber") ? "" : undefined,
                                columns.find((element) => element?.field === "providerChannel") ? "" : undefined,
                                columns.find((element) => element?.field === "productNamesBalanceSheet") ? "" : undefined,
                                columns.find((element) => element?.field === "paymentAmount") ? getGreekCurrencyFormat(totalPaymentAmount) : undefined,
                            ]
                            rows.push(row.filter((item) => item !== undefined));
                        } else {
                            rows = [{no: "no record found"}];
                        }
                        doc.autoTable({
                            startY: y,
                            head: [columnNames],
                            body: rows,
                            theme: "striped",
                            rowPageBreak: "avoid",
                            styles: {
                                font: "DejaVu Sans Normal",
                                fontSize: 10, // set font size
                                halign: "center", // set alignment
                            },
                            didParseCell: (data) => {
                                // Check if the current row is a header row
                                if (data.row.raw === columnNames || data.row.index === rows.length - 1) {
                                    data.cell.styles.fillColor = [241, 253, 255]; // set fill color for the header row
                                    data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                                    data.cell.styles.font = "DejaVu Sans Bold";
                                } else {
                                    data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                                }
                            }
                        });
                        const pageCount = doc.getNumberOfPages();
                        // Add the pagination to each page
                        for (let i = 1; i <= pageCount; i++) {
                            doc.setPage(i);
                            doc.setFontSize(10);
                            doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
                        }
                        if (printer) {
                            this.setState({iframeData: `${doc.output('bloburl')}`});
                        } else {
                            doc.save("Sales Balance Report.pdf");
                        }
                    } else if (res.data && res.data.status === "422") {
                        toast.error("Something went wrong. Please try again later!");
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast.error("Something went wrong. Please try again later!");
                });
        } else if (this.props.tableName === "Buys Balance Sheet") {
            const tableColumns = this.state.tableColumns;
            const tableName = this.props.tableName;
            let requestData = {
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                columns: tableColumns,
                tableName: tableName,
                date_from: this.props.filters?.date_from,
                date_to: this.props.filters?.date_to,
                month: this.props.filters?.month ? this.props.filters?.month : "",
                supplierName: this.props.filters?.supplierName,
                invoiceType: this.props.filters?.invoiceType.map(el => el.value),
                paymentType: this.props.filters?.paymentType.map(el => el.value),
                carNumber: this.props.filters?.carNumber.map(el => el.value),
                numberFrom: this.props.filters?.numberFrom,
                numberTo: this.props.filters?.numberTo,
                preNumberFrom: this.props.filters?.preNumberFrom,
                preNumberTo: this.props.filters?.preNumberTo,
                amountFrom: this.props.filters?.amountFrom,
                amountTo: this.props.filters?.amountTo,
                notes: this.props.filters?.notes,
            }
            axios.post(process.env.REACT_APP_API_URL2 + `/report/export-report`, requestData, {
                headers: {"Content-Type": "application/json"}
            }).then((res) => {
                let dR = [];
                if (res.data && res.data.status === "200") {
                    if (res.data.data.length > 0) {
                        res.data.data.forEach((element) => {
                            let obj = element;
                            obj.number = obj.number.replace("noprefix", "");
                            dR.push(obj);
                        });
                    }
                    const doc = new jsPDF('landscape');
                    this.setState({loader: false});
                    let y = 20;
                    doc.addFileToVFS("arialuni.ttf", liberationSerif);
                    doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
                    doc.addFileToVFS("dejavusans.ttf", dejavuSans);
                    doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
                    doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
                    doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
                    doc.setFont("DejaVu Sans Bold");
                    doc.setFontSize(17);
                    const header = doc.splitTextToSize("Ανάλυση Αγορών", 200);
                    const headerString = header.join("\n"); // Join the array elements into a single string with line breaks
                    doc.text(headerString, doc.internal.pageSize.getWidth() / 2.5, 9, {alignment: "center"});
                    doc.setFont("DejaVu Sans Normal");
                    doc.setFontSize(11);
                    doc.text(`Ημερομηνία Εξαγωγής: ${moment().format("DD/MM/YYYY HH:mm:ss")}`, 15, 18, {alignment: "center"});
                    const imgData = 'assets/images/prrimer-logo.png';
                    const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
                    const imgY = 3; // adjust the position as needed
                    doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
                    doc.setFont("DejaVu Sans Normal");
                    const columns = tableColumns.map(column => {
                        if (column.field !== "actions" && column.field !== "no" && !column.hiddenColumn) {
                            return column;
                        }
                    })
                    let columnNames = columns.map(column => {
                        return column?.name;
                    })
                    columnNames = ["Ημερομηνία", "Προμηθευτής", "ΑΦΜ Προμηθευτή", "Τύπος Παραστατικού", "Αρίθμηση", "Καθαρή Εκπ. Αξία", "Μη Καθαρή Εκπ. Αξία", "Αξία Εκπ. Φ.Π.Α.", "Αξία Μη Εκπ. Φ.Π.Α.", "Συνολική Αξία"]
                    let rows;
                    let totalDeductibleNetValue = 0;
                    let totalNonDeductibleNetValue = 0;
                    let totalDeductibleVAT = 0;
                    let totalNonDeductibleVAT = 0;
                    let totalValue = 0;
                    if (dR.length > 0) {
                        rows = dR.map((movement) => {
                            totalDeductibleNetValue += movement.totalDeductibleNetValue;
                            totalNonDeductibleNetValue += movement.totalNonDeductibleNetValue;
                            totalDeductibleVAT += movement.totalDeductibleVAT;
                            totalNonDeductibleVAT += movement.totalNonDeductibleVAT;
                            totalValue = totalValue + movement.totalAmount;
                            let row = [
                                moment(movement.date).format("DD/MM/YYYY"),
                                columns.find((element) => element?.field === "supplierName") ? movement.supplierName : "",
                                columns.find((element) => element?.field === "vat") ? movement.vat : "",
                                columns.find((element) => element?.field === "documentTypeName") ? movement.documentTypeName : "",
                                columns.find((element) => element?.field === "number") ? movement.number : "",
                                columns.find((element) => element?.field === "totalDeductibleNetValue") ? getGreekCurrencyFormat(movement.totalDeductibleNetValue) : "",
                                columns.find((element) => element?.field === "totalNonDeductibleNetValue") ? getGreekCurrencyFormat(movement.totalNonDeductibleNetValue) : "",
                                columns.find((element) => element?.field === "totalDeductibleVAT") ? getGreekCurrencyFormat(movement.totalDeductibleVAT) : "",
                                columns.find((element) => element?.field === "totalNonDeductibleVAT") ? getGreekCurrencyFormat(movement.totalNonDeductibleVAT) : "",
                                columns.find((element) => element?.field === "totalAmount") ? getGreekCurrencyFormat(movement.totalAmount) : "",
                                columns.find((element) => element?.field === "paymentTypeName") ? movement.paymentTypeName : "",
                                columns.find((element) => element?.field === "vehNumber") ? movement.vehNumber : "",
                            ]
                            return row.filter((item) => item !== undefined);
                        });
                        rows.push([
                            "",
                            columns.find((element) => element?.field === "supplierName") ? "Συνολικά Ποσά" : "",
                            columns.find((element) => element?.field === "vat") ? "" : "",
                            columns.find((element) => element?.field === "documentTypeName") ? "" : "",
                            columns.find((element) => element?.field === "number") ? "" : "",
                            columns.find((element) => element?.field === "totalDeductibleNetValue") ? getGreekCurrencyFormat(totalDeductibleNetValue) : "",
                            columns.find((element) => element?.field === "totalNonDeductibleNetValue") ? getGreekCurrencyFormat(totalNonDeductibleNetValue) : "",
                            columns.find((element) => element?.field === "totalDeductibleVAT") ? getGreekCurrencyFormat(totalDeductibleVAT) : "",
                            columns.find((element) => element?.field === "totalNonDeductibleVAT") ? getGreekCurrencyFormat(totalNonDeductibleVAT) : "",
                            columns.find((element) => element?.field === "totalAmount") ? getGreekCurrencyFormat(totalValue) : "",
                            columns.find((element) => element?.field === "paymentTypeName") ? "" : "",
                            columns.find((element) => element?.field === "vehNumber") ? "" : "",
                        ])
                    } else {
                        rows = [{no: "no record found"}];
                    }
                    doc.autoTable({
                        startY: y,
                        head: [columnNames],
                        body: rows,
                        theme: "striped",
                        rowPageBreak: "avoid",
                        styles: {
                            font: "DejaVu Sans Normal",
                            fontSize: 10, // set font size
                            halign: "center", // set alignment
                        },
                        didParseCell: (data) => {
                            // Check if the current row is a header row
                            if (data.row.raw === columnNames || data.row.index === rows.length - 1) {
                                data.cell.styles.fillColor = [241, 253, 255]; // set fill color for the header row
                                data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                                data.cell.styles.font = "DejaVu Sans Bold";
                            } else {
                                data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                            }
                        }
                    });
                    const pageCount = doc.getNumberOfPages();
                    // Add the pagination to each page
                    for (let i = 1; i <= pageCount; i++) {
                        doc.setPage(i);
                        doc.setFontSize(10);
                        doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
                    }
                    if (printer) {
                        this.setState({iframeData: `${doc.output('bloburl')}`});
                    } else {
                        doc.save("Buys Balance Report.pdf");
                    }
                } else if (res.data && res.data.status === "422") {
                    toast.error("Something went wrong. Please try again later!");
                }
            }).catch((error) => {
                console.log(error);
                toast.error("Something went wrong. Please try again later!");
            })
        } else if (this.props.tableName === "Client Payments Report") {
            const tableColumns = this.state.tableColumns;
            const tableName = this.props.tableName;
            let requestData = {
                company: this.props.company.id,
                year: String(this.props.company.year),
                columns: tableColumns,
                tableName: tableName,
                date_from: this.props.filters?.date_from ? this.props.filters?.date_from : "",
                date_to: this.props.filters?.date_to ? this.props.filters?.date_to : "",
                clientName: this.props.filters?.clientName ? this.props.filters?.clientName : "",
                invoiceType: this.props.filters?.invoiceType ? this.props.filters?.invoiceType.map(el => el.value) : [],
                paymentType: this.props.filters?.paymentType ? this.props.filters?.paymentType.map(el => el.value) : [],
                numberFrom: this.props.filters?.numberFrom ?? "",
                numberTo: this.props.filters?.numberTo ?? "",
            }
            axios.post(process.env.REACT_APP_API_URL2 + `/report/export-report`, requestData, {
                headers: {"Content-Type": "application/json"}
            })
                .then((res) => {
                    let dR = [];
                    if (res.data && res.data.status === "200") {
                        if (res.data.data.length > 0) {
                            if (res.data.data.length > 0) {
                                res.data.data.forEach((element, j) => {
                                    element.no = j + 1;
                                    element.number = element.number.replace("noprefix", "");
                                    dR.push(element);
                                });
                            }
                        }
                        const doc = new jsPDF('landscape');
                        this.setState({loader: false});
                        let y = 20;
                        doc.addFileToVFS("arialuni.ttf", liberationSerif);
                        doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
                        doc.addFileToVFS("dejavusans.ttf", dejavuSans);
                        doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
                        doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
                        doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
                        doc.setFont("DejaVu Sans Bold");
                        doc.setFontSize(17);
                        const header = doc.splitTextToSize("Ανάλυση Εισπράξεων", 200);
                        const headerString = header.join("\n"); // Join the array elements into a single string with line breaks
                        doc.text(headerString, doc.internal.pageSize.getWidth() / 2.5, 9, {alignment: "center"});
                        doc.setFont("DejaVu Sans Normal");
                        doc.setFontSize(11);
                        doc.text(`Ημερομηνία Εξαγωγής: ${moment().format("DD/MM/YYYY HH:mm:ss")}`, 15, 18, {alignment: "center"});
                        const imgData = 'assets/images/prrimer-logo.png';
                        const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
                        const imgY = 3; // adjust the position as needed
                        doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
                        doc.setFont("DejaVu Sans Normal");
                        let columnNames = [
                            "Αρ.",
                            "Ημ/νια",
                            "Όνομα Πελάτη",
                            "ΑΦΜ Πελάτη",
                            "Τύπος Παραστατικού",
                            "Αρίθμηση",
                            "Ποσό",
                            "Τρόπος Πληρωμής"
                        ]
                        let rows = [];
                        if (dR.length > 0) {
                            let totalPaymentAmount = 0;
                            for (let movement of dR) {
                                rows.push([
                                    movement.no,
                                    moment(movement.date).format("DD/MM/YYYY"),
                                    movement.clientName,
                                    movement.vatNumber,
                                    movement.documentTypeName,
                                    movement.preNumber === "noprefix" ? movement.number : movement.preNumber + " " + movement.number,
                                    getGreekCurrencyFormat(movement.amount),
                                    movement.paymentTypeName
                                ])
                                totalPaymentAmount += movement.amount;
                            }
                            rows.push([
                                "",
                                "",
                                "Συνολικά Ποσά",
                                "",
                                "",
                                "",
                                getGreekCurrencyFormat(totalPaymentAmount),
                                ""
                            ])
                        } else {
                            rows = [{no: "no record found"}];
                        }
                        doc.autoTable({
                            startY: y,
                            head: [columnNames],
                            body: rows,
                            theme: "striped",
                            rowPageBreak: "avoid",
                            styles: {
                                font: "DejaVu Sans Normal",
                                fontSize: 10, // set font size
                                halign: "center", // set alignment
                            },
                            didParseCell: (data) => {
                                // Check if the current row is a header row
                                if (data.row.raw === columnNames || data.row.index === rows.length - 1) {
                                    data.cell.styles.fillColor = [241, 253, 255]; // set fill color for the header row
                                    data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                                    data.cell.styles.font = "DejaVu Sans Bold";
                                } else {
                                    data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                                }
                            }
                        });
                        const pageCount = doc.getNumberOfPages();
                        // Add the pagination to each page
                        for (let i = 1; i <= pageCount; i++) {
                            doc.setPage(i);
                            doc.setFontSize(10);
                            doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
                        }
                        if (printer) {
                            this.setState({iframeData: `${doc.output('bloburl')}`});
                        } else {
                            doc.save("Client Payment Report.pdf");
                        }
                    } else if (res.data && res.data.status === "422") {
                        toast.error("Something went wrong. Please try again later!");
                    }
                }).catch((error) => {
                console.log(error);
                toast.error("Something went wrong. Please try again later!");
            });
        } else if (this.props.tableName === "Supplier Payments Report") {
            const tableColumns = this.state.tableColumns;
            const tableName = this.props.tableName;
            let requestData = {
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                columns: tableColumns,
                tableName: tableName,
                date_from: this.props.filters?.date_from ? this.props.filters?.date_from : "",
                date_to: this.props.filters?.date_to ? this.props.filters?.date_to : "",
                supplierName: this.props.filters?.supplierName ? this.props.filters?.supplierName : "",
                invoiceType: this.props.filters?.invoiceType ? this.props.filters?.invoiceType.map(el => el.value) : [],
                paymentType: this.props.filters?.paymentType ? this.props.filters?.paymentType.map(el => el.value) : [],
                numberFrom: this.props.filters?.numberFrom ?? "",
                numberTo: this.props.filters?.numberTo ?? "",
                amountFrom: this.props.filters?.amountFrom ?? "",
                amountTo: this.props.filters?.amountTo ?? "",
            }
            axios.post(process.env.REACT_APP_API_URL2 + `/report/export-report`, requestData, {
                headers: {"Content-Type": "application/json"}
            }).then((res) => {
                let dR = [];
                if (res.data && res.data.status === "200") {
                    if (res.data.data.length > 0) {
                        if (res.data.data.length > 0) {
                            res.data.data.forEach((element, j) => {
                                element.no = j + 1;
                                element.number = element.number.replace("noprefix", "");
                                dR.push(element);
                            });
                        }
                    }
                    const doc = new jsPDF('landscape');
                    this.setState({loader: false});
                    let y = 20;
                    doc.addFileToVFS("arialuni.ttf", liberationSerif);
                    doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
                    doc.addFileToVFS("dejavusans.ttf", dejavuSans);
                    doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
                    doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
                    doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
                    doc.setFont("DejaVu Sans Bold");
                    doc.setFontSize(17);
                    const header = doc.splitTextToSize("Ανάλυση Πληρωμών", 200);
                    const headerString = header.join("\n");
                    doc.text(headerString, doc.internal.pageSize.getWidth() / 2.5, 9, {alignment: "center"});
                    doc.setFont("DejaVu Sans Normal");
                    doc.setFontSize(11);
                    doc.text(`Ημερομηνία Εξαγωγής: ${moment().format("DD/MM/YYYY HH:mm:ss")}`, 15, 18, {alignment: "center"});
                    const imgData = 'assets/images/prrimer-logo.png';
                    const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
                    const imgY = 3; // adjust the position as needed
                    doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
                    doc.setFont("DejaVu Sans Normal");
                    let columnNames = [
                        "Αρ.",
                        "Ημ/νια",
                        "Όνομα Προμηθευτή",
                        "ΑΦΜ Προμηθευτή",
                        "Τύπος Παραστατικού",
                        "Αρίθμηση",
                        "Ποσό",
                        "Τρόπος Πληρωμής"
                    ]
                    let rows = [];
                    let totalPaymentAmount = 0;
                    if (dR.length > 0) {
                        for (let movement of dR) {
                            rows.push([
                                movement.no,
                                moment(movement.date).format("DD/MM/YYYY"),
                                movement.supplierName,
                                movement.vatNumber,
                                movement.documentTypeName,
                                movement.preNumber === "noprefix" ? movement.number : movement.preNumber + " " + movement.number,
                                getGreekCurrencyFormat(movement.amount),
                                movement.paymentTypeName
                            ])
                            totalPaymentAmount += movement.amount;
                        }
                        rows.push([
                            "",
                            "",
                            "Συνολικά Ποσά",
                            "",
                            "",
                            "",
                            getGreekCurrencyFormat(totalPaymentAmount),
                            ""
                        ])
                    } else {
                        rows = [{no: "no record found"}];
                    }
                    doc.autoTable({
                        startY: y,
                        head: [columnNames],
                        body: rows,
                        theme: "striped",
                        rowPageBreak: "avoid",
                        styles: {
                            font: "DejaVu Sans Normal",
                            fontSize: 10, // set font size
                            halign: "center", // set alignment
                        },
                        didParseCell: (data) => {
                            // Check if the current row is a header row
                            if (data.row.raw === columnNames || data.row.index === rows.length - 1) {
                                data.cell.styles.fillColor = [241, 253, 255]; // set fill color for the header row
                                data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                                data.cell.styles.font = "DejaVu Sans Bold";
                            } else {
                                data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                            }
                        }
                    });
                    const pageCount = doc.getNumberOfPages();
                    // Add the pagination to each page
                    for (let i = 1; i <= pageCount; i++) {
                        doc.setPage(i);
                        doc.setFontSize(10);
                        doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
                    }
                    if (printer) {
                        this.setState({iframeData: `${doc.output('bloburl')}`});
                    } else {
                        doc.save("Supplier Payment Report.pdf");
                    }
                } else if (res.data && res.data.status === "422") {
                    toast.error("Something went wrong. Please try again later!");
                }
            }).catch((error) => {
                console.log(error);
                toast.error("Something went wrong. Please try again later!");
            });
        } else if (this.props.tableName === "Client Balance Report") {
            const tableColumns = this.state.tableColumns;
            const tableName = this.props.tableName;
            let requestData = {
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                columns: tableColumns,
                tableName: tableName,
                date_to: this.props.filters?.date_to ? this.props.filters?.date_to : "",
                clientName: this.props.filters?.clientName ? this.props.filters?.clientName : "",
                showZeroBalance: this.props.filters?.showZeroBalance,
                showInActive: this.props.filters?.showInActive,
                includeSettlements: this.props.filters?.includeSettlements,
            }
            axios.post(process.env.REACT_APP_API_URL2 + `/report/export-report`, JSON.stringify(requestData), {
                headers: {"Content-Type": "application/json"}
            })
                .then((res) => {
                    let dR = [];
                    if (res.data && res.data.status === "200") {
                        if (res.data.data.length > 0) {
                            res.data.data.forEach((element, i) => {
                                let obj = element;
                                obj.no = i + 1;
                                dR.push(obj);
                            });
                        }
                    } else {
                        if (res.data && res.data.message) {
                            toast.error(res.data && res.data.message);
                        } else {
                            toast.error(res.data && res.data.error);
                        }
                    }
                    const doc = new jsPDF('landscape');
                    this.setState({loader: false});
                    let y = 20;
                    doc.addFileToVFS("arialuni.ttf", liberationSerif);
                    doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
                    doc.addFileToVFS("dejavusans.ttf", dejavuSans);
                    doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
                    doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
                    doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
                    doc.setFont("DejaVu Sans Bold");
                    doc.setFontSize(17);
                    const header = doc.splitTextToSize("Υπόλοιπο Πελατών", 200);
                    const headerString = header.join("\n"); // Join the array elements into a single string with line breaks
                    doc.text(headerString, doc.internal.pageSize.getWidth() / 2.5, 9, {alignment: "center"});
                    doc.setFont("DejaVu Sans Normal");
                    doc.setFontSize(11);
                    doc.text(`Ημερομηνία Εξαγωγής: ${moment().format("DD/MM/YYYY HH:mm:ss")}`, 15, 18, {alignment: "center"});
                    const imgData = 'assets/images/prrimer-logo.png';
                    const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
                    const imgY = 3; // adjust the position as needed
                    doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
                    doc.setFont("DejaVu Sans Normal");
                    let columns = [];
                    if (res.data?.includeSettlements === "active") {
                        columns = ["Απογραφή", "Όνομα Πελάτη", "Α.Φ.Μ. Πελάτη", "Υπόλοιπο", "Διακανονισμένο ποσό", "Υπόλοιπο μετά διακανονισμών"];
                    } else {
                        columns = ["Απογραφή", "Όνομα Πελάτη", "Α.Φ.Μ. Πελάτη", "Υπόλοιπο"];
                    }
                    let rows;
                    let totalBalance = 0;
                    let totalSettledAmount = 0;
                    let totalBalanceWithSettledAmount = 0;
                    if (dR.length > 0) {
                        rows = dR.map((movement) =>
                            {
                                totalBalance += movement.balance;
                                if (res.data?.includeSettlements === "active") {
                                    totalSettledAmount += movement.totalSettledAmount;
                                    totalBalanceWithSettledAmount += movement.balanceWithSettledAmount;
                                    return [
                                        getGreekCurrencyFormat(movement.census),
                                        movement.clientName,
                                        movement.vatNumber,
                                        getGreekCurrencyFormat(movement.balance),
                                        getGreekCurrencyFormat(movement.totalSettledAmount),
                                        getGreekCurrencyFormat(movement.balanceWithSettledAmount),
                                    ]
                                } else {
                                    return [
                                        getGreekCurrencyFormat(movement.census),
                                        movement.clientName,
                                        movement.vatNumber,
                                        getGreekCurrencyFormat(movement.balance),
                                    ]
                                }
                            }
                        )
                        if (res.data?.includeSettlements === "active") {
                            rows.push([
                                "",
                                "Συνολικό Υπόλοιπο Πελατών",
                                "",
                                getGreekCurrencyFormat(totalBalance),
                                getGreekCurrencyFormat(totalSettledAmount),
                                getGreekCurrencyFormat(totalBalanceWithSettledAmount),
                            ])
                        } else {
                            rows.push([
                                "",
                                "Συνολικό Υπόλοιπο Πελατών",
                                "",
                                getGreekCurrencyFormat(totalBalance)
                            ])
                        }
                    } else {
                        rows = [{no: "no record found"}];
                    }
                    doc.autoTable({
                        startY: y,
                        head: [columns],
                        body: rows,
                        theme: "striped",
                        rowPageBreak: "avoid",
                        styles: {
                            font: "DejaVu Sans Normal",
                            fontSize: 10, // set font size
                            halign: "center", // set alignment
                        },
                        didParseCell: (data) => {
                            // Check if the current row is a header row
                            if (data.row.raw === columns || data.row.index === rows.length - 1) {
                                data.cell.styles.fillColor = [241, 253, 255]; // set fill color for the header row
                                data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                                data.cell.styles.font = "DejaVu Sans Bold";
                            } else {
                                data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                            }
                        }
                    });
                    const pageCount = doc.getNumberOfPages();
                    // Add the pagination to each page
                    for (let i = 1; i <= pageCount; i++) {
                        doc.setPage(i);
                        doc.setFontSize(10);
                        doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
                    }
                    if (printer) {
                        this.setState({iframeData: `${doc.output('bloburl')}`});
                    } else {
                        doc.save("Client Balance Report.pdf");
                    }
                })
                .catch((error) => {
                    toast.error("Something went wrong. Please try again later!");
                    console.log(error);
                });
        } else if (this.props.tableName === "Supplier Balance Report") {
            const tableColumns = this.state.tableColumns;
            let requestData = {
                columns: tableColumns,
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                tableName: "Supplier Balance Report",
                ...this.props.filters,
                page: -1,
            }
            axios.post(process.env.REACT_APP_API_URL2 + `/report/export-report`, requestData, {
                headers: {"Content-Type": "application/json"}
            })
                .then((res) => {
                    let dR = [];
                    if (res.data && res.data.status === "200") {
                        if (res.data.data.length > 0) {
                            dR = res.data.data;
                        }
                    } else {
                        if (res.data && res.data.message) {
                            toast.error(res.data && res.data.message);
                        } else {
                            toast.error(res.data && res.data.error);
                        }
                    }
                    const doc = new jsPDF('landscape');
                    this.setState({loader: false});
                    let y = 20;
                    doc.addFileToVFS("arialuni.ttf", liberationSerif);
                    doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
                    doc.addFileToVFS("dejavusans.ttf", dejavuSans);
                    doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
                    doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
                    doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
                    doc.setFont("DejaVu Sans Bold");
                    doc.setFontSize(17);
                    const header = doc.splitTextToSize("Υπόλοιπο Προμηθευτών", 200);
                    const headerString = header.join("\n"); // Join the array elements into a single string with line breaks
                    doc.text(headerString, doc.internal.pageSize.getWidth() / 2.5, 9, {alignment: "center"});
                    doc.setFont("DejaVu Sans Normal");
                    doc.setFontSize(11);
                    doc.text(`Ημερομηνία Εξαγωγής: ${moment().format("DD/MM/YYYY HH:mm:ss")}`, 15, 18, {alignment: "center"});
                    const imgData = 'assets/images/prrimer-logo.png';
                    const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
                    const imgY = 3; // adjust the position as needed
                    doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
                    doc.setFont("DejaVu Sans Normal");
                    const columns = ["Απογραφή", "Όνομα Προμηθευτή", "Α.Φ.Μ. Προμηθευτή", "Υπόλοιπο"];
                    let rows;
                    let totalBalance = 0;
                    if (dR.length > 0) {
                        rows = dR.map((movement) =>
                            {
                                totalBalance = totalBalance + Number(movement.balance);
                                return [
                                    getGreekCurrencyFormat(movement.census),
                                    movement.supplierName,
                                    movement.vatNumber,
                                    getGreekCurrencyFormat(movement.balance),
                                ]
                            }
                        );
                        rows.push([
                            "",
                            "Συνολικό Υπόλοιπο Προμηθευτών",
                            "",
                            getGreekCurrencyFormat(totalBalance)
                        ])
                    } else {
                        rows = [{no: "No data found"}];
                    }
                    doc.autoTable({
                        startY: y,
                        head: [columns],
                        body: rows,
                        theme: "striped",
                        rowPageBreak: "avoid",
                        styles: {
                            font: "DejaVu Sans Normal",
                            fontSize: 10, // set font size
                            halign: "center", // set alignment
                        },
                        didParseCell: (data) => {
                            // Check if the current row is a header row
                            if (data.row.raw === columns || data.row.index === rows.length - 1) {
                                data.cell.styles.fillColor = [241, 253, 255]; // set fill color for the header row
                                data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                                data.cell.styles.font = "DejaVu Sans Bold";
                            } else {
                                data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                            }
                        }
                    });
                    const pageCount = doc.getNumberOfPages();
                    // Add the pagination to each page
                    for (let i = 1; i <= pageCount; i++) {
                        doc.setPage(i);
                        doc.setFontSize(10);
                        doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
                    }
                    if (printer) {
                        this.setState({iframeData: `${doc.output('bloburl')}`});
                    } else {
                        doc.save("Supplier Balance Report.pdf");
                    }
                })
                .catch((error) => {
                    toast.error("Something went wrong. Please try again later!");
                    console.log(error);
                });
        } else if (this.props.tableName === "Client Isozygio Report" || this.props.tableName === "Supplier Isozygio Report") {
            const tableColumns = this.state.tableColumns;
            let reportTotals;
            let requestData = {
                columns: tableColumns,
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                tableName: this.props.tableName,
                ...this.props.filters,
                page: -1,
            }
            axios.post(process.env.REACT_APP_API_URL2 + `/report/export-report`, requestData, {
                headers: {"Content-Type": "application/json"}
            }).then((res) => {
                let dR = [];
                if (res.data && res.data.status === "200") {
                    reportTotals = res.data.totals;
                    if (res.data.data.length > 0) {
                        dR = res.data.data;
                    }
                } else {
                    if (res.data && res.data.message) {
                        toast.error(res.data && res.data.message);
                    } else {
                        toast.error(res.data && res.data.error);
                    }
                }

                const doc = new jsPDF('landscape');
                this.setState({loader: false});
                let y = 20;
                doc.addFileToVFS("arialuni.ttf", liberationSerif);
                doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
                doc.addFileToVFS("dejavusans.ttf", dejavuSans);
                doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
                doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
                doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
                doc.setFont("DejaVu Sans Bold");
                doc.setFontSize(17);
                const header = doc.splitTextToSize(this.props.tableName === "Client Isozygio Report" ? "Ισοζύγιο Πελατών" : "Ισοζύγιο Προμηθευτών", 200);
                const headerString = header.join("\n");
                doc.text(headerString, doc.internal.pageSize.getWidth() / 2.5, 9, {alignment: "center"});
                doc.setFont("DejaVu Sans Normal");
                doc.setFontSize(11);
                doc.text(`Ημερομηνία Εξαγωγής: ${moment().format("DD/MM/YYYY HH:mm:ss")}`, 15, 18, {alignment: "center"});
                const imgData = 'assets/images/prrimer-logo.png';
                const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
                const imgY = 3; // adjust the position as needed
                doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
                doc.setFont("DejaVu Sans Normal");
                const columns = this.props.tableName === "Client Isozygio Report" ?
                    ["Από Μεταφορά", "Όνομα Πελάτη", "Α.Φ.Μ. Πελάτη", "Χρέωση", "Πίστωση", "Υπόλοιπο Περιόδου", "Συνολικό Υπόλοιπο"]
                    :
                    ["Από Μεταφορά", "Όνομα Προμηθευτή", "Α.Φ.Μ. Προμηθευτή", "Χρέωση", "Πίστωση", "Υπόλοιπο Περιόδου", "Συνολικό Υπόλοιπο"];
                let rows;
                if (dR.length > 0) {
                    rows = dR.map((row) => {
                        return [
                            getGreekCurrencyFormat(row.carryOverBalance),
                            row.name,
                            row.vatNumber,
                            getGreekCurrencyFormat(row.debit),
                            getGreekCurrencyFormat(row.credit),
                            getGreekCurrencyFormat(row.balance),
                            getGreekCurrencyFormat(row.totalBalance),
                        ];
                    });
                    rows.push([
                        getGreekCurrencyFormat(reportTotals.totalCarryOverBalance),
                        "Σύνολα Υπολοίπων",
                        "",
                        getGreekCurrencyFormat(reportTotals.totalDebit),
                        getGreekCurrencyFormat(reportTotals.totalCredit),
                        getGreekCurrencyFormat(reportTotals.totalPeriodBalance),
                        getGreekCurrencyFormat(reportTotals.totalBalanceTotal),
                    ]);
                } else {
                    rows = [{no: "no record found"}];
                }
                doc.autoTable({
                    startY: y,
                    head: [columns],
                    body: rows,
                    theme: "striped",
                    rowPageBreak: "avoid",
                    styles: {
                        font: "DejaVu Sans Normal",
                        fontSize: 10, // set font size
                        halign: "center", // set alignment
                    },
                    didParseCell: (data) => {
                        // Check if the current row is a header row
                        if (data.row.raw === columns || data.row.index === rows.length - 1) {
                            data.cell.styles.fillColor = [241, 253, 255]; // set fill color for the header row
                            data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                            data.cell.styles.font = "DejaVu Sans Bold";
                        } else {
                            data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                        }
                    }
                });
                const pageCount = doc.getNumberOfPages();
                // Add the pagination to each page
                for (let i = 1; i <= pageCount; i++) {
                    doc.setPage(i);
                    doc.setFontSize(10);
                    doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
                }
                if (printer) {
                    this.setState({iframeData: `${doc.output('bloburl')}`});
                } else {
                    doc.save("Client Balance Report.pdf");
                }
            }).catch((error) => {
                toast.error("Something went wrong. Please try again later!");
                console.log(error);
            });
        } else if (this.props.tableName === "Emron Payments Report") {
            let tableColumns = this.state.tableColumns;
            let tableName = this.props.tableName;
            let requestData = {
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                columns: tableColumns,
                tableName: tableName,
                month: this.props.filters?.month ? this.props.filters?.month : "",
                clientName: this.props.filters?.clientName ? this.props.filters?.clientName : "",
                groupInstallations: this.props.filters.groupInstallations,
                groupClients: this.props.filters.groupClients,
                cardPayments: this.props.filters.cardPayments,
                showZero: this.props.filters.showZero,
                page: -1
            }
            axios.post(process.env.REACT_APP_API_URL2 + "/report/emron-payments-report", requestData, {
                headers: {"Content-Type": "application/json"}
            })
                .then((res) => {
                    if (res.data && res.data.status === "200") {
                        let dR = [];
                        if (res.data.data.length > 0) {
                            dR = res.data.data;
                        }
                        const doc = new jsPDF('landscape');
                        this.setState({loader: false});
                        let y = 20;
                        doc.addFileToVFS("arialuni.ttf", liberationSerif);
                        doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
                        doc.addFileToVFS("dejavusans.ttf", dejavuSans);
                        doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
                        doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
                        doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
                        doc.setFont("DejaVu Sans Bold");
                        doc.setFontSize(13);
                        const header = doc.splitTextToSize("Μηνιαίες Χρεώσεις", 200);
                        const headerString = header.join("\n"); // Join the array elements into a single string with line breaks
                        doc.text(headerString, doc.internal.pageSize.getWidth() / 2.5, 9, {alignment: "center"});
                        doc.setFont("DejaVu Sans Normal");
                        doc.setFontSize(11);
                        doc.text(`Ημερομηνία Εξαγωγής: ${moment().format("DD/MM/YYYY HH:mm:ss")}`, 15, 18, {alignment: "center"});
                        const imgData = 'assets/images/prrimer-logo.png';
                        const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
                        const imgY = 3; // adjust the position as needed
                        doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
                        doc.setFont("DejaVu Sans Normal");
                        tableColumns = tableColumns.filter((el) => el.field !== "actions" && !el.hiddenColumn)
                        let columnNames = [];
                        let columnFields = [];
                        for (let tb of tableColumns) {
                            columnNames.push(tb.name);
                            columnFields.push(tb.field);
                        }

                        let rows = [];
                        let totals = res.data.totals;
                        if (dR.length > 0) {
                            for (let movement of res.data.data) {
                                let resRow = [];
                                for (let col of columnFields) {
                                    if (res.data.months?.includes(col)) {
                                        resRow.push(getGreekCurrencyFormat(movement[col]));
                                    } else {
                                        resRow.push(movement[col]);
                                    }
                                }
                                rows.push(resRow);
                            }
                            if (totals && Object.keys(totals).length > 0) {
                                let totalsNetRow = [];
                                let totalsVatRow = [];
                                let totalsRow = [];
                                for (let col of columnFields) {
                                    if (res.data.months?.includes(col)) {
                                        totalsNetRow.push(getGreekCurrencyFormat(Number(totals[col])));
                                        totalsVatRow.push(getGreekCurrencyFormat(Number(totals[col] * 0.24)));
                                        totalsRow.push(getGreekCurrencyFormat(Number(totals[col] * 1.24)));
                                    } else {
                                        if (col === "installation") {
                                            totalsNetRow.push("Συνολική Καθαρή Αξία");
                                            totalsVatRow.push("Συνολική Αξία Φ.Π.Α.");
                                            totalsRow.push("Συνολική Αξία");
                                        } else {
                                            totalsNetRow.push("");
                                            totalsVatRow.push("");
                                            totalsRow.push("");
                                        }
                                    }
                                }
                                rows.push(totalsNetRow);
                                rows.push(totalsVatRow);
                                rows.push(totalsRow);
                            }
                        } else {
                            rows = [{no: "no record found"}];
                        }
                        doc.autoTable({
                            startY: y,
                            head: [columnNames],
                            body: rows,
                            theme: "striped",
                            rowPageBreak: "avoid",
                            styles: {
                                font: "DejaVu Sans Normal",
                                fontSize: 8, // set font size
                                halign: "center", // set alignment
                            },
                            didParseCell: (data) => {
                                // Check if the current row is a header row
                                if (data.row.raw === columnNames || data.row.index === rows.length - 1 || data.row.index === rows.length - 2 || data.row.index === rows.length - 3) {
                                    data.cell.styles.fillColor = [241, 253, 255]; // set fill color for the header row
                                    data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                                    data.cell.styles.font = "DejaVu Sans Bold";
                                } else {
                                    data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                                }
                            }
                        });
                        const pageCount = doc.getNumberOfPages();
                        // Add the pagination to each page
                        for (let i = 1; i <= pageCount; i++) {
                            doc.setPage(i);
                            doc.setFontSize(10);
                            doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
                        }
                        if (printer) {
                            this.setState({iframeData: `${doc.output('bloburl')}`});
                        } else {
                            doc.save("Μηνιαίες Χρεώσεις.pdf");
                        }
                    } else if (res.data && res.data.status === "422") {
                        toast.error("Something went wrong. Please try again later!");
                    }
                })
                .catch((error) => {
                    toast.error("Something went wrong. Please try again later!");
                    console.log(error);
                });
        } else if (this.props.tableName === "Client Subscriptions Report") {
            const tableColumns = this.state.tableColumns;
            const tableName = this.props.tableName;
            let requestData = {
                tableName: tableName,
                columns: tableColumns,
                company: this.props.company.id,
                user: JSON.parse(localStorage.getItem("user"))._id,
                filterClientName: this.props.filters?.filterClientName,
                filterSubscriptionNames: this.props.filters?.filterSubscriptionNames,
                startDateFrom: this.props.filters?.startDateFrom,
                startDateTo: this.props.filters?.startDateTo,
                activationDateFrom: this.props.filters?.activationDateFrom,
                activationDateTo: this.props.filters?.activationDateTo,
                status: this.props.filters?.status,
                page: -1,
            };
            axios.post(process.env.REACT_APP_API_URL2 + `/report/export-report`, requestData, {headers: {"Content-Type": "application/json"}})
                .then(async (res) => {
                    if (res.data && res.data.status === "200") {
                        const doc = new jsPDF('landscape');
                        this.setState({loader: false});
                        let y = 20;
                        doc.addFileToVFS("arialuni.ttf", liberationSerif);
                        doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
                        doc.addFileToVFS("dejavusans.ttf", dejavuSans);
                        doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
                        doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
                        doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
                        doc.setFont("DejaVu Sans Bold");
                        doc.setFontSize(17);
                        const header = doc.splitTextToSize("Συνδρομές", 200);
                        const headerString = header.join("\n"); // Join the array elements into a single string with line breaks
                        doc.text(headerString, doc.internal.pageSize.getWidth() / 2.5, 9, {alignment: "center"});
                        doc.setFont("DejaVu Sans Normal");
                        doc.setFontSize(11);
                        doc.text(`Ημερομηνία Εξαγωγής: ${moment().format("DD/MM/YYYY HH:mm:ss")}`, 15, 18, {alignment: "center"});
                        const imgData = 'assets/images/prrimer-logo.png';
                        const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
                        const imgY = 3; // adjust the position as needed
                        doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
                        doc.setFont("DejaVu Sans Normal");
                        const columnNames = ["Αρ.", "Όνομα Πελάτη", "Α.Φ.Μ Πελάτη", "Όνομα Συνδρομής", "Ημερομηνία Έναρξης", "Ημερομηνία Ενεργοποίησης", "Ημερομηνία Λήξης", "Κατάσταση", "Τιμή"];
                        let rows = [];
                        if(res.data.data.length > 0) {
                            res.data.data.map((row) => {
                                rows.push([row.no, row.clientName, row.clientVATNumber, row.subscriptionName, row.startDate, row.activatedDate, row.endDate, row.status, row.price]);
                            })
                        }
                        rows.push(["", "", "", "", "", "", "", "Συνολικό ποσό συνδρομών", this.props.totalSubscriptionSum ? this.props.totalSubscriptionSum.toLocaleString('de-DE', {minimumFractionDigits: 2, useGrouping: true, groupingSeparator: '.'}) : "0.00"])
                        doc.autoTable({
                            startY: y,
                            head: [columnNames],
                            body: rows,
                            theme: "striped",
                            rowPageBreak: "avoid",
                            styles: {
                                font: "DejaVu Sans Normal",
                                fontSize: 10, // set font size
                                halign: "center", // set alignment
                            },
                            didParseCell: (data) => {
                                console.log(data.row);
                                if(data.row.raw[7] === "Ανενεργή") {
                                    data.cell.styles.fillColor = [250, 115, 115];
                                }
                                // Check if the current row is a header row
                                if (data.row.raw === columnNames || data.row.index === rows.length - 1) {
                                    data.cell.styles.fillColor = [241, 253, 255]; // set fill color for the header row
                                    data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                                    data.cell.styles.font = "DejaVu Sans Bold";
                                } else {
                                    data.cell.styles.textColor = [0, 0, 0]; // set text color for the header row
                                }
                            }
                        });
                        const pageCount = doc.getNumberOfPages();
                        // Add the pagination to each page
                        for (let i = 1; i <= pageCount; i++) {
                            doc.setPage(i);
                            doc.setFontSize(10);
                            doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
                        }
                        if (printer) {
                            this.setState({iframeData: `${doc.output('bloburl')}`});
                        } else {
                            doc.save("Client Subscriptions Report.pdf");
                        }
                    } else if (res.data && res.data.status === "422") {
                        toast.error("Something went wrong. Please try again later!");
                    }
                })
                .catch((error) => {
                    toast.error("Something went wrong. Please try again later!");
                    console.log(error);
                });
        }
    };
    handleExportToExcel = async () => {
        const company = this.props.requestData.company;
        const tableColumns = this.state.tableColumns;
        const tableName = this.props.tableName;
        const filterData = this.props.requestData;
        let requestData = {
            company: company,
            columns: tableColumns,
            tableName: tableName,
            filterData: filterData
        }
        await axios.post(process.env.REACT_APP_API_URL2 + `/sale/fetch-data-for-results`, requestData, {
            headers: {'Content-Type': 'application/json'}
        }).then((res) => {
            if (res.data.status === "200") {
                if (res.data.data.length > 0) {
                    toast.success(res.data.message);
                    let sumTotalAmount = 0;
                    let sumTotalNetValue = 0;
                    let sumTotalVatValue = 0;
                    res.data.data.map((sale) => {
                        // Calculate totals
                        sumTotalAmount += sale.totalAmount;
                        sumTotalNetValue += sale.totalNetValue;
                        sumTotalVatValue += sale.totalVatValue;
                        // Fix columns
                        delete sale._id;
                        delete sale.preNumber;
                        tableColumns.map((column) => {
                            if (column.hiddenColumn === true) {
                                delete sale[column.field];
                            }
                        })
                    })
                    sumTotalAmount = sumTotalAmount.toFixed(2);
                    sumTotalNetValue = sumTotalNetValue.toFixed(2);
                    sumTotalVatValue = sumTotalVatValue.toFixed(2);
                    res.data.data.push({
                        clientName: "",
                        date: "",
                        documentTypeName: "ΣΥΝΟΛΑ",
                        myDataStatus: "",
                        number: "",
                        totalAmount: sumTotalAmount,
                        totalNetValue: sumTotalNetValue,
                        totalVatValue: sumTotalVatValue,
                    })
                    const workbook = XLSX.utils.book_new();
                    let companyDetails = res.data.company;
                    let worksheet = XLSX.utils.json_to_sheet(res.data.data, {origin: "A8"});
                    XLSX.utils.sheet_add_aoa(worksheet, [['Επωνυμία:', companyDetails?.name], ['Διακ. Τίτλος:', companyDetails?.smallName], ['ΑΦΜ:', companyDetails?.vatNumber], ['Δραστηριότητα:', companyDetails?.activity], ['ΓΕΜΗ:', companyDetails?.gemhNo], ['Τηλέφωνο:', companyDetails?.phone]], {origin: "A1"})
                    let columnNames = [];
                    let keys = Object.keys(res.data.data[0]);
                    keys.map((key) => {
                        tableColumns.map((column) => {
                            if (column.field === key && column.field !== "select_checkbox" && column.field !== "actions" && column.hiddenColumn !== true) {
                                columnNames.push(column.name);
                            }
                            return column;
                        })
                        return key;
                    })
                    XLSX.utils.sheet_add_aoa(worksheet, [columnNames], {origin: "A8"});
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Πωλήσεις");
                    XLSX.writeFile(workbook, "Πωλήσεις.xlsx");
                } else {
                    toast.error("Service is unavailable for the moment. Please try again later.");
                }
            } else {
                toast.error(res.data.Error);
            }
        }).catch((err) => {
            if (err) {
                console.log(err)
                toast.error("Service is unavailable for the moment. Please try again later.");
            }
        })
    }

    handleExportToExcelReport = async () => {
        this.setState({
            loader: true,
        });
        const tableColumns = this.state.tableColumns;
        const tableName = this.state.tableName;
        let requestData = {
            company: this.props.company.id,
            year: this.props.company.year.toString(),
            columns: tableColumns,
            tableName: tableName,
        };
        if (this.props.tableName === "Sales Balance Sheet") {
            requestData = {
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                columns: tableColumns,
                tableName: tableName,
                date_from: this.props.filters?.date_from,
                date_to: this.props.filters?.date_to,
                clientName: this.props.filters?.clientName,
                invoiceType: this.props.filters?.invoiceType.map(el => el.value),
                paymentType: this.props.filters?.paymentType.map(el => el.value),
                automaticSales: this.props.filters?.automaticSales,
                providerChannel: this.props.filters?.providerChannel.map(el => el.value),
                carNumber: this.props.filters?.carNumber.map(el => el.value),
                numberFrom: this.props.filters?.numberFrom,
                numberTo: this.props.filters?.numberTo,
                productName: this.props.filters?.productName,
                totalSum_from: this.props.filters?.totalSum_from,
                totalSum_to: this.props.filters?.totalSum_to,
                preNumber_from: this.props.filters?.preNumber_from,
                preNumber_to: this.props.filters?.preNumber_to,
                myDataStatus: this.props.filters?.myDataStatus,
                paymentStatus: this.props.filters?.paymentStatus,
                notes: this.props.filters?.notes,
            };
        } else if (this.props.tableName === "Buys Monthly Turnover") {
            requestData = {
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                columns: tableColumns,
                tableName: tableName,
                date_from: this.props.filters?.date_from,
                date_to: this.props.filters?.date_to,
                clientName: this.props.filters?.clientName,
            };
        } else if (this.props.tableName === "Sales Monthly Turnover") {
            requestData = {
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                columns: tableColumns,
                tableName: tableName,
                date_from: this.props.filters?.date_from,
                date_to: this.props.filters?.date_to,
                clientName: this.props.filters?.clientName,
            };
        } else if (this.props.tableName === "Emron Payments Report") {
            requestData = {
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                columns: tableColumns,
                tableName: tableName,
                month: this.props.filters?.month ? this.props.filters?.month : "",
                clientName: this.props.filters?.clientName ? this.props.filters?.clientName : "",
                groupInstallations: this.props.filters.groupInstallations,
                groupClients: this.props.filters.groupClients,
                cardPayments: this.props.filters.cardPayments,
                showZero: this.props.filters.showZero,
                page: -1
            };
        } else if (this.props.tableName === "Buys Balance Sheet") {
            requestData = {
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                columns: tableColumns,
                tableName: tableName,
                month: this.props.filters?.month ? this.props.filters?.month : "",
                date_from: this.props.filters?.date_from,
                date_to: this.props.filters?.date_to,
                supplierName: this.props.filters?.supplierName_from,
                invoiceType: this.props.filters?.invoiceType.map(el => el.value),
                paymentType: this.props.filters?.paymentType.map(el => el.value),
                carNumber: this.props.filters?.carNumber.map(el => el.value),
                numberFrom: this.props.filters?.numberFrom,
                numberTo: this.props.filters?.numberTo,
                preNumberFrom: this.props.filters?.preNumberFrom,
                preNumberTo: this.props.filters?.preNumberTo,
                amountFrom: this.props.filters?.amountFrom,
                amountTo: this.props.filters?.amountTo,
                notes: this.props.filters?.notes,
            };
        } else if (this.props.tableName === "Client Payments Report") {
            requestData = {
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                columns: tableColumns,
                tableName: tableName,
                month: this.props.filters?.month ? this.props.filters?.month : "",
                date_from: this.props.filters?.date_from ? this.props.filters?.date_from : "",
                date_to: this.props.filters?.date_to ? this.props.filters?.date_to : "",
                clientName_from: this.props.filters?.clientName_from ? this.props.filters?.clientName_from : "",
                clientName_to: this.props.filters?.clientName_to ? this.props.filters?.clientName_to : "",
                invoiceType: this.props.filters?.invoiceType ? this.props.filters?.invoiceType.map(el => el.value) : [],
                paymentType: this.props.filters?.paymentType ? this.props.filters?.paymentType.map(el => el.value) : [],
                numberFrom: this.props.filters.numberFrom ?? "",
                numberTo: this.props.filters.numberTo ?? "",
                amountFrom: this.props.filters.amountFrom ?? "",
                amountTo: this.props.filters.amountTo ?? "",
                notes: this.props.filters.notes ?? "",
            };
        } else if (this.props.tableName === "Supplier Payments Report") {
            requestData = {
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                columns: tableColumns,
                tableName: tableName,
                date_from: this.props.filters?.date_from ? this.props.filters?.date_from : "",
                date_to: this.props.filters?.date_to ? this.props.filters?.date_to : "",
                supplierName: this.props.filters?.supplierName ? this.props.filters?.supplierName : "",
                invoiceType: this.props.filters?.invoiceType ? this.props.filters?.invoiceType.map(el => el.value) : [],
                paymentType: this.props.filters?.paymentType ? this.props.filters?.paymentType.map(el => el.value) : [],
                numberFrom: this.props.filters?.numberFrom ?? "",
                numberTo: this.props.filters?.numberTo ?? "",
                amountFrom: this.props.filters?.amountFrom ?? "",
                amountTo: this.props.filters?.amountTo ?? "",
            }
        } else if (this.props.tableName === "Product Balance Report") {
            requestData = {
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                columns: tableColumns,
                tableName: tableName,
                date_to: this.props.filters?.date_to ? this.props.filters?.date_to : "",
                product_inactive: this.props.filters.product_inactive,
                product_zero: this.props.filters.product_zero,
                installation: this.props.filters?.installation ? this.props.filters.installation.map(el => el.value) : [],
                productName: this.props.filters?.productName ? this.props.filters.productName.map(el => el.value) : [],
            };
        } else if (this.props.tableName === "Supplier Balance Report") {
            requestData = {
                columns: tableColumns,
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                tableName: "Supplier Balance Report",
                ...this.props.filters,
                page: -1,
            }
        } else if (this.props.tableName === "Client Balance Report") {
            requestData = {
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                columns: tableColumns,
                tableName: tableName,
                date_to: this.props.filters?.date_to ? this.props.filters?.date_to : "",
                clientName: this.props.filters?.clientName ? this.props.filters?.clientName : "",
                showZeroBalance: this.props.filters?.showZeroBalance,
                showInActive: this.props.filters?.showInActive,
                includeSettlements: this.props.filters?.includeSettlements,
            };
        } else if (this.props.tableName === "Client Isozygio Report") {
            requestData = {
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                columns: tableColumns,
                tableName: tableName,
                date_from: this.props.filters?.date_from ? this.props.filters?.date_from : "",
                date_to: this.props.filters?.date_to ? this.props.filters?.date_to : "",
                clientName: this.props.filters?.clientName ? this.props.filters?.clientName : "",
                zeroBalanceClients: this.props.filters?.zeroBalanceClients,
                page: -1
            };
        } else if (this.props.tableName === "Supplier Isozygio Report") {
            requestData = {
                company: this.props.company.id,
                year: this.props.company.year.toString(),
                columns: tableColumns,
                tableName: tableName,
                date_from: this.props.filters?.date_from ? this.props.filters?.date_from : "",
                date_to: this.props.filters?.date_to ? this.props.filters?.date_to : "",
                supplierName: this.props.filters?.clientName ? this.props.filters?.supplierName : "",
                zeroBalanceSuppliers: this.props.filters?.zeroBalanceSuppliers,
                page: -1
            };
        } else if (this.props.tableName === "Client Subscriptions Report") {
            requestData = {
                tableName: tableName,
                columns: tableColumns,
                company: this.props.company.id,
                user: JSON.parse(localStorage.getItem("user"))._id,
                filterClientName: this.props.filters?.filterClientName,
                filterSubscriptionNames: this.props.filters?.filterSubscriptionNames,
                startDateFrom: this.props.filters?.startDateFrom,
                startDateTo: this.props.filters?.startDateTo,
                activationDateFrom: this.props.filters?.activationDateFrom,
                activationDateTo: this.props.filters?.activationDateTo,
                status: this.props.filters?.status,
                page: -1,
            }
        }
        await axios.post(process.env.REACT_APP_API_URL2 + `/report/export-report`, requestData, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            if (res.data.status === "200" || res.data.status === "422") {
                if (this.props.tableName === "Sales Balance Sheet") {
                    let dR = [];
                    if (res.data.data.length > 0) {
                        res.data.data.forEach((element) => {
                            let obj = {
                                "Ημερομηνία": moment(element?.date).format("DD/MM/YYYY HH:mm"),
                                "Τύπος Παραστατικού": element?.documentTypeName,
                                "Αρίθμηση": element?.preNumber.replace("noprefix", "") + " " + element?.number,
                                "Όνομα Πελάτη": element?.clientName,
                                "ΑΦΜ Πελάτη": element?.vat,
                                "Τηλέφωνο Πελάτη": element?.phone,
                                "Τρόπος Πληρωμής": element?.paymentTypeName,
                                "Καθαρή Αξία": Number(Number(element?.totalNetValue).toFixed(2)),
                                "Αξία ΦΠΑ": Number(Number(element?.totalVatValue).toFixed(2)),
                                "Συνολική Αξία": Number(Number(element?.totalAmount).toFixed(2)),
                                "Πληρωτέο Ποσό": Number(Number(element?.paymentAmount).toFixed(2)),
                                "Περιέχοντα είδη": element?.productNamesBalanceSheet,
                            };
                            dR.push(obj);
                        });
                    }
                    this.setState({loader: false});
                    const worksheet = XLSX.utils.json_to_sheet(dR);
                    toast.success(res.data.message);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                    XLSX.writeFile(workbook, "Sales Balance Sheet.xlsx");
                } else if (this.props.tableName === "Sales Monthly Turnover" || this.props.tableName === "Buys Monthly Turnover") {
                    let dR = [];
                    let generalTotal = 0;
                    if (res.data.data.length > 0) {
                        res.data.data.forEach((element) => {
                            generalTotal += this.props.tableName === "Sales Monthly Turnover" ? element.cTotal : element.sTotal;
                            let obj = {
                                [this.props.tableName === "Sales Monthly Turnover" ? "Πελάτης" : "Προμηθευτής"]: this.props.tableName === "Sales Monthly Turnover" ? element.clientName : element.supplierName,
                                "Ιανουάριος": element.jan,
                                "Φεβρουάριος": element.feb,
                                "Μάρτιος": element.mar,
                                "Απρίλιος": element.apr,
                                "Μάιος": element.may,
                                "Ιούνιος": element.jun,
                                "Ιούλιος": element.jul,
                                "Αύγουστος": element.aug,
                                "Σεπτέμβιος": element.sep,
                                "Οκτώβριος": element.oct,
                                "Νοέμβριος": element.nov,
                                "Δεκέμβριος": element.dec,
                                [this.props.tableName === "Sales Monthly Turnover" ? "Σύνολο Πελάτη" : "Σύνολο Προμηθευτή"]: this.props.tableName === "Sales Monthly Turnover" ? element.cTotal : element.sTotal,
                            };
                            dR.push(obj);
                        });
                    }
                    dR.push({
                        "Πελάτης": "",
                        "Ιανουάριος": "",
                        "Φεβρουάριος": "",
                        "Μάρτιος": "",
                        "Απρίλιος": "",
                        "Μάιος": "",
                        "Ιούνιος": "",
                        "Ιούλιος": "",
                        "Αύγουστος": "",
                        "Σεπτέμβιος": "",
                        "Οκτώβριος": "",
                        "Νοέμβριος": "",
                        "Δεκέμβριος": "",
                        [this.props.tableName === "Sales Monthly Turnover" ? "Σύνολο Πελάτη" : "Σύνολο Προμηθευτή"]: generalTotal
                    });
                    this.setState({loader: false});
                    const worksheet = XLSX.utils.json_to_sheet(dR);
                    toast.success(res.data.message);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                    XLSX.writeFile(workbook, this.props.tableName === "Sales Monthly Turnover" ? "Τζίρος Πωλήσεων Ανα Περίοδο.xlsx" : "Τζίρος Αγορών Ανα Περίοδο.xlsx");
                } else if (this.props.tableName === "Emron Payments Report") {
                    let columnNames = [];
                    let columnFields = [];
                    for (let tb of tableColumns) {
                        columnNames.push(tb.name);
                        columnFields.push(tb.field);
                    }
                    let rows = [];
                    rows.push(columnNames);
                    if (res.data.data.length > 0) {
                        for (let movement of res.data.data) {
                            let resRow = [];
                            for (let col of columnFields) {
                                if (!isEmpty(movement[col])) {
                                    resRow.push(movement[col]);
                                } else {
                                    resRow.push("");
                                }
                            }
                            rows.push(resRow);
                        }
                    }
                    this.setState({loader: false});
                    const worksheet = XLSX.utils.aoa_to_sheet(rows);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                    XLSX.writeFile(workbook, "Μηνιαίες χρεώσεις.xlsx");
                } else if (this.props.tableName === "Buys Balance Sheet") {
                    let dR = [];
                    if (res.data.data.length > 0) {
                        res.data.data.forEach((element) => {
                            let obj = {
                                "Ημερομηνία": moment(element?.date).format("DD/MM/YYYY HH:mm"),
                                "Τύπος Παραστατικού": element?.documentTypeName,
                                "Αρίθμηση": element?.preNumber.replace("noprefix", "") + " " + element?.number,
                                "Όνομα Προμηθευτή": element?.supplierName,
                                "ΑΦΜ Προμηθευτή": element?.vat,
                                "Τρόπος Πληρωμής": element?.paymentTypeName,
                                "Εκπιπτόμενη Καθαρή Αξία": Number(Number(element?.totalDeductibleNetValue).toFixed(2)),
                                "Μη Εκπιπτόμενη Καθαρή Αξία": Number(Number(element?.totalNonDeductibleNetValue).toFixed(2)),
                                "Αξία Εκπιπτόμενου Φ.Π.Α.": Number(Number(element?.totalDeductibleVAT).toFixed(2)),
                                "Αξία Μη Εκπιπτόμενου Φ.Π.Α.": Number(Number(element?.totalNonDeductibleVAT).toFixed(2)),
                                "Αναμόρφωση Καθαρής Αξίας": Number(Number(element?.totalAnamorfosisNet).toFixed(2)),
                                "Αναμόρφωση ΦΠΑ": Number(Number(element?.totalAnamorfosisVat).toFixed(2)),
                                "Συνολική Αξία": Number(Number(element?.totalAmount).toFixed(2))
                            }
                            dR.push(obj);
                        })
                    }
                    this.setState({loader: false});
                    const worksheet = XLSX.utils.json_to_sheet(dR);
                    toast.success(res.data.message);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                    XLSX.writeFile(workbook, "Buys Balance Sheet.xlsx");
                } else if (this.props.tableName === "Client Payments Report") {
                    let dR = [];
                    if (res.data.data.length > 0) {
                        res.data.data.forEach((element) => {
                            let obj = {
                                "Ημερομηνία": moment(element?.date).format("DD/MM/YYYY"),
                                "Όνομα Πελάτη": element.clientName,
                                "ΑΦΜ Πελάτη": element.vatNumber,
                                "Τύπος Παραστατικού": element.documentTypeName,
                                "Αρίθμηση": element.preNumber === "noprefix" ? element.number : element.preNumber + " " + element.number,
                                "Ποσό": Number(Number(element.amount).toFixed(2)),
                                "Τρόπος Πληρωμής": element.paymentTypeName,
                            }
                            dR.push(obj);
                        })
                    }
                    this.setState({loader: false});
                    const worksheet = XLSX.utils.json_to_sheet(dR);
                    toast.success(res.data.message);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                    XLSX.writeFile(workbook, "Client Payments Report.xlsx");
                } else if (this.props.tableName === "Supplier Payments Report") {
                    let dR = [];
                    if (res.data.data.length > 0) {
                        res.data.data.forEach((element) => {
                            let obj = {
                                "Ημερομηνία": moment(element?.date).format("DD/MM/YYYY"),
                                "Όνομα Προμηθευτή": element.supplierName,
                                "ΑΦΜ Προμηθευτή": element.vatNumber,
                                "Τύπος Παραστατικού": element.documentTypeName,
                                "Αρίθμηση": element.preNumber === "noprefix" ? element.number : element.preNumber + " " + element.number,
                                "Ποσό": Number(Number(element.amount).toFixed(2)),
                                "Τρόπος Πληρωμής": element.paymentTypeName,
                            }
                            dR.push(obj);
                        })
                    }
                    this.setState({loader: false});
                    const worksheet = XLSX.utils.json_to_sheet(dR);
                    toast.success(res.data.message);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                    XLSX.writeFile(workbook, "Supplier Payments Report.xlsx");
                } else if (this.props.tableName === "Product Balance Report") {
                    let dR = [];
                    if (res.data.data.length > 0) {
                        res.data.data.forEach((element) => {
                            let obj = {
                                "Κωδικός Προιόντος": element.code,
                                "Όνομα Προιόντος": element._id.productName,
                                "Απόθεμα": element.inventory,
                                "Υπόλοιπο": Number(Number(element.balance).toFixed(2)),
                            }
                            dR.push(obj);
                        })
                    }
                    this.setState({loader: false});
                    const worksheet = XLSX.utils.json_to_sheet(dR);
                    toast.success(res.data.message);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                    XLSX.writeFile(workbook, "Product Balance Report.xlsx");
                } else if (this.props.tableName === "Client Balance Report") {
                    let dR = [];
                    if (res.data.data.length > 0) {
                        if (res.data.includeSettlements === "active") {
                            res.data.data.forEach((element) => {
                                let obj = {
                                    "Απογραφή": Number(Number(element.census).toFixed(2)),
                                    "Όνομα Πελάτη": element.clientName,
                                    "Α.Φ.Μ. Πελάτη": element.vatNumber,
                                    "Υπόλοιπο": Number(Number(element.balance).toFixed(2)),
                                    "Διακανονισμένο Υπόλοιπο": Number(Number(element.totalSettledAmount).toFixed(2)),
                                    "Υπόλοιπο μετά διακανονισμών": Number(Number(element.balanceWithSettledAmount).toFixed(2)),
                                }
                                dR.push(obj);
                            })
                        } else {
                            res.data.data.forEach((element) => {
                                let obj = {
                                    "Απογραφή": Number(Number(element.census).toFixed(2)),
                                    "Όνομα Πελάτη": element.clientName,
                                    "Α.Φ.Μ. Πελάτη": element.vatNumber,
                                    "Υπόλοιπο": Number(Number(element.balance).toFixed(2)),
                                }
                                dR.push(obj);
                            })
                        }
                    }
                    this.setState({loader: false});
                    const worksheet = XLSX.utils.json_to_sheet(dR);
                    toast.success(res.data.message);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                    XLSX.writeFile(workbook, "Client Balance Report.xlsx");
                } else if (this.props.tableName === "Client Isozygio Report") {
                    let dR = [];
                    if (res.data.data.length > 0) {
                        res.data.data.forEach((element) => {
                            let obj = {
                                "Από Μεταφορά": element.carryOverBalance,
                                "Όνομα Πελάτη": element.name,
                                "Α.Φ.Μ. Πελάτη": element.vatNumber,
                                "Χρέωση": element.debit,
                                "Πίστωση": element.credit,
                                "Υπόλοιπο Περιόδου": element.balance,
                                "Συνολικό Υπόλοιπο": element.totalBalance,
                            }
                            dR.push(obj);
                        })
                    }
                    this.setState({loader: false});
                    const worksheet = XLSX.utils.json_to_sheet(dR);
                    toast.success(res.data.message);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                    XLSX.writeFile(workbook, "Client Isozygio Report.xlsx");
                } else if (this.props.tableName === "Supplier Isozygio Report") {
                    let dR = [];
                    if (res.data.data.length > 0) {
                        res.data.data.forEach((element) => {
                            let obj = {
                                "Από Μεταφορά": element.carryOverBalance,
                                "Όνομα Προμηθευτή": element.name,
                                "Α.Φ.Μ. Προμηθευτή": element.vatNumber,
                                "Χρέωση": element.debit,
                                "Πίστωση": element.credit,
                                "Υπόλοιπο Περιόδου": element.balance,
                                "Συνολικό Υπόλοιπο": element.totalBalance,
                            }
                            dR.push(obj);
                        })
                    }
                    this.setState({loader: false});
                    const worksheet = XLSX.utils.json_to_sheet(dR);
                    toast.success(res.data.message);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                    XLSX.writeFile(workbook, "Supplier Isozygio Report.xlsx");
                } else if (this.props.tableName === "Supplier Balance Report") {
                    let dR = [];
                    if (res.data.data.length > 0) {
                        res.data.data.forEach((element) => {
                            let obj = {
                                "Απογραφή": Number(Number(element.census).toFixed(2)),
                                "Όνομα Προμηθευτή": element.supplierName,
                                "Α.Φ.Μ. Προμηθευτή": element.vatNumber,
                                "Υπόλοιπο": Number(Number(element.balance).toFixed(2)),
                            };
                            dR.push(obj);
                        });
                    }
                    this.setState({loader: false});
                    const worksheet = XLSX.utils.json_to_sheet(dR);
                    toast.success(res.data.message);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                    XLSX.writeFile(workbook, "Supplier Balance Report.xlsx");
                } else if (this.props.tableName === "Client Subscriptions Report") {
                    let dR = [];
                    if (res.data.data.length > 0) {
                        res.data.data.forEach((element) => {
                            let obj = {
                                "Όνομα Πελάτη": element.clientName,
                                "Α.Φ.Μ. Πελάτη": element.clientVATNumber,
                                "Όνομα Συνδρομής": element.subscriptionName,
                                "Ημερομηνία Έναρξης": element.startDate,
                                "Ημερομηνία Ενεργοποίησης": element.activatedDate,
                                "Ημερομηνία Λήξης" : element.endDate,
                                "Κατάσταση" : element.status,
                                "Τιμή": element.price,
                            };
                            dR.push(obj);
                        });
                    }
                    dR.push({
                        "Όνομα Πελάτη": "",
                        "Α.Φ.Μ. Πελάτη": "",
                        "Όνομα Συνδρομής": "",
                        "Ημερομηνία Έναρξης": "",
                        "Ημερομηνία Ενεργοποίησης": "",
                        "Ημερομηνία Λήξης" : "",
                        "Κατάσταση" : "",
                        "Τιμή": "",
                    })
                    dR.push({
                        "Όνομα Πελάτη": "",
                        "Α.Φ.Μ. Πελάτη": "",
                        "Όνομα Συνδρομής": "",
                        "Ημερομηνία Έναρξης": "",
                        "Ημερομηνία Ενεργοποίησης": "",
                        "Ημερομηνία Λήξης" : "",
                        "Κατάσταση": "Συνολικό ποσό συνδρομών",
                        "Τιμή": this.props.totalSubscriptionSum ? this.props.totalSubscriptionSum.toLocaleString('de-DE', {minimumFractionDigits: 2, useGrouping: true, groupingSeparator: '.'}) : "0.00"
                    })
                    this.setState({loader: false});
                    const worksheet = XLSX.utils.json_to_sheet(dR);
                    toast.success(res.data.message);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                    XLSX.writeFile(workbook, "Client Subscriptions Report.xlsx");
                }
            }
        }).catch((err) => {
            if (err) {
                toast.error(
                    "Service is unavailable for the moment. Please try again later."
                );
            }
        })
    }

    getSelectedRow = {
        getRow: (data) => {
            this.props.onUpdate && this.props.onUpdate.selected(data);
        },
    };
    handleColumnShowHide = (field) => {
        let tempTc = this.state.tableColumns.slice();
        let object = getObjectFromArrayOfObjects(tempTc, field, "field");
        const index = tempTc.findIndex((item) => field === item.field);
        if (object.length > 0) {
            tempTc[index].hiddenColumn = !object["0"].hiddenColumn;
            this.setState({
                tableColumns: tempTc,
            });
        }
    };
    handleSaveToggleColumns = () => {
        if (
            this.props.tableName &&
            this.props.tableName !== "" &&
            this.state.tableColumns
        ) {
            const key = getKeyByName(this.props.tableName, "ethide_");
            localStorage.removeItem(key);
            localStorage.setItem(key, JSON.stringify(this.state.tableColumns));
            toast.success("Table Columns Permanenlty Saved.");
        } else {
            toast.error("Table Name or Data not found.");
        }
    };
    handleBulkDelete = () => {
        let data = this.state.tableData.slice();
        let rows = this.state.checkedRows.slice();
        let itemIds = [];
        rows.forEach((row) => {
            itemIds.push(data[row]._id);
        });

        console.log("Items to delete.", itemIds);
        // TODO:
        // Bulk Update API needed
    };

    sendSelectedDocumentsToMyData = () => {
        let data = this.state.tableData.slice();
        let rows = this.state.checkedRows.slice();
        const company = localStorage.getItem("company");
        const companyData = JSON.parse(company);
        this.setState((state) => {
            if (state.tabCloseWarning) {
                return {
                    tabCloseWarning: false,
                };
            }
        });
        let selectedData = [];
        rows.forEach((row) => {
            if (data[row]?.myDataStatus !== "sent" && data[row]?.myDataSentStatus !== "sent") {
                selectedData.push(data[row]._id);
            }
        });
        let type = "sale";
        if (this.state.tableName === "Buys Search") type = "buy";
        if (this.state.tableName === "Accounting Record Search") type = "accounting";
        if (selectedData.length > 0) {
            let requestedData;
            if(type === "sale") {
                requestedData = {
                    saleIds: selectedData,
                    company: this.props.requestData?.company ? this.props.requestData.company : companyData.id
                }
            } else if (type === "buy") {
                requestedData = {
                    buyIds: selectedData,
                    company: this.props.requestData?.company ? this.props.requestData.company : companyData.id
                }
            } else if (type === "accounting") {
                requestedData = {
                    recordIds: selectedData,
                    company: this.props.requestData?.company ? this.props.requestData.company : companyData.id
                }
            }
            this.setState(() => {
                return { loadingSendSelected: true }
            });
            axios.post(process.env.REACT_APP_API_URL2 + `/${type}/mass-send-selected-to-mydata`, JSON.stringify(requestedData), {
                headers: {"Content-Type": "application/json"},
            })
                .then((res) => {
                    if (res.data && res.data.status === "200") {
                        let responseStringModal = "";
                        if (res.data.data.length > 0) {
                            res.data.data.map(obj => {
                                if (obj?.uid !== undefined) {
                                    responseStringModal += obj?.name + "Mark: " + obj?.mark + "Uid: " + obj?.uid + " MyDataStatus: " + obj?.myDataStatus + "\n\n";
                                } else if (obj?.message !== undefined) {
                                    responseStringModal += " Error: " + obj?.message + "\n\n";
                                } else {
                                    responseStringModal += obj?.name?.toString() + " MyDataStatus: " + obj?.myDataStatus?.toString() + " Error: " + obj?.myDataError?.toString() + "\n\n";
                                }
                                return obj;
                            })
                        } else {
                            responseStringModal = "No data found with these filters.";
                        }
                        this.setState((state) => {
                            if (!state.showResponseSentToMyData) {
                                return {
                                    showResponseSentToMyData: true,
                                };
                            }
                        });
                        this.setState(() => {
                            return {
                                loadingSendSelected: false,
                            };
                        });
                        this.setState(() => {
                            return {responseSentToMyData: responseStringModal};
                        });
                    } else {
                        this.setState(() => {
                            return {responseSentToMyData: res.data.message};
                        });
                        this.setState((state) => {
                            if (!state.showResponseSentToMyData) {
                                return {
                                    showResponseSentToMyData: true,
                                };
                            }
                        });

                    }
                })
                .catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.errors) {
                        console.log(error.response.data.errors);
                        toast.error(error.response.data.errors);
                        this.setState(() => {
                            return {
                                loadingSendSelected: false,
                            };
                        });
                    }
                });
        } else {
            toast.error("Please select one or more documents that are not already sent to MyData!");
        }
        this.state.checkedRows.length = 0;
    };

    cancelSelectedDocumentsToMyData = () => {
        let data = this.state.tableData.slice();
        let rows = this.state.checkedRows.slice();
        const company = localStorage.getItem("company");
        const companyData = JSON.parse(company);
        this.setState((state) => {
            if (state.cancelTabCloseWarning) {
                return {
                    cancelTabCloseWarning: false,
                };
            }
        });
        let selectedData = [];
        rows.forEach((row) => {
            console.log(data[row])
            if (data[row]?.myDataStatus === "sent" || data[row]?.myDataSentStatus === "sent") {
                selectedData.push(data[row]?.erpExists === "true" ? data[row]._id : data[row].mark);
            }
        });
        if (selectedData.length > 0) {
            let requestedData = {
                saleIds: selectedData,
                company: this.props.requestData?.company ? this.props.requestData.company : companyData.id
            }
            this.setState(() => {
                return {
                    loadingSendSelected: true,
                };
            });
            axios
                .post(process.env.REACT_APP_API_URL2 + `/sale/mass-cancel-selected-to-mydata`, JSON.stringify(requestedData), {
                    headers: {"Content-Type": "application/json"},
                })
                .then((res) => {
                    if (res.data && res.data.status === "200") {
                        let responseStringModal = "";
                        if (res.data.data.length > 0) {
                            res.data.data.map(obj => {
                                if (obj?.myDataStatus !== "sent") {
                                    responseStringModal += (obj?.name ? (obj?.name + " MyDataStatus: " + obj?.myDataStatus) : "") + " Message: " + obj?.message + "\n\n";
                                    console.log(responseStringModal)
                                } else if (obj?.message !== undefined) {
                                    responseStringModal += " Error: " + obj?.message + "\n\n";
                                } else {
                                    responseStringModal += obj?.name + " MyDataStatus: " + obj?.myDataStatus + " Error: " + obj?.myDataError + "\n\n";
                                }
                                return obj;
                            })
                        } else {
                            responseStringModal = "No Sales were found for these filters!";
                        }
                        this.setState((state) => {
                            if (!state.showResponseCancelToMyData) {
                                return {
                                    showResponseCancelToMyData: true,
                                };
                            }
                        });
                        this.setState(() => {
                            return {
                                loadingSendSelected: false,
                            };
                        });
                        this.setState(() => {
                            return {responseCancelToMyData: responseStringModal};
                        });
                    } else {
                        this.setState(() => {
                            return {responseCancelToMyData: res.data.message};
                        });
                        this.setState((state) => {
                            if (!state.showResponseCancelToMyData) {
                                return {
                                    showResponseCancelToMyData: true,
                                };
                            }
                        });

                    }
                })
                .catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.errors) {
                        console.log(error.response.data.errors);
                        toast.error(error.response.data.errors);
                        this.setState(() => {
                            return {
                                loadingSendSelected: false,
                            };
                        });
                    }
                });
        } else {
            toast.error("Please select one or more documents that are sent to MyData!");
        }
        this.state.checkedRows.length = 0;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.tableData !== this.state.tableData) {
            const { tableName, columns } = this.props;

            if (tableName === "Emron Payments Report") {
                const updatedTableColumns = getSavedHiddenColumns(tableName, columns);
                this.setState({ tableColumns: updatedTableColumns });
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state !== nextState;
    }

    render() {
        const currentUser = getCurrentUser();
        const {tableData, filteredData, tableColumns} = this.state;
        // It will manage the new insert row with prop allowInsertRow value.
        let tableClasses = "table table-bordered mb-2 editable-table-ui";
        if (this.props.allowInsertRow || this.props.allowInsertRowFromTop) {
            tableClasses += " allow-insert-row";
        }

        let data = tableData;
        if (this.props.allowPagination) {
            data = filteredData;
        }
        let tableWrClasses = "table-responsive ";
        tableWrClasses = this.props.className
            ? tableWrClasses + this.props.className
            : tableWrClasses;
        return (
            <>
                <div className="table-pagination float-right no-print">
                    {this.props.allowPagination && data.length > 0 && (
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.rowsPerPage}
                            totalItemsCount={this.state.count}
                            pageRangeDisplayed={this.state.pageRange}
                            onChange={this.handlePageChange.bind(this)}
                            itemClass="page-item"
                            disabledClass="disabled"
                            activeClass="active"
                            linkClass="page-link"
                            prevPageText={currentUser?.lang && currentUser.lang === "el" ? "Προηγούμενη" : "Previous"}
                            nextPageText={currentUser?.lang && currentUser.lang === "el" ? "Επόμενη" : "Next"}
                            firstPageText={currentUser?.lang && currentUser.lang === "el" ? "Πρώτη" : "First"}
                            lastPageText={currentUser?.lang && currentUser.lang === "el" ? "Τελευταία" : "Last"}
                        />
                    )}
                </div>
                {this.props.showErrors && this.props.showErrorMessage !== "" && (
                    <div className="text-danger">{this.props.showErrorMessage}</div>
                )}
                <div className="no-print">
                    <div id="editableTableWr" ref={(el) => (this.editableTableRef = el)} className={tableWrClasses}>
                        {this.props.heading && (
                            <div className="text-muted mb-2">
                                <i>{this.props.heading}</i>
                            </div>
                        )}
                        <table style={{borderCollapse: "collapse"}} id="editableTable" className={tableClasses}>
                            <TableHead
                                {...this.props}
                                toggleColumns={this.handleColumnShowHide}
                                saveToggleColumns={this.handleSaveToggleColumns}
                                headColumns={tableColumns}
                            />
                            <TableBody
                                {...this.props}
                                key={Math.random()}
                                requestData={this.state.pRequestData}
                                reqName={this.state.reqName}
                                insert={this.handleInsertNewRowToData}
                                update={this.handleUpdateRowToData}
                                editRedirectAllowed={this.props.editRedirectAllowed}
                                data={data}
                                columns={tableColumns}
                                switchCreditAndDebit={this.props.switchCreditAndDebit}
                                switchCreditAndDebitDisabled={this.props.switchCreditAndDebitDisabled}
                                emronPaymentsMonth={this.state.emronPaymentsMonth}
                                onRowSelect={this.getSelectedRow}
                                checkedRows={this.state.checkedRows}
                            />
                            {this.props.enableFooter && (
                                <TableFooter
                                    data={data}
                                    footerData={this.props.footerData}
                                    columns={tableColumns}
                                    enableFooter={this.props.enableFooter}
                                />
                            )}
                        </table>
                        {this.props.pageTotals && (
                            <Row className="justify-content-end">
                                <Col sm="12" md="4" className="d-flex justify-content-end">
                                    <span className="mr-3 fw-bold" style={{width: "20%"}}>Σύνολα</span>
                                    <table className="table table-bordered mb-3" style={{width: "70%"}} id="editableTableTotal">
                                        <tbody>
                                        {this.props.totalNetValueTrue && (
                                            <tr>
                                                <td className="fw-bold">Συνολική Καθαρή Αξία</td>
                                                <td>{getGreekCurrencyFormat(this.props.totalNetValue.toFixed(2))}</td>
                                            </tr>
                                        )}
                                        {this.props.totalVatAmountTrue && (
                                            <tr>
                                                <td className="fw-bold">Συνολική Αξία ΦΠΑ</td>
                                                <td>{getGreekCurrencyFormat(this.props.totalVatAmount.toFixed(2))}</td>
                                            </tr>
                                        )}
                                        {this.props.totalDeductibleNetValueTrue && (
                                            <tr>
                                                <td className="fw-bold">Συνολική Εκπιπτόμενη Καθαρή Αξία</td>
                                                <td>{getGreekCurrencyFormat(this.props.totalDeductibleNetValue)}</td>
                                            </tr>
                                        )}
                                        {this.props.totalNonDeductibleNetValueTrue && (
                                            <tr>
                                                <td className="fw-bold">Συνολική Μη Εκπιπτόμενη Καθαρή Αξία</td>
                                                <td>{getGreekCurrencyFormat(this.props.totalNonDeductibleNetValue)}</td>
                                            </tr>
                                        )}
                                        {this.props.totalDeductibleVATTrue && (
                                            <tr>
                                                <td className="fw-bold">Συνολική Αξία Εκπιπτόμενου Φ.Π.Α.</td>
                                                <td>{getGreekCurrencyFormat(this.props.totalDeductibleVAT)}</td>
                                            </tr>
                                        )}
                                        {this.props.totalNonDeductibleVATTrue && (
                                            <tr>
                                                <td className="fw-bold">Συνολική Αξία Μη Εκπιπτόμενου Φ.Π.Α.</td>
                                                <td>{getGreekCurrencyFormat(this.props.totalNonDeductibleVAT)}</td>
                                            </tr>
                                        )}
                                        {this.props.totalValueTrue && (
                                            <tr>
                                                <td className="fw-bold">Συνολική Αξία</td>
                                                <td>{getGreekCurrencyFormat(this.props.totalValue)}</td>
                                            </tr>
                                        )}
                                        {this.props.totalPaymentAmountTrue && (
                                            <tr>
                                                <td className="fw-bold">Συνολικό Πληρωτέο Ποσό</td>
                                                <td>{getGreekCurrencyFormat(this.props.totalPaymentAmount)}</td>
                                            </tr>
                                        )}
                                        {this.props.inventoryAmountTrue && (
                                            <tr>
                                                <td className="fw-bold">Συνολικό Απόθεμα</td>
                                                <td>{this.props.inventoryAmount.toFixed(2)}</td>
                                            </tr>
                                        )}
                                        {this.props.totalBalanceTrue && (
                                            <tr>
                                                <td className="fw-bold">Συνολικό Υπόλοιπο</td>
                                                <td>{getGreekCurrencyFormat(this.props.totalBalance)}</td>
                                            </tr>
                                        )}
                                        {this.props.showSubscriptionSum && (
                                            <tr>
                                                <td className="fw-bold">Συνολικό Ποσό Συνδρομών</td>
                                                {this.props.totalSubscriptionSum ? <td>{getGreekCurrencyFormat(this.props.totalSubscriptionSum)}</td> : <td>0.00</td>}
                                            </tr>
                                        )}
                                        {this.props.totalSettledAmountTrue && (
                                            <tr>
                                                <td className="fw-bold">Σύνολο διακανονισμένων ποσών</td>
                                                {this.props.totalSettledAmount ? <td>{getGreekCurrencyFormat(this.props.totalSettledAmount)}</td> : <td>0.00</td>}
                                            </tr>
                                        )}
                                        {this.props.totalBalanceWithSettledAmountTrue && (
                                            <tr>
                                                <td className="fw-bold">Συνολικό υπόλοιπο μετά διακανονισμών</td>
                                                {this.props.totalBalanceWithSettledAmount ? <td>{getGreekCurrencyFormat(this.props.totalBalanceWithSettledAmount)}</td> : <td>0.00</td>}
                                            </tr>
                                        )}
                                        {this.props.showIsozygioTotals && (
                                            <tr>
                                                <td className="fw-bold">Συνολικό Υπόλοιπο Από Μεταφορά</td>
                                                {this.props.totalCarryOverBalance ? <td>{getGreekCurrencyFormat(this.props.totalCarryOverBalance)}</td> : <td>0.00</td>}
                                            </tr>
                                        )}
                                        {this.props.showIsozygioTotals && (
                                            <tr>
                                                <td className="fw-bold">Σύνολο Χρεώσεων</td>
                                                {this.props.totalDebit ? <td>{getGreekCurrencyFormat(this.props.totalDebit)}</td> : <td>0.00</td>}
                                            </tr>
                                        )}
                                        {this.props.showIsozygioTotals && (
                                            <tr>
                                                <td className="fw-bold">Σύνολο Πιστώσεων</td>
                                                {this.props.totalCredit ? <td>{getGreekCurrencyFormat(this.props.totalCredit)}</td> : <td>0.00</td>}
                                            </tr>
                                        )}
                                        {this.props.showIsozygioTotals && (
                                            <tr>
                                                <td className="fw-bold">Συνολικό Υπόλοιπο Περιόδου</td>
                                                {this.props.totalPeriodBalance ? <td>{getGreekCurrencyFormat(this.props.totalPeriodBalance)}</td> : <td>0.00</td>}
                                            </tr>
                                        )}
                                        {this.props.showIsozygioTotals && (
                                            <tr>
                                                <td className="fw-bold">Συνολικό Γενικό Υπόλοιπο</td>
                                                {this.props.totalBalanceTotal ? <td>{getGreekCurrencyFormat(this.props.totalBalanceTotal)}</td> : <td>0.00</td>}
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        )}
                    </div>
                </div>
                <div style={{display: "none"}} className="print-me" id="printReport">
                    <iframe onLoad={this.handleIframeLoad} ref={this.iframeRef} style={{ width: "100%" }} src={this.state.iframeData} title={"Print Report"}></iframe>
                </div>
                <div className="extra-features no-print">
                    {this.props.allowBulkActions && (
                        <div className="bulk-actions">
                            {this.props.allowPrintResults && (
                                <Dropdown className="mb-3">
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                        Εκτύπωση Αποτελεσμάτων
                                        {this.state.loader && (
                                            <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                                        )}
                                    </Dropdown.Toggle>
                                    {!this.state.loader && (
                                        <Dropdown.Menu>
                                            {this.props.reportExcelPrint && (
                                                <Dropdown.Item onClick={() => this.handleExportToPdfReport(".pdf",null, true)}>
                                                    Εκτυπωτής
                                                </Dropdown.Item>
                                            )}
                                            {this.props.reportExcelPrint && (
                                                <Dropdown.Item onClick={() => this.handleExportToPdfReport()}>
                                                    Αποθήκευση ως PDF
                                                </Dropdown.Item>
                                            )}
                                            {!this.props.reportExcelPrint && (
                                                <Dropdown.Item onClick={() => this.handleExportToExcel()}>
                                                    Αποθήκευση ως Excel
                                                </Dropdown.Item>
                                            )}
                                            {this.props.reportExcelPrint && (
                                                <Dropdown.Item onClick={() => this.handleExportToExcelReport()}>
                                                    Αποθήκευση ως Excel
                                                </Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    )}
                                </Dropdown>
                            )}
                            {this.props.allowBulkDelete && (
                                <Button
                                    onClick={() => this.handleBulkDelete()}
                                    variant="primary"
                                >
                                    {currentUser?.lang && currentUser.lang === "el" ? "Διαγραφή Επιλεγμένων" : "Delete Selected"}
                                </Button>
                            )}
                            <Modal className="themeModal" show={this.state.tabCloseWarning}>
                                <Modal.Header>
                                    <Modal.Title>{currentUser?.lang && currentUser.lang === "el" ? "Προειδοποίηση!" : "Warning!"}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{currentUser?.lang && currentUser.lang === "el" ? "Προειδοποίηση: Έχετε επιλέξει" : "Warning: You have selected"} {this.state.checkedRows.length} {currentUser?.lang && currentUser.lang === "el" ? "παραστατικά για αποστολή στο MyData, θέλετε να συνεχίσετε;" : "documents to be sent to MyData, do you want to continue?"}</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="outline-primary" onClick={() => {
                                        this.setState((state) => {
                                            if (state.tabCloseWarning) {
                                                return {
                                                    tabCloseWarning: false,
                                                };
                                            }
                                        });
                                    }}>{currentUser?.lang && currentUser.lang === "el" ? "Όχι" : "Νο"}</Button>
                                    <Button variant="primary"
                                            onClick={this.sendSelectedDocumentsToMyData}>{currentUser?.lang && currentUser.lang === "el" ? "Ναι" : "Yes"}</Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal className="themeModal" show={this.state.cancelTabCloseWarning}>
                                <Modal.Header>
                                    <Modal.Title>{currentUser?.lang && currentUser.lang === "el" ? "Προειδοποίηση!" : "Warning!"}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{currentUser?.lang && currentUser.lang === "el" ? "Προειδοποίηση: Έχετε επιλέξει" : "Warning: You have selected"} {this.state.checkedRows.length} {currentUser?.lang && currentUser.lang === "el" ? "παραστατικά για ακύρωση στο MyData, θέλετε να συνεχίσετε;" : "documents to be cancelled to MyData, do you want to continue?"}</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="outline-primary" onClick={() => {
                                        this.setState((state) => {
                                            if (state.cancelTabCloseWarning) {
                                                return {
                                                    cancelTabCloseWarning: false,
                                                };
                                            }
                                        });
                                    }}>{currentUser?.lang && currentUser.lang === "el" ? "Όχι" : "Νο"}</Button>
                                    <Button variant="primary"
                                            onClick={this.cancelSelectedDocumentsToMyData}>{currentUser?.lang && currentUser.lang === "el" ? "Ναι" : "Yes"}</Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal className="themeModalBig" show={this.state.showResponseSentToMyData}>
                                <Modal.Header>
                                    <Modal.Title>{currentUser?.lang && currentUser.lang === "el" ? "Αποστολή Αναφοράς στο MyData" : "Send To MyData Report"}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{currentUser?.lang && currentUser.lang === "el" ? "Παρακάτω εμφανίζονται τα αποτελέσματα για την αποστολή των επιλεγμένων Παραστατικών στο MyData:" : "Below are shown the results for the sending of the selected Documents to MyData:"}<br/> {this.state.responseSentToMyData}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="outline-primary" onClick={() => {
                                        this.setState((state) => {
                                            if (state.showResponseSentToMyData) {
                                                return {
                                                    showResponseSentToMyData: false,
                                                };
                                            }
                                        });
                                    }}>OK</Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal className="themeModalBig" show={this.state.showResponseCancelToMyData}>
                                <Modal.Header>
                                    <Modal.Title>{currentUser?.lang && currentUser.lang === "el" ? "Αποστολή Αναφοράς στο MyData" : "Send To MyData Report"}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{currentUser?.lang && currentUser.lang === "el" ? "Παρακάτω εμφανίζονται τα αποτελέσματα για την ακύρωση των επιλεγμένων Παραστατικών στο MyData:" : "Below are shown the results for the canceling of the selected Documents to MyData:"}<br/> {this.state.responseCancelToMyData}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="outline-primary" onClick={() => {
                                        this.setState((state) => {
                                            if (state.showResponseCancelToMyData) {
                                                return {
                                                    showResponseCancelToMyData: false,
                                                };
                                            }
                                        });
                                    }}>OK</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    )}
                    {this.props.sendSelectedToMyData && (
                        <Button className="sendSelected"
                                disabled={this.state.checkedRows.length === 0 || this.state.loadingSendSelected}
                                onClick={() => {
                                    this.setState((state) => {
                                        if (!state.tabCloseWarning) {
                                            return {
                                                tabCloseWarning: true,
                                            };
                                        }
                                    });
                                }} variant="primary">
                            {this.state.loadingSendSelected && (
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            )}
                            {currentUser?.lang && currentUser.lang === "el" ? "Αποστολή Επιλεγμένων παραστατικών στο MyData" : "Send Selected Documents to MyData"}</Button>
                    )}
                    {this.props.cancelSelectedToMyData && (
                        <Button className="cancelSelected"
                                disabled={this.state.checkedRows.length === 0 || this.state.loadingSendSelected}
                                onClick={() => {
                                    this.setState((state) => {
                                        if (!state.cancelTabCloseWarning) {
                                            return {
                                                cancelTabCloseWarning: true,
                                            };
                                        }
                                    });
                                }} variant="primary">
                            {this.state.loadingSendSelected && (
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            )}
                            {currentUser?.lang && currentUser.lang === "el" ? "Ακύρωση Επιλεγμένων παραστατικών στο MyData" : "Cancel Selected Documents to MyData"}</Button>
                    )}
                    <DeleteModal
                        showDelete={this.state.showDelete}
                        showDeleteModal={this.showDeleteModal}
                        toBeDeleted={this.state.toBeDeleted}
                        tableName={this.state.tableName}
                        updateFunction={this.props.handleAfterDelete}
                    />
                </div>
            </>
        );
    }
}
export default EditableTable;
