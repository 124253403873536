import React from "react";
import {isEmpty} from "../../../_helpers/commonFunctions";
import {epayLogoBase64, vivaLogoBase64} from "./print-constants";

const SalesPrintA5DoubleGreek = React.forwardRef((props, ref) => {
    let templateData = [];
    require("./scss/sales-print-a5-double.css");
    if (props.data) {
        templateData = props.data;
    }
    let showWaterMark = templateData.MYDATAENVIRONMENT === "test";

    const marginzero = {
        margin: "0"
    }
    const transactionDetailsBlock = {
        border: "1px solid #555",
        padding: "8px",
        borderRadius: "8px",
        fontSize: "9px",
        overflow: "hidden",
        overflowWrap: "break-word",
        textAlign: "center",
        fontWeight: "500",
    }

    const balanceContainer = {
        width: "200px",
        fontSize: "10px",
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid #555",
        borderRadius: "8px",
        padding: "2px",
        fontWeight: "bold",
        textAlign: "center",
    }

    const totalPagesA = templateData.TOTAL_PAGES !== undefined ? templateData.TOTAL_PAGES : [];

    return(
        <React.Fragment>
            <div style={marginzero} ref={ref}>
                { totalPagesA.length > 0 && totalPagesA.map((currentPage1,index) => (
                    <div className="sales-print-template" style={{paddingTop: "20px"}}>
                        <div className="a5-receipt-double a5-invoice-box">
                            <div className="a5-top_table">
                                <div className="a5-paging">Σελίδα: {index + 1} από {totalPagesA.length}</div>
                                <table cellPadding="0" cellSpacing="0">
                                    <tr className="top">
                                        <td>
                                            <table className="a5-header_table">
                                                <tr>
                                                    {templateData.BRANCH === "" && (
                                                        <td className="a5-issuer_container">
                                                            {templateData.ISSUER_NAME && templateData.ISSUER_NAME !== "" && <span className="a5-issuer_name a5-skin">{templateData.ISSUER_NAME}</span>}
                                                            {templateData.ISSUER_SMALL_NAME && templateData.ISSUER_SMALL_NAME !== "" && <p><span className="a5-issuer_subjectField a5-skin">{templateData.ISSUER_SMALL_NAME}</span></p>}
                                                            {templateData.ISSUER_SUBJECTFIELD && templateData.ISSUER_SUBJECTFIELD !== "" && <p><span className="a5-issuer_address a5-skin">{templateData.ISSUER_SUBJECTFIELD}</span></p>}
                                                            {templateData.ISSUER_ADDRESS && templateData.ISSUER_ADDRESS !== "" && <p><span className="a5-issuer_address a5-skin">{templateData.ISSUER_ADDRESS}, {templateData.ISSUER_CITY}, {templateData.ISSUER_TK}, ΤΗΛ: {templateData.ISSUER_PHONE}, ΔΟΥ: {templateData.ISSUER_DOY}</span></p>}
                                                            {templateData.ISSUER_VAT && templateData.ISSUER_VAT !== "" && <p><span className="a5-issuer_vat a5-skin">ΑΦΜ: {templateData.ISSUER_VAT}, {!isEmpty(templateData.ISSUER_GEMH) && `ΓΕΜΗ: ${templateData.ISSUER_GEMH}, `} EMAIL: {templateData.ISSUER_EMAIL}</span></p>}
                                                            {!isEmpty(templateData.ISSUER_WEBPAGE) && (
                                                                <p><span className="a5-issuer_vat a5-skin">Ηλεκ. Ιστοσελίδα: {templateData.ISSUER_WEBPAGE}</span></p>
                                                            )}
                                                        </td>
                                                    )}
                                                    {templateData.BRANCH !== "" && (
                                                        <td className="a5-issuer_container">
                                                            {templateData.ISSUER_NAME && templateData.ISSUER_NAME !== "" && <span className="a5-issuer_name a5-skin">{templateData.ISSUER_NAME}</span>}
                                                            {templateData.ISSUER_SMALL_NAME && templateData.ISSUER_SMALL_NAME !== "" && <p><span className="a5-issuer_subjectField a5-skin">{templateData.ISSUER_SMALL_NAME}</span></p>}
                                                            {templateData.ISSUER_SUBJECTFIELD && templateData.ISSUER_SUBJECTFIELD !== "" && <p><span className="a5-issuer_subjectField a5-skin">{templateData.ISSUER_SUBJECTFIELD}</span></p>}
                                                            {templateData.ISSUER_ADDRESS && templateData.ISSUER_ADDRESS !== "" && <p><span className="a5-issuer_address a5-skin">Υποκατάστημα: {templateData.BRANCH?.address}, {templateData.BRANCH?.city}, {templateData.BRANCH?.postalCode}</span></p>}
                                                            {templateData.ISSUER_PHONE && templateData.ISSUER_PHONE !== "" && <p><span className="a5-issuer_address a5-skin">ΤΗΛΕΦΩΝΟ: {templateData.BRANCH?.phone}</span></p>}
                                                            {templateData.ISSUER_DOY && templateData.ISSUER_DOY !== "" && <p><span className="a5-issuer_address a5-skin">ΔΟΥ: {templateData.BRANCH?.doy}</span></p>}
                                                            {templateData.ISSUER_VAT && templateData.ISSUER_VAT !== "" && <p><span className="a5-issuer_address a5-skin">ΑΦΜ: {templateData.ISSUER_VAT}</span></p>}
                                                            {templateData.ISSUER_GEMH && templateData.ISSUER_GEMH !== "" && <p><span className="a5-issuer_address a5-skin">ΓΕΜΗ: {templateData.ISSUER_GEMH}</span></p>}
                                                            {templateData.ISSUER_EMAIL && templateData.ISSUER_EMAIL !== "" && <p><span className="a5-issuer_address a5-skin">EMAIL: {templateData.ISSUER_EMAIL}</span></p>}
                                                            {!isEmpty(templateData.ISSUER_WEBPAGE) && (
                                                                <p><span className="a5-issuer_vat a5-skin">Ηλεκ. Ιστοσελίδα: {templateData.ISSUER_WEBPAGE}</span></p>
                                                            )}
                                                        </td>
                                                    )}
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <div className="a5-main_info">
                                    <table cellPadding="0" cellSpacing="0">
                                        <tr>
                                            <table className="a5-main_info_table">
                                                <tr className="a5-heading">
                                                    <td className="a5-p">ΕΙΔΟΣ ΠΑΡΑΣΤΑΤΙΚΟΥ</td>
                                                    <td className="a5-p">ΣΕΙΡΑ</td>
                                                    <td className="a5-p">ΑΡΙΘΜΟΣ</td>
                                                    <td className="a5-p">ΗΜΕΡ/ΝΙΑ</td>
                                                    <td className="a5-p">ΩΡΑ</td>
                                                </tr>
                                                <tr>
                                                    <td><span className="a5-invoice_type">{templateData.INVOICE_TYPE ? templateData.INVOICE_TYPE : '-'}</span></td>
                                                    <td><span className="a5-invoice_number">{templateData.SERIES ? templateData.SERIES : '-'}</span></td>
                                                    <td><span className="a5-invoice_number">{templateData.NUMBER ? templateData.NUMBER : '-'}</span></td>
                                                    <td><span className="a5-invoice_date">{templateData.INVOICE_DATE ? templateData.INVOICE_DATE : '-'}</span></td>
                                                    <td><span className="a5-invoice_time">{templateData.INVOICE_TIME ? templateData.INVOICE_TIME : '-'}</span></td>
                                                </tr>
                                            </table>
                                        </tr>
                                    </table>
                                </div>
                                <table className="a5-information_table" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                    <tr>
                                        <td className="a5-information_td_left">
                                            <div className="a5-information left">
                                                <table className="a5-information_customer">
                                                    <tr>
                                                        <td className="a5-information_customer_td a5-skin a5-bold"><span> ΟΝΟΜΑΤΕΠΩΝΥΜΟ</span></td>
                                                        <td className="a5-info_value"><span>: </span><span className="a5-counterparty_name">{templateData.CUSTOMER_NAME}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="a5-skin a5-bold"><span> ΕΠΑΓΓΕΛΜΑ</span></td>
                                                        <td className="a5-info_value"><span>: </span><span className="a5-counterparty_activity">ΙΔΙΩΤΗΣ</span></td>
                                                    </tr>

                                                    <tr>
                                                        <td className="a5-skin a5-bold"><span> EMAIL ΠΕΛΑΤΗ</span></td>
                                                        <td className="a5-info_value"><span>: </span><span className="a5-counterparty_name">{templateData.CUSTOMER_EMAIL}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="a5-skin a5-bold"><span> ΔΙΕΥΘΥΝΣΗ</span></td>
                                                        <td className="a5-info_value"><span>: </span><span className="a5-counterparty_name">{templateData.CUSTOMER_ADDRESS}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="a5-skin a5-bold"><span> ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ</span></td>
                                                        <td className="a5-info_value"><span>: </span><span className="a5-counterparty_name">{templateData.PAYMENT_METHOD}</span></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            {(templateData?.PAGE_TYPES && templateData?.PAGE_TYPES.length > 0
                                && templateData.PAGE_TYPES.find((el) => el.index === index)?.type === "PAYMENT_HISTORY" && !templateData.TRANSACTION_DETAILS) ? (
                                <React.Fragment>
                                    <div style={{
                                        textAlign: "center",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                        marginBottom: "5px",
                                        color: "white",
                                        backgroundColor: "#555",
                                        fontWeight: "bold"
                                    }}>ΙΣΤΟΡΙΚΟ ΠΛΗΡΩΜΩΝ
                                    </div>
                                    <div style={{
                                        minHeight: "500px",
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap"
                                    }}>
                                        {templateData.PAYMENT_HISTORY[index].map((transDetails) => {
                                            if (transDetails.providerSignature) {
                                                return (
                                                    <div style={{
                                                        ...transactionDetailsBlock,
                                                        width: "50%",
                                                        maxWidth: "50%",
                                                        flex: "50%",
                                                        height: "220px",
                                                    }}>
                                                        {transDetails?.isEuronet === "true" && (
                                                            <React.Fragment>
                                                                <img style={{margin: "0", width: "20mm"}} className={"mb-2"}
                                                                     src={epayLogoBase64} alt={"ePay Logo"}/>
                                                                <br/>
                                                                <span style={{fontSize: "7px"}}>EURONET MERCHANT SERVICES A.E. GEMH: 159277401000</span>
                                                            </React.Fragment>
                                                        )}
                                                        {transDetails?.isEuronet === "viva" && (
                                                            <React.Fragment>
                                                                <img style={{margin: "0", width: "15mm"}} src={vivaLogoBase64} alt={"Viva Logo"}/>
                                                                <br/>
                                                            </React.Fragment>
                                                        )}
                                                        <table className={"transactionDetailsTable"}
                                                               style={{width: "100%", tableLayout: "fixed"}}>
                                                            <tbody>
                                                            <tr>
                                                                <td>&nbsp;</td>
                                                                <td>{transDetails?.transactionDate}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>TID/MID:</td>
                                                                <td>{transDetails?.terminalId} / {transDetails?.merchantId}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΑΡ.
                                                                    ΣΥΝ.: {transDetails?.transactionNumber}</td>
                                                                <td>ΕΓΚΡΙΣΗ: {transDetails?.authorizationCode}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>RRN.:</td>
                                                                <td>{transDetails?.rnn}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΜΕΣΩ:
                                                                    {transDetails?.isEuronet === "true" && `EPAY`}
                                                                    {transDetails?.isEuronet === "viva" && `VIVA`}
                                                                </td>
                                                                <td>{transDetails?.cardType} {transDetails?.aid} {transDetails?.panCard} - {transDetails?.finalCode}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΠΟΣΟ:</td>
                                                                <td>{transDetails?.amountTotalString} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΦΙΛΟΔΩΡΗΜΑ:</td>
                                                                <td>{transDetails?.tipAmountString} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΥΠΟΓΡΑΦΗ ΠΑΡΟΧΟΥ:</td>
                                                                <td style={{wordBreak: "break-all"}}>{transDetails?.providerSignature}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΜΟΝ. ΤΑΥΤ. ΠΛΗΡΩΜΗΣ:</td>
                                                                <td>{transDetails?.providerTransactionId}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div style={{
                                                        ...transactionDetailsBlock,
                                                        width: "50%",
                                                        maxWidth: "50%",
                                                        flex: "50%",
                                                        height: "220px",
                                                        fontSize: "10px"
                                                    }}>
                                                        <table className={"transactionDetailsTable"}
                                                               style={{width: "100%", tableLayout: "fixed"}}>
                                                            <tbody>
                                                            <tr>
                                                                <td>ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ</td>
                                                                <td>{transDetails?.paymentTypeName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΗΜΕΡΟΜΗΝΙΑ ΠΛΗΡΩΜΗΣ</td>
                                                                <td>{transDetails?.paymentDate}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΠΟΣΟ</td>
                                                                <td>{transDetails?.paymentAmount}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div className="a5-product_container">
                                    <table className="a5-product_table" cellPadding="0" cellSpacing="0">
                                        <tr className="a5-heading">
                                            <td className="a5-description_head_td a5-p">ΚΩΔΙΚΟΣ</td>
                                            <td className="a5-description_head_td a5-p">ΠΕΡΙΓΡΑΦΗ</td>
                                            <td className="a5-quantity_head_td a5-p">ΠΟΣΟΤΗΤΑ</td>
                                            <td className="a5-up_head_td a5-p">ΤΙΜΗ ΜΟΝΑΔΑΣ</td>
                                            <td className="a5-vat_head_td a5-p">ΦΠΑ %</td>
                                            <td className="a5-pricenovat_head_td a5-p">ΕΚΠΤΩΣΗ</td>
                                            <td className="a5-pricenovat_head_td a5-p">ΤΙΜΗ ΠΡΟ ΦΠΑ</td>
                                            <td className="a5-pricenovat_head_td a5-p">ΑΞΙΑ ΦΠΑ</td>
                                            <td className="a5-whtax_head_td a5-p">ΦΟΡΟΙ/ΤΕΛΗ</td>
                                            <td className="a5-price_head_td a5-p">ΤΕΛΙΚΗ ΑΞΙΑ</td>
                                        </tr>
                                        {templateData.PRODUCT_DETAILS && templateData.PRODUCT_DETAILS[index]?.length > 0 && templateData.PRODUCT_DETAILS[index].map((product) => (
                                            <tr className="a5-products" key={Math.random()}>
                                                <td><span className="a5-item_code">{product.CODE}</span></td>
                                                <td><span
                                                    className="a5-item_description">{product.PRODUCT_DESCRIPTION}</span>
                                                </td>
                                                <td><span className="a5-item_quantity">{product.QTY}</span></td>
                                                <td><span className="a5-item_unit_price">{product.PRICE_QTY}</span></td>
                                                <td><span className="a5-item_vat">{product.VAT}{product?.DONT_SHOW_PERCENTAGE === true ? "" : "%"}</span></td>
                                                <td><span className="a5-item_discount">{product.DISCOUNT}</span></td>
                                                <td><span className="a5-item_price_no_vat">{product.PRICE_NOVAT}</span>
                                                </td>
                                                <td><span className="a5-item_vat_price">{product.VAT_PRICE}</span></td>
                                                <td><span className="a5-item_other_taxes">{product.OTHER_TAXES}</span>
                                                </td>
                                                <td><span className="a5-item_total_price">{product.TOTAL}</span></td>
                                            </tr>
                                        ))}
                                    </table>
                                </div>
                            )}
                            <div className="a5-footer_container">
                                <table className="a5-footer_table" style={{tableLayout: "fixed", width: "100%"}}>
                                    <tbody>
                                    <tr>
                                        <td className="a5-vat_total_td">
                                            {(templateData.OLD_BALANCE && templateData.NEW_BALANCE && !templateData.ENABLE_PEPPOL) &&
                                                <div style={balanceContainer}>
                                                    <div>
                                                        <div style={{margin: "auto"}}>Προηγ. Υπόλοιπο</div>
                                                        <div style={{margin: "auto", fontWeight: "normal"}}>{templateData.OLD_BALANCE}</div>
                                                    </div>
                                                    <div>
                                                        <div style={{margin: "auto"}}>Νέο Υπόλοιπο</div>
                                                        <div style={{margin: "auto", fontWeight: "normal"}}>{templateData.NEW_BALANCE}</div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="a5-per_vat_totals_container">
                                                <table className="a5-totals_per_vat">
                                                    <tr>
                                                        <th>Συντελεστής ΦΠΑ</th>
                                                        <th>Καθαρή Αξία</th>
                                                        <th>Αξία ΦΠΑ</th>
                                                        <th>Φόροι/Τέλη</th>
                                                        <th>Τελική Αξία</th>
                                                    </tr>
                                                    {templateData.VAT_TABLE && index + 1 === totalPagesA.length && templateData.VAT_TABLE?.length > 0 && templateData.VAT_TABLE.map((taxes) => {
                                                        return <tr key={Math.random()}>
                                                            <td>{taxes.TAX_PERCENT} {taxes.TAX_CODE ? `(${taxes.TAX_CODE})` : null}</td>
                                                            <td>{taxes.NET_VALUE}</td>
                                                            <td>{taxes.TAX_VALUE}</td>
                                                            <td>{taxes.OTHER_TAXES}</td>
                                                            <td>{taxes.TOTAL_VALUE}</td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                            <div className="a5-cont_notation">
                                                {templateData.VATEXPCAT && templateData.VATEXPCAT?.length > 0 && templateData.VATEXPCAT.map((reason) => (
                                                    <>
                                                        <span className="skin bold">ΑΠΑΛΛΑΓΗ ΑΠΟ ΤΟ Φ.Π.Α. :</span>
                                                        <div className="a5-cont_notation_inner">
                                                            <span>{reason.vat} {reason.description} <br/></span>
                                                        </div>
                                                    </>
                                                ))}
                                                <span className="a5-skin a5-bold">ΠΑΡΑΤΗΡΗΣΕΙΣ: <span dangerouslySetInnerHTML={{__html: String(templateData?.INVOICE_NOTES).replaceAll("\n", "<br/>")}}></span></span>
                                            </div>
                                        </td>
                                        {templateData?.UID !== "" && templateData.MYDATADESTINATION === "provider" && (
                                            <td>
                                                <div className="a5-cont_signs">
                                                    <img src={templateData?.QR_CODE} alt="Qr code"
                                                         className="a5-product-qr-code-img"/>
                                                </div>
                                            </td>
                                        )}
                                        {templateData?.UID !== "" && templateData.MYDATADESTINATION !== "provider" && templateData.MYDATAQRURL && (
                                            <td>
                                                <div className="a5-cont_signs">
                                                    <img src={templateData?.QR_CODE} alt="Qr code"
                                                         className="a5-product-qr-code-img"/>
                                                </div>
                                            </td>
                                        )}
                                        {((templateData?.TRANSACTION_DETAILS && index + 1 === totalPagesA.length) && (
                                            <td>
                                                {(templateData?.TRANSACTION_DETAILS?.providerSignature) ? (
                                                    <div style={{...transactionDetailsBlock, width: "100%", fontSize: "8px", height: "190px"}}>
                                                        {templateData.TRANSACTION_DETAILS?.isEuronet === "true" && (
                                                            <React.Fragment>
                                                                <img style={{margin: "0", width: "20mm"}} className={"mb-2"}
                                                                     src={epayLogoBase64} alt={"ePay Logo"}/>
                                                                <br/>
                                                                <span style={{fontSize: "7px"}}>EURONET MERCHANT SERVICES A.E. GEMH: 159277401000</span>
                                                            </React.Fragment>
                                                        )}
                                                        {templateData.TRANSACTION_DETAILS?.isEuronet === "viva" && (
                                                            <React.Fragment>
                                                                <img style={{margin: "0", width: "15mm"}} src={vivaLogoBase64} alt={"Viva Logo"}/>
                                                                <br/>
                                                            </React.Fragment>
                                                        )}
                                                        <table className={"transactionDetailsTable"} style={{width: "100%", tableLayout: "fixed", fontSize: "8px"}} cellSpacing={0} cellPadding={0}>
                                                            <tbody>
                                                            <tr>
                                                                <td>&nbsp;</td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.transactionDate}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>TID/MID:</td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.terminalId} / {templateData.TRANSACTION_DETAILS?.merchantId}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΑΡ.
                                                                    ΣΥΝ.: {templateData.TRANSACTION_DETAILS?.transactionNumber}</td>
                                                                <td>ΕΓΚΡΙΣΗ: {templateData.TRANSACTION_DETAILS?.authorizationCode}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>RRN.:</td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.rnn}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΜΕΣΩ:
                                                                    {templateData.TRANSACTION_DETAILS?.isEuronet === "true" && `EPAY`}
                                                                    {templateData.TRANSACTION_DETAILS?.isEuronet === "viva" && `VIVA`}
                                                                </td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.cardType} {templateData.TRANSACTION_DETAILS?.aid} {templateData.TRANSACTION_DETAILS?.panCard} - {templateData.TRANSACTION_DETAILS?.finalCode}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΠΟΣΟ:</td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.amountTotalString} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΦΙΛΟΔΩΡΗΜΑ:</td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.tipAmountString} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΥΠΟΓΡΑΦΗ ΠΑΡΟΧΟΥ:</td>
                                                                <td style={{wordBreak: "break-all"}}>{templateData.TRANSACTION_DETAILS?.providerSignature}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΜΟΝ. ΤΑΥΤ. ΠΛΗΡΩΜΗΣ:</td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.providerTransactionId}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ) : (
                                                    <div style={{
                                                        ...transactionDetailsBlock,
                                                        width: "100%",
                                                        height: "190px"
                                                    }}>
                                                        <table className={"transactionDetailsTable"} style={{width: "100%", tableLayout: "fixed"}}>
                                                            <tbody>
                                                            <tr>
                                                                <td>ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ</td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.paymentTypeName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΗΜΕΡΟΜΗΝΙΑ ΠΛΗΡΩΜΗΣ</td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.paymentDate}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΠΟΣΟ</td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.paymentAmount}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </td>
                                        ))}
                                        <td className="a5-total_td_block">
                                            <div className="a5-count_totals_container">
                                                <span>ΣΥΝΟΛΟ ΤΕΜΑΧΙΩΝ: </span> <span
                                                className="a5-count_total_prods"> {index + 1 === totalPagesA.length ? templateData.TOTAL_QTY : ''}</span>
                                            </div>
                                            <div className="a5-total_container">
                                                <div className="a5-totals">
                                                    <table className="a5-totals_table">
                                                        <tr>
                                                            <td className="a5-text-left a5-p">ΣΥΝΟΛΟ ΧΩΡΙΣ ΦΠΑ</td>
                                                            <td className="a5-text-right a5-p">
                                                                <span
                                                                    className="a5-total_withoutvat">{index + 1 === totalPagesA.length ? templateData.TOTAL_NOVAT : ''}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="a5-text-left a5-p">Φ.Π.Α.</td>
                                                            <td className="a5-text-right a5-p">
                                                                <span
                                                                    className="a5-total_withoutvat">{index + 1 === totalPagesA.length ? templateData.TOTAL_VAT : ''}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="a5-text-left a5-p">ΤΕΛΙΚΟ ΣΥΝΟΛΟ</td>
                                                            <td className="a5-text-right a5-p">
                                                                <span
                                                                    className="a5-amounttotal">{index + 1 === totalPagesA.length ? templateData.TOTAL_PRICE : ''}</span>
                                                            </td>
                                                        </tr>
                                                        {templateData.TOTAL_WITHHOLD && (
                                                            <tr>
                                                                <td className="a5-text-left a5-p">ΣΥΝΟΛΟ ΠΑΡΑΚΡΑΤΟΥΜΕΝΩΝ
                                                                    ΦΟΡΩΝ
                                                                </td>
                                                                <td className="a5-text-right a5-p">
                                                                <span
                                                                    className="a5-amounttotal">{index + 1 === totalPagesA.length ? templateData.TOTAL_WITHHOLD : ''}</span>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        <tr className="a5-blank_row a5-bordered">
                                                            <td className="text-left">&nbsp;</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="a5-text-left a5-finalprice a5-p">ΠΛΗΡΩΤΕΟ
                                                                ΠΟΣΟ
                                                            </td>
                                                            <td className="a5-text-right a5-p">
                                                                <span
                                                                    className="a5-totalpayment">{index + 1 === totalPagesA.length ? templateData.TOTAL_PRICE : ''}</span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                {showWaterMark && <div className="a5-cancel">ΑΚΥΡΟ</div>}
                                {((templateData.TRANSMISSION_FAILURE && (templateData.TRANSMISSION_FAILURE === "1" || templateData.TRANSMISSION_FAILURE === "2") && (templateData.MYDATADESTINATION === "Provider" || templateData.MYDATADESTINATION === "provider")) || (templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                                    <div>
                                        <span className="a5-sender_sign">www.primer.gr/search-invoice Provided by Primer Software P.C.</span>
                                        <br/>
                                        <span className="a5-sender_sign">Άδεια ΥΠΑΗΕΣ: 2021_01_107Primer Software ΙΚΕ_001_ Primer MyData_V1_21012021</span>
                                    </div>
                                }
                                <br/>
                                {index + 1 === totalPagesA.length && (
                                    <div className="a5-mydata_sign a5-p">
                                        {((templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "1" && (templateData.MYDATADESTINATION === "Provider" || templateData.MYDATADESTINATION === "provider")) || (templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "1" && templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                                            <span className="a5-uid_sign">Απώλεια Διασύνδεσης Οντότητας - Παρόχου - Transmission Failure_1<br/></span>}
                                        {((templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "2" && (templateData.MYDATADESTINATION === "Provider" || templateData.MYDATADESTINATION === "provider")) || (templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "2" && templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                                            <span className="a5-uid_sign">Απώλεια Διασύνδεσης Παρόχου - ΑΑΔΕ - Transmission Failure_2<br/></span>}
                                        {(templateData.TRANSMISSION_FAILURE === "3" && templateData.MYDATADESTINATION === "MyData") && (
                                            <span>Απώλεια Διασύνδεσης ERP – Α.Α.Δ.Ε. - Transmission Failure_3<br/></span>
                                        )}
                                        {templateData.UID && templateData.UID !== "" && templateData.AUTHCODE !== "" &&
                                            <span className="a5-uid_sign"> UID: {templateData.UID}</span>}
                                        {templateData.MARK && templateData.MARK !== "" &&
                                            <span> Μ.ΑΡ.Κ.: {templateData.MARK}</span>}
                                        {templateData.AUTHCODE && templateData.AUTHCODE !== "" &&
                                            <span className="a5-uid_sign"><br/> AUTHENTICATION CODE: {templateData.AUTHCODE}</span>}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="a5-receipt-double a5-invoice-box a5-double-clone">
                            <div className="a5-top_table">
                                <div className="a5-paging">Σελίδα: {index + 1} από {totalPagesA.length}</div>
                                <table cellPadding="0" cellSpacing="0">
                                    <tr className="top">
                                        <td>
                                            <table className="a5-header_table">
                                                <tr>
                                                    {templateData.BRANCH === "" && (
                                                        <td className="a5-issuer_container">
                                                            {templateData.ISSUER_NAME && templateData.ISSUER_NAME !== "" && <span className="a5-issuer_name a5-skin">{templateData.ISSUER_NAME}</span>}
                                                            {templateData.ISSUER_SMALL_NAME && templateData.ISSUER_SMALL_NAME !== "" && <p><span className="a5-issuer_subjectField a5-skin">{templateData.ISSUER_SMALL_NAME}</span></p>}
                                                            {templateData.ISSUER_SUBJECTFIELD && templateData.ISSUER_SUBJECTFIELD !== "" && <p><span className="a5-issuer_address a5-skin">{templateData.ISSUER_SUBJECTFIELD}</span></p>}
                                                            {templateData.ISSUER_ADDRESS && templateData.ISSUER_ADDRESS !== "" && <p><span className="a5-issuer_address a5-skin">{templateData.ISSUER_ADDRESS}, {templateData.ISSUER_CITY}, {templateData.ISSUER_TK}, ΤΗΛ: {templateData.ISSUER_PHONE}, ΔΟΥ: {templateData.ISSUER_DOY}</span></p>}
                                                            {templateData.ISSUER_VAT && templateData.ISSUER_VAT !== "" && <p><span className="a5-issuer_vat a5-skin">ΑΦΜ: {templateData.ISSUER_VAT}, {!isEmpty(templateData.ISSUER_GEMH) && `ΓΕΜΗ: ${templateData.ISSUER_GEMH}, `} EMAIL: {templateData.ISSUER_EMAIL}</span></p>}
                                                            {!isEmpty(templateData.ISSUER_WEBPAGE) && (
                                                                <p><span className="a5-issuer_vat a5-skin">Ηλεκ. Ιστοσελίδα: {templateData.ISSUER_WEBPAGE}</span></p>
                                                            )}
                                                        </td>
                                                    )}
                                                    {templateData.BRANCH !== "" && (
                                                        <td className="a5-issuer_container">
                                                            {templateData.ISSUER_NAME && templateData.ISSUER_NAME !== "" && <span className="a5-issuer_name a5-skin">{templateData.ISSUER_NAME}</span>}
                                                            {templateData.ISSUER_SMALL_NAME && templateData.ISSUER_SMALL_NAME !== "" && <p><span className="a5-issuer_subjectField a5-skin">{templateData.ISSUER_SMALL_NAME}</span></p>}
                                                            {templateData.ISSUER_SUBJECTFIELD && templateData.ISSUER_SUBJECTFIELD !== "" && <p><span className="a5-issuer_subjectField a5-skin">{templateData.ISSUER_SUBJECTFIELD}</span></p>}
                                                            {templateData.ISSUER_ADDRESS && templateData.ISSUER_ADDRESS !== "" && <p><span className="a5-issuer_address a5-skin">Υποκατάστημα: {templateData.BRANCH?.address}, {templateData.BRANCH?.city}, {templateData.BRANCH?.postalCode}</span></p>}
                                                            {templateData.ISSUER_PHONE && templateData.ISSUER_PHONE !== "" && <p><span className="a5-issuer_address a5-skin">ΤΗΛΕΦΩΝΟ: {templateData.BRANCH?.phone}</span></p>}
                                                            {templateData.ISSUER_DOY && templateData.ISSUER_DOY !== "" && <p><span className="a5-issuer_address a5-skin">ΔΟΥ: {templateData.BRANCH?.doy}</span></p>}
                                                            {templateData.ISSUER_VAT && templateData.ISSUER_VAT !== "" && <p><span className="a5-issuer_address a5-skin">ΑΦΜ: {templateData.ISSUER_VAT}</span></p>}
                                                            {templateData.ISSUER_GEMH && templateData.ISSUER_GEMH !== "" && <p><span className="a5-issuer_address a5-skin">ΓΕΜΗ: {templateData.ISSUER_GEMH}</span></p>}
                                                            {templateData.ISSUER_EMAIL && templateData.ISSUER_EMAIL !== "" && <p><span className="a5-issuer_address a5-skin">EMAIL: {templateData.ISSUER_EMAIL}</span></p>}
                                                            {!isEmpty(templateData.ISSUER_WEBPAGE) && (
                                                                <p><span className="a5-issuer_vat a5-skin">Ηλεκ. Ιστοσελίδα: {templateData.ISSUER_WEBPAGE}</span></p>
                                                            )}
                                                        </td>
                                                    )}
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <div className="a5-main_info">
                                    <table cellPadding="0" cellSpacing="0">
                                        <tr>
                                            <table className="a5-main_info_table">
                                                <tr className="a5-heading">
                                                    <td className="a5-p">ΕΙΔΟΣ ΠΑΡΑΣΤΑΤΙΚΟΥ</td>
                                                    <td className="a5-p">ΣΕΙΡΑ</td>
                                                    <td className="a5-p">ΑΡΙΘΜΟΣ</td>
                                                    <td className="a5-p">ΗΜΕΡ/ΝΙΑ</td>
                                                    <td className="a5-p">ΩΡΑ</td>
                                                </tr>
                                                <tr>
                                                    <td><span className="a5-invoice_type">{templateData.INVOICE_TYPE ? templateData.INVOICE_TYPE : '-'}</span></td>
                                                    <td><span className="a5-invoice_number">{templateData.SERIES ? templateData.SERIES : '-'}</span></td>
                                                    <td><span className="a5-invoice_number">{templateData.NUMBER ? templateData.NUMBER : '-'}</span></td>
                                                    <td><span className="a5-invoice_date">{templateData.INVOICE_DATE ? templateData.INVOICE_DATE : '-'}</span></td>
                                                    <td><span className="a5-invoice_time">{templateData.INVOICE_TIME ? templateData.INVOICE_TIME : '-'}</span></td>
                                                </tr>
                                            </table>
                                        </tr>
                                    </table>
                                </div>
                                <table className="a5-information_table" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                    <tr>
                                        <td className="a5-information_td_left">
                                            <div className="a5-information left">
                                                <table className="a5-information_customer">
                                                    <tr>
                                                        <td className="a5-information_customer_td a5-skin a5-bold"><span> ΟΝΟΜΑΤΕΠΩΝΥΜΟ</span></td>
                                                        <td className="a5-info_value"><span>: </span><span className="a5-counterparty_name">{templateData.CUSTOMER_NAME}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="a5-skin a5-bold"><span> ΕΠΑΓΓΕΛΜΑ</span></td>
                                                        <td className="a5-info_value"><span>: </span><span className="a5-counterparty_activity">ΙΔΙΩΤΗΣ</span></td>
                                                    </tr>

                                                    <tr>
                                                        <td className="a5-skin a5-bold"><span> EMAIL ΠΕΛΑΤΗ</span></td>
                                                        <td className="a5-info_value"><span>: </span><span className="a5-counterparty_name">{templateData.CUSTOMER_EMAIL}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="a5-skin a5-bold"><span> ΔΙΕΥΘΥΝΣΗ</span></td>
                                                        <td className="a5-info_value"><span>: </span><span className="a5-counterparty_name">{templateData.CUSTOMER_ADDRESS}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="a5-skin a5-bold"><span> ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ</span></td>
                                                        <td className="a5-info_value"><span>: </span><span className="a5-counterparty_name">{templateData.PAYMENT_METHOD}</span></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            {(templateData?.PAGE_TYPES && templateData?.PAGE_TYPES.length > 0
                                && templateData.PAGE_TYPES.find((el) => el.index === index)?.type === "PAYMENT_HISTORY" && !templateData.TRANSACTION_DETAILS) ? (
                                <React.Fragment>
                                    <div style={{
                                        textAlign: "center",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                        marginBottom: "5px",
                                        color: "white",
                                        backgroundColor: "#555",
                                        fontWeight: "bold"
                                    }}>ΙΣΤΟΡΙΚΟ ΠΛΗΡΩΜΩΝ
                                    </div>
                                    <div style={{
                                        minHeight: "500px",
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap"
                                    }}>
                                        {templateData.PAYMENT_HISTORY[index].map((transDetails) => {
                                            if (transDetails.providerSignature) {
                                                return (
                                                    <div style={{
                                                        ...transactionDetailsBlock,
                                                        width: "50%",
                                                        maxWidth: "50%",
                                                        flex: "50%",
                                                        height: "220px",
                                                    }}>
                                                        {templateData.TRANSACTION_DETAILS?.isEuronet === "true" && (
                                                            <React.Fragment>
                                                                <img style={{margin: "0", width: "20mm"}} className={"mb-2"}
                                                                     src={epayLogoBase64} alt={"ePay Logo"}/>
                                                                <br/>
                                                                <span style={{fontSize: "7px"}}>EURONET MERCHANT SERVICES A.E. GEMH: 159277401000</span>
                                                            </React.Fragment>
                                                        )}
                                                        {templateData.TRANSACTION_DETAILS?.isEuronet === "viva" && (
                                                            <React.Fragment>
                                                                <img style={{margin: "0", width: "15mm"}} src={vivaLogoBase64} alt={"Viva Logo"}/>
                                                                <br/>
                                                            </React.Fragment>
                                                        )}
                                                        <table className={"transactionDetailsTable"}
                                                               style={{width: "100%", tableLayout: "fixed"}}>
                                                            <tbody>
                                                            <tr>
                                                                <td>&nbsp;</td>
                                                                <td>{transDetails?.transactionDate}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>TID/MID:</td>
                                                                <td>{transDetails?.terminalId} / {transDetails?.merchantId}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΑΡ.
                                                                    ΣΥΝ.: {transDetails?.transactionNumber}</td>
                                                                <td>ΕΓΚΡΙΣΗ: {transDetails?.authorizationCode}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>RRN.:</td>
                                                                <td>{transDetails?.rnn}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΜΕΣΩ:
                                                                    {transDetails?.isEuronet === "true" && `EPAY`}
                                                                    {transDetails?.isEuronet === "viva" && `VIVA`}
                                                                </td>
                                                                <td>{transDetails?.cardType} {transDetails?.aid} {transDetails?.panCard} - {transDetails?.finalCode}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΠΟΣΟ:</td>
                                                                <td>{transDetails?.amountTotalString} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΦΙΛΟΔΩΡΗΜΑ:</td>
                                                                <td>{transDetails?.tipAmountString} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΥΠΟΓΡΑΦΗ ΠΑΡΟΧΟΥ:</td>
                                                                <td style={{wordBreak: "break-all"}}>{transDetails?.providerSignature}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΜΟΝ. ΤΑΥΤ. ΠΛΗΡΩΜΗΣ:</td>
                                                                <td>{transDetails?.providerTransactionId}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div style={{
                                                        ...transactionDetailsBlock,
                                                        width: "50%",
                                                        maxWidth: "50%",
                                                        flex: "50%",
                                                        height: "220px",
                                                        fontSize: "10px"
                                                    }}>
                                                        <table className={"transactionDetailsTable"}
                                                               style={{width: "100%", tableLayout: "fixed"}}>
                                                            <tbody>
                                                            <tr>
                                                                <td>ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ</td>
                                                                <td>{transDetails?.paymentTypeName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΗΜΕΡΟΜΗΝΙΑ ΠΛΗΡΩΜΗΣ</td>
                                                                <td>{transDetails?.paymentDate}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΠΟΣΟ</td>
                                                                <td>{transDetails?.paymentAmount}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div className="a5-product_container">
                                    <table className="a5-product_table" cellPadding="0" cellSpacing="0">
                                        <tr className="a5-heading">
                                            <td className="a5-description_head_td a5-p">ΚΩΔΙΚΟΣ</td>
                                            <td className="a5-description_head_td a5-p">ΠΕΡΙΓΡΑΦΗ</td>
                                            <td className="a5-quantity_head_td a5-p">ΠΟΣΟΤΗΤΑ</td>
                                            <td className="a5-up_head_td a5-p">ΤΙΜΗ ΜΟΝΑΔΑΣ</td>
                                            <td className="a5-vat_head_td a5-p">ΦΠΑ %</td>
                                            <td className="a5-pricenovat_head_td a5-p">ΕΚΠΤΩΣΗ</td>
                                            <td className="a5-pricenovat_head_td a5-p">ΤΙΜΗ ΠΡΟ ΦΠΑ</td>
                                            <td className="a5-pricenovat_head_td a5-p">ΑΞΙΑ ΦΠΑ</td>
                                            <td className="a5-whtax_head_td a5-p">ΦΟΡΟΙ/ΤΕΛΗ</td>
                                            <td className="a5-price_head_td a5-p">ΤΕΛΙΚΗ ΑΞΙΑ</td>
                                        </tr>
                                        {templateData.PRODUCT_DETAILS && templateData.PRODUCT_DETAILS[index]?.length > 0 && templateData.PRODUCT_DETAILS[index].map((product) => (
                                            <tr className="a5-products" key={Math.random()}>
                                                <td><span className="a5-item_code">{product.CODE}</span></td>
                                                <td><span
                                                    className="a5-item_description">{product.PRODUCT_DESCRIPTION}</span>
                                                </td>
                                                <td><span className="a5-item_quantity">{product.QTY}</span></td>
                                                <td><span className="a5-item_unit_price">{product.PRICE_QTY}</span></td>
                                                <td><span className="a5-item_vat">{product.VAT}{product?.DONT_SHOW_PERCENTAGE === true ? "" : "%"}</span></td>
                                                <td><span className="a5-item_discount">{product.DISCOUNT}</span></td>
                                                <td><span className="a5-item_price_no_vat">{product.PRICE_NOVAT}</span>
                                                </td>
                                                <td><span className="a5-item_vat_price">{product.VAT_PRICE}</span></td>
                                                <td><span className="a5-item_other_taxes">{product.OTHER_TAXES}</span>
                                                </td>
                                                <td><span className="a5-item_total_price">{product.TOTAL}</span></td>
                                            </tr>
                                        ))}
                                    </table>
                                </div>
                            )}
                            <div className="a5-footer_container">
                                <table className="a5-footer_table" style={{tableLayout: "fixed", width: "100%"}}>
                                    <tbody>
                                    <tr>
                                        <td className="a5-vat_total_td">
                                            {(templateData.OLD_BALANCE && templateData.NEW_BALANCE && !templateData.ENABLE_PEPPOL) &&
                                                <div style={balanceContainer}>
                                                    <div>
                                                        <div style={{margin: "auto"}}>Προηγ. Υπόλοιπο</div>
                                                        <div style={{margin: "auto", fontWeight: "normal"}}>{templateData.OLD_BALANCE}</div>
                                                    </div>
                                                    <div>
                                                        <div style={{margin: "auto"}}>Νέο Υπόλοιπο</div>
                                                        <div style={{margin: "auto", fontWeight: "normal"}}>{templateData.NEW_BALANCE}</div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="a5-per_vat_totals_container">
                                                <table className="a5-totals_per_vat">
                                                    <tr>
                                                        <th>Συντελεστής ΦΠΑ</th>
                                                        <th>Καθαρή Αξία</th>
                                                        <th>Αξία ΦΠΑ</th>
                                                        <th>Φόροι/Τέλη</th>
                                                        <th>Τελική Αξία</th>
                                                    </tr>
                                                    {templateData.VAT_TABLE && index + 1 === totalPagesA.length && templateData.VAT_TABLE?.length > 0 && templateData.VAT_TABLE.map((taxes) => {
                                                        return <tr key={Math.random()}>
                                                            <td>{taxes.TAX_PERCENT} {taxes.TAX_CODE ? `(${taxes.TAX_CODE})` : null}</td>
                                                            <td>{taxes.NET_VALUE}</td>
                                                            <td>{taxes.TAX_VALUE}</td>
                                                            <td>{taxes.OTHER_TAXES}</td>
                                                            <td>{taxes.TOTAL_VALUE}</td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                            <div className="a5-cont_notation">
                                                {templateData.VATEXPCAT && templateData.VATEXPCAT?.length > 0 && templateData.VATEXPCAT.map((reason) => (
                                                    <>
                                                        <span className="skin bold">ΑΠΑΛΛΑΓΗ ΑΠΟ ΤΟ Φ.Π.Α. :</span>
                                                        <div className="a5-cont_notation_inner">
                                                            <span>{reason.vat} {reason.description} <br/></span>
                                                        </div>
                                                    </>
                                                ))}
                                                <span className="a5-skin a5-bold">ΠΑΡΑΤΗΡΗΣΕΙΣ: <span dangerouslySetInnerHTML={{__html: String(templateData?.INVOICE_NOTES).replaceAll("\n", "<br/>")}}></span></span>
                                            </div>
                                        </td>
                                        {templateData?.UID !== "" && templateData.MYDATADESTINATION === "provider" && (
                                            <td>
                                                <div className="a5-cont_signs">
                                                    <img src={templateData?.QR_CODE} alt="Qr code"
                                                         className="a5-product-qr-code-img"/>
                                                </div>
                                            </td>
                                        )}
                                        {templateData?.UID !== "" && templateData.MYDATADESTINATION !== "provider" && templateData.MYDATAQRURL && (
                                            <td>
                                                <div className="a5-cont_signs">
                                                    <img src={templateData?.QR_CODE} alt="Qr code"
                                                         className="a5-product-qr-code-img"/>
                                                </div>
                                            </td>
                                        )}
                                        {((templateData?.TRANSACTION_DETAILS && index + 1 === totalPagesA.length) && (
                                            <td>
                                                {(templateData?.TRANSACTION_DETAILS?.providerSignature) ? (
                                                    <div style={{...transactionDetailsBlock, width: "100%", fontSize: "8px", height: "190px"}}>
                                                        {templateData.TRANSACTION_DETAILS?.isEuronet === "true" && (
                                                            <React.Fragment>
                                                                <img style={{margin: "0", width: "20mm"}} className={"mb-2"}
                                                                     src={epayLogoBase64} alt={"ePay Logo"}/>
                                                                <br/>
                                                                <span style={{fontSize: "7px"}}>EURONET MERCHANT SERVICES A.E. GEMH: 159277401000</span>
                                                            </React.Fragment>
                                                        )}
                                                        {templateData.TRANSACTION_DETAILS?.isEuronet === "viva" && (
                                                            <React.Fragment>
                                                                <img style={{margin: "0", width: "15mm"}} src={vivaLogoBase64} alt={"Viva Logo"}/>
                                                                <br/>
                                                            </React.Fragment>
                                                        )}
                                                        <table className={"transactionDetailsTable"} style={{width: "100%", tableLayout: "fixed", fontSize: "8px"}} cellSpacing={0} cellPadding={0}>
                                                            <tbody>
                                                            <tr>
                                                                <td>&nbsp;</td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.transactionDate}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>TID/MID:</td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.terminalId} / {templateData.TRANSACTION_DETAILS?.merchantId}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΑΡ.
                                                                    ΣΥΝ.: {templateData.TRANSACTION_DETAILS?.transactionNumber}</td>
                                                                <td>ΕΓΚΡΙΣΗ: {templateData.TRANSACTION_DETAILS?.authorizationCode}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>RRN.:</td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.rnn}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΜΕΣΩ:
                                                                    {templateData.TRANSACTION_DETAILS?.isEuronet === "true" && `EPAY`}
                                                                    {templateData.TRANSACTION_DETAILS?.isEuronet === "viva" && `VIVA`}
                                                                </td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.cardType} {templateData.TRANSACTION_DETAILS?.aid} {templateData.TRANSACTION_DETAILS?.panCard} - {templateData.TRANSACTION_DETAILS?.finalCode}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΠΟΣΟ:</td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.amountTotalString} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΦΙΛΟΔΩΡΗΜΑ:</td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.tipAmountString} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΥΠΟΓΡΑΦΗ ΠΑΡΟΧΟΥ:</td>
                                                                <td style={{wordBreak: "break-all"}}>{templateData.TRANSACTION_DETAILS?.providerSignature}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΜΟΝ. ΤΑΥΤ. ΠΛΗΡΩΜΗΣ:</td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.providerTransactionId}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ) : (
                                                    <div style={{
                                                        ...transactionDetailsBlock,
                                                        width: "100%",
                                                        height: "190px"
                                                    }}>
                                                        <table className={"transactionDetailsTable"} style={{width: "100%", tableLayout: "fixed"}}>
                                                            <tbody>
                                                            <tr>
                                                                <td>ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ</td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.paymentTypeName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΗΜΕΡΟΜΗΝΙΑ ΠΛΗΡΩΜΗΣ</td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.paymentDate}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΠΟΣΟ</td>
                                                                <td>{templateData.TRANSACTION_DETAILS?.paymentAmount}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </td>
                                        ))}
                                        <td className="a5-total_td_block">
                                            <div className="a5-count_totals_container">
                                                <span>ΣΥΝΟΛΟ ΤΕΜΑΧΙΩΝ: </span> <span
                                                className="a5-count_total_prods"> {index + 1 === totalPagesA.length ? templateData.TOTAL_QTY : ''}</span>
                                            </div>
                                            <div className="a5-total_container">
                                                <div className="a5-totals">
                                                    <table className="a5-totals_table">
                                                        <tr>
                                                            <td className="a5-text-left a5-p">ΣΥΝΟΛΟ ΧΩΡΙΣ ΦΠΑ</td>
                                                            <td className="a5-text-right a5-p">
                                                                <span
                                                                    className="a5-total_withoutvat">{index + 1 === totalPagesA.length ? templateData.TOTAL_NOVAT : ''}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="a5-text-left a5-p">Φ.Π.Α.</td>
                                                            <td className="a5-text-right a5-p">
                                                                <span
                                                                    className="a5-total_withoutvat">{index + 1 === totalPagesA.length ? templateData.TOTAL_VAT : ''}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="a5-text-left a5-p">ΤΕΛΙΚΟ ΣΥΝΟΛΟ</td>
                                                            <td className="a5-text-right a5-p">
                                                                <span
                                                                    className="a5-amounttotal">{index + 1 === totalPagesA.length ? templateData.TOTAL_PRICE : ''}</span>
                                                            </td>
                                                        </tr>
                                                        {templateData.TOTAL_WITHHOLD && (
                                                            <tr>
                                                                <td className="a5-text-left a5-p">ΣΥΝΟΛΟ ΠΑΡΑΚΡΑΤΟΥΜΕΝΩΝ
                                                                    ΦΟΡΩΝ
                                                                </td>
                                                                <td className="a5-text-right a5-p">
                                                                <span
                                                                    className="a5-amounttotal">{index + 1 === totalPagesA.length ? templateData.TOTAL_WITHHOLD : ''}</span>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        <tr className="a5-blank_row a5-bordered">
                                                            <td className="text-left">&nbsp;</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="a5-text-left a5-finalprice a5-p">ΠΛΗΡΩΤΕΟ
                                                                ΠΟΣΟ
                                                            </td>
                                                            <td className="a5-text-right a5-p">
                                                                <span
                                                                    className="a5-totalpayment">{index + 1 === totalPagesA.length ? templateData.TOTAL_PRICE : ''}</span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                {showWaterMark && <div className="a5-cancel">ΑΚΥΡΟ</div>}
                                {((templateData.TRANSMISSION_FAILURE && (templateData.TRANSMISSION_FAILURE === "1" || templateData.TRANSMISSION_FAILURE === "2") && (templateData.MYDATADESTINATION === "Provider" || templateData.MYDATADESTINATION === "provider")) || (templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                                    <div>
                                        <span className="a5-sender_sign">www.primer.gr/search-invoice Provided by Primer Software P.C.</span>
                                        <br/>
                                        <span className="a5-sender_sign">Άδεια ΥΠΑΗΕΣ: 2021_01_107Primer Software ΙΚΕ_001_ Primer MyData_V1_21012021</span>
                                    </div>
                                }
                                <br/>
                                {index + 1 === totalPagesA.length && (
                                    <div className="a5-mydata_sign a5-p">
                                        {((templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "1" && (templateData.MYDATADESTINATION === "Provider" || templateData.MYDATADESTINATION === "provider")) || (templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "1" && templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                                            <span className="a5-uid_sign">Απώλεια Διασύνδεσης Οντότητας - Παρόχου - Transmission Failure_1<br/></span>}
                                        {((templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "2" && (templateData.MYDATADESTINATION === "Provider" || templateData.MYDATADESTINATION === "provider")) || (templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "2" && templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                                            <span className="a5-uid_sign">Απώλεια Διασύνδεσης Παρόχου - ΑΑΔΕ - Transmission Failure_2<br/></span>}
                                        {(templateData.TRANSMISSION_FAILURE === "3" && templateData.MYDATADESTINATION === "MyData") && (
                                            <span>Απώλεια Διασύνδεσης ERP – Α.Α.Δ.Ε. - Transmission Failure_3<br/></span>
                                        )}
                                        {templateData.UID && templateData.UID !== "" && templateData.AUTHCODE !== "" &&
                                            <span className="a5-uid_sign"> UID: {templateData.UID}</span>}
                                        {templateData.MARK && templateData.MARK !== "" &&
                                            <span> Μ.ΑΡ.Κ.: {templateData.MARK}</span>}
                                        {templateData.AUTHCODE && templateData.AUTHCODE !== "" &&
                                            <span className="a5-uid_sign"><br/> AUTHENTICATION CODE: {templateData.AUTHCODE}</span>}
                                    </div>
                                )}
                            </div>
                        </div>
                        {index + 1 !== totalPagesA.length && (
                            <div className="page-break"></div>
                        )}
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
})

export default SalesPrintA5DoubleGreek
