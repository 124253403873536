import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {userSigninWithUrl} from "./_apis/api";
import MainWrapper from "../src/_components/layouts/MainWrapper";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {getURLParams} from "./_helpers/helperFunctions";
import {login} from "./_reducers/Login";
import {setCompany} from "./_reducers/companySlice";

const App = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.LOGIN_STATE);
    const apiLoginURL = process.env.REACT_APP_LOGIN_URL;
    const error = 'You are not Authorized! Please Login First'

    useEffect(() => {
        const params = getURLParams();
        const token = params.get("token");
        const company = params.get("company");
        const deviceId = params.get("device_id");
        const erp_ip = params.get("erp_ip");
        if (token && company && deviceId && erp_ip) { // Logging in from login page
            if (token && company) {
                const companyData = JSON.parse(company);
                let selectedCompany = {
                    year: Number(companyData.year),
                    id: companyData._id,
                    name: companyData.name,
                    vatNumber: companyData.vatNumber,
                    email: companyData.email,
                    companyExpireDate: companyData.companyExpireDate
                }
                let objectParams = {
                    year: Number(companyData.year),
                    companyId: companyData._id,
                    vatNumber: companyData.vatNumber,
                    token: token,
                    erp_ip: erp_ip,
                    deviceId: deviceId,
                    companyExpireDate: companyData.companyExpireDate
                }
                localStorage.setItem("company", JSON.stringify(selectedCompany));
                userSigninWithUrl(token, dispatch, selectedCompany, objectParams, companyData, "");
            } else {
                localStorage.clear();
                window.location.replace(`${apiLoginURL}?redirect=ERP&error=${error}`)
            }
        } else { // Logging in directly
            if (localStorage.getItem("token") && localStorage.getItem("company") && localStorage.getItem("user")) {
                const company = localStorage.getItem("company")
                if (company) {
                    const companyData = JSON.parse(company);
                    companyData.year = Number(companyData.year);
                    dispatch(login());
                    dispatch(setCompany(companyData));
                    window.history.pushState({}, "", window.location.origin); // Do not touch. Removes huge url and fixes change company
                }
            } else {
                localStorage.clear();
                window.location.replace(`${apiLoginURL}?redirect=ERP&error=${error}`)
            }
        }
    }, [dispatch, apiLoginURL]);

    return (
        <React.Fragment>
            {isLoggedIn && <MainWrapper/>}
            <ToastContainer
                position="top-right"
                autoClose={3000}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={false}
            />
        </React.Fragment>
    )
}

export default App;
