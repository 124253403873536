import React, {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import axios from "axios";
import Button from "react-bootstrap/Button";
import {useDispatch, useSelector} from "react-redux";
import {classicStyleBelowNavbar} from "../Statics";
import {Badge, Col, Row, Spinner} from "react-bootstrap";
import "./styles/op-statement.css";
import {setOperatingStatementReportResponseData} from "../../../../_reducers/DataPanel/Accounting/accountingSlice";
import AccountingBox from "../../../primer/AccountingBox";
import {isEmpty} from "../../../../_helpers/commonFunctions";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import {liberationSerif} from "../../../../fonts/liberationSerif";
import moment from "moment";

const AccountingOperatingStatement = () => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const responseData = useSelector((state) => state.ACCOUNTING_DATA.operatingStatementReportResponseData);
    const [loading, setLoading] = useState(false);

    const pageRef = useRef();

    const defaultOptions = {
        year: String(company.year),
        previousDamages: 0,
        paidInAdvanceTax: 0,
        telosEpit: 0,
        withholdTax: 0,
    }
    const [options, setOptions] = useState(defaultOptions);

    useEffect(() => {
        fetchOptions();
    }, [])

    const fetchOptions = () => {
        axios.get(`${process.env.REACT_APP_API_URL2}/report/accounting-operating-statement-options`, {
            headers: { "Content-Type": "application/json" },
            params: {
                company: company.id,
                year: String(company.year),
            }
        }).then((res) => {
            if (res.data.status === "200") setOptions(res.data.data);
        }).catch((err) => {
            console.log(err);
        })
    }

    const fetchReport = () => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/report/accounting-operating-statement`, {
            company: company.id,
            year: company.year,
            options: options,
        }, {
            headers: { 'Content-Type': 'application/json' },
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                dispatch(setOperatingStatementReportResponseData(res.data.data));
                toast.success("Επιτυχής ανανέωση.");
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const exportPDF = () => {
        const doc = new jsPDF({
            orientation: "landscape",
            unit: "mm",
            format: "a4"
        });
        const page = document.getElementById("wholeOPReport");
        page.style.fontFamily = "Liberation Serif";
        page.style.letterSpacing = "0.02em";
        doc.addFileToVFS("liberationserif.ttf", liberationSerif);
        doc.addFont("liberationserif.ttf", "Liberation Serif", "normal");
        doc.setFont("Liberation Serif");
        doc.html(page, {
            callback: (doc) => {
                doc.save("generated.pdf");  // Save the PDF with a file name
                page.style.fontFamily = "";
                page.style.letterSpacing = "";
            },
            x: 10,
            y: 10,
            html2canvas: {
                scale: 0.17,
                letterRendering: true,  // This might help with spacing issues
            },
        });
    }

    const exportExcel = () => {
        const tables = document.querySelectorAll('.opStatementTable');

        // Convert tables to worksheets
        const ws1 = XLSX.utils.table_to_sheet(tables[1]);
        const ws2 = XLSX.utils.table_to_sheet(tables[2]);
        const ws3 = XLSX.utils.table_to_sheet(tables[3]);
        const ws4 = XLSX.utils.table_to_sheet(tables[4]);

        // Convert sheets to arrays (AOA)
        const data1 = XLSX.utils.sheet_to_json(ws1, { header: 1, defval: " " });
        const data2 = XLSX.utils.sheet_to_json(ws2, { header: 1, defval: " " });
        const data3 = XLSX.utils.sheet_to_json(ws3, { header: 1, defval: " " });
        const data4 = XLSX.utils.sheet_to_json(ws4, { header: 1, defval: " " });

        // Determine max row count for alignment
        const maxRows12 = Math.max(data1.length, data2.length);
        const maxRows34 = Math.max(data3.length, data4.length);

        // Function to merge tables side by side
        const mergeTablesSideBySide = (leftTable, rightTable, maxRows) => {
            const merged = [];
            for (let i = 0; i < maxRows; i++) {
                const rowLeft = leftTable[i] || [];
                const rowRight = rightTable[i] || [];
                merged.push([...rowLeft, '', '', ...rowRight]); // Empty spaces for separation
            }
            return merged;
        }

        const firstBlock = mergeTablesSideBySide(data1, data2, maxRows12);
        const secondBlock = mergeTablesSideBySide(data3, data4, maxRows34);
        const finalData = [...firstBlock, [], ...secondBlock];

        const finalSheet = XLSX.utils.aoa_to_sheet(finalData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, finalSheet, "Γεν. Εκμ.");
        XLSX.writeFile(wb, "Γενική Εκμετάλλευση.xlsx");
    }

    const getRowStyle = (row) => {
        let style = {};
        if (row?.type) {
            const rowType = String(row?.type);
            if (rowType.includes("bold")) {
                style.fontWeight = "700";
            }
            if (rowType.includes("center")) {
                style.textAlign = "center";
            } else if (rowType.includes("right")) {
                style.textAlign = "right";
            } else if (rowType.includes("left")) {
                style.textAlign = "left";
            }
        }
        return style;
    }

    const filterNum = (num) => {
        const parsed = parseFloat(num);
        return !isNaN(parsed) ? parseFloat(num).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0,00";
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row>
                <Col md={3}>
                    <span>Προηγούμενες ζημιές</span>
                    <AccountingBox
                        name="previousDamages"
                        className="mb-3 form-control"
                        placeholder={"Προηγούμενες ζημιές"}
                        defaultValue={options.previousDamages}
                        key={options.previousDamages}
                        onBlur={(e) => setOptions({...options, previousDamages: e.target.value})}
                    />
                </Col>
                <Col md={3}>
                    <span>Προκαταβολή προηγ. έτους/παρακράτηση</span>
                    <AccountingBox
                        name="previousDamages"
                        className="mb-3 form-control"
                        placeholder={"Προκαταβολή προηγ. έτους/παρακράτηση"}
                        defaultValue={options.paidInAdvanceTax}
                        key={options.paidInAdvanceTax}
                        onBlur={(e) => setOptions({...options, paidInAdvanceTax: e.target.value})}
                    />
                </Col>
                <Col md={2}>
                    <span>Παρακράτηση Φόρου</span>
                    <AccountingBox
                        name="withholdTax"
                        className="mb-3 form-control"
                        placeholder={"Παρακράτηση Φόρου"}
                        defaultValue={options.withholdTax}
                        key={options.withholdTax}
                        onBlur={(e) => setOptions({...options, withholdTax: e.target.value})}
                    />
                </Col>
                <Col md={2}>
                    <span>Τέλος Επιτηδεύματος</span>
                    <AccountingBox
                        name="telosEpit"
                        className="mb-3 form-control"
                        placeholder={"Τέλος Επιτηδεύματος"}
                        defaultValue={options.telosEpit}
                        key={options.telosEpit}
                        onBlur={(e) => setOptions({...options, telosEpit: e.target.value})}
                    />
                </Col>
                <Col md={2}>
                    <Button size={"sm"} onClick={() => fetchReport()} style={{marginTop: "15px"}} disabled={loading}>
                        Εφαρμογή και προβολή αποτελεσμάτων {loading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                    </Button>
                </Col>
            </Row>
            <div hidden={loading} ref={pageRef}>
                {Object.keys(responseData).length > 0 && (
                    <Row className={"mb-5 mt-2"}>
                        <Col md={12}>
                            <Badge bg={"primary"} className={"mb-2"} onClick={() => exportPDF()}>Εξαγωγή PDF</Badge>
                            <div id={"wholeOPReport"}>
                                <table style={{width: "100%", textAlign: "center", fontWeight: "600"}} className={"opStatementTable m-0"}>
                                    <tbody>
                                    <tr>
                                        <td>ΚΑΤΑΣΤΑΣΗ ΛΟΓΑΡΙΑΣΜΟΥ ΓΕΝΙΚΗΣ ΕΚΜΕΤΑΛΛΕΥΣΕΩΣ (Λ/86) ΤΗΣ
                                            {String(company.year) === moment().format("YYYY")
                                                ? ` ${moment().format("DD/MM/YYYY")} (ΠΕΡΙΟΔΟΣ 01/01/${company.year} - ${moment().format("DD/MM/YYYY")})`
                                                : ` 31/12/${company.year} (ΠΕΡΙΟΔΟΣ 01/01/${company.year} - 31/12/${company.year})` }
                                            </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div style={{width: "100%", display: "flex", alignItems: "flex-start"}}>
                                    <table style={{flex: "1", fontSize: "12px"}} className={"m-0 opStatementTable"}>
                                        <colgroup>
                                            <col style={{width: "5%"}}></col>
                                            <col style={{width: "35%"}}></col>
                                            <col style={{width: "10%"}}></col>
                                            <col style={{width: "10%"}}></col>
                                            <col style={{width: "10%"}}></col>
                                            <col style={{width: "15%"}}></col>
                                            <col style={{width: "15%"}}></col>
                                        </colgroup>
                                        <thead style={{textAlign: "center"}}>
                                        <tr>
                                            <th colSpan={2}>{" "}</th>
                                            <th colSpan={3}>ΠΟΣΑ ΚΛΕΙΟΜΕΝΗΣ ΧΡΗΣΕΩΣ</th>
                                            <th colSpan={2}>ΠΟΣΑ ΠΡΟΗΓΟΥΜΕΝΗΣ ΧΡΗΣΕΩΣ</th>
                                        </tr>
                                        <tr>
                                            <th colSpan={2} style={{textDecoration: "underline"}}>ΧΡΕΩΣΗ</th>
                                            <th colSpan={3}>{company.year}</th>
                                            <th colSpan={2}>{+company.year - 1}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {responseData.debit.map((row, idx) => (
                                            <tr key={`${idx}`}>
                                                <td style={{textAlign: "right", ...getRowStyle(row)}}>
                                                    {String(row?.type).includes("groupHeader") && row["sideName"]}
                                                </td>
                                                <td style={{textAlign: "left", ...getRowStyle(row)}}>
                                                    {String(row?.type).includes("groupHeader") ? (
                                                        <span>{row["name"]}</span>
                                                    ) : (
                                                        <span>{row["sideName"]} {row["name"]}</span>
                                                    )}
                                                </td>
                                                <td>{row["subAm"] !== null && filterNum(row["subAm"])}</td>
                                                <td>{row["am"] !== null && filterNum(row["am"])}</td>
                                                <td>{row["perc"] !== null && filterNum(row["perc"]) + "%"}</td>
                                                <td>{row["prevSubAm"] !== null && filterNum(row["prevSubAm"])}</td>
                                                <td>{row["prevAm"] !== null && filterNum(row["prevAm"])}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <table style={{flex: "1", fontSize: "12px"}} className={"m-0 opStatementTable"}>
                                        <colgroup>
                                            <col style={{width: "5%"}}></col>
                                            <col style={{width: "35%"}}></col>
                                            <col style={{width: "10%"}}></col>
                                            <col style={{width: "10%"}}></col>
                                            <col style={{width: "10%"}}></col>
                                            <col style={{width: "15%"}}></col>
                                            <col style={{width: "15%"}}></col>
                                        </colgroup>
                                        <thead style={{textAlign: "center"}}>
                                        <tr>
                                            <th colSpan={2}>{" "}</th>
                                            <th colSpan={3}>ΠΟΣΑ ΚΛΕΙΟΜΕΝΗΣ ΧΡΗΣΕΩΣ</th>
                                            <th colSpan={2}>ΠΟΣΑ ΠΡΟΗΓΟΥΜΕΝΗΣ ΧΡΗΣΕΩΣ</th>
                                        </tr>
                                        <tr>
                                            <th colSpan={2} style={{textDecoration: "underline"}}>ΠΙΣΤΩΣΗ</th>
                                            <th colSpan={3}>{company.year}</th>
                                            <th colSpan={2}>{+company.year - 1}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {responseData.credit.map((row, idx) => (
                                            <tr key={`${idx}`}>
                                                <td style={{textAlign: "right", ...getRowStyle(row)}}>
                                                    {String(row?.type).includes("groupHeader") && row["sideName"]}
                                                </td>
                                                <td style={{textAlign: "left", ...getRowStyle(row)}}>
                                                    {String(row?.type).includes("groupHeader") ? (
                                                        <span>{row["name"]}</span>
                                                    ) : (
                                                        <span>{isEmpty(row["sideName"]) && <span>&nbsp;</span>} {row["name"]}</span>
                                                    )}
                                                </td>
                                                <td>{row["subAm"] !== null && filterNum(row["subAm"])}</td>
                                                <td>{row["am"] !== null && filterNum(row["am"])}</td>
                                                <td>{row["perc"] !== null && filterNum(row["perc"]) + "%"}</td>
                                                <td>{row["prevSubAm"] !== null && filterNum(row["prevSubAm"])}</td>
                                                <td>{row["prevAm"] !== null && filterNum(row["prevAm"])}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{display: "flex", alignItems: "flex-start"}}>
                                    <table style={{width: "50%", fontSize: "12px"}} className={"opStatementTable mt-1"}>
                                        <colgroup>
                                            <col style={{width: "5%"}}></col>
                                            <col style={{width: "35%"}}></col>
                                            <col style={{width: "10%"}}></col>
                                            <col style={{width: "10%"}}></col>
                                            <col style={{width: "10%"}}></col>
                                            <col style={{width: "15%"}}></col>
                                            <col style={{width: "15%"}}></col>
                                        </colgroup>
                                        <tbody>
                                        {responseData.totals.map((row, idx) => (
                                            <tr key={`${idx}`}>
                                                <td>&nbsp;</td>
                                                <td style={{textAlign: "left", ...getRowStyle(row)}}>{row["name"]}</td>
                                                <td>&nbsp;</td>
                                                <td>{row["am"] !== null && filterNum(row["am"])}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <table style={{width: "50%", fontSize: "12px"}} className={"opStatementTable mt-1"}>
                                        <colgroup>
                                            <col style={{width: "5%"}}></col>
                                            <col style={{width: "35%"}}></col>
                                            <col style={{width: "10%"}}></col>
                                            <col style={{width: "10%"}}></col>
                                            <col style={{width: "10%"}}></col>
                                            <col style={{width: "15%"}}></col>
                                            <col style={{width: "15%"}}></col>
                                        </colgroup>
                                        <tbody>
                                        {responseData.yearlyResults.map((row, idx) => (
                                            <tr key={`${idx}`}>
                                                <td>&nbsp;</td>
                                                <td style={{textAlign: "left", ...getRowStyle(row)}}>{row["name"]}</td>
                                                <td>&nbsp;</td>
                                                <td>{row["am"] !== null && filterNum(row["am"])}</td>
                                                <td style={{...getRowStyle(row)}}>{row["perc"] !== null && filterNum(row["perc"]) + "%"}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    )
}

export default AccountingOperatingStatement
