import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Badge, Col, Row} from "react-bootstrap";
import DateBox from "../../../primer/DateBox";
import Button from "react-bootstrap/Button";
import moment from "moment";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import axios from "axios";
import "jspdf-autotable";
import {
    checkPermission,
    getOptionsByData, isEmpty,
} from "../../../../_helpers/commonFunctions";
import Dropdown from "../../../primer/Dropdown";
import {getInvoiceTypesOptionList} from "../../../../_helpers/sharedmydata";
import EditableTable from "../../../primer/Editable-Table";
import {fetchBuysGeneralSettings} from "../../../../_apis/api";
import {
    setBuysReportData,
    setBuysReportRequestData,
} from "../../../../_reducers/DataPanel/Buys/buysSlice";
import {useTranslation} from "react-i18next";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import {toast} from "react-toastify";
import {classicStyleBelowNavbar} from "../Statics";
import Input from "../../../common/Input";

const BuysBalanceSheet = () => {
    const [t, i18n] = useTranslation('common');
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const BUYS_SETTINGS = useSelector((state) => state.BUYS_SETTINGS);
    const reportData = useSelector((state) => state.BUYS.buysBalanceReportData);
    const requestData = useSelector((state) => state.BUYS.buysBalanceReportRequestData);
    const carNumbersOptions = useMemo(() => [...getOptionsByData(BUYS_SETTINGS.buysGeneralSettings.carNumbers, "_id", "number")], [BUYS_SETTINGS.buysGeneralSettings.carNumbers]);
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const [pageChanged, setPageChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [incrKey, setIncrKey] = useState(0);

    const columns = [
        {
            name: t("General.no"),
            field: "no",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.date"),
            field: "date",
            width: "100px",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.supplier"),
            field: "supplierName",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.supplierVAT"),
            field: "vat",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.documentType"),
            field: "documentTypeName",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.numbering"),
            field: "number",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.totalDeductibleNetValue"),
            field: "totalDeductibleNetValue",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.totalDeductibleVAT"),
            field: "totalDeductibleVAT",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.totalNonDeductibleNetValue"),
            field: "totalNonDeductibleNetValue",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.totalNonDeductibleVAT"),
            field: "totalNonDeductibleVAT",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.totalAmount"),
            field: "totalAmount",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.paymentType"),
            field: "paymentTypeName",
            editable: false,
            hiddenColumn: true,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.carNumber"),
            field: "vehNumber",
            editable: false,
            hiddenColumn: true,
            inputType: {
                type: "text",
            },
        },
    ];
    useEffect(() => {
        dispatch(fetchBuysGeneralSettings({company: company.id}));
        loadSuppliersListData(null, setSuppliersList);
        getInvoiceTypesOption();
        if (requestData.firstLoad) {
            const currentYear = Number(moment().format("YYYY"));
            if (Number(company.year) < currentYear) {
                dispatch(setBuysReportRequestData({
                    ...requestData,
                    date_from: `01/01/${company.year}`,
                    date_to: `31/12/${company.year}`,
                    firstLoad: false,
                }));
            } else {
                dispatch(setBuysReportRequestData({
                    ...requestData,
                    date_from: moment().startOf("month").format("DD/MM/YYYY"),
                    date_to: moment().endOf("month").format("DD/MM/YYYY"),
                    firstLoad: false,
                }));
            }
        }
    }, [])

    useEffect(() => {
        setTableColumns(columns.slice());
    }, [i18n.language])

    useEffect(() => {
        if (pageChanged) {
            setPageChanged(false);
            fetchReport(true);
        }
    }, [pageChanged])

    const [suppliersList, setSuppliersList] = useState([]);
    let paymentTypeOptions = getOptionsByData(BUYS_SETTINGS.buysGeneralSettings.paymentTypes, "_id", "name");
    const [invoiceTypes, setInvoiceTypes] = useState([]);
    const [tableColumns, setTableColumns] = useState(columns.slice());

    const getInvoiceTypesOption = () => {
        axios.post(process.env.REACT_APP_API_URL2 + `/settings/list-setting-advance-type-buy`, {company: company.id}, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                let invoiceList = [];
                if (res.data.data) {
                    invoiceList = getInvoiceTypesOptionList(res.data.data);
                    setInvoiceTypes(invoiceList);
                }
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const onChangeSearch = (keyword, setData) => {
        if (keyword.length >= 2) {
            loadSuppliersListData(keyword, setData);
        }
    }
    const loadSuppliersListData = (keyword, setData) => {
        let reqData = {
            company: company.id,
        }
        if (keyword) reqData.name = keyword;
        axios.post(process.env.REACT_APP_API_URL2 + `/supplier/list-supplier`, {name: keyword, company: company.id}, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                if (res.data.data) {
                    if (setData) setData(res.data.data);
                } else {
                    if (setData) setData([]);
                }
            } else {
                if (setData) setData([]);
            }
        }).catch((e) => {
            console.log(e);
        })
    }
    const supplierDropdownColumns = [
        {
            label: t("General.supplierName"),
            name: "name",
        },
        {
            label: t("General.vatNumber"),
            name: "vatNumber",
        },
        {
            label: t("General.phone"),
            name: "phone",
        },
    ];

    const handleOnChange = (e, type, name) => {
        if (type === "datebox") {
            if (moment(e).isValid()) {
                dispatch(setBuysReportRequestData({...requestData, [name] : moment(e).format("DD/MM/YYYY")}));
            } else {
                dispatch(setBuysReportRequestData({...requestData, [name] : ""}));
            }
        } else if (type === "dropdown") {
            dispatch(setBuysReportRequestData({...requestData, [name] : e}));
        } else if (type === "acdd") {
            dispatch(setBuysReportRequestData({...requestData, [name] : e.row.name}));
        } else if (type === "input") {
            dispatch(setBuysReportRequestData({...requestData, [name] : e.target.value}));
        }
    }

    const fetchReport = (fromPagination = false) => {
        setLoading(true);
        let newData = {...requestData,
            company: company.id,
            year: company.year,
            invoiceType: requestData.invoiceType.map(el => el.value),
            paymentType: requestData.paymentType.map(el => el.value),
            carNumber: requestData.carNumber.map(el => el.value)
        }
        if (!fromPagination) newData.page = 1;
        axios.post(process.env.REACT_APP_API_URL2 + `/report/buys-balance-report`, newData, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                if (res.data.data.length === 0) {
                    toast.info("Δεν βρέθηκαν αγορές.");
                    dispatch(setBuysReportData([]));
                } else {
                    let dR = [];
                    if (res.data.data.data.length > 0) {
                        res.data.data.data.forEach((obj, i) => {
                            obj.no = 20*Number(res.data.data.currentPage - 1) + i + 1;
                            if (obj.preNumber) {
                                if (obj.preNumber !== "noprefix") {
                                    obj.number = obj.preNumber + obj.number;
                                }
                            }
                            obj.vehNumber = obj.extraData[0].carNumber;
                            obj.vatAmount = Number(Number(obj.buysTaxes[0].vatEuro).toFixed(2));
                            obj.totalNetValue = Number(Number(obj.totalNetValue).toFixed(2));
                            obj.totalAmount = Number(Number(obj.totalAmount).toFixed(2));
                            obj.totalNonDeductibleVAT = Number(Number(obj.totalNonDeductibleVAT).toFixed(2));
                        })
                        dispatch(setBuysReportRequestData({
                            ...requestData,
                            page: res.data.data.currentPage,
                            totalPages: res.data.data.totalPages,
                            totalDeductibleNetValue: res.data.totalDeductibleNetValue,
                            totalNonDeductibleNetValue: res.data.totalNonDeductibleNetValue,
                            totalDeductibleVAT: res.data.totalDeductibleVAT,
                            totalNonDeductibleVAT: res.data.totalNonDeductibleVAT,
                            totalValue: res.data.totalValue,
                        }));
                        dR = res.data.data.data;
                        dispatch(setBuysReportData([...dR]));
                        setIncrKey(incrKey + 1);
                        if (!fromPagination) toast.success("Τα δεδομένα ανανεώθηκαν!", {autoClose: 1000});
                    }
                }
            }
        }).catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    const toFirstPage = () => {
        dispatch(setBuysReportRequestData({...requestData, page: 1}));
        setPageChanged(true);
    }
    const toNextPage = () => {
        dispatch(setBuysReportRequestData({...requestData, page: requestData.page + 1}));
        setPageChanged(true);
    }
    const toPreviousPage = () => {
        dispatch(setBuysReportRequestData({...requestData, page: requestData.page - 1}));
        setPageChanged(true);
    }
    const toLastPage = () => {
        dispatch(setBuysReportRequestData({...requestData, page: requestData.totalPages}));
        setPageChanged(true);
    }
    return (
        <div style={classicStyleBelowNavbar}>
            <div className="no-print">
                <Row>
                    <Col md={3}>
                        <DateHelper
                            dateFromAndToFunction={(from, to) => dispatch(setBuysReportRequestData({...requestData, date_from: from, date_to: to}))}
                        />
                    </Col>
                    <Col md={2}>
                        <DateBox
                            name="date_from"
                            selected={isEmpty(requestData.date_from) ? moment().toDate() : moment(requestData.date_from, "DD/MM/YYYY").toDate()}
                            label={t('General.dateFrom')}
                            onChange={(e) => handleOnChange(e, "datebox", "date_from")}
                        />
                    </Col>
                    <Col md={2}>
                        <DateBox
                            name="date_to"
                            selected={isEmpty(requestData.date_to) ? moment().toDate() : moment(requestData.date_to, "DD/MM/YYYY").toDate()}
                            label={t('General.dateTo')}
                            onChange={(e) => handleOnChange(e, "datebox", "date_to")}
                        />
                    </Col>
                    <Col md={5}>
                        <AdvancedCombobox
                            name="supplierName"
                            ACName="Buys Supplier" // do not change name
                            className="w-100 ac-select-dropdown"
                            searchableDropdownEnable={false}
                            defaultValue={requestData.supplierName}
                            label={t("General.supplier")}
                            data={suppliersList}
                            onChange={(e) => handleOnChange(e, "acdd", "supplierName")}
                            resetFilter={() => dispatch(setBuysReportRequestData({...requestData, supplierName: ""}))}
                            onChangeSearch={onChangeSearch}
                            excludeColumns={[]}
                            columns={supplierDropdownColumns}
                            editEnable={false}
                            deleteEnable={false}
                            searchTableEnable={false}
                            showTableOptionOnSelect={true}
                            placeholder={t("Placeholders.selectSupplier")}
                            showInsertAddButton={true}
                            hideSaveButton={true}
                            hideInsertNewRow={true}
                            inputFieldClasses="form-control"
                        />
                    </Col>
                </Row>
                <Row className={"mt-2"}>
                    <Col md={3}>
                        <Dropdown
                            label={t('General.documentType')}
                            name="invoiceType"
                            width="30%"
                            options={invoiceTypes}
                            enabledValue={true}
                            multiSelect={true}
                            onChange={(e) => handleOnChange(e, "dropdown", "invoiceType")}
                            defaultValue={requestData.invoiceType}
                        />
                    </Col>
                    <Col md={3}>
                        <Dropdown
                            label={t('General.paymentType')}
                            options={paymentTypeOptions}
                            enabledValue={true}
                            multiSelect={true}
                            name="paymentType"
                            searchEnable={true}
                            onChange={(e) => handleOnChange(e, "dropdown", "paymentType")}
                            defaultValue={requestData.paymentType}
                        />
                    </Col>
                    <Col md={2}>
                        <Dropdown
                            name="carNumber"
                            label={t('General.carNumber')}
                            options={carNumbersOptions}
                            enabledValue={true}
                            multiSelect={true}
                            onChange={(e) => handleOnChange(e, "dropdown", "carNumber")}
                            defaultValue={requestData.carNumber}
                        />
                    </Col>
                    <Col md={1}>
                        <Input
                            label={t('General.numberFrom')}
                            name={"numberFrom"}
                            placeholder={t('General.numberFrom')}
                            type={"number"}
                            value={requestData.numberFrom}
                            onChange={(e) => handleOnChange(e, "input", "numberFrom")}
                        />
                    </Col>
                    <Col md={1}>
                        <Input
                            label={t('General.numberTo')}
                            name={"numberTo"}
                            placeholder={t('General.numberTo')}
                            type={"number"}
                            value={requestData.numberTo}
                            onChange={(e) => handleOnChange(e, "input", "numberTo")}
                        />
                    </Col>
                </Row>
                {checkPermission("buys-reports/buybalancesheet-read", permissionsData) &&
                    <Row>
                        <Col md={12}>
                            <Button onClick={() => fetchReport()} className="btn btn-primary float-right" disabled={loading}>
                                {t('General.applyFilter')}
                            </Button>
                        </Col>
                    </Row>
                }
                <hr style={{color: "gray", height: 1}} />

                {reportData.length > 0 ? (
                    <Row>
                        {requestData.totalPages > 0 && (
                            <>
                                <div className="d-flex justify-content-end mb-2">
                                    <Button
                                        size={"sm"}
                                        className={"mr-1"}
                                        onClick={() => toFirstPage()}
                                        disabled={requestData.page === 1 || loading}
                                    >
                                        {t('SalesBalanceSheet.first')}
                                    </Button>
                                    <Button
                                        size={"sm"}
                                        className={"mr-1"}
                                        onClick={() => toPreviousPage()}
                                        disabled={requestData.page === 1 || loading}
                                    >
                                        {t('SalesBalanceSheet.previous')}
                                    </Button>
                                    <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1"
                                           bg="primary">{t('SalesBalanceSheet.page')} {requestData.page} / {requestData.totalPages}</Badge>
                                    <Button
                                        size={"sm"}
                                        className={"mr-1"}
                                        onClick={() => toNextPage()}
                                        disabled={requestData.page === requestData.totalPages || loading}
                                    >
                                        {t('SalesBalanceSheet.next')}
                                    </Button>
                                    <Button
                                        size={"sm"}
                                        className={"mr-1"}
                                        onClick={() => toLastPage()}
                                        disabled={requestData.page === requestData.totalPages || loading}
                                    >
                                        {t('SalesBalanceSheet.last')}
                                    </Button>
                                </div>
                            </>
                        )}
                        <EditableTable
                            key={"Buys Balance Sheet" + incrKey}
                            tableName="Buys Balance Sheet"
                            data={reportData}
                            allowPagination={false}
                            allowToggleColumns={true}
                            allowInsertRow={false}
                            enableNewRowCheckbox={false}
                            columns={tableColumns}
                            allowActions={true}
                            filters={requestData}
                            allowPrintResults={checkPermission("buys-reports/buybalancesheet-balancesheet-print", permissionsData) === true}
                            company={company}
                            editRedirectAllowed={true}
                            reportExcelPrint={true}
                            allowBulkActions={true}

                            pageTotals={true}
                            totalDeductibleNetValueTrue={true}
                            totalDeductibleNetValue={requestData.totalDeductibleNetValue}
                            totalNonDeductibleNetValueTrue={true}
                            totalNonDeductibleNetValue={requestData.totalNonDeductibleNetValue}
                            totalDeductibleVATTrue={true}
                            totalDeductibleVAT={requestData.totalDeductibleVAT}
                            totalNonDeductibleVATTrue={true}
                            totalNonDeductibleVAT={requestData.totalNonDeductibleVAT}
                            totalValueTrue={true}
                            totalValue={requestData.totalValue}
                        />
                    </Row>
                ) : (
                    <Row>
                        <h3 style={{textAlign: "center", alignItems: "center"}}>
                            {t("General.noData")}
                        </h3>
                    </Row>
                )}
            </div>
        </div>
    )
}

export default BuysBalanceSheet;
