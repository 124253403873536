import {getObjectFromArrayOfObjects} from "./helperFunctions";
import {DoyOptions} from "../_data/clients/doy-options"
import {countryList, countryListEU, countryListNonEU, onlyGreeceList} from "../_data/country-list"
import {withHoldingTaxesData} from "../_data/mydata/withHoldingTaxes";
import {otherTaxesData} from "../_data/mydata/otherTaxes";
import {vatCategoryData} from "../_data/mydata/vatCategory";
import jwt_decode from "jwt-decode";
import {vatZeroPerData} from "../_data/mydata/vatZeroPer";
import {getSalesTaxesTemplateData} from "./product-sales-calculations";
import getMyData from "./sharedmydata";
import {getBuysTaxesTemplateData} from "./product-buys-calculations";
import QR from 'qrcode-base64'
import moment from "moment/moment";
import {myDataCharOptions} from "../_data/mydata/myDataChar";
import {measurementUnitsData} from "../_data/mydata/measurementUnits";

export const vatIncludedOptions = () => {
	const user = getCurrentUser();
	if (user?.lang && user.lang === "el") {
		return [
			{
				label: 'Περιλαμβάνεται Φ.Π.Α.',
				value: true,
			},
			{
				label: 'Δεν περιλαμβάνεται Φ.Π.Α.',
				value: false,
			}
		]
	} else {
		return [
			{
				label: 'VAT included',
				value: true,
			},
			{
				label: 'VAT Not included',
				value: false,
			}
		]
	}
}

export const getVatCategoryOptions = () => {
	const user = getCurrentUser();
	if (user?.lang && user.lang === 'el') {
		return [
			{
				label: 'Κανονικό Φ.Π.Α.',
				value: 'Normal VAT',
			},
			{
				label: 'Μειωμένο Φ.Π.Α.',
				value: 'Reduced VAT',
			},
			{
				label: 'Μηδενικό Φ.Π.Α.',
				value: 'Zero VAT',
			}
		]
	} else {
		return [
			{
				label: 'Normal VAT',
				value: 'Normal VAT',
			},
			{
				label: 'Reduced VAT',
				value: 'Reduced VAT',
			},
			{
				label: 'Zero VAT',
				value: 'Zero VAT',
			}
		]
	}
}

export const getSexOptions = () => {
	const user = getCurrentUser();
	if (user?.lang && user.lang === 'el') {
		return [
			{
				label: 'Άνδρας',
				value: 'male',
			},
			{
				label: 'Γυναίκα',
				value: 'female',
			}
		]
	} else {
		return [
			{
				label: 'Male',
				value: 'male',
			},
			{
				label: 'Female',
				value: 'female',
			}
		]
	}
}

export const getIsNetValueOptions = () => {
		return [
			{
				label: 'Όχι',
				value: 'false',
			},
			{
				label: 'Καθαρή Αξία για 24%',
				value: '24',
			},
			{
				label: 'Καθαρή Αξία για 13%',
				value: '13',
			},
			{
				label: 'Καθαρή Αξία για 6%',
				value: '6',
			},
			{
				label: 'Καθαρή Αξία για 17%',
				value: '17',
			},
			{
				label: 'Καθαρή Αξία για 9%',
				value: '9',
			},
			{
				label: 'Καθαρή Αξία για 4%',
				value: '4',
			},
			{
				label: 'Καθαρή Αξία για 3%',
				value: '3',
			},
			{
				label: 'Καθαρή Αξία για 0%',
				value: '0',
			},
			{
				label: 'Καθαρή Αξία άνευ Φ.Π.Α. (Μισθοδοσία, Αποσβέσεις κτλπ.)',
				value: '-',
			},
		];
}

export const getTaxTypeOptions = () => {
	return [
		{
			label: "Κενό",
			value: ""
		},
		{
			label: "Παρακρατούμενος Φόρος",
			value: "withHoldTax"
		},
		{
			label: "Λοιπός Φόρος",
			value: "otherTax"
		},
		{
			label: "Τέλος",
			value: "fee"
		},
		{
			label: "Χαρτόσημο",
			value: "stamp"
		},
	]
}

export const getSubaccountOptions = () => {
	const user= getCurrentUser();
	if(user?.lang && user.lang === 'el') {
		return [
			{
				label: 'Δεν υποστηρίζει',
				value: '',
			},
			{
				label: 'Εγκαταστάσεις',
				value: 'installation',
			},
			{
				label: 'Πελάτες',
				value: 'client',
			},
			{
				label: 'Προμηθευτές',
				value: 'supplier',
			},
			{
				label: 'Εργαζόμενοι',
				value: 'employee',
			},
			{
				label: 'Τραπεζικός Λογαριασμός',
				value: 'bankAccount',
			},
			{
				label: 'POS',
				value: 'pos',
			},
			{
				label: 'Ταμειακή Μηχανή',
				value: 'cashRegister',
			},
			{
				label: "Μέτοχος",
				value: "stockholder"
			}
		]
	} else{
		return [
			{
				label: 'No SubAccount',
				value: '',
			},
			{
				label: 'Installations',
				value: 'installation',
			},
			{
				label: 'Clients',
				value: 'client',
			},
			{
				label: 'Suppliers',
				value: 'supplier',
			},
			{
				label: 'Bank',
				value: 'back',
			},
			{
				label: 'Bank Account',
				value: 'bankAccount',
			},
			{
				label: 'POS',
				value: 'pos',
			},
			{
				label: 'Cash Register',
				value: 'cashRegister',
			},
			{
				label: "Stockholder",
				value: "stockholder"
			}
		]
	}
}

export const getEmployeeTypeOptions = () => {
	return [
		{
			label: 'Κανονικός',
			value: 'normal',
		},
		{
			label: 'Εργατοτεχνίτης Οικοδομικών Εργασιών',
			value: 'constructionWorker',
		},
		{
			label: 'Ναυτικός',
			value: 'marine',
		},
		{
			label: 'Επαγγελματίας Με ΙΚΑ',
			value: 'professionalWithIka',
		},
		{
			label: 'Ειδική Κατηγορία',
			value: 'special',
		},
		{
			label: 'Εκπαιδευτικός',
			value: 'educator',
		},
		{
			label: 'Απασχολούμενος με Εργόσημο',
			value: 'employeedByErgosimo',
		},
		{
			label: 'Αμοιβόμενος με ΔΠΥ',
			value: 'paidByDPY',
		},
		{
			label: 'Ναυτικός σε Γραφείο',
			value: 'marineOffice',
		},
		{
			label: 'Ωρομίσθιος',
			value: 'hourly',
		}
	]
}

export const getWorkingDaysPerWeekOptions = () => {
	return [
		{
			label: "Κενό",
			value: "",
		},
		{
			label: "5",
			value: "5",
		},
		{
			label: "6",
			value: "6",
		},
		{
			label: "Ημερομίσθιος",
			value: "dailyWageEarner",
		}
	]
}

export const getEmployeeCategoryOptions = () => {
	return [
		{
			label: 'Έμμισθος',
			value: 'salaried',
		},
		{
			label: 'Ημερομίσθιος',
			value: 'daily',
		},
		{
			label: 'Ωρομίσθιος',
			value: 'hourly',
		},
		{
			label: 'Εκπαιδευτικός',
			value: 'professor',
		},
	]
}

export const getResignationCauseOptions = () => {
	return [
		{
			label: "Κενό",
			value: ""
		},
		{
			label: "Οικειοθελής",
			value: "voluntary"
		},
		{
			label: "Απόλυση",
			value: "dismissal"
		},
		{
			label: "Συνταξιοδότηση",
			value: "retirement"
		},
		{
			label: "Λήξη Σύμβασης",
			value: "expiredContract"
		},
		{
			label: "Αλλαγή Εργασιακής Σχέσης",
			value: "changeEmploymentRelationship"
		},
		{
			label: "Άλλος",
			value: "other"
		},
		{
			label: "Οικειοθελής με 15 έτη προϋπηρεσίας",
			value: "voluntary15yearExperience"
		}
	]
}

export const getMyDataEnvironments = () => {
	const user= getCurrentUser();
	if(user?.lang && user.lang === 'el') {
		return [{
			label: 'Δοκιμή',
			value: 'test',
		},
			{
				label: 'Παραγωγή',
				value: 'production',
			}];
	} else {
		return [{
			label: 'Test',
			value: 'test',
		},
			{
				label: 'Production',
				value: 'production',
			}];
	}
}

export const getMyDataModes = () => {
	const user= getCurrentUser();
	if(user?.lang && user.lang === 'el') {
		return [{
			label: 'Ζωντανά',
			value: 'live',
		},
			{
				label: 'Χειροκίνητα',
				value: 'manual',
			}];
	} else {
		return [{
			label: 'Live',
			value: 'live',
		},
			{
				label: 'Manual',
				value: 'manual',
			}];
	}

}

export const getVatCategoryAbroadOptions = () => {
	const user=getCurrentUser();
	if(user?.lang && user.lang ==="el") {
		return [{
			label: 'Κανονικό Φ.Π.Α.',
			value: 'Normal VAT',
		},
			{
				label: 'Μειωμένο Φ.Π.Α.',
				value: 'Reduced VAT',
			},
			{
				label: 'Μηδενικό Φ.Π.Α.',
				value: 'Zero VAT',
			},
			{
				label: 'Προσαρμοσμένο Φ.Π.Α.',
				value: 'Custom VAT',
			}];
	} else {
		return [{
			label: 'Normal VAT',
			value: 'Normal VAT',
		},
			{
				label: 'Reduced VAT',
				value: 'Reduced VAT',
			},
			{
				label: 'Zero VAT',
				value: 'Zero VAT',
			},
			{
				label: 'Custom VAT',
				value: 'Custom VAT',
			}];
	}
}

export const getRecordTypeOptions = () => {
	return [
		{ label: "Κανονική", value: "normal" },
		{ label: "Κλεισίματος", value: "closure" },
		{ label: "Απογραφή", value: "census" },
	]
}

export const getAcquirerPOSOptions = (isETameiaki = false) => {
	if (isETameiaki) {
		return [
			{ label: "ePay", value: "epay" },
			{ label: "VIVA", value: "viva" },
			{ label: "EDPS", value: "edps" },
			{ label: "Worldline", value: "worldLine" }
		]
	} else {
		return [
			{ label: "ePay", value: "epay" },
			{ label: "VIVA", value: "viva" },
			{ label: "EDPS", value: "edps" },
			{ label: "myPOS", value: "myPOS" },
			{ label: "Worldline", value: "worldLine" },
			{ label: "Nexi", value: "nexi" },
			{ label: "Everypay", value: "everypay" },
			{ label: "SumUp", value: "sumup"},
			{ label: "myPOS", value: "myPOS" },
			{ label: "Mellon", value: "mellon" },
			{ label: "Άλλο", value: "else" },
		]
	}
}

/*
* Get Token From The Local Storage
*/
export const getToken = () => {
  let userToken;
  const token = localStorage.getItem('token');
  if(token) {
    userToken = token.replace(/['"]+/g, '');
  } else {
    console.warn('User token not found.');
    userToken = false;
  }
  return userToken;
}

/*
* Get Decoded Token From The Local Storage
*/
export const getDecodedToken = () => {
  let decodedToken = "";
  let token = getToken();
  if (token && token !== '') {
    decodedToken = jwt_decode(token);
  }
  return decodedToken;
}
// Get Current User Data
export const getCurrentUser = () => {
	const token = localStorage.getItem("token");
	let user = localStorage.getItem("user");
	if (!user) {
		user = getDecodedToken(token);
	} else {
		user = JSON.parse(user);
	}

	if (user && token && token !== '') {
		return user;
	} else {
		return false;
	}
}

// Get dropdown options by category data
export const productCategoryOptions = (data) => {
	let options = [];
	data.forEach((item) => {
		let obj = {};
		obj.label = item.title;
		obj.value = item._id;
		obj.color = '#76AEB7';
		if ((item.isActive !== "false") && (item.type === "product")) {
			options.push(obj);
		}
	 });
	return options;
}

// Covert Code to Code Mask eg cmd-000 to cmd-***
export const getMaskCode = (code)  => {
	if (code !== '' && code !== undefined) {
		const maskCodeArr = code.split("-");
		return maskCodeArr[0] + '-****';
	}
	return '';
}

// Get Mask Code Prefix
export const getMaskCodePrefix = (code)  => {
	if (code !== '' && code !== undefined) {
		const maskCodeArr = code.split("-");
		return maskCodeArr['0'];
	}
	return '';
}
// It will replace the prefix of given code
export const getMaskCodeWithNewPrefix = (code, prefix)  => {

	if ((code !== '') && (code !== undefined)) {
		const maskCodeArr = code.split("-");
		const maskCodeArrLen = maskCodeArr.length;
		return prefix + '-' + leadingZeros(maskCodeArr[maskCodeArrLen - 1], 4);
	}
	return code;
}

export const leadingZeros = (num, size) => {

	if (num && num !== '') {
		num = parseInt(num).toString();
		while (num.length < size) {
			num = "0" + num
		}
	}
	return num;
}

// Years for ERP Company Selection
export const companyYearOptions = () => {
	const startYear = 2016;
	const endYear = new Date().getFullYear();

	let options = [];
	for(let year = endYear; year >= startYear; year--) {
		options.push({ label: String(year), value: String(year) });
	}

	return options;
}

// Get Template Options
export const getTemplateOptions = (data)  => {
	if (data && data.length > 0) {
		return data.map((template) => ({
			value: template._id,
			label: template.name,
			color: "#76AEB7",
		}));
	} else {
		return [];
	}
}

// Get Objects from Array of Object for Dropdown and Advanced Combobox
export const getOptionsByData = (data, field1, field2)  => {
	if (data && data.length > 0) {
		return data.map((item) => ({
			value: item[field1],
			label: item[field2],
			// color: "#76AEB7",
		}));
	} else {
		return [];
	}
}

// Get Objects from Array of Object for Dropdown and Advanced Combobox
export const getOptionsByDataMultiFields = (data, field1, fields = [])  => {
	if (data && data.length > 0) {
		return data.map((item) => ({
			value: item[field1],
			label: fields.map(field => {
				let labelVal = '';
				if(item[field] !== undefined) {
					if (field === 'code') {
						labelVal +=  ` (${item[field]})`;
					} else {
						labelVal +=  item[field];
					}
				}
				return labelVal;
			}),
		}));
	} else {
		return [];
	}
}

// Get Object by label / value
export const getDefaultOptionObject = (label, value)  => {
	if (label && value) {
		return {
			value: value,
			label: label,
			color: "#76AEB7",
		}
	} else if (label !== ''){
		return {
			value: label,
			label: label,
			color: "#76AEB7",
		}
	} else {
		return null;
	}
}

// Get Default Object By Value
export const getDefaultOptionByValue = (data, id, field = 'id', field1, field2) => {
  let output = {};
  if (data && data.length > 0) {
    const opobj = getObjectFromArrayOfObjects(data, id, field.trim());
    if (opobj.length > 0 && opobj !== 'undefined') {
        output.label = opobj['0'][field1];
    	output.value = opobj['0'][field2];
    }
  }
	return output;
}

// get DOY options
export const getDOYOptions = () => {
	let options;
	options = getOptionsByData(DoyOptions,'value', 'label')
 	return options;
}

// get Country options
export const getCountryOptions = (type = 'all') => {
	let options = [];
	if (type === 'all') {
		options = getOptionsByData(countryList,'code', 'name');
	}
	if (type === 'greece') {
		options = getOptionsByData(onlyGreeceList,'code', 'name');
	}
	if (type === 'insideEU') {
		options = getOptionsByData(countryListEU,'code', 'name');
	}
	if (type === 'outsideEU') {
		options = getOptionsByData(countryListNonEU,'code', 'name');
	}
	return options;
}

// Get Key by Name
export const getKeyByName = (name, prefix) => {
	let key = '';
	if (name) {
		if (prefix && prefix !=='') {
			key = prefix + name.toLowerCase().trim().replaceAll(' ', '_').replaceAll('-', '_')
		} else {
			key = name.toLowerCase().trim().replaceAll(' ', '_').replaceAll('-', '_')
		}
	}
	return key;
}

// Get Table Columns with localstorage hidden fields
export const getSavedHiddenColumns = (tableName, tableColumns) => {
	if(tableName !== 'Product Balance Report' && tableName !== 'Sales Balance Sheet' && tableName !== 'Client Payments Report'  && tableName !== 'Buys Balance Sheet' && tableName !== 'Supplier Payments Report'  && tableName !== 'Product Movements Report'  && tableName !== 'Client Balance Report' && tableName !== 'Supplier Balance Report') {
	if (tableName && tableName !=='' && tableColumns) {
		const key = getKeyByName(tableName, 'ethide_');
		let lsColumns = localStorage.getItem(key);
		if (lsColumns && lsColumns !== '') {
			lsColumns = JSON.parse(lsColumns);
			return tableColumns.map(obj => lsColumns.find(o => o.name === obj.name) || obj);
		}
	}
}
	return tableColumns;
}

// get Installations Types
export const getInstallationsTypes = () => {
	const user = getCurrentUser();
	if(user?.lang && user.lang ==="el") {
		let options = [{
			label: 'Κεντρικό',
			value: "1",
			color: '#76AEB7'
		},
		];

		for(let oid = 2; oid <= 50 ; oid++) {
			options = [...options, { label: `Υποκατάστημα ${oid - 1}`, value: oid.toString() }]
		}
		return options;
	} else {
		let options = [{
			label: 'Main Offices',
			value: "1",
			color: '#76AEB7'
		},
		];

		for(let oid = 2; oid <= 50 ; oid++) {
			options = [...options, { label: `Office Address Subsidiary ${oid - 1}`, value: oid.toString() }]
		}
		return options;
	}

}

// get Installations Types
export const getMainInstallation = () => {
	let firstInstalltion = null;

	let allInstallations = getInstallationsTypes();
	if (allInstallations.length > 0) {
		firstInstalltion = allInstallations['0'];
	}

	return firstInstalltion;
}

export const getMultiSelectOptionsToArrayEdit = (data, invoiceTypes) => {
	let array = [];
	if (data && data.length > 0) {
		data.forEach((item) => {
			let name = invoiceTypes.find(type => type.value === item);
			let obj = {label:name.label, value: item}
			array.push(obj)
		});
		return array;
	}
}
export const getMultiSelectOptionsToArrayEditEditable = (data, options) => {
	let array = [];
	if (data && data.length > 0) {
		data.forEach((item) => {
			if(item?.value){
				let name = options.find(type => type.value === item.value);
				if(name) {
					let obj = {label: name.label, value: item.value}
					array.push(obj)
				}
			} else {
				let name1 = options.find(type => type.value === item);
				if(name1) {
					let obj1 = {label: name1.label, value: item}
					array.push(obj1)
				}
			}
		});
		return array;
	}
}

// comma seperated data to dropdown options
export const getOptionsByCommaString = (str) => {
	let output = [];
	if (str && str !== '') {
		const strArray = str.split(',');
		output = strArray.map((item) => ({
			value: item,
			label: item,
			color: "#76AEB7",
		}));
  }
	return output;
}

// open add event link
// https://calendar.google.com/calendar/u/0/r/eventedit?dates=${event.startDateTime}/${event.endDateTime}&location=${event.location}&text=${event.text}&details=${event.details}
export const addGoogleCalendarEventLink = (event) => {
	let calUrl = `https://calendar.google.com/calendar/u/0/r/eventedit?dates=${event.dates}&text=${event.text}&details=${event.details}`;
	window.open(calUrl); // open new tab
}

// Print Preference
export const getPrintPreference = () => {
	const user = getCurrentUser();
	if(user?.lang && user.lang === "el")
	{
		return [{
			label: 'Εκτυπωτής',
			value: 'printer',
			color: '#76AEB7'
		},
			{
				label: 'PDF',
				value: 'pdf',
				color: '#76AEB7'
			},
			{
				label: 'Excel',
				value: 'excel',
				color: '#76AEB7'
			},
			{
				label: 'Word',
				value: 'word',
				color: '#76AEB7'
			},
		];
	} else {
		return [{
			label: 'Printer',
			value: 'printer',
			color: '#76AEB7'
		},
			{
				label: 'PDF',
				value: 'pdf',
				color: '#76AEB7'
			},
			{
				label: 'Excel',
				value: 'excel',
				color: '#76AEB7'
			},
			{
				label: 'Word',
				value: 'word',
				color: '#76AEB7'
			},
		];
	}
}


export const clearEmptyValuesFromObject = (obj) => {
	for (let propName in obj) {
		if (obj[propName] === null || obj[propName] === 'null' || obj[propName] === '' || obj[propName] === undefined || (Array.isArray(obj[propName]) && obj[propName].length === 0)) {
			delete obj[propName];
		}
	}
	return obj;
}

// Get New Mask Codee
export const getGeneratedMaskCode = (type = 'product', data, product_categories = null,categoryMaskCodePrefix) => {
	let maskCode = 'PC-001';
	if(product_categories !== null && data !== '') {
		for (let i = 0; i < product_categories.length; i++) {
			if (data === product_categories[i]._id) {
					let lastMaskCode = product_categories[i].maskCodeCounter;
				if (type === 'product') {
					maskCode = categoryMaskCodePrefix + '-' + leadingZeros(lastMaskCode, 4);
				}

				if (type === 'client') {
					maskCode = categoryMaskCodePrefix + '-' + leadingZeros(lastMaskCode, 4);
				}
			}
		}
	}

	return maskCode;
}


// Get New Mask Codee
export const getGeneratedMaskCodeCLient = (id, client_categories = null, categoryMaskCodePrefix) => {
	let maskCode = 'PC-001';
	if(client_categories !== null && id !== '') {
		for (let i = 0; i < client_categories.length; i++) {
			if (id === client_categories[i]._id) {
					let lastMaskCode = client_categories[i].maskCodeCounter;
					maskCode = categoryMaskCodePrefix + '-' + leadingZeros(lastMaskCode, 4);
				}
			}
		}
	return maskCode;
}

// Filter Movements Installation
export const filterMovementsInstallations = (allInstallations, documentNumbering) => {
	let filteredInstallations = [];
	if (allInstallations.length > 0 && documentNumbering.length > 0) {
		return allInstallations.filter(function(item) {
			let obj = documentNumbering.find((item2) => item2['installation'] === item.value);
			return !!obj;

		});
	}
	return filteredInstallations;
}

// Filter Movements PreNumber Not Already in use on document numbering page.
export const filterUsedPreNumbers = (allInstallations, documentNumbering, currentValue = '', installationValue = "") => {
	let filteredInstallations = allInstallations;
	if (allInstallations.length > 0 && documentNumbering.length > 0) {
		return allInstallations.filter(function(item) {

			// Show Current Value
			if (currentValue !== '' && item.value === currentValue) {
				return true;
			}

			// Filter the used values
			// let obj = documentNumbering.find((item2) => item2['preNumber'] == item.value);
			let obj = documentNumbering.filter(item2 => {
				if(item2.preNumber === item.value && item2.installation === installationValue) {
					return item2;
				} else {
					return false;
				}
			});

			return !(obj && obj.length > 0);


		});
	}
	return filteredInstallations;
}

// Filter Numbering Modes
export const filterUnusedNumberingModes = (allModes, documentNumbering, showAll = false) => {
	let filteredModes = allModes;
	if (allModes && documentNumbering && allModes?.length > 0 && documentNumbering?.length > 0) {
		return allModes.filter(function(item) {
			// Filter the used values
			let obj = documentNumbering.find((item2) => item2.numberingMode !== item.value);
			if (obj && !showAll) {
				return false;
			} else if (obj && showAll && documentNumbering.length > 1) {
				return false;
			}

			return true;
		});
	}
	return filteredModes;
}

// Get PreNumber by Selected Installation Value
export const getPreNumberByInstallation = (documentNumbering, value, field = 'installation') => {
	let preNumber = "";
	if (documentNumbering && documentNumbering.length > 0) {
		let documberNumberRow = documentNumbering.filter((item) => item[field] === value);
		if (documberNumberRow.length > 0) {
			preNumber = documberNumberRow['0'].preNumber;
		}
	}
	return preNumber;
}

// Get Number by Selected Installation Value
export const getNumberByInstallation = (documentNumbering, value, field = 'installation') => {
	let number = "";
	if (documentNumbering && documentNumbering.length > 0) {
		let documberNumberRow = documentNumbering.filter((item) => item[field] === value);
		if (documberNumberRow.length > 0) {
			number = documberNumberRow['0'].number;
		}
	}
	return number;
}

// Get Mode by Selected Installation Value
export const getModeByInstallation = (documentNumbering, value, field = 'installation') => {
	let mode = "";
	if (documentNumbering && documentNumbering.length > 0) {
		let documberNumberRow = documentNumbering.filter((item) => item[field] === value);
		if (documberNumberRow.length > 0) {
			mode = documberNumberRow['0'].numberingMode;
		}
	}
	return mode;
}

// Get Selected Installation Values from Document Numbering Table
export const getSelectedInstallation = (documentNumbering, value = 'true', field = 'selected') => {
	let selectedInstallation = {};
	if (documentNumbering && documentNumbering.length > 0) {
		let documberNumberRow = documentNumbering.filter((item) => item[field] === value);
		if (documberNumberRow.length > 0) {
			selectedInstallation = documberNumberRow['0'];
		}
	}
	return selectedInstallation;
}

// Get Selected Pre Numbers with Document Types
export const getSelectedPreNumbers = (documentNumbering, allPreNumbers = [], installation) => {
	let preNumbers = allPreNumbers;
	// Get Filtered Objects
	if( installation !== undefined) {
		if(documentNumbering && documentNumbering.length > 0) {
			documentNumbering = documentNumbering.filter(obj => {
				return obj.installationMasterId === installation.toString();
			});
		}
	}
	if (documentNumbering && documentNumbering.length > 0 && allPreNumbers && allPreNumbers.length > 0) {
		preNumbers = documentNumbering.map((item) => ({
			value: item['preNumber'],
			label: item['preNumber'] === 'noprefix' ? 'Κενή' : item['preNumber'],
		}));

		// Remove Duplicates
		preNumbers = removeDuplicateObjects(preNumbers, 'value')
	}

	return preNumbers;
}

// Remove Duplicate Objects
export const removeDuplicateObjects = (originalArray, field) => {
	let newArray = [];
	let lookupObject  = {};
	for (let i in originalArray) {
		lookupObject[originalArray[i][field]] = originalArray[i];
	}
	for (let i in lookupObject) {
		newArray.push(lookupObject[i]);
	}
	return newArray;
}

// Get The Last highest value for array of object
export const getLastHighestValue = (data, field = 'id') => {
	let value = 0;
	if (data && data.length > 0) {
		value =	Math.max.apply(Math, data.map(function(o) { return o[field]; }))
	}
	return value;
}

// Set Advanced Type Items ID on delete
export const resetAdvancedTypeItemsID = (data) => {
	// Reset Id, no after delete
	let newData = [];
	if (data && data.length > 0) {
		for (let i = 0; i < data.length; i++) {
			let newObj = Object.assign({}, data[i]);
			newObj.id = i + 1;
			newObj.no = i + 1;
			newData.push(newObj);
		}
		return newData;
	}
	return data;
}

// Set Advanced Type Selected Row
export const setAdvancedTypesSelectedRow = (data, id) => {
	let newData = [];
	if (data && data.length > 0) {
		for (let i = 0; i < data.length; i++) {
			let newObj = Object.assign({}, data[i]);
			newObj.selected = 'false';
			if (newObj.id === id) {
				newObj.selected = 'true';
			}
			newData.push(newObj);
		}
		return newData;
	}
	return data;
}

// Get The Last Hightest Value for array of object
export const getLastHighestPrefixNumber = (data, field = 'number', prefix) => {
	let value = 0;
	if (data && data.length > 0) {
		value =	Math.max.apply(Math, data.map(function(o) {
			if (o.preNumber === prefix) {
				return o.number;
			}
			return 0;
		}))
	}
	return value;
}

// Reset Table item id and number on delete
export const resetTableItemsId = (data) => {
	let newData = [];
	if (data && data.length > 0) {
		for (let i = 0; i < data.length; i++) {
			let newObj = Object.assign({}, data[i]);
			newObj.id = i + 1;
			newObj.no = i + 1;
			newData.push(newObj);
		}
		return newData;
	}
	return data;
}

// Data For Mananged Numering Count in Advanced Types
export const dataForManagedNumering = (data, company, year) => {
	let newData = [];
	if (data && data.length > 0) {
		for (let i = 0; i < data.length; i++) {
			let newObj = Object.assign({}, data[i]);

			// Delete Numbering Mode
			if ('numberingMode' in newObj) {
        delete newObj.numberingMode
      }

			if ('isActive' in newObj) {
        delete newObj.isActive
      }

			if ('selected' in newObj) {
        delete newObj.selected
      }

			if ('id' in newObj) {
        delete newObj.id
      }

			if ('no' in newObj) {
        delete newObj.no
      }

			// Company and Year
			newObj.company = company;
			newObj.year = year;

			newData.push(newObj);
		}
		return newData;
	}
	return data;
}
export const getDifficultyOptions = () => {
	return [{
		label: '1',
		value: '1',
		},
		{
			label: '2',
			value: '2',
		},
		{
			label: '3',
			value: '3',
		},
		{
			label: '4',
			value: '4',
		},
		{
			label: '5',
			value: '5',
		},
		{
			label: '6',
			value: '6',
		},
		{
			label: '7',
			value: '7',
		},
		{
			label: '8',
			value: '8',
		},
		{
			label: '9',
			value: '9',
		},
		{
			label: '10',
			value: '10',
		},

	];
}

// Get Dropdown Stockholder Positions
export const getStockholderPositions = () => {
	const user = getCurrentUser();
	if(user?.lang && user.lang === "el") {
		return [{
			label: 'Κενό',
			value: '',
		},
			{
				label: 'Μέτοχος',
				value: 'stockholder',
			},
			{
				label: 'Νόμιμος εκπρόσωπος',
				value: 'legal representative',
			},
			{
				label: 'Μέτοχος & Νόμιμος Εκπρόσωπος',
				value: 'stockholder & legal representative',
			},
		];

	} else {
		return [{
			label: 'Blank',
			value: '',
		},
			{
				label: 'Stockholder',
				value: 'stockholder',
			},
			{
				label: 'Legal representative',
				value: 'legal representative',
			},
			{
				label: 'Stockholder & Legal Representative',
				value: 'stockholder & legal representative',
			},
		];

	}
}

// Remove Empty Values From Array
export const removeEmptyValuesFromArray = (data) => {
	let filteredData = []

	if (data.length > 0) {
		filteredData = data.filter(item => {
			return item && item !== "";

		});
	}

	return filteredData;
}

// Validate Email Address
export const isValidEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

// Is Valid Date
export const isValidDate = (date) => {
  let isValid = false;

	if (date && date !== '') {
		let timestamp = Date.parse(date);
		if (!isNaN(timestamp)) {
			isValid = true;
		}
	}

	return isValid;
};
// Save As Template Clear Fields
export const saveAsTemplateClearTableFields = (data, clear = ['name'])  => {
	let newData = [];
	if (data && data.length > 0) {
		for (let i = 0; i < data.length; i++) {
			let newObj = Object.assign({}, data[i]);
			clear.forEach(item => {
				newObj[item] = "";
			})
			newData.push(newObj);
		}
		return newData;
	}
	return data;
}

// Get Client Vat Percentage
export const getClientVatPercentage = (vatCategory, vatPercentage, customVat = 0)  => {

	let vatPer = vatPercentage; // Default vat from the product

	if (vatPercentage === "No VAT No VAT (π.χ. Μισθοδοσία, Αποσβέσεις κλπ)") {
		vatPer = 0;
	} else if (vatPercentage === "Vat For Companies Abroad - (Custom)") {
		vatPer = customVat;
	} else {

		if (vatCategory === 'Normal VAT') {
			vatPer = vatPercentage;
		}

		if (vatCategory === 'Reduced VAT') {
			let vatCatArr = getObjectFromArrayOfObjects(vatCategoryData, vatPercentage, 'percentage');
			if (vatCatArr.length > 0) {
				let vatCatObj = vatCatArr['0'];
				if (vatCatObj.reducedVat) {
					vatPer = ((vatCatObj.reducedVat === '' || vatCatObj.reducedVat === null) ? 0 : vatCatObj.reducedVat);
				}
			}
		}

		if (vatCategory === 'Zero VAT') {
			vatPer = 0;
		}

	}

	return vatPer;
}

// Calculate Net Values for Sales
export const getProductNetValue = (unitPrice = 0, quantity = 1, discount = 0, discountType = 'euro')  => {
	let value = 0;
	let floatDiscount = 0;

	if(quantity >= 1 && unitPrice) {
		let floatQuantity = parseFloat(quantity).toFixed(2);

		let floatUnitPrice = getNormalAmountByGreek(unitPrice);
		floatUnitPrice = parseFloat(floatUnitPrice).toFixed(2);

		let totalProductPrice = (floatQuantity * floatUnitPrice);

		if (discountType === 'euro' || discountType === 'amount') {
			discount = getNormalAmountByGreek(discount);
			floatDiscount = parseFloat(discount).toFixed(2); // if discount in euro / amount
		} else {
			floatDiscount = totalProductPrice * discount / 100; // if discount in percentage
		}

		// Return Zero if total price less then discount
		if (totalProductPrice > floatDiscount) {
			value = totalProductPrice - floatDiscount; // Geting net value
		}

	}

	return value;
}

// Calculate Unit Price for Sales
export const getProductUnitPrice = (value = 0, quantity = 1, discount = 0, discountType = 'euro')  => {
	let unitPrice = 0;
	let floatDiscount = 0;

	if(quantity >= 1) {
		let floatQuantity = parseFloat(quantity).toFixed(2);
		let floatValue = parseFloat(value).toFixed(2);

		if (discountType === 'euro' || discountType === 'amount') {
			floatDiscount = parseFloat(discount).toFixed(2); // if discount in euro / amount
		} else {
			floatDiscount = floatValue * discount / 100; // if discount in percentage
		}

		let totalValue = parseFloat(floatValue) + parseFloat(floatDiscount);

		unitPrice = parseFloat(totalValue) / floatQuantity; // Getting unit price
		unitPrice = parseFloat(unitPrice).toFixed(2);
	}

	return unitPrice;
}

// Calculate Total Value for Sales
export const getProductTotalValue = (vat = 0, netValue = 0)  => {
	let totalValue = 0;

	if(vat && netValue) {
		let intVat = parseInt(vat);
		let floatNetValue = parseFloat(netValue).toFixed(2);
		let totalVatValue = (intVat * floatNetValue / 100);

		totalValue = parseFloat(totalVatValue) + parseFloat(floatNetValue); // Getting total price
		totalValue = parseFloat(totalValue).toFixed(2);

	}

	return totalValue;
}

// Calculate Net Values by Total value
export const getProductNetValueByTotalValue = (totalValue = 0, vat = 1)  => {
	let netValue = 0;

	if(totalValue && vat) {
		let intVat = parseInt(vat);
		let floatTotalValue = parseFloat(totalValue).toFixed(2);
		let vatPerValue = intVat / 100;
		netValue = floatTotalValue / (1 + vatPerValue); // Geting net value
		netValue = parseFloat(netValue).toFixed(2);
	}

	return netValue;
}

// Convert Greek Amount to Normal Amount
export const getNormalAmountByGreek = (greekValue = "") => {
	let value  = 0;

	if (greekValue && greekValue !== '') {
		value = greekValue.toString().replaceAll('.','').replaceAll(',', '.');
	}

	return value;
}

// Get Sales Product Totals
export const getSalesProductsTotals = (products) => {

	let totals  = {
		totalQuantity : 0,
		totalBeforeDiscount : 0,
		totalDiscount : 0,
		totalNetValue : 0,
		totalAmount : 0,
		paymentAmount : 0,
	}

	if (products && products.length > 0) {
		products.forEach((item) => {

			let priceBeforeDiscount = 0;
			let discount = item.discount && item.discount !== "" ? item.discount : 0;
			let quantity = item.quantity && item.quantity !== "" ? item.quantity : 1;

			// check
			if (item.unitPrice && quantity) {
				if (quantity === '') {
					quantity = 1;
				}

				if (item.unitPrice === '') {
					item.unitPrice = 0;
				}

				priceBeforeDiscount = parseFloat(item.unitPrice) * parseFloat(item.quantity);
			}

			totals.totalQuantity = parseFloat(parseFloat(totals.totalQuantity) + parseFloat(quantity)).toFixed(2);
			totals.totalBeforeDiscount = parseFloat(parseFloat(totals.totalBeforeDiscount) + parseFloat(priceBeforeDiscount)).toFixed(2);

			totals.totalDiscount = parseFloat(parseFloat(totals.totalDiscount) + parseFloat(discount)).toFixed(2);
			totals.totalNetValue = parseFloat(parseFloat(totals.totalNetValue) + parseFloat(item.netValue)).toFixed(2);
			totals.totalAmount = parseFloat(parseFloat(totals.totalAmount) + parseFloat(item.totalValue)).toFixed(2);

		});
		totals.paymentAmount = parseFloat(parseFloat(totals.totalAmount) + getSalesProductsWithholdTax(products) + getSalesProductsOtherTax(products)).toFixed(2);

	}

	return totals;
}

// Get Buys Product Totals
export const getBuysProductsTotals = (products) => {

	let totals  = {
		totalQuantity : 0,
		totalBeforeDiscount : 0,
		totalDiscount : 0,
		totalNetValue : 0,
		totalAmount : 0,
		paymentAmount : 0,
	}

	if (products && products.length > 0) {
		products.forEach((item) => {

			let priceBeforeDiscount = 0;
			let discount = item.discount && item.discount !== "" ? item.discount : 0;
			let quantity = item.quantity && item.quantity !== "" ? item.quantity : 1;

			// check
			if (item.unitPrice && quantity) {
				if (quantity === '') {
					quantity = 1;
				}

				if (item.unitPrice === '') {
					item.unitPrice = 0;
				}

				priceBeforeDiscount = parseFloat(item.unitPrice) * parseFloat(item.quantity);
			}

			totals.totalQuantity = parseFloat(parseFloat(totals.totalQuantity) + parseFloat(quantity)).toFixed(2);
			totals.totalBeforeDiscount = parseFloat(parseFloat(totals.totalBeforeDiscount) + parseFloat(priceBeforeDiscount)).toFixed(2);

			totals.totalDiscount = parseFloat(parseFloat(totals.totalDiscount) + parseFloat(discount)).toFixed(2);
			totals.totalNetValue = parseFloat(parseFloat(totals.totalNetValue) + parseFloat(item.netValue)).toFixed(2);
			totals.totalAmount = parseFloat(parseFloat(totals.totalAmount) + parseFloat(item.totalValue)).toFixed(2);

		});
		totals.paymentAmount = parseFloat(parseFloat(totals.totalAmount) + getBuysProductsWithholdTax(products) + getBuysProductsOtherTax(products)).toFixed(2);

	}

	return totals;
}

// Get Sales Product Totals
export const getQrsProductTotals = (products) => {

	let totals  = {
		productName : "Totals",
		quantity : 0,
		price : 0,
		totals : 0,
	}

	if (products && Array.isArray(products) && products.length > 0) {
		products.forEach((item) => {

			let quantity = item.quantity && item.quantity !== "" ? item.quantity : 0;

			totals.quantity = parseFloat(parseFloat(totals.quantity) + parseFloat(quantity)).toFixed(2);
			totals.price = parseFloat(parseFloat(totals.price) + parseFloat(item.price)).toFixed(2);
			totals.totals = parseFloat(parseFloat(totals.totals) + parseFloat(item.totals)).toFixed(2);

		});
	}

	return totals;
}

// Get Withhold tax by code id
export const getWithholdTaxValue = (withholdTaxCode, amount) => {
	let currentWithholdTax = getObjectFromArrayOfObjects(withHoldingTaxesData, withholdTaxCode, 'code');

	if (currentWithholdTax && currentWithholdTax.length > 0) {
		if (currentWithholdTax['0'].taxPer === 'true') {
			return parseFloat(currentWithholdTax['0'].tax.replaceAll('%', '')) * parseFloat(amount) / 100;
		} else {
			return currentWithholdTax['0'].tax && currentWithholdTax['0'].tax !== '' ? currentWithholdTax['0'].tax : 0;
		}
	}
	return 0;
}

// Get Other tax by code id
export const getOtherTaxValue = (otherTaxCode, amount) => {
	let currentOtherTax = getObjectFromArrayOfObjects(otherTaxesData, otherTaxCode, 'code');

	if (currentOtherTax && currentOtherTax.length > 0) {
		if (currentOtherTax['0'].taxPer === 'true') {
			return parseFloat(currentOtherTax['0'].tax.replaceAll('%', '')) * parseFloat(amount) / 100;
		} else {
			return currentOtherTax['0'].tax && currentOtherTax['0'].tax !== '' ? currentOtherTax['0'].tax : 0;
		}
	}
	return 0;
}

// Get Sales Products Taxes Data
export const getSalesProductsWithholdTax = (products) => {

	let taxesData = [];

	if (products && products.length > 0) {
		products.forEach((item) => {

			let vatTaxes = {};

			let pVatPer = item.vatPercentage.toString().replaceAll('%', '');
			let pNetValue = item.netValue;
			let withholdTaxEuro = getWithholdTaxValue(item.withHoldTax, pNetValue);
			let pWithholdTaxes = item.withHoldTax && item.withHoldTax !=='' ?  parseFloat(withholdTaxEuro) : 0;

			// Check if vat percentage already exists
			pVatPer = pVatPer + "%";
			let isVatRowAlreadyExists = getObjectFromArrayOfObjects(taxesData, pVatPer, 'vatPercentage')
			if (isVatRowAlreadyExists && isVatRowAlreadyExists.length > 0) {
				let productIndex = taxesData.findIndex(xProduct => xProduct.vatPercentage === pVatPer);
				let existingRowData = isVatRowAlreadyExists['0'];
				let updateExistingRow = {}

				updateExistingRow.withholdTaxes = parseFloat(existingRowData.withholdTaxes) + parseFloat(pWithholdTaxes);

				// Update the array with new data
				taxesData[productIndex] = updateExistingRow;

			} else {
				vatTaxes[`withholdTaxes`] = pWithholdTaxes;
				taxesData.push(vatTaxes);
			}

		});
	}

	// Total Calculation
	if (taxesData.length > 0) {
		return getTotalsOfField(taxesData, 'withholdTaxes');
	}

	return taxesData;
}


// Get Buys Products Taxes Data
export const getBuysProductsWithholdTax = (products) => {

	let taxesData = [];

	if (products && products.length > 0) {
		products.forEach((item) => {

			let vatTaxes = {};

			let pVatPer = item.vatPercentage.toString().replaceAll('%', '');
			let pNetValue = item.netValue;
			let withholdTaxEuro = getWithholdTaxValue(item.withHoldTax, pNetValue);
			let pWithholdTaxes = item.withHoldTax && item.withHoldTax !=='' ?  parseFloat(withholdTaxEuro) : 0;

			// Check if vat percentage already exists
			pVatPer = pVatPer + "%";
			let isVatRowAlreadyExists = getObjectFromArrayOfObjects(taxesData, pVatPer, 'vatPercentage')
			if (isVatRowAlreadyExists && isVatRowAlreadyExists.length > 0) {
				let productIndex = taxesData.findIndex(xProduct => xProduct.vatPercentage === pVatPer);
				let existingRowData = isVatRowAlreadyExists['0'];
				let updateExistingRow = {}

				updateExistingRow.withholdTaxes = parseFloat(existingRowData.withholdTaxes) + parseFloat(pWithholdTaxes);

				// Update the array with new data
				taxesData[productIndex] = updateExistingRow;

			} else {
				vatTaxes[`withholdTaxes`] = pWithholdTaxes;
				taxesData.push(vatTaxes);
			}

		});
	}
	// Total Calculation
	if (taxesData.length > 0) {
		return getTotalsOfField(taxesData, 'withholdTaxes');
	}
	return taxesData;
}

// Get Sales Products Taxes Data
export const getSalesProductsOtherTax = (products) => {

	let taxesData = [];

	if (products && products.length > 0) {
		products.forEach((item) => {

			let vatTaxes = {};

			let pVatPer = item.vatPercentage.toString().replaceAll('%', '');
			let pNetValue = item.netValue;
			let otherTaxEuro = getOtherTaxValue(item.otherTax, pNetValue);
			let pOtherTaxes = item.otherTax && item.otherTax !=='' ?  parseFloat(otherTaxEuro) : 0;

			// Check if vat percentage already exists
			pVatPer = pVatPer + "%";
			let isVatRowAlreadyExists = getObjectFromArrayOfObjects(taxesData, pVatPer, 'vatPercentage')
			if (isVatRowAlreadyExists && isVatRowAlreadyExists.length > 0) {
				let productIndex = taxesData.findIndex(xProduct => xProduct.vatPercentage === pVatPer);
				let existingRowData = isVatRowAlreadyExists['0'];
				let updateExistingRow = {}

				updateExistingRow.otherTaxes = parseFloat(existingRowData.otherTaxes) + parseFloat(pOtherTaxes);

				// Update the array with new data
				taxesData[productIndex] = updateExistingRow;

			} else {
				vatTaxes[`otherTaxes`] = pOtherTaxes;
				taxesData.push(vatTaxes);
			}

		});
	}

	// Total Calculation
	if (taxesData.length > 0) {
		return getTotalsOfField(taxesData, 'otherTaxes');
	}
	return taxesData;
}

// Get Buys Products Taxes Data
export const getBuysProductsOtherTax = (products) => {

	let taxesData = [];

	if (products && products.length > 0) {
		products.forEach((item) => {

			let vatTaxes = {};

			let pVatPer = item.vatPercentage.toString().replaceAll('%', '');
			let pNetValue = item.netValue;
			let otherTaxEuro = getOtherTaxValue(item.otherTax, pNetValue);
			let pOtherTaxes = item.otherTax && item.otherTax !=='' ?  parseFloat(otherTaxEuro) : 0;

			// Check if vat percentage already exists
			pVatPer = pVatPer + "%";
			let isVatRowAlreadyExists = getObjectFromArrayOfObjects(taxesData, pVatPer, 'vatPercentage')
			if (isVatRowAlreadyExists && isVatRowAlreadyExists.length > 0) {
				let productIndex = taxesData.findIndex(xProduct => xProduct.vatPercentage === pVatPer);
				let existingRowData = isVatRowAlreadyExists['0'];
				let updateExistingRow = {}

				updateExistingRow.otherTaxes = parseFloat(existingRowData.otherTaxes) + parseFloat(pOtherTaxes);

				// Update the array with new data
				taxesData[productIndex] = updateExistingRow;

			} else {
				vatTaxes[`otherTaxes`] = pOtherTaxes;
				taxesData.push(vatTaxes);
			}

		});
	}

	// Total Calculation
	if (taxesData.length > 0) {
		return getTotalsOfField(taxesData, 'otherTaxes');
	}
	return taxesData;
}


// Check if is ISO Date
export const checkIsISODateFormat = (date) =>  {
	if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(date)) {
		return false;
	}
	const d = new Date(date);
	return d.toISOString() === date;
}

// Convert ISO String 2013-03-10T02:00:00Z format to dd/mm/yyyy
export const getISOStringDateToDate = (date) => {
	let newDate = date;

	if (date !== '') {
		let isISODate = checkIsISODateFormat(date);
		if (isISODate) {
			let datetime = new Date(date);
			newDate = datetime.toLocaleDateString('en-GB');
		}
	}

	return newDate;
}

// Get Totals of fields by data
export const getTotalsOfField = (data, field = '') => {
	let totalValue = 0;
	data.forEach((item) => {
		totalValue += parseFloat(item[field]);
	});
	return totalValue;
}

// Get Greek Currency Format
export const getGreekCurrencyFormat = (amount = 0) => {

	// Case if the amount is not a number or empty
	if (isNaN(amount) || isEmpty(amount)) {
		if (amount?.toString().includes("%") || amount?.toString().includes("€")) {
			// Do nothing
		} else {
			amount = 0; // Set zero
		}
	} else {
		amount = parseFloat(amount).toLocaleString('el-GR', {style:'currency', currency:'EUR'})
	}
	return amount;
}

// Get Multi Select Options to Array
export const getMultiSelectOptionsToArray = (data) => {
	let options = [];
	if (data && data.length > 0) {
		data.forEach((item) => {
			options.push(item.value);
		});
	}
	return options;
}

/*
* Get Amount with Vat
* @params amount, vat, vatType (inclusive/exclusive), amountType (per/value)
* @return amount (Float)
*/
export const getAmountWithVat = (amount, vat, vatIncluded = 'false', amountType = 'per') => {
	let amountWithVat = amount;

	// Get Val Type by vat Included
	// false means exclusive and true means inclusive
	let vatType = 'inclusive';
	if (vatIncluded === 'false') {
		vatType = 'exclusive';
	}

	if (amount && amount !== "" && vat && vat !== '') {
		if (vatType === 'inclusive') {
			return parseFloat(amount);
		} else {
			// Calculate Percentage Vat
			if (amountType) {
				// Exclusive Vat Formula => Amount * (100 + VAT Percentage) / 100 = Amount inclusive of VAT.
				amountWithVat = parseFloat(amount) * (100 + parseFloat(vat)) / 100;
			}

			// Calculate Value VAT
			if (amountType === 'value') {
				amountWithVat = parseFloat(amount) + (parseFloat(vat) * parseFloat(amount) / 100);
			}
		}
	}

	return parseFloat(amountWithVat);
}

// It will be used on edit pages to manage the changes log
// Get the differences between two objects
// Obj1 is Old Data
// Obj2 is new data
export const getDifferenceBetweenObjects = (obj1, obj2) => {
	let keysFound = [];

	if (obj1 !== null && obj2 !== null && (typeof obj1 == 'object') && (typeof obj2 == 'object')) {

		let obj1Keys = Object.keys(obj1);
		let obj2Keys = Object.keys(obj2);
		let finalObjectkeys = obj2Keys;

		if (obj1Keys.length < obj2Keys.length) {
			finalObjectkeys = obj2Keys;
		}

		// Delete some keys that no need to compare
		let deleteKeys = ['_id', 'created_at', 'update_at', '__v', 'company', 'year', 'type']
		if (deleteKeys && deleteKeys.length > 0) {
			deleteKeys.forEach(field => {
				// Deleting array values
				let foFIndex = finalObjectkeys.indexOf(field);
				if (foFIndex > -1) {
					finalObjectkeys.splice(foFIndex, 1);
				}
			});
		}

		// Object check top level 1
		finalObjectkeys.forEach(key => {
			if (Array.isArray(obj1[key])) {
				// Inner Array check level 2
				if(JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
					obj1[key].forEach((innerItem, iiIndex) => {
						if(JSON.stringify(innerItem) !== JSON.stringify(obj2[key][iiIndex])) {
							let logChangeMsg = `${key}: => `;
							if (typeof innerItem === 'object') {
									let objVal = getDifferenceBetweenObjects(innerItem, obj2[key][iiIndex]);
									logChangeMsg += `${iiIndex} => ` + objVal;
							} else {
								logChangeMsg += innerItem ? innerItem : 'empty'; // if value of array
							}
							keysFound.push(logChangeMsg);
						}
					});
				}
			} else if (typeof obj1[key] === 'object') {
				// Inner Object Check level 2
				// Object.keys(obj1[key]).forEach((oItem) => {
					if(JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])){
						let logChangeMsg;
						let objVal = getDifferenceBetweenObjects(obj1[key], obj2[key]);
						logChangeMsg = `${key}: => ` + objVal ? objVal : "empty";
						keysFound.push(logChangeMsg);
					}
				// });
			} else {
				// Field value compare level 1
				if(obj1[key] !== obj2[key]){
					let obj1Val = obj1[key] ? obj1[key] : "empty";
					let obj2Val = obj2[key] ? obj2[key] : "empty";
					let logChangeMsg = `${key}: ${obj1Val} -> ${obj2Val}`;
					keysFound.push(logChangeMsg);
				}
			}
		});

	} else {
		if ((typeof obj1 == 'object') && (typeof obj2 != 'object')) {
			let logChangeMsg = 'Deleted =>' + (getSearchLogFieldsString(obj1));
			keysFound.push(logChangeMsg);
		} else if ((typeof obj1 != 'object') && (typeof obj2 == 'object')) {
			let logChangeMsg = 'Added =>' + (getSearchLogFieldsString(obj2));
			keysFound.push(logChangeMsg);
		} else {
			console.log('Invalid object found.', obj1, obj2);
		}
	}

	 return keysFound;
};

// Get Totals of fields by data
export const getSearchLogFieldsString = (fields) => {
  let fieldsArr = [];

	if (fields.company) {
    delete fields.company;
  }

  if (fields.year) {
    delete fields.year;
  }

  if (typeof fields === 'object') {
    Object.keys(fields).forEach((field) => {
      let fieldString;

      if (Array.isArray(fields[field])) {
        fieldString = `${field}: ${fields[field].join(', ')}`;
      } else {
        fieldString = `${field}: ${fields[field]}`;
      }

      fieldsArr.push(fieldString);
    });
  }

  return fieldsArr.join(', ');
}

// It will used add log page
// Remove empty arrray, object and fields
export const createAddLogString = (obj1) => {
	let keysFound = [];

	if (typeof obj1 == 'object') {

		// Remove Empty Fields
		obj1 = clearEmptyValuesFromObject(Object.assign({}, obj1));

		let finalObjectkeys = Object.keys(obj1);

		// Delete some keys that no need to compare
		let deleteKeys = ['_id', 'created_at', 'update_at', '__v', 'company', 'year']
		if (deleteKeys && deleteKeys.length > 0) {
			deleteKeys.forEach(field => {
				// Deleting array values
				let foFIndex = finalObjectkeys.indexOf(field);
				if (foFIndex > -1) {
					finalObjectkeys.splice(foFIndex, 1);
				}
			});
		}

		// Object check top level 1
		finalObjectkeys.forEach(key => {
			if (Array.isArray(obj1[key])) {
					obj1[key].forEach((innerItem, iiIndex) => {
						let logChangeMsg = `${key}: => `;
						if (typeof innerItem === 'object') {
								let objVal = createAddLogString(innerItem);
								logChangeMsg += `${iiIndex} => ` + objVal;
						} else {
							logChangeMsg += innerItem ? innerItem : 'empty'; // if value of array
						}
						keysFound.push(logChangeMsg);
					});
			} else if (typeof obj1[key] === 'object') {
				let logChangeMsg;
				let objVal = createAddLogString(obj1[key]);
				logChangeMsg = `${key}: => ` + objVal ? objVal : "empty";
				keysFound.push(logChangeMsg);
			} else {
					let obj1Val = obj1[key] ? obj1[key] : "empty";
					let logChangeMsg = `${key}: ${obj1Val}`;
					keysFound.push(logChangeMsg);
			}
		});

	} else {
		let logChangeMsg = 'Invalid object';
		keysFound.push(logChangeMsg);
		console.log('Invalid object found.', obj1);
	}

	keysFound = removeEmptyValuesFromArray(keysFound);

	// Unexcecuted expression - Converting value to name
	if (keysFound.printPreferenceName && keysFound.printPreference) {
		keysFound.printPreferenceName = keysFound.printPreference.capitalize();
	}

	return keysFound.join(', ');
};

// Check if the value already exists
// data = array if objects
export const isAlreadyExists = (data, field, value) => {
	let isExists = false;

	if(Array.isArray(data)) {
		let obj = data.find((item) => item[field]?.toString() === value?.toString());
		if (obj) {
			isExists = true;
		}
	}

	return isExists;
}

// Sales My Data Status Options
export const salesMyDataStatus = () => {
	const user = getCurrentUser();
	if(user?.lang && user.lang === "el"){
		return [
			{
				label: 'Δεν εστάλη',
				value: 'not_sent',
			},
			{
				label: 'Αδυναμία Σύνδεσης',
				value: 'aade_offline',
			},
			{
				label: 'Εστάλη',
				value: 'sent',
			}
		];
	} else {
		return [
			{
				label: 'Not Sent',
				value: 'not_sent',
			},
			{
				label: 'AADE offline',
				value: 'aade_offline',
			},
			{
				label: 'Sent',
				value: 'sent',
			}
		];
	}
}

export const paymentStatusOptions = () => {
	const user = getCurrentUser();
	if(user?.lang && user.lang === "el") {
		return [
			{
				label: "Κενό",
				value: "",
			},
			{
				label: "Μη εξοφλημένη",
				value: "unpaid",
			},
			{
				label: "Μερικώς εξοφλημένη",
				value: "semipaid",
			},
			{
				label: "Εξοφλημένη",
				value: "paid",
			}
		]
	} else {
		return [
			{
				label: "Empty",
				value: "",
			},
			{
				label: "Non paid",
				value: "unpaid",
			},
			{
				label: "Semi paid",
				value: "semipaid",
			},
			{
				label: "Paid",
				value: "paid",
			}
		]
	}
}

export const providerChannelOptions = () => {
		return [
			{
				label: 'Κενό',
				value: '',
			},
			{
				label: 'Primer E-shop Plugin',
				value: 'Primer Plugin',
			},
			{
				label: 'eΤαμειακή',
				value: 'eΤαμειακή',
			},
			{
				label: 'Εξωτερικό Λογισμικό',
				value: 'Εξωτερικό Λογισμικό',
			},
			{
				label: ' Primer ERP',
				value: 'ERP',
			}
		];
}


// Buys My Data Status Options
export const buysMyDataStatus = () => {
	return [
		{
			label: 'Not Sent',
			value: 'not_sent',
		},
		{
			label: 'AADE offline',
			value: 'aade_offline',
		},
		{
			label: 'Sent',
			value: 'sent',
		}
	];
}

// Sales My Data Status Options
export const salesPrintTemplates = () => {
	const user = getCurrentUser();
	if(user?.lang && user.lang === "el") {
		return [{
			label: 'Κενό',
			value: '',
		}, {
			label: 'A4 (Ελληνικό)',
			value: 'sales-print-template1-greek',
		}, {
			label: 'A4 (Αγγλικό)',
			value: 'sales-print-template1-english',
		}, {
			label: 'A5 (Ελληνικό)',
			value: 'sales-print-a5-greek',
		}, {
			label: 'A5 (Αγγλικό)',
			value: 'sales-print-a5-english',
		}, {
			label: 'Διπλό A5 (Ελληνικό)',
			value: 'sales-print-a5-double-greek',
		}, {
			label: 'Διπλό A5 (Αγγλικό)',
			value: 'sales-print-a5-double-english',
		}];
	} else {
		return [{
			label: 'Null (Blank)',
			value: '',
		}, {
			label: 'A4 (Greek)',
			value: 'sales-print-template1-greek',
		}, {
			label: 'A4 (English)',
			value: 'sales-print-template1-english',
		}, {
			label: 'A5 (Greek)',
			value: 'sales-print-a5-greek',
		}, {
			label: 'A5 (English)',
			value: 'sales-print-a5-english',
		}, {
			label: 'Double A5 (Greek)',
			value: 'sales-print-a5-double-greek',
		}, {
			label: 'Double A5 (English)',
			value: 'sales-print-a5-double-english',
		}];
	}
}

export const clientPaymentsPrintTemplates = () => {
	const user = getCurrentUser();
	if(user?.lang && user.lang === "el") {
		return [{
			label: 'Κενό',
			value: '',
		}, {
			label: 'A4 (Ελληνικό)',
			value: 'clientpayments-print-template1-greek',
		}, {
			label: 'A4 (Αγγλικό)',
			value: 'clientpayments-print-template1-english',
		}];
	} else {
		return [{
			label: 'Null (Blank)',
			value: '',
		}, {
			label: 'A4 (Greek)',
			value: 'clientpayments-print-template1-greek',
		}, {
			label: 'A4 (English)',
			value: 'clientpayments-print-template1-english',
		}];
	}
}

// Sales My Data Status Options
export const buysPrintTemplates = () => {
	const user = getCurrentUser()
	if(user?.lang && user.lang === "el") {
		return [{
			label: 'Κενό',
			value: '',
		}, {
			label: 'A4 (Ελληνικό)',
			value: 'buy-print-a4-greek',
		}, {
			label: 'A4 (Αγγλικό)',
			value: 'buy-print-a4-english',
		}];
	} else {
		return [{
			label: 'Null (Blank)',
			value: '',
		}, {
			label: 'A4 (Greek)',
			value: 'buy-print-a4-greek',
		}, {
			label: 'A4 (English)',
			value: 'buy-print-a4-english',
		}];
	}
}



// Get Sales Print Data
export const getSalesPrintData = (type, sales, company, SETTINGS, printLayout)  => {
	/*
		type = templateType
		sales = SALES from state
		company = COMPANY_DATA from state

	    printLayouts for sales:
		sales-print-template1-greek     is A4
		sales-print-template1-english   is A4
		sales-print-a5-greek            is A5
		sales-print-a5-double-greek     is A5
		sales-print-a5-english          is A5
		sales-print-a5-double-english   is A5
	 */
	let size = "A4";
	if(printLayout === "sales-print-a5-greek" || printLayout === "sales-print-a5-double-greek" || printLayout === "sales-print-a5-english" || printLayout === "sales-print-a5-double-english") size = "A5";

	let salesData = {};
	const salesPurposeOptions = getMyData({type: 'salePurpose', settings: SETTINGS});
	if (type === 'sales') {
		salesData = sales.newSales;
	}
	if (type === 'editSales') {
		salesData = sales.editSales;
	}
	let branch = "";
	if(salesData?.installation !== "0"){
		if(company?.companyInstallations?.length > 0) {
			company.companyInstallations.map(installation => {
				if (installation?.installationMasterId === salesData?.installation) {
					branch = installation;
				}
				return installation;
			})
		}
	}

	let templateDate = new Date(salesData.date)
	templateDate = templateDate.getDate()+'/'+(templateDate.getMonth()+1)+'/'+templateDate.getFullYear();
	let printTemplateData = {
		ISSUER_NAME: company.companyDetails?.name,
		ISSUER_LOGO: company.companyDetails?.useLogo === "active" ? company.companyDetails?.companyLogo : "",
		ISSUER_SMALL_NAME: company.companyDetails?.smallName,
		ISSUER_SUBJECTFIELD: company.companyDetails?.activity,
		ISSUER_ADDRESS: company.companyDetails?.headquartersAddress,
		ISSUER_CITY: company.companyDetails?.city,
		ISSUER_TK: company.companyDetails?.postalCode,
		ISSUER_PHONE: company.companyDetails?.phone,
		ISSUER_DOY: company.companyDetails?.doy,
		ISSUER_VAT: company.companyDetails?.vatNumber,
		ISSUER_EMAIL: company.companyDetails?.email,
		ISSUER_GEMH: company.companyDetails?.gemhNo,
		ISSUER_WEBPAGE: company.companyDetails?.webpage,
		BRANCH: branch,
		INVOICE_TYPE: salesData?.documentTypeName,
		SERIES: salesData?.preNumber !== "noprefix" ? salesData?.preNumber : "",
		NUMBER: salesData?.number.toString().replace(salesData?.preNumber,''),
		INVOICE_DATE: templateDate,
		INVOICE_TIME: salesData?.time,
		CUSTOMER_CODE: salesData?.clientCode,
		CUSTOMER_NAME: salesData.myDataType === "11.1" || salesData.myDataType === "11.2" || salesData.myDataType === "11.4" ? salesData?.clientName : "",
		CUSTOMER_COMPANY: salesData.myDataType !== "11.1" && salesData.myDataType !== "11.2" && salesData.myDataType !== "11.4" ? salesData?.clientName : "",
		CUSTOMER_ACTIVITY: salesData?.activityName,
		CUSTOMER_EMAIL: salesData?.email,
		CUSTOMER_PHONE: salesData?.phone,
		CUSTOMER_VAT: salesData?.vat,
		CUSTOMER_DOY: salesData?.doy,
		CUSTOMER_ADDRESS: salesData?.address,
		CUSTOMER_CITY: salesData?.city,
		CUSTOMER_ZIP: salesData?.pc,
		SALER_NAME: "",
		SALER_COMPANY_NAME: "",
		SALER_EMAIL: "",
		SALER_GEMH: "",
		SALER_VAT: "",
		TAX_REPRESENTATIVE: "",
		TAX_REPRESENTATIVE_VAT: "",
		LOCATION_NAME: "",
		PAYMENT_METHOD: salesData?.paymentTypeName,
		PURPOSE: salesData?.salePurpose !== "19" ? getDefaultOptionByValue(salesPurposeOptions, salesData?.salePurpose, 'value', 'label', 'value')?.label : salesData?.salePurposeName,
		HIGHLIGHT: "",
		CORRELATION: salesData?.correlationName,
		DISPATCH_TIME: salesData?.goodsSendDate ? getISOStringDateToDate(salesData?.goodsSendDate) : '',
		VEHICLE_NUMBER: salesData?.extraData[0]?.carNumberName,
		VATEXPCAT: "",
		VATEXPCATCODE: "",
		INVOICE_NOTES: salesData.notes,
		TOTAL_QTY: salesData.totalQuantity,
		TOTAL_WITHOUT_DISCOUNT: salesData?.enablePeppol === "active" ? Number(salesData.totalBeforeDiscount).toFixed(2) : getGreekCurrencyFormat(salesData.totalBeforeDiscount),
		TOTAL_DISCOUNT: salesData?.enablePeppol === "active" ? Number(salesData.totalDiscount).toFixed(2) : getGreekCurrencyFormat(salesData.totalDiscount),
		TOTAL_NOVAT: salesData?.enablePeppol === "active" ? Number(salesData.totalNetValue).toFixed(2) : getGreekCurrencyFormat(salesData.totalNetValue),
		TOTAL_VAT: salesData?.enablePeppol === "active" ? Number(salesData.totalVatValue).toFixed(2) : getGreekCurrencyFormat(salesData.totalVatValue),
		PRICE_WITHVAT: salesData?.enablePeppol === "active" ? Number(salesData.totalAmount).toFixed(2) : getGreekCurrencyFormat(salesData.totalAmount),
		TOTAL_PRICE: salesData?.enablePeppol === "active" ? Number(salesData.paymentAmount).toFixed(2) + " EUR" : getGreekCurrencyFormat(salesData.paymentAmount),
		TOTAL_TAXES_AMOUNT: 0, // Other & Fees & Stamp
		MARK: salesData?.mark,
		UID: salesData?.uid,
		AUTHCODE: salesData?.authCode,
		TRANSMISSION_FAILURE: salesData?.transmissionFailure,
		MYDATAENVIRONMENT: salesData?.myDataEnvironment,
		MYDATADESTINATION: salesData?.myDataDestination,
		PRODUCT_DETAILS: [],
		VAT_TABLE: [],
		TOTAL_PAGES: [],
		TOO_MANY_CUSTOMER_DETAILS: false,
	}
	if (!isEmpty(printTemplateData.CUSTOMER_DOY) && !isNaN(printTemplateData.CUSTOMER_DOY)) {
		const matchingDOY = getDOYOptions().find((el) => String(el.value) === String(printTemplateData.CUSTOMER_DOY));
		if (matchingDOY) printTemplateData.CUSTOMER_DOY = matchingDOY.label;
	}
	if (String(printTemplateData.CUSTOMER_NAME).length + String(printTemplateData.CUSTOMER_COMPANY).length
		+ String(printTemplateData.CUSTOMER_ACTIVITY).length + String(printTemplateData.CUSTOMER_ADDRESS).length > 60) {
		printTemplateData.TOO_MANY_CUSTOMER_DETAILS = true;
	}
	if (!isEmpty(salesData?.displayedBalance)) {
		printTemplateData.OLD_BALANCE = getGreekCurrencyFormat(String(salesData.displayedBalance).split(" ")[0]);
		printTemplateData.NEW_BALANCE = getGreekCurrencyFormat(String(salesData.displayedBalance).split(" ")[1]);
	}
	let totalWithhold = 0;
	let totalDeductions = 0;
	const invoiceLevelTax = salesData?.salesTaxes?.find((el) => el.vatPercentage === "Invoice Level Tax");
	if (invoiceLevelTax) {
		totalWithhold += parseFloat(invoiceLevelTax.withholdTaxes);
		if (invoiceLevelTax.deductions) {
			totalDeductions += parseFloat(invoiceLevelTax.withholdTaxes);
		}
	}
	const ROW_HEIGHT = 30; // Set the height of each row in pixels
	let MAX_ROWS_PER_PAGE = salesData?.enablePeppol === "active" ? 5 : 8; // Set the maximum number of rows per page
	printTemplateData.PRINT_SIZE = "A4"
	if (size === "A5") {
		MAX_ROWS_PER_PAGE = 6;
		printTemplateData.PRINT_SIZE = "A5"
	}
	let listProducts = [];
	let zeroVatReasons = [];

	if (salesData.products && salesData.products.length > 0) {
		listProducts = [];
		zeroVatReasons = [];
		let currentHeight = 0;
		let pageProducts = [];
		printTemplateData.TOTAL_PAGES.push(1);
		salesData.products.forEach((product) => {
			let cloneProduct = {...product};
			if (salesData?.discountAsLine === true) {
				cloneProduct.discount = 0;
				cloneProduct.netValue = cloneProduct.unitPrice * cloneProduct.quantity;
				cloneProduct.vat = (cloneProduct.unitPrice * cloneProduct.quantity) * (cloneProduct.vatPercentage / 100);
				cloneProduct.totalValue = cloneProduct.netValue + cloneProduct.vat;
			}
			let qtyLabel = measurementUnitsData.find((el) => String(product?.mu) === String(el.code));
			if (qtyLabel && String(product.mu) !== "4") {
				qtyLabel = qtyLabel.shortName;
				if (product.quantity === 1) {
					if (qtyLabel === "Κιλά") {
						qtyLabel = "Κιλό";
					} else if (qtyLabel === "Μέτρα") {
						qtyLabel = "Μέτρο";
					}
				}
			} else {
				qtyLabel = "";
			}
			let productData = {
				CPV_CODE: product?.cpvCode ? product.cpvCode : "",
				PEPPOL_MEASUREMENT_UNIT: product?.peppolMeasurementUnit ? product.peppolMeasurementUnit : "",
				CODE: product.code,
				VAT_ID: product.vatId,
				TAXES_VALUE: parseFloat(
					parseFloat(product.otherTaxEuro) +
					parseFloat(product.feeEuro) +
					parseFloat(product.stampEuro)
				),
				PRODUCT_DESCRIPTION: product.name,
				QTY: String(product.quantity) + String(salesData?.enablePeppol === "active" ? "" : ` ${qtyLabel}`),
				PRICE_QTY: salesData?.enablePeppol === "active" ? Number(product.unitPrice).toFixed(2) : getGreekCurrencyFormat(product.unitPrice),
				VAT: product.vatPercentage,
				DISCOUNT: salesData?.enablePeppol === "active" ? Number(cloneProduct.discount).toFixed(2) : String(cloneProduct?.isPerDiscount) !== "true" ? getGreekCurrencyFormat(cloneProduct.discount) : getGreekCurrencyFormat(cloneProduct.discount).replace("€", "%"),
				PRICE_NOVAT: salesData?.enablePeppol === "active" ? Number(cloneProduct.netValue).toFixed(2) : getGreekCurrencyFormat(cloneProduct.netValue),
				VAT_PRICE: product.taxes
					? getGreekCurrencyFormat(cloneProduct.vat)
					: getGreekCurrencyFormat(0),
				OTHER_TAXES: salesData?.enablePeppol === "active" ? Number(parseFloat(
					parseFloat(product.otherTaxEuro) -
					parseFloat(product.witholdTaxEuro) +
					parseFloat(product.feeEuro) +
					parseFloat(product.stampEuro) -
					parseFloat(product?.deductionsEuro ? product?.deductionsEuro : 0)
				)).toFixed(2) : getGreekCurrencyFormat(
					parseFloat(
						parseFloat(product.otherTaxEuro) -
						parseFloat(product.witholdTaxEuro) +
						parseFloat(product.feeEuro) +
						parseFloat(product.stampEuro) -
						parseFloat(product?.deductionsEuro ? product?.deductionsEuro : 0)
					)
				),
				TOTAL: salesData?.enablePeppol === "active" ? Number(cloneProduct.totalValue).toFixed(2) : getGreekCurrencyFormat(cloneProduct.totalValue),
			}
			if (salesData?.enablePeppol === "active") productData.VAT_PRICE = parseFloat(product.vat).toFixed(2);
			printTemplateData.TOTAL_TAXES_AMOUNT += productData.TAXES_VALUE;
			if (product.vatId === 7) {
				zeroVatReasons.push(product.vatReason);
			}
			const productRowHeight = Math.ceil(
				product.name.length / 50 // Set the maximum number of characters per line
			) * ROW_HEIGHT;
			currentHeight += productRowHeight;
			if (currentHeight > MAX_ROWS_PER_PAGE * ROW_HEIGHT) {
				// Start a new page if the current page height exceeds the maximum
				printTemplateData.TOTAL_PAGES.push(1);
				listProducts.push(pageProducts);
				pageProducts = [];
				currentHeight = productRowHeight;
			}
			if (product?.showAlternativeCodeOnPrint === "true") productData.CODE = product.alternativeCode;
			if (product?.showAlternativeNameOnPrint === "true") productData.PRODUCT_DESCRIPTION = product.alternativeName;
			if (!isNaN(parseFloat(product.witholdTaxEuro))) {
				totalWithhold += parseFloat(product.witholdTaxEuro);
			}
			if (product?.deductionsEuro && !isEmpty(product?.deductionsEuro)) {
				totalDeductions += parseFloat(product.deductionsEuro);
			}
			pageProducts.push(productData);
		})
		listProducts.push(pageProducts);
	}
	// Fees, Other Taxes, Withhold Taxes, Stamps that are selected by user to be a line
	if (listProducts?.length > 0) {
		if (salesData.extraData[0]?.withHoldTaxAsLine === "true" && parseFloat(salesData.extraData[0]?.withHoldTaxEuro) > 0) {
			const pStr = "-" + parseFloat(salesData.extraData[0]?.withHoldTaxEuro).toFixed(2);
			let prepFakeProduct = {
				CPV_CODE: "",
				CODE: "",
				VAT_ID: "8",
				TAXES_VALUE: pStr,
				PRODUCT_DESCRIPTION: isEmpty(salesData.extraData[0]?.withHoldTaxAsLineText) ? "Παρακράτηση Φόρου" : salesData.extraData[0]?.withHoldTaxAsLineText,
				QTY: "",
				PRICE_QTY: "0.00",
				DISCOUNT: "0.00",
				VAT: "0",
				PRICE_NOVAT: "0.00",
				VAT_PRICE: "0.00",
				OTHER_TAXES: pStr,
				TOTAL: salesData?.enablePeppol === "active" ? pStr : getGreekCurrencyFormat(pStr),
			}
			listProducts[listProducts.length - 1].push(prepFakeProduct);
		}
		if (salesData.extraData[0]?.deductionsAsLine === "true" && parseFloat(salesData.extraData[0]?.deductionsEuro) > 0) {
			const pStr = "-" + parseFloat(salesData.extraData[0]?.deductionsEuro).toFixed(2);
			let prepFakeProduct = {
				CPV_CODE: "",
				CODE: "",
				VAT_ID: "8",
				TAXES_VALUE: pStr,
				PRODUCT_DESCRIPTION: isEmpty(salesData.extraData[0]?.deductionsAsLineText) ? "Γενικές κρατήσεις" : salesData.extraData[0]?.deductionsAsLineText,
				QTY: "1",
				PRICE_QTY: "0.00",
				DISCOUNT: "0.00",
				VAT: "0",
				PRICE_NOVAT: "0.00",
				VAT_PRICE: "0.00",
				OTHER_TAXES: pStr,
				TOTAL: salesData?.enablePeppol === "active" ? pStr : getGreekCurrencyFormat(pStr),
			}
			listProducts[listProducts.length - 1].push(prepFakeProduct);
		}
		if (salesData.extraData[0]?.feeAsLine === "true" && parseFloat(salesData.extraData[0]?.feeEuro) > 0) {
			const pStr = parseFloat(salesData.extraData[0]?.feeEuro).toFixed(2);
			let prepFakeProduct = {
				CPV_CODE: "",
				CODE: "",
				VAT_ID: "8",
				TAXES_VALUE: pStr,
				PRODUCT_DESCRIPTION: isEmpty(salesData.extraData[0]?.feeAsLineText) ? "Τέλη" : salesData.extraData[0]?.feeAsLineText,
				QTY: "1",
				PRICE_QTY: "0.00",
				DISCOUNT: "0.00",
				VAT: "0",
				PRICE_NOVAT: "0.00",
				VAT_PRICE: "0.00",
				OTHER_TAXES: pStr,
				TOTAL: salesData?.enablePeppol === "active" ? pStr : getGreekCurrencyFormat(pStr),
			}
			listProducts[listProducts.length - 1].push(prepFakeProduct);
		}
		if (salesData.extraData[0]?.stampAsLine === "true" && parseFloat(salesData.extraData[0]?.stampEuro) > 0) {
			const pStr = parseFloat(salesData.extraData[0]?.stampEuro).toFixed(2);
			let prepFakeProduct = {
				CPV_CODE: "",
				CODE: "",
				VAT_ID: "8",
				TAXES_VALUE: pStr,
				PRODUCT_DESCRIPTION: isEmpty(salesData.extraData[0]?.stampAsLineText) ? "Χαρτόσημο (Φόρος)" : salesData.extraData[0]?.stampAsLineText,
				QTY: "1",
				PRICE_QTY: "0.00",
				DISCOUNT: "0.00",
				VAT: "0",
				PRICE_NOVAT: "0.00",
				VAT_PRICE: "0.00",
				OTHER_TAXES: pStr,
				TOTAL: salesData?.enablePeppol === "active" ? pStr : getGreekCurrencyFormat(pStr),
			}
			listProducts[listProducts.length - 1].push(prepFakeProduct);
		}
		if (salesData.extraData[0]?.otherTaxAsLine === "true" && parseFloat(salesData.extraData[0]?.otherTaxEuro) > 0) {
			const pStr = parseFloat(salesData.extraData[0]?.otherTaxEuro).toFixed(2);
			let prepFakeProduct = {
				CPV_CODE: "",
				CODE: "",
				VAT_ID: "8",
				TAXES_VALUE: pStr,
				PRODUCT_DESCRIPTION: isEmpty(salesData.extraData[0]?.otherTaxAsLineText) ? "Λοιπός Φόρος" : salesData.extraData[0]?.otherTaxAsLineText,
				QTY: "1",
				PRICE_QTY: "0.00",
				DISCOUNT: "0.00",
				VAT: "0",
				PRICE_NOVAT: "0.00",
				VAT_PRICE: "0.00",
				OTHER_TAXES: pStr,
				TOTAL: salesData?.enablePeppol === "active" ? pStr : getGreekCurrencyFormat(pStr),
			}
			listProducts[listProducts.length - 1].push(prepFakeProduct);
		}
		if (salesData?.discountAsLine === true) { // Need to edit previous lines, re-calculate totals
			let totalNetDisc = 0;
			let totalVatDisc = 0;
			for (let prod of salesData.products) {
				if (prod.isPerDiscount) {
					totalNetDisc += ((prod.unitPrice * prod.quantity)) * (prod.discount / 100);
					totalVatDisc += ((prod.unitPrice * prod.quantity) * (prod.vatPercentage / 100)) * (prod.discount / 100);
				} else {
					totalNetDisc += prod.discount;
					totalVatDisc += prod.discount * (prod.vatPercentage / 100);
				}
			}
			totalNetDisc = -totalNetDisc;
			totalVatDisc = -totalVatDisc;
			let displayNetDisc = salesData?.enablePeppol === "active" ? Number(totalNetDisc).toFixed(2) : getGreekCurrencyFormat(totalNetDisc);
			let displayVatDisc = salesData?.enablePeppol === "active" ? Number(totalVatDisc).toFixed(2) : getGreekCurrencyFormat(totalVatDisc);

			let discAm = +Number(totalNetDisc + totalVatDisc).toFixed(2);
			let prepFakeProduct = {
				CPV_CODE: "",
				CODE: "",
				VAT_ID: "8",
				TAXES_VALUE: "-",
				PRODUCT_DESCRIPTION: salesData.discountAsLineText,
				QTY: "",
				PRICE_QTY: displayNetDisc,
				DISCOUNT: "-",
				VAT: "-",
				PRICE_NOVAT: displayNetDisc,
				VAT_PRICE: displayVatDisc,
				OTHER_TAXES: "-",
				TOTAL: salesData?.enablePeppol === "active" ? discAm : getGreekCurrencyFormat(discAm),
				DONT_SHOW_PERCENTAGE: true,
			}
			listProducts[listProducts.length - 1].push(prepFakeProduct);
		}
	}

	if (totalWithhold) {
		printTemplateData.TOTAL_WITHHOLD = salesData?.enablePeppol === "active" ? Number(totalWithhold) : getGreekCurrencyFormat(totalWithhold);
	}
	if (totalDeductions) {
		printTemplateData.TOTAL_DEDUCTIONS = salesData?.enablePeppol === "active" ? Number(totalDeductions) : getGreekCurrencyFormat(totalDeductions);
	}
	let taxesData = [];
	let vatDataList = vatCategoryData;
	let vatPerReasonList = vatZeroPerData; // Is used to get the reason in exceptional case
	// For PEPPOL BG-21
	if (salesData.extraData[0]) {
		if (salesData.extraData[0].otherTaxEuro) printTemplateData.TOTAL_TAXES_AMOUNT += parseFloat(salesData.extraData[0].otherTaxEuro);
		if (salesData.extraData[0].feeEuro) printTemplateData.TOTAL_TAXES_AMOUNT += parseFloat(salesData.extraData[0].feeEuro);
		if (salesData.extraData[0].stampEuro) printTemplateData.TOTAL_TAXES_AMOUNT += parseFloat(salesData.extraData[0].stampEuro);
	}
	if (printTemplateData.TOTAL_TAXES_AMOUNT > 0) printTemplateData.TOTAL_TAXES_AMOUNT = salesData?.enablePeppol === "active" ? Number(printTemplateData.TOTAL_TAXES_AMOUNT).toFixed(2) : getGreekCurrencyFormat(printTemplateData.TOTAL_TAXES_AMOUNT);
	// Updated calculations for the sales taxes
	let newSalesTaxes = getSalesTaxesTemplateData(salesData.products, SETTINGS);
	if (newSalesTaxes && newSalesTaxes?.length > 0) {
		newSalesTaxes.forEach((tax) => {
			let taxVatPer = tax.vatPercentage;
			let taxVatPerWithoutSymbol = parseFloat(taxVatPer.replaceAll('%', ''));
			let vatObj = getObjectFromArrayOfObjects(vatDataList, taxVatPerWithoutSymbol, 'percentage');
			let taxCode = '';
			if (vatObj.length > 0) {
				taxCode = vatObj['0'].code;
			}
			let taxData = {
				TAX_PERCENT: taxVatPer,
				TAX_CODE: taxCode,
				NET_VALUE: salesData?.enablePeppol === "active" ? Number(tax.netValue).toFixed(2) : getGreekCurrencyFormat(tax.netValue),
				TAX_VALUE: salesData?.enablePeppol === "active" ? Number(tax.vatEuro).toFixed(2) : getGreekCurrencyFormat(tax.vatEuro),
				OTHER_TAXES: salesData?.enablePeppol === "active" ? Number(tax.taxes).toFixed(2) : getGreekCurrencyFormat(tax.taxes),
				TOTAL_VALUE: salesData?.enablePeppol === "active" ? Number(tax.totalValue).toFixed(2) : getGreekCurrencyFormat(tax.totalValue),
			}
			for (let key of Object.keys(taxData)) {
				if (taxData[key] === "NaN") {
					taxData[key] = salesData?.enablePeppol === "active" ? "0.00" : getGreekCurrencyFormat(0)
				}
			}
			taxesData.push(taxData);
		});
	}
	let zeroVatReasonDes = [];
	if (zeroVatReasons.length > 0) {
		zeroVatReasons.map(reason => {
			let vatReasonObj = getObjectFromArrayOfObjects(vatPerReasonList, reason, 'code');
			if(vatReasonObj['0'] !== undefined) {
				let zeroReason = {
					vat: "0%",
					description: vatReasonObj['0'].description + ' (' + reason + ')',
					code: reason
				}
				zeroVatReasonDes.push(zeroReason);
			}
			return reason;
		})
	}
	zeroVatReasonDes = removeDuplicateObjects(zeroVatReasonDes, 'code');
	printTemplateData.VATEXPCAT = zeroVatReasonDes;
	printTemplateData.PRODUCT_DETAILS = listProducts;
	printTemplateData.VAT_TABLE = taxesData;
	if(salesData.myDataType && salesData?.extraData && salesData?.extraData.length > 0 && salesData?.myDataType !== "2.1" && salesData?.myDataType !== "2.2" && salesData?.myDataType !== "2.3" && salesData?.myDataType !== "2.4" && salesData?.myDataType !== "11.2" && salesData?.myDataType !== "7.1" && salesData?.myDataType !== "8.1" && salesData?.myDataType !== "8.2") {
		if (salesData?.extraData[0].deliveryLocation === "companyLocation") {
			printTemplateData.LOCATION_NAME = "ΕΔΡΑ ΜΑΣ";
		} else if (salesData?.extraData[0].deliveryLocation === "clientLocation") {
			printTemplateData.LOCATION_NAME = "ΕΔΡΑ ΠΕΛΑΤΗ";
			printTemplateData.LOCATION_ADDRESS = salesData?.address;
			printTemplateData.LOCATION_COUNTRY = salesData?.country;
			printTemplateData.LOCATION_CITY = salesData?.city;
			printTemplateData.LOCATION_ZIP = salesData?.pc;
		} else if (salesData?.extraData[0].deliveryLocation === "custom") {
			printTemplateData.LOCATION_NAME = "ΑΛΛΟ";
			printTemplateData.LOCATION_ADDRESS = salesData?.extraData[0].locationAddress;
			printTemplateData.LOCATION_COUNTRY = salesData?.extraData[0].locationCountry;
			printTemplateData.LOCATION_CITY = salesData?.extraData[0].locationCity;
			printTemplateData.LOCATION_ZIP = salesData?.extraData[0].locationZip;
		}
	}
	let uid = salesData?.uid;
	if((!uid || uid === "") && salesData?.myDataDestination === "provider") {
		if (salesData.myDataRequest) {
			let invoice = JSON.parse(salesData.myDataRequest).invoice[0];
			uid = generateMyDataUID(invoice.issuer.vatNumber, invoice.invoiceHeader.issueDate, invoice.issuer.branch, invoice.invoiceHeader.invoiceType, invoice.invoiceHeader.series, invoice.invoiceHeader.aa);
			printTemplateData.UID = uid;
			/*
			const sha1 = require('js-sha1');
			const iconv = require('iconv-lite');
			uid = String(sha1(iconv.encode(invoice.issuer.vatNumber.toString() + '-' + invoice.invoiceHeader.issueDate + '-' + invoice.issuer.branch.toString()
				+ '-' + invoice.invoiceHeader.invoiceType.toString() + '-' + invoice.invoiceHeader.series + '-' + invoice.invoiceHeader.aa.toString(), 'ISO-8859-7'))).toUpperCase();
			printTemplateData.UID = uid;
			 */
		}
	}
	// QR Code
	if(salesData?.myDataDestination === "provider") {
		const qrCodeData = 'https://primer.gr/mydatasearch/' + uid;
		// Convert the canvas image to a base64 string
		printTemplateData.QR_CODE = QR.drawImg(qrCodeData, {
			typeNumber: 4,
			errorCorrectLevel: 'M',
			size: 190
		});
	} else if (salesData?.myDataDestination !== "provider" && salesData?.myDataQRURL) {
		const qrCodeData = salesData?.myDataQRURL;
		// Convert the canvas image to a base64 string
		printTemplateData.QR_CODE = QR.drawImg(qrCodeData, {
			typeNumber: 4,
			errorCorrectLevel: 'M',
			size: 190
		});
		printTemplateData.MYDATAQRURL = "YES";
	}
	if (salesData?.transactionDetails) {
		printTemplateData.PAYMENT_METHOD = "";
		printTemplateData.TRANSACTION_DETAILS = salesData.transactionDetails;
	}
	printTemplateData.PAGE_TYPES = [];
	if (salesData?.linkedClientPayments && salesData?.linkedClientPayments?.length > 0 && !printTemplateData.TRANSACTION_DETAILS) {
		if (salesData.linkedClientPayments.length === 1) {
			const payment = salesData.linkedClientPayments[0];
			if (payment.transactionDetails) {
				if (Object.keys(payment.transactionDetails).length > 0) {
					printTemplateData.TRANSACTION_DETAILS = payment.transactionDetails;
					printTemplateData.PAYMENT_METHOD = "";
				}
			}
		} else {
			printTemplateData.PAYMENT_HISTORY = {};
			const filterPOSPayments = [];
			for (let posP of salesData?.linkedClientPayments) {
				if (posP?.transactionDetails) {
					if (Object.keys(posP.transactionDetails).length > 0) {
						filterPOSPayments.push(posP.transactionDetails);
					}
				}
			}
			if (filterPOSPayments?.length > 0) {
				let perPage = 4;
				if (printTemplateData.PRINT_SIZE === "A5") perPage = 2;
				const extraPages = Math.ceil(filterPOSPayments.length / perPage);
				for (let i = 0; i < extraPages; i++) {
					printTemplateData.TOTAL_PAGES.push(1);
					printTemplateData.PAGE_TYPES.push({
						index: printTemplateData.TOTAL_PAGES.length - 1,
						type: "PAYMENT_HISTORY",
					})
					let inPageTransactionDetails = [];
					for (let j = 0; j < perPage; j++) {
						if (filterPOSPayments[perPage*i + j]) {
							inPageTransactionDetails.push(filterPOSPayments[perPage*i + j]);
						}
					}
					printTemplateData.PAYMENT_HISTORY[printTemplateData.TOTAL_PAGES.length - 1] = inPageTransactionDetails;
					printTemplateData.PAYMENT_METHOD = "";
				}
			}
		}
	}
	printTemplateData.ENABLE_PEPPOL = false;
	if (salesData?.enablePeppol === "active") {
		printTemplateData.ENABLE_PEPPOL = true;
		printTemplateData.PEPPOL = {};
		// BT-1
		printTemplateData.PEPPOL.DOCUMENT_REFERENCE
			= `${company.companyDetails?.vatNumber}|${moment(salesData.date).format("DD/MM/YYYY")}|${salesData.installation}|${salesData.myDataType}|${salesData.preNumber === "noprefix" ? "0" : salesData.preNumber}|${salesData.number}`;
		// BT-11 (Α.Δ.Α.)
		if(salesData?.peppol?.projectReference) printTemplateData.PEPPOL.PROJECT_REFERENCE = salesData.peppol.projectReference;
		// BT-46 (Κωδικός Αναθέτουσας Αρχής)
		printTemplateData.PEPPOL.PARTY_IDENTIFICATION = salesData?.peppol?.partyIdentification;
		// BT-12 (ΑΔΑΜ)
		printTemplateData.PEPPOL.CONTRACT_DOCUMENT_REFERENCE = isEmpty(salesData?.peppol?.contractDocumentReference) ? "0" : salesData?.peppol?.contractDocumentReference;
		// BT-3
		printTemplateData.PEPPOL.BT_3_CODE = (salesData.myDataType === "5.1" || salesData.myDataType === "5.2") ? "381" : "380";
		// BT-5
		printTemplateData.PEPPOL.BT_5_CODE = "EUR";
		// BT-31
		printTemplateData.PEPPOL.BT_31_CODE = isEmpty(salesData?.extraData[0]?.locationVatNumber) ? `EL${company.companyDetails?.vatNumber}` : `${salesData?.extraData[0]?.locationVatNumber}`;
		// BT-40
		printTemplateData.PEPPOL.BT_40_CODE = "GR";
		// BT-55
		printTemplateData.PEPPOL.BT_55_CODE = salesData.country;
		// BT-80
		printTemplateData.PEPPOL.BT_80_CODE = isEmpty(salesData?.extraData[0]?.locationCountry) ? salesData.country : salesData?.extraData[0]?.locationCountry;
		// BT-24
		printTemplateData.PEPPOL.BT_24_CODE = "urn:cen.eu:en16931:2017#compliant#urn:fdc:peppol.eu:2017:poacc:billing:3.0";
		// BT-34
		printTemplateData.PEPPOL.BT_34_CODE = `9933:${company.companyDetails?.vatNumber}`
		// BT-49
		printTemplateData.PEPPOL.BT_49_CODE = `9933:997001671`
		// BT-25
		if ((salesData.myDataType === "1.6" || salesData.myDataType === "2.4" || salesData.myDataType === "5.1" || salesData.myDataType === "5.2") && salesData?.peppol?.previousInvoices) {
			printTemplateData.PEPPOL.BT_25_CODE = salesData.peppol.previousInvoices;
		}
		// BG-15 Group (in case of companyLocation)
		if (salesData?.extraData && salesData?.extraData.length > 0) {
			if (salesData?.extraData[0].deliveryLocation === "companyLocation") {
				printTemplateData.LOCATION_NAME = "ΕΔΡΑ ΠΕΛΑΤΗ";
				printTemplateData.LOCATION_ADDRESS = salesData?.address;
				printTemplateData.LOCATION_COUNTRY = salesData?.country;
				printTemplateData.LOCATION_CITY = salesData?.city;
				printTemplateData.LOCATION_ZIP = salesData?.pc;
				/*if (!isEmpty(branch)) {
					printTemplateData.LOCATION_ADDRESS = branch.address;
					printTemplateData.LOCATION_COUNTRY = "GR";
					printTemplateData.LOCATION_CITY = branch.city;
					printTemplateData.LOCATION_ZIP = branch.postalCode;
				} else {
					printTemplateData.LOCATION_ADDRESS = company.companyDetails?.headquartersAddress;
					printTemplateData.LOCATION_COUNTRY = "GR";
					printTemplateData.LOCATION_CITY = company.companyDetails?.city;
					printTemplateData.LOCATION_ZIP = company.companyDetails?.postalCode;
				}*/
			}
		}
	}
	return printTemplateData;
}

export const getClientPaymentPrintData = (COMPANY_DATA, cData) => {
	let branch = "";
	if(cData?.installation !== "0"){
		if(cData?.companyInstallations?.length > 0) {
			cData.companyInstallations.map(installation => {
				if (installation?.installationMasterId === cData?.installation) {
					branch = installation;
				}
				return installation;
			})
		}
	}
	let returnObj = {
		ISSUER_NAME: COMPANY_DATA.companyDetails?.name,
		ISSUER_LOGO: COMPANY_DATA.companyDetails?.useLogo === "active" ? COMPANY_DATA.companyDetails?.companyLogo : "",
		ISSUER_SMALL_NAME: COMPANY_DATA.companyDetails?.smallName,
		ISSUER_SUBJECTFIELD: COMPANY_DATA.companyDetails?.activity,
		ISSUER_ADDRESS: COMPANY_DATA.companyDetails?.headquartersAddress,
		ISSUER_CITY: COMPANY_DATA.companyDetails?.city,
		ISSUER_TK: COMPANY_DATA.companyDetails?.postalCode,
		ISSUER_PHONE: COMPANY_DATA.companyDetails?.phone,
		ISSUER_DOY: COMPANY_DATA.companyDetails?.doy,
		ISSUER_VAT: COMPANY_DATA.companyDetails?.vatNumber,
		ISSUER_EMAIL: COMPANY_DATA.companyDetails?.email,
		ISSUER_GEMH: COMPANY_DATA.companyDetails?.gemhNo,
		ISSUER_WEBPAGE: COMPANY_DATA.companyDetails?.webpage,
		BRANCH: branch,
		CUSTOMER_CODE: cData?.clientCode,
		CUSTOMER_NAME: cData?.myDataType === "11.1" || cData?.myDataType === "11.2" || cData?.myDataType === "11.4" ? cData?.clientName : "",
		CUSTOMER_COMPANY: cData?.clientName,
		CUSTOMER_ACTIVITY: cData?.activity,
		CUSTOMER_EMAIL: cData?.email,
		CUSTOMER_PHONE: cData?.phone,
		CUSTOMER_VAT: cData?.vat,
		CUSTOMER_DOY: cData?.doy,
		CUSTOMER_ADDRESS: cData?.address,
		CUSTOMER_CITY: cData?.city,
		CUSTOMER_ZIP: cData?.pc,
		PAYMENT_DATE: moment(new Date(cData.date)).format("DD-MM-YYYY"),
		PAYMENT_TYPE: cData?.paymentTypeName,
		PAYMENT_AMOUNT: cData?.amount + " €",
		INVOICE_TYPE: cData?.documentTypeName,
		NUMBER: cData?.number,
		SERIES: cData?.preNumber,
		INVOICE_DATE: moment(new Date(cData.date)).format("DD-MM-YYYY"),
		INVOICE_TIME: moment(new Date(cData.date)).format("HH:mm"),
		INVOICE_NOTES: cData?.paymentNotes,
		PRINT_SIZE: "A4",
		UID: cData?.uid,
		MARK: cData?.mark,
		AUTHCODE: cData?.authCode,
		TRANSMISSION_FAILURE: cData?.transmissionFailure,
		MYDATA_DESTINATION: cData?.myDataDestination,
	}
	if (cData?.transactionDetails) {
		returnObj.TRANSACTION_DETAILS = cData.transactionDetails;
	}
	if (cData?.myDataDestination === "provider" && cData?.myDataStatus === "sent") {
		const qrCodeData = 'https://primer.gr/mydatasearch/' + cData?.uid;
		// Convert the canvas image to a base64 string
		returnObj.QR_CODE = QR.drawImg(qrCodeData, {
			typeNumber: 4,
			errorCorrectLevel: 'M',
			size: 190
		});
	}
	return returnObj;
}


// Get Buys Print Data
export const getBuysPrintData = (type, buys, company, SETTINGS, printLayout)  => {
	let buysData = {};
	const buysPurposeOptions = getMyData({type: 'buyPurpose', settings: SETTINGS});
	if (type === 'buys') {
		buysData = buys.newBuys;
	}
	if (type === 'editBuys') {
		buysData = buys.editBuys;
	}
	let branch = "";
	if(buysData?.installation !== "0"){
		if(company?.companyInstallations?.length > 0) {
			company.companyInstallations.map(installation => {
				if (installation?.installationMasterId === buysData?.installation) {
					branch = installation;
				}
				return installation;
			})
		}
	}

	let templateDate = new Date(buysData.date)
	templateDate = templateDate.getDate()+'/'+(templateDate.getMonth()+1)+'/'+templateDate.getFullYear();
	let printTemplateData = {
		ISSUER_NAME: company.companyDetails?.name,
		ISSUER_LOGO: company.companyDetails?.useLogo === "active" ? company.companyDetails?.companyLogo : "",
		ISSUER_SMALL_NAME: company.companyDetails?.smallName,
		ISSUER_SUBJECTFIELD: company.companyDetails?.activity,
		ISSUER_ADDRESS: company.companyDetails?.headquartersAddress,
		ISSUER_CITY: company.companyDetails?.city,
		ISSUER_TK: company.companyDetails?.postalCode,
		ISSUER_PHONE: company.companyDetails?.phoneNumber,
		ISSUER_DOY: company.companyDetails?.doy,
		ISSUER_VAT: company.companyDetails?.vatNumber,
		ISSUER_EMAIL: company.companyDetails?.email,
		ISSUER_GEMH: company.companyDetails?.gemhNo,
		BRANCH: branch,
		INVOICE_TYPE: buysData?.documentTypeName,
		SERIES: buysData?.preNumber !== "noprefix" ? buysData?.preNumber : "",
		NUMBER: buysData?.number.toString().replace(buysData?.preNumber,''),
		INVOICE_DATE: templateDate,
		INVOICE_TIME: buysData?.time,
		SUPPLIER_CODE: buysData?.supplierCode,
		SUPPLIER_NAME: buysData.myDataType === "11.1" || buysData.myDataType === "11.2" || buysData.myDataType === "11.4" ? buysData?.clientName : "",
		SUPPLIER_COMPANY: buysData?.supplierName,
		SUPPLIER_ACTIVITY: buysData?.supplierActivity,
		SUPPLIER_EMAIL: buysData?.email,
		SUPPLIER_VAT: buysData?.vat,
		SUPPLIER_ADDRESS: buysData?.address,
		SUPPLIER_CITY: buysData?.city,
		SUPPLIER_ZIP: buysData?.pc,
		SUPPLIER_PHONE: buysData?.phone,
		SUPPLIER_DOY: buysData?.doy,
		BUYER_NAME: "",
		BUYER_COMPANY_NAME: "",
		BUYER_EMAIL: "",
		BUYER_GEMH: "",
		BUYER_VAT: "",
		TAX_REPRESENTATIVE: "",
		TAX_REPRESENTATIVE_VAT: "",
		LOCATION_NAME: "",
		//LOCATION_ADDRESS: "",
		//LOCATION_COUNTRY: "",
		//LOCATION_CITY: "",
		//LOCATION_ZIP: "",
		PAYMENT_METHOD: buysData?.paymentTypeName,
		PURPOSE: getDefaultOptionByValue(buysPurposeOptions, buysData?.buyPurpose, 'value', 'label', 'value')?.label,
		HIGHLIGHT: "",
		CORRELATION: buysData?.correlationName,
		DISPATCH_TIME: buysData?.goodsSendDate ? getISOStringDateToDate(buysData?.goodsSendDate) : '',
		VEHICLE_NUMBER: buysData?.carNumberName,
		VATEXPCAT: "",
		VATEXPCATCODE: "",
		INVOICE_NOTES: buysData.notes,
		TOTAL_QTY: buysData.totalQuantity,
		TOTAL_WITHOUT_DISCOUNT: getGreekCurrencyFormat(buysData.totalBeforeDiscount),
		TOTAL_DISCOUNT: getGreekCurrencyFormat(buysData.totalDiscount),
		TOTAL_NOVAT: getGreekCurrencyFormat(buysData.totalNetValue),
		TOTAL_VAT: getGreekCurrencyFormat(buysData.totalVatValue),
		PRICE_WITHVAT: getGreekCurrencyFormat(buysData.totalAmount),
		TOTAL_PRICE: getGreekCurrencyFormat(buysData.paymentAmount),
		MARK: buysData?.mark,
		UID: buysData?.uid,
		AUTHCODE: buysData?.authCode,
		TRANSMISSION_FAILURE: buysData?.transmissionFailure,
		MYDATAENVIRONMENT: buysData?.myDataEnvironment,
		PRODUCT_DETAILS: [],
		VAT_TABLE: [],
		TOTAL_PAGES: [],
		PRINT_SIZE: "A4"
	}
	let listProducts = [];
	let zeroVatReasons = [];
	if (buysData.products && buysData.products?.length > 0) {
		printTemplateData.TOTAL_PAGES.push(1);
		buysData.products.slice(0, 20).forEach((product, index) => {
			let productData = {
				CODE: product.code,
				PRODUCT_DESCRIPTION: product.name,
				QTY: product.quantity,
				PRICE_QTY: getGreekCurrencyFormat(product.unitPrice),
				VAT: product.vatPercentage,
				DISCOUNT: getGreekCurrencyFormat(product.discount),
				PRICE_NOVAT: getGreekCurrencyFormat(product.netValue),
				VAT_PRICE: product.taxes ? getGreekCurrencyFormat(product.vat) : getGreekCurrencyFormat(0),
				OTHER_TAXES: getGreekCurrencyFormat(parseFloat(parseFloat(product.otherTaxEuro) + parseFloat(product.witholdTaxEuro) + parseFloat(product.feeEuro) + parseFloat(product.stampEuro))),
				TOTAL: getGreekCurrencyFormat(product.totalValue),
			}
			if(product.vatId === 7){
				zeroVatReasons.push(product.vatReason)
			}
			listProducts.push(productData);
			if((index + 1) % 6 === 0){
				printTemplateData.TOTAL_PAGES.push(1);
			}
		});
	}
	let taxesData = [];
	let vatDataList = vatCategoryData;
	let vatPerReasonList = vatZeroPerData; // Is used to get the reason in exceptional case
	// Updated calculations for the buys taxes
	let newBuysTaxes = getBuysTaxesTemplateData(buysData.products, SETTINGS);
	if (newBuysTaxes && newBuysTaxes?.length > 0) {
		newBuysTaxes.forEach((tax) => {
			let taxVatPer = tax.vatPercentage;
			let taxVatPerWithoutSymbol = parseFloat(taxVatPer.replaceAll('%', ''));
			let vatObj = getObjectFromArrayOfObjects(vatDataList, taxVatPerWithoutSymbol, 'percentage');
			let taxCode = '';
			if (vatObj.length > 0) {
				taxCode = vatObj['0'].code;
			}
			let taxData = {
				TAX_PERCENT: taxVatPer,
				TAX_CODE: taxCode,
				NET_VALUE: getGreekCurrencyFormat(tax.netValue),
				TAX_VALUE: getGreekCurrencyFormat(tax.vatEuro),
				OTHER_TAXES: getGreekCurrencyFormat(tax.taxes),
				TOTAL_VALUE: getGreekCurrencyFormat(tax.totalValue),
			}
			taxesData.push(taxData);
		});
	}
	let zeroVatReasonDes = [];
	if (zeroVatReasons.length > 0) {
		zeroVatReasons.map(reason => {
			let vatReasonObj = getObjectFromArrayOfObjects(vatPerReasonList, reason, 'code');
			console.log('vatReasonObj',vatReasonObj);
			let zeroReason = {
				vat: "0%",
				description: vatReasonObj['0']?.description + ' (' + reason + ')',
				code: reason
			}
			zeroVatReasonDes.push(zeroReason);
			return reason;
		})
	}
	zeroVatReasonDes = removeDuplicateObjects(zeroVatReasonDes, 'code');
	printTemplateData.VATEXPCAT = zeroVatReasonDes;
	printTemplateData.PRODUCT_DETAILS = listProducts;
	printTemplateData.VAT_TABLE = taxesData;
	if(buysData.myDataType && buysData?.extraData && buysData?.extraData.length > 0 && buysData?.myDataType !== "2.1" && buysData?.myDataType !== "2.2" && buysData?.myDataType !== "2.3" && buysData?.myDataType !== "2.4" && buysData?.myDataType !== "11.2" && buysData?.myDataType !== "7.1" && buysData?.myDataType !== "8.1" && buysData?.myDataType !== "8.2") {
		if (buysData?.extraData[0].deliveryLocation === "companyLocation") {
			printTemplateData.LOCATION_NAME = "ΕΔΡΑ ΜΑΣ";
		} else if (buysData?.extraData[0].deliveryLocation === "clientLocation") {
			printTemplateData.LOCATION_NAME = "ΕΔΡΑ ΠΕΛΑΤΗ";
			printTemplateData.LOCATION_ADDRESS = buysData?.address;
			printTemplateData.LOCATION_COUNTRY = buysData?.country;
			printTemplateData.LOCATION_CITY = buysData?.city;
			printTemplateData.LOCATION_ZIP = buysData?.pc;
		} else if (buysData?.extraData[0].deliveryLocation === "custom") {
			printTemplateData.LOCATION_NAME = "ΑΛΛΟ";
			printTemplateData.LOCATION_ADDRESS = buysData?.extraData[0].locationAddress;
			printTemplateData.LOCATION_COUNTRY = buysData?.extraData[0].locationCountry;
			printTemplateData.LOCATION_CITY = buysData?.extraData[0].locationCity;
			printTemplateData.LOCATION_ZIP = buysData?.extraData[0].locationZip;
		}
	}
	const qrCodeData = 'https://primer.gr/mydatasearch/' + buysData?.uid;
	// Convert the canvas image to a base64 string
	printTemplateData.QR_CODE = QR.drawImg(qrCodeData, {
		typeNumber: 4,
		errorCorrectLevel: 'M',
		size: 190
	});
	return printTemplateData;
}

// List of Wildcards Used on Email
export const salesEmailWildcardsList = () => {
	return [
		"{CompanyName}",
		"{CompanySmallName}",
		"{CompanyVAT}",
		"{CompanyDOY}",
		"{CompanyActivity}",
		"{CompanyAddress}",
		"{CompanyCity}",
		"{CompanyPostalCode}",
		"{CompanyPhone}",
		"{CompanyEmail}",
		"{ContactPointNameRow2}",
		"{ContactPointMobilePhoneRow2}",
		"{ContactPointLandlineRow2}",
		"{ContactPointOtherPhoneRow2}",
		"{ContactPointEmailRow2}"
	];
}

// List of Wildcards Used on Email
export const buysEmailWildcardsList = () => {
	return [
		"{CompanyName}",
		"{CompanySmallName}",
		"{CompanyVAT}",
		"{CompanyDOY}",
		"{CompanyActivity}",
		"{CompanyAddress}",
		"{CompanyCity}",
		"{CompanyPostalCode}",
		"{CompanyPhone}",
		"{CompanyEmail}",
		"{ContactPointNameRow2}",
		"{ContactPointMobilePhoneRow2}",
		"{ContactPointLandlineRow2}",
		"{ContactPointOtherPhoneRow2}",
		"{ContactPointEmailRow2}"
	];
}

// List of Wildcards Used on Sales Payment Reason
export const salesPaymentReasonWildcardsList = () => {
	return [
		"{ClientName}",
		"{DateOfReceipt}",
		"{ClientVAT}",
		"{PaymentType}",
	];
}

export const accountingRecordReasonWildcardsList = () => {
	return [
		"{ClientName}",
		"{ClientVatNumber}",
		"{DateOfDocument}",
		"{TimeOfDocument}",
	];
}

export const emailSettingsWildcardsList = () => {
	return [
		"{ClientName}",
		"{DateOfDocument}",
		"{ClientVAT}",
		"{PaymentAmount}",
		"{TotalAmount}",
		"{CompanyName}",
		"{CompanySmallName}",
	];
}
// List of Wildcards Used on Buys Payment Reason
export const buysPaymentReasonWildcardsList = () => {
	return [
		"{ClientName}",
		"{DateOfReceipt}",
		"{ClientVAT}",
		"{PaymentType}",
	];
}

// Sales Validation Check
export const isValidSalesData = (data) => {

	let validCheck = true;

	if (data.data === '') {
		validCheck = false
	}

	if (
		(data.clientType === 'Existing' && data.client === '') ||
		(data.clientType === 'New' && data.newClient === '')
		) {
		validCheck = false
	}

	if (data.clientType === 'New' && (data.email === '' || data.email === '')) {
		validCheck = false
	}

	if (data.products && data.products?.length === 0) {
		validCheck = false
	}

	return validCheck;
}


// Buys Validation Check
export const isValidBuysData = (data) => {

	let validCheck = true;

	if (data.data === '') {
		validCheck = false
	}

	if (
		(data.supplierType === 'Existing' && data.supplier === '') ||
		(data.supplierType === 'New' && data.newSupplier === '')
		) {
		validCheck = false
	}

	if (data.supplierType === 'New' && (data.email === '' || data.email === '')) {
		validCheck = false
	}

	if (data.products && data.products?.length === 0) {
		validCheck = false
	}

	return validCheck;
}

// Not a number filter
export const filterNaNValue = (value, name = '') => {
  if (isNaN(value)) {
    return 0;
  }
  return value;
}

export const checkPermissionMasterParentStartingTree = (perm, permissionsData) => {
	const user = JSON.parse(localStorage.getItem("user"));
	if(user.userType === "Admin") {
		return true;
	} else {
		if (permissionsData !== undefined && permissionsData !== null && permissionsData.length > 0) {
			return permissionsData.some((el) => el.permissionName.split("-")[0] === perm);
		} else {
			return false;
		}
	}
}
export const checkPermissionStartingTree = (perm, permissionsData) => {
	const user = JSON.parse(localStorage.getItem("user"));
	if(user.userType === "Admin") {
		return true;
	} else {
		if (permissionsData !== undefined && permissionsData !== null && permissionsData.length > 0) {
			return permissionsData.some((el) => el.permissionName.split("/")[0] === perm);
		} else {
			return false;
		}
	}
}
export const checkPermission = (perm, permissionsData) => {
	const user = JSON.parse(localStorage.getItem("user"));
	if(user.userType === "Admin") {
		return true;
	} else {
		if (permissionsData !== undefined && permissionsData !== null && permissionsData.length > 0) {
			return permissionsData.some((el) => el.permissionName === perm);
		}
	}
}

export const isEmpty = (o) => {
	return o === undefined || o === null || String(o).trim() === "";
}

export const fixNetValueAndVat = (allAccounts) => {
	let accountsClone = [...allAccounts]
	if (accountsClone.length > 0) {
		accountsClone = accountsClone.map((a) => Object.assign({}, a));
		const matchingNetValues = ["24", "17", "13", "9", "6", "4", "3"];
		for (let vat of matchingNetValues) {
			let netValuesAccounts = accountsClone.filter((el) => el.isNetValue === vat);
			let vatValueAccount = accountsClone.find((el) => el.vat === vat);
			if (netValuesAccounts.length > 0 && vatValueAccount) {
				let netDebit = 0;
				let netCredit = 0;
				for (let netAcc of netValuesAccounts) {
					if(!isNaN(netAcc.debit)) netDebit += Number(netAcc.debit);
					if(!isNaN(netAcc.credit)) netCredit += Number(netAcc.credit);
				}
				netDebit = Number(Number(netDebit).toFixed(2));
				netCredit = Number(Number(netCredit).toFixed(2));

				let vatDebit = (Number(vat) / 100) * netDebit;
				let vatCredit = (Number(vat) / 100) * netCredit;
				vatDebit = Number(Number(vatDebit).toFixed(2));
				vatCredit = Number(Number(vatCredit).toFixed(2));
				vatValueAccount.debit = vatDebit;
				vatValueAccount.credit = vatCredit;
			}
		}
	}
	return accountsClone;
}

export const getCategoriesByInvoiceType = (invType, rowType) => {
	const findInvTypeObj = myDataCharOptions.find((el) => el.value === invType);
	if (findInvTypeObj) {
		if (rowType === "income") {
			return findInvTypeObj.category.filter((el) => String(el.value).startsWith("category1_") || String(el.value).startsWith("category3"));
		} else if (rowType === "expense") {
			return findInvTypeObj.category.filter((el) => String(el.value).startsWith("category2_"));
		}
	}
	return [];
}

export const getTypesByCategory = (invType, category) => {
	const findInvTypeObj = myDataCharOptions.find((el) => el.value === invType);
	if (findInvTypeObj) {
		const findCategoryInObj = findInvTypeObj.category.find((el) => el.value === category);
		if (findCategoryInObj) {
			return findCategoryInObj.type;
		}
	}
	return [];
}

export const getGreekMonthName = (monthNum) => {
	let num = Number(monthNum);
	const mapping = ["", "Ιανουάριος", "Φεβρουάριος", "Μάρτιος", "Απρίλιος", "Μάιος", "Ιούνιος", "Ιούλιος", "Αύγουστος", "Σεπτέμβριος", "Οκτώβριος", "Νοέμβριος", "Δεκέμβριος"];
	if (mapping[num]) return mapping[num];

	return "";
}

export const recalculateStockHolders = (arr = [], capital = 0) => {
	// Returns new stockholder array, based on new capital
	let cloneArr = structuredClone(arr);
	if (!isEmpty(capital)) {
		for (let holder of cloneArr) {
			if (!isNaN(holder.percentage) && holder.isActive === "true") {
				const percentage = parseFloat(holder.percentage);
				holder.stockValue = (percentage/100) * capital;
			}
		}
	}
	return cloneArr;
}
export const stockHolderPercentageDiff = (arr) => {
	let tp = 0;
	for (let s of arr) {
		if (s.isActive === "true") tp += parseFloat(s.percentage);
	}
	tp = +Number(tp).toFixed(2);

	return tp - 100;
}

export const isValidNumber = (str) => {
	if (typeof str != "string") return false
	return !isNaN(str) && !isNaN(parseFloat(str))
}

export const removeEmptyValues = (obj) => {
	for (let key in obj) {
		if (obj[key] === null || obj[key] === undefined) {
			delete obj[key];
		}
	}
	return obj;
}

export const normalizeText = (text) => text.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[;΄¨]/g, '');

export const validateAFM = (afm) => {
	if (String(afm).length !== 9) return false; // "Τα ψηφία του ΑΦΜ πρέπει να είναι 9 αριθμοί"
	if (!/^\d+$/.test(afm)) return false; // "Αυτό που εισάγετε δεν είναι αριθμός"

	const sum = afm.substring(0, 8).split('').reduce((s, v, i) => s + (parseInt(v) << 8 - i), 0);
	const calc = sum % 11;
	const d9 = parseInt(afm[8]);

	return calc % 10 === d9;
}

export const generateMyDataUID = (issuerVat, issueDate, issuerBranch, invoiceType, series, number) => {
	const sha1 = require('js-sha1');
	const iconv = require('iconv-lite');

	const toEncode = `${String(issuerVat)}-${String(issueDate)}-${String(issuerBranch)}-${String(invoiceType)}-${String(series)}-${String(number)}`;
	const encoded = iconv.encode(toEncode, 'ISO-8859-7');
	const sha1Encrypted = sha1(encoded);

	return String(sha1Encrypted).toUpperCase();
}

export const validateEmail = (email) => {
	if (!email || isEmpty(email)) return false;
	const tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

	const emailParts = email.split('@');
	if (emailParts.length !== 2) return false;

	const account = emailParts[0];
	const address = emailParts[1];
	if (account.length > 64 || address.length > 255) return false;

	const domainParts = address.split('.');
	if (domainParts.some((part) => part.length > 63)) return false;

	return tester.test(email);
}
