import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import getMyData from "../../../../_helpers/sharedmydata";
import {
    getDefaultOptionByValue,
    getEmployeeTypeOptions,
    getIsNetValueOptions, getMultiSelectOptionsToArray, getMultiSelectOptionsToArrayEdit,
    getOptionsByData,
    getSubaccountOptions, getTaxTypeOptions
} from "../../../../_helpers/commonFunctions";
import {setSaveEditAccountingAccount} from "../../../../_reducers/activitySlice";
import {findObjectFromArrayOfObjects, getLabelByValue} from "../../../../_helpers/helperFunctions";
import {setEditAccountingAccount} from "../../../../_reducers/DataPanel/Accounting/accountingSlice";
import {Col, Row} from "react-bootstrap";
import Checkbox from "../../../common/CheckBox";
import Dropdown from "../../../primer/Dropdown";
import Input from "../../../common/Input";
import LogTable from "../../../primer/LogTable";

const EditAccountingAccount = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const ACTIVITY = useSelector( (state) => state.ACTIVITY);
    const ACCOUNTING_DATA = useSelector( (state) => state.ACCOUNTING_DATA);
    const CLIENTS_DATA = useSelector((state) => state.CLIENTS_DATA);
    const SUPPLIERS_DATA = useSelector((state) => state.SUPPLIERS_DATA);
    const [showZeroVatReason, setShowZeroVatReason] = useState(false);
    let vatTaxes      = getMyData({type: 'vat', option: "doNotShowZero"});
    vatTaxes = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...vatTaxes]
    let zeroVatReason = getMyData({type: 'zerovatreason'});
    const isNetValueOptions = getIsNetValueOptions();
    const subAccountOptions = getSubaccountOptions();
    const taxTypeOptions = getTaxTypeOptions();
    const accountCategories = getOptionsByData(ACCOUNTING_DATA.accountingCategories, "_id", "category");
    let disabledFields = false;
    if (ACCOUNTING_DATA.editAccountingAccount.category === undefined || ACCOUNTING_DATA.editAccountingAccount.category === "") {
        disabledFields = true;
    }
    let clientCategories = getOptionsByData(CLIENTS_DATA.clientsSettings.categories, "_id", "category");
    clientCategories = [{ value: "", label: "Όλες"}, ...clientCategories];
    let supplierCategories = getOptionsByData(SUPPLIERS_DATA.suppliersSettings.categories, "_id", "category");
    supplierCategories = [{ value: "", label: "Όλες" }, ...supplierCategories];
    let employeeCategories = getEmployeeTypeOptions();
    employeeCategories = [{ value: "", label: "Όλες" }, ...employeeCategories];
    const SETTINGS = useSelector(state => state.SETTINGS);
    let pMDDSettings = SETTINGS.primerMyDataData;
    let otherTaxes    = getOptionsByData(pMDDSettings?.otherTaxes !== undefined && pMDDSettings?.otherTaxes !== '' ? (pMDDSettings.otherTaxes) : SETTINGS.otherHoldTaxes, 'code', 'name');
    let taxesWithhold = getOptionsByData(pMDDSettings?.withHoldTaxes !== undefined && pMDDSettings?.withHoldTaxes !== '' ? (pMDDSettings.withHoldTaxes) : SETTINGS.withHoldTaxes, 'code', 'name');
    let taxesStamp    = getOptionsByData(pMDDSettings?.stamp !== undefined && pMDDSettings?.stamp !== '' ? (pMDDSettings.stamp) : SETTINGS.stamp, 'code', 'name');
    let taxesFees     = getOptionsByData(pMDDSettings?.fees !== undefined && pMDDSettings?.fees !== '' ? (pMDDSettings.fees) : SETTINGS.fees, 'code', 'name');
    // Fix Blank In the first position
    otherTaxes = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...otherTaxes];
    taxesWithhold = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...taxesWithhold];
    taxesStamp = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...taxesStamp];
    taxesFees = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...taxesFees];
    const handleOnChange = (e, type = "default", ddname = '') => {
        // Set if editing the form
        if (!ACTIVITY.saveEditAccountingAccount) {
            dispatch(setSaveEditAccountingAccount(true));
        }
        let name;
        let value;
        if (type === 'dp') {
            value = e;
            name = ddname;
        } else if (type === 'ac') {
            value = e.target.value;
            name = ddname;
        } else if (type === 'dd') {
            name = ddname;
            value = e.value;
        } else  if (type === 'msdd')  {
            name = ddname;
            value = getMultiSelectOptionsToArray(e);
            console.log(e)
        }  else {
            value = e.target.value;
            name = e.target.name;
        }
        let accountData = Object.assign({}, ACCOUNTING_DATA);
        let newAccountData = {};
        if (name === "category") {
            newAccountData.categoryName = getLabelByValue(accountCategories, value, "value");
            const selectedCategory =  findObjectFromArrayOfObjects(ACCOUNTING_DATA.accountingCategories, value, "_id");
            if (selectedCategory) {
                newAccountData["vat"] = selectedCategory.vat;
                if(selectedCategory.vat === "0"){
                    setShowZeroVatReason(true);
                } else {
                    setShowZeroVatReason(false);
                }
                newAccountData["vatreason"] = selectedCategory.vatreason;
                newAccountData["mydata"] = selectedCategory.mydata;
                newAccountData["isNetValue"] = selectedCategory.isNetValue;
                newAccountData["subAccount"] = selectedCategory.subAccount;
            }
        }
        if(name === "vat") {
            if (value !== "") {
                newAccountData["isNetValue"] = "false";
                newAccountData["taxType"] = "";
            }
        }
        if(name === "isNetValue"){
            if(value !== "false"){
                newAccountData["vat"] = "";
                newAccountData["taxType"] = "";
            }
            if(value === "0"){
                setShowZeroVatReason(true);
            } else {
                setShowZeroVatReason(false);
            }
        }
        if(name === "taxType") {
            newAccountData["vat"] = "";
            newAccountData["isNetValue"] = "false";
        }

        if (name === "code") {
            const containsOnlyNumbers = /^[0-9.]+$/.test(value);
            if (containsOnlyNumbers) {
                if (value.length === 3 && value[2] !== ".") {
                    // first grade
                    value = value.slice(0, 2) + '.' + value.slice(2);
                } else if (value.length === 6 && value[5] !== ".") {
                    // second grade
                    value = value.slice(0, 5) + '.' + value.slice(5);
                }
            } else {
                value = value.slice(0, value.length - 1);
            }
        }
        newAccountData[name] = value;
        dispatch(setEditAccountingAccount({...accountData.editAccountingAccount, ...newAccountData}));
    }
    return (
        <div className="new-client-general product-form position-relative">
            {disabledFields && (
                <div className="overlay_w">{t('ClientsNewGeneral.message')}</div>
            )}
            <Row className="position-relative border-bottom mb-3">
                <Col xs="12" md="12" className="d-flex align-items-center justify-content-end ">
                    <div className="inline-field d-flex align-items-center acremd  mb-3">
                        <label htmlFor="account-active" className="mb-0 mr-2 ">
                            {t('AccountingAccountNew.isActive')}
                        </label>
                        <Checkbox
                            className="mb-2"
                            name="isActive"
                            key={Math.random()}
                            id="account-active"
                            defaultValue={ACCOUNTING_DATA.editAccountingAccount.isActive}
                            value={ACCOUNTING_DATA.editAccountingAccount.isActive === "active" ? "inactive" : "active"}
                            onChange={(e) => handleOnChange(e)}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="3" className="showWithOverlay">
                    <Dropdown
                        label={t('AccountingAccountNew.category')}
                        required={true}
                        options={accountCategories}
                        classes="w-100 mb-3"
                        name="category"
                        key={Math.random()}
                        defaultValue={getDefaultOptionByValue(accountCategories, ACCOUNTING_DATA.editAccountingAccount.category, "value", "label", "value")}
                        onChange={(e) => handleOnChange(e, "dd", "category")}
                    />
                </Col>
                <Col xs="12" md="12" className="d-flex align-items-center justify-content-end ">
                    <div className="inline-field d-flex align-items-center acremd">
                        <label htmlFor="account-active" className="mb-0 mr-2 ">
                            {t('AccountingAccountNew.movable')}
                        </label>
                        <Checkbox
                            className="mb-2"
                            name="movable"
                            key={Math.random()}
                            id="movable"
                            defaultValue={ACCOUNTING_DATA.editAccountingAccount.movable}
                            value={ACCOUNTING_DATA.editAccountingAccount.movable === "active" ? "inactive" : "active"}
                            onChange={(e) => handleOnChange(e)}
                        />
                    </div>
                    <div className="inline-field d-flex align-items-center acremd">
                        <label htmlFor="account-active" className="mb-0 mr-2 ">
                            {t('AccountingAccountNew.transferable')}
                        </label>
                        <Checkbox
                            className="mb-2"
                            name="transferable"
                            key={Math.random()}
                            id="transferable"
                            defaultValue={ACCOUNTING_DATA.editAccountingAccount.transferable}
                            value={ACCOUNTING_DATA.editAccountingAccount.transferable === "active" ? "inactive" : "active"}
                            onChange={(e) => handleOnChange(e)}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="5">
                    <div className="mb-3">
                        <Input
                            label={t('AccountingAccountNew.accountingCode.name')}
                            required={true}
                            className="w-100"
                            name="code"
                            type="text"
                            value={ACCOUNTING_DATA.editAccountingAccount.code}
                            placeholder={t('AccountingAccountNew.accountingCode.placeholder')}
                            onChange={(e) => handleOnChange(e)}
                        />
                        {ACTIVITY.saveEditAccountingAccount && ACCOUNTING_DATA.editAccountingAccount.code === "" && (
                            <div
                                className="text-danger">{t('AccountingAccountNew.accountingCode.required')}</div>)}
                    </div>
                </Col>
                <Col sm="12" md="4">
                    <div className="mb-3">
                        <Input
                            label={t('AccountingAccountNew.alternativeName.name')}
                            required={false}
                            className="w-100"
                            name="alternativeName"
                            type="text"
                            value={ACCOUNTING_DATA.editAccountingAccount.alternativeName}
                            placeholder={t('AccountingAccountNew.alternativeName.placeholder')}
                            onChange={(e) => handleOnChange(e)}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="5">
                    <div className="mb-3">
                        <Input
                            label={t('AccountingAccountNew.name.name')}
                            required={true}
                            className="w-100"
                            name="name"
                            type="text"
                            value={ACCOUNTING_DATA.editAccountingAccount.name}
                            placeholder={t('AccountingAccountNew.name.placeholder')}
                            onChange={(e) => handleOnChange(e)}
                        />
                        {ACTIVITY.saveEditAccountingAccount && ACCOUNTING_DATA.editAccountingAccount.name === "" && (<div className="text-danger">{t('AccountingAccountNew.name.required')}</div>)}
                    </div>
                </Col>
                {ACCOUNTING_DATA.editAccountingAccount.vat === "" && ACCOUNTING_DATA.editAccountingAccount.taxType === "" &&
                    <div className="col-lg-2 ml-3">
                        <Dropdown
                            defaultValue={getDefaultOptionByValue(isNetValueOptions, ACCOUNTING_DATA.editAccountingAccount.isNetValue, 'value', 'label', 'value')}
                            key={Math.random()}
                            label={t('AccountingAccountNew.isNetValue')}
                            onChange={(e) => handleOnChange(e, 'dd', 'isNetValue')}
                            options={isNetValueOptions}
                        />
                    </div>
                }
                {showZeroVatReason &&
                    <div className="col-lg-2">
                        <Dropdown
                            defaultValue={getDefaultOptionByValue(zeroVatReason, ACCOUNTING_DATA.editAccountingAccount.vatreason, 'value', 'label', 'value')}
                            key={Math.random()}
                            required={true}
                            label={t('AccountingAccountNew.vatReason')}
                            onChange={(e) => handleOnChange(e, 'dd', 'vatreason')}
                            options={zeroVatReason}/>
                        {ACTIVITY.saveEditAccountingAccount && ACCOUNTING_DATA.editAccountingAccount.vatreason === "" && (<div className="text-danger">{t('AccountingAccountNew.vatReasonRequired')}</div>)}
                    </div>
                }
                {ACCOUNTING_DATA.editAccountingAccount.isNetValue === "false" && ACCOUNTING_DATA.editAccountingAccount.taxType === "" &&
                    <div className="col-lg-2">
                        <Dropdown
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(vatTaxes, ACCOUNTING_DATA.editAccountingAccount.vat, 'value', 'label', 'value')}
                            onChange={(e) => handleOnChange(e, 'dd', 'vat')}
                            label={t('AccountingAccountNew.isVat')}
                            options={vatTaxes}/>
                    </div>
                }
                {ACCOUNTING_DATA.editAccountingAccount.isNetValue === "false" && ACCOUNTING_DATA.editAccountingAccount.vat === "" &&
                    <div className="col-lg-2">
                        <Dropdown
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(taxTypeOptions, ACCOUNTING_DATA.editAccountingAccount.taxType, 'value', 'label', 'value')}
                            onChange={(e) => handleOnChange(e, 'dd', 'taxType')}
                            label="Είναι Φόρος"
                            options={taxTypeOptions}/>
                    </div>
                }
                {ACCOUNTING_DATA.editAccountingAccount.taxType === "withHoldTax" &&
                    <div className="col-lg-2">
                        <Dropdown
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(taxesWithhold, ACCOUNTING_DATA.editAccountingAccount.withHoldTax, 'value', 'label', 'value')}
                            onChange={(e) => handleOnChange(e, 'dd', 'withHoldTax')}
                            required={true}
                            label="Παρακρατούμενος Φόρος"
                            options={taxesWithhold}
                        />
                        {ACTIVITY.saveEditAccountingAccount && ACCOUNTING_DATA.editAccountingAccount.withHoldTax === "" && (<div className="text-danger">{t('AccountingAccountNew.clientCategories.required')}</div>)}
                    </div>
                }
                {ACCOUNTING_DATA.editAccountingAccount.taxType === "otherTax" &&
                    <div className="col-lg-2">
                        <Dropdown
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(otherTaxes, ACCOUNTING_DATA.editAccountingAccount.otherTax, 'value', 'label', 'value')}
                            onChange={(e) => handleOnChange(e, 'dd', 'otherTax')}
                            required={true}
                            label="Λοιπός Φόρος"
                            options={otherTaxes}
                        />
                        {ACTIVITY.saveEditAccountingAccount && ACCOUNTING_DATA.editAccountingAccount.otherTax === "" && (<div className="text-danger">{t('AccountingAccountNew.clientCategories.required')}</div>)}
                    </div>
                }
                {ACCOUNTING_DATA.editAccountingAccount.taxType === "fee" &&
                    <div className="col-lg-2">
                        <Dropdown
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(taxesFees, ACCOUNTING_DATA.editAccountingAccount.fee, 'value', 'label', 'value')}
                            onChange={(e) => handleOnChange(e, 'dd', 'fee')}
                            required={true}
                            label="Τέλος"
                            options={taxesFees}
                        />
                        {ACTIVITY.saveEditAccountingAccount && ACCOUNTING_DATA.editAccountingAccount.fee === "" && (<div className="text-danger">{t('AccountingAccountNew.clientCategories.required')}</div>)}
                    </div>
                }
                {ACCOUNTING_DATA.editAccountingAccount.taxType === "stamp" &&
                    <div className="col-lg-2">
                        <Dropdown
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(taxesStamp, ACCOUNTING_DATA.editAccountingAccount.stamp, 'value', 'label', 'value')}
                            onChange={(e) => handleOnChange(e, 'dd', 'stamp')}
                            required={true}
                            label="Χαρτόσημο"
                            options={taxesStamp}
                        />
                        {ACTIVITY.saveEditAccountingAccount && ACCOUNTING_DATA.editAccountingAccount.stamp === "" && (<div className="text-danger">{t('AccountingAccountNew.clientCategories.required')}</div>)}
                    </div>
                }
            </Row>
            <Row>
                <div className="col-lg-4">
                    <Dropdown
                        defaultValue={getDefaultOptionByValue(subAccountOptions, ACCOUNTING_DATA.editAccountingAccount.subAccount, 'value', 'label', 'value')}
                        key={Math.random()}
                        label={"Υπολογαριασμός"}
                        onChange={(e) => handleOnChange(e, 'dd', 'subAccount')}
                        options={subAccountOptions}
                    />
                </div>
                {ACCOUNTING_DATA.editAccountingAccount.subAccount === "client" &&
                    <div className="col-lg-4">
                        <Dropdown
                            defaultValue={ACCOUNTING_DATA.editAccountingAccount.clientCategories && ACCOUNTING_DATA.editAccountingAccount.clientCategories.length > 0 ? getMultiSelectOptionsToArrayEdit(ACCOUNTING_DATA.editAccountingAccount.clientCategories, clientCategories) : []}
                            multiSelect={true}
                            enabledValue={true}
                            label={t('AccountingAccountNew.clientCategories.name')}
                            onChange={(e) => handleOnChange(e, 'msdd', 'clientCategories')}
                            options={clientCategories}
                        />
                        {ACTIVITY.saveEditAccountingAccount && ACCOUNTING_DATA.editAccountingAccount.clientCategories.length === 0 && (<div className="text-danger">{t('AccountingAccountNew.clientCategories.required')}</div>)}
                    </div>
                }
                {ACCOUNTING_DATA.editAccountingAccount.subAccount === "supplier" &&
                    <div className="col-lg-4">
                        <Dropdown
                            defaultValue={ACCOUNTING_DATA.editAccountingAccount.supplierCategories && ACCOUNTING_DATA.editAccountingAccount.supplierCategories.length > 0 ? getMultiSelectOptionsToArrayEdit(ACCOUNTING_DATA.editAccountingAccount.supplierCategories, supplierCategories) : []}
                            multiSelect={true}
                            enabledValue={true}
                            label={t('AccountingAccountNew.supplierCategories.name')}
                            onChange={(e) => handleOnChange(e, 'msdd', 'supplierCategories')}
                            options={supplierCategories}
                        />
                        {ACTIVITY.saveEditAccountingAccount && ACCOUNTING_DATA.editAccountingAccount.supplierCategories.length === 0 && (<div className="text-danger">{t('AccountingAccountNew.supplierCategories.required')}</div>)}
                    </div>
                }
                {ACCOUNTING_DATA.editAccountingAccount.subAccount === "employee" &&
                    <div className="col-lg-4">
                        <Dropdown
                            defaultValue={ACCOUNTING_DATA.editAccountingAccount.employeeCategories && ACCOUNTING_DATA.editAccountingAccount.employeeCategories.length > 0 ? getMultiSelectOptionsToArrayEdit(ACCOUNTING_DATA.editAccountingAccount.employeeCategories, employeeCategories) : []}
                            multiSelect={true}
                            enabledValue={true}
                            label={t('AccountingAccountNew.employeeCategories.name')}
                            onChange={(e) => handleOnChange(e, 'msdd', 'employeeCategories')}
                            options={employeeCategories}
                        />
                        {ACTIVITY.saveEditAccountingAccount && ACCOUNTING_DATA.editAccountingAccount.employeeCategories.length === 0 && (<div className="text-danger">{t('AccountingAccountNew.employeeCategories.required')}</div>)}
                    </div>
                }
            </Row>
        </div>
    )
}

export const AccountingAccountsEditLog = () => {
    const { t } = useTranslation('common');
    const ACCOUNTING_DATA = useSelector((state) => state.ACCOUNTING_DATA);

    return (
        <React.Fragment>
            <div className="text-muted mb-2"><i>{t('Logs.accountingAccountLog')}</i></div>
            <LogTable itemId={ACCOUNTING_DATA.editAccountingAccount._id}/>
        </React.Fragment>
    )
}

export default EditAccountingAccount;
