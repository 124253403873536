import React from "react";
import {useSelector} from "react-redux";
import TableBodyCell from "./TableBodyCell";
import {myDataCharOptions} from "../../../_data/mydata/myDataChar";
import {
  getECCategoryOptions,
  getECTypeOptions, getICCategoryOptions,
  getICTypeOptions,
} from "../../../_helpers/sharedmydata";
import {
  filterUnusedNumberingModes,
  filterUsedPreNumbers,
  getTypesByCategory,
  isEmpty,
} from "../../../_helpers/commonFunctions";
import {numberingModeOptions, preNumberGreek,} from "../../../_data/settings/advanced-types-search";

const TableBodyRow = (props) => {
  let {
    dataLength,
    tableName,
    columns,
    updateRowData,
    rowIndex,
    checkedRows,
    quickEditFields,
    pRequestData,
    reqName,
      emronPaymentsMonth
  } = props;
  const SETTINGS = useSelector((state) => state.SETTINGS);

  let data = Object.assign({}, props.data);
  let dataKey = "";
  if (data) {
    if (!data.id && data._id) {
      dataKey = data["_id"];
    }
    if (!data._id && data.id) {
      dataKey = data["id"];
    }
    if (data._id && data.id) {
      dataKey = data["_id"];
    }
    if(tableName === "Emron Stockholders" || tableName === "Emron Payments" || tableName === "Emron Passwords" || tableName === "New Product My Data"){
      dataKey = data["id"];
    }
    if(tableName === "Client New Installations" || tableName === "Supplier New Installations"){
      dataKey = data["no"];
    }
  }

  return (
    <>
      {data &&
        columns &&
        columns.map((cell, celli) => {
          let disabled = false;
          let enableDynamicOptions = false;
          let dynamicOptions = [];
          // For Advanced Types Document Numbering
          if (tableName === "Advanced Types Document Numbering") {
            if (
              (cell.field === "preNumber" || cell.field === "number") &&
              data.numberingMode === "manual"
            ) {
              disabled = true;
            }
            // Dynamic Options for | It will hide the used prenumbers but not the current
            if (cell.field === "preNumber") {
              let etDocumentNumbering =
                SETTINGS.settings.newAdvancedType.documentNumbering;
              let currentPreNumber = data[cell.field];
              let currentInstallation = data["installation"];
              dynamicOptions = filterUsedPreNumbers(
                preNumberGreek,
                etDocumentNumbering,
                currentPreNumber,
                currentInstallation
              );
              enableDynamicOptions = "true";
            }
            // Dynamic Options for | It will hide unused modes -- fixes
            if (cell.field === "numberingMode") {
              let etDocumentNumbering = SETTINGS.settings.newAdvancedType.documentNumbering;
              dynamicOptions = filterUnusedNumberingModes(
                numberingModeOptions,
                etDocumentNumbering,
                true
              );
              enableDynamicOptions = "true";
            }
          } else if (tableName === "Sales Search") {
            if(cell.field === "number"){
              data.number = data.preNumber + " " + data.number;
            }
          } else if (tableName === "Edit Advanced Types Document Numbering") {
            if (
              (cell.field === "preNumber" || cell.field === "number") &&
              data.numberingMode === "manual"
            ) {
              disabled = true;
            }
            // Dynamic Options for | It will hide the used prenumbers from the list on new row
            if (cell.field === "preNumber") {
              let etDocumentNumbering = SETTINGS.settings.editAdvancedType.documentNumbering;
              let currentPreNumber = data[cell.field];
              let currentInstallation = data["installation"];
              dynamicOptions = filterUsedPreNumbers(
                preNumberGreek,
                etDocumentNumbering,
                currentPreNumber,
                currentInstallation
              );
              enableDynamicOptions = "true";
            }

            // Dynamic Options for | It will hide unused modes -- fixes
            if (cell.field === "numberingMode") {
              let etDocumentNumbering = SETTINGS.settings.editAdvancedType.documentNumbering;
              dynamicOptions = filterUnusedNumberingModes(numberingModeOptions, etDocumentNumbering, false);
              enableDynamicOptions = "true";
            }
          } else if (tableName === "Search Product Movements") {
            if (cell.field === "products_list") {
              if (data.products && data.products.length > 0) {
                data.products_list = data.products.map(function (item) {
                  return item["name"];
                });
              }
            }
          } else if (tableName === "Product Category My Data" || tableName === "New Product My Data") {
            let rowType = data['rowType'];
            if (cell.field === 'invoiceType') {
              if (rowType === "income" || isEmpty(rowType)) {
                let clone = [...myDataCharOptions.filter((el) => el.category?.filter((el2) => String(el2.value).startsWith("category1_") || String(el2.value).startsWith("category3"))?.length > 0)];
                clone = clone.map((o) => Object.assign({}, o));
                for (let invType of clone) {
                  invType.label = invType.value + " " + invType.label;
                }
                dynamicOptions = clone;
              } else if (rowType === "expense") {
                let clone = [...myDataCharOptions.filter((el) => el.category?.filter((el2) => String(el2.value).startsWith("category2_"))?.length > 0)];
                clone = clone.map((o) => Object.assign({}, o));
                for (let invType of clone) {
                  invType.label = invType.value + " " + invType.label;
                }
                dynamicOptions = clone;
              }
              enableDynamicOptions = 'true';
            }
            // Data population logic for category
            if (cell.field === "category") {
              let mdType = String(data["invoiceType"]);
              if (mdType === "1.6" || mdType === "2.4" || mdType === "5.2") {
                if (rowType === "expense") {
                  dynamicOptions = getECCategoryOptions();
                } else {
                  dynamicOptions = getICCategoryOptions();
                }
              } else {
                let invoiceTypeData = myDataCharOptions.find((el) => el.value === mdType);
                if (invoiceTypeData) {
                  if (invoiceTypeData.category && invoiceTypeData.category.length > 0) {
                    dynamicOptions = invoiceTypeData.category.filter((el) => rowType === "income" || isEmpty(rowType) ?
                        (String(el.value).startsWith("category1_") || String(el.value).startsWith("category3"))
                        :
                        String(el.value).startsWith("category2_"));
                  }
                }
              }
              enableDynamicOptions = "true";
            }
            // Data population logic for type
            if (cell.field === "type") {
              let mdType = String(data["invoiceType"])
              let mdCategory = data["category"];
              if (mdType === "1.6" || mdType === "2.4" || mdType === "5.2") {
                if (rowType === "expense") {
                  dynamicOptions = getECTypeOptions();
                } else {
                  dynamicOptions = getICTypeOptions();
                }
              } else {
                let invoiceTypeData = myDataCharOptions.find((el) => el.value === mdType);
                if (invoiceTypeData) {
                  if (invoiceTypeData.category && invoiceTypeData.category.length > 0) {
                    let categoryData = invoiceTypeData.category.find((el) => el.value === mdCategory);
                    if (categoryData) {
                      if (categoryData.type && categoryData.type.length > 0) {
                        dynamicOptions = categoryData.type;
                      }
                    }
                  }
                }
              }
              enableDynamicOptions = "true";
            }
            if (cell.field === "vatExpenseType") {
              let rowType = data['rowType'];
              disabled = rowType !== "expense";
            }
          } else if (tableName === "MyData Report Classifications Buys") {
            if (cell.field === "classificationType") {
              dynamicOptions = getTypesByCategory(String(data["invoiceType"]), String(data["classificationCategory"]));
              enableDynamicOptions = 'true';
            }
          }


          return (
            <TableBodyCell
              reqName={reqName}
              reqData={pRequestData}
              quickEditFields={quickEditFields}
              enableDynamicOptions={enableDynamicOptions}
              dynamicOptions={dynamicOptions}
              isInsertRow={false}
              tableName={tableName}
              emronPaymentsMonth={emronPaymentsMonth}
              disabled={disabled}
              key={celli}
              columns={columns}
              rowData={data}
              dataKey={dataKey}
              cell={cell}
              data={data[cell.field]}
              updateRowData={updateRowData}
              dataLength={dataLength}
              rowIndex={rowIndex}
              checkedRows={checkedRows}
            />
          )
        })}
    </>
  )
}
export default TableBodyRow;
