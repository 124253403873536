// PATHS object defines all the routes that are present in overview of an application

// Products section paths
export const PRODUCT_PATHS = {
  NEW_PRODUCT: "/products/products-new",
  EDIT_PRODUCT: "/product/edit-product",
  PRODUCTS_SEARCH: "/products/products-search",
  NEW_PRODUCT_MOVEMENT: "/products/product-movement-new",
  PRODUCT_MOVEMENT_SEARCH: "/products/product-movement-search",
  NEW_PRICE_LIST: "/products/product-pricelist-new",
  PRICE_LIST_SEARCH: "/products/pricelist-search",
  BALANCE_SHEET: "/products/product-balancesheet-reports",
  PRODUCT_MOVEMENTS: "/products/product-movements-reports",
  PRODUCT_CATEGORIES: "/products/settings-product-categories",
  PRODUCTS_SETTINGS: "/products/settings-products-settings",
  COMPONENTS: "/products/_components/",
}

// Sales section paths
export const SALES_PATHS = {
  NEW_ORDERS: "/sales/orders/new",
  SEARCH_ORDERS: "/sales/orders/search",
  NEW_SALES: "/sales/new",
  SEARCH_SALES: "/sales/search",
  NEW_CLIENT_PAYMENT: "/sales/client-payments/new",
  SEARCH_CLIENT_PAYMENTS: "/sales/client-payments/search",
  PRIMER_CLIENT_PAYMENTS: "/sales/client-payments/primer",
  EMRON_CLIENT_PAYMENTS: "/sales/client-payments/emron",
  SEND_MY_DATA_DATA: "/sales/my-data/send-my-data",
  MY_DATA_REPORT: "/sales/my-data/data-report",
  SALES_BALANCE_SHEET: "/sales/reports/sales-balance-sheet",
  SALES_GRAPHIC_REPORT: "/sales/reports/sales-graphic-report",
  CLIENT_PAYMENT_REPORT: "/sales/reports/client-payment=report",
  CLIENT_PAYMENT_GRAPHIC_REPORT: "/sales/reports/client-payment-graphic-report",
  GENERAL_SETTINGS: "/sales/settings/general-settings",
}

// Buys section paths
export const BUYS_PATHS = {
  NEW_ORDERS: "/buys/orders/new",
  SEARCH_ORDERS: "/buys/orders/search",
  NEW_BUYS: "/buys/new",
  SEARCH_BUYS: "/buys/search",
  NEW_QUICK_RETAIL_BUYS: "/buys/quick-retail-buys/new",
  SEARCH_QUICK_RETAIL_BUYS: "/buys/quick-retail-buys/search",
  NEW_SUPPLIER_PAYMENT: "/buys/supplier-payments/new",
  SEARCH_SUPPLIER_PAYMENTS: "/buys/supplier-payments/search",
  NEW_AUTOMATIC_BUYS: "/buys/automatic-buys/new",
  SEARCH_AUTOMATIC_BUYS: "/buys/automatic-buys/search",
  SEND_MY_DATA_DATA: "/buys/my-data/send-my-data",
  MY_DATA_REPORT: "/buys/my-data/data-report",
  BUYS_BALANCE_SHEET: "/buys/reports/buys-balance-sheet",
  BUYS_GRAPHIC_REPORT: "/buys/reports/buys-graphic-report",
  SUPPLIER_PAYMENT_REPORT: "/buys/reports/supplier-payment=report",
  GENERAL_SETTINGS: "/buys/settings/general-settings",
  QUICK_RETAIL_BUYS: "/buys/settings/quick-retail-buys",
}

// Client section paths
export const CLIENT_PATHS = {
  NEW_CLIENT: "/clients/new-client",
  SEARCH_CLIENT: "/clients/search-client",
  GENERAL_SETTINGS: "/clients/general-settings",
  CLIENT_QUICK_RETAIL_SETTINGS: "/clients/quick-retail-settings",
  NEW_CLIENT_MOVEMENTS: "/clients/client-movements/new",
  SEARCH_CLIENT_MOVEMENTS: "/clients/client-movements/search",
  CALENDAR: "/clients/calendar",
  CLIENT_LIST: "/clients/reports/client-list",
  CLIENT_BALANCE: "/clients/reports/client-balance",
  CLIENT_REPORT: "/clients/reports/client-report",
  CLIENTS_MOVEMENTS: "/clients/reports/clients-movements",
  EMRON_PAYMENTS: "/clients/reports/emron-payments",
  CLIENTS_SETTINGS: "/clients/settings/clients-settings",
  CLIENT_SUBSCRIPTIONS: "/clients/settings/client-subscriptions",
  PRIMER_PAYMENTS: "/client/reports/primer-payments",
  EMRON_SETTLEMENTS_REPORT: "/client/reports/emron-settlements-report",
  CLIENT_ISOZYGIO_REPORT: "/client/reports/isozygio-client-report"
}

// Supplier section paths
export const SUPPLIER_PATHS = {
  NEW_SUPPLIER: "/suppliers/new-supplier",
  SEARCH_SUPPLIER: "/suppliers/search-supplier",
  NEW_SUPPLIER_MOVEMENTS: "/suppliers/supplier-movements/new",
  SEARCH_SUPPLIER_MOVEMENTS: "/suppliers/supplier-movements/search",
  SUPPLIER_BALANCE: "/suppliers/reports/supplier-balance",
  SUPPLIERS_MOVEMENTS: "/suppliers/reports/suppliers-movements",
  SUPPLIER_ISOZYGIO_REPORT: "/suppliers/reports/isozygio-supplier-report",
  SUPPLIERS_SETTINGS: "/suppliers/settings/suppliers-settings",
}

// Cash Registers Paths
export const CASH_REGISTERS_PATHS = {
  NEW_CASH_REGISTERS: "/cash/cash-registers-POS-BA/cash-registers/new",
  SEARCH_CASH_REGISTERS: "/cash/cash-registers-POS-BA/cash-registers/search",
  NEW_POS: "/cash/cash-registers-POS-BA/POS/new",
  SEARCH_POS: "/cash/cash-registers-POS-BA/POS/search",
  NEW_BANK_ACCOUNTS: "/cash/cash-registers-POS-BA/POS/bank-accounts/new",
  SEARCH_BANK_ACCOUNTS: "/cash/cash-registers-POS-BA/POS/bank-accounts/search",
  CASH_REPORTS: "/cash/cash-registers-POS-BA/POS/reports/cash",
  GENERAL_SETTINGS: "/cash/cash-registers-POS-BA/reports/general-settings",
  ACCOUNTING_SETTINGS: "/cash/settings/accounting",
}

// Accounting Paths
export const ACCOUNTING_PATHS = {
  NEW_ACCOUNTS: "/accounting/accounting-account/new",
  SEARCH_ACCOUNTS: "/accounting/accounting-account/search",
  NEW_RECORDS: "/accounting/accounting-records/new",
  SEARCH_RECORDS: "/accounting/accounting-records/search",
  BALANCE_SHEET: "/accounting/reports/balance-sheet",
  ACCOUNTING_SETTINGS: "/accounting/settings/accounting-settings",
  ACCOUNTING_MYDATA_DATA: "/accounting/settings/accounting-mydata-data",
  ACCOUNTING_RECORDS_TEMPLATES: "/accounting/settings/accounting-records-templates",
  ACCOUNTING_TRANSFER: "/accounting/settings/accounting-transfer",
  ISOZYGIO: "/accounting/reports/accounting-isozygio",
  REPORT_ACCOUNTS: "/accounting/reports/accounts",
  REPORT_CALENDAR: "/accounting/reports/calendar",
  YEAR_REPORT: "/accounting/reports/year",
  ACCOUNTING_BRIDGE_SETTINGS: "/accounting/settings/bridge",
  ACCOUNT_CLOSURE: "/accounting/account/closure",
  REPORT_OPERATING_STATEMENT: "/accounting/reports/operatingstatement",
}

// Payroll Paths
export const PAYROLL_PATHS = {
  NEW_EMPLOYEE: "/payroll/employee/new",
  SEARCH_EMPLOYEE: "/payroll/employee/search",
  SETTINGS: "/payroll/settings",
  NEW_WORK_MOVEMENT: "/payroll/workMovements/new",
  SEARCH_WORK_MOVEMENTS: "/payroll/workMovements/search",
  QR_SCANNER: "/payroll/qr-scanner",
  NEW_WORK_PERMIT: "/payroll/permits/new",
  SEARCH_WORK_PERMITS: "/payroll/permits/search",

}

// Settings Paths
export const SETTINGS_PATHS = {
  MY_DATA_PRIMER_MY_DATA: "/settings/myData/primer-myData",
  MY_DATA_DATA: "/settings/myData/data",
  DOCUMENT_TYPE_BASIC_TYPES_NEW: "/settings/document-type/basic-types/new",
  DOCUMENT_TYPE_BASIC_TYPES_SEARCH: "/settings/document-type/basic-types/search",
  DOCUMENT_TYPE_ADVANCE_TYPE_NEW: "/settings/document-type/advance-types/new",
  DOCUMENT_TYPE_ADVANCE_TYPE_SEARCH: "/settings/document-type/advance-types/search",
  GENERAL_SETTINGS_COMPANY_SETTINGS: "/settings/general-settings/company-settings",
  GENERAL_SETTINGS_SYSTEM_TASKS: "/settings/general-settings/system-tasks",
  EMAIL_SMTP: "/settings/general-settings/email-smtp",
  PRINT_TEMPLATE_NEW: "/settings/print-template/new",
  PRINT_TEMPLATE_SEARCH: "/settings/print-template/search",
  IMPORT_EXPORT_IMPORT: "/settings/import-export/import",
  IMPORT_EXPORT_EXPORT: "/settings/import-export/export",
  BACKUP_RESTORE_BACKUP: "/settings/backup-restore/backup",
  BACKUP_RESTORE_RESTORE: "/settings/backup-restore/restore",
  SETTINGS_SUBSCRIPTIONS: "/settings/subscriptions",
  EMRON_EXPENSES: "/settings/emronExpenses",
  MASTER_LOG_SEARCH: "/settings/masterLogSearch"
}
