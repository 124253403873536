import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    setEditReferral,
    setEditReferralLoading,
    setEditReferralReload
} from "../../../../_reducers/DataPanel/Prescription/prescriptionSlice";
import {Col, Row, Spinner} from "react-bootstrap";
import axios from "axios";
import {toast} from "react-toastify";
import Button from "react-bootstrap/Button";

const ReferralEdit = () => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);

    const editReferral = useSelector((state) => state.PRESCRIPTION.editReferral);
    const editReferralReload = useSelector((state) => state.PRESCRIPTION.editReferralReload);
    const editReferralLoading = useSelector((state) => state.PRESCRIPTION.editReferralLoading);

    const [loadingPrint, setLoadingPrint] = useState(false);

    useEffect(() => {
        if (editReferralReload) {
            dispatch(setEditReferralReload(false));
            fetchEditReferral();
        }
    }, [editReferralReload])

    const fetchEditReferral = () => {
        dispatch(setEditReferralLoading(true));
        axios.get(`${process.env.REACT_APP_API_URL2}/prescription/fetch-referral`, {
            headers: { 'Content-Type': 'application/json' },
            params: {
                barcode: editReferral.barcode,
                company: company.id
            }
        }).then((res) => {
            if (res.data.status === "200") {
                dispatch(setEditReferral(res.data.data));
            } else {
                toast.error(res.data.message);
            }
            dispatch(setEditReferralLoading(false));
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την άντληση παραπεμπτικού.");
            dispatch(setEditReferralLoading(false));
        })
    }

    const handlePrint = () => {
        setLoadingPrint(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/prescription/print-referral`, {
            company: company.id,
            barcode: editReferral.barcode
        }).then((res) => {
            if (res.data.status === "200") {
                const binaryString = atob(res.data.data);
                const byteArray = new Uint8Array(binaryString.split('').map((char) => char.charCodeAt(0)));
                const blob = new Blob([byteArray], { type: "application/pdf" });
                let blobUrl = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = `Παραπεμπτικό ${editReferral.barcode}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);
            } else {
                toast.error(res.data.message);
            }
            setLoadingPrint(false);
        }).catch((err) => {
            setLoadingPrint(false);
            console.log(err);
            toast.error("Σφάλμα κατά την άντληση.");
        })
    }

    return (
        <React.Fragment>
            {editReferralLoading ? (
                <React.Fragment>
                    <Row className={"mb-2"}>
                        <Col md={12} className={"d-flex justify-content-center"}>
                            Παρακαλώ περιμένετε... <Spinner size={"sm"} className={"ml-2"} animation={"border"} variant={"dark"} />
                        </Col>
                    </Row>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Row className={"mb-2"}>
                        <Col md={12}>
                            <div style={{textAlign: "center"}}>
                                <h5>Παραπεμπτικό με barcode : <strong>{editReferral.barcode}</strong></h5>
                            </div>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>

                    </Row>
                    <div className={"mb-3 borderedContainer"}>
                        <h5 style={{fontWeight: "600"}}>Λοιπά</h5>
                        <Row>
                            <Col md={3}>
                                <Button size={"sm"} onClick={() => handlePrint()} disabled={loadingPrint}>
                                    Λήψη PDF παραπεμπτικού
                                    {loadingPrint && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default ReferralEdit
