// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.opStatementTable {
    width: 100%;
    table-layout: fixed;
    text-align: right;
    word-break: break-word;
}

.opStatementTable thead tr {
    background-color: var(--light-skyblue);
}

.opStatementTable tr td, .opStatementTable tr th {
    border: 1px solid gray;
}

.opStatementTable tr:nth-child(even) {
    background-color: #f2f2f2;
}
`, "",{"version":3,"sources":["webpack://./src/_components/layouts/data-panel/Accounting/styles/op-statement.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".opStatementTable {\r\n    width: 100%;\r\n    table-layout: fixed;\r\n    text-align: right;\r\n    word-break: break-word;\r\n}\r\n\r\n.opStatementTable thead tr {\r\n    background-color: var(--light-skyblue);\r\n}\r\n\r\n.opStatementTable tr td, .opStatementTable tr th {\r\n    border: 1px solid gray;\r\n}\r\n\r\n.opStatementTable tr:nth-child(even) {\r\n    background-color: #f2f2f2;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
