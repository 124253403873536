import React, { useState, useEffect } from "react";
import {Row, Col, Button, Modal} from "react-bootstrap";
import Input from "../../../common/Input";
import Dropdown from "../../../primer/Dropdown";
import {
    fetchSupplierPaymentData,
    fetchAdvancedTypes,
    fetchBuysGeneralSettings,
    fetchSuppliers,
    fetchPOS,
    fetchBankAccounts,
    fetchInitialSuppliers,
} from "../../../../_apis/api";
import { useSelector, useDispatch } from "react-redux";
import {
    getCurrentUser,
    getDefaultOptionByValue,
    getOptionsByData,
    getSelectedInstallation, isEmpty,
    removeDuplicateObjects,
} from "../../../../_helpers/commonFunctions";
import "react-datepicker/dist/react-datepicker.css";
import {setSaveEditSupplierPayments} from "../../../../_reducers/activitySlice";
import {
    getLabelByValue,
    getObjectFromArrayOfObjects,
} from "../../../../_helpers/helperFunctions";
import {setEditSupplierPayment} from "../../../../_reducers/DataPanel/Suppliers/supplierSlice";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import TextArea from "antd/lib/input/TextArea";
import EditableTable from "../../../primer/Editable-Table";
import { toast } from "react-toastify";
import AccountingBox from "../../../primer/AccountingBox";
import DateBox from "../../../primer/DateBox";
import {useTranslation} from "react-i18next";
import {AccountingView} from "../../../primer/AccountingView/AccountingView";
import LogTable from "../../../primer/LogTable";
import {preNumberGreek} from "../../../../_data/settings/advanced-types-search";
import moment from "moment";

function EditSupplierPayments() {
    const [t] = useTranslation('common');
    const [showPaymentTypeConfirmation, setShowPaymentTypeConfirmation] = useState(false);
    const [paymentTypeTempData, setPaymentTypeTempData] = useState({});
    const [showErrors, setShowErrors] = useState(false);
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const SUPPLIERS_DATA = useSelector((state) => state.SUPPLIERS_DATA);
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const BUYS_SETTINGS = useSelector((state) => state.BUYS_SETTINGS);
    const CASH_DATA = useSelector((state) => state.CASH_DATA);
    let selectedClintData = []
    let suppliersListData = SUPPLIERS_DATA.suppliers.slice();

    const requestData = {
        company: company.id,
        year: company.year.toString(),
    };
    let requestDataF = {
        company: company.id,
        year: company.year.toString(),
        name: ''
    };

    const currentUser = getCurrentUser();

    const requestData2 = {
        company: company.id,
        year: company.year.toString(),
        isActive: "active",
        userId: currentUser.id,
    };

    // Options for Document Type Dropdown
    const allDocumentTypes = SETTINGS.settings.advancedTypes;
    const supplierPaymentsDT = getObjectFromArrayOfObjects(
        allDocumentTypes,
        "Supplier Payments",
        "section"
    );
    const documentTypesOptions = getOptionsByData(
        supplierPaymentsDT,
        "_id",
        "name"
    );
    let selectedDocumentTypeData = [];
    let dtInstallationsOptions = [];

    useEffect(() => {
        dispatch(fetchAdvancedTypes(requestData));
        dispatch(fetchSupplierPaymentData(requestData));
        dispatch(fetchBuysGeneralSettings(requestData2));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchPOS(requestData2));
        dispatch(fetchBankAccounts(requestData2));
        dispatch(fetchInitialSuppliers(requestData));
    }, []);
    const paymentTypeOptions = getOptionsByData(
        BUYS_SETTINGS.buysGeneralSettings.paymentTypes?.filter((el) => el.type !== "credit"),
        "_id",
        "name"
    );
    if (
        SUPPLIERS_DATA.editSupplierPayment.documentType === undefined ||
        SUPPLIERS_DATA.editSupplierPayment.documentType === ""
    ) {
    } else {
        let dtData = getObjectFromArrayOfObjects(
            supplierPaymentsDT,
            SUPPLIERS_DATA.editSupplierPayment.documentType,
            "_id"
        );
        if (dtData.length > 0) {
            selectedDocumentTypeData = dtData["0"];
            dtInstallationsOptions = getOptionsByData(selectedDocumentTypeData['documentNumbering'], "installationMasterId", "installationType");
            dtInstallationsOptions = removeDuplicateObjects(dtInstallationsOptions, 'value');
        }
    }
    const handlePaymentTypeConfirmation = () => {
        // Populate Selected Category Data
        let newSupplierPaymentData = {};
        let type = paymentTypeTempData.type;

        newSupplierPaymentData.paymentType = paymentTypeTempData.paymentType;
        newSupplierPaymentData.paymentTypeName = paymentTypeTempData.name;
        newSupplierPaymentData.paymentTypeType = type;

        if (type === "cash" || type === "iris") {
            newSupplierPaymentData.posDetails = SUPPLIERS_DATA.newSupplierPaymentInitialValue.posDetails;
            newSupplierPaymentData.bankDetails = SUPPLIERS_DATA.newSupplierPaymentInitialValue.bankDetails;
        }

        if (type === "pos") {
            newSupplierPaymentData.amount = "";
            newSupplierPaymentData.bankDetails = SUPPLIERS_DATA.newSupplierPaymentInitialValue.bankDetails;
        }

        if (type === "bank-account" || type === "web-banking" || type === "bank-account-outside") {
            newSupplierPaymentData.amount = "";
            newSupplierPaymentData.posDetails = SUPPLIERS_DATA.newSupplierPaymentInitialValue.posDetails;
        }

        dispatch(setEditSupplierPayment({...SUPPLIERS_DATA.editSupplierPayment, ...newSupplierPaymentData}));
        setShowPaymentTypeConfirmation(false);
    };

    const handleOnChange = (e, type = "default", ddname = "") => {
        setShowErrors(true);

        // Set if editing the form
        if (!ACTIVITY.saveEditSupplierPayments) {
            dispatch(setSaveEditSupplierPayments(true));
        }

        let name;
        let value;

        if (type === "dd") {
            value = e.value;
            name = ddname;
        } else if (type === 'ac' ) {
            value = e.target.value;
            name = ddname;
        } else if (type === "dp") {
            value = e;
            name = ddname;
        } else if (type === "acdd") {
            selectedClintData = []
            console.log('e.row',e.row);
            e = e.row;
            selectedClintData.push(e)
            value = e._id;
            name = ddname;
        } else if (type === "tp") {
            value = e;
            name = ddname;
        } else {
            value = e.target.value;
            name = e.target.name;
        }

        let newSupplierPaymentData = Object.assign({}, SUPPLIERS_DATA.editSupplierPayment);

        if (name !== "paymentType") {
            newSupplierPaymentData[name] = value;
        }

        if (name === "documentType") {
            newSupplierPaymentData[name] = value;

            newSupplierPaymentData.documentTypeName = getLabelByValue(
                documentTypesOptions,
                value,
                "value"
            );
            let selectedDocumentTypeData = getObjectFromArrayOfObjects(
                supplierPaymentsDT,
                value,
                "_id"
            );
            if (selectedDocumentTypeData.length > 0) {
                let selectedDTData = selectedDocumentTypeData["0"];
                let selectedInstallation = getSelectedInstallation(
                    selectedDTData["documentNumbering"]
                );
                // Preposed Installation by the fields
                if (selectedDTData.proposedInstallation !== "") {
                    newSupplierPaymentData.installation = selectedDTData.proposedInstallation;
                    // This applies first if the preposed installation is given
                    let defaultPreposedInstallation = getObjectFromArrayOfObjects(selectedDTData.documentNumbering, selectedDTData.proposedInstallation, "no");
                    if (defaultPreposedInstallation && defaultPreposedInstallation.length > 0) {
                        selectedInstallation = defaultPreposedInstallation["0"];
                    }
                }

                if (Object.keys(selectedInstallation).length > 0) {
                    newSupplierPaymentData.installation = selectedInstallation.installation;
                    newSupplierPaymentData.preNumber = selectedInstallation.preNumber;
                    newSupplierPaymentData.number = selectedInstallation.number;
                    newSupplierPaymentData.installationMode =
                        selectedInstallation.numberingMode;
                    newSupplierPaymentData.numberingMode =
                        selectedInstallation.numberingMode;
                }
                newSupplierPaymentData.paymentType = selectedDTData.proposedPaymentType;
                newSupplierPaymentData.paymentTypeName = selectedDTData.proposedPaymentTypeName;
                newSupplierPaymentData.paymentTypeType = selectedDTData.proposedPaymentTypeType;
                if(selectedDTData.proposedPosBankAccount !== undefined && selectedDTData.proposedPosBankAccount !== ""){
                    if(CASH_DATA.posList.length > 0 || CASH_DATA.bankAccounts.length > 0){
                        if(selectedDTData.proposedPaymentTypeType === "pos"){
                            const selectedPos = CASH_DATA.posList.filter(pos => pos._id === selectedDTData.proposedPosBankAccount)[0];
                            console.log(selectedPos)
                            let selectedPosDetails = {
                                _id: selectedPos._id,
                                id: "1",
                                no: "1",
                                name: selectedPos.name,
                                registrationNumber: selectedPos.serialNumber,
                                amount: 0,
                                notes: ""
                            }
                            newSupplierPaymentData.posDetails = [];
                            newSupplierPaymentData.posDetails.push(selectedPosDetails);
                        } else if(selectedDTData.proposedPaymentTypeType === "bank-account" || selectedDTData.proposedPaymentTypeType === "web-banking" || selectedDTData.proposedPaymentTypeType === "bank-account-outside"){
                            const selectedBank = CASH_DATA.bankAccounts.filter(bank => bank._id === selectedDTData.proposedPosBankAccount)[0];
                            console.log(selectedBank)
                            let selectedBankDetails = {
                                _id: selectedBank._id,
                                id: "1",
                                no: "1",
                                name: selectedBank.name,
                                accountNumber: selectedBank.iban,
                                amount: 0,
                                notes: ""
                            }
                            newSupplierPaymentData.bankDetails = [];
                            newSupplierPaymentData.bankDetails.push(selectedBankDetails);
                        }
                    }
                }
            }
        }

        if (name === "supplier") {
            // populating supplier values on the buys form
            let selectedSupplier = getObjectFromArrayOfObjects(
                suppliersListData,
                value,
                "_id"
            );
            if(selectedSupplier.length === 0) {
                selectedSupplier = [...selectedClintData]
            }
            if (selectedSupplier.length > 0) {
                let sSupplier = selectedSupplier["0"];
                newSupplierPaymentData.supplierName = sSupplier.name;
                newSupplierPaymentData.supplierCode = sSupplier.systemCode; // It is the supplier code
                newSupplierPaymentData.address = sSupplier.address;
                newSupplierPaymentData.vatNumber = sSupplier.vatNumber;
                newSupplierPaymentData.doy = sSupplier.DOY;
                newSupplierPaymentData.city = sSupplier.city;
                newSupplierPaymentData.pc = sSupplier.postalCode;
                newSupplierPaymentData.country = sSupplier.country;
                newSupplierPaymentData.phone = sSupplier.phone;
                newSupplierPaymentData.email = sSupplier.email.replaceAll("mailto:", "");
            }
        }

        if (name === "paymentType") {
            let paymentTypesData = getObjectFromArrayOfObjects(
                BUYS_SETTINGS.buysGeneralSettings.paymentTypes,
                value,
                "_id"
            );
            if (paymentTypesData && paymentTypesData.length > 0) {
                // Show warning before change
                if (
                    newSupplierPaymentData.paymentType !== "" &&
                    newSupplierPaymentData.paymentTypeType !== paymentTypesData["0"].type
                ) {
                    setShowPaymentTypeConfirmation(true);
                    let paymentTypeName = getLabelByValue(
                        paymentTypeOptions,
                        value,
                        "value"
                    );
                    setPaymentTypeTempData({
                        name: paymentTypeName,
                        paymentType: value,
                        type: paymentTypesData["0"].type,
                    });
                } else {
                    newSupplierPaymentData[name] = value;
                    newSupplierPaymentData.paymentTypeName = getLabelByValue(
                        paymentTypeOptions,
                        value,
                        "value"
                    );
                    newSupplierPaymentData.paymentTypeType = paymentTypesData["0"].type;
                }
            }
        }
        dispatch(setEditSupplierPayment({...newSupplierPaymentData}));
    }

    const supplierDropdownColumns = [
        {
            label: t("General.code"),
            name: "systemCode",
        },
        {
            label: t("General.name"),
            name: "name",
        },
        {
            label: t("General.phone"),
            name: "phone",
        },
        {
            label: t("General.email"),
            name: "email",
        },
    ];

    const posFieldColumns = [
        {
            label: t("General.name"),
            name: "name",
        },
        {
            label: t("General.serialNumber"),
            name: "serialNumber",
        },
    ];

    const bankFieldColumns = [
        {
            label: t("General.name"),
            name: "name",
        },
        {
            label: t("General.bankName"),
            name: "bankName",
        },
        {
            label: t("General.iban"),
            name: "iban",
        },
    ];

    const posColumns = [
        {
            name: t("General.name"),
            field: "name",
            editable: true,
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: posFieldColumns,
                    data: CASH_DATA.posList,
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: "Select POS",
                },
                type: "advanced-combobox",
            },
        },
        {
            name: t("General.serialNumber"),
            field: "registrationNumber",
            editable: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.amount"),
            field: "amount",
            editable: true,
            inputType: {
                type: "accounting",
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 10,
                },
            },
        },
        {
            name: t("General.notes"),
            field: "notes",
            editable: true,
            inputType: {
                type: "text",
            },
        },
    ];

    const bankAccountColumns = [
        {
            name: t("General.name"),
            field: "name",
            editable: true,
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: bankFieldColumns,
                    data: CASH_DATA.bankAccounts,
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: "Select Bank",
                },
                type: "advanced-combobox",
            },
        },
        {
            name: t("General.iban"),
            field: "accountNumber",
            editable: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.amount"),
            field: "amount",
            editable: true,
            inputType: {
                type: "accounting",
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 10,
                },
            },
        },
        {
            name: t("General.notes"),
            field: "notes",
            editable: true,
            inputType: {
                type: "text",
            },
        },
    ];

    const CheckColumns = [
        {
            name: t("General.startDate"),
            field: "startDate",
            editable: true,
            inputType: {
                type: "datebox",
            },
        },
        {
            name: t("General.endDate"),
            field: "endDate",
            editable: true,
            inputType: {
                type: "datebox",
            },
        },
        {
            name: t("General.amount"),
            field: "amount",
            editable: true,
            inputType: {
                type: "accounting",
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 10,
                },
            },
        },
        {
            name: t("General.notes"),
            field: "notes",
            editable: true,
            inputType: {
                type: "text",
            },
        },
    ];

    const posDetailsActions = {
        insert: () => {},
        update: (data, id) => {
            // Set if editing the form
            if (!ACTIVITY.saveEditSupplierPayments) {
                dispatch(setSaveEditSupplierPayments(true));
            }

            if (data.name && data.name === "") {
                toast.error("Name is mandatory field.");
            } else {
                let cpPosDetails = SUPPLIERS_DATA.editSupplierPayment.posDetails.slice();
                let foundPos = cpPosDetails.find((item) => item.id === id);
                if (foundPos !== undefined) {
                    let rowPosDetail = [];

                    // special case update name by name
                    if (data.name && data.name !== "") {
                        rowPosDetail = getObjectFromArrayOfObjects(
                            CASH_DATA.posList,
                            data.name,
                            "name"
                        );
                    }

                    // Field auto population on change product
                    if (rowPosDetail.length > 0) {
                        let rowPosDetailData = rowPosDetail["0"];
                        data._id = rowPosDetailData._id;
                        data.name = rowPosDetailData.name;
                        data.registrationNumber = rowPosDetailData.serialNumber;
                    }

                    // Updated selected product with updated field data.
                    foundPos = { ...foundPos, ...data };

                    const updateCpPos = { ...cpPosDetails[id - 1], ...foundPos };
                    cpPosDetails[id - 1] = updateCpPos;
                    console.log('updateCpPos',updateCpPos);

                    let innerData = {...SUPPLIERS_DATA.editSupplierPayment};
                    innerData.amount = updateCpPos.amount
                    dispatch(setEditSupplierPayment({...innerData, posDetails: cpPosDetails})
                    );
                }
            }
        },
        selected: () => {},
        delete: () => {},
    };

    const bankDetailsActions = {
        insert: () => {},
        update: (data, id) => {
            // Set if editing the form
            if (!ACTIVITY.saveEditSupplierPayments) {
                dispatch(setSaveEditSupplierPayments(true));
            }

            if (data.name && data.name === "") {
                toast.error("Name is mandatory field.");
            } else {
                let cpBankDetails = SUPPLIERS_DATA.editSupplierPayment.bankDetails.slice();
                let foundBank = cpBankDetails.find((item) => item.id === id);
                if (foundBank !== undefined) {
                    let rowBankDetail = [];

                    // special case update name by name
                    if (data.name && data.name !== "") {
                        rowBankDetail = getObjectFromArrayOfObjects(
                            CASH_DATA.bankAccounts,
                            data.name,
                            "name"
                        );
                    }

                    // Field auto population on change product
                    if (rowBankDetail.length > 0) {
                        let rowBankDetailData = rowBankDetail["0"];
                        data._id = rowBankDetailData._id;
                        data.name = rowBankDetailData.name;
                        data.accountNumber = rowBankDetailData.iban;
                    }

                    // Updated selected product with updated field data.
                    foundBank = { ...foundBank, ...data };

                    const updateCpBank = { ...cpBankDetails[id - 1], ...foundBank };
                    cpBankDetails[id - 1] = updateCpBank;
                    let innerData = {...SUPPLIERS_DATA.editSupplierPayment};
                    innerData.amount = updateCpBank.amount
                    dispatch(setEditSupplierPayment({...innerData, bankDetails: cpBankDetails,}));
                }
            }
        },
        selected: () => {},
        delete: () => {},
    };

    const chequeDetailsActions = {
        insert: () => {},
        update: (data, id) => {
            // Set if editing the form
            if (!ACTIVITY.saveEditSupplierPayments) {
                dispatch(setSaveEditSupplierPayments(true));
            }
            let chequeDetails = SUPPLIERS_DATA.editSupplierPayment.chequeDetails.slice();
            let foundCheck = chequeDetails.find((item) => item.id === id);
            if (foundCheck !== undefined) {
                foundCheck = { ...foundCheck, ...data };
                const updateCheque = { ...chequeDetails[id - 1], ...foundCheck };
                chequeDetails[id - 1] = updateCheque;
                let innerData = {...SUPPLIERS_DATA.editSupplierPayment};
                innerData.amount = updateCheque.amount;
                dispatch(setEditSupplierPayment({...innerData, chequeDetails: chequeDetails}));
            }
        },
        selected: () => {},
        delete: () => {},
    }
    const onChangeSearch = (keyword,setData) => {
        requestDataF.name = keyword

        if(keyword.length >= 2) {
            (dispatch(fetchSuppliers(requestDataF,setData)))
        }
    }
    const selectedDocumentType = getDefaultOptionByValue(documentTypesOptions, SUPPLIERS_DATA.editSupplierPayment.documentType, 'value', 'label', 'value');
    return (
        <div className="new-buys">
            <AccountingView document={SUPPLIERS_DATA.editSupplierPayment} />
            <Row className="border-bottom mb-3">
                <Col sm={12} md={3} className="showWithOverlay">
                    <Dropdown
                        label={t("General.documentType")}
                        key={Math.random()}
                        defaultValue={selectedDocumentType}
                        required={true}
                        name="documentType"
                        options={documentTypesOptions}
                        onChange={(e) => handleOnChange(e, "dd", "documentType")}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="3" className={"mb-3"}>
                    <DateBox
                        classes="w-100"
                        label={t("General.date")}
                        name="date"
                        selected={moment(SUPPLIERS_DATA.editSupplierPayment.date).isValid() ? new Date(SUPPLIERS_DATA.editSupplierPayment.date) : null}
                        onChange={(e) => handleOnChange(e, 'dp', 'date')}
                    />
                    {isEmpty(SUPPLIERS_DATA.editSupplierPayment.date) && <div>Απαιτείται Ημερομηνία.</div>}
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={3} className="mb-2">
                    <div className="mb-3">
                        <Dropdown
                            label={t("General.installation")}
                            options={dtInstallationsOptions}
                            value={getDefaultOptionByValue(dtInstallationsOptions, SUPPLIERS_DATA.editSupplierPayment.installation , 'value', 'label', 'value')}
                            enabledValue={true}
                            required={true}
                            name="installation"
                            classes="w-100"
                            onChange={(e) => handleOnChange(e, "dd", "installation")}
                        />
                    </div>
                </Col>
                <Col sm="12" md="3">
                    <div className="mb-3">
                        <Dropdown
                            className="mb-3 w-100"
                            label={t("General.preNumber")}
                            name="preNumber"
                            options={preNumberGreek}
                            defaultValue={preNumberGreek.find((el) => el.value === SUPPLIERS_DATA.editSupplierPayment.preNumber)}
                            key={Math.random()}
                            disabled={true}
                        />
                    </div>
                </Col>
                <Col sm="12" md="3">
                    <Input
                        className="mb-3 w-100"
                        label={t("General.number")}
                        name="number"
                        value={SUPPLIERS_DATA.editSupplierPayment.number}
                        placeholder="Number"
                        onChange={(e) => handleOnChange(e)}
                        disabled={ SUPPLIERS_DATA.editSupplierPayment.numberingMode !== "manual" }
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={3} className="mb-2">
                    <Dropdown
                        label={t("General.paymentType")}
                        options={paymentTypeOptions}
                        value={getDefaultOptionByValue(
                            paymentTypeOptions,
                            SUPPLIERS_DATA.editSupplierPayment.paymentType,
                            "value",
                            "label",
                            "value"
                        )}
                        enabledValue={true}
                        name="paymentType"
                        searchEnable={true}
                        onChange={(e) => handleOnChange(e, "dd", "paymentType")}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={3} className="mb-2">
                    <AdvancedCombobox
                        ACName="Supplier Payment Supplier" // do not change name
                        className="w-100 ac-select-dropdown"
                        searchableDropdownEnable={false}
                        defaultValue={SUPPLIERS_DATA.editSupplierPayment.supplierName}
                        key={Math.random()}
                        label={t("Placeholders.selectSupplier")}
                        data={suppliersListData}
                        onChange={(e) => {
                            handleOnChange(e, "acdd", "supplier");
                        }}
                        onChangeSearch = {onChangeSearch}
                        excludeColumns={[]}
                        columns={supplierDropdownColumns}
                        editEnable={false}
                        deleteEnable={false}
                        searchTableEnable={false}
                        showTableOptionOnSelect={true}
                        placeholder={t("Placeholders.selectSupplier")}
                        required={true}
                        showInsertAddButton={true}
                        hideSaveButton={true}
                        hideInsertNewRow={true}
                    />
                    {showErrors && SUPPLIERS_DATA.editSupplierPayment.supplier === "" && (
                        <div className="text-danger">Supplier is required.</div>
                    )}
                </Col>
                <Col sm="12" md="3">
                    <div className="mb-3">
                        <Input
                            className="w-100"
                            label={t("General.vatNumber")}
                            name="vat"
                            value={SUPPLIERS_DATA.editSupplierPayment.vatNumber}
                            onChange={(e) => handleOnChange(e)}
                            disabled={true}
                            readonly
                        />
                    </div>
                </Col>
                <Col sm="12" md="3">
                    <div className="d-flex">
                        <Input
                            label="Address"
                            className="w-100"
                            name={t("General.address")}
                            value={SUPPLIERS_DATA.editSupplierPayment.address}
                            onChange={(e) => handleOnChange(e)}
                            disabled={true}
                            readonly
                        />
                    </div>
                </Col>
                <Col sm="12" md="3">
                    <div className="d-flex">
                        <Input
                            label={t("General.country")}
                            className="w-100"
                            name="country"
                            value={SUPPLIERS_DATA.editSupplierPayment.country}
                            onChange={(e) => handleOnChange(e)}
                            disabled={true}
                            readonly
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="3">
                    <div className="d-flex">
                        <Input
                            label={t("General.phone")}
                            className="w-100"
                            name="phone"
                            value={SUPPLIERS_DATA.editSupplierPayment.phone}
                            onChange={(e) => handleOnChange(e)}
                            disabled={true}
                            readonly
                        />
                    </div>
                </Col>
                <Col sm="12" md="3">
                    <div className="d-flex">
                        <Input
                            label={t("General.email")}
                            className="w-100"
                            name="email"
                            value={SUPPLIERS_DATA.editSupplierPayment.email}
                            onChange={(e) => handleOnChange(e)}
                            disabled={true}
                            readonly
                        />
                    </div>
                </Col>
            </Row>
            <br />
            <Row>
                <Col md={12}>
                    <Input
                        label={t("General.reason")}
                        className="w-100 mb-3"
                        name="reason"
                        value={SUPPLIERS_DATA.editSupplierPayment.reason}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
            </Row>

            {(SUPPLIERS_DATA.editSupplierPayment.paymentTypeType === "cash" || SUPPLIERS_DATA.editSupplierPayment.paymentTypeType === "iris") && (
                <Row>
                    <Col sm="12" md="4">
                        <div className="mb-3">
                            <div className={"mb-1"}>{t("General.paymentAmount")}</div>
                            <AccountingBox
                                name="amount"
                                label={t("General.paymentAmount")}
                                placeholder={t("General.paymentAmount")}
                                defaultValue={ SUPPLIERS_DATA.editSupplierPayment.amount }
                                onChange={(e) => handleOnChange(e, "ac", "amount")}
                            />
                        </div>
                    </Col>
                </Row>
            )}

            {(SUPPLIERS_DATA.editSupplierPayment.paymentTypeType === "bank-account" || SUPPLIERS_DATA.editSupplierPayment.paymentTypeType === "web-banking" || SUPPLIERS_DATA.editSupplierPayment.paymentTypeType === "bank-account-outside") && (
                <div className="bank-data">
                    <div className="text-muted mb-2">
                        <i>{t("General.bankAccountData")}</i>
                    </div>
                    <EditableTable
                        reqName="bank"
                        key={Math.random()}
                        tableName="Supplier Payment Bank Account Data Table" // do not change name
                        allowToggleColumns={true}
                        allowActions={true}
                        allowInsertRow={false}
                        allowPagination={false}
                        allowBulkDelete={false}
                        allowSorting={false}
                        allowSelectionCheckbox={false}
                        columns={bankAccountColumns}
                        allowPrintResults={false}
                        enableNewRowCheckbox={true}
                        onUpdate={bankDetailsActions}
                        data={SUPPLIERS_DATA.editSupplierPayment.bankDetails}
                    />
                </div>
            )}

            {SUPPLIERS_DATA.editSupplierPayment.paymentTypeType === "pos" && (
                <div className="pos-data">
                    <div className="text-muted mb-2">
                        <i>{t("General.posData")}</i>
                    </div>
                    <EditableTable
                        reqName="pos"
                        key={Math.random()}
                        tableName="Supplier Payment POS Data Table" // do not change name
                        allowToggleColumns={true}
                        allowActions={true}
                        allowInsertRow={false}
                        allowPagination={false}
                        allowBulkDelete={false}
                        allowSorting={false}
                        allowSelectionCheckbox={false}
                        columns={posColumns}
                        enableNewRowCheckbox={true}
                        allowPrintResults={false}
                        onUpdate={posDetailsActions}
                        data={SUPPLIERS_DATA.editSupplierPayment.posDetails}
                    />
                </div>
            )}

            {SUPPLIERS_DATA.editSupplierPayment.paymentTypeType === "cheque" && (
                <div className="cheque-data">
                    <div className="text-muted mb-2">
                        <i>{t("General.chequeData")}</i>
                    </div>
                    <EditableTable
                        key={Math.random()}
                        tableName="Supplier Payment Cheque Data Table" // do not change name
                        allowToggleColumns={true}
                        allowActions={true}
                        allowInsertRow={false}
                        allowPagination={false}
                        allowBulkDelete={false}
                        allowSorting={false}
                        allowSelectionCheckbox={false}
                        columns={CheckColumns}
                        enableNewRowCheckbox={true}
                        allowPrintResults={false}
                        onUpdate={chequeDetailsActions}
                        data={SUPPLIERS_DATA.editSupplierPayment.chequeDetails}
                    />
                </div>
            )}

            <Row>
                <Col sm="12">
                    <div className="mb-3 w-100">
                        <div className="text-muted mb-2">
                            <i>{t("General.notes")}</i>
                        </div>
                        <TextArea
                            rows="4"
                            placeholder={t("Placeholders.notes")}
                            limit="1000"
                            name="paymentNotes"
                            value={SUPPLIERS_DATA.editSupplierPayment.paymentNotes}
                            onChange={(e) => handleOnChange(e)}
                        />
                    </div>
                </Col>
            </Row>

            <Modal className="themeModal" show={showPaymentTypeConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("General.warning")}!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t("General.warningDataLoss")}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-primary"
                        onClick={() => setShowPaymentTypeConfirmation(false)}
                    >
                        {t("General.noOption")}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => handlePaymentTypeConfirmation()}
                    >
                        {t("General.yesOption")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export const SupplierPaymentsEditLog = () => {
    const { t } = useTranslation('common');
    const SUPPLIERS_DATA = useSelector((state) => state.SUPPLIERS_DATA);

    return (
        <React.Fragment>
            <div className="text-muted mb-2"><i>{t('Logs.supplierPaymentLog')}</i></div>
            <LogTable itemId={SUPPLIERS_DATA.editSupplierPayment._id}/>
        </React.Fragment>
    )
}

export default EditSupplierPayments;
