import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setEditSupplier} from "../../../../../_reducers/DataPanel/Suppliers/supplierSlice";
import {Col, Row} from "react-bootstrap";
import Input from "../../../../common/Input";
import {setSaveEditSupplier} from "../../../../../_reducers/activitySlice";

const EditSupplierAccounting = () => {
    const dispatch = useDispatch();
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const SUPPLIERS_DATA = useSelector((state) => state.SUPPLIERS_DATA);

    const handleOnChange = (e, type, compName) => {
        if (!ACTIVITY.saveEditSupplier) dispatch(setSaveEditSupplier(true));
        if(type === "input") {
            if(compName === "accounting") {
                dispatch(setEditSupplier({...SUPPLIERS_DATA.editSupplier, accounting: e.target.value}));
            } else if (compName === "bypassAccounting") {
                dispatch(setEditSupplier({...SUPPLIERS_DATA.editSupplier, bypassAccounting: e.target.value}));
            }
        }
    }
    return (
        <React.Fragment>
            <Row>
                <Col md={4}>
                    <Input
                        name="accounting"
                        className="mb-3"
                        charLimit={20}
                        placeholder="Εισάγετε Πρόθεμα Κωδικού Λογαριασμού"
                        label="Πρόθεμα Κωδικού Λογαριασμού"
                        value={SUPPLIERS_DATA.editSupplier.accounting}
                        onChange={(e) => handleOnChange(e, "input", "accounting")}
                    />
                </Col>
                <Col md={4}>
                    <Input
                        name="bypassAccounting"
                        className="mb-3"
                        charLimit={20}
                        placeholder="Εισάγετε Κωδικό Λογαριασμού Παράκαμψης"
                        label="Κωδικός Λογαριασμού Παράκαμψης"
                        value={SUPPLIERS_DATA.editSupplier.bypassAccounting}
                        onChange={(e) => handleOnChange(e, "input", "bypassAccounting")}
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
}
export default EditSupplierAccounting;