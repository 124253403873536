import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {Badge, Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import Dropdown from "../../../primer/Dropdown";
import {bridgedSections, isEmpty} from "../../../../_helpers/commonFunctions";
import {
    setAccountingBridgeFilters,
    setAccountingBridgeReportData, setEditAccountingRecord
} from "../../../../_reducers/DataPanel/Accounting/accountingSlice";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import moment from "moment";
import DateBox from "../../../primer/DateBox";
import {setEditProductMovements} from "../../../../_reducers/DataPanel/Products/productsSlice";
import {setEditSales} from "../../../../_reducers/DataPanel/Sales/salesSlice";
import {setEditClientMovements, setEditClientPayment} from "../../../../_reducers/DataPanel/Clients/clientSlice";
import {setEditBuys} from "../../../../_reducers/DataPanel/Buys/buysSlice";
import {
    setEditSupplierMovements,
    setEditSupplierPayment
} from "../../../../_reducers/DataPanel/Suppliers/supplierSlice";
import {addTab, updateActiveMainTab} from "../../../../_reducers/TabsSlice";
import BetterCheckBox from "../../../common/BetterCheckBox";
import {StorageController} from "../../../../_helpers/storage-controller";
import {classicStyleBelowNavbar} from "../Statics";

export const AccountingBridgeSettings = () => {
    const dispatch = useDispatch();
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const filters = useSelector((state) => state.ACCOUNTING_DATA.accountingBridgeFilters);
    const reportData = useSelector((state) => state.ACCOUNTING_DATA.accountingBridgeReportData);
    const [loading, setLoading] = useState(false);
    const [autoBridge, setAutoBridge] = useState(false);

    const [showBridgeFiltered, setShowBridgeFiltered] = useState(false);
    const [bridgedFilterCount, setBridgedFilterCount] = useState(0);

    const [showUnbridgeFiltered, setShowUnbridgeFiltered] = useState(false);
    const [unbridgeFilterCount, setUnbridgeFilterCount] = useState(0);

    const pageRef = useRef();
    const [autoScrollRunning, setAutoscrollRunning] = useState(false);

    const [toggleBridgeModal, setToggleBridgeModal] = useState(false);
    const [confirmationStatus, setConfirmationStatus] = useState(false);

    const [toUnbridgeID, setToUnbridgeID] = useState("");
    const [showUnbridgeUnique, setShowUnbridgeUnique] = useState(false);

    useEffect(() => {
        fetchAutoBridgeStatus();
    }, [])

    useEffect(() => {
        pageRef.current.scrollTo(0, StorageController.getKey("accountingBridgeScroll"));
        if (!autoScrollRunning) scrollHolder();
        setAutoscrollRunning(true);
    }, [])

    const scrollHolder = () => {
        if (pageRef && pageRef?.current && !isEmpty(pageRef?.current?.scrollTop) && pageRef?.current?.scrollTop !== StorageController.getKey("accountingBridgeScroll")) {
            StorageController.setKey("accountingBridgeScroll", pageRef.current.scrollTop);
        }
        setTimeout(() => {
            if (pageRef && pageRef?.current && !isEmpty(pageRef?.current?.scrollTop)) scrollHolder();
        }, 250)
    }
    const clientPaymentColumns = [
        { label: "Γενικό Παραστατικό", value: "documentTypeName" },
        { label: "Αρίθμηση", value: "numbering" },
        { label: "Όνομα Πελάτη", value: "clientName" },
        { label: "ΑΦΜ Πελάτη", value: "vatNumber" },
        { label: "Ημερομηνία", value: "date" },
        { label: "Τρόπος Πληρωμής", value: "paymentTypeName" },
        { label: "Αξία", value: "amount" },
        { label: "Γεφύρωση", value: "bridge" },
        { label: "Τελευταίο Σφάλμα", value: "accountingBridgeError" },
        { label: "Ενέργειες", value: "actions" }
    ]
    const buysColumns = [
        { label: "Γενικό Παραστατικό", value: "documentTypeName" },
        { label: "Αρίθμηση", value: "numbering" },
        { label: "Όνομα Προμηθευτή", value: "supplierName" },
        { label: "ΑΦΜ Προμηθευτή", value: "vat" },
        { label: "Ημερομηνία", value: "date" },
        { label: "Τρόπος Πληρωμής", value: "paymentTypeName" },
        { label: "Πληρωτέο ποσό", value: "paymentAmount" },
        { label: "Γεφύρωση", value: "bridge" },
        { label: "Τελευταίο Σφάλμα", value: "accountingBridgeError" },
        { label: "Ενέργειες", value: "actions" }
    ]
    const supplierPaymentColumns = [
        { label: "Γενικό Παραστατικό", value: "documentTypeName" },
        { label: "Αρίθμηση", value: "numbering" },
        { label: "Όνομα Προμηθευτή", value: "supplierName" },
        { label: "ΑΦΜ Προμηθευτή", value: "vatNumber" },
        { label: "Ημερομηνία", value: "date" },
        { label: "Τρόπος Πληρωμής", value: "paymentTypeName" },
        { label: "Αξία", value: "amount" },
        { label: "Γεφύρωση", value: "bridge" },
        { label: "Τελευταίο Σφάλμα", value: "accountingBridgeError" },
        { label: "Ενέργειες", value: "actions" }
    ]
    const salesColumns = [
        { label: "Γενικό Παραστατικό", value: "documentTypeName" },
        { label: "Αρίθμηση", value: "numbering" },
        { label: "Όνομα Πελάτη", value: "clientName" },
        { label: "ΑΦΜ Πελάτη", value: "vat" },
        { label: "Ημερομηνία", value: "date" },
        { label: "Τρόπος Πληρωμής", value: "paymentTypeName" },
        { label: "Πληρωτέο ποσό", value: "paymentAmount" },
        { label: "Γεφύρωση", value: "bridge" },
        { label: "Τελευταίο Σφάλμα", value: "accountingBridgeError" },
        { label: "Ενέργειες", value: "actions" }
    ]
    const clientMovementsColumns = [
        { label: "Γενικό Παραστατικό", value: "documentTypeName" },
        { label: "Αρίθμηση", value: "numbering" },
        { label: "Ημερομηνία", value: "date" },
        { label: "Συνολικό ποσό", value: "sum" },
        { label: "Γεφύρωση", value: "bridge" },
        { label: "Τελευταίο Σφάλμα", value: "accountingBridgeError" },
        { label: "Ενέργειες", value: "actions" }
    ]
    const supplierMovementsColumns = [
        { label: "Γενικό Παραστατικό", value: "documentTypeName" },
        { label: "Αρίθμηση", value: "numbering" },
        { label: "Ημερομηνία", value: "date" },
        { label: "Συνολικό ποσό", value: "sum" },
        { label: "Γεφύρωση", value: "bridge" },
        { label: "Τελευταίο Σφάλμα", value: "accountingBridgeError" },
        { label: "Ενέργειες", value: "actions" }
    ]
    const productMovementsColumns = [
        { label: "Γενικό Παραστατικό", value: "documentTypeName" },
        { label: "Αρίθμηση", value: "numbering" },
        { label: "Ημερομηνία", value: "date" },
        { label: "Συνολικό ποσό", value: "totalAmount" },
        { label: "Γεφύρωση", value: "bridge" },
        { label: "Τελευταίο Σφάλμα", value: "accountingBridgeError" },
        { label: "Ενέργειες", value: "actions" }
    ]
    const sectionColumnMapping = {
        "Client Payments" : clientPaymentColumns,
        "Buys" : buysColumns,
        "Supplier Payments" : supplierPaymentColumns,
        "Sales" : salesColumns,
        "Client Movements" : clientMovementsColumns,
        "Supplier Movements" : supplierMovementsColumns,
        "Product Movements" : productMovementsColumns,
    }
    const backendMapping = {
        "Client Payments" : "bridge-client-payment",
        "Buys" : "bridge-buy",
        "Supplier Payments" : "bridge-supplier-payment",
        "Sales" : "bridge-sale",
        "Client Movements" : "bridge-client-movement",
        "Supplier Movements" : "bridge-supplier-movement",
        "Product Movements" : "bridge-product-movement",
    }

    const showFilterOptions = [
        { label: "Προβολή όλων", value: "" },
        { label: "Μόνο γεφυρωμένων", value: "true" },
        { label: "Μόνο μη γεφυρωμένων", value: "false" },
        { label: "Αρύθμιστης λογ. γέφυρας", value: "cannot" },
    ]

    const fetchBridgeableDocuments = (showMessage = true) => {
        setLoading(true);
        if (isEmpty(filters.section)) {
            toast.error("Παρακαλώ επιλέξτε κατηγορία.");
            setLoading(false);
        } else {
            fetchAutoBridgeStatus();
            axios.get(process.env.REACT_APP_API_URL2 + "/accounting/bridge-get-bridgeable-documents", {
                headers: { "Content-Type": "application/json" },
                params: {
                    ...filters,
                    company: company.id,
                    year: String(company.year),
                }
            }).then((res) => {
                setLoading(false);
                if (res.data.status === "200") {
                    dispatch(setAccountingBridgeReportData(res.data.data));
                } else {
                    if (showMessage) toast.error(res.data.message);
                }
            }).catch((err) => {
                setLoading(false);
                console.log(err);
            })
        }
    }

    const bridgeDocument = (docId) => {
        if (loading) {
            toast.error("Παρακαλώ περιμένετε να τελειώσει η προηγούμενη γεφύρωση/φόρτωση δεδομένων.");
        } else {
            if (!isEmpty(docId)) {
                setLoading(true);
                axios.post(process.env.REACT_APP_API_URL2 + `/accounting/${backendMapping[filters.section]}`, {company: company.id, docId: docId}, {
                    headers: { "Content-Type": "application/json" }
                }).then((res) => {
                    setLoading(false);
                    if (res.data.status === "200") {
                        toast.success("Η γεφύρωση έγινε με επιτυχία.");
                        fetchBridgeableDocuments(false);
                    } else {
                        toast.error(res.data.message);
                        fetchBridgeableDocuments(false);
                    }
                }).catch((err) => {
                    setLoading(false);
                    toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
                    console.log(err);
                })
            } else {
                toast.error("Πρόβλημα κατά την αναζήτηση.");
            }
        }
    }

    const handleFilterChange = (e, type, name) => {
        if (type === "dropdown") {
            dispatch(setAccountingBridgeFilters({...filters, [name] : e.value}));
            if (name === "section") dispatch(setAccountingBridgeReportData([]));
        } else if (type === "datebox") {
            if(moment(e).isValid()) {
                dispatch(setAccountingBridgeFilters({...filters, [name] : moment(e).format("DD/MM/YYYY")}));
            } else {
                dispatch(setAccountingBridgeFilters({...filters, [name] : ""}));
            }
        } else if (type === "msdd") {
            dispatch(setAccountingBridgeFilters({...filters, [name] : e}));
        }
    }

    const handleShowBridgeFilteredModal = () => {
        if (reportData && reportData?.length > 0) {
            const nonBridged = reportData.filter((el) => el.isBridged === "false");
            if (nonBridged.length > 0) {
                setShowBridgeFiltered(true);
                setBridgedFilterCount(nonBridged.length);
            } else {
                toast.error("Όλα τα έγγραφα είναι γεφυρωμένα.");
            }
        } else {
            toast.error("Δεν υπάρχουν έγγραφα προς γεφύρωση.");
        }
    }

    const handleShowUnbridgeFilteredModal = () => {
        if (reportData && reportData?.length > 0) {
            const bridged = reportData.filter((el) => el.isBridged === "true");
            if (bridged.length > 0) {
                setShowUnbridgeFiltered(true);
                setUnbridgeFilterCount(bridged.length);
            } else {
                toast.error("Δεν υπάρχουν έγγραφα προς απογεφύρωση.");
            }
        } else {
            toast.error("Δεν υπάρχουν έγγραφα προς απογεφύρωση.");
        }
    }

    const handleBridgeFiltered = async () => {
        if (loading) {
            toast.error("Παρακαλώ περιμένετε να τελειώσει η προηγούμενη εργασία.");
        } else {
            setLoading(true);
            const nonBridged = reportData.filter((el) => el.isBridged === "false");
            let bridgedCount = 0;
            for (let obj of nonBridged) {
                try {
                    const bridgeResponse = await axios.post(process.env.REACT_APP_API_URL2 + `/accounting/${backendMapping[filters.section]}`, {
                        company: company.id,
                        docId: obj._id
                    }, {
                        headers: {"Content-Type": "application/json"}
                    })
                    if (bridgeResponse?.data?.status === "200") bridgedCount++;
                } catch (error) {
                    console.log(error);
                }
            }
            setLoading(false);
            fetchBridgeableDocuments(false);
            setShowBridgeFiltered(false);
            if (bridgedCount > 0 && nonBridged.length - bridgedCount === 0) {
                toast.info(`Γεφυρώθηκαν επιτυχώς ${bridgedCount} έγγραφα.`, {autoClose: 10000});
            } else if (bridgedCount > 0 && nonBridged.length - bridgedCount > 0) {
                toast.info(`Γεφυρώθηκαν επιτυχώς ${bridgedCount} έγγραφα ενώ προέκυψε σφάλμα σε ${nonBridged.length - bridgedCount} έγγραφα.`, {autoClose: 10000});
            } else {
                toast.warning(`Η γεφύρωση έτρεξε αλλά προέκυψε σφάλμα σε ${nonBridged.length - bridgedCount} έγγραφα.`, {autoClose: 10000});
            }
        }
    }

    const handleOpenDeleteUnique = (_id) => {
        setToUnbridgeID(_id);
        setShowUnbridgeUnique(true);
    }

    const handleUnbridgeUnique = async () => {
        if (loading) {
            toast.error("Παρακαλώ περιμένετε να τελειώσει η προηγούμενη εργασία.");
        } else {
            setLoading(true);
            axios.post(process.env.REACT_APP_API_URL2 + `/delete/delete-doc`, {
                company: company.id,
                year: company.year,
                docId: toUnbridgeID,
                isDeleting: "Accounting Record Search",
            }, {
                headers: {"Content-Type": "application/json"}
            }).then((res) => {
                setLoading(false);
                if (res.data.status === "200") {
                    setShowUnbridgeUnique(false);
                    fetchBridgeableDocuments(false);
                    toast.success("Επιτυχής απογεφύρωση.");
                } else {
                    toast.error(res.data.message);
                }
            }).catch((err) => {
                setLoading(false);
                console.log(err);
                toast.success("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        }
    }

    const handleUnbridgeFiltered = async () => {
        if (loading) {
            toast.error("Παρακαλώ περιμένετε να τελειώσει η προηγούμενη εργασία.");
        } else {
            setLoading(true);
            const bridged = reportData.filter((el) => el.isBridged === "true");
            let unbridgedCount = 0;
            for (let obj of bridged) {
                try {
                    const unbridgeResponse = await axios.post(process.env.REACT_APP_API_URL2 + `/delete/delete-doc`, {
                        company: company.id,
                        year: company.year,
                        docId: obj.bridgedDocument,
                        isDeleting: "Accounting Record Search",
                    }, {
                        headers: {"Content-Type": "application/json"}
                    })
                    if (unbridgeResponse?.data?.status === "200") unbridgedCount++;
                } catch (error) {
                    console.log(error);
                }
            }
            setLoading(false);
            fetchBridgeableDocuments(false);
            setShowUnbridgeFiltered(false);
            if (unbridgedCount > 0 && bridged.length - unbridgedCount === 0) {
                toast.info(`Απογεφυρώθηκαν επιτυχώς ${unbridgedCount} έγγραφα.`, {autoClose: 10000});
            } else if (unbridgedCount > 0 && bridged.length - unbridgedCount > 0) {
                toast.info(`Απογεφυρώθηκαν επιτυχώς ${unbridgedCount} έγγραφα ενώ προέκυψε σφάλμα σε ${bridged.length - unbridgedCount} έγγραφα.`, {autoClose: 10000});
            } else {
                toast.warning(`Η απογεφύρωση έτρεξε αλλά προέκυψε σφάλμα σε ${bridged.length - unbridgedCount} έγγραφα.`, {autoClose: 10000});
            }
        }
    }

    const handleOpenDocument = (docId, section) => {
        const tabMapping = {
            "Product Movements" : "edit-product-movements",
            "Sales" : "view-sales",
            "Client Movements" : "edit-client-movements",
            "Client Payments" : "edit-client-payments",
            "Buys" : "edit-buys",
            "Supplier Payments" : "edit-supplier-payments",
            "Supplier Movements" : "edit-supplier-movements",
            "Accounting Records" : "accounting-record-edit",
        }
        if (docId && section) {
            axios.post(process.env.REACT_APP_API_URL2 + "/accounting/show-bridged-document", {company: company.id, docId: docId, section: section}, {
                headers: { "Content-Type": "application/json" }
            }).then((res) => {
                if (res.data.status === "200") {
                    const tabName = tabMapping[section];
                    if (tabName === "edit-product-movements") {
                        dispatch(setEditProductMovements(res.data.data));
                    } else if (tabName === "view-sales") {
                        dispatch(setEditSales(res.data.data));
                    } else if (tabName === "edit-client-movements") {
                        dispatch(setEditClientMovements(res.data.data));
                    } else if (tabName === "edit-client-payments") {
                        dispatch(setEditClientPayment(res.data.data));
                    } else if (tabName === "edit-buys") {
                        dispatch(setEditBuys(res.data.data));
                    } else if (tabName === "edit-supplier-payments") {
                        dispatch(setEditSupplierPayment(res.data.data));
                    } else if (tabName === "edit-supplier-movements") {
                        dispatch(setEditSupplierMovements(res.data.data));
                    } else if (tabName === "accounting-record-edit") {
                        dispatch(setEditAccountingRecord(res.data.data));
                    }
                    const idx = TABS_DATA.findIndex((item) => tabName === item);
                    if(idx === -1) {
                        dispatch(addTab(tabName));
                        dispatch(updateActiveMainTab(TABS_DATA.length));
                    } else {
                        dispatch(updateActiveMainTab(idx));
                    }
                } else {
                    toast.error(res.data.message);
                }
            }).catch((err) => {
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        } else {
            toast.error("Σφάλμα κατά την εύρεση γραμμής.")
        }
    }

    const fetchAutoBridgeStatus = () => {
        axios.get(process.env.REACT_APP_API_URL2 + "/accounting/get-auto-bridge", {
            headers: { "Content-Type": "application/json" },
            params: {
                company: company.id
            }
        }).then((res) => {
            if (res.data.status === "200") {
                setAutoBridge(res.data.data === "true");
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleAutoBridgeChange = (bool) => {
        axios.post(process.env.REACT_APP_API_URL2 + "/accounting/update-auto-bridge", {company: company.id, autoBridge: String(bool)}, {
            headers: { "Content-Type": "application/json" }
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success(`Η αυτόματη γέφυρα ${res.data.data === "true" ? "ενεργοποιήθηκε" : "απενεργοποιήθηκε"}`);
                setAutoBridge(res.data.data === "true");
                setToggleBridgeModal(false);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            console.log(err);
        })
    }

    const getRowColor = (isBridged) => {
        if (isBridged === "true") {
            return "#d7fcd8"
        } else if (isBridged === "false") {
            return "#fff5bd"
        } else if (isBridged === "cannot") {
            return "#e0e0e0"
        } else {
            return ""
        }
    }

    return (
        <div key={"wholeBody"} ref={pageRef} style={classicStyleBelowNavbar}>
            <Row>
                <Col md={2}>
                    <DateHelper
                        dateFromAndToFunction={(from, to) => dispatch(setAccountingBridgeFilters({...filters, date_from: from, date_to: to}))}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        dateFormat="dd-MM-yyyy"
                        name="date_from"
                        selected={filters.date_from !== "" ? moment(filters.date_from, "DD/MM/YYYY").toDate() : ""}
                        label={"Ημερομηνία από"}
                        onChange={(e) => handleFilterChange(e, "datebox", "date_from")}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        dateFormat="dd-MM-yyyy"
                        name="date_to"
                        selected={filters.date_to !== "" ? moment(filters.date_to, "DD/MM/YYYY").toDate() : ""}
                        label={"Ημερομηνία εως"}
                        onChange={(e) => handleFilterChange(e, "datebox", "date_to")}
                    />
                </Col>
                <Col md={2}>
                    <Dropdown
                        name={"section"}
                        label={"Κατηγορία Γενικού Παραστατικού"}
                        key={Math.random()}
                        options={bridgedSections}
                        defaultValue={bridgedSections.find((el) => el.value === filters.section)}
                        onChange={(e) => handleFilterChange(e, "dropdown", "section")}
                    />
                </Col>
                <Col md={2}>
                    <Dropdown
                        name={"showFilter"}
                        label={"Φιλτράρισμα εγγραφών"}
                        key={Math.random()}
                        defaultValue={showFilterOptions.find((el) => el.value === filters.showFilter)}
                        options={showFilterOptions}
                        onChange={(e) => handleFilterChange(e, "dropdown", "showFilter")}
                    />
                </Col>
                <Col md={2}>
                    <Button onClick={() => fetchBridgeableDocuments()} className={"mt-3"} disabled={loading}>Φόρτωση</Button>
                </Col>
            </Row>
            <Row className={"mb-2"}>
                <Col md={2}>
                    <BetterCheckBox
                        name={"autoBridge"}
                        className={"mt-3"}
                        text={"Αυτοματοποίηση γέφυρας"}
                        checked={autoBridge}
                        onChange={(e) => {
                            setToggleBridgeModal(true);
                            setConfirmationStatus(e.target.checked);
                        }}
                    />
                </Col>
                <Col md={2}>
                    <Button size={"sm"} onClick={() => handleShowBridgeFilteredModal()} className={"mt-3"} disabled={loading}>Γεφύρωση φιλτραρισμένων</Button>
                </Col>
                <Col md={2}>
                    <Button size={"sm"} onClick={() => handleShowUnbridgeFilteredModal()} className={"mt-3"} disabled={loading}>Απογεφύρωση φιλτραρισμένων</Button>
                </Col>
                <Col md={3}>
                    <span style={{backgroundColor: "#d7fcd8"}}>Πράσινο = Γεφυρωμένο</span><br/>
                    <span style={{backgroundColor: "#fff5bd"}}>Κίτρινο = Σφάλμα Γεφύρωσης (μη γεφυρωμένο)</span><br/>
                    <span style={{backgroundColor: "#e0e0e0"}}>Γκρι = Μη ρυθμισμένη γέφυρα</span><br/>
                    <span>Άσπρο = Μη γεφυρωμένο</span>
                </Col>
            </Row>
            <Row>
                {reportData.length > 0 && (
                    <table style={{width: "98%", border: "1px solid gray", textAlign: "center", marginLeft: "1%"}} key={"mainTable"}>
                        <thead style={{border: "1px solid gray", backgroundColor: "#ededed"}}>
                        {sectionColumnMapping[filters.section]?.length > 0 && sectionColumnMapping[filters.section].map((col) => (
                            <th style={{border: "1px solid gray"}}>{col.label}</th>
                        ))}
                        </thead>
                        <tbody>
                        {reportData.length > 0 && reportData.map((doc, idx) => (
                            <tr key={"row" + idx} style={{backgroundColor: getRowColor(doc.isBridged)}}>
                                {sectionColumnMapping[filters.section]?.length > 0 && sectionColumnMapping[filters.section].map((col) => {
                                    if (col.value === "actions") {
                                        return (
                                            <td style={{marginTop: "4px", marginBottom: "4px", border: "1px solid gray", width: "12%"}}>
                                                <Badge style={{cursor: "pointer"}} bg={"primary"} className={"mr-1"} title={"Προβολή Εμπορικού Εγγράφου"} onClick={() => handleOpenDocument(doc._id, filters.section)}>Εμπ.</Badge>
                                                {doc.isBridged === "true" && (
                                                    <React.Fragment>
                                                        <Badge style={{cursor: "pointer"}} bg={"primary"} className={"mr-1"} title={"Προβολή Λογιστικού Άρθρου"} onClick={() => handleOpenDocument(doc._id, "Accounting Records")}>Λογ.</Badge>
                                                        <Badge style={{cursor: "pointer"}} bg={"danger"} className={"mr-1"} title={"Απογεφύρωση"} onClick={() => handleOpenDeleteUnique(doc.bridgedDocument)}>Απογεφύρωση</Badge>
                                                    </React.Fragment>
                                                )}
                                            </td>
                                        )
                                    } else if (col.value === "bridge" && doc.isBridged !== "true") {
                                        return (
                                            <td style={{marginTop: "4px", marginBottom: "4px", border: "1px solid gray"}}>
                                                <Badge bg={"primary"} style={{cursor: "pointer"}} onClick={() => bridgeDocument(doc._id)} title={"Γεφύρωση Εγγράφου"}>Γεφ.</Badge>
                                            </td>
                                        )
                                    } else if (col.value === "date") {
                                        return (<td style={{marginTop: "4px", marginBottom: "4px", border: "1px solid gray"}}>{moment(doc[col.value]).format("DD/MM/YYYY HH:mm")}</td>)
                                    } else {
                                        return (<td style={{marginTop: "4px", marginBottom: "4px", border: "1px solid gray"}}>{doc[col.value]}</td>)
                                    }
                                })}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </Row>

            <Modal show={showBridgeFiltered} onHide={() => setShowBridgeFiltered(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Γεφύρωση φιλτραρισμένων</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Είσαστε σίγουροι ότι θέλετε να τρέξετε τον αλγόριθμο της γέφυρας για τα φιλτραρισμένα έγγραφα;<br/>
                    Βρέθηκαν {bridgedFilterCount} έγγραφα προς γεφύρωση.<br/>
                    Τα ήδη γεφυρωμένα θα παραλειφθούν
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowBridgeFiltered(false)} disabled={loading}>Όχι, κλείσιμο</Button>
                    <Button variant="primary" onClick={() => handleBridgeFiltered()} disabled={loading}>Ναι, γεφύρωση {loading && <Spinner animation="border" variant="dark" />}</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showUnbridgeFiltered} onHide={() => setShowUnbridgeFiltered(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Απογεφύρωση φιλτραρισμένων</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Είσαστε σίγουροι ότι θέλετε να τρέξετε τον αλγόριθμο της απογεφύρωσης για τα φιλτραρισμένα έγγραφα;<br/>
                    Βρέθηκαν {unbridgeFilterCount} έγγραφα προς απογεφύρωση.<br/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowUnbridgeFiltered(false)} disabled={loading}>Όχι, κλείσιμο</Button>
                    <Button variant="primary" onClick={() => handleUnbridgeFiltered()} disabled={loading}>Ναι, απόγεφύρωση {loading && <Spinner animation="border" variant="dark" />}</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={toggleBridgeModal} onHide={() => setToggleBridgeModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{confirmationStatus === true ? "Ενεργοποίηση" : "Απενεργοποίηση"} αυτοματοποίησης γέφυρας</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Είσαστε σίγουροι ότι θέλετε να {confirmationStatus === true ? "ενεργοποιήσετε" : "απενεργοποιήσετε"} την αυτοματοποίηση της γέφυρας;
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setToggleBridgeModal(false)} disabled={loading}>Όχι, κλείσιμο</Button>
                    <Button variant="primary" onClick={() => handleAutoBridgeChange(confirmationStatus)} disabled={loading}>Ναι</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showUnbridgeUnique} onHide={() => setShowUnbridgeUnique(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Απογεφύρωση εγγράφου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Είσαστε σίγουροι ότι θέλετε να απογεφυρώσετε το έγγραφο;
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowUnbridgeUnique(false)} disabled={loading}>Όχι, κλείσιμο</Button>
                    <Button variant="primary" onClick={() => handleUnbridgeUnique()} disabled={loading}>Ναι, απόγεφύρωση {loading && <Spinner animation="border" variant="dark" />}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
