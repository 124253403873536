import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import Input from "../../../../common/Input";
import Button from "react-bootstrap/Button";
import {Spinner} from "react-bootstrap";
import axios from "axios";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";

const ICD10Modal = ({show, setShow, diagnoses, handleCheck, backButtonMessage}) => {
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const [icd10, setICD10] = useState([]);
    const [icd10Filter, setICD10Filter] = useState("");
    const [loadingICD10, setLoadingICD10] = useState(false);

    const fetchICD10s = () => {
        setLoadingICD10(true);
        axios.get(`${process.env.REACT_APP_API_URL2}/prescription/icd10s`, {
            headers: { 'Content-Type': 'application/json' },
            params: {
                company: company.id,
                filter: icd10Filter,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                setICD10(res.data.data);
            } else {
                toast.error(res.data.message);
            }
            setLoadingICD10(false);
        }).catch((err) => {
            setLoadingICD10(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    return (
        <React.Fragment>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Επιλογή Διαγνώσεων</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{display: "flex", gap: "10px", width: "100%"}}>
                        <div style={{ flex: "6" }} onKeyDown={(e) => {
                            if (e.key === "Enter") fetchICD10s()
                        }}>
                            <Input
                                name={"icd10Filter"}
                                unlabbled={true}
                                className={"m-0"}
                                placeholder={"Αναζήτηση ICD10"}
                                value={icd10Filter}
                                onChange={(e) => setICD10Filter(e.target.value)}
                            />
                        </div>
                        <Button size={"sm"} onClick={() => fetchICD10s()} style={{ flex: "4" }} disabled={loadingICD10}>
                            Αναζήτηση ICD10
                            {loadingICD10 && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                        </Button>
                    </div>
                    {icd10.length > 0 && (
                        <React.Fragment>
                            <table className={"simpleClassicTable mt-1"}>
                                <colgroup>
                                    <col span={1} style={{width: "15%"}}></col>
                                    <col span={1} style={{width: "25%"}}></col>
                                    <col span={1} style={{width: "60%"}}></col>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>Κωδικός</th>
                                    <th>Τίτλος</th>
                                </tr>
                                </thead>
                                <tbody>
                                {icd10.map((item, idx) => (
                                    <tr key={`icd10table-${idx}`}>
                                        <td style={{zoom: "1.3"}}>
                                            <input type={"checkbox"} checked={diagnoses.some((el) => el.id === item.id)}
                                                   onChange={(e) => handleCheck(e, item)} />
                                        </td>
                                        <td>{item.code}</td>
                                        <td>{item.title}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" size={"sm"} onClick={() => setShow(false)}>
                        {backButtonMessage}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default ICD10Modal
