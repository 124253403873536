export const salesInvoiceTypesData = [
    {
        "id": "1.1",
        "myCodeData": "1.1",
        "name": "Τιμολόγιο Πώλησης",
        "details1": "Client allowed only Greek Companies -wholesale (all client Categories except \"Retail\")"
    },
    {
        "id": "1.2",
        "myCodeData": "1.2",
        "name": "Τιμολόγιο Πώλησης/ Ενδοκοινοτικές Παραδόσεις",
        "details1": "Client allowed only Companies within E.U. (except Greece)"
    },
    {
        "id": "1.3",
        "myCodeData": "1.3",
        "name": "Τιμολόγιο Πώλησης/ Παραδόσεις Τρίτων Χωρών",
        "details1": "Client allowed only Companies outside E.U."
    },
    {
        "id": "1.4",
        "myCodeData": "1.4",
        "name": "Τιμολόγιο Πώλησης/ Πώληση για Λογαριασμό Τρίτων",
        "details1": "Client allowed only Greek Companies -wholesale (all client Categories except \"Retail\")"
    },
    {
        "id": "1.5",
        "myCodeData": "1.5",
        "name": "Τιμολόγιο Πώλησης/ Εκκαθάριση Πωλήσεων Τρίτων- Αμοιβή από Πωλήσεις Τρίτων",
        "details1": "Client allowed only Greek Companies -wholesale (all client Categories except \"Retail\")"
    },
    {
        "id": "1.6",
        "myCodeData": "1.6",
        "name": "Τιμολόγιο Πώλησης/ Συμπληρωματικό Παραστατικό",
        "details1": "Client allowed only Greek Companies -wholesale (all client Categories except \"Retail\")",
        "details3": "Supports correlated sale"
    },
    {
        "id": "2.1",
        "conflictingDocumentsDomestic": "Τιμολόγιο Παροχής Υπηρεσιών",
        "myCodeData": "2.1",
        "name": "Τιμολόγιο Παροχής",
        "details1": "Client allowed only Greek Companies -wholesale (all client Categories except \"Retail\")"
    },
    {
        "id": "2.2",
        "myCodeData": "2.2",
        "name": "Τιμολόγιο Παροχής/ Ενδοκοινοτική Παροχή Υπηρεσιών",
        "details1": "Client allowed only Companies within E.U. (except Greece)"
    },
    {
        "id": "2.3",
        "myCodeData": "2.3",
        "name": "Τιμολόγιο Παροχής/ Παροχή Υπηρεσιών σε λήπτη Τρίτης Χώρας",
        "details1": "Client allowed only Companies outside E.U."
    },
    {
        "id": "2.4",
        "myCodeData": "2.4",
        "name": "Τιμολόγιο Παροχής / Συμπληρωματικό Παραστατικό",
        "details1": "Client allowed only Greek Companies -wholesale (all client Categories except \"Retail\")",
        "details3": "Supports correlated sale"
    },
    {
        "id": "5.1",
        "conflictingDocumentsDomestic": "Πιστωτικό Τιμολόγιο",
        "myCodeData": "5.1",
        "name": "Πιστωτικό Τιμολόγιο /Συσχετιζόμενο",
        "details1": "Client allowed only Greek Companies",
        "details2": "Credit Document",
        "details3": "Supports correlated sale"
    },
    {
        "id": "5.2",
        "myCodeData": "5.2",
        "name": "Πιστωτικό Τιμολόγιο /Μη Συσχετιζόμενο",
        "details1": "Client allowed only Greek Companies",
        "details2": "Credit Document"
    },
    {
        "id": "6.1",
        "conflictingDocumentsDomestic": "Στοιχείο Αυτοπαράδοσης -   Ιδιοχρησιμοποίησης",
        "myCodeData": "6.1",
        "name": "Στοιχείο Αυτοπαράδοσης",
        "details1": "Client is not allowed. Client and issuer is the same."
    },
    {
        "id": "6.2",
        "myCodeData": "6.2",
        "name": "Στοιχείο Ιδιοχρησιμοποίησης",
        "details1": "Client is not allowed. Client and issuer is the same."
    },
    {
        "id": "7.1",
        "conflictingDocumentsDomestic": "Συμβόλαιο/Έσοδο",
        "myCodeData": "7.1",
        "name": "Συμβόλαιο/Έσοδο",
        "details1": "All clients allowed"
    },
    {
        "id": "8.1",
        "conflictingDocumentsDomestic": "Ειδικό Στοιχείο (Έσοδο) - Απόδειξη Είσπραξης",
        "myCodeData": "8.1",
        "name": "Ενοίκια-Έσοδο",
        "details1": "All clients allowed"
    },
    {
        "id": "8.2",
        "myCodeData": "8.2",
        "name": "Τέλος ανθεκτικότητας κλιματικής κρίσης",
        "details1": "Client allowed only Greek Companies"
    },
    {
        "id": "9.3",
        "myCodeData": "9.3",
        "name": "Παραστατικό Διακίνησης Μη Συσχετιζόμενο",
        "details1": "All clients allowed"
    },
    {
        "id": "11.1",
        "conflictingDocumentsDomestic": "Παραστατικά Λιανικής",
        "myCodeData": "11.1",
        "name": "ΑΛΠ",
        "details1": "Client allowed only Greek Companies -retail (only client category \"Retail\")"
    },
    {
        "id": "11.2",
        "myCodeData": "11.2",
        "name": "ΑΠΥ",
        "details1": "Client allowed only Greek Companies -retail (only client category \"Retail\")"
    },
    {
        "id": "11.3",
        "myCodeData": "11.3",
        "name": "Απλοποιημένο Τιμολόγιο",
        "details1": "Client allowed only Greek Companies -retail (only client category \"Retail\")"
    },
    {
        "id": "11.4",
        "myCodeData": "11.4",
        "name": "Πιστωτικό Στοιχ. Λιανικής",
        "details1": "Client allowed only Greek Companies -retail (only client category \"Retail\")",
        "details2": "Credit Document"
    },
    {
        "id": "11.5",
        "myCodeData": "11.5",
        "name": "Απόδειξη Λιανικής πώλησης για Λογ/σμο Τρίτων",
        "details1": "Client allowed only Greek Companies -retail (only client category \"Retail\")"
    },
    {
        "id": "17.3",
        "myCodeData": "17.3",
        "name": "Λοιπές Εγγραφές Τακτοποίησης Εσόδων - Λογιστική Βάση"
    },
    {
        "id": "17.4",
        "myCodeData": "17.4",
        "name": "Λοιπές Εγγραφές Τακτοποίησης Εσόδων - Φορολογική Βάση"
    },
]

export const buysInvoiceTypesData = [
    {
        "id": "3.1",
        "conflictingDocumentsDomestic": "Τίτλος Κτήσης",
        "myCodeData": "3.1",
        "name": "Τίτλος Κτήσης ( Μη υπόχρεος Εκδότης)",
        "details1": "Client allowed only Greek Companies"
    },
    {
        "id": "3.2",
        "myCodeData": "3.2",
        "name": "Τίτλος κτήσης (άρνηση χρέωσης από υπόχρεο Εκδότη)",
        "details1": "Client allowed only Greek Companies"
    },
    {
        "id": "13.1",
        "conflictingDocumentsDomestic": "Λήψη Παραστατικών Λιανικής",
        "myCodeData": "13.1",
        "name": "Έξοδα- Αγορές Λιανικών Συναλλαγών  ημεδαπής/αλλοδαπής"
    },
    {
        "id": "13.2",
        "myCodeData": "13.2",
        "name": "Παροχή Λιανικών Συναλλαγών ημεδαπής/αλλοδαπής"
    },
    {
        "id": "13.3",
        "myCodeData": "13.3",
        "name": "Κοινόχρηστα"
    },
    {
        "id": "13.4",
        "myCodeData": "13.4",
        "name": "Συνδρομές"
    },
    {
        "id": "13.30",
        "myCodeData": "13.30",
        "name": "Παραστατικά Οντότητας ως Αναγράφονται από την ίδια (Δυναμικό)"
    },
    {
        "id": "13.31",
        "myCodeData": "13.31",
        "name": "Πιστωτικό Στοιχ. Λιανικής ημεδαπής/αλλοδαπής"
    },
    {
        "id": "14.1",
        "conflictingDocumentsDomestic": "Παραστ. Εξαιρ. Οντοτήτων ημεδαπής/αλλοδαπής",
        "myCodeData": "14.1",
        "name": "Τιμολόγιο/Ενδοκοινοτικές  Αποκτήσεις"
    },
    {
        "id": "14.2",
        "myCodeData": "14.2",
        "name": "Τιμολόγιο Αποκτήσεις Τρίτων Χωρών"
    },
    {
        "id": "14.3",
        "myCodeData": "14.3",
        "name": "Τιμολόγιο /Ενδοκοινοτική Λήψη Υπηρεσιών"
    },
    {
        "id": "14.4",
        "myCodeData": "14.4",
        "name": "Τιμολόγιο / Λήψη Υπηρεσιών Τρίτων Χωρών"
    },
    {
        "id": "14.5",
        "myCodeData": "14.5",
        "name": "ΕΦΚΑ και λοιποί Ασφαλιστικοί Οργανισμοί"
    },
    {
        "id": "14.30",
        "myCodeData": "14.30",
        "name": "Παραστατικά Οντότητας ως Αναγράφονται από την ίδια (Δυναμικό)"
    },
    {
        "id": "14.31",
        "myCodeData": "14.31",
        "name": "Πιστωτικό ημεδαπής/αλλοδαπής"
    },
    {
        "id": "15.1",
        "conflictingDocumentsDomestic": "Συμβόλαιο Έξοδο",
        "myCodeData": "15.1",
        "name": "Συμβόλαιο- Έξοδο"
    },
    {
        "id": "16.1",
        "conflictingDocumentsDomestic": "Ειδικό Στοιχείο (Έξοδο)- Απόδειξη Πληρωμής",
        "myCodeData": "16.1",
        "name": "Ενοίκιο Έξοδο"
    },
    /*{
        "id": "17.1",
        "conflictingDocumentsDomestic": "Εγγραφές Οντότητας",
        "myCodeData": "17.1",
        "name": "Μισθοδοσία "
    },*/
    {
        "id": "17.2",
        "myCodeData": "17.2",
        "name": "Αποσβέσεις"
    },
    {
        "id": "17.5",
        "myCodeData": "17.5",
        "name": "Λοιπές Εγγραφές Τακτοποίησης Εξόδων - Λογιστική Βάση"
    },
    {
        "id": "17.6",
        "myCodeData": "17.6",
        "name": "Λοιπές Εγγραφές Τακτοποίησης Εξόδων - Φορολογική Βάση"
    }
]

export const buysInvoiceTypesDataProvider = [
    {
        "id": "3.1",
        "conflictingDocumentsDomestic": "Τίτλος Κτήσης",
        "myCodeData": "3.1",
        "name": "Τίτλος Κτήσης ( Μη υπόχρεος Εκδότης)",
        "details1": "Client allowed only Greek Companies"
    },
    {
        "id": "3.2",
        "myCodeData": "3.2",
        "name": "Τίτλος κτήσης (άρνηση χρέωσης από υπόχρεο Εκδότη)",
        "details1": "Client allowed only Greek Companies"
    },
];

export const salesInvoiceTypesDataProvider = [
    {
        "id": "1.1",
        "myCodeData": "1.1",
        "name": "Τιμολόγιο Πώλησης",
        "details1": "Client allowed only Greek Companies -wholesale (all client Categories except \"Retail\")"
    },
    {
        "id": "1.2",
        "myCodeData": "1.2",
        "name": "Τιμολόγιο Πώλησης/ Ενδοκοινοτικές Παραδόσεις",
        "details1": "Client allowed only Companies within E.U. (except Greece)"
    },
    {
        "id": "1.3",
        "myCodeData": "1.3",
        "name": "Τιμολόγιο Πώλησης/ Παραδόσεις Τρίτων Χωρών",
        "details1": "Client allowed only Companies outside E.U."
    },
    {
        "id": "1.4",
        "myCodeData": "1.4",
        "name": "Τιμολόγιο Πώλησης/ Πώληση για Λογαριασμό Τρίτων",
        "details1": "Client allowed only Greek Companies -wholesale (all client Categories except \"Retail\")"
    },
    {
        "id": "1.5",
        "myCodeData": "1.5",
        "name": "Τιμολόγιο Πώλησης/ Εκκαθάριση Πωλήσεων Τρίτων- Αμοιβή από Πωλήσεις Τρίτων",
        "details1": "Client allowed only Greek Companies -wholesale (all client Categories except \"Retail\")"
    },
    {
        "id": "1.6",
        "myCodeData": "1.6",
        "name": "Τιμολόγιο Πώλησης/ Συμπληρωματικό Παραστατικό",
        "details1": "Client allowed only Greek Companies -wholesale (all client Categories except \"Retail\")",
        "details3": "Supports correlated sale"
    },
    {
        "id": "2.1",
        "conflictingDocumentsDomestic": "Τιμολόγιο Παροχής Υπηρεσιών",
        "myCodeData": "2.1",
        "name": "Τιμολόγιο Παροχής",
        "details1": "Client allowed only Greek Companies -wholesale (all client Categories except \"Retail\")"
    },
    {
        "id": "2.2",
        "myCodeData": "2.2",
        "name": "Τιμολόγιο Παροχής/ Ενδοκοινοτική Παροχή Υπηρεσιών",
        "details1": "Client allowed only Companies within E.U. (except Greece)"
    },
    {
        "id": "2.3",
        "myCodeData": "2.3",
        "name": "Τιμολόγιο Παροχής/ Παροχή Υπηρεσιών σε λήπτη Τρίτης Χώρας",
        "details1": "Client allowed only Companies outside E.U."
    },
    {
        "id": "2.4",
        "myCodeData": "2.4",
        "name": "Τιμολόγιο Παροχής / Συμπληρωματικό Παραστατικό",
        "details1": "Client allowed only Greek Companies -wholesale (all client Categories except \"Retail\")",
        "details3": "Supports correlated sale"
    },
    {
        "id": "5.1",
        "conflictingDocumentsDomestic": "Πιστωτικό Τιμολόγιο",
        "myCodeData": "5.1",
        "name": "Πιστωτικό Τιμολόγιο /Συσχετιζόμενο",
        "details1": "Client allowed only Greek Companies",
        "details2": "Credit Document",
        "details3": "Supports correlated sale"
    },
    {
        "id": "5.2",
        "myCodeData": "5.2",
        "name": "Πιστωτικό Τιμολόγιο /Μη Συσχετιζόμενο",
        "details1": "Client allowed only Greek Companies",
        "details2": "Credit Document"
    },
    {
        "id": "6.1",
        "conflictingDocumentsDomestic": "Στοιχείο Αυτοπαράδοσης -   Ιδιοχρησιμοποίησης",
        "myCodeData": "6.1",
        "name": "Στοιχείο Αυτοπαράδοσης",
        "details1": "Client is not allowed. Client and issuer is the same."
    },
    {
        "id": "6.2",
        "myCodeData": "6.2",
        "name": "Στοιχείο Ιδιοχρησιμοποίησης",
        "details1": "Client is not allowed. Client and issuer is the same."
    },
    {
        "id": "7.1",
        "conflictingDocumentsDomestic": "Συμβόλαιο/Έσοδο",
        "myCodeData": "7.1",
        "name": "Συμβόλαιο/Έσοδο",
        "details1": "All clients allowed"
    },
    {
        "id": "8.1",
        "conflictingDocumentsDomestic": "Ειδικό Στοιχείο (Έσοδο) - Απόδειξη Είσπραξης",
        "myCodeData": "8.1",
        "name": "Ενοίκια-Έσοδο",
        "details1": "All clients allowed"
    },
    {
        "id": "8.2",
        "myCodeData": "8.2",
        "name": "Τέλος ανθεκτικότητας κλιματικής κρίσης",
        "details1": "Client allowed only Greek Companies"
    },
    {
        "id": "9.3",
        "myCodeData": "9.3",
        "name": "Παραστατικό Διακίνησης Μη Συσχετιζόμενο",
        "details1": "All clients allowed"
    },
    {
        "id": "11.1",
        "conflictingDocumentsDomestic": "Παραστατικά Λιανικής",
        "myCodeData": "11.1",
        "name": "ΑΛΠ",
        "details1": "Client allowed only Greek Companies -retail (only client category \"Retail\")"
    },
    {
        "id": "11.2",
        "myCodeData": "11.2",
        "name": "ΑΠΥ",
        "details1": "Client allowed only Greek Companies -retail (only client category \"Retail\")"
    },
    {
        "id": "11.3",
        "myCodeData": "11.3",
        "name": "Απλοποιημένο Τιμολόγιο",
        "details1": "Client allowed only Greek Companies -retail (only client category \"Retail\")"
    },
    {
        "id": "11.4",
        "myCodeData": "11.4",
        "name": "Πιστωτικό Στοιχ. Λιανικής",
        "details1": "Client allowed only Greek Companies -retail (only client category \"Retail\")",
        "details2": "Credit Document"
    },
    {
        "id": "11.5",
        "myCodeData": "11.5",
        "name": "Απόδειξη Λιανικής πώλησης για Λογ/σμο Τρίτων",
        "details1": "Client allowed only Greek Companies -retail (only client category \"Retail\")"
    }
]

export const allInvoiceTypesData = [
    {
        "id": "1.1",
        "myCodeData": "1.1",
        "name": "Τιμολόγιο Πώλησης",
        "details1": "Client allowed only Greek Companies -wholesale (all client Categories except \"Retail\")"
    },
    {
        "id": "1.2",
        "myCodeData": "1.2",
        "name": "Τιμολόγιο Πώλησης/ Ενδοκοινοτικές Παραδόσεις",
        "details1": "Client allowed only Companies within E.U. (except Greece)"
    },
    {
        "id": "1.3",
        "myCodeData": "1.3",
        "name": "Τιμολόγιο Πώλησης/ Παραδόσεις Τρίτων Χωρών",
        "details1": "Client allowed only Companies outside E.U."
    },
    {
        "id": "1.4",
        "myCodeData": "1.4",
        "name": "Τιμολόγιο Πώλησης/ Πώληση για Λογαριασμό Τρίτων",
        "details1": "Client allowed only Greek Companies -wholesale (all client Categories except \"Retail\")"
    },
    {
        "id": "1.5",
        "myCodeData": "1.5",
        "name": "Τιμολόγιο Πώλησης/ Εκκαθάριση Πωλήσεων Τρίτων- Αμοιβή από Πωλήσεις Τρίτων",
        "details1": "Client allowed only Greek Companies -wholesale (all client Categories except \"Retail\")"
    },
    {
        "id": "1.6",
        "myCodeData": "1.6",
        "name": "Τιμολόγιο Πώλησης/ Συμπληρωματικό Παραστατικό",
        "details1": "Client allowed only Greek Companies -wholesale (all client Categories except \"Retail\")",
        "details3": "Supports correlated sale"
    },
    {
        "id": "2.1",
        "conflictingDocumentsDomestic": "Τιμολόγιο Παροχής Υπηρεσιών",
        "myCodeData": "2.1",
        "name": "Τιμολόγιο Παροχής",
        "details1": "Client allowed only Greek Companies -wholesale (all client Categories except \"Retail\")"
    },
    {
        "id": "2.2",
        "myCodeData": "2.2",
        "name": "Τιμολόγιο Παροχής/ Ενδοκοινοτική Παροχή Υπηρεσιών",
        "details1": "Client allowed only Companies within E.U. (except Greece)"
    },
    {
        "id": "2.3",
        "myCodeData": "2.3",
        "name": "Τιμολόγιο Παροχής/ Παροχή Υπηρεσιών σε λήπτη Τρίτης Χώρας",
        "details1": "Client allowed only Companies outside E.U."
    },
    {
        "id": "2.4",
        "myCodeData": "2.4",
        "name": "Τιμολόγιο Παροχής / Συμπληρωματικό Παραστατικό",
        "details1": "Client allowed only Greek Companies -wholesale (all client Categories except \"Retail\")",
        "details3": "Supports correlated sale"
    },
    {
        "id": "3.1",
        "conflictingDocumentsDomestic": "Τίτλος Κτήσης",
        "myCodeData": "3.1",
        "name": "Τίτλος Κτήσης ( Μη υπόχρεος Εκδότης)",
        "details1": "Client allowed only Greek Companies"
    },
    {
        "id": "3.2",
        "myCodeData": "3.2",
        "name": "Τίτλος κτήσης (άρνηση χρέωσης από υπόχρεο Εκδότη)",
        "details1": "Client allowed only Greek Companies"
    },
    {
        "id": "5.1",
        "conflictingDocumentsDomestic": "Πιστωτικό Τιμολόγιο",
        "myCodeData": "5.1",
        "name": "Πιστωτικό Τιμολόγιο /Συσχετιζόμενο",
        "details1": "Client allowed only Greek Companies",
        "details2": "Credit Document",
        "details3": "Supports correlated sale"
    },
    {
        "id": "5.2",
        "myCodeData": "5.2",
        "name": "Πιστωτικό Τιμολόγιο /Μη Συσχετιζόμενο",
        "details1": "Client allowed only Greek Companies",
        "details2": "Credit Document"
    },
    {
        "id": "6.1",
        "conflictingDocumentsDomestic": "Στοιχείο Αυτοπαράδοσης -   Ιδιοχρησιμοποίησης",
        "myCodeData": "6.1",
        "name": "Στοιχείο Αυτοπαράδοσης",
        "details1": "Client is not allowed. Client and issuer is the same."
    },
    {
        "id": "6.2",
        "myCodeData": "6.2",
        "name": "Στοιχείο Ιδιοχρησιμοποίησης",
        "details1": "Client is not allowed. Client and issuer is the same."
    },
    {
        "id": "7.1",
        "conflictingDocumentsDomestic": "Συμβόλαιο/Έσοδο",
        "myCodeData": "7.1",
        "name": "Συμβόλαιο/Έσοδο",
        "details1": "All clients allowed"
    },
    {
        "id": "8.1",
        "conflictingDocumentsDomestic": "Ειδικό Στοιχείο (Έσοδο) - Απόδειξη Είσπραξης",
        "myCodeData": "8.1",
        "name": "Ενοίκια-Έσοδο",
        "details1": "All clients allowed"
    },
    {
        "id": "8.2",
        "myCodeData": "8.2",
        "name": "Τέλος ανθεκτικότητας κλιματικής κρίσης",
        "details1": "Client allowed only Greek Companies"
    },
    {
        "id": "9.3",
        "myCodeData": "9.3",
        "name": "Παραστατικό Διακίνησης Μη Συσχετιζόμενο",
        "details1": "All clients allowed"
    },
    {
        "id": "11.1",
        "conflictingDocumentsDomestic": "Παραστατικά Λιανικής",
        "myCodeData": "11.1",
        "name": "ΑΛΠ",
        "details1": "Client allowed only Greek Companies -retail (only client category \"Retail\")"
    },
    {
        "id": "11.2",
        "myCodeData": "11.2",
        "name": "ΑΠΥ",
        "details1": "Client allowed only Greek Companies -retail (only client category \"Retail\")"
    },
    {
        "id": "11.3",
        "myCodeData": "11.3",
        "name": "Απλοποιημένο Τιμολόγιο",
        "details1": "Client allowed only Greek Companies -retail (only client category \"Retail\")"
    },
    {
        "id": "11.4",
        "myCodeData": "11.4",
        "name": "Πιστωτικό Στοιχ. Λιανικής",
        "details1": "Client allowed only Greek Companies -retail (only client category \"Retail\")",
        "details2": "Credit Document"
    },
    {
        "id": "11.5",
        "myCodeData": "11.5",
        "name": "Απόδειξη Λιανικής πώλησης για Λογ/σμο Τρίτων",
        "details1": "Client allowed only Greek Companies -retail (only client category \"Retail\")"
    },
    {
        "id": "13.1",
        "conflictingDocumentsDomestic": "Λήψη Παραστατικών Λιανικής",
        "myCodeData": "13.1",
        "name": "Έξοδα- Αγορές Λιανικών Συναλλαγών  ημεδαπής/αλλοδαπής"
    },
    {
        "id": "13.2",
        "myCodeData": "13.2",
        "name": "Παροχή Λιανικών Συναλλαγών ημεδαπής/αλλοδαπής"
    },
    {
        "id": "13.3",
        "myCodeData": "13.3",
        "name": "Κοινόχρηστα"
    },
    {
        "id": "13.4",
        "myCodeData": "13.4",
        "name": "Συνδρομές"
    },
    {
        "id": "13.30",
        "myCodeData": "13.30",
        "name": "Παραστατικά Οντότητας ως Αναγράφονται από την ίδια (Δυναμικό)"
    },
    {
        "id": "13.31",
        "myCodeData": "13.31",
        "name": "Πιστωτικό Στοιχ. Λιανικής ημεδαπής/αλλοδαπής"
    },
    {
        "id": "14.1",
        "conflictingDocumentsDomestic": "Παραστ. Εξαιρ. Οντοτήτων ημεδαπής/αλλοδαπής",
        "myCodeData": "14.1",
        "name": "Τιμολόγιο/Ενδοκοινοτικές  Αποκτήσεις"
    },
    {
        "id": "14.2",
        "myCodeData": "14.2",
        "name": "Τιμολόγιο Αποκτήσεις Τρίτων Χωρών"
    },
    {
        "id": "14.3",
        "myCodeData": "14.3",
        "name": "Τιμολόγιο /Ενδοκοινοτική Λήψη Υπηρεσιών"
    },
    {
        "id": "14.4",
        "myCodeData": "14.4",
        "name": "Τιμολόγιο / Λήψη Υπηρεσιών Τρίτων Χωρών"
    },
    {
        "id": "14.5",
        "myCodeData": "14.5",
        "name": "ΕΦΚΑ και λοιποί Ασφαλιστικοί Οργανισμοί"
    },
    {
        "id": "14.30",
        "myCodeData": "14.30",
        "name": "Παραστατικά Οντότητας ως Αναγράφονται από την ίδια (Δυναμικό)"
    },
    {
        "id": "14.31",
        "myCodeData": "14.31",
        "name": "Πιστωτικό ημεδαπής/αλλοδαπής"
    },
    {
        "id": "15.1",
        "conflictingDocumentsDomestic": "Συμβόλαιο Έξοδο",
        "myCodeData": "15.1",
        "name": "Συμβόλαιο- Έξοδο"
    },
    {
        "id": "16.1",
        "conflictingDocumentsDomestic": "Ειδικό Στοιχείο (Έξοδο)- Απόδειξη Πληρωμής",
        "myCodeData": "16.1",
        "name": "Ενοίκιο Έξοδο"
    },
    {
        "id": "17.1",
        "conflictingDocumentsDomestic": "Εγγραφές Οντότητας",
        "myCodeData": "17.1",
        "name": "Μισθοδοσία "
    },
    {
        "id": "17.2",
        "myCodeData": "17.2",
        "name": "Αποσβέσεις"
    },
    {
        "id": "17.3",
        "myCodeData": "17.3",
        "name": "Λοιπές Εγγραφές Τακτοποίησης Εσόδων - Λογιστική Βάση"
    },
    {
        "id": "17.4",
        "myCodeData": "17.4",
        "name": "Λοιπές Εγγραφές Τακτοποίησης Εσόδων - Φορολογική Βάση"
    },
    {
        "id": "17.5",
        "myCodeData": "17.5",
        "name": "Λοιπές Εγγραφές Τακτοποίησης Εξόδων - Λογιστική Βάση"
    },
    {
        "id": "17.6",
        "myCodeData": "17.6",
        "name": "Λοιπές Εγγραφές Τακτοποίησης Εξόδων - Φορολογική Βάση"
    }
]

export const clientPaymentInvoiceTypesProvider = [
    {
        "id": "8.4",
        "myCodeData": "8.4",
        "name": "Απόδειξη Είσπραξης POS",
        "details1": ""
    },
    {
        "id": "8.5",
        "myCodeData": "8.5",
        "name": "Απόδειξη Επιστροφής POS",
        "details1": ""
    },
]
