// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.employeeProgramTable tr td, .employeeProgramTable tr th {
    border: 1px solid gray;
}

.employeeProgramTable tr th {
    border: 1px solid gray;
}

.employeeProgramTable {
    width: 100%;
    table-layout: fixed;
    text-align: center;
    border-collapse: collapse;
}`, "",{"version":3,"sources":["webpack://./src/_components/layouts/data-panel/Payroll/Components/tableStyle.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;AAC7B","sourcesContent":[".employeeProgramTable tr td, .employeeProgramTable tr th {\r\n    border: 1px solid gray;\r\n}\r\n\r\n.employeeProgramTable tr th {\r\n    border: 1px solid gray;\r\n}\r\n\r\n.employeeProgramTable {\r\n    width: 100%;\r\n    table-layout: fixed;\r\n    text-align: center;\r\n    border-collapse: collapse;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
