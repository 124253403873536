import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {Badge, Button, Col, Row, Spinner} from "react-bootstrap";
import {
    setPayrollHourReportDisplayData,
    setPayrollHourReportRequestData,
    setPayrollHourReportResponseData
} from "../../../../_reducers/DataPanel/Payroll/payrollSlice";
import {classicStyleBelowNavbar} from "../Statics";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Input from "../../../common/Input";
import {formatEmployeeIdentifier, getHourIntervals, greekMonthOptions} from "./Components/constants";
import Dropdown from "../../../primer/Dropdown";
import moment from "moment";
import {getEmployeeCategoryOptions, isEmpty} from "../../../../_helpers/commonFunctions";
import BetterCheckBox from "../../../common/BetterCheckBox";
import "./style.css";
import EmployeeInfoModal from "./Components/employee-info-modal";

const PayrollHourReport = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const requestData = useSelector((state) => state.PAYROLL.hourReportRequestData);
    const responseData = useSelector((state) => state.PAYROLL.hourReportResponseData);
    const displayData = useSelector((state) => state.PAYROLL.hourReportDisplayData);

    const [loading, setLoading] = useState(false);
    const [updateDisplayData, setUpdateDisplayData] = useState(false);

    // Employee Modal
    const [showEmployeeInfoModal, setShowEmployeeInfoModal] = useState(false);
    const [employeeInfoModalId, setEmployeeInfoModalId] = useState("");

    useEffect(() => {
        if (isEmpty(requestData.month)) {
            const month = moment().format("MM");
            const date_from = moment(`15/${month}/${company.year}`, "DD/MM/YYYY").startOf("month").format("DD/MM/YYYY");
            const date_to = moment(`15/${month}/${company.year}`, "DD/MM/YYYY").endOf("month").format("DD/MM/YYYY");
            dispatch(setPayrollHourReportRequestData({...requestData, month: month, date_from: date_from, date_to: date_to}))
        }
    }, [requestData.month])

    useEffect(() => {
        if (updateDisplayData) {
            setUpdateDisplayData(false);
            setDisplayData("auto");
        }
    }, [updateDisplayData])

    const fetchReport = () => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/fetch-hour-report`, {
            company: company.id,
            ...requestData,
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                toast.success("Τα δεδομένα ανανεώθηκαν.");
                dispatch(setPayrollHourReportResponseData({...responseData, data: res.data.data}));
                setUpdateDisplayData(true);
            } else {
                toast.error(res.data.message);
                dispatch(setPayrollHourReportResponseData({...responseData, data: []}));
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const formatFromText = (val) => {
        if (val === "card") {
            return "Από κάρτα εργασίας";
        } else if (val === "program") {
            return "Από πρόγραμμα εργασίας";
        } else {
            return val;
        }
    }

    const getDayNightSundayHours = (data) => {
        const employeeMonthlyData = structuredClone(data);
        const resData = {
            totals: {
                enshma: 0, // ????
                daysWorked: 0,
                hoursWorked: 0,
            },
            working: {
                day: 0,
                night: 0,
                daySunday: 0,
                nightSunday: 0,
            },
            permits: {
                day: 0,
                night: 0,
                daySunday: 0,
                nightSunday: 0,

                totalDays: 0,
                totalHours: 0,
            },
            overwork: {
                day: 0,
                night: 0,
                daySunday: 0,
                nightSunday: 0,

                totalHours: 0,
            },
            overtime: {
                day: 0,
                night: 0,
                daySunday: 0,
                nightSunday: 0,

                totalHours: 0,
            },
        }
        const uniqueDays = [];
        const uniquePermitDays = [];
        for (let dcData of employeeMonthlyData) {
            if (!uniqueDays.includes(dcData.date)) uniqueDays.push(dcData.date);

            const hoursWorked = getHourIntervals(dcData.hourFrom, dcData.hourTo);
            const dayHours = hoursWorked.dayHours;
            const nightHours = hoursWorked.nightHours;

            const isSunday = moment(dcData.date, "DD/MM/YYYY").day() === 0;
            const isPermit = dcData?.permit === true;

            if (isPermit) {
                if (!uniquePermitDays.includes(dcData.date)) uniquePermitDays.push(dcData.date);
                if (isSunday) {
                    resData.permits.daySunday += dayHours;
                    resData.permits.nightSunday += nightHours;
                } else {
                    resData.permits.day += dayHours;
                    resData.permits.night += nightHours;
                }
            } else {
                const totalHours = +Number(dayHours + nightHours).toFixed(2);
                resData.totals.hoursWorked += totalHours;
                let overwork = 0;
                let overtime = 0;
                let overworkNight = false;
                let overtimeNight = false;

                if (dcData?.workingDaysPerWeek === "5") {
                    if (totalHours <= 9 && totalHours > 8) {
                        overwork = totalHours - 8;
                        if (dayHours <= 8) overworkNight = true;
                    } else if (totalHours > 9) {
                        overwork = 1;
                        overtime = totalHours - 9;
                        if (dayHours <= 9) overtimeNight = true;
                    }
                } else if (dcData?.workingDaysPerWeek === "6") {
                    if (totalHours > 6.4) {
                        if (totalHours <= 8 && totalHours > 6.4) {
                            overwork = totalHours - 6.4;
                            if (dayHours <= 6.4) overworkNight = true;
                        } else if (totalHours > 8) {
                            overwork = 1.6;
                            overtime = totalHours - 8;
                            if (dayHours <= 8) overtimeNight = true;
                        }
                    }
                }
                overwork = +Number(overwork).toFixed(2);
                overtime = +Number(overtime).toFixed(2);

                if (isSunday) {
                    resData.working.daySunday += dayHours;
                    resData.working.nightSunday += nightHours;

                    if (overwork && !overworkNight) resData.overwork.daySunday += overwork;
                    if (overwork && overworkNight) resData.overwork.nightSunday += overwork;
                    if (overtime && !overtimeNight) resData.overtime.daySunday += overtime;
                    if (overtime && overtimeNight) resData.overtime.nightSunday += overtime;
                } else {
                    resData.working.day += dayHours;
                    resData.working.night += nightHours;

                    if (overwork && !overworkNight) resData.overwork.day += overwork;
                    if (overwork && overworkNight) resData.overwork.night += overwork;
                    if (overtime && !overtimeNight) resData.overtime.day += overtime;
                    if (overtime && overtimeNight) resData.overtime.night += overtime;
                }
                if (overwork) resData.overwork.totalHours += overwork;
                if (overtime) resData.overtime.totalHours += overtime;
            }
        }
        resData.working.day = resData.working.day - resData.overwork.day - resData.overtime.day;
        resData.working.night = resData.working.night - resData.overwork.night - resData.overtime.night;
        resData.working.daySunday = resData.working.daySunday - resData.overwork.daySunday - resData.overtime.daySunday;
        resData.working.nightSunday = resData.working.nightSunday - resData.overwork.nightSunday - resData.overtime.nightSunday;

        resData.totals.daysWorked = uniqueDays.length;

        resData.permits.totalDays = uniquePermitDays.length;
        resData.permits.totalHours = resData.permits.day + resData.permits.night + resData.permits.daySunday + resData.permits.nightSunday;

        for (let majorKey in resData) {
            for (let innerKey in resData[majorKey]) {
                resData[majorKey][innerKey] = +Number(resData[majorKey][innerKey]).toFixed(2);
            }
        }

        return resData;
    }

    const setDisplayData = (dayAnalysis) => {
        setLoading(true);
        if (dayAnalysis === "auto") dayAnalysis = displayData.dayAnalysis;

        if (dayAnalysis) {
            dispatch(setPayrollHourReportDisplayData({...displayData, page: 1, data: responseData.data, totalPages: Math.ceil(responseData.data.length / 31), dayAnalysis: dayAnalysis}));
        } else {
            let employeesDone = [];
            let resData = [];
            for (let rowData of responseData.data) {
                const split = String(rowData.employeeIdentifier).split("|");
                const firstName = split[0];
                const lastName = split[1];
                const vatNum = split[2];
                if (vatNum) {
                    if (employeesDone.includes(vatNum)) continue;
                    employeesDone.push(vatNum);
                    const findMatchingMonthlyData = responseData.data.filter((el) => String(el.vatNumber) === String(vatNum));
                    resData.push({
                        _id: isEmpty(rowData._id) ? null : rowData._id,
                        no: String(resData.length + 1),
                        employee: `${firstName} ${lastName}`,
                        employeeType: "",
                        salary: 0,
                        employeeCategory: rowData.employeeCategory,
                        ...getDayNightSundayHours(findMatchingMonthlyData),
                    })
                }
            }
            dispatch(setPayrollHourReportDisplayData({...displayData, page: 1, data: resData, totalPages: Math.ceil(resData.length / 31), dayAnalysis: dayAnalysis}));
        }
        setTimeout(() => {
            setLoading(false);
        })
    }

    const thickBorderStyle = "2px solid #76aeb7";

    const aroundStyle = {
        borderTop: thickBorderStyle,
        borderBottom: thickBorderStyle
    }
    const rightStyle = {
        borderRight: thickBorderStyle
    }
    const dayStyle = {
        backgroundColor: "#d4d2fc",
    }
    const nightStyle = {
        backgroundColor: "#ffd6d8",
    }
    const totalStyle = {
        backgroundColor: "#edfcdc",
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row className={"mb-2"}>
                <Col md={3}>
                    <Dropdown
                        name={"monthSelection"}
                        options={greekMonthOptions}
                        label={"Επιλογή μηνός"}
                        defaultValue={greekMonthOptions.find((el) => el.value === requestData.month)}
                        key={Math.random()}
                        onChange={(e) => {
                            const month = e.value;
                            const date_from = moment(`15/${month}/${company.year}`, "DD/MM/YYYY").startOf("month").format("DD/MM/YYYY");
                            const date_to = moment(`15/${month}/${company.year}`, "DD/MM/YYYY").endOf("month").format("DD/MM/YYYY");
                            dispatch(setPayrollHourReportRequestData({...requestData, month: month, date_from: date_from, date_to: date_to}))
                        }}
                    />
                </Col>
                <Col md={2}>
                    <Input
                        name={"currentYear"}
                        label={"Χρονιά"}
                        defaultValue={company.year}
                        disabled={true}
                    />
                </Col>
                <Col md={7}>
                    <Button size={"sm"} onClick={() => fetchReport()} disabled={loading} style={{marginTop: "25px"}}>
                        Ανάκτηση δεδομένων από το Π.Σ. Εργάνη
                        {loading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                    </Button>
                </Col>
            </Row>
            {displayData.data.length > 0 && (
                <React.Fragment>
                    <Row>
                        <Col md={2}>
                            <BetterCheckBox
                                name={"dayAnalysis"}
                                text={"Ανάλυση ημερών"}
                                className={"mt-4"}
                                checked={displayData.dayAnalysis}
                                onChange={(e) => setDisplayData(e.target.checked)}
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                name={"employeeFilter"}
                                label={"Φιλτράρισμα εργαζόμενου"}
                                autoComplete={"off"}
                                defaultValue={displayData.employeeFilter}
                                onBlur={(e) => dispatch(setPayrollHourReportDisplayData({...displayData, employeeFilter: e.target.value}))}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="d-flex justify-content-end mb-2">
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => dispatch(setPayrollHourReportDisplayData({...displayData, page: 1}))}
                                    disabled={displayData.page === 1 || loading}
                                >
                                    {t('SalesBalanceSheet.first')}
                                </Button>
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => dispatch(setPayrollHourReportDisplayData({
                                        ...displayData,
                                        page: displayData.page - 1
                                    }))}
                                    disabled={displayData.page === 1 || loading}
                                >
                                    {t('SalesBalanceSheet.previous')}
                                </Button>
                                <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1"
                                       bg="primary">{t('SalesBalanceSheet.page')} {displayData.page} / {displayData.totalPages}</Badge>
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => dispatch(setPayrollHourReportDisplayData({
                                        ...displayData,
                                        page: displayData.page + 1
                                    }))}
                                    disabled={displayData.page === displayData.totalPages || loading}
                                >
                                    {t('SalesBalanceSheet.next')}
                                </Button>
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => dispatch(setPayrollHourReportDisplayData({
                                        ...displayData,
                                        page: displayData.totalPages
                                    }))}
                                    disabled={displayData.page === displayData.totalPages || loading}
                                >
                                    {t('SalesBalanceSheet.last')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    {!loading && (
                        <React.Fragment>
                            {displayData.dayAnalysis ? (
                                <Row>
                                    <Col md={12}>
                                        <table className={"simpleClassicTable"} style={{fontSize: "16px"}}>
                                            <colgroup>
                                                <col span={1} style={{width: "15%"}}></col>
                                                <col span={1} style={{width: "35%"}}></col>
                                                <col span={1} style={{width: "15%"}}></col>
                                                <col span={1} style={{width: "15%"}}></col>
                                                <col span={1} style={{width: "20%"}}></col>
                                            </colgroup>
                                            <thead>
                                            <tr>
                                                <th>Ημερομηνία</th>
                                                <th>Εργαζόμενος</th>
                                                <th>Ώρα από</th>
                                                <th>Ώρα έως</th>
                                                <th>Από</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {displayData.data.slice((displayData.page - 1) * 31, displayData.page * 31).map((row, idx) => (
                                                <tr key={`phr-${idx}`}>
                                                    <td>{row["date"]}</td>
                                                    <td>{formatEmployeeIdentifier(row["employeeIdentifier"])}</td>
                                                    <td>{row["hourFrom"]}</td>
                                                    <td>{row["hourTo"]}</td>
                                                    <td>{formatFromText(row["from"])}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            ) : (
                                <Row>
                                    <Col md={12}>
                                        <table className={"simpleClassicTable hourReportTable"} style={{fontSize: "12px", borderLeft: thickBorderStyle, borderRight: thickBorderStyle, wordWrap: "break-word"}}>
                                            <colgroup>
                                                <col span={1} style={{width: "35px", ...aroundStyle}}></col>
                                                <col span={1} style={{width: "150px", ...aroundStyle}}></col>
                                                <col span={2} style={{width: "15%", ...aroundStyle}}></col>

                                                <col span={3} style={{width: "15%", ...aroundStyle}}></col>

                                                <col span={4} style={{width: "15%", ...aroundStyle}}></col>

                                                <col span={6} style={{width: "15%", ...aroundStyle}}></col>

                                                <col span={5} style={{width: "15%", ...aroundStyle}}></col>

                                                <col span={5} style={{width: "15%", ...aroundStyle}}></col>
                                            </colgroup>
                                            <thead>
                                            <tr>
                                                <th colSpan={4} style={rightStyle}>Στοιχεία Εργαζομένων</th>
                                                <th colSpan={3} style={rightStyle}>Σύνολα</th>
                                                <th colSpan={4} style={rightStyle}>Ώρες Εργασίας</th>
                                                <th colSpan={6} style={rightStyle}>Ώρες Αδείας</th>
                                                <th colSpan={5} style={rightStyle}>Ώρες Υπερεργασίας</th>
                                                <th colSpan={5} style={rightStyle}>Ώρες Υπερωρίας</th>
                                            </tr>
                                            <tr>
                                                <th>A/A</th>
                                                <th>Εργαζόμενος</th>
                                                <th>Τύπος Εργ.</th>
                                                <th style={rightStyle}>Μισθός</th>

                                                <th>Ένσημα</th>
                                                <th>Ημέρες Εργ.</th>
                                                <th style={rightStyle}>Ώρες Εργ.</th>

                                                <th>ΗΜΕΡΑ</th>
                                                <th>ΝΥΧΤΑ</th>
                                                <th>ΗΜΕΡΑ ΚΥΡ.</th>
                                                <th style={rightStyle}>ΝΥΧΤΑ ΚΥΡ.</th>

                                                <th>ΗΜΕΡΑ</th>
                                                <th>ΝΥΧΤΑ</th>
                                                <th>ΗΜΕΡΑ ΚΥΡ.</th>
                                                <th>ΝΥΧΤΑ ΚΥΡ.</th>
                                                <th>Συν. Ημ.</th>
                                                <th style={rightStyle}>Συν. Ωρ.</th>

                                                <th>ΗΜΕΡΑ</th>
                                                <th>ΝΥΧΤΑ</th>
                                                <th>ΗΜΕΡΑ ΚΥΡ.</th>
                                                <th>ΝΥΧΤΑ ΚΥΡ.</th>
                                                <th style={rightStyle}>Σύνολο</th>

                                                <th>ΗΜΕΡΑ</th>
                                                <th>ΝΥΧΤΑ</th>
                                                <th>ΗΜΕΡΑ ΚΥΡ.</th>
                                                <th>ΝΥΧΤΑ ΚΥΡ.</th>
                                                <th style={rightStyle}>Σύνολο</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {displayData.data.slice((displayData.page - 1) * 31, displayData.page * 31).map((row, idx) => (
                                                <tr key={`phr-${idx}`} style={{cursor: "pointer"}} onClick={() => {
                                                    if (row._id) {
                                                        setEmployeeInfoModalId(row._id);
                                                        setShowEmployeeInfoModal(true);
                                                    }
                                                }}>
                                                    <td>{row["no"]}</td>
                                                    <td>{row["employee"]}</td>
                                                    <td>{getEmployeeCategoryOptions().find((el) => el.value === row["employeeCategory"])?.label}</td>
                                                    <td style={rightStyle}>{row["salary"]}</td>

                                                    <td style={totalStyle}>{row["totals"]["enshma"]}</td>
                                                    <td style={totalStyle}>{row["totals"]["daysWorked"]}</td>
                                                    <td style={{...rightStyle, ...totalStyle}}>{row["totals"]["hoursWorked"]}</td>

                                                    <td style={dayStyle}>{row["working"]["day"]}</td>
                                                    <td style={nightStyle}>{row["working"]["night"]}</td>
                                                    <td style={dayStyle}>{row["working"]["daySunday"]}</td>
                                                    <td style={{...nightStyle, ...rightStyle}}>{row["working"]["nightSunday"]}</td>

                                                    <td style={dayStyle}>{row["permits"]["day"]}</td>
                                                    <td style={nightStyle}>{row["permits"]["night"]}</td>
                                                    <td style={dayStyle}>{row["permits"]["daySunday"]}</td>
                                                    <td style={nightStyle}>{row["permits"]["nightSunday"]}</td>
                                                    <td style={totalStyle}>{row["permits"]["totalDays"]}</td>
                                                    <td style={{...rightStyle, ...totalStyle}}>{row["permits"]["totalHours"]}</td>

                                                    <td style={dayStyle}>{row["overwork"]["day"]}</td>
                                                    <td style={nightStyle}>{row["overwork"]["night"]}</td>
                                                    <td style={dayStyle}>{row["overwork"]["daySunday"]}</td>
                                                    <td style={nightStyle}>{row["overwork"]["nightSunday"]}</td>
                                                    <td style={{...rightStyle, ...totalStyle}}>{row["overwork"]["totalHours"]}</td>

                                                    <td style={dayStyle}>{row["overtime"]["day"]}</td>
                                                    <td style={nightStyle}>{row["overtime"]["night"]}</td>
                                                    <td style={dayStyle}>{row["overtime"]["daySunday"]}</td>
                                                    <td style={nightStyle}>{row["overtime"]["nightSunday"]}</td>
                                                    <td style={{...rightStyle, ...totalStyle}}>{row["overtime"]["totalHours"]}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            )}
                            <EmployeeInfoModal
                                employeeId={employeeInfoModalId}
                                show={showEmployeeInfoModal}
                                setShow={setShowEmployeeInfoModal}
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </div>
    )
}

export default PayrollHourReport;
