export const DoyOptions = [
    {
        value: 1101,
        label: "ΑΘΗΝΩΝ Α'"
    },
    {
        value: 1104,
        label: "ΑΘΗΝΩΝ Δ'"
    },
    {
        value: 1105,
        label: "ΑΘΗΝΩΝ Ε'"
    },
    {
        value: 1106,
        label: "ΑΘΗΝΩΝ ΣΤ'"
    },
    {
        value: 1110,
        label: "ΑΘΗΝΩΝ Ι'"
    },
    {
        value: 1111,
        label: "ΑΘΗΝΩΝ ΙΑ'"
    },
    {
        value: 1112,
        label: "ΑΘΗΝΩΝ ΙΒ'"
    },
    {
        value: 1113,
        label: "ΑΘΗΝΩΝ ΙΓ'"
    },
    {
        value: 1114,
        label: "ΑΘΗΝΩΝ ΙΔ'"
    },
    {
        value: 1115,
        label: "ΑΘΗΝΩΝ ΙΕ'"
    },
    {
        value: 1116,
        label: "ΑΘΗΝΩΝ ΙΣΤ'"
    },
    {
        value: 1117,
        label: "ΑΘΗΝΩΝ ΙΖ'"
    },
    {
        value: 1118,
        label: "ΑΘΗΝΩΝ ΦΑΒΕ"
    },
    {
        value: 1124,
        label: "ΑΘΗΝΩΝ ΙΗ'"
    },
    {
        value: 1125,
        label: "ΚΑΤΟΙΚΩΝ ΕΞΩΤΕΡΙΚΟΥ"
    },
    {
        value: 1126,
        label: "ΑΘΗΝΩΝ ΙΘ'"
    },
    {
        value: 1129,
        label: "  ΑΓ. ΔΗΜΗΤΡΙΟΥ"
    },
    {
        value: 1130,
        label: "ΚΑΛΛΙΘΕΑΣ Α'"
    },
    {
        value: 1131,
        label: "ΝΕΑΣ ΙΩΝΙΑΣ"
    },
    {
        value: 1132,
        label: "ΝΕΑΣ ΣΜΥΡΝΗΣ"
    },
    {
        value: 1133,
        label: "ΠΑΛΑΙΟΥ ΦΑΛΗΡΟΥ"
    },
    {
        value: 1134,
        label: "ΧΑΛΑΝΔΡΙΟΥ"
    },
    {
        value: 1135,
        label: "ΑΜΑΡΟΥΣΙΟΥ"
    },
    {
        value: 1136,
        label: "ΑΓΙΩΝ ΑΝΑΡΓΥΡΩΝ"
    },
    {
        value: 1137,
        label: "ΑΙΓΑΛΕΩ"
    },
    {
        value: 1138,
        label: "  ΠΕΡΙΣΤΕΡΙΟΥ Α'"
    },
    {
        value: 1139,
        label: "  ΓΛΥΦΑΔΑΣ"
    },
    {
        value: 1140,
        label: "ΑΘΗΝΩΝ Κ'"
    },
    {
        value: 1141,
        label: "  ΑΘΗΝΩΝ ΚΑ'"
    },
    {
        value: 1142,
        label: "ΑΘΗΝΩΝ ΚΒ'"
    },
    {
        value: 1143,
        label: "ΑΘΗΝΩΝ ΚΓ'"
    },
    {
        value: 1144,
        label: "ΔΑΦΝΗΣ"
    },
    {
        value: 1145,
        label: "ΗΡΑΚΛΕΙΟΥ ΑΤΤΙΚΗΣ"
    },
    {
        value: 1151,
        label: "ΑΓΙΑΣ ΠΑΡΑΣΚΕΥΗΣ"
    },
    {
        value: 1152,
        label: "ΒΥΡΩΝΑ"
    },
    {
        value: 1153,
        label: "ΚΗΦΙΣΙΑΣ"
    },
    {
        value: 1154,
        label: "ΙΛΙΟΥ"
    },
    {
        value: 1155,
        label: "ΝΕΑΣ ΦΙΛΑΔΕΛΦΕΙΑΣ"
    },
    {
        value: 1156,
        label: "ΧΑΙΔΑΡΙΟΥ"
    },
    {
        value: 1157,
        label: "ΠΕΡΙΣΤΕΡΙΟΥ Β'"
    },
    {
        value: 1159,
        label: "ΑΘΗΝΩΝ ΦΑΕΕ"
    },
    {
        value: 1172,
        label: "ΖΩΓΡΑΦΟΥ"
    },
    {
        value: 1173,
        label: "ΗΛΙΟΥΠΟΛΗΣ"
    },
    {
        value: 1174,
        label: "ΚΑΛΛΙΘΕΑΣ Β'"
    },
    {
        value: 1175,
        label: "ΨΥΧΙΚΟΥ"
    },
    {
        value: 1176,
        label: "ΧΟΛΑΡΓΟΥ"
    },
    {
        value: 1177,
        label: "ΑΡΓΥΡΟΥΠΟΛΗΣ"
    },
    {
        value: 1178,
        label: "ΠΕΤΡΟΥΠΟΛΗΣ"
    },
    {
        value: 1179,
        label: "ΓΑΛΑΤΣΙΟΥ"
    },
    {
        value: 1180,
        label: "ΑΝΩ ΛΙΟΣΙΩΝ"
    },
    {
        value: 1190,
        label: "ΚΕΦΟΔΕ ΑΤΤΙΚΗΣ",
    },
    {
        value: 1201,
        label: "ΠΕΙΡΑΙΑ Α'"
    },
    {
        value: 1203,
        label: "ΠΕΙΡΑΙΑ Γ'"
    },
    {
        value: 1204,
        label: "ΠΕΙΡΑΙΑ Δ'"
    },
    {
        value: 1205,
        label: "ΠΕΙΡΑΙΑ Ε'"
    },
    {
        value: 1206,
        label: "  ΠΕΙΡΑΙΑ ΦΑΕ"
    },
    {
        value: 1207,
        label: "ΠΕΙΡΑΙΑ ΠΛΟΙΩΝ"
    },
    {
        value: 1209,
        label: "ΠΕΙΡΑΙΑ ΣΤ'"
    },
    {
        value: 1210,
        label: "ΚΟΡΥΔΑΛΛΟΥ"
    },
    {
        value: 1211,
        label: "ΜΟΣΧΑΤΟΥ"
    },
    {
        value: 1220,
        label: "ΝΙΚΑΙΑΣ"
    },
    {
        value: 1301,
        label: "ΑΙΓΙΝΑΣ"
    },
    {
        value: 1302,
        label: "ΑΧΑΡΝΩΝ"
    },
    {
        value: 1303,
        label: "ΕΛΕΥΣΙΝΑΣ"
    },
    {
        value: 1304,
        label: "ΚΟΡΩΠΙΟΥ"
    },
    {
        value: 1305,
        label: "ΚΥΘΗΡΩΝ"
    },
    {
        value: 1306,
        label: "ΛΑΥΡΙΟΥ"
    },
    {
        value: 1307,
        label: "ΑΓΙΟΥ ΣΤΕΦΑΝΟΥ"
    },
    {
        value: 1308,
        label: "ΜΕΓΑΡΩΝ"
    },
    {
        value: 1309,
        label: "ΣΑΛΑΜΙΝΑΣ"
    },
    {
        value: 1310,
        label: "ΠΟΡΟΥ"
    },
    {
        value: 1311,
        label: "ΥΔΡΑΣ"
    },
    {
        value: 1312,
        label: "ΠΑΛΛΗΝΗΣ"
    },
    {
        value: 1411,
        label: "ΘΗΒΑΣ"
    },
    {
        value: 1421,
        label: "ΛΕΙΒΑΔΙΑΣ"
    },
    {
        value: 1511,
        label: "ΑΜΦΙΛΟΧΙΑΣ"
    },
    {
        value: 1521,
        label: "ΑΣΤΑΚΟΥ"
    },
    {
        value: 1522,
        label: "ΒΟΝΙΤΣΑΣ"
    },
    {
        value: 1531,
        label: "ΜΕΣΟΛΟΓΓΙΟΥ"
    },
    {
        value: 1541,
        label: "ΝΑΥΠΑΚΤΟΥ"
    },
    {
        value: 1551,
        label: "ΘΕΡΜΟΥ"
    },
    {
        value: 1552,
        label: "ΑΓΡΙΝΙΟΥ"
    },
    {
        value: 1611,
        label: "ΚΑΡΠΕΝΗΣΙΟΥ"
    },
    {
        value: 1711,
        label: "ΙΣΤΙΑΙΑΣ"
    },
    {
        value: 1721,
        label: "ΚΑΡΥΣΤΟΥ"
    },
    {
        value: 1722,
        label: "ΚΥΜΗΣ"
    },
    {
        value: 1731,
        label: "ΛΙΜΝΗΣ"
    },
    {
        value: 1732,
        label: "ΧΑΛΚΙΔΑΣ"
    },
    {
        value: 1811,
        label: "ΔΟΜΟΚΟΥ"
    },
    {
        value: 1821,
        label: "ΑΜΦΙΚΛΕΙΑΣ"
    },
    {
        value: 1822,
        label: "ΑΤΑΛΑΝΤΗΣ"
    },
    {
        value: 1831,
        label: "ΜΑΚΡΑΚΩΜΗΣ"
    },
    {
        value: 1832,
        label: "ΛΑΜΙΑΣ"
    },
    {
        value: 1833,
        label: "ΣΤΥΛΙΔΑΣ"
    },
    {
        value: 1911,
        label: "ΛΙΔΟΡΙΚΙΟΥ"
    },
    {
        value: 1912,
        label: "ΑΜΦΙΣΣΑΣ"
    },
    {
        value: 2111,
        label: "ΑΡΓΟΥΣ"
    },
    {
        value: 2121,
        label: "ΣΠΕΤΣΩΝ"
    },
    {
        value: 2122,
        label: "ΚΡΑΝΙΔΙΟΥ"
    },
    {
        value: 2131,
        label: "ΝΑΥΠΛΙΟΥ"
    },
    {
        value: 2211,
        label: "ΔΗΜΗΤΣΑΝΑΣ"
    },
    {
        value: 2213,
        label: "ΛΕΩΝΙΔΙΟΥ"
    },
    {
        value: 2214,
        label: "ΤΡΟΠΑΙΩΝ"
    },
    {
        value: 2221,
        label: "ΠΑΡΑΛΙΟΥ ΑΣΤΡΟΥΣ"
    },
    {
        value: 2231,
        label: "ΤΡΙΠΟΛΗΣ"
    },
    {
        value: 2241,
        label: "ΜΕΓΑΛΟΠΟΛΗΣ"
    },
    {
        value: 2311,
        label: "ΑΙΓΙΟΥ"
    },
    {
        value: 2312,
        label: "ΑΚΡΑΤΑΣ"
    },
    {
        value: 2321,
        label: "ΚΑΛΑΒΡΥΤΩΝ"
    },
    {
        value: 2322,
        label: "ΚΛΕΙΤΟΡΙΑΣ"
    },
    {
        value: 2331,
        label: "ΠΑΤΡΩΝ Α'"
    },
    {
        value: 2332,
        label: "ΠΑΤΡΩΝ Β'"
    },
    {
        value: 2333,
        label: "ΚΑΤΩ ΑΧΑΙΑΣ"
    },
    {
        value: 2334,
        label: "ΠΑΤΡΩΝ Γ'"
    },
    {
        value: 2411,
        label: "ΑΜΑΛΙΑΔΑΣ"
    },
    {
        value: 2412,
        label: "ΠΥΡΓΟΥ"
    },
    {
        value: 2413,
        label: "ΓΑΣΤΟΥΝΗΣ"
    },
    {
        value: 2414,
        label: "ΒΑΡΔΑ"
    },
    {
        value: 2421,
        label: "ΚΡΕΣΤΕΝΩΝ"
    },
    {
        value: 2422,
        label: "ΛΕΧΑΙΝΩΝ"
    },
    {
        value: 2423,
        label: "ΑΝΔΡΙΤΣΑΙΝΑΣ"
    },
    {
        value: 2424,
        label: "ΖΑΧΑΡΩΣ"
    },
    {
        value: 2511,
        label: "ΔΕΡΒΕΝΙΟΥ"
    },
    {
        value: 2512,
        label: "ΚΙΑΤΟΥ"
    },
    {
        value: 2513,
        label: "ΚΟΡΙΝΘΟΥ"
    },
    {
        value: 2514,
        label: "ΝΕΜΕΑΣ"
    },
    {
        value: 2515,
        label: "ΞΥΛΟΚΑΣΤΡΟΥ"
    },
    {
        value: 2611,
        label: "ΓΥΘΕΙΟΥ"
    },
    {
        value: 2621,
        label: "ΜΟΛΑΩΝ"
    },
    {
        value: 2622,
        label: "ΝΕΑΠΟΛΗΣ ΒΟΙΩΝ ΛΑΚΩΝΙΑΣ"
    },
    {
        value: 2630,
        label: "ΣΚΑΛΑ ΛΑΚΩΝΙΑΣ"
    },
    {
        value: 2631,
        label: "ΚΡΟΚΕΩΝ"
    },
    {
        value: 2632,
        label: "ΣΠΑΡΤΗΣ"
    },
    {
        value: 2641,
        label: "ΑΡΕΟΠΟΛΗΣ"
    },
    {
        value: 2711,
        label: "ΚΑΛΑΜΑΤΑΣ"
    },
    {
        value: 2721,
        label: "ΜΕΛΙΓΑΛΑ"
    },
    {
        value: 2722,
        label: "ΜΕΣΣΗΝΗΣ"
    },
    {
        value: 2731,
        label: "ΠΥΛΟΥ"
    },
    {
        value: 2741,
        label: "ΓΑΡΓΑΛΙΑΝΩΝ"
    },
    {
        value: 2742,
        label: "ΚΥΠΑΡΙΣΣΙΑΣ"
    },
    {
        value: 2743,
        label: "ΦΙΛΙΑΤΡΩΝ ΜΕΣΣΗΝΙΑΣ"
    },
    {
        value: 3111,
        label: "ΚΑΡΔΙΤΣΑΣ"
    },
    {
        value: 3112,
        label: "ΜΟΥΖΑΚΙΟΥ"
    },
    {
        value: 3113,
        label: "ΣΟΦΑΔΩΝ"
    },
    {
        value: 3114,
        label: "ΠΑΛΑΜΑ"
    },
    {
        value: 3211,
        label: "ΑΓΙΑΣ"
    },
    {
        value: 3221,
        label: "ΕΛΑΣΣΟΝΑΣ"
    },
    {
        value: 3222,
        label: "ΔΕΣΚΑΤΗΣ"
    },
    {
        value: 3231,
        label: "ΛΑΡΙΣΑΣ Α'"
    },
    {
        value: 3232,
        label: "ΛΑΡΙΣΑΣ Β'"
    },
    {
        value: 3233,
        label: "ΛΑΡΙΣΑΣ Γ'"
    },
    {
        value: 3241,
        label: "ΤΥΡΝΑΒΟΥ"
    },
    {
        value: 3251,
        label: "ΦΑΡΣΑΛΩΝ"
    },
    {
        value: 3311,
        label: "ΑΛΜΥΡΟΥ"
    },
    {
        value: 3321,
        label: "ΒΟΛΟΥ Α'"
    },
    {
        value: 3322,
        label: "ΒΟΛΟΥ Β'"
    },
    {
        value: 3323,
        label: "ΙΩΝΙΑΣ ΜΑΓΝΗΣΙΑΣ"
    },
    {
        value: 3331,
        label: "ΣΚΟΠΕΛΟΥ"
    },
    {
        value: 3332,
        label: "ΣΚΙΑΘΟΥ"
    },
    {
        value: 3411,
        label: "ΚΑΛΑΜΠΑΚΑΣ"
    },
    {
        value: 3412,
        label: "ΤΡΙΚΑΛΩΝ"
    },
    {
        value: 3413,
        label: "ΠΥΛΗΣ"
    },
    {
        value: 4111,
        label: "ΑΛΕΞΑΝΔΡΕΙΑΣ"
    },
    {
        value: 4112,
        label: "ΒΕΡΟΙΑΣ"
    },
    {
        value: 4121,
        label: "ΝΑΟΥΣΑΣ"
    },
    {
        value: 4211,
        label: "ΘΕΣΣΑΛΟΝΙΚΗΣ Α'"
    },
    {
        value: 4212,
        label: "ΘΕΣΣΑΛΟΝΙΚΗΣ Β'"
    },
    {
        value: 4214,
        label: "ΘΕΣΣΑΛΟΝΙΚΗΣ Δ'"
    },
    {
        value: 4215,
        label: "ΘΕΣΣΑΛΟΝΙΚΗΣ Ε'"
    },
    {
        value: 4216,
        label: "ΘΕΣΣΑΛΟΝΙΚΗΣ ΣΤ'"
    },
    {
        value: 4217,
        label: "ΘΕΣΣΑΛΟΝΙΚΗΣ Ζ'"
    },
    {
        value: 4221,
        label: "ΖΑΓΚΛΙΒΕΡΙΟΥ"
    },
    {
        value: 4222,
        label: "ΛΑΓΚΑΔΑ"
    },
    {
        value: 4223,
        label: "ΣΩΧΟΥ"
    },
    {
        value: 4224,
        label: "ΘΕΣΣΑΛΟΝΙΚΗΣ ΦΑΕ"
    },
    {
        value: 4225,
        label: "ΝΕΑΠΟΛΗΣ ΘΕΣ/ΝΙΚΗΣ"
    },
    {
        value: 4226,
        label: "ΤΟΥΜΠΑΣ"
    },
    {
        value: 4227,
        label: "ΘΕΣΣΑΛΟΝΙΚΗΣ Ι'"
    },
    {
        value: 4228,
        label: "ΘΕΣΣΑΛΟΝΙΚΗΣ Η'"
    },
    {
        value: 4229,
        label: "ΘΕΣΣΑΛΟΝΙΚΗΣ Θ'"
    },
    {
        value: 4231,
        label: "ΑΓ. ΑΘΑΝΑΣΙΟΥ"
    },
    {
        value: 4232,
        label: "ΚΑΛΑΜΑΡΙΑΣ"
    },
    {
        value: 4233,
        label: "ΑΜΠΕΛΟΚΗΠΩΝ"
    },
    {
        value: 4234,
        label: "Ν.ΙΩΝΙΑΣ ΘΕΣ/ΚΗΣ"
    },
    {
        value: 4311,
        label: "ΚΑΣΤΟΡΙΑΣ"
    },
    {
        value: 4312,
        label: "ΝΕΣΤΟΡΙΟΥ"
    },
    {
        value: 4313,
        label: "ΑΡΓΟΥΣ ΟΡΕΣΤΙΚΟΥ"
    },
    {
        value: 4411,
        label: "ΚΙΛΚΙΣ"
    },
    {
        value: 4421,
        label: "ΓΟΥΜΕΝΙΣΣΑΣ"
    },
    {
        value: 4521,
        label: "ΓΡΕΒΕΝΩΝ"
    },
    {
        value: 4511,
        label: "ΝΕΑΠΟΛΗΣ ΒΟΙΟΥ"
    },
    {
        value: 4531,
        label: "ΠΤΟΛΕΜΑΙΔΑΣ"
    },
    {
        value: 4541,
        label: "ΚΟΖΑΝΗ"
    },
    {
        value: 4542,
        label: "ΣΕΡΒΙΩΝ"
    },
    {
        value: 4543,
        label: "ΣΙΑΤΙΣΤΑΣ"
    },
    {
        value: 4611,
        label: "ΑΡΙΔΑΙΑΣ"
    },
    {
        value: 4621,
        label: "ΓΙΑΝΝΙΤΣΩΝ"
    },
    {
        value: 4631,
        label: "ΕΔΕΣΣΑΣ"
    },
    {
        value: 4641,
        label: "ΣΚΥΔΡΑΣ"
    },
    {
        value: 4711,
        label: "ΚΑΤΕΡΙΝΗΣ Α'"
    },
    {
        value: 4712,
        label: "ΚΑΤΕΡΙΝΗΣ Β'"
    },
    {
        value: 4714,
        label: "ΑΙΓΙΝΙΟΥ"
    },
    {
        value: 4811,
        label: "ΑΜΥΝΤΑΙΟΥ"
    },
    {
        value: 4812,
        label: "ΦΛΩΡΙΝΑΣ"
    },
    {
        value: 4911,
        label: "ΑΡΝΑΙΑΣ"
    },
    {
        value: 4921,
        label: "ΚΑΣΣΑΝΔΡΑΣ"
    },
    {
        value: 4922,
        label: "ΠΟΛΥΓΥΡΟΥ"
    },
    {
        value: 4923,
        label: "ΝΕΩΝ ΜΟΥΔΑΝΙΩΝ"
    },
    {
        value: 5111,
        label: "ΔΡΑΜΑΣ"
    },
    {
        value: 5112,
        label: "ΝΕΥΡΟΚΟΠΙΟΥ"
    },
    {
        value: 5211,
        label: "ΑΛΕΞΑΝΔΡΟΥΠΟΛΗΣ"
    },
    {
        value: 5221,
        label: "ΔΙΔΥΜΟΤΕΙΧΟΥ"
    },
    {
        value: 5231,
        label: "ΟΡΕΣΤΕΙΑΔΑΣ"
    },
    {
        value: 5241,
        label: "ΣΟΥΦΛΙΟΥ"
    },
    {
        value: 5311,
        label: "ΘΑΣΟΥ"
    },
    {
        value: 5321,
        label: "ΚΑΒΑΛΑΣ Α'"
    },
    {
        value: 5322,
        label: "ΚΑΒΑΛΑΣ Β'"
    },
    {
        value: 5331,
        label: "ΧΡΥΣΟΥΠΟΛΗΣ"
    },
    {
        value: 5341,
        label: "ΕΛΕΥΘΕΡΟΥΠΟΛΗΣ"
    },
    {
        value: 5411,
        label: "ΞΑΝΘΗΣ Α'"
    },
    {
        value: 5412,
        label: "ΞΑΝΘΗΣ Β'"
    },
    {
        value: 5511,
        label: "ΚΟΜΟΤΗΝΗΣ"
    },
    {
        value: 5521,
        label: "ΣΑΠΠΩΝ"
    },
    {
        value: 5611,
        label: "ΝΙΓΡΙΤΑΣ"
    },
    {
        value: 5621,
        label: "ΣΕΡΡΩΝ Α'"
    },
    {
        value: 5622,
        label: "ΣΕΡΡΩΝ Β'"
    },
    {
        value: 5631,
        label: "ΣΙΔΗΡΟΚΑΣΤΡΟΥ"
    },
    {
        value: 5632,
        label: "ΗΡΑΚΛΕΙΑΣ"
    },
    {
        value: 5641,
        label: "ΝΕΑΣ ΖΙΧΝΗΣ"
    },
    {
        value: 6111,
        label: "ΑΡΤΑΣ"
    },
    {
        value: 6113,
        label: "ΦΙΛΙΠΠΙΑΔΑΣ"
    },
    {
        value: 6211,
        label: "ΗΓΟΥΜΕΝΙΤΣΑΣ"
    },
    {
        value: 6231,
        label: "ΠΑΡΑΜΥΘΙΑΣ"
    },
    {
        value: 6241,
        label: "ΦΙΛΙΑΤΩΝ"
    },
    {
        value: 6221,
        label: "ΠΑΡΓΑΣ"
    },
    {
        value: 6222,
        label: "ΦΑΝΑΡΙΟΥ"
    },
    {
        value: 6411,
        label: "ΠΡΕΒΕΖΑΣ"
    },
    {
        value: 6311,
        label: "ΙΩΑΝΝΙΝΩΝ Α'"
    },
    {
        value: 6312,
        label: "ΙΩΑΝΝΙΝΩΝ Β'"
    },
    {
        value: 6313,
        label: "ΔΕΛΒΙΝΑΚΙΟΥ"
    },
    {
        value: 6315,
        label: "ΜΕΤΣΟΒΟΥ"
    },
    {
        value: 6321,
        label: "ΚΟΝΙΤΣΑΣ"
    },
    {
        value: 7111,
        label: "ΑΝΔΡΟΥ"
    },
    {
        value: 7121,
        label: "ΘΗΡΑΣ"
    },
    {
        value: 7131,
        label: "ΚΕΑΣ"
    },
    {
        value: 7141,
        label: "ΜΗΛΟΥ"
    },
    {
        value: 7151,
        label: "ΝΑΞΟΥ"
    },
    {
        value: 7161,
        label: "ΠΑΡΟΥ"
    },
    {
        value: 7171,
        label: "ΣΥΡΟΥ"
    },
    {
        value: 7172,
        label: "ΜΥΚΟΝΟΥ"
    },
    {
        value: 7181,
        label: "ΤΗΝΟΥ"
    },
    {
        value: 7211,
        label: "ΛΗΜΝΟΥ"
    },
    {
        value: 7221,
        label: "ΚΑΛΛΟΝΗΣ"
    },
    {
        value: 7222,
        label: "ΜΗΘΥΜΝΑΣ"
    },
    {
        value: 7231,
        label: "ΜΥΤΙΛΗΝΗΣ"
    },
    {
        value: 7241,
        label: "ΠΛΩΜΑΡΙΟΥ"
    },
    {
        value: 7311,
        label: "ΑΓ. ΚΗΡΥΚΟΥ ΙΚΑΡΙΑΣ"
    },
    {
        value: 7321,
        label: "ΚΑΡΛΟΒΑΣΙΟΥ"
    },
    {
        value: 7322,
        label: "ΣΑΜΟΥ"
    },
    {
        value: 7411,
        label: "ΧΙΟΥ"
    },
    {
        value: 7511,
        label: "ΚΑΛΥΜΝΟΥ"
    },
    {
        value: 7512,
        label: "ΛΕΡΟΥ"
    },
    {
        value: 7521,
        label: "ΚΑΡΠΑΘΟΥ"
    },
    {
        value: 7531,
        label: "ΚΩ"
    },
    {
        value: 7542,
        label: "ΡΟΔΟΥ"
    },
    {
        value: 8110,
        label: "ΗΡΑΚΛΕΙΟΥ"
    },
    {
        value: 8111,
        label: "ΗΡΑΚΛΕΙΟΥ Α'"
    },
    {
        value: 8112,
        label: "ΜΟΙΡΩΝ"
    },
    {
        value: 8113,
        label: "ΗΡΑΚΛΕΙΟΥ Β'"
    },
    {
        value: 8114,
        label: "ΤΥΜΠΑΚΙΟΥ"
    },
    {
        value: 8115,
        label: "ΛΙΜΕΝΑ ΧΕΡΣΟΝΗΣΟΥ"
    },
    {
        value: 8121,
        label: "ΚΑΣΤΕΛΙΟΥ ΠΕΔΙΑΔΟΣ"
    },
    {
        value: 8131,
        label: "ΑΡΚΑΛΟΧΩΡΙΟΥ"
    },
    {
        value: 8211,
        label: "ΙΕΡΑΠΕΤΡΑΣ"
    },
    {
        value: 8221,
        label: "ΑΓΙΟΥ ΝΙΚΟΛΑΟΥ"
    },
    {
        value: 8231,
        label: "ΝΕΑΠΟΛΗΣ ΚΡΗΤΗΣ"
    },
    {
        value: 8241,
        label: "ΣΗΤΕΙΑΣ"
    },
    {
        value: 8341,
        label: "ΡΕΘΥΜΝΟΥ"
    },
    {
        value: 8421,
        label: "ΚΙΣΣΑΜΟΥ"
    },
    {
        value: 8431,
        label: "ΧΑΝΙΩΝ Α'"
    },
    {
        value: 8432,
        label: "ΧΑΝΙΩΝ Β'"
    },
    {
        value: 9111,
        label: "ΖΑΚΥΝΘΟΥ"
    },
    {
        value: 9211,
        label: "ΚΕΡΚΥΡΑΣ Α'"
    },
    {
        value: 9212,
        label: "ΚΕΡΚΥΡΑΣ Β'"
    },
    {
        value: 9221,
        label: "ΠΑΞΩΝ"
    },
    {
        value: 9311,
        label: "ΑΡΓΟΣΤΟΛΙΟΥ"
    },
    {
        value: 9321,
        label: "ΛΗΞΟΥΡΙΟΥ"
    },
    {
        value: 9411,
        label: "ΙΘΑΚΗΣ"
    },
    {
        value: 9421,
        label: "ΛΕΥΚΑΔΑΣ"
    }
]
