import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    editing: false,
    editPage: false,

    // Product
    saveNewProduct: false,
    saveEditProduct: false,
    saveNewProductMovements: false,
    saveEditProductMovements: false,
    saveNewPriceList: false,
    saveEditPriceList: false,

    // Client
    saveNewClient: false,
    saveEditClient: false,
    saveNewClientMovements: false,
    saveEditClientMovements: false,

    // Supplier
    saveNewSupplier: false,
    saveEditSupplier: false,
    saveNewSupplierMovements: false,
    saveEditSupplierMovements: false,

    // Cash
    saveNewCashRegister: false,
    saveEditCashRegister: false,
    saveNewBankAccounts: false,
    saveEditBankAccounts: false,
    saveNewPOS: false,
    saveEditPOS: false,
    saveCashAccountingSettings: false,

    // Sales Settings
    saveSalesSettings: false,
    saveQuickRetailSettings: false,

    // Buys Settings
    saveBuysSettings: false,

    // Sales
    saveAutomaticSales: false,
    saveEditAutomaticSales: false,
    saveQuickRetailSales: false,
    saveEditQuickRetailSales: false,
    saveSales: false,
    saveEditSales: false,
    saveClientPayments: false,
    saveEditClientPayments: false,
    sendEmail: "inactive",

    // Buys
    saveAutomaticBuys: false,
    saveEditAutomaticBuys: false,
    saveQuickRetailBuys: false,
    saveEditQuickRetailBuys: false,
    saveBuys: false,
    saveEditBuys: false,
    saveSupplierPayments: false,
    saveEditSupplierPayments: false,

    // Settings
    saveBasicType: false,
    saveEditBasicType: false,
    saveAdvancedType: false,
    saveEditAdvancedType: false,
    savePrimerMyData: false,
    saveEmailSMtp: false,

    // Accounting
    saveNewAccountingAccount: false,
    saveEditAccountingAccount: false,
    saveNewAccountingRecord: false,
    saveEditAccountingRecord: false,
    saveAccountingPaymentTypes: false,
    saveNewAccountingClosure: false,

    // Payroll
    saveNewEmployee: false,
    saveEditEmployee: false,
    sendEmployeeQROnSave: false,
    sendEmployeeNewPasswordOnSave: false,
    sendEmployeeChangesToErgani: false,
    saveNewWorkMovement: false,
    saveEditWorkMovement: false,
    saveNewWorkPermit: false,
    saveEditWorkPermit: false,

    // Scrolling
    rememberScroll: {}, // Will have tabName : scroll position
    rememberInnerTab: {}, // Will have templateType : tabIndex
    updateInnerTab: -1,

    // For Emron
    emronCompanyInvalid: false,
    canBypassInvalidCompany: false,
    emronCompanyInvalidDays: 0,
    isEmronClient: false,
    emronClientData: null,
    isEmron: false,

    // For Emron Connect
    isEmronConnectFileERP: false,

    primerMyDataToExpireModal: false,
    primerMyDataToExpireModalLink: "",
    primerMyDataExpirationModalMessage: "",
}

export const ActivitySlice = createSlice({
    name: "Activity",
    initialState,
    reducers: {
        setEditing: (state, {payload}) => {
            state.editing = payload;
        },
        setEditPage: (state, {payload}) => {
            state.editPage = payload;
        },

        // Product
        setSaveNewProduct: (state, {payload}) => {
            state.saveNewProduct = payload;
        },
        setSaveEditProduct: (state, {payload}) => {
            state.saveEditProduct = payload;
        },
        setSaveNewProductMovements: (state, {payload}) => {
            state.saveNewProductMovements = payload;
        },
        setSaveEditProductMovements: (state, {payload}) => {
            state.saveEditProductMovements = payload;
        },
        setSaveNewPriceList: (state, {payload}) => {
            state.saveNewPriceList = payload;
        },
        setSaveEditPriceList: (state, {payload}) => {
            state.saveEditPriceList = payload;
        },

        // Client
        setSaveNewClient: (state, {payload}) => {
            state.saveNewClient = payload;
        },
        setSaveEditClient: (state, {payload}) => {
            state.saveEditClient = payload;
        },
        setSaveNewClientMovements: (state, {payload}) => {
            state.saveNewClientMovements = payload;
        },

        // Supplier
        setSaveNewSupplier: (state, {payload}) => {
            state.saveNewSupplier = payload;
        },
        setSaveEditSupplier: (state, {payload}) => {
            state.saveEditSupplier = payload;
        },

        // Cash
        setSaveNewCashRegister: (state, {payload}) => {
            state.saveNewCashRegister = payload;
        },
        setSaveEditCashRegister: (state, {payload}) => {
            state.saveEditCashRegister = payload;
        },
        setSaveNewBankAccounts: (state, {payload}) => {
            state.saveNewBankAccounts = payload;
        },
        setSaveEditBankAccounts: (state, {payload}) => {
            state.saveEditBankAccounts = payload;
        },
        setSaveNewPOS: (state, {payload}) => {
            state.saveNewPOS = payload;
        },
        setSaveEditPOS: (state, {payload}) => {
            state.saveEditPOS = payload;
        },
        setSaveCashAccountingSettings: (state, {payload}) => {
            state.saveCashAccountingSettings = payload;
        },

        // Sales Settings
        setSaveSalesSettings: (state, {payload}) => {
            state.saveSalesSettings = payload;
        },

        // Buys Settings
        setSaveBuysSettings: (state, {payload}) => {
            state.saveBuysSettings = payload;
        },

        // Sales
        setSaveSales: (state, {payload}) => {
            state.saveSales = payload;
        },
        setSaveEditSales: (state, {payload}) => {
            state.saveEditSales = payload;
        },

        setSaveClientPayments: (state, {payload}) => {
            state.saveClientPayments = payload;
        },
        setSaveEditClientPayments: (state, {payload}) => {
            state.saveEditClientPayments = payload;
        },


        // Buys
        setSaveAutomaticBuys: (state, {payload}) => {
            state.saveAutomaticBuys = payload;
        },
        setSaveQuickRetailBuys: (state, {payload}) => {
            state.saveQuickRetailBuys = payload;
        },
        setSaveBuys: (state, {payload}) => {
            state.saveBuys = payload;
        },
        setSaveEditBuys: (state, {payload}) => {
            state.saveEditBuys = payload;
        },

        setSaveSupplierPayments: (state, {payload}) => {
            state.saveSupplierPayments = payload;
        },
        setSaveEditSupplierPayments: (state, {payload}) => {
            state.saveEditSupplierPayments = payload;
        },

        // Settings
        setSaveBasicType: (state, {payload}) => {
            state.saveBasicType = payload;
        },
        setSaveEditBasicType: (state, {payload}) => {
            state.saveEditBasicType = payload;
        },
        setSaveAdvancedType: (state, {payload}) => {
            state.saveAdvancedType = payload;
        },
        setSaveEditAdvancedType: (state, {payload}) => {
            state.saveEditAdvancedType = payload;
        },
        setSavePrimerMyData: (state, {payload}) => {
            state.savePrimerMyData = payload;
        },
        setSavePrimerMyDataData: (state, {payload}) => {
            state.savePrimerMyDataData = payload;
        },
        setSaveEmailSmtp: (state, {payload}) => {
            state.saveEmailSMtp = payload;
        },
        setSendEmail: (state, {payload}) => {
            state.sendEmail = payload;
        },

        //Accounting
        setSaveNewAccountingAccount: (state, {payload}) => {
            state.saveNewAccountingAccount = payload;
        },
        setSaveEditAccountingAccount: (state, {payload}) => {
            state.saveEditAccountingAccount = payload;
        },
        setSaveNewAccountingRecord: (state, {payload}) => {
            state.saveNewAccountingRecord = payload;
        },
        setSaveEditAccountingRecord: (state, {payload}) => {
            state.saveEditAccountingRecord = payload;
        },
        setSaveAccountingPaymentTypes: (state, {payload}) => {
            state.saveAccountingPaymentTypes = payload;
        },
        setSaveNewAccountingClosure: (state, {payload}) => {
            state.saveNewAccountingClosure = payload;
        },

        // Payroll
        setSaveNewEmployee: (state, {payload}) => {
            state.saveNewEmployee = payload;
        },
        setSaveEditEmployee: (state, {payload}) => {
            state.saveEditEmployee = payload;
        },
        setSendEmployeeQROnSave: (state, {payload}) => {
            state.sendEmployeeQROnSave = payload;
        },
        setSendEmployeeNewPasswordOnSave: (state, {payload}) => {
            state.sendEmployeeNewPasswordOnSave = payload;
        },
        setSendEmployeeChangesToErgani: (state, {payload}) => {
            state.sendEmployeeChangesToErgani = payload;
        },
        setSaveNewWorkMovement: (state, {payload}) => {
            state.saveNewWorkMovement = payload;
        },
        setSaveEditWorkMovement: (state, {payload}) => {
            state.saveEditWorkMovement = payload;
        },
        setSaveNewWorkPermit: (state, {payload}) => {
            state.saveNewWorkPermit = payload;
        },
        setSaveEditWorkPermit: (state, {payload}) => {
            state.saveEditWorkPermit = payload;
        },

        setScroll: (state, {payload}) => {
            state.rememberScroll = payload;
        },
        setRememberInnerTab: (state, {payload}) => {
            state.rememberInnerTab = {...state.rememberInnerTab, ...payload};
        },
        setRememberInnerTabsForce: (state, {payload}) => {
            state.rememberInnerTab = payload;
        },
        setEmronCompanyInvalid: (state, {payload}) => {
            state.emronCompanyInvalid = payload;
        },
        setCanBypassInvalidCompany: (state, {payload}) => {
            state.canBypassInvalidCompany = payload;
        },
        setEmronCompanyInvalidDays: (state, {payload}) => {
            state.emronCompanyInvalidDays = payload;
        },
        setIsEmronClient: (state, {payload}) => {
            state.isEmronClient = payload;
        },
        setEmronClientData: (state, {payload}) => {
            state.emronClientData = payload;
        },
        setIsEmron: (state, {payload}) => {
            state.isEmron = payload;
        },
        setIsEmronConnectFileERP: (state, {payload}) => {
            state.isEmronConnectFileERP = payload;
        },

        setUpdateInnerTab: (state, {payload}) => {
            state.updateInnerTab = payload;
        },
        setShowPrimerMyDataToExpireModal: (state, {payload}) => {
            state.primerMyDataToExpireModal = payload;
        },
        setPrimerMyDataModalLink: (state, {payload}) => {
            state.primerMyDataToExpireModalLink = payload;
        },
        setPrimerMyDataExpirationModalMessage: (state, {payload}) => {
            state.primerMyDataExpirationModalMessage = payload;
        }
    },
});

export const {
    // Common
    setEditing,
    setEditPage,

    // Product
    setSaveEditProduct,
    setSaveNewProduct,
    setSaveNewProductMovements,
    setSaveEditProductMovements,
    setSaveNewPriceList,
    setSaveEditPriceList,

    // Client
    setSaveNewClient,
    setSaveEditClient,
    setSaveNewClientMovements,

    // Supplier
    setSaveNewSupplier,
    setSaveEditSupplier,

    // Cash
    setSaveNewCashRegister,
    setSaveEditCashRegister,
    setSaveNewBankAccounts,
    setSaveEditBankAccounts,
    setSaveNewPOS,
    setSaveEditPOS,
    setSaveCashAccountingSettings,

    // Sales
    setSaveSalesSettings,
    setSaveSales,
    setSaveEditSales,
    setSaveClientPayments,
    setSaveEditClientPayments,

    // Buys
    setSaveBuysSettings,
    setSaveBuys,
    setSaveEditBuys,
    setSaveSupplierPayments,
    setSaveEditSupplierPayments,

    // Settings
    setSaveBasicType,
    setSaveEditBasicType,
    setSaveAdvancedType,
    setSaveEditAdvancedType,
    setSavePrimerMyData,
    setSavePrimerMyDataData,
    setSaveEmailSmtp,
    setSendEmail,

    // Accounting
    setSaveNewAccountingAccount,
    setSaveEditAccountingAccount,
    setSaveNewAccountingRecord,
    setSaveEditAccountingRecord,
    setSaveAccountingPaymentTypes,
    setSaveNewAccountingClosure,

    // Payroll
    setSaveNewEmployee,
    setSaveEditEmployee,
    setSendEmployeeQROnSave,
    setSendEmployeeNewPasswordOnSave,
    setSendEmployeeChangesToErgani,
    setSaveNewWorkMovement,
    setSaveEditWorkMovement,
    setSaveNewWorkPermit,
    setSaveEditWorkPermit,

    setScroll,
    setUpdateInnerTab,
    setRememberInnerTab,
    setRememberInnerTabsForce,

    setEmronCompanyInvalid,
    setCanBypassInvalidCompany,
    setEmronCompanyInvalidDays,
    setIsEmronClient,
    setEmronClientData,
    setIsEmron,

    setIsEmronConnectFileERP,
    setShowPrimerMyDataToExpireModal,
    setPrimerMyDataModalLink,
    setPrimerMyDataExpirationModalMessage,
} = ActivitySlice.actions;
