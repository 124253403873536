import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector, useDispatch} from "react-redux";
import {fetchSearchSupplierCommunicationHistory} from "../../../../../_apis/api";
import { deleteObjectFromArrayOfObjects } from "../../../../../_helpers/helperFunctions";
import { clearEmptyValuesFromObject, getCurrentUser, getMultiSelectOptionsToArray, getOptionsByCommaString } from "../../../../../_helpers/commonFunctions";
import EditableTable from "../../../../primer/Editable-Table";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import DateBox from "../../../../primer/DateBox";
import Dropdown from "../../../../primer/Dropdown";
import { setSaveEditSupplier } from "../../../../../_reducers/activitySlice";
import { resetNewCommunicationHistory, setSuppliersSettings, setEditSupplier, setNewCommunicationHistory, resetSearchSupplierCommunicationHistory } from "../../../../../_reducers/DataPanel/Suppliers/supplierSlice";
import {useTranslation} from "react-i18next";
const apiURL2 = process.env.REACT_APP_API_URL2;

function EditSupplierCommunicationHistory() {
  const [t] = useTranslation('common');
  const company = useSelector(state => state.COMPANY_DATA.company)
  const ACTIVITY = useSelector( (state) => state.ACTIVITY );
  const SUPPLIERS_DATA = useSelector( (state) => state.SUPPLIERS_DATA );
  const supplierSubjects = SUPPLIERS_DATA.suppliersSettings.supplierCommunicationHistory;
  const subjectsOptionsInit = getOptionsByCommaString(supplierSubjects);
  const [subjectsOptions, setSubjectsOptions] = useState(subjectsOptionsInit);
  const [requestDataPage, setRequestDataPage] = useState( {company:company.id, year:company.year, clientId: SUPPLIERS_DATA.editSupplier._id});
  const [totalItems, setTotalItems] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const dispatch = useDispatch();
  const [nextId, setNextId] = useState(1);
  let requestData = {company:company.id, year:company.year};
  let chColumns = [
    {
      name: t("General.no"),
      field: "no",
      width: "15px",
      editable: false,
    },
    {
      name: t("General.date"),
      field: "date",
      width: "80px",
      editable: true,
      inputType: {
        type: 'datebox',
      }
    },
    {
      name: t("General.time"),
      field: "time",
      width: "50px",
      editable:true,
      inputType: {
        type: 'time',
      }
    },
    {
      name: t("General.subject"),
      field: "subject",
      width: "100px",
      editable: true,
      inputType: {
        type: 'dropdown',
        options: subjectsOptions
      }
    },
    {
      name: t("General.details"),
      field: "details",
      editable: true,
      width: "400px",
      inputType: {
        type: 'textarea',
      }
    },
    {
      name: t("General.followUpDate"),
      field: "followUpDate",
      editable: true,
      width: "100px",
      inputType: {
        type: 'datebox',
      }
    },
    {
      name: t("General.done"),
      field: "done",
      editable: true,
      width: "90px",
      inputType: {
        type: 'checkbox',
        options: [{
          value: true,
          label: 'Yes'
        }, {
          value: false,
          label: 'No'
        }]
      }
    },
  ]
  const [tableColumns, setTableColumns] = useState(chColumns.slice())
  const [filterFormData, setFilterFormData] = useState({});
  useEffect(() => {
    axios.post(apiURL2 + `/supplier/list-supplier-setting`, JSON.stringify({
      company:company.id,
      year:company.year.toString(),
      isActive:"active",
      userId: getCurrentUser.id
    }), { headers: { 'Content-Type': 'application/json'}})
    .then(res => {
      if (res.data &&  res.data.status === '200') {
        if (res.data.data.length > 0) {
          dispatch(setSuppliersSettings(res.data.data[res.data.data.length - 1]));
          sessionStorage.setItem('suppliersSettingsData', JSON.stringify(res.data.data[res.data.data.length - 1]));
          const subjectsOptionsUp = getOptionsByCommaString(res.data.data[res.data.data.length - 1]?.supplierCommunicationHistory);
          setSubjectsOptions(subjectsOptionsUp);
        }
      }
    }).catch((err) => {
      console.log(err);
    });

    setTableColumns(chColumns); // set options
    dispatch(resetSearchSupplierCommunicationHistory());
  }, [company.id, company.year, dispatch]);

  const doneOptions = [
    {
      value: '',
      label: 'Κενό'
    },
    {
      value: 'true',
      label: t('ClientsNewCommunicationHistoryEdit.table2.yes')
    }, {
      value: 'false',
      label: t('ClientsNewCommunicationHistoryEdit.table2.no')
    }];

  const handleOnFilterChange = (e, type="default", ddname = '') => {
    let name;
    let value;

    if (type === 'msdd')  {
      name = ddname;
      value = getMultiSelectOptionsToArray(e);
    } else if (type === 'dd')  {
      name = ddname;
      value = e.value;
    } else if (type === 'dp' ) {
      value = e;
      name = ddname;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    setFilterFormData({...filterFormData, [name]: value });
  }

  const tableActions = {
    insert: (e, data) => {
      // Set if editing the form
      if (!ACTIVITY.saveEditSupplier) {
        dispatch(setSaveEditSupplier(true));
      }
      let tempCommunicationHistory = Object.assign({},  SUPPLIERS_DATA.newCommunicationHistory);
      tempCommunicationHistory[data.field] = data.value;
      dispatch(setNewCommunicationHistory(tempCommunicationHistory)); // Update redux
        // Save Data
      if (tempCommunicationHistory && tempCommunicationHistory.subject !== '' && tempCommunicationHistory.details !== "" && tempCommunicationHistory.date !== '' && tempCommunicationHistory.time !== '') {
          let UpdatedComHist = Object.assign({},  tempCommunicationHistory);
          let oldComHist = SUPPLIERS_DATA.editSupplier.communicationHistory;
          UpdatedComHist.no = oldComHist.length + 1;
          UpdatedComHist.id = oldComHist.length + 1;
          dispatch(setEditSupplier({...SUPPLIERS_DATA.editSupplier, communicationHistory: [UpdatedComHist, ...oldComHist]}));
        setPerPage(perPage + 1);
        setTotalItems(totalItems + 1);
          dispatch(resetNewCommunicationHistory()); // Reset
        }

    },
    update: (data, id) => {
      // Set if editing the form
      if (!ACTIVITY.saveEditSupplier) {
        dispatch(setSaveEditSupplier(true));
      }
      if (data.date && (data.date === "")) {
        toast.error("Date is mandatory field.");
      } else if (data.time && (data.time === "")) {
        toast.error("Time  is mandatory field.");
      } else {
        let communicationHistory = SUPPLIERS_DATA.editSupplier.communicationHistory.slice();
        const index = communicationHistory.findIndex(item => item.id === id);
        if (index !== -1) {
          // Update Code
          communicationHistory[index] = {...communicationHistory[index], ...data};
          dispatch(setEditSupplier({...SUPPLIERS_DATA.editSupplier, communicationHistory: communicationHistory})); // update data
        }
      }

    },
    selected: () => {},
    delete: (id) => {
      // Set if editing the form
      if (!ACTIVITY.saveEditSupplier) {
        dispatch(setSaveEditSupplier(true));
      }
      let communicationHistory = SUPPLIERS_DATA.editSupplier.communicationHistory.slice();
      if (id !== '' && Number.isInteger(id)) {
        communicationHistory = deleteObjectFromArrayOfObjects(communicationHistory, id, 'id');
        dispatch(setEditSupplier({...SUPPLIERS_DATA.editSupplier, communicationHistory: communicationHistory }))
      }
      if (id !== '' && !Number.isInteger(id)) {
        communicationHistory = deleteObjectFromArrayOfObjects(communicationHistory, id, '_id');
        dispatch(setEditSupplier({...SUPPLIERS_DATA.editSupplier, communicationHistory: communicationHistory }))
      }
      let cchRequestData = {...filterFormData, ...requestData};
      cchRequestData.clientId = SUPPLIERS_DATA.editSupplier._id;
      cchRequestData.page = 1;
      cchRequestData = clearEmptyValuesFromObject(cchRequestData); // clear empty values before sending to the api.
      setRequestDataPage(cchRequestData);
      setPerPage(5);
    }
  }

  const handleApplyFilter = (e) => {
    e.preventDefault();
    toast.info('Loading search result.');
    let cchRequestData = {...filterFormData, ...requestData};
    cchRequestData.clientId = SUPPLIERS_DATA.editSupplier._id;
    cchRequestData.page = 1;
    cchRequestData = clearEmptyValuesFromObject(cchRequestData); // clear empty values before sending to the api.
    setRequestDataPage(cchRequestData);
    setPerPage(5);
    dispatch(fetchSearchSupplierCommunicationHistory(cchRequestData, setTotalItems, setNextId));
  }

  return (
    <div className="search-products">
        <Row>
          <Col sm={6} md={4}>
            <DateBox
              name="dateFrom"
              selected={filterFormData?.dateFrom}
              label={t("General.dateFrom")}
              onChange={(date) => handleOnFilterChange(date, 'dp', 'dateFrom')}
            />
          </Col>
          <Col sm={6} md={4}>
            <DateBox
              dateFormat="dd-MM-yyyy"
              name="dateTo"
              selected={filterFormData?.dateTo}
              label={t("General.dateTo")}
              onChange={(date) => handleOnFilterChange(date, 'dp', 'dateTo')}
            />
          </Col>
          <Col sm={6} md={4}>
            <Dropdown
              multiSelect={true}
              label={t("General.subject")}
              placeholder='Select subjects.'
              name="subject"
              options = {subjectsOptions}
              onChange = {(e) => handleOnFilterChange(e, 'msdd', 'subject') }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4}>
            <DateBox
              dateFormat="dd-MM-yyyy"
              name="followupFrom"
              selected={filterFormData?.followupFrom}
              label={t("General.followUpDateFrom")}
              onChange={(date) => handleOnFilterChange(date, 'dp', 'followupFrom')}
            />
          </Col>
          <Col sm={6} md={4}>
            <DateBox
              dateFormat="dd-MM-yyyy"
              name="followupTo"
              selected={filterFormData?.followupTo}
              label={t("General.followUpDateTo")}
              onChange={(date) => handleOnFilterChange(date, 'dp', 'followupTo')}
            />
          </Col>
          <Col sm={6} md={4}>
            <Dropdown
              label={t("General.done")}
              placeholder='Select subjects.'
              name="done"
              options = {doneOptions}
              onChange = {(e) => handleOnFilterChange(e, 'dd', 'done') }
            />
          </Col>
        </Row>
        <Row>
          <div className="col-12 ml-auto">
            <button
              type="submit"
              onClick={(e) => handleApplyFilter(e)}
              className="btn btn-primary mb-4 float-right"
            > {t("General.applyFilter")}
            </button>
          </div>
        </Row>

      <EditableTable
        className = "supplier-communication-history"
        tableName = "Supplier Communication History"
        allowActions = {true}
        key = {Math.random()}
        actionButtons = {{
          edit: false,
          print: false,
          export: false,
          delete: true,
        }}
        allowFilter = { true }
        allowPagination={ true }
        allowBulkActions={ true }
        allowPrintResults={ true }
        columns={tableColumns}
        requestData={requestDataPage}
        allowInsertRowFromTop={true}
        allowFilterTable={false}
        data={SUPPLIERS_DATA.editSupplier?.communicationHistory ? SUPPLIERS_DATA.editSupplier.communicationHistory.slice() : []}
        totalItems= {totalItems}
        perPage= {perPage}
        onUpdate={tableActions}
        enableNewRowCheckbox = {true}
      />
    </div>
  )
}
export default EditSupplierCommunicationHistory;
