import React, {useEffect, useRef, useState} from "react";
import Loader from "../../../primer/POPUP-COMPONENTS/Loader/PrimerLoader";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import DateBox from "../../../primer/DateBox";
import {Badge, Button, Col, Row} from "react-bootstrap";
import Dropdown from "../../../primer/Dropdown";
import {
    setAccountsReportDisplayData,
    setAccountsReportRequestData,
    setAccountsReportResponseData, setEditAccountingRecord,
} from "../../../../_reducers/DataPanel/Accounting/accountingSlice";
import {toast} from "react-toastify";
import axios from "axios";
import Input from "../../../common/Input";
import {addTab, updateActiveMainTab} from "../../../../_reducers/TabsSlice";
import * as XLSX from "xlsx";
import "./styles/reports.css"
import {isEmpty} from "../../../../_helpers/commonFunctions";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import {jsPDF} from "jspdf";
import {liberationSerif} from "../../../../fonts/liberationSerif";
import {dejavuSans} from "../../../../fonts/DejaVuSans-normal";
import {dejavuSansBold} from "../../../../fonts/DejaVuSans-Bold-bold";
import {StorageController} from "../../../../_helpers/storage-controller";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import {classicStyleBelowNavbar} from "../Statics";

const AccountingReportAccounts = () => {
    const dispatch = useDispatch();
    const requestData = useSelector((state) => state.ACCOUNTING_DATA.accountsReportRequestData);
    const responseData = useSelector((state) => state.ACCOUNTING_DATA.accountsReportResponseData);
    const displayData = useSelector((state) => state.ACCOUNTING_DATA.accountsReportDisplayData);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const companyDetails = useSelector((state) => state.COMPANY_DATA.companyDetails);
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const [installationOptions, setInstallationOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const tdStyle = { backgroundColor: "#bdfcce", fontSize: `${displayData.fontSize}px` }
    const tdStyle2 = { paddingTop: "3px", paddingBottom: "3px", fontSize: `${displayData.fontSize}px` }
    const tdStyle3 = { fontSize: `${displayData.fontSize}px` }

    const [render, setRender] = useState(false);
    const pageRef = useRef();
    const [autoScrollRunning, setAutoscrollRunning] = useState(false);
    const [sliceRows, setSliceRows] = useState(false);

    const [defaultSubaccountData, setDefaultSubaccountData] = useState([]);

    useEffect(() => {
        if(companyInstallations.length > 0) {
            let options = [{ value: "", label: "Όλες"}];
            companyInstallations.forEach((inst) => {
                options.push({ value: inst.installationMasterId, label: inst.type + " " + inst.address + " " + inst.city });
            })
            setInstallationOptions(options);
        }
        setTimeout(() => {
            setRender(true);
        }, 100)
        if (defaultSubaccountData.length === 0) {
            onChangeSubaccount(null, setDefaultSubaccountData);
        }
    }, [])

    useEffect(() => {
        if (render) {
            pageRef.current.scrollTo(0, StorageController.getKey("accountingAccountsScroll"));
            if (!autoScrollRunning) scrollHolder();
            setAutoscrollRunning(true);
        }
    }, [render]);

    const scrollHolder = () => {
        if (pageRef && pageRef?.current && !isEmpty(pageRef?.current?.scrollTop) && pageRef?.current?.scrollTop !== StorageController.getKey("accountingAccountsScroll")) {
            StorageController.setKey("accountingAccountsScroll", pageRef.current.scrollTop);
        }
        setTimeout(() => {
            if (pageRef && pageRef?.current && !isEmpty(pageRef?.current?.scrollTop)) scrollHolder();
        }, 250)
    }

    const onChangeSubaccount = (keyword, setData) => {
        let requestData = { company: company.id }
        if (!isEmpty(keyword) && String(keyword).length >= 2) requestData.keyword = keyword;
        axios.get(`${process.env.REACT_APP_API_URL2}/accounting/mass-subaccount-filter`, {
            headers: {"Content-Type": "application/json"},
            params: {
                ...requestData
            }
        }).then((res) => {
            if (res.data.status === "200") {
                setData(res.data.data);
            } else {
                setData([]);
                console.log(res.data);
            }
        }).catch((error) => {
            setData([]);
            console.log(error);
        })
    }

    const handleFilterChange = (e, type, name) => {
        setSliceRows(true);
        if (type === "datebox") {
            if(moment(e).isValid()) {
                dispatch(setAccountsReportRequestData({...requestData, [name] : moment(e).format("DD/MM/YYYY")}));
            } else {
                dispatch(setAccountsReportRequestData({...requestData, [name] : ""}));
            }
        } else if (type === "dropdown") {
            dispatch(setAccountsReportRequestData({...requestData, [name] : e.value}));
        } else if (type === "input") {
            dispatch(setAccountsReportRequestData({...requestData, [name] : e.target.value}));
        } else if (type === "acdd" && name === "subAccount") {
            dispatch(setAccountsReportRequestData({...requestData, subAccount: e.row._id, subAccountInput: e.row.name}));
        }
    }

    const fetchReport = () => {
        setSliceRows(false);
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL2 + "/report/accounting-accounts", {...requestData, company: company.id, year: company.year}, {
            headers: { "Content-Type": "application/json" }
        }).then((res) => {
            setLoading(false);
            if(res.data.status === "200") {
                let resData = res.data.data;
                let maxLength = 0;
                for(let acc of resData) {
                    const length = String(acc.code).replaceAll(".", "").length;
                    if(length > maxLength) maxLength = length;
                }
                resData = resData.sort((a, b) => {
                    let fixedA = String(a.code).replaceAll(".", "");
                    let fixedB = String(b.code).replaceAll(".", "");
                    fixedA += "0".repeat(maxLength - fixedA.length);
                    fixedB += "0".repeat(maxLength - fixedB.length);
                    if(Number(fixedA) < Number(fixedB)) {
                        return -1;
                    } else {
                        return 1;
                    }
                })
                let counter = 1;
                for (let table of resData) {
                    for(let subAccount of table.subAccounts) {
                        for (let article of subAccount.articles) {
                            article.counter = counter;
                            counter++;
                        }
                    }
                }
                dispatch(setAccountsReportResponseData(resData));
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            setLoading(false);
            console.log(error);
            toast.error("Πρόβλημα κατά την αποστολή αιτήματος.");
        })
    }

    const handleEnter = (e) => {
        if(e.key === "Enter") fetchReport();
    }

    const handleDoubleClick = (articleId) => {
        if (!isEmpty(articleId)) {
            axios.get(process.env.REACT_APP_API_URL2 + "/accounting/get-record-by-id", {
                headers: { "Content-Type": "application/json" },
                params: {
                    company: company.id,
                    articleId: articleId,
                }
            }).then((res) => {
                if(res.data.status === "200") {
                    dispatch(setEditAccountingRecord(res.data.data));
                    const idx = TABS_DATA.findIndex((item) => "accounting-record-edit" === item);
                    if(idx === -1) {
                        dispatch(addTab("accounting-record-edit"));
                        dispatch(updateActiveMainTab(TABS_DATA.length));
                    } else {
                        dispatch(updateActiveMainTab(idx));
                    }
                } else {
                    toast.error("Πρόβλημα κατά την εύρεση άρθρου.");
                }
            }).catch((error) => {
                console.log(error);
                toast.error("Πρόβλημα κατά την αποστολή αιτήματος.");
            })
        }
    }

    const handleExcelExport = () => {
        let dateFrom = requestData.date_from;
        let dateTo = requestData.date_to;
        if (isEmpty(dateFrom)) dateFrom = moment(company.year, "YYYY", true).startOf("year").format("DD/MM/YYYY");
        if (isEmpty(dateTo)) dateTo = moment(company.year, "YYYY", true).endOf("year").format("DD/MM/YYYY");
        const dateExported = moment().format("DD/MM/YYYY HH:mm:ss");

        let rows = [];
        rows.push(["Επωνυμία:", company.name]);
        rows.push(["ΑΦΜ:", company.vatNumber]);
        rows.push(["ΔΟΥ:", companyDetails.doy]);
        rows.push([`Εύρος ημερομηνίας: ${dateFrom} - ${dateTo}`]);
        rows.push([`Ημερομηνία Εξαγωγής: ${dateExported}`]);
        rows.push([]);

        rows.push(["", "", "", "", "", "Απογραφή", "Απογραφή", "Κίνηση", "Κίνηση", "Προοδευτικά", "Προοδευτικά", "Υπόλοιπο", "Υπόλοιπο"]);
        rows.push(["Α/Α", "Ημερ/νία", "Αρ. Άρθρου", "Πρότυπο", "Περιγραφή", "Χρέωση", "Πίστωση", "Χρέωση", "Πίστωση", "Χρέωση", "Πίστωση", "Χρέωση", "Πίστωση"]);
        for (let table of responseData) {
            rows.push([String(table.code + " " + table.name), "", "", "", "", "", "", "", "", "", "", "", ""]);
            for (let sub of table.subAccounts) {
                if(sub.subAccount !== "") {
                    rows.push([String(table.code + " " + sub.subAccount), "", "", "", "", "", "", "", "", "", "", "", ""]);
                }
                for (let article of sub.articles) {
                    rows.push([
                        article.counter,
                        article.date,
                        article.number,
                        article.subCategoryName,
                        article.reason,
                        Number(article.censusDebit).toFixed(2),
                        Number(article.censusCredit).toFixed(2),
                        Number(article.moveDebit).toFixed(2),
                        Number(article.moveCredit).toFixed(2),
                        Number(article.progressivelyDebit).toFixed(2),
                        Number(article.progressivelyCredit).toFixed(2),
                        Number(article.balanceDebit).toFixed(2),
                        Number(article.balanceCredit).toFixed(2)
                    ]);
                }
                if(sub.subAccount !== "") {
                    rows.push([
                        `Σύνολα Υπολογαριασμού ${table.code} ${sub.subAccount}`,
                        "",
                        "",
                        "",
                        "",
                        Number(sub.totals.censusDebit).toFixed(2),
                        Number(sub.totals.censusCredit).toFixed(2),
                        Number(sub.totals.moveDebit).toFixed(2),
                        Number(sub.totals.moveCredit).toFixed(2),
                        Number(sub.totals.progressivelyDebit).toFixed(2),
                        Number(sub.totals.progressivelyCredit).toFixed(2),
                        Number(sub.totals.balanceDebit).toFixed(2),
                        Number(sub.totals.balanceCredit).toFixed(2)
                    ]);
                }
            }
            rows.push([
                `Σύνολα Υπολογαριασμού ${table.code} ${table.name}`,
                "",
                "",
                "",
                "",
                Number(table.totals.censusDebit).toFixed(2),
                Number(table.totals.censusCredit).toFixed(2),
                Number(table.totals.moveDebit).toFixed(2),
                Number(table.totals.moveCredit).toFixed(2),
                Number(table.totals.progressivelyDebit).toFixed(2),
                Number(table.totals.progressivelyCredit).toFixed(2),
                Number(table.totals.balanceDebit).toFixed(2),
                Number(table.totals.balanceCredit).toFixed(2)
            ]);
        }
        const workSheet = XLSX.utils.aoa_to_sheet(rows);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Καρτέλα Λογαριασμών");
        XLSX.writeFile(workBook, 'Καρτέλα Λογαριασμών.xlsx');
    }
    const handlePDFExport = () => {
        const doc = new jsPDF('landscape');
        let y = 23;
        doc.addFileToVFS("arialuni.ttf", liberationSerif);
        doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
        doc.addFileToVFS("dejavusans.ttf", dejavuSans);
        doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
        doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
        doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
        doc.setFont("DejaVu Sans Bold");
        doc.setFontSize(12);
        let dateFrom = requestData.date_from;
        let dateTo = requestData.date_to;
        if (isEmpty(dateFrom)) dateFrom = moment(company.year, "YYYY", true).startOf("year").format("DD/MM/YYYY");
        if (isEmpty(dateTo)) dateTo = moment(company.year, "YYYY", true).endOf("year").format("DD/MM/YYYY");
        const header = doc.splitTextToSize(`Καρτέλα Λογαριασμών από ${dateFrom} έως ${dateTo}`, 200);
        const headerString = header.join("\n");
        doc.text(headerString, doc.internal.pageSize.getWidth() / 4, 9, {alignment: "center"});
        doc.setFont("DejaVu Sans Normal");
        doc.setFontSize(9);
        doc.text(`${company.name}, ΑΦΜ: ${company.vatNumber}`, 15, 17, {alignment: "center"});
        doc.text(`ΔΟΥ: ${companyDetails.doy}`, 15, 21, {alignment: "center"});
        doc.text(`Ημερομηνία Εξαγωγής: ${moment().format("DD/MM/YYYY")}`, 190, 21, {alignment: "center"});
        const imgData = 'assets/images/prrimer-logo.png';
        const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
        const imgY = 3; // adjust the position as needed
        doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
        doc.setFont("DejaVu Sans Normal");
        const columns1 = [{content: "", colSpan: 5}, {content: "Απογραφή", colSpan: 2}, {content: "Κίνηση", colSpan: 2}, {content: "Προοδευτικά", colSpan: 2}, {content: "Υπόλοιπο", colSpan: 2}]
        const columns2 = ["Α/Α", "Ημερ/νία", "Αρ. Άρθρου", "Πρότυπο", "Περιγραφή", "Χρέωση", "Πίστωση", "Χρέωση", "Πίστωση", "Χρέωση", "Πίστωση", "Χρέωση", "Πίστωση"];
        let rows = [];
        for (let table of responseData) {
            rows.push([{content: `${table.code} ${table.name}`, colSpan: 13, styles: {halign: "left", fillColor: "#decae3"}}])
            for (let sub of table.subAccounts) {
                if(sub.subAccount !== "") {
                    rows.push([{content: `${table.code} ${sub.subAccount}`, colSpan: 13, styles: {halign: "left", fillColor: "#f1fdff"}}]);
                }
                for (let article of sub.articles) {
                    rows.push([
                        article.counter,
                        article.date,
                        article.number,
                        article.subCategoryName,
                        article.reason,
                        Number(article.censusDebit).toFixed(2),
                        {content: Number(article.censusCredit).toFixed(2), styles: {fillColor: "#bdfcce"}},
                        Number(article.moveDebit).toFixed(2),
                        {content: Number(article.moveCredit).toFixed(2), styles: {fillColor: "#bdfcce"}},
                        Number(article.progressivelyDebit).toFixed(2),
                        {content: Number(article.progressivelyCredit).toFixed(2), styles: {fillColor: "#bdfcce"}},
                        Number(article.balanceDebit).toFixed(2),
                        {content: Number(article.balanceCredit).toFixed(2), styles: {fillColor: "#bdfcce"}}
                    ]);
                }
                if(sub.subAccount !== "") {
                    rows.push([
                        {content: `Σύνολα Υπολογαριασμού ${table.code} ${sub.subAccount}`, colSpan: 5, styles: {halign: "left", fillColor: "#f1fdff"}},
                        {content: Number(sub.totals.censusDebit).toFixed(2), styles: {fillColor: "#f1fdff"}},
                        {content: Number(sub.totals.censusCredit).toFixed(2), styles: {fillColor: "#f1fdff"}},
                        {content: Number(sub.totals.moveDebit).toFixed(2), styles: {fillColor: "#f1fdff"}},
                        {content: Number(sub.totals.moveCredit).toFixed(2), styles: {fillColor: "#f1fdff"}},
                        {content: Number(sub.totals.progressivelyDebit).toFixed(2), styles: {fillColor: "#f1fdff"}},
                        {content: Number(sub.totals.progressivelyCredit).toFixed(2), styles: {fillColor: "#f1fdff"}},
                        {content: Number(sub.totals.balanceDebit).toFixed(2), styles: {fillColor: "#f1fdff"}},
                        {content: Number(sub.totals.balanceCredit).toFixed(2), styles: {fillColor: "#f1fdff"}}
                    ]);
                }
            }
            rows.push([
                {content: `Σύνολα Υπολογαριασμού ${table.code} ${table.name}`, colSpan: 5, styles: {halign: "left", fillColor: "#decae3"}},
                {content: Number(table.totals.censusDebit).toFixed(2), styles: {fillColor: "#decae3"}},
                {content: Number(table.totals.censusCredit).toFixed(2), styles: {fillColor: "#decae3"}},
                {content: Number(table.totals.moveDebit).toFixed(2), styles: {fillColor: "#decae3"}},
                {content: Number(table.totals.moveCredit).toFixed(2), styles: {fillColor: "#decae3"}},
                {content: Number(table.totals.progressivelyDebit).toFixed(2), styles: {fillColor: "#decae3"}},
                {content: Number(table.totals.progressivelyCredit).toFixed(2), styles: {fillColor: "#decae3"}},
                {content: Number(table.totals.balanceDebit).toFixed(2), styles: {fillColor: "#decae3"}},
                {content: Number(table.totals.balanceCredit).toFixed(2), styles: {fillColor: "#decae3"}},
            ]);
            rows.push([]);
        }
        doc.autoTable({
            startY: y,
            head: [columns1, columns2],
            body: rows,
            rowPageBreak: "avoid",
            styles: {
                font: "DejaVu Sans Normal",
                fontSize: 8, // set font size
                halign: "center", // set alignment
            },
        })
        const pageCount = doc.getNumberOfPages();
        // Add the pagination to each page
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
        }
        doc.save("Καρτέλα Λογαριασμών.pdf");
    }


    return (
        <React.Fragment>
            {loading && (<Loader doNotShowSmallText={true} />)}
            <div ref={pageRef} key={"wholeBody"} hidden={loading} style={classicStyleBelowNavbar}
                 onKeyDown={(e) => handleEnter(e)}>
                <Row key={"filters"} onBlur={() => setSliceRows(false)} className={"mb-2"}>
                    <Col md={2}>
                        <DateHelper
                            dateFromAndToFunction={(from, to) => dispatch(setAccountsReportRequestData({...requestData, date_from: from, date_to: to}))}
                        />
                    </Col>
                    <Col md={2}>
                        <DateBox
                            name="date_from"
                            selected={requestData.date_from !== "" ? moment(requestData.date_from, "DD-MM-YYYY").toDate() : ""}
                            label={"Ημερομηνία από"}
                            onChange={(e) => handleFilterChange(e, "datebox", "date_from")}
                        />
                    </Col>
                    <Col md={2}>
                        <DateBox
                            name="date_to"
                            selected={requestData.date_to !== "" ? moment(requestData.date_to, "DD-MM-YYYY").toDate() : ""}
                            label={"Ημερομηνία μέχρι"}
                            onChange={(e) => handleFilterChange(e, "datebox", "date_to")}
                        />
                    </Col>
                    <Col md={3}>
                        <Dropdown
                            name={"installation"}
                            label={"Εγκατάσταση"}
                            options={installationOptions}
                            key={Math.random()}
                            defaultValue={installationOptions.find((el) => el.value === requestData.installation) ? installationOptions.find((el) => el.value === requestData.installation) : ""}
                            onChange={(e) => handleFilterChange(e, "dropdown", "installation")}
                        />
                    </Col>
                    <Col md={3}>
                        <Input
                            name={"code_prefix"}
                            label={"Πρόθεμα κωδικού"}
                            placeholder={"Πρόθεμα κωδικού"}
                            value={requestData.code_prefix}
                            autoComplete={"off"}
                            key={"code_prefix"}
                            onChange={(e) => handleFilterChange(e, "input", "code_prefix")}
                        />
                    </Col>
                </Row>
                <Row key={"filters2"}>
                    <Col md={4}>
                        <AdvancedCombobox
                            name={"subAccount"}
                            className="w-100 ac-select-dropdown"
                            searchableDropdownEnable={false}
                            label={"Υπολογαριασμός"}
                            placeholder={"Πληκτρολογήστε υπολογαριασμό"}
                            defaultValue={requestData.subAccountInput}
                            data={defaultSubaccountData}
                            onChange={(e) => handleFilterChange(e, "acdd", "subAccount")}
                            excludeColumns={[]}
                            columns={[
                                {label: "Όνομα", name: "name"},
                                {label: "Τύπος", name: "type"},
                            ]}
                            editEnable={false}
                            deleteEnable={false}
                            searchTableEnable={false}
                            showTableOptionOnSelect={true}
                            key={Math.random()}
                            onChangeSearch={onChangeSubaccount}
                            disabled={!isEmpty(requestData.subAccount)}
                        />
                    </Col>
                    <Col md={1}>
                        {!isEmpty(requestData.subAccount) &&
                            <button style={{position: "absolute", bottom: "16px"}}
                                    className="btn btn-sm"
                                    title="Καθαρισμός υπολογαριασμού"
                                    onClick={() => dispatch(setAccountsReportRequestData({...requestData, subAccount: "", subAccountInput: ""}))}><i className="fas fa-xmark"></i></button>
                        }
                    </Col>
                    <Col md={2}>
                        <Button onClick={() => fetchReport()} className={"mt-3"}>Ανανέωση</Button>
                    </Col>
                </Row>
                {responseData.length > 0 ? (
                    <React.Fragment>
                        <Badge key={"b1"} bg={"primary"} style={{userSelect: "none", cursor: "pointer"}} onClick={() => dispatch(setAccountsReportDisplayData({...displayData, fontSize: displayData.fontSize + 1}))}>+</Badge>
                        <Badge key={"b2"} bg={"primary"} style={{userSelect: "none"}} className={"ml-1"}>Μέγεθος: {displayData.fontSize}</Badge>
                        <Badge key={"b3"} bg={"primary"} style={{userSelect: "none", cursor: "pointer"}} className={"ml-1 mt-2"} onClick={() => dispatch(setAccountsReportDisplayData({...displayData, fontSize: displayData.fontSize - 1}))}>-</Badge>
                        <Badge key={"b4"} bg={"primary"} style={{userSelect: "none", cursor: "pointer"}} className={"ml-4"} onClick={() => handleExcelExport()}>Εξαγωγή XLSX</Badge>
                        <Badge key={"b5"} bg={"primary"} style={{userSelect: "none", cursor: "pointer"}} className={"ml-1 mb-3"} onClick={() => handlePDFExport()}>Εξαγωγή PDF</Badge>
                    </React.Fragment>
                ) : (
                    <h6 key={"text1"} className={"mt-1"}>Δεν βρέθηκαν δεδομένα</h6>
                )}
                {!render && (
                    <h6 key={"text1"} className={"mt-1"}>Η σελίδα φορτώνει, παρακαλώ περιμένετε...</h6>
                )}
                {render && responseData.length > 0 && responseData.map((table) => (
                    <table className={"accountReportTable"} key={`${table.code}-${table.name}`}>
                        <tbody style={{textAlign: "center"}} key={"headers"}>
                            <tr style={{backgroundColor: "#decae3", lineHeight: "20px"}} key={"headFirstLine"}>
                                <th style={{...tdStyle3, textAlign: "left"}} colSpan={5}>{`${table.code} ${table.name}`}</th>
                                <th style={tdStyle3} colSpan={2}>Απογραφή</th>
                                <th style={tdStyle3} colSpan={2}>Κίνηση</th>
                                <th style={tdStyle3} colSpan={2}>Προοδευτικά</th>
                                <th style={tdStyle3} colSpan={2}>Υπόλοιπο</th>
                            </tr>
                            <tr style={{backgroundColor: "#f1fdff", lineHeight: "20px"}} key={"headSecondLine"}>
                                <th style={tdStyle2}>Α/Α</th>
                                <th style={tdStyle2}>Ημερ/νία</th>
                                <th style={tdStyle2}>Αρ. Άρθρου</th>
                                <th style={tdStyle2}>Πρότυπο</th>
                                <th style={tdStyle2}>Περιγραφή</th>
                                <th style={tdStyle2}>Χρέωση</th>
                                <th style={tdStyle2}>Πίστωση</th>
                                <th style={tdStyle2}>Χρέωση</th>
                                <th style={tdStyle2}>Πίστωση</th>
                                <th style={tdStyle2}>Χρέωση</th>
                                <th style={tdStyle2}>Πίστωση</th>
                                <th style={tdStyle2}>Χρέωση</th>
                                <th style={tdStyle2}>Πίστωση</th>
                            </tr>
                        </tbody>
                        <tbody style={{textAlign: "center"}} key={"data"}>
                            {table.subAccounts.length > 0 && table.subAccounts.map((sub, idx) => (
                                <React.Fragment>
                                    {sub.subAccount !== "" && (
                                        <tr style={{textAlign: "left", backgroundColor: "#f1fdff", lineHeight: "20px"}} key={"headerSubAccount"}>
                                            <th style={tdStyle2} colSpan={13}>{table.code} {sub.subAccount}</th>
                                        </tr>
                                    )}
                                    {(() => {
                                        let articlesToRender = sub.articles;
                                        if(sliceRows) {
                                            articlesToRender = sub.articles.slice(0, 30);
                                        }
                                        return (
                                            <React.Fragment>
                                                {articlesToRender.length > 0 && articlesToRender.map((article) => (
                                                    <tr className={"reportTableRow"} style={{lineHeight: "20px"}} key={article.counter} onDoubleClick={() => handleDoubleClick(article._id)}>
                                                        <td style={tdStyle2}>{article.counter}</td>
                                                        <td style={tdStyle2}>{article.date}</td>
                                                        <td style={tdStyle2}>{article.number}</td>
                                                        <td style={tdStyle2}>{article.subCategoryName}</td>
                                                        <td style={tdStyle2}>{article.reason}</td>
                                                        <td style={tdStyle2}>{Number(article.censusDebit).toFixed(2)}</td>
                                                        <td style={{...tdStyle, ...tdStyle2}}>{Number(article.censusCredit).toFixed(2)}</td>
                                                        <td style={tdStyle2}>{Number(article.moveDebit).toFixed(2)}</td>
                                                        <td style={{...tdStyle, ...tdStyle2}}>{Number(article.moveCredit).toFixed(2)}</td>
                                                        <td style={tdStyle2}>{Number(article.progressivelyDebit).toFixed(2)}</td>
                                                        <td style={{...tdStyle, ...tdStyle2}}>{Number(article.progressivelyCredit).toFixed(2)}</td>
                                                        <td style={tdStyle2}>{Number(article.balanceDebit).toFixed(2)}</td>
                                                        <td style={{...tdStyle, ...tdStyle2}}>{Number(article.balanceCredit).toFixed(2)}</td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        )
                                    })()}
                                    {sub.subAccount !== "" && (
                                        <React.Fragment>
                                            <tr style={{backgroundColor: "#f1fdff", lineHeight: "20px"}} key={"subTotals" + String(idx)}>
                                                <th style={{...tdStyle2, textAlign: "left"}} colSpan={5}>Σύνολα Υπολογαριασμού {`${table.code} ${sub.subAccount}`}</th>
                                                <th style={tdStyle2}>{Number(sub.totals.censusDebit).toFixed(2)}</th>
                                                <th style={tdStyle2}>{Number(sub.totals.censusCredit).toFixed(2)}</th>
                                                <th style={tdStyle2}>{Number(sub.totals.moveDebit).toFixed(2)}</th>
                                                <th style={tdStyle2}>{Number(sub.totals.moveCredit).toFixed(2)}</th>
                                                <th style={tdStyle2}>{Number(sub.totals.progressivelyDebit).toFixed(2)}</th>
                                                <th style={tdStyle2}>{Number(sub.totals.progressivelyCredit).toFixed(2)}</th>
                                                <th style={tdStyle2}>{Number(sub.totals.balanceDebit).toFixed(2)}</th>
                                                <th style={tdStyle2}>{Number(sub.totals.balanceCredit).toFixed(2)}</th>
                                            </tr>
                                            {(idx !== table.subAccounts.length - 1) && (<tr><td colSpan={13}></td></tr>)}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            ))}
                        </tbody>
                        <tbody style={{textAlign: "center"}}>
                            <tr style={{backgroundColor: "#decae3", lineHeight: "20px"}} key={"totals"}>
                                <th style={{...tdStyle3, textAlign: "left"}} colSpan={5}>Σύνολα Λογαριασμού {`${table.code} ${table.name}`}</th>
                                <th style={tdStyle3}>{Number(table.totals.censusDebit).toFixed(2)}</th>
                                <th style={tdStyle3}>{Number(table.totals.censusCredit).toFixed(2)}</th>
                                <th style={tdStyle3}>{Number(table.totals.moveDebit).toFixed(2)}</th>
                                <th style={tdStyle3}>{Number(table.totals.moveCredit).toFixed(2)}</th>
                                <th style={tdStyle3}>{Number(table.totals.progressivelyDebit).toFixed(2)}</th>
                                <th style={tdStyle3}>{Number(table.totals.progressivelyCredit).toFixed(2)}</th>
                                <th style={tdStyle3}>{Number(table.totals.balanceDebit).toFixed(2)}</th>
                                <th style={tdStyle3}>{Number(table.totals.balanceCredit).toFixed(2)}</th>
                            </tr>
                        </tbody>
                    </table>
                ))}
            </div>
        </React.Fragment>
    )
}

export default AccountingReportAccounts;