import React, {useEffect, useState} from "react";
import {classicStyleBelowNavbar} from "../Statics";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";
import {
    resetPreviewFile, setAutoDir, setAutoPreview,
    setCopyFileCut,
    setCopyFilePath,
    setCurrentPath,
    setCurrentPathLocked,
    setDirData,
    setDisableCloudPage,
    setExtraData,
    setPreviewFile, setSearchKeyword
} from "../../../../_reducers/cloudSlice";
import FileBrowser from "./Components/FileBrowser";
import Button from "react-bootstrap/Button";
import {
    arrayBufferToBase64,
    convertToBase64,
    formatInstallation,
    formatPath,
    getMimeType,
    popExtension
} from "./Components/constants";
import {Breadcrumb, Col, OverlayTrigger, ProgressBar, Row, Spinner, Tooltip} from "react-bootstrap";
import {checkPermission, isEmpty} from "../../../../_helpers/commonFunctions";
import FilePreviewer from "./Components/FilePreviewer";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import EmronCloudTotals from "./EmronCloudTotals";
import * as pdfjsLib from 'pdfjs-dist/webpack'
import jsPDF from "jspdf";
import Input from "../../../common/Input";
import { PDFDocument } from 'pdf-lib';

const Cloud = () => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const currentPath = useSelector((state) => state.CLOUD.currentPath);
    const currentPathLocked = useSelector((state) => state.CLOUD.currentPathLocked);
    const dirData = useSelector((state) => state.CLOUD.dirData);
    const extraData = useSelector((state) => state.CLOUD.extraData);
    const previewFile = useSelector((state) => state.CLOUD.previewFile);
    const copyFilePath = useSelector((state) => state.CLOUD.copyFilePath);
    const copyFileCut = useSelector((state) => state.CLOUD.copyFileCut);
    const autoPreview = useSelector((state) => state.CLOUD.autoPreview);
    const searchKeyword = useSelector((state) => state.CLOUD.searchKeyword);
    const autoDir = useSelector((state) => state.CLOUD.autoDir);

    const disableCloudPage = useSelector((state) => state.CLOUD.disableCloudPage);

    const [loading, setLoading] = useState(false);
    const [loadingPreview, setLoadingPreview] = useState(false);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0); // Percentage uploaded

    const [overwriteModal, setOverwriteModal] = useState(false);
    const [tempUploadFiles, setTempUploadFiles] = useState([]); // Used when overwriting is required

    const [showFileHistory, setShowFileHistory] = useState(false);
    const [fileHistoryData, setFileHistoryData] = useState({});

    const cloudSpaceProgressBar = extraData.spaceUsed / extraData.cloudSpace * 100;

    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const isEmron = checkPermission("custom-logistirio", permissionsData);
    const pathLength = String(formatPath(currentPath)).split("/").length;
    const canUpload = extraData.specialCode !== "1" || (extraData.specialCode === "1" && !dirData.some((el) => el.folder) && !currentPathLocked) || (isEmron && pathLength === 5);
    const hasPreview = !isEmpty(previewFile.name) || loadingPreview;

    useEffect(() => {
        onDirectoryRefresh(true, true);
        if (!isEmpty(autoPreview)) {
            onFileLeftClick(autoPreview).then(() => {
                dispatch(setAutoPreview(""));
            });
        }
    }, [])

    useEffect(() => {
        if (!isEmpty(autoDir)) {
            onDirectoryChange(autoDir);
            dispatch(setAutoDir(""));
        }
    }, [autoDir])

    useEffect(() => {
        if (!showFileHistory) setFileHistoryData({});
    }, [showFileHistory])

    useEffect(() => {
        if (!overwriteModal) setTempUploadFiles([]);
    }, [overwriteModal])

    const fetchExtraData = (fetchEmronTotals = false, fetchUsage = false, path = "/") => {
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/extra-data`, {
            company: company.id,
            year: company.year,
            inLegal: String(path).includes("/Νομιμοποιητικά Έγγραφα"),
            fetchTotals: String(fetchEmronTotals),
            fetchUsage: String(fetchUsage),
        }).then((res) => {
            if (res.data.status === "200") {
                dispatch(setExtraData({...extraData, ...res.data.data}));
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onDirectoryChange = (path = "/", fetchEmronTotals = false, fetchUsage = false) => {
        fetchExtraData(fetchEmronTotals, fetchUsage, path);
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "dir",
            currentPath: path,
            company: company.id,
            year: company.year,
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                dispatch(setDirData(res.data.data));
                dispatch(setCurrentPath(path));
                if (!isEmpty(res.data.currentPathLocked)) dispatch(setCurrentPathLocked(res.data.currentPathLocked));
                if (!res.data.data?.some((el) => el.name === previewFile.name)) dispatch(resetPreviewFile());
                if (!isEmpty(searchKeyword)) dispatch(setSearchKeyword(""));
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const fetchFileAsBlob = async (name) => {
        setLoading(true);
        // returns error message else blob
        try {
            const fetchResp = await axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
                action: "fetch",
                currentPath: formatPath(currentPath + "/" + name),
                company: company.id,
                year: company.year,
            });
            if (fetchResp.data.status === "200") {
                setLoading(false);
                const binaryString = atob(fetchResp.data.data);
                const byteArray = new Uint8Array(binaryString.split('').map((char) => char.charCodeAt(0)));
                return new Blob([byteArray], {type: getMimeType(name)});
            } else {
                setLoading(false);
                return null;
            }
        } catch (err) {
            setLoading(false);
            return null;
        }
    }

    const fetchFileAsBlobURL = async (name, attemptConversion = true) => {
        setLoading(true);
        // returns error message else blob url
        try {
            const fetchResp = await axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
                action: "fetch",
                attemptConversion: String(attemptConversion),
                currentPath: formatPath(currentPath + "/" + name),
                company: company.id,
                year: company.year,
            });
            if (fetchResp.data.status === "200") {
                const fromConversion = fetchResp.data.fromConversion;
                setLoading(false);
                const binaryString = atob(fetchResp.data.data);
                const byteArray = new Uint8Array(binaryString.split('').map((char) => char.charCodeAt(0)));
                let mimeType = getMimeType(name);
                if (fromConversion) mimeType = "application/pdf";

                const blob = new Blob([byteArray], { type: mimeType });
                let blobUrl = URL.createObjectURL(blob);
                if (popExtension(name) === "pdf" || fromConversion) blobUrl += "#navpanes=0&view=Fit";

                return blobUrl;
            } else {
                setLoading(false);
                return null;
            }
        } catch (err) {
            setLoading(false);
            return null;
        }
    }

    const onFileLeftClick = async (name) => {
        if (loading) return toast.error("Παρακαλώ περιμένετε να φορτώσει η προηγούμενη προεπισκόπηση.");

        setLoadingPreview(true);
        const data = await fetchFileAsBlobURL(name, true);
        setLoadingPreview(false);

        if (data) {
            dispatch(setPreviewFile({
                name: name,
                path: formatPath(currentPath + "/" + name),
                data: data,
            }));
        } else {
            toast.error("Σφάλμα κατά την άντληση αρχείου.");
        }
    }

    const onFolderLeftClick = (name) => {
        onDirectoryChange(formatPath(currentPath + "/" + name));
    }

    const onDirectoryBack = () => {
        let split = String(currentPath).split("/").filter((el) => !isEmpty(el));
        if (split.length >= 2) {
            let prePath = split.filter((el) => !isEmpty(el)).slice(0, split.length - 1);
            onDirectoryChange(formatPath("/" + prePath.join("/")));
        } else if (split.length === 1) {
            onDirectoryChange();
        }
    }

    const onDirectoryRefresh = (fetchEmronTotals = false, fetchUsage = false) => {
        onDirectoryChange(currentPath, fetchEmronTotals, fetchUsage);
    }

    const onFilesDrop = (files, overwrite = false, successfulCallback) => {
        /*
        files is array of objects like
        [{
            fileName: "...",
            base64Encoded: "..."
        }]
         */
        // Quick validations
        for (let dat of files) {
            let mbSize = new TextEncoder().encode(JSON.stringify(dat.base64Encoded)).length / (1024 * 1024);
            mbSize = 3 / 4 * mbSize;
            if (mbSize > extraData.cloudMaximumFileSize) {
                return toast.error(`Το αρχείο με όνομα ${dat.fileName} ξεπερνά το όριο μέγιστου μεγέθους αρχείου (${extraData.cloudMaximumFileSize} MB). Δεν έγινε μεταφόρτωση κανενός αρχείου.`, {autoClose: 12000});
            }
        }
       /* if (!overwrite) {
            for (let dat of files) {
                if (dirData.some((el) => el.name === dat.fileName && !el.folder)) {
                    setTempUploadFiles(files);
                    setOverwriteModal(true);
                    return;
                }
            }
        } */
        const cloneFiles = overwrite ? structuredClone(tempUploadFiles) : files;
        if (overwriteModal || tempUploadFiles.length > 0) {
            setOverwriteModal(false);
            setTempUploadFiles([]);
        }

        // Upload
        setLoadingUpload(true);
        setUploadProgress(0);
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "upload",
            currentPath: formatPath(currentPath + "/"),
            data: cloneFiles,
            company: company.id,
            year: company.year,
        }, {
            onUploadProgress: (progressEvent) => {
                const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent?.target?.getResponseHeader('content-length') || progressEvent?.target?.getResponseHeader('x-decompressed-content-length');
                if (totalLength !== null) setUploadProgress(Math.round( (progressEvent.loaded * 100) / totalLength));
            }
        }).then((res) => {
            setLoadingUpload(false);
            setUploadProgress(0);
            if (res.data.status === "200") {
                toast.success(`Επιτυχής μεταφορά ${cloneFiles.length === 1 ? "αρχείου" : "αρχείων"}.`);
                onDirectoryRefresh(true, true);
                if (typeof successfulCallback === "function") successfulCallback();
            } else {
                toast.error(res.data.message, {autoClose: 10000});
            }
        }).catch((err) => {
            setLoadingUpload(false);
            setUploadProgress(0);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onNewFolder = (folderName) => {
        if (dirData.some((el) => el.name === folderName && el.folder)) {
            toast.error("Υπάρχει ήδη φάκελος με αυτό το όνομα.");
            return false;
        }
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "createFolder",
            currentPath: formatPath(currentPath + "/"),
            folderName: folderName,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής δημιουργία φακέλου.");
                onDirectoryRefresh();
            } else {
                if (res.data.specialCode === "1") {
                    toast.info(res.data.message);
                } else {
                    toast.error(res.data.message);
                }
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
        return true;
    }

    const onFileDelete = (fileName, deleteCause = "", showMsg = true) => {
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "deleteFile",
            currentPath: formatPath(currentPath + "/" + fileName),
            deleteCause: deleteCause,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                if (showMsg) toast.success("Επιτυχής διαγραφή αρχείου.");
                onDirectoryRefresh(true, true);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onFolderDelete = (folderName, deleteCause) => {
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "deleteFolder",
            currentPath: formatPath(currentPath + "/" + folderName),
            deleteCause: deleteCause,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής διαγραφή φακέλου.");
                onDirectoryRefresh(true, true);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const getBreadcrumbMapping = () => {
        let arr = [];
        let split = String(currentPath).split("/");
        split.filter((el) => !isEmpty(el)).forEach((item, idx) => {
            let prePath = split.filter((el) => !isEmpty(el)).slice(0, idx);
            arr.push({
                name: item,
                path: formatPath("/" + prePath.join("/") + "/" + item),
            })
        })
        return arr;
    }

    const onFileRename = (oldName, newName) => {
        if (dirData.some((el) => el.name === newName && !el.folder)) {
            toast.error(`Υπάρχει ήδη αρχείο με όνομα ${newName}`);
            return false;
        }
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "renameFile",
            currentPath: formatPath(currentPath + "/"),
            fileName: oldName,
            newFileName: newName,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής μετανομασία αρχείου.");
                onDirectoryRefresh();
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
        return true;
    }

    const onFolderRename = (oldName, newName) => {
        if (dirData.some((el) => el.name === newName && el.folder)) {
            toast.error(`Υπάρχει ήδη φάκελος με όνομα ${newName}`);
            return false;
        }
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "renameFolder",
            currentPath: formatPath(currentPath + "/"),
            folderName: oldName,
            newFolderName: newName,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής μετανομασία φακέλου.");
                onDirectoryRefresh();
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
        return true;
    }

    const onFileDownload = async (data, fileName, fetchNew = false, revokeURL = false) => {
        setLoading(true);
        let finalData = data;
        if (fetchNew || (String(fileName).endsWith(".doc") || String(fileName).endsWith(".docx")
            || String(fileName).endsWith(".ppt") || String(fileName).endsWith(".pptx")
            || String(fileName).endsWith(".xls") || String(fileName).endsWith(".xlsx"))) {
            const fetchFile = await fetchFileAsBlobURL(fileName, false);
            if (fetchFile) {
                finalData = fetchFile;
            } else {
                toast.error("Σφάλμα κατά την άντληση αρχείου.");
            }
        }
        const link = document.createElement("a");
        link.href = finalData;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        if (revokeURL) URL.revokeObjectURL(finalData);
        setLoading(false);
    }

    const onFileCopy = (fileName, isCut) => {
        const path = formatPath(currentPath + "/" + fileName);
        dispatch(setCopyFilePath(path));
        if (isCut) dispatch(setCopyFileCut(true));
    }

    const onFilePaste = () => {
        setLoading(true);
        const split = copyFilePath.split("/");
        const fileName = split[split.length - 1];
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "pasteFile",
            oldPath: copyFilePath,
            currentPath: formatPath(currentPath + "/" + fileName),
            isCut: copyFileCut,
            company: company.id,
            year: company.year,
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                if (copyFileCut) {
                    toast.success("Επιτυχής επικόλληση αρχείου.");
                } else {
                    toast.success("Επιτυχής αντιγραφή αρχείου.");
                }
                onDirectoryRefresh();
                if (copyFileCut) {
                    dispatch(setCopyFilePath(""));
                    dispatch(setCopyFileCut(false));
                }
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleFileUpload = async (e) => {
        const files = e.target.files;
        if (files.length) {
            for (let file of files) {
                if (file.size % 4096 === 0) {
                    return toast.error("Δεν είναι δυνατή η μεταφορά φακέλου ή άδειων αρχείων.");
                } else if (file.type === "") {
                    return toast.error("Δεν είναι δυνατή η μεταφορά άμορφου αρχείου.");
                }
            }
            const arr = [];
            for (let file of files) {
                const base64Encoded = await convertToBase64(file);
                arr.push({
                    fileName: file.name,
                    base64Encoded: base64Encoded,
                })
            }
            onFilesDrop(arr);
            document.getElementById("filesInput").value = null;
        }
    }

    const onFileHistory = (name, folder = false) => {
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "history",
            currentPath: formatPath(currentPath + "/" + name),
            folder: folder,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                setFileHistoryData(res.data.data);
                setShowFileHistory(true);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onResetPreview = () => {
        dispatch(resetPreviewFile());
    }

    const onNotesSave = (path, userNotes, accountantNotes) => {
        let prepReq = {
            action: "notes",
            currentPath: path,
            userNotes: userNotes,
            company: company.id,
            year: company.year,
        }
        if (isEmron) prepReq.accountantNotes = accountantNotes;

        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, prepReq).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής αποθήκευση.");
                onDirectoryRefresh();
            } else {
                toast.error(res.data.message);
                onDirectoryRefresh();
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const [proceedDoubleClick, setProceedDoubleClick] = useState(false);

    useEffect(() => {
        if (proceedDoubleClick) {
            setProceedDoubleClick(false);
            onPreviewNewTab(formatPath(currentPath + "/" + previewFile.name));
        }
    }, [proceedDoubleClick])

    const onFileDoubleClick = async (name) => {
        await onFileLeftClick(name);
        setProceedDoubleClick(true);
    }

    const onDocumentDecorrelation = (path, data) => {
        // Data is _id, section, label
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "dehook",
            currentPath: formatPath(path),
            documentHook: data,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής ενέργεια.");
                onDirectoryRefresh(false);
            } else {
                toast.error(res.data.message);
                onDirectoryRefresh(false);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onPDFToImages = async (name, deleteAfterConversion) => {
        // Fetch file as blob
        const blobData = await fetchFileAsBlob(name);
        if (blobData) {
            try {
                const pdf = await pdfjsLib.getDocument({ data: await blobData.arrayBuffer() }).promise;
                const files = [];

                for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                    const page = await pdf.getPage(pageNumber);
                    const viewport = page.getViewport({ scale: 2 }); // Adjust scale for quality
                    const canvas = document.createElement("canvas");
                    const context = canvas.getContext("2d");

                    canvas.width = viewport.width;
                    canvas.height = viewport.height;

                    const renderContext = { canvasContext: context, viewport }
                    await page.render(renderContext).promise;

                    // Convert canvas to base64 image
                    const imageData = canvas.toDataURL("image/png");
                    files.push({
                        fileName: `${String(name).replace(".pdf", "")}-IMG${pageNumber}.png`,
                        base64Encoded: String(imageData).split(",")[1],
                    });
                }
                onFilesDrop(files, false, () => {
                    if (deleteAfterConversion) {
                        onFileDelete(name, "Μετατροπή PDF σε εικόνες", false);
                    }
                })
            } catch (error) {
                console.error("Error rendering PDF from Blob:", error);
                return toast.error("Σφάλμα κατά την μετατροπή αρχείου αρχείου.");
            }
        } else {
            toast.error("Σφάλμα κατά την λήψη αρχείου.");
        }
    }

    const onImagesToPDF = async (fileSelections, deleteAfterConversion, customName) => {
        const blobData = [];
        for (let file of fileSelections) {
            const fileBlobData = await fetchFileAsBlob(file);
            if (fileBlobData) blobData.push(fileBlobData);
        }
        const pdf = new jsPDF();
        for (let i = 0; i < blobData.length; i++) {
            const blob = blobData[i];

            const imageUrl = URL.createObjectURL(blob);
            const img = new Image();
            img.src = imageUrl;
            await new Promise((resolve) => {
                img.onload = () => {
                    // Get image dimensions
                    const imgWidth = img.width;
                    const imgHeight = img.height;

                    // Get PDF page dimensions
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const pdfHeight = pdf.internal.pageSize.getHeight();

                    // Calculate image aspect ratio
                    const imgAspectRatio = imgWidth / imgHeight;

                    // Calculate PDF aspect ratio
                    const pdfAspectRatio = pdfWidth / pdfHeight;

                    // Initialize scaled dimensions
                    let scaledWidth, scaledHeight;

                    // Scale the image while maintaining aspect ratio
                    if (imgAspectRatio > pdfAspectRatio) {
                        // Image is wider compared to PDF
                        scaledWidth = pdfWidth;
                        scaledHeight = pdfWidth / imgAspectRatio;
                    } else {
                        // Image is taller or equally proportioned compared to PDF
                        scaledHeight = pdfHeight;
                        scaledWidth = pdfHeight * imgAspectRatio;
                    }

                    // Center the image
                    const x = (pdfWidth - scaledWidth) / 2;
                    const y = (pdfHeight - scaledHeight) / 2;

                    const canvas = document.createElement("canvas");
                    canvas.width = img.width;
                    canvas.height = img.height;

                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    const base64Image = canvas.toDataURL("image/jpeg");

                    // Add a new page for each image except the first
                    if (i > 0) pdf.addPage();

                    // Add image to PDF
                    pdf.addImage(base64Image, 'JPEG', x, y, scaledWidth, scaledHeight);

                    resolve();
                }
                img.onerror = (err) => {
                    console.error("Error loading image:", imageUrl, err);
                    resolve(); // Continue processing other images even if one fails
                }
            })
            URL.revokeObjectURL(imageUrl);
        }
        const base64PDF = pdf.output('datauristring').split("base64,")[1];

        onFilesDrop([{
            fileName: customName,
            base64Encoded: base64PDF,
        }], false, () => {
            if (deleteAfterConversion) {
                for (let fileName of fileSelections) {
                    onFileDelete(fileName, "Μετατροπή εικόνων σε .PDF", false);
                }
            }
        })
    }

    const onPDFConcat = async (fileSelections, deleteAfterConversion, customName) => {
        const blobData = [];
        for (let file of fileSelections) {
            const fileBlobData = await fetchFileAsBlob(file);
            if (fileBlobData) blobData.push(fileBlobData);
        }
        const mergedPdf = await PDFDocument.create();
        for (const blob of blobData) {
            const arrayBuffer = await blob.arrayBuffer();
            const pdfDoc = await PDFDocument.load(arrayBuffer);

            const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
            copiedPages.forEach((page) => mergedPdf.addPage(page));
        }
        const mergedPdfBytes = await mergedPdf.save();
        const base64PDF = arrayBufferToBase64(mergedPdfBytes);

        onFilesDrop([{
            fileName: customName,
            base64Encoded: base64PDF,
        }], false, () => {
            if (deleteAfterConversion) {
                for (let fileName of fileSelections) {
                    onFileDelete(fileName, "Μετατροπή εικόνων σε .PDF", false);
                }
            }
        })
    }

    const onMultipleDownloads = (data) => {
        setLoading(true);
        // data should have { files: [], folders: [] }
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "fetchMany",
            selections: data,
            currentPath: currentPath,
            company: company.id,
            year: company.year,
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                const binaryString = atob(res.data.data);
                const byteArray = new Uint8Array(binaryString.split('').map((char) => char.charCodeAt(0)));
                const blob = new Blob([byteArray], { type: "application/octet-stream" });
                const blobUrl = URL.createObjectURL(blob);

                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = "Cloud Files.zip";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                setTimeout(() => {
                    URL.revokeObjectURL(blobUrl); // Cleanup blob
                }, 60000)
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την λήψη.");
        })
    }

    const onMultipleDelete = (data, deleteCause) => {
        setLoading(true);
        // data should have { files: [], folders: [] }
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "multipleDelete",
            selections: data,
            currentPath: currentPath,
            deleteCause: deleteCause,
            company: company.id,
            year: company.year,
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                onDirectoryRefresh(false);
                toast.success("Επιτυχής ενέργεια.");
            } else {
                onDirectoryRefresh(false);
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onPDFToPDFs = async (name, exportData, deleteAfterConversion) => {
        /*
        exportData like [{
            id: ... (useless)
            fileName: ...
            selection: ...
        }]
         */
        // Fetch file as blob
        const blobData = await fetchFileAsBlob(name);
        if (blobData) {
            try {
                const arrayBuffer = await blobData.arrayBuffer();
                const pdf = await PDFDocument.load(arrayBuffer);
                const files = [];

                for (let d of exportData) {
                    const newPdfDoc = await PDFDocument.create();
                    let err = false;
                    let pages = [];
                    String(d.selection).replace(/ /g, "").split(",").forEach((o) => {
                        if (String(o).includes("-")) {
                            const split = String(o).split("-");
                            const from = Number(split[0]);
                            const to = Number(split[1]);
                            if (!isNaN(from) && !isNaN(to)) {
                                for (let i = from; i <= to; i++) pages.push(i);
                            }
                        } else if (!isNaN(Number(o))) {
                            pages.push(Number(o));
                        }
                    });
                    if (err) return toast.error(`Σφάλμα στην γραμμή ${d.id}. Δεν έγινε αναγνώριση σελιδών προς εξαγωγή`, {autoClose: 6000});

                    for (let pageNum of pages) {
                        if (pageNum < 1 || pageNum > pdf.numPages) continue;

                        const [copiedPage] = await newPdfDoc.copyPages(pdf, [pageNum - 1]); // Zero-indexed
                        newPdfDoc.addPage(copiedPage);
                    }
                    const newPdfBytes = await newPdfDoc.save();
                    const base64Encoded = arrayBufferToBase64(newPdfBytes);
                    files.push({
                        fileName: String(d.fileName).replace(/.pdf/g, "") + ".pdf",
                        base64Encoded: base64Encoded,
                    })
                }

                onFilesDrop(files, false, () => {
                    if (deleteAfterConversion) {
                        onFileDelete(name, "Μετατροπή PDF σε εικόνες", false);
                    }
                })
            } catch (error) {
                console.error("Error rendering PDF from Blob:", error);
                return toast.error("Σφάλμα κατά την μετατροπή αρχείου αρχείου.");
            }
        } else {
            toast.error("Σφάλμα κατά την λήψη αρχείου.");
        }
    }

    const howManyPages = async (path) => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
                action: "howManyPages",
                currentPath: path,
                company: company.id,
                year: company.year,
            });
            if (res.data.status === "200") {
                return res.data.data;
            }
        } catch (err) {
            console.log(err);
        }
        return 0;
    }

    /*
        Emron special functions below
     */

    const onEmronSort = (path, option = null, showMsg = true, refresh = true) => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "emronSort",
            currentPath: path,
            option: option,
            company: company.id,
            year: company.year,
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                if (showMsg) toast.success("Επιτυχής ενέργεια.");
                if (refresh) {
                    onDirectoryRefresh(true);
                    dispatch(resetPreviewFile());
                }
            } else {
                if (showMsg) toast.error(res.data.message);
                if (refresh) onDirectoryRefresh(true);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            if (showMsg) toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onEmronDelete = (selection, deleteCause = "") => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "emronDelete",
            currentPath: formatPath(currentPath + "/" + selection),
            deleteCause: deleteCause,
            company: company.id,
            year: company.year,
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                toast.success("Επιτυχής ενέργεια.");
                onDirectoryRefresh(true);
            } else {
                toast.error(res.data.message);
                onDirectoryRefresh(true);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onEmronMultipleDelete = (data, deleteCause) => {
        setLoading(true);
        // data should have { files: [], folders: [] }
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "emronMultipleDelete",
            selections: data,
            currentPath: currentPath,
            deleteCause: deleteCause,
            company: company.id,
            year: company.year,
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                onDirectoryRefresh(false);
                toast.success("Επιτυχής ενέργεια.");
            } else {
                onDirectoryRefresh(false);
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onEmronRestore = (path, showMsg = true, refresh = true) => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "emronRestore",
            currentPath: path,
            company: company.id,
            year: company.year,
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                if (showMsg) toast.success("Επιτυχής ενέργεια.");
                if (refresh) {
                    onDirectoryRefresh(true);
                    dispatch(resetPreviewFile());
                }
            } else {
                if (showMsg) toast.error(res.data.message);
                if (refresh) onDirectoryRefresh(true);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            if (showMsg) toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onEmronRegister = (path, option = null, showMsg = true, refresh = true) => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "emronRegister",
            currentPath: path,
            option: option,
            company: company.id,
            year: company.year,
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                if (showMsg) toast.success("Επιτυχής ενέργεια.");
                if (refresh) {
                    onDirectoryRefresh(true);
                    dispatch(resetPreviewFile());
                }
            } else {
                if (showMsg) toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            if (showMsg) toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onEmronMove = (path, option, month, showMsg = true, refresh = true) => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "emronMove",
            currentPath: path,
            option: option,
            monthTo: month,
            company: company.id,
            year: company.year,
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                if (showMsg) toast.success("Επιτυχής ενέργεια.");
                if (refresh) {
                    onDirectoryRefresh(true);
                    dispatch(resetPreviewFile());
                }
            } else {
                if (showMsg) toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            if (showMsg) toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onEmronUnlock = (name, days) => {
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "emronUnlock",
            currentPath: currentPath + "/" + name,
            days: days,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής ενέργεια.");
                onDirectoryRefresh(true);
            } else {
                toast.error(res.data.message);
                onDirectoryRefresh(true);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onPreviewNewTab = (path) => {
        if (extraData.specialCode === "1" && isEmron) {
            const timestamp = String(Date.now());
            const dataToSend = {
                name: previewFile.name,
                currentPath: path,
                data: previewFile.data,
                dirData: dirData,
                documentHooks: getPreviewFileDocumentHooks(),
            }
            sessionStorage.setItem(`emronCloudData-${timestamp}`, JSON.stringify(dataToSend));

            const newURL = new URL(window.location.href);
            newURL.searchParams.set("emronCloudDataTS", timestamp);

            const clientWidth = window.innerWidth;
            const clientHeight = window.innerHeight;
            const popUpWindow = window.open(newURL.toString(), "_blank", `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${clientWidth},height=${clientHeight}`);
            if (popUpWindow) {
                dispatch(setDisableCloudPage(true));
                const monitorPopup = setInterval(() => {
                    if (popUpWindow.closed) {
                        clearInterval(monitorPopup);
                        onDirectoryRefresh(true);
                        dispatch(setDisableCloudPage(false));
                        clearEmronCloudDataOnSS();
                    }
                }, 250);
            }
        } else {
            window.open(previewFile.data, "_blank");
        }
    }

    const clearEmronCloudDataOnSS = () => {
        const keys = Object.keys(sessionStorage).filter((el) => String(el).startsWith("emronCloudData-"));
        for (let key of keys) sessionStorage.removeItem(key);
    }

    const getPreviewFileDocumentHooks = () => {
        return dirData.find((el) => el.name === previewFile.name && !el.folder)?.documentHooks ? dirData.find((el) => el.name === previewFile.name && !el.folder).documentHooks : [];
    }

    const getPreviewFileUserNotes = () => {
        return dirData.find((el) => el.name === previewFile.name && !el.folder)?.userNotes ? dirData.find((el) => el.name === previewFile.name && !el.folder).userNotes : "";
    }

    const getPreviewFileAccountantNotes = () => {
        return dirData.find((el) => el.name === previewFile.name && !el.folder)?.accountantNotes ? dirData.find((el) => el.name === previewFile.name && !el.folder).accountantNotes : "";
    }

    const getFilterDirData = () => {
        if (!isEmpty(searchKeyword)) {
            return dirData.filter((el) => String(el.name).toLowerCase().replace(/ /g, "").includes(String(searchKeyword).toLocaleLowerCase().replace(/ /g, "")));
        }
        return dirData;
    }

    const disabledPageStyle = disableCloudPage ? {
        opacity: "0.3",
        pointerEvents: "none",
        userSelect: "none",
    } : {};

    return (
        <React.Fragment>
            {disableCloudPage && (
                <div style={{
                    left: "0",
                    width: "100%",
                    position: "absolute",
                    top: "40%",
                    fontSize: "32px",
                    fontWeight: "800",
                    opacity: "1",
                    textAlign: "center",
                    zIndex: "1000",
                }}>
                    Παρακαλώ τελειώστε την εργασία σας στην νέα καρτέλα
                </div>
            )}
            <div style={{...classicStyleBelowNavbar, ...disabledPageStyle}}>
                <Row>
                    <Col md={hasPreview ? 7 : 12}>
                        <Row>
                            <Col md={4} className={"mt-1"}>
                                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                    <Button size={"sm"} onClick={() => onDirectoryBack()} className={"mr-1"}
                                            style={{height: "45px", flex: "1 1"}} disabled={currentPath === "/"} title={"Προηγούμενος φάκελος"}>
                                        <i className="fa-solid fa-arrow-left"></i>
                                    </Button>
                                    <Button size={"sm"} style={{height: "45px", flex: "1 1"}} className={"mr-1"}
                                            onClick={() => onDirectoryRefresh()} title={"Ανανέωση φακέλου"}>
                                        <i className="fa-solid fa-arrows-rotate"></i>
                                    </Button>
                                    {canUpload && (
                                        <React.Fragment>
                                            <input type={"file"} style={{display: "none"}} id={"filesInput"}
                                                   onChange={(e) => handleFileUpload(e)}/>
                                            <Button size={"sm"} style={{height: "45px", flex: "1 1"}} className={"mr-1"}
                                                    onClick={() => document.getElementById("filesInput").click()} title={"Ανέβασμα αρχείων"}>
                                                <i className="fa-solid fa-upload"></i>
                                            </Button>
                                        </React.Fragment>
                                    )}
                                </div>
                            </Col>
                            <Col md={8} className={"mt-1"}>
                                <Breadcrumb>
                                    <Breadcrumb.Item onClick={() => onDirectoryChange("/")}>Αρχική</Breadcrumb.Item>
                                    {getBreadcrumbMapping().map((bc, idx) => (
                                        <Breadcrumb.Item key={idx} onClick={() => onDirectoryChange(bc.path)}>
                                            {(extraData.specialCode === "1" && !isNaN(bc.name)) ? (
                                                <React.Fragment>{formatInstallation(bc.name, companyInstallations)}</React.Fragment>
                                            ) : (
                                                <React.Fragment>{bc.name}</React.Fragment>
                                            )}
                                        </Breadcrumb.Item>
                                    ))}
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Input
                                    unlabbled={true}
                                    value={searchKeyword}
                                    placeholder={"Αναζήτηση εντός φακέλου"}
                                    onChange={(e) => dispatch(setSearchKeyword(e.target.value))}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className={"mt-1"} style={{pointerEvents: loading ? "none" : "", opacity: loading ? "0.8" : ""}}>
                                <FileBrowser
                                    dirData={getFilterDirData()}
                                    hasCopiedFile={!isEmpty(copyFilePath)}

                                    onFileLeftClick={onFileLeftClick}
                                    onFolderLeftClick={onFolderLeftClick}
                                    onFilesDrop={onFilesDrop}
                                    onNewFolder={onNewFolder}
                                    onFileDelete={onFileDelete}
                                    onFolderDelete={onFolderDelete}
                                    onFileRename={onFileRename}
                                    onFolderRename={onFolderRename}
                                    onFileDownload={onFileDownload}
                                    onFileCopy={onFileCopy}
                                    onFilePaste={onFilePaste}
                                    onFileHistory={onFileHistory}
                                    onFileDoubleClick={onFileDoubleClick}
                                    onPDFToImages={onPDFToImages}
                                    onImagesToPDF={onImagesToPDF}
                                    onMultipleDownloads={onMultipleDownloads}
                                    onPDFConcat={onPDFConcat}
                                    onMultipleDelete={onMultipleDelete}
                                    onPDFToPDFs={onPDFToPDFs}
                                    howManyPages={howManyPages}

                                    onEmronSort={onEmronSort}
                                    onEmronRestore={onEmronRestore}
                                    onEmronRegister={onEmronRegister}
                                    onEmronMove={onEmronMove}

                                    onEmronDelete={onEmronDelete}
                                    onEmronMultipleDelete={onEmronMultipleDelete}
                                    onEmronUnlock={onEmronUnlock}
                                    specialCode={extraData.specialCode}
                                    canUpload={canUpload}
                                    isEmron={isEmron}
                                />
                                {loading && (
                                    <Spinner size={"sm"} animation={"border"} variant={"dark"} style={{position: "absolute", right: "15px", bottom: "5px"}}></Spinner>
                                )}
                            </Col>
                            {(isEmron && extraData.specialCode === "1") && (
                                <Col md={12} className={"mt-1"}>
                                    <EmronCloudTotals
                                        emronTotals={extraData.emronTotals}
                                    />
                                </Col>
                            )}
                            <Col md={12} className={"mt-1"}>
                                <span>Αποθηκευτικός χώρος
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{show: 150, hide: 150}}
                                        overlay={
                                            <Tooltip id="button-tooltip">
                                                <span>Μέγιστο επιτρεπτό μέγεθος αρχείου {extraData.cloudMaximumFileSize} MB</span>
                                            </Tooltip>
                                        }
                                    >
                                        <strong>{"  "}<u>({extraData.spaceUsed} / {extraData.cloudSpace} MB) - {Number(cloudSpaceProgressBar).toFixed(2)}%</u></strong>
                                    </OverlayTrigger>
                                </span>
                                <div style={{position: "relative"}}>
                                    <ProgressBar variant={cloudSpaceProgressBar < 90 ? "info" : "danger"} striped
                                                 now={cloudSpaceProgressBar} style={{height: "20px", textAlign: "center"}}/>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={5} hidden={!hasPreview}>
                        <FilePreviewer
                            path={previewFile.path}
                            previewData={previewFile.data}
                            fileName={previewFile.name}
                            documentHooks={getPreviewFileDocumentHooks()}
                            userNotes={getPreviewFileUserNotes()}
                            loading={loadingPreview}
                            onFileDownload={onFileDownload}
                            onResetPreview={onResetPreview}
                            onPreviewNewTab={onPreviewNewTab}
                            onNotesSave={onNotesSave}
                            onDocumentDecorrelation={onDocumentDecorrelation}

                            onEmronSort={onEmronSort}
                            onEmronRestore={onEmronRestore}
                            onEmronRegister={onEmronRegister}
                            onEmronMove={onEmronMove}

                            specialCode={extraData.specialCode}
                            accountantNotes={getPreviewFileAccountantNotes()}
                            isEmron={isEmron}
                        />
                    </Col>
                </Row>
                <div style={{height: "200px"}}></div>

                <Modal backdrop={"static"} show={loadingUpload}>
                    <Modal.Header>
                        <Modal.Title>Μεταφόρτωση αρχείων</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={"mb-2"}>Γίνεται μεταφόρτωση αρχείων παρακαλώ περιμένετε...</div>
                        <ProgressBar variant={"info"} striped now={uploadProgress}/>
                    </Modal.Body>
                </Modal>

                <Modal backdrop={"static"} show={overwriteModal} onHide={() => setOverwriteModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Προσοχή!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Κάποια αρχεία υπάρχουν ήδη. Πατώντας αντικατάσταση θα αντικατασταθούν με τα καινούργια και τα
                        παλαιά θα διαγραφούν.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => onFilesDrop(tempUploadFiles, true)}>
                            Αντικατάσταση
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal backdrop={"static"} show={showFileHistory} onHide={() => setShowFileHistory(false)}
                       dialogClassName={"modal50PercentWidth"}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Ιστορικό
                            {Object.keys(fileHistoryData).length > 0 && (
                                <span>{" "}{fileHistoryData.folder ? "φακέλου" : "αρχείου"}
                                    <strong>{String(fileHistoryData.path).split("/")[String(fileHistoryData.path).split("/").length - 1]}</strong></span>
                            )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {Object.keys(fileHistoryData).length > 0 && (
                            <React.Fragment>
                                <table className={"simpleClassicTable mb-3"}>
                                    <colgroup>
                                        <col span={1} style={{width: "30%"}}></col>
                                        <col span={1} style={{width: "40%"}}></col>
                                        <col span={1} style={{width: "30%"}}></col>
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        <th>Ημερομηνία</th>
                                        <th>Ενέργεια</th>
                                        <th>Χρήστης</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {fileHistoryData.history.map((row, idx) => (
                                        <tr key={`fsHistory-${idx}`}>
                                            <td>{moment(row["date"]).format("DD/MM/YYYY HH:mm:ss")}</td>
                                            <td>{row["details"]}</td>
                                            <td>{row["user"]}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </React.Fragment>
                        )}
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default Cloud
