import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {Badge, Col, Row} from "react-bootstrap";
import DateBox from "../../../primer/DateBox";
import Button from "react-bootstrap/Button";
import moment from "moment";
import {
    setSupplierPaymentsReportRequestData, setSupplierPaymentsReportResponseData
} from "../../../../_reducers/DataPanel/Buys/buysSlice";
import {getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import axios from "axios";
import "jspdf-autotable";
import {
    checkPermission,
    getOptionsByData, isEmpty,
} from "../../../../_helpers/commonFunctions";
import Dropdown from "../../../primer/Dropdown";
import EditableTable from "../../../primer/Editable-Table";
import {fetchAdvancedTypes, fetchBuysGeneralSettings} from "../../../../_apis/api";
import {useTranslation} from "react-i18next";
import {classicStyleBelowNavbar} from "../Statics";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import Input from "../../../common/Input";

const BuysSupplierPaymentReport = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const requestData = useSelector((state) => state.BUYS.supplierPaymentsReportRequestData);
    const responseData = useSelector((state) => state.BUYS.supplierPaymentsReportResponseData);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const BUYS_SETTINGS = useSelector((state) => state.BUYS_SETTINGS);
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const [suppliersList, setSuppliersList] = useState([]);

    let paymentTypeOptions = getOptionsByData(BUYS_SETTINGS.buysGeneralSettings.paymentTypes, "_id", "name");

    const allDocumentTypes = SETTINGS.settings.advancedTypes;
    const supplierPaymentsDT = getObjectFromArrayOfObjects(allDocumentTypes, "Supplier Payments", "section");
    let invoiceTypes = getOptionsByData(supplierPaymentsDT, "_id", "name");

    const [loading, setLoading] = useState(false);
    const [pageChanged, setPageChanged] = useState(false);
    const [incrKey, setIncrKey] = useState(0);

    useEffect(() => {
        dispatch(fetchAdvancedTypes({ company: company.id }));
        dispatch(fetchBuysGeneralSettings({ company: company.id }));
        loadSuppliersListData(null, setSuppliersList);
        if (requestData.firstLoad) {
            const currentYear = Number(moment().format("YYYY"));
            if (Number(company.year) < currentYear) {
                dispatch(setSupplierPaymentsReportRequestData({...requestData,
                    ...requestData,
                    date_from: `01/01/${company.year}`,
                    date_to: `31/12/${company.year}`,
                    firstLoad: false,
                }));
            } else {
                dispatch(setSupplierPaymentsReportRequestData({...requestData,
                    ...requestData,
                    date_from: moment().startOf("month").format("DD/MM/YYYY"),
                    date_to: moment().endOf("month").format("DD/MM/YYYY"),
                    firstLoad: false,
                }));
            }
        }
    }, []);

    useEffect(() => {
        if (pageChanged) {
            setPageChanged(false);
            fetchReport(true);
        }
    }, [pageChanged])

    const columns = [
        {
            name: t("General.no"),
            field: "no",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.date"),
            field: "date",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.supplierName"),
            field: "supplierName",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.vatNumber"),
            field: "vatNumber",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.documentType"),
            field: "documentTypeName",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.numbering"),
            field: "number",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.paymentAmount"),
            field: "amount",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("General.paymentType"),
            field: "paymentTypeName",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
    ]

    const loadSuppliersListData = (keyword, setData) => {
        let reqData = {
            company: company.id,
        }
        if (keyword) reqData.name = keyword;
        axios.post(process.env.REACT_APP_API_URL2 + `/supplier/list-supplier`, reqData, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                if (res.data.data) {
                    if (setData) setData(res.data.data)
                } else {
                    if (setData) setData([])
                }
            } else {
                if (setData) setData([]);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const supplierDropdownColumns = [
        {
            label: t("General.supplierName"),
            name: "name",
        },
        {
            label: t("General.vatNumber"),
            name: "vatNumber",
        },
        {
            label: t("General.phone"),
            name: "phone",
        },
    ]

    const handleOnChange = (e, type, name) => {
        if (type === "datebox") {
            if (moment(e).isValid()) {
                dispatch(setSupplierPaymentsReportRequestData({...requestData, [name] : moment(e).format("DD/MM/YYYY")}));
            } else {
                dispatch(setSupplierPaymentsReportRequestData({...requestData, [name] : ""}));
            }
        } else if (type === "dropdown") {
            dispatch(setSupplierPaymentsReportRequestData({...requestData, [name] : e}));
        } else if (type === "acdd") {
            if (name === "supplierName") {
                dispatch(setSupplierPaymentsReportRequestData({...requestData, supplierName: e.row.name}));
            }
        } else if (type === "input") {
            dispatch(setSupplierPaymentsReportRequestData({...requestData, [name] : e.target.value}));
        }
    }

    const fetchReport = (fromPagination = false) => {
        setLoading(true);
        let reqData = {
            ...requestData,
            company: company.id,
            year: company.year,
            invoiceType: requestData.invoiceType.map(el => el.value),
            paymentType: requestData.paymentType.map(el => el.value),
        }
        if (!fromPagination) reqData.page = 1;
        axios.post(process.env.REACT_APP_API_URL2 + `/report/supplier-payments-report`, reqData, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                if (!fromPagination) {
                    if (res.data.totalPages === 0) {
                        toast.info("Δεν βρέθηκαν αποτελέσματα.");
                    } else {
                        toast.success("Τα δεδομένα ανανεώθηκαν.", {autoClose: 1000});
                    }
                    dispatch(setSupplierPaymentsReportRequestData({...requestData, page: 1}));
                }
                let cnt = 1;
                for (let d of res.data.data) {
                    d.no = String((requestData.page - 1) * 20 + cnt);
                    cnt++;
                }
                dispatch(setSupplierPaymentsReportResponseData({...requestData, data: res.data.data, totalPages: res.data.totalPages, totalPaymentAmount: res.data.totalPaymentAmount}));
                setIncrKey(incrKey + 1);
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            setLoading(false);
            console.log(error);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const toFirstPage = () => {
        dispatch(setSupplierPaymentsReportRequestData({...requestData, page: 1}));
        setPageChanged(true);
    }

    const toNextPage = () => {
        dispatch(setSupplierPaymentsReportRequestData({...requestData, page: requestData.page + 1}));
        setPageChanged(true);
    }

    const toPreviousPage = () => {
        dispatch(setSupplierPaymentsReportRequestData({...requestData, page: requestData.page - 1}));
        setPageChanged(true);
    }

    const toLastPage = () => {
        dispatch(setSupplierPaymentsReportRequestData({...requestData, page: responseData.totalPages}));
        setPageChanged(true);
    }

    const onChangeSearch = (keyword, setData) => {
        if (keyword.length >= 2) {
            loadSuppliersListData(keyword, setData)
        }
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row className={"mb-2"}>
                <Col md={3}>
                    <DateHelper
                        dateFromAndToFunction={(from, to) => dispatch(setSupplierPaymentsReportRequestData({
                            ...requestData,
                            date_from: from,
                            date_to: to
                        }))}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        name="date_from"
                        selected={isEmpty(requestData.date_from) ? moment().toDate() : moment(requestData.date_from, "DD/MM/YYYY").toDate()}
                        label={t('SalesBalanceSheet.dateFrom')}
                        onChange={(e) => handleOnChange(e, "datebox", "date_from")}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        name="date_to"
                        selected={isEmpty(requestData.date_to) ? moment().toDate() : moment(requestData.date_to, "DD/MM/YYYY").toDate()}
                        label={t('SalesBalanceSheet.dateTo')}
                        onChange={(e) => handleOnChange(e, "datebox", "date_to")}
                    />
                </Col>
                <Col md={5}>
                    <AdvancedCombobox
                        ACName="Buys Supplier" // do not change name
                        className="w-100 ac-select-dropdown"
                        searchableDropdownEnable={false}
                        defaultValue={requestData.supplierName}
                        label={"Προμηθευτής"}
                        data={suppliersList}
                        onChange={(e) => handleOnChange(e, "acdd", "supplierName")}
                        resetFilter={() => dispatch(setSupplierPaymentsReportRequestData({
                            ...requestData,
                            supplierName: ""
                        }))}
                        onChangeSearch={onChangeSearch}
                        excludeColumns={[]}
                        columns={supplierDropdownColumns}
                        editEnable={false}
                        deleteEnable={false}
                        searchTableEnable={false}
                        showTableOptionOnSelect={true}
                        placeholder={"Επιλέξτε προμηθευτή"}
                        required={true}
                        name="supplierName"
                        showInsertAddButton={true}
                        hideSaveButton={true}
                        hideInsertNewRow={true}
                        inputFieldClasses="form-control"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <Dropdown
                        name={"invoiceType"}
                        label={t('SalesBalanceSheet.invoiceType')}
                        options={invoiceTypes}
                        multiSelect={true}
                        onChange={(e) => handleOnChange(e, "dropdown", "invoiceType")}
                        defaultValue={requestData.invoiceType}
                    />
                </Col>
                <Col md={3}>
                    <Dropdown
                        name={"paymentType"}
                        label={t('SalesBalanceSheet.paymentType')}
                        options={paymentTypeOptions}
                        multiSelect={true}
                        onChange={(e) => handleOnChange(e, "dropdown", "paymentType")}
                        defaultValue={requestData.paymentType}
                    />
                </Col>
                <Col md={1}>
                    <Input
                        label={t('General.numberFrom')}
                        name={"numberFrom"}
                        placeholder={t('General.numberFrom')}
                        type={"number"}
                        value={requestData.numberFrom}
                        onChange={(e) => handleOnChange(e, "input", "numberFrom")}
                    />
                </Col>
                <Col md={1}>
                    <Input
                        label={t('General.numberTo')}
                        name={"numberTo"}
                        placeholder={t('General.numberTo')}
                        type={"number"}
                        value={requestData.numberTo}
                        onChange={(e) => handleOnChange(e, "input", "numberTo")}
                    />
                </Col>
            </Row>
            <Row className={"mb-2"}>
                <Col md={12} className={"ml-auto"}>
                    <Button onClick={() => fetchReport()} className="btn btn-primary float-right" disabled={loading}>
                        {t('General.applyFilter')}
                    </Button>
                </Col>
            </Row>
            {responseData.totalPages > 0 && (
                <Row>
                    <hr style={{color: "gray", height: 1}}/>
                    <Col md={12}>
                        <div className="d-flex justify-content-end mb-2">
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toFirstPage()}
                                disabled={requestData.page === 1 || loading}
                            >
                                {t('SalesBalanceSheet.first')}
                            </Button>
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toPreviousPage()}
                                disabled={requestData.page === 1 || loading}
                            >
                                {t('SalesBalanceSheet.previous')}
                            </Button>
                            <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1"
                                   bg="primary">{t('SalesBalanceSheet.page')} {requestData.page} / {responseData.totalPages}</Badge>
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toNextPage()}
                                disabled={requestData.page === responseData.totalPages || loading}
                            >
                                {t('SalesBalanceSheet.next')}
                            </Button>
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toLastPage()}
                                disabled={requestData.page === responseData.totalPages || loading}
                            >
                                {t('SalesBalanceSheet.last')}
                            </Button>
                        </div>
                    </Col>
                    <Col md={12}>
                        <EditableTable
                            key={"Supplier Payments Report" + incrKey}
                            tableName="Supplier Payments Report"
                            data={responseData.data}
                            allowPagination={false}
                            allowToggleColumns={true}
                            allowInsertRow={false}
                            editRedirectAllowed={true}
                            enableNewRowCheckbox={false}
                            allowBulkActions={true}
                            reportExcelPrint={true}
                            pageTotals={true}
                            company={company}
                            columns={columns}
                            allowActions={false}
                            allowPrintResults={checkPermission("buys-reports/supplierpaymentreport-supplierpaymentreport-print", permissionsData) === true}
                            totalPaymentAmountTrue={true}
                            totalPaymentAmount={responseData.totalPaymentAmount}
                            filters={requestData}
                        />
                    </Col>
                </Row>
            )}
        </div>
    )
}

export default BuysSupplierPaymentReport;
