import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    setEditVisit,
    setEditVisitLoading,
    setEditVisitReload
} from "../../../../_reducers/DataPanel/Prescription/prescriptionSlice";
import axios from "axios";
import {toast} from "react-toastify";
import {Col, Row, Spinner} from "react-bootstrap";
import Input from "../../../common/Input";
import moment from "moment/moment";
import TextArea from "../../../common/TextArea";
import Button from "react-bootstrap/Button";

const VisitEdit = () => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const editVisit = useSelector((state) => state.PRESCRIPTION.editVisit);
    const editVisitLoading = useSelector((state) => state.PRESCRIPTION.editVisitLoading);
    const editVisitReload = useSelector((state) => state.PRESCRIPTION.editVisitReload);

    const [loadingPrint, setLoadingPrint] = useState(false);
    const [loadingPrintReferrals, setLoadingPrintReferrals] = useState(false);

    useEffect(() => {
        if (editVisitReload) {
            dispatch(setEditVisitReload(false));
            fetchEditVisit();
        }
    }, [editVisitReload])

    const fetchEditVisit = () => {
        dispatch(setEditVisitLoading(true));
        axios.get(`${process.env.REACT_APP_API_URL2}/prescription/fetch-visit`, {
            headers: { 'Content-Type': 'application/json' },
            params: {
                id: editVisit.id,
                company: company.id
            }
        }).then((res) => {
            if (res.data.status === "200") {
                dispatch(setEditVisit(res.data.data));
            } else {
                toast.error(res.data.message);
            }
            dispatch(setEditVisitLoading(false));
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την άντληση επίσκεψης.");
            dispatch(setEditVisitLoading(false));
        })
    }

    const handlePrint = () => {
        setLoadingPrint(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/prescription/print-visit`, {
            company: company.id,
            id: editVisit.id
        }).then((res) => {
            if (res.data.status === "200") {
                const binaryString = atob(res.data.data);
                const byteArray = new Uint8Array(binaryString.split('').map((char) => char.charCodeAt(0)));
                const blob = new Blob([byteArray], { type: "application/pdf" });
                let blobUrl = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = `Επίσκεψη ${editVisit.id}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);
            } else {
                toast.error(res.data.message);
            }
            setLoadingPrint(false);
        }).catch((err) => {
            setLoadingPrint(false);
            console.log(err);
            toast.error("Σφάλμα κατά την άντληση.");
        })
    }

    const handlePrintReferrals = () => {
        setLoadingPrintReferrals(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/prescription/print-visit-referrals`, {
            company: company.id,
            visitId: editVisit.id,
        }).then((res) => {
            if (res.data.status === "200") {
                const binaryString = atob(res.data.data);
                const byteArray = new Uint8Array(binaryString.split('').map((char) => char.charCodeAt(0)));
                const blob = new Blob([byteArray], { type: "application/pdf" });
                let blobUrl = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = `Παραπεμπτικά επίσκεψης ${editVisit.id}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);
            } else {
                toast.error(res.data.message);
            }
            setLoadingPrintReferrals(false);
        }).catch((err) => {
            setLoadingPrintReferrals(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    return (
        <React.Fragment>
            {editVisitLoading ? (
                <React.Fragment>
                    <Row className={"mb-3"}>
                        <Col md={12} className={"d-flex justify-content-center"}>
                            Παρακαλώ περιμένετε... <Spinner size={"sm"} className={"ml-2"} animation={"border"} variant={"dark"} />
                        </Col>
                    </Row>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div style={{textAlign: "center"}}>
                        <h5>Επίσκεψη : <strong>{editVisit.id}</strong></h5>
                    </div>
                    <Row className={"mb-3"}>
                        <Col md={5}>
                            <Row>
                                <Col md={11}>
                                    <Input
                                        label={"Ασθενής"}
                                        name={"clientName"}
                                        value={`${editVisit.clientName} (${editVisit.amka})`}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className={"mb-3 borderedContainer"}>
                        <h5 style={{fontWeight: "600"}}>Πληροφορίες Ασθενή</h5>
                        <Row className={"mb-2"}>
                            <Col md={3}>
                                <Input
                                    label={"Ημερομηνία Γέννησης"}
                                    value={moment(editVisit.birthDate, "YYYYMMDD").format("DD/MM/YYYY")}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={3}>
                                <Input
                                    label={"Οδός/Αριθμός"}
                                    value={editVisit.address}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={3}>
                                <Input
                                    label={"Πόλη"}
                                    value={editVisit.city}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={3}>
                                <Input
                                    label={"Ταχ. Κώδικας"}
                                    value={editVisit.postalCode}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <Input
                                    label={"E-Mail"}
                                    value={editVisit.email}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={3}>
                                <Input
                                    label={"Τηλέφωνο"}
                                    value={editVisit.telephone}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={3}>
                                <Input
                                    label={"Φύλο"}
                                    value={editVisit.sex}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                    </div>

                    <div className={"mb-3 borderedContainer"}>
                        <h5 style={{fontWeight: "600"}}>Πληροφορίες Επίσκεψης</h5>
                        <Row>
                            <Col md={6}>
                                <TextArea
                                    name={"visitCause"}
                                    label={"Λόγος Επίσκεψης"}
                                    value={editVisit.visitCause}
                                    required={true}
                                    rows={4}
                                    limit={2000}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={6}>
                                <TextArea
                                    name={"visitNotes"}
                                    label={"Σημειώσεις"}
                                    value={editVisit.visitNotes}
                                    rows={4}
                                    limit={2000}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        {editVisit.status === "completed" && (
                            <Row>
                                <Col md={12}>
                                    <Button size={"sm"} onClick={() => handlePrint()} disabled={loadingPrint}>
                                        Λήψη PDF επίσκεψης
                                        {loadingPrint && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </div>

                    <div className={"mb-3 borderedContainer"}>
                        <h5 style={{fontWeight: "600"}}>Συνταγές</h5>
                        {editVisit?.prescriptions?.length > 0 && (
                            <table className={"simpleClassicTable"}>
                                <thead>
                                <tr>
                                    <th>Αρ. Συνταγής</th>
                                    <th>Ημερομηνία Έκδοσης</th>
                                    <th>Ημερομηνία Λήξης</th>
                                    <th>Κατάσταση</th>
                                </tr>
                                </thead>
                                <tbody>
                                {editVisit.prescriptions.map((prescription, idx) => (
                                    <tr key={`prescriptions-${idx}`}>
                                        <td>{prescription.prescriptionId}</td>
                                        <td>{prescription.startDate}</td>
                                        <td>{prescription.endDate}</td>
                                        <td>{prescription.status}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                    <div className={"mb-3 borderedContainer"}>
                        <h5 style={{fontWeight: "600"}}>Παραπεμπτικά</h5>
                        {editVisit?.referrals?.length > 0 && (
                            <React.Fragment>
                                <table className={"simpleClassicTable"}>
                                    <thead>
                                    <tr>
                                        <th>Αρ. Παραπεμπτικού</th>
                                        <th>Ημερομηνία Έκδοσης</th>
                                        <th>Ημερομηνία Λήξης</th>
                                        <th>Κατάσταση</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {editVisit.referrals.map((referral, idx) => (
                                        <tr key={`referrals-${idx}`}>
                                            <td>{referral.referralId}</td>
                                            <td>{referral.startDate}</td>
                                            <td>{referral.endDate}</td>
                                            <td>{referral.status}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                <Button size={"sm"} className={"mt-2"} onClick={() => handlePrintReferrals()} disabled={loadingPrintReferrals}>
                                    Λήψη παραπεμπτικών σε PDF
                                    {loadingPrintReferrals && (
                                        <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />
                                    )}
                                </Button>
                            </React.Fragment>
                        )}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default VisitEdit
