import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./../modalWidths.css";
import {useDispatch, useSelector} from "react-redux";
import {getGreekCurrencyFormat} from "../../../../_helpers/commonFunctions";
import {setEditBuys} from "../../../../_reducers/DataPanel/Buys/buysSlice";
import {toast} from "react-toastify";
import Dropdown from "../../Dropdown";
import {Col, Row} from "react-bootstrap";
import Input from "../../../common/Input";
import {setSaveEditBuys} from "../../../../_reducers/activitySlice";

const AnamorfosisModal = ({from, show, setShow}) => {
    const dispatch = useDispatch();
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const BUYS = useSelector((state) => state.BUYS);
    const products = from === "Buys" ? BUYS.editBuys.products : [];

    const [tempData, setTempData] = useState([]);

    const [autoData, setAutoData] = useState({
        type: "amount",
        value: 0,
    })

    const percOptions = [
        {value: "amount", label: "Ποσό (€)"},
        {value: "percentage", label: "Ποσοστό (%)"},
    ]

    const handleSave = () => {
        if (from === "Buys") {
            // Quick Validations
            let line = 1;
            for (let prod of products) {
                const findMatch = tempData.find((el) => String(el.no) === String(prod.no));
                if (findMatch) {
                    if (+findMatch.netValue > +prod.netValue) {
                        return toast.error(`Γραμμή ${line} η καθαρή αξία αναμόρφωσης δεν μπορεί να ξεπερνά την καθαρή αξία.`);
                    }
                    if (+findMatch.netValue < 0) {
                        return toast.error(`Γραμμή ${line} η καθαρή αξία αναμόρφωσης δεν μπορεί να είναι αρνητική.`);
                    }
                }
                line++;
            }

            // Insert
            let cloneProducts = structuredClone(BUYS.editBuys.products);
            for (let tmp of tempData) {
                const idx = cloneProducts.findIndex((el) => String(el.no) === String(tmp.no));
                if (idx >= 0) {
                    cloneProducts[idx].anamorfosisNetValue = tmp.netValue;
                    cloneProducts[idx].anamorfosisVatValue = tmp.vat;
                }
            }
            if (!ACTIVITY.saveEditBuys) dispatch(setSaveEditBuys(true));
            dispatch(setEditBuys({...BUYS.editBuys, products: cloneProducts}));
            setShow(false);
            toast.info("Επιτυχής καταχώρηση. Μην ξεχάσετε να αποθηκεύσετε την αγορά.");
        }
    }

    useEffect(() => {
        if (show) {
            let arr = [];
            for (let prod of products) {
                arr.push({
                    no: String(prod.no),
                    netValue: prod?.anamorfosisNetValue ? +prod.anamorfosisNetValue : 0,
                    vat: prod?.anamorfosisVatValue ? +prod.anamorfosisVatValue : 0,
                    deductible: prod.accountingNonDeductibleVAT !== "active",
                })
            }
            setTempData(arr);
        }
    }, [show])

    const handleAutoApply = () => {
        let am = autoData.value;
        if (autoData.type === "percentage") am = totalRealNet * (autoData.value / 100);
        am = +am.toFixed(2);
        let ratio = am / totalRealNet;

        const arr = []; // New tempData
        for (let prod of products) {
            let prepObj = {
                no: String(prod.no),
                netValue: +Number(ratio * prod.netValue).toFixed(2),
                vat: 0,
                deductible: prod.accountingNonDeductibleVAT !== "active"
            }
            if (prepObj.deductible) {
                if (prepObj.netValue === 0) {
                    prepObj.vat = 0;
                } else {
                    prepObj.vat = +prod.vat;
                }
            } else {
                prepObj.vat = +Number((+prod.vatPercentage / 100) * prepObj.netValue).toFixed(2);
            }
            arr.push(prepObj);
        }
        setTempData(arr);
    }

    const totalRealNet = products.reduce((acc, obj) => acc + (obj.netValue || 0), 0);
    const totalNet = getGreekCurrencyFormat(tempData.reduce((acc, obj) => acc + (obj.netValue || 0), 0));
    const totalVat = getGreekCurrencyFormat(tempData.reduce((acc, obj) => acc + (obj.vat || 0), 0));

    return (
        <React.Fragment>
            <Modal show={show} backdrop={"static"} onHide={() => setShow(false)} dialogClassName={"modal65PercentWidth"}>
                <Modal.Header closeButton>
                    <Modal.Title>Λογιστική Αναμόρφωση</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {show && (
                        <React.Fragment>
                            <table className={"simpleClassicTable"} style={{wordWrap: "break-word", fontSize: "14px"}}>
                                <colgroup>
                                    <col span={1} style={{width: "5%"}}></col>
                                    <col span={1} style={{width: "25%"}}></col>
                                    <col span={1} style={{width: "20%"}}></col>
                                    <col span={1} style={{width: "15%"}}></col>
                                    <col span={1} style={{width: "15%"}}></col>
                                    <col span={1} style={{width: "15%"}}></col>
                                    <col span={1} style={{width: "15%"}}></col>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th>Αρ.</th>
                                    <th>Είδος</th>
                                    <th>Καθαρή Αξία</th>
                                    <th>ΦΠΑ</th>
                                    <th>Εκπίπτει</th>
                                    <th>Αναμόρφωση Καθαρής Αξίας</th>
                                    <th>Αναμόρφωση ΦΠΑ</th>
                                </tr>
                                </thead>
                                <tbody>
                                {products.map((prod, idx) => (
                                    <tr key={`anamorfosis-${idx}`}>
                                        <td>{prod.no}</td>
                                        <td>{prod.name}</td>
                                        <td>{getGreekCurrencyFormat(prod.netValue)}</td>
                                        <td>{getGreekCurrencyFormat(prod.vat)}</td>
                                        <td>{tempData.find((el) => String(el.no) === String(prod.no))?.deductible ? "Ναι" : "Όχι"}</td>
                                        <td>
                                            <input
                                                style={{width: "100%", textAlign: "center"}}
                                                type={"number"}
                                                key={tempData.find((el) => String(el.no) === String(prod.no))?.netValue}
                                                defaultValue={tempData.find((el) => String(el.no) === String(prod.no))?.netValue}
                                                onBlur={(e) => {
                                                    let value = +e.target.value;
                                                    if (isNaN(value)) value = 0;

                                                    const clone = structuredClone(tempData);
                                                    const idx = clone.findIndex((el) => String(el.no) === String(prod.no));
                                                    clone[idx].netValue = value;
                                                    if (clone[idx].deductible) {
                                                        if (value === 0) {
                                                            clone[idx].vat = 0;
                                                        } else {
                                                            clone[idx].vat = +prod.vat;
                                                        }
                                                    } else {
                                                        clone[idx].vat = +Number((+prod.vatPercentage / 100) * value).toFixed(2);
                                                    }
                                                    setTempData(clone);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <input style={{width: "100%", textAlign: "center"}} disabled value={tempData.find((el) => String(el.no) === String(prod.no))?.vat} />
                                        </td>
                                    </tr>
                                ))}
                                <tr key={"totals-anamorfosis"}>
                                    <td colSpan={5}><strong>ΣΥΝΟΛΑ</strong></td>
                                    <td>{totalNet}</td>
                                    <td>{totalVat}</td>
                                </tr>
                                </tbody>
                            </table>

                            <div className={"text-muted mt-2"}><i>Αυτόματη εφαρμογή</i></div>
                            <Row>
                                <Col md={4}>
                                    <Dropdown
                                        label={"Τύπος"}
                                        defaultValue={percOptions.find((el) => el.value === autoData.type)}
                                        key={Math.random()}
                                        options={percOptions}
                                        onChange={(e) => setAutoData({...autoData, type: e.value})}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Input
                                        type={"number"}
                                        label={autoData.type === "amount" ? "Ποσό Αναμόρφωσης (€)" : "Ποσοστό Αναμόρφωσης (%)"}
                                        value={autoData.value}
                                        autoComplete={"off"}
                                        onChange={(e) => {
                                            const value = +e.target.value;
                                            if (isNaN(value)) {
                                                setAutoData({...autoData, value: 0});
                                            } else {
                                                setAutoData({...autoData, value: value});
                                            }
                                        }}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Button size={"sm"} style={{marginTop: "30px"}} onClick={() => handleAutoApply()}>Εφαρμογή</Button>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleSave()}>
                        Καταχώρηση
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default AnamorfosisModal
