import {toast} from "react-toastify";
import axios from "axios";
import {login, logout} from "../_reducers/Login";
import {
  deleteProductCategory,
  failedProductCategory,
  loadingProductCategory,
  setProductCategory,
} from "../_reducers/DataPanel/Products/productCategorySlice";
import {
  resetNewPriceList,
  resetNewProductMovements,
  resetSelectedProduct,
  setPerPagePM,
  setPerPagePP,
  setPerPagePS,
  setPriceListProducts,
  setProductMovementsTemplates,
  setProductPriceListTemplates,
  setProducts,
  setProductSubmitting,
  setProductTemplates,
  setSearchProductMovements,
  setSearchProductPriceList,
  setSearchProducts,
  setSelectedProductTemplate,
  setTotalItemsPM,
  setTotalItemsPP,
  setTotalItemsPS,
} from "../_reducers/DataPanel/Products/productsSlice";
import * as SETTINGS from '../_reducers/settingsSlice';
import {
  clearNewAdvancedType,
  clearNewBasicType,
  otherDataInitialValues,
  setAdvancedTypes,
  setAdvancedTypesBuys,
  setAdvancedTypesSales,
  setBasicTypes,
  setEmailSmtp,
  setPerPageATS,
  setPerPageBTS,
  setPrimerApiData,
  setPrimerMyDataData,
  setSearchAdvancedTypes,
  setSearchBasicTypes,
  setTotalItemsATS,
  setTotalItemsBTS
} from '../_reducers/settingsSlice';
import * as CLIENTS_DATA from '../_reducers/DataPanel/Clients/clientSlice';
import {
  resetNewClient,
  resetNewClientMovements,
  resetNewClientPayment,
  resetSearchCommunicationHistory,
  setClientMovementsTemplates,
  setClientPayments,
  setClientPaymentsSearch,
  setClientPaymentsTemplates,
  setClients,
  setClientsList,
  setClientsPayment,
  setClientsSettings,
  setClientTemplates,
  setEditClient,
  setNewClientPayment,
  setperPageCMS,
  setperPageCPS,
  setperPageCS,
  setSearchClientMovements,
  setSearchClients,
  setSearchCommunicationHistory,
  setTotalItemsCMS,
  setTotalItemsCPS,
  setTotalItemsCS,
  softResetNewClientPayment
} from '../_reducers/DataPanel/Clients/clientSlice';
import {getObjectFromArrayOfObjects} from '../_helpers/helperFunctions';
import {
  resetNewPOS,
  setCashSettings,
  setSearchBankAccounts,
  setSearchCashRegisters,
  setSearchPOS
} from '../_reducers/DataPanel/Cash/cashSlice';
import {
  setQuickRetailSettings,
  setSalesGeneralSettings,
  setSyncInvoicesProviderLog
} from '../_reducers/DataPanel/Sales/salesSettingsSlice';
import {
  setAutomaticSales,
  setAutomaticSalesSearch,
  setPerPageASS,
  setTotalItemsASS
} from '../_reducers/DataPanel/Sales/automaticSalesSlice';
import {
  setCanBypassInvalidCompany, setEmronClientData,
  setEmronCompanyInvalid,
  setEmronCompanyInvalidDays,
  setIsEmronClient,
  setSaveAdvancedType,
  setSaveBasicType,
  setSaveBuys,
  setSaveClientPayments,
  setSaveEditAccountingRecord,
  setSaveEditBuys,
  setSaveEditProductMovements,
  setSaveEmailSmtp,
  setSaveNewWorkPermit,
  setSavePrimerMyDataData,
  setSaveSales,
  setSendEmail, setSendEmployeeNewPasswordOnSave, setSendEmployeeQROnSave
} from '../_reducers/activitySlice';
import {
  resetNewSales,
  setEditSales,
  setNewSales,
  setPerPageSS,
  setSales,
  setSalesTemplates,
  setSearchSales,
  setTotalItemsSS
} from '../_reducers/DataPanel/Sales/salesSlice';
import {getCurrentUser, leadingZeros,} from '../_helpers/commonFunctions';
import {
  setPerPageQRS,
  setQrsSearchProducts,
  setQuickRetailSales,
  setQuickRetailSalesSearch,
  setQuickRetailSalesTemplates,
  setTotalItemsQRS
} from '../_reducers/DataPanel/Sales/quickRetailSalesSlice';
import {
  setCompany,
  setCompanyDetails,
  setCompanyInstallations,
  setDashboardData,
  setUserRightsList
} from '../_reducers/companySlice';
import {vatCategoryData} from '../_data/mydata/vatCategory';
import {highlightData} from "../_data/mydata/highlight";
import {measurementUnitsData} from "../_data/mydata/measurementUnits";
import {paymentTypesData} from "../_data/mydata/paymentTypes";
import {salesPurposeData} from "../_data/mydata/salesPurpose";
import {withHoldingTaxesData} from "../_data/mydata/withHoldingTaxes";
import {otherTaxesData} from "../_data/mydata/otherTaxes";
import {clientsCategoryData} from "../_data/clients/clients-settings";
import {feesData} from "../_data/mydata/fees";
import {taxStampCategoryData} from "../_data/mydata/taxStampCategory";
import {
  resetNewSupplier,
  resetNewSupplierPayment,
  resetSearchSupplierCommunicationHistory,
  setperPageSMSS,
  setperPageSPS,
  setperPageSSS,
  setSearchSupplierCommunicationHistory,
  setSearchSupplierMovements,
  setSearchSuppliers,
  setSupplierPayments,
  setSupplierPaymentsSearch,
  setSuppliers,
  setSuppliersPayment,
  setSuppliersSettings,
  setTotalItemsSMSS,
  setTotalItemsSPS,
  setTotalItemsSSS,
  softResetNewSupplierPayment
} from "../_reducers/DataPanel/Suppliers/supplierSlice";
import {setBuysGeneralSettings} from "../_reducers/DataPanel/Buys/buysSettingsSlice";
import {
  resetNewBuys,
  setBuys, setBuysTemplates,
  setEditBuys,
  setNewBuys,
  setPerPageBS,
  setSearchBuys,
  setTotalItemsBS
} from "../_reducers/DataPanel/Buys/buysSlice";
import jsPDF from "jspdf";
import {arial} from "../fonts/ARIAL-normal";
import {
  resetNewAccountingAccount,
  resetNewAccountingRecord,
  resetNewEmployee,
  setAccountingAdvancedTypes,
  setAccountingPaymentTypes,
  setAccountingRecordCategories,
  setAccountingRecordSubcategories,
  setAccountingSettings,
  setEditAccountingRecord,
  setperPageAAS,
  setperPageAR,
  setperPageES,
  setSearchAccountingAccounts,
  setSearchAccountingRecords,
  setSearchEmployees,
  setTotalItemsAAS,
  setTotalItemsAR,
  setTotalItemsES, softResetAccountingRecord
} from "../_reducers/DataPanel/Accounting/accountingSlice";
import {suppliersCategoryData} from "../_data/suppliers/suppliers-settings";
import {setSubscriptions} from "../_reducers/subscriptionsSlice";
import {addTab, removeTab, updateActiveMainTab} from "../_reducers/TabsSlice";
import {addToForceRefresh} from "../_reducers/SearchTableSlice";
import {
  resetNewWorkMovement,
  resetNewWorkPermit,
  setCompanyHasErgani,
  setPayrollSettings,
  setSearchWorkMovements,
  setSearchWorkMovementsPerPage,
  setSearchWorkMovementsTotalItems,
  setSearchWorkPermits,
  setSearchWorkPermitsPerPage,
  setSearchWorkPermitsTotalItems
} from "../_reducers/DataPanel/Payroll/payrollSlice";

const apiURL2 = process.env.REACT_APP_API_URL2;
const apiLoginURL = process.env.REACT_APP_LOGIN_URL;
const errorAuth = "You are not Authorized!";

export const userSigninWithUrl = (payloadObj, dispatch, selectedData, params, companyData, deviceId) => {
  axios.post(apiURL2 + `/public/detail`, params, {
    headers: { 'Content-Type': 'application/json' },
  }).then((res) => {
    if (res.status === 200) {
      if (res.data.status === 200 || res.data.status === "200") {
        let userPermissions = res.data.data.permissions
        localStorage.setItem("token", res.data.data.token);
        localStorage.setItem("deviceId", deviceId);
        dispatch(setUserRightsList(userPermissions));
        localStorage.setItem("user", JSON.stringify(res.data.data));
        if (userPermissions.length > 0) {
          localStorage.setItem("permissions", JSON.stringify(userPermissions));
          dispatch(setUserRightsList(userPermissions));
        }
        dispatch(setCompany(selectedData));
        dispatch(login());
        toast.success("You are successfully logged in.");
        dispatch(fetchPrimerMyDataCredentials(selectedData.id));
        window.history.pushState({}, "", window.location.origin); // Do not touch. Removes huge url and fixes change company
      } else {
        localStorage.clear();
        window.location.replace(`${apiLoginURL}?redirect=ERP&error=${res.data.message}`);
      }
    } else {
      if (res.error) {
        localStorage.clear();
        window.location.replace(`${apiLoginURL}?redirect=ERP&error=${res.error}`);
      }
    }
  }).catch((error) => {
    console.log(error.response)
    localStorage.clear();
    window.location.replace(`${apiLoginURL}?redirect=ERP&error=${error?.response?.data?.message}`);
  })
}

export const handleLogOut = () => {
  return (dispatch) => {
    const email = getCurrentUser()?.email;
    axios.post(apiURL2 + `/user/logout`, {email: email}, {
      headers: { 'Content-Type': 'application/json' },
    }).then((res) => {
      console.log(res.data);
      if (res.data.status === 200) {
        localStorage.clear();
        dispatch(logout());
        window.location.replace(`${apiLoginURL}?redirect=ERP&message=${res.data.message}`);
      } else {
        toast.error(res.data.message)
        localStorage.clear();
        dispatch(logout());
        window.location.replace(`${apiLoginURL}?redirect=ERP&error=${res.data.message}`);
      }
    }).catch((err) => {
      if (err) {
        let message = 'Service is unavailable for the moment. Please try again later.'
        localStorage.clear();
        dispatch(logout());
        window.location.replace(`${apiLoginURL}?redirect=ERP&error=${message}`);
      }
    })
  }
}

/*
 * Fetch Category
 */
export const fetchCategory = (data) => {
  return (dispatch) => {
    dispatch(loadingProductCategory());
    let requestData = {
      type: 'product',
      company: data.company,
      isActive: data.isActive
    }
    axios.post(apiURL2 + `/category/category-list`, requestData, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setProductCategory(res.data.data));
      }
    }).catch(() => {
      console.log("Unable to load category.");
      dispatch(failedProductCategory());
    })
  }
}

/*
 * Add New Category
 */
export const addNewCategory = (data, requestData) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/category/category-add`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success(res.data.message);
        dispatch(fetchCategory(requestData));
      } else {
        toast.error(res.data.message);
      }
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.errors) {
        toast.error(error.response.data.errors);
      } else {
        console.log("Add category Failed.");
      }
    })
  }
}

/*
 * Update Category Data
 */
export const putCategory = (data, id, action = "update", requestData) => {
  return (dispatch) => {
    axios.put(`${apiURL2}/category/category-update`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        if (action === "delete") {
          dispatch(deleteProductCategory({ id: id, data: res.data.data }));
          toast.success("Category deleted successfully.");
        } else {
          dispatch(fetchCategory(requestData));
          toast.success("Category updated successfully.");
        }
      } else {
        toast.error(res.data.message);
      }
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.errors) {
        toast.error(error.response.data.errors);
      } else {
        console.log("Category update failed.");
      }
    })
  }
}


export const addAccountingRecordCategory = (data, requestData) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/accounting/add-accounting-record-category`, JSON.stringify(data), {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success(res.data.message);
        dispatch(fetchAccountingRecordCategories(requestData.company));
      } else {
        toast.error(res.data.message);
      }
    }).catch((error) => {
     console.log(error);
    })
  }
}

export const updateAccountingRecordCategory = (data, action = "update", requestData, setSubCategories, setSelectedCategory) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/accounting/update-accounting-record-category`, JSON.stringify(data), {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        if (action === "delete") {
          toast.success("1 Accounting Record Category deleted");
        } else {
          toast.success("Accounting Record Category updated.");
        }
        dispatch(fetchAccountingRecordCategories(requestData.company));
        if (typeof setSubCategories === 'function') {
          setSubCategories(res.data?.data?.subCategories);
        }
        if (typeof setSelectedCategory === 'function') {
          setSelectedCategory(res.data?.data);
        }
      } else {
        toast.error(res.data.message);
      }
    }).catch((error) => {
      console.log(error);
    })
  }
}

export const fetchAccountingRecordCategories = (company) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/accounting/accounting-record-categories-list`,{company: company}, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setAccountingRecordCategories(res.data.data));
      }
    }).catch((err) => {
      console.log(err);
    })
  }
}

export const fetchAccountingRecordSubcategories = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/accounting/accounting-record-subcategories-list`,{company: data}, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setAccountingRecordSubcategories(res.data.data));
      }
    }).catch((error) => {
      console.log(error);
    })
  }
}

export const fetchAccountingAdvancedTypes = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/accounting/accounting-advanced-types-list`,{company: data}, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setAccountingAdvancedTypes(res.data.data));
      }
    }).catch((error) => {
      console.log(error);
    })
  }
}

/*
 * Fetch Products
 */
export const fetchProducts = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/category/list-product`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setProducts(res.data.data));
      }
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.errors) {
        toast.error(error.response.data.errors);
      }
    })
  }
}

export const fetchProductsONChange = (data, setData) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/category/list-product`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        setData(res.data.data);
      } else if (res.data && res.data.status === "422") {
        setData([]);
      }
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.errors) {
        toast.error(error.response.data.errors);
      }
    })
  }
}

/*
 * Add New Product
 */
export const postProduct = (data, requestData) => {
  return (dispatch) => {
    if (data.mydata.length > 0) {
      axios.post(apiURL2 + `/category/add-product`, data, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.data && res.data.status === "200") {
            toast.success(res.data.message);
            dispatch(fetchCategory(requestData));
            dispatch(setSelectedProductTemplate({}));
            dispatch(addToForceRefresh("Search Products"));
            if (data.isActive !== "draft") {
              dispatch(resetSelectedProduct()); // Reset Selected Product
            }
            if (data.isActive === "draft") {
              if (res.data.data) {
                // dispatch(setSelectedProduct(res.data.data)); // Set Draft Product
              }
            }
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((error) => {
          if (error && error.response) {
            toast.error(error.response.data?.errors);
          }
        });
    } else {
      toast.error("My Data table should have at least one row.");
    }
  }
}

/*
 * Update Product
 */
export const putProduct = (data, requestData, action = "update", reset = false, setLoadingSave) => {
  if (typeof setLoadingSave === 'function') {
    setLoadingSave(true);
  }
  if (data?.mydata?.length > 0) {
    return (dispatch) => {
      if (data._id) {
        data.id = data._id;
        delete data._id;
      }
      if (data.__v) {
        delete data.__v;
      }
      axios.put(apiURL2 + `/category/update-product`, data, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.data && res.data.status === "200") {
            if (action === "delete") {
              toast.success("1 Product deleted");
            } else {
              toast.success("Product updated.");
            }
            dispatch(fetchCategory(requestData));
            dispatch(addToForceRefresh("Search Products"));
            if (reset) {
              dispatch(resetSelectedProduct());
            }
          } else {
            toast.error(res.data.errors);
            console.log(res.data.errors);
          }
          if (typeof setLoadingSave === 'function') setLoadingSave(false);

        })
      .catch(error => {
        if (typeof setLoadingSave === 'function') setLoadingSave(false);
        console.log(error);
      })
    };
  } else {
    setLoadingSave(false);
    toast.error("My Data table should have at least one row.");
  }
}

/*
 * Search Product By Keywords
 */
export const fetchProductByKeywords = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/category/product-search-by-name`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setQrsSearchProducts(res.data.data));
      }
    }).catch((error) => {
      console.log(error);
    })
  }
}

/*
 * Fetch Templates
 */
export const fetchTemplates = (data) => {
  const templateType = data && data.type ? data.type : "";
  return (dispatch) => {
    axios.post(apiURL2 + `/category/list-product-template`, data, {
      headers: {"Content-Type": "application/json"}
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        if (templateType === "product") {
          dispatch(setProductTemplates(res.data.data));
        } else if (templateType === "productMovements") {
          dispatch(setProductMovementsTemplates(res.data.data));
        } else if (templateType === "priceList") {
          dispatch(setProductPriceListTemplates(res.data.data));
        } else if (templateType === "client") {
          dispatch(setClientTemplates(res.data.data));
        } else if (templateType === "clientMovements") {
          dispatch(setClientMovementsTemplates(res.data.data));
        } else if (templateType === "sales") {
          dispatch(setSalesTemplates(res.data.data));
        } else if (templateType === "quickRetailSales") {
          dispatch(setQuickRetailSalesTemplates(res.data.data));
        } else if (templateType === "clientPayments") {
          dispatch(setClientPaymentsTemplates(res.data.data));
        } else if (templateType === "buys") {
          dispatch(setBuysTemplates(res.data.data));
        }
      } else {
        toast.error(res.data.errors);
      }
    }).catch((error) => {
      if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors
      ) {
        toast.error(error.response.data.errors);
      } else {
        console.log("Unable to load templates");
      }
    })
  }
}

/*
 * Add New Template
 */
export const postTemplate = (data, requestData, clearStates) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/category/add-product-template`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("New template added.");
        clearStates();
        dispatch(fetchTemplates(requestData));
      } else {
        toast.error(res.data.error);
      }
    }).catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        toast.error(error.response.data.errors);
      } else {
        console.log("Unable to add template.");
      }
    })
  }
}

/*
 * Update Template
 */
export const putTemplate = (data, requestData, action = "update") => {
  return (dispatch) => {
    axios.put(apiURL2 + `/category/update-product-template`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        if (action === "delete") {
          toast.success("Template deleted.");
        } else {
          toast.success("Template data updated.");
        }
        dispatch(fetchTemplates(requestData));
      } else {
        toast.error(res.data.error);
      }
    }).catch((error) => {
      toast.error(error + " Update Template");
    })
  }
}

/*
 * Add Price List
 */
export const postPriceList = (data, requestData, reset = true, setLoadingSave, setActivePriceListModal) => {
  setLoadingSave(true);
  return (dispatch) => {
    axios
      .post(apiURL2 + `/category/add-product-price`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        if (res.data && res.data.status === "200") {
          toast.success("New Price List Added.");
          dispatch(fetchPriceList(requestData));
          setActivePriceListModal(false);
          dispatch(addToForceRefresh("Search Product Price List"));
          if (reset) {
            dispatch(resetNewPriceList()); // reset
          }
        } else if(res.data && res.data?.status === "400"){
          setActivePriceListModal(true);
        } else {
          toast.error(res.data.error);
        }
        setLoadingSave(false);
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          toast.error(error.response.data.errors);
        }
        setLoadingSave(false);
      });
  };
};

/*
 * Fetch Product Movements
 */
export const fetchPriceList = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/category/list-product-price`, data, {
      headers: {"Content-Type": "application/json"},
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setPriceListProducts(res.data.data));
      }
    }).catch((error) => {
      if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors
      ) {
        toast.error(error.response.data.errors);
      } else {
        console.log("Unable to fetch product price list.");
      }
    })
  }
}

/*
 * Update Product Price List
 */
export const putPriceList = (data, requestData, action = "update", setLoadingSave, setActivePriceListModal) => {
  setLoadingSave(true);
  return (dispatch) => {
    if (data._id !== null) {
      data.id = data._id;
      axios.put(apiURL2 + `/category/update-product-price`, data, {
          headers: { "Content-Type": "application/json" },
        }).then((res) => {
          if (res.data && res.data.status === "200") {
            if (action === "delete") {
              toast.success("1 Product Price List deleted");
            } else {
              toast.success("Product Price List updated.");
            }
            dispatch(addToForceRefresh("Search Product Price List"));
            setActivePriceListModal(false);
          } else if (res.data && res.data.status === "400") {
            setActivePriceListModal(true);
          } else {
            toast.error(res.data.errors);
            console.log(res.data.errors);
          }
          setLoadingSave(false);
        }).catch((error) => {
          console.log(error + " Update Product Product Price List");
          setLoadingSave(false);
        });
    } else {
      console.log("null id error");
    }
  }
}

/*
 * Add Product Movement
 */
export const postProductMovements = (data, requestData) => {
  return (dispatch) => {
    data.number = data.number !== "" ? parseInt(data.number) : "";
    axios.post(apiURL2 + `/category/add-product-movements`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("New Product Movements Added.");
        dispatch(resetNewProductMovements());
        dispatch(addToForceRefresh("Search Product Movements"));
      } else {
        toast.error(res.data.message);
      }
    }).catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        toast.error(error.response.data.errors);
      } else {
        console.log("Unable to add product movements.");
      }
    })
  }
}

/*
 * Update Product Movements
 */
export const putProductMovements = (data, requestData, action = "update") => {
  return (dispatch) => {
    if (data._id !== null) {
      data.id = data._id;
      delete data._id;
      delete data.__v;
      delete data.created_at;
      delete data.update_at;
      axios.put(apiURL2 + `/category/update-product-movements`, data, {
        headers: { "Content-Type": "application/json" }
      }).then((res) => {
        if (res.data && res.data.status === "200") {
          toast.success("Product Movement updated.");
          dispatch(setSaveEditProductMovements(false));
          dispatch(addToForceRefresh("Search Product Movements"));
        } else {
          toast.error(res.data.error);
          console.log(res.data.error);
        }
        setProductSubmitting(false);
      }).catch((error) => {
        console.log(error + " Update Product Product Movement");
      })
    } else {
      console.log("null id error");
    }
  }
}

/*
 * Add Setting Basic Type
 */
export const postBasicType = (data, requestData) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/settings/add-setting-basic-type`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("Basic Type Added.");
        dispatch(clearNewBasicType());
        dispatch(addToForceRefresh("Basic Type Search"));
        dispatch(setSaveBasicType(false));
      } else {
         toast.error(res.data.message);
      }
    }).catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        toast.error(error.response.data.errors);
      } else {
        console.log("Unable to add basic type.");
      }
    })
  }
}

/*
 * Update Basic Type
 */
export const putBasicType = (data, requestData, action = "update", requestData2 = {}) => {
  return (dispatch) => {
    if (data._id !== null) {
      data.id = data._id;
      axios.put(apiURL2 + `/settings/update-setting-basic-type`,
          data,
          { headers: { "Content-Type": "application/json" } }
        )
        .then((res) => {
          if (res.data && res.data.status === "200") {
            if (action === "delete") {
              toast.success("1 Basic Type deleted");
            } else {
              toast.success("Basic Type Updated.");
            }
            dispatch(addToForceRefresh("Basic Type Search"));
          } else {
            toast.error(res.data.error);
          }
        });
    } else {
      console.log("null id error");
    }
  }
}

/*
 * Get Basic Types List
 */
export const fetchBasicTypes = (data) => {
  return (dispatch) => {
    data.isActive = "active";
    axios.post(apiURL2 + `/settings/list-setting-basic-type`, data, {
      headers: { 'Content-Type': 'application/json' }
    }).then(res => {
      if (res.data && res.data.status === '200') {
        dispatch(setBasicTypes(res.data.data));
      } else if (res.data && res.data.status === "422") {
        console.log("Basic types not found.");
      }
    }).catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        toast.error(error.response.data.errors);
      } else {
        console.log("Unable to fetch basic types.");
      }
    })
  }
}

/*
 * Search Basic Types
 */
export const fetchBasicTypesSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/settings/basic-type-search-setting`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data.status === "200") {
        dispatch(setSearchBasicTypes(res.data.data.data));
        dispatch(setTotalItemsBTS(res.data.data.totalItems));
        dispatch(setPerPageBTS(res.data.data.perPage));
        if (res.data.data && res.data.data.length === 0) toast.info("No records found.");
      } else if (res.data.status === "422") {
        toast.info("No records found.");
        dispatch(setSearchBasicTypes([]));
      } else {
        toast.error(res.data.error);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

/*
 * Add Setting Advanced Type
 */
export const postAdvancedType = (data, setLoadingSave) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/settings/add-setting-advance-type`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      setLoadingSave(false);
      if (res.data && res.data.status === "200") {
        toast.success("New Advanced Type Added.");
        dispatch(addToForceRefresh("Advanced Type Search"));
        dispatch(clearNewAdvancedType());
        dispatch(setSaveAdvancedType(false));
      } else {
        toast.error(res.data.error);
      }
    }).catch((err) => {
      setLoadingSave(false);
      console.log(err);
      toast.error("Σφάλμα κατά την αποθήκευση.");
    })
  }
}

/*
 * Update Advanced Type
 */
export const putAdvancedType = (data, setLoadingSave) => {
  return (dispatch) => {
    axios.put(apiURL2 + `/settings/update-setting-advance-type`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      setLoadingSave(false);
      if (res.data && res.data.status === "200") {
        toast.success("Advanced Type Updated.");
        dispatch(addToForceRefresh("Advanced Type Search"));
      } else {
        toast.error(res.data.error);
      }
    }).catch((err) => {
      setLoadingSave(false);
      console.log(err);
      toast.error("Σφάλμα κατά την αποθήκευση.");
    })
  }
}
/*
 * Get Advanced Types List
 */
export const fetchAdvancedTypes = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/settings/list-setting-advance-type`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data.status === "200") {
        dispatch(setAdvancedTypes(res.data.data));
      } else {
        console.log("Advanced types not found.");
      }
    }).catch((err) => {
      console.log(err);
    })
  }
}

/*
 * Search Advanced Types
 */
export const fetchAdvancedTypesSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/settings/advance-type-search-setting`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data.status === "200") {
        dispatch(setSearchAdvancedTypes(res.data.data.data));
        dispatch(setTotalItemsATS(res.data.data.totalItems));
        dispatch(setPerPageATS(res.data.data.perPage));
        if (res.data.data && res.data.data.data.length === 0) toast.info("No records found.");
      } else {
        if (res.data && res.data.message) {
          toast.error(res.data.message);
        } else {
          toast.error(res.data.error);
        }
        dispatch(setSearchAdvancedTypes([])); // reset table
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

/*
 * Add Setting Advanced Type Sales
 */
export const postAdvancedTypeSales = (data, setLoadingSave) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/settings/add-setting-advance-type-sale`, data, {
      headers: {"Content-Type": "application/json"}
    }).then((res) => {
      setLoadingSave(false);
      if (res.data && res.data.status === "200") {
        toast.success("New advanced type added.");
        dispatch(addToForceRefresh("Advanced Type Search"));
        dispatch(clearNewAdvancedType());
      } else {
        toast.error(res.data.message);
      }
    }).catch((err) => {
      setLoadingSave(false);
      console.log(err);
      toast.error("Σφάλμα κατά την αποθήκευση.");
    })
  }
}

/*
 * Add Setting Advanced Type Buys
 */
export const postAdvancedTypeBuys = (data, setLoadingSave) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/settings/add-setting-advance-type-buy`, data,
      { headers: { "Content-Type": "application/json" } }
    ).then((res) => {
      setLoadingSave(false);
      if (res.data && res.data.status === "200") {
        toast.success("New advanced type added.");
        dispatch(addToForceRefresh("Advanced Type Search"));
        dispatch(clearNewAdvancedType());
      } else {
        toast.error(res.data.message);
      }
    }).catch((err) => {
      setLoadingSave(false);
      console.log(err);
      toast.error("Σφάλμα κατά την αποθήκευση.");
    })
  }
}

/*
 * Get Advanced Types List Sales
 */
export const fetchAdvancedTypesSales = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/settings/list-setting-advance-type-sale`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data.status === "200") {
        dispatch(setAdvancedTypesSales(res.data.data));
      } else {
        console.log("Advanced types sales not found.");
      }
    }).catch((err) => {
      console.log(err);
    })
  }
}

/*
 * Get Advanced Types List Buys
 */
export const fetchAdvancedTypesBuys = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/settings/list-setting-advance-type-buy`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data.status === "200") {
        dispatch(setAdvancedTypesBuys(res.data.data));
      } else {
        console.log("Advanced types buys not found.");
      }
    }).catch((err) => {
      console.log(err);
    })
  }
}

/*
 * Update Advanced Type
 */
export const putAdvancedTypeSales = (data, setLoadingSave) => {
  return (dispatch) => {
      axios.put(apiURL2 + `/settings/update-setting-advance-type-sale`, data,
          { headers: { "Content-Type": "application/json" } }
      ).then((res) => {
        setLoadingSave(false);
        if (res.data && res.data.status === "200") {
          toast.success("Advanced Type Updated.");
          dispatch(addToForceRefresh("Advanced Type Search"));
        } else {
          toast.error(res.data.message);
        }
      }).catch((err) => {
        setLoadingSave(false);
        console.log(err);
        toast.error("Σφάλμα κατά την αποθήκευση.");
      })
  }
}

/*
 * Update Advanced Type
 */
export const putAdvancedTypeBuys = (data, setLoadingSave) => {
  return (dispatch) => {
    axios.put(apiURL2 + `/settings/update-setting-advance-type-buy`, data,
        { headers: { "Content-Type": "application/json" } }
    ).then((res) => {
      setLoadingSave(false);
      if (res.data && res.data.status === "200") {
        toast.success("Advanced Type Updated.");
        dispatch(addToForceRefresh("Advanced Type Search"));
      } else {
        toast.error(res.data.message);
      }
    }).catch((err) => {
      setLoadingSave(false);
      console.log(err);
      toast.error("Σφάλμα κατά την αποθήκευση.");
    })
  }
}

/*
 * Get Client Settings
 */
export const fetchClientsSettings = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/client/list-client-setting`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        if (res.data.data.length > 0) {
          dispatch(setClientsSettings(res.data.data[res.data.data.length - 1]));
        }
      } else if (res.data && res.data.status === "422") {
        let newSettings = CLIENTS_DATA.clientSettingsInitialValues;
        newSettings = { ...newSettings, categories: clientsCategoryData }; // Static Data Loading
        dispatch(postClientsSettings(newSettings, {company: data.company, year: data.year})); // Post Client Settings To API
      }
    }).catch((error) => {
        toast.error("Something went wrong!");
        console.log(error);
    })
  }
}


export const fetchAccountingSettings = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/accounting/list-accounting-setting`, data, {
      headers: {"Content-Type": "application/json"},
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        if (res.data.data) {
          dispatch(setAccountingSettings(res.data.data.categories));
        }
      }
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.errors) {
        toast.error(error.response.data.errors);
      } else {
        console.log("Unable to fetch Accounting settings.");
      }
    })
  }
}

export const postAccountingSettings = (data, requestData) => {
  data.company = requestData.company;
  return (dispatch) => {
    axios.post(apiURL2 + `/accounting/add-accounting-setting`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("Accounting Settings Updated");
        dispatch(fetchAccountingSettings(requestData));
      } else {
        toast.error(res.data.message);
      }
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.errors) {
        toast.error(error.response.data.errors);
      } else {
        console.log("Unable to add client setting.");
      }
    })
  }
}

/*
 * Get Supplier Settings
 */
export const fetchSuppliersSettings = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/supplier/list-supplier-setting`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        if (res.data.data.length > 0) {
          dispatch(setSuppliersSettings(res.data.data[res.data.data.length - 1]));
        }
      } else if (res.data && res.data.status === "422") {
        let newSettings = CLIENTS_DATA.clientSettingsInitialValues;
        newSettings = { ...newSettings, categories: suppliersCategoryData }; // Static Data Loading
        dispatch(postSuppliersSettings(newSettings, {company: data.company, year: data.year})); // Post Client Settings To API
      }
    }).catch((error) => {
      console.log(error);
    })
  }
}

/*
 * Add Clients Settings
 */
export const postClientsSettings = (data, requestData, setLoadingSave) => {
  data.company = requestData.company;
  data.year = requestData.year;
  return (dispatch) => {
    axios.post(apiURL2 + `/client/add-client-setting`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (setLoadingSave) setLoadingSave(false);
      if (res.data && res.data.status === "200") {
        toast.success("Clients Settings Updated");
        dispatch(fetchClientsSettings(requestData));
      } else if(res.data && res.data.status === "400"){
        toast.error(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    }).catch((error) => {
      if (setLoadingSave) setLoadingSave(false);
      if (error && error.response && error.response.data && error.response.data.errors) {
        toast.error(error.response.data.errors);
      } else {
        console.log("Unable to add client setting.");
      }
    })
  }
}

/*
 * Add Suppliers Settings
 */
export const postSuppliersSettings = (data, requestData) => {
  data.company = requestData.company;
  data.year = requestData.year;
  return (dispatch) => {
    axios.post(apiURL2 + `/supplier/add-supplier-setting`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("Suppliers Settings Updated");
        dispatch(fetchSuppliersSettings(requestData));
      } else if (res.data && res.data.status === "400"){
        toast.error(res.data.message);
      } else {
        toast.error(res.data.error);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

/*
 * Get Clients
 */
export const fetchClients = (data, setData) => {
  return () => {
    axios.post(apiURL2 + `/client/list-client`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        setData(res.data.data);
      }
      if (res.data && res.data.status === "422") {
        setData([]);
        console.log("Client not found.");
      }
    }).catch((error) => {
      console.log(error);
    })
  }
}

/*
 * Get Clients
 */
export const fetchSuppliers = (data, setData) => {
  return () => {
    axios.post(apiURL2 + `/supplier/list-supplier`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        setData(res.data.data);
      }
      if (res.data && res.data.status === "422") {
        setData([]);
      }
    }).catch((error) => {
      console.log(error);
    })
  }
}

export const fetchInitialClients = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/client/list-client`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setClients(res.data.data));
      }
      if (res.data && res.data.status === "422") {
        console.log("Client not found.");
      }
    }).catch((error) => {
      console.log(error);
    })
  }
}

export const fetchInitialProducts = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/category/list-product`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setProducts(res.data.data));
      }
      if (res.data && res.data.status === "422") {
        console.log("Product not found.");
      }
    }).catch((error) => {
      console.log(error);
    })
  }
}

export const fetchInitialSuppliers = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/supplier/list-supplier`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setSuppliers(res.data.data));
      }
      if (res.data && res.data.status === "422") {
        console.log("Supplier not found.");
      }
    }).catch((error) => {
      console.log(error);
    })
  }
}

/*
 * Add Client
 */
export const postClient = (data, requestData, emronData = {}, clearForm = false) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/client/add-client`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("New Client Added.");
        dispatch(fetchClientsSettings(requestData));
        if (clearForm) dispatch(resetNewClient()); // Reset client form
        dispatch(addToForceRefresh("Client Search"));
      } else {
        toast.error(res.data.message);
      }
    }).catch((error) => {
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
      console.log(error);
    })
  }
}


/*
 * Add Client
 */
export const postSupplier = (data, requestData, emronData = {}, clearForm = false) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/supplier/add-supplier`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("New Supplier Added.");
        dispatch(fetchSuppliersSettings(requestData));
        if (clearForm) dispatch(resetNewSupplier()); // Reset supplier form
        dispatch(addToForceRefresh("Supplier Search"));
      } else {
        toast.error(res.data.message);
      }
    }).catch((error) => {
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
      console.log(error);
    })
  }
}


/*
 * Update Client
 */
export const putClient = (data, requestData, action = "update", requestData2 = {}, clearForm = false, setLoadingSave) => {
  return (dispatch) => {
    if (data._id !== null) {
      data.id = data._id;
      const currentUser = getCurrentUser();
      data = {...data, userId: currentUser._id }
      axios.put(apiURL2 + `/client/update-client`, data, {
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        if (setLoadingSave) setLoadingSave(false);
        if (res.data && res.data.status === "200") {
          toast.success("Client updated.");
          if (clearForm) dispatch(resetNewClient()); // Reset client form
          dispatch(addToForceRefresh("Client Search"));
          dispatch(setEditClient(res.data.data));
        } else {
          toast.error(res.data.message);
        }
      }).catch((error) => {
        if (setLoadingSave) setLoadingSave(false);
        toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        console.log(error);
      })
    } else {
      console.log("Null _id error");
    }
  }
}


/*
 * Update Supplier
 */
export const putSupplier = (data, requestData, action = "update", requestData2 = {}, clearForm = false) => {
  return (dispatch) => {
    if (data._id !== null) {
      data.id = data._id;
      axios.put(apiURL2 + `/supplier/update-supplier`, data, {
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        if (res.data && res.data.status === "200") {
          toast.success("Supplier updated.");
          dispatch(fetchInitialSuppliers(requestData));
          dispatch(addToForceRefresh("Supplier Search"));
        } else {
          toast.error(res.data.error);
        }
      }).catch((error) => {
        toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        console.log(error);
      })
    } else {
      console.log("Null _id error");
    }
  }
}

/*
 * Add Client Movement
 */
export const postClientMovements = (data, requestData) => {
  return (dispatch) => {
    data.type = "clientMovements";
    axios.post(apiURL2 + `/client/add-client-movement`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("New Client Movements Added.");
        dispatch(addToForceRefresh("Client Movements Search"));
        dispatch(resetNewClientMovements());
      } else {
        toast.error(res.data.message);
      }
    }).catch((error) => {
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
      console.log(error);
    })
  }
}
/*
 * Add Supplier Movement
 */
export const postSupplierMovements = (data, requestData) => {
  return (dispatch) => {
    data.type = "supplierMovements";
    axios.post(apiURL2 + `/supplier/add-supplier-movement`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("New Supplier Movements Added.");
        dispatch(addToForceRefresh("Supplier Movements Search"));
      } else {
        toast.error(res.data.error);
      }
    }).catch((error) => {
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
      console.log(error);
    })
  }
}
/*
 * Update Client Movements
 */
export const putClientMovements = (data, requestData, action = "update") => {
  return (dispatch) => {
    axios.put(apiURL2 + `/client/update-client-movement`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(addToForceRefresh("Client Movements Search"));
        toast.success("Client Movement updated.");
      } else {
        toast.error(res.data.message);
      }
    }).catch((error) => {
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
      console.log(error);
    })
  }
}

/*
 * Update Supplier Movements
 */
export const putSupplierMovements = (data, requestData, action = "update") => {
  return (dispatch) => {
    if (data._id !== null) {
      axios.put(apiURL2 + `/supplier/update-supplier-movement`, data, {
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        if (res.data && res.data.status === "200") {
          toast.success("Supplier Movement updated.");
          dispatch(addToForceRefresh("Supplier Movements Search"));
        } else {
          toast.error(res.data.error);
          console.log(res.data.error);
        }
      }).catch((error) => {
        toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        console.log(error);
      })
    } else {
      console.log("Null _id error");
    }
  }
}

/*
 * Product Search Table API
 */
export const fetchProductSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/category/master-product-search`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setTotalItemsPS(res.data.totalItems));
        dispatch(setSearchProducts(res.data.data));
        dispatch(setPerPagePS(res.data.perPage));
        if (res.data.data && res.data.data.length === 0) toast.info("No records found.")
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

/*`
 * Product Movements Search Table API
 */
export const fetchProductMovementsSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/category/master-product-movement-search`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setSearchProductMovements(res.data.data));
        dispatch(setTotalItemsPM(res.data.totalItems));
        dispatch(setPerPagePM(res.data.perPage));
        if (res.data.data && res.data.data.length === 0) toast.info("No records found.");
      } else {
        toast.error(res.data.error);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

/*
 * Product Price List Search Table API
 */
export const fetchProductPriceListSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/category/master-product-price-search`, data,
      { headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data.status === "200") {
        dispatch(setSearchProductPriceList(res.data.data));
        dispatch(setTotalItemsPP(res.data.totalItems));
        dispatch(setPerPagePP(res.data.perPage));
      }
    }).catch((err) => {
      console.log(err);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

/*
 * Client Search Table API
 */
export const fetchClientSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/client/master-client-search`, data, {
      headers: {"Content-Type": "application/json"},
    }).then((res) => {
      if (res.data.status === "200") {
        dispatch(setSearchClients(res.data.data.data));
        dispatch(setperPageCS(res.data.data.perPage));
        dispatch(setTotalItemsCS(res.data.data.totalItems));
        if (res.data.data && res.data.data.data.length === 0) toast.info("No records found.");
      } else {
        dispatch(setSearchClients([]));
        toast.error(res.data.message);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}


/*
 * Supplier Search  Table API
 */
export const fetchSupplierSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/supplier/master-supplier-search`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data.status === "200") {
        dispatch(setSearchSuppliers(res.data.data.data));
        dispatch(setperPageSSS(res.data.data.perPage));
        dispatch(setTotalItemsSSS(res.data.data.totalItems));
        if (res.data.data && res.data.data.data.length === 0) toast.info("No records found.");
      } else {
        dispatch(setSearchSuppliers([]));
        toast.error(res.data && res.data.message);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

/*
 * Client Search Table API
 */
export const fetchClientMovementsSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/client/master-client-movement-search`, data, {
      headers: {"Content-Type": "application/json"}
    }).then((res) => {
      if (res.data.status === "200") {
        dispatch(setSearchClientMovements(res.data.data.data));
        dispatch(setperPageCMS(res.data.data.perPage));
        dispatch(setTotalItemsCMS(res.data.data.totalItems));
        if (res.data.data && res.data.data.data.length === 0) toast.info("No records found.");
      } else {
        dispatch(setSearchClientMovements([]));
        toast.error(res.data && res.data.message);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}


/*
 * Supplier Search Table API
 */
export const fetchSupplierMovementsSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/supplier/master-supplier-movement-search`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data.status === "200") {
        dispatch(setSearchSupplierMovements(res.data.data.data));
        dispatch(setTotalItemsSMSS(res.data.data.totalItems));
        dispatch(setperPageSMSS(res.data.data.perPage));
        if (res.data.data && res.data.data.data.length === 0) toast.info("No records found.");
      } else {
        dispatch(setSearchSupplierMovements([]));
        toast.error("No records found.");
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}
/*
 * Add Cash Registers
 */
export const postCashRegisters = (data, requestData) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/cash/add-cash-registers`, data, {
      headers: {"Content-Type": "application/json"},
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("New Cash Register Added.");
        dispatch(fetchCashRegisters(requestData));
      } else {
        toast.error(res.data.error);
      }
    }).catch((error) => {
      if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors
      ) {
        toast.error(error.response.data.errors);
      }
    })
  }
}

/*
 * Fetch Cash Registers
 */
export const fetchCashRegisters = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/cash/list-cash-registers`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data.status === "200") {
        dispatch(setSearchCashRegisters(res.data.data));
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

/*
 * Update Cash Registers
 */

export const putCashRegisters = (data, requestData, action = "update", requestData2 = {}) => {
  return (dispatch) => {
    if (data._id !== null) {
      data.id = data._id;
      if ("_id" in data) {
        delete data._id;
      }
      if ("__v" in data) {
        delete data.__v;
      }
      if ("type" in data) {
        delete data.type;
      }
      axios.put(apiURL2 + `/cash/update-cash-registers`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        if (res.data && res.data.status === "200") {
          toast.success("Cash Register updated.");
          dispatch(fetchCashRegisters(requestData));
        } else {
          toast.error(res.data.error);
          console.log(res.data.error);
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
      })
    } else {
      console.log("null id error");
    }
  }
}

/*
 * Add Bank Accounts
 */
export const postBankAccounts = (data, requestData) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/cash/add-bank-accounts`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("New Bank Account Added.");
        dispatch(fetchBankAccounts(requestData));
      } else {
        toast.error(res.data.error);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

/*
 * Fetch Bank Accounts
 */
export const fetchBankAccounts = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/cash/list-bank-accounts`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setSearchBankAccounts(res.data.data));
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

export const fetchBankAccountsOnChange = (data, setData) => {
  return () => {
    axios.post(apiURL2 + `/cash/list-bank-accounts/`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        setData(res.data.data);
      }
      if (res.data.status === "422") {
        setData([]);
      }
    }).catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        toast.error(error.response.data.errors);
      }
    })
  }
}

/*
 * Update Bank Accounts
 */
export const putBankAccounts = (data, requestData, action = "update", requestData2 = {}) => {
  return (dispatch) => {
    if (data._id !== null) {
      data.id = data._id;
      if ("_id" in data) {
        delete data._id;
      }
      if ("__v" in data) {
        delete data.__v;
      }
      if ("type" in data) {
        delete data.type;
      }
      axios.put(apiURL2 + `/cash/update-bank-accounts`, JSON.stringify(data), {
        headers: {"Content-Type": "application/json"},
      }).then((res) => {
        if (res.data && res.data.status === "200") {
          toast.success("Bank Account updated.");
          dispatch(fetchBankAccounts(requestData));
        } else {
          toast.error(res.data.error);
          console.log(res.data.error);
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
      })
    } else {
      console.log("null id error");
    }
  }
}

/*
 * Add POS
 */
export const postPOS = (data, requestData, setLoadingSave) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/cash/add-pos`, JSON.stringify(data), {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setLoadingSave(false);
      if (res.data && res.data.status === "200") {
        toast.success("New POS added.");
        dispatch(fetchPOS(requestData));
        dispatch(resetNewPOS());
      } else {
        toast.error(res.data.error);
      }
    }).catch((error) => {
      setLoadingSave(false);
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

/*
 * Fetch POS
 */
export const fetchPOS = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/cash/list-pos`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data.status === "200") {
        dispatch(setSearchPOS(res.data.data));
      }
    }).catch((err) => {
      console.log(err);
    })
  }
}

export const fetchPOSOnChange = (data, setData) => {
  return async () => {
    await axios.post(apiURL2 + `/cash/list-pos/`, JSON.stringify(data), {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        setData(res.data.data);
      }
      if (res.data.status === "422") {
        setData([]);
      }
    }).catch((error) => {
      console.log(error);
    })
  }
}

/*
 * Update POS
 */
export const putPOS = (data, requestData, setLoadingSave) => {
  return (dispatch) => {
    axios.put(apiURL2 + `/cash/update-pos`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setLoadingSave(false);
      if (res.data && res.data.status === "200") {
        toast.success("POS updated.");
        dispatch(fetchPOS(requestData));
      } else {
        toast.error(res.data.error);
        console.log(res.data.error);
      }
    }).catch((error) => {
      setLoadingSave(false);
      toast.error("Connection error.");
      console.log(error);
    })
  }
}

/*
 * Add Sales General Settings
 */
export const postSalesGeneralSettings = (data, requestData) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/sale/add-sales-general-email-setting`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("Sales General Settings Added");
        dispatch(fetchSalesGeneralSettings(requestData));
      } else {
        toast.error(res.data.error);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}


/*
 * Add Buys General Settings
 */
export const postBuysGeneralSettings = (data, requestData) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/buy/add-buys-general-email-setting/`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("Buys General Settings Added");
        dispatch(fetchBuysGeneralSettings(requestData));
      } else {
        toast.error(res.data.error);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

/*
 * Update Sales General Settings
 */
export const putSalesGeneralSettings = (data, requestData) => {
  return (dispatch) => {
    if (data._id !== null) {
      data.id = data._id;
      if ("_id" in data) delete data._id;
      if ("__v" in data) delete data.__v;
      axios.post(apiURL2 + `/sale/add-sales-general-email-setting/`, data, {
        headers: { "Content-Type": "application/json" }
      }).then((res) => {
        if (res.data && res.data.status === "200") {
          toast.success("Sales General Settings Updated");
          dispatch(fetchSalesGeneralSettings(requestData));
        } else {
          toast.error(res.data.error);
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
      })
    }
  }
}

/*
 * Update Buys General Settings
 */
export const putBuysGeneralSettings = (data, requestData) => {
  return (dispatch) => {
    if (data._id !== null) {
      data.id = data._id;
      if ("_id" in data) delete data._id;
      if ("__v" in data) delete data.__v;
      axios.post(apiURL2 + `/buy/add-buys-general-email-setting`, data, {
        headers: { "Content-Type": "application/json" }
      }).then((res) => {
        if (res.data && res.data.status === "200") {
          toast.success("Buys General Settings Updated");
          dispatch(fetchBuysGeneralSettings(requestData));
        } else {
          toast.error(res.data.error);
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
      })
    }
  }
}

/*
 * Fetch Sales General Settings
 */
export const fetchSalesGeneralSettings = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/sale/sales-general-email-setting-list`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        if (res.data.data.length > 0) {
          dispatch(setSalesGeneralSettings(res.data.data[res.data.data.length - 1]));
        }
      }
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.errors) {
        toast.error(error.response.data.errors);
      }
    })
  }
}

export const fetchSyncInvoicesProviderLog = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/company/fetch-sync-invoices-provider-log`, { company: data }, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setSyncInvoicesProviderLog(res.data.data));
      }
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.errors) {
        toast.error(error.response.data.errors);
      }
    })
  }
}

export const fetchDashboardData = (companyId, companyYear, setLoader) => {
  if (typeof setLoader === 'function') {
    setLoader(true);
  }
  return (dispatch) => {
    axios.post(apiURL2 + `/company/fetch-dashboard-data`, {company: companyId, year: companyYear}, {
      headers: { "Content-Type": "application/json" }
    }).then(async (res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setDashboardData(res.data.data));
      }
      if (typeof setLoader === 'function') {
        setLoader(false);
        toast.success("Επιτυχής Ενημέρωση!");
      }
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.errors) {
        toast.error(error.response.data.errors);
      }
      if (typeof setLoader === 'function') {
        setLoader(false);
      }
    })
  }
}
export const triggerSyncProviderInvoices = (data, setLoader, mass = false, year) => {
  setLoader(true);
  return (dispatch) => {
    axios.post(apiURL2 + `/company/sync-invoices-provider`, {company: data, mass: mass, year: year}, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      dispatch(fetchSyncInvoicesProviderLog(data));
      setLoader(false);
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.errors) {
        toast.error(error.response.data.errors);
      }
      setLoader(false);
    })
  }
}

export const triggerCleanFailedSyncs = (data, setLoader) => {
  setLoader(true);
  return (dispatch) => {
    axios.post(apiURL2 + "/company/clear-failed-syncs/", {company: data}, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
      dispatch(fetchSyncInvoicesProviderLog(data));
      setLoader(false);
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.errors) {
        toast.error(error.response.data.errors);
      }
      setLoader(false);
    })
  }
}


/*
 * Fetch Buys General Settings
 */
export const fetchBuysGeneralSettings = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/buy/buys-general-email-setting-list/`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        if (res.data.data.length > 0) {
          dispatch(setBuysGeneralSettings(res.data.data[res.data.data.length - 1]));
        }
      }
    }).catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        toast.error(error.response.data.errors);
      }
    })
  }
}


/*
 * Add Quick Retail Sales Settings
 */
export const postQuickRetailSalesSettings = (data, requestData) => {
  return (dispatch) => {
    if ("_id" in data) {
      delete data._id;
    }
    if ("__v" in data) {
      delete data.__v;
    }
    axios.post(apiURL2 + `/sale/add-quickRetail-sales-settings`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("Quick Retail Sales Settings Updated");
        dispatch(fetchQuickRetailSalesSettings(requestData));
      } else {
        toast.error(res.data.error);
      }
    }).catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        toast.error(error.response.data.errors);
      }
    })
  }
}

/*
 * Fetch Quick Retail Sales Settings
 */
export const fetchQuickRetailSalesSettings = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/sale/list-quickRetail-sales-settings/`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        if (res.data.data.length > 0) {
          dispatch(setQuickRetailSettings(res.data.data[res.data.data.length - 1]));
        }
      }
    }).catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        toast.error(error.response.data.errors);
      }
    })
  }
}

/*
 * Get Automatic Sales
 */
export const fetchAutomaticSales = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/sale/list-automatic-sale`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setAutomaticSales(res.data.data));
      }
      if (res.data && res.data.status === "422") {
        console.log("Automatic Sales not found.");
      }
    }).catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        toast.error(error.response.data.errors);
      }
    })
  }
}

/*
 * Add Automatic Sales
 */
export const postAutomaticSales = (data, requestData) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/sale/add-automatic-sale`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("New Automatic Sales Added.");
        dispatch(fetchAutomaticSales(requestData));
      } else {
        toast.error(res.data.error);
      }
    }).catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        toast.error(error.response.data.errors);
      }
    })
  }
}
/*
 * Add Sales
 */
export const postSales = (data, requestData, handleDpActions = null, printOnSave = false, sendEmail, setLoader, advancedTypesSales) => {
  setLoader(true);
  let logRequestData = {
    company: requestData.company
  };
  if (data?.newClient && data?.newClient !== "") {
    data.clientName = data.newClient;
  }
  let saveType = data?.isActive;
  return (dispatch) => {
    axios
      .post(apiURL2 + `/sale/add-sale/`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then(async (res) => {
        if (res.data && res.data.status === "200") {
          toast.success("Sales added successfully.");
          if(res.data.data?.myDataError !== "" &&  res.data.data?.myDataError !== undefined){
            toast.error(res.data.data?.myDataError);
          }
          await dispatch(fetchAdvancedTypesSales(logRequestData));
          await dispatch(setNewSales(res.data.data));
          // Used in Data Panel index to show print on save
          if (handleDpActions !== null && printOnSave === true) {
           await handleDpActions("printOnSave");
          }
          if (handleDpActions !== null && sendEmail === "active") {
            await handleDpActions("sendEmailOnSave", res.data.data);
          }
          dispatch(addToForceRefresh("Sales Search"));
          await dispatch(resetNewSales());
          await dispatch(setSaveSales(false)); // Set save status false
          // Set Data to new if save and repeat
          if (res.data.data && saveType === "draft") {
            let draftSale = JSON.parse(JSON.stringify(res.data.data));
            let date = new Date();
            const currentTime = leadingZeros(date.getHours(), 2) + ":" + leadingZeros(date.getMinutes(), 2);
            // Passing updated time on template selection
            draftSale.date = date;
            draftSale.time = currentTime;
            draftSale.number = "";
            draftSale._id = undefined;
            draftSale.myDataStatus = "not_sent";
            draftSale.transmissionFailure = undefined;
            draftSale.mark = undefined;
            draftSale.uid = undefined;
            draftSale.authCode = undefined;
            draftSale.myDataRequest = undefined;
            draftSale.myDataEnvironment = undefined;
            draftSale.myDataError = undefined;
            draftSale.myDataDestination = undefined;
            let dtData = getObjectFromArrayOfObjects(advancedTypesSales, draftSale.documentType, '_id');
            let selectedDocumentTypeData = dtData['0'];
            let selectedInstallation = getObjectFromArrayOfObjects(selectedDocumentTypeData['documentNumbering'], draftSale.installation, "installationMasterId");
            let selectedInstallationNumbering = selectedInstallation.filter(selected => selected.preNumber === draftSale.preNumber);
            console.log(selectedInstallationNumbering)
            if (Object.keys(selectedInstallation).length > 0 && selectedInstallationNumbering.length > 0) {
              draftSale.number = selectedInstallationNumbering[0].number + 1;
            } else {
              draftSale.number = "";
            }
            await dispatch(resetNewSales());
            await dispatch(setNewSales(draftSale));
            await dispatch(setSaveSales(true));
          } else {
            await dispatch(resetNewSales());
            await dispatch(setSaveSales(false)); // Set save status false
          }
          await dispatch(setSendEmail("inactive"));
        } else {
          toast.error(res.data.message);
        }
        setLoader(false);
      })
      .catch((error) => {
        console.log(error)
        if (error && error.response && error.response.data && error.response.data.errors) {
          toast.error(error.response.data.errors);
        }
        dispatch(resetNewSales());
        dispatch(setSaveSales(false)); // Set save status false
        setLoader(false);
      });
  };
};

/*
 * Add Buys
 */
export const postBuys = (data, requestData, handleDpActions = null, printOnSave = false, setLoader, advancedTypesSales, sendEmail) => {
  setLoader(true);
  let logRequestData = {
    company: requestData.company
  };
  // For new client case we are providing the name to clinetName also to resolve searching confict at backend.
  if (data.newSupplier && data.newSupplier !== "") {
    data.supplierName = data.newSupplier;
  }
  let saveType = data.isActive;
  return (dispatch) => {
    axios
      .post(apiURL2 + `/buy/add-buy/`, JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then(async (res) => {
        if (res.data && res.data.status === "200") {
          toast.success("Buys added successfully.");
          if(res.data.data?.myDataError !== "" &&  res.data.data?.myDataError !== undefined){
            toast.error(res.data.data?.myDataError);
          }
          await dispatch(fetchAdvancedTypesBuys(logRequestData));
          dispatch(setNewBuys(res.data.data));
          // Used in Data Panel index to show print on save
          if (handleDpActions !== null && printOnSave === true) {
            await handleDpActions("printOnSave");
          }
          if (handleDpActions !== null && sendEmail === "active") {
            await handleDpActions("sendEmailOnSave", res.data.data);
          }
          dispatch(addToForceRefresh("Buys Search"));
          // Set Data to new if save and repeat
          if (res.data.data && saveType === "draft") {
            let draftBuy = JSON.parse(JSON.stringify(res.data.data));
            let date = new Date();
            const currentTime = leadingZeros(date.getHours(), 2) + ":" + leadingZeros(date.getMinutes(), 2);
            // Passing updated time on template selection
            draftBuy.date = date;
            draftBuy.time = currentTime;
            draftBuy.number = "";
            draftBuy._id = undefined;
            draftBuy.myDataStatus = "not_sent";
            draftBuy.transmissionFailure = undefined;
            draftBuy.mark = undefined;
            draftBuy.uid = undefined;
            draftBuy.authCode = undefined;
            draftBuy.myDataRequest = undefined;
            draftBuy.myDataEnvironment = undefined;
            draftBuy.myDataError = undefined;
            let dtData = getObjectFromArrayOfObjects(advancedTypesSales, draftBuy.documentType, '_id');
            let selectedDocumentTypeData = dtData['0'];
            let selectedInstallation = getObjectFromArrayOfObjects(selectedDocumentTypeData['documentNumbering'], draftBuy.installation, "installationMasterId");
            let selectedInstallationNumbering = selectedInstallation.filter(selected => selected.preNumber === draftBuy.preNumber);
            console.log(selectedInstallationNumbering)
            if (Object.keys(selectedInstallation).length > 0 && selectedInstallationNumbering.length > 0) {
              draftBuy.number = selectedInstallationNumbering[0].number + 1;
            } else {
              draftBuy.number = "";
            }
            dispatch(resetNewBuys());
            dispatch(setNewBuys(draftBuy));
          } else {
              dispatch(resetNewBuys());
              dispatch(setSaveBuys(false)); // Set save status false
          }
        } else {
          toast.error(res.data.error);
        }
        setLoader(false);
      })
      .catch((error) => {
        console.log(error)
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          toast.error(error.response.data.errors);
        }
        setLoader(false);
      })
  }
}

/*
 * Update Sales
 */
export const putSales = (data, requestData) => {
  let saveType = data.isActive;
  return (dispatch) => {
    if (data._id !== null) {
      data.id = data._id;
      axios.put(apiURL2 + `/sale/update-sale`, data, {
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        if (res.data && res.data.status === "200") {
          toast.success("Sales Updated.");
          if (res.data.data && saveType === "draft") {
            dispatch(setNewSales(res.data.data));
          }
        } else {
          toast.error(res.data.error);
        }
      }).catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          toast.error(error.response.data.errors);
        }
      })
    }
  }
}

/*
 * Update Buys
 */
export const putBuys = (data, requestData) => {
  let saveType = data.isActive;
  return (dispatch) => {
    if (data._id !== null) {
      data.id = data._id;
      axios.put(apiURL2 + `/buy/update-buy`, data, {
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        if (res.data && res.data.status === "200") {
          toast.success("Buy updated.");
          dispatch(addToForceRefresh("Buys Search"));
          if (res.data.data && saveType === "draft") {
            dispatch(setEditBuys(res.data.data));
          }
        } else {
          toast.error(res.data.message);
        }
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.errors) {
          toast.error(error.response.data.errors);
        }
      })
    }
  }
}
/*
 * Fetch Sales
 */
export const fetchSales = (data) => {
  if ("type" in data) {
    delete data.type;
  }
  return (dispatch) => {
    axios.post(apiURL2 + `/sale/list-sale/`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setSales(res.data.data));
      }
    }).catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        toast.error(error.response.data.errors);
      }
    })
  }
}


/*
 * Fetch Buys
 */
export const fetchBuys = (data) => {
  if ("type" in data) {
    delete data.type;
  }
  return (dispatch) => {
    axios.post(apiURL2 + `/buy/list-buy/`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setBuys(res.data.data));
      }
    }).catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        toast.error(error.response.data.errors);
      }
    })
  }
}

/*
 * Fetch Client
 */
export const fetchClient = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/client/list-client`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setClientsList(res.data.data));
      }
    }).catch(() => {
      toast.error("Unable to load client.");
    });
  }
}
/*
 * Get Client Payment
 */
export const fetchClientPaymentData = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/client/list-client-payment`, data, {
      headers: {"Content-Type": "application/json"}
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setClientsPayment(res.data.data));
      }
      if (res.data && res.data.status === "422") {
        console.log("Client settings not found.");
      }
    }).catch((error) => {
      if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors
      ) {
        toast.error(error.response.data.errors);
      } else {
        console.log("Unable to fetch client settings.");
      }
    })
  }
}

/*
 * Get Supplier Payment
 */
export const fetchSupplierPaymentData = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/supplier/list-supplier-payment`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setSuppliersPayment(res.data.data));
      }
      if (res.data && res.data.status === "422") {
        console.log("Supplier settings not found.");
      }
    }).catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        toast.error(error.response.data.errors);
      } else {
        console.log("Unable to fetch supplier settings.");
      }
    })
  }
}

/*
 * Get Client Payments
 */
export const fetchClientPayments = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/client/list-client-payment`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setClientPayments(res.data.data));
      }
      if (res.data && res.data.status === "422") {
        console.log("Client Payments not found.");
      }
    }).catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        console.log(error.response.data.errors);
      } else {
        console.log("Unable to fetch client Payments.");
      }
    })
  }
}

/*
 * Get Supplier Payments
 */
export const fetchSupplierPayments = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/supplier/list-supplier-payment`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        dispatch(setSupplierPayments(res.data.data));
      }
      if (res.data && res.data.status === "422") {
        console.log("Supplier Payments not found.");
      }
    }).catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        console.log(error.response.data.errors);
      } else {
        console.log("Unable to fetch supplier Payments.");
      }
    })
  }
}

/*
 * Add Client Payment
 */
export const postClientPayment = (data, requestData, setLoader, handleDpActions = null, printOnSave = false, saveAndRepeat = false, sendEmail, setShowPOSModal, setShowPOSModalCancel) => {
  let logRequestData = {
    company: requestData.company,
    year: requestData.year,
  }
  setLoader(true);
  return (dispatch) => {
    data.posAmount = data?.posDetails["0"]?.amount ? data.posDetails["0"].amount : 0;
    data.bankAmount = data?.bankDetails["0"]?.amount ? data.bankDetails["0"].amount : 0;
    data.chequeAmount = data?.chequeDetails["0"]?.amount ? data.chequeDetails["0"].amount : 0;
    axios.post(apiURL2 + `/client/add-client-payment`, data, {
      headers: { "Content-Type": "application/json" },
    }).then(async (res) => {
      if (setShowPOSModal && typeof setShowPOSModal === "function") setShowPOSModal(false);
      if (setShowPOSModalCancel && typeof setShowPOSModalCancel === "function" && data.myDataType === "8.5") setShowPOSModalCancel(false);
      if (res.data && res.data.status === "200") {
        toast.success("New Client Payment Added.");
        await dispatch(setNewClientPayment(res.data.data));
        // Used in Data Panel index to show print on save
        if (handleDpActions !== null && printOnSave === true) {
          await handleDpActions("printOnSave");
        }
        if (handleDpActions !== null && sendEmail === "active") {
          await handleDpActions("sendEmailOnSave", res.data.data);
        }
        if (saveAndRepeat) {
          let newPayment = structuredClone(data);
          let date = new Date();
          const currentTime = leadingZeros(date.getHours(), 2) + ":" + leadingZeros(date.getMinutes(), 2);
          newPayment.date = date;
          newPayment.time = currentTime;
          newPayment.number = String(Number(data.number) + 1);
          newPayment.paymentType = "";
          newPayment.paymentTypeName = "";
          newPayment.paymentTypeType = "";
          newPayment.paymentNotes = "";
          newPayment.amount = "0";
          newPayment.reason = "";
          dispatch(softResetNewClientPayment(newPayment));
        } else {
          dispatch(resetNewClientPayment());
        }
        dispatch(addToForceRefresh("Client Payments Search"));
        dispatch(fetchAdvancedTypes(logRequestData));
      } else {
        toast.error(res.data.message);
        dispatch(setSaveClientPayments(true));
      }
      setLoader(false);
    }).catch((error) => {
      console.log(error);
      if (setShowPOSModal && typeof setShowPOSModal === "function") setShowPOSModal(false);
      if (setShowPOSModalCancel && typeof setShowPOSModalCancel === "function" && data.myDataType === "8.5") setShowPOSModalCancel(false);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        toast.error(error.response.data.errors);
      } else {
        console.log("Σφάλμα κατά την αποστολή αιτήματος.");
      }
      setLoader(false);
    })
  }
}

/*
 * Add Supplier Payment
 */
export const postSupplierPayment = (data, requestData, setLoadingSave, saveAndRepeat = false) => {
  let logRequestData = {
    company: requestData.company,
    year: requestData.year,
  };
  return (dispatch) => {
    data.posAmount = data.posDetails["0"].amount;
    data.bankAmount = data.bankDetails["0"].amount;
    data.chequeAmount = data.chequeDetails["0"].amount;
    axios.post(apiURL2 + `/supplier/add-supplier-payment`, data, {
      headers: {"Content-Type": "application/json"},
    }).then((res) => {
      setLoadingSave(false);
      if (res.data && res.data.status === "200") {
        toast.success("New Supplier Payment Added.");
        if (saveAndRepeat) {
          let newPayment = data;
          let date = new Date();
          const currentTime = leadingZeros(date.getHours(), 2) + ":" + leadingZeros(date.getMinutes(), 2);
          newPayment.date = date;
          newPayment.time = currentTime;
          newPayment.number = String(Number(data.number) + 1);
          newPayment.paymentType = "";
          newPayment.paymentTypeName = "";
          newPayment.paymentTypeType = "";
          newPayment.paymentNotes = "";
          newPayment.amount = "0";
          newPayment.reason = "";
          dispatch(softResetNewSupplierPayment(newPayment));
        } else {
          dispatch(resetNewSupplierPayment());
        }
        dispatch(addToForceRefresh("Supplier Payments Search"));
        dispatch(fetchAdvancedTypes(logRequestData));
      } else {
        toast.error(res.data.error);
      }
    }).catch((error) => {
      setLoadingSave(false);
      if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors
      ) {
        toast.error(error.response.data.errors);
      } else {
        console.log("Unable to add supplier payment.");
      }
    })
  }
}


/*
 * Update Client Payment
 */
export const putClientPayment = (data) => {
  return (dispatch) => {
    if (data._id !== null) {
      data.id = data._id;
      data.posAmount = data?.posDetails["0"]?.amount ? data.posDetails["0"].amount : 0;
      data.bankAmount = data?.bankDetails["0"]?.amount ? data.bankDetails["0"].amount : 0;
      data.chequeAmount = data?.chequeDetails["0"]?.amount ? data.chequeDetails["0"].amount : 0;
      axios.put(apiURL2 + `/client/update-client-payment`, data, {
          headers: { "Content-Type": "application/json" },
        }).then((res) => {
          if (res.data && res.data.status === "200") {
            toast.success("Client Payment updated.");
            dispatch(addToForceRefresh("Client Payments Search"));
          } else {
            toast.error(res.data.message);
          }
        }).catch((error) => {
          toast.error(error + " Update Client Payment");
        });
    } else {
      console.log("Null id error. Please refresh and try again.");
    }
  }
}

/*
 * Update Supplier Payment
 */
export const putSupplierPayment = (data, requestData, setLoadingSave) => {
  return (dispatch) => {
    if (data._id !== null) {
      data.id = data._id;
      data.posAmount = data?.posDetails["0"]?.amount ? data.posDetails["0"].amount : 0;
      data.bankAmount = data?.bankDetails["0"]?.amount ? data.bankDetails["0"].amount : 0;
      data.chequeAmount = data?.chequeDetails["0"]?.amount ? data.chequeDetails["0"].amount : 0;
      axios.put(apiURL2 + `/supplier/update-supplier-payment`, data, {
        headers: {"Content-Type": "application/json"},
      }).then((res) => {
        setLoadingSave(false);
        if (res.data && res.data.status === "200") {
          toast.success("Supplier Payment updated.");
          dispatch(addToForceRefresh("Supplier Payments Search"));
        } else {
          toast.error(res.data.error);
        }
      }).catch((error) => {
        console.log(error);
        setLoadingSave(false);
        toast.error(error + " Update Supplier Payment");
      })
    } else {
      console.log("Null id error. Please refresh and try again.");
    }
  }
}

export const sendFilteredToMyDataApi = (data, setSendFilteredToMyDataResponse, showSendFilteredToMyDataResponse, setLoader, type) => {
  /*
    type: sale || buy || accounting
   */
  return () => {
    setLoader(true);
    axios.post(apiURL2 + `/${type}/mass-send-to-mydata`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        let responseStringModal = "";
        if(res.data.data.length > 0) {
          res.data.data.map(obj => {
            if(obj?.uid !== undefined) {
              responseStringModal += obj?.name + " Mark: " + obj?.mark + " Uid: " + obj?.uid + " MyDataStatus: " + obj?.myDataStatus + "\n\n";
            } else if(obj?.message !== undefined){
              responseStringModal += obj?.name + " Error: " + obj?.message + " \n\n";
            } else {
              responseStringModal += obj?.name  + " MyDataStatus: " + obj?.myDataStatus + " Error: " + obj?.myDataError + " \n\n";
            }
            return obj;
          })
        } else {
          responseStringModal = "No data found with these filters.";
        }
        setSendFilteredToMyDataResponse(responseStringModal);
        showSendFilteredToMyDataResponse(true);
        setLoader(false);
      } else {
        setSendFilteredToMyDataResponse(res.data.message);
        showSendFilteredToMyDataResponse(true);
        setLoader(false);
      }
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.errors) {
        console.log(error.response.data.errors);
        toast.error(error.response.data.errors);
        setLoader(false);
      }
    })
  }
}

/*
 * Search Sales
 */
export const fetchSalesSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/sale/sales-search-data`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        if (res.data.data && res.data.data.length === 0) toast.info("No records found.");
        dispatch(setSearchSales(res.data.data.data));
        if (res.data.data.totalItems && res.data.data.perPage) {
          dispatch(setTotalItemsSS(res.data.data.totalItems));
          dispatch(setPerPageSS(res.data.data.perPage));
        }
      } else {
        if (res.data && res.data.message) {
          toast.error(res.data && res.data.message);
        }
        if (res.data && res.data.error) {
          toast.error(res.data && res.data.error);
        }
        dispatch(setSearchSales([])); // reset table
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

/*
 * Search Buys
 */
export const fetchBuysSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/buy/buys-search-data`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        if (res.data.data && res.data.data.length === 0) {
          toast.info("No records found.");
        }
        if(res.data.data.data.length > 0) {
          for (let item of res.data.data.data) {
            item.preNumberAndNumber = item.preNumber.replace("noprefix", "") + " " + item.number;
          }
        }
        dispatch(setSearchBuys(res.data.data.data));
        if (res.data.data.totalItems && res.data.data.perPage) {
          dispatch(setTotalItemsBS(res.data.data.totalItems));
          dispatch(setPerPageBS(res.data.data.perPage));
        }
      } else {
        if (res.data && res.data.message) {
          toast.error(res.data && res.data.message);
        }
        if (res.data && res.data.error) {
          toast.error(res.data && res.data.error);
        }
        dispatch(setSearchBuys([])); // reset table
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

/*
 * Search Quick Retail Sales
 */
export const fetchQuickRetailSalesSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/sale/quick-retail-sales-search`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        let resData = res.data.data.data;
        let filteredRes = [];
        if (resData.length > 0) {
          resData.forEach((item) => {
            Object.keys(item).forEach((field) => {
              if (field === "products") {
                let productList = [];
                if (item[field] && item[field].length > 0) {
                  // Get all products names
                  item[field].forEach((pitem) => {
                    if (pitem.name !== "") {
                      if (!productList.includes(pitem.productName)) {
                        productList.push(pitem.productName);
                      }
                    }
                  });
                  // Convert to string
                  item[field] = productList.toString();
                }
              }
            });
            filteredRes.push(item);
          });
          dispatch(setQuickRetailSalesSearch(filteredRes));
          dispatch(setTotalItemsQRS(res.data.data.totalItems));
          dispatch(setPerPageQRS(res.data.data.perPage));
        } else {
          toast.info("No records found.");
        }
      } else {
        if (res.data && res.data.message) {
          toast.error(res.data && res.data.message);
        } else {
          toast.error(res.data && res.data.error);
        }
        dispatch(setQuickRetailSalesSearch([])); // reset table
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

/*
 * Search Automatic Sales
 */
export const fetchAutomaticSalesSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/sale/automatic-sales-search`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data.status === "401") {
        localStorage.clear();
        window.location.replace(`${apiLoginURL}?redirect=ERP&error=${errorAuth}`);
      }
      if (res.data && res.data.status === "200") {
        dispatch(setAutomaticSalesSearch(res.data.data.data));
        dispatch(setPerPageASS(res.data.data.perPage));
        dispatch(setTotalItemsASS(res.data.data.totalItems));
        if (res.data.data && res.data.data?.length === 0) {
          toast.info("No records found.");
        }
      } else {
        if (res.data && res.data.message) {
          toast.error(res.data && res.data.message);
        } else {
          toast.error(res.data && res.data.error);
        }
        dispatch(setAutomaticSalesSearch([])); // reset table
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

/*
 * Search Client Payments
 */
export const fetchClientPaymentsSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/client/client-payment-search`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        if (res.data.data.data && res.data.data.data.length > 0) {
          for (let cp of res.data.data.data) {
            cp.preNumberAndNumber = cp.preNumber.replace("noprefix", "") + " " + cp.number;
          }
        }
        dispatch(setClientPaymentsSearch(res.data.data.data));
        dispatch(setTotalItemsCPS(res.data.data.totalItems));
        dispatch(setperPageCPS(res.data.data.perPage));
        if (res.data.data.data && res.data.data.data.length === 0) {
          toast.info("No records found.");
        }
      } else {
        if (res.data && res.data.message) {
          toast.error(res.data && res.data.message);
        } else {
          toast.error(res.data && res.data.error);
        }
        dispatch(setClientPaymentsSearch([])); // reset table
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}


/*
 * Search Supplier Payments
 */
export const fetchSupplierPaymentsSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/supplier/supplier-payment-search`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        if (res.data.data.data && res.data.data.data.length > 0) {
          for (let sp of res.data.data.data) {
            sp.preNumberAndNumber = sp.preNumber.replace("noprefix", "") + " " + sp.number;
          }
        }
        dispatch(setSupplierPaymentsSearch(res.data.data.data));
        dispatch(setTotalItemsSPS(res.data.data.totalItems));
        dispatch(setperPageSPS(res.data.data.perPage));
        if (res.data.data.data && res.data.data.data.length === 0) toast.info("No records found.");
      } else {
        if (res.data && res.data.message) {
          toast.error(res.data && res.data.message);
        } else {
          toast.error(res.data && res.data.error);
        }
        dispatch(setSupplierPaymentsSearch([]));
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}
/*
 * Get Quick Retail Sales
 */
export const fetchQuickRetailSales = (data) => {
  return (dispatch) => {
    axios
      .post(apiURL2 + `/sale/list-quick-retail-sale`, JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        if (res.data && res.data.status === "200") {
          dispatch(setQuickRetailSales(res.data.data));
        }
        if (res.data && res.data.status === "422") {
          console.log("Quick Retail Sales not found.");
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          toast.error(error.response.data.errors);
        }
      });
  };
};

/*
 * Add Quick Retail Sales
 */
export const postQuickRetailSales = (data, requestData) => {
  return (dispatch) => {
    axios
      .post(apiURL2 + `/sale/add-quick-retail-sale`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        if (res.data && res.data.status === "200") {
          toast.success("New Quick Retail Sales Added.");
          dispatch(fetchQuickRetailSales(requestData));
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          toast.error(error.response.data.errors);
        } else {
          console.log("Unable to add quick retail sales.");
        }
      });
  };
};
/*
 * Search Communication History
 */
export const fetchSearchCommunicationHistory = (data, setItems, setNextId) => {
  return async (dispatch) => {
    await axios
      .post(
        apiURL2 + `/client/search-communication-history/`,
          data,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        console.log(res.data)
        if (res.data && res.data.status === "200") {
          dispatch(setSearchCommunicationHistory(res.data.data.data));
          if (res.data.data && res.data.data.data.length === 0) {
            toast.info("No records found.");
          } else {
            setItems(res.data.data.totalItems);
            setNextId(res.data.nextId);
          }
        } else {
          toast.error(res.data.message);
          dispatch(resetSearchCommunicationHistory());
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          console.log(error.response.data.errors);
          dispatch(resetSearchCommunicationHistory());
        }
      });
  };
};

export const fetchSearchSupplierCommunicationHistory = (data, setItems, setNextId) => {
  return async (dispatch) => {
    await axios
        .post(
            apiURL2 + `/supplier/search-communication-history/`,
            data,
            { headers: { "Content-Type": "application/json" } }
        )
        .then((res) => {
          console.log(res.data)
          if (res.data && res.data.status === "200") {
            dispatch(setSearchSupplierCommunicationHistory(res.data.data.data));
            if (res.data.data && res.data.data.data.length === 0) {
              toast.info("No records found.");
            } else {
              setItems(res.data.data.totalItems);
              setNextId(res.data.nextId);
            }
          } else {
            toast.error(res.data.message);
            dispatch(resetSearchSupplierCommunicationHistory());
          }
        })
        .catch((error) => {
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.errors
          ) {
            console.log(error.response.data.errors);
            dispatch(resetSearchSupplierCommunicationHistory());
          }
        });
  };
};

/*
 * Search Communication History
 */
export const fetchSearchCommunicationHistorySupplier = (data) => {
  return async (dispatch) => {
    // axios.defaults.headers.common["token"] = getToken();
    await axios
      .post(
        apiURL2 + `/client/search-communication-history/`,
        JSON.stringify(data),
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        if (res.data.status === "401") {
          localStorage.clear();
          window.location.replace(
            `${apiLoginURL}?redirect=ERP&error=${errorAuth}`
          );
        }
        if (res.data && res.data.status === "200") {
          dispatch(setSearchCommunicationHistory(res.data.data));
          if (res.data.data && res.data.data.length === 0) {
            toast.info("No records found.");
          }
        } else {
          toast.error(res.data && res.data.error);
          dispatch(resetSearchCommunicationHistory());
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          console.log(error.response.data.errors);
          dispatch(resetSearchCommunicationHistory());
        }
      });
  };
};

/*
 * User Signin and Save Token to sessionStorage.
 */
export const sendMail =  (html, data, emailSettings, recipientEmails, documentType) => { //documentType: sale , clientPayment for now
  return async () => {
    let requestDataForm = new FormData();
    requestDataForm.append("from", emailSettings?.fromEmail ? emailSettings?.fromEmail.trim() : "noreply@primer.gr")
    requestDataForm.append("to", [...new Set(recipientEmails)].join())
    requestDataForm.append("company", data?.company?.trim())
    requestDataForm.append("documentType", documentType)
    requestDataForm.append("documentId", data?._id ? data?._id?.trim() : "")
    const doc = new jsPDF();
    doc.addFileToVFS("arial.ttf", arial);
    doc.addFont("arial.ttf", "Arial", "normal");
    doc.setFont("Arial");
    doc.setFontSize(8);
    await doc.html(html, {
      callback: function (doc) {
        // Save the PDF
        requestDataForm.append("file", (doc.output('datauristring').split('base64,')[1]))
      },
      html2canvas: {scale: 0.215},
      x: 20,
    });
    axios
        .post(`${apiURL2}/settings/send-email`, (requestDataForm))
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === '200' && res.data.message === "send email") {
              toast.success("Email sent successfully to recipients!");
            } else {
              toast.error(res.data.message)
            }
          }
        })
        .catch((error) => {
          console.log(error)
          toast.error(error);
        });
  }
}
/*
 * Fetch Primer My Data Data
 */
export const fetchPrimerMyDataData = (data) => {
  return (dispatch) => {
    try {
      axios.post(apiURL2 + `/settings/get-primer-mydata-data/`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } })
        .then(res => {
          if (res.data && res.data.status === '200') {
            if (res.data.data.length > 0) {
              dispatch(setPrimerMyDataData(res.data.data["0"]));
            }
          } else if(res.data && res.data.status === '422'){
              let pMDDSettings = SETTINGS.primerMyDataDataInitialValue;
              pMDDSettings = {...pMDDSettings, vat: JSON.stringify(vatCategoryData)}
              pMDDSettings = {...pMDDSettings, withHoldTaxes: withHoldingTaxesData}
              pMDDSettings = {...pMDDSettings, otherTaxes: otherTaxesData}
              pMDDSettings = {...pMDDSettings, fees: feesData}
              pMDDSettings = {...pMDDSettings, otherData: otherDataInitialValues}
              pMDDSettings = {...pMDDSettings, stamp: taxStampCategoryData}
              pMDDSettings = {...pMDDSettings, highlight: JSON.stringify(highlightData)}
              pMDDSettings = {...pMDDSettings, paymentWays: JSON.stringify(paymentTypesData)}
              pMDDSettings = {...pMDDSettings, measurementUnits: JSON.stringify(measurementUnitsData)}
              pMDDSettings = {...pMDDSettings, salePurpose: JSON.stringify(salesPurposeData)}
              let pMDDRequestData = {
                  year: data?.year,
                  company:data?.company,
              }
              pMDDSettings = {...pMDDSettings, ...pMDDRequestData}
              dispatch(setPrimerMyDataData(pMDDSettings));
              dispatch(putPrimerMyDataData(pMDDSettings));
          }
        }).catch((error) => {
          console.log(error);
      })
    } catch (error) {
      console.log(error?.response?.data?.errors);
    }
  }
}

export const fetchEmailSmtp = (data) => {
  return (dispatch) => {
    try {
      axios.post(apiURL2 + `/settings/get-email-smtp/`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } })
          .then(res => {
            if (res.data && res.data.status === '200') {
              if (res.data.data.length > 0) {
                dispatch(setEmailSmtp(res.data.data["0"]));
              }
            } else if(res.data && res.data.status === '422'){
              let smtpSettings = SETTINGS.companyEmailSmtp;
              let pMDDRequestData = {
                company:data?.company,
              }
              smtpSettings = {...smtpSettings, ...pMDDRequestData}
              dispatch(setEmailSmtp(smtpSettings));
              dispatch(putCompanyEmailSmtp(smtpSettings, pMDDRequestData));
            }
          }).catch((error) => {
            console.log(error);
      });
    } catch (error) {
      console.log(error?.response?.data?.errors);
    }
  };
};

/*
 * Update Primer MyData Data
 */
export const putPrimerMyDataData = (data) => {
  return (dispatch) => {
    try {
      axios.put(`${apiURL2}/settings/update-primer-mydata-data`, JSON.stringify(data), {
        headers: { "Content-Type": "application/json" }
      }).then((res) => {
        if (res.data && res.data.status === "200") {
          toast.success(res.data.message);
          dispatch(setSavePrimerMyDataData(false))
        } else {
          toast.error(res.data.errors);
        }
      });
    } catch (error) {
      console.log(error?.response?.data?.errors);
    }
  }
}

export const putCompanyEmailSmtp = (data) => {
  return (dispatch) => {
    try {
      axios.put(`${apiURL2}/settings/update-email-smtp`, JSON.stringify(data), {
        headers: { "Content-Type": "application/json" }
      }).then((res) => {
        if (res.data && res.data.status === "200") {
          toast.success(res.data.message);
          dispatch(setSaveEmailSmtp(false))
        } else {
          toast.error(res.data.message);
        }
      })
    } catch (error) {
      console.log(error?.response?.data?.errors);
    }
  }
}

export const fetchCompanyInstallations = (data) => {
  return (dispatch) => {
    try {
      let requestInstallations = {
        companyId: data,
      };
      axios
          .post(apiURL2 + `/company/installations`, requestInstallations)
          .then((res) => {
            if(res.data.status === "200") {
              if(res.data.data.length > 0 ) {
                let data = res.data.data;
                data.forEach((install, i) => {
                  install.no = ++i;
                });
                dispatch(setCompanyInstallations(data));
              }
            }
          })
          .catch((err) => {
            if (err) {
              toast.error("Service is unavailable for the moment. Please try again later.");
            }
          });
    } catch (error) {
      if (error) {
        toast.error("Service is unavailable for the moment. Please try again later.");
      }
    }
  };
}

export const fetchCompanyDetails = (data) => {
  return (dispatch) => {
    try {
      let requestData = {
        _id: data,
      };
      axios
          .post(apiURL2 + `/company/details`, requestData)
          .then((res) => {
            if(res.data.status === "200") {
                let data = res.data.data;
                dispatch(setCompanyDetails(data));
            }
          })
          .catch((err) => {
            if (err) {
              toast.error("Service is unavailable for the moment. Please try again later.");
            }
          });
    } catch (error) {
      if (error) {
        toast.error("Service is unavailable for the moment. Please try again later.");
      }
    }
  };
}

export const postUserLanguage = (data) => {
  return () => {
    try {
      let requestData = {
        userId: data.userId,
        language: data.language
      };
      axios
          .post(apiURL2 + `/user/language`, requestData)
          .then((res) => {
            if (res.data.status === "200") {
              localStorage.setItem("user", JSON.stringify(res.data.data));
                toast.success(res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
              toast.error("Service is unavailable for the moment. Please try again later.");
            }
          });
    } catch (error) {
      if (error) {
        toast.error("Service is unavailable for the moment. Please try again later.");
      }
    }
  }
}

export const activateMyData = (data, setLoader) => {
  return (dispatch) => {
    try {
      const permissions = JSON.parse(localStorage.getItem("user")).permissions;
      const isEpay = permissions?.find((el) => el.permissionName === "custom-epay" || el.permissionName === "custom-peiraios" || el.permissionName === "custom-daras");
      const isCardlink = permissions?.find((el) => el.permissionName === "custom-cardlink");
      const isEmployee = permissions?.find((el) => el.permissionName === "custom-employee");

      if(!isEpay && !isCardlink && !isEmployee) {
        let requestData = {
          companyId: data.companyId,
          userId: data.userId,
          subscriptionKey: data.subscriptionKey,
          environment: data.environment,
          myDataMode: data.myDataMode
        }
        if (data.accountantMyDataApiUserId) requestData.accountantMyDataApiUserId = data.accountantMyDataApiUserId;
        if (data.accountantMyDataApiSubscriptionKey) requestData.accountantMyDataApiSubscriptionKey = data.accountantMyDataApiSubscriptionKey;
        axios
            .post(apiURL2 + `/company/activateMyData`, requestData, { headers: { 'Content-Type': 'application/json' } })
            .then((res) => {
              if (res.data.status === "200") {
                toast.success(res.data.message);
                dispatch(fetchPrimerMyDataCredentials(data.companyId));
              } else {
                toast.error(res.data.message);
              }
              if (typeof setLoader === 'function') {
                setLoader(false);
              }
            })
            .catch((err) => {
              if (err) {
                toast.error("Service is unavailable for the moment. Please try again later.");
                if (typeof setLoader === 'function') {
                  setLoader(false);
                }
              }
            });
      }
    } catch (error) {
        toast.error("Service is unavailable for the moment. Please try again later.");
        if (typeof setLoader === 'function') {
          setLoader(false);
        }
    }
  }
}

export const activatePrimerMyData = (data, setLoader, refetch) => {
  return (dispatch) => {
    try {
      const permissions = JSON.parse(localStorage.getItem("user")).permissions;
      const isEpay = permissions?.find((el) => el.permissionName === "custom-epay" || el.permissionName === "custom-peiraios" || el.permissionName === "custom-daras");
      const isCardlink = permissions?.find((el) => el.permissionName === "custom-cardlink");
      const isEmployee = permissions?.find((el) => el.permissionName === "custom-employee");

      if(!isEpay && !isCardlink && !isEmployee) {
        axios.post(apiURL2 + `/company/activatePrimerMyData`, data, {
          headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
          console.log(res.data);
          if (res.data.status === "200") {
            if(refetch) dispatch(fetchPrimerMyDataCredentials(data.companyId, true));
            toast.success(res.data.message);
            if (typeof setLoader === 'function') {
              setLoader(false);
            }
          } else {
            toast.error(res.data.message?.Error ? res.data.message.Error : res.data.message);
            if (typeof setLoader === 'function') {
              setLoader(false);
            }
          }
        }).catch((err) => {
          if (err) {
            toast.error("Service is unavailable for the moment. Please try again later.");
            if (typeof setLoader === 'function') {
              setLoader(false);
            }
          }
        })
      }
    } catch (error) {
      if (error) {
        toast.error("Service is unavailable for the moment. Please try again later.");
        if (typeof setLoader === 'function') {
          setLoader(false);
        }
      }
    }
  }
}

export const fetchPrimerMyDataCredentials = (data, dontActivate, setEnablePrimerMyDataProviderSettings) => {
  return (dispatch) => {
    try {
      let requestData = {
        companyId: data,
      };
      axios.post(apiURL2 + `/company/get-primer-mydata-credentials/`, requestData, { headers: { 'Content-Type': 'application/json' } })
          .then(res => {
            if (res.data && res.data.status === "200") {
              let initialSettings = SETTINGS.primerApiInitialData;
              initialSettings = {...initialSettings, ...res.data.data}
                dispatch(setPrimerApiData(initialSettings));
              if(initialSettings?.username && initialSettings?.password && initialSettings?.serialNumber){
                if (typeof setEnablePrimerMyDataProviderSettings === 'function') {
                  setEnablePrimerMyDataProviderSettings(true);
                }
                if(!dontActivate) {
                  dispatch(activatePrimerMyData({
                    companyId: data,
                    username: initialSettings.username,
                    password: initialSettings.password,
                    serialNumber: initialSettings.serialNumber,
                    environment: initialSettings.primerEnvironment,
                  }));
                }
              }
            } else if(res.data && res.data.status === "422"){
              toast.error(res.data.message);
            }
          });
    } catch (error) {
      console.log(error?.response?.data?.errors);
    }
  };
};

export const sendInvoiceToMyData = (data, handleDpActions = null, setLoader) => {
  return (dispatch) => {
    try {
      setLoader(true);
      let requestJsonData = {
        saleId: data.saleId,
        companyId: data.companyId,
      }
      axios.post(apiURL2 + `/sale/send-invoice-mydata/`, requestJsonData, { headers: { 'Content-Type': 'application/json' } })
          .then((res) => {
            if (res.data && res.data.status === "200") {
              if(res.data.data?.myDataError !== "" &&  res.data.data?.myDataError !== undefined){
                toast.error(res.data.data?.myDataError);
              } else {
                toast.success("Sales sent successfully to MyData.");
              }
              dispatch(fetchAdvancedTypesSales({ company: data.companyId }));
              dispatch(setEditSales(res.data.data));
              // Used in Data Panel index to show print on save
              if (handleDpActions !== null) {
                handleDpActions("printOnSave");
              }
              dispatch(resetNewSales());
              dispatch(setSaveSales(false)); // Set save status false
              dispatch(addToForceRefresh("Sales Search"));
            } else {
              toast.error(res.data.error);
            }
            setLoader(false);
          })
          .catch((error) => {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.errors
            ) {
              toast.error(error.response.data.errors);
            }
            setLoader(false);
          });
    } catch (error) {
      console.log(error);
    }
  }
}

export const sendAccountingRecordToMyData = (data, handleDpActions = null, setLoader, setForceRefreshAccounts) => {
  return (dispatch) => {
    setLoader(true);
    axios.post(apiURL2 + `/accounting/send-accounting-record-to-mydata`, data, {
      headers: {'Content-Type': 'application/json'}
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        if (res.data.data?.myDataError !== "" && res.data.data?.myDataError !== undefined) {
          toast.error(res.data.data?.myDataError);
        } else {
          toast.success("Accounting Record successfully to MyData.");
        }
        dispatch(setEditAccountingRecord(res.data.data));
        setForceRefreshAccounts(true);
      } else {
        toast.error(res.data.message);
      }
      setLoader(false);
    }).catch((error) => {
      console.log(error);
      setLoader(false);
    })
  }
}

export const testSmtpConnection = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/settings/test-smtp-connection/`, data, {
      headers: { 'Content-Type': 'application/json' }
    }).then(res => {
      console.log(res.data)
      if (res.data && res.data.status === "200") {
        toast.success(res.data.message)
      } else if(res.data && res.data.status === "422") {
        toast.error(res.data.message);
        dispatch(setSaveEmailSmtp(false));
      }
    }).catch((err) => {
      console.log(err);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

export const cancelMyDataSale = (data, setModal, setButton) => {
  return (dispatch) => {
    setButton(true);
    try {
      axios.post(apiURL2 + `/sale/cancelMyDataInvoice`, JSON.stringify(data), {
        headers: {"Content-Type": "application/json"},
      })
          .then((res) => {
            console.log(res.data)
            if (res.data && res.data.status === "200") {
              toast.success(res.data.message);
              dispatch(fetchAdvancedTypesSales({ company: data.company }));
              dispatch(setEditSales(res.data.data));
              dispatch(resetNewSales());
              dispatch(setSaveSales(false)); // Set save status false
            } else {
              toast.error(res.data.message);
            }
            setModal(false);
            setButton(false);
          })
          .catch((error) => {
            if (error && error.response && error.response.data && error.response.data.errors) {
              toast.error(error.response.data.errors);
            }
            setModal(false);
            setButton(false);
          });
    } catch (error) {
      console.log(error?.response?.data?.errors);
    }
  }
}

export const cancelMyDataBuy = (data, setModal, setButton, TABS_DATA) => {
  return (dispatch) => {
    setButton(true);
    axios.post(apiURL2 + `/buy/cancelMyDataInvoice`, {...data}, {
      headers: {"Content-Type": "application/json"},
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success(res.data.message);
        dispatch(setEditBuys(res.data.data));
        dispatch(setSaveEditBuys(false)); // Set save status false
        if (TABS_DATA) {
          dispatch(removeTab("view-buys"));
          const idx = TABS_DATA.findIndex((item) => "edit-buys" === item);
          if (idx === -1) {
            dispatch(addTab("edit-buys"));
            dispatch(updateActiveMainTab(TABS_DATA.length))
          }
        }
      } else {
        toast.error(res.data.message);
      }
      setModal(false);
      setButton(false);
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.errors) {
        toast.error(error.response.data.errors);
      }
      setModal(false);
      setButton(false);
    })
  }
}

export const cancelMyDataAccountingRecord = (data, setModal, setButton) => {
  return (dispatch) => {
    setButton(true);
    try {
      axios.post(apiURL2 + "/accounting/cancelMyDataInvoice", {...data}, {
        headers: { "Content-Type": "application/json" },
      })
          .then((res) => {
            if(res.data && res.data.status === "200") {
              toast.success(res.data.message);
              dispatch(setEditAccountingRecord(res.data.data));
              dispatch(setSaveEditAccountingRecord(false));
            } else {
              toast.error(res.data.message);
            }
            setModal(false);
            setButton(false);
          })
          .catch((error) => {
            if (error && error.response && error.response.data && error.response.data.errors) {
              toast.error(error.response.data.errors);
            }
            setModal(false);
            setButton(false);
          });
    } catch (error) {
      console.log(error?.response?.data?.errors);
    }
  }
}

export const sendInvoiceToMyDataBuy = (data, handleDpActions = null, setLoader, tabcount = -1) => {
  return (dispatch) => {
    try {
      setLoader(true);
      let requestJsonData = {
        buyId: data.buyId,
        companyId: data.companyId,
      };
      axios.post(apiURL2 + `/buy/send-invoice-mydata/`, requestJsonData, { headers: { 'Content-Type': 'application/json' } })
          .then((res) => {
            if (res.data && res.data.status === "200") {
              if(res.data.data?.myDataError !== "" &&  res.data.data?.myDataError !== undefined){
                toast.error(res.data.data?.myDataError);
              } else {
                toast.success("Buys sent successfully to MyData.");
                if(tabcount !== -1) {
                  dispatch(removeTab("edit-buys"));
                  dispatch(addTab("view-buys"));
                  dispatch(updateActiveMainTab(tabcount));
                }
              }
              dispatch(fetchAdvancedTypesBuys({ company: data.companyId }));
              dispatch(setEditBuys(res.data.data));
              // Used in Data Panel index to show print on save
              if (handleDpActions !== null) {
                handleDpActions("printOnSave");
              }
              dispatch(resetNewBuys());
              dispatch(setSaveBuys(false)); // Set save status false
              dispatch(addToForceRefresh("Buys Search"));
            } else {
              toast.error(res.data.error);
            }
            setLoader(false);
          })
          .catch((error) => {
            setLoader(false);
            if (error && error.response && error.response.data && error.response.data.errors) {
              toast.error(error.response.data.errors);
            }
          });
    } catch (error) {
      console.log(error);
    }
  }
}

export const postEmployee = (data, requestData, setLoader) => {
  return (dispatch) => {
    setLoader(true);
    axios.post(apiURL2 + `/accounting/add-employee`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("New Employee Added.");
        dispatch(resetNewEmployee()); // Reset client form
        dispatch(addToForceRefresh("Employees Search"));
        dispatch(setSendEmployeeNewPasswordOnSave(false));
        dispatch(setSendEmployeeQROnSave(false));
      } else {
        toast.error(res.data.message);
      }
      setLoader(false);
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.errors) {
        toast.error(error.response.data.errors);
      }
      setLoader(false);
    })
  }
}

export const fetchEmployeeSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/accounting/employee-search`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data.status === "200") {
        dispatch(setSearchEmployees(res.data.data.data));
        dispatch(setperPageES(res.data.data.perPage));
        dispatch(setTotalItemsES(res.data.data.totalItems));
        if (res.data.data && res.data.data.data.length === 0) toast.info("No records found.");
      } else {
        dispatch(setSearchEmployees([]));
        toast.error(res.data.message);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

export const putEmployee = (data, requestData, action = "update", setLoader) => {
  return (dispatch) => {
    if (typeof setLoader === 'function') setLoader(true);
    data.id = data._id;
    axios.post(apiURL2 + `/accounting/edit-employee`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("Employee updated.");
        dispatch(addToForceRefresh("Employees Search"));
        dispatch(setSendEmployeeNewPasswordOnSave(false));
        dispatch(setSendEmployeeQROnSave(false));
      } else {
        toast.error(res.data.message);
      }
      if (typeof setLoader === 'function') setLoader(false);
    }).catch((error) => {
      if (typeof setLoader === 'function') setLoader(false);
      toast.error(error + " Update Employee");
    })
  }
}

export const postAccountingAccount = (data, requestData, setLoader) => {
  return (dispatch) => {
    setLoader(true);
    axios.post(apiURL2 + `/accounting/add-accounting-account`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("New Accounting Account Added.");
        dispatch(resetNewAccountingAccount()); // Reset client form
        dispatch(addToForceRefresh("Accounting Account Search"));
      } else {
        toast.error(res.data.message);
      }
      setLoader(false);
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.errors) {
        toast.error(error.response.data.errors);
      }
      setLoader(false);
    })
  }
}

export const postAccountingRecord = (data, requestData, setLoader, action) => {
  return (dispatch) => {
    setLoader(true);
    axios.post(apiURL2 + `/accounting/add-accounting-record`, data, {
      headers: {"Content-Type": "application/json"},
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("New Accounting Record Added.");
        if(res.data.data?.myDataError !== "" &&  res.data.data?.myDataError !== undefined){
          toast.error(res.data.data?.myDataError);
        }
        if (action === "save") dispatch(resetNewAccountingRecord()); // Reset accounting record form
        if (action === "saveandrepeat") dispatch(softResetAccountingRecord());

        dispatch(addToForceRefresh("Accounting Record Search"));
      } else {
        if(res.data?.myDataError === true) {
          dispatch(resetNewAccountingRecord()); // Reset accounting record form
        }
        toast.error(res.data.message);
      }
      setLoader(false);
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.errors) {
        toast.error(error.response.data.errors);
      }
      setLoader(false);
    });
  }
}

export const putAccountingRecord = (data, requestData, action = "update", setLoader) => {
  return (dispatch) => {
    if (typeof setLoader === 'function') setLoader(true);
    data.id = data._id;
    axios.post(apiURL2 + `/accounting/edit-accounting-record`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("Accounting Record updated.");
        dispatch(addToForceRefresh("Accounting Record Search"));
      } else {
        toast.error(res.data.message);
      }
      if (typeof setLoader === 'function') setLoader(false);
    }).catch((error) => {
      if (typeof setLoader === 'function') setLoader(false);
      toast.error(error + " Update Accounting Record");
    })
  }
}

export const fetchAccountingRecordSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/accounting/accounting-record-search`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        if(res.data.data.data.length > 0) {
          for (let item of res.data.data.data) {
            item.preNumberAndNumber = item.preNumber.replace("noprefix", "") + " " + item.number;
          }
        }
        dispatch(setSearchAccountingRecords(res.data.data.data));
        dispatch(setperPageAR(res.data.data.perPage));
        dispatch(setTotalItemsAR(res.data.data.totalItems));
        if (res.data.data && res.data.data.data.length === 0) toast.info("No records found.");
      } else {
        dispatch(setSearchAccountingRecords([]));
        toast.error(res.data && res.data.message);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

export const fetchAccountingAccountSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/accounting/accounting-account-search`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data.status === "200") {
        dispatch(setSearchAccountingAccounts(res.data.data.data));
        dispatch(setperPageAAS(res.data.data.perPage));
        dispatch(setTotalItemsAAS(res.data.data.totalItems));
        if (res.data.data && res.data.data.data.length === 0) toast.info("No records found.");
      } else {
        dispatch(setSearchAccountingAccounts([]));
        toast.error(res.data && res.data.message);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

export const putAccountingAccount = (data, requestData, action = "update", setLoader) => {
  return (dispatch) => {
    if (typeof setLoader === 'function') setLoader(true);
    data.id = data._id;
    axios.post(apiURL2 + `/accounting/edit-accounting-account`, data, {
      headers: { "Content-Type": "application/json" }
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        toast.success("Accounting Account updated.");
        dispatch(addToForceRefresh("Accounting Account Search"));
      } else {
        toast.error(res.data.message);
      }
      if (typeof setLoader === 'function') setLoader(false);
    }).catch((error) => {
      if (typeof setLoader === 'function') setLoader(false);
      toast.error(error + " Update Accounting Account");
    })
  }
}

export const getSubscriptions = (reqData) => {
  return (dispatch) => {
    axios.get(apiURL2 + `/settings/get-subscriptions`, {
      headers: { "Content-Type": "application/json" },
      params: reqData
    }).then((res) => {
      if (res.data.status === "200") {
        dispatch(setSubscriptions(res.data.data));
      }
    }).catch((err) => {
      console.log(err);
    })
  }
}
export const deleteSubscription = (reqData) => {
  return (dispatch) => {
    axios.get(apiURL2 + `/settings/delete-subscription`, {
      headers: { "Content-Type": "application/json" },
      params: reqData
    }).then((res) => {
      if (res.data.status === "200") {
        dispatch(getSubscriptions({company: reqData.company}));
      }
    }).catch((err) => {
      console.log(err);
    })
  }
}
export const endTelephoneSupport = (company, client, sub, supportTime) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/settings/end-telephone-support`, {company: company, client: client, sub: sub, supportTime: String(supportTime)}, {
      headers: { "Content-Type" : "application/json" },
    }).then((res) => {
      if(res.data.status === "200") {
        toast.success("Η υποστήριξη έληξε και η χρονοχρέωση αποθηκεύτηκε.");
      } else if (res.data.status === "400") {
        toast.error(res.data.message);
      }
    }).catch((err) => {
      console.log(err);
    })
  }
}

export const fetchAccountingPaymentTypes = (company) => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL2 + "/accounting/get-payment-types", {
      headers: { "Content-Type": "application/json" },
      params: {
        company: company.id
      }
    }).then((response) => {
      if(response.data.status === "200") {
        dispatch(setAccountingPaymentTypes(response.data.data));
      } else {
        toast.error(response.data.message);
      }
    }).catch((err) => {
      console.log(err);
    })
  }
}

export const fetchCashSettings = (companyID) => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL2 + "/cash/fetch-cash-settings", {
      headers: { "Content-Type": "application/json" },
      params: {
        company: companyID
      }
    }).then((res) => {
      if (res.data?.status === "200" && res.data?.data) {
        dispatch(setCashSettings(res.data.data));
      }
    }).catch((err) => {
      console.log(err);
    })
  }
}

export const postCashSettings = (postData) => {
  return (dispatch) => {
    axios.post(process.env.REACT_APP_API_URL2 + "/cash/post-cash-settings", postData, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data.status === "200") {
        toast.success(res.data.message);
        dispatch(fetchCashSettings(postData.company));
      } else {
        toast.error(res.data.message);
      }
    }).catch((err) => {
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
      console.log(err);
    })
  }
}

export const fetchPayrollSettings = (company) => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL2 + "/payroll/fetch-settings", {
      headers: { "Content-Type": "application/json" },
      params: {
        company: company
      }
    }).then((res) => {
      if (res.data.status === "200") {
        if (res.data.payrollSettings) {
          dispatch(setPayrollSettings(res.data.payrollSettings));
        }
        if (res.data.hasErgani) {
          dispatch(setCompanyHasErgani(true));
        }
      }
    }).catch((err) => {
      console.log(err);
    })
  }
}

export const postWorkMovement = (data, setLoadingSave) => {
  return (dispatch) => {
    if (typeof setLoadingSave === "function") setLoadingSave(true);
    axios.post(`${process.env.REACT_APP_API_URL2}/payroll/post-work-movement`, {
      ...data
    }, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (typeof setLoadingSave === "function") setLoadingSave(false);
      if (res.data.status === "200") {
        toast.success("Η κίνηση εργαζομένου έγινε επιτυχώς.");
        dispatch(resetNewWorkMovement());
      } else {
        toast.error(res.data.message);
      }
    }).catch((error) => {
      if (typeof setLoadingSave === "function") setLoadingSave(false);
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

export const fetchWorkMovementsSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/payroll/work-movements-search`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data.status === "200") {
        dispatch(setSearchWorkMovements(res.data.data.data));
        dispatch(setSearchWorkMovementsPerPage(res.data.data.perPage));
        dispatch(setSearchWorkMovementsTotalItems(res.data.data.totalItems));
        if (res.data.data && res.data.data.data.length === 0) toast.info("No records found.");
      } else {
        dispatch(setSearchWorkMovements([]));
        toast.error(res.data && res.data.message);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

export const postWorkPermit = (data, setLoader) => {
  setLoader(true);
  return (dispatch) => {
    axios.post(apiURL2 + "/payroll/post-work-permit", data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setLoader(false);
      if (res.data.status === "200") {
        toast.success("Οι άδειες αποθηκεύτηκαν επιτυχώς.");
        dispatch(resetNewWorkPermit());
      } else {
        dispatch(setSaveNewWorkPermit(true));
        toast.error(res.data.message, {autoClose: 7000});
      }
    }).catch((error) => {
      dispatch(setSaveNewWorkPermit(true));
      setLoader(false);
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

export const fetchWorkPermitsSearch = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/payroll/work-permits-search`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data.status === "200") {
        dispatch(setSearchWorkPermits(res.data.data.data));
        dispatch(setSearchWorkPermitsPerPage(res.data.data.perPage));
        dispatch(setSearchWorkPermitsTotalItems(res.data.data.totalItems));
        if (res.data.data && res.data.data.data.length === 0) toast.info("No records found.");
      } else {
        dispatch(setSearchWorkPermits([]));
        toast.error(res.data && res.data.message);
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}

export const fetchCompanyStatus = (data) => {
  return (dispatch) => {
    axios.post(apiURL2 + `/emron/company-status`, data, {
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data.status === "200") {
        dispatch(setEmronCompanyInvalid(!res.data.valid));
        dispatch(setCanBypassInvalidCompany(res.data.canBypass));
        dispatch(setEmronCompanyInvalidDays(res.data.lastOrderDateDiff));
        dispatch(setIsEmronClient(res.data.isEmronClient));
        if (res.data.clientData) {
          if (Object.keys(res.data.clientData).length > 0) {
            dispatch(setEmronClientData(res.data.clientData));
          } else {
            dispatch(setEmronClientData(null));
          }
        } else {
          dispatch(setEmronClientData(null));
        }
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
    })
  }
}
