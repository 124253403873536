// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.et-table-ui,
.price-list-table {
  .table-responsive {
    overflow: visible;
  }
  .advanced-combobox {
    .popupOptions {
      min-width: 400px;
     /* overflow-y: scroll; */
      th, td {
        padding: 10px !important;
        font-size: 12px;
        font-weight: normal;
      }

      th {
        font-weight: 600;
      }
    }
  }
  th,
  td  {
    .form-control {
      margin: 0 !important;
    }

    &.client {
      width: 200px;
    }
  }

  .client .mb-3 {
    margin: 0 !important;
  }
  .css-1vp9nu5-control,
  .css-5duvv3-control  {
    background: transparent !important;
    border-radius: 0 !important;
    box-shadow: unset !important;
    border: unset !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/_components/layouts/data-panel/ProductsPriceList/index.css"],"names":[],"mappings":"AAAA;;EAEE;IACE,iBAAiB;EACnB;EACA;IACE;MACE,gBAAgB;KACjB,wBAAwB;MACvB;QACE,wBAAwB;QACxB,eAAe;QACf,mBAAmB;MACrB;;MAEA;QACE,gBAAgB;MAClB;IACF;EACF;EACA;;IAEE;MACE,oBAAoB;IACtB;;IAEA;MACE,YAAY;IACd;EACF;;EAEA;IACE,oBAAoB;EACtB;EACA;;IAEE,kCAAkC;IAClC,2BAA2B;IAC3B,4BAA4B;IAC5B,wBAAwB;EAC1B;AACF","sourcesContent":[".et-table-ui,\r\n.price-list-table {\r\n  .table-responsive {\r\n    overflow: visible;\r\n  }\r\n  .advanced-combobox {\r\n    .popupOptions {\r\n      min-width: 400px;\r\n     /* overflow-y: scroll; */\r\n      th, td {\r\n        padding: 10px !important;\r\n        font-size: 12px;\r\n        font-weight: normal;\r\n      }\r\n\r\n      th {\r\n        font-weight: 600;\r\n      }\r\n    }\r\n  }\r\n  th,\r\n  td  {\r\n    .form-control {\r\n      margin: 0 !important;\r\n    }\r\n\r\n    &.client {\r\n      width: 200px;\r\n    }\r\n  }\r\n\r\n  .client .mb-3 {\r\n    margin: 0 !important;\r\n  }\r\n  .css-1vp9nu5-control,\r\n  .css-5duvv3-control  {\r\n    background: transparent !important;\r\n    border-radius: 0 !important;\r\n    box-shadow: unset !important;\r\n    border: unset !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
