import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
    resetNewReferral,
    setNewPrescription,
    setNewReferral
} from "../../../../../_reducers/DataPanel/Prescription/prescriptionSlice";
import {
    asArray,
    getExamTypesOptions,
    getZeroReferralParticipationReasonsOptions, referralOverrides,
    referralTypeOptions
} from "../constants";
import Dropdown from "../../../../primer/Dropdown";
import {Badge, Col, Row, Spinner} from "react-bootstrap";
import TextArea from "../../../../common/TextArea";
import axios from "axios";
import ICD10Modal from "./ICD10Modal";
import ExamsModal from "./examsModal";
import Input from "../../../../common/Input";
import {toast} from "react-toastify";
import {isEmpty} from "../../../../../_helpers/commonFunctions";
import DateBox from "../../../../primer/DateBox";
import moment from "moment";

const NewReferralModal = ({show, setShow, successCallback}) => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const newReferral = useSelector((state) => state.PRESCRIPTION.newReferral);
    const activeVisit = useSelector((state) => state.PRESCRIPTION.activeVisit);

    const [icd10modal, setICD10Modal] = useState(false);
    const [referralLimits, setReferralLimits] = useState([]);
    const [fetchLimit, setFetchLimit] = useState(false);

    const [loading, setLoading] = useState(false);
    const [lastError, setLastError] = useState("");
    const [examsModal, setExamsModal] = useState(false);
    const [showOverrides, setShowOverrides] = useState(false);

    const [autoICD10, setAutoICD10] = useState("");
    const [autoReference, setAutoReference] = useState("");

    useEffect(() => {
        if (!show) resetModal();
    }, [show]);

    useEffect(() => {
        if (fetchLimit) {
            setFetchLimit(false);
            fetchReferralLimit();
        }
    }, [fetchLimit])

    const resetModal = () => {
        dispatch(resetNewReferral());
        setShowOverrides(false);
        setReferralLimits([]);
        setLastError("");
        setAutoICD10("");
        setAutoReference("");
    }

    const handleSaveNewReferral = () => {
        // Validation
        if (isEmpty(newReferral.cause)) {
            return toast.error("Παρακαλώ συμπληρώστε την αιτιολογία του παραπεμπτικού.");
        } else if (newReferral.diagnoses.length === 0) {
            return toast.error("Παρακαλώ επιλέξτε τουλάχιστον μία διάγνωση.");
        } else if (newReferral.exams.length === 0) {
            return toast.error("Παρακαλώ επιλέξτε τουλάχιστον μία εξέταση.");
        } else if (newReferral.exams.some((el) => isEmpty(el.erp_reference) || isEmpty(el.erp_icd10))) {
            return toast.error("Παρακαλώ συμπληρώστε τις στήλες Διάγνωση/Λόγος Παραπομπής σε όλες τις εξετάσεις.");
        } else if (newReferral.overrides.some((el) => isEmpty(el.erp_cause))) {
            return toast.error("Παρακαλώ συμπληρώστε όλες τις αιτιολογίες στις εξαιρέσεις/υπερβάσεις παραπεμπτικού.");
        } else if (newReferral.overrides.some((el) => String(el.erp_checked) !== "true")) {
            return toast.error("Παρακαλώ αποδεχτείτε όλες τις εξαιρέσεις/υπερβάσεις παραπεμπτικού.");
        }

        // Post to API
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/prescription/new-referral`, {...newReferral, company: company.id}, {
            headers: { 'Content-Type': 'application/json' },
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                toast.success("Επιτυχής καταχώρηση παραπεμπτικού.");
                setShow(false);
                dispatch(resetNewReferral());
                setLastError("");
                setShowOverrides(false);
                successCallback();
            } else {
                setLastError(res.data.message);
                if (res.data?.specialCode === "1") {
                    setShowOverrides(true);
                    const extraDetails = asArray(res.data.extraDetails);
                    let prepOverrides = [];
                    for (let ed of extraDetails) {
                        prepOverrides.push({
                            ...ed,
                            parentId: referralOverrides.find((el) => el.id === ed.overrideTypeId)?.parentId || "",
                            erp_cause: "",
                            erp_checked: false,
                            erp_override_name: referralOverrides.find((el) => el.id === ed.overrideTypeId)?.description,
                        })
                    }
                    dispatch(setNewReferral({...newReferral, overrides: prepOverrides}));
                    toast.error("Παρακαλώ συμπληρώστε τις εξαιρέσεις/υπερβάσεις παραπεμπτικού που χρειάζονται.");
                } else {
                    toast.error(res.data.message);
                }
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleOnChange = (e, type, name) => {
        const prepChange = {};
        if (type === "input") {
            prepChange[name] = e.target.value;
        } else if (type === "dd") {
            prepChange[name] = e.value;
            if (name === "zeroParticipationReason") prepChange["zeroParticipationReasonNeedsDecision"] = e.needsDecision === "true";
            if (name === "examType") setFetchLimit(true);
        } else if (type === "db") {
            if (moment(e).isValid()) {
                prepChange[name] = moment(e).format("DD/MM/YYYY");
            } else {
                prepChange[name] = "";
            }
        }
        dispatch(setNewReferral({...newReferral, ...prepChange}));
    }

    const fetchReferralLimit = () => {
        axios.get(`${process.env.REACT_APP_API_URL2}/prescription/referral-limit`, {
            headers: { "Content-Type": "application/json" },
            params: {
                company: company.id,
                examinationGroupId: newReferral.examType,
                amka: activeVisit.amka,
            }
        }).then((res) => {
            if (res.data.status === "200") setReferralLimits(res.data.data);
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleICD10Check = (e, icd10) => {
        if (e.target.checked) {
            dispatch(setNewReferral({...newReferral, diagnoses: [...newReferral.diagnoses, icd10]}));
        } else {
            dispatch(setNewReferral({...newReferral, diagnoses: newReferral.diagnoses.filter((el) => el.id !== icd10.id)}));
        }
    }

    const handleExamAdd = (exam, amount) => {
        let arr = [];
        for (let i = 1; i <= amount; i++) {
            const prepExam = structuredClone(exam);
            if (autoICD10) prepExam.erp_icd10 = autoICD10;
            if (autoReference) prepExam.erp_reference = autoReference;
            arr.push(prepExam);
        }
        dispatch(setNewReferral({...newReferral, exams: [...newReferral.exams, ...arr]}));
    }

    const handleExamRemove = (idx) => {
        const cloneExams = structuredClone(newReferral.exams);
        cloneExams.splice(idx, 1);
        dispatch(setNewReferral({...newReferral, exams: cloneExams}));
    }

    const diagnosesOptions = newReferral.diagnoses.map((o) => {
        return { label: o.code, value: o.id }
    })

    const diagnosesOptionsExtended = [{label: "Κενό ICD10", value: ""}, ...newReferral.diagnoses.map((o) => {
        return { label: o.code, value: o.id }
    })]

    const referenceOptions = [
        {label: "Διάγνωση", value: "1"},
        {label: "Παρακολούθηση", value: "2"},
    ]

    const referenceOptionsExtended = [
        {label: "Κενός λόγος", value: ""},
        {label: "Διάγνωση", value: "1"},
        {label: "Παρακολούθηση", value: "2"},
    ]

    const handleExamChange = (e, type, name, idx) => {
        const cloneExams = structuredClone(newReferral.exams);
        if (type === "input") {
            cloneExams[idx][name] = e.target.value;
        } else if (type === "dd") {
            cloneExams[idx][name] = e.value;
        }
        dispatch(setNewReferral({...newReferral, exams: cloneExams}));
    }

    const handleOverrideChange = (e, type, name, idx) => {
        const cloneOverrides = structuredClone(newReferral.overrides);
        if (type === "input") {
            cloneOverrides[idx][name] = e.target.value;
        } else if (type === "cb") {
            cloneOverrides[idx][name] = e.target.checked;
        }
        dispatch(setNewReferral({...newReferral, overrides: cloneOverrides}));
    }

    const handleApplyAutoToAll = () => {
        const cloneExams = structuredClone(newReferral.exams);
        for (let exam of cloneExams) {
            exam.erp_icd10 = autoICD10;
            exam.erp_reference = autoReference;
        }
        dispatch(setNewReferral({...newReferral, exams: cloneExams}));
    }

    return (
        <React.Fragment>
            {/* New Referral Modal */}
            <Modal show={show} backdrop={"static"} dialogClassName={"modal95PercentWidth"} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Νέο Παραπεμπτικό</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"borderedContainer mb-2"}>
                        <Row>
                            <Col md={3}>
                                <Dropdown
                                    name={"type"}
                                    label={"Τύπος παραπεμπτικού"}
                                    options={referralTypeOptions}
                                    defaultValue={referralTypeOptions.find((el) => el.value === newReferral.type)}
                                    onChange={(e) => handleOnChange(e, "dd", "type")}
                                />
                            </Col>
                            <Col md={3}>
                                <Dropdown
                                    name={"examType"}
                                    label={"Κατηγορία εξέτασης"}
                                    options={getExamTypesOptions()}
                                    defaultValue={getExamTypesOptions().find((el) => el.value === newReferral.examType)}
                                    onChange={(e) => handleOnChange(e, "dd", "examType")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <Dropdown
                                    name={"zeroParticipationReason"}
                                    label={"Περίπτωση Μηδενικής Συμμετοχής"}
                                    options={getZeroReferralParticipationReasonsOptions()}
                                    defaultValue={getZeroReferralParticipationReasonsOptions().find((el) => el.value === newReferral.zeroParticipationReason)}
                                    onChange={(e) => handleOnChange(e, "dd", "zeroParticipationReason")}
                                />
                            </Col>
                            {newReferral.zeroParticipationReasonNeedsDecision && (
                                <React.Fragment>
                                    <Col md={3}>
                                        <DateBox
                                            name={"needsDecisionDate"}
                                            label={"Ημ/νία Απόφασης"}
                                            selected={moment(newReferral.needsDecisionDate, "DD/MM/YYYY").isValid() ? moment(newReferral.needsDecisionDate, "DD/MM/YYYY").toDate() : ""}
                                            allowOtherYears={true}
                                            onChange={(e) => handleOnChange(e, "db", "needsDecisionDate")}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Input
                                            name={"needsDecisionNumber"}
                                            label={"Αριθμός Απόφασης"}
                                            value={newReferral.needsDecisionNumber}
                                            onChange={(e) => handleOnChange(e, "input", "needsDecisionNumber")}
                                        />
                                    </Col>
                                </React.Fragment>
                            )}
                        </Row>
                        <Row>
                            <Col md={6}>
                                <TextArea
                                    name={"cause"}
                                    label={"Αιτιολογία"}
                                    required={true}
                                    rows={3}
                                    limit={2000}
                                    value={newReferral.cause}
                                    onChange={(e) => handleOnChange(e, "input", "cause")}
                                />
                            </Col>
                            <Col md={6}>
                                <TextArea
                                    name={"notes"}
                                    label={"Σημειώσεις"}
                                    rows={3}
                                    limit={200}
                                    value={newReferral.notes}
                                    onChange={(e) => handleOnChange(e, "input", "notes")}
                                />
                            </Col>
                        </Row>
                        {referralLimits.length > 0 && (
                            <Row>
                                <Col md={12}>
                                    <div className={"mb-1"} style={{fontSize: "20px"}}>Όρια κατηγοριών εξετάσεων</div>
                                    <table className={"simpleClassicTable"}>
                                        <thead>
                                        <tr>
                                            <th>Κατηγορία εξετάσεων</th>
                                            <th>Όριο Ειδικότητας</th>
                                            <th>Πλήθος εξετάσεων ασθενή</th>
                                            <th>Μήνυμα</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {referralLimits.map((refLimit, idx) => (
                                            <tr key={`refLimit${idx}`}>
                                                <td>{refLimit.examinationSubgroupDescription}</td>
                                                <td>{refLimit.limit}</td>
                                                <td>{refLimit.actual}</td>
                                                <td>-</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        )}
                    </div>
                    <div className={"borderedContainer mb-2"}>
                        <div style={{display: "flex", textAlign: "center"}}>
                            <div className={"mb-1"} style={{fontSize: "20px"}}>Διαγνώσεις</div>
                            <Button size={"sm"} onClick={() => setICD10Modal(true)} className={"ml-2"}>Εισαγωγή Διαγνώσεων ICD10</Button>
                        </div>
                        {newReferral.diagnoses.length > 0 && (
                            <React.Fragment>
                                <table className={"simpleClassicTable mt-1"}>
                                    <colgroup>
                                        <col span={1} style={{width: "20%"}}></col>
                                        <col span={1} style={{width: "70%"}}></col>
                                        <col span={1} style={{width: "10%"}}></col>
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        <th>Κωδικός</th>
                                        <th>Τίτλος</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {newReferral.diagnoses.map((diag, idx) => (
                                        <tr key={`diagnoses-${idx}`}>
                                            <td>{diag.code}</td>
                                            <td>{diag.title}</td>
                                            <td><Badge bg={"danger"}
                                                       title={"Διαγραφή"}
                                                       style={{cursor: "pointer"}}
                                                       onClick={() => handleICD10Check({target: {checked: false}}, diag)}>
                                                <img title="Διαγραφή" src={"assets/images/callcenter/bin.png"} alt={"Bin"} />
                                            </Badge></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </React.Fragment>
                        )}
                    </div>
                    <div className={"borderedContainer mb-2"}>
                        <Row>
                            <Col md={5}>
                                <div style={{display: "flex"}}>
                                    <div className={"mb-1"} style={{fontSize: "20px"}}>Εξετάσεις</div>
                                    <Button size={"sm"} onClick={() => {
                                        if (isEmpty(newReferral.examType)) return toast.error("Παρακαλώ επιλέξτε πρώτα κατηγορία εξέτασης.");
                                        setExamsModal(true)
                                    }} className={"ml-2"}>Εισαγωγή Εξετάσεων</Button>
                                </div>
                            </Col>
                            <Col md={7}>
                                <div style={{display: "flex", gap: "10px"}}>
                                    <div style={{flex: "1"}}>
                                        <div className={"mt-2"}><h6>Αυτόματη συσχέτιση:</h6></div>
                                    </div>
                                    <div style={{flex: "1"}}>
                                        <Dropdown
                                            className={"m-0"}
                                            options={diagnosesOptionsExtended}
                                            defaultValue={diagnosesOptionsExtended.find((el) => el.value === autoICD10)}
                                            key={Math.random()}
                                            onChange={(e) => setAutoICD10(e.value)}
                                        />
                                    </div>
                                    <div style={{flex: "1"}}>
                                        <Dropdown
                                            className={"m-0"}
                                            options={referenceOptionsExtended}
                                            defaultValue={referenceOptionsExtended.find((el) => el.value === autoReference)}
                                            key={Math.random()}
                                            onChange={(e) => setAutoReference(e.value)}
                                        />
                                    </div>
                                    <div style={{flex: "1"}}>
                                        <Button size={"sm"} style={{width: "100%", height: "100%"}} onClick={() => handleApplyAutoToAll()}>Εφαρμογή σε όλες</Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {newReferral.exams.length > 0 && (
                            <React.Fragment>
                                <table className={"simpleClassicTable mt-1"}>
                                    <colgroup>
                                        <col span={1} style={{width: "40%"}}></col>
                                        <col span={1} style={{width: "15%"}}></col>
                                        <col span={1} style={{width: "15%"}}></col>
                                        <col span={1} style={{width: "25%"}}></col>
                                        <col span={1} style={{width: "5%"}}></col>
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        <th>Εξέταση</th>
                                        <th>Διάγνωση</th>
                                        <th>Λόγος παραπομπής</th>
                                        <th>Σχόλια</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {newReferral.exams.map((exam, idx) => (
                                        <tr key={`examinations-${idx}`}>
                                            <td>{exam.description}</td>
                                            <td>
                                                <Dropdown
                                                    name={"erp_icd10"}
                                                    className={"m-0"}
                                                    options={diagnosesOptions}
                                                    defaultValue={diagnosesOptions.find((el) => el.value === exam.erp_icd10)}
                                                    key={Math.random()}
                                                    onChange={(e) => handleExamChange(e, "dd", "erp_icd10", idx)}
                                                />
                                            </td>
                                            <td>
                                                <Dropdown
                                                    name={"erp_reference"}
                                                    className={"m-0"}
                                                    options={referenceOptions}
                                                    defaultValue={referenceOptions.find((el) => el.value === exam.erp_reference)}
                                                    key={Math.random()}
                                                    onChange={(e) => handleExamChange(e, "dd", "erp_reference", idx)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    name={"erp_notes"}
                                                    unlabbled={true}
                                                    value={exam.erp_notes}
                                                    placeholder={"Σχόλια"}
                                                    autoComplete={"off"}
                                                    onChange={(e) => handleExamChange(e, "input", "erp_notes", idx)}
                                                />
                                            </td>
                                            <td><Badge bg={"danger"}
                                                       title={"Διαγραφή"}
                                                       style={{cursor: "pointer"}}
                                                       onClick={() => handleExamRemove(idx)}>
                                                <img title="Διαγραφή" src={"assets/images/callcenter/bin.png"} alt={"Bin"} />
                                            </Badge></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </React.Fragment>
                        )}
                    </div>
                    {showOverrides && (
                        <div className={"borderedContainer mb-2"}>
                            <div style={{display: "flex"}}>
                                <div className={"mb-1"} style={{fontSize: "20px"}}>Εξαιρέσεις/Υπερβάσεις Παραπεμπτικού</div>
                            </div>
                            {newReferral.overrides.length > 0 && (
                                <React.Fragment>
                                    <table className={"simpleClassicTable mt-1"}>
                                        <colgroup>
                                            <col span={1} style={{width: "5%"}}></col>
                                            <col span={1} style={{width: "10%"}}></col>
                                            <col span={1} style={{width: "15%"}}></col>
                                            <col span={1} style={{width: "15%"}}></col>
                                            <col span={1} style={{width: "30%"}}></col>
                                            <col span={1} style={{width: "25%"}}></col>
                                        </colgroup>
                                        <thead>
                                        <tr>
                                            <th>Αποδοχή υπέρβασης</th>
                                            <th>Διάγνωση</th>
                                            <th>Εξέταση</th>
                                            <th>Εξαίρεση</th>
                                            <th>Περιγραφή</th>
                                            <th>Αιτιολόγηση</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {newReferral.overrides.map((over, idx) => (
                                            <tr key={`overrides-${idx}`}>
                                                <td style={{zoom: "1.3"}}>
                                                    <input name={"erp_checked"} type={"checkbox"} checked={over.erp_checked}
                                                           onChange={(e) => handleOverrideChange(e, "cb", "erp_checked", idx)} />
                                                </td>
                                                <td>{over.icd10Code}</td>
                                                <td>{newReferral.exams.find((el) => el.id === over.examinationId)?.description}</td>
                                                <td>{over.erp_override_name}</td>
                                                <td>{over.violationDetails}</td>
                                                <td>
                                                    <Input
                                                        name={"erp_cause"}
                                                        unlabbled={true}
                                                        value={over.erp_cause}
                                                        placeholder={"Αιτιολογία"}
                                                        autoComplete={"off"}
                                                        onChange={(e) => handleOverrideChange(e, "input", "erp_cause", idx)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </React.Fragment>
                            )}
                        </div>
                    )}
                    {!isEmpty(lastError) && (
                        <Row className={"mt-2"}>
                            <Col md={12} style={{fontSize: "16px", fontWeight: "600"}}>
                                <div className={"text-danger"}>Τελευταίο μήνυμα σφάλματος:</div>
                                <div className={"text-danger"}>{lastError}</div>
                            </Col>
                        </Row>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleSaveNewReferral()} disabled={loading}>
                        Ολοκλήρωση {loading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                    </Button>
                </Modal.Footer>
            </Modal>

            <ICD10Modal
                show={icd10modal}
                setShow={setICD10Modal}
                diagnoses={newReferral.diagnoses}
                handleCheck={(e, item) => handleICD10Check(e, item)}
                backButtonMessage={"Πίσω στο παραπεμπτικό"}
            />

            <ExamsModal
                show={examsModal}
                setShow={setExamsModal}
                examType={newReferral.examType}
                examinations={newReferral.exams}
                handleAdd={(item, amount) => handleExamAdd(item, amount)}
                backButtonMessage={"Πίσω στο παραπεμπτικό"}
            />
        </React.Fragment>
    )
}

export default NewReferralModal;
