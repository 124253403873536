import React from "react";
import { useSelector } from "react-redux";
import { myDataCharOptions } from "../../../_data/mydata/myDataChar";
import TableBodyCell from "./TableBodyCell";
import {
  getICCategoryOptions,
  getICTypeOptions,
} from "../../../_helpers/sharedmydata";
import { numberingModeOptions, preNumberGreek } from "../../../_data/settings/advanced-types-search";
import {
  filterUnusedNumberingModes,
  filterUsedPreNumbers,
  getCurrentUser,
  isEmpty
} from "../../../_helpers/commonFunctions";
import {useTranslation} from "react-i18next";
import * as moment from "moment/moment";

const icCategory = getICCategoryOptions();
const icType = getICTypeOptions();

const TableInsertDataRow = (props) => {
  const [t] = useTranslation('common');
  const { columns, insert, tableName, pRequestData, reqName } = props;
  const PRODUCTS_DATA = useSelector(state => state.PRODUCTS_DATA);
  const CLIENTS_DATA = useSelector(state => state.CLIENTS_DATA);
  const PRODUCTS_CATEGORY = useSelector(state => state.PRODUCTS_CATEGORY);
  const SUPPLIERS_DATA = useSelector(state => state.SUPPLIERS_DATA);
  const newContactPoint = useSelector(state => state.CLIENTS_DATA.newContactPoint);
  const newContactPointSupplier = useSelector(state => state.SUPPLIERS_DATA.newContactPoint);
  const newCategoryData = useSelector(state => state.PRODUCTS_CATEGORY.newCategory);
  const newClientCategoryData = CLIENTS_DATA.newCategory;
  const newSupplierCategoryData = SUPPLIERS_DATA.newCategory;
  const newProductsMyData = useSelector(state => state.PRODUCTS_DATA.newProductsMyData);
  const newProductsCategoryMyData = useSelector(state => state.PRODUCTS_CATEGORY.newProductsCategoryMyData);
  const SETTINGS = useSelector(state => state.SETTINGS);
  const SALES = useSelector(state => state.SALES);
  const SALES_SETTINGS = useSelector(state => state.SALES_SETTINGS);
  const BUYS_SETTINGS = useSelector(state => state.BUYS_SETTINGS);
  const PAYROLL = useSelector((state) => state.PAYROLL);

  const handleNewRowEntry = (e, field, defaultValue, ftype) => {
    let value;
    if (ftype === 'dd' || ftype === 'ac') {
      value = e.value;
    } else if (ftype === 'db') {
      value = e;
    } else if (ftype === 'tp' || ftype === "hour-picker") {
      value = e;
    } else {
      value = e.target.value;
    }
    const cellData =  {
      field: field,
      value: value,
    }
    insert(e, cellData);
  }

  const handleEmptyFieldEntry = (e) => {
    const cellData =  {
      field: 'add',
      value: 'add',
    }
    e.key = 'Enter'
    insert(e, cellData);
  }

  return (
    <>
    {
      columns && columns.map((oldcell, celli) => {
        let cell =  Object.assign({}, oldcell);
        // Dynamic Options
        let dynamicOptions = [];
        let enableDynamicOptions = 'false';
        let data = "";
        let rowData = {};
        let inputPlaceholder = t('insertPlaceholders.insert');
        let disabled = false;
        // For Product Category
        if (tableName === 'Product Category') {
          data = newCategoryData[cell.field];
          // Product Category Field
          if (cell.field === 'title') {
            inputPlaceholder = t('insertPlaceholders.insertCategory');
          }

          // Product Code Mask Field
          if (cell.field === 'maskCode' || cell.field === 'mask') {
            inputPlaceholder = t('insertPlaceholders.insertCodeMask');
          }

          if (cell.field === 'maskCode' && newCategoryData.title === '') {
            disabled = true;
            inputPlaceholder = ``;
          }
        }
        // For Client Category
        if (tableName === 'Client Category') {
          data = newClientCategoryData[cell.field];
          // Client Category Field
          if (cell.field === 'category') {
            inputPlaceholder = t('insertPlaceholders.insertCategory');
          }

          // Client Code Mask Field
          if (cell.field === 'maskCode' || cell.field === 'mask') {
            inputPlaceholder = t('insertPlaceholders.insertCodeMask');
          }
          if (cell.field === 'maskCode' && newClientCategoryData.category === '') {
            disabled = true;
            inputPlaceholder = ``;
          }
        }

              // For Supplier Category
              if (tableName === 'Supplier Category') {
                data = newSupplierCategoryData[cell.field];
                // Supplier Category Field
                if (cell.field === 'category') {
                  inputPlaceholder = `Insert Category`;
                }

                // Supplier Code Mask Field
                if (cell.field === 'maskCode' || cell.field === 'mask') {
                  inputPlaceholder = `Insert Code Mask`;
                }
                if (cell.field === 'maskCode' && newSupplierCategoryData.category === '') {
                  disabled = true;
                  inputPlaceholder = ``;
                }
              }

        // For Product Category My Data Table
        if (tableName === 'Product Category My Data' || tableName === 'New Product My Data') {
          data = newProductsCategoryMyData[cell.field];
          let rowType = newProductsCategoryMyData['rowType'];
          if (cell.field === 'invoiceType') {
            if (rowType === "income") {
              let clone = [...myDataCharOptions.filter((el) => el.category?.filter((el2) => String(el2.value).startsWith("category1_") || String(el2.value).startsWith("category3"))?.length > 0)];
              clone = clone.map((o) => Object.assign({}, o));
              for (let invType of clone) {
                invType.label = invType.value + " " + invType.label;
              }
              dynamicOptions = clone;
            } else if (rowType === "expense") {
              let clone = [...myDataCharOptions.filter((el) => el.category?.filter((el2) => String(el2.value).startsWith("category2_"))?.length > 0)];
              clone = clone.map((o) => Object.assign({}, o));
              for (let invType of clone) {
                invType.label = invType.value + " " + invType.label;
              }
              dynamicOptions = clone;
            } else {
              dynamicOptions = [];
              inputPlaceholder = "Παρακαλώ επιλέξτε πρώτα Κατηγορία Γραμμής";
            }
            enableDynamicOptions = 'true';
          }
          // Data population logic for category
          if (cell.field === 'category') {
            let mdType = String(newProductsCategoryMyData["invoiceType"]);
            let invoiceTypeData = myDataCharOptions.find((el) => el.value === mdType);
            if (invoiceTypeData) {
              if (invoiceTypeData.category && invoiceTypeData.category.length > 0) {
                dynamicOptions = invoiceTypeData.category.filter((el) => rowType === "income" || isEmpty(rowType) ?
                    (String(el.value).startsWith("category1_") || String(el.value).startsWith("category3"))
                    :
                    String(el.value).startsWith("category2_"));
              }
            }
            if (mdType === '1.6' || mdType === '2.4' || mdType === '5.1') {
              dynamicOptions = icCategory
            }
            if(dynamicOptions.length === 0 && newProductsCategoryMyData['rowType']){
              inputPlaceholder = "Παρακαλώ επιλέξτε πρώτα τύπο παραστατικού";
            } else if(dynamicOptions.length === 0 && !newProductsCategoryMyData['rowType']){
              inputPlaceholder = "Παρακαλώ επιλέξτε πρώτα Κατηγορία Γραμμής";
            }
            enableDynamicOptions = 'true';
          }

          // Data population logic for type
          if (cell.field === 'type') {
            let mdType = String(newProductsCategoryMyData["invoiceType"]);
            let mdCategory = newProductsCategoryMyData['category'];
            let invoiceTypeData = myDataCharOptions.find((el) => el.value === mdType);
            if (invoiceTypeData) {
              if (invoiceTypeData.category && invoiceTypeData.category.length > 0) {
                let categoryData = invoiceTypeData.category.find((el) => el.value === mdCategory);
                if (categoryData) {
                  if (categoryData.type && categoryData.type.length > 0) {
                    dynamicOptions = categoryData.type;
                  }
                }
              }
            }
            if (mdType === '1.6' || mdType === '2.4' || mdType === '5.1') {
              dynamicOptions = icType
            }
            if(dynamicOptions.length === 0 && newProductsCategoryMyData['rowType']){
              inputPlaceholder = "Παρακαλώ επιλέξτε πρώτα Κατηγορία";
            } else if(dynamicOptions.length === 0 && !newProductsCategoryMyData['rowType']){
              inputPlaceholder = "Παρακαλώ επιλέξτε πρώτα Κατηγορία Γραμμής";
            }
            enableDynamicOptions = 'true';
          }
          if(cell.field === "vatExpenseType"){
            let rowType = newProductsCategoryMyData['rowType'];
            disabled = rowType !== "expense";
          }
        }


        // For New Price List Product Table
        if (tableName === 'New Price List General') {
          data = PRODUCTS_DATA.priceListNewProduct[cell.field];
          rowData = PRODUCTS_DATA.priceListNewProduct;
          if ((cell.field === 'client' ||
            cell.field === 'price' ||
            cell.field === 'vat'  ||
            cell.field === 'discount'
           ) && rowData.name === '') {
            disabled = true;
          }
        }

        // For Edit Price List Product Table
        if (tableName === 'Edit Price List General') {
          data = PRODUCTS_DATA.priceListEditProduct[cell.field];
          rowData = PRODUCTS_DATA.priceListEditProduct;
          if ((cell.field === 'client' ||
            cell.field === 'price' ||
            cell.field === 'vat'  ||
            cell.field === 'discount'
          ) && rowData.name === '') {
            disabled = true;
          }
        }

        // For Products list of Product Movements Table
        if (tableName === 'Product Movements Products') {
          data = PRODUCTS_DATA.productMovementsNewProduct[cell.field];
        }

        // For Products list of Product Movements Table
        if (tableName === 'Edit Product Movements Products') {
          data = PRODUCTS_DATA.productMovementsEditProduct[cell.field];
        }

        // For Client Contact Points
        if (tableName === 'Client Contact Points') {
          data = newContactPoint[cell.field];
        }

        // For Supplier Contact Points

        if (tableName === 'Supplier Contact Points') {
          data = newContactPointSupplier[cell.field];
        }

        // For Client Installations
        if (tableName === 'Client New Installations') {
          data = CLIENTS_DATA.newInstallation[cell.field];
        }
         // For Supplier Installations
         if (tableName === 'Supplier New Installations') {
          data = SUPPLIERS_DATA.newInstallation[cell.field];
        }
         if (tableName === "New Work Permit Employees") {
           data = PAYROLL.tempNewWorkPermit[cell.field];
         }
          if (tableName === "Edit Work Permit Employees") {
            data = PAYROLL.tempNewEditWorkPermit[cell.field];
          }
         if (tableName === "New Product Properties") {
           data = PRODUCTS_DATA.tempNewProductProperties[cell.field];
         }
         if (tableName === "Edit Product Properties") {
           data = PRODUCTS_DATA.tempEditProductProperties[cell.field];
         }
         if (tableName === "Default Category Properties") {
           data = PRODUCTS_CATEGORY.tempNewDefaultProperties[cell.field];
         }

        // For Client Notes dynamic tables
        if(CLIENTS_DATA.clientsSettings.clientsInfo && CLIENTS_DATA.clientsSettings.clientsInfo.length > 0) {
          const currentDate = moment().format('DD/MM/YYYY');
          const currentTime = moment().format('H:mm');
          const currentUser = getCurrentUser();
          const cUserName   = currentUser?.firstName + " " + currentUser?.lastName;
          CLIENTS_DATA.clientsSettings.clientsInfo.forEach((item) => {
            if ((tableName === 'Client New Notes ' + item) && cell.field === "date") {
              data = currentDate.toString();
            } else if((tableName === 'Client New Notes ' + item) && cell.field === "time"){
              data = currentTime.toString();
            } else if((tableName === 'Client New Notes ' + item) && cell.field === "lastEditedBy"){
              data = cUserName;
            }
          });
        }

       // For Supplier Notes dynamic tables
       if(SUPPLIERS_DATA.suppliersSettings.suppliersInfo && SUPPLIERS_DATA.suppliersSettings.suppliersInfo.length > 0) {
        SUPPLIERS_DATA.suppliersSettings.suppliersInfo.forEach((item) => {
          if (tableName === 'Supplier New Notes ' + item) {
            data = SUPPLIERS_DATA.newNotes && SUPPLIERS_DATA.newNotes[item] ? SUPPLIERS_DATA.newNotes[item][cell.field] : '';
          }
        });
      }

        // For Client Information
        if (tableName === 'Client New Information') {
          data = CLIENTS_DATA.newInformation[cell.field];
        }

            // For Supplier Information
            if (tableName === 'Supplier New Information') {
              data = SUPPLIERS_DATA.newInformation[cell.field];
            }

        // For Clients list of Client Movements Table
        if (tableName === 'Client Movements Clients') {
          data = CLIENTS_DATA.clientMovementsNewClient[cell.field];
        }

        // For Clients list of Client Movements Table
        if (tableName === 'Edit Client Movements Clients') {
          data = CLIENTS_DATA.clientMovementsEditClient[cell.field];
        }

              // For Suppliers list of Supplier Movements Table
              if (tableName === 'Supplier Movements Suppliers') {
                data = SUPPLIERS_DATA.supplierMovementsNewSupplier[cell.field];
              }

              // For Supplier list of Supplier Movements Table
              if (tableName === 'Edit Supplier Movements Suppliers') {
                data = SUPPLIERS_DATA.supplierMovementsEditSupplier[cell.field];
              }


        if (tableName === "Withhold Taxes Table") {
          data = SETTINGS.newWithHoldTax[cell.field];
          rowData = SETTINGS.newWithHoldTax;
        }

        if (tableName === "Fees Table") {
          data = SETTINGS.newFee[cell.field];
          rowData = SETTINGS.newFee;
        }

        if (tableName === "Other Taxes Table") {
          data = SETTINGS.newOtherHoldTax[cell.field];
          rowData = SETTINGS.newOtherHoldTax;
        }

        // For Client Communication History
        if (tableName === 'Client Communication History') {
          data = CLIENTS_DATA.newCommunicationHistory[cell.field];
        }


        // For Client Communication History
        if (tableName === 'Supplier Communication History') {
          data = SUPPLIERS_DATA.newCommunicationHistory[cell.field];
        }

        // For Client Emron Passwords
        if (tableName === 'Emron Passwords') {
          data = CLIENTS_DATA.newClientEmronPassword[cell.field];
          data = SUPPLIERS_DATA.newSupplierEmronPassword[cell.field];

        }

        // For Client Emron Stockholders
        if (tableName === 'Emron Stockholders') {
          data = CLIENTS_DATA.newClientEmronStockholder[cell.field];
          data = SUPPLIERS_DATA.newSupplierEmronStockholder[cell.field];

        }

        // For Sales Settings Payment Types
        if (tableName === 'Sales Settings Payment Types') {
          data = SALES_SETTINGS.newPaymentTypes[cell.field];
        }

        // For BUys Settings Payment Types
        if (tableName === 'Buys Settings Payment Types') {
          data = BUYS_SETTINGS.newPaymentTypes[cell.field];
        }

        if (tableName === "Payroll Settings Shifts") {
          data = PAYROLL.tempNewShift[cell.field];
        }

        // For Advanced Types Document Numbering
        if (tableName === 'Advanced Types Document Numbering') {
          data = SETTINGS.newDocumentNumbering[cell.field];
          let newAtdn = SETTINGS.newDocumentNumbering;
          if ((cell.field === 'preNumber' || cell.field === 'number') && newAtdn.numberingMode === "manual") {
            disabled = true;
            inputPlaceholder = "";
          }

          // Dynamic Options for | It will hide the used prenumbers from the list on new row
          if (cell.field === 'preNumber') {
            let etDocumentNumbering = SETTINGS.settings.newAdvancedType.documentNumbering;
            dynamicOptions= filterUsedPreNumbers(preNumberGreek, etDocumentNumbering, '', SETTINGS.newDocumentNumbering.installation)
            enableDynamicOptions = 'true';
          }

          // Dynamic Options for | It will hide unused modes -- fixes
          if (cell.field === 'numberingMode') {
            let etDocumentNumbering = SETTINGS.settings.newAdvancedType.documentNumbering;
            dynamicOptions= filterUnusedNumberingModes(numberingModeOptions, etDocumentNumbering)
            enableDynamicOptions = 'true';
          }
        }

        // For Edit Advanced Types Document Numbering
        if (tableName === 'Edit Advanced Types Document Numbering') {
          data = SETTINGS.editDocumentNumbering[cell.field];
          let newAtdn = SETTINGS.editDocumentNumbering;
          if ((cell.field === 'preNumber' || cell.field === 'number') && newAtdn.numberingMode === "manual") {
            disabled = true;
            inputPlaceholder = "";
          }
          // Dynamic Options for | It will hide the used prenumbers from the list on new row
          if (cell.field === 'preNumber') {
            let etDocumentNumbering = SETTINGS.settings.editAdvancedType.documentNumbering;
            dynamicOptions= filterUsedPreNumbers(preNumberGreek, etDocumentNumbering, SETTINGS.editDocumentNumbering.installation)
            enableDynamicOptions = 'true';
          }

          // Dynamic Options for | It will hide unused modes -- fixes
          if (cell.field === 'numberingMode') {
            let etDocumentNumbering = SETTINGS.settings.editAdvancedType.documentNumbering;
            dynamicOptions= filterUnusedNumberingModes(numberingModeOptions, etDocumentNumbering, false);
            enableDynamicOptions = 'true';
          }
        }

        // For Product Codes
        if (tableName === "Product Codes") {
          data = PRODUCTS_DATA.newProductCode[cell.field];
        }

        // For New Sales Product List
        if (tableName === 'Sales Products List') {
          rowData = SALES.newSalesNewProduct;
          data = rowData[cell.field];
          if ((cell.field === 'quantity' ||
            cell.field === 'mu' ||
            cell.field === 'unitPrice'  ||
            cell.field === 'isPerDiscount'  ||
            cell.field === 'isDiscountAmount'  ||
            cell.field === 'vatPercentage'  ||
            cell.field === 'netValue'  ||
            cell.field === 'totalValue'  ||
            cell.field === 'discount'
            ) && rowData.name === '') {
            disabled = true;
          }
        }

        // Number will be empty for the new field
        if (cell.field === 'no' && tableName !== 'Client New Installations') {
          data = '';
        }
        return (
          cell.field !== 'actions' ?
            <TableBodyCell
              reqName = {reqName}
              reqData={pRequestData}
              enableDynamicOptions = {enableDynamicOptions}
              dynamicOptions = {dynamicOptions}
              isInsertRow = {true}
              disabled={disabled}
              placeholder={inputPlaceholder}
              newRow={handleNewRowEntry}
              dataKey={data && data._id ? data._id : '0'}
              key={celli}
              cell={cell}
              data={data}
              rowData = {rowData}
              tableName = {tableName}
            />
          :
            (props.enableNewRowCheckbox && props.enableNewRowCheckbox === true) ?
             <td key={celli} className="text-center w-auto">
               <input
                title="Stops being new row"
                onChange={(e) => handleEmptyFieldEntry(e)}
                type="checkbox"
                checked={false}
                value="addemptyfield"
                />
              </td>
            :
            <td key={celli} className="text-center w-auto"></td>
        )
      })
    }
    </>
  )

}
export default TableInsertDataRow;
