import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Chart from "react-apexcharts";
import {checkPermissionMasterParentStartingTree, getGreekCurrencyFormat, isEmpty} from "../_helpers/commonFunctions";
import Select from "react-select";
import {fetchDashboardBuysData} from "../_apis/api";
import {Spinner} from "react-bootstrap";
import {setDashboardGraphSettings} from "../_reducers/companySlice";
import moment from "moment";

export const OverviewChart2 = () => {
    const dispatch = useDispatch();
    const dashboardData = useSelector(state => state.COMPANY_DATA.dashboardData);
    const dashboardBuysData = useSelector(state => state.COMPANY_DATA.dashboardBuysData);
    const graphSettings = useSelector(state => state.COMPANY_DATA.dashboardGraphSettings);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const [loader, setLoader] = useState(false);
    const [chartOptions, setChartOptions] = useState({});
    const [series, setSeries] = useState([]);

    const permissionsData = JSON.parse(localStorage.getItem("permissions"));

    const months = {
        0: "Ιανουάριος",
        1: "Φεβρουάριος",
        2: "Μάρτιος",
        3: "Απρίλιος",
        4: "Μάιος",
        5: "Ιούνιος",
        6: "Ιούλιος",
        7: "Αύγουστος",
        8: "Σεπτέμβριος",
        9: "Οκτώβριος",
        10: "Νοέμβριος",
        11: "Δεκέμβριος",
    }

    const dropdownOptions = [
        {
            label: "Γραφική Αναφορά Πωλήσεων - Εισπράξεων",
            value: 1
        }, {
            label: "Γραφική Αναφορά Αγορών - Πληρωμών",
            value: 2
        },
    ];

    const dropdownStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: '27px',
            height: '27px',
            borderRadius: 'unset',
            border: 'unset',
            borderBottom: '1px solid #ccc',
            fontSize: '18px',
            fontWeight: '600',
            width: '400px',
            margin: 'auto',
            boxShadow: 'none',
            '&:hover': {
                borderBottom: '1px solid #888',
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: '27px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 8px',
        }),
        input: (provided) => ({
            ...provided,
            margin: '0',
            padding: '0',
        }),
        placeholder: (provided) => ({
            ...provided,
            margin: '0',
            padding: '0',
            display: 'flex',
            alignItems: 'center',
        }),
        singleValue: (provided) => ({
            ...provided,
            margin: '0',
            padding: '0',
            display: 'flex',
            alignItems: 'center',
            position: 'absolute'
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: '0',
            height: '27px',
            display: 'flex',
            alignItems: 'center',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        menu: (provided) => ({
            ...provided,
            overflow: 'hidden',
            zIndex: '99',
        }),
        option: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            height: '27px',
            padding: '0 8px',
        }),
    }

    const createGraph = () => {
        let graphData = {};
        if (graphSettings.graphMode === 1) {
            graphData = structuredClone(dashboardData);
        } else if (graphSettings.graphMode === 2) {
            graphData = structuredClone(dashboardBuysData);
        }
        if (isEmpty(graphData.monthlyData)) graphData.monthlyData = [];
        let co = {
            chart: {
                toolbar: {
                    show: true,
                    offsetY: 10,
                    tools: {
                        customIcons: [
                            {
                                icon: graphSettings.graphSetting === "daily" ? '<img src="/assets/images/calendar.png" class="apexcharts-selected-icon" alt="Calendar" />' : '<img src="/assets/images/calendar.png" style="" alt="Calendar" />',
                                index: "-10",
                                title: 'Ημερήσια Αναπαράσταση',
                                class: 'graph-icon mr-1 mt-1',
                                click: (chart, options, e) => {
                                    dispatch(setDashboardGraphSettings({...graphSettings, graphSetting: "daily" }));
                                },
                            },
                            {
                                icon: graphSettings.graphSetting === "monthly" ? '<img src="/assets/images/30-days.png" class="apexcharts-selected-icon" alt="Calendar" />' : '<img src="/assets/images/30-days.png" style="" alt="Calendar" />',
                                index: "-10",
                                title: 'Ημερήσια Αναπαράσταση',
                                class: 'graph-icon mr-1 mt-1',
                                click: (chart, options, e) => {
                                    dispatch(setDashboardGraphSettings({...graphSettings, graphSetting: "monthly" }));
                                },
                            },
                            {
                                icon: graphSettings.graphSetting === "yearly" ? '<img src="/assets/images/calendar-yearly.png" class="apexcharts-selected-icon" alt="Calendar" />' : '<img src="/assets/images/calendar-yearly.png" style="" alt="Calendar" />',
                                index: "-10",
                                title: 'Ημερήσια Αναπαράσταση',
                                class: 'graph-icon mr-1 mt-1',
                                click: (chart, options, e) => {
                                    dispatch(setDashboardGraphSettings({...graphSettings, graphSetting: "yearly" }));
                                },
                            },
                        ],
                    },
                },
            },
            xaxis: {
                categories: [],
            },
            yaxis: {
                labels: {
                    formatter: (value) => `${value} €`
                }
            },
            dataLabels: {
                enabled: false,
            },
            tooltip: {
                y: {
                    formatter: (value) => getGreekCurrencyFormat(value)
                }
            },
            colors: ["#d95f02", "#1b9e77"]
        }
        let s = [
            {
                name: graphSettings.graphMode === 1 ? "Πωλήσεις" : "Αγορές",
                data: [{}]
            },
            {
                name: graphSettings.graphMode === 1 ? "Εισπράξεις" : "Πληρωμές",
                data: [{}]
            }
        ];
        if (graphSettings.graphSetting === "monthly") {
            s[0].data.pop();
            s[1].data.pop();
            for (let d of graphData.monthlyData) {
                co.xaxis.categories.push(d.month);
                s[0].data.push(graphSettings.graphMode === 1 ? d.totalSales : d.totalBuys);
                s[1].data.push(d.totalUnits);
            }
        } else if (graphSettings.graphSetting === "yearly") {
            s[0].data.pop();
            s[1].data.pop();
            for (let d of graphData.yearData) {
                co.xaxis.categories.push(d.year);
                s[0].data.push(graphSettings.graphMode === 1 ? d.totalSales : d.totalBuys);
                s[1].data.push(d.totalUnits);
            }
        } else if (graphSettings.graphSetting === "daily") {
            s[0].data.pop();
            s[1].data.pop();
            const selectedMonthData = graphData.monthlyData.filter((el) => el.month === months[graphSettings.currentMonth]);
            const dailyData = !isEmpty(selectedMonthData) ? selectedMonthData[0].dailyData : [];
            for (let d of dailyData) {
                co.xaxis.categories.push(d.day);
                s[0].data.push(graphSettings.graphMode === 1 ? d.totalSales : d.totalBuys);
                s[1].data.push(d.totalUnits);
            }
        }
        setChartOptions(co);
        setSeries(s);
    }

    useEffect(() => {
        if (graphSettings.graphMode === 2 && JSON.stringify(dashboardBuysData) === "{}") {
            dispatch(fetchDashboardBuysData(company.id, company.year, setLoader));
        }
    }, [graphSettings.graphMode]);

    useEffect(() => {
        createGraph();
    }, [dashboardData, dashboardBuysData, graphSettings.graphMode, graphSettings.graphSetting, graphSettings.currentMonth]);

    const handleModeChange = (e) => dispatch(setDashboardGraphSettings({...graphSettings, graphMode: e.value}));

    const canClickNextMonth = () => (company.year !== moment().year()) || (graphSettings.currentMonth !== moment().month())
    const canClickPrevMonth = () => (company.year !== moment().year()) || (graphSettings.currentMonth !== 0)

    const handleArrowClick = (direction) => {
        if (direction === "R") {
            if (graphSettings.currentMonth === 11) {
                dispatch(setDashboardGraphSettings({...graphSettings, currentMonth: 0}));
            } else {
                dispatch(setDashboardGraphSettings({...graphSettings, currentMonth: graphSettings.currentMonth + 1}));
            }
        } else if (direction === "L") {
            if (graphSettings.currentMonth === 0) {
                dispatch(setDashboardGraphSettings({...graphSettings, currentMonth: 11}));
            } else {
                dispatch(setDashboardGraphSettings({...graphSettings, currentMonth: graphSettings.currentMonth - 1}));
            }
        }
    }

    return (
        <div style={{
            borderRadius: "10px",
            border: "1px solid lightgray",
            padding: "10px",
            paddingTop: "5px",
            height: "100%",
            width: "100%",
            boxShadow: "0px 2px 4px -1px lightgray",
            minHeight: "370px",
        }}>
            {checkPermissionMasterParentStartingTree("buys", permissionsData) === true ?
                <Select
                    styles={dropdownStyles}
                    options={dropdownOptions}
                    isSearchable={false}
                    value={dropdownOptions.find((el) => el.value === graphSettings.graphMode)}
                    onChange={(e) => handleModeChange(e)}
                />
                :
                <div style={{textAlign: "center", fontSize: "20px", fontWeight: "600"}}>Γραφική Αναφορά Πωλήσεων -
                    Εισπράξεων</div>
            }
            <Chart
                options={chartOptions}
                series={series}
                type="area"
                height={"94%"}
                width={"100%"}
            />
            {graphSettings.graphSetting === "daily" && <div className={"d-flex justify-content-center align-items-center"}>
                <div className={"graph-month-switch"}>
                    <div onClick={() => handleArrowClick("L")} style={{padding: "10px", cursor: "pointer", visibility: canClickPrevMonth() ? "visible" : "hidden"}}>
                        <img src={"assets/images/cloud/left-arrow.png"} alt={"Left arrow"}
                             style={{height: "16px", width: "16px"}}/>
                    </div>
                    <div style={{fontSize: "18px", fontWeight: "600"}}>{months[graphSettings.currentMonth]}</div>
                    <div onClick={() => handleArrowClick("R")} style={{padding: "10px", cursor: "pointer", visibility: canClickNextMonth() ? "visible" : "hidden"}}>
                        <img src={"assets/images/cloud/right-arrow.png"} alt={"Right arrow"}
                             style={{height: "16px", width: "16px"}}/>
                    </div>
                </div>
            </div>}
            {loader && <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                <Spinner style={{width: "100px", height: "100px"}} animation={"border"} variant={"dark"}/></div>}
        </div>
    )
}
