import React, {useEffect, useState} from 'react';
import {Button, Modal, Row} from "react-bootstrap";
import {isEmpty} from "../../../../_helpers/commonFunctions";
import QR from "qrcode-base64";
import {useSelector} from "react-redux";
import {jsPDF} from "jspdf";
import {liberationSerif} from "../../../../fonts/liberationSerif";
import {dejavuSans} from "../../../../fonts/DejaVuSans-normal";
import {dejavuSansBold} from "../../../../fonts/DejaVuSans-Bold-bold";
import Dropdown from "react-bootstrap/Dropdown";

const ShowQRModal = ({employeeData}) => {
    const companyDetails = useSelector((state) => state.COMPANY_DATA.companyDetails);
    const [show, setShow] = useState(false);
    const [qr, setQR] = useState("");
    const [iframeData, setIframeData] = useState();
    const iframeRef = React.createRef();

    useEffect(() => {
        if (show) {
            const firstName = employeeData?.firstName;
            const lastName = employeeData?.lastName;
            const vatNumber = employeeData?.vatNumber;
            const id = companyDetails.erganiEmployerId;
            if (!isEmpty(companyDetails.erganiEmployerId) && !isEmpty(firstName) && !isEmpty(lastName) && !isEmpty(vatNumber)) {
                const QR = `erg|nm:${String(firstName).trim()};ln:${String(lastName).trim()};afm:${String(vatNumber).trim()};id:${id}`;
                setQR(QR);
            }
        } else {
            setQR("");
        }
    },[show])

    const handleIframeLoad = () => {
        // wait for the iframe content to load
        if (iframeData && iframeRef.current) {
            // access the iframe's window object
            const iframeWindow = iframeRef.current.contentWindow;
            // print the iframe content
            iframeWindow.print();
        }
    }

    const handlePrint = (printer) => {
        const firstName = employeeData?.firstName;
        const lastName = employeeData?.lastName;
        const vatNumber = employeeData?.vatNumber;
        const doc = new jsPDF('portrait', "mm", "a4",);
        doc.addFileToVFS("arialuni.ttf", liberationSerif);
        doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
        doc.addFileToVFS("dejavusans.ttf", dejavuSans);
        doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
        doc.setFont("DejaVu Sans Normal");
        doc.setFontSize(12);

        const textX = 10;
        const textY = 14;
        const lineHeight = 7;
        const keyWidth = 25;
        const valueX = textX + keyWidth

        console.log(companyDetails)
        const data = [
            { key: "Όνομα:", value: firstName ?? "" },
            { key: "Επώνυμο:", value: lastName ?? "" },
            { key: "Α.Φ.Μ.:", value: vatNumber ?? "" },
        ];
        data.forEach((item, index) => {
            doc.text(item.key, textX, textY + index * lineHeight);
            doc.text(item.value, valueX, textY + index * lineHeight);
        });

        const logoData = 'assets/images/prrimer-logo.png';
        const logoX = doc.internal.pageSize.getWidth() - 45;
        const logoY = 10;
        doc.addImage(logoData, 'PNG', logoX, logoY, 40, 15);

        const lineY = textY + data.length * lineHeight + 5;
        doc.line(10, lineY, 200, lineY);

        const qrImage = QR.drawImg(qr, { typeNumber: 4, errorCorrectLevel: 'M', size: 190 })
        const qrSize = 80;
        const qrX = (210 - qrSize) / 2;
        const qrY = (297 - qrSize) / 2;
        doc.addImage(qrImage, 'PNG', qrX, qrY, qrSize, qrSize);

        const copyright = "Primer ERP Copyright © Primer Software P.C. 2025";
        doc.setFontSize(10);

        const crWidth = doc.getTextWidth(copyright);
        const footerX = 200 - crWidth;
        const footerY = 287;

        doc.text(copyright, footerX, footerY);

        if (printer) {
            setIframeData(`${doc.output('bloburl')}`);
        } else {
            doc.save(`${firstName ? firstName + "-" : ""}${lastName ? lastName + "-" : ""}QRεργαζομένου.pdf`);
        }
        setShow(false);
    }

    return (
        <React.Fragment>
            <Button onClick={() => setShow(true)}>Προβολή QR</Button>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>QR Εργαζομένου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!isEmpty(qr) ? (
                        <div className={"d-flex justify-content-center"}>
                            <img src={QR.drawImg(qr, {
                                typeNumber: 4,
                                errorCorrectLevel: 'M',
                                size: 190
                            })} alt="QR Code"/>
                        </div>
                    ) : (
                        <div>Πρόβλημα κατά την παραγωγή QR. Παρακαλώ βεβαιωθείτε ότι έχετε συμπληρώσει, όνομα, επώνυμο
                            και ΑΦΜ εργαζομένου.</div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <div className={"d-flex justify-content-between w-100"}>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic" size={"md"}>
                                Εκτύπωση αποτελεσμάτων
                            </Dropdown.Toggle>
                            <Dropdown.Menu className={"w-100"}>
                                <Dropdown.Item onClick={() => handlePrint(true)}>
                                    Εκτυπωτής
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handlePrint()}>
                                    Αποθήκευση ως PDF
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button variant="outline-primary" onClick={() => setShow(false)}>
                            Κλείσιμο
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <div style={{display: "none"}} className="print-me" id="printReport">
                <iframe onLoad={handleIframeLoad} ref={iframeRef} style={{width: "100%"}} src={iframeData}
                        title={"Print Report"}></iframe>
            </div>
        </React.Fragment>
    )
}

export default ShowQRModal
