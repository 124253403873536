// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-time-picker {
  font-family: monospace;
  input {
    padding: 0 5px;
    text-align: center;
  }

  .text-danger {
    font-size: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/_components/common/TimePickerInput/custom-time-picker.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB;IACE,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".custom-time-picker {\r\n  font-family: monospace;\r\n  input {\r\n    padding: 0 5px;\r\n    text-align: center;\r\n  }\r\n\r\n  .text-danger {\r\n    font-size: 12px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
