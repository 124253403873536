import React, {useRef} from "react";
import { sampleSalesTemplateData } from "../../../_data/sales/sales-print-template-json";
import {browserName} from "react-device-detect";
import {epayLogoBase64, vivaLogoBase64} from "./print-constants";
import {isEmpty} from "../../../_helpers/commonFunctions";

const SalesPrintTemplateEnglish1 = React.forwardRef((props, ref) => {
  if(browserName !== "Safari"){
    require ('./scss/sales-print-template1.css');
  } else {
    require ('./scss/sales-print-template1-safari.css');
  }
  let templateData = [];
  if (props.data) {
    templateData = props.data;
  } else {
    templateData = sampleSalesTemplateData;
  }
  let showWaterMark = templateData.MYDATAENVIRONMENT === "test";
  const waterMarkStyle = {
    position: "absolute",
    transform: "translate(90px, -30%) rotate(-45deg)",
    left: "-30px",
    bottom: "50%",
    fontSize: "222px",
    margin: "0 0 -250px 0",
    color: "#cdcdcd",
    textTransform: "uppercase",
    opacity: "0.7",
  }

  const pageStyle = {
    width: "210mm",
    height: "297mm",
  }

  const skinBoldStyle = {
    color: "#555",
    fontWeight: "bold",
    lineHeight: "1.2",
  }

  const customerInformationStyle = {
    fontSize: "16px",
    textDecoration: "underline",
    color: "#555",
    fontWeight: "bold",
    textAlign: "center",
  }

  const informationCustomerIdStyle = {
    width: "23%",
    color: "#555",
    fontWeight: "bold",
  }
  const informationCustomerIdStyle2 = {
    width: "45%",
    color: "#555",
    fontWeight: "bold",
  }

  const invoiceBoxStyle = {
    fontSize: "16px",
    //fontFamily: "'Helvetica Neue', Helvetica, Helvetica, Arial, sans-serif",
    fontFamily: "Arial",
    color: "#555",
    width: "100%",
    height: "100%",
    position: "relative",
  }

  const totalContainerStyle = {
    border: "1px solid transparent",
    borderRadius: "14px",
    overflow: "visible",
    maxHeight: "280px"
    // marginBottom: "10px",
  }

  const totalContainerTotalsStyle = {
    border: "1px solid #ddd",
    boxShadow: "0 0 10px rgba(0, 0, 0, .15)",
    fontSize: "9px",
    backgroundColor: "#555",
  }

  const totalPaymentStyleTd = {
    textAlign: "right",
    marginRight: "20px",
    backgroundColor: "white",
  }

  const totalPaymentPStyle = {
    padding: "4px",
    margin: "4px",
    fontSize: "16px",
  }

  const totalPaymentStyle = {
    fontWeight: "700",
    fontSize: "16px",
  }

  const invoiceBoxTableTdStyle = {
    padding: "1px",
    border: "unset",
  }

  const logoContainerStyle = {
    fontSize: "30px",
    width: "40%",
    color: "#333",
    border: "none",
  }

  const totalTdBlock = {
    width: "263px",
    verticalAlign: templateData?.ENABLE_PEPPOL ? "middle" : "bottom"
  }

  const totalTdBlockTotalsTable = {
    width: "263px",
    borderSpacing: 0,
    borderCollapse: "collapse",
    border: 0,
  }

  const totalContainerHeadingStyle = {
    backgroundColor: "#555",
    color: "white",
    maxWidth: "130px"
  }

  const totalContainerHeadingPStyle = {
    paddingTop: "5px",
    paddingBottom: "5px",
    margin: "1px",
    fontSize: "16px",
  }

  const mainInfoTableTr = {
    // border: "1px solid #ddd",
    fontWeight: "700",
    textAlign: "center",
  }

  const productsStyle = {
    border: "1px solid black",
    textAlign: "center",
    fontSize: "16px",
  }

  const productsStyleTdStyle = {
    fontSize: "16px",
    textAlign: "center",
    maxWidth: "300px",
    overflowWrap: "break-word",
    overflow: "hidden",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
  }

  const mainInfoTableHeadingPStyle = {
    margin: "4px",
    fontSize: "16px",
    lineHeight: 1,
  }

  const productContainerStyle = {
    marginTop: "4px",
    border: "2px solid #555",
    borderRadius: "4px",
    overflow: "hidden",
    height: (templateData.TRANSACTION_DETAILS?.providerSignature || templateData?.ENABLE_PEPPOL) ? "450px" : "575px",
    padding: "2px",
  }
  const productContainerStyleLastPage = {
    marginTop: "4px",
    border: "2px solid #555",
    borderRadius: "4px",
    overflow: "hidden",
    height: (templateData.TRANSACTION_DETAILS?.providerSignature || templateData?.ENABLE_PEPPOL) ? "450px" : "575px",
    padding: "2px",
  }

  const productTableHeadingStyle = {
    backgroundColor: "#555",
    color: "white",
  }

  const headingStyle = {
    backgroundColor: "#555",
    color: "white",
    border: "1px solid #ddd",
    fontWeight: "700",
    textAlign: "center",
  }

  const headingTdStyle = {
    margin: "4px",
    borderTop: "unset",
    borderBottom: "unset",
    borderRight: "2px solid #fff",
    borderLeft: "2px solid #fff",
  }

  const mainInfoStyle = {
    border: "1px solid #555",
    borderRadius: "4px",
    overflow: "hidden",
    padding: "3px 3px 0 3px",
  }

  const footerContainerStyle = {
    position: "absolute !important",
    bottom: "0px",
    width: "100%",
    margin: "20px auto auto auto",
    paddingBottom: "10",
  }

  const headerTableStyle = {
    width: "100%",
    textAlign: "left",
  }

  const issuerContainerStyle = {
    textAlign: "center",
    marginTop: "6px",
    border: "none",
    lineHeight: 1.2,
    padding: "10px",
  }

  const IssuerName = {
    fontSize: "15px",
    fontWeight: "bold",
    color: "#555",
    lineHeight: 1.2,
  }

  const issuerSubjectField = {
    fontWeight: "normal",
    fontStyle: "italic",
    fontSize: "15px",
    color: "#555",
    lineHeight: 1.2,
  }

  const issuerAddressStyle = {
    fontSize: "13px",
    color: "#555",
    lineHeight: 1.2,
  }

  const issuerContainerPStyle = {
    margin: "0px",
    fontSize: "14px",
    lineHeight: 1.2,
  }

  const informationTableStyle = {
    marginTop: "2px",
    width: "100%",
    wordBreak: "break-all"
  }

  const quantityHeadTd = {
    fontSize: "16px",
    textAlign: "center",
    borderRight: "1px solid #ddd",
  }

  const descriptionHeadTd = {
    fontSize: "16px",
    textAlign: "center",
    borderRight: "1px solid #ddd",
    width: "34%",
  }

  const priceHeadTdStyle = {
    fontSize: "16px",
    textAlign: "center",
    borderRight: "1px solid #ddd",
    width: "8%",
  }
  const vatPerHeadTdStyle = {
    fontSize: "16px",
    textAlign: "center",
    borderRight: "1px solid #ddd",
    width: "4%",
  }

  const priceWithVatStyle = {
    padding: "4px",
    margin: "4px",
    fontSize: "16px",
  }

  const blankRowBorderedTdStyle = {
    borderTop: "1px solid white",
    backgroundColor: "white",
    maxHeight: "2px",
    height: "2px",
    lineHeight: "2px",
  }

  const infoValueStyle = {
    color: "#555",
    fontWeight: "normal",
    lineHeight: "1.2",
  }

  const contNotationStyle = {
    border: "1px solid #555",
    padding: "8px",
    borderRadius: "8px",
    fontSize: "13px",
    overflow: "hidden",
    marginTop: "10px",
    overflowWrap: "break-word",
  }

  const contSignsStyle = {
    border: "1px solid #555",
    borderRadius: "8px",
    margin: "10px auto 0 auto",
    boxSizing: "border-box",
    textAlign: "center",
    width: "200px",
    height: "200px",
    overflow: "hidden",
    padding: "5px 0",
  }

  const hideQrCodeStyle = {
    borderRadius: "8px",
    margin: "10px auto 0 auto",
    boxSizing: "border-box",
    textAlign: "center",
    width: "160px",
    height: "160px",
    overflow: "hidden",
    padding: "5px 0",
  }

  const footerTableTdStyle = {
    verticalAlign: "bottom",
    maxWidth:"300px",
  }

  const perVatTotalsContainerStyle = {
    border: "1px solid #555",
    borderRadius: "8px",
    overflow: "hidden",
    overflowWrap: "anywhere",
    marginTop: "10px",
    padding: "5px"
  }

  const totalsPerVatThStyle = {
    width: "10%",
    color: "#555",
    height: "5%",
    fontWeight: "bold",
    lineHeight: 1,
  }

  const countTotalsContainerStyle = {
    padding: "4px",
    border: "4px solid #555",
    borderRadius: "8px",
    minHeight: "16px",
    // maxHeight: "16px",
    overflow: "hidden",
    marginBottom: "4px"
  }

  const countTotalProdsStyle = {
    fontSize: "16px",
    fontWeight: "bold",
  }

  const finalPricePStyle = {
    fontWeight: "bold",
    fontSize: "16px",
    padding: "4px",
    margin: "4px",
  }

  const informationTdLeftStyle = {
    fontSize: "16px",
    border: 'none',
    padding: "0 0px",
  }

  const informationLeftStyle = {
    border: "1px solid #555",
    height: "180px",
    width: "395px",
    borderRadius: "4px",
    overflow: "hidden",
    padding: "1px",
  }

  const balanceContainer = {
    width: "200px",
    border: "1px solid #555",
    borderRadius: "8px",
    margin: "1px auto",
    padding: "8px",
    fontWeight: "bold",
    textAlign: "center",
  }

  const sellerDetailsStyle = {
    color: "#555",
    fontWeight: "bold",
    fontSize: "16px",
    textDecoration: "underline",
    textAlign: "center",
  }

  const totalBoxTd2 = {
    textAlign: "right",
    backgroundColor: "white",
  }

  const totalsValueStyle = {
    padding: "1px",
    margin: "1px",
    fontSize: "16px",
    lineHeight: "16px",
    textAlign: "right",
  }

  const invoiceTableValues = {
    fontSize: "16px",
  }

  const descriptionValues = {
    fontSize: templateData?.TOO_MANY_CUSTOMER_DETAILS ? "14px" : "16px",
  }

  const senderSignStyle = {
    left: "0px"
  }
  const marginzero = {
    margin: "0"
  }
  const transactionDetailsBlock = {
    border: "1px solid #555",
    padding: "8px",
    borderRadius: "8px",
    fontSize: "13px",
    overflow: "hidden",
    overflowWrap: "break-word",
    textAlign: "center",
    fontWeight: "500",
  }

  const totalPagesA = templateData.TOTAL_PAGES !== undefined ? templateData.TOTAL_PAGES : [];
  const refHeight = useRef(null);
  return (
      <React.Fragment>
        <div style={marginzero} ref={ref}>
          { totalPagesA.length > 0 && totalPagesA.map((currentPage1,index) => (
              <>
                <div style={pageStyle} id={"page" + (index + 1)} className="sales-print-template">
                  <div style={invoiceBoxStyle}>
                    {showWaterMark && <div style={waterMarkStyle}>ΑΚΥΡΟ</div>}
                    <div className="top_table">
                      <span>Page: {index + 1} of {totalPagesA.length}</span>
                      <table style={{ width: "100%", textAlign: "left" }}>
                        <tbody>
                        <tr>
                          <td>
                            <table style={headerTableStyle}>
                              <tbody>
                              <tr>
                                <td style={logoContainerStyle}>
                                  {templateData.ISSUER_LOGO && templateData.ISSUER_LOGO !== "" && <img alt="Company_Logo" src={templateData.ISSUER_LOGO}></img>}
                                </td>
                                {templateData.BRANCH === "" &&
                                    <td style={issuerContainerStyle}>
                                      {templateData.ISSUER_NAME && templateData.ISSUER_NAME !== "" && <span style={IssuerName}>{templateData.ISSUER_NAME}</span>}
                                      {templateData.ISSUER_SMALL_NAME && templateData.ISSUER_SMALL_NAME !== "" && <p style={issuerContainerPStyle}><span style={issuerSubjectField} >{templateData.ISSUER_SMALL_NAME}</span></p>}
                                      {templateData.ISSUER_SUBJECTFIELD && templateData.ISSUER_SUBJECTFIELD !== "" && <p style={issuerContainerPStyle}><span style={issuerSubjectField}>{templateData.ISSUER_SUBJECTFIELD}</span></p>}
                                      {templateData.ISSUER_ADDRESS && templateData.ISSUER_ADDRESS !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle} >{templateData.ISSUER_ADDRESS}, {templateData.ISSUER_CITY}, {templateData.ISSUER_TK}, Phone: {templateData.ISSUER_PHONE}, ΔΟΥ: {templateData.ISSUER_DOY}</span></p>}
                                      {templateData.ISSUER_VAT && templateData.ISSUER_VAT !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>ΑΦΜ: {templateData.ISSUER_VAT}, {!isEmpty(templateData.ISSUER_GEMH) && `ΓΕΜΗ: ${templateData.ISSUER_GEMH}, `} EMAIL: {templateData.ISSUER_EMAIL}</span></p>}
                                      {!isEmpty(templateData.ISSUER_WEBPAGE) && (
                                          <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>Website: {templateData.ISSUER_WEBPAGE}</span></p>
                                      )}
                                    </td>
                                }
                                {templateData.BRANCH !== "" &&
                                    <td style={issuerContainerStyle}>
                                      {templateData.ISSUER_NAME && templateData.ISSUER_NAME !== "" && <span style={IssuerName}>{templateData.ISSUER_NAME}</span>}
                                      {templateData.ISSUER_SMALL_NAME && templateData.ISSUER_SMALL_NAME !== "" && <p style={issuerContainerPStyle}><span style={issuerSubjectField} >{templateData.ISSUER_SMALL_NAME}</span></p>}
                                      {templateData.ISSUER_SUBJECTFIELD && templateData.ISSUER_SUBJECTFIELD !== "" && <p style={issuerContainerPStyle}><span style={issuerSubjectField}>{templateData.ISSUER_SUBJECTFIELD}</span></p>}
                                      {templateData.ISSUER_ADDRESS && templateData.ISSUER_ADDRESS !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle} >Branch: {templateData.BRANCH?.address}, {templateData.BRANCH?.city}, {templateData.BRANCH?.postalCode}</span></p>}
                                      {templateData.ISSUER_PHONE && templateData.ISSUER_PHONE !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>PHONE: {templateData.BRANCH?.phone}</span></p>}
                                      {templateData.ISSUER_DOY && templateData.ISSUER_DOY !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>ΔΟΥ: {templateData.BRANCH?.doy}</span></p>}
                                      {templateData.ISSUER_VAT && templateData.ISSUER_VAT !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>ΑΦΜ: {templateData.ISSUER_VAT}</span></p>}
                                      {templateData.ISSUER_GEMH && templateData.ISSUER_GEMH !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>ΓΕΜΗ: {templateData.ISSUER_GEMH}</span></p>}
                                      {templateData.ISSUER_EMAIL && templateData.ISSUER_EMAIL !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>EMAIL: {templateData.ISSUER_EMAIL}</span></p>}
                                      {templateData.ISSUER_WEBPAGE && (
                                          <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>Website: {templateData.ISSUER_WEBPAGE}</span></p>
                                      )}
                                    </td>
                                }
                              </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      <div style={mainInfoStyle}>
                        <table style={{ width: "100%" }}>
                          <tbody>
                          <tr>
                            <td>
                              <table style={{ width: "100%" }}>
                                <tbody>
                                <tr style={headingStyle}>
                                  <td style={headingTdStyle}><p style={mainInfoTableHeadingPStyle}>INVOICE TYPE</p></td>
                                  <td style={headingTdStyle}><p style={mainInfoTableHeadingPStyle}>SERIES</p></td>
                                  <td style={headingTdStyle}><p style={mainInfoTableHeadingPStyle}>NUMBER</p></td>
                                  <td style={headingTdStyle}><p style={mainInfoTableHeadingPStyle}>DATE</p></td>
                                  <td style={headingTdStyle}><p style={mainInfoTableHeadingPStyle}>TIME</p></td>
                                </tr>
                                <tr style={mainInfoTableTr}>
                                  <td style={invoiceBoxTableTdStyle}><span style={invoiceTableValues}>{templateData.INVOICE_TYPE ? templateData.INVOICE_TYPE : '-'}</span></td>
                                  <td style={invoiceBoxTableTdStyle}><span style={invoiceTableValues}>{templateData.SERIES ? templateData.SERIES : '-'}</span></td>
                                  <td style={invoiceBoxTableTdStyle}><span style={invoiceTableValues}>{templateData.NUMBER ? templateData.NUMBER : '-'}</span></td>
                                  <td style={invoiceBoxTableTdStyle}><span style={invoiceTableValues}>{templateData.INVOICE_DATE ? templateData.INVOICE_DATE : '-'}</span></td>
                                  <td style={invoiceBoxTableTdStyle}><span style={invoiceTableValues}>{templateData.INVOICE_TIME ? templateData.INVOICE_TIME : '-'}</span></td>
                                </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <table style={informationTableStyle} className="information_table">
                        <tbody>
                        <tr>
                          <td style={informationTdLeftStyle}>
                            <div style={informationLeftStyle} className="changeWidth">
                              <table style={{width: "100%"}}>
                                <tbody>
                                <tr>
                                  <td colSpan="2" style={customerInformationStyle} >
                                    <span>CLIENT DETAILS</span>
                                  </td>
                                </tr>
                                {
                                    templateData.CUSTOMER_CODE !== '' && templateData.CUSTOMER_CODE &&
                                    <tr>
                                      <td style={informationCustomerIdStyle}>
                                        <span style={descriptionValues}>CODE:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.CUSTOMER_CODE}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.CUSTOMER_NAME !== '' && templateData.CUSTOMER_NAME &&
                                    <tr>
                                      <td style={informationCustomerIdStyle}>
                                        <span style={descriptionValues}>NAME:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.CUSTOMER_NAME}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.CUSTOMER_COMPANY !== '' && templateData.CUSTOMER_COMPANY &&
                                    <tr>
                                      <td style={informationCustomerIdStyle}>
                                        <span style={descriptionValues}>COMPANY:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.CUSTOMER_COMPANY}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.CUSTOMER_ACTIVITY !== '' && templateData.CUSTOMER_ACTIVITY &&
                                    <tr>
                                      <td style={skinBoldStyle}><span style={descriptionValues}>ACTIVITY:</span></td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.CUSTOMER_ACTIVITY}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.CUSTOMER_EMAIL !== '' && templateData.CUSTOMER_EMAIL &&
                                    <tr>
                                      <td style={skinBoldStyle}>
                                        <span style={descriptionValues}>EMAIL:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.CUSTOMER_EMAIL}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.CUSTOMER_VAT !== '' && templateData.CUSTOMER_VAT &&
                                    <tr>
                                      <td style={skinBoldStyle}>
                                        <span style={descriptionValues}>VAT Number:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.CUSTOMER_VAT}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.CUSTOMER_DOY !== '' && templateData.CUSTOMER_DOY &&
                                    <tr>
                                      <td style={skinBoldStyle}>
                                        <span style={descriptionValues}>Δ.Ο.Υ.:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.CUSTOMER_DOY}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.CUSTOMER_ADDRESS !== '' && templateData.CUSTOMER_ADDRESS &&
                                    <tr>
                                      <td style={skinBoldStyle}>
                                        <span style={descriptionValues}>ADDRESS:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.CUSTOMER_ADDRESS}, </span>
                                        {templateData.CUSTOMER_CITY !== '' && templateData.CUSTOMER_CITY &&
                                            <span style={descriptionValues}>{templateData.CUSTOMER_CITY}, </span>
                                        }
                                        {templateData.CUSTOMER_ZIP !== '' && templateData.CUSTOMER_ZIP &&
                                            <span style={descriptionValues}>{templateData.CUSTOMER_ZIP}</span>
                                        }
                                      </td>
                                    </tr>
                                }
                                </tbody>
                              </table>
                            </div>
                          </td>
                          <td className="d-none" style={informationTdLeftStyle}>
                            <div style={informationLeftStyle} className="changeWidth">
                              <table style={{width: "100%"}}>
                                <tbody>
                                <tr>
                                  <td colSpan="2" style={sellerDetailsStyle}>
                                    <span>ΣΤΟΙΧΕΙΑ ΠΩΛΗΤΗ</span>
                                  </td>
                                </tr>
                                {
                                    templateData.SALER_NAME !== '' && templateData.SALER_NAME &&
                                    <tr>
                                      <td style={skinBoldStyle}>
                                        <span style={descriptionValues}>SALER NAME:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.SALER_NAME}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.SALER_COMPANY_NAME !== '' && templateData.SALER_COMPANY_NAME &&
                                    <tr>
                                      <td style={skinBoldStyle}>
                                        <span style={descriptionValues}>COMPANY NAME:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.SALER_COMPANY_NAME}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.SALER_EMAIL !== '' && templateData.SALER_EMAIL &&
                                    <tr>
                                      <td style={skinBoldStyle}>
                                        <span style={descriptionValues}>EMAIL:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.SALER_EMAIL}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.SALER_GEMH !== '' && templateData.SALER_GEMH &&
                                    <tr>
                                      <td style={skinBoldStyle}>
                                        <span style={descriptionValues}>ΓΕΜΗ:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.SALER_GEMH}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.SALER_VAT !== '' && templateData.SALER_VAT &&
                                    <tr>
                                      <td style={skinBoldStyle}>
                                        <span style={descriptionValues}>VAT Number:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.SALER_VAT}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.TAX_REPRESENTATIVE !== '' && templateData.TAX_REPRESENTATIVE &&
                                    <tr>
                                      <td style={skinBoldStyle}>
                                        <span style={descriptionValues}>TAX REPRESENTATIVE:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.TAX_REPRESENTATIVE}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.TAX_REPRESENTATIVE_VAT !== '' && templateData.TAX_REPRESENTATIVE_VAT &&
                                    <tr>
                                      <td style={skinBoldStyle}>
                                        <span style={descriptionValues}>TAX REPRESENTATIVE VAT Num.:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.TAX_REPRESENTATIVE_VAT}</span>
                                      </td>
                                    </tr>
                                }
                                </tbody>
                              </table>
                            </div>
                          </td>
                          <td style={informationTdLeftStyle} className="information_td_left">
                            <div style={informationLeftStyle} className="changeWidth">
                              <table style={{width: "100%"}}>
                                <tbody>
                                <tr>
                                  <td colSpan="2" style={customerInformationStyle}>
                                    <span>EXTRA DATA</span>
                                  </td>
                                </tr>
                                {templateData.LOCATION_NAME !== '' && templateData.LOCATION_NAME &&
                                    <tr>
                                      <td style={informationCustomerIdStyle2}>
                                        <span style={descriptionValues}>LOCATION NAME:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.LOCATION_NAME}</span>
                                      </td>
                                    </tr>}
                                {
                                    templateData.LOCATION_ADDRESS !== '' && templateData.LOCATION_ADDRESS &&
                                    <tr>
                                      <td style={informationCustomerIdStyle2}>
                                        <span style={descriptionValues}>DELIVERY ADDRESS:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.LOCATION_ADDRESS}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.LOCATION_CITY !== '' && templateData.LOCATION_CITY &&
                                    <tr>
                                      <td style={informationCustomerIdStyle2}>
                                        <span style={descriptionValues}>DELIVERY CITY:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.LOCATION_CITY}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.LOCATION_ZIP !== '' && templateData.LOCATION_ZIP &&
                                    <tr>
                                      <td style={informationCustomerIdStyle2}>
                                        <span style={descriptionValues}>DELIVERY POSTAL:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.LOCATION_ZIP}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.PAYMENT_METHOD !== '' && templateData.PAYMENT_METHOD &&
                                    <tr>
                                      <td style={informationCustomerIdStyle2}>
                                        <span style={descriptionValues}>PAYMENT METHOD:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.PAYMENT_METHOD}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.PURPOSE !== '' && templateData.PURPOSE &&
                                    <tr>
                                      <td style={informationCustomerIdStyle2}>
                                        <span style={descriptionValues}>SALE PURPOSE:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.PURPOSE}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.HIGHLIGHT !== '' && templateData.HIGHLIGHT &&
                                    <tr>
                                      <td style={informationCustomerIdStyle2}>
                                        <span style={descriptionValues}>HIGHLIGHT:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.HIGHLIGHT}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.CORRELATION !== '' && templateData.CORRELATION &&
                                    <tr>
                                      <td style={informationCustomerIdStyle2}>
                                        <span style={descriptionValues}>CORRELATION:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.CORRELATION}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.DISPATCH_TIME !== '' && templateData.DISPATCH_TIME &&
                                    <tr>
                                      <td style={informationCustomerIdStyle2}>
                                        <span style={descriptionValues}>DISPATCH TIME:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.DISPATCH_TIME}</span>
                                      </td>
                                    </tr>
                                }
                                {
                                    templateData.VEHICLE_NUMBER !== '' && templateData.VEHICLE_NUMBER &&
                                    <tr>
                                      <td style={informationCustomerIdStyle2}>
                                        <span style={descriptionValues}>VEHICLE NUMBER:</span>
                                      </td>
                                      <td style={infoValueStyle}>
                                        <span style={descriptionValues}>{templateData.VEHICLE_NUMBER}</span>
                                      </td>
                                    </tr>
                                }
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    {(templateData?.PAGE_TYPES && templateData?.PAGE_TYPES.length > 0
                        && templateData.PAGE_TYPES.find((el) => el.index === index)?.type === "PAYMENT_HISTORY" && !templateData.TRANSACTION_DETAILS) ? (
                        <React.Fragment>
                          <div style={{textAlign: "center", fontSize: "20px", marginTop: "5px", marginBottom: "5px", color: "white", backgroundColor: "#555", fontWeight: "bold"}}>PAYMENT HISTORY</div>
                          <div style={{minHeight: "500px", display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                            {templateData.PAYMENT_HISTORY[index].map((transDetails) => {
                              if (transDetails.providerSignature) {
                                return (
                                    <div style={{
                                      ...transactionDetailsBlock,
                                      width: "50%",
                                      maxWidth: "50%",
                                      flex: "50%",
                                      height: "305px",
                                    }}>
                                      {transDetails?.isEuronet === "true" && (
                                          <React.Fragment>
                                            <img style={{margin: "0", width: "20mm"}} className={"mb-1"}
                                                 src={epayLogoBase64} alt={"ePay Logo"}/>
                                            <br/>
                                            <span style={{fontSize: "12px"}}>EURONET MERCHANT SERVICES A.E. GEMH: 159277401000</span>
                                          </React.Fragment>
                                      )}
                                      {transDetails?.isEuronet === "viva" && (
                                          <React.Fragment>
                                            <img style={{margin: "0", width: "15mm"}} src={vivaLogoBase64} alt={"Viva Logo"}/>
                                            <br/>
                                          </React.Fragment>
                                      )}
                                      <table className={"transactionDetailsTable"}
                                             style={{width: "100%", tableLayout: "fixed"}}>
                                        <tbody>
                                        <tr>
                                          <td>&nbsp;</td>
                                          <td>{transDetails?.transactionDate}</td>
                                        </tr>
                                        <tr>
                                          <td>TID/MID:</td>
                                          <td>{transDetails?.terminalId} / {transDetails?.merchantId}</td>
                                        </tr>
                                        <tr>
                                          <td>ΑΡ.
                                            ΣΥΝ.: {transDetails?.transactionNumber}</td>
                                          <td>ΕΓΚΡΙΣΗ: {transDetails?.authorizationCode}</td>
                                        </tr>
                                        <tr>
                                          <td>RRN.:</td>
                                          <td>{transDetails?.rnn}</td>
                                        </tr>
                                        <tr>
                                          <td>ΜΕΣΩ:</td>
                                          <td>
                                            {transDetails?.isEuronet === "true" && `EPAY`}
                                            {transDetails?.isEuronet === "viva" && `VIVA`}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colSpan={2}>{transDetails?.cardType}</td>
                                        </tr>
                                        <tr>
                                          <td colSpan={2}>{transDetails?.aid}</td>
                                        </tr>
                                        <tr>
                                          <td colSpan={2}>{transDetails?.panCard} - {transDetails?.finalCode}</td>
                                        </tr>
                                        <tr>
                                          <td>ΠΟΣΟ:</td>
                                          <td>{transDetails?.amountTotalString} €</td>
                                        </tr>
                                        <tr>
                                          <td>ΦΙΛΟΔΩΡΗΜΑ:</td>
                                          <td>{transDetails?.tipAmountString} €</td>
                                        </tr>
                                        <tr>
                                          <td>ΥΠΟΓΡΑΦΗ ΠΑΡΟΧΟΥ:</td>
                                          <td style={{wordBreak: "break-all"}}>{transDetails?.providerSignature}</td>
                                        </tr>
                                        <tr>
                                          <td>ΜΟΝ. ΤΑΥΤ. ΠΛΗΡΩΜΗΣ:</td>
                                          <td>{transDetails?.providerTransactionId}</td>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                )
                              } else {
                                return (
                                    <div style={{
                                      ...transactionDetailsBlock,
                                      width: "50%",
                                      maxWidth: "50%",
                                      flex: "50%",
                                      height: "320px",
                                      maxHeight: "305px",
                                      fontSize: "16px"
                                    }}>
                                      <table className={"transactionDetailsTable"} style={{width: "100%", tableLayout: "fixed"}}>
                                        <tbody>
                                        <tr>
                                          <td>ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ</td>
                                          <td>{transDetails?.paymentTypeName}</td>
                                        </tr>
                                        <tr>
                                          <td>ΗΜΕΡΟΜΗΝΙΑ ΠΛΗΡΩΜΗΣ</td>
                                          <td>{transDetails?.paymentDate}</td>
                                        </tr>
                                        <tr>
                                          <td>ΠΟΣΟ</td>
                                          <td>{transDetails?.paymentAmount}</td>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                )
                              }
                            })}
                          </div>
                        </React.Fragment>
                    ) : (
                        <div style={index + 1 !== totalPagesA.length ? productContainerStyleLastPage : productContainerStyle}>
                          <table style={{width: "100%"}} ref={refHeight}>
                            <tbody>
                            <tr style={productTableHeadingStyle} key={Math.random()}>
                              <td style={quantityHeadTd}><p
                                  style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> CODE</p></td>
                              <td style={descriptionHeadTd}><p
                                  style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> DETAILS</p></td>
                              <td style={quantityHeadTd}><p
                                  style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> QTY</p></td>
                              <td style={quantityHeadTd}><p
                                  style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> UNIT PRICE</p></td>
                              <td style={vatPerHeadTdStyle}><p
                                  style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> VAT %</p></td>
                              <td style={quantityHeadTd}><p
                                  style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> DISCOUNT</p></td>
                              <td style={quantityHeadTd}><p
                                  style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> PRICE BEFORE VAT</p>
                              </td>
                              <td style={quantityHeadTd}><p
                                  style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> VAT VALUE</p></td>
                              <td style={quantityHeadTd}><p
                                  style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> OTHER TAXES</p></td>
                              <td style={priceHeadTdStyle}><p
                                  style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> TOTAL VALUE</p></td>
                            </tr>
                            {templateData.PRODUCT_DETAILS && templateData.PRODUCT_DETAILS[index]?.length > 0 && templateData.PRODUCT_DETAILS[index].map((product) =>
                                (
                                    <tr style={productsStyle} key={Math.random()}>
                                      <td style={productsStyleTdStyle}><span>{product.CODE}</span></td>
                                      <td style={productsStyleTdStyle}>
                                        <span>{product.PRODUCT_DESCRIPTION}</span>
                                        {templateData?.ENABLE_PEPPOL && (
                                            <div style={{fontSize: "12px"}}>
                                              <span>VAT Category Code: {Number(product.VAT_ID) === 7 ? "Z" : Number(product.VAT_ID) === 8 ? "E" : "S"}</span>
                                              <br/>
                                              <span>Charges: {Number(product.TAXES_VALUE).toFixed(2)}</span>
                                              {product.CPV_CODE && (
                                                  <React.Fragment>
                                                    <br/>
                                                    <span>CPV Code: {product.CPV_CODE}</span>
                                                  </React.Fragment>
                                              )}
                                            </div>
                                        )}
                                      </td>
                                      <td style={productsStyleTdStyle}>
                                        <span>{product.QTY}</span>
                                        {templateData?.ENABLE_PEPPOL && (
                                            <React.Fragment>
                                              {isEmpty(product.PEPPOL_MEASUREMENT_UNIT) ? (
                                                  <React.Fragment>
                                                    <br/>
                                                    <span style={{fontSize: "12px"}}><i>H87</i></span>
                                                  </React.Fragment>
                                              ) : (
                                                  <React.Fragment>
                                                    <br/>
                                                    <span style={{fontSize: "12px"}}><i>{product.PEPPOL_MEASUREMENT_UNIT}</i></span>
                                                  </React.Fragment>
                                              )}
                                            </React.Fragment>
                                        )}
                                      </td>
                                      <td style={productsStyleTdStyle}><span>{product.PRICE_QTY}</span></td>
                                      <td style={productsStyleTdStyle}><span>{product.VAT}{product?.DONT_SHOW_PERCENTAGE === true ? "" : "%"}</span></td>
                                      <td style={productsStyleTdStyle}><span>{product.DISCOUNT}</span></td>
                                      <td style={productsStyleTdStyle}><span>{product.PRICE_NOVAT}</span></td>
                                      <td style={productsStyleTdStyle}><span>{product.VAT_PRICE}</span></td>
                                      <td style={productsStyleTdStyle}><span>{product.OTHER_TAXES}</span></td>
                                      <td style={productsStyleTdStyle}><span>{product.TOTAL}</span></td>
                                    </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                    )}
                    <div style={footerContainerStyle}>
                      <table style={{width: "100%"}}>
                        <tbody>
                        <tr>
                          <td style={footerTableTdStyle}>
                            {(templateData?.ENABLE_PEPPOL) && (
                                <React.Fragment>
                                  <div style={{
                                    textAlign: "center",
                                    fontSize: "16",
                                    marginBottom: "5px",
                                    color: "white",
                                    backgroundColor: "#555",
                                    fontWeight: "bold"
                                  }}>B2G Specifications & Details
                                  </div>
                                  <div style={transactionDetailsBlock}>
                                    <table className={"transactionDetailsTable"} style={{width: "100%", tableLayout: "fixed"}}>
                                      <tbody>
                                      <tr>
                                        <td>Invoice Number</td>
                                        <td>{templateData.PEPPOL?.DOCUMENT_REFERENCE}</td>
                                      </tr>
                                      {templateData.PEPPOL?.PROJECT_REFERENCE && (
                                          <tr>
                                            <td>Project Reference</td>
                                            <td>{templateData.PEPPOL?.PROJECT_REFERENCE}</td>
                                          </tr>
                                      )}
                                      <tr>
                                        <td>Buyer Identifier</td>
                                        <td>{templateData.PEPPOL?.PARTY_IDENTIFICATION}</td>
                                      </tr>
                                      <tr>
                                        <td>Contract Reference</td>
                                        <td>{templateData.PEPPOL?.CONTRACT_DOCUMENT_REFERENCE}</td>
                                      </tr>
                                      <tr style={{borderTop: "1px solid gray"}}>
                                        <td>Invoice Type Code: {templateData.PEPPOL?.BT_3_CODE}</td>
                                        <td>Invoice Currency Code: {templateData.PEPPOL?.BT_5_CODE}</td>
                                      </tr>
                                      <tr>
                                        <td>Seller Country Code: {templateData.PEPPOL?.BT_40_CODE}</td>
                                        <td>Buyer Country Code: {templateData.PEPPOL?.BT_55_CODE}</td>
                                      </tr>
                                      <tr>
                                        <td colSpan={2}>Deliver to Country Code: {templateData.PEPPOL?.BT_80_CODE}</td>
                                      </tr>
                                      <tr>
                                        <td colSpan={2}>Specification Identifier: <i>{templateData.PEPPOL?.BT_24_CODE}</i></td>
                                      </tr>
                                      <tr>
                                        <td colSpan={2}>Buyer Electronic Address: {templateData.PEPPOL?.BT_49_CODE}</td>
                                      </tr>
                                      {(templateData.PEPPOL.BT_25_CODE) && (
                                          <tr>
                                            <td colSpan={2}>Preceding Invoice Number: {templateData.PEPPOL.BT_25_CODE}</td>
                                          </tr>
                                      )}
                                      </tbody>
                                    </table>
                                  </div>
                                </React.Fragment>
                            )}
                            {((templateData?.TRANSACTION_DETAILS && index + 1 === totalPagesA.length) && !templateData?.ENABLE_PEPPOL && (
                                <React.Fragment>
                                  <div style={{
                                    textAlign: "center",
                                    fontSize: "16",
                                    marginBottom: "5px",
                                    color: "white",
                                    backgroundColor: "#555",
                                    fontWeight: "bold"
                                  }}>ΙΣΤΟΡΙΚΟ ΠΛΗΡΩΜΩΝ
                                  </div>
                                  {(templateData?.TRANSACTION_DETAILS?.providerSignature) ? (
                                      <div style={transactionDetailsBlock}>
                                        {templateData.TRANSACTION_DETAILS?.isEuronet === "true" && (
                                            <React.Fragment>
                                              <img style={{margin: "0", width: "20mm"}} className={"mb-1"}
                                                   src={epayLogoBase64} alt={"ePay Logo"}/>
                                              <br/>
                                              <span style={{fontSize: "12px"}}>EURONET MERCHANT SERVICES A.E. GEMH: 159277401000</span>
                                            </React.Fragment>
                                        )}
                                        {templateData.TRANSACTION_DETAILS?.isEuronet === "viva" && (
                                            <React.Fragment>
                                              <img style={{margin: "0", width: "15mm"}} src={vivaLogoBase64} alt={"Viva Logo"}/>
                                              <br/>
                                            </React.Fragment>
                                        )}
                                        <table className={"transactionDetailsTable"}
                                               style={{width: "100%", tableLayout: "fixed", fontSize: "12px"}}>
                                          <tbody>
                                          <tr>
                                            <td>&nbsp;</td>
                                            <td>{templateData.TRANSACTION_DETAILS?.transactionDate}</td>
                                          </tr>
                                          <tr>
                                            <td>TID/MID:</td>
                                            <td>{templateData.TRANSACTION_DETAILS?.terminalId} / {templateData.TRANSACTION_DETAILS?.merchantId}</td>
                                          </tr>
                                          <tr>
                                            <td>ΑΡ.
                                              ΣΥΝ.: {templateData.TRANSACTION_DETAILS?.transactionNumber}</td>
                                            <td>ΕΓΚΡΙΣΗ: {templateData.TRANSACTION_DETAILS?.authorizationCode}</td>
                                          </tr>
                                          <tr>
                                            <td>RRN.:</td>
                                            <td>{templateData.TRANSACTION_DETAILS?.rnn}</td>
                                          </tr>
                                          <tr>
                                            <td>ΜΕΣΩ:</td>
                                            <td>
                                              {templateData.TRANSACTION_DETAILS?.isEuronet === "true" && `EPAY`}
                                              {templateData.TRANSACTION_DETAILS?.isEuronet === "viva" && `VIVA`}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colSpan={2}>{templateData.TRANSACTION_DETAILS?.cardType}</td>
                                          </tr>
                                          <tr>
                                            <td colSpan={2}>{templateData.TRANSACTION_DETAILS?.aid}</td>
                                          </tr>
                                          <tr>
                                            <td colSpan={2}>{templateData.TRANSACTION_DETAILS?.panCard} - {templateData.TRANSACTION_DETAILS?.finalCode}</td>
                                          </tr>
                                          <tr>
                                            <td>ΠΟΣΟ:</td>
                                            <td>{templateData.TRANSACTION_DETAILS?.amountTotalString} €</td>
                                          </tr>
                                          <tr>
                                            <td>ΦΙΛΟΔΩΡΗΜΑ:</td>
                                            <td>{templateData.TRANSACTION_DETAILS?.tipAmountString} €</td>
                                          </tr>
                                          <tr>
                                            <td>ΥΠΟΓΡΑΦΗ ΠΑΡΟΧΟΥ:</td>
                                            <td style={{wordBreak: "break-all"}}>{templateData.TRANSACTION_DETAILS?.providerSignature}</td>
                                          </tr>
                                          <tr>
                                            <td>ΜΟΝ. ΤΑΥΤ. ΠΛΗΡΩΜΗΣ:</td>
                                            <td>{templateData.TRANSACTION_DETAILS?.providerTransactionId}</td>
                                          </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                  ) : (
                                      <div style={transactionDetailsBlock}>
                                        <table className={"transactionDetailsTable"}
                                               style={{width: "100%", tableLayout: "fixed"}}>
                                          <tbody>
                                          <tr>
                                            <td>ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ</td>
                                            <td>{templateData.TRANSACTION_DETAILS?.paymentTypeName}</td>
                                          </tr>
                                          <tr>
                                            <td>ΗΜΕΡΟΜΗΝΙΑ ΠΛΗΡΩΜΗΣ</td>
                                            <td>{templateData.TRANSACTION_DETAILS?.paymentDate}</td>
                                          </tr>
                                          <tr>
                                            <td>ΠΟΣΟ</td>
                                            <td>{templateData.TRANSACTION_DETAILS?.paymentAmount}</td>
                                          </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                  )}
                                </React.Fragment>
                            ))}
                            <div style={perVatTotalsContainerStyle}>
                              <table style={{width: "100%"}}>
                                <tbody>
                                <tr>
                                  <th style={totalsPerVatThStyle}>VAT Percentage</th>
                                  <th style={totalsPerVatThStyle}>Net Value</th>
                                  <th style={totalsPerVatThStyle}>VAT Value</th>
                                  <th style={totalsPerVatThStyle}>Other taxes</th>
                                  <th style={totalsPerVatThStyle}>Total Value</th>
                                </tr>
                                {templateData.VAT_TABLE && index + 1 === totalPagesA.length && templateData.VAT_TABLE?.length > 0 && templateData.VAT_TABLE.map((taxes) => {
                                  return <tr key={Math.random()}>
                                    <td>{taxes.TAX_PERCENT} {taxes.TAX_CODE ? `(${taxes.TAX_CODE})` : null}
                                      {templateData.ENABLE_PEPPOL && (
                                          <span>
                                            {(parseFloat(taxes.TAX_PERCENT) === 0) ? taxes.TAX_CODE ? "(Z)" : "(E)" : "(S)"}
                                          </span>
                                      )}
                                    </td>
                                    <td>{taxes.NET_VALUE}</td>
                                    <td>{taxes.TAX_VALUE}</td>
                                    <td>{taxes.OTHER_TAXES}</td>
                                    <td>{taxes.TOTAL_VALUE}</td>
                                  </tr>
                                })}
                                </tbody>
                              </table>
                            </div>
                            <div style={contNotationStyle}>
                              {templateData.VATEXPCAT && templateData.VATEXPCAT?.length > 0 && templateData.VATEXPCAT.map((reason) => {
                                return (
                                    <div className="exception-block">
                                      <span style={skinBoldStyle}>VAT EXCEPTION:</span>
                                      <div className="cont_notation_inner">
                                        <span>{reason.vat} {reason.description} <br/></span>
                                      </div>
                                    </div>
                                )
                              })}
                              <div className="notes-block">
                                <span style={skinBoldStyle}>INVOICE NOTES:</span>
                                <div className="cont_notation_inner">
                                  <span dangerouslySetInnerHTML={{__html: String(templateData?.INVOICE_NOTES).replaceAll("\n", "<br/>")}}></span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td style={{...footerTableTdStyle, maxWidth: "200px"}}>
                            {(templateData?.ENABLE_PEPPOL) && (
                                <div style={{...transactionDetailsBlock, width: "200px", marginLeft: "auto", marginRight: "auto"}}>
                                  <table className={"transactionDetailsTable"} style={{width: "100%", tableLayout: "fixed"}}>
                                    <tbody>
                                    <tr>
                                      <td colSpan={2}><strong>Seller Name:</strong> {templateData.ISSUER_NAME}</td>
                                    </tr>
                                    <tr>
                                      <td colSpan={2}><strong>Seller Intern. VAT Number:</strong> {templateData.PEPPOL?.BT_31_CODE}</td>
                                    </tr>
                                    <tr>
                                      <td colSpan={2}><strong>Seller Local VAT Number:</strong> {templateData.ISSUER_VAT}</td>
                                    </tr>
                                    <tr>
                                      <td colSpan={2}><strong>Seller Electronic Address:</strong> {templateData.PEPPOL?.BT_34_CODE}</td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                            )}
                            {(templateData.OLD_BALANCE && templateData.NEW_BALANCE && !templateData.ENABLE_PEPPOL) &&
                                <div style={balanceContainer}>
                                  <div style={{margin: "auto"}}>Previous Balance</div>
                                  <div style={{margin: "auto", fontWeight: "normal"}}>{templateData.OLD_BALANCE}</div>
                                  <div style={{margin: "auto", marginTop: "10px"}}>New Balance</div>
                                  <div style={{margin: "auto", fontWeight: "normal"}}>{templateData.NEW_BALANCE}</div>
                                </div>
                            }
                            {(() => {
                              if (index + 1 === totalPagesA.length && templateData?.UID !== "" && templateData.MYDATADESTINATION === "provider") {
                                return (
                                    <div style={contSignsStyle}>
                                      <img src={templateData?.QR_CODE} alt="Qr code"/>
                                    </div>
                                )
                              } else if (index + 1 === totalPagesA.length && templateData?.UID !== "" && templateData.MYDATADESTINATION !== "provider" && templateData.MYDATAQRURL) {
                                return (
                                    <div style={contSignsStyle}>
                                      <img src={templateData?.QR_CODE} alt="Qr code"/>
                                    </div>
                                )
                              } else {
                                return (<div style={hideQrCodeStyle}></div>)
                              }
                            })()}
                          </td>
                          <td style={totalTdBlock}>
                            <div style={countTotalsContainerStyle}>
                              <span>TOTAL QTY: </span>
                              {index + 1 === totalPagesA.length && (
                                  <span
                                      style={countTotalProdsStyle}> {templateData.TOTAL_QTY ? templateData.TOTAL_QTY : '0'}</span>
                              )}
                            </div>
                            <div style={totalContainerStyle}>
                              <div style={totalContainerTotalsStyle}>
                                <table style={totalTdBlockTotalsTable}>
                                  <tbody>
                                  <tr>
                                    <td style={totalContainerHeadingStyle}>
                                      <p style={totalContainerHeadingPStyle}>PRICE BEFORE DISCOUNT</p>
                                    </td>
                                    <td style={totalBoxTd2}>
                                      <div style={totalsValueStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_WITHOUT_DISCOUNT ? templateData.TOTAL_WITHOUT_DISCOUNT : ""}</div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={totalContainerHeadingStyle}>
                                      <p style={totalContainerHeadingPStyle}>DISCOUNT</p>
                                    </td>
                                    <td style={totalBoxTd2}>
                                      <div style={totalsValueStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_DISCOUNT ? templateData.TOTAL_DISCOUNT : ""}</div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={totalContainerHeadingStyle}>
                                      <p style={totalContainerHeadingPStyle}>NET VALUE</p>
                                    </td>
                                    <td style={totalBoxTd2}>
                                      <div style={totalsValueStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_NOVAT ? templateData.TOTAL_NOVAT : ""}</div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={totalContainerHeadingStyle}>
                                      <p style={totalContainerHeadingPStyle}>VAT VALUE</p>
                                    </td>
                                    <td style={totalBoxTd2}>
                                      <div style={totalsValueStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_VAT ? templateData.TOTAL_VAT : ""}</div>
                                    </td>
                                  </tr>
                                  {(templateData.TOTAL_DEDUCTIONS && (parseFloat(templateData.TOTAL_DEDUCTIONS) > 0 || templateData.TOTAL_DEDUCTIONS !== "0,00 €")) && (
                                      <tr>
                                        <td style={totalContainerHeadingStyle}>
                                          <p style={totalContainerHeadingPStyle}>DEDUCTIONS</p>
                                        </td>
                                        <td style={totalBoxTd2}>
                                          <div style={totalsValueStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_DEDUCTIONS ? templateData.TOTAL_DEDUCTIONS : ""}</div>
                                        </td>
                                      </tr>
                                  )}
                                  {(templateData.ENABLE_PEPPOL || (!templateData.ENABLE_PEPPOL && templateData.TOTAL_TAXES_AMOUNT > 0)) && (
                                      <tr>
                                        <td style={totalContainerHeadingStyle}>
                                          <p style={totalContainerHeadingPStyle}>TOTAL CHARGES</p>
                                        </td>
                                        <td style={totalBoxTd2}>
                                          <div style={totalsValueStyle}>{index + 1 === totalPagesA.length && !isEmpty(templateData.TOTAL_TAXES_AMOUNT) ? templateData.TOTAL_TAXES_AMOUNT : ""}</div>
                                        </td>
                                      </tr>
                                  )}
                                  <tr>
                                    <td style={totalContainerHeadingStyle}>
                                      <p style={totalContainerHeadingPStyle}>TOTAL VALUE</p>
                                    </td>
                                    <td style={totalBoxTd2}>
                                      <div style={totalsValueStyle}>{index + 1 === totalPagesA.length && templateData.PRICE_WITHVAT ? templateData.PRICE_WITHVAT : ""}</div>
                                    </td>
                                  </tr>
                                  {templateData.TOTAL_WITHHOLD && (
                                      <tr>
                                        <td style={totalContainerHeadingStyle}>
                                          <p style={totalContainerHeadingPStyle}>TOTAL WITHHOLDING TAXES</p>
                                        </td>
                                        <td style={totalBoxTd2}>
                                          <div style={priceWithVatStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_WITHHOLD ? templateData.TOTAL_WITHHOLD : ""}</div>
                                        </td>
                                      </tr>
                                  )}
                                  <tr style={blankRowBorderedTdStyle}>
                                    <td style={totalContainerHeadingStyle}>&nbsp;</td>
                                  </tr>
                                  <tr>
                                    <td style={totalContainerHeadingStyle}>
                                      <p style={finalPricePStyle}>PAYMENT AMOUNT</p>
                                    </td>
                                    <td style={totalPaymentStyleTd}>
                                      <p style={totalPaymentPStyle}>
                                        <span
                                            style={totalPaymentStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_PRICE ? templateData.TOTAL_PRICE : ""}</span>
                                      </p>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      {((templateData.TRANSMISSION_FAILURE && (templateData.TRANSMISSION_FAILURE === "1" || templateData.TRANSMISSION_FAILURE === "2") && (templateData.MYDATADESTINATION === "Provider" || templateData.MYDATADESTINATION === "provider")) || (templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                          <div>
                            <span style={senderSignStyle}>www.primer.gr/search-invoice Provided by Primer Software P.C.</span>
                            <br/>
                            <span>Άδεια ΥΠΑΗΕΣ: 2021_01_107Primer Software ΙΚΕ_001_ Primer MyData_V1_21012021</span>
                          </div>
                      }
                      {index + 1 === totalPagesA.length && (
                          <div>
                            {((templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "1" && (templateData.MYDATADESTINATION === "Provider" || templateData.MYDATADESTINATION === "provider")) || (templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "1" && templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                                <span>Απώλεια Διασύνδεσης Οντότητας - Παρόχου - Transmission Failure_1<br/></span>}
                            {((templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "2" && (templateData.MYDATADESTINATION === "Provider" || templateData.MYDATADESTINATION === "provider")) || (templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "2" && templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                                <span>Απώλεια Διασύνδεσης Παρόχου - ΑΑΔΕ - Transmission Failure_2<br/></span>}
                            {(templateData.TRANSMISSION_FAILURE === "3" && templateData.MYDATADESTINATION === "MyData") && (
                                <span>Απώλεια Διασύνδεσης ERP – Α.Α.Δ.Ε. - Transmission Failure_3<br/></span>
                            )}
                            {templateData.UID && templateData.UID !== "" && templateData.AUTHCODE !== "" &&
                                <span> UID: {templateData.UID}</span>}
                            {templateData.MARK && templateData.MARK !== "" && <span> Μ.ΑΡ.Κ.: {templateData.MARK}</span>}
                            {templateData.AUTHCODE && templateData.AUTHCODE !== "" &&
                                <span> <br/>AUTHENTICATION CODE: {templateData.AUTHCODE}</span>}
                          </div>
                      )}
                    </div>
                  </div>
                </div>
                {(index + 1 !== totalPagesA.length && totalPagesA.length !== 1) && templateData.PRINT_SIZE === "A4" &&
                    <div className={(index + 1 !== totalPagesA.length && totalPagesA.length !== 1) ? "page-break" : null}></div>
                }
              </>
          ))}
        </div>
      </React.Fragment>
  )
})

export default SalesPrintTemplateEnglish1;
