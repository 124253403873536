import {createSlice} from "@reduxjs/toolkit";

const newVisitDefaultValue = {
    client: "",
    clientName: "",
    cause: "",
    notes: "",
}

const activeVisitDefaultValue = {
    client: "",
    clientName: "",
    visitCause: "",
    visitNotes: "",
}

const newPrescriptionDefaultValue = {
    date: new Date(),
    type: "normal", // normal, ifet, gal
    repeat: "1",
    repeatPeriod: "",
    monthlyTreatment: false,
    bimonthlyTreatment: false,
    chronicIllness: false,
    zeroParticipationReason: "",
    oneDose: false,
    prescriptionWithCommercialName: false,
    prescriptionWithCommercialNameCause: "",
    prescriptionWithCommercialNameNotes: "",
    medicalOpinion: false,
    diagnoses: [],
    drugs: [],
}

const newTreatmentProtocolDefaultValue = {
    treatmentProtocolId: "",
    selectedICD10s: [],
    treatmentStep: "",
    treatmentStepNotes: "",
}

const newReferralDefaultValue = {
    type: "1",
    examType: "", // examinationGroupId
    cause: "",
    notes: "",
    zeroParticipationReason: "",
    zeroParticipationReasonNeedsDecision: false,
    needsDecisionDate: "",
    needsDecisionNumber: "",
    diagnoses: [],
    exams: [],
    overrides: [],
}

const editVisitDefaultValue = {
    id: "0",
    prescriptions: [],
    referrals: [],
}

const editPrescriptionDefaultValue = {
    barcode: "0",
    diagnoses: [],
    drugs: [],
}

const editReferralDefaultValue = {
    barcode: "0",
}

const initialState = {
    companyHasPrescription: false,
    newVisit: newVisitDefaultValue,
    newVisitSelectedClient: {},

    editVisit: editVisitDefaultValue,
    editVisitLoading: false,
    editVisitReload: false,

    newPrescription: newPrescriptionDefaultValue,
    newTreatmentProtocol: newTreatmentProtocolDefaultValue,
    newReferral: newReferralDefaultValue,

    activeVisit: activeVisitDefaultValue,
    activeVisitFirstLoad: true,
    activeVisitPrescriptions: [],
    activeVisitTreatmentProtocols: [],
    activeVisitReferrals: [],

    editPrescription: editPrescriptionDefaultValue,
    editPrescriptionLoading: false,
    editPrescriptionReload: false,

    editReferral: editReferralDefaultValue,
    editReferralLoading: false,
    editReferralReload: false,

    doctorTreatmentProtocols: [],

    searchVisits: [],
    totalItemsV: 0,
    perPageV: 0,

    searchPrescriptions: [],
    totalItemsP: 0,
    perPageP: 0,

    searchReferrals: [],
    totalItemsR: 0,
    perPageR: 0,
}

export const PrescriptionSlice = createSlice({
    name: "prescription",
    initialState,
    reducers: {
        setCompanyHasPrescription: (state, {payload}) => {
            state.companyHasPrescription = payload;
        },
        // Visits
        setNewVisit: (state, {payload}) => {
            state.newVisit = payload;
        },
        setNewVisitSelectedClient: (state, {payload}) => {
            state.newVisitSelectedClient = payload;
        },
        resetNewVisit: (state) => {
            state.newVisit = newVisitDefaultValue;
            state.newVisitSelectedClient = {};
        },

        setActiveVisit: (state, {payload}) => {
            state.activeVisit = payload;
        },
        setActiveVisitFirstLoad: (state, {payload}) => {
            state.activeVisitFirstLoad = payload;
        },
        setActiveVisitPrescriptions: (state, {payload}) => {
            state.activeVisitPrescriptions = payload;
        },
        setActiveVisitTreatmentProtocols: (state, {payload}) => {
            state.activeVisitTreatmentProtocols = payload;
        },
        setActiveVisitReferrals: (state, {payload}) => {
            state.activeVisitReferrals = payload;
        },
        resetActiveVisit: (state) => {
            state.activeVisit = activeVisitDefaultValue;
            state.activeVisitPrescriptions = [];
            state.activeVisitTreatmentProtocols = [];
        },

        setEditVisit: (state, {payload}) => {
            state.editVisit = payload;
        },
        setEditVisitLoading: (state, {payload}) => {
            state.editVisitLoading = payload;
        },
        setEditVisitReload: (state, {payload}) => {
            state.editVisitReload = payload;
        },

        // Prescriptions
        setNewPrescription: (state, {payload}) => {
            state.newPrescription = payload;
        },
        resetNewPrescription: (state) => {
            state.newPrescription = newPrescriptionDefaultValue;
        },
        setEditPrescription: (state, {payload}) => {
            state.editPrescription = payload;
        },
        setEditPrescriptionLoading: (state, {payload}) => {
            state.editPrescriptionLoading = payload;
        },
        setEditPrescriptionReload: (state, {payload}) => {
            state.editPrescriptionReload = payload;
        },
        resetEditPrescription: (state) => {
            state.editPrescription = editPrescriptionDefaultValue;
        },

        // Treatment Protocols
        setNewTreatmentProtocol: (state, {payload}) => {
            state.newTreatmentProtocol = payload;
        },
        resetNewTreatmentProtocol: (state) => {
            state.newTreatmentProtocol = newTreatmentProtocolDefaultValue;
        },

        // Referrals
        setNewReferral: (state, {payload}) => {
            state.newReferral = payload;
        },
        resetNewReferral: (state) => {
            state.newReferral = newReferralDefaultValue;
        },
        setEditReferral: (state, {payload}) => {
            state.editReferral = payload;
        },
        setEditReferralLoading: (state, {payload}) => {
            state.editReferralLoading = payload;
        },
        setEditReferralReload: (state, {payload}) => {
            state.editReferralReload = payload;
        },
        resetEditReferral: (state) => {
            state.editReferral = editReferralDefaultValue;
        },

        // Doctor Master Data
        setDoctorTreatmentProtocols: (state, {payload}) => {
            state.doctorTreatmentProtocols = payload;
        },

        // Searches
        setSearchVisits: (state, {payload}) => {
            state.searchVisits = payload;
        },
        setSearchVisitsTotalItems: (state, {payload}) => {
            state.totalItemsV = payload;
        },
        setSearchVisitsPerPage: (state, {payload}) => {
            state.perPageV = payload;
        },

        setSearchPrescriptions: (state, {payload}) => {
            state.searchPrescriptions = payload;
        },
        setSearchPrescriptionsTotalItems: (state, {payload}) => {
            state.totalItemsP = payload;
        },
        setSearchPrescriptionsPerPage: (state, {payload}) => {
            state.perPageP = payload;
        },

        setSearchReferrals: (state, {payload}) => {
            state.searchReferrals = payload;
        },
        setSearchReferralsTotalItems: (state, {payload}) => {
            state.totalItemsR = payload;
        },
        setSearchReferralsPerPage: (state, {payload}) => {
            state.perPageR = payload;
        },
    }
})

export const {
    setCompanyHasPrescription,
    setNewVisit,
    setNewVisitSelectedClient,
    resetNewVisit,

    setActiveVisit,
    setActiveVisitFirstLoad,
    setActiveVisitPrescriptions,
    setActiveVisitTreatmentProtocols,
    setActiveVisitReferrals,
    resetActiveVisit,

    setEditVisit,
    setEditVisitLoading,
    setEditVisitReload,

    setNewPrescription,
    resetNewPrescription,
    setEditPrescription,
    setEditPrescriptionLoading,
    setEditPrescriptionReload,
    resetEditPrescription,

    setNewTreatmentProtocol,
    resetNewTreatmentProtocol,

    setNewReferral,
    resetNewReferral,
    setEditReferral,
    setEditReferralLoading,
    setEditReferralReload,
    resetEditReferral,

    setDoctorTreatmentProtocols,

    setSearchVisits,
    setSearchVisitsTotalItems,
    setSearchVisitsPerPage,

    setSearchPrescriptions,
    setSearchPrescriptionsTotalItems,
    setSearchPrescriptionsPerPage,

    setSearchReferrals,
    setSearchReferralsTotalItems,
    setSearchReferralsPerPage,
} = PrescriptionSlice.actions;
