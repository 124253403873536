import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import Input from "../../common/Input";
import Dropdown from "../Dropdown";
import {getOtherCorrelatedEntitiesTypes} from "../../../_data/mydata/otherCorrelatedEntities";
import {getCountryOptions, validateAFM} from "../../../_helpers/commonFunctions";
import {toast} from "react-toastify";
import {setNewSales} from "../../../_reducers/DataPanel/Sales/salesSlice";

const OtherCorrelatedEntitiesModal = (props) => {
    const dispatch = useDispatch();
    const SALES = useSelector(state => state.SALES);
    const [entityData, setEntityData] = useState(props.entity);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(props.entity) {
            console.log(props.entity)
            setEntityData(props.entity);
            setShow(true);
        }
    }, [props.entity]);

    const closeModal = () => {
        props.setSelectedEntity(null);
        setShow(false);
    }

    const deleteEntity = () => {
        let cloneEntities = [...SALES.newSales.otherCorrelatedEntities];
        const findIndex = cloneEntities.findIndex((entity) => entity.id === entityData?.id);
        if(findIndex !== -1) {
            cloneEntities.splice(findIndex, 1);
            cloneEntities = cloneEntities.map((item, index) => ({...item, id: index + 1}));
            toast.success("Επιτυχής Διαγραφή Οντότητας!");
            dispatch(setNewSales({...SALES.newSales, otherCorrelatedEntities: cloneEntities}));
            closeModal();
        }
    }

    const handleOnChange = (e, type, name) => {
        if(type === "dropdown") {
            setEntityData({...entityData, [name]: e.value});
        } else if (type === "input") {
            setEntityData({...entityData, [name]: e.target.value});
        }
    }

    const saveClicked = () => {
        //validate
        if(!entityData?.type || !entityData?.country || !entityData?.name || !entityData?.vatNumber) {
            toast.error("Παρακαλώ συμπληρώστε όλα τα απαιτούμενα πεδία!");
        } else if(entityData?.country === "GR" && validateAFM(entityData.vatNumber) === false) {
            toast.error("Το Α.Φ.Μ. που συμπληρώσατε δεν είναι σωστό.");
        } else if((entityData?.address || entityData?.city || entityData?.postalCode) && (!entityData?.address || !entityData?.city || !entityData?.postalCode)) {
            toast.error("Παρακαλώ συμπληρώστε όλα τα πεδία της διεύθυνσης!");
        } else  {
            let cloneEntities = [...SALES.newSales.otherCorrelatedEntities];
            const findIndex = cloneEntities.findIndex((entity) => entity.id === entityData?.id);
            if(findIndex !== -1) {
                cloneEntities[findIndex] = entityData;
                toast.success("Επιτυχής Ενημέρωση Οντότητας!");
            } else {
                cloneEntities.push(entityData);
                toast.success("Επιτυχής Προσθήκη Οντότητας!");
            }
            dispatch(setNewSales({...SALES.newSales, otherCorrelatedEntities: cloneEntities}));
            closeModal();
        }
    }

    return (
            <Modal backdrop={"static"} show={show} onHide={() => closeModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Συσχετιζόμενη Οντότητα</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Dropdown
                        name={"type"}
                        label={"Τύπος Οντότητας"}
                        options={getOtherCorrelatedEntitiesTypes}
                        required={true}
                        key={Math.random()}
                        defaultValue={getOtherCorrelatedEntitiesTypes.find((el) => String(el.value) === String(entityData?.type))}
                        onChange={(e) => handleOnChange(e, "dropdown", "type")}
                    />
                    <Row>
                        <Col md={6}>
                            <Dropdown
                                name={"country"}
                                label={"Χώρα"}
                                options={getCountryOptions('all')}
                                required={true}
                                key={Math.random()}
                                defaultValue={getCountryOptions('all').find((el) => String(el.value) === String(entityData?.country))}
                                onChange={(e) => handleOnChange(e, "dropdown", "country")}
                            />
                        </Col>
                        <Col md={6}>
                            <Input
                                name={"vatNumber"}
                                label={"Α.Φ.Μ."}
                                required={true}
                                value={entityData?.vatNumber}
                                onChange={(e) => handleOnChange(e, "input", "vatNumber")}
                            />
                        </Col>
                    </Row>
                    <Input
                        name={"name"}
                        required={true}
                        label={"Επωνυμία Οντότητας"}
                        className={"mb-2"}
                        value={entityData?.name}
                        onChange={(e) => handleOnChange(e, "input", "name")}
                    />
                    <Input
                        name={"address"}
                        label={"Διεύθυνση"}
                        className={"mb-2"}
                        value={entityData?.address}
                        onChange={(e) => handleOnChange(e, "input", "address")}
                    />
                    <Row>
                        <Col md={6}>
                            <Input
                                name={"city"}
                                label={"Πόλη"}
                                className={"mb-2"}
                                value={entityData?.city}
                                onChange={(e) => handleOnChange(e, "input", "city")}
                            />
                        </Col>
                        <Col md={6}>
                            <Input
                                name={"postalCode"}
                                label={"Ταχυδρομικός Κώδικας"}
                                className={"mb-2"}
                                value={entityData?.postalCode}
                                onChange={(e) => handleOnChange(e, "input", "postalCode")}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {props?.entity?.type !== "new" ? (
                        <Button variant="outline-primary" onClick={() => deleteEntity()}>Διαγραφή</Button>
                    ) : (
                        <Button variant="outline-primary" onClick={() => closeModal()}>Κλείσιμο</Button>
                    )}
                    <Button variant="primary" onClick={() => saveClicked()}>Αποθήκευση</Button>
                </Modal.Footer>
            </Modal>
    )
}

export default OtherCorrelatedEntitiesModal;
