// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.employeeHover {

}

.employeeHover:hover {
    background-color: #dedfe0;
}

.employeeHoverText {
    display: none;
}

.employeeHover:hover .employeeHoverText {
    display: block;
}
`, "",{"version":3,"sources":["webpack://./src/_components/layouts/data-panel/Payroll/Components/otherStyles.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".employeeHover {\r\n\r\n}\r\n\r\n.employeeHover:hover {\r\n    background-color: #dedfe0;\r\n}\r\n\r\n.employeeHoverText {\r\n    display: none;\r\n}\r\n\r\n.employeeHover:hover .employeeHoverText {\r\n    display: block;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
