import React, {useEffect, useState} from "react";
import SearchTable from "../../../primer/Search-Table";
import {getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {fetchAdvancedTypes} from "../../../../_apis/api";
import {classicStyleBelowNavbar} from "../Statics";

function SearchProductMovements() {
    const dispatch = useDispatch();
    const [t, i18n] = useTranslation("common");
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const [firstLoad, setFirstLoad] = useState(true);
    const company = useSelector((state) => state.COMPANY_DATA.company);

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            dispatch(fetchAdvancedTypes({company: company.id}))
        }
    }, [firstLoad]);

    const getDocumentTypes = () => {
        const docTypes = [...SETTINGS.settings.advancedTypes.filter((el) => el.section === "Product Movements")];
        let advOptions = [];
        for (let doc of docTypes) {
            advOptions.push({value: doc._id, label: doc.name})
        }
        return advOptions;
    }

    const searchProductsColumns = [
        {
            name: t('ProductsMovementsSearch.table.date'),
            field: "date",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ProductsMovementsSearch.table.documentType'),
            field: "documentTypeName",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ProductsMovementsSearch.table.number'),
            field: "number",
            editable: false,
            hiddenColumn: true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ProductsMovementsSearch.table.reason'),
            field: "reason",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ProductsMovementsSearch.table.totalQuantity'),
            field: "totalQuantity",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ProductsMovementsSearch.table.totalNetValue'),
            field: "totalNetValue",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t('ProductsMovementsSearch.table.totalVatValue'),
            field: "totalVatValue",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t('ProductsMovementsSearch.table.totalAmount'),
            field: "totalAmount",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        }
    ]
    const searchProductMovementsFilters = {
        filters: [
            {
                name: "documentType",
                label: t('SalesSearch.table2.documentType'),
                type: "multiselect",
                options: getDocumentTypes(),
            },
            {
                name: "numberFrom",
                label: t('General.numberFrom'),
                type: "number",
            },
            {
                name: "numberTo",
                label: t('General.numberTo'),
                type: "number",
            },
        ]
    }

    useEffect(() => {
        setTableColumns(searchProductsColumns.slice());
    }, [i18n.language])
    const [tableColumns, setTableColumns] = useState(searchProductsColumns.slice())
    // It will hide or show the table columns for searchtable.
    const handleColumnShowHide = (field) => {
        let tempTc = tableColumns.slice();
        let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
        const index = tempTc.findIndex((item) => field === item.field);
        if (object.length > 0) {
            tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
            setTableColumns(tempTc);
        }
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName="Search Product Movements"
                key={"Search Product Movements"}
                hideYearFilter={false}
                columns={tableColumns}
                allowToggleColumns={true}
                allowPagination={true}
                toggleColumns={handleColumnShowHide}
                showDummyData={false}
                data={[]}
                filtersConfig={searchProductMovementsFilters}
            />
        </div>
    )
}

export default SearchProductMovements;
