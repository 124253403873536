import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {Col, Row, Spinner} from "react-bootstrap";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import {
    resetNewVisit,
    setNewVisit,
    setNewVisitSelectedClient
} from "../../../../_reducers/DataPanel/Prescription/prescriptionSlice";
import Button from "react-bootstrap/Button";
import Input from "../../../common/Input";
import {getSexOptions} from "../../../../_helpers/commonFunctions";
import TextArea from "../../../common/TextArea";
import {setSaveNewVisit} from "../../../../_reducers/activitySlice";
import {checkForActiveVisit} from "../../../../_apis/api";
import moment from "moment";
import "./styles.css";
import NewClientModal from "../Clients/new-client-modal";
import BiometricsModal from "./Components/biometricsModal";
import {fetchPatientDetails} from "../Clients/clientHelpers";
import Modal from "react-bootstrap/Modal";

const VisitNew = () => {
    const dispatch = useDispatch();
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const newVisit = useSelector((state) => state.PRESCRIPTION.newVisit);
    const newVisitSelectedClient = useSelector((state) => state.PRESCRIPTION.newVisitSelectedClient);
    const [patientsList, setPatientsList] = useState([]);

    const [showBiometrics, setShowBiometrics] = useState(false);
    const [loadingCheck, setLoadingCheck] = useState(false);

    const [patientNotifications, setPatientNotifications] = useState([]);
    const [patientNotificationsModal, setPatientNotificationsModal] = useState(false);

    const patientDropdownColumns = [
        {
            label: "Ονοματεπώνυμο",
            name: "name",
        },
        {
            label: "AMKA",
            name: "amka",
        },
    ]

    useEffect(() => {
        loadPatientsList(null, setPatientsList);
        dispatch(checkForActiveVisit(company, TABS_DATA, setLoadingCheck));
    }, [])

    const loadPatientsList = (keyword, setData) => {
        let reqData = {
            company: company.id,
        }
        if (keyword) reqData.keyword = keyword;
        axios.get(`${process.env.REACT_APP_API_URL2}/prescription/list-patients`, {
            headers: { "Content-Type": "application/json" },
            params: reqData
        }).then((res) => {
            if (res.data.status === "200") {
                if (typeof setData === "function") setData(res.data.data);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleOnChange = (e, type, name) => {
        if (!ACTIVITY.saveNewVisit) dispatch(setSaveNewVisit(true));

        if (type === "acdd") {
            if (name === "clientName") {
                dispatch(setNewVisit({...newVisit, client: e.row._id, clientName: e.row.name}));
                dispatch(setNewVisitSelectedClient(e.row));
                fetchPatientDetails(e.row.amka, company.id).then((patientData) => {
                    let arr = [];
                    if (patientData.success) {
                        if (patientData.data.message) arr.push(patientData.data.message);
                        if (patientData.data.covidVaccineMessage) arr.push(patientData.data.covidVaccineMessage);
                    }
                    if (arr.length > 0) setPatientNotificationsModal(true);
                    setPatientNotifications(arr);
                })
            }
        } else if (type === "textarea") {
            dispatch(setNewVisit({...newVisit, [name] : e.target.value}));
        }
    }

    const handleResetNewVisit = () => {
        dispatch(resetNewVisit());
    }

    const hookNewPatient = (data) => {
        dispatch(setNewVisit({...newVisit, client: data._id, clientName: data.name}));
        dispatch(setNewVisitSelectedClient(data));
    }

    const linkify = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.split(urlRegex).map((part, index) =>
            urlRegex.test(part) ? (
                <span><br/><a key={index} href={part} target="_blank" rel="noopener noreferrer">
                    {part}
                </a></span>
            ) : (
                part
            )
        )
    }

    const patientSelected = String(newVisit.client).length === 24;

    return (
        <React.Fragment>
            {loadingCheck ? (
                <React.Fragment>
                    <Row className={"mb-3"}>
                        <Col md={12} className={"d-flex justify-content-center"}>
                            Παρακαλώ περιμένετε... <Spinner size={"sm"} className={"ml-2"} animation={"border"} variant={"dark"} />
                        </Col>
                    </Row>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Row className={"mb-3"}>
                        <Col md={5}>
                            <Row>
                                <Col md={11}>
                                    <AdvancedCombobox
                                        name={"clientName"}
                                        className={"w-100 ac-select-dropdown"}
                                        searchableDropdownEnable={false}
                                        defaultValue={`${newVisit.clientName}${newVisitSelectedClient.amka ? ` (${newVisitSelectedClient.amka})` : ""}`}
                                        label={"Ασθενής"}
                                        data={patientsList}
                                        onChange={(e) => handleOnChange(e, "acdd", "clientName")}
                                        key={newVisit.client}
                                        onChangeSearch={(keyword, setData) => loadPatientsList(keyword, setData)}
                                        excludeColumns={[]}
                                        columns={patientDropdownColumns}
                                        editEnable={false}
                                        deleteEnable={false}
                                        searchTableEnable={false}
                                        showTableOptionOnSelect={true}
                                        placeholder={"Πληκτρολογίστε και επιλέξτε ασθενή"}
                                        required={true}
                                        showInsertAddButton={true}
                                        hideSaveButton={true}
                                        hideInsertNewRow={true}
                                        inputFieldClasses="form-control"
                                        isPayrollCombobox={true}
                                        disabled={patientSelected}
                                    />
                                </Col>
                                <Col md={1}>
                                    {patientSelected ? (
                                        <Button title="Καθαρισμός πεδίου ασθενή" size={"sm"} onClick={() => handleResetNewVisit()} style={{marginTop: "28px"}}>Καθαρισμός</Button>
                                    ) : (
                                        <div style={{paddingTop: "23px"}}><NewClientModal fromPrescription={true} successCallback={(data) => hookNewPatient(data)} /></div>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {Object.keys(newVisitSelectedClient).length > 0 && (
                        <React.Fragment>
                            <div className={"mb-3 borderedContainer"}>
                                <h5 style={{fontWeight: "600"}}>Πληροφορίες Ασθενή</h5>
                                <Row className={"mb-2"}>
                                    <Col md={3}>
                                        <Input
                                            label={"Ημερομηνία Γέννησης"}
                                            value={moment(newVisitSelectedClient.birthDate, "YYYYMMDD").format("DD/MM/YYYY")}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Input
                                            label={"Οδός/Αριθμός"}
                                            value={newVisitSelectedClient.address}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Input
                                            label={"Πόλη"}
                                            value={newVisitSelectedClient.city}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Input
                                            label={"Ταχ. Κώδικας"}
                                            value={newVisitSelectedClient.postalCode}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-2"}>
                                    <Col md={3}>
                                        <Input
                                            label={"Τηλέφωνο"}
                                            value={newVisitSelectedClient.phone}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Input
                                            label={"Φύλο"}
                                            value={getSexOptions().find((el) => el.value === newVisitSelectedClient.sex)?.label}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Button size={"sm"} onClick={() => setShowBiometrics(true)}>Βιομετρικά</Button>
                                    </Col>
                                </Row>
                            </div>

                            <div className={"mb-3 borderedContainer"}>
                                <h5 style={{fontWeight: "600"}}>Πληροφορίες Επίσκεψης</h5>
                                <Row>
                                    <Col md={6}>
                                        <TextArea
                                            name={"cause"}
                                            label={"Λόγος Επίσκεψης"}
                                            rows={4}
                                            required={true}
                                            limit={2000}
                                            onChange={(e) => handleOnChange(e, "textarea", "cause")}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextArea
                                            name={"notes"}
                                            label={"Σημειώσεις"}
                                            rows={4}
                                            limit={2000}
                                            onChange={(e) => handleOnChange(e, "textarea", "notes")}
                                        />
                                    </Col>
                                </Row>
                            </div>

                            <BiometricsModal
                                show={showBiometrics}
                                setShow={setShowBiometrics}
                                amka={newVisitSelectedClient.amka}
                            />

                            <Modal show={patientNotificationsModal} onHide={() => setPatientNotificationsModal(false)} dialogClassName={"modal42PercentWidth"}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Ενημερώσεις σχετικά με τον ασθενή</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {patientNotifications.length > 0 && patientNotifications.map((msg) => (
                                        <div className={"borderedContainer mt-2"} style={{wordWrap: "break-word"}}>
                                            {linkify(msg)}
                                        </div>
                                    ))}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={() => setPatientNotificationsModal(false)}>
                                        Εντάξει
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default VisitNew
