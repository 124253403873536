import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import Input from "../../../../common/Input";
import {Badge, ButtonGroup, Dropdown, DropdownButton, Spinner} from "react-bootstrap";
import moment from "moment";

const BiometricsModal = ({show, setShow, amka}) => {
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const [loading, setLoading] = useState(false);
    const [biometrics, setBiometrics] = useState([]);

    const defaultBiometrics = [
        {
            "biometricsPool": {
                "id": "WEIGHT",
                "description": "Βάρος",
                "mesUnit": "Kg",
                "datatype": "NUM"
            },
            "bioValue": "",
            "dateInserted": ""
        },
        {
            "biometricsPool": {
                "id": "HEIGHT",
                "description": "Ύψος",
                "mesUnit": "cm",
                "datatype": "NUM"
            },
            "bioValue": "",
            "dateInserted": ""
        },
        {
            "biometricsPool": {
                "id": "HEADPERIM",
                "description": "Περίμετρος Κεφαλής",
                "mesUnit": "cm",
                "datatype": "NUM"
            },
            "bioValue": "",
            "dateInserted": ""
        },
        {
            "biometricsPool": {
                "id": "EYECOLOR",
                "description": "Χρώμα ματιών",
                "mesUnit": "Δ/Υ",
                "datatype": "CHR"
            },
            "bioValue": "",
            "dateInserted": ""
        }
    ]

    useEffect(() => {
        if (show) fetchBiometrics();
    }, [show])

    const handleSaveBiometrics = () => {
        // Quick Validation & Tweaks
        const cloneBiometrics = structuredClone(biometrics.filter((el) => el.erp_changed === true));
        for (let bio of cloneBiometrics) {
            delete bio.erp_created;
            delete bio.erp_changed;
            if (["WEIGHT", "HEIGHT", "HEADPERIM"].includes(bio.biometricsPool.id) && isNaN(bio.bioValue)) return toast.error("Οι τιμές δεν είναι αποδεκτές.");
        }

        // Post to API
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/prescription/update-biometrics`, {
            company: company.id,
            amka: amka,
            biometrics: cloneBiometrics,
        }, {
            headers: { 'Content-Type': 'application/json' },
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής αποθήκευση.");
                setShow(false);
                setBiometrics([]);
            } else {
                toast.error(res.data.message);
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος. Η αποθήκευση δεν πραγματοποιήθηκε.");
        })
    }

    const fetchBiometrics = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL2}/prescription/fetch-biometrics`, {
            headers: { 'Content-Type': 'application/json' },
            params: {
                company: company.id,
                amka: amka,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                setBiometrics(res.data.data);
            } else {
                toast.error(res.data.message);
                setShow(false);
            }
            setLoading(false);
        }).catch((err) => {
            console.log(err);
            setLoading(false);
            setShow(false);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onBioAdd = (opt) => {
        const objectToClone = structuredClone(defaultBiometrics.find((el) => el.biometricsPool.description === opt));
        objectToClone.erp_created = true;
        objectToClone.dateInserted = moment().format("YYYY-MM-DD HH:mm:ss")
        if (objectToClone) {
            setBiometrics([...biometrics, objectToClone]);
        }
    }

    const onBioRemove = (idx) => {
        const cloneBios = structuredClone(biometrics);
        cloneBios.splice(idx, 1);
        setBiometrics(cloneBios);
    }

    const handleOnChange = (e, type, name, idx) => {
        const cloneBios = structuredClone(biometrics);
        if (type === "input") {
            cloneBios[idx][name] = e.target.value;
            if (!cloneBios[idx].erp_changed) cloneBios[idx].erp_changed = true;
        }
        setBiometrics(cloneBios);
    }

    const availableBioOptions = ["Βάρος", "Περίμετρος Κεφαλής", "Ύψος", "Χρώμα ματιών"].filter((el) => !biometrics.some((bio) => bio.biometricsPool.description === el));

    return (
        <React.Fragment>
            <Modal show={show} backdrop={"static"} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Βιομετρικά Στοιχεία Ασθενή</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {biometrics.length > 0 && (
                        <React.Fragment>
                            {biometrics.map((bio, idx) => (
                                <div className={"borderedContainer mt-2"} key={`biometric-${idx}`}>
                                    <div style={{display: "flex", position: "relative"}}>
                                        {bio.erp_created && (
                                            <div style={{position: "absolute", top: "-4px", right: "5px"}}>
                                                <Badge bg={"danger"} onClick={() => onBioRemove(idx)} style={{cursor: "pointer"}}>X</Badge>
                                            </div>
                                        )}
                                        <Input
                                            label={`${bio.biometricsPool.description} (${moment(bio.dateInserted, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")})`}
                                            value={bio.bioValue}
                                            placeholder={`Εισαγάγετε ${bio.biometricsPool.description}`}
                                            onChange={(e) => handleOnChange(e, "input", "bioValue", idx)}
                                            disabled={loading}
                                        />
                                        <Badge bg={"dark"} style={{position: "absolute", right: "10px", top: "32px"}}>{bio.biometricsPool.mesUnit}</Badge>
                                    </div>
                                </div>
                            ))}
                        </React.Fragment>
                    )}
                    {biometrics.length < 4 && (
                        <DropdownButton
                            as={ButtonGroup}
                            size={"sm"}
                            variant={"primary"}
                            title={"Προσθήκη βιομετρικού"}
                            style={{flex: "1"}}
                            className={"ml-1 mb-1 mt-2"}
                        >
                            {availableBioOptions.map((opt, idx) => (
                                <Dropdown.Item key={`moveTo-${idx}`}
                                               onClick={() => onBioAdd(opt)}>{opt}</Dropdown.Item>
                            ))}
                        </DropdownButton>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShow(false)} disabled={loading}>
                        Κλείσιμο
                    </Button>
                    <Button variant="primary" onClick={() => handleSaveBiometrics()} disabled={loading}>
                        Αποθήκευση {loading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default BiometricsModal
