import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import EditableTable from '../../../../primer/Editable-Table';
import {deleteObjectFromArrayOfObjects} from '../../../../../_helpers/helperFunctions';
import {
  getDOYOptions,
  getInstallationsTypes, getVatCategoryAbroadOptions, getVatCategoryOptions
} from '../../../../../_helpers/commonFunctions';
import {
  resetNewInstallations,
  setEditSupplier,
  setNewInstallations
} from '../../../../../_reducers/DataPanel/Suppliers/supplierSlice';
import {setSaveEditSupplier} from '../../../../../_reducers/activitySlice';
import getMyData from "../../../../../_helpers/sharedmydata";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

const EditSupplierInstallations = () => {
  const [t, i18n] = useTranslation('common');
  const dispatch = useDispatch();
  const ACTIVITY = useSelector( (state) => state.ACTIVITY );
  const SUPPLIERS_DATA = useSelector( (state) => state.SUPPLIERS_DATA );
  let vatCategoryInstallationList = SUPPLIERS_DATA.editSupplier.categoryName === "Supplier within E.U." || SUPPLIERS_DATA.editSupplier.categoryName === "Supplier outside E.U." ? getVatCategoryAbroadOptions() : getVatCategoryOptions();
  let doyList = SUPPLIERS_DATA.editSupplier.categoryName === "Supplier within E.U." || SUPPLIERS_DATA.editSupplier.categoryName === "Supplier outside E.U." ? [] : getDOYOptions();
  const zeroVatReasonOptions = getMyData({type: 'zerovatreason'});
  const supplierInstallationsColumns = [
    {
      name: t("General.no"),
      field: "no",
      width: "7%",
      editable: true,
      inputType: {
        type: 'number',
      }
    },
    {
      name: t("General.type"),
      field: "type",
      editable: true,
      width: "15%",
      inputType: {
        options: getInstallationsTypes(),
        type: 'dropdown',
        config: {
          searchEnable: true
        }
      }
    },
    {
      name: t("General.address"),
      field: "address",
      width: "15%",
      editable:true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t("General.doy"),
      field: "DOY",
      editable: true,
      width: "15%",
      inputType: {
        options: doyList,
        type: 'dropdown',
        config: {
          searchEnable: true
        }
      }
    },
    {
      name: t("General.city"),
      field: "city",
      width: "15%",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t("General.postalCode"),
      field: "postalCode",
      width: "10%",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t("General.vatCategory"),
      field: "vatCategory",
      editable: true,
      width: "15%",
      inputType: {
        options: vatCategoryInstallationList,
        type: 'dropdown',
      }
    },
    {
      name: t("General.0vatReason"),
      field: "vatReason",
      editable: true,
      width: "20%",
      disabled: true,
      inputType: {
        options: zeroVatReasonOptions,
        type: 'dropdown',
        config: {
          searchEnable: true
        }
      }
    },
    {
      name: t("General.customVat"),
      field: "customVat",
      editable: true,
      disabled: true,
      width: "10%",
      inputType: {
        type: 'number',
      }
    },
  ]

  useEffect( () => {
    setTableColumns(supplierInstallationsColumns.slice());
  },[i18n.language])
  const [tableColumns, setTableColumns] = useState(supplierInstallationsColumns.slice());
  const parentMydataActions = {
    insert: (e, data) => {
      let tempInstallations = Object.assign({},  SUPPLIERS_DATA.newInstallation);
      if (e.key !== "Enter") {
        tempInstallations[data.field] = data.value;
        dispatch(setNewInstallations(tempInstallations)); // Update redux
        let oldInstallations = SUPPLIERS_DATA.editSupplier.installations;
        if(data.field === "no"){
          const findDuplicate = oldInstallations.filter(installation => installation.no.toString() === data.value);
          if(findDuplicate.length > 0){
            toast.error("Ο Αριθμός Εγκατάστασης υπάρχει ηδη.");
            dispatch(resetNewInstallations());
            return;
          }
        }
        let updatedInstallation = Object.assign({},  tempInstallations);
        if((updatedInstallation && updatedInstallation.vatCategory === 'Zero VAT' && updatedInstallation.vatReason === '') || (updatedInstallation && updatedInstallation.vatCategory === 'Custom VAT' && updatedInstallation.customVat === '')){
          dispatch(setSaveEditSupplier(false));
        }
        if (updatedInstallation && (updatedInstallation.address !== '' && updatedInstallation.DOY !== '' && updatedInstallation.city !== '' && updatedInstallation.vatCategory !== '' && updatedInstallation.postalCode !== '' && updatedInstallation.no !== '')) {
          updatedInstallation.id = oldInstallations.length;
          dispatch(setEditSupplier({...SUPPLIERS_DATA.editSupplier, installations: [...oldInstallations, updatedInstallation]}));
          dispatch(resetNewInstallations()); // Reset
          dispatch(setSaveEditSupplier(true));
          toast.success('Please press Save in order to save the installation changes');
        } else if(updatedInstallation && (SUPPLIERS_DATA.editSupplier.categoryName === "Supplier within E.U." || SUPPLIERS_DATA.editSupplier.categoryName === "Supplier outside E.U.") && (updatedInstallation.address !== '' && updatedInstallation.no !== '' && updatedInstallation.postalCode !== '' && updatedInstallation.city !== '' && updatedInstallation.vatCategory !== '')){
          updatedInstallation.id = oldInstallations.length;
          dispatch(setEditSupplier({...SUPPLIERS_DATA.editSupplier, installations: [...oldInstallations, updatedInstallation]}));
          dispatch(resetNewInstallations()); // Reset
          dispatch(setSaveEditSupplier(true));
          toast.success('Please press Save in order to save the installation changes');
        }
      } else {
        let updatedInstallation = Object.assign({},  SUPPLIERS_DATA.newInstallation);
        let oldInstallations = SUPPLIERS_DATA.editSupplier.installations;
        if(data.field === "no"){
          const findDuplicate = oldInstallations.filter(installation => installation.no.toString() === data.value);
          if(findDuplicate.length > 0){
            toast.error("Ο Αριθμός Εγκατάστασης υπάρχει ηδη.");
            dispatch(resetNewInstallations());
            return;
          }
        }
        if (data.field === 'add' || e.key === 'Enter') {
          if (updatedInstallation && (updatedInstallation.address !== '' && updatedInstallation.DOY !== '' && updatedInstallation.no !== '' && updatedInstallation.postalCode !== '' && updatedInstallation.city !== '' && updatedInstallation.vatCategory !== '')) {
         // updatedInstallation.no = oldInstallations.length;
          updatedInstallation.id = oldInstallations.length;
          dispatch(setEditSupplier({...SUPPLIERS_DATA.editSupplier, installations: [...oldInstallations, updatedInstallation]}));
          dispatch(resetNewInstallations()); // Reset
          dispatch(setSaveEditSupplier(true));
            toast.success('Please press Save in order to save the installation changes');
            } else if(updatedInstallation && (SUPPLIERS_DATA.editSupplier.categoryName === "Supplier within E.U." || SUPPLIERS_DATA.editSupplier.categoryName === "Supplier outside E.U.") && (updatedInstallation.address !== '' && updatedInstallation.city !== '' && updatedInstallation.no !== '' && updatedInstallation.postalCode !== '' && updatedInstallation.vatCategory !== '')){
            updatedInstallation.id = oldInstallations.length;
            dispatch(setEditSupplier({...SUPPLIERS_DATA.editSupplier, installations: [...oldInstallations, updatedInstallation]}));
            dispatch(resetNewInstallations()); // Reset
            dispatch(setSaveEditSupplier(true));
            toast.success('Please press Save in order to save the installation changes');
          }
        }
      }
    },
    update: (data, id) => {

      // Set if editing the form
      if (!ACTIVITY.saveEditSupplier) {
        dispatch(setSaveEditSupplier(true));
      }
      let installations = SUPPLIERS_DATA.editSupplier.installations.slice();
      if(data?.no){
        const findDuplicate = installations.filter(installation => installation.no.toString() === data.no);
        if(findDuplicate.length > 0){
          toast.error("Ο Αριθμός Εγκατάστασης υπάρχει ηδη.");
          return;
        }
      }
      if (installations.find(item => item.id === id) !== undefined) {
        installations[id] = {...installations[id], ...data};
        dispatch(setEditSupplier({...SUPPLIERS_DATA.editSupplier, installations: installations})); // update data
      }
    },
    selected: () => {
      console.log('selected mydata row clicked');
    },
    delete: (id) => {

      // Set if editing the form
      if (!ACTIVITY.saveEditSupplier) {
        dispatch(setSaveEditSupplier(true));
      }
      let installations = SUPPLIERS_DATA.editSupplier.installations.slice();
      if (id !== '') {
        installations = deleteObjectFromArrayOfObjects(installations, id, 'no');
        dispatch(setEditSupplier({...SUPPLIERS_DATA.editSupplier, installations: installations }))
      }
    }
  }
  return (
    <div className="new-supplier-general">
      <p className="text-muted"><i>{t("General.installations")}</i></p>
      <EditableTable
        tableName="Supplier New Installations"
        key={Math.random()}
        allowActions={true}
        allowToggleColumns={false}
        columns={tableColumns}
        allowInsertRow={true}
        enableNewRowCheckbox = {true}
        data={ SUPPLIERS_DATA.editSupplier.installations }
        onUpdate={parentMydataActions}
      />
    </div>
  )
}

export default EditSupplierInstallations;
