import React from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    getICCategoryOptions,
    getICTypeOptions,
    getInvoiceTypesOptions,
    getMyDataRowTypes, getVatExpenseClassificationType
} from "../../../../_helpers/sharedmydata";
import {setNewProductsCategoryMyData} from "../../../../_reducers/DataPanel/Products/productCategorySlice";
import {setEditAccountingAccount} from "../../../../_reducers/DataPanel/Accounting/accountingSlice";
import {toast} from "react-toastify";
import {resetTableItemsId} from "../../../../_helpers/commonFunctions";
import EditableTable from "../../../primer/Editable-Table";
import {setSaveEditAccountingAccount} from "../../../../_reducers/activitySlice";

const AccountingAccountEditMyData = () => {
    const [t] = useTranslation('common');
    const ACTIVITY = useSelector( (state) => state.ACTIVITY);
    const ACCOUNTING_DATA = useSelector( (state) => state.ACCOUNTING_DATA);
    const PRODUCTS_CATEGORY = useSelector(state => state.PRODUCTS_CATEGORY);
    const dispatch = useDispatch();
    const invoiceTypes = getInvoiceTypesOptions();
    const icCategory = getICCategoryOptions();
    const icType = getICTypeOptions();
    const myDataRowTypes = getMyDataRowTypes();
    const vatExpenseTypes = getVatExpenseClassificationType();
    const productsColumns = [
        {
            name: t('ProductsNewGeneral.table.rowType'),
            field: "rowType",
            width: "10%",
            editable:true,
            inputType: {
                type: 'select',
                options: myDataRowTypes
            }
        },
        {
            name: t('ProductsSettings.table.invoiceType'),
            field: "invoiceType",
            editable: true,
            width: "30%",
            inputType: {
                type: 'select',
                multiselect: true,
                options: invoiceTypes
            }
        },
        {
            name: t('ProductsSettings.table.category'),
            field: "category",
            editable: true,
            width: "20%",
            inputType: {
                type: 'select',
                options: icCategory
            }
        },
        {
            name: t('ProductsSettings.table.type'),
            field: 'type',
            editable: true,
            width: "20%",
            inputType: {
                type: 'select',
                options: icType
            }
        },
        {
            name: t('ProductsNewGeneral.table.vatExpenseType'),
            field: 'vatExpenseType',
            editable: true,
            width: "20%",
            inputType: {
                type: 'select',
                options: vatExpenseTypes,
            }
        }
    ];
    const parentMydataActions = {
        insert: (e, data) => {
            if (!ACTIVITY.saveEditAccountingAccount) dispatch(setSaveEditAccountingAccount(true));
            let tempPcMyData = Object.assign({},  PRODUCTS_CATEGORY.newProductsCategoryMyData);
            if (e.key !== "Enter") {
                tempPcMyData[data.field] = data.value;
                dispatch(setNewProductsCategoryMyData(tempPcMyData)); // Update redux my data values
            }
            // If validated fields done add new row
            if (tempPcMyData.rowType !== '' || tempPcMyData.invoiceType !== '' || tempPcMyData.category !== '' || tempPcMyData.type !=='') {
                let updateNewPcMydata = Object.assign({},  PRODUCTS_CATEGORY.newProductsCategoryMyData);
                updateNewPcMydata.id = ACCOUNTING_DATA.editAccountingAccount.mydata.length + 1;
                // set the last updated value
                if (tempPcMyData.type) {
                    updateNewPcMydata.type = tempPcMyData.type;
                }
                if (tempPcMyData.rowType) {
                    updateNewPcMydata.rowType = tempPcMyData.rowType;
                }
                if (tempPcMyData.invoiceType) {
                    updateNewPcMydata.invoiceType = tempPcMyData.invoiceType;
                }
                if (tempPcMyData.category) {
                    updateNewPcMydata.category = tempPcMyData.category;
                }
                let accountingCategory = Object.assign({}, ACCOUNTING_DATA.editAccountingAccount);
                let updatedMyData = Array.isArray(accountingCategory.mydata) ? [...accountingCategory.mydata, updateNewPcMydata] : [updateNewPcMydata];
                dispatch(setEditAccountingAccount({ ...accountingCategory, mydata: updatedMyData }));
                dispatch(setNewProductsCategoryMyData(PRODUCTS_CATEGORY.newProductsCategoryMyDataInitialValue)); // Reset new category mydata
            }
        },
        update: (data, id) => {
            if (!ACTIVITY.saveEditAccountingAccount) dispatch(setSaveEditAccountingAccount(true));
            let selectedCategoryMyData = Object.assign([],ACCOUNTING_DATA.editAccountingAccount.mydata);
            if (selectedCategoryMyData.find(item => item.id === id) !== undefined) {
                let accountingCategory = Object.assign({}, ACCOUNTING_DATA.editAccountingAccount);
                selectedCategoryMyData[id-1] = {...selectedCategoryMyData[id - 1], ...data};
                if (data.invoiceType) {
                    selectedCategoryMyData[id-1].category = "";
                    selectedCategoryMyData[id-1].type = "";
                    selectedCategoryMyData[id-1].vatExpenseType = "";
                } else if (data.category) {
                    selectedCategoryMyData[id-1].type = "";
                    selectedCategoryMyData[id-1].vatExpenseType = "";
                } else if (data.type) {
                    selectedCategoryMyData[id-1].vatExpenseType = "";
                }
                dispatch(setEditAccountingAccount({ ...accountingCategory, mydata: selectedCategoryMyData}));
            }
        },
        selected: () => {},
        delete: (id) => {
            if (!ACTIVITY.saveEditAccountingAccount) dispatch(setSaveEditAccountingAccount(true));
            let oldMydata = Object.assign([],ACCOUNTING_DATA.editAccountingAccount.mydata);
            if (id > 0) {
                oldMydata.splice(id - 1, 1);
                oldMydata = resetTableItemsId(oldMydata);
                let accountingCategory = Object.assign({}, ACCOUNTING_DATA.editAccountingAccount);
                dispatch(setEditAccountingAccount({ ...accountingCategory, mydata: oldMydata }));
                toast.success('1 row deleted.');
            } else {
                console.log('delete mydata row clicked');
            }
        }
    }
    return (
        <>
            <br />
            <p className="text-center border-bottom pb-3 pt-3 "><i>{t('ProductsSettings.categorySettings')}</i></p>
            <div className="row">
                <div className="col-12">
                    <p className="text-muted"><i>{t('ProductsSettings.proposedMyDataValue')}</i></p>
                    <EditableTable
                        tableName = "Product Category My Data" // Do not change name
                        showAccounting={true}
                        key={Math.random()}
                        onUpdate={parentMydataActions}
                        allowActions={true}
                        allowInsertRow={ true }
                        columns={productsColumns}
                        data={ACCOUNTING_DATA.editAccountingAccount?.mydata ? ACCOUNTING_DATA.editAccountingAccount?.mydata : []}
                        enableNewRowCheckbox = {true}
                    />
                </div>
            </div>
            <hr />
        </>
    )
}
export default AccountingAccountEditMyData;
