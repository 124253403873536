import React, {useEffect, useState} from "react";
import Checkbox from "../../../common/CheckBox";
import TextArea from "../../../common/TextArea";
import Dropdown from "../../../primer/Dropdown";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import {
  deleteObjectFromArrayOfObjects,
  findObjectFromArrayOfObjects,
  getLabelByValue,
  getObjectFromArrayOfObjects,
  isValueExists
} from "../../../../_helpers/helperFunctions";
import {
  checkPermission,
  getDefaultOptionByValue,
  getGeneratedMaskCode,
  getMaskCodePrefix,
  getMaskCodeWithNewPrefix,
  getOptionsByData,
  isEmpty,
  isValidNumber,
  productCategoryOptions,
  removeEmptyValues,
  vatIncludedOptions,
} from "../../../../_helpers/commonFunctions";
import {
  addProductNewAllVariants,
  addProductNewVariant,
  resetNewVariantProps,
  resetTempNewProductProperties,
  setAllCheckedNewVariants,
  setNewProductCode,
  setNewProductDisplayedVariants,
  setNewProductsMyData,
  setNewSelectedVariantsRows,
  setNewVariant,
  setNewVariantIds,
  setNewVariantSearchTerm,
  setProductCodes,
  setProductsSelectedCategoryData,
  setSelectedProduct,
  setTempNewProductProperties,
  updateProductNewVariants
} from "../../../../_reducers/DataPanel/Products/productsSlice";
import {Button, Col, FormLabel, Modal, Row,} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import EditableTable from '../../../primer/Editable-Table';
import getMyData, {
  getICCategoryOptions,
  getICTypeOptions,
  getInvoiceTypesOptions,
  getMyDataRowTypes,
  getVatExpenseClassificationType
} from '../../../../_helpers/sharedmydata';
import './index.css';
import {toast} from "react-toastify";
import AccountingBox from "../../../primer/AccountingBox";
import Input from "../../../common/Input";
import {setSaveNewProduct} from "../../../../_reducers/activitySlice";
import {useTranslation} from "react-i18next";
import BetterCheckBox from "../../../common/BetterCheckBox";
import ModalInstructions from "../../../primer/POPUP-COMPONENTS/Instructions";
import {fuelCodes} from "../../../../_data/mydata/fuelCodes";

// Products General Tab
export const ProductsNewGeneral = () => {
  const [t] = useTranslation("common");
  const invoiceTypes = getInvoiceTypesOptions("products");
  const icCategory = getICCategoryOptions();
  const icType = getICTypeOptions();
  const myDataRowTypes = getMyDataRowTypes();
  const vatExpenseTypes = getVatExpenseClassificationType();
  const permissionData = JSON.parse(localStorage.getItem("permissions"));
  const fuelCodesOptions = [{value: "", label: "Κενό"}, ...fuelCodes];
  const invoiceDetailTypeOptions = [{value: "", label: "Κενό"}, {value: "1", label: "Εκκαθάριση Πωλήσεων Τρίτων"}, {value: "2", label: "Αμοιβή από Πωλήσεις Τρίτων"}];

  const productsColumns = [
    {
      name: t('ProductsNewGeneral.table.rowType'),
      field: "rowType",
      width: "10%",
      editable:true,
      inputType: {
        type: 'select',
        options: myDataRowTypes
      }
    },
    {
      name: t('ProductsNewGeneral.table.invoiceType'),
      field: "invoiceType",
      editable: true,
      width: "30%",
      inputType: {
        type: 'select',
        multiselect: true,
        options: invoiceTypes,
      }
    },
    {
      name: t('ProductsNewGeneral.table.category'),
      field: "category",
      editable: true,
      width: "20%",
      inputType: {
        type: 'select',
        options: icCategory,
      }
    },
    {
      name: t('ProductsNewGeneral.table.type'),
      field: 'type',
      editable: true,
      width: "20%",
      inputType: {
        type: 'select',
        options: icType,
      }
    },
    {
      name: t('ProductsNewGeneral.table.vatExpenseType'),
      field: 'vatExpenseType',
      editable: true,
      width: "20%",
      inputType: {
        type: 'select',
        options: vatExpenseTypes,
      }
    }
  ];

  const propertiesColumns = [
    {
      name: 'Όνομα Ιδιότητας',
      field: 'name',
      editable: true,
      width: "20%",
      inputType: {
        type: 'text',
      }
    },
    {
      name: 'Τιμές Ιδιότητας',
      field: 'values',
      editable: true,
      width: "80%",
      inputType: {
        type: 'text',
      }
    },
  ];

  const propertiesInstructions = {
    title: "Οδηγίες Εισαγωγής Ιδιοτήτων",
    content: "<div>Εισάγετε ιδιότητες ειδών χωρίζοντάς τις τιμές με κόμμα(,).<br><br>Καθορίστε αυξομειώσεις τιμής σε συγκεκριμένες παραλλαγές με ανω-κάτω τελεία(:)<br><br><u>Παράδειγμα</u><br>Όνομα Ιδιότητας: Χρώμα<br>Τιμές Ιδιότητας: μαύρο,κόκκινο:3,κίτρινο:-3,μπλε</div>"
  };

  const dispatch = useDispatch();

  const ACTIVITY = useSelector( (state) => state.ACTIVITY );
  const PRODUCTS_DATA = useSelector( (state) => state.PRODUCTS_DATA );
  const PRODUCTS_CATEGORY = useSelector( (state) => state.PRODUCTS_CATEGORY.categories );
  const SETTINGS = useSelector(state => state.SETTINGS)
  const vatTaxes             = getMyData({type: 'vat', settings: SETTINGS});
  const zeroVatReasonOptions = getMyData({type: 'zerovatreason'});
  const measurementUnits     = getMyData({type: 'mu', settings: SETTINGS});
  let productCategoriesOptions = productCategoryOptions(PRODUCTS_CATEGORY);
  const [showCategoryConfirmation, setShowCategoryConfirmation] = useState(false);
  const [tempCatValue, setTempCatValue] = useState({});

  // For editable table re-rendering
  const [incrKey, setIncrKey] = useState(0);
  useEffect(() => {
    setIncrKey(incrKey + 1);
  }, [PRODUCTS_DATA.selectedProduct.mydata])

  // Get Last Product Code
  let tempProductCode = getGeneratedMaskCode('product', PRODUCTS_DATA.selectedProduct.category, PRODUCTS_CATEGORY, PRODUCTS_CATEGORY.maskCodePrefix,PRODUCTS_DATA.products);
  const handleOnChange = async (e, type="default", ddname = '') => {
    // Set if editing the form
    if (!ACTIVITY.saveNewProduct) {
      dispatch(setSaveNewProduct(true));
    }
    let name;
    let value;
    if (type === 'dd') {
      value = e.value;
      name = ddname;
    } else if (type === 'ac' ) {
      value = e.target.value;
      name = ddname;
    } else if (type === 'dp' ) {
      value = e;
      name = ddname;
    } else if (type === "checkbox") {
      name = ddname;
      value = String(e.target.checked);
    } else {
      value = e.target.value;
      name = e.target.name;
    }
    let selectedProductData = Object.assign({}, PRODUCTS_DATA.selectedProduct);
    let newProductData = {};
    if (name === 'customVat') {
      value = +String(value).slice(0, 3)
    }
    // Validation on Category Change
    if (name === 'category') {
      setIncrKey(incrKey + 1);
      if (PRODUCTS_DATA.selectedProduct.category !== "") {
        // Show confirmation before category change.
        setShowCategoryConfirmation(true);
        setTempCatValue({new: value, old: PRODUCTS_DATA.selectedProduct.category});
      } else {
        // Populate Selected Category Data
        setSelectedCategoryData(value);
      }
      const nextCode = `PC-${PRODUCTS_DATA.products.length + 1}`
      console.log('nextCode',nextCode);
    } else {
      newProductData[name] = value;
      // Do not dispatch outside.
      // First time data population issues fix
      // Set Generated Product Code
      newProductData.code = tempProductCode;
      dispatch(setSelectedProduct({...selectedProductData, ...newProductData}));
    }
  }

  const propertiesActions = {
    insert: (e, data) => {
      if (isEmpty(data.value)) return;

      let tempNewProperty = structuredClone(PRODUCTS_DATA.tempNewProductProperties);
      if (data.name) data.name = data.name.trim();
      if(data.values) data.values = data.values.replace(/\s+/g, '');
      tempNewProperty[data.field] = data.value;
      if (isEmpty(tempNewProperty.name) || isEmpty(tempNewProperty.values)) {
        dispatch(setTempNewProductProperties(tempNewProperty));
      } else {
        let productProperties = Object.assign([], PRODUCTS_DATA.selectedProduct.productProperties);
        if(productProperties.filter((prop) => prop.name === tempNewProperty.name).length !== 0) return toast.error("Διπλότυπη Ιδιότητα.");
        productProperties.push(tempNewProperty);
        dispatch(resetTempNewProductProperties());
        dispatch(resetNewVariantProps());
        dispatch(setSelectedProduct({...PRODUCTS_DATA.selectedProduct, productProperties: productProperties}));
      }
    },
    update: (data, id, rowIndex) => {
      let productProperties = structuredClone(PRODUCTS_DATA.selectedProduct.productProperties);
      if (data.name) data.name = data.name.trim();

      if (data.values) data.values = data.values.replace(/\s+/g, '');
      if (productProperties.filter((prop, index) => index !== rowIndex && prop.name === data.name).length !== 0) return toast.error("Διπλότυπη Ιδιότητα.");
      productProperties[rowIndex] = {...productProperties[rowIndex], ...data};
      dispatch(resetNewVariantProps());
      dispatch(setSelectedProduct({...PRODUCTS_DATA.selectedProduct, productProperties: productProperties}));
    },
    selected: () => {},
    delete: (id, rowIndex) => {
      let productProperties = structuredClone(PRODUCTS_DATA.selectedProduct.productProperties);
      const deleted = productProperties.splice(rowIndex, 1);

      let canBeDeleted = true;
      PRODUCTS_DATA.selectedProduct.variants.forEach(variant => {
        if (JSON.stringify(variant.properties).includes(deleted[0].name)) canBeDeleted= false;
      });
      if (!canBeDeleted) {
        toast.error("Η ιδιότητα δε μπορεί να διαγραφεί καθώς υπάρχει σε παραλλαγή του είδους.");
        return;
      }
      dispatch(resetNewVariantProps());
      dispatch(setSelectedProduct({...PRODUCTS_DATA.selectedProduct, productProperties: productProperties}));
    }
  }

  // Validate if product name already exists in Database.
  const isProductNameAlreadyExists = (value) => {
    let error;
    if (isValueExists(PRODUCTS_DATA.products, value , 'name')) {
      error = 'Product Name Already Exists';
    }
    return error;
  }

  const setSelectedCategoryData = (categoryId) => {
    const selected = getObjectFromArrayOfObjects(PRODUCTS_CATEGORY, categoryId,'_id')['0'];

    // Update the Taxes to current product.
    const categoryTaxesData = selected.taxes && (selected.taxes !== 'undefined') ? JSON.parse(selected.taxes) : '';
    const taxesData = {
      category: categoryId ? categoryId : '',
      categoryName: categoryId ? getLabelByValue(productCategoriesOptions, categoryId,'value') : '',
      categoryMaskCodePrefix: selected.maskCodePrefix ? selected.maskCodePrefix : 'PC',
      vat: categoryTaxesData.vat ? categoryTaxesData.vat : '',
      vatReason: categoryTaxesData.vatreason ? categoryTaxesData.vatreason : '',
      otherTaxes: categoryTaxesData.othertaxes ? categoryTaxesData.othertaxes : '',
      withholdTax: categoryTaxesData.witholdtaxes ? categoryTaxesData.witholdtaxes : '',
      stamp: categoryTaxesData.stamp ? categoryTaxesData.stamp : '',
      fees: categoryTaxesData.fees ? categoryTaxesData.fees : '',
      measurementUnit: selected.measurementUnit ? selected.measurementUnit : '',
      accountingCode: selected.accountingCode ? selected.accountingCode : '',
      netAmountAccountingCode: selected.netAmountAccountingCode ? selected.netAmountAccountingCode : '',
      vatAccountingCode: selected.vatAccountingCode ? selected.vatAccountingCode : '',
      taxAccountCode: selected.taxAccountCode ? selected.taxAccountCode : '',
      accountingNonDeductibleVAT: selected.accountingNonDeductibleVAT ? selected.accountingNonDeductibleVAT : '',
    }
    // Update my data to current product
    let combinedData = {...taxesData}
    if(selected.mydata && selected.mydata.length > 0) {
      const categoryMyData = selected.mydata && selected.mydata !== [] ? JSON.parse(selected.mydata) : {};
      const myData = {mydata: categoryMyData}

       combinedData = {...myData, ...taxesData}
    }
    let defaultProperties = [];
    PRODUCTS_CATEGORY.forEach((category) => {
      if (category._id === categoryId) {
        defaultProperties = category.defaultProperties ?? [];
      }
    });
    combinedData = {...combinedData, productProperties: defaultProperties}

    tempProductCode = getGeneratedMaskCode('product', categoryId, PRODUCTS_CATEGORY)
    combinedData.categoryMaskCodePrefix = getMaskCodePrefix(selected.maskCode);
    combinedData.code = getMaskCodeWithNewPrefix(tempProductCode, combinedData.categoryMaskCodePrefix); // Set Product Code
    dispatch(setProductsSelectedCategoryData(selected));
    dispatch(setSelectedProduct({...PRODUCTS_DATA.selectedProduct, ...combinedData}));

    toast.success("Data populated from the selected category.");
  }

  const handleConfirmCategory = () => {
    setSelectedCategoryData(tempCatValue.new);
    setShowCategoryConfirmation(false);
  }

  const parentMydataActions = {
    insert: (e, data) => {

      let productCodeObj = Object.assign({},  PRODUCTS_DATA.newProductsMyData);
      if (e.key !== "Enter") {
        productCodeObj[data.field] = data.value;
        dispatch(setNewProductsMyData(productCodeObj)); // Update redux my data values
      }

      // When one field is populated by the user another new row should be automatically created.
      if (productCodeObj.rowType !=='' || productCodeObj.invoiceType !=='' || productCodeObj.category !=='' || productCodeObj.type !=='') {
        let newProductCode = Object.assign({},  productCodeObj);
        newProductCode.id = PRODUCTS_DATA.selectedProduct.mydata.length + 1;
        const tempProductCodes = [...PRODUCTS_DATA.selectedProduct.mydata, newProductCode];
        dispatch(setSelectedProduct({...PRODUCTS_DATA.selectedProduct, ...{ mydata: tempProductCodes}}));  // update products mydata
        dispatch(setNewProductsMyData(PRODUCTS_DATA.newProductsMyDataInitialValue)); // Reset new category mydata
      }

      // Add on press enter
      if (e.key === "Enter" && data.field !== 'add') {
        if (data.field === 'name' && data.value === '') {
          toast.error("Product Name should not be empty.");
        } else {
          let newProductCode = Object.assign({},  PRODUCTS_DATA.newProductsMyData);
              const tempProductCodes = [...PRODUCTS_DATA.selectedProduct.mydata, newProductCode];
              dispatch(setSelectedProduct({...PRODUCTS_DATA.selectedProduct, ...{ mydata: tempProductCodes}}));  // update products mydata
              dispatch(setNewProductsMyData(PRODUCTS_DATA.newProductsMyDataInitialValue)); // Reset new category mydata
        }
      }

      // Fix allow empty row insert on check box click
      if (e.key === "Enter" && data.field === 'add') {
        let newProductCode = Object.assign({},  PRODUCTS_DATA.newProductsMyData);
        newProductCode.id = PRODUCTS_DATA.selectedProduct.mydata.length + 1;
        newProductCode.rowType = "";
        newProductCode.invoiceType = "";
        newProductCode.category = "";
        newProductCode.type = "";
        const tempProductCodes = [...PRODUCTS_DATA.selectedProduct.mydata, newProductCode];
        dispatch(setSelectedProduct({...PRODUCTS_DATA.selectedProduct, ...{ mydata: tempProductCodes}}));  // update products mydata
        dispatch(setNewProductsMyData(PRODUCTS_DATA.newProductsMyDataInitialValue)); // Reset new category mydata
      }

    },
    update: (data, id, rowIndex) => {
      let cloneMyData = structuredClone(PRODUCTS_DATA.selectedProduct.mydata);
      cloneMyData[rowIndex] = {...cloneMyData[rowIndex], ...data}
      if (data.invoiceType) {
        cloneMyData[rowIndex].category = "";
        cloneMyData[rowIndex].type = "";
        cloneMyData[rowIndex].vatExpenseType = "";
      } else if (data.category) {
        cloneMyData[rowIndex].type = "";
        cloneMyData[rowIndex].vatExpenseType = "";
      } else if (data.type) {
        cloneMyData[rowIndex].vatExpenseType = "";
      }

      dispatch(setSelectedProduct({...PRODUCTS_DATA.selectedProduct, mydata: cloneMyData}));
    },
    selected: () => {},
    delete: (id, rowIndex) => {
      let clonedMyData = structuredClone(PRODUCTS_DATA.selectedProduct.mydata);
      clonedMyData.splice(rowIndex, 1);
      dispatch(setSelectedProduct({...PRODUCTS_DATA.selectedProduct, mydata: clonedMyData}));
    }
  }

  let disabledFields = (PRODUCTS_DATA.selectedProduct.category === undefined || PRODUCTS_DATA.selectedProduct.category === "");
  return (
    <React.Fragment>
      <div className="position-relative product-form">
        {disabledFields && <div className="overlay_w">{t('ProductsNewGeneral.message')}</div>}
        <Row className="position-relative border-bottom mb-3 justify-content-end pb-3">
          <Col xs="12" md="2" className="d-flex justify-content-end">
              <label htmlFor="product-active" className="mb-0 mr-2 " >{t('ProductsNewGeneral.productActive')}</label>
              <Checkbox
                  className="mb-2"
                  name="isActive"
                  key={Math.random()}
                  id="product-active"
                  defaultValue={PRODUCTS_DATA.selectedProduct.isActive}
                  value={PRODUCTS_DATA.selectedProduct.isActive === 'active' ? "inactive" : "active"}
                  onChange={(e) => handleOnChange(e)}
              />
          </Col>
        </Row>
        {/* First Row */}
        <Row>
          <Col xs="12" md="4" className="showWithOverlay">
            <div className="mb-3">
              <FormLabel htmlFor="category">{t('ProductsNewGeneral.productCategory.productCategory')}<span
                  className="text-danger">*</span></FormLabel>
              <input type="hidden" value={PRODUCTS_DATA.selectedProduct.categoryName}/>
              <Dropdown
                  key={Math.random()}
                  classes="w-100"
                  name="category"
                  defaultValue={getDefaultOptionByValue(productCategoriesOptions, PRODUCTS_DATA.selectedProduct.category, 'value', 'label', 'value')}
                  options={productCategoriesOptions}
                  onChange={(e) => handleOnChange(e, 'dd', 'category')}
              />
              {ACTIVITY.saveNewProduct && PRODUCTS_DATA.selectedProduct.category === "" &&
                  <div className="text-danger">{t('ProductsNewGeneral.productCategory.required')}</div>}
            </div>
          </Col>
        </Row>

        {/* Second Row */}
        <Row>
          <Col xs="12" md="6">
            <div className="mb-3">
              <Input
                  name="name"
                  label={t('ProductsNewGeneral.productName.productName')}
                  required={true}
                  charLimit={200}
                  placeholder={t('ProductsNewGeneral.productName.placeholder')}
                  value={PRODUCTS_DATA.selectedProduct.name}
                  onChange={(e) => handleOnChange(e)}
                  // validate={isProductNameAlreadyExists}
              />
              {
                  ACTIVITY.saveNewProduct &&
                  PRODUCTS_DATA.selectedProduct.name === "" &&
                  <div className="text-danger">{t('ProductsNewGeneral.productName.required')}</div>
              }
              {
                  isProductNameAlreadyExists(PRODUCTS_DATA.selectedProduct.name) &&
                  <div className="text-danger">Product Name already exists.</div>
              }
            </div>
          </Col>
          <Col xs="12" md="4">
            <div className="mb-3">
              <Input
                  name="alternateName"
                  charLimit={200}
                  placeholder={t('ProductsNewGeneral.alternativeName.placeholder')}
                  label={t('ProductsNewGeneral.alternativeName.alternativeName')}
                  onChange={(e) => handleOnChange(e)}
                  value={PRODUCTS_DATA.selectedProduct.alternateName}
              />
            </div>
          </Col>
          <Col md={2}>
            <BetterCheckBox
                className={"mt-3"}
                name={"showAlternativeNameOnPrint"}
                text={t("SalesNew.displayAltName")}
                checked={PRODUCTS_DATA.selectedProduct?.showAlternativeNameOnPrint === "true"}
                onChange={(e) => handleOnChange(e, "checkbox", "showAlternativeNameOnPrint")}
            />
          </Col>
        </Row>
        {/* Third Row */}
        <Row>
          <Col xs="12" md="2">
            <div className="mb-3">
              <Input
                  label={t('ProductsNewGeneral.productCode.productCode')}
                  disabled={true}
                  name="code"
                  value={getMaskCodeWithNewPrefix(PRODUCTS_DATA.selectedProduct.code, PRODUCTS_DATA.selectedProduct.categoryMaskCodePrefix)}
                  placeholder={t('ProductsNewGeneral.productCode.placeholder')}
              />
            </div>
          </Col>
          <Col xs="12" md="2">
            <div className="mb-3">
              <Input
                  name="alternateCode"
                  id="alternative-code"
                  placeholder={t('ProductsNewGeneral.alternativeCode.placeholder')}
                  label={t('ProductsNewGeneral.alternativeCode.alternativeCode')}
                  onChange={(e) => handleOnChange(e)}
                  value={PRODUCTS_DATA.selectedProduct.alternateCode}
              />
            </div>
          </Col>
          <Col md={2}>
            <BetterCheckBox
                className={"mt-3"}
                name={"showAlternativeCodeOnPrint"}
                text={t("SalesNew.displayAltCode")}
                checked={PRODUCTS_DATA.selectedProduct?.showAlternativeCodeOnPrint === "true"}
                onChange={(e) => handleOnChange(e, "checkbox", "showAlternativeCodeOnPrint")}
            />
          </Col>
          <Col xs="12" md="3">
            <div className="mb-3">
              <Input
                  name="barcode"
                  id="ean-barode"
                  label="EAN Barcode"
                  placeholder="EAN Barcode"
                  onChange={(e) => handleOnChange(e)}
                  value={PRODUCTS_DATA.selectedProduct.barcode}
              />
            </div>
          </Col>
          {checkPermission("custom-b2g", permissionData) && (
              <Col md={3}>
                <Input
                    name={"cpvCode"}
                    label={"Κωδικός CPV (Απαραίτητο για δημόσιες συμβάσεις)"}
                    value={PRODUCTS_DATA.selectedProduct.cpvCode}
                    placeholder={"Προσθέστε Κωδικό CPV"}
                    onChange={(e) => handleOnChange(e, "input", "cpvCode")}
                />
              </Col>
          )}
        </Row>

        {/* Fourth Row */}
        <Row>
          <Col xs="12" md="3">
            <div className="mb-3">
              <Dropdown
                  label={t('ProductsNewGeneral.vat.vat')}
                  classes="w-100"
                  required={true}
                  name="vat"
                  key={Math.random()}
                  defaultValue={PRODUCTS_DATA.selectedProduct.vat ? {
                    value: PRODUCTS_DATA.selectedProduct.vat,
                    label: getLabelByValue(vatTaxes, PRODUCTS_DATA.selectedProduct.vat, 'value'),
                    color: "#76AEB7"
                  } : null}
                  options={vatTaxes}
                  disabled={PRODUCTS_DATA.selectedProduct.isTaxLineWithoutVat === "true"}
                  onChange={(e) => handleOnChange(e, 'dd', 'vat')}
              />
              {
                  ACTIVITY.saveNewProduct &&
                  PRODUCTS_DATA.selectedProduct.vat === "" &&
                  <div className="text-danger">{t('ProductsNewGeneral.vat.required')}</div>
              }
            </div>
          </Col>
          {PRODUCTS_DATA.selectedProduct.vat === '0' &&
              <Col xs="12" md="3">
                <div className="mb-3">
                  <Dropdown
                      classes='w-100'
                      label={t('ProductsNewGeneral.0VatReason.0VatReason')}
                      key={Math.random()}
                      name="vatReason"
                      required={true}
                      options={zeroVatReasonOptions}
                      defaultValue={PRODUCTS_DATA.selectedProduct.vatReason ? {
                        value: PRODUCTS_DATA.selectedProduct.vatReason,
                        label: getLabelByValue(zeroVatReasonOptions, PRODUCTS_DATA.selectedProduct.vatReason, 'value'),
                        color: "#76AEB7"
                      } : null}
                      onChange={(e) => handleOnChange(e, 'dd', 'vatReason')}
                  />
                  {
                      ACTIVITY.saveNewProduct &&
                      PRODUCTS_DATA.selectedProduct.vat === 0 &&
                      PRODUCTS_DATA.selectedProduct.vatReason === "" &&
                      <div className="text-danger">{t('ProductsNewGeneral.0VatReason.required')}</div>
                  }
                </div>
              </Col>
          }
          <Col xs="12" md="3">
            <Dropdown
                options={measurementUnits}
                classes="w-100"
                name="measurementUnit"
                label={t("ProductsNewGeneral.measurementUnit.measurementUnit")}
                key={Math.random()}
                required={true}
                searchEnable={true}
                defaultValue={PRODUCTS_DATA.selectedProduct.measurementUnit ? {
                  value: PRODUCTS_DATA.selectedProduct.measurementUnit,
                  label: getLabelByValue(measurementUnits, PRODUCTS_DATA.selectedProduct.measurementUnit, 'value'),
                  color: "#76AEB7"
                } : null}
                onChange={(e) => handleOnChange(e, 'dd', 'measurementUnit')}
            />
            {ACTIVITY.saveNewProduct && PRODUCTS_DATA.selectedProduct.measurementUnit === "" &&
                <div className="text-danger">{t("ProductsNewGeneral.measurementUnit.required")}</div>}
          </Col>
          <Col xs={"12"} md={"3"}>
            <Dropdown
                options={fuelCodesOptions}
                classes="w-100"
                name="fuelCode"
                label={"Κωδικός Καυσίμου"}
                key={Math.random()}
                defaultValue={fuelCodesOptions.find((el) => el.value === PRODUCTS_DATA.selectedProduct.fuelCode)}
                onChange={(e) => handleOnChange(e, 'dd', 'fuelCode')}
            />
          </Col>
          <Col xs={"12"} md={"3"}>
            <Dropdown
                options={invoiceDetailTypeOptions}
                classes="w-100"
                name="invoiceDetailType"
                label={"Επισήμανση στο παραστατικό"}
                key={Math.random()}
                defaultValue={invoiceDetailTypeOptions.find((el) => el.value === PRODUCTS_DATA.selectedProduct.invoiceDetailType)}
                onChange={(e) => handleOnChange(e, 'dd', 'invoiceDetailType')}
            />
            {ACTIVITY.saveNewProduct && (PRODUCTS_DATA.selectedProduct.mydata.some((row) => row.invoiceType === "1.5") && !PRODUCTS_DATA.selectedProduct?.invoiceDetailType) &&
                <div className="text-danger">Απαιτείται η επισήμανση στο παραστατικό.</div>}
          </Col>
        </Row>

        {/* Fifth Row */}
        <Row>
          <Col xs="12" md="2">
            <div className="mb-3">
              <FormLabel className="" htmlFor="price">{t('General.preferredSalePrice')}</FormLabel>
              <AccountingBox
                name="price"
                placeholder={t("Placeholders.preferredSalePrice")}
                defaultValue={ PRODUCTS_DATA.selectedProduct.price }
                onChange = {(e) => handleOnChange(e, 'ac', 'price')}
              />
            </div>
          </Col>
          <Col xs="12" md="2">
            <Dropdown
              label={t('ProductsNewGeneral.vatIncluded')}
              name="vatIncluded"
              key={Math.random()}
              defaultValue={vatIncludedOptions().find((el) => String(el.value) === String(PRODUCTS_DATA.selectedProduct.vatIncluded))}
              options={vatIncludedOptions()}
              onChange = {(e) => handleOnChange(e, 'dd', 'vatIncluded')}
            />
          </Col>
          {checkPermission("buys-buy/new-write", permissionData) && (
            <React.Fragment>
              <Col xs="12" md="2">
                <div className="mb-3">
                  <FormLabel className="" htmlFor="price">{t('General.preferredBuyPrice')}</FormLabel>
                  <AccountingBox
                      name="buyPrice"
                      placeholder={t("Placeholders.preferredBuyPrice")}
                      defaultValue={ PRODUCTS_DATA.selectedProduct.buyPrice }
                      onChange = {(e) => handleOnChange(e, 'ac', 'buyPrice')}
                  />
                </div>
              </Col>
              <Col xs="12" md="2">
                <Dropdown
                    label={t('ProductsNewGeneral.vatIncluded')}
                    name="vatIncludedBuy"
                    key={Math.random()}
                    defaultValue={vatIncludedOptions().find((el) => String(el.value) === String(PRODUCTS_DATA.selectedProduct.vatIncludedBuy))}
                    options={vatIncludedOptions()}
                    onChange = {(e) => handleOnChange(e, 'dd', 'vatIncludedBuy')}
                />
              </Col>
            </React.Fragment>
          )}
        </Row>
        <hr/>
        <Row>
          <Col>
            <div className="text-muted mb-2"><i>My Data</i> <span className="text-danger">*</span></div>
            <EditableTable
                tableName="New Product My Data" // Do not change name
                showErrors={PRODUCTS_DATA.selectedProduct.mydata && PRODUCTS_DATA.selectedProduct.mydata.length === 0}
                showErrorMessage={t('ProductsNewGeneral.errorMessage')}
                key={"NPMD" + incrKey}
                allowActions={true}
                onUpdate={parentMydataActions}
                allowInsertRow="true"
                columns={productsColumns}
                enableNewRowCheckbox={true}
                data={PRODUCTS_DATA.selectedProduct.mydata}/>
          </Col>
        </Row>

        {checkPermission("products-products/new-variants-write", permissionData) && (
            <React.Fragment>
              <hr/>
              <Row>
                <Col>
                  <div className="text-muted mb-2 d-flex align-items-center">
                    <i>{t("ProductVariants.productProperties")}</i>
                    <div className="ml-2">
                      <ModalInstructions data={propertiesInstructions}/>
                    </div>
                  </div>
                  <EditableTable
                      tableName="New Product Properties" // Do not change name
                      key={Math.random()}
                      allowActions={true}
                      onUpdate={propertiesActions}
                      columns={propertiesColumns}
                      allowInsertRow={true}
                      enableNewRowCheckbox={true}
                      data={PRODUCTS_DATA.selectedProduct.productProperties}/>
                </Col>
              </Row>
            </React.Fragment>
        )}
        <hr/>
        <Row>
          <Col>
            <div className="text-muted mb-2"><i>{t('ProductsNewGeneral.productNotes')}</i></div>
            <TextArea className="mb-3"
                      rows="4"
                      placeholder={t('ProductsNewGeneral.addProductNotes')}
                      name="notes"
                      value={PRODUCTS_DATA.selectedProduct.notes}
                      onChange={(e) => handleOnChange(e)}
            />
          </Col>
        </Row>

      </div>

      {/* Confirm Category Change */}
      <Modal backdrop={"static"} show={showCategoryConfirmation}>
        <Modal.Header closeButton>
          <Modal.Title>{t('ProductsNewGeneral.warning')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('ProductsNewGeneral.warningMessage')}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary"
                  onClick={() => setShowCategoryConfirmation(false)}>{t('ProductsNewGeneral.no')}</Button>
          <Button variant="primary" onClick={() => handleConfirmCategory()}>{t('ProductsNewGeneral.yes')}</Button>
        </Modal.Footer>
      </Modal>

    </React.Fragment>
  );
};

// Products Others Tab
export const ProductsNewOthers = () => {
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const SETTINGS = useSelector(state => state.SETTINGS);
  const permissionData = JSON.parse(localStorage.getItem("permissions"));
  let pMDDSettings = SETTINGS.primerMyDataData;
  let otherTaxes    = getOptionsByData(pMDDSettings?.otherTaxes !== undefined && pMDDSettings?.otherTaxes !== '' ? (pMDDSettings.otherTaxes).filter(otherTax => otherTax.taxPer !== "true" || otherTax.taxLevel !== 1) : SETTINGS.otherHoldTaxes, 'code', 'name');
  let taxesWithhold = getOptionsByData(pMDDSettings?.withHoldTaxes !== undefined && pMDDSettings?.withHoldTaxes !== '' ? (pMDDSettings.withHoldTaxes).filter(withHoldTax => withHoldTax.taxPer !== "true" || withHoldTax.taxLevel !== 1) : SETTINGS.withHoldTaxes, 'code', 'name');
  let taxesStamp    = getOptionsByData(pMDDSettings?.stamp !== undefined && pMDDSettings?.stamp !== '' ? (pMDDSettings.stamp).filter(stamp => stamp.taxPer !== "true" || stamp.taxLevel !== 1) : SETTINGS.stamp, 'code', 'name');
  let taxesFees     = getOptionsByData(pMDDSettings?.fees !== undefined && pMDDSettings?.fees !== '' ? (pMDDSettings.fees).filter(fee => fee.taxPer !== "true" || fee.taxLevel !== 1) : SETTINGS.fees, 'code', 'name');

  // Fix Blank In the first position
  otherTaxes = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...otherTaxes]
  taxesWithhold = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...taxesWithhold]
  taxesStamp = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...taxesStamp]
  taxesFees = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...taxesFees]

  const PRODUCTS_DATA = useSelector( (state) => state.PRODUCTS_DATA);
  const product = PRODUCTS_DATA.selectedProduct;
    const handleOnFieldChange = (e, name = '') => {
      let tax = {};
      tax[name] = e.value
      let selectedProduct = Object.assign({},PRODUCTS_DATA.selectedProduct);
      if(name === "otherTaxes"){
        const otherTax = findObjectFromArrayOfObjects(pMDDSettings.otherTaxes, e.value, 'code');
        if(otherTax && otherTax.taxAmount === "true"){
          selectedProduct.price = !isNaN(Number(otherTax.tax)) ? Number(otherTax.tax).toString() : "0";
          selectedProduct.isTaxLine = "true";
          if(otherTax.vatIncluded === 1 || otherTax.vatIncluded === 2){
            selectedProduct.isTaxLineWithoutVat = "false";
            if(otherTax.vatIncluded === 2){
              selectedProduct.vatIncluded = false;
            }
            if(otherTax.vatPercentage !== 0){
              selectedProduct.vat = otherTax.vatPercentage.toString();
            }
          } else {
            selectedProduct.vat = "No VAT No VAT (π.χ. Μισθοδοσία, Αποσβέσεις κλπ)";
            selectedProduct.isTaxLineWithoutVat = "true";
          }
          tax["withholdTax"] = "";
          tax["stamp"] = "";
          tax["fee"] = "";
          selectedProduct["measurementUnit"] = "1";
          dispatch(setSelectedProduct({selectedProduct}));
        } else {
          selectedProduct.isTaxLineWithoutVat = "false";
          selectedProduct.isTaxLine = "false";
          dispatch(setSelectedProduct({selectedProduct}));
        }
      }
      if(name === "withholdTax"){
        const witholdTax = findObjectFromArrayOfObjects(pMDDSettings.withHoldTaxes, e.value, 'code');
        if(witholdTax && witholdTax.taxAmount === "true"){
          selectedProduct.price = !isNaN(Number(witholdTax.tax)) ? Number(witholdTax.tax).toString() : "0";
          selectedProduct.isTaxLine = "true";
          if(witholdTax.vatIncluded === 1 || witholdTax.vatIncluded === 2){
            selectedProduct.isTaxLineWithoutVat = "false";
            if(witholdTax.vatIncluded === 2){
              selectedProduct.vatIncluded = false;
            }
            if(witholdTax.vatPercentage !== 0){
              selectedProduct.vat = witholdTax.vatPercentage.toString();
            }
          } else {
            selectedProduct.vat = "No VAT No VAT (π.χ. Μισθοδοσία, Αποσβέσεις κλπ)";
            selectedProduct.isTaxLineWithoutVat = "true";
          }
          tax["otherTaxes"] = "";
          tax["stamp"] = "";
          tax["fee"] = "";
          tax["otherTaxes"] = "";
          selectedProduct["measurementUnit"] = "1";
          dispatch(setSelectedProduct({selectedProduct}));
        } else {
          selectedProduct.isTaxLineWithoutVat = "false";
          selectedProduct.isTaxLine = "false";
          dispatch(setSelectedProduct({selectedProduct}));
        }
      }
      if(name === "fee"){
        const fee = findObjectFromArrayOfObjects(pMDDSettings.fees, e.value, 'code');
        if(fee && fee.taxAmount === "true"){
          selectedProduct.price = !isNaN(Number(fee.tax)) ? Number(fee.tax).toString() : "0";
          selectedProduct.isTaxLine = "true";
          if(fee.vatIncluded === 1 || fee.vatIncluded === 2){
            selectedProduct.isTaxLineWithoutVat = "false";
            if(fee.vatIncluded === 2){
              selectedProduct.vatIncluded = false;
            }
            if(fee.vatPercentage !== 0){
              selectedProduct.vat = fee.vatPercentage.toString();
            }
          } else {
            selectedProduct.vat = "No VAT No VAT (π.χ. Μισθοδοσία, Αποσβέσεις κλπ)";
            selectedProduct.isTaxLineWithoutVat = "true";
          }
          tax["otherTaxes"] = "";
          tax["stamp"] = "";
          tax["withholdTax"] = "";
          tax["fee"] = e.value;
          selectedProduct["measurementUnit"] = "1";
          dispatch(setSelectedProduct({selectedProduct}));
        } else {
          selectedProduct.isTaxLineWithoutVat = "false";
          selectedProduct.isTaxLine = "false";
          dispatch(setSelectedProduct({selectedProduct}));
        }
      }
      if(name === "stamp"){
        const stamp = findObjectFromArrayOfObjects(pMDDSettings.stamp, e.value, 'code');
        if(stamp && stamp.taxAmount === "true"){
          selectedProduct.price = !isNaN(Number(stamp.tax)) ? Number(stamp.tax).toString() : "0";
          selectedProduct.isTaxLine = "true";
          if(stamp.vatIncluded === 1 || stamp.vatIncluded === 2){
            selectedProduct.isTaxLineWithoutVat = "false";
            if(stamp.vatIncluded === 2){
              selectedProduct.vatIncluded = false;
            }
            if(stamp.vatPercentage !== 0){
              selectedProduct.vat = stamp.vatPercentage.toString();
            }
          } else {
            selectedProduct.vat = "No VAT No VAT (π.χ. Μισθοδοσία, Αποσβέσεις κλπ)";
            selectedProduct.isTaxLineWithoutVat = "true";
          }
          tax["otherTaxes"] = "";
          tax["fee"] = "";
          tax["withholdTax"] = "";
          selectedProduct["measurementUnit"] = "1";
          dispatch(setSelectedProduct({selectedProduct}));
        } else {
            selectedProduct.isTaxLineWithoutVat = "false";
            selectedProduct.isTaxLine = "false";
          dispatch(setSelectedProduct({selectedProduct}));
        }
      }
      dispatch(setSelectedProduct({...selectedProduct, ...tax}));
    }

  let disabledFields = (product.category === undefined || product.category === "");

  return (
      <Row hidden={!checkPermission("products-products/new-othertaxes-read", permissionData)}>
        <div className="position-relative product-form">
          <div className="text-muted mb-2"><i>{t('ProductsNewOtherTaxes.otherTaxes')}</i></div>
          {disabledFields && <div className="overlay_w">{t('ProductsNewOtherTaxes.message')}</div>}
          <Row>
            <Col xs="12" md="3">
              <AdvancedCombobox key={Math.random()} defaultValue={{ value: product.otherTaxes, label: getLabelByValue(otherTaxes, product.otherTaxes,'value'), color: "#76AEB7" }} onChange={(e) => handleOnFieldChange(e, 'otherTaxes', 'ac')} searchableDropdownEnable={true} label={t('ProductsNewOtherTaxes.otherTaxes')}
                data={otherTaxes}
              />
            </Col>
            <Col xs="12" md="3">
              <AdvancedCombobox key={Math.random()} defaultValue={{ value: product.withholdTax, label: getLabelByValue(taxesWithhold, product.withholdTax,'value'), color: "#76AEB7" }} onChange={(e) => handleOnFieldChange(e, 'withholdTax', 'ac')} searchableDropdownEnable={true} label={t('ProductsNewOtherTaxes.withholdTaxes')}
                data={taxesWithhold}
              />
            </Col>
            <Col xs="12" md="3">
              <AdvancedCombobox key={Math.random()} defaultValue={{ value: product.stamp, label: getLabelByValue(taxesStamp, product.stamp,'value'), color: "#76AEB7" }} onChange={(e) => handleOnFieldChange(e, 'stamp', 'ac')} searchableDropdownEnable={true} label={t('ProductsNewOtherTaxes.stamp')}
                data={taxesStamp}
              />
            </Col>
            <Col xs="12" md="3">
              <AdvancedCombobox key={Math.random()} defaultValue={{ value: product.fee, label: getLabelByValue(taxesFees, product.fee,'value'), color: "#76AEB7" }} onChange={(e) => handleOnFieldChange(e, 'fee', 'ac')} searchableDropdownEnable={true} label={t('ProductsNewOtherTaxes.fees')}
                data={taxesFees}
              />
            </Col>
          </Row>
           <p className="mb-4">{t('ProductsNewOtherTaxes.message2')}</p>
          <div className={"text-muted mt-2 mb-2"}><i>Κρατήσεις</i></div>
          <Row>
            <Col md={3}>
              <label>{"Ποσό κρατήσεων"}</label>
              <AccountingBox
                  name={"deductionsEuro"}
                  defaultValue={product.deductionsEuro}
                  onBlur={(e) => {
                    let val = 0;
                    if (!isNaN(parseFloat(e.target.value))) {
                      val = parseFloat(e.target.value).toFixed(2);
                    }
                    dispatch(setSelectedProduct({...product, deductionsEuro: val}))
                  }}
              />
            </Col>
          </Row>
        </div>
      </Row>
  )
}

// Products Accounting Tab
export const ProductsNewAccounting = () => {
  const [t] = useTranslation("common");
  const PRODUCTS_DATA = useSelector( (state) => state.PRODUCTS_DATA );
  const dispatch = useDispatch();
  const ACTIVITY = useSelector((state) => state.ACTIVITY);
  const handleOnChange = (e, type="default", ddname = '') => {
    if (!ACTIVITY.saveNewProduct) dispatch(setSaveNewProduct(true));

    let name;
    let value;
    if (type === 'dp' ) {
      value = e;
      name = ddname;
    } else if (type === "checkbox") {
      value = e.target.checked === true ? "active" : "inactive";
      name = ddname;
    } else {
      value = e.target.value;
      name = e.target.name;
    }
    let productData = Object.assign({}, PRODUCTS_DATA);
    let newProductData = {};
    newProductData[name] = value;
    dispatch(setSelectedProduct({...productData.selectedProduct, ...newProductData}));
  }
  return (
    <React.Fragment>
      <div className="text-muted mb-2"><i>{t('ProductsNewAccounting.accounting')}</i></div>
      <Row>
        <Col xs="12" md="4">
          <div className="mb-3">
            <Input
                name="accountingCode"
                charLimit={20}
                placeholder="Εισάγετε Πρόθεμα Κωδικού Λογαριασμού"
                label="Πρόθεμα Κωδικού Λογαριασμού"
                onChange = {(e) => handleOnChange(e)}
                value={PRODUCTS_DATA.selectedProduct.accountingCode}
            />
          </div>
        </Col>
        <Col md={4}>
          <BetterCheckBox
              name={"accountingNonDeductibleVAT"}
              className={"mt-3"}
              text={"Μη Εκπιπτόμενος Φ.Π.Α."}
              checked={PRODUCTS_DATA.selectedProduct.accountingNonDeductibleVAT === "active"}
              onChange={(e) => handleOnChange(e, "checkbox", "accountingNonDeductibleVAT")}
          />
        </Col>
      </Row>
      <Row>
        <div className="text-muted mb-2"><i>{t("General.bypassAccounting")}:</i></div>
        <Col xs="12" md="4">
          <div className="mb-3">
            <Input
                name="netAmountAccountingCode"
                charLimit={20}
                placeholder="Εισάγετε Κωδικό Λογαριασμού Καθαρής Αξίας"
                label="Κωδικός Λογαριασμού Καθαρής Αξίας"
                onChange = {(e) => handleOnChange(e) }
                value={ PRODUCTS_DATA.selectedProduct.netAmountAccountingCode }
            />
          </div>
        </Col>
        <Col xs="12" md="4">
          <div className="mb-3">
            <Input
                name="vatAccountingCode"
                charLimit={20}
                placeholder="Εισάγετε Κωδικό Λογαριασμού ΦΠΑ"
                label="Κωδικός Λογαριασμού ΦΠΑ"
                onChange = {(e) => handleOnChange(e) }
                value={ PRODUCTS_DATA.selectedProduct.vatAccountingCode }
            />
          </div>
        </Col>
        <Col xs="12" md="4" className={"mb-3"}>
          <Input
              name={"taxAccountCode"}
              charLimit={20}
              placeholder={"Εισάγετε Κωδικό Λογαριασμού Φόρου"}
              label={"Κωδικός λογαριασμού Φόρου"}
              onChange={(e) => handleOnChange(e)}
              value={PRODUCTS_DATA.selectedProduct.taxAccountCode}
          />
        </Col>
      </Row>
    </React.Fragment>
  )
}

// Products Product Codes
export const ProductsNewProductCodes = () => {
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const PRODUCTS_DATA = useSelector( (state) => state.PRODUCTS_DATA );
  const permissionData = JSON.parse(localStorage.getItem("permissions"));

  const parentProductCodes = {
    insert: (e, data) => {

      let productCodeObj = Object.assign({},  PRODUCTS_DATA.newProductCode);
      if (e.key !== "Enter") {
        productCodeObj[data.field] = data.value;

        // special case to populate name
        if (data.field === 'code') {
          if (data.value) {
            const products = getObjectFromArrayOfObjects(PRODUCTS_DATA.products, data.value, data.field);
            if (products.length > 0) {
              productCodeObj['name'] = products['0'].name;
            }
          }
        }

        // special case to populate code
        if (data.field === 'name') {
          if (data.value) {
            const products = getObjectFromArrayOfObjects(PRODUCTS_DATA.products, data.value, data.field);
            if (products.length > 0) {
              productCodeObj['code'] = products['0'].code;
            }
          }
        }

        dispatch(setNewProductCode(productCodeObj)); // Update redux my data values
      }

      // If validated fields done add new row
      if (productCodeObj.name !=='' && productCodeObj.code !=='') {
        let newProductCode = Object.assign({},  PRODUCTS_DATA.newProductCode);
        let productCodes = PRODUCTS_DATA.productCodes.slice();
        newProductCode.id = productCodes.length + 1;
        newProductCode.no = productCodes.length + 1;
        newProductCode.name = productCodeObj.name;
        newProductCode.code = productCodeObj.code;
        dispatch(setProductCodes([...productCodes, newProductCode]));  // update product codes
        dispatch(setNewProductCode(PRODUCTS_DATA.newProductCodeInitialValue)); // Reset new category mydata
      }

      // Add on press enter
      if (e.key === "Enter" && data.field !== 'add') {
        if (data.field === 'name' && data.value === '') {
          toast.error("Product Name should not be empty.");
        } else {
          let newProductCode = Object.assign({},  PRODUCTS_DATA.newProductCode);
          if (newProductCode.field === 'name' && newProductCode.value === '') {
            toast.error("Product Name should not be empty.");
          } else {
            let productCodes = PRODUCTS_DATA.productCodes.slice();
            if (productCodes.find(item => item.name === data.value) === undefined) {
              newProductCode.id = productCodes.length + 1;
              newProductCode.no = productCodes.length + 1;
              newProductCode.name = data.value;
              dispatch(setProductCodes([...productCodes, newProductCode]));  // update product codes
              dispatch(setNewProductCode(PRODUCTS_DATA.newProductCodeInitialValue)); // Reset new category mydata
            } else {
              toast.error("Product Name should be unique.");
            }
          }
        }
      }

      // Fix allow empty row insert on check box click
      if (e.key === "Enter" && data.field !== 'add') {
        let newProductCode = Object.assign({},  PRODUCTS_DATA.newProductCode);
        let productCodes = PRODUCTS_DATA.productCodes.slice();
        newProductCode.id = productCodes.length + 1;
        newProductCode.no = productCodes.length + 1;
        dispatch(setProductCodes([...productCodes, newProductCode]));  // update product codes
        dispatch(setNewProductCode(PRODUCTS_DATA.newProductCodeInitialValue)); // Reset new category mydata
      }

      // Fix allow empty row insert on check box click
      if (data.field === 'add') {
        let newProductCode = Object.assign({},  PRODUCTS_DATA.newProductCode);
        let productCodes = PRODUCTS_DATA.productCodes.slice();
        newProductCode.id = productCodes.length + 1;
        newProductCode.no = productCodes.length + 1;
        newProductCode.name = "";
        newProductCode.code = "";
        dispatch(setProductCodes([...productCodes, newProductCode]));  // update product codes
      }

    },
    update: (data, id) => {
      if (data.name && data.name === '') {
        toast.error("Name is mandatory field.");
      } else {
        let productCodes = PRODUCTS_DATA.productCodes.slice();
        let tempProductCodes = productCodes.slice();
        if (tempProductCodes && tempProductCodes.find(item => item.id === id) !== undefined) {
          tempProductCodes[id-1] = {...tempProductCodes[id - 1], ...data};
          dispatch(setProductCodes(tempProductCodes));
        }
      }
    },
    selected: () => {
      console.log('selected mydata row clicked');
    },
    delete: (id) => {
      let productCodes = PRODUCTS_DATA.productCodes.slice();
      if (id >= 1) {
        let deletedProductCodes = deleteObjectFromArrayOfObjects(productCodes, id,'id');
        toast.success('1 row deleted.');
        dispatch(setProductCodes(deletedProductCodes));
      }
    }
  }


  const supplierCodeColumns = [
    {
      label: t('ProductsNewTags.table2.supplierCode'),
      name: "code",
    },
    {
      label: t('ProductsNewTags.table2.supplierName'),
      name: "name",
    },
    {
      label: t('ProductsNewTags.table2.vat'),
      name: "vat",
    },
  ];

  const productCodeColumns = [
    {
      label: t('ProductsNewTags.table3.code'),
      name: "code",
    },
    {
      label: t('ProductsNewTags.table3.productName'),
      name: "name",
    },
    {
      label: t('ProductsNewTags.table3.alternativeName'),
      name: "alternateName",
    },
  ];

  const productCodesColumns = [
    {
      name: "No",
      field: "no",
    },
    {
      name: t('ProductsNewTags.table.supplier'),
      field: "supplierName", // supplier
      editable:true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: supplierCodeColumns,
          data: [], // TODO: There will be the data for supplier, It is not in current module
          editEnable: false ,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('ProductsNewTags.table.selectSupplierName')
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t('ProductsNewTags.table.productName'),
      field: "name",
      editable:true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: productCodeColumns,
          data: PRODUCTS_DATA.products,
          editEnable: false ,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('ProductsListNew.table.placeholderName')
        },
        // type: 'advanced-combobox',
        type: 'text',
      }
    },
    {
      name: t('ProductsNewTags.table.productCode'),
      field: "code",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: productCodeColumns,
          data: PRODUCTS_DATA.products,
          editEnable: false ,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('ProductsListNew.table.placeholderCode')
        },
        type: 'text',
        // type: 'advanced-combobox',
      }
    },
  ];

  const [tag, setTag] = useState("");

  const addTags = () => {
    console.log("No further details about tags");
    // dispatch(postProductTag())
  }

  const newTag = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name !=="") {
      setTag(value)
    }
  }

  return (
    <React.Fragment>
      <Row hidden={!checkPermission("products-products/new-tags-read", permissionData)}>
        <EditableTable
          key={Math.random()}
          heading={t('ProductsNewTags.productCodes')}
          tableName="Product Codes" // do not change it
          columns={productCodesColumns}
          allowActions={ true }
          allowInsertRow={ true }
          data={ PRODUCTS_DATA.productCodes }
          enableNewRowCheckbox = {true}
          onUpdate={parentProductCodes}
        />
      </Row>
      <Row hidden={!checkPermission("products-products/new-tags-write", permissionData)}>
        <Col className="mt-3" xs="12">
          <div className="text-muted mb-2"><i>{t('ProductsNewTags.productTag')}</i></div>
          <div className="d-flex justify-content-start">
            <Input unlabbled={true} placeholder={t('ProductsNewTags.addTag')} name="tagName" value={tag} className="mr-3" onChange={(e) => newTag(e)} />
            <Button name="save" onClick={() => addTags()} className="mb-3 btn btn-primary btn-sm">{t('ProductsNewTags.addTag')}</Button>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export const ProductsNewVariants = () => {
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const PRODUCTS_DATA = useSelector((state) => state.PRODUCTS_DATA);
  const [variantPropertiesOptions, setVariantPropertiesOptions] = useState([]);
  const [variantName, setVariantName] = useState(PRODUCTS_DATA.selectedProduct.name);
  const [showVariantModal, setShowVariantModal] = useState(false);
  const [showAutoCreateModal, setShowAutoCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteMultipleModal, setShowDeleteMultipleModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const handleSelect = (e, type, dataKey) => {
    if (type === "selectRow") {
      const selectedRows = Array.from(PRODUCTS_DATA.selectedNewVariantsRows);
      if (selectedRows.includes(dataKey)) {
        selectedRows.splice(selectedRows.indexOf(dataKey), 1);
      } else {
        selectedRows.push(dataKey);
      }
      dispatch(setNewSelectedVariantsRows(selectedRows));
    } else if (type === "selectAll") {
      if (!PRODUCTS_DATA.allCheckedNewVariants) {
        dispatch(setNewSelectedVariantsRows(Array.from({ length: PRODUCTS_DATA.selectedProduct.displayedVariants.length }, (v, index) => index)));
        dispatch(setAllCheckedNewVariants(true));
      } else {
        dispatch(setNewSelectedVariantsRows([]));
        dispatch(setAllCheckedNewVariants(false));
      }
    }
  }

  const variantColumns = [
    {
      field: "select_checkbox",
      name: "Select All",
      width: "40px",
      inputType: {
        type: 'selection',
        select: handleSelect
      }
    },{
      name: 'Ονομασία',
      field: "name",
      width: "35%",
      editable: false,
      inputType: {
        type: 'text',
      }
    },{
      name: 'Ιδιότητες',
      field: "properties",
      width: "25%",
      editable: false,
      inputType: {
        type: 'text',
      }
    },{
      name: 'SKU',
      field: "sku",
      width: "20%",
      editable: false,
      inputType: {
        type: 'text',
      }
    },{
      name: 'Απόθεμα',
      field: "inventoryBalance",
      width: "10%",
      editable: false,
      inputType: {
        type: 'text',
      }
    },{
      name: 'Τιμή',
      field: "totalPrice",
      width: "10%",
      editable: false,
      inputType: {
        type: 'text',
      }
    },
  ];

  useEffect(() => {
    dispatch(setNewVariantSearchTerm(""));
    PRODUCTS_DATA.selectedProduct.productProperties.forEach((property) => {
      if (property.values.length === 0) return;
      let options = property.values.split(",");

      options = [...new Set(options)]; //Remove duplicates

      // Create property options including price variations
      options = options.map((opt) => {
        let priceVariation;
        if (opt.includes(":") && isValidNumber(opt.split(":")[1])) {
          let temp = opt.split(":")[1].trim();
          priceVariation = temp > 0 ? `+${temp}€` : temp < 0 ? `${temp}€` : "";
          opt = opt.split(":")[0].trim();
          return {label: `${opt} ${priceVariation}`, value: opt.trim(), priceVariation: temp};
        }
        return  {label: opt, value: opt.trim()};
      });
      options.unshift({label: "Κενό", value: null});
      setVariantPropertiesOptions(oldArr =>[...oldArr, {name: property.name, options: options}]);
    });
  }, []);

  useEffect(() => {
    const props = PRODUCTS_DATA.newVariant.properties;
    setVariantName(`${PRODUCTS_DATA.selectedProduct.name}  ${Object.values(props).filter((el) => el !== null).join(" - ")}`);
    const priceVariationSum = Object.values(PRODUCTS_DATA.newVariant.priceVariations ?? {}).reduce((a = 0, b = 0) => Number(a) + Number(b), 0);
    const price = Number(PRODUCTS_DATA.newVariant.price);
    const totalPrice = price + priceVariationSum;
    dispatch(setNewVariant({...PRODUCTS_DATA.newVariant, price: price, totalPrice: totalPrice}))
  }, [PRODUCTS_DATA.newVariant.properties]);

  const handleOnChange = (e, type="default", name="") => {
    let newVariantData = structuredClone(PRODUCTS_DATA.newVariant);
    if (type === "property") {
        newVariantData["properties"][name] = e.value;
        if(e.priceVariation) newVariantData["priceVariations"][name] = e.priceVariation;
        else delete newVariantData["priceVariations"][name];
    } else if (type === "input" || type === "accountingBox") {
      newVariantData[name] = e.target.value;
    } else if (type === "checkbox") {
      newVariantData[name] = e.target.value;
    }
    dispatch(setNewVariant({...PRODUCTS_DATA.newVariant, ...newVariantData}));
  }

  const addVariant = () => {
    let newVariant = structuredClone(PRODUCTS_DATA.newVariant);
    let productVariants = structuredClone(PRODUCTS_DATA.selectedProduct.variants);

    if (newVariant.name.length === 0) newVariant.name = variantName;
    newVariant.id = productVariants.length;

    removeEmptyValues(newVariant.properties);
    if (isEmpty(newVariant.properties) || Object.values(newVariant.properties).every(value => value === null)) {
      return toast.error("Οι παραλλαγές πρέπει να έχουν τουλάχιστον μια ιδιότητα.");
    }
    let isDuplicate = false;
    productVariants.forEach((element) => {
      if(JSON.stringify(element.properties) === JSON.stringify(newVariant.properties)) isDuplicate = true;
    });
    if (isDuplicate) {
      return toast.error("Υπάρχει ήδη παραλλαγή με ίδιες ιδιότητες.");
    }
    productVariants.push(newVariant);

    dispatch(addProductNewVariant(newVariant));
    dispatch(setSaveNewProduct(true));
    setShowVariantModal(false);
    toast.success("Προστέθηκε νέα παραλλαγή.");
  }

  const editVariant = () => {
    let newVariant = structuredClone(PRODUCTS_DATA.newVariant);
    let productVariants = structuredClone(PRODUCTS_DATA.selectedProduct.variants);
    let displayedVariants = structuredClone(PRODUCTS_DATA.selectedProduct.displayedVariants);

    if (newVariant.name.length === 0) newVariant.name = variantName;

    removeEmptyValues(newVariant.properties);
    if (isEmpty(newVariant.properties) || Object.values(newVariant.properties).every(value => value === null)) {
      return toast.error("Οι παραλλαγές πρέπει να έχουν τουλάχιστον μια ιδιότητα.");
    }
    const index1 = productVariants.findIndex(obj => obj.id === newVariant.id);
    const index2 = displayedVariants.findIndex(obj => obj.id === newVariant.id);
    if (index1 !== -1) productVariants[index1] = newVariant;
    if (index2 !== -1) displayedVariants[index2] = newVariant;

    dispatch(updateProductNewVariants(productVariants));
    dispatch(setNewProductDisplayedVariants(displayedVariants));
    dispatch(setSaveNewProduct(true));
    setShowVariantModal(false);
    toast.success("Η παραλλαγή ενημερώθηκε.");
  }

  const createNewVariant = () => {
    const startingPrice = Number(PRODUCTS_DATA.selectedProduct.price);
    dispatch(setNewVariant({...PRODUCTS_DATA.newVariantInitialValue, price: startingPrice, totalPrice: startingPrice}));
    setShowVariantModal(true);
  }

  const variantTableActions =  {
    insert: () => {},
    update: () => {},
    selected: (data) => {
      dispatch(setNewVariant(data));
      setShowVariantModal(true);
    },
    delete: (id, rowIndex) => {
      setDeleteIndex(rowIndex)
      setShowDeleteModal(true);
    },
  }

  const handleDelete = () => {
    let displayedVariants = structuredClone(PRODUCTS_DATA.selectedProduct.displayedVariants);
    let variants = structuredClone(PRODUCTS_DATA.selectedProduct.variants);

    const deleted = displayedVariants.splice(deleteIndex, 1);
    if (!isEmpty(deleted)) variants = variants.filter(variant => variant.id !== deleted[0].id);

    dispatch(updateProductNewVariants(variants));
    dispatch(setNewProductDisplayedVariants(displayedVariants));
    setShowDeleteModal(false);
    dispatch(setSaveNewProduct(true));
  }

  const handleDeleteMany = () => {
      let displayedVariants = structuredClone(PRODUCTS_DATA.selectedProduct.displayedVariants);
      let variants = structuredClone(PRODUCTS_DATA.selectedProduct.variants);
      const selectedNewVariantsRows = structuredClone(PRODUCTS_DATA.selectedNewVariantsRows);
      let deletedIds = [];
      selectedNewVariantsRows.sort((x, y) => y - x);

      selectedNewVariantsRows.forEach((selIndex) => {
        let deleted = displayedVariants.splice(selIndex, 1);
        if (!isEmpty(deleted)) deletedIds.push(deleted[0].id);
      });
      variants = variants.filter((variant) => {
        return !deletedIds.includes(variant.id);
      });
      setShowDeleteMultipleModal(false);
      dispatch(setNewSelectedVariantsRows([]));
      dispatch(setAllCheckedNewVariants(false));
      dispatch(setNewProductDisplayedVariants(displayedVariants));
      dispatch(updateProductNewVariants(variants));
      dispatch(setSaveNewProduct(true));
  }

  const autoCreateVariants = () => {
    const productProperties = structuredClone(PRODUCTS_DATA.selectedProduct.productProperties);
    const startingPrice = Number(PRODUCTS_DATA.selectedProduct.price);
    const arrays = productProperties.map((prop) => prop.values.split(",").map(val => { return {[prop.name]: val} }));
    const variants = [];
    let createdCount = 0;

    function createCombos(currentProps, index) {
      if (index === arrays.length) {
        let priceVariation = 0;
        let priceVariations = {};
        let tempCurrentProps = structuredClone(currentProps);
        tempCurrentProps.forEach((prop) => {
          Object.keys(prop).forEach((key) => {
            let temp = prop[key].split(":");
            prop[key] = temp[0].trim();
            if (temp.length === 2 && isValidNumber(temp[1])) {
              priceVariation += Number(temp[1]);
              priceVariations[key] = temp[1];
            }
          });
        });
        // Merge all props
        const combinedProps = Object.assign({}, ...tempCurrentProps);

        let alreadyExists = false;
        PRODUCTS_DATA.selectedProduct.variants?.forEach((variant) => {
          if(JSON.stringify(variant.properties) === JSON.stringify(combinedProps)) {
            alreadyExists = true;
          }
        });
        if (alreadyExists) return;

        let variantName = `${PRODUCTS_DATA.selectedProduct.name}  ${Object.values(combinedProps).filter((el) => el !== null).join(" - ")}`;
        const newVariant = {...PRODUCTS_DATA.newVariantInitialValue, name: variantName, properties: combinedProps, priceVariations: priceVariations, price: startingPrice, totalPrice: startingPrice + priceVariation};
        variants.push(newVariant);
        createdCount++;
        return;
      }

      for (let i = 0; i < arrays[index].length; i++) {
        createCombos(currentProps.concat(arrays[index][i]), index + 1);
      }
    }
    createCombos([], 0);

    dispatch(addProductNewAllVariants(variants));
    dispatch(setNewVariantIds());
    dispatch(setSaveNewProduct(true));
    setShowAutoCreateModal(false);
    createdCount !== 0 ? toast.success(`Δημιουργήθηκαν ${createdCount} παραλλαγές επιτυχώς.`) : toast.info("Έχουν ήδη δημιουργηθεί όλες οι πιθανές παραλλαγές.");
  }

  const searchTermOnChange = (e) => {
    const term = e.target.value;
    const regex = /[{},:.]/g;
    dispatch(setNewVariantSearchTerm(term));

    let result = PRODUCTS_DATA.selectedProduct.variants?.filter((variant) => {
      return variant.name.match(term.trim()) || JSON.stringify(variant.properties).replace(regex).match(term.trim());
    });
    dispatch(setNewProductDisplayedVariants(result));
  }

  const editing = PRODUCTS_DATA.newVariant.id == null;
  return (
      <React.Fragment>
        <Row>
          <Col xs="12" md="4" className="mb-2">
            <Input
                name="search"
                charLimit={100}
                placeholder="Αναζήτηση"
                autoComplete="off"
                onChange={(e) => searchTermOnChange(e)}
                value={PRODUCTS_DATA.newVariantSearchTerm}
            />
          </Col>
          <Col xs="12" md="8" className="mb-2">
            <div className="float-right">
              <Button hidden={PRODUCTS_DATA.selectedNewVariantsRows.length === 0} type="button"
                      onClick={() => setShowDeleteMultipleModal(true)}
                      className="btn btn-primary mb-2 mr-2">Διαγραφή επιλεγμένων</Button>
              <Button disabled={variantPropertiesOptions.length === 0} type="button"
                      onClick={() => setShowAutoCreateModal(true)}
                      className="btn btn-primary mb-2 mr-2">Αυτόματη Δημιουργία</Button>
              <Button disabled={variantPropertiesOptions.length === 0} type="button" onClick={createNewVariant}
                      className="btn btn-primary mb-2">Νέα Παραλλαγή</Button>
            </div>
          </Col>
        </Row>
        <Modal show={showVariantModal} onEscapeKeyDown={() => setShowVariantModal(false)}
               onHide={() => setShowVariantModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{editing ? <strong>Νέα Παραλλαγή</strong> : <strong>Επεξεργασία: {PRODUCTS_DATA.newVariant.name}</strong>}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="position-relative border-bottom mb-3 d-flex align-items-center px-2">
              <Col xs="12" md="4" className="d-flex align-items-center">
                <div className="inline-field d-flex align-items-center acremd  mb-3">
                  <label htmlFor="variant-active" className="mb-0 mr-2">{"Ενεργή Παραλλαγή"}</label>
                  <Checkbox
                      className="mb-2"
                      name="isActive"
                      key={Math.random()}
                      id="variant-active"
                      defaultValue={PRODUCTS_DATA.newVariant.isActive}
                      value={PRODUCTS_DATA.newVariant.isActive === 'active' ? "inactive" : "active"}
                      onChange={(e) => handleOnChange(e, "checkbox", "isActive")}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              {
                variantPropertiesOptions.map((property) => {
                  let defaultValue = PRODUCTS_DATA["newVariant"]["properties"][property.name];
                  return (
                      <Col md={6}>
                        <Dropdown
                            name={property.name}
                            label={<strong>{property.name}</strong>}
                            key={Math.random()}
                            options={property.options}
                            defaultValue={defaultValue ? {label: defaultValue, value: defaultValue} : {label: t("General.empty"), value: null}}
                            enabledValue={true}
                            disabled={!editing}
                            onChange={(e) => handleOnChange(e, "property", property.name)}
                        />
                      </Col>
                  )
                })
              }
            </Row>
            <Row>
              <Col xs="12" md="12">
                <div className="mb-3">
                  <Input
                      name="name"
                      charLimit={200}
                      placeholder={variantName}
                      label={t('General.name')}
                      autoComplete="off"
                      onChange={(e) => handleOnChange(e, 'input', 'name')}
                      value={PRODUCTS_DATA.newVariant.name}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="12">
                <div className="mb-3">
                  <Input
                      name="sku"
                      charLimit={100}
                      placeholder="SKU"
                      label="SKU"
                      autoComplete="off"
                      onChange={(e) => handleOnChange(e, 'input', 'sku')}
                      value={PRODUCTS_DATA.newVariant.sku}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="mb-3">
                  <FormLabel className="" htmlFor="price">{t('ProductsNewGeneral.preferredPrice.preferredPrice')}</FormLabel>
                  <AccountingBox
                      name="price"
                      defaultValue={PRODUCTS_DATA.newVariant.price}
                      onChange={(e) => handleOnChange(e, 'accountingBox', 'price')}
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="mb-3">
                  <FormLabel className="" htmlFor="price">{t('General.totalPrice')}</FormLabel>
                  <AccountingBox
                      name="totalPrice"
                      disabled={true}
                      defaultValue={PRODUCTS_DATA.newVariant.totalPrice}
                  />
                </div>
                <div>
                  {
                    Object.entries(PRODUCTS_DATA.newVariant.priceVariations ?? {}).map(([key, val]) => {
                      return (<div className="mb-2">{`${key}: ${val > 0 ? `+${val}€` : val < 0 ? `${val}€` : ""}`}</div>)
                    })
                  }
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={() => setShowVariantModal(false)}>
              Κλείσιμο
            </Button>
            {editing ?
                <Button disabled={variantPropertiesOptions.length === 0} type="button" onClick={addVariant}
                        className="btn btn-primary mb-2 ml-2 float-right">Δημιουργία Παραλλαγής</Button>
                :
                <Button disabled={variantPropertiesOptions.length === 0} type="button" onClick={editVariant}
                        className="btn btn-primary mb-2 ml-2 float-right">Ενημέρωση Παραλλαγής</Button>
            }
          </Modal.Footer>
        </Modal>
        {/*DELETE CONFIRM*/}
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header>
            <Modal.Title>{t('ProductVariants.delete')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t('ProductVariants.confirmDelete')}
          </Modal.Body>
          <Modal.Footer>
            <Button
                variant="primary"
                onClick={handleDelete}
            >
              {t('DataPanelIndex.yes')}
            </Button>
            <Button
                variant="outline-primary"
                onClick={() => setShowDeleteModal(false)}
            >
              {t('DataPanelIndex.no')}
            </Button>
          </Modal.Footer>
        </Modal>
        {/*DELETE MULTIPLE CONFIRM*/}
        <Modal show={showDeleteMultipleModal} onHide={() => setShowDeleteMultipleModal(false)}>
          <Modal.Header>
            <Modal.Title>{t('ProductVariants.deleteMultiple')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t('ProductVariants.confirmDeleteMultiple')}
          </Modal.Body>
          <Modal.Footer>
            <Button
                variant="primary"
                onClick={handleDeleteMany}
            >
              {t('DataPanelIndex.yes')}
            </Button>
            <Button
                variant="outline-primary"
                onClick={() => setShowDeleteMultipleModal(false)}
            >
              {t('DataPanelIndex.no')}
            </Button>
          </Modal.Footer>
        </Modal>
        {/*AUTO CREATE CONFIRM*/}
        <Modal show={showAutoCreateModal} onHide={() => setShowAutoCreateModal(false)}>
          <Modal.Header>
            <Modal.Title>{t('ProductVariants.autoCreate')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t('ProductVariants.confirmAutoCreate')}
          </Modal.Body>
          <Modal.Footer>
            <Button
                variant="primary"
                onClick={autoCreateVariants}
            >
              {t('DataPanelIndex.create')}
            </Button>
            <Button
                variant="outline-primary"
                onClick={() => setShowAutoCreateModal(false)}
            >
              {t('DataPanelIndex.cancel')}
            </Button>
          </Modal.Footer>
        </Modal>
        <Row>
          <Col md={12}>
            <EditableTable
                key={Math.random()}
                heading="Πίνακας Παραλλαγών Είδους"
                tableName="Product Variants"
                columns={variantColumns}
                allowActions={true}
                allowInsertRow={false}
                enableNewRowCheckbox={false}
                data={PRODUCTS_DATA.selectedProduct.displayedVariants}
                onUpdate={variantTableActions}
                selectedRows={PRODUCTS_DATA.selectedNewVariantsRows}
                allChecked={PRODUCTS_DATA.allCheckedNewVariants}
            />
          </Col>
        </Row>
      </React.Fragment>
  );
}
