// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hourReportTable thead tr {
    background-color: var(--light-skyblue);
}

.hourReportTable tr td, .hourReportTable tr th {
    border: 1px solid rgba(128, 128, 128, 0.3);
}

.hourReportTable tr td {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 700;
}

.hourReportTable tbody tr:hover {
    border: 1px solid rgba(128, 128, 128, 0.3);
    background-color: #d9d9d9 !important;
}
`, "",{"version":3,"sources":["webpack://./src/_components/layouts/data-panel/Payroll/style.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;AAC1C;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,0CAA0C;IAC1C,oCAAoC;AACxC","sourcesContent":[".hourReportTable thead tr {\r\n    background-color: var(--light-skyblue);\r\n}\r\n\r\n.hourReportTable tr td, .hourReportTable tr th {\r\n    border: 1px solid rgba(128, 128, 128, 0.3);\r\n}\r\n\r\n.hourReportTable tr td {\r\n    padding-top: 5px;\r\n    padding-bottom: 5px;\r\n    font-weight: 700;\r\n}\r\n\r\n.hourReportTable tbody tr:hover {\r\n    border: 1px solid rgba(128, 128, 128, 0.3);\r\n    background-color: #d9d9d9 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
