import React, {useState} from "react";
import {useSelector} from "react-redux";
import SearchTable from "../../../primer/Search-Table";
import {getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import {getOptionsByData} from "../../../../_helpers/commonFunctions";
import {useTranslation} from "react-i18next";
import {classicStyleBelowNavbar} from "../Statics";

const SearchClient = () => {
    const [t] = useTranslation('common');
    const clientCategories = getObjectFromArrayOfObjects(useSelector((state) => state.CLIENTS_DATA.clientsSettings.categories), "active", "isActive");
    let clientCategoriesOption = getOptionsByData(clientCategories, "_id", "category");
    clientCategoriesOption = [{value: "", label: t("Null"), color: "#76AEB7"}, ...clientCategoriesOption]
    const searchClientFilters = {
        filters: [
            {
                name: "clientName",
                label: t("ClientsSearch.clientName"),
                type: "input",
            },
            {
                name: "contactName",
                label: t("ClientsSearch.contactName"),
                type: "input",
            },
            {
                name: "email",
                label: "E-Mail",
                type: "input",
            },
            {
                name: "vatNumber",
                label: t("ClientsSearch.clientVatNumber"),
                type: "input",
            },
            {
                name: "phone",
                label: t("ClientsSearch.clientPhone"),
                type: "input",
            },
            {
                name: "manualCode",
                label: t("ClientsSearch.manualCode"),
                type: "input",
            },
            {
                name: "systemCode",
                label: t("ClientsSearch.clientCode"),
                type: "input",
            },
            {
                name: "category",
                label: t("ClientsSearch.clientCategory"),
                type: "multiselect",
                options: clientCategoriesOption
            },
            {
                name: "isActive",
                label: t("ClientsSearch.inactiveClient"),
                type: "checkbox",
            },
        ],
        actions: ["quick-edit", "delete"],
    };
    const scColumns = [
        {
            name: t('ClientsSearch.table.clientCode'),
            field: "systemCode",
            width: "10%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsSearch.table.manualCode'),
            field: "manualCode",
            width: "10%",
            hiddenColumn: true,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsSearch.table.clientName'),
            field: "name",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsSearch.table.clientCategory'),
            field: "categoryName",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsSearch.table.vatNo'),
            field: "vatNumber",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsSearch.table.contactName'),
            field: "name",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsSearch.table.phoneNo'),
            field: "phone",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
    ]

    const [tableColumns, setTableColumns] = useState(scColumns.slice());

    // It will hide or show the table columns for searchtable.
    const handleColumnShowHide = (field) => {
        let tempTc = tableColumns.slice();
        let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
        const index = tempTc.findIndex((item) => field === item.field);
        if (object.length > 0) {
            tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
            setTableColumns(tempTc);
        }
    }
    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName="Client Search"
                key={"Client Search"}
                hideYearFilter={true}
                allowPagination={true}
                allowToggleColumns={true}
                showDummyData={false}
                columns={tableColumns}
                toggleColumns={handleColumnShowHide}
                allowSelectionCheckbox={false}
                data={[]}  // Empty by default
                filtersConfig={searchClientFilters}
            />
        </div>
    )
}

export default SearchClient;
