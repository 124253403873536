import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Badge, Button, Col, Row, Table} from "react-bootstrap";
import Input from "../../../common/Input";
import Dropdown from "../../../primer/Dropdown";
import BootstrapDropdown from "react-bootstrap/Dropdown";
import DateBox from "../../../primer/DateBox";
import moment from "moment/moment";
import axios from "axios";
import {
    setPrimerPaymentsRequestData,
    setPrimerPaymentsResponseData
} from "../../../../_reducers/DataPanel/Clients/clientSlice";
import EditableTable from "../../../primer/Editable-Table";
import {checkPermission, getCurrentUser, getGreekCurrencyFormat} from "../../../../_helpers/commonFunctions";
import {toast} from "react-toastify";
import * as XLSX from "xlsx";
import {jsPDF} from "jspdf";
import {liberationSerif} from "../../../../fonts/liberationSerif";
import {dejavuSans} from "../../../../fonts/DejaVuSans-normal";
import {dejavuSansBold} from "../../../../fonts/DejaVuSans-Bold-bold";
import {classicStyleBelowNavbar} from "../Statics";

export const ReportsPrimerPayments = () => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const requestData = useSelector((state) => state.CLIENTS_DATA.reportPrimerPaymentsRequestData);
    const responseData = useSelector((state) => state.CLIENTS_DATA.reportPrimerPaymentsResponseData);
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const [firstLoad, setFirstLoad] = useState(true);
    const [subscriptionNames, setSubscriptionNames] = useState([]);
    const [pageChanged, setPageChanged] = useState(false);
    const [incrKey, setIncrKey] = useState(0);
    const [loading, setLoading] = useState(false);
    const isSpecial = getCurrentUser()?.permissions?.find((el) => el.permissionName === "custom-peiraios")
        || getCurrentUser()?.permissions?.find((el) => el.permissionName === "custom-daras")

    const statusOptions = [
        {label: "Ενεργές", value: "active"},
        {label: "Ανενεργές", value: "inactive"},
        {label: "Ενεργές & Ανενεργές", value: "both"}
    ]

    const voucherOptions = [
        {label: "Προβολή όλων", value: "both"},
        {label: "Προβολή πληρωμών με voucher ", value: "true"},
        {label: "Προβολή πληρωμών χωρίς voucher", value: "false"}
    ]

    const columns = [
        {
            name: "Αρ. Συνδρομής",
            field: "subscription_id",
            hiddenColumn: false,
            disabled: true,
            width: "5%",
            inputType: {
                type: "text",
            }
        },
        {
            name: "Όνομα πελάτη",
            field: "clientName",
            hiddenColumn: false,
            disabled: true,
            width: checkPermission("custom-viewAllFees", permissionsData) ? "10%" : "20%",
            inputType: {
                type: "text",
            }
        },
        {
            name: "ΑΦΜ Πελάτη",
            field: "clientVATNumber",
            hiddenColumn: false,
            disabled: true,
            width: "10%",
            inputType: {
                type: "text",
            }
        },
        {
            name: "Όνομα συνδρομής",
            field: "subscriptionName",
            hiddenColumn: false,
            disabled: true,
            width: "15%",
            inputType: {
                type: "text",
            }
        },
        {
            name: "Ημερομηνία Πληρωμής",
            field: "paymentDate",
            hiddenColumn: false,
            disabled: true,
            width: "10%",
            inputType: {
                type: "text",
            }
        },
        {
            name: "Ημερομηνία Έναρξης Συνδρομής",
            field: "startDate",
            hiddenColumn: false,
            disabled: true,
            width: "10%",
            inputType: {
                type: "text",
            }
        },
        {
            name: "Ημερομηνία Επόμενης Πληρωμής",
            field: "nextPaymentDate",
            hiddenColumn: false,
            disabled: true,
            width: "10%",
            inputType: {
                type: "text",
            }
        },
        {
            name: "Κατάσταση",
            field: "status",
            hiddenColumn: checkPermission("custom-viewAllFees", permissionsData),
            disabled: true,
            width: "5%",
            inputType: {
                type: "text",
            }
        },
        {
            name: "TID",
            field: "tid",
            hiddenColumn: !checkPermission("custom-viewAllFees", permissionsData),
            disabled: true,
            width: "5%",
            inputType: {
                type: "text",
            }
        },
        {
            name: (checkPermission("custom-peiraios", permissionsData) || checkPermission("custom-daras", permissionsData) || checkPermission("custom-cardlink", permissionsData))
                    && !checkPermission("custom-epay", permissionsData) ? "Καθαρή Αξία Βασικού Πακέτου" : "Καθαρή Αξία",
            field: "price",
            hiddenColumn: false,
            editable: true,
            disabled: true,
            width: "7%",
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: "Προμήθεια",
            field: "fee",
            hiddenColumn: false,
            editable: true,
            disabled: true,
            width: "5%",
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: "Προμήθεια ePay",
            field: "feeEpay",
            hiddenColumn: !checkPermission("custom-viewAllFees", permissionsData),
            editable: true,
            disabled: true,
            width: "5%",
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: "Προμήθεια Πειραιώς",
            field: "feePeiraios",
            hiddenColumn: !checkPermission("custom-viewAllFees", permissionsData),
            editable: true,
            disabled: true,
            width: "5%",
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: "Προμήθεια Μεσαζόντων",
            field: "feeDaras",
            hiddenColumn: !checkPermission("custom-viewAllFees", permissionsData),
            editable: true,
            disabled: true,
            width: "5%",
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: "Προμήθεια Cardlink",
            field: "feeCardlink",
            hiddenColumn: !checkPermission("custom-viewAllFees", permissionsData),
            editable: true,
            disabled: true,
            width: "5%",
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
    ]

    useEffect(() => {
        if(firstLoad) {
            setFirstLoad(false);
            getSubscriptionNames();
            if (isSpecial) dispatch(setPrimerPaymentsRequestData({...requestData, paymentDateFrom: "01/02/2024", startDateFrom: "01/02/2024"}))
        }
    }, []);

    useEffect(() => {
        if (pageChanged === true) {
            setPageChanged(false);
            fetchReport(false);
        }
    }, [pageChanged])

    const getSubscriptionNames = () => {
        axios.get(process.env.REACT_APP_API_URL2 + `/settings/get-subscription-names`, {
            headers: { "Content-Type": "application/json" },
            params: {
                company: company.id,
                user: JSON.parse(localStorage.getItem("user"))._id,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                let finalData = [];
                if(res.data.data && res.data.data?.length > 0) {
                    res.data.data.forEach((sub) => {
                        finalData.push({
                            label: sub,
                            value: sub,
                        })
                    })
                }
                setSubscriptionNames(finalData);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleOnChange = (e, type, name) => {
        if (type === "input") {
            dispatch(setPrimerPaymentsRequestData({...requestData, [name] : e.target.value}));
        } else if (type === "dropdown") {
            dispatch(setPrimerPaymentsRequestData({...requestData, [name] : e.value}));
        } else if (type === "msdd") {
            dispatch(setPrimerPaymentsRequestData({...requestData, [name] : e}));
        } else if (type === "datebox") {
            if (moment(e).isValid()) {
                dispatch(setPrimerPaymentsRequestData({...requestData, [name] : moment(e).format("DD/MM/YYYY")}));
            } else {
                dispatch(setPrimerPaymentsRequestData({...requestData, [name] : ""}));
            }
        }
    }

    const fetchReport = (showMsg) => {
        setLoading(true);
        let cloneReq = {...requestData};
        if (showMsg) cloneReq.page = 1;
        dispatch(setPrimerPaymentsRequestData({...cloneReq}));
        axios.get(process.env.REACT_APP_API_URL2 + `/client/get-primer-payments-report`, {
            headers: { "Content-Type": "application/json" },
            params: {
                ...cloneReq,
                company: company.id,
                user: JSON.parse(localStorage.getItem("user"))._id
            }
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                dispatch(setPrimerPaymentsResponseData(res.data.data));
                setIncrKey(incrKey + 1);
                if(showMsg) toast.success("Τα δεδομένα ανανεώθηκαν.", {autoClose: 1000});
            }
        }).catch((err) => {
            setLoading(false);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            console.log(err);
        })
    }

    const firstPage = () => {
        dispatch(setPrimerPaymentsRequestData({...requestData, page: 1}));
        setPageChanged(true);
    }
    const previousPage = () => {
        dispatch(setPrimerPaymentsRequestData({...requestData, page: requestData.page - 1}));
        setPageChanged(true);
    }
    const nextPage = () => {
        dispatch(setPrimerPaymentsRequestData({...requestData, page: requestData.page + 1}));
        setPageChanged(true);
    }
    const lastPage = () => {
        dispatch(setPrimerPaymentsRequestData({...requestData, page: responseData.totalPages}));
        setPageChanged(true);
    }

    const handlePDFExport = async () => {
        try {
            const apiResponse = await axios.get(process.env.REACT_APP_API_URL2 + `/client/get-primer-payments-report`, {
                headers: {"Content-Type": "application/json"},
                params: {
                    ...requestData,
                    page: -1,
                    company: company.id,
                    user: JSON.parse(localStorage.getItem("user"))._id
                }
            })
            if (apiResponse?.data?.status === "200") {
                let rows = [];
                let basic = (checkPermission("custom-peiraios", permissionsData) || checkPermission("custom-daras", permissionsData) || checkPermission("custom-cardlink")) && !checkPermission("custom-epay", permissionsData)
                let headerColumns;
                if (!checkPermission("custom-viewAllFees", permissionsData)) {
                    headerColumns = ["Αρ. Συνδρομής", "Όνομα Πελάτη", "ΑΦΜ Πελάτη", "Όνομα Συνδρομής", "Ημερομηνία Πληρωμής", "Ημερομηνία Έναρξης Συνδρομής", "Ημερομηνία Επόμενης Πληρωμής", "Κατάσταση", basic ? "Καθαρή Αξία Βασικού Πακέτου" : "Καθαρή Αξία", "Προμήθεια"]
                } else {
                    headerColumns = ["Αρ. Συνδρομής", "Όνομα Πελάτη", "ΑΦΜ Πελάτη", "Όνομα Συνδρομής", "Ημερομηνία Πληρωμής", "Ημερομηνία Έναρξης Συνδρομής", "Ημερομηνία Επόμενης Πληρωμής", "TID", basic ? "Καθαρή Αξία Βασικού Πακέτου" : "Καθαρή Αξία", "Προμήθεια", "Προμήθεια ePay", "Προμήθεια Πειραιώς", "Προμήθεια Μεσαζόντων", "Προμήθεια Cardlink"]
                }
                if (apiResponse.data.data.data && apiResponse.data.data.data?.length > 0) {
                    for (let row of apiResponse.data.data.data) {
                        if (!checkPermission("custom-viewAllFees", permissionsData)) {
                            rows.push([row.subscription_id, row.clientName, row.clientVATNumber, row.subscriptionName, row.paymentDate, row.startDate, row.nextPaymentDate, row.status, row.price, row.fee])
                        } else {
                            rows.push([row.subscription_id, row.clientName, row.clientVATNumber, row.subscriptionName, row.paymentDate, row.startDate, row.nextPaymentDate, row.tid, row.price, row.fee,
                                row.feeEpay ? row.feeEpay : 0,
                                row.feePeiraios ? row.feePeiraios : 0,
                                row.feeDaras ? row.feeDaras : 0,
                                row.feeCardlink ? row.feeCardlink : 0,
                            ])
                        }
                    }
                    if (!checkPermission("custom-viewAllFees", permissionsData)) {
                        rows.push([
                            {content: "", styles: {fillColor: "#f1fdff"}},
                            {content: "", styles: {fillColor: "#f1fdff"}},
                            {content: "", styles: {fillColor: "#f1fdff"}},
                            {content: "", styles: {fillColor: "#f1fdff"}},
                            {content: "", styles: {fillColor: "#f1fdff"}},
                            {content: "", styles: {fillColor: "#f1fdff"}},
                            {content: "", styles: {fillColor: "#f1fdff"}},
                            {content: "ΣΥΝΟΛΑ", styles: {fillColor: "#f1fdff"}},
                            {content: apiResponse.data.data.totalNetValue, styles: {fillColor: "#f1fdff"}},
                            {content: apiResponse.data.data.totalFeeValue, styles: {fillColor: "#f1fdff"}}
                        ])
                    } else {
                        rows.push([
                            {content: "", styles: {fillColor: "#f1fdff"}},
                            {content: "", styles: {fillColor: "#f1fdff"}},
                            {content: "", styles: {fillColor: "#f1fdff"}},
                            {content: "", styles: {fillColor: "#f1fdff"}},
                            {content: "", styles: {fillColor: "#f1fdff"}},
                            {content: "", styles: {fillColor: "#f1fdff"}},
                            {content: "", styles: {fillColor: "#f1fdff"}},
                            {content: "ΣΥΝΟΛΑ", styles: {fillColor: "#f1fdff"}},
                            {content: apiResponse.data.data.totalNetValue, styles: {fillColor: "#f1fdff"}},
                            {content: apiResponse.data.data.totalFeeValue, styles: {fillColor: "#f1fdff"}},
                            {content: apiResponse.data.data?.totalFeeEpay ? apiResponse.data.data.totalFeeEpay : "0", styles: {fillColor: "#f1fdff"}},
                            {content: apiResponse.data.data?.totalFeePeiraos ? apiResponse.data.data.totalFeePeiraos : "0", styles: {fillColor: "#f1fdff"}},
                            {content: apiResponse.data.data?.totalFeeDaras ? apiResponse.data.data?.totalFeeDaras : "0", styles: {fillColor: "#f1fdff"}},
                            {content: apiResponse.data.data?.totalFeeCardlink ? apiResponse.data.data?.totalFeeCardlink : "0", styles: {fillColor: "#f1fdff"}},
                        ])
                    }
                }
                const doc = new jsPDF('landscape');
                let y = 20;
                doc.addFileToVFS("arialuni.ttf", liberationSerif);
                doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
                doc.addFileToVFS("dejavusans.ttf", dejavuSans);
                doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
                doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
                doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
                doc.setFont("DejaVu Sans Bold");
                if (!checkPermission("custom-viewAllFees", permissionsData)) {
                    doc.setFontSize(12);
                } else {
                    doc.setFontSize(10);
                }
                const header = doc.splitTextToSize(`Συνδρομές & Πληρωμές Συνδρομών`, 200);
                const headerString = header.join("\n");
                doc.text(headerString, doc.internal.pageSize.getWidth() / 4, 9, {alignment: "center"});
                doc.setFont("DejaVu Sans Normal");
                const imgData = 'assets/images/prrimer-logo.png';
                const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
                const imgY = 3; // adjust the position as needed
                doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
                doc.setFont("DejaVu Sans Normal");
                doc.autoTable({
                    startY: y,
                    head: [headerColumns],
                    body: rows,
                    rowPageBreak: "avoid",
                    styles: {
                        font: "DejaVu Sans Normal",
                        fontSize: checkPermission("custom-viewAllFees", permissionsData) ? 6 : 8, // set font size
                        halign: "center", // set alignment
                    },
                })
                const pageCount = doc.getNumberOfPages();
                // Add the pagination to each page
                for (let i = 1; i <= pageCount; i++) {
                    doc.setPage(i);
                    doc.setFontSize(10);
                    doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
                }
                doc.save("Συνδρομές.pdf");
            } else {
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            }
        } catch (error) {
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            console.log(error);
        }
    }

    const handleExcelExport = async () => {
        try {
            const apiResponse = await axios.get(process.env.REACT_APP_API_URL2 + `/client/get-primer-payments-report`, {
                headers: { "Content-Type": "application/json" },
                params: {
                    ...requestData,
                    page: -1,
                    company: company.id,
                    user: JSON.parse(localStorage.getItem("user"))._id
                }
            })
            if (apiResponse?.data?.status === "200") {
                let rows = [];
                let basic = (checkPermission("custom-peiraios", permissionsData) || checkPermission("custom-daras", permissionsData) || checkPermission("custom-cardlink", permissionsData)) && !checkPermission("custom-epay", permissionsData)
                if (!checkPermission("custom-viewAllFees", permissionsData)) {
                    rows.push(["Αρ. Συνδρομής", "Όνομα Πελάτη", "ΑΦΜ Πελάτη", "Όνομα Συνδρομής", "Ημερομηνία Πληρωμής", "Ημερομηνία Έναρξης Συνδρομής", "Ημερομηνία Επόμενης Πληρωμής", "Κατάσταση", basic ? "Καθαρή Αξία Βασικού Πακέτου" : "Καθαρή Αξία", "Προμήθεια"]);
                } else {
                    rows.push(["Αρ. Συνδρομής", "Όνομα Πελάτη", "ΑΦΜ Πελάτη", "Όνομα Συνδρομής", "Ημερομηνία Πληρωμής", "Ημερομηνία Έναρξης Συνδρομής", "Ημερομηνία Επόμενης Πληρωμής", "TID", basic ? "Καθαρή Αξία Βασικού Πακέτου" : "Καθαρή Αξία", "Προμήθεια", "Προμήθεια ePay", "Προμήθεια Πειραιώς", "Προμήθεια Μεσαζόντων", "Προμήθεια Cardlink"]);
                }
                if (apiResponse.data.data.data && apiResponse.data.data.data?.length > 0) {
                    for (let row of apiResponse.data.data.data) {
                        if (!checkPermission("custom-viewAllFees", permissionsData)) {
                            rows.push([row.subscription_id, row.clientName, row.clientVATNumber, row.subscriptionName, row.paymentDate, row.startDate, row.nextPaymentDate, row.status, row.price, row.fee])
                        } else {
                            rows.push([row.subscription_id, row.clientName, row.clientVATNumber, row.subscriptionName, row.paymentDate, row.startDate, row.nextPaymentDate, row.tid, row.price, row.fee,
                                row.feeEpay ? row.feeEpay : 0,
                                row.feePeiraios ? row.feePeiraios : 0,
                                row.feeDaras ? row.feeDaras : 0,
                                row.feeCardlink ? row.feeCardlink : 0
                            ])
                        }
                    }
                }
                const workSheet = XLSX.utils.aoa_to_sheet(rows);
                const workBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workBook, workSheet, "Πληρωμές Συνδρομών");
                XLSX.writeFile(workBook, 'Συνδρομές.xlsx');
            } else {
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            }
        } catch (error) {
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            console.log(error);
        }
    }

    const getPercentage = (a, b) => {
        if (!a || !b || isNaN(a) || isNaN(b)) {
            return 0;
        } else {
            return `${Number(a/b * 100).toFixed(2)}%`;
        }
    }

    return (
        <div style={classicStyleBelowNavbar} onKeyDown={(e) => {
            if (e.key === "Enter") fetchReport(true)}
        }>
            <Row>
                <Col md={3}>
                    <Input
                        name="filterClientName"
                        label="Όνομα/ΑΦΜ Πελάτη"
                        autoComplete="off"
                        value={requestData.clientName}
                        onChange={(e) => handleOnChange(e, "input", "clientName")}
                    />
                </Col>
                <Col md={3}>
                    <Dropdown
                        name="filterSubscriptionNames"
                        label="Συνδρομές"
                        key={Math.random()}
                        multiSelect={true}
                        options={subscriptionNames}
                        defaultValue={requestData.subscriptionNames}
                        onChange={(e) => handleOnChange(e, "msdd", "subscriptionNames")}
                    />
                </Col>
                <Col md={3}>
                    <DateBox
                        name="paymentDateFrom"
                        label="Ημερομηνία Πληρωμής από"
                        selected={moment(requestData.paymentDateFrom, "DD/MM/YYYY").isValid() ? moment(requestData.paymentDateFrom, "DD/MM/YYYY").toDate() : ""}
                        minDate={isSpecial ? moment("01/02/2024", "DD/MM/YYYY").toDate() : null}
                        onChange={(e) => handleOnChange(e, "datebox", "paymentDateFrom")}
                    />
                </Col>
                <Col md={3}>
                    <DateBox
                        name="paymentDateTo"
                        label="Ημερομηνία Πληρωμής μέχρι"
                        selected={moment(requestData.paymentDateTo, "DD/MM/YYYY").isValid() ? moment(requestData.paymentDateTo, "DD/MM/YYYY").toDate() : ""}
                        minDate={isSpecial ? moment("01/02/2024", "DD/MM/YYYY").toDate() : null}
                        onChange={(e) => handleOnChange(e, "datebox", "paymentDateTo")}
                    />
                </Col>
                <Col md={3}>
                    <DateBox
                        name="startDateFrom"
                        label="Ημερομηνία Έναρξης από"
                        selected={moment(requestData.startDateFrom, "DD/MM/YYYY").isValid() ? moment(requestData.startDateFrom, "DD/MM/YYYY").toDate() : ""}
                        minDate={isSpecial ? moment("01/02/2024", "DD/MM/YYYY").toDate() : null}
                        onChange={(e) => handleOnChange(e, "datebox", "startDateFrom")}
                    />
                </Col>
                <Col md={3}>
                    <DateBox
                        name="startDateTo"
                        label="Ημερομηνία Έναρξης μέχρι"
                        selected={moment(requestData.startDateTo, "DD/MM/YYYY").isValid() ? moment(requestData.startDateTo, "DD/MM/YYYY").toDate() : ""}
                        minDate={isSpecial ? moment("01/02/2024", "DD/MM/YYYY").toDate() : null}
                        onChange={(e) => handleOnChange(e, "datebox", "startDateTo")}
                    />
                </Col>
                <Col md={3}>
                    <Dropdown
                        name={"status"}
                        label={"Κατάσταση Συνδρομών"}
                        key={Math.random()}
                        options={statusOptions}
                        defaultValue={statusOptions.find((el) => el.value === requestData.status)}
                        onChange={(e) => handleOnChange(e, "dropdown", "status")}
                    />
                </Col>
                <Col md={3}>
                    <Dropdown
                        name={"voucher"}
                        label={"Φιλτράρισμα Voucher"}
                        key={Math.random()}
                        options={voucherOptions}
                        defaultValue={voucherOptions.find((el) => el.value === requestData.voucher)}
                        onChange={(e) => handleOnChange(e, "dropdown", "voucher")}
                    />
                </Col>
            </Row>
            {checkPermission("custom-viewAllFees", permissionsData) && (
                <Row>
                    <Col md={3}>
                        <Input
                            name={"tid"}
                            label={"TID"}
                            value={requestData.tid}
                            autoComplete={"off"}
                            onChange={(e) => handleOnChange(e, "input", "tid")}
                        />
                    </Col>
                </Row>
            )}
            <Row className="mb-3 mt-2 justify-content-end">
                <Col md={3} style={{display: "flex", justifyContent: "right"}}>
                    <Button onClick={() => fetchReport(true)} disabled={loading}>Εφαρμογή φίλτρων και αναζήτηση</Button>
                </Col>
            </Row>
            {responseData.totalPages > 0 ? (
                <React.Fragment>
                    <hr/>
                    <Row className="mb-3 justify-content-end">
                        <Col md={9} style={{display: "flex", justifyContent: "right"}}>
                            <Button size="sm" className="mr-1" onClick={() => firstPage()} disabled={requestData.page === 1 || loading}>{"<< Πρώτη"}</Button>
                            <Button size="sm" className="mr-1" onClick={() => previousPage()} disabled={requestData.page === 1 || loading}>{"< Προηγούμενη"}</Button>
                            <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1" bg="primary">Σελίδα {requestData.page} / {responseData.totalPages}</Badge>
                            <Button size="sm" className="mr-1" onClick={() => nextPage()} disabled={requestData.page === responseData.totalPages || loading}>{"Επόμενη >"}</Button>
                            <Button size="sm" className="mr-1" onClick={() => lastPage()} disabled={requestData.page === responseData.totalPages || loading}>{"Τελευταία >>"}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <EditableTable
                            key={"Primer Payments Report" + incrKey}
                            tableName="Primer Payments Report"
                            data={responseData.data}
                            allowPagination={false}
                            allowToggleColumns={false}
                            allowInsertRow={false}
                            editRedirectAllowed={true}
                            enableNewRowCheckbox={false}
                            columns={columns}
                            allowBulkActions={false}
                            allowPrintResults={checkPermission("clients-reports/reportsprimerpayments-print", permissionsData) === true}
                            allowActions={false}
                        />
                    </Row>
                    <Row className="mb-3 justify-content-end">
                        <Col md={4}>
                            <Table bordered>
                                <tbody>
                                {checkPermission("custom-viewAllFees", permissionsData) && (
                                    <React.Fragment>
                                        <tr>
                                            <td style={{fontWeight: "700"}}>Συνολικό ποσό προμήθειας ePay</td>
                                            <td>{responseData?.totalFeeEpay ? getGreekCurrencyFormat(responseData.totalFeeEpay) : "0,00 €"} ({getPercentage(responseData?.totalFeeEpay, responseData.totalNetValue)})</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight: "700"}}>Συνολικό ποσό προμήθειας Πειραιώς</td>
                                            <td>{responseData?.totalFeePeiraos ? getGreekCurrencyFormat(responseData.totalFeePeiraos) : "0,00 €"} ({getPercentage(responseData?.totalFeePeiraos, responseData.totalNetValue)})</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight: "700"}}>Συνολικό ποσό προμήθειας Μεσαζόντων</td>
                                            <td>{responseData?.totalFeeDaras ? getGreekCurrencyFormat(responseData.totalFeeDaras) : "0,00 €"} ({getPercentage(responseData?.totalFeeDaras, responseData.totalNetValue)})</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight: "700"}}>Συνολικό ποσό προμήθειας Cardlink</td>
                                            <td>{responseData?.totalFeeCardlink ? getGreekCurrencyFormat(responseData.totalFeeCardlink) : "0,00 €"} ({getPercentage(responseData?.totalFeeCardlink, responseData.totalNetValue)})</td>
                                        </tr>
                                    </React.Fragment>
                                )}
                                <tr>
                                    <td style={{fontWeight: "700"}}>Συνολικό ποσό προμήθειας</td>
                                    <td>{responseData.totalFeeValue ? getGreekCurrencyFormat(responseData.totalFeeValue) : "0,00 €"}
                                        {checkPermission("custom-viewAllFees", permissionsData) ? ` (${getPercentage(responseData?.totalFeeValue, responseData.totalNetValue)})` : ``}</td>
                                </tr>
                                <tr>
                                    <td style={{fontWeight: "700"}}>
                                        {(checkPermission("custom-peiraios", permissionsData) || checkPermission("custom-daras", permissionsData)) && !checkPermission("custom-epay", permissionsData) ? (
                                            `Συνολική καθαρή αξία βασικού πακέτου`
                                        ) : (
                                            `Συνολική καθαρή αξία`
                                        )}
                                    </td>
                                    <td>{responseData.totalNetValue ? getGreekCurrencyFormat(responseData.totalNetValue) : "0,00 €"}</td>
                                </tr>
                                {checkPermission("custom-viewAllFees", permissionsData) && (
                                    <tr>
                                        <td style={{fontWeight: "700"}}>Συνολικό ποσό μετά από τις προμήθειες</td>
                                        <td>{responseData?.totalNetValue && responseData?.totalFeeValue ? getGreekCurrencyFormat(Number(Number(responseData.totalNetValue) - Number(responseData.totalFeeValue))) : "0,00 €"}</td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    {(checkPermission("clients-reports/reportsprimerpayments-print", permissionsData) === true
                        || checkPermission("custom-epay", permissionsData) === true
                        || checkPermission("custom-peiraios", permissionsData) === true
                        || checkPermission("custom-daras", permissionsData) === true
                        || checkPermission("custom-cardlink", permissionsData) === true
                    ) && (
                        <Row>
                            <BootstrapDropdown className="mb-3 mt-3">
                                <BootstrapDropdown.Toggle variant="primary" id="dropdown-basic">
                                    Αποθήκευση αποτελεσμάτων
                                </BootstrapDropdown.Toggle>
                                <BootstrapDropdown.Menu>
                                    <BootstrapDropdown.Item onClick={() => handlePDFExport()}>
                                        Αποθήκευση ως PDF
                                    </BootstrapDropdown.Item>
                                    <BootstrapDropdown.Item onClick={() => handleExcelExport()}>
                                        Αποθήκευση ως XLSX
                                    </BootstrapDropdown.Item>
                                </BootstrapDropdown.Menu>
                            </BootstrapDropdown>
                        </Row>
                    )}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <span></span>
                </React.Fragment>
            )}
        </div>
    )
}