import React, {useRef} from "react";
import AdvancedCombobox from '../../primer/AdvancedCombobox/index';
import QuickEdit from "../QuickEdit";
import Dropdown from "../Dropdown";
import AccountingBox from "../AccountingBox";
import {
  getDefaultOptionByValue,
  getKeyByName,
  getMaskCode,
  getISOStringDateToDate,
  isValidDate,
  leadingZeros,
  getCurrentUser,
  salesMyDataStatus,
  getMultiSelectOptionsToArrayEditEditable,
  getOptionsByData,
  getEmployeeTypeOptions,
  getResignationCauseOptions,
  getSubaccountOptions,
  paymentStatusOptions,
  isEmpty,
  getGreekCurrencyFormat,
  bridgedSections, getIsNetValueOptions, getTaxTypeOptions
} from '../../../_helpers/commonFunctions';
import DateBox from "../DateBox";
import TimePickerInput from "../../common/TimePickerInput";
import SalesProductQuickEdit from "../QuickEdit/sales-edit-product";
import moment from "moment";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchBankAccountsOnChange, fetchPOSOnChange, fetchProductsONChange } from "../../../_apis/api";
import {getLabelByValue, translateClientCategories} from "../../../_helpers/helperFunctions";
import getMyData, {getInvoiceTypesOptions} from "../../../_helpers/sharedmydata";
import {useTranslation} from "react-i18next";
import BuysProductQuickEdit from "../QuickEdit/buy-edit-product";
import AccountingRecordQuickEdit from "../QuickEdit/accounting-record-quick-edit";
import AccountingRecordWarning from "../QuickEdit/accounting-record-warning";
import {Badge, Button} from "react-bootstrap";
import {measurementUnitsData} from "../../../_data/mydata/measurementUnits";
import {vatCategoryData} from "../../../_data/mydata/vatCategory";
import EmronMonthlyPaymentDetails from "../POPUP-COMPONENTS/EmronMonthlyPaymentDetails/EmronMonthlyPaymentDetails";
import HourPicker from "../HourPicker";
import {movementTypeOptions} from "../../layouts/data-panel/Payroll/Components/constants";
let hideZero = true;
let hideCustomVat = true;

const TableBodyCell = (props) => {
  const [t] = useTranslation('common');
  const company = useSelector((state) => state.COMPANY_DATA.company);
  const installations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
  const currentUser = getCurrentUser();
  const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);

  let requestDataF = {
    company: company.id,
    year: company.year.toString(),
    name: ''
  };
  const requestDataPos = {
    company: company.id,
    year: company.year.toString(),
    isActive: "active",
    userId: currentUser.id,
    name:''
  };
  const requestDataBank = {
    company: company.id,
    year: company.year.toString(),
    isActive: "active",
    userId: currentUser.id,
    name:''
  };

  const dispatch = useDispatch();
  const textAreaRef = useRef(null);
  const ACCOUNTING_DATA = useSelector( (state) => state.ACCOUNTING_DATA);
  const {quickEditFields, rowIndex, checkedRows, tableName, columns, data, rowData, cell, dataKey, placeholder, newRow, updateRowData, disabled, isInsertRow, enableDynamicOptions, dynamicOptions, reqData, reqName } = props;
  let date = new Date();
  const currentTime = leadingZeros(date.getHours(), 2) + ':' + leadingZeros(date.getMinutes(), 2);
  const handleActionClick = (e, type, data, cell, dataKey, rowIndex, rowData) => {
    if(type === "delete") {
      if(e?.nativeEvent?.pointerType === "mouse" || e?.nativeEvent?.type === "click") {
        cell.inputType.callbackFn(dataKey, type, rowIndex)
      } else {
        e.preventDefault();
      }
    } else if (type === "select") {
      cell.inputType.callbackFn(dataKey, type, rowIndex, rowData)
    } else {
      cell.inputType.callbackFn(dataKey, type, rowIndex)
    }
  }

  const handleOnChange = (e, field, defaultValue, ftype = 'default') => {
    // Allow insert only with enter
    if (e?.key !== "Enter") {
      if (typeof(newRow) !== 'undefined') {
        newRow(e, field, defaultValue, ftype);
      } else {
        if (rowData) {
          const id = rowData.id ? rowData.id : rowData._id;
          updateRowData && updateRowData(e, field, id, rowIndex);
        }
      }
    } else {
      if (typeof(newRow) !== 'undefined') {
        newRow(e, field, defaultValue, ftype);
      }
    }
  }
  const adjustTextAreaHeight = () => {
    const textArea = textAreaRef.current;
    if(textArea) {
      textArea.style.height = 'auto'; // Reset the height to recalculate
      textArea.style.height = `${textArea.scrollHeight}px`;
      textArea.classList.add('auto-height');
    }
  };

  const onChangeAdvancedComboboxCheckbox = (value , setAdvancedComboboxCheckbox, cellName, setData, searchKeyword) => {
    if(cellName === "Υπολογαριασμός" && rowData?.subAccountType) {
      if (value === "inactive") {
        setAdvancedComboboxCheckbox("active");
      } else {
        setAdvancedComboboxCheckbox("inactive");
      }
      let keyword = searchKeyword ? searchKeyword : "empty";
      let requestData = {company: company.id, isActive: value, subAccountType: rowData.subAccountType, subAccountCategories: rowData.subAccountCategories};
      axios.get(process.env.REACT_APP_API_URL2 + `/accounting/get-subAccount-by-word`, {
        params: {
          ...requestData,
          keyword: keyword,
        }
      })
      .then(res => {
        if (res.data && res.data.status === '200') {
          if (res.data.data) {
            setData(res.data.data);
          } else {
            setData([]);
          }
        } else {
          setData([]);
        }
      }).catch((err) => console.log(err))
    }
  }

  const onChangeSearchCell = (cellName,keyword,setData, advancedComboboxCheckbox) => {
    if (keyword.length >= 2) {
      if (cellName === 'Client Name' || cellName === "Όνομα Πελάτη") {
        loadClientsListData(keyword,setData);
      } else if (cellName === 'Client Code' || cellName === "Κωδικός Πελάτη") {
        loadClientsListDataByCode(keyword,setData);
      } else if (cellName === 'Supplier Name' || cellName === "Όνομα Προμηθευτή" || (tableName === "Supplier Movements Suppliers" && cell.field === "name")) {
        loadSuppliersListData(keyword,setData);
      } else if (cellName === 'Supplier Code' || cellName === "Κωδικός Προμηθευτή" || (tableName === "Supplier Movements Suppliers" && cell.field === "systemCode")) {
        loadSuppliersListDataByCode(keyword,setData);
      } else if (cellName === 'Product Name' || cellName === 'Ονομασία Προϊόντος'
          || (tableName === "Buys Products List" && cell.field === "name")
          || (tableName === "Sales Products List" && cell.field === "name")
      ) {
        if(reqData) {
          loadProductListData(keyword,setData)
        } else {
          requestDataF.name = keyword
          dispatch(fetchProductsONChange(requestDataF,setData));
        }
      } else if (cellName === 'Product Code' || cellName === 'Κωδικός Προϊόντος'
          || (tableName === "Buys Products List" && cell.field === "code")
          || (tableName === "Sales Products List" && cell.field === "code")
      ) {
        if(reqData) {
          loadProductListDataByCode(keyword,setData);
        } else {
          requestDataF.name = keyword
          dispatch(fetchProductsONChange(requestDataF,setData));
        }
      } else if (cellName === "Κωδικός Λογαριασμού") {
        loadAccountListDataByCode(keyword,setData);
      } else if (cellName === "Όνομα Λογαριασμού") {
        loadAccountListData(keyword,setData);
      } else if (tableName === "Accounting Record Accounts" && cell.field === "subAccount" && rowData?.subAccountType) {
        loadSubAccountListData(keyword,setData, rowData.subAccountType, advancedComboboxCheckbox, rowData.subAccountCategories);
      } else {
        requestDataF.name = keyword
        dispatch(fetchProductsONChange(requestDataF,setData));
        }
      }
      if(reqName) {
        if(reqName === 'pos') {
          requestDataPos.name = keyword
          dispatch(fetchPOSOnChange(requestDataPos, setData));
        }
        if(reqName === 'bank') {
          requestDataBank.name = keyword
          dispatch(fetchBankAccountsOnChange(requestDataBank, setData));
        }
      }
    }

    const loadSubAccountListData = (keyword, setData, subAccountType, advancedComboboxCheckbox, subAccountCategories) => {
      let requestData = {company: company.id, subAccountType: subAccountType, isActive: advancedComboboxCheckbox, subAccountCategories: subAccountCategories};
      axios.get(process.env.REACT_APP_API_URL2 + `/accounting/get-subAccount-by-word`, {
        params: {
          ...requestData,
          keyword: keyword,
        }
      })
      .then(res => {
        if (res.data && res.data.status === '200') {
          if (res.data.data) {
            setData(res.data.data);
          } else {
            setData([]);
          }
        } else {
          setData([]);
        }
      }).catch((err) => console.log(err))
    }

    const loadClientsListData = (name, setData) => {
      let c = company.id;
      let y = moment().format("YYYY");
          axios
        .post(process.env.REACT_APP_API_URL2 + `/client/list-client`,{name: name, company: c, year: y})
        .then(res => {
          if (res.data && res.data.status === '200') {
            if (res.data.data) {
              setData(res.data.data);
            } else {
              setData([]);
            }
          } else {
            setData([]);
          }
        }).catch((err) => console.log(err))
    }
const loadClientsListDataByCode = (code, setData) => {
  let c = company.id;
  let y = moment().format("YYYY");
  axios
      .post(process.env.REACT_APP_API_URL2 + `/client/list-client`,{code: code, company: c, year: y})
      .then(res => {
        if (res.data && res.data.status === '200') {
          if (res.data.data) {
            setData(res.data.data);
          } else {
            setData([]);
          }
        } else {
          setData([]);
        }
      }).catch((err) => console.log(err))
}

  const loadSuppliersListData = (name, setData) => {
    let c = company.id;
    let y = moment().format("YYYY");
    axios
        .post(process.env.REACT_APP_API_URL2 + `/supplier/list-supplier`,{name: name, company: c, year: y})
        .then(res => {
          if (res.data && res.data.status === '200') {
            if (res.data.data) {
              setData(res.data.data);
            } else {
              setData([]);
            }
          } else {
            setData([]);
          }
        }).catch((err) => console.log(err))
  }
  const loadSuppliersListDataByCode = (code, setData) => {
    let c = company.id;
    let y = moment().format("YYYY");
    axios
        .post(process.env.REACT_APP_API_URL2 + `/supplier/list-supplier`,{code: code, company: c, year: y})
        .then(res => {
          if (res.data && res.data.status === '200') {
            if (res.data.data) {
              setData(res.data.data);
            } else {
              setData([]);
            }
          } else {
            setData([]);
          }
        }).catch((err) => console.log(err))
  }



  const loadAccountListData = (value, setData) => {
    let data = reqData
    data.name = value
    data.company = company.id
    axios.get(process.env.REACT_APP_API_URL2 + `/accounting/account-list-by-advanced-type-id`,{
      headers: { "Content-Type": "application/json" },
      params: data
    })
    .then(res => {
      if (res.data && res.data.status === '200') {
        if (res.data.data) {
          setData(res.data.data);
        } else {
          setData([]);
        }
      } else {
        setData([]);
      }
    }).catch((err) => console.log(err))
  }

  const loadAccountListDataByCode = (value, setData) => {
    let data = reqData
    data.code = value
    data.company = company.id
    axios.get(process.env.REACT_APP_API_URL2 + `/accounting/account-list-by-advanced-type-id`,{
      headers: { "Content-Type": "application/json" },
      params: data
    }).then(res => {
      if (res.data && res.data.status === '200') {
        if (res.data.data) {
          setData(res.data.data);
        } else {
          setData([]);
        }
      } else {
        setData([]);
      }
    }).catch((err) => console.log(err))

  }

    const loadProductListData = (value, setData) => {
      let data = reqData
       data.name = value
      data.company = company.id
      if(tableName === "Product Movements Products" || tableName === "Edit Product Movements Products"){
        data.isProductMovement = "true"
      }
      let type = "sale";
      if(tableName === "Buys Products List") type = "buy";
      axios.get(process.env.REACT_APP_API_URL2 + `/${type}/product-list-by-advanced-type-id/${data.id}`,{params :data})
        .then(res => {
          if (res.data && res.data.status === '200') {
            if (res.data.data) {
              setData(res.data.data);
            } else {
              setData([]);
            }
          } else {
            setData([]);
          }
        }).catch((err) => console.log(err))
    }

  const loadProductListDataByCode = (value, setData) => {
    let data = reqData
    data.code = value
    data.company = company.id
    if(tableName === "Product Movements Products" || tableName === "Edit Product Movements Products"){
      data.isProductMovement = "true"
    }
    let type = "sale";
    if(tableName === "Buys Products List") type = "buy";
    axios.get(process.env.REACT_APP_API_URL2 + `/${type}/product-list-by-advanced-type-id/${data.id}`,{params :data})
        .then(res => {
          if (res.data && res.data.status === '200') {
            if (res.data.data) {
              setData(res.data.data);
            } else {
              setData([]);
            }
          } else {
            setData([]);
          }
        }).catch((err) => console.log(err))
  }

  const handleOnKeyDown = (e, field, defaultValue, ftype = 'default') => {
    if (e.key === "Enter") {
      handleOnChange(e, field, defaultValue, ftype);

      // Select Next Input on Enter
      const form = e.target.form;
      if (form) {
        const index = Array.prototype.indexOf.call(form, e.target);
        if (index >= 0) {
          form.elements[index + 1].focus();
        }
      }
    }
  }

  const handleCheckInput = (e) => {
    if (tableName === "Accounting Bridge" && cell.field === "account") {
      e.target.value = e.target.value?.replace(/[^a-zA-Z0-9{}\.]/, "");
    }
  }

  const selectRow = (e, type, rowkey) => {
    cell.inputType.select(e, type, rowkey);
  }

  const CellFormFields =  ({cell, rowId, rowData, width, inputDisabled}) => {
    let required = false;
    let disabled = false;
    // Required Field for Product My Data

    if (cell.required === true && !isInsertRow) required = true;

    if (tableName && (
        tableName === "New Product My Data" ||
        tableName === "Product Category My Data" ||
        tableName === "New Price List General" || tableName === "Accounting Record Accounts")
    ) {
      if (!isInsertRow && (!rowData?.code || rowData.code !== "ΣΥΝΟΛΑ") && cell.field !== "vatExpenseType") {
        required = true;
      }
    }

    // Required Field for new client contact points
    if (tableName && ((tableName === "Client Contact Points" || tableName === "Edit Client Contact Points") && cell.field === 'name')) {
      if (!isInsertRow) {
        if (dataKey > 1) {
          required = true;
        }
      }
    }

    if (tableName && (
        (tableName === "Client Contact Points" || tableName === "Edit Client Contact Points"))
    ) {
      if (!isInsertRow && dataKey <= 1) {
        disabled = true;
      }
    }

    // Required Field for new client contact points
    if (tableName && (
        (tableName === "Supplier Contact Points" || tableName === "Edit Supplier Contact Points") && cell.field === 'name')
    ) {

      if (!isInsertRow) {
        if (dataKey > 1) {
          required = true;
        }
      }
    }

    if (tableName && (
        (tableName === "Supplier Contact Points" || tableName === "Edit Supplier Contact Points"))
    ) {
      if (!isInsertRow && dataKey <= 1) {
        disabled = true;
      }
    }

    if(tableName && (tableName === "Accounting Bridge") && !props.isInsertRow) {
      required = true;
    }

    if (tableName && (
        (tableName === "Client New Installations" || tableName === "Edit Client New Installations"))
    ) {
      if (!isInsertRow && (dataKey <= 0 || rowIndex <= 0)) {
        disabled = true;
      }
      if (cell.field === 'vatCategory' && data === 'Zero VAT') {
        hideZero = false;
      } else if (cell.field === 'vatCategory' && data !== 'Zero VAT') {
        hideZero = true;
      }
      if (cell.field === 'vatReason') {
        cell.disabled = hideZero;
      }
      if (cell.field === 'vatCategory' && data === 'Custom VAT') {
        hideCustomVat = false;
      } else if (cell.field === 'vatCategory' && data !== 'Custom VAT') {
        hideCustomVat = true;
      }
      if (cell.field === 'customVat') {
        cell.disabled = hideCustomVat;
      }
    }


    if (tableName && (
        (tableName === "Supplier New Installations" || tableName === "Edit Supplier New Installations"))
    ) {
      if (!isInsertRow && (dataKey <= 0 || rowIndex <= 0)) {
        disabled = true;
      }
      if (cell.field === 'vatCategory' && data === 'Zero VAT') {
        hideZero = false;
      } else if (cell.field === 'vatCategory' && data !== 'Zero VAT') {
        hideZero = true;
      }
      if (cell.field === 'vatReason') {
        cell.disabled = hideZero;
      }
      if (cell.field === 'vatCategory' && data === 'Custom VAT') {
        hideCustomVat = false;
      } else if (cell.field === 'vatCategory' && data !== 'Custom VAT') {
        hideCustomVat = true;
      }
      if (cell.field === 'customVat') {
        cell.disabled = hideCustomVat;
      }
    }

    if (tableName && ((tableName === "Withhold Taxes Table" || tableName === "Other Taxes Table" || tableName === "Stamp Table" || tableName === "Fees Table"))) {
      if (cell.field === 'taxLevel' && data === 1) {
        hideZero = false;
      } else if (cell.field === 'taxLevel' && data !== 1) {
        hideZero = true;
      }
      if (cell.field === 'vatIncluded') {
        cell.disabled = hideZero;
      }
      if (cell.field === 'taxLevel' && data === 1) {
        hideCustomVat = false;
      } else if (cell.field === 'taxLevel' && data !== 1) {
        hideCustomVat = true;
      }
      if (cell.field === 'vatPercentage') {
        cell.disabled = hideCustomVat;
      }
    }

    // Sales table fields not editable until user selects product and product code.
    if (tableName && ((tableName === "Sales Products List" || tableName === "Edit Sales Products List" || tableName === "New Price List General"))) {
      if (isInsertRow) {
        disabled = true;
      }
    }
    if (tableName && tableName === "Sales Search") {
      if (cell.field === "number") {
        data.replace("noprefix", '');
      }
    }
    if (tableName && tableName === "Buys Search") {
      if (cell.field === "number") {
        data.replace("noprefix", '');
      }
    }

    //  Client Movement Table
    if (tableName && (
        (tableName === "Client Movements Clients" || tableName === "Edit Client Movements Clients" || tableName === "Supplier Movements Suppliers" || tableName === "Edit Supplier Movements Suppliers"))
    ) {
      if (isInsertRow) {
        disabled = true;
      }
    }

    if(tableName === "Accounting Record Accounts") {
      if(cell.field === "debit") {
        if(props.rowData?.isDebit === "inactive") {
          disabled = true;
        }
      } else if (cell.field === "credit") {
        if(props.rowData?.isCredit === "inactive") {
          disabled = true;
        }
      }
    }

    let editableByFunc = false;
    if (cell.editableCallback) {
      if (typeof cell.editableCallback === "function") {
        editableByFunc = cell.editableCallback(rowData);
      }
    }

    let disabledByFunc = false;
    if (cell.disabledCallback) {
      if (typeof cell.disabledCallback === "function") {
        disabledByFunc = cell.disabledCallback(rowData);
      }
    }
    disabled = disabled || disabledByFunc;

    if ((cell.field !== 'actions') && (cell.field !== 'select_checkbox')) {
      if (cell.editable || editableByFunc) {
        switch (cell.inputType?.type) {
          case 'advanced-combobox':
            if (tableName === "Accounting Record Accounts" && cell.field === "subAccount") {
              let subAccountColumns = [];
              if (rowData.subAccountType === "client" || rowData.subAccountType === "supplier") {
                subAccountColumns = [
                  {
                    label: t('SalesNew.clientTable.code'),
                    name: "systemCode",
                  },
                  {
                    label: t('SalesNew.clientTable.name'),
                    name: "name",
                  },
                  {
                    label: t('SalesNew.clientTable.phone'),
                    name: "phone",
                  },
                  {
                    label: t('SalesNew.clientTable.email'),
                    name: "email",
                  },
                  {
                    label: t('SalesNew.clientTable.vatNumber'),
                    name: "vatNumber",
                  },
                ];
              } else if(rowData.subAccountType === "employee"){
                subAccountColumns = [
                  {
                    label: "Όνομα",
                    name: "firstName",
                  },
                  {
                    label: "Επώνυμο",
                    name: "lastName",
                  },
                  {
                    label: "Α.Φ.Μ.",
                    name: "vatNumber",
                  },
                  {
                    label: "Τηλέφωνο",
                    name: "phone",
                  }
                ];
              } else if(rowData.subAccountType === "installation"){
                subAccountColumns = [
                  {
                    label: "Τύπος",
                    name: "type",
                  },
                  {
                    label: "Διεύθυνση",
                    name: "address",
                  },
                  {
                    label: "Τ.Κ.",
                    name: "postalCode",
                  },
                  {
                    label: "Πόλη",
                    name: "city",
                  }
                ];
              } else if(rowData.subAccountType === "bankAccount"){
                subAccountColumns = [
                  {
                    label: "Όνομα",
                    name: "name",
                  },
                  {
                    label: "Τράπεζα",
                    name: "bankName",
                  },
                  {
                    label: "IBAN",
                    name: "iban",
                  }
                ];
              } else if(rowData.subAccountType === "pos" || rowData.subAccountType === "cashRegister"){
                subAccountColumns = [
                  {
                    label: "Όνομα",
                    name: "name",
                  },
                  {
                    label: "Σειριακός Αριθμός",
                    name: "serialNumber",
                  }
                ];
              }
                return <span>
                <AdvancedCombobox
                    onChangeSearchCell={onChangeSearchCell}
                    onChange={(e) => handleOnChange(e, cell.field, data, 'ac')}
                    {...cell.inputType.config}
                    data={rowData?.subAccountData}
                    defaultValue={data}
                    columns={subAccountColumns}
                    onChangeAdvancedComboboxCheckbox={onChangeAdvancedComboboxCheckbox}
                    showAdvancedComboboxCheckbox={rowData.subAccountType !== "installation" && rowData.subAccountType !== "pos" && rowData.subAccountType !== "cashRegister"}
                    className="ac-select-dropdown"
                    cellName={cell.name}
                    ACName={cell.field}
                    disabled={!rowData?.subAccountType}
                    field={cell.field}
                    onKeyDown={(e) => handleOnKeyDown(e, cell.field, data)}
                />
                  {required && data === '' && rowData?.subAccountType !== "" && <small className="text-danger">{t('insertPlaceholders.required')}</small>}
            </span>
            }  else {
              return <span>
                <AdvancedCombobox
                    onChangeSearchCell={onChangeSearchCell}
                    onChange={(e) => handleOnChange(e, cell.field, data, 'ac')}
                    {...cell.inputType.config}
                    defaultValue={data}
                    className="ac-select-dropdown"
                    cellName={cell.name}
                    field={cell.field}
                    ACName={cell.field}
                    onKeyDown={(e) => handleOnKeyDown(e, cell.field, data)}
                />
                {required && data === '' && <small className="text-danger">{t('insertPlaceholders.required')}</small>}
                {(tableName === "Sales Products List" && rowData?.showAlternativeCodeOnPrint === "true" && cell.field === "code") && (
                    <span className={"text-muted"}><i>{rowData.alternativeCode}</i></span>
                )}
                {(tableName === "Sales Products List" && rowData?.showAlternativeNameOnPrint === "true" && cell.field === "name") && (
                    <span className={"text-muted"}><i>Θα προβληθεί ως: {rowData.alternativeName}</i></span>
                )}
            </span>
            }
          case 'datebox':
            return <DateBox
                {...cell.inputType.config}
                selected={isValidDate(data) ? new Date(data) : null}
                name={cell.field}
                allowOtherYears={cell.inputType.allowOtherYears === "true"}
                autoComplete="off"
                classes="m-0"
                onChange={(e) => handleOnChange(e, cell.field, data, 'db')}
                field={cell.field}
            />
          case "dayFormatDatebox":
            return <DateBox
                {...cell.inputType.config}
                selected={moment(data, "DD/MM/YYYY").isValid() ? moment(data, "DD/MM/YYYY").toDate() : null}
                name={cell.field}
                allowOtherYears={cell.inputType.allowOtherYears === "true"}
                autoComplete="off"
                classes="m-0"
                onChange={(e) => handleOnChange(e, cell.field, data, 'db')}
                field={cell.field}
            />
          case 'monthDatebox':
            return <DateBox
                {...cell.inputType.config}
                dateFormat={"MM-yyyy"}
                showMonthYearPicker={true}
                allowOtherYears={true}
                selected={isValidDate(data) ? new Date(data) : null}
                disabled={tableName === "Emron Payments" && !isEmpty(rowData.subscription_id) && cell.field === "month"}
                name={cell.field}
                autoComplete="off"
                classes="m-0"
                onChange={(e) => handleOnChange(e, cell.field, data, 'db')}
                field={cell.field}
            />
          case 'button':
            return <button type="button" disabled={cell.inputType.config.disabled}
                           onClick={() => cell.inputType.config.onClick(rowData)} className="btn btn-primary mt-2 mb-2">
              {cell.inputType.config.disabled && (
                  <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                  ></span>
              )}
              {cell.name}
            </button>
          case 'button-sm':
            return (
                <Button type="button" size={"sm"} style={{paddingTop: "5px", paddingBottom: "5px"}} disabled={cell.inputType.config.disabled} onClick={() => cell.inputType.config.onClick(rowData)} className="btn btn-primary mt-1">
                  {cell.name === "Αντιστροφή" ? <img alt={"Αντιμετάθεση Χρεώσεων & Πιστώσεων"} src={"assets/images/xp.png"}/> : isEmpty(cell.inputType.config.text) ? cell.name : cell.inputType.config.text}
                </Button>
              )
          case 'time':
            return <TimePickerInput
                value={data ? data : currentTime}
                invalidMessage="Invalid."
                onChange={(e) => handleOnChange(e.target.value, cell.field, data, 'tp')}
            />
          case "hour-picker":
            return <HourPicker
                selected={moment().set({hour: data.split(":")[0], minute: data.split(":")[1]}).toDate()}
                onChange={(e) => handleOnChange(e, cell.field, data, "hour-picker")}
            />
          case 'select':
            if (enableDynamicOptions && enableDynamicOptions === 'true') {
              return <span>
              <select disabled={disabled || cell.disabled || inputDisabled} {...cell.inputType.config} defaultValue={String(data)}
                      onChange={(e) => handleOnChange(e, cell.field, data)} className="tdelement custom-select">
                <option value="">{placeholder ? placeholder : t('insertPlaceholders.select')}</option>
                {
                    dynamicOptions.length > 0 && dynamicOptions.map((op, idx) => {
                      return <option key={idx} value={op.value}>{op.label}</option>
                    })
                }
              </select>
                {required && data === '' && <small className="text-danger">{t('insertPlaceholders.required')}</small>}
            </span>
            } else {
              return <span>
                <select disabled={disabled || cell.disabled || inputDisabled} {...cell.inputType.config} defaultValue={String(data)}
                        onChange={(e) => handleOnChange(e, cell.field, data)} className="tdelement custom-select">
                <option value="">{placeholder ? placeholder : t('insertPlaceholders.select')}</option>
                  {
                      cell.inputType.options.length > 0 && cell.inputType.options.map((op, idx) => {
                        return <option key={idx} value={op.value}>{op.label}</option>
                      })
                  }
              </select>
                {required && data === '' && <small className="text-danger">{t('insertPlaceholders.required')}</small>}
            </span>
            }
          case 'dropdown':
            let dddefaultData = data;

            if (data === 'true') {
              dddefaultData = true;
            }

            if (data === 'false') {
              dddefaultData = false;
            }
            if (cell.field === "DOY" && (tableName === "Client New Installations")) {
              dddefaultData = Number(data)
            }
            return <span>
              <Dropdown
                  {...cell.inputType.config}
                  disabled={disabled || cell.disabled}
                  classes={'table-dropdown'}
                  defaultValue={getDefaultOptionByValue(cell.inputType.options, dddefaultData, 'value', 'label', 'value')}
                  options={cell.inputType.options}
                  fromEditableTable={true}
                  onChange={(e) => handleOnChange(e, cell.field, data, 'dd')}
              />
              {required && (data === '' || data === 0) &&
                  <small className="text-danger">{t('insertPlaceholders.required')}</small>}
          </span>
          case 'multiselectdropdown':
            return <span><Dropdown
                {...cell.inputType.config}
                classes={'table-dropdown'}
                multiSelect={true}
                defaultValue={data ? getMultiSelectOptionsToArrayEditEditable(data, cell.inputType.options) : []}
                options={cell.inputType.options}
                fromEditableTable={true}
                onChange={(e) => handleOnChange(e, cell.field, data, 'dd')}
            />
              {required && data === '' && <small className="text-danger">{t('insertPlaceholders.required')}</small>}
          </span>
          case 'accounting':
            let suffix = '%';
            let changeFont1 = "";
            let enableDynamicCss = false;
            if (cell.inputType && cell.inputType.config && cell.inputType.config.suffix) {
              suffix = cell.inputType.config.suffix;
            }

            if (rowData && Object.keys(rowData).length > 0 && rowData.taxPer && rowData.taxPer === 'false' && cell.field !== "vatPercentage") {
              suffix = '€';
            }

            // Used on price list
            if (rowData && Object.keys(rowData).length > 0 && rowData.isDiscountAmount && String(rowData.isDiscountAmount) === 'true' && cell.field === 'discount') {
              suffix = '€';
            }

            // Used on price list
            if (rowData && Object.keys(rowData).length > 0 && rowData.isPerDiscount && String(rowData.isPerDiscount) === 'true' && cell.field === 'discount') {
              suffix = '%';
            }

            if (cell.field === 'price' && cell.inputType && cell.inputType.config && cell.inputType.config.suffix) {
              suffix = cell.inputType.config.suffix;
            }

            if (cell.field === 'quantity') {
              suffix = "nosuffix"
            }
            if (tableName && ((tableName === "Sales Products List" || tableName === "Edit Sales Products List"))) {
              enableDynamicCss = true;
              if (enableDynamicCss) {
                if (Math.floor(data).toString().length >= 5 && Math.floor(data).toString().length < 7) {
                  changeFont1 = " changeFont";
                } else if (Math.floor(data).toString().length >= 7 && Math.floor(data).toString().length <= 10) {
                  changeFont1 = " changeFont2";
                }
              }
            }
            return <span><AccountingBox
                {...cell.inputType.config}
                defaultValue={data}
                disabled={disabled}
                onBlur={(e) => handleOnChange(e, cell.field, data)}
                className={"tdelement form-control bg-white" + changeFont1 + (disabled || cell.disabled || cell.disabledAccountingBox ? " sales-search-disabled" : "") + (props?.emronPaymentsMonth && cell.field === props?.emronPaymentsMonth ? " emronPaymentsSelected" : "")}
                suffix={suffix}
                enableDynamicCss={enableDynamicCss}
                onKeyDown={(e) => handleOnKeyDown(e, cell.field, data)}
            />
              {required && data === '' && <small className="text-danger">{t('insertPlaceholders.required')}</small>}
              {(tableName === "Primer Payments Report" && cell.field === "price" && rowData?.voucher === true) && (
                  <div style={{textAlign: "center"}}>
                    <Badge bg={"primary"}>Voucher</Badge>
                  </div>
              )}
              {(tableName === "Emron Payments Report" && cell.isForMonthYear && rowData[`${cell.field}_paid`] === "paid") && (
                  <div style={{textAlign: "center"}}>
                    <Badge bg={"primary"} style={{width: "100%"}}>Πληρωμένη</Badge>
                  </div>
              )}
              {(tableName === "Emron Payments Report" && cell.isForMonthYear && rowData[`${cell.field}_paid`] === "failed") && (
                  <div style={{textAlign: "center"}}>
                    <Badge bg={"danger"} style={{width: "100%"}}>Αποτυχία πληρωμής</Badge>
                  </div>
              )}
              {(tableName === "Emron Payments Report" && cell.isForMonthYear && rowData[`${cell.field}_paid`] === "pending") && (
                  <div style={{textAlign: "center"}}>
                    <Badge bg={"dark"} style={{width: "100%"}}>Εκκρεμεί πληρωμή</Badge>
                  </div>
              )}
          </span>
          case 'number':
            return <input type="number" {...cell.inputType.config} disabled={cell.disabled} defaultValue={data}
                          onBlur={(e) => handleOnChange(e, cell.field, data)}
                          onKeyDown={(e) => handleOnKeyDown(e, cell.field, data)} className="tdelement form-control"/>
          case 'radio':
            let name = cell.field;
            if (cell.inputType.radioLogic && (cell.inputType.radioLogic === 'oneperrow')) {
              name = `${rowIndex}_${cell.inputType.name}`;
            }
            if (cell.inputType.radioLogic && (cell.inputType.radioLogic === 'onepertable')) {
              name = `${cell.inputType.name}`;
            }
            if (tableName) {
              let key = getKeyByName(tableName);
              name = key + name;
            }
            const isChecked = (data === 'true' || data === 'on' || data === true);
            return <input disabled={disabled || cell.disabled} name={name}
                          checked={isChecked} {...cell.inputType.config} type="radio" className="tdelement"
                          onChange={(e) => handleOnChange(e, cell.field, data)}/>
          case 'checkbox':
            if (cell.field === "accountingNonDeductibleVAT") {
              let checkIt = (data !== "active");
              return <input value={!checkIt} checked={checkIt} {...cell.inputType.config}
                            type="checkbox" onChange={(e) => handleOnChange(e, cell.field, data)}
                            className="tdelement custom-checkbox" />
            } else {
              let isChecked2 = (data === 'true');
              return <input value={!isChecked2 ? 'true' : 'false'} checked={isChecked2} {...cell.inputType.config}
                            type="checkbox" onChange={(e) => handleOnChange(e, cell.field, data)}
                            className="tdelement custom-checkbox" />
            }
          case 'textarea':
            return <span>
                <textarea ref={textAreaRef} onFocus={adjustTextAreaHeight} onInput={adjustTextAreaHeight}
                          disabled={disabled} required={required} {...cell.inputType?.config} placeholder={placeholder}
                          defaultValue={data} onBlur={(e) => handleOnChange(e, cell.field, data)}
                          onKeyDown={(e) => handleOnKeyDown(e, cell.field, data)}
                          className="tdelement form-control textarea-notes"/>
              {required && data === '' && <small className="text-danger">{t('insertPlaceholders.required')}</small>}
              </span>
          default:
            if (cell.mask || cell.maskCode) {
              let maskedData = getMaskCode(data);
              return <input disabled={disabled || cell.disabled || inputDisabled} type="text" {...cell.inputType.config}
                            placeholder={placeholder} defaultValue={maskedData}
                            onBlur={(e) => handleOnChange(e, cell.field, data)}
                            onKeyDown={(e) => handleOnKeyDown(e, cell.field, data)} className="tdelement form-control"/>
            } else if (tableName === "Accounting Bridge" && cell.field === "account") {
              return <span>
                <input disabled={disabled || cell.disabled} required={required} type="text" {...cell.inputType?.config}
                       placeholder={placeholder} defaultValue={data} onChange={(e) => handleCheckInput(e)} onBlur={(e) => handleOnChange(e, cell.field, data)}
                       onKeyDown={(e) => handleOnKeyDown(e, cell.field, data)} className="tdelement form-control"/>
                {required && data === '' && <small className="text-danger">{t('insertPlaceholders.required')}</small>}
              </span>
            } else {
              return <span>
                <input disabled={disabled || cell.disabled} required={required} type="text" {...cell.inputType?.config}
                       placeholder={placeholder} defaultValue={cell.inputType?.textFormatter ? cell.inputType?.textFormatter(data) : data} onBlur={(e) => handleOnChange(e, cell.field, data)}
                       onKeyDown={(e) => handleOnKeyDown(e, cell.field, data)} className="tdelement form-control"/>
                {required && data === '' && <small className="text-danger">{t('insertPlaceholders.required')}</small>}
              </span>
            }
        }
      } else {
        if(cell?.inputType?.type === "dropdownOption") {
          const selectedOption = cell.inputType.options.find(option => option.value === data);
          return <span title={selectedOption?.label} className={"tdelement"}><span className={"two-lines"}>{selectedOption?.label}</span></span>
        } else if ((cell.field === 'date' || cell.field === "employmentDate" || cell.field === "created_at") && data !== '') {
          let newDate = getISOStringDateToDate(data);
          return <span title={newDate} className={"tdelement"}><span className={"two-lines"}>{newDate}</span></span>
        } else if ((tableName === "Client Payments Report" || tableName === "Supplier Payments Report") && cell.field === "number") {
          const cellData = rowData.preNumber === "noprefix" ? rowData.number : rowData.preNumber + " " + rowData.number;
          return <span title={cellData} className={"tdelement"}><span className={"two-lines"}>{cellData}</span></span>
        } else if (cell.field === 'number' && data !== '') {
          const cellData = isEmpty(data) ? "" : String(data).replace("noprefix", '');
          return <span title={cellData} className={"tdelement"}><span className={"two-lines"}>{cellData}</span></span>
        } else if ((cell.field === 'myDataStatus' || cell.field === 'myDataSentStatus') && data !== '') {
          if (tableName === "MyData Report Buys" && rowData?.correlatable === "true" && cell.field === "myDataStatus") {
            return (<div style={{textAlign: "center"}}>
              <span>{getLabelByValue(salesMyDataStatus(), data, 'value')}</span>
              <Badge bg={"primary"} style={{padding: "3px"}}>Συσχετίσιμο</Badge>
            </div>)
          } else {
            const cellData = getLabelByValue(salesMyDataStatus(), data, 'value');
            return <span title={cellData} className={"tdelement"}><span className={"two-lines"}>{cellData}</span></span>
          }
        } else if ((cell.field === "paymentStatus") && data !== '') {
          const cellData = getLabelByValue(paymentStatusOptions(), data, 'value');
          return <span title={cellData} className={"tdelement"}><span className={"two-lines"}>{cellData}</span></span>
        } else if (cell.field === 'myDataType' && data !== '') {
          const cellData = getLabelByValue(getInvoiceTypesOptions("all"), data, 'value');
          return <span title={cellData} className={"tdelement"}><span className={"two-lines"}>{cellData}</span></span>
        } else if (tableName && (tableName === "Employees Search" || tableName === "Accounting Record Search") && cell.field === "installation") {
          const companyInstallations = getOptionsByData(installations, "installationMasterId", "address");
          const cellData = getDefaultOptionByValue(companyInstallations, data, 'value', 'label', 'value').label;
          return <span title={cellData} className={"tdelement"}><span className={"two-lines"}>{cellData}</span></span>
        } else if (cell.field === "employeeType") {
          const cellData = getLabelByValue(getEmployeeTypeOptions(), data, 'value');
          return <span title={cellData} className={"tdelement"}><span className={"two-lines"}>{cellData}</span></span>
        } else if (cell.field === "resignationCause") {
          const cellData = getLabelByValue(getResignationCauseOptions(), data, 'value');
          return <span title={cellData} className={"tdelement"}><span className={"two-lines"}>{cellData}</span></span>
        } else if (cell.field === "muLabel") {
          return (
              <div style={{textAlign: "center", padding: "5px"}}>
                <span>{measurementUnitsData.find((el) => el.name === data)?.shortName}</span>
                {(tableName === "Sales Products List" && !isEmpty(rowData.peppolMeasurementUnit)) && (
                    <React.Fragment>
                      <br/>
                      <div className={"text-muted"}>{rowData.peppolMeasurementUnit}</div>
                    </React.Fragment>
                )}
              </div>
          )
        } else if (tableName && tableName === "Accounting Account Search" && cell.field === "category") {
          const accountCategories = getOptionsByData(ACCOUNTING_DATA.accountingCategories, "_id", "category");
          const cellData = getLabelByValue(accountCategories, data, 'value');
          return <span title={cellData} className={"tdelement"}><span className={"two-lines"}>{cellData}</span></span>
        } else if (tableName && tableName === "Accounting Account Search" && cell.field === "subAccount") {
          const cellData = getLabelByValue(getSubaccountOptions(), data, 'value');
          return <span title={cellData} className={"tdelement"}><span className={"two-lines"}>{cellData}</span></span>
        } else if (tableName && ((tableName === "Supplier Search" || tableName === "Client Search") && cell.field === "categoryName")){
          const cellData = translateClientCategories(data);
          return <span title={cellData} className={"tdelement"}><span className={"two-lines"}>{cellData}</span></span>
        } else if (tableName && (tableName === "Buys Search" && cell.field === "supplierName")){
          const cellData = data ? data : rowData.employeeName;
          return <span title={cellData} className={"tdelement"}><span className={"two-lines"}>{cellData}</span></span>
        } else if (tableName === "MyData Report Classifications Buys" && cell.field === "vatCategory") {
          return <span className="tdelement">{vatCategoryData.find((el) => el.code === data)?.percentage}</span>;
        } else if (cell.field === "totalValue" && cell.inputType?.otherInfo === "view-sales-totalValue") {
          return <AccountingBox defaultValue={Number(Number(rowData.totalValue).toFixed(2))} />;
        } else if (tableName === "MyData Report Classifications Buys" && cell.field === "totalValue") {
          return <AccountingBox defaultValue={(Number(rowData.netPrice) + Number(rowData.vatAmount)).toFixed(2)} disabled={true} />;
        } else if ((tableName === "MyData Report" || tableName === "MyData Report Buys") && cell.field === "totalTaxes") {
          return <AccountingBox
              className={"sales-search-disabled tdelement form-control bg-white"}
              defaultValue={Number(Number(rowData.totalFeesAmount) + Number(rowData.totalStampDutyAmount) + Number(rowData.totalOtherTaxesAmount) + Number(rowData.totalWithheldAmount)).toFixed(2)}
          />;
        } else if ((tableName === "MyData Report" || tableName === "MyData Report Buys") && cell.field === "classified") {
          return <span className="tdelement">{data === "true" ? "Ναι" : "Όχι"}</span>;
        } else if ((tableName === "Sales Monthly Turnover" || tableName === "Buys Monthly Turnover") && cell.field !== "no" && !isNaN(data)) {
          return <span className="tdelement">{getGreekCurrencyFormat(data)}</span>;
        } else if (tableName === "Sales Balance Sheet" && (cell.field === "totalNetValue" || cell.field === "vatAmount" || cell.field === "totalAmount" || cell.field === "paymentAmount")) {
          return <span className="tdelement">{getGreekCurrencyFormat(data)}</span>;
        } else if (tableName === "Buys Balance Sheet" && (cell.field === "totalDeductibleVAT" || cell.field === "totalDeductibleNetValue" || cell.field === "totalNonDeductibleNetValue" || cell.field === "totalNonDeductibleVAT" || cell.field === "totalAmount")) {
          return <span className="tdelement">{getGreekCurrencyFormat(data)}</span>;
        } else if ((tableName === "Client Payments Report" || tableName === "Supplier Payments Report") && cell.field === "amount") {
          return <span className="tdelement">{getGreekCurrencyFormat(data)}</span>;
        } else if ((tableName === "Client Isozygio Report" || tableName === "Supplier Isozygio Report") && ["carryOverBalance", "debit", "credit", "balance", "totalBalance"].includes(cell.field)) {
          return <span className="tdelement">{getGreekCurrencyFormat(data)}</span>;
        } else if ((tableName === "Product Variants") && ["price", "totalPrice"].includes(cell.field)) {
          return <span className="tdelement">{getGreekCurrencyFormat(data)}</span>;
        } else if ((tableName === "Product Variants") && cell.field === "properties") {
          return <span title={Object.values(data).join(" - ")} className={"tdelement"}><span className={"two-lines"}>{Object.values(data).join(" - ")}</span></span>
        } else if (tableName === "Company Installations List" && cell.field === "installationActions") {
          return <span className={"tdelement"}>
            <Button style={{pointerEvents: "auto"}} size={"sm"} variant={"default"} className={"ml-2"} title={props.title ? props.title : "Quick Edit"} onClick={() => cell.inputType.editInstallationClicked(rowData)}>
              <i id={props.id} className="far fa-edit"></i>
            </Button>
          </span>
        } else if (tableName === "Company Installations List" && cell.field === "isActive") {
          return <span className={"tdelement"}>{data === "active" ? "Ενεργή" : "Ανενεργή"}</span>
        } else if ((tableName === "Work Movements Search" || tableName === "Work Permits Search") && cell.field === "installationMasterId") {
          const findInst = companyInstallations.find((el) => el.installationMasterId === data);
          let projectData = data;
          if (findInst) {
            projectData = `${findInst.type} ${findInst.address} ${findInst.city}`
          }
          return <span title={projectData} className={"tdelement"}><span className={"two-lines"}>{projectData}</span></span>
        } else if (tableName === "Work Movements Search" && cell.field === "movementType") {
          const cellData = movementTypeOptions.find((el) => el.value === data)?.label;
          return <span title={cellData} className={"tdelement"}><span className={"two-lines"}>{cellData}</span></span>
        } else if (tableName === "Accounting Record Search" && cell.field === "subAccounts") {
          const subAccs = rowData.accounts?.length > 0 ? rowData.accounts?.flatMap((r) => r ? r.subAccount : null).filter((el) => el).join(", ") : "";
          return <span title={subAccs} className={"tdelement"}><span className={"two-lines"}>{subAccs}</span></span>
        } else if (tableName === "Accounting Record Search" && cell.field === "bridgedSection") {
          const cellData = data ? bridgedSections.find((el) => el.value === data)?.label : "";
          return <span title={cellData} className={"tdelement"}><span className={"two-lines"}>{cellData}</span></span>
        }  else if ((tableName === "Client Subscriptions Report" || tableName === "Primer Payments Report") && cell.field === "clientName") {
          if(!isEmpty(rowData?.linkedConstructorName)) {
            return <><span title={data} className={"tdelement mt-1 mb-1"}><span className={"two-lines"}>{data}</span></span><Badge bg={"primary"}>Κατασκευαστής: {rowData?.linkedConstructorName}</Badge></>
          } else {
            return <span title={data} className={"tdelement"}><span className={"two-lines"}>{data}</span></span>
          }
        } else if ((tableName === "Client Subscriptions Report" || tableName === "Primer Payments Report") && cell.field === "clientVATNumber") {
          if(!isEmpty(rowData?.linkedConstructorVat)) {
            return <><span title={data} className={"tdelement mt-1 mb-1"}><span className={"two-lines"}>{data}</span></span><Badge bg={"primary"}>Κατασκευαστής: {rowData?.linkedConstructorVat}</Badge></>
          } else {
            return <span title={data} className={"tdelement"}><span className={"two-lines"}>{data}</span></span>
          }
        } else if (tableName === "Accounting Account Search" && cell.field === "accountCategory") {
          let display = "";
          if (rowData.isNetValue !== "false") {
            display = getIsNetValueOptions().find((el) => el.value === rowData.isNetValue)?.label;
          } else if (rowData.vat) {
            display = getMyData({type: 'vat', option: "doNotShowZero"}).find((el) => el.value === rowData.vat)?.label;
          } else {
            display = getTaxTypeOptions().find((el) => el.value === rowData.taxType)?.label;
          }
          return <span title={display} className={"tdelement"}><span className={"two-lines"}>{display}</span></span>
        } else {
          // checkbox to Greek
          if (data === "active") {
            return <span className="tdelement">Ενεργό</span>;
          } else if (data === "inactive") {
            return <span className="tdelement">Ανενεργό</span>;
          } else {
            return (
            <span title={data} className={"tdelement"}><span className={"two-lines"}>{data}</span></span>
            )
          }
        }
      }
    } else if (cell.field === 'select_checkbox') {
      let checkIndex = checkedRows?.indexOf(rowIndex);
      if (checkIndex > -1) {
        return <input type="checkbox" defaultChecked={true} onClick={(e) => selectRow(e, 'selectRow', rowIndex)}/>
      } else {
        return <input type="checkbox" onClick={(e) => selectRow(e, 'selectRow', rowIndex)}/>
      }
    } else {
      if (cell.actionButtons) {
        return <div className="et-actions">

          {cell.actionButtons.print && cell.actionButtons.print ?
              <button title="Print" onClick={(e) => handleActionClick(e, 'print', data, cell, dataKey)}
                      className="btn text-dark">
                <i className="fas fa-print"></i>
              </button>
              : ''}

          {(() => {
            if(cell.actionButtons.edit) {
              if (tableName === "Sales Products List") {
                return (
                    <SalesProductQuickEdit
                        tableName={tableName}
                        id={dataKey}
                        data={rowData}
                    />
                );
              } else if (tableName === "Buys Products List") {
                return (
                    <BuysProductQuickEdit
                        tableName={tableName}
                        id={dataKey}
                        data={rowData}
                    />
                )
              } else if (tableName === "Accounting Record Accounts") {
                if(props.rowData && props.rowData.isNetValue !== "false") {
                  return(
                      <AccountingRecordQuickEdit
                          tableName={tableName}
                          id={dataKey}
                          data={rowData}
                      />
                  )
                }
              } else {
                return (
                    <QuickEdit
                        tableName={tableName}
                        columns={quickEditFields?.fields && quickEditFields?.fields?.length > 0 ? quickEditFields?.fields : columns}
                        id={dataKey}
                        data={quickEditFields?.data ? quickEditFields?.data : rowData}
                        updateData={(e) => handleActionClick(e, 'edit', data, cell, dataKey)}
                    />
                )
              }
            }
          })()}

          {cell.actionButtons.delete ?
                <button title="Delete" className="btn text-danger"
                        onClick={(e) => handleActionClick(e, 'delete', data, cell, dataKey, rowIndex)}>
                  <i className="fas fa-minus-circle"></i>
                </button>
              : ''}

          {cell.actionButtons.select ?
                <button title="Select" className="btn"
                        onClick={(e) => handleActionClick(e, 'select', data, cell, dataKey, rowIndex, rowData)}>
                  <i className="fas fa-arrow-pointer"></i>
                </button>
              : ''}

          {cell.actionButtons.print ?
              <button title="Export" onClick={(e) => handleActionClick(e, 'export', data, cell, dataKey)}
                      className="btn text-dark">
                <i className="fas fa-upload"></i>
              </button>
              : ''}

          {(() => {
            if (tableName === "Accounting Record Accounts") {
              if(props.rowData && props.rowData.isNetValue !== "false") {
                return (
                    <AccountingRecordWarning
                        tableName={tableName}
                        id={dataKey}
                        data={rowData}
                    />
                )
              }
            }
          })()}

          {(() => {
            if (tableName === "Emron Payments" && !isEmpty(rowData.subscription_id)) {
              return (
                  <EmronMonthlyPaymentDetails
                      data={rowData}
                  />
                )
            }
          })()}

        </div>
      } else {
        return <span className="tdelement"></span>;
      }
    }
  }

  let tdWidth = cell.width ? cell.width : 'auto';
  if(cell.field === 'email') {
    tdWidth = 300
  }
  let rowId = ((rowData && rowData._id !== 'undefined')) ? rowData._id : '';
  if (rowId === undefined) {
    rowId = ((rowData && rowData.id !== 'undefined')) ? rowData.id : '';
  }
  return (
    <React.Fragment>
      { !cell.hiddenColumn &&
        <td className={ cell.field } style={{ width: tdWidth, backgroundColor: (props?.emronPaymentsMonth && cell.field === props?.emronPaymentsMonth) ? "#ffdab9" : "" }}>
            <CellFormFields
              quickEditFields = {quickEditFields}
              tableName={tableName}
              rowData = {rowData}
              rowId={rowId}
              cell = {cell}
              width={tdWidth}
              inputDisabled = {disabled}
            />
        </td>
      }
    </React.Fragment>
  )
}

export default TableBodyCell;
