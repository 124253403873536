import React, {useEffect, useState} from "react";
import SearchTable from "../../../primer/Search-Table";
import {getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {fetchAdvancedTypes} from "../../../../_apis/api";
import {classicStyleBelowNavbar} from "../Statics";

function ClientMovementsSearch() {
    const dispatch = useDispatch();
    const [t] = useTranslation("common");
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const company = useSelector((state) => state.COMPANY_DATA.company);

    useEffect(() => {
        dispatch(fetchAdvancedTypes({company: company.id}))
    }, []);

    const getDocumentTypes = () => {
        const docTypes = [...SETTINGS.settings.advancedTypes.filter((el) => el.section === "Client Movements")];
        let advOptions = [];
        for (let doc of docTypes) {
            advOptions.push({value: doc._id, label: doc.name})
        }
        return advOptions;
    }

    const searchClientFilters = {
        filters: [
            {
                name: "name",
                label: t("ClientMovementsSearch.clientName"),
                type: "input",
            },
            {
                name: "sum_from",
                label: t("ClientMovementsSearch.totalAmountFrom"),
                type: "input",
            },
            {
                name: "sum_to",
                label: t("ClientMovementsSearch.totalAmountTo"),
                type: "input",
            },
            {
                name: "documentType",
                label: t('SalesSearch.table2.documentType'),
                type: "multiselect",
                options: getDocumentTypes(),
            },
            {
                name: "numberFrom",
                label: t('General.numberFrom'),
                type: "number",
            },
            {
                name: "numberTo",
                label: t('General.numberTo'),
                type: "number",
            },
        ],
        actions: ["quick-edit", "delete"],
    };

    const scColumns = [
        {
            name: t("ClientMovementsSearch.columns.date"),
            field: "date",
            editable: false,
            inputType: {
                type: 'date',
            }
        },
        {
            name: t("ClientMovementsSearch.columns.movementCategory"),
            field: "documentTypeName",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("ClientMovementsSearch.columns.reason"),
            field: "reason",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("ClientMovementsSearch.columns.totalAmount"),
            field: "sum",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
    ]
    const [tableColumns, setTableColumns] = useState(scColumns.slice());
    // It will hide or show the table columns for search table.
    const handleColumnShowHide = (field) => {
        let tempTc = tableColumns.slice();
        let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
        const index = tempTc.findIndex((item) => field === item.field);
        if (object.length > 0) {
            tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
            setTableColumns(tempTc);
        }
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName="Client Movements Search"
                key={"Client Movements Search"}
                allowPagination={true}
                hideYearFilter={false}
                allowToggleColumns={true}
                columns={tableColumns}
                toggleColumns={handleColumnShowHide}
                data={[]}
                filtersConfig={searchClientFilters}
            />
        </div>
    )
}

export default ClientMovementsSearch;
