import React, {useState, useEffect, useRef} from "react";
import {Button, Spinner} from 'react-bootstrap';
import AcFilterTable from "../AdvancedCombobox/AcFilterTable";
import AcModalEditTable from "../AdvancedCombobox/AcModalEditTable";
import Dropdown from "../Dropdown";
import "./index.css";
import {useTranslation} from "react-i18next";
import Checkbox from "../../common/CheckBox";

const AdvancedCombobox = (props) => {

    const [t] = useTranslation('common');
    const {
        popupClassName,
        disabled,
        allowSelection,
        ACName,
        data,
        columns,
        deleteEnable,
        editEnable,
        label,
        searchableDropdownEnable,
        searchTableEnable,
        showTableOptionOnSelect,
        placeholder,
        onChange,
        onDelete,
        onKeyDown,
        inputFieldClasses,
        cellName,
        onChangeSearchCell,
        onChangeAdvancedComboboxCheckbox,
        showAdvancedComboboxCheckbox,
        isPayrollCombobox,
    } = props;
    const [acData, setAcData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [advancedComboboxCheckbox, setAdvancedComboboxCheckbox] = useState("active");
    const [acColumns, setAcColumns] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [showTableOptions, setShowTableOptions] = useState(false);

    const acRef = useRef(null)

    const [loader, setLoader] = useState(false);
    const [willDeleteWholeKeyword, setWillDeleteWholeKeyword] = useState(false);

    const rowDelete = {
        delete: (e, key, row) => {
            // handleRowDelete(row); // this is for dummy data
            onDelete(e, key, row) // delete action to parent
        },
        addRow: (e, newRow) => {
            setAcData([...acData, newRow]);
            setFilterData([...filterData, newRow]);
        }
    }

    // Filter Data
    const clearFilter = () => {
        setLoader(false);
        if (showTableOptionOnSelect === true) {
            setFilterData(data);
        } else {
            setFilterData([]);
        }
    }

    // Update the Data
    const handleSaveChanges = (e, updatedData, newRow) => {
        if (ACName) {
            if (ACName === 'Measurement Unit') {
                console.log('save changes', e, updatedData, newRow)
            }
        }
    }


    const searchHandleKeyDown = (e) => {
        if (e.key === "Enter") {
            return;
            //onChangeSearchKeyword(e);
        }
        if ((e.key === 'Backspace' || e.key === 'Delete') && willDeleteWholeKeyword) {
            if (props?.resetFilter) props.resetFilter();
            setSearchKeyword('');
            clearFilter();
            setAdvancedComboboxCheckbox("active");
        } else {
            if (willDeleteWholeKeyword) setWillDeleteWholeKeyword(false);
        }
        if (e.key === "Tab") {
          setShowTableOptions(false);
        }
        onKeyDown && onKeyDown(e) // Key down
    }

    const onChangeSearchKeyword = (e) => {
        if (!showTableOptions) setShowTableOptions(true);

        let value = String(e.target.value);
        setSearchKeyword(value);
        if (value && value.length > 0) {
            if (props?.onChangeSearch) {
                props.onChangeSearch(value, setFilterData)
            }
            if (onChangeSearchCell && onChangeAdvancedComboboxCheckbox) {
                onChangeSearchCell(cellName, value, setFilterData, advancedComboboxCheckbox === "inactive" ? "active" : "inactive")
            } else if (onChangeSearchCell) {
                onChangeSearchCell(cellName, value, setFilterData)
            }
            setLoader(false);
        } else {
            if (props.resetFilter !== null && props.resetFilter !== undefined) {
                props.resetFilter()
            }
            clearFilter()
        }
    }


    const handleSearchOnClick = (e) => {
        if (showTableOptionOnSelect === true) {
            if (!onChangeAdvancedComboboxCheckbox && !showTableOptions) {
                setFilterData(data);
                setAdvancedComboboxCheckbox("active");
            }
            if (showAdvancedComboboxCheckbox && e.target?.value) {
                onChangeSearchCell(cellName, e.target.value, setFilterData, advancedComboboxCheckbox === "inactive" ? "active" : "inactive")
            }
        }
        if (showTableOptions) {
            setShowTableOptions(true);
        } else {
            setShowTableOptions(true);
        }
    };

    const handleClickOutside = (event) => {
        // It will check if clicked inside the advanced combobox
        if (
            acRef.current && !acRef.current.contains(event.target) &&
            event.target.textContent !== 'OK' &&
            event.target.textContent !== 'Cancel' && (event.target?.name !== "advancedComboboxCheckbox" && event.target?.className !== "checkmark")
        ) {
            setShowTableOptions(false);
        }
    };

    const handleFocus = (e) => {
        setWillDeleteWholeKeyword(true);
    }

    useEffect(() => {
        setAcData(data);
        if (deleteEnable) {
            const actionObj = {
                label: t('ClientsNewGeneral.contactPoints.actions'),
                name: "actions",
            }
            setAcColumns([...columns, actionObj]);
        } else {
            setAcColumns(columns);
        }

        // Fill input with value on selection
        showTableOptionOnSelect && props.defaultValue && setSearchKeyword(props.defaultValue);

        // Show and hide outside click logic
        document.addEventListener('click', handleClickOutside, true);

        // Set Filter to data
        setFilterData(data);

        return () => {
            clearStates()
            document.removeEventListener('click', handleClickOutside, true);
        }
    }, [columns, data, deleteEnable, props])

    useEffect(() => {
        setFilterData(data);

    }, [props])
    const clearStates = () => {
        setAcColumns([]);
        setAcData([]);
    }

    const handleSelectOption = {
        selected: (row) => {
            props.field && setSearchKeyword(row[props.field]); // props.field only avaiable for editable table fields
            setShowTableOptions(false); // on select hide popup
            onChange && onChange({value: row[props.field], row: row})
        },
    }

    const formatPayrollCombobox = (val) => {
        return String(val).replace(/\|/g, " ");
    }

    return (
        <>

            {searchableDropdownEnable ?
                props.defaultValue && props.defaultValue !== '' ?
                    <Dropdown defaultValue={props.defaultValue} onChange={props.onChange}
                              classes="mb-3 w-100 advanced-combobox-dropdown" label={label} searchEnable={true}
                              options={data}/>
                    :
                    <Dropdown onChange={props.onChange} classes="mb-3 w-100 advanced-combobox-dropdown" label={label}
                              searchEnable={true} options={data}/>
                :
                <div className="advanced-combobox">

                    {/* Label */}
                    {label && <label>{label} {props.required && <span className="text-danger">*</span>}</label>}

                    {/* Search Field */}
                    <input
                        // key = { Math.random() }
                        ref={acRef}
                        placeholder={placeholder && !disabled ? placeholder : ""}
                        defaultValue={isPayrollCombobox ? formatPayrollCombobox(searchKeyword) : searchKeyword}
                        value={isPayrollCombobox ? formatPayrollCombobox(searchKeyword) : searchKeyword}
                        onKeyDown={(e) => searchHandleKeyDown(e)}
                        onChange={(e) => onChangeSearchKeyword(e)}
                        className={inputFieldClasses ? inputFieldClasses : "form-control mb-2"}
                        type="text"
                        name="search"
                        disabled={disabled}
                        autoComplete="off"
                        onClick={(e) => handleSearchOnClick(e)}
                        onFocus={(e) => handleFocus(e)}
                    />

                    {searchTableEnable &&
                        <div className="ac-result">
                            {loader && (<div className="text-center py-1 bg-white"><Spinner animation="grow"/></div>)}
                            {!loader && (searchKeyword.length > 2) && (
                                <AcFilterTable tableColumns={acColumns} tableData={filterData} {...props}
                                               rowActions={rowDelete}/>)}
                        </div>
                    }

                    {!loader && showEditModal &&
                        <AcModalEditTable
                            {...props}
                            key={Math.random()}
                            acData={acData}
                            tableColumns={acColumns}
                            tableData={filterData}
                            closeModal={() => setShowEditModal(false)}
                            saveChanges={handleSaveChanges}
                            rowActions={rowDelete}
                        />
                    }

                    {showTableOptionOnSelect && showTableOptions && allowSelection !== false &&
                        <div ref={acRef} className={"popupOptions " + popupClassName}>
                            {(onChangeAdvancedComboboxCheckbox && showAdvancedComboboxCheckbox) &&
                                <div className=" d-flex align-items-center justify-content-end"
                                     style={{borderBottom: "1px solid grey"}}>
                                    Εμφάνιση Όλων
                                    <Checkbox
                                        className="ml-2 mb-2"
                                        name="advancedComboboxCheckbox"
                                        key={Math.random()}
                                        defaultValue={advancedComboboxCheckbox === "active" ? "inactive" : "active"}
                                        value={advancedComboboxCheckbox}
                                        onChange={(e) => {
                                            onChangeAdvancedComboboxCheckbox(e.target.checked ? "active" : "inactive", setAdvancedComboboxCheckbox, cellName, setFilterData, searchKeyword);
                                        }
                                        }
                                    />
                                    <hr/>
                                </div>}
                            {loader && (<div className="text-center py-1 bg-white"><Spinner animation="grow"/></div>)}
                            <AcFilterTable
                                {...props}
                                tableColumns={acColumns}
                                tableData={filterData}
                                rowActions={rowDelete}
                                selectOption={handleSelectOption}
                                key={Math.random()}
                            />
                            {
                                editEnable && <div className="ac-actions">
                                    <Button className="m-2" onClick={() => setShowEditModal(true)} variant="primary"
                                            size="sm">Edit</Button>
                                </div>
                            }
                            <div style={{borderBottom: '6px solid #aed1d7'}} className="scrollAdvancedCombobox"></div>
                        </div>
                    }
                    {editEnable && !showTableOptionOnSelect && (
                      <div className="ac-actions" key={Math.random()}>
                          <Button disabled={filterData.length < 1} onClick={() => setShowEditModal(true)} variant="primary" size="sm">Edit</Button>
                      </div>
                    )}
                </div>
            }
        </>
    )
}
export default AdvancedCombobox;
