import React, {useState} from "react";
import CurrencyInput from "react-currency-input-field";
import {isEmpty} from "../../../_helpers/commonFunctions";

const AccountingBox = ({
                           focused,
                           suffix,
                           className,
                           maxLength,
                           decimalLimit,
                           conversion,
                           defaultValue,
                           onChange,
                           onBlur,
                           onKeyDown,
                           disabled,
                           enableDynamicCss,
                           allowNegative,
                           fromAccountTable
                       }) => {
    // focused input is the state that renders the accounting box in two events. onBlur, and onFocus to render the input value conditionally with extra functionalities
    const [focusedInput, setFocusedInput] = useState(true);
    const [autoFocusInput, setAutoFocusInput] = useState(false);
    if (!maxLength) {
        maxLength = 10;
    }
    let decimalScaleValue = 2;
    maxLength = maxLength + decimalScaleValue;
    // enteredInput is the state that keeps track of the input that is being entered inside the accounting box
    let newDval = 0;

    if (Number.isInteger(defaultValue)) {
        newDval = defaultValue;
    } else {
        if (defaultValue !== undefined && defaultValue !== 'undefined' && defaultValue !== '' && defaultValue !== null && defaultValue !== 'null') {
            // newDval = defaultValue.match(/\d+/)
            newDval = defaultValue.toString().replace(/[^\d.]/gi, "");
        }
    }

    // Count the number of decimals places
    let newValStr = newDval.toString();
    if (newValStr.includes(".")) {
        let newDValArr = newDval.split('.');
        decimalScaleValue = newDValArr['1'].length ? newDValArr['1'].length : 0;
    }

    let defaultvalue = newDval ? newDval : 0;
    const [enteredInput = defaultValue, setEnteredInput] = useState();
    const handleEnteredValue = (e) => {
        let value = e.target.value.trim();
        if (maxLength && (value.length > maxLength)) {
            e.target.value = enteredInput;
        } else {
            value = value.replace(",", "."); // decimal
            if (allowNegative) {
                value = value.replace(/[^\d.-]|(?<=.)-/g, "");
            } else {
                value = value.replace(/[^\d.]/gi, "");
            }
            setEnteredInput(value);
        }

        onChange && onChange(e); // set value to parent
        setAutoFocusInput(false)
        const caret = e.target.selectionStart
        const element = e.target
        window.requestAnimationFrame(() => {
            element.selectionStart = caret
            element.selectionEnd = caret
        })
    }

    const handleOnBlur = (e) => {
        setFocusedInput(!focusedInput);
        let val = 0;
        if (!isEmpty(e.target.value)) {
            e.target.value = Number(e.target.value).toFixed(2);
        } else {
            e.target.value = val;
        }
        onBlur && onBlur(e);
    }

    const handleOnFocus = (e) => {
        e.target.select();
    }

    const handleOnFocus2 = (e) => {
        setFocusedInput(!focusedInput)
        setAutoFocusInput(true)
    }


    let newSuffix = "€";
    if (suffix && suffix === "nosuffix") {
        newSuffix = "";
    } else if (suffix && suffix !== "nosuffix") {
        newSuffix = suffix;
    }
    return (
        <React.Fragment>
            <div className="form-group accounting-field" style={{marginBottom: fromAccountTable ? "0px" : ""}}>
                {/* When the cursor is blurred, this section of accounting box will render */}
                {focusedInput && (
                    <CurrencyInput
                        intlConfig={{locale: 'en-US'}}
                        groupSeparator={`${conversion ? "," : "."}`}
                        decimalSeparator={`${conversion ? "." : ","}`}
                        decimalsLimit={decimalLimit}
                        decimalScale={decimalScaleValue}
                        maxLength={maxLength}
                        value={enteredInput}
                        defaultValue={defaultvalue}
                        suffix={newSuffix}
                        className={className ? className : "form-control"}
                        onFocus={(e) => handleOnFocus2(e)}
                        onKeyDown={onKeyDown}
                        disabled={disabled}
                    />
                )}
                {/* When the cursor is focused, this section of the accounting box will render */}
                {!focusedInput && (
                    <CurrencyInput
                        intlConfig={{locale: 'en-US'}}
                        decimalsLimit={decimalLimit}
                        maxLength={2}
                        className={className ? className : "form-control"}
                        value={enteredInput}
                        onBlur={(e) => handleOnBlur(e)}
                        onChange={(e) => handleEnteredValue(e)}
                        onFocus={(e) => handleOnFocus(e)}
                        onKeyDown={onKeyDown}
                        allowDecimals
                        disableGroupSeparators
                        {...(autoFocusInput && {autoFocus: true})}
                        disabled={disabled}
                    />
                )}
            </div>
        </React.Fragment>
    );
};

export default AccountingBox;
