import React, {useEffect, useRef, useState} from "react";
import Loader from "../../../primer/POPUP-COMPONENTS/Loader/PrimerLoader";
import {classicStyleBelowNavbar} from "../Statics";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {
    setEditAccountingRecord,
    setGeneralCalendarReportDisplayData,
    setGeneralCalendarReportRequestData,
    setGeneralCalendarReportResponseData
} from "../../../../_reducers/DataPanel/Accounting/accountingSlice";
import {toast} from "react-toastify";
import {Badge, Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import moment from "moment";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import DateBox from "../../../primer/DateBox";
import Dropdown from "../../../primer/Dropdown";
import Input from "../../../common/Input";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import {isEmpty} from "../../../../_helpers/commonFunctions";
import "./styles/reports.css";
import * as XLSX from "xlsx";
import {jsPDF} from "jspdf";
import {liberationSerif} from "../../../../fonts/liberationSerif";
import {dejavuSans} from "../../../../fonts/DejaVuSans-normal";
import {dejavuSansBold} from "../../../../fonts/DejaVuSans-Bold-bold";
import {addTab, updateActiveMainTab} from "../../../../_reducers/TabsSlice";
import BetterCheckBox from "../../../common/BetterCheckBox";

const AccountingReportGeneralCalendar = () => {
    const [t, i18n] = useTranslation('common');
    const dispatch = useDispatch();
    const requestData = useSelector((state) => state.ACCOUNTING_DATA.generalCalendarReportRequestData);
    const responseData = useSelector((state) => state.ACCOUNTING_DATA.generalCalendarReportResponseData);
    const displayData = useSelector((state) => state.ACCOUNTING_DATA.generalCalendarReportDisplayData);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const companyDetails = useSelector((state) => state.COMPANY_DATA.companyDetails);
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const [installationOptions, setInstallationOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageChanged, setPageChanged] = useState(false);

    const pageRef = useRef();

    const [defaultSubaccountData, setDefaultSubaccountData] = useState([]);

    useEffect(() => {
        if (pageChanged) {
            setPageChanged(false);
            fetchReport();
        }
    }, [pageChanged])

    useEffect(() => {
        if (companyInstallations.length > 0) {
            let options = [{ value: "", label: "Όλες"}];
            companyInstallations.forEach((inst) => {
                options.push({ value: inst.installationMasterId, label: inst.type + " " + inst.address + " " + inst.city });
            })
            setInstallationOptions(options);
        }

        if (defaultSubaccountData.length === 0) {
            onChangeSubaccount(null, setDefaultSubaccountData);
        }
    }, []);

    const handleDoubleClick = (articleId) => {
        axios.get(process.env.REACT_APP_API_URL2 + "/accounting/get-record-by-id", {
            headers: { "Content-Type": "application/json" },
            params: {
                company: company.id,
                articleId: articleId,
            }
        }).then((res) => {
            if(res.data.status === "200") {
                dispatch(setEditAccountingRecord(res.data.data));
                const idx = TABS_DATA.findIndex((item) => "accounting-record-edit" === item);
                if(idx === -1) {
                    dispatch(addTab("accounting-record-edit"));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(idx));
                }
            } else {
                toast.error("Πρόβλημα κατά την εύρεση άρθρου.");
            }
        }).catch((error) => {
            console.log(error);
            toast.error("Πρόβλημα κατά την αποστολή αιτήματος.");
        })
    }

    const fetchReport = (fromPagination = false) => {
        let prepReq = {
            ...requestData,
            company: company.id,
            year: company.year,
        }
        if (fromPagination) {
            prepReq.page = 1;
            dispatch(setGeneralCalendarReportRequestData({...requestData, page: 1}));
        }

        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL2 + "/report/accounting-general-calendar", prepReq, {
            headers: { "Content-Type": "application/json" }
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                dispatch(setGeneralCalendarReportResponseData({data: res.data.data, totalPages: res.data.totalPages}));
            }
        }).catch((error) => {
            setLoading(false);
            console.log(error);
            toast.error("Πρόβλημα κατά την αποστολή αιτήματος.");
        })
    }

    const handleFilterChange = (e, type, name) => {
        if (type === "datebox") {
            if(moment(e).isValid()) {
                dispatch(setGeneralCalendarReportRequestData({...requestData, [name] : moment(e).format("DD/MM/YYYY")}));
            } else {
                dispatch(setGeneralCalendarReportRequestData({...requestData, [name] : ""}));
            }
        } else if (type === "dropdown") {
            dispatch(setGeneralCalendarReportRequestData({...requestData, [name] : e.value}));
        } else if (type === "input") {
            dispatch(setGeneralCalendarReportRequestData({...requestData, [name] : e.target.value}));
        } else if (type === "acdd" && name === "subAccount") {
            dispatch(setGeneralCalendarReportRequestData({...requestData, subAccount: e.row._id, subAccountInput: e.row.name}));
        } else if (type === "cb") {
            dispatch(setGeneralCalendarReportRequestData({...requestData, [name]: String(e.target.checked)}));
        }
    }

    const handleEnter = (e) => {
        if (e.key === "Enter") fetchReport();
    }

    const handleExcelExport = async () => {
        try {
            let prepReq = {
                ...requestData,
                company: company.id,
                year: company.year,
                page: -1,
            }
            const res = await axios.post(process.env.REACT_APP_API_URL2 + "/report/accounting-general-calendar", prepReq, {
                headers: { 'Content-Type': 'application/json' },
            })
            if (res.data.status === "200") {
                const data = res.data.data;
                let dateFrom = requestData.date_from;
                let dateTo = requestData.date_to;
                if (isEmpty(dateFrom)) dateFrom = moment(company.year, "YYYY", true).startOf("year").format("DD/MM/YYYY");
                if (isEmpty(dateTo)) dateTo = moment(company.year, "YYYY", true).endOf("year").format("DD/MM/YYYY");
                const dateExported = moment().format("DD/MM/YYYY HH:mm:ss");


                let rows = [];
                rows.push(["Επωνυμία:", company.name]);
                rows.push(["ΑΦΜ:", company.vatNumber]);
                rows.push(["ΔΟΥ:", companyDetails.doy]);
                rows.push([`Εύρος ημερομηνίας: ${dateFrom} - ${dateTo}`]);
                rows.push([`Ημερομηνία Εξαγωγής: ${dateExported}`]);
                rows.push([]);
                rows.push(["Α/Α", "Ημερομηνία", "Αρ. Άρθρου", "Περιγραφή", "Κωδικός", "Όνομα", "Ανάλυση", "Χρέωση", "Πίστωση", "Αναμόρφωση"]);
                for (let rec of data) {
                    for (let acc of rec.accounts) {
                        rows.push([
                            acc.no,
                            moment(rec.date).format("DD/MM/YYYY"),
                            rec.preNumber === "noprefix" ? rec.number : `${rec.preNumber} ${rec.number}`,
                            rec.reason,
                            acc.code,
                            acc.name,
                            acc.subAccount,
                            acc.debit,
                            acc.credit,
                            acc.anamorfosis,
                        ])
                    }
                    rows.push([]);
                }
                const workSheet = XLSX.utils.aoa_to_sheet(rows);
                const workBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workBook, workSheet, "Γεν. Ημερολόγιο");
                XLSX.writeFile(workBook, 'Γενικό Ημερολόγιο.xlsx');
            } else {
                toast.error(res.data.message);
            }
        } catch (err) {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        }
    }

    const handlePDFExport = async () => {
        try {
            try {
                let prepReq = {
                    ...requestData,
                    company: company.id,
                    year: company.year,
                    page: -1,
                }
                const res = await axios.post(process.env.REACT_APP_API_URL2 + "/report/accounting-general-calendar", prepReq, {
                    headers: { 'Content-Type': 'application/json' },
                })
                if (res.data.status === "200") {
                    const data = res.data.data;

                    let rows = [];
                    const columns = ["Α/Α", "Ημερομηνία", "Αρ. Άρθρου", "Περιγραφή", "Κωδικός", "Όνομα", "Ανάλυση", "Χρέωση", "Πίστωση", "Αναμόρφωση"];
                    let sum = 0;
                    for (let rec of data) {
                        sum += rec.totalCredit;
                        for (let acc of rec.accounts) {
                            rows.push([
                                acc.no,
                                moment(rec.date).format("DD/MM/YYYY"),
                                rec.preNumber === "noprefix" ? rec.number : `${rec.preNumber} ${rec.number}`,
                                rec.reason,
                                acc.code,
                                acc.name,
                                acc.subAccount,
                                acc.debit,
                                acc.credit,
                                acc.anamorfosis,
                            ])
                        }
                        rows.push([]);
                    }
                    sum = sum.toFixed(2);
                    rows.push([
                        {content: `Σύνολο`, colSpan: 7, styles: {halign: "right", fillColor: "#decae3"}},
                        {content: sum, styles: {fillColor: "#decae3"}},
                        {content: sum, styles: {fillColor: "#decae3"}},
                        {content: "", styles: {fillColor: "#decae3"}},
                    ])

                    const doc = new jsPDF('landscape');
                    let y = 23;
                    doc.addFileToVFS("arialuni.ttf", liberationSerif);
                    doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
                    doc.addFileToVFS("dejavusans.ttf", dejavuSans);
                    doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
                    doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
                    doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
                    doc.setFont("DejaVu Sans Bold");
                    doc.setFontSize(12);
                    let dateFrom = requestData.date_from;
                    let dateTo = requestData.date_to;
                    if (isEmpty(dateFrom)) dateFrom = moment(company.year, "YYYY", true).startOf("year").format("DD/MM/YYYY");
                    if (isEmpty(dateTo)) dateTo = moment(company.year, "YYYY", true).endOf("year").format("DD/MM/YYYY");
                    const header = doc.splitTextToSize(`Γενικό Ημερολόγιο από ${dateFrom} έως ${dateTo}`, 200);
                    const headerString = header.join("\n");
                    doc.text(headerString, doc.internal.pageSize.getWidth() / 4, 9, {alignment: "center"});
                    doc.setFont("DejaVu Sans Normal");
                    doc.setFontSize(9);
                    doc.text(`${company.name}, ΑΦΜ: ${company.vatNumber}`, 15, 17, {alignment: "center"});
                    doc.text(`ΔΟΥ: ${companyDetails.doy}`, 15, 21, {alignment: "center"});
                    doc.text(`Ημερομηνία Εξαγωγής: ${moment().format("DD/MM/YYYY")}`, 190, 21, {alignment: "center"});
                    const imgData = 'assets/images/prrimer-logo.png';
                    const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
                    const imgY = 3; // adjust the position as needed
                    doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
                    doc.setFont("DejaVu Sans Normal");

                    doc.autoTable({
                        startY: y,
                        head: [columns],
                        body: rows,
                        rowPageBreak: "avoid",
                        styles: {
                            font: "DejaVu Sans Normal",
                            fontSize: 8,
                            halign: "center",
                        },
                    })
                    const pageCount = doc.getNumberOfPages();
                    // Add the pagination to each page
                    for (let i = 1; i <= pageCount; i++) {
                        doc.setPage(i);
                        doc.setFontSize(10);
                        doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
                    }
                    doc.save("Γενικό Ημερολόγιο.pdf");
                } else {
                    toast.error(res.data.message);
                }
            } catch (err) {
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            }
        } catch (err) {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        }
    }

    const toFirstPage = () => {
        dispatch(setGeneralCalendarReportRequestData({...requestData, page: 1}));
        setPageChanged(true);
    }

    const toPreviousPage = () => {
        dispatch(setGeneralCalendarReportRequestData({...requestData, page: requestData.page - 1}));
        setPageChanged(true);
    }

    const toNextPage = () => {
        dispatch(setGeneralCalendarReportRequestData({...requestData, page: requestData.page + 1}));
        setPageChanged(true);
    }

    const toLastPage = () => {
        dispatch(setGeneralCalendarReportRequestData({...requestData, page: responseData.totalPages}));
        setPageChanged(true);
    }

    const onChangeSubaccount = (keyword, setData) => {
        let requestData = { company: company.id }
        if (!isEmpty(keyword) && String(keyword).length >= 2) requestData.keyword = keyword;
        axios.get(`${process.env.REACT_APP_API_URL2}/accounting/mass-subaccount-filter`, {
            headers: {"Content-Type": "application/json"},
            params: {
                ...requestData
            }
        }).then((res) => {
            if (res.data.status === "200") {
                setData(res.data.data);
            } else {
                setData([]);
                console.log(res.data);
            }
        }).catch((error) => {
            setData([]);
            console.log(error);
        })
    }

    return (
        <React.Fragment>
            {loading && (<Loader doNotShowSmallText={true}/>)}
            <div ref={pageRef} key={"wholeBody"} hidden={loading}
                 style={classicStyleBelowNavbar}
                 onKeyDown={(e) => handleEnter(e)}>
                <Row key={"filters"} className={"mb-2"}>
                    <Col md={2}>
                        <DateHelper
                            dateFromAndToFunction={(from, to) => dispatch(setGeneralCalendarReportRequestData({...requestData, date_from: from, date_to: to}))}
                        />
                    </Col>
                    <Col md={2}>
                        <DateBox
                            dateFormat="dd-MM-yyyy"
                            name="date_from"
                            selected={requestData.date_from !== "" ? moment(requestData.date_from, "DD-MM-YYYY").toDate() : ""}
                            label={"Ημερομηνία από"}
                            onChange={(e) => handleFilterChange(e, "datebox", "date_from")}
                        />
                    </Col>
                    <Col md={2}>
                        <DateBox
                            dateFormat="dd-MM-yyyy"
                            name="date_to"
                            selected={requestData.date_to !== "" ? moment(requestData.date_to, "DD-MM-YYYY").toDate() : ""}
                            label={"Ημερομηνία μέχρι"}
                            onChange={(e) => handleFilterChange(e, "datebox", "date_to")}
                        />
                    </Col>
                    <Col md={3}>
                        <Dropdown
                            name={"installation"}
                            label={"Εγκατάσταση"}
                            options={installationOptions}
                            key={Math.random()}
                            defaultValue={installationOptions.find((el) => el.value === requestData.installation) ? installationOptions.find((el) => el.value === requestData.installation) : ""}
                            onChange={(e) => handleFilterChange(e, "dropdown", "installation")}
                        />
                    </Col>
                    <Col md={3}>
                        <Input
                            name={"code_prefix"}
                            label={"Πρόθεμα κωδικού"}
                            placeholder={"Πρόθεμα κωδικού"}
                            value={requestData.code_prefix}
                            autoComplete={"off"}
                            key={"code_prefix"}
                            onChange={(e) => handleFilterChange(e, "input", "code_prefix")}
                        />
                    </Col>
                </Row>
                <Row key={"filters2"}>
                    <Col md={4}>
                        <AdvancedCombobox
                            name={"subAccount"}
                            className="w-100 ac-select-dropdown"
                            searchableDropdownEnable={false}
                            label={"Υπολογαριασμός"}
                            placeholder={"Πληκτρολογήστε υπολογαριασμό"}
                            defaultValue={requestData.subAccountInput}
                            data={defaultSubaccountData}
                            onChange={(e) => handleFilterChange(e, "acdd", "subAccount")}
                            excludeColumns={[]}
                            columns={[
                                {label: "Όνομα", name: "name"},
                                {label: "Τύπος", name: "type"},
                            ]}
                            editEnable={false}
                            deleteEnable={false}
                            searchTableEnable={false}
                            showTableOptionOnSelect={true}
                            key={Math.random()}
                            onChangeSearch={onChangeSubaccount}
                            disabled={!isEmpty(requestData.subAccount)}
                        />
                    </Col>
                    <Col md={1}>
                        {!isEmpty(requestData.subAccount) &&
                            <button style={{position: "absolute", bottom: "16px"}}
                                    className="btn btn-sm"
                                    title="Καθαρισμός υπολογαριασμού"
                                    onClick={() => dispatch(setGeneralCalendarReportRequestData({...requestData, subAccount: "", subAccountInput: ""}))}><i className="fas fa-xmark"></i></button>
                        }
                    </Col>
                    <Col md={2}>
                        <BetterCheckBox
                            name={"onlyAnamorfosis"}
                            text={"Προβολή μόνο άρθρων με λογιστική αναμόρφωση"}
                            checked={requestData.onlyAnamorfosis === "true"}
                            onChange={(e) => handleFilterChange(e, "cb", "onlyAnamorfosis")}
                        />
                    </Col>
                    <Col md={2}>
                        <Button onClick={() => fetchReport()}>Ανανέωση</Button>
                    </Col>
                </Row>
                {responseData.data.length > 0 ? (
                    <React.Fragment>
                        <Badge key={"b1"} bg={"primary"} style={{userSelect: "none", cursor: "pointer"}} onClick={() => dispatch(setGeneralCalendarReportDisplayData({...displayData, fontSize: displayData.fontSize + 1}))}>+</Badge>
                        <Badge key={"b2"} bg={"primary"} style={{userSelect: "none"}} className={"ml-1"}>Μέγεθος: {displayData.fontSize}</Badge>
                        <Badge key={"b3"} bg={"primary"} style={{userSelect: "none", cursor: "pointer"}} className={"ml-1 mt-2"} onClick={() => dispatch(setGeneralCalendarReportDisplayData({...displayData, fontSize: displayData.fontSize - 1}))}>-</Badge>
                        <Badge key={"b4"} bg={"primary"} style={{userSelect: "none", cursor: "pointer"}} className={"ml-4"} onClick={() => handleExcelExport()}>Εξαγωγή XLSX</Badge>
                        <Badge key={"b5"} bg={"primary"} style={{userSelect: "none", cursor: "pointer"}} className={"ml-1 mb-3"} onClick={() => handlePDFExport()}>Εξαγωγή PDF</Badge>

                        <div className="d-flex justify-content-end mb-2">
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toFirstPage()}
                                disabled={requestData.page === 1 || loading}
                            >
                                {t('SalesBalanceSheet.first')}
                            </Button>
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toPreviousPage()}
                                disabled={requestData.page === 1 || loading}
                            >
                                {t('SalesBalanceSheet.previous')}
                            </Button>
                            <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1"
                                   bg="primary">{t('SalesBalanceSheet.page')} {requestData.page} / {responseData.totalPages}</Badge>
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toNextPage()}
                                disabled={requestData.page === responseData.totalPages || loading}
                            >
                                {t('SalesBalanceSheet.next')}
                            </Button>
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toLastPage()}
                                disabled={requestData.page === responseData.totalPages || loading}
                            >
                                {t('SalesBalanceSheet.last')}
                            </Button>
                        </div>

                        <div>
                            <table className={"simpleClassicTable mb-3"} style={{fontSize: `${displayData.fontSize}px`}}>
                                <colgroup>
                                    <col span={1} style={{width: "3%"}}></col>
                                    <col span={1} style={{width: "7%"}}></col>
                                    <col span={1} style={{width: "8%"}}></col>
                                    <col span={1} style={{width: "20%"}}></col>
                                    <col span={1} style={{width: "10%"}}></col>
                                    <col span={1} style={{width: "20%"}}></col>
                                    <col span={1} style={{width: "15%"}}></col>
                                    <col span={1} style={{width: "5%"}}></col>
                                    <col span={1} style={{width: "5%"}}></col>
                                    <col span={1} style={{width: "7%"}}></col>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th>Α/Α</th>
                                    <th>Ημερομηνία</th>
                                    <th>Αρ. Άρθρου</th>
                                    <th>Περιγραφή</th>
                                    <th>Κωδικός</th>
                                    <th>Όνομα</th>
                                    <th>Ανάλυση</th>
                                    <th>Χρέωση</th>
                                    <th>Πίστωση</th>
                                    <th>Αναμόρφωση</th>
                                </tr>
                                </thead>
                                <tbody>
                                {responseData.data.map((rec, idx) => {
                                    if (rec._id === "previousBalance") {
                                        return (
                                            <React.Fragment>
                                                <tr key={`previousBalance-${idx}`} className={"row-hoverable"}>
                                                    <td colSpan={7} style={{textAlign: "center", fontWeight: "600"}}><i>ΑΠΟ ΜΕΤΑΦΟΡΑ</i></td>
                                                    <td style={{fontWeight: "600"}}>{rec.sum}</td>
                                                    <td style={{fontWeight: "600"}}>{rec.sum}</td>
                                                    <td style={{fontWeight: "600"}}>{rec.sumAnamorfosis}</td>
                                                </tr>
                                                <tr key={`totals-${idx}-blank`} style={{backgroundColor: "lightgray"}}>
                                                    <td colSpan={10}>&nbsp;</td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    } else if (rec._id === "pageTotals") {
                                        return (
                                            <React.Fragment>
                                                <tr key={`pageTotals-${idx}`} className={"row-hoverable"}>
                                                    <td colSpan={7} style={{textAlign: "center", fontWeight: "600"}}><i>ΣΥΝΟΛΟ ΣΕΛΙΔΑΣ</i></td>
                                                    <td style={{fontWeight: "600"}}>{rec.sum}</td>
                                                    <td style={{fontWeight: "600"}}>{rec.sum}</td>
                                                    <td style={{fontWeight: "600"}}>{rec.sumAnamorfosis}</td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    } else if (rec._id === "currentBalance") {
                                        return (
                                            <React.Fragment>
                                                <tr key={`pageTotals-${idx}`} className={"row-hoverable"}>
                                                    <td colSpan={7} style={{textAlign: "center", fontWeight: "600"}}><i>ΤΡΕΧΟΝ ΣΥΝΟΛΟ</i></td>
                                                    <td style={{fontWeight: "600"}}>{rec.sum}</td>
                                                    <td style={{fontWeight: "600"}}>{rec.sum}</td>
                                                    <td style={{fontWeight: "600"}}>{rec.sumAnamorfosis}</td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    } else if (rec._id === "totals") {
                                        return (
                                            <React.Fragment>
                                                <tr key={`totals-${idx}`} className={"row-hoverable"}>
                                                    <td colSpan={7} style={{textAlign: "center", fontWeight: "600"}}><i>ΓΕΝΙΚΟ ΣΥΝΟΛΟ</i></td>
                                                    <td style={{fontWeight: "600"}}>{rec.sum}</td>
                                                    <td style={{fontWeight: "600"}}>{rec.sum}</td>
                                                    <td style={{fontWeight: "600"}}>{rec.sumAnamorfosis}</td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    } else {
                                        return (
                                            <React.Fragment>
                                                {rec.accounts.map((acc, idx2) => (
                                                    <tr key={`argc-acc-${idx2}`} className={"row-hoverable"} onDoubleClick={() => handleDoubleClick(rec._id)}>
                                                        <td>{idx2 + 1}</td>
                                                        <td>{moment(rec.date).format("DD/MM/YYYY")}</td>
                                                        <td>{rec.preNumber === "noprefix" ? rec.number : `${rec.preNumber} ${rec.number}`}</td>
                                                        <td>{rec.reason}</td>
                                                        <td>{acc.code}</td>
                                                        <td>{acc.name}</td>
                                                        <td>{acc.subAccount}</td>
                                                        <td>{acc.debit}</td>
                                                        <td>{acc.credit}</td>
                                                        <td>{acc.anamorfosis}</td>
                                                    </tr>
                                                ))}
                                                {idx + 1 !== responseData.data.length && (
                                                    <tr key={`argc-space-${idx}`} style={{backgroundColor: "lightgray"}}>
                                                        <td colSpan={10}>&nbsp;</td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        )
                                    }
                                })}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                ) : (
                    <h6 key={"text1"} className={"mt-1"}>Δεν βρέθηκαν δεδομένα</h6>
                )}
            </div>
        </React.Fragment>
    )
}

export default AccountingReportGeneralCalendar
