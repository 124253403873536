import React, {useEffect, useState} from 'react';
import {Accordion, Alert, Badge, Button, Col, Modal, Row, Spinner, Stack} from "react-bootstrap";
import Input from "../../../common/Input";
import Dropdown from "../../../primer/Dropdown";
import {toast} from "react-toastify";
import {checkPermission, getEmployeeCategoryOptions, isEmpty} from "../../../../_helpers/commonFunctions";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {Popconfirm, Popover} from "antd";
import {
    resetTempNewShift, setCompanyHasErgani,
    setPayrollSettings,
    setTempNewShift,
} from "../../../../_reducers/DataPanel/Payroll/payrollSlice";
import EditableTable from "../../../primer/Editable-Table";
import BetterCheckBox from "../../../common/BetterCheckBox";
import {fetchPayrollSettings} from "../../../../_apis/api";
import "../../../primer/POPUP-COMPONENTS/modalWidths.css";
import moment from "moment";
import {
    colorMapping,
    decimalToHourMinutesFormat, formatEmployeeIdentifier,
    getWorkType,
    greekMonthOptions,
    permitTypes
} from "./Components/constants";
import DateBox from "../../../primer/DateBox";

export const PayrollSettings = () => {
    const dispatch = useDispatch();
    const tempNewShift = useSelector((state) => state.PAYROLL.tempNewShift);
    const payrollSettings = useSelector((state) => state.PAYROLL.payrollSettings);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const [installationOptions, setInstallationOptions] = useState([]);

    const [activationLoading, setActivationLoading] = useState(false);
    const [loadingSettings, setLoadingSettings] = useState(false);

    const permissionsData = JSON.parse(localStorage.getItem("permissions"));

    const defaultExProanData = {
        installationMasterId: "0",
        month: moment().format("MM"),
        year: moment().format("YYYY"),
        yesNoOption: "1",
        comments: "",
    }
    const [showExProanModal, setShowExProanModal] = useState(false);
    const [exProanData, setExProanData] = useState({...defaultExProanData});
    const [yearOptions, setYearOptions] = useState([]);
    const [loadingExProan, setLoadingExProan] = useState(false);

    const [showRetrieveEmployeesModal, setShowRetrieveEmployeesModal] = useState(false);
    const [retrievingEmployees, setRetrievingEmployees] = useState(false);
    const [retrievedEmployeesData, setRetrievedEmployeesData] = useState([]);
    const [savingRetrievedEmployees, setSavingRetrievedEmployees] = useState(false);

    const [showRetrieveProgramModal, setShowRetrieveProgramModal] = useState(false);
    const [retrievingProgram, setRetrievingProgram] = useState(false);
    const defaultProgramData = {
        date_input: moment().toDate(),
        date_from: moment().startOf("week").format("DD/MM/YYYY"),
        date_to: moment().endOf("week").format("DD/MM/YYYY"),
        data: { employees: [] }
    }
    const [retrievedProgramData, setRetrievedProgramData] = useState({...defaultProgramData});
    const [savingRetrievedProgram, setSavingRetrievedProgram] = useState(false);

    const [showRetrievePermitsModal, setShowRetrievePermitsModal] = useState(false);
    const [retrievingPermits, setRetrievingPermits] = useState(false);
    const [retrievedPermitsData, setRetrievedPermitsData] = useState({});
    const [savingRetrievedPermits, setSavingRetrievedPermits] = useState(false);

    const [showMassProgramsModal, setShowMassProgramsModal] = useState(false);
    const [loadingMassPrograms, setLoadingMassPrograms] = useState(false);

    const defaultNewNoWorkingDay = {
        type: "normal",
        day: "",
        notes: "",
    }
    const [newNoWorkingDay, setNewNoWorkingDay] = useState({...defaultNewNoWorkingDay});
    const [newNoWorkingDayModal, setNewNoWorkingDayModal] = useState(false);

    const envOptions = [
        { label: "Δοκιμή", value: "test" },
        { label: "Παραγωγικό", value: "production" }
    ]
    const [erganiCredentials, setErganiCredentials] = useState({
        username: "",
        password: "",
        environment: "test",
    })
    const yesNoOptions = [
        { label: "Όχι", value: "0" },
        { label: "Ναι", value: "1" }
    ]
    const noWorkingDaysOptions = [
        { label: "Αργία", value: "publicHoliday" },
        { label: "Μεμονωμένη ημέρα", value: "normal" },
        { label: "Επαναλαμβανόμενη", value: "recurring" },
    ]

    const tableColumns = [
        {
            name: "Όνομα βάρδιας",
            field: "name",
            editable: true,
            width: "25%",
            required: true,
            inputType: {
                type: "input"
            }
        },
        {
            name: "Ώρα από",
            field: "hourFrom",
            editable: true,
            width: "15%",
            inputType: {
                type: "hour-picker"
            }
        },
        {
            name: "Ώρα μέχρι",
            field: "hourTo",
            editable: true,
            width: "15%",
            inputType: {
                type: "hour-picker"
            }
        },
        {
            name: "Σπαστό ωράριο από",
            field: "splitHourFrom",
            editable: true,
            width: "15%",
            inputType: {
                type: "hour-picker"
            }
        },
        {
            name: "Σπαστό ωράριο μέχρι",
            field: "splitHourTo",
            editable: true,
            width: "15%",
            inputType: {
                type: "hour-picker"
            }
        },
    ]

    const tableColumnsMultipliers = [
        {
            name: "Κατηγορία Εργαζομένου",
            field: "employeeCategory",
            editable: true,
            width: "25%",
            inputType: {
                type: "select",
                options: getEmployeeCategoryOptions(),
            }
        },
        {
            name: "Συντ. Ημέρας",
            field: "day",
            editable: true,
            width: "15%",
            required: true,
            inputType: {
                type: "input",
                config: {
                    type: "number",
                }
            }
        },
        {
            name: "Συντ. Νύχτας",
            field: "night",
            editable: true,
            width: "15%",
            required: true,
            inputType: {
                type: "input",
                config: {
                    type: "number",
                }
            }
        },
        {
            name: "Συντ. Κυριακής",
            field: "sunday",
            editable: true,
            width: "15%",
            required: true,
            inputType: {
                type: "input",
                config: {
                    type: "number",
                }
            }
        },
        {
            name: "Συντ. Υπερεργασίας",
            field: "overwork",
            editable: true,
            width: "15%",
            required: true,
            inputType: {
                type: "input",
                config: {
                    type: "number",
                }
            }
        },
        {
            name: "Συντ. Υπερωρίας",
            field: "overtime",
            editable: true,
            width: "15%",
            required: true,
            inputType: {
                type: "input",
                config: {
                    type: "number",
                }
            }
        }
    ]

    useEffect(() => {
        fetchSettings();

        if (companyInstallations.length > 0) {
            let options = [];
            companyInstallations.forEach((inst) => {
                options.push({ value: inst.installationMasterId, label: inst.type + " " + inst.address + " " + inst.city });
            })
            setInstallationOptions(options);

            let tmp = [];
            for (let i = 2023; i < Number(moment().add(10, "years").format("YYYY")); i++) {
                tmp.push({ label: String(i), value: String(i) });
            }
            setYearOptions(tmp);
        }
    }, []);

    useEffect(() => {
        if (!showExProanModal) {
            setExProanData({...defaultExProanData});
        }
    }, [showExProanModal])

    useEffect(() => {
        if (!showRetrieveEmployeesModal) {
            setRetrievingEmployees(false);
            setRetrievedEmployeesData([]);
            setSavingRetrievedEmployees(false);
        }
    }, [showRetrieveEmployeesModal])

    useEffect(() => {
        if (!showRetrieveProgramModal) {
            setShowRetrieveProgramModal(false);
            setRetrievingProgram(false);
            setRetrievedProgramData({...defaultProgramData});
            setSavingRetrievedProgram(false);
        }
    }, [showRetrieveProgramModal])

    useEffect(() => {
        if (!newNoWorkingDay) {
            setNewNoWorkingDay({...defaultNewNoWorkingDay});
        }
    }, [newNoWorkingDay])

    const handleActivateErgani = () => {
        if (!isEmpty(erganiCredentials) && !isEmpty(erganiCredentials.password)) {
            setActivationLoading(true);
            axios.post(`${process.env.REACT_APP_API_URL2}/payroll/activate-company`, {
                ...erganiCredentials,
                company: company.id
            }, {
                headers: {'Content-Type': 'application/json'}
            }).then((res) => {
                setActivationLoading(false);
                if (res.data.status === "200") {
                    dispatch(setCompanyHasErgani(true));
                    toast.success(res.data.message);
                } else {
                    toast.error(res.data.message, {autoClose: 8000});
                }
            }).catch((err) => {
                setActivationLoading(false);
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        } else {
            toast.error("Παρακαλώ συμπληρώστε όνομα και κωδικό χρήστη.");
        }
    }

    const fetchSettings = () => {
        axios.get(`${process.env.REACT_APP_API_URL2}/payroll/fetch-settings`, {
            headers: {'Content-Type': 'application/json'},
            params: {
                company: company.id,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                setErganiCredentials(res.data.erganiCredentials);
                if (res.data.payrollSettings) {
                    dispatch(setPayrollSettings({...res.data.payrollSettings}));
                }
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την ανάκτηση στοιχείων.");
        })
    }

    const tableShiftsFunctions = {
        insert: (e, data) => {
            const cloneNewTempShift = structuredClone(tempNewShift);
            cloneNewTempShift[data.field] = data.value;

            if (!isEmpty(cloneNewTempShift.name) && (cloneNewTempShift.hourFrom !== "00:00" || cloneNewTempShift.hourTo !== "00:00")) { // Insert
                let cloneShifts = structuredClone(payrollSettings.shifts);
                cloneShifts.push(cloneNewTempShift);
                dispatch(setPayrollSettings({...payrollSettings, shifts: cloneShifts}));
                dispatch(resetTempNewShift());
            } else { // Pre-insert row update
                dispatch(setTempNewShift(cloneNewTempShift));
            }
        },
        update: (data, id, rowIndex) => {
            let cloneShifts = structuredClone(payrollSettings.shifts);
            cloneShifts[rowIndex] = {...cloneShifts[rowIndex], ...data};
            dispatch(setPayrollSettings({...payrollSettings, shifts: cloneShifts}));
        },
        selected: () => {},
        delete: (id, rowIndex) => {
            let cloneShifts = structuredClone(payrollSettings.shifts);
            cloneShifts.splice(rowIndex, 1);
            dispatch(setPayrollSettings({...payrollSettings, shifts: cloneShifts}));
        }
    }

    const formatMultiplierObj = (arr) => {
        let cloneArr = structuredClone(arr);
        if (cloneArr) {
            for (let cloneObj of cloneArr) {
                for (let key in cloneObj) {
                    if (!isNaN(cloneObj[key])) {
                        cloneObj[key] = +Number(cloneObj[key]).toFixed(2);
                    }
                }
            }
        }
        return cloneArr;
    }

    const tableMultipliersFunctions = {
        insert: (e, data) => {
            if (data.field !== "employeeCategory") {
                return toast.error("Παρακαλώ επιλέξτε κατηγορία πρώτα.");
            } else if (payrollSettings?.multipliers?.some((el) => el.employeeCategory === data.value)) {
                return toast.error("Η κατηγορία αυτή υπάρχει ήδη.");
            }
            const newOne = {
                employeeCategory: "",
                day: 1,
                night: 1.25,
                sunday: 1.75,
                overwork: 1.2,
                overtime: 1.4,
                ...{ [data.field] : data.value }
            }
            let cloneMultipliers = structuredClone(payrollSettings.multipliers);
            if (!payrollSettings.multipliers) {
                cloneMultipliers = [newOne];
            } else {
                cloneMultipliers.push(newOne);
            }
            dispatch(setPayrollSettings({...payrollSettings, multipliers: formatMultiplierObj(cloneMultipliers)}));
        },
        update: (data, id, rowIndex) => {
            if ("employeeCategory" in data) {
                if (payrollSettings?.multipliers?.some((el) => el.employeeCategory === data.employeeCategory)) {
                    dispatch(setPayrollSettings({...payrollSettings})); // Refresh
                    return toast.error("Η κατηγορία αυτή υπάρχει ήδη.");
                } else if (isEmpty(data.employeeCategory) || data.employeeCategory === "0") {
                    dispatch(setPayrollSettings({...payrollSettings})); // Refresh
                    return toast.error("Παρακαλώ επιλέξτε μία κατηγορία.");
                }
            }
            let cloneMultipliers = structuredClone(payrollSettings.multipliers);
            cloneMultipliers[rowIndex] = {...cloneMultipliers[rowIndex], ...data};
            dispatch(setPayrollSettings({...payrollSettings, multipliers: formatMultiplierObj(cloneMultipliers)}));
        },
        selected: () => {},
        delete: (id, rowIndex) => {
            let cloneMultipliers = structuredClone(payrollSettings.multipliers);
            cloneMultipliers.splice(rowIndex, 1);
            dispatch(setPayrollSettings({...payrollSettings, multipliers: formatMultiplierObj(cloneMultipliers)}));
        }
    }

    const handleOnChange = (e, type, name) => {
        if (type === "cb") {
            dispatch(setPayrollSettings({...payrollSettings, [name] : e.target.checked}));
        }
    }

    const handleSavePayrollSettings = () => {
        // Quick validate
        if (payrollSettings?.multipliers) {
            if (payrollSettings.multipliers.some((el) => {
                for (let key in el) {
                    if (isEmpty(el[key]) || el.employeeCategory === "0") return true;
                }
                return false;
            })) {
                return toast.error("Παρακαλώ συμπληρώστε όλα τα πεδία στον πίνακα των συντελεστών.");
            }
        }

        setLoadingSettings(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/save-settings`, {
            ...payrollSettings,
            company: company.id,
        }).then((res) => {
            setLoadingSettings(false);
            if (res.data.status === "200") {
                toast.success("Επιτυχής αποθήκευση ρυθμίσεων!");
                dispatch(fetchPayrollSettings(company.id));
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoadingSettings(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleSaveExProan = () => {
        setLoadingExProan(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/post-exproan`, {
            ...exProanData,
            company: company.id,
        }).then((res) => {
            setLoadingExProan(false);
            if (res.data.status === "200") {
                toast.success(res.data.message);
                setShowExProanModal(false);
            } else {
                toast.error(res.data.message, {autoClose: 10000});
            }
        }).catch((err) => {
            setLoadingExProan(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleFetchErganiEmployees = () => {
        setRetrievingEmployees(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/get-employees-from-ergani`, {
            company: company.id,
        }, {
            headers: { 'Content-Type': 'application/json' },
        }).then((res) => {
            setRetrievingEmployees(false);
            if (res.data.status === "200") {
                if (res.data.data?.length === 0) {
                    toast.info("Δεν βρέθηκε κανένας εργαζόμενος.");
                    setRetrievedEmployeesData([]);
                } else {
                    toast.success(res.data.message);
                    setRetrievedEmployeesData(res.data.data);
                }
            } else {
                toast.error(res.data.message, {autoClose: 5000});
                setRetrievedEmployeesData([]);
            }
        }).catch((err) => {
            setRetrievingEmployees(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος. Παρακαλώ ξαναδοκιμάστε");
        })
    }

    const handleSaveRetrievedEmployees = () => {
        setSavingRetrievedEmployees(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/save-employees-from-ergani`, {
            data: retrievedEmployeesData,
            company: company.id
        }, {
            headers: {'Content-Type': 'application/json'},
        }).then((res) => {
            setSavingRetrievedEmployees(false);
            if (res.data.status === "200") {
                toast.success(res.data.message);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setSavingRetrievedEmployees(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος. Παρακαλώ ξαναδοκιμάστε");
        })
    }

    const handleFetchErganiProgram = () => {
        setRetrievingProgram(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/fetch-program-from-ergani`, {
            company: company.id,
            date_from: retrievedProgramData.date_from,
            date_to: retrievedProgramData.date_to,
        }, {
            headers: {'Content-Type': 'application/json'},
        }).then((res) => {
            setRetrievingProgram(false);
            if (res.data.status === "200") {
                setRetrievedProgramData({...retrievedProgramData, data: res.data.data});
                toast.success("Η άντληση έγινε επιτυχώς.");
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setRetrievingProgram(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος. Παρακαλώ ξαναδοκιμάστε");
        })
    }

    const renderFetchedEmployeeProgram = (dailyCalendar, idx) => {
        let compArr = [];
        let daysDone = [];
        dailyCalendar.forEach((dc, idx2) => {
            if (daysDone.includes(dc.day)) return;
            daysDone.push(dc.day);
            let findSameDay = dailyCalendar.filter((el) => el.day === dc.day);
            const dayFormat = moment(retrievedProgramData.date_from, "DD/MM/YYYY").add(dc.day, "days").format("DD/MM/YYYY");
            if (findSameDay.length === 1) {
                compArr.push(
                    <div style={{
                        padding: "8px",
                        borderRadius: "8px",
                        width: "13.25%",
                        backgroundColor: colorMapping[dc.workType],
                        marginRight: "1%",
                        textAlign: "center",
                        minHeight: "120px",
                    }} key={`emp-${idx}-${idx2}`}>
                        <span style={{textDecoration: "underline", fontWeight: "600"}}>{dayFormat}</span><br/>
                        {getWorkType(dc)}<br/>
                        {dc?.allDay ? (
                            <React.Fragment>
                                Όλη μέρα<br/>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {decimalToHourMinutesFormat(dc.hourFrom)} - {decimalToHourMinutesFormat(dc.hourTo)}<br/>
                            </React.Fragment>
                        )}
                        Εγκατάσταση: {dc.installationMasterId}
                    </div>
                )
            } else {
                let compSemiArr = [];
                for (let subDc of findSameDay) {
                    compSemiArr.push(
                        <div style={{
                            padding: "8px",
                            borderRadius: "8px",
                            backgroundColor: colorMapping[subDc.workType],
                            width: "100%",
                            textAlign: "center",
                            height: `${Number(100 / findSameDay.length)}%`,
                            minHeight: `${Number(120 / findSameDay.length)}px`,
                            marginBottom: "2px"
                        }} key={`emp-${idx}-${idx2}`}>
                            <span style={{textDecoration: "underline", fontWeight: "600"}}>{dayFormat}</span><br/>
                                {getWorkType(dc)}<br/>
                                {subDc?.allDay ? (
                                <React.Fragment>
                                    Όλη μέρα<br/>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {decimalToHourMinutesFormat(subDc.hourFrom)} - {decimalToHourMinutesFormat(subDc.hourTo)}<br/>
                                </React.Fragment>
                            )}
                            Εγκατάσταση: {subDc.installationMasterId}
                        </div>
                    )
                }
                compArr.push(
                    <div style={{width: "13.25%", marginRight: "1%"}}>
                        {compSemiArr}
                    </div>
                )
            }
        })
        return <div style={{display: "flex"}}>{compArr}</div>;
    }

    const handleSaveRetrievedProgram = () => {
        setSavingRetrievedProgram(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/save-working-program`, {
            dateFrom: retrievedProgramData.date_from,
            dateTo: retrievedProgramData.date_to,
            ...retrievedProgramData.data,
            force: "true",
            company: company.id,
        }, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            setSavingRetrievedProgram(false);
            if (res.data.status === "200") {
                setShowRetrieveProgramModal(false);
                toast.success("Επιτυχής αποθήκευση!");
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setSavingRetrievedProgram(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleSaveNewNoWorkingDay = () => {
        if (isEmpty(newNoWorkingDay.date)) {
            toast.error("Παρακαλώ επιλέξτε ημερομηνία.");
        } else {
            let clone = [];
            if (payrollSettings.companyNonWorkingDays) {
                clone = structuredClone(payrollSettings.companyNonWorkingDays);
            }
            clone.push(newNoWorkingDay);
            setNewNoWorkingDayModal(false);
            dispatch(setPayrollSettings({...payrollSettings, companyNonWorkingDays: clone}));
        }
    }

    const handleDeleteNoWorkingDay = (rowIdx) => {
        let clone = structuredClone(payrollSettings.companyNonWorkingDays);
        clone.splice(rowIdx, 1);
        dispatch(setPayrollSettings({...payrollSettings, companyNonWorkingDays: clone}));
    }

    const handleRetrievePermits = () => {
        setRetrievingPermits(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/get-yearly-permits`, {company: company.id, year: company.year}, {
            headers: { "Content-Type": "application/json" }
        }).then((res) => {
            setRetrievingPermits(false);
            if (res.data.status === "200") {
                toast.success("Επιτυχής άντληση αδειών.");
                setRetrievedPermitsData(res.data.data);
            } else {
                toast.error(res.data.message);
                setRetrievedPermitsData({});
            }
        }).catch((err) => {
            setRetrievingPermits(false);
            setRetrievedPermitsData({});
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleSaveWorkPermits = () => {
        setSavingRetrievedPermits(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/mass-post-work-permits`, {...retrievedPermitsData}, {
            headers: { "Content-Type": "application/json" }
        }).then((res) => {
            setSavingRetrievedPermits(false);
            if (res.data.status === "200") {
                toast.success("Επιτυχής αποθήκευση ετήσιων αδειών.");
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setSavingRetrievedPermits(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const massFetchPrograms = () => {
        setLoadingMassPrograms(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/mass-fetch-save-working-programs`, {
            company: company.id,
            date_to: moment().format("DD/MM/YYYY")
        }, {
            headers: { "Content-Type": "application/json" }
        }).then((res) => {
            setLoadingMassPrograms(false);
            if (res.data.status === "200") {
                setShowMassProgramsModal(false);
                toast.success("Η διαδικασία ολοκληρώθηκε επιτυχώς.");
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoadingMassPrograms(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    return (
        <React.Fragment>
            <Row className={"mb-2"}>
                <Col md={4}>
                    <div className={"text-muted mb-1"}><i>Στοιχεία Εργάνη</i></div>
                    <Popover placement={"right"} content={
                        <div>
                            Το όνομα χρήστη που χρησιμοποιείτε για να συνδεθείτε στο https://eservices.yeka.gr
                        </div>
                    }>
                        <>
                            <Input
                                className={"mb-2"}
                                name={"erganiUsername"}
                                label={"Όνομα Χρήστη"}
                                value={erganiCredentials.username}
                                autoComplete={"off"}
                                onChange={(e) => setErganiCredentials({...erganiCredentials, username: e.target.value})}
                            />
                        </>
                    </Popover>

                    <Popover placement={"right"} content={
                        <div>
                            Ο κωδικός που χρησιμοποιείτε για να συνδεθείτε στο https://eservices.yeka.gr
                        </div>
                    }>
                        <>
                            <Input
                                className={"mb-2"}
                                name={"erganiPassword"}
                                label={"Κωδικός Χρήστη"}
                                type={"password"}
                                value={erganiCredentials.password}
                                autoComplete={"off"}
                                onChange={(e) => setErganiCredentials({...erganiCredentials, password: e.target.value})}
                            />
                        </>
                    </Popover>
                    <Dropdown
                        name={"erganiEnvironment"}
                        options={envOptions}
                        label={"Περιβάλλον"}
                        defaultValue={envOptions.find((el) => el.value === erganiCredentials.environment)}
                        key={Math.random()}
                        onChange={(e) => setErganiCredentials({...erganiCredentials, environment: e.value})}
                    />
                    <div style={{textAlign: "center"}}>
                        <Button onClick={() => handleActivateErgani()} disabled={activationLoading}>
                            Ενεργοποίηση {activationLoading &&
                            <Spinner className={"ml-2"} animation={"border"} variant={"dark"}/>}
                        </Button>
                    </div>
                    <div className={"mt-2"}>
                        <div className={"text-muted mb-1"}><i>Λοιπές δηλώσεις Εργάνης</i></div>
                        <Stack gap={1}>
                            <Button size={"sm"} onClick={() => setShowExProanModal(true)}>
                                Δημιουργία δήλωσης εξαίρεσης από την υποχρέωση προαναγγελίας
                            </Button>
                        </Stack>
                    </div>
                    {checkPermission("custom-full-admin", permissionsData) && (
                        <React.Fragment>
                            <div className={"mt-3 mb-2"}>
                                <div className={"text-muted"}><i>Λειτουργίες Άντλησης δεδομένων από την Εργάνη</i></div>
                            </div>
                            <Stack gap={1}>
                                <Button size={"sm"} onClick={() => setShowRetrieveEmployeesModal(true)}>
                                    Άντληση εργαζομένων από την Εργάνη
                                </Button>
                                <Button size={"sm"} onClick={() => setShowMassProgramsModal(true)}>
                                    Άντληση προηγούμενων προγραμμάτων
                                </Button>
                                <Button size={"sm"} onClick={() => setShowRetrieveProgramModal(true)}>
                                    Άντληση εβδομαδιαίου προγράμματος από την Εργάνη
                                </Button>
                                <Button size={"sm"} onClick={() => setShowRetrievePermitsModal(true)}>
                                    Άντληση αδειών από την Εργάνη
                                </Button>
                            </Stack>
                        </React.Fragment>
                    )}
                </Col>
                <Col md={8} style={{borderLeft: "1px solid lightgray"}}>
                    <div className={"text-muted mb-2"}><i>Βάρδιες</i></div>
                    <EditableTable
                        tableName={"Payroll Settings Shifts"}
                        key={Math.random()}
                        data={payrollSettings.shifts}
                        columns={tableColumns}
                        onUpdate={tableShiftsFunctions}
                        allowInsertRow={true}
                        allowActions={true}
                    />
                    <hr/>
                    <div className={"text-muted mb-2"}><i>Συντελεστές</i></div>
                    <EditableTable
                        tableName={"Payroll Settings Multipliers"}
                        key={Math.random()}
                        data={payrollSettings.multipliers}
                        columns={tableColumnsMultipliers}
                        onUpdate={tableMultipliersFunctions}
                        allowInsertRow={true}
                        allowActions={true}
                    />
                    <hr/>
                    <div className={"text-muted mb-2"}>
                        <i>Ημέρες μη εργασίας</i>
                        <Badge bg={"primary"} style={{cursor: "pointer"}} className={"ml-2"} onClick={() => setNewNoWorkingDayModal(true)}>Προσθήκη</Badge>
                    </div>
                    {(payrollSettings?.companyNonWorkingDays && payrollSettings.companyNonWorkingDays?.length > 0) && (
                        <table className={"simpleClassicTable"}>
                            <colgroup>
                                <col span={1} style={{width: "25%"}}></col>
                                <col span={1} style={{width: "25%"}}></col>
                                <col span={1} style={{width: "40%"}}></col>
                                <col span={1} style={{width: "10%"}}></col>
                            </colgroup>
                            <thead>
                            <tr>
                                <th>Τύπος</th>
                                <th>Ημερομηνία</th>
                                <th>Σημειώσεις</th>
                                <th>&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            {payrollSettings.companyNonWorkingDays.map((row, idx) => (
                                <tr key={`companyNonWorkingDays-${idx}`}>
                                    <td>{noWorkingDaysOptions.find((el) => el.value === row["type"])?.label}</td>
                                    <td>{row["date"]}</td>
                                    <td>{row["notes"]}</td>
                                    <td>
                                        <Popconfirm
                                            title={"Διαγραφή ημέρας μη εργασίας"}
                                            description={"Είσαστε σίγουροι ότι θέλετε να διαγράψετε την συγκεκριμένη μέρα μη εργασίας;"}
                                            onConfirm={() => handleDeleteNoWorkingDay(idx)}
                                            okText={"Ναι"}
                                            cancelText={"Όχι"}
                                        >
                                            <Badge bg={"danger"} style={{cursor: "pointer"}}>Διαγραφή</Badge>
                                        </Popconfirm>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                    <hr/>
                    <div className={"text-muted mb-2 mt-2"}><i>Αυτοματισμοί</i></div>
                    <Row>
                        <Col md={12}>
                            <BetterCheckBox
                                name={"autoSendEmployeeCard"}
                                text={"Να γίνεται αυτόματη αποστολή καρτών στην Εργάνη (Ο αυτοματισμός δεν θα λειτουργεί τις ημέρες που αναγράφονται παραπάνω ως ημέρες μη εργασίας)"}
                                checked={payrollSettings.autoSendEmployeeCard}
                                onChange={(e) => handleOnChange(e, "cb", "autoSendEmployeeCard")}
                            />
                        </Col>
                        {/*
                        <Col md={6}>
                            <BetterCheckBox
                                name={"autoSendOutOfTimeDays"}
                                text={"Να γίνεται αυτόματη αποστολή απολογιστικού στο τέλος του μήνα"}
                                checked={payrollSettings.autoSendOutOfTimeDays}
                                onChange={(e) => handleOnChange(e, "cb", "autoSendEmployeeCard")}
                            />
                        </Col>
                        */}
                    </Row>
                    <Button size={"sm"} className={"float-right"} disabled={loadingSettings}
                            onClick={() => handleSavePayrollSettings()}>
                        Αποθήκευση ρυθμίσεων {loadingSettings &&
                        <Spinner className={"ml-2"} animation={"border"} variant={"dark"}/>}
                    </Button>
                </Col>
            </Row>

            <Modal show={showExProanModal} backdrop={"static"} onHide={() => setShowExProanModal(false)} dialogClassName={"modal42PercentWidth"}>
                <Modal.Header closeButton>
                    <Modal.Title>Δημιουργία δήλωσης εξαίρεσης από την υποχρέωση προαναγγελίας</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <Dropdown
                                name={"installationMasterId"}
                                label={"Εγκατάσταση"}
                                options={installationOptions}
                                key={Math.random()}
                                defaultValue={installationOptions.find((el) => el.value === exProanData.installationMasterId)}
                                onChange={(e) => setExProanData({...exProanData, installationMasterId: e.value})}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Dropdown
                                name={"yesNoOption"}
                                label={"Εξαίρεση Προαναγγελίας"}
                                options={yesNoOptions}
                                key={Math.random()}
                                defaultValue={yesNoOptions.find((el) => el.value === exProanData.yesNoOption)}
                                onChange={(e) => setExProanData({...exProanData, yesNoOption: e.value})}
                            />
                        </Col>
                        <Col md={4}>
                            <Dropdown
                                name={"month"}
                                label={"Μήνας"}
                                options={greekMonthOptions}
                                key={Math.random()}
                                defaultValue={greekMonthOptions.find((el) => el.value === exProanData.month)}
                                onChange={(e) => setExProanData({...exProanData, month: e.value})}
                            />
                        </Col>
                        <Col md={4}>
                            <Dropdown
                                name={"year"}
                                label={"Έτος"}
                                options={yearOptions}
                                key={Math.random()}
                                defaultValue={yearOptions.find((el) => el.value === exProanData.year)}
                                onChange={(e) => setExProanData({...exProanData, year: e.value})}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowExProanModal(false)}>
                        Ακύρωση
                    </Button>
                    <Button variant="primary" onClick={() => handleSaveExProan()} disabled={loadingExProan}>
                        Δημιουργία {loadingExProan && <Spinner className={"ml-2"} animation="border" variant="dark"/>}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showRetrieveEmployeesModal} backdrop={"static"} dialogClassName={"modal42PercentWidth"}>
                <Modal.Header>
                    <Modal.Title>Άντληση εργαζομένων από την Εργάνη</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{overflowY: "auto", maxHeight: "65vh"}} className={"mr-1"}>
                        Η διαδικασία της άντλησης θα ξεκινήσει πατώντας το κουμπί "Άντληση εργαζομένων από την Εργάνη".
                        Ο χρόνος που θα πάρει η διαδικασία εξαρτάται από το πλήθος των εργαζομένων που έχετε. Αμέσως μετά την άντληση,
                        θα εμφανιστεί ένας πίνακας ο οποίος θα σας δείξει τα αποτελέσματα. Δεν θα τροποποιηθεί ούτε θα δημιουργηθεί κανένας εργαζόμενος.
                        Θα εμφανιστεί και κουμπί "Αποθήκευση" που πατώντας το, θα ενημερώσει κάθε εργαζόμενο στην περίπτωση που υπάρχει αλλιώς θα δημιουργηθεί.
                        <div style={{textAlign: "center"}} className={"mb-3 mt-2"}>
                            <Button size={"sm"} disabled={retrievingEmployees || savingRetrievedEmployees} onClick={() => handleFetchErganiEmployees()}>
                                Άντληση εργαζομένων από την Εργάνη
                                {retrievingEmployees && <Spinner className={"ml-2"} animation="border" variant="dark"/>}
                            </Button>
                        </div>
                        {retrievedEmployeesData?.length > 0 && (
                            <React.Fragment>
                                {retrievedEmployeesData.length === 1 ? (
                                    <div className={"mb-2"}>Βρέθηκε μόνο ένας εργαζόμενος</div>
                                ) : (
                                    <div className={"mb-2"}>Βρέθηκαν {retrievedEmployeesData.length} εργαζόμενοι</div>
                                )}
                                <Accordion>
                                    {retrievedEmployeesData.map((empData, idx) => (
                                        <Accordion.Item eventKey={String(idx)}>
                                            <Accordion.Header>{`${empData["Επώνυμο"]} ${empData["Όνομα"]} (ΑΦΜ: ${empData["ΑΦΜ"]})`}</Accordion.Header>
                                            <Accordion.Body>
                                                {(() => {
                                                    let compArray = [];
                                                    for (let key in empData) {
                                                        if (!isEmpty(empData[key])) {
                                                            compArray.push(<span><strong>{key}:</strong> {empData[key]}<br/></span>);
                                                        }
                                                    }
                                                    return compArray;
                                                })()}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                                <div style={{textAlign: "center"}} className={"mt-3"}>
                                    <Button size={"sm"} onClick={() => handleSaveRetrievedEmployees()} disabled={savingRetrievedEmployees || retrievingEmployees}>
                                        Αποθήκευση
                                        {savingRetrievedEmployees && <Spinner className={"ml-2"} animation="border" variant="dark"/>}
                                    </Button>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </Modal.Body>
                {(!retrievingEmployees && !savingRetrievedEmployees) && (
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setShowRetrieveEmployeesModal(false)}>
                            Κλείσιμο
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>

            <Modal show={showRetrieveProgramModal} backdrop={"static"} dialogClassName={"modal65PercentWidth"}>
                <Modal.Header>
                    <Modal.Title>Άντληση προγράμματος από την Εργάνη</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4}>
                            <DateBox
                                name={"date"}
                                label={"Ημερομηνία"}
                                required={true}
                                disabled={retrievingProgram}
                                selected={moment(retrievedProgramData.date).isValid() ? moment(retrievedProgramData.date).toDate() : null}
                                onChange={(e) => {
                                    setRetrievedProgramData({
                                        ...retrievedProgramData,
                                        date: e,
                                        date_from: moment(e).startOf("week").format("DD/MM/YYYY"),
                                        date_to: moment(e).endOf("week").format("DD/MM/YYYY")
                                    })
                                }}
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                name={"date_from"}
                                label={"Ημ/νία από"}
                                value={retrievedProgramData.date_from}
                                disabled={true}
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                name={"date_to"}
                                label={"Ημ/νία έως"}
                                value={retrievedProgramData.date_to}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                    <Row className={"mt-3"}>
                        <Col md={12} style={{textAlign: "center"}}>
                            <Button size={"sm"} onClick={() => handleFetchErganiProgram()} disabled={retrievingProgram || savingRetrievedProgram}>
                                Άντληση προγράμματος από την Εργάνη
                                {retrievingProgram && <Spinner className={"ml-2"} animation="border" variant="dark"/>}
                            </Button>
                        </Col>
                    </Row>
                    {retrievedProgramData.data.employees.length > 0 && (
                        <React.Fragment>
                            <Row className={"mt-3"}>
                                <Col md={12}>
                                    <React.Fragment>
                                        <Accordion defaultActiveKey={['0']} alwaysOpen>
                                            {retrievedProgramData.data.employees.map((emp, idx) => (
                                                <Accordion.Item eventKey={String(idx)}>
                                                    <Accordion.Header>{emp["employeeIdentifier"].split("|").join(" ")}{emp?.persistent === "true" ? " (Σταθερό Εβδομαδιαίο)" : ""}</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div>
                                                            <BetterCheckBox
                                                                name={"persistentCheckbox"}
                                                                text={"Ορισμός ως σταθερό εβδομαδιαίο πρόγραμμα"}
                                                                checked={emp?.persistent === "true"}
                                                                onChange={(e) => {
                                                                    let cloneProgram = structuredClone(retrievedProgramData.data);
                                                                    const findEmployee = cloneProgram.employees.find((el) => el.employeeId === emp.employeeId);
                                                                    if (findEmployee) {
                                                                        if (e.target.checked) {
                                                                            findEmployee.persistent = "true";
                                                                        } else {
                                                                            delete findEmployee.persistent;
                                                                        }
                                                                        setRetrievedProgramData({...retrievedProgramData, data: cloneProgram});
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        {renderFetchedEmployeeProgram(emp.dailyCalendar, idx)}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))}
                                        </Accordion>
                                    </React.Fragment>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className={"mt-2"}>
                                    <Alert variant={"secondary"} style={{textAlign: "center"}}>
                                        Παρακαλώ επιλέξτε με προσοχή ποιοι εργαζόμενοι έχουν σταθερό εβδομαδιαίο πρόγραμμα, από την καρτέλα του κάθε ενός παραπάνω.
                                        Αν έχουν όλοι οι εργαζόμενοι σας σταθερό εβδομαδιαίο πρόγραμμα, πατήστε το παρακάτω κουμπί<br/>
                                        <Button size={"sm"} className={"mt-1"} onClick={() => {
                                            let cloneProgram = structuredClone(retrievedProgramData.data);
                                            for (let emp of cloneProgram.employees) {
                                                emp.persistent = "true";
                                            }
                                            setRetrievedProgramData({...retrievedProgramData, data: cloneProgram});
                                        }}>Όλοι οι εργαζόμενοι έχουν σταθερό εβδομαδιαίο πρόγραμμα</Button>
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Alert variant={"danger"} style={{textAlign: "center"}}>
                                        <strong>ΠΡΟΣΟΧΗ:</strong> Πατώντας αποθήκευση εβδομάδας, θα διαγραφεί η εβδομάδα που είναι ήδη αποθηκευμένη στο πρόγραμμα εργασίας.
                                        <div>
                                            <Button size={"sm"} className={"mt-2"} disabled={retrievingProgram || savingRetrievedProgram} onClick={() => handleSaveRetrievedProgram()}>
                                                Αποθήκευση εβδομαδιαίου προγράμματος εργασίας στο ERP
                                                {savingRetrievedProgram && (
                                                    <Spinner className={"ml-2"} animation="border" variant="dark"/>
                                                )}
                                            </Button>
                                        </div>
                                    </Alert>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                </Modal.Body>
                {(!retrievingProgram && !savingRetrievedProgram) && (
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setShowRetrieveProgramModal(false)}>
                            Κλείσιμο
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>

            <Modal show={newNoWorkingDayModal} onHide={() => setNewNoWorkingDayModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Προσθήκη ημέρας μη εργασίας</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Dropdown
                        name={"type"}
                        label={"Τύπος ημέρας"}
                        key={Math.random()}
                        required={true}
                        options={noWorkingDaysOptions}
                        defaultValue={noWorkingDaysOptions.find((el) => el.value === newNoWorkingDay.type)}
                        onChange={(e) => {
                            if (e.value !== newNoWorkingDay.type) {
                                setNewNoWorkingDay({...newNoWorkingDay, type: e.value, date: ""});
                            }
                        }}
                    />
                    {(newNoWorkingDay.type === "normal" || newNoWorkingDay.type === "publicHoliday") && (
                        <React.Fragment>
                            <DateBox
                                name={"date"}
                                classes={"mb-1"}
                                label={"Ημερομηνία"}
                                minDate={moment().toDate()}
                                selected={moment(newNoWorkingDay.date, "DD/MM/YYYY").isValid() ? moment(newNoWorkingDay.date, "DD/MM/YYYY").toDate() : null}
                                onChange={(e) => setNewNoWorkingDay({...newNoWorkingDay, date: moment(e).format("DD/MM/YYYY")})}
                            />
                        </React.Fragment>
                    )}
                    {newNoWorkingDay.type === "recurring" && (
                        <React.Fragment>
                            <DateBox
                                dateFormat={"dd/MM"}
                                name={"date"}
                                classes={"mb-1"}
                                label={"Ημερομηνία"}
                                required={true}
                                selected={moment(newNoWorkingDay.date, "DD/MM").isValid() ? moment(newNoWorkingDay.date, "DD/MM").toDate() : null}
                                onChange={(e) => setNewNoWorkingDay({...newNoWorkingDay, date: moment(e).format("DD/MM")})}
                            />
                        </React.Fragment>
                    )}
                    <Input
                        name={"notes"}
                        label={"Σημειώσεις"}
                        value={newNoWorkingDay.notes}
                        maxLength={100}
                        autoComplete={"off"}
                        onChange={(e) => setNewNoWorkingDay({...newNoWorkingDay, notes: e.target.value})}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setNewNoWorkingDayModal(false)}>
                        Κλείσιμο
                    </Button>
                    <Button variant="primary" onClick={() => handleSaveNewNoWorkingDay()}>
                        Προσθήκη
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showRetrievePermitsModal} backdrop={"static"} dialogClassName={"modal65PercentWidth"}>
                <Modal.Header>
                    <Modal.Title>Άντληση αδειών από την Εργάνη</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{textAlign: "center"}}>
                        <Button size={"sm"} onClick={() => handleRetrievePermits()} disabled={retrievingPermits || savingRetrievedPermits}>
                            Άντληση αδειών έτους {retrievingPermits && <Spinner className={"ml-2"} animation="border" variant="dark"/>}
                        </Button>
                    </div>
                    {Object.keys(retrievedPermitsData).length > 0 && (
                        <React.Fragment>
                            {retrievedPermitsData.employees?.length > 0 ? (
                                <React.Fragment>
                                    <div style={{textAlign: "center"}} className={"mt-3"}>
                                        <div className={"text-muted mb-2"}><i>Πίνακας αδειών</i></div>
                                        <div style={{maxHeight: "50vh", overflowY: "auto"}}>
                                            <table className={"simpleClassicTable"}>
                                                <colgroup>
                                                    <col span={1} style={{width: "30%"}}></col>
                                                    <col span={1} style={{width: "50%"}}></col>
                                                    <col span={1} style={{width: "20%"}}></col>
                                                </colgroup>
                                                <thead style={{textAlign: "center", backgroundColor: "var(--light-skyblue)", position: "sticky", top: "-1px"}}>
                                                <tr>
                                                    <th>Εργαζόμενος</th>
                                                    <th>Τύπος Άδειας</th>
                                                    <th>Ημερομηνία</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {retrievedPermitsData.employees.map((row, idx) => (
                                                    <tr key={`permitTable-${idx}`}>
                                                        <td>{formatEmployeeIdentifier(row["employeeIdentifier"])}</td>
                                                        <td>{permitTypes.find((el) => el.value === row["permitType"])?.label}</td>
                                                        <td>{row["dateFrom"]}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div style={{textAlign: "center"}}>
                                        <Button size={"sm"} className={"mt-2"} onClick={() => handleSaveWorkPermits()}
                                                disabled={retrievingPermits || savingRetrievedPermits}>
                                            Αποθήκευση {savingRetrievedPermits &&
                                            <Spinner className={"ml-2"} animation="border" variant="dark"/>}
                                        </Button>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div style={{textAlign: "center"}} className={"mt-3"}>
                                    Δεν βρέθηκαν ετήσιες άδειες.
                                </div>
                            )}
                        </React.Fragment>
                    )}
                </Modal.Body>
                {!(retrievingPermits || savingRetrievedPermits) && (
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setShowRetrievePermitsModal(false)}>
                            Κλείσιμο
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>

            <Modal show={showMassProgramsModal} backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title>Άντληση προηγούμενων προγραμμάτων</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Η διαδικασία της άντλησης των προηγούμενων προγραμμάτων θα φέρει όλα τα προγράμματα από το Π.Σ. Εργάνη στο ERP, που αφορούν το έτος που έχετε συνδεθεί ({company.year}), από την προηγούμενη εβδομάδα αναδρομικά.
                    Τα ήδη υπάρχων προγράμματα δεν θα τροποποιηθούν.
                </Modal.Body>
                <Modal.Footer>
                    {!loadingMassPrograms && (
                        <Button variant="outline-primary" onClick={() => setShowMassProgramsModal(false)}>
                            Κλείσιμο
                        </Button>
                    )}
                    <Button variant="primary" onClick={() => massFetchPrograms()} disabled={loadingMassPrograms}>
                        Εκτέλεση διαδικασίας {loadingMassPrograms && <Spinner className={"ml-2"} animation="border" variant="dark"/>}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export const PayrollSettingsLogs = () => {
    return (
        <React.Fragment>

        </React.Fragment>
    )
}