import axios from "axios";

export const fetchPatientDetails = async (amka, companyId) => {
    try {
        const serverResponse = await axios.post(`${process.env.REACT_APP_API_URL2}/prescription/fetch-patient-details`, {
            amka: amka,
            company: companyId
        }, {
            headers: { "Content-Type": "application/json" }
        });
        if (serverResponse.data.status === "200") {
            return {success: true, data: serverResponse.data.data}
        } else {
            return {success: false, data: serverResponse.data.message}
        }
    } catch (err) {
        console.log(err);
        return {success: false, data: "Σφάλμα κατά την αποστολή αιτήματος."}
    }
}