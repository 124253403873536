import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import TextArea from "../../../common/TextArea";
import Input from "../../../common/Input";
import DateBox from "../../../primer/DateBox";
import { useSelector, useDispatch } from "react-redux";
import {
  setSaveAutomaticSales
} from "../../../../_reducers/activitySlice";
import { getOptionsByData, getDefaultOptionByValue } from "../../../../_helpers/commonFunctions";
import { getLabelByValue, getObjectFromArrayOfObjects } from "../../../../_helpers/helperFunctions";
import Checkbox from "../../../common/CheckBox";
import './salesnew.css';
import Dropdown from "../../../primer/Dropdown";
import { setNewAutomaticSales } from "../../../../_reducers/DataPanel/Sales/automaticSalesSlice";
import * as moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";
import {useTranslation} from "react-i18next";

const getDaysArrayByMonth = () => {
  let daysInMonth = moment().daysInMonth();
  const lastDay = daysInMonth;
  let days = [];

  while(daysInMonth) {

    let option  = {
      value: daysInMonth,
      label: `${daysInMonth}th`,
      color: "#76AEB7",
    }

    // 1st
    if ([1, 21, 31].includes(daysInMonth)) {
      option.label = `${daysInMonth}st`;
    }

    // 2nd
    if ([2, 22].includes(daysInMonth)) {
      option.label = `${daysInMonth}nd`;
    }

    // 3rd
    if ([3, 23].includes(daysInMonth)) {
      option.label = `${daysInMonth}rd`;
    }

    if (lastDay === daysInMonth) {
      option.label = `Last Day`;
    }

    days.push(option);
    daysInMonth--;
  }

  return days.reverse();
}

const AutomaticSalesNew = () => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const ACTIVITY = useSelector( (state) => state.ACTIVITY );
  const AUTOMATIC_SALES = useSelector( (state) => state.AUTOMATIC_SALES );
  const company = useSelector(state => state.COMPANY_DATA.company)
  const getMonthDaysOptions =  getDaysArrayByMonth();

  const [salesTemplateList, setSalesTemplateList] = useState([]);
  const [salesTemplateOptions, setSalesTemplateOptions] = useState([]);

  const paramsAutomaticSales = {
    type:'sales',
    isActive:'active',
    useForAutomaticSales:'active',
    company:company.id,
    year:company.year.toString(),
  }

  const apiURL2 = process.env.REACT_APP_API_URL2;

  const handleOnChange = (e, type="default", ddname = '') => {

    // Set if editing the form
    if (!ACTIVITY.saveAutomaticSales) {
      dispatch(setSaveAutomaticSales(true));
    }

    let name;
    let value;

    if (type === 'dd' || type === 'ac' ) {
      value = e.value;
      name = ddname;
    } else if (type === 'dp' ) {
      value = e;
      name = ddname;
    } else {
      value = e.target.value;
      name = e.target.name;
    }

    if (name === 'day') {
      if (parseInt(value) > 365) {
        value = 365;
        toast.error('Max limit 365 days');
      }
    }

    let automaticSalesData = Object.assign({}, AUTOMATIC_SALES);
    let newAutomaticSalesData = {};
    newAutomaticSalesData[name] = value;
    if (name === 'dayofmonth') {
      newAutomaticSalesData.dayofmonthName = getLabelByValue(getMonthDaysOptions, value, 'value');
      newAutomaticSalesData['issueInvoiceType'] = 'dayofmonth';
    }
    if (name === 'salesTemplate') {
      newAutomaticSalesData.salesTemplateName = getLabelByValue(salesTemplateOptions, value, 'value');
      newAutomaticSalesData['invoiceDataType'] = 'existing';
      newAutomaticSalesData.salesTemplateData = []
      let selectedTemplate = getObjectFromArrayOfObjects(salesTemplateList, value, '_id');
      if (selectedTemplate && selectedTemplate.length > 0) {
        if (typeof(selectedTemplate['0'].templateData) === "string") {
          let templateDataJson = JSON.parse(selectedTemplate['0'].templateData);
          newAutomaticSalesData.salesTemplateData = templateDataJson;
          newAutomaticSalesData.documentType = templateDataJson.documentType;
          newAutomaticSalesData.documentTypeName = templateDataJson.documentTypeName;
          newAutomaticSalesData.client = templateDataJson.client;
          newAutomaticSalesData.clientName = templateDataJson.clientName;
          newAutomaticSalesData.totalAmount = templateDataJson.totalAmount;
        }
      }
    }
    // If automatic sales is inactive then add the end date.
    if (name === 'isActive') {
      if (value === 'active') {
        newAutomaticSalesData.endDate = new Date();
      } else {
        newAutomaticSalesData.endDate = '';
      }
    }
    dispatch(setNewAutomaticSales({...automaticSalesData.newAutomaticSales, ...newAutomaticSalesData}));
  }

  useEffect(() => {

    // Get list of sales templates for automatic sales
    axios.post(apiURL2 + `/category/list-automatic-sales-templates/`, JSON.stringify(paramsAutomaticSales), { headers: { 'Content-Type': 'application/json'}})
    .then(res => {
      if (res.data &&  res.data.status === '200') {
        setSalesTemplateList(res.data.data); // Full Data
        if (res.data.data && res.data.data.length > 0) {
          let salesOptions = getOptionsByData(res.data.data, '_id', 'name');
          setSalesTemplateOptions(salesOptions); // Options
        }
      } else {
        toast.error(res.data.errors);
      }
    })
 }, [dispatch])

  return (
    <React.Fragment>
      <form className="client-form product-form position-relative" autoComplete = "off">
      <Row className="position-relative border-bottom mb-3">
          <Col xs="12" md="12" className="d-flex align-items-center justify-content-end " >
            <div className="inline-field d-flex align-items-center acremd  mb-3">
              <label htmlFor="product-active" className="mb-0 mr-2 " >{t('SalesAutomaticSalesNew.saleActive')}</label>
              <Checkbox
                className="mb-2"
                name="isActive"
                key = {Math.random()}
                defaultValue={AUTOMATIC_SALES.newAutomaticSales.isActive}
                value={ AUTOMATIC_SALES.newAutomaticSales.isActive === 'active' ? "inactive" : "active" }
                onChange = {(e) => handleOnChange(e) }
              />
            </div>
          </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} >
          <div className="mb-3">
            <Input
              className="w-100"
              label={t('SalesAutomaticSalesNew.name.name')}
              name="name"
              placeholder={t('SalesAutomaticSalesNew.name.placeholder')}
              value = { AUTOMATIC_SALES.newAutomaticSales.name }
              required={ true }
              onChange = {(e) => handleOnChange(e)}
            />
            {
              ACTIVITY.saveAutomaticSales &&
              AUTOMATIC_SALES.newAutomaticSales.name === ''  &&
              <div className="text-danger">{t('SalesAutomaticSalesNew.name.required')}</div>
            }
          </div>
        </Col>
        <Col sm={12} md={6} className="text-right ">
          <Button className="mr-2" size="small">Log</Button>
          <Button className="disabled" size="small">{t('SalesAutomaticSalesNew.files')}</Button>
        </Col>
      </Row>
      <Row>
        <Col sm="12" md="3">
          <div className="mb-3">
            <DateBox
              classes="w-100 mb-0"
              label={t('SalesAutomaticSalesNew.startDate.startDate')}
              name="startDate"
              required = {true}
              selected={AUTOMATIC_SALES.newAutomaticSales.startDate}
              onChange = {(e) => handleOnChange(e ,'dp', 'startDate')}
            />
            {
              ACTIVITY.saveAutomaticSales &&
              (AUTOMATIC_SALES.newAutomaticSales.startDate === '' || AUTOMATIC_SALES.newAutomaticSales.startDate === null) &&
              <div className="text-danger">{t('SalesAutomaticSalesNew.startDate.required')}</div>
            }
          </div>
        </Col>
        <Col sm="12" md="3">
          <DateBox
            classes="w-100 mb-3"
            label={t('SalesAutomaticSalesNew.saleActive')}
            name="endDate"
            selected={AUTOMATIC_SALES.newAutomaticSales.endDate}
            onChange = {(e) => handleOnChange(e ,'dp', 'endDate')}
          />
        </Col>
        <Col sm="12" md="6">
          <div className="invoiceissues">
            <p className="label">{t('SalesAutomaticSalesNew.issueInvoiceEvery')}</p>
            <div className="daysoptions">
              <div className="option1">
                <input
                  className="form-check-input position-static"
                  type="radio"
                  value="days"
                  name="issueInvoiceType"
                  checked = {AUTOMATIC_SALES.newAutomaticSales.issueInvoiceType === 'days'}
                  onChange = {(e) => handleOnChange(e) }
                />
                <input
                  className="daysbox form-control"
                  name="day"
                  placeholder={t('SalesAutomaticSalesNew.days.days')}
                  max= {365}
                  min= {1}
                  disabled = {AUTOMATIC_SALES.newAutomaticSales.issueInvoiceType === 'dayofmonth'}
                  type="number"
                  value = { AUTOMATIC_SALES.newAutomaticSales.day }
                  onChange = {(e) => handleOnChange(e)}
                />
                <span>
                  {t('SalesAutomaticSalesNew.days.days')}
                </span>
              </div>
              {
                ACTIVITY.saveAutomaticSales &&
                AUTOMATIC_SALES.newAutomaticSales.issueInvoiceType === 'days'  &&
                AUTOMATIC_SALES.newAutomaticSales.day === '' &&
                <div className="text-danger">{t('SalesAutomaticSalesNew.days.required')}</div>
              }
              <p>{t('SalesAutomaticSalesNew.or')}</p>
              <div className="option2">
                <input
                  className="form-check-input position-static"
                  type="radio"
                  value="dayofmonth"
                  checked = {AUTOMATIC_SALES.newAutomaticSales.issueInvoiceType === 'dayofmonth'}
                  name="issueInvoiceType"
                  onChange = {(e) => handleOnChange(e) }
                />
                <Dropdown
                  name="dayofmonth"
                  value = {getDefaultOptionByValue(getMonthDaysOptions, AUTOMATIC_SALES.newAutomaticSales.dayofmonth, 'value', 'label', 'value' )}
                  classes="mr-2"
                  enabledValue = {true}
                  disabled = {AUTOMATIC_SALES.newAutomaticSales.issueInvoiceType === 'days'}
                  options={getMonthDaysOptions}
                  onChange = {(e) => handleOnChange(e, 'dd', 'dayofmonth')}
                />
                <span>
                  {t('SalesAutomaticSalesNew.ofEachMonth')}
                </span>
              </div>
              {
                ACTIVITY.saveAutomaticSales &&
                AUTOMATIC_SALES.newAutomaticSales.issueInvoiceType === 'dayofmonth'  &&
                AUTOMATIC_SALES.newAutomaticSales.dayofmonth === ''  &&
                <div className="text-danger">{t('SalesAutomaticSalesNew.require')}</div>
              }
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <div className="col-12 col-md-6">
          <div className="text-muted mb-3"><i>{t('SalesAutomaticSalesNew.invoiceData')}</i></div>
          <div className="invoice-data-section">
            <div className="row d-flex align-items-center create-new">
              <input
                className="form-check-input position-static"
                type="radio"
                value="new"
                name="invoiceDataType"
                checked = {AUTOMATIC_SALES.newAutomaticSales.invoiceDataType === 'new'}
                onChange = {(e) => handleOnChange(e) }
              />
              <Button
               className="mb-3 ml-3"
               size="small"
               disabled = {AUTOMATIC_SALES.newAutomaticSales.invoiceDataType === 'existing'}
              >{t('SalesAutomaticSalesNew.createNewSalesDocument')}</Button>
            </div>
            <p>{t('SalesAutomaticSalesNew.or')}</p>
            <div className="row d-flex align-items-center select-template">
              <input
                className="form-check-input position-static"
                type="radio"
                value="existing"
                name="invoiceDataType"
                checked = {AUTOMATIC_SALES.newAutomaticSales.invoiceDataType === 'existing'}
                onChange = {(e) => handleOnChange(e) }
              />

              <Dropdown
                label={t('SalesAutomaticSalesNew.selectSalesTemplate')}
                classes="selecttemplatesales mb-3"
                key = { Math.random() }
                value = {getDefaultOptionByValue(salesTemplateOptions, AUTOMATIC_SALES.newAutomaticSales.salesTemplate, 'value', 'label', 'value' )}
                enabledValue = {true}
                name="salesTemplate"
                options={salesTemplateOptions}
                onChange = {(e) => handleOnChange(e, 'dd', 'salesTemplate')}
              />
            </div>
          </div>
        </div>
      </Row>
      <hr className="mb-4" />
      <Row>
        <div className="w-100">
          <div className="text-muted mb-2"><i>{t('SalesAutomaticSalesNew.notes')}</i></div>
          <TextArea
            rows="4"
            placeholder={t('SalesAutomaticSalesNew.addNotes')}
            name="notes"
            value = { AUTOMATIC_SALES.newAutomaticSales.notes }
            onChange = {(e) => handleOnChange(e)}
          />
        </div>
      </Row>
      </form>
    </React.Fragment>
  );
};

export default AutomaticSalesNew;
