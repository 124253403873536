import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {checkPermissionStartingTree} from "../../../../_helpers/commonFunctions";
import SidebarLabel from "../SidebarLabel";
import SideBarLink from "../SidebarLinks";
import {
    productsPathConfig,
    salesPathConfig,
    buysPathConfig,
    clientsPathConfig,
    cashRegistersConfig,
    settingsPathConfig, accountingPathConfig, suppliersPathConfig, payrollPathConfig,
} from "./config";
import "./index.css";
import {useTranslation} from "react-i18next";
import {activePath} from "../../../../_reducers/Navbar";
import {useMediaQuery} from "@mui/material";

const Sidebar = () => {
    const dispatch = useDispatch();
    const permissions = localStorage.getItem("permissions");
    const permissionsData = JSON.parse(permissions);
    const [t] = useTranslation('common');
    const MOBILE_SIDEBAR_TOGGLE = useSelector((state) => state.MOBILE_SIDEBAR_TOGGLE);
    const ACTIVE_PATH = useSelector((state) => state.ACTIVE_PATH);

    const isMobileScreen = useMediaQuery("(max-width: 980px)");
    const [escapeNav, setEscapeNav] = useState(false);

    let activeListMapping = {
        "Products" : productsPathConfig,
        "Sales" : salesPathConfig,
        "Buys" : buysPathConfig,
        "Clients" : clientsPathConfig,
        "Suppliers" : suppliersPathConfig,
        "Cash Registers - POS - Bank Accounts" : cashRegistersConfig,
        "Settings" : settingsPathConfig,
        "Accounting" : accountingPathConfig,
        "Payroll": payrollPathConfig,
    }

    const renderSidebarFunc = (activeLink) => {
        const checkLinkFunc = () => {
            switch (activeLink) {
                case "Products":
                    return productsPathConfig;
                case "Sales":
                    return salesPathConfig;
                case "Buys":
                    return buysPathConfig;
                case "Clients":
                    return clientsPathConfig;
                case "Suppliers":
                    return suppliersPathConfig;
                case "Cash Registers - POS - Bank Accounts":
                    return cashRegistersConfig;
                case "Settings":
                    return settingsPathConfig;
                case "Accounting":
                    return accountingPathConfig;
                case "Payroll":
                    return payrollPathConfig;
                default:
                    return productsPathConfig;
            }
        }
        return (
            <SidebarLabel labelName={activeLink} labelDesc={activeLink ? t(activeLink.toString()) : activeLink}>
                {checkLinkFunc().map((pathConfig, i) => {
                    if (checkPermissionStartingTree(pathConfig.permission, permissionsData)) {
                        return (
                            <SideBarLink
                                key={i}
                                id={pathConfig.id}
                                title={t(pathConfig.title)}
                                linksInfo={pathConfig.linkInfo}
                            />
                        )
                    }
                    return false;
                })}
                {isMobileScreen && (
                    <li style={{cursor: "pointer"}} className="nav-item border-bottom-1-gray" onClick={() => setEscapeNav(false)}>
                        <div className="nav-link">
                            <span className="menu-title">Πίσω</span>
                            <i className="menu-arrow"></i>
                        </div>
                    </li>
                )}
            </SidebarLabel>
        )
    }

    const handleClickMobileSidebar = (activeLink) => {
        setEscapeNav(true);
        dispatch(activePath(activeLink));
    }

    return (
        <React.Fragment>
            {isMobileScreen ? (
                <nav className={`sidebar sidebar-offcanvas ${MOBILE_SIDEBAR_TOGGLE && "active"}`} id="sidebar" style={{zIndex: "1000", marginTop: "20px"}}>
                    {!escapeNav && (
                        <div className="tab-content active">
                            <ul className="nav" id={`menu-Menu`}>
                                <li className="nav-label">Μενού</li>
                                {Object.keys(activeListMapping).map((key, idx) => {
                                    if (checkPermissionStartingTree(activeListMapping[key].permission, permissionsData)) {
                                        return (
                                            <li onClick={() => handleClickMobileSidebar(key)} style={{cursor: "pointer"}} className="nav-item border-bottom-1-gray" key={`mobileSideBar${key}${idx}`}>
                                                <div className="nav-link" data-toggle="collapse">
                                                    <span className="menu-title">{key === "Cash Registers - POS - Bank Accounts" ? "Ταμειακά" : t(key)}</span>
                                                    <i className="menu-arrow"></i>
                                                </div>
                                            </li>
                                        )
                                    }
                                })}
                            </ul>
                        </div>
                    )}
                    {escapeNav && (
                        <div className="tab-content active">
                            <ul className="nav" id={`menu-Menu`}>
                                <li className="nav-label">{t(ACTIVE_PATH.toString())}</li>
                                {Object.keys(activeListMapping[ACTIVE_PATH]).map((key, idx) => {
                                    if (checkPermissionStartingTree(activeListMapping[ACTIVE_PATH][key].permission, permissionsData)) {
                                        return (
                                            <SideBarLink
                                                key={idx}
                                                id={activeListMapping[ACTIVE_PATH][key].id}
                                                title={t(activeListMapping[ACTIVE_PATH][key].title)}
                                                linksInfo={activeListMapping[ACTIVE_PATH][key].linkInfo}
                                            />
                                        )
                                    }
                                })}
                                <li style={{cursor: "pointer"}} className="nav-item border-bottom-1-gray" onClick={() => setEscapeNav(false)}>
                                    <div className="nav-link">
                                        <span className="menu-title">Πίσω</span>
                                        <i className="menu-arrow"></i>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    )}
                </nav>
            ) : (
                <nav className={`sidebar sidebar-offcanvas ${MOBILE_SIDEBAR_TOGGLE && "active"}`} id="sidebar">
                    <div className="tab-content">
                        {renderSidebarFunc(ACTIVE_PATH)}
                    </div>
                </nav>
            )}
        </React.Fragment>
    )
}

export default Sidebar;
