import React, {useRef, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {getCurrentUser, isEmpty} from "../../../_helpers/commonFunctions";
import Input from "../../common/Input";
import {toast} from "react-toastify";
import axios from "axios";
import Dropdown from "../../primer/Dropdown";
import {postUserLanguage} from "../../../_apis/api";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {classicStyleBelowNavbar} from "./Statics";

export const UserSettings = () => {
    const dispatch = useDispatch();
    const [t, i18n] = useTranslation("common");
    const currentUser = getCurrentUser();
    const avatarInputRef = useRef();

    const [newAvatar, setNewAvatar] = useState(""); // base 64
    const [loading, setLoading] = useState(false);

    const initPData = {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    }
    const [passwordData, setPasswordData] = useState({...initPData});

    const initUserData = {
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
    }
    const [userData, setUserData] = useState({...initUserData});

    const userIsEmployee = currentUser?.userType === "Employee";

    const languageOptions = [{
        label: <React.Fragment><img
            alt="/"
            src={"assets/images/united-states.png"}
            width="25px"
        /> Αγγλικά</React.Fragment>,
        value: "en"
    }, {
        label: <React.Fragment><img
            alt="/"
            src={"assets/images/Greek.png"}
            width="25px"
        /> Ελληνικά</React.Fragment>,
        value: "el",
    }]

    const handleOnChange = (e, type, name) => {
        if (type === "file" && name === "avatar") {
            const file = e.target.files[0];
            const fileType = file.type;
            const validImageTypes = ['image/jpeg', 'image/png'];
            if (!validImageTypes.includes(fileType)) {
                toast.error("Παρακαλώ ανεβάστε αρχείο jpg ή png.");
            } else {
                const reader = new FileReader();
                reader.onload = (resp) => {
                    const myImage = new Image()
                    myImage.onload = () => {
                        if (myImage.width === 150 && myImage.height === 150) {
                            setNewAvatar(String(reader.result));
                        } else {
                            toast.error("Η φωτογραφία δεν είναι 150x150px");
                        }
                    }
                    myImage.src = resp.target.result;
                }
                reader.readAsDataURL(file);
            }
            e.target.value = "";
        } else if (type === "input-password") {
            setPasswordData({...passwordData, [name] : e.target.value});
        } else if (type === "input-userDetails") {
            setUserData({...userData, [name] : e.target.value});
        }
    }

    const handleChangePasswordClicked = () => {
        if (isEmpty(passwordData.currentPassword) || isEmpty(passwordData.newPassword) || isEmpty(passwordData.confirmNewPassword)) {
            toast.error("Παρακαλώ συμπληρώστε όλα τα πεδία.");
        } else if (passwordData.newPassword !== passwordData.confirmNewPassword) {
            toast.error("Ο νέος κωδικός δεν είναι ίδιος με την επιβεβαίωση του.");
        } else {
            setLoading(true);
            axios.post(`${process.env.REACT_APP_API_URL2}/user/change-password`, {
                userId: currentUser?.userType === "Employee" ? currentUser.email : currentUser._id,
                data: btoa(JSON.stringify(passwordData))
            }, {
                headers: {"Content-Type": "application/json"},
            }).then((res) => {
                setLoading(false);
                if (res.data.status === "200") {
                    toast.success("Ο κωδικός άλλαξε επιτυχώς.");
                    setPasswordData({...initPData});
                } else {
                    toast.error(res.data.message);
                }
            }).catch((err) => {
                setLoading(false);
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        }
    }

    const handleChangeUserDetailsClicked = () => {
        if (isEmpty(userData.firstName) || isEmpty(userData.lastName) || isEmpty(userData.email)) {
            toast.error("Παρακαλώ συμπληρώστε όλα τα πεδία.");
        } else {
            setLoading(true);
            axios.post(`${process.env.REACT_APP_API_URL2}/user/change-user-details`, {
                userId: currentUser._id,
                data: userData,
            }, {
                headers: {"Content-Type": "application/json"},
            }).then((res) => {
                setLoading(false);
                if (res.data.status === "200") {
                    toast.success("Τα στοιχεία αλλάξανε επιτυχώς.");
                    localStorage.setItem("user", JSON.stringify(res.data.data));
                    setTimeout(() => {
                        window.location.reload();
                    }, 100)
                } else {
                    toast.error(res.data.message);
                }
            }).catch((err) => {
                setLoading(false);
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        }
    }

    const handleChangeAvatar = () => {
        if (isEmpty(newAvatar)) {
            toast.error("Η εικόνα είναι άδεια.");
        } else {
            setLoading(true);
            axios.post(`${process.env.REACT_APP_API_URL2}/user/change-user-details`, {
                userId: currentUser._id,
                data: {
                    avatar: newAvatar
                },
            }, {
                headers: {"Content-Type": "application/json"},
            }).then((res) => {
                setLoading(false);
                if (res.data.status === "200") {
                    toast.success("Τα στοιχεία αλλάξανε επιτυχώς.");
                    localStorage.setItem("user", JSON.stringify(res.data.data));
                    setTimeout(() => {
                        window.location.reload();
                    }, 100)
                } else {
                    toast.error(res.data.message);
                }
            }).catch((err) => {
                setLoading(false);
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        }
    }

    const handleLanguageChange = (e) => {
        i18n.changeLanguage(e.value);
        let data = {
            userId: currentUser._id, language: i18n.language
        }
        dispatch(postUserLanguage(data));
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row>
                {!userIsEmployee ? (
                    <Col md={4} className={"mt-2"}>
                        <div style={{borderRadius: "12px", border: "1px solid #6ea2a9", padding: "12px", height: "100%"}}>
                            <h5 style={{fontWeight: "600"}}>Αλλαγή εικόνας προφίλ</h5>
                            <img src={newAvatar ? newAvatar :  currentUser?.avatar ? currentUser?.avatar : "assets/images/faces/face16.jpg"} alt="/"
                                 style={{width: "150px", height: "150px", borderRadius: "50%", marginRight: "auto", marginLeft: "auto", display: "block"}} />
                            <div style={{textAlign: "center"}} className={"mt-4"}>
                                <input
                                    type={"file"}
                                    id={"avatarFileInput"}
                                    style={{display: "none"}}
                                    ref={avatarInputRef}
                                    accept={".jpg,.jpeg,.png"}
                                    onChange={(e) => handleOnChange(e, "file", "avatar")}
                                />
                                {isEmpty(newAvatar) ? (
                                    <Button size={"sm"} onClick={() => avatarInputRef.current?.click()} disabled={loading}>Ανέβασμα</Button>
                                ) : (
                                    <React.Fragment>
                                        <Button size={"sm"} onClick={() => handleChangeAvatar()} disabled={loading}>Αποθήκευση</Button>
                                        <Button size={"sm"} className={"ml-1"} variant={"outline-danger"} onClick={() => setNewAvatar("")} disabled={loading}>Ακύρωση</Button>
                                    </React.Fragment>
                                )}
                                <div className={"text-muted"}>Επιτρέπονται φωτογραφίες 150px * 150px (JPG, PNG)</div>
                                <br/>
                                <div className={"text-muted"}>(*) Η σελίδα θα ανανεωθεί αμέσως μετά την επιτυχή αποθήκευση</div>
                            </div>
                        </div>
                    </Col>
                ) : (
                    <Col md={4}></Col>
                )}
                <Col md={4} className={"mt-2"}>
                    <div style={{borderRadius: "12px", border: "1px solid #6ea2a9", padding: "12px", height: "100%"}}>
                        <h5 style={{fontWeight: "600"}}>Αλλαγή κωδικού</h5>
                        <Input
                            label={"Τωρινός κωδικός"}
                            name={"currentPassword"}
                            type={"password"}
                            autoComplete={"off"}
                            className={"mb-2"}
                            placeholder={"Εισάγετε τον τωρινό σας κωδικό"}
                            value={passwordData.currentPassword}
                            onChange={(e) => handleOnChange(e, "input-password", "currentPassword")}
                        />
                        <Input
                            label={"Νέος κωδικός"}
                            name={"newPassword"}
                            type={"password"}
                            autoComplete={"off"}
                            className={"mb-2"}
                            placeholder={"Εισάγετε τον καινούργιο κωδικό"}
                            value={passwordData.newPassword}
                            onChange={(e) => handleOnChange(e, "input-password", "newPassword")}
                        />
                        <Input
                            label={"Επιβεβαίωση νέου κωδικού"}
                            name={"confirmNewPassword"}
                            type={"password"}
                            autoComplete={"off"}
                            className={"mb-2"}
                            placeholder={"Εισάγετε ξανά τον καινούργιο κωδικό"}
                            value={passwordData.confirmNewPassword}
                            onChange={(e) => handleOnChange(e, "input-password", "confirmNewPassword")}
                        />
                        <div style={{textAlign: "center"}}>
                            <Button className={"mt-2"} size={"sm"} onClick={() => handleChangePasswordClicked()} disabled={loading}>Αλλαγή κωδικού</Button>
                        </div>
                    </div>
                </Col>
                {!userIsEmployee ? (
                    <Col md={4} className={"mt-2"}>
                        <div style={{borderRadius: "12px", border: "1px solid #6ea2a9", padding: "12px", height: "100%"}}>
                            <h5 style={{fontWeight: "600"}}>Αλλαγή στοιχείων χρήστη</h5>
                            <Input
                                label={"Όνομα"}
                                name={"firstName"}
                                type={"text"}
                                autoComplete={"off"}
                                className={"mb-2"}
                                placeholder={"Εισάγετε όνομα"}
                                value={userData.firstName}
                                onChange={(e) => handleOnChange(e, "input-userDetails", "firstName")}
                            />
                            <Input
                                label={"Επώνυμο"}
                                name={"lastName"}
                                type={"text"}
                                autoComplete={"off"}
                                className={"mb-2"}
                                placeholder={"Εισάγετε επώνυμο"}
                                value={userData.lastName}
                                onChange={(e) => handleOnChange(e, "input-userDetails", "lastName")}
                            />
                            <Input
                                label={"E-Mail"}
                                name={"email"}
                                type={"text"}
                                autoComplete={"off"}
                                className={"mb-2"}
                                placeholder={"Εισάγετε E-Mail"}
                                value={userData.email}
                                disabled={true}
                                //onChange={(e) => handleOnChange(e, "input-userDetails", "email")}
                            />
                            <Dropdown
                                label={"Γλώσσα"}
                                options={languageOptions}
                                key={Math.random()}
                                defaultValue={languageOptions.find((el) => el.value === i18n.language)}
                                onChange={(e) => handleLanguageChange(e)}
                            />
                            <div style={{textAlign: "center"}}>
                                <Button size={"sm"} onClick={() => handleChangeUserDetailsClicked()} disabled={loading}>Αποθήκευση αλλαγών</Button>
                            </div>
                            <div className={"text-muted"}>(*) Η σελίδα θα ανανεωθεί αμέσως μετά την επιτυχή αποθήκευση</div>
                        </div>
                    </Col>
                ) : (
                    <Col md={4}></Col>
                )}
            </Row>
        </div>
    )
}