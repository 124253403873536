// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offline-alert {
    z-index: 999;
    position: fixed;
    left: 0;
    right: 0;
    padding: 0;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/_components/common/OnlineOffline/offlineAlert.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,OAAO;IACP,QAAQ;IACR,UAAU;IACV,kBAAkB;AACtB","sourcesContent":[".offline-alert {\r\n    z-index: 999;\r\n    position: fixed;\r\n    left: 0;\r\n    right: 0;\r\n    padding: 0;\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
