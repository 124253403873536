import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    fixNetValueAndVat,
    getDefaultOptionByValue,
    getMultiSelectOptionsToArray,
    getOptionsByData, getRecordTypeOptions, isEmpty,
    salesMyDataStatus
} from "../../../../_helpers/commonFunctions";
import {
    fetchAccountingAdvancedTypes,
    fetchAccountingPaymentTypes,
    fetchAccountingRecordCategories,
    fetchAccountingRecordSubcategories, fetchBankAccounts, fetchPOS, sendAccountingRecordToMyData
} from "../../../../_apis/api";
import axios from "axios";
import {
    getLabelByValue,
    getObjectFromArrayOfObjects
} from "../../../../_helpers/helperFunctions";
import {
    setAccountingRecordSubcategories, setEditAccountingRecord,
} from "../../../../_reducers/DataPanel/Accounting/accountingSlice";
import {setSaveEditAccountingRecord} from "../../../../_reducers/activitySlice";
import {toast} from "react-toastify";
import {Button, Col, Modal, Row} from "react-bootstrap";
import Dropdown from "../../../primer/Dropdown";
import DateBox from "../../../primer/DateBox";
import TimePicker from "react-time-picker";
import Input from "../../../common/Input";
import TextArea from "../../../common/TextArea";
import Loader from "../../../primer/POPUP-COMPONENTS/Loader/PrimerLoader";
import Alert from "react-bootstrap/Alert";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import "./styles/reports.css"
import AccountingTable from "../../../primer/AccountingTable/AccountingTable";
import {addTab, updateActiveMainTab} from "../../../../_reducers/TabsSlice";
import {setEditProductMovements} from "../../../../_reducers/DataPanel/Products/productsSlice";
import {setEditSales} from "../../../../_reducers/DataPanel/Sales/salesSlice";
import {setEditClientMovements, setEditClientPayment} from "../../../../_reducers/DataPanel/Clients/clientSlice";
import {setEditBuys} from "../../../../_reducers/DataPanel/Buys/buysSlice";
import {
    setEditSupplierMovements,
    setEditSupplierPayment
} from "../../../../_reducers/DataPanel/Suppliers/supplierSlice";
import LogTable from "../../../primer/LogTable";
import moment from "moment";

export const EditAccountingRecord = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    let date = new Date();
    const currentTime = date.getHours() + ':' + String(date.getMinutes()).padStart(2, '0');
    const ACTIVITY = useSelector( (state) => state.ACTIVITY);
    const ACCOUNTING_DATA = useSelector( (state) => state.ACCOUNTING_DATA);
    const CASH_DATA = useSelector( (state) => state.CASH_DATA );
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const categories = [{ label: "Κενό", value: ""}, ...getOptionsByData(ACCOUNTING_DATA.accountingRecordCategories, "_id", "name")]
    const subCategories = [{ label: "Κενό", value: ""}, ...getOptionsByData(ACCOUNTING_DATA.accountingRecordSubcategories, "_id", "name")]
    const documentTypesOptions = useMemo(() => getOptionsByData(ACCOUNTING_DATA.accountingAdvancedTypes, "_id", "name"), [ACCOUNTING_DATA.accountingAdvancedTypes]);
    const installations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const [showModalConfirmation, setShowModalConfirmation] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [requiredPaymentType, setRequiredPaymentType] = useState(false);
    const [tempSubCategoryValue, setTempSubCategoryValue] = useState("");
    const [tempSubCategoryLabel, setTempSubCategoryLabel] = useState("");
    const installationOptions = getOptionsByData(installations, "installationMasterId", "address");
    const [accountsList, setAccountsList] = useState([]);
    const [loadingSendToMyData, setLoadingSendToMyData] = useState(false);
    const [showMyDataErrorModal, setShowMyDataErrorModal] = useState(false);
    const paymentTypeOptions = useMemo(() => getOptionsByData(ACCOUNTING_DATA.paymentTypes, "_id", "name"), [ACCOUNTING_DATA.paymentTypes]);
    const [defaultCorrelatedData, setDefaultCorrelatedData] = useState([]);
    const [documentToSwitch, setDocumentToSwitch] = useState({});

    const [incrKey, setIncrKey] = useState(0);
    useEffect(() => {
        setIncrKey(incrKey + 1);
    }, [ACCOUNTING_DATA.editAccountingRecord.accounts, accountsList])

    useEffect(() => {
        reloadAccountsFromServer();
    }, [ACCOUNTING_DATA.editAccountingRecord?.myDataStatus])

    const isInvulnerable  = ACCOUNTING_DATA.editAccountingRecord?.myDataStatus === "sent" || !isEmpty(ACCOUNTING_DATA.editAccountingRecord?.bridgedDocument);
    let disabledFields = false;
    if ((isEmpty(ACCOUNTING_DATA.editAccountingRecord.category) || isEmpty(ACCOUNTING_DATA.editAccountingRecord.subCategory)) && !isInvulnerable) {
        disabledFields = true;
    }
    const posOptions = getOptionsByData(CASH_DATA.posList, '_id', 'name');
    const bankOptions = getOptionsByData(CASH_DATA.bankAccounts, '_id', 'name');
    const requestData2 = {
        company: company.id,
        year: company.year.toString(),
        isActive: "active"
    }
    useEffect(() => {
        dispatch(fetchAccountingPaymentTypes(company));
        dispatch(fetchPOS(requestData2));
        dispatch(fetchBankAccounts(requestData2));
        dispatch(fetchAccountingRecordCategories(company.id));
        dispatch(fetchAccountingRecordSubcategories(company.id));
        dispatch(fetchAccountingAdvancedTypes(company.id));

        if(ACCOUNTING_DATA.editAccountingRecord.paymentType) {
            const filter = ACCOUNTING_DATA.paymentTypes.filter((el) => el._id === ACCOUNTING_DATA.editAccountingRecord.paymentType);
            if(filter.length > 0) {
                let paymentType = filter[0];
                if (paymentType.type === "pos") {
                    setPosBankFieldOptions(posOptions);
                    setHidePosBankField(false);
                }
                if (paymentType.type === "bank-account") {
                    setPosBankFieldOptions(bankOptions);
                    setHidePosBankField(false);
                }
                if (paymentType.type === "cash" || paymentType.type === "credit" || paymentType.type === "cheque") {
                    setPosBankFieldOptions([]);
                    setHidePosBankField(true);
                }
            }
        }
        reloadAccountsFromServer();
        if (String(ACCOUNTING_DATA?.editAccountingRecord?.category).length === 24) {
            loadSubCategories(ACCOUNTING_DATA?.editAccountingRecord?.category);
        }
    }, []);

    useEffect(() => {
        if(ACCOUNTING_DATA.editAccountingRecord?.myDataType === "5.1") {
            axios.get(process.env.REACT_APP_API_URL2 + "/accounting/get-correlatable-records", {
                headers: {"Content-Type": "application/json"},
                params: {
                    company: company.id,
                    year: String(company.year),
                    fromEdit: true,
                    fromEditCorrelated: ACCOUNTING_DATA.editAccountingRecord?.correlatedRecord,
                }
            }).then((res) => {
                if(res.data.status === "200") {
                    setDefaultCorrelatedData(res.data.data);
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [ACCOUNTING_DATA.editAccountingRecord.myDataType])

    const loadSubCategories = (categoryID) => {
        axios.post(process.env.REACT_APP_API_URL2 + `/accounting/accounting-record-category-list`, {id: categoryID}, {
            headers: {"Content-Type": "application/json"},
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                dispatch(setAccountingRecordSubcategories(res.data.data?.subCategories));
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            toast.error("Κάτι πήγε λάθος κατά την ανάκτηση της συγκεκριμένης κατηγορίας.");
            console.log(error);
        })
    }

    const reloadAccountsFromServer = (updateClassifications = false) => {
        if(ACCOUNTING_DATA.editAccountingRecord.accounts && ACCOUNTING_DATA.editAccountingRecord.accounts.length > 0 && !isEmpty(ACCOUNTING_DATA.editAccountingRecord.myDataType)) {
            let ids = [];
            ACCOUNTING_DATA.editAccountingRecord.accounts.map((obj) => ids.push(obj._id));
            axios.get(process.env.REACT_APP_API_URL2 + "/accounting/get-mydata-by-accounts", {
                headers: {"Content-Type": "application/json"},
                params: {
                    company: company.id,
                    accounts: ids,
                }
            }).then((res) => {
                if(res.data.status === "200") {
                    if(res.data.data) {
                        let accountsClone = ACCOUNTING_DATA.editAccountingRecord.accounts.slice();
                        accountsClone = accountsClone.map((a) => Object.assign({}, a));
                        accountsClone.forEach((acc) => {
                            acc.mydata = res.data.data[acc._id];
                            /* Update My Data classifications */
                            if(updateClassifications === true) {
                                const matchingMyData = acc.mydata.filter((myData) => String(myData.invoiceType) === String(ACCOUNTING_DATA.editAccountingRecord.myDataType));
                                if(matchingMyData.length > 0) {
                                    acc.classificationCategory = matchingMyData[0].category;
                                    acc.classificationType = matchingMyData[0].type;
                                    if(matchingMyData[0].vatExpenseType) acc.vatExpenseType = matchingMyData[0].vatExpenseType;
                                } else {
                                    acc.classificationCategory = "";
                                    acc.classificationType = "";
                                    acc.vatExpenseType = "";
                                }
                            }
                        })
                        dispatch(setEditAccountingRecord({...ACCOUNTING_DATA.editAccountingRecord, accounts: fixDisabledAccounts(accountsClone)}));
                    }
                }
            })
        } else {
            let accountsClone = ACCOUNTING_DATA.editAccountingRecord.accounts.slice();
            accountsClone = accountsClone.map((a) => Object.assign({}, a));
            dispatch(setEditAccountingRecord({...ACCOUNTING_DATA.editAccountingRecord, accounts: fixDisabledAccounts(accountsClone)}));
        }
    }

    const [hidePosBankField, setHidePosBankField] = useState(true);
    const [posBankFieldOptions, setPosBankFieldOptions] = useState();

    const accountsColumns = [
        {
            label: t("AccountingAccountNew.accountingCode.name"),
            name: "code",
        },
        {
            label: t("AccountingAccountNew.name.name"),
            name: "name",
        },
        {
            label: t("AccountingAccountNew.alternativeName.name"),
            name: "alternativeName",
        }
    ];

    const accountingAccountsTableColumns = [
        {
            name: t("AccountingAccountNew.accountingCode.name"),
            field: "code",
            width: "25%",
            editable: true,
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: accountsColumns,
                    data: accountsList,
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: t("AccountingAccountNew.name.placeholder")
                },
                type: 'advanced-combobox',
            }
        },
        {
            name: t("AccountingAccountNew.name.name"),
            field: "name",
            editable: true,
            width: "25%",
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: accountsColumns,
                    data: accountsList,
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: t("AccountingAccountNew.name.placeholder")
                },
                type: 'advanced-combobox',
            }
        },
        {
            name: t("AccountingAccountNew.subAccount.name"),
            field: "subAccount",
            editable: true,
            width: "20%",
            inputType: {
                config: {
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: t("AccountingAccountNew.subAccount.placeholder")
                },
                type: 'advanced-combobox',
            }
        },
        {
            name: t("General.debit"),
            field: "debit",
            width: "10%",
            editable: true,
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    allowNegative: true,
                    maxLength: 7
                }
            }
        },
        {
            name: t("General.credit"),
            field: "credit",
            width: "10%",
            editable: true,
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    allowNegative: true,
                    suffix: "€",
                    maxLength: 7
                }
            }
        },
        {
            name: t("General.actions"),
            field: "actions",
            width: "10%",
            actions: {
                delete: true,
                editClassifications: true,
                warning: true,
            },
            inputType: {
                type: "actions",
            }
        }
    ]
    useEffect(() => {
        if(ACCOUNTING_DATA.editAccountingRecord?.documentType){
            loadAccountListData(ACCOUNTING_DATA.editAccountingRecord.documentType);
        }
    },[ACCOUNTING_DATA.editAccountingRecord.documentType]);

    const sendToMyData = () => {
        console.log( ACCOUNTING_DATA.editAccountingRecord);
        let data = {
            companyId: company.id,
            recordId: ACCOUNTING_DATA.editAccountingRecord._id
        }
        dispatch(sendAccountingRecordToMyData(data, null, setLoadingSendToMyData, setForceRefreshAccounts));
    }

    const loadAccountListData = (value) => {
        let params = {
            id: value,
            company: company.id
        }
        axios.get(process.env.REACT_APP_API_URL2 + `/accounting/account-list-by-advanced-type-id`,{
            headers: {"Content-Type": "application/json"},
            params: params
        }).then(res => {
            if (res.data && res.data.status === '200') {
                if (res.data.data) {
                    setAccountsList(res.data.data);
                } else {
                    setAccountsList([]);
                }
            } else {
                setAccountsList([]);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const loadInitialSubAccountListData = async (subAccountType, subAccountCategories, tempRowAccount) => {
        let requestData = {company: company.id, subAccountType: subAccountType, subAccountCategories: subAccountCategories}
        await axios.get(process.env.REACT_APP_API_URL2 + `/accounting/get-subAccount-by-word`, {
            params: {
                ...requestData,
                keyword: "empty",
            }
        }).then(res => {
            if (res.data && res.data.status === '200') {
                if (res.data.data) {
                    tempRowAccount.subAccountData = res.data.data;
                } else {
                    tempRowAccount.subAccountData = [];
                }
            } else {
                tempRowAccount.subAccountData = [];
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const handleConfirmCategory = async () => {
        let accountData = Object.assign({}, ACCOUNTING_DATA.editAccountingRecord);
        let newRecordData = {}
        let value = tempSubCategoryValue;
        let foundCategory = null;
        setShowModalConfirmation(false);
        setShowLoader(true);
        newRecordData["subCategory"] = value;
        //let category;
        await axios.post(process.env.REACT_APP_API_URL2 + `/accounting/accounting-record-subcategories-list`, {name: tempSubCategoryLabel, id: value, company: company.id}, {
            headers: {"Content-Type": "application/json"},
        }).then((res) => {
            if (res.data && res.data.status === "200" && res.data?.data) {
                foundCategory = res.data.data;
                //category = res.data.category;
            }
        }).catch((err) => {
            console.log(err);
        })
        if (foundCategory) {
            //newRecordData["category"] = category.category;
            newRecordData["subCategoryName"] = foundCategory.name;
            //newRecordData["categoryName"] = category.categoryName;
            //newRecordData["documentType"] = foundCategory?.documentType;
            newRecordData["recordType"] = foundCategory.recordType ? foundCategory.recordType : "normal";
            newRecordData["correlatedRecord"] = "";
            newRecordData["correlatedRecordLabel"] = "";
            const selectedDocumentTypeData = getObjectFromArrayOfObjects(ACCOUNTING_DATA.accountingAdvancedTypes, foundCategory?.documentType, "_id");
            newRecordData["installation"] = selectedDocumentTypeData[0]?.proposedInstallation;
            //newRecordData["myDataType"] = selectedDocumentTypeData[0]?.myDataType;
            newRecordData["reason"] = foundCategory?.reason;
            newRecordData["accounts"] = [];
            if (foundCategory?.accounts && foundCategory.accounts.length > 0) {
                for (let account of foundCategory.accounts) {
                    const updatedAccount = Object.assign({}, account);
                    const myDataData = updatedAccount.mydata.filter(myData => String(myData.invoiceType) === String(selectedDocumentTypeData[0]?.myDataType));
                    if( myDataData.length > 0) {
                        updatedAccount.classificationCategory = myDataData[0].category;
                        updatedAccount.classificationType = myDataData[0].type;
                    } else {
                        updatedAccount.classificationCategory = '';
                        updatedAccount.classificationType = '';
                    }
                    await loadInitialSubAccountListData(updatedAccount.subAccountType, updatedAccount.subAccountCategories, updatedAccount);
                    newRecordData["accounts"].push(updatedAccount);
                }
            }
        }
        setShowLoader(false);
        dispatch(setEditAccountingRecord({...accountData, ...newRecordData}));
    }

    const [forceRefreshAccounts, setForceRefreshAccounts] = useState(false);
    const [documentTypeChanged, setDocumentTypeChanged] = useState(false);
    const handleSwitchDocumentType = (toSwitch, forceDoc) => {
        let doc = forceDoc ? forceDoc : documentToSwitch;
        if(toSwitch) {
            let newRecordData = {};
            newRecordData["documentType"] = doc?._id;
            newRecordData["documentTypeName"] = doc?.name;
            newRecordData["installation"] = doc?.proposedInstallation;
            newRecordData["myDataType"] = doc?.myDataType;
            newRecordData["correlatedRecord"] = "";
            newRecordData["correlatedRecordLabel"] = "";
            if (doc?.enableMyDataRules === "active") setRequiredPaymentType(true);
            dispatch(setEditAccountingRecord({...ACCOUNTING_DATA.editAccountingRecord, ...newRecordData}));
            setDocumentTypeChanged(true);
        }
        setDocumentToSwitch({});
    }
    useEffect(() => {
        if(documentTypeChanged === true) {
            setDocumentTypeChanged(false);
            reloadAccountsFromServer(true);
        }
    }, [documentTypeChanged])

    useEffect(() => {
        if(forceRefreshAccounts === true) {
            setForceRefreshAccounts(false);
            reloadAccountsFromServer(false);
        }
    }, [forceRefreshAccounts])

    const handleOnChange = async (e, type = "default", ddname = '') => {
        // Set if editing the form
        if (!ACTIVITY.saveEditAccountingRecord) {
            dispatch(setSaveEditAccountingRecord(true));
        }
        let name;
        let value;
        let newRecordData = {}
        if (type === 'dp') {
            value = e;
            name = ddname;
        } else if (type === 'ac') {
            value = e.target.value;
            name = ddname;
        } else if (type === 'dd') {
            name = ddname;
            value = e.value;
        } else if (type === 'msdd') {
            name = ddname;
            value = getMultiSelectOptionsToArray(e);
        } else if (type === 'tp') {
            value = e;
            name = ddname;
        } else if (type === "correlatedComboBox") {
            newRecordData["correlatedRecord"] = e.row._id;
            newRecordData["correlatedRecordLabel"] = e.row.date + " | " + e.row.documentTypeName + " | " + e.row.number
        } else {
            value = e.target.value;
            name = e.target.name;
        }
        let accountData = Object.assign({}, ACCOUNTING_DATA.editAccountingRecord);
        let updateRedux = true;
        if (name === "category") {
            if (isEmpty(value)) {
                newRecordData["category"] = "";
                newRecordData["categoryName"] = "";
            } else if (typeof (value) !== 'undefined') {
                newRecordData["category"] = e.value;
                newRecordData["categoryName"] = e.label;
                await axios.post(process.env.REACT_APP_API_URL2 + `/accounting/accounting-record-category-list`, {id: value}, {
                    headers: {"Content-Type": "application/json"},
                }).then((res) => {
                    if (res.data && res.data.status === "200") {
                        if (isEmpty(accountData.subCategory)) {
                            dispatch(setAccountingRecordSubcategories(res.data.data?.subCategories));
                        } else {
                            let tmp = [{_id: accountData.subCategory, name: accountData.subCategoryName}];
                            tmp = [...tmp, ...res.data.data?.subCategories];
                            dispatch(setAccountingRecordSubcategories(tmp));
                        }
                    } else {
                        toast.error(res.data.message);
                    }
                }).catch((error) => {
                    toast.error("Κάτι πήγε λάθος κατά την ανάκτηση της συγκεκριμένης κατηγορίας");
                    console.log(error);
                });
            }
        } else if (name === "documentType") {
            updateRedux = false;
            const selectedDocumentTypeData = getObjectFromArrayOfObjects(ACCOUNTING_DATA.accountingAdvancedTypes, value, "_id");
            if(accountData.documentType) {
                setDocumentToSwitch(selectedDocumentTypeData[0]);
            } else {
                handleSwitchDocumentType(true, selectedDocumentTypeData[0]);
            }
        } else if (name === "subCategory") {
            if (isEmpty(value)) {
                newRecordData["subCategory"] = "";
                newRecordData["subCategoryName"] = "";
            } else if (accountData?.subCategory !== "" && accountData.subCategory !== value){
                setTempSubCategoryValue(value);
                setTempSubCategoryLabel(e.label);
                setShowModalConfirmation(true);
                return;
            } else {
                let  foundCategory;
                //let category;
                await axios.post(process.env.REACT_APP_API_URL2 + `/accounting/accounting-record-subcategories-list`, {name: e.label, id: value, company: company.id}, {
                    headers: {"Content-Type": "application/json"},
                }).then((res) => {
                    if (res.data && res.data.status === "200" && res.data?.data) {
                        foundCategory = res.data.data;
                        //category = res.data.category;
                    }
                }).catch((err) => {
                    console.log(err);
                })
                if (foundCategory) {
                    //newRecordData["category"] = category.category;
                    newRecordData["subCategoryName"] = foundCategory.name;
                    //newRecordData["categoryName"] = category.categoryName;
                    //newRecordData["documentType"] = foundCategory?.documentType;
                    const selectedDocumentTypeData = getObjectFromArrayOfObjects(ACCOUNTING_DATA.accountingAdvancedTypes, foundCategory?.documentType, "_id");
                    //newRecordData["documentTypeName"] = selectedDocumentTypeData[0]?.name;
                    newRecordData["recordType"] = foundCategory.recordType ? foundCategory.recordType : "normal";
                    newRecordData["installation"] = selectedDocumentTypeData[0]?.proposedInstallation;
                    //newRecordData["myDataType"] = selectedDocumentTypeData[0]?.myDataType;
                    newRecordData["reason"] = foundCategory?.reason;
                    newRecordData["accounts"] = [];
                    if (foundCategory?.accounts && foundCategory.accounts.length > 0) {
                        for (let account of foundCategory.accounts) {
                            const updatedAccount = Object.assign({}, account);
                            const myDataData = updatedAccount.mydata.filter(myData => String(myData.invoiceType) === String(ACCOUNTING_DATA.editAccountingRecord?.myDataType));
                            if( myDataData.length > 0) {
                                updatedAccount.classificationCategory = myDataData[0].category;
                                updatedAccount.classificationType = myDataData[0].type;
                            } else {
                                updatedAccount.classificationCategory = '';
                                updatedAccount.classificationType = '';
                            }
                            await loadInitialSubAccountListData(updatedAccount.subAccountType, updatedAccount.subAccountCategories, updatedAccount);
                            newRecordData["accounts"].push(updatedAccount);
                        }
                    }
                }
            }
        } else if (name === 'paymentType') {
            newRecordData.paymentTypeName = getLabelByValue(paymentTypeOptions, value, 'value');
            // This will update the pre-selected POS / Bank
            let paymentTypesData = getObjectFromArrayOfObjects(ACCOUNTING_DATA.paymentTypes, value, '_id');
            if (paymentTypesData && paymentTypesData.length > 0) {
                let selectedPaymentType = paymentTypesData['0'];
                newRecordData.myDataPaymentId = selectedPaymentType?.myDataPaymentId;
                if (selectedPaymentType?.type === "pos") {
                    newRecordData.bankAccount = selectedPaymentType.options?.preSelectedPosBankAccount;
                } else if (selectedPaymentType?.type === "bank-account") {
                    newRecordData.bankAccount = selectedPaymentType.options?.preSelectedPosBankAccount;
                } else {
                    newRecordData.bankAccount = "";
                    newRecordData.bankAccountName = "";
                }
            }
            // This will update the list of pos / bank on type selection
            if (paymentTypesData['0']?.type === "pos") {
                setPosBankFieldOptions(posOptions);
                setHidePosBankField(false);
            }
            if (paymentTypesData['0']?.type === "bank-account") {
                setPosBankFieldOptions(bankOptions);
                setHidePosBankField(false);
            }
            if (paymentTypesData['0']?.type === "cash" || paymentTypesData['0']?.type === "credit" || paymentTypesData['0']?.type === "cheque") {
                setPosBankFieldOptions([]);
                setHidePosBankField(true);
            }
        } else if (name === "bankAccount") {
            newRecordData["bankAccountName"] = e.label;
        }
        setShowLoader(false);
        newRecordData[name] = value;
        if(updateRedux) dispatch(setEditAccountingRecord({...accountData, ...newRecordData}));
    }

    const fixDisabledAccounts = (allAccounts) => {
        if (ACCOUNTING_DATA.accountingRecordSubcategories) {
            const findSubCategory = ACCOUNTING_DATA.accountingRecordSubcategories.find((el) => el.name === ACCOUNTING_DATA.editAccountingRecord.subCategoryName);
            if (findSubCategory?.accounts?.length > 0) {
                allAccounts = allAccounts.map((a) => Object.assign({}, a));
                for (let acc of allAccounts) {
                    const findMatching = findSubCategory.accounts.find((el) => el.code === acc.code);
                    if (findMatching) {
                        acc.isCredit = findMatching.isCredit;
                        acc.isDebit = findMatching.isDebit;
                    }
                }
            }
        }
        return allAccounts;
    }
    const selectedPaymentType = getDefaultOptionByValue(paymentTypeOptions, ACCOUNTING_DATA.editAccountingRecord.paymentType, 'value', 'label', 'value');

    const onChangeSearchCorrelatedRecord = (keyword, setData) => {
        axios.get(process.env.REACT_APP_API_URL2 + "/accounting/get-correlatable-records", {
            headers: {"Content-Type": "application/json"},
            params: {
                company: company.id,
                year: String(company.year),
                keyword: keyword,
                fromEdit: true,
                fromEditCorrelated: ACCOUNTING_DATA.editAccountingRecord?.correlatedRecord,
            }
        }).then((res) => {
            if(res.data.status === "200") {
                setData(res.data.data);
            }
        })
    }
    const clearCorrelated = () => {
        dispatch(setEditAccountingRecord({...ACCOUNTING_DATA.editAccountingRecord, correlatedRecord: "", correlatedRecordLabel: ""}));
    }

    const handleAutoFillCorrelatedAccounts = () => {
        if (!ACTIVITY.saveEditAccountingRecord) dispatch(setSaveEditAccountingRecord(true));

        axios.get(process.env.REACT_APP_API_URL2 + "/accounting/get-auto-fill-correlated-accounts", {
            headers: {"Content-Type": "application/json"},
            params: {
                company: company.id,
                year: String(company.year),
                correlated: ACCOUNTING_DATA.editAccountingRecord.correlatedRecord,
            }
        }).then((res) => {
            if(res.data.status === "200") {
                let totalDebit = 0;
                let totalCredit = 0;
                for(let acc of res.data.data) {
                    totalDebit += Number(acc.debit);
                    totalCredit += Number(acc.credit);
                }
                dispatch(setEditAccountingRecord({
                    ...ACCOUNTING_DATA.editAccountingRecord,
                    accounts: res.data.data,
                    totalDebit: parseFloat(totalDebit).toFixed(2),
                    totalCredit: parseFloat(totalCredit).toFixed(2)
                }));
                toast.success("Επιτυχής συμπλήρωση.");
                setDocumentTypeChanged(true);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }
    const handleSwitchCreditAndDebit = () => {
        if (!ACTIVITY.saveEditAccountingRecord) dispatch(setSaveEditAccountingRecord(true));

        let accountsClone = ACCOUNTING_DATA.editAccountingRecord.accounts.slice();
        if(accountsClone?.length > 0) {
            accountsClone = accountsClone.map((a) => Object.assign({}, a));
            accountsClone.forEach((acc) => {
                let t = acc.debit;
                acc.debit = acc.credit;
                acc.credit = t;
            })
        }
        let totalDebit = 0;
        let totalCredit = 0;
        for(let acc of accountsClone) {
            totalDebit += Number(acc.debit);
            totalCredit += Number(acc.credit);
        }
        dispatch(setEditAccountingRecord({
            ...ACCOUNTING_DATA.editAccountingRecord,
            accounts: accountsClone,
            totalDebit: parseFloat(totalDebit).toFixed(2),
            totalCredit: parseFloat(totalCredit).toFixed(2)
        }));
    }

    const [showMyDataXML, setShowMyDataXML] = useState(false);
    const toggleShowMyDataXML = () => {
        setShowMyDataXML(!showMyDataXML);
    }

    const tableFunctions = {
        onInsert: async (field, value) => {
            if (!ACTIVITY.saveEditAccountingRecord) dispatch(setSaveEditAccountingRecord(true));
            let accountsClone = ACCOUNTING_DATA.editAccountingRecord.accounts.slice();
            accountsClone = accountsClone.map((a) => Object.assign({}, a));
            if ((field === "code" || field === "name") && value instanceof Object) {
                let tempNewAccount = Object.assign({}, ACCOUNTING_DATA.newAccountInstance);
                tempNewAccount["_id"] = value._id;
                tempNewAccount["no"] = accountsClone.length + 1;
                tempNewAccount["id"] = accountsClone.length + 1;
                tempNewAccount["code"] = value.code;
                tempNewAccount["name"] = value.name;
                tempNewAccount["isNetValue"] = value.isNetValue;
                tempNewAccount["vat"] = value.vat;
                tempNewAccount["vatreason"] = value.vatreason;
                tempNewAccount["subAccountType"] = value.subAccount;
                tempNewAccount["taxType"] = value.taxType;
                tempNewAccount["otherTax"] = value.otherTax;
                tempNewAccount["fee"] = value.fee;
                tempNewAccount["stamp"] = value.stamp;
                tempNewAccount["withHoldTax"] = value.withHoldTax;
                tempNewAccount["mydata"] = value.mydata;
                const myDataData = value.mydata.filter(myData => String(myData.invoiceType) === String(ACCOUNTING_DATA.editAccountingRecord.myDataType));
                if (myDataData.length > 0) {
                    tempNewAccount["classificationCategory"] = myDataData[0].category;
                    tempNewAccount["classificationType"] = myDataData[0].type;
                    if (myDataData[0].vatExpenseType) {
                        tempNewAccount["vatExpenseType"] = myDataData[0].vatExpenseType;
                    }
                } else {
                    tempNewAccount["classificationCategory"] = '';
                    tempNewAccount["classificationType"] = '';
                    tempNewAccount["vatExpenseType"] = '';
                }
                if (tempNewAccount["subAccount"] === "client") {
                    tempNewAccount.subAccountCategories = value.clientCategories;
                } else if (tempNewAccount["subAccount"] === "supplier") {
                    tempNewAccount.subAccountCategories = value.supplierCategories;
                } else if (tempNewAccount["subAccount"] === "employee") {
                    tempNewAccount.subAccountCategories = value.employeeCategories;
                } else {
                    tempNewAccount.subAccountCategories = [];
                }
                if (tempNewAccount.subAccountType) {
                    await loadInitialSubAccountListData(tempNewAccount.subAccountType, tempNewAccount.subAccountCategories, tempNewAccount);
                }
                tempNewAccount.subAccount = "";
                tempNewAccount.credit = 0;
                tempNewAccount.debit = 0;
                accountsClone.push(tempNewAccount);

                accountsClone = fixNetValueAndVat(accountsClone);
                accountsClone = fixNetValueAndVat(accountsClone);
                let totalDebit = 0;
                let totalCredit = 0;
                for(let acc of accountsClone) {
                    totalDebit += Number(acc.debit);
                    totalCredit += Number(acc.credit);
                }
                dispatch(setEditAccountingRecord({
                    ...ACCOUNTING_DATA.editAccountingRecord,
                    accounts: fixDisabledAccounts(accountsClone),
                    totalDebit: parseFloat(totalDebit).toFixed(2),
                    totalCredit: parseFloat(totalCredit).toFixed(2)
                }));
            }
        },
        onChange: async (row, field, value) => {
            if (!ACTIVITY.saveEditAccountingRecord) dispatch(setSaveEditAccountingRecord(true));
            //console.log(row, field, value);
            let accountsClone = ACCOUNTING_DATA.editAccountingRecord.accounts.slice();
            accountsClone = accountsClone.map((a) => Object.assign({}, a));
            if (field === "debit") {
                accountsClone[row]["credit"] = 0;
                accountsClone[row]["debit"] = value;
            } else if (field === "credit") {
                accountsClone[row]["credit"] = value;
                accountsClone[row]["debit"] = 0;
            } else if ((field === "code" || field === "name") && typeof value === "object") {
                // value is the new selected account
                accountsClone[row]["_id"] = value._id;
                accountsClone[row]["code"] = value.code;
                accountsClone[row]["name"] = value.name;
                accountsClone[row]["subAccountType"] = value.subAccount;
                accountsClone[row]["isNetValue"] = value.isNetValue;
                accountsClone[row]["vat"] = value.vat;
                accountsClone[row]["vatreason"] = value.vatreason;
                accountsClone[row]["subAccount"] = "";
                accountsClone[row]["subAccountId"] = "";
                accountsClone[row]["credit"] = 0;
                accountsClone[row]["debit"] = 0;
                accountsClone[row]["taxType"] = value.taxType;
                accountsClone[row]["otherTax"] = value.otherTax;
                accountsClone[row]["fee"] = value.fee;
                accountsClone[row]["stamp"] = value.stamp;
                accountsClone[row]["withHoldTax"] = value.withHoldTax;
                accountsClone[row]["mydata"] = value.mydata;
                const myDataData = value.mydata.filter(myData => String(myData.invoiceType) === String(ACCOUNTING_DATA.editAccountingRecord.myDataType));
                if (myDataData.length > 0) {
                    accountsClone[row]["classificationCategory"] = myDataData[0].category;
                    accountsClone[row]["classificationType"] = myDataData[0].type;
                    if (myDataData[0].vatExpenseType) {
                        accountsClone[row]["vatExpenseType"] = myDataData[0].vatExpenseType;
                    }
                } else {
                    accountsClone[row]["classificationCategory"] = "";
                    accountsClone[row]["classificationType"] = "";
                    accountsClone[row]["vatExpenseType"] = "";
                }
                if (value.subAccount === "client") {
                    accountsClone[row]["subAccountCategories"] = value.clientCategories;
                } else if (value.subAccount === "supplier") {
                    accountsClone[row]["subAccountCategories"] = value.supplierCategories;
                } else if (value.subAccount === "employee") {
                    accountsClone[row]["subAccountCategories"] = value.employeeCategories;
                } else {
                    accountsClone[row]["subAccountCategories"] = [];
                }
                if (value.subAccount !== "") {
                    await loadInitialSubAccountListData(value.subAccount, accountsClone[row]["subAccountCategories"], accountsClone[row]);
                }
            } else if (field === "subAccount" && typeof value === "object") {
                accountsClone[row]["subAccountId"] = value._id;
                if (accountsClone[row]["subAccountType"] === "client" || accountsClone[row]["subAccountType"] === "supplier") {
                    accountsClone[row]["subAccount"] = value.name;
                } else if (accountsClone[row]["subAccountType"] === "employee") {
                    accountsClone[row]["subAccount"] = value.firstName + " " + value.lastName;
                } else if (accountsClone[row]["subAccountType"] === "installation") {
                    accountsClone[row]["subAccount"] = value.address + ", " + value.postalCode + ", " + value.city;
                } else {
                    accountsClone[row]["subAccount"] = value.name;
                }
            }
            if(accountsClone[row]["vat"] === "") accountsClone = fixNetValueAndVat(accountsClone);

            let totalDebit = 0;
            let totalCredit = 0;
            for(let acc of accountsClone) {
                totalDebit += Number(acc.debit);
                totalCredit += Number(acc.credit);
            }
            dispatch(setEditAccountingRecord({
                ...ACCOUNTING_DATA.editAccountingRecord,
                accounts: fixDisabledAccounts(accountsClone),
                totalDebit: parseFloat(totalDebit).toFixed(2),
                totalCredit: parseFloat(totalCredit).toFixed(2)
            }));
        },
        onDelete: (rowIndex) => {
            if (!ACTIVITY.saveEditAccountingRecord) dispatch(setSaveEditAccountingRecord(true));
            let accounts = ACCOUNTING_DATA.editAccountingRecord.accounts.slice();
            accounts = accounts.map((a) => Object.assign({}, a));
            accounts.splice(rowIndex, 1);
            accounts = fixNetValueAndVat(accounts);
            let totalCredit = 0;
            let totalDebit = 0;
            for (let newAcc of accounts) {
                totalCredit = totalCredit + Number(newAcc.credit);
                totalDebit = totalDebit + Number(newAcc.debit);
            }
            dispatch(setEditAccountingRecord({
                ...ACCOUNTING_DATA.editAccountingRecord,
                accounts: accounts,
                totalCredit: parseFloat(totalCredit).toFixed(2),
                totalDebit: parseFloat(totalDebit).toFixed(2)
            }));
        }
    }
    const showBridgedDocument = () => {
        const tabMapping = {
            "Product Movements" : "edit-product-movements",
            "Sales" : "view-sales",
            "Client Movements" : "edit-client-movements",
            "Client Payments" : "edit-client-payments",
            "Buys" : "view-buys",
            "Supplier Payments" : "edit-supplier-payments",
            "Supplier Movements" : "edit-supplier-movements",
        }
        axios.post(process.env.REACT_APP_API_URL2 + "/accounting/show-bridged-document", {company: company.id, recordId: ACCOUNTING_DATA.editAccountingRecord._id}, {
            headers: {"Content-Type": "application/json"},
        }).then((res) => {
            if (res.data.status === "200") {
                const tabName = tabMapping[ACCOUNTING_DATA.editAccountingRecord.bridgedSection];
                if (tabName === "edit-product-movements") {
                    dispatch(setEditProductMovements(res.data.data));
                } else if (tabName === "view-sales") {
                    dispatch(setEditSales(res.data.data));
                } else if (tabName === "edit-client-movements") {
                    dispatch(setEditClientMovements(res.data.data));
                } else if (tabName === "edit-client-payments") {
                    dispatch(setEditClientPayment(res.data.data));
                } else if (tabName === "view-buys") {
                    dispatch(setEditBuys(res.data.data));
                } else if (tabName === "edit-supplier-payments") {
                    dispatch(setEditSupplierPayment(res.data.data));
                } else if (tabName === "edit-supplier-movements") {
                    dispatch(setEditSupplierMovements(res.data.data));
                }
                const idx = TABS_DATA.findIndex((item) => tabName === item);
                if(idx === -1) {
                    dispatch(addTab(tabName));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(idx));
                }
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            console.log(error);
        })
    }

    const advancedTypeSectionOptionsSearch = [
        {value: "null", label: t('AdvanceTypesNewDropDown.null'), color: "#76AEB7"},
        {value: "Product Movements", label: t('AdvanceTypesNewDropDown.productMovements'), color: "#76AEB7"},
        {value: "Sales", label: t('AdvanceTypesNewDropDown.sales'), color: "#76AEB7"},
        {value: "Client Movements", label: t('AdvanceTypesNewDropDown.clientMovements'), color: "#76AEB7"},
        {value: "Client Payments", label: t('AdvanceTypesNewDropDown.clientPayments'), color: "#76AEB7"},
        {value: "Buys", label: t('AdvanceTypesNewDropDown.buys'), color: "#76AEB7"},
        {value: "Supplier Payments", label: t('AdvanceTypesNewDropDown.supplierPayments'), color: "#76AEB7"},
        {value: "Supplier Movements", label: "Κινήσεις Προμηθευτών", color: "#76AEB7"},
    ];

    return (
        <React.Fragment>
            {isInvulnerable === true && <Alert variant={"secondary"}>
                {!isEmpty(ACCOUNTING_DATA.editAccountingRecord?.bridgedDocument) && (
                    <React.Fragment>
                        <span>Το λογιστικό άρθρο δεν είναι επεξεργάσιμο επειδή έχει δημιουργηθεί από την λογιστική γέφυρα.</span>
                        <br/>
                        <span style={{fontWeight: "700"}}>Κατηγορία εμπορικού εγγράφου:</span>
                        <span> {advancedTypeSectionOptionsSearch.find((el) => el.value === ACCOUNTING_DATA.editAccountingRecord?.bridgedSection)?.label} </span>
                        <Button style={{position: "absolute", right: "5px", top: "5px"}} className={"ml-1"} onClick={() => showBridgedDocument()} size={"sm"}>Προβολή Εμπορικού Εγγράφου</Button>
                    </React.Fragment>
                )}
                {ACCOUNTING_DATA.editAccountingRecord?.myDataStatus === "sent" && (<span>Το λογιστικό άρθρο δεν είναι επεξεργάσιμο επειδή έχει σταλεί στο My Data.</span>)}
                {ACCOUNTING_DATA.editAccountingRecord.myDataQRURL && String(ACCOUNTING_DATA.editAccountingRecord?.myDataQRURL).length > 10 && (
                    <Button href={ACCOUNTING_DATA.editAccountingRecord.myDataQRURL} className={"ml-1"} target="_blank" size={"sm"} style={{padding: "4px"}}>
                        <img src={"assets/images/external_link.png"} style={{height: "16px", width: "16px"}} alt={"External Link"} />
                    </Button>
                )}
                {!isEmpty(ACCOUNTING_DATA.editAccountingRecord.myDataRequest) && (
                    <Button style={{position: "absolute", right: "5px", top: "5px"}} className={"ml-1"} onClick={() => toggleShowMyDataXML()} size={"sm"}>Προβολή Απεσταλμένου XML</Button>
                )}
                {ACCOUNTING_DATA.editAccountingRecord.mark && (
                    <React.Fragment>
                        <br/>
                        <span style={{fontWeight: "600"}}>MARK: </span><span>{ACCOUNTING_DATA.editAccountingRecord.mark}</span>
                        <br/>
                        <span style={{fontWeight: "600"}}>UID: </span><span>{ACCOUNTING_DATA.editAccountingRecord.uid}</span>
                    </React.Fragment>
                )}
            </Alert>}
            <div className="client-form product-form position-relative" style={{pointerEvents: isInvulnerable ? "none" : ""}}>
                {disabledFields && <div className="overlay_w">Επιλέξτε πρώτα Τύπο παραστατικού..</div>}
                <Row className="border-bottom mb-3">
                    <Col sm={12} md={3}>
                        <Dropdown
                            className={"showWithOverlay mb-3"}
                            label="Κατηγορία"
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(categories, ACCOUNTING_DATA.editAccountingRecord.category, 'value', 'label', 'value')}
                            searchEnable={true}
                            disabled={isInvulnerable}
                            name="category"
                            options={categories}
                            onChange={(e) => handleOnChange(e, 'dd', 'category')}
                        />
                        {isEmpty(ACCOUNTING_DATA.editAccountingRecord.category) && !isInvulnerable && (
                            <div className={"text-danger"}>Η κατηγορία είναι υποχρεωτική</div>
                        )}
                    </Col>
                    <Col sm={12} md={3}>
                        <Dropdown
                            className={"showWithOverlay"}
                            label="Yπόκατηγορία"
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(subCategories, ACCOUNTING_DATA.editAccountingRecord.subCategory, 'value', 'label', 'value')}
                            searchEnable={true}
                            disabled={isInvulnerable}
                            name="subCategory"
                            options={subCategories}
                            onChange={(e) => handleOnChange(e, 'dd', 'subCategory')}
                        />
                        {isEmpty(ACCOUNTING_DATA.editAccountingRecord.subCategory) && !isInvulnerable && (
                            <div className={"text-danger"}>Η υποκατηγορία είναι υποχρεωτική</div>
                        )}
                    </Col>
                    <Col sm={12} md={3}>
                        <Dropdown
                            label="Τύπος Εγγραφής"
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(getRecordTypeOptions(), ACCOUNTING_DATA.editAccountingRecord.recordType, 'value', 'label', 'value')}
                            searchEnable={true}
                            name="recordType"
                            options={getRecordTypeOptions()}
                            className={"showWithOverlay"}
                        />
                    </Col>
                    <Col sm={12} md={3}>
                        <div className="d-flex justify-content-end">
                            <div className="mydata-status disabled-page">
                                <Dropdown
                                    label={t('SalesNew.myDataStatus')}
                                    options={salesMyDataStatus()}
                                    defaultValue={getDefaultOptionByValue(salesMyDataStatus(), ACCOUNTING_DATA.editAccountingRecord.myDataStatus, 'value', 'label', 'value')}
                                    name="myDataStatus"
                                    key={Math.random()}
                                    disabled={isInvulnerable}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col sm={12} md={3}>
                        <Dropdown
                            label={t('SalesNew.DocumentType')}
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(documentTypesOptions, ACCOUNTING_DATA.editAccountingRecord.documentType, 'value', 'label', 'value')}
                            required={true}
                            searchEnable={true}
                            name="documentType"
                            disabled={isInvulnerable}
                            options={documentTypesOptions}
                            onChange={(e) => handleOnChange(e, 'dd', 'documentType')}
                        />
                    </Col>
                    {ACCOUNTING_DATA.editAccountingRecord?.myDataType === "5.1" && (
                        <React.Fragment>
                            <Col md={6}>
                                <AdvancedCombobox
                                    className="w-100 ac-select-dropdown"
                                    searchableDropdownEnable={false}
                                    label={"Συσχετιζόμενο Άρθρο"}
                                    placeholder={"Εισάγετε συσχετιζόμενο άρθρο"}
                                    defaultValue={ACCOUNTING_DATA.editAccountingRecord?.correlatedRecordLabel}
                                    data={defaultCorrelatedData}
                                    onChange={(e) => handleOnChange(e, "correlatedComboBox")}
                                    excludeColumns={[]}
                                    columns={[
                                        {label: "Ημερομηνία", name: "date"},
                                        {label: "Τύπος Παραστατικού", name: "documentTypeName"},
                                        {label: "Αρίθμηση", name: "number"},
                                        {label: "Ποσό", name: "totalAmount"},
                                    ]}
                                    required={ACCOUNTING_DATA.editAccountingRecord?.myDataType === "5.1"}
                                    editEnable={false}
                                    deleteEnable={false}
                                    searchTableEnable={false}
                                    showTableOptionOnSelect={true}
                                    key={Math.random()}
                                    disabled={String(ACCOUNTING_DATA.editAccountingRecord?.correlatedRecord).length === 24 || isInvulnerable}
                                    onChangeSearch={onChangeSearchCorrelatedRecord}
                                />
                            </Col>
                            <Col sm="12" md="1">
                                {String(ACCOUNTING_DATA.editAccountingRecord?.correlatedRecord).length === 24 && !isInvulnerable &&
                                    <button style={{position: "absolute", bottom: "16px"}} className="btn btn-sm" title="Καθαρισμός συσχετιζόμενου άρθρου" onClick={() => clearCorrelated()}><i className="fas fa-xmark"></i></button>
                                }
                            </Col>
                            <Col md={2}>
                                {String(ACCOUNTING_DATA.editAccountingRecord?.correlatedRecord).length === 24 && (
                                    <Button size={"sm"} onClick={() => handleAutoFillCorrelatedAccounts()} disabled={isInvulnerable}>Αυτόματη συμπλήρωση λογαριασμών από συσχετιζόμενο άρθρο</Button>
                                )}
                            </Col>
                        </React.Fragment>
                    )}
                </Row>
                {showLoader ? <Loader /> : (
                    <>
                <Row>
                    <Col sm="12" md="2" className={"mb-3"}>
                        {/* If "MyData rules" is enabled in "Settings-Document Types-Advanced Types", then FIELD IS READ ONLY. Select current date only. */}
                        <DateBox
                            classes="w-100"
                            label={t('SalesNew.Date')}
                            name="date"
                            selected={moment(ACCOUNTING_DATA.editAccountingRecord.date).isValid() ? new Date(ACCOUNTING_DATA.editAccountingRecord.date) : null}
                            allowOlderDate={true}
                            allowOlderDateValue={"999999"}
                            disabled={isInvulnerable}
                            onChange={(e) => handleOnChange(e, 'dp', 'date')}
                        />
                        {isEmpty(ACCOUNTING_DATA.editAccountingRecord.date) && <div className={"text-danger"}>Απαιτείται Ημερομηνία.</div> }
                    </Col>
                    <Col sm="12" md="1">
                        {/* If "MyData rules" is enabled in "Settings-Document Types-Advanced Types", then FIELD IS READ ONLY. Select current date only. */}
                        <label htmlFor="time">{t('SalesNew.time')}</label>
                        <TimePicker
                            locale="en-us"
                            className="form-control"
                            name="time"
                            disabled={isInvulnerable}
                            value={ACCOUNTING_DATA.editAccountingRecord.time ? ACCOUNTING_DATA.editAccountingRecord.time : currentTime}
                            disableClock={true}
                            clearIcon={null}
                            format={"H:mm"}
                            onChange={(e) => handleOnChange(e, 'tp', 'time')}
                        />
                    </Col>
                    <Col sm="12" md="2">
                        <div className="mb-3">
                            <Dropdown
                                label={t("General.installation")}
                                options={installationOptions}
                                value={getDefaultOptionByValue(installationOptions, ACCOUNTING_DATA.editAccountingRecord.installation, 'value', 'label', 'value')}
                                enabledValue={true}
                                searchEnable={true}
                                disabled={isInvulnerable}
                                required={true}
                                name="installation"
                                onChange={(e) => handleOnChange(e, 'dd', 'installation')}
                            />
                            {ACTIVITY.saveEditAccountingRecord && ACCOUNTING_DATA.editAccountingRecord.installation === '' && <div className="text-danger">Η εγκατάσταση είναι υποχρεωτική.</div> }
                        </div>
                    </Col>
                    <Col sm="12" md="1">
                        <div className="d-flex">
                            <Input
                                className="mb-3 w-100"
                                label={t("General.preNumber")}
                                name="preNumber"
                                type="text"
                                value={ACCOUNTING_DATA.editAccountingRecord.preNumber}
                                disabled={isInvulnerable}
                                placeholder="Σειρά"
                                onChange={(e) => handleOnChange(e)}
                            />
                        </div>
                    </Col>
                    <Col sm="12" md="1">
                        <div className="d-flex">
                            <Input
                                className="mb-3 w-100"
                                label={t("General.number")}
                                name="number"
                                type="number"
                                required={ACCOUNTING_DATA.editAccountingRecord?.myDataType}
                                value={ACCOUNTING_DATA.editAccountingRecord.number}
                                disabled={isInvulnerable}
                                placeholder="Αριθμός"
                                onChange={(e) => handleOnChange(e)}
                            />
                        </div>
                    </Col>
                    {!isEmpty(ACCOUNTING_DATA.editAccountingRecord?.myDataType) && (
                        <Col sm="12" md="5" className="text-right d-flex align-items-center justify-content-end">
                            {!isEmpty(ACCOUNTING_DATA.editAccountingRecord?.myDataError) && (
                                <Button className="view-sales-buttons" disabled={ACCOUNTING_DATA.editAccountingRecord.myDataStatus === "sent" || isEmpty(ACCOUNTING_DATA.editAccountingRecord?.myDataType)} onClick={() => setShowMyDataErrorModal(true)}>{t('SalesNewView.myDataErrors')}</Button>
                            )}
                            <Modal className="themeModal" show={showMyDataErrorModal}>
                                <Modal.Header>
                                    <Modal.Title>Warning!</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>The document failed to be sent to MyData! <br /> The Error is: <br /> {ACCOUNTING_DATA.editAccountingRecord?.myDataError}</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="outline-primary" onClick={() => setShowMyDataErrorModal(false)}>OK</Button>
                                </Modal.Footer>
                            </Modal>
                            <Button className="view-sales-buttons" disabled = {ACCOUNTING_DATA.editAccountingRecord.myDataStatus === "sent" || loadingSendToMyData} onClick={() => sendToMyData()}>
                                {loadingSendToMyData && (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                )}
                                {t('SalesNewView.sendToMyData')}</Button>
                        </Col>
                    )}
                    <Col sm="12" md="5">
                        <div className="d-flex">
                            <Input
                                className="mb-3 w-100"
                                label={t("ProductsMovementsNew.reason.reason")}
                                name="reason"
                                type="text"
                                required={false}
                                value={ACCOUNTING_DATA.editAccountingRecord.reason}
                                disabled={t("ProductsMovementsNew.reason.placeholder")}
                                placeholder="Εισάγετε Αιτία"
                                onChange={(e) => handleOnChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="4">
                        <Dropdown
                            label={t('SalesNew.paymentType.paymentType')}
                            options={paymentTypeOptions}
                            value={Object.keys(selectedPaymentType).length !== 0 ? selectedPaymentType : ''}
                            enabledValue={true}
                            disabled={isInvulnerable}
                            name="paymentType"
                            searchEnable={true}
                            onChange={(e) => handleOnChange(e, 'dd', 'paymentType')}
                        />
                        {requiredPaymentType && isEmpty(ACCOUNTING_DATA.newAccountingRecord.paymentType) && (
                            <div className={"text-danger"}>Ο τρόπος πληρωμής είναι υποχρεωτικός</div>
                        )}
                    </Col>
                    {!hidePosBankField &&
                        <Col sm="12" md="4">
                            <Dropdown
                                label={t('SalesNew.posBankAccount')}
                                options={posBankFieldOptions}
                                defaultValue={getDefaultOptionByValue(posBankFieldOptions, ACCOUNTING_DATA.editAccountingRecord.bankAccount, 'value', 'label', 'value')}
                                enabledValue={true}
                                required={!hidePosBankField}
                                disabled={isInvulnerable}
                                name="bankAccount"
                                searchEnable={true}
                                onChange={(e) => handleOnChange(e, 'dd', 'bankAccount')}
                            />
                            {ACTIVITY.saveEditAccountingRecord && !hidePosBankField && ACCOUNTING_DATA.editAccountingRecord.bankAccount === "" && <div className="text-danger">{t('SalesNew.posBankAccountRequired')}</div>}
                        </Col>
                    }
                </Row>
                    <Row>
                        <AccountingTable
                            functions={tableFunctions}
                            columns={accountingAccountsTableColumns}
                            arrayOfObjData={ACCOUNTING_DATA.editAccountingRecord.accounts}
                            totalDebit={parseFloat(ACCOUNTING_DATA.editAccountingRecord.totalDebit).toFixed(2)}
                            totalCredit={parseFloat(ACCOUNTING_DATA.editAccountingRecord.totalCredit).toFixed(2)}
                            switchCreditAndDebit={handleSwitchCreditAndDebit}
                        />
                    </Row>
                <Row>
                    <div style={{ width: "100%" }}>
                        <div className="text-muted mb-2"><i>Σημειώσεις Λογιστικού Άρθρου</i></div>
                        <TextArea
                            rows="4"
                            placeholder="Προσθέστε Σημειώσεις Λογιστικού Άρθρου"
                            name="notes"
                            value={ACCOUNTING_DATA.editAccountingRecord.notes}
                            onChange={(e) => handleOnChange(e)}
                            disabled={isInvulnerable}
                        />
                    </div>
                </Row>
                    </> )}
            </div>
            <Modal className="themeModal" show={showModalConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('SalesNew.warning')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('SalesNew.warningMessage')}</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowModalConfirmation(false)}>{t('SalesNew.no')}</Button>
                    <Button variant="primary" onClick={() => handleConfirmCategory()} >{t('SalesNew.yes')}</Button>
                </Modal.Footer>
            </Modal>

            <Modal className="themeModal" show={Object.keys(documentToSwitch).length > 0}>
                <Modal.Header closeButton>
                    <Modal.Title>Επιλογή τύπου παραστατικού</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Είσαστε σίγουροι ότι θέλετε να αλλάξετε τύπο παραστατικού;
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => handleSwitchDocumentType(false)}>Όχι</Button>
                    <Button variant="primary" onClick={() => handleSwitchDocumentType(true)}>Ναι</Button>
                </Modal.Footer>
            </Modal>

            <Modal className="themeModal" onHide={() => toggleShowMyDataXML()} show={showMyDataXML} dialogClassName={"modal-50per"}>
                <Modal.Header closeButton>
                    <Modal.Title>Προβολή XML</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <pre style={{whiteSpace: "pre-wrap"}}>{ACCOUNTING_DATA.editAccountingRecord.myDataRequest}</pre>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => toggleShowMyDataXML()}>Κλείσιμο</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export const AccountingRecordEditLog = () => {
    const { t } = useTranslation('common');
    const ACCOUNTING_DATA = useSelector((state) => state.ACCOUNTING_DATA);

    return (
        <React.Fragment>
            <div className="text-muted mb-2"><i>{t('Logs.accountingRecordLog')}</i></div>
            <LogTable itemId={ACCOUNTING_DATA.editAccountingRecord._id}/>
        </React.Fragment>
    )
}