import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    resetNewTreatmentProtocol,
    setNewTreatmentProtocol
} from "../../../../../_reducers/DataPanel/Prescription/prescriptionSlice";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Dropdown from "../../../../primer/Dropdown";
import {Col, Row} from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import Input from "../../../../common/Input";
import {asArray} from "../constants";
import {isEmpty} from "../../../../../_helpers/commonFunctions";
import axios from "axios";
import BetterCheckBox from "../../../../common/BetterCheckBox";
import moment from "moment";
import DateBox from "../../../../primer/DateBox";
import {toast} from "react-toastify";
import TextArea from "../../../../common/TextArea";

const NewTreatmentProtocolModal = ({show, setShow, successCallback}) => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const activeVisit = useSelector((state) => state.PRESCRIPTION.activeVisit);
    const newTreatmentProtocol = useSelector((state) => state.PRESCRIPTION.newTreatmentProtocol);
    const doctorTreatmentProtocols = useSelector((state) => state.PRESCRIPTION.doctorTreatmentProtocols);

    const [tpParameters, setTPParameters] = useState([]);
    const [toFetchTP, setToFetchTP] = useState(false);
    const [toFetchDrugs, setToFetchDrugs] = useState(false);
    const [lockParameters, setLockParameters] = useState(false);

    const [availableSteps, setAvailableSteps] = useState([]);
    const [availableDrugs, setAvailableDrugs] = useState([]);
    const [selectedDrugs, setSelectedDrugs] = useState([]);

    const drugGroups = availableDrugs.groups ? availableDrugs.groups.group : [];

    const treatmentStepsOptions = availableSteps.map((o) => {
        return {
            id: o.id,
            value: o.step,
            label: o.description,
        }
    })

    const doctorTPOptions = doctorTreatmentProtocols.map((obj) => {
        return {
            value: obj.id,
            label: `${obj.code} ${obj.title}`,
        }
    })

    const selectedTreatmentProtocol = doctorTreatmentProtocols.find((el) => el.id === newTreatmentProtocol.treatmentProtocolId);
    const icd10s = selectedTreatmentProtocol ? asArray(selectedTreatmentProtocol.treatmentProtocolICD10s.treatmentProtocolICD10) : [];
    const icd10sOptions = icd10s.map((obj) => {
        return {
            value: obj.icd10.id,
            label: `${obj.icd10.code} - ${obj.icd10.title}`,
        }
    })

    const resetModal = () => {
        dispatch(resetNewTreatmentProtocol());
        setTPParameters([]);
        setToFetchTP(false);
        setToFetchDrugs(false);
        setLockParameters(false);
        setAvailableSteps([]);
        setAvailableDrugs([]);
        setSelectedDrugs([]);
    }

    useEffect(() => {
        if (!show) resetModal();
    }, [show]);

    useEffect(() => {
        if (toFetchTP) {
            setToFetchTP(false);
            fetchTPParameters();
        }
    }, [toFetchTP])

    useEffect(() => {
        if (toFetchDrugs) {
            setToFetchDrugs(false);
            fetchDrugs();
        }
    }, [toFetchDrugs])

    useEffect(() => {
        if (lockParameters) {
            fetchTPSteps();
        } else {
            setAvailableSteps([]);
            dispatch(setNewTreatmentProtocol({...newTreatmentProtocol, treatmentStep: ""}));
        }
    }, [lockParameters])

    const fetchTPParameters = () => {
        axios.get(`${process.env.REACT_APP_API_URL2}/prescription/treatment-protocol-parameters`, {
            headers: { 'Content-Type': 'application/json' },
            params: {
                company: company.id,
                amka: activeVisit.amka,
                treatmentProtocolId: newTreatmentProtocol.treatmentProtocolId,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                const cleanedUp = res.data.data.filter((el) => !["ICD10IDS", "DOCID", "NST"].includes(el.protocolParamPool.id));
                setTPParameters(cleanedUp);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const fetchTPSteps = () => {
        axios.post(`${process.env.REACT_APP_API_URL2}/prescription/treatment-protocol-steps`, {
            ...newTreatmentProtocol,
            amka: activeVisit.amka,
            icd10s: newTreatmentProtocol.selectedICD10s || [],
            params: tpParameters,
            company: company.id,
        }, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            if (res.data.status === "200") {
                setAvailableSteps(asArray(res.data.data));
            } else {
                setLockParameters(false);
                toast.error(`Σφάλμα κατά την άντληση βημάτων αγωγής. Σφάλμα: ${res.data.message}`, {autoClose: 5000});
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την άντληση βημάτων αγωγής.");
        })
    }

    const fetchDrugs = () => {
        axios.post(`${process.env.REACT_APP_API_URL2}/prescription/treatment-protocol-drugs`, {
            ...newTreatmentProtocol,
            amka: activeVisit.amka,
            stepId: treatmentStepsOptions.find((el) => el.value === newTreatmentProtocol.treatmentStep).id,
            icd10s: newTreatmentProtocol.selectedICD10s || [],
            params: tpParameters,
            company: company.id,
        }, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            if (res.data.status === "200") {
                let respData = res.data.data;
                respData.groups.group = asArray(respData.groups.group);
                for (let g of respData.groups.group) {
                    g.protocolGroupSubstances.protocolGroupSubstance = asArray(g.protocolGroupSubstances.protocolGroupSubstance);
                }
                setAvailableDrugs(respData);
            } else {
                toast.error(`Σφάλμα κατά την άντληση δραστικών ουσιών. Σφάλμα: ${res.data.message}`, {autoClose: 5000});
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την άντληση βημάτων αγωγής.");
        })
    }

    const handleOnChange = (e, type, name) => {
        let prepChange = {};
        if (type === "dd") {
            prepChange[name] = e.value;
            if (name === "treatmentProtocolId") {
                prepChange["selectedICD10s"] = [];
                setToFetchTP(true);
            }
            if (name === "treatmentStep") {
                setToFetchDrugs(true);
            }
        } else if (type === "msdd") {
            prepChange[name] = e.flatMap((o) => o.value);
        } else if (type === "input") {
            prepChange[name] = e.target.value;
        }
        dispatch(setNewTreatmentProtocol({
            ...newTreatmentProtocol,
            ...prepChange,
        }));
    }

    const handleSaveTreatmentProtocol = () => {
        axios.post(`${process.env.REACT_APP_API_URL2}/prescription/new-treatment-protocol`, {
            treatmentProtocolId: newTreatmentProtocol.treatmentProtocolId,
            treatmentStepNotes: newTreatmentProtocol.treatmentStepNotes,
            amka: activeVisit.amka,
            stepId: treatmentStepsOptions.find((el) => el.value === newTreatmentProtocol.treatmentStep).id,
            icd10s: newTreatmentProtocol.selectedICD10s || [],
            params: tpParameters,
            resultKey: availableDrugs.resultKey,
            substances: selectedDrugs,
            company: company.id,
        }, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής καταχώρηση νέου θεραπευτικού πρωτοκόλλου.");
                if (typeof successCallback === "function") successCallback();
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const renderPrevInputComponent = (param) => {
        const inputType = param.protocolParamPool.control;

        if (inputType === "TEXT") {
            return <span>{param.previousValue}</span>
        } else if (inputType === "CHECK") {
            return (
                <BetterCheckBox
                    name={`${param.protocolParamPool.id}_cell`}
                    checked={param.previousValue === "1"}
                    disabled={true}
                />
            )
        } else if (inputType === "DROPDOWN") {
            const options = String(param.protocolParamPool.data).split("#").map((obj) => {
                const resplit = String(obj).split(";");
                return {
                    value: resplit[0],
                    label: resplit[1],
                }
            })
            return (
                <Dropdown
                    className={"m-0"}
                    name={`${param.protocolParamPool.id}_cell`}
                    unlabbled={true}
                    options={options}
                    defaultValue={options.find((el) => el.value === param.previousValue)}
                    key={Math.random()}
                    disabled={true}
                />
            )
        } else {
            return <React.Fragment></React.Fragment>
        }
    }

    const renderPrevDateComponent = (param) => {
        if (param.previousDate) {
            const momented = moment(param.previousDate, "YYYY-MM-DD HH:mm:ss");
            if (momented.isValid()) {
                return <React.Fragment>{momented.format("DD/MM/YYYY στις HH:mm")}</React.Fragment>
            }
        }
        return <React.Fragment>{param.previousDate}</React.Fragment>
    }

    const renderInputComponent = (param, idx) => {
        const readOnly = param.readOnly === "true";
        const inputType = param.protocolParamPool.control;

        const onChangeParam = (e) => {
            const copyParams = structuredClone(tpParameters);
            if (inputType === "TEXT") {
                copyParams[idx].value = e.target.value;
            } else if (inputType === "CHECK") {
                copyParams[idx].value = e.target.checked ? "1" : "0";
            } else if (inputType === "DROPDOWN") {
                copyParams[idx].value = e.value;
            }
            setTPParameters(copyParams);
        }

        if (inputType === "TEXT") {
            if (readOnly) {
                return <span>{param.value}</span>
            } else {
                return (
                    <Input
                        name={`${param.protocolParamPool.id}_cell`}
                        unlabbled={true}
                        type={param.protocolParamPool.dataType === "NUMBER" ? "number" : "text"}
                        placeholder={"Εισ. τιμή"}
                        onBlur={(e) => onChangeParam(e)}
                    />
                )
            }
        } else if (inputType === "CHECK") {
            return (
                <BetterCheckBox
                    name={`${param.protocolParamPool.id}_cell`}
                    checked={param.value === "1"}
                    onChange={(e) => onChangeParam(e)}
                    disabled={readOnly}
                />
            )
        } else if (inputType === "DROPDOWN") {
            const options = String(param.protocolParamPool.data).split("#").map((obj) => {
                const resplit = String(obj).split(";");
                return {
                    value: resplit[0],
                    label: resplit[1],
                }
            })
            return (
                <Dropdown
                    className={"m-0"}
                    name={`${param.protocolParamPool.id}_cell`}
                    unlabbled={true}
                    options={options}
                    defaultValue={options.find((el) => el.value === param.value)}
                    key={Math.random()}
                    disabled={readOnly}
                    onChange={(e) => onChangeParam(e)}
                />
            )
        } else {
            return <React.Fragment></React.Fragment>
        }
    }

    const renderDateComponent = (param, idx) => {
        const readOnly = param.readOnly === "true";

        const onChangeDate = (e) => {
            const copyParams = structuredClone(tpParameters);
            if (moment(e).isValid()) {
                copyParams[idx].measuredDate = moment(e).format("YYYYMMDDHHmmss");
            } else {
                copyParams[idx].measuredDate = moment().format("YYYYMMDDHHmmss");
            }
            setTPParameters(copyParams);
        }

        if (readOnly) {
            return <React.Fragment></React.Fragment>
        } else {
            return (
                <DateBox
                    selected={moment(param.measuredDate, "YYYYMMDDHHmmss").isValid() ? moment(param.measuredDate, "YYYYMMDDHHmmss").toDate() : null}
                    onChange={(e) => onChangeDate(e)}
                />
            )
        }
    }

    const renderDoctorAmkaComponent = (param, idx) => {
        if (param.amkaIsVisible === "true") {
            const onChangeAmka = (e) => {
                const copyParams = structuredClone(tpParameters);
                copyParams[idx].amka = e.target.value;
                setTPParameters(copyParams);
            }
            return (
                <Input
                    name={`${param.protocolParamPool.id}_amka`}
                    unlabbled={true}
                    type={"number"}
                    placeholder={"Εισ. τιμή"}
                    onBlur={(e) => onChangeAmka(e)}
                />
            )
        }
        return <React.Fragment></React.Fragment>
    }

    return (
        <React.Fragment>
            {/* New treatment Protocol Modal */}
            <Modal show={show} onHide={() => setShow(false)} backdrop={"static"} dialogClassName={"modal95PercentWidth"}>
                <Modal.Header closeButton>
                    <Modal.Title>Νέο θεραπευτικό πρωτόκολλο</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4}>
                            <Dropdown
                                name={"treatmentProtocolId"}
                                label={"Θεραπευτικό Πρωτόκολλο"}
                                options={doctorTPOptions}
                                defaultValue={doctorTPOptions.find((el) => el.value === newTreatmentProtocol.treatmentProtocolId)}
                                key={Math.random()}
                                onChange={(e) => handleOnChange(e, "dd", "treatmentProtocolId")}
                            />
                        </Col>
                        {selectedTreatmentProtocol && (
                            <React.Fragment>
                                <Col md={4}>
                                    <Input
                                        label={"Ημερομηνία ένταξης στο Πρωτόκολλο"}
                                        disabled={true}
                                        placeholder={"-"}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Input
                                        label={"Ημερομηνία τελευταίας Συνταγογράφησης"}
                                        disabled={true}
                                        placeholder={"-"}
                                    />
                                </Col>
                            </React.Fragment>
                        )}
                    </Row>
                    {selectedTreatmentProtocol && (
                        <React.Fragment>
                            <Row>
                                <Col md={12}>
                                    <Alert variant={"secondary"}>
                                        <strong>MDC:</strong> {selectedTreatmentProtocol?.mdc?.code} - {selectedTreatmentProtocol?.mdc?.description}<br/>
                                        {!isEmpty(selectedTreatmentProtocol?.description) && (
                                            <span><strong>Περιγραφή:</strong> {selectedTreatmentProtocol?.description}<br/></span>
                                        )}
                                        {!isEmpty(selectedTreatmentProtocol?.documentationUrl) && (
                                            <span><strong>Σχετικό URL:</strong> <a href={selectedTreatmentProtocol?.documentationUrl} target={"_blank"}>{selectedTreatmentProtocol?.documentationUrl}</a><br/></span>
                                        )}
                                        {!isEmpty(selectedTreatmentProtocol.notes) && (
                                            <span><strong>Σημειώσεις</strong> {selectedTreatmentProtocol.notes}<br/></span>
                                        )}
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Dropdown
                                        name={"selectedICD10s"}
                                        label={"Διαγνώσεις ICD10"}
                                        multiSelect={true}
                                        defaultValue={icd10sOptions.filter((el) => newTreatmentProtocol.selectedICD10s.includes(el.value))}
                                        options={icd10sOptions}
                                        onChange={(e) => handleOnChange(e, "msdd", "selectedICD10s")}
                                    />
                                </Col>
                            </Row>
                            {tpParameters.length > 0 && (
                                <React.Fragment>
                                    <table className={"simpleClassicTable"}>
                                        <colgroup>
                                            <col span={1} style={{width: "30%"}}></col>

                                            <col span={1} style={{width: "10%"}}></col>
                                            <col span={1} style={{width: "10%"}}></col>

                                            <col span={1} style={{width: "10%"}}></col>
                                            <col span={1} style={{width: "10%"}}></col>
                                            <col span={1} style={{width: "10%"}}></col>
                                            <col span={1} style={{width: "10%"}}></col>
                                            <col span={1} style={{width: "10%"}}></col>
                                        </colgroup>
                                        <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th colSpan={2}>Προηγούμενη Τιμή</th>
                                            <th colSpan={5}>Νέες τιμές & Στοιχεία Ιατρού</th>
                                        </tr>
                                        <tr>
                                            <th>Παράμετρος</th>

                                            <th>Τιμή Παραμέτρου</th>
                                            <th>Ημερ. Μέτρησης</th>

                                            <th>Τιμή Παραμέτρου</th>
                                            <th>Ημ/νία Μέτρησης</th>
                                            <th>ΑΜΚΑ Ιατρού</th>
                                            <th>Ονοματεπώνυμο Ιατρού</th>
                                            <th>Μονάδα Μέτρησης / Όρια Τιμών</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {tpParameters.map((param, idx) => (
                                            <tr key={`treatmentParameters-${idx}`} style={{backgroundColor: "#f5f5f5"}}>
                                                <td>{param.protocolParamPool.label}</td>

                                                <td>{renderPrevInputComponent(param)}</td>
                                                <td>{renderPrevDateComponent(param)}</td>

                                                <td style={{textAlign: "center"}}>{renderInputComponent(param, idx)}</td>
                                                <td>{renderDateComponent(param, idx)}</td>
                                                <td>{renderDoctorAmkaComponent(param, idx)}</td>
                                                <td></td>
                                                <td>{param.protocolParamPool.extraLabel}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    {!lockParameters ? (
                                        <div style={{textAlign: "center"}} className={"mt-2"}>
                                            <Button size={"sm"} onClick={() => setLockParameters(true)}>Οριστικοποίηση παραμέτρων & μετάβαση στα βήματα αγωγής</Button>
                                        </div>
                                    ) : (
                                        <div style={{textAlign: "center"}} className={"mt-2"}>
                                            <Button size={"sm"} onClick={() => setLockParameters(false)}>Τροποποίηση παραμέτρων</Button>
                                            <div>(*) Θα χαθεί η επιλογή του βήματος αγωγή καθώς και πιθανά σχόλια που έχετε εισάγει</div>
                                        </div>
                                    )}
                                    {availableSteps.length > 0 && (
                                        <div className={"borderedContainer mt-2"}>
                                            <Row>
                                                <Col md={4}>
                                                    <Dropdown
                                                        name={"treatmentStep"}
                                                        label={"Βήμα Αγωγής"}
                                                        options={treatmentStepsOptions}
                                                        defaultValue={treatmentStepsOptions.find((el) => el.value === newTreatmentProtocol.treatmentStep)}
                                                        key={Math.random()}
                                                        onChange={(e) => handleOnChange(e, "dd", "treatmentStep")}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <TextArea
                                                        name={"treatmentStepNotes"}
                                                        label={"Σχόλιο"}
                                                        rows={3}
                                                        limit={4000}
                                                        onChange={(e) => handleOnChange(e, "input", "treatmentStepNotes")}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                    {!isEmpty(newTreatmentProtocol.treatmentStep) && (
                                        <div className={"borderedContainer mt-2"}>
                                            <div style={{textAlign: "center"}}><h5><u>{availableDrugs.description}</u></h5></div>
                                            <Row>
                                                {drugGroups.length > 0 && (
                                                    <React.Fragment>
                                                        {drugGroups.map((group, idx) => (
                                                            <Col md={4} className={"mb-2"}>
                                                                <div className={"borderedContainer"}>
                                                                    <div style={{textAlign: "center"}}><h5>{group.description}</h5></div>
                                                                    <table className={"simpleClassicTable"}>
                                                                        <colgroup>
                                                                            <col span={1} style={{width: "20%"}}></col>
                                                                            <col span={1} style={{width: "40%"}}></col>
                                                                            <col span={1} style={{width: "30%"}}></col>
                                                                            <col span={1} style={{width: "10%"}}></col>
                                                                        </colgroup>
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Κωδικός ATC</th>
                                                                            <th>Περιγραφή</th>
                                                                            <th>Περιεκτικότητα</th>
                                                                            <th>&nbsp;</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {group.protocolGroupSubstances.protocolGroupSubstance.map((sub, idx2) => (
                                                                            <tr key={`group-${idx}-sub-${idx2}`}>
                                                                                <td>{sub.atcCode}</td>
                                                                                <td>{sub.description}</td>
                                                                                <td>{sub.displayContent}</td>
                                                                                <td style={{zoom: "1.2", border: "1px solid gray", paddingLeft: "4px", paddingRight: "4px"}}>
                                                                                    <input type={"checkbox"} checked={selectedDrugs.includes(sub.id)} onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            setSelectedDrugs([...selectedDrugs, sub.id])
                                                                                        } else {
                                                                                            setSelectedDrugs(selectedDrugs.filter((el) => el !== sub.id))
                                                                                        }
                                                                                    }} />
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </Col>
                                                        ))}
                                                    </React.Fragment>
                                                )}
                                            </Row>
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleSaveTreatmentProtocol()}>
                        Ολοκλήρωση
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default NewTreatmentProtocolModal
