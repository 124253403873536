import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './modalui.css';
import {useTranslation} from "react-i18next";

const ModalInstructions = (props) => {
    const [show, setShow] = useState(false);
    const [t] = useTranslation("common");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const {title, content} = props.data;
    return (
        <React.Fragment>
            <i onClick={handleShow}
               className="fas fa-question-circle mr-5 ml-2 text-primary modalTriggerIcon"
            ></i>
            <Modal className="themeModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body dangerouslySetInnerHTML={{__html: content}}/>

                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => handleClose()}>{t("basicModal.close")}</Button>
                    {/*<Button variant="primary" onClick={ () => handleClose() } >{t("basicModal.ok")}</Button>*/}
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default ModalInstructions;