import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import EditableTable from '../../../primer/Editable-Table';
import {deleteObjectFromArrayOfObjects, getObjectFromArrayOfObjects} from '../../../../_helpers/helperFunctions';
import TextArea from '../../../common/TextArea';
import {setSaveNewClient} from "../../../../_reducers/activitySlice";
import {
    resetNewNotes,
    setNewClient,
    setNewNotes
} from '../../../../_reducers/DataPanel/Clients/clientSlice';
import {toast} from 'react-toastify';
import {fetchClientsSettings} from '../../../../_apis/api';
import * as moment from "moment";
import {getCurrentUser} from '../../../../_helpers/commonFunctions';
import {useTranslation} from "react-i18next";

const currentDate = moment().format('DD/MM/YYYY');
const currentTime = moment().format('H:mm');
const currentUser = getCurrentUser();
const cUserName = currentUser?.firstName + " " + currentUser?.lastName;

const NewClientNotes = () => {
    const [t] = useTranslation('common');
    const notesColumns = [
        {
            name: "No",
            field: "no",
            width: "50px",
            editable: false,
        },
        {
            name: t('ClientsNewNotes.description'),
            field: "description",
            width: "200px",
            editable: true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsNewNotes.details'),
            field: "details",
            width: "400px",
            editable: true,
            inputType: {
                type: 'textarea',
            }
        },
        {
            name: t('ClientsNewNotes.date'),
            field: "date",
            editable: false,
            width: "100px",
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsNewNotes.time'),
            field: "time",
            editable: false,
            width: "80px",
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsNewNotes.lastEditedBy'),
            field: "lastEditedBy",
            width: "150px",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
    ]
    const dispatch = useDispatch();
    const CLIENTS_DATA = useSelector((state) => state.CLIENTS_DATA);
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const [tableColumns, setTableColumns] = useState(notesColumns.slice())
    const company = useSelector(state => state.COMPANY_DATA.company)
    const clientsSettings = CLIENTS_DATA.clientsSettings; //
    const clientsInfo = clientsSettings.clientsInfo;

    // It will hide or show the table columns for searchtable.
    const handleColumnShowHide = (field) => {
        let tempTc = tableColumns.slice();
        let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
        const index = tempTc.findIndex((item) => field === item.field);
        if (object.length > 0) {
            tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
            setTableColumns(tempTc);
        }
    }
    const handleOnChange = (e, type = "default", ddname = '') => {
        // Set if editing the form
        if (!ACTIVITY.saveNewClient) {
            dispatch(setSaveNewClient(true));
        }
        let name;
        let value;
        if (type === 'dd') {
            name = ddname;
            value = e.value;
        } else {
            name = e.target.name;
            value = e.target.value;
        }
        let newClient = Object.assign({}, CLIENTS_DATA.newClient);
        let newClientData = {};
        newClientData[name] = value;
        newClient = {...newClient, ...newClientData}
        dispatch(setNewClient(newClient));
    }

    useEffect(() => {
        dispatch(fetchClientsSettings({company: company.id, year: company.year.toString(), isActive: "active"}));
    }, [dispatch])

    return (
        <div className="new-client-general">
            <p className="text-muted"><i>{t('ClientsNewNotes.notes')}</i></p>
            {clientsInfo.length > 0 && clientsInfo.map((item, index) => {
                let notesData = CLIENTS_DATA.newClient.notes[0] ? CLIENTS_DATA.newClient.notes[0][item] : []
                let parentMydataActions = {
                    insert: async (e, data) => {
                        if (!ACTIVITY.saveNewClient) {
                            dispatch(setSaveNewClient(true));
                        }
                        let tempClientNotes = Object.assign({}, CLIENTS_DATA.newNotes[item]);
                        tempClientNotes[data.field] = data.value;
                        dispatch(setNewNotes({...CLIENTS_DATA.newNotes, [item]: tempClientNotes}));
                        if (tempClientNotes && (tempClientNotes.description || tempClientNotes.details)) {
                            let updatedNotes = Object.assign({}, tempClientNotes);
                            let oldNotes = CLIENTS_DATA.newClient.notes[0] ? CLIENTS_DATA.newClient.notes[0][item] : [];
                            if (!oldNotes) {
                                oldNotes = [];
                            }
                            if (oldNotes && oldNotes?.length) {
                                updatedNotes.no = oldNotes.length + 1;
                                updatedNotes.id = oldNotes.length + 1;
                            } else {
                                updatedNotes.no = 1;
                                updatedNotes.id = 1;
                            }
                            updatedNotes.date = currentDate;
                            updatedNotes.time = currentTime;
                            updatedNotes.lastEditedBy = cUserName;
                            // New Client update notes
                            let clientNotes = {...CLIENTS_DATA.newClient.notes[0]};
                            let newNote = true;

                            Object.keys(clientNotes).forEach((note) => {
                                if (note === item) {
                                    if (Array.isArray(clientNotes[note])) {
                                        if (!Object.isExtensible(clientNotes[note])) {
                                            clientNotes[note] = [...clientNotes[note]]; // Create a copy of the array
                                        }
                                        clientNotes[note].push(updatedNotes);
                                    }
                                    newNote = false;
                                }
                            })
                            if (newNote) {
                                clientNotes[item] = [];
                                clientNotes[item].push(updatedNotes);
                            }
                            dispatch(setNewClient({...CLIENTS_DATA.newClient, notes: [clientNotes]}));
                            dispatch(resetNewNotes());
                        }
                    },
                    update: async (data, id) => {
                        // Set if editing the form
                        if (!ACTIVITY.saveNewClient) {
                            dispatch(setSaveNewClient(true));
                        }
                        if (data.description && data.description === '') {
                            toast.error("Description is mandatory field.");
                        } else {
                            let clientNotes = {...CLIENTS_DATA.newClient.notes[0]};
                            let notes = CLIENTS_DATA.newClient.notes[0][item].slice();
                            notes.forEach((note, index) => {
                                if (note.id === id) {
                                    data.date = currentDate;
                                    data.time = currentTime;
                                    data.lastEditedBy = cUserName;
                                    notes[index] = {...note, ...data};
                                }
                            })
                            clientNotes[item] = notes;
                            // Update Notes Data
                            dispatch(setNewClient({...CLIENTS_DATA.newClient, notes: [clientNotes]}));
                        }
                    },
                    selected: () => {
                        console.log('selected mydata row clicked');
                    },
                    delete: async (id) => {
                        if (!ACTIVITY.saveNewClient) {
                            dispatch(setSaveNewClient(true));
                        }
                        if (id !== '' && Number.isInteger(id)) {
                            let clientNotes = {...CLIENTS_DATA.newClient.notes[0]};
                            Object.keys(clientNotes).forEach((note) => {
                                if (note === item) {
                                    if (Array.isArray(clientNotes[note])) {
                                        if (!Object.isExtensible(clientNotes[note])) {
                                            clientNotes[note] = [...clientNotes[note]]; // Create a copy of the array
                                        }
                                        clientNotes[note] = deleteObjectFromArrayOfObjects(clientNotes[note], id, 'id');
                                    }
                                }
                            })
                            dispatch(setNewClient({...CLIENTS_DATA.newClient, notes: [clientNotes]}));
                        }
                    }
                }
                return (
                    <EditableTable
                        tableName={"Client New Notes " + item}
                        key={Math.random() + index}
                        heading={item}
                        allowActions={true}
                        allowToggleColumns={true}
                        toggleColumns={handleColumnShowHide}
                        columns={tableColumns}
                        allowInsertRow={true}
                        enableNewRowCheckbox={false}
                        onUpdate={parentMydataActions}
                        data={notesData}
                    />
                )
            })
            }
            <TextArea
                className="mb-3 mt-3"
                rows="4"
                label={t('ClientsNewNotes.notes')}
                placeholder={t('ClientsNewNotes.addNotes')}
                name="notesNotes"
                value={CLIENTS_DATA.newClient.notesNotes}
                onChange={(e) => handleOnChange(e)}
            />
        </div>
    )
}

export default NewClientNotes;
