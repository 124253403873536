import React, {useState} from "react";
import {getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import {classicStyleBelowNavbar} from "../Statics";
import SearchTable from "../../../primer/Search-Table";

const SearchVisits = () => {
    const searchFilters = {
        filters: [
            {
                name: "amka",
                label: "ΑΜΚΑ Ασθενή",
                type: "input",
            },
        ],
        actions: [],
    }

    const searchColumns = [
        {
            name: "Αρ. επίσκεψης",
            field: "visitId",
            editable: false,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Αναγνωριστικό Ασθενή",
            field: "identifier",
            editable: false,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Ονοματεπώνυμο",
            field: "name",
            editable: false,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Ασφαλιστικός Φορέας",
            field: "socialInsurance",
            editable: false,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Ημ/νια έναρξης",
            field: "dateFrom",
            editable: false,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Ημ/νια λήξης",
            field: "dateTo",
            editable: false,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Κατάσταση Επίσκεψης",
            field: "status",
            editable: false,
            inputType: {
                type: "text",
            }
        },
        {
            name: "Χρεωμένη στο Όριο Επισκέψεων",
            field: "billed",
            editable: false,
            inputType: {
                type: "text",
            }
        }
    ]

    const [tableColumns, setTableColumns] = useState(searchColumns.slice());

    const handleColumnShowHide = (field) => {
        let tempTc = tableColumns.slice();
        let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
        const index = tempTc.findIndex((item) => field === item.field);
        if (object.length > 0) {
            tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
            setTableColumns(tempTc);
        }
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName={"Prescription Search Visits"}
                key={"Prescription Search Visits"}
                hideYearFilter={false}
                allowPagination={true}
                allowToggleColumns={true}
                showDummyData={false}
                columns={tableColumns}
                toggleColumns={handleColumnShowHide}
                allowSelectionCheckbox={false}
                data={[]}
                filtersConfig={searchFilters}
            />
        </div>
    )
}

export default SearchVisits
