import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getDefaultOptionByValue, isEmpty} from "../../../../_helpers/commonFunctions";
import moment from "moment/moment";
import axios from "axios";
import {getICCategoryOptions, getICTypeOptions, getInvoiceTypesOptions} from "../../../../_helpers/sharedmydata";
import Loader from "../../../primer/POPUP-COMPONENTS/Loader/PrimerLoader";
import {toast} from "react-toastify";
import {Badge, Col, Modal, Row, Spinner} from "react-bootstrap";
import Dropdown from "../../../primer/Dropdown";
import DateBox from "../../../primer/DateBox";
import Button from "react-bootstrap/Button";
import EditableTable from "../../../primer/Editable-Table";
import Input from "../../../common/Input";
import {useTranslation} from "react-i18next";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import {
    setSalesMyDataReportRequestData,
    setSalesMyDataReportTotals
} from "../../../../_reducers/DataPanel/Sales/salesSlice";

const SalesMydataReport = () => {
    const dispatch = useDispatch();
    const [t] = useTranslation('common');
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const requestData = useSelector((state) => state.SALES.salesMyDataReportRequestData);
    const totals = useSelector((state) => state.SALES.salesMyDataReportTotals);
    const [salesReportData, setSalesReportData] = useState([]);
    const [incrKey, setIncrKey] = useState(0);

    const [loading, setLoading] = useState(false);
    const invoiceTypes = getInvoiceTypesOptions();
    const icCategory = getICCategoryOptions();
    const icType = getICTypeOptions();
    const showFilterOptions = [{value: 'all', label: t('SalesMyData.table2.allDocuments')},{value: 'differences', label: t('SalesMyData.table2.onlyDifferences')}, {value: 'success', label: t('SalesMyData.table2.onlyTheSent')}, {value: 'failed', label: t('SalesMyData.table2.onlyTheFailed')}];
    const invoicesFromChannelOptions = [{value: 'all', label: t('SalesMyData.table2.allDocuments')},{value: 'myData', label: t('SalesMyData.table2.onlyFromMyData')}, {value: 'provider', label: t('SalesMyData.table2.onlyFromMyProvider')}];

    const [showClassificationsModal, setShowClassificationsModal] = useState(false);
    const [classificationsTableData, setClassificationsTableData] = useState([]);
    const [currentMark, setCurrentMark] = useState("");
    const [pageChanged, setPageChanged] = useState(false);
    const [sendClassificationLoading, setSendClassificationLoading] = useState(false);

    const handleClassificationClicked = (rowData) => {
        if(rowData?.classifications.length > 0) {
            setClassificationsTableData(rowData.classifications);
            setCurrentMark(rowData.mark ? rowData.mark : "");
        } else {
            setClassificationsTableData([]);
            setCurrentMark("");
            toast.error("Δεν βρέθηκαν χαρακτηρισμοί.");
        }
        setShowClassificationsModal(true);
    }
    const sendClassifications = () => {
        setSendClassificationLoading(true);
        let incomeClassificationsDetails = [];
        if(classificationsTableData?.length > 0){
            classificationsTableData.map(classification => {
                incomeClassificationsDetails.push({
                    "icls:lineNumber": classification.lineNumber,
                    "icls:incomeClassificationDetailData": {
                        "icls:classificationType": classification.classificationType,
                        "icls:classificationCategory" : classification.classificationCategory,
                        "icls:amount": classification.netPrice
                    },
                });
                return classification;
            })
        }
        let requestData = {
            company: company.id,
            "icls:invoiceMark": currentMark,
            "icls:invoicesIncomeClassificationDetails": incomeClassificationsDetails,
            reportType: "sales",
        }
        try {
            axios.post(process.env.REACT_APP_API_URL2 + `/sale/sendClassifications`, requestData, {
                headers: { 'Content-Type': 'application/json' }
            }).then(res => {
                setSendClassificationLoading(false);
                if(res.data.status === "200") {
                    toast.success(res.data.message);
                    fetchReport();
                } else {
                    toast.error(res.data.message);
                }
            })
        } catch (error) {
            setSendClassificationLoading(false);
            console.log(error);
            console.log(error?.response?.data?.errors);
        }
    }
    const parentMydataActions = {
        update: (data, id, rowIndex) => {
            let cloneClassifications = classificationsTableData.slice();
            if(cloneClassifications.length >= 0) {
                cloneClassifications = cloneClassifications.map((a) => Object.assign({}, a));
                cloneClassifications[rowIndex] = {...cloneClassifications[rowIndex], ...data};
            }
            setClassificationsTableData(cloneClassifications);
        },
        selected: () => {},
    }

    const columns = [
        {
            name: t('SalesMyData.table.date'),
            field: "date",
            width: "8%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: "MARK",
            field: "mark",
            width: "10%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: "Πελάτης",
            field: "clientName",
            width: "12%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: "ΑΦΜ Πελάτη",
            field: "vat",
            width: "8%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesMyData.table.invoiceType'),
            field: "myDataType",
            width: "12%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesMyData.table.number'),
            field: "preNumberAndNumber",
            width: "5%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesMyData.table.netValue'),
            field: "totalNetValue",
            width: "5%",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t('SalesMyData.table.vatAmount'),
            field: "totalVatValue",
            width: "5%",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t('SalesMyData.table.totalValue'),
            field: "totalAmount",
            width: "5%",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t('SalesMyData.table.erpStatus'),
            field: "myDataStatus",
            width: "7%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesMyData.table.myDataStatus'),
            field: "myDataSentStatus",
            width: "6%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: "Ενέργειες",
            field: "changeClassifications",
            width: "7%",
            editable: true,
            hiddenColumn: false,
            inputType: {
                config: {
                    disabled: false,
                    onClick: handleClassificationClicked,
                    text: "Εμφάνιση Χαρακτηρισμών",
                },
                type: "button-sm"
            },
        },
    ]

    const classificationsColumns = [
        {
            name: "Line Number",
            field: "lineNumber",
            width: "5%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: "Line Name",
            field: "lineName",
            width: "15%",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('ProductsNewGeneral.table.invoiceType'),
            field: "invoiceType",
            editable: true,
            disabled: true,
            width: "15%",
            inputType: {
                type: 'select',
                options: invoiceTypes,
            }
        },
        {
            name: t('ProductsNewGeneral.table.category'),
            field: "classificationCategory",
            editable: true,
            width: "15%",
            inputType: {
                type: 'select',
                options: icCategory,
            }
        },
        {
            name: t('ProductsNewGeneral.table.type'),
            field: 'classificationType',
            editable: true,
            width: "15%",
            inputType: {
                type: 'select',
                options: icType,
            }
        },
        {
            name: "Καθαρή Αξία",
            field: "netPrice",
            hiddenColumn: false,
            width: "10%",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
    ];
    const fetchReport = (forceRefresh = false) => {
        setLoading(true);
        let cloneReqData = {...requestData};
        cloneReqData.company = company.id;
        cloneReqData.year = String(company.year);
        dispatch(setSalesMyDataReportRequestData({...cloneReqData, page: 1, totalPages: 0}));
        let reqUrl = "/report/fetch-mydata-report";
        if (forceRefresh) {
            cloneReqData.page = 1;
            reqUrl = "/report/mydata-report";
        }
        let invTypes = [];
        if(cloneReqData.invoiceType.length > 0) {
            cloneReqData.invoiceType.forEach((obj) => {
                invTypes.push(obj.value);
            })
        }
        axios.post(process.env.REACT_APP_API_URL2 + reqUrl, {...cloneReqData, invoiceType: invTypes, page: requestData.page}, {
            headers: { "Content-Type": "application/json" }
        }).then((res) => {
            setLoading(false);
            if(res.data.status === "200") {
                if (res.data.data.data) {
                    let resData = [...res.data.data.data];
                    if(resData.length > 0) {
                        resData.forEach((row) => {
                            if(row.date) row.date = moment(row.date).format("DD/MM/YYYY");
                            if(row.preNumber && row.preNumber !== "noprefix") {
                                row.preNumberAndNumber = String(row.preNumber + " " + row.number);
                            } else {
                                row.preNumberAndNumber = row.number;
                            }
                            row.vatAmount = row.totalVatValue;
                        })
                    } else {
                        setSalesReportData([]);
                        dispatch(setSalesMyDataReportRequestData({...requestData, page: 1, totalPages: 0}));
                        toast.info("Δεν βρέθηκε καμία πώληση.");
                    }
                    let newRequestData = {...requestData};
                    if(res.data.data.totalPages) newRequestData = {...newRequestData, totalPages: res.data.data.totalPages};
                    if(res.data.totals) dispatch(setSalesMyDataReportTotals(res.data.totals));
                    if(res.data.filters) newRequestData = {...newRequestData, ...res.data.filters};
                    if(res.data.lastUpdate) newRequestData = {...newRequestData, lastUpdate: res.data.lastUpdate};
                    dispatch(setSalesMyDataReportRequestData(newRequestData));
                    setSalesReportData(resData);
                } else {
                    setSalesReportData([]);
                    dispatch(setSalesMyDataReportRequestData({...requestData, page: 1, totalPages: 0}));
                    toast.info("Δεν βρέθηκε καμία πώληση.");
                }
            } else {
                setSalesReportData([]);
                let zeroTotals = {...totals};
                for(let k in zeroTotals) {
                    zeroTotals[k] = 0;
                }
                dispatch(setSalesMyDataReportTotals(zeroTotals));
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }
    useEffect(() => {
        fetchReport();
    }, [])

    useEffect(() => {
        if (pageChanged) {
            setPageChanged(false);
            fetchReport();
        }
    }, [pageChanged])

    useEffect(() => {
        setIncrKey(incrKey + 1);
    }, [salesReportData])

    const handlePage = (str) => {
        if(str === "first") {
            dispatch(setSalesMyDataReportRequestData({...requestData, page: 1}));
            setPageChanged(true);
        } else if (str === "previous") {
            dispatch(setSalesMyDataReportRequestData({...requestData, page: requestData.page - 1}));
            setPageChanged(true);
        } else if (str === "next") {
            dispatch(setSalesMyDataReportRequestData({...requestData, page: requestData.page + 1}));
            setPageChanged(true);
        } else if (str === "last") {
            dispatch(setSalesMyDataReportRequestData({...requestData, page: requestData.totalPages}));
            setPageChanged(true);
        }
    }

    const handleFilterChange = (e, type, name) => {
        let cloneReqData = {...requestData};
        if (type === "datebox") {
            cloneReqData = {...cloneReqData, [name] : moment(e).format("DD/MM/YYYY")};
        } else if (type === "msdd") {
            cloneReqData = {...cloneReqData, [name] : e};
        } else if (type === "input") {
            cloneReqData = {...cloneReqData, [name] : e.target.value}
        } else if (type === "dropdown") {
            cloneReqData = {...cloneReqData, [name] : e.value};
        } else if (type === "ac") {
            cloneReqData = {...cloneReqData, [name] : e.row.name};
        }
        dispatch(setSalesMyDataReportRequestData(cloneReqData));
    }

    const formatNumber = (num) => {
        return num?.toFixed(2).replace(".", ",") + " €"
    }

    return (
        <React.Fragment>
            {loading && (<Loader/>)}
            <div hidden={loading}>
                <Row>
                    <Col md={2}>
                        <DateHelper
                            dateFromAndToFunction={(from, to) => dispatch(setSalesMyDataReportRequestData({
                                ...requestData,
                                date_from: from,
                                date_to: to
                            }))}
                        />
                    </Col>
                    <Col md={2}>
                        <DateBox
                            name="date_from"
                            selected={isEmpty(requestData.date_from) ? moment().toDate() : moment(requestData.date_from, "DD/MM/YYYY").toDate()}
                            label={t('SalesMyData.dateFrom')}
                            onChange={(e) => handleFilterChange(e, "datebox", "date_from")}
                            allowOtherYears={true}
                        />
                    </Col>
                    <Col md={2}>
                        <DateBox
                            name="date_to"
                            selected={isEmpty(requestData.date_to) ? moment().toDate() : moment(requestData.date_to, "DD/MM/YYYY").toDate()}
                            label={t('SalesMyData.dateTo')}
                            onChange={(e) => handleFilterChange(e, "datebox", "date_to")}
                            allowOtherYears={true}
                        />
                    </Col>
                    <Col md={3}>
                        {!isEmpty(requestData.lastUpdate) && (
                            <Badge bg={"primary"} className={"mt-3"}>
                                Τελευταία ανανέωση από το My Data: <br/>
                                {moment(requestData.lastUpdate).format("DD/MM/YYYY HH:mm:ss")}
                            </Badge>
                        )}
                    </Col>
                    <Col md={3}>
                        <br/>
                        <Button onClick={() => fetchReport(true)} className={"float-right"} disabled={loading}>Εφαρμογή
                            φίλτρων & αναζήτηση από MyData</Button>
                    </Col>
                </Row>
                <hr />
                <Row className={"mt-2"}>
                    <label className={"text-muted"}><i>Φίλτρα Αναζήτησης</i></label>
                    <Col md={3}>
                        <Dropdown
                            label={t('SalesMyData.invoiceType')}
                            name="invoiceType"
                            defaultValue={requestData.invoiceType}
                            key={Math.random()}
                            multiSelect={true}
                            enabledValue={true}
                            options={invoiceTypes}
                            onChange={(e) => handleFilterChange(e, "msdd", "invoiceType")}
                        />
                    </Col>
                    <Col md={2}>
                        <Input
                            type={"number"}
                            label={"Αριθμός Τιμολογίου από"}
                            name={"number_from"}
                            autoComplete={"off"}
                            value={requestData.number_from}
                            onChange={(e) => handleFilterChange(e, "input", "number_from")}
                        />
                    </Col>
                    <Col md={2}>
                        <Input
                            type={"number"}
                            label={"Αριθμός Τιμολογίου μέχρι"}
                            name={"number_to"}
                            autoComplete={"off"}
                            value={requestData.number_to}
                            onChange={(e) => handleFilterChange(e, "input", "number_to")}
                        />
                    </Col>
                    <Col md={2}>
                        <Input
                            label={"ΑΦΜ Πελάτη"}
                            name={"clientVAT"}
                            value={requestData.clientVAT}
                            onChange={(e) => handleFilterChange(e, "input", "clientVAT")}
                        />
                    </Col>
                    <Col md={3}>
                        <Dropdown
                            label={t('SalesMyData.showFilter')}
                            options={showFilterOptions}
                            defaultValue={requestData["showFilter"]}
                            value={requestData["showFilter"] ? getDefaultOptionByValue(showFilterOptions, requestData["showFilter"], "value", "label", "value") : ""}
                            enabledValue={true}
                            name="showFilter"
                            searchEnable={true}
                            onChange={(e) => handleFilterChange(e, "dropdown", "showFilter")}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <Dropdown
                            label={t('SalesMyData.showDocumentsFromChannel')}
                            options={invoicesFromChannelOptions}
                            defaultValue={requestData["showFilter"]}
                            value={requestData["invoicesFromChannel"] ? getDefaultOptionByValue(invoicesFromChannelOptions, requestData["invoicesFromChannel"], "value", "label", "value") : ""}
                            enabledValue={true}
                            name="invoicesFromChannel"
                            searchEnable={true}
                            onChange={(e) => handleFilterChange(e, "dropdown", "invoicesFromChannel")}
                        />
                    </Col>
                    <Col md={2}>
                        <Input
                            type={"number"}
                            label={"Συνολική αξία από"}
                            name={"totalSum_from"}
                            autoComplete={"off"}
                            value={requestData.totalSum_from}
                            onChange={(e) => handleFilterChange(e, "input", "totalSum_from")}
                        />
                    </Col>
                    <Col md={2}>
                        <Input
                            type={"number"}
                            label={"Συνολική αξία μέχρι"}
                            name={"totalSum_to"}
                            autoComplete={"off"}
                            value={requestData.totalSum_to}
                            onChange={(e) => handleFilterChange(e, "input", "totalSum_to")}
                        />
                    </Col>
                </Row>
                <Row className={"justify-content-end mb-2"}>
                    <Col md={4}>
                        <Button onClick={() => fetchReport()} className={"float-right"} disabled={loading}>Εφαρμογή φίλτρων</Button>
                    </Col>
                </Row>
                {requestData.totalPages > 0 && salesReportData.length > 0 && (
                    <React.Fragment>
                        <hr />
                        <div className={"d-flex justify-content-end mb-2"}>
                            <Button size={"sm"} className={"mr-1"} disabled={requestData.page === 1} onClick={() => handlePage("first")}>{t('SalesMyData.first')}</Button>
                            <Button size={"sm"} className={"mr-1"} disabled={requestData.page === 1} onClick={() => handlePage("previous")}>{t('SalesMyData.previous')}</Button>
                            <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1" bg="primary">{t('SalesMyData.page')} {requestData.page} / {requestData.totalPages}</Badge>
                            <Button size={"sm"} className={"mr-1"} disabled={requestData.page === requestData.totalPages} onClick={() => handlePage("next")}>{t('SalesMyData.next')}</Button>
                            <Button size={"sm"} className={"mr-1"} disabled={requestData.page === requestData.totalPages} onClick={() => handlePage("last")}>{t('SalesMyData.last')}</Button>
                        </div>
                        <EditableTable
                            key={"MyData Report" + incrKey}
                            tableName="MyData Report"
                            data={salesReportData}
                            allowPagination={false}
                            allowToggleColumns={true}
                            allowInsertRow={false}

                            allowSelectionCheckbox={false}
                            sendSelectedToMyData={false}
                            cancelSelectedToMyData={false}

                            enableNewRowCheckbox={true}
                            allowBulkActions={true}
                            actionButtons={{ print: false, delete: false, export: false, edit: false }}
                            allowActions={false}
                            columns={columns}
                            filtersConfig={["quick-edit", "delete"]}
                        />
                        <Row className="justify-content-end mt-3">
                            <Col md={4} className="d-flex justify-content-end">
                                <table className="table table-bordered mb-3">
                                    <tbody>
                                    <tr>
                                        <td style={{textAlign: "center"}}>{t('SalesMyData.pageTotals')}</td>
                                        <td style={{textAlign: "center"}}>{t('SalesMyData.TotalsERP')}</td>
                                        <td style={{textAlign: "center"}}>{t('SalesMyData.TotalsMyData')}</td>
                                        <td style={{textAlign: "center"}}>{t('SalesMyData.differences')}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">{t('SalesMyData.totalsCount')}</td>
                                        <td>{totals.totalCount}</td>
                                        <td>{totals.totalCountMyData}</td>
                                        <td>{Math.abs(totals.totalCount - totals.totalCountMyData)}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">{t('SalesMyData.totalNetValue')}</td>
                                        <td>{formatNumber(totals.totalNetValue)}</td>
                                        <td>{formatNumber(totals.totalNetValueMyData)}</td>
                                        <td>{formatNumber(Math.abs(totals.totalNetValueMyData - totals.totalNetValue))}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">{t('SalesMyData.totalVatAmount')}</td>
                                        <td>{formatNumber(totals.totalVatValue)}</td>
                                        <td>{formatNumber(totals.totalVatValueMyData)}</td>
                                        <td>{formatNumber(Math.abs(totals.totalVatValueMyData - totals.totalVatValue))}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">{t('SalesMyData.totalValue')}</td>
                                        <td>{formatNumber(totals.totalValue)}</td>
                                        <td>{formatNumber(totals.totalValueMyData)}</td>
                                        <td>{formatNumber(Math.abs(totals.totalValueMyData - totals.totalValue))}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <Row className="justify-content-end">
                            <Col md={4} className={"d-flex justify-content-end"}>
                                {Number(totals.totalValue.toFixed(2) - totals.totalValueMyData.toFixed(2)) === 0 ? (
                                    <p style={{ color: 'green' }}><b>{t('SalesMyData.discordOk')}</b></p>
                                ) : (
                                    <p style={{ color: 'red' }}><b>{t('SalesMyData.discord')}</b></p>
                                )}
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </div>

            <Modal className="themeModalBig" show={showClassificationsModal} onHide={() => setShowClassificationsModal(false)}>
                <Modal.Header>
                    <Modal.Title>{t('SalesMyData.table.classificationsModalTitle')}</Modal.Title>
                </Modal.Header>
                <Modal.Title style={{ fontStyle: 'italic', fontSize: '12px', marginTop: '5px', textAlign:"center" }}>
                    {t('SalesMyData.table.classificationsModalDesc')}
                </Modal.Title>
                <Modal.Body>
                    <EditableTable
                        key={Math.random() + "table"}
                        tableName="MyData Report Classifications"
                        data={classificationsTableData}
                        allowActions={false}
                        onUpdate={parentMydataActions}
                        columns={classificationsColumns}
                        allowBulkActions={true}
                        actionButtons={{ print: false, delete: false, export: false, edit: false }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={ () => setShowClassificationsModal(false) }>{t('SalesMyData.table.classificationsModalClose')}</Button>
                    <Button variant="primary" onClick={() => sendClassifications()} disabled={sendClassificationLoading}>
                        {t('SalesMyData.table.classificationsModalSave')}
                        {sendClassificationLoading && <Spinner animation="border" variant="dark" />}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default SalesMydataReport;
