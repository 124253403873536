import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {Badge, Col, Row} from "react-bootstrap";
import DateBox from "../../../primer/DateBox";
import Button from "react-bootstrap/Button";
import moment from "moment";
import {useTranslation} from "react-i18next";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import axios from "axios";
import "jspdf-autotable";
import {checkPermission, isEmpty, providerChannelOptions,} from "../../../../_helpers/commonFunctions";
import EditableTable from "../../../primer/Editable-Table";
import {fetchBuysGeneralSettings} from "../../../../_apis/api";
import {
    setBuysMonthlyTurnoverReportData,
    setBuysMonthlyTurnoverReportRequestData
} from "../../../../_reducers/DataPanel/Buys/buysSlice";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import {classicStyleBelowNavbar} from "../Statics";

const BuysMonthlyTurnvover = () => {
    const [t, i18n] = useTranslation('common');
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const reportData = useSelector((state) => state.BUYS.buysMonthlyTurnoverReportData);
    const requestData = useSelector((state) => state.BUYS.buysMonthlyTurnoverReportRequestData);
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const [loading, setLoading] = useState(false);
    const [incrKey, setIncrKey] = useState(0);
    const [pageChanged, setPageChanged] = useState(false);

    const columns = [
        {
            name: "No",
            field: "no",
            editable: false,
            hiddenColumn: false,
            width: "3%",
            inputType: {
                type: "text",
            },
        },{
            name: "Προμηθευτής",
            shortcut: "Πελάτης",
            field: "supplierName",
            editable: false,
            hiddenColumn: false,
            width: "18%",
            inputType: {
                type: "text",
            },
        },{
            name: "Ιανουάριος",
            shortcut: "Ιαν",
            field: "jan",
            editable: false,
            hiddenColumn: false,
            width: "6%",
            inputType: {
                type: "text",
            },
        },{
            name: "Φεβρουάριος",
            shortcut: "Φεβ",
            field: "feb",
            editable: false,
            hiddenColumn: false,
            width: "6%",
            inputType: {
                type: "text",
            },
        },{
            name: "Μάρτιος",
            shortcut: "Μαρ",
            field: "mar",
            editable: false,
            hiddenColumn: false,
            width: "6%",
            inputType: {
                type: "text",
            },
        },{
            name: "Απρίλιος",
            shortcut: "Απρ",
            field: "apr",
            editable: false,
            hiddenColumn: false,
            width: "6%",
            inputType: {
                type: "text",
            },
        },{
            name: "Μάιος",
            shortcut: "Μάι",
            field: "may",
            editable: false,
            hiddenColumn: false,
            width: "6%",
            inputType: {
                type: "text",
            },
        },{
            name: "Ιούνιος",
            shortcut: "Ιουν",
            field: "jun",
            editable: false,
            hiddenColumn: false,
            width: "6%",
            inputType: {
                type: "text",
            },
        },{
            name: "Ιούλιος",
            shortcut: "Ιουλ",
            field: "jul",
            editable: false,
            hiddenColumn: false,
            width: "6%",
            inputType: {
                type: "text",
            },
        },{
            name: "Αύγουστος",
            shortcut: "Αυγ",
            field: "aug",
            editable: false,
            hiddenColumn: false,
            width: "6%",
            inputType: {
                type: "text",
            },
        },{
            name: "Σεπτέμβριος",
            shortcut: "Σεπ",
            field: "sep",
            editable: false,
            hiddenColumn: false,
            width: "6%",
            inputType: {
                type: "text",
            },
        },{
            name: "Οκτώβριος",
            shortcut: "Οκτ",
            field: "oct",
            editable: false,
            hiddenColumn: false,
            width: "6%",
            inputType: {
                type: "text",
            },
        },{
            name: "Νοέμβριος",
            shortcut: "Νοε",
            field: "nov",
            editable: false,
            hiddenColumn: false,
            width: "6%",
            inputType: {
                type: "text",
            },
        },{
            name: "Δεκέμβριος",
            shortcut: "Δεκ",
            field: "dec",
            editable: false,
            hiddenColumn: false,
            width: "6%",
            inputType: {
                type: "text",
            },
        },{
            name: "Σύνολο",
            shortcut: "Σύνολο",
            field: "sTotal",
            editable: false,
            hiddenColumn: false,
            width: "8%",
            inputType: {
                type: "text",
            },
        },
    ];

    useEffect(() => {
        dispatch(fetchBuysGeneralSettings({ company: company.id }));
        loadInitialSuppliersListData();
        if(isEmpty(requestData.date_to) && isEmpty(requestData.date_from)) {
            dispatch(setBuysMonthlyTurnoverReportRequestData({...requestData, date_from: `01/01/${company.year}`, date_to: `31/12/${company.year}`}));
        }
    }, []);

    useEffect(() => {
        setTableColumns(columns.slice());
    }, [i18n.language])

    useEffect(() => {
        if (pageChanged) {
            setPageChanged(false);
            fetchReport(true);
        }
    }, [pageChanged])

    const [suppliersList, setSuppliersList] = useState([]);
    const providerChannelOptionsDropdown = providerChannelOptions();
    providerChannelOptionsDropdown.shift();
    const [tableColumns, setTableColumns] = useState(columns.slice());

    const onChangeSearch = (keyword, setData) => {
        if (String(keyword).length >= 2) {
            loadSuppliersListData(keyword, setData);
        }
    }

    const loadInitialSuppliersListData = () => {
        axios.post(process.env.REACT_APP_API_URL2 + `/supplier/list-supplier`, {company: company.id}, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                if (res.data.data) {
                    let filteredData = res.data.data;
                    setSuppliersList(filteredData);
                } else {
                    setSuppliersList([]);
                }
            } else {
                setSuppliersList([]);
            }
        }).catch((e) => {
            console.log(e);
        })
    }
    const loadSuppliersListData = (name, setData) => {
        axios.post(process.env.REACT_APP_API_URL2 + `/supplier/list-supplier`, {name: name, company: company.id}, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                if (res.data.data) {
                    setData(res.data.data);
                } else {
                    setData([]);
                }
            } else {
                setData([]);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const supplierDropdownColumns = [
        {
            label: t('BuysBalanceSheet.table3.supplierName'),
            name: "name",
        },
        {
            label: t('BuysBalanceSheet.table3.vatNumber'),
            name: "vatNumber",
        },
        {
            label: t('BuysBalanceSheet.table3.phoneNumber'),
            name: "phone",
        },
    ]

    const handleOnChange = (e, type, name) => {
        if (type === "datebox") {
            if (moment(e).isValid()) {
                dispatch(setBuysMonthlyTurnoverReportRequestData({...requestData, [name] : moment(e).format("DD/MM/YYYY")}))
            } else {
                dispatch(setBuysMonthlyTurnoverReportRequestData({...requestData, [name] : ""}));
            }
        } else if (type === "dropdown") {
            dispatch(setBuysMonthlyTurnoverReportRequestData({...requestData, [name] : e}));
        } else if (type === "acdd") {
            dispatch(setBuysMonthlyTurnoverReportRequestData({...requestData, [name] : e.row.name}));
        } else if (type === "input") {
            dispatch(setBuysMonthlyTurnoverReportRequestData({...requestData, [name] : e.target.value}));
        }
    }

    const fetchReport = (fromPagination = false) => {
        setLoading(true);
        let newData = {
            ...requestData,
            company: company.id,
            year: company.year,
        }
        if (!fromPagination) newData.page = 1;
        axios.post(process.env.REACT_APP_API_URL2 + `/report/buys-monthly-turnover-report`, newData, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                if (res.data.data.length === 0) {
                    toast.info("Δεν βρέθηκαν αποτελέσματα.");
                    dispatch(setBuysMonthlyTurnoverReportData({totalPages: 0, data: [], totalPayment: 0}));
                } else {
                    if (res.data.data.data.length > 0) {
                        res.data.data.data.forEach((el, i) => {
                            el.no = 20*Number(res.data.data.currentPage - 1) + i + 1;
                        })
                        dispatch(setBuysMonthlyTurnoverReportData({totalPages: res.data.data.totalPages, data: [...res.data.data.data], totalPayment: res.data.generalTotal}));
                        setIncrKey(incrKey + 1);
                        if (!fromPagination) {
                            dispatch(setBuysMonthlyTurnoverReportRequestData({...requestData, page: 1}));
                            toast.success("Τα δεδομένα ανανεώθηκαν.", {autoClose: 1000});
                        }
                    }
                }
            } else {
                toast.error("Σφάλμα κατά την αποστολή αιτήματος");
                console.log(res.data);
            }
        }).catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    const toFirstPage = () => {
        dispatch(setBuysMonthlyTurnoverReportRequestData({...requestData, page: 1}));
        setPageChanged(true);
    }
    const toNextPage = () => {
        dispatch(setBuysMonthlyTurnoverReportRequestData({...requestData, page: requestData.page + 1}));
        setPageChanged(true);
    }
    const toPreviousPage = () => {
        dispatch(setBuysMonthlyTurnoverReportRequestData({...requestData, page: requestData.page - 1}));
        setPageChanged(true);
    }
    const toLastPage = () => {
        dispatch(setBuysMonthlyTurnoverReportRequestData({...requestData, page: reportData.totalPages}));
        setPageChanged(true);
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row className={"mb-2"}>
                <Col md={2}>
                    <DateHelper
                        dateFromAndToFunction={(from, to) => {
                            dispatch(setBuysMonthlyTurnoverReportRequestData({...requestData, date_from: from, date_to: to}));
                        }}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        name="date_from"
                        selected={isEmpty(requestData.date_from) ? moment().toDate() : moment(requestData.date_from, "DD/MM/YYYY").toDate()}
                        label={t('General.dateFrom')}
                        onChange={(e) => handleOnChange(e, "datebox", "date_from")}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        name="date_to"
                        selected={isEmpty(requestData.date_to) ? moment().toDate() : moment(requestData.date_to, "DD/MM/YYYY").toDate()}
                        label={t('General.dateTo')}
                        onChange={(e) => handleOnChange(e, "datebox", "date_to")}
                    />
                </Col>
                <Col md={6}>
                    <AdvancedCombobox
                        name="supplierName"
                        ACName="Buys Supplier" // do not change name
                        className="w-100 ac-select-dropdown"
                        searchableDropdownEnable={false}
                        defaultValue={requestData.supplierName}
                        label={t("General.supplier")}
                        data={suppliersList}
                        onChange={(e) => handleOnChange(e, "acdd", "supplierName")}
                        resetFilter={() => dispatch(setBuysMonthlyTurnoverReportRequestData({...requestData, supplierName: ""}))}
                        onChangeSearch={onChangeSearch}
                        excludeColumns={[]}
                        columns={supplierDropdownColumns}
                        editEnable={false}
                        deleteEnable={false}
                        searchTableEnable={false}
                        showTableOptionOnSelect={true}
                        placeholder={t("Placeholders.selectSupplier")}
                        required={true}
                        showInsertAddButton={true}
                        hideSaveButton={true}
                        hideInsertNewRow={true}
                        inputFieldClasses="form-control"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12} className={"ml-auto"}>
                    <Button onClick={() => fetchReport()} className="btn btn-primary float-right" disabled={loading}>
                        {t('General.applyFilter')}
                    </Button>
                </Col>
            </Row>
            <hr style={{color: "gray", height: 1}}/>
            {reportData.data.length > 0 ? (
                <Row>
                    <Row>
                        <h3
                            style={{
                                textAlign: "center",
                                alignItems: "center",
                                fontWeight: "bold",
                            }}
                        >
                            {t('TabsText.buys-monthly-turnover')}
                        </h3>
                    </Row>
                    {reportData.totalPages > 0 && (
                        <>
                            <div className="d-flex justify-content-end mb-2">
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => toFirstPage()}
                                    disabled={requestData.page === 1 || loading}
                                >
                                    {t('SalesBalanceSheet.first')}
                                </Button>
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => toPreviousPage()}
                                    disabled={requestData.page === 1 || loading}
                                >
                                    {t('SalesBalanceSheet.previous')}
                                </Button>
                                <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1"
                                       bg="primary">{t('SalesBalanceSheet.page')} {requestData.page} / {reportData.totalPages}</Badge>
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => toNextPage()}
                                    disabled={requestData.page === reportData.totalPages || loading}
                                >
                                    {t('SalesBalanceSheet.next')}
                                </Button>
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => toLastPage()}
                                    disabled={requestData.page === reportData.totalPages || loading}
                                >
                                    {t('SalesBalanceSheet.last')}
                                </Button>
                            </div>
                        </>
                    )}
                    <EditableTable
                        key={"Buys Monthly Turnover" + incrKey}
                        id="buysmonthlyTurnoverReport"
                        tableName="Buys Monthly Turnover"
                        data={reportData.data}
                        allowPagination={false}
                        filters={requestData}
                        allowToggleColumns={true}
                        allowInsertRow={false}
                        enableNewRowCheckbox={false}
                        allowBulkActions={true}
                        pageTotals={true}
                        editRedirectAllowed={true}
                        reportExcelPrint={true}
                        company={company}
                        totalNetValueTrue={false}
                        totalVatAmountTrue={false}
                        totalValueTrue={false}
                        totalPaymentAmountTrue={true}
                        totalPaymentAmount={reportData.totalPayment}
                        columns={tableColumns}
                        allowActions={false}
                        allowPrintResults={checkPermission("buys-reports/buysmonthlyturnover-print", permissionsData) === true}
                    />
                </Row>
            ) : (
                <Row>
                    <h3 style={{textAlign: "center", alignItems: "center"}}>
                        {t('General.noData')}
                    </h3>
                </Row>
            )}
        </div>
    )
}

export default BuysMonthlyTurnvover;
