import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row} from "react-bootstrap";
import {getDefaultOptionByValue, getOptionsByData, isEmpty} from "../../../../_helpers/commonFunctions";
import Dropdown from "../../../primer/Dropdown";
import {
    fetchAccountingAdvancedTypes,
    fetchAccountingPaymentTypes,
    fetchBankAccounts,
    fetchPOS
} from "../../../../_apis/api";
import {useTranslation} from "react-i18next";
import {setNewAccountingClosure} from "../../../../_reducers/DataPanel/Accounting/accountingSlice";
import moment from "moment/moment";
import DateBox from "../../../primer/DateBox";
import HourPicker from "../../../primer/HourPicker";
import Input from "../../../common/Input";
import {setSaveNewAccountingClosure} from "../../../../_reducers/activitySlice";
import TextArea from "../../../common/TextArea";
import Alert from "react-bootstrap/Alert";

const AccountingClosure = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const ACCOUNTING_DATA = useSelector((state) => state.ACCOUNTING_DATA);
    const CASH_DATA = useSelector((state) => state.CASH_DATA);
    const installations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const newAccountingClosure = useSelector((state) => state.ACCOUNTING_DATA.newAccountingClosure);
    const documentTypesOptions = getOptionsByData(ACCOUNTING_DATA.accountingAdvancedTypes, "_id", "name");
    const paymentTypeOptions = getOptionsByData(ACCOUNTING_DATA.paymentTypes, "_id", "name");
    const installationOptions = getOptionsByData(installations, "installationMasterId", "address");
    const [hidePosBankField, setHidePosBankField] = useState(true);
    const [posBankFieldOptions, setPosBankFieldOptions] = useState([]);
    const posOptions = getOptionsByData(CASH_DATA.posList, '_id', 'name');
    const bankOptions = getOptionsByData(CASH_DATA.bankAccounts, '_id', 'name');
    const selectedPaymentType = getDefaultOptionByValue(paymentTypeOptions, newAccountingClosure.paymentType, 'value', 'label', 'value');

    const requestData2 = {
        company: company.id,
        year: company.year.toString(),
        isActive: "active"
    }

    useEffect(() => {
        dispatch(fetchAccountingPaymentTypes(company));
        dispatch(fetchPOS(requestData2));
        dispatch(fetchBankAccounts(requestData2));
        dispatch(fetchAccountingAdvancedTypes(company.id));

        if (newAccountingClosure.paymentType) {
            const filter = ACCOUNTING_DATA.paymentTypes.filter((el) => el._id === newAccountingClosure.paymentType);
            if (filter.length > 0) {
                let paymentType = filter[0];
                if (paymentType.type === "pos") {
                    setPosBankFieldOptions(posOptions);
                    setHidePosBankField(false);
                }
                if (paymentType.type === "bank-account") {
                    setPosBankFieldOptions(bankOptions);
                    setHidePosBankField(false);
                }
                if (paymentType.type === "cash" || paymentType.type === "credit" || paymentType.type === "cheque") {
                    setPosBankFieldOptions([]);
                    setHidePosBankField(true);
                }
            }
        }
    }, [])

    const handleOnChange = (e, type, name) => {
        if (!ACTIVITY.saveNewAccountingClosure) dispatch(setSaveNewAccountingClosure(true));

        const newRecordData = structuredClone(newAccountingClosure);
        if (type === "dd") {
            if (name === "documentType") {
                newRecordData.documentType = e.value;
                newRecordData.documentTypeName = e.label;
            } else if (name === "installation") {
                newRecordData.installation = e.value;
            } else if (name === "paymentType") {
                if (newRecordData.paymentType !== e.value) {
                    newRecordData.bankAccount = "";
                    newRecordData.bankAccountName = "";
                }
                newRecordData.paymentType = e.value;
                newRecordData.paymentTypeName = e.label;
                const ptType = ACCOUNTING_DATA.paymentTypes?.find((el) => el._id === newRecordData.paymentType);
                if (ptType?.type === "pos") {
                    setPosBankFieldOptions(posOptions);
                    setHidePosBankField(false);
                } else if (ptType?.type === "bank-account") {
                    setPosBankFieldOptions(bankOptions);
                    setHidePosBankField(false);
                } else {
                    setPosBankFieldOptions([]);
                    setHidePosBankField(true);
                }
            } else if (name === "bankAccount") {
                newRecordData.bankAccount = e.value;
                newRecordData.bankAccountName = e.label;
            }
        } else if (type === "dp" || type === "tp") {
            newRecordData[name] = e;
        } else if (type === "input") {
            newRecordData[name] = e.target.value;
        }

        dispatch(setNewAccountingClosure(newRecordData));
    }

    return (
        <React.Fragment>
            <Row>
                <Col md={3}>
                    <Dropdown
                        label={t('SalesNew.DocumentType')}
                        key={Math.random()}
                        defaultValue={getDefaultOptionByValue(documentTypesOptions, newAccountingClosure.documentType, 'value', 'label', 'value')}
                        required={true}
                        searchEnable={true}
                        name="documentType"
                        options={documentTypesOptions}
                        onChange={(e) => handleOnChange(e, 'dd', 'documentType')}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                    <DateBox
                        classes="w-100"
                        label={t('SalesNew.Date')}
                        name="date"
                        selected={moment(newAccountingClosure.date).isValid() ? new Date(newAccountingClosure.date) : null}
                        allowOlderDate={true}
                        allowOlderDateValue={"999999"}
                        onChange={(e) => handleOnChange(e, 'dp', 'date')}
                    />
                    {isEmpty(newAccountingClosure.date) && <div className={"text-danger"}>Απαιτείται Ημερομηνία.</div> }
                </Col>
                <Col md={1}>
                    <HourPicker
                        label={t('SalesNew.time')}
                        selected={moment(newAccountingClosure.time, "HH:mm").toDate()}
                        onChange={(e) => handleOnChange(e, "tp", "time")}
                    />
                </Col>
                <Col md={2}>
                    <Dropdown
                        label={t("General.installation")}
                        options={installationOptions}
                        value={getDefaultOptionByValue(installationOptions, newAccountingClosure.installation, 'value', 'label', 'value')}
                        enabledValue={true}
                        searchEnable={true}
                        required={true}
                        name="installation"
                        className={"mb-0"}
                        onChange={(e) => handleOnChange(e, 'dd', 'installation')}
                    />
                    {ACTIVITY.saveNewAccountingClosure && isEmpty(newAccountingClosure.installation) &&
                        <div className="text-danger">Η εγκατάσταση είναι υποχρεωτική.</div>}
                </Col>
                <Col md={1}>
                    <Input
                        className="mb-3 w-100"
                        label={t("General.preNumber")}
                        name="preNumber"
                        type="text"
                        value={newAccountingClosure.preNumber}
                        placeholder={t("General.preNumber")}
                        onChange={(e) => handleOnChange(e, "input", "preNumber")}
                        disabled={false}
                    />
                </Col>
                <Col sm="12" md="1">
                    <Input
                        className="mb-3 w-100"
                        label={t("General.number")}
                        name="number"
                        type="number"
                        value={newAccountingClosure.number}
                        placeholder={t("General.number")}
                        onChange={(e) => handleOnChange(e, "input", "number")}
                        disabled={false}
                    />
                    {ACTIVITY.saveNewAccountingClosure && isEmpty(newAccountingClosure.number) &&
                        <div className="text-danger">{t("Required.number")}</div>}
                </Col>
                <Col sm="12" md={hidePosBankField ? "5" : "3"}>
                    <Dropdown
                        label={t('SalesNew.paymentType.paymentType')}
                        options={paymentTypeOptions}
                        value={Object.keys(selectedPaymentType).length !== 0 ? selectedPaymentType : ''}
                        enabledValue={true}
                        name="paymentType"
                        searchEnable={true}
                        onChange={(e) => handleOnChange(e, 'dd', 'paymentType')}
                    />
                </Col>
                {!hidePosBankField &&
                    <Col sm="12" md="2">
                        <Dropdown
                            label={t('SalesNew.posBankAccount')}
                            options={posBankFieldOptions}
                            defaultValue={getDefaultOptionByValue(posBankFieldOptions, newAccountingClosure.bankAccount, 'value', 'label', 'value')}
                            enabledValue={true}
                            required={!hidePosBankField}
                            name="bankAccount"
                            searchEnable={true}
                            onChange={(e) => handleOnChange(e, 'dd', 'bankAccount')}
                        />
                        {ACTIVITY.saveNewAccountingClosure && !hidePosBankField && isEmpty(newAccountingClosure.bankAccount) &&
                            <div className="text-danger">{t('SalesNew.posBankAccountRequired')}</div>}
                    </Col>
                }
            </Row>
            <Row>
                <Col md={12}>
                    <Input
                        className="mb-3 w-100"
                        label={t("General.reason")}
                        name="reason"
                        type="text"
                        required={false}
                        value={newAccountingClosure.reason}
                        placeholder={t("Placeholders.reason")}
                        onChange={(e) => handleOnChange(e, "input", "reason")}
                        disabled={false}
                        autoComplete={"off"}
                    />
                </Col>
            </Row>
            <Row className={"mb-3"}>
                <Col md={12}>
                    <Alert variant={"secondary"}>
                        Παρακαλώ συμπληρώστε τους κωδικούς ή τα προθέματα των λογαριασμών διαχωρισμένα με κόμμα (,) που επιθημείτε να κλείσετε.
                    </Alert>
                    <TextArea
                        name={"closingAccounts"}
                        label={"Κωδικοί λογαριασμών"}
                        value={newAccountingClosure.closingAccounts}
                        onChange={(e) => handleOnChange(e, "input", "closingAccounts")}
                    />
                </Col>
                <Col md={12}>
                    <Input
                        name={"closingAt"}
                        label={"Κλείσιμο σε λογαριασμό"}
                        value={newAccountingClosure.closingAt}
                        onChange={(e) => handleOnChange(e, "input", "closingAt")}
                        autoComplete={"off"}
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default AccountingClosure
