import {
    PRODUCT_PATHS,
    SALES_PATHS,
    CLIENT_PATHS,
    CASH_REGISTERS_PATHS,
    SETTINGS_PATHS, ACCOUNTING_PATHS, SUPPLIER_PATHS,
    BUYS_PATHS, PAYROLL_PATHS,
} from "../../../../_config/webPaths";

// products path config
export const productsPathConfig = [
    {
        id: "products",
        title: "ProductsSideBar.Products.title",
        key: "products",
        permission: "products-products",
        linkInfo: [
            {
                key: "products-new",
                id: "products-new",
                name: "ProductsSideBar.Products.new",
                link: PRODUCT_PATHS.NEW_PRODUCT,
                permission: "products-products/new-read",
            },
            {
                key: "products-search",
                id: "products-search",
                name: "ProductsSideBar.Products.search",
                link: PRODUCT_PATHS.PRODUCTS_SEARCH,
                permission: "products-products/search-read",
            },
        ],
    },

    {
        key: "productMovements",
        id: "productsMovement",
        title: "ProductsSideBar.Movements.title",
        permission: "products-productsmovements",
        linkInfo: [
            {
                key: "productMovements-new",
                id: "product-movements-new",
                name: "ProductsSideBar.Movements.new",
                link: PRODUCT_PATHS.NEW_PRODUCT,
                permission: "products-productsmovements/new-read",
            },
            {
                key: "productMovements-search",
                id: "product-movements-search",
                name: "ProductsSideBar.Movements.search",
                permission: "products-productsmovements/search-read",
                link: [
                    {
                        key: "product-movements-new",
                        id: "product-movements-new",
                        name: "New",
                        link: PRODUCT_PATHS.NEW_PRODUCT_MOVEMENT,
                        permission: "products-productsmovements/new-read",
                    },
                    {
                        key: "product-movements-search",
                        id: "product-movements-search",
                        name: "Search",
                        link: PRODUCT_PATHS.PRODUCT_MOVEMENT_SEARCH,
                        permission: "products-productsmovements/search-read",
                    },
                ],
            },
        ],
    },

    {
        key: "products-priceList",
        id: "pricelist",
        title: "ProductsSideBar.List.title",
        permission: "products-pricelist",
        linkInfo: [
            {
                key: "products-priceList-new",
                id: "product-pricelist-new",
                name: "ProductsSideBar.List.new",
                link: PRODUCT_PATHS.NEW_PRICE_LIST,
                permission: "products-pricelist/new-general-read"
            },
            {
                key: "products-priceList-search",
                id: "pricelist-search",
                name: "ProductsSideBar.List.search",
                permission: "products-pricelist/search-read",
                link: [
                    {
                        key: "products-priceList-new",
                        id: "product-pricelist-new",
                        name: "New",
                        link: PRODUCT_PATHS.NEW_PRICE_LIST,
                        permission: "products-pricelist/new-general-read"
                    },
                    {
                        key: "products-priceList-search",
                        id: "pricelist-search",
                        name: "Search",
                        link: PRODUCT_PATHS.PRICE_LIST_SEARCH,
                        permission: "products-pricelist/search-read"
                    },
                ],
            },
        ],
    },

    {
        key: "products-reports",
        id: "reports",
        title: "ProductsSideBar.Reports.title",
        permission: "products-reports",
        linkInfo: [
            {
                key: "products-reports-balanceSheet",
                id: "product-balancesheet-reports",
                name: "ProductsSideBar.Reports.balanceSheet",
                link: PRODUCT_PATHS.BALANCE_SHEET,
                permission: "products-reports/balancesheet-read",
            },
            {
                key: "products-reports-productMovements",
                id: "product-movments-reports",
                name: "ProductsSideBar.Reports.productMovements",
                link: PRODUCT_PATHS.PRODUCT_MOVEMENTS,
                permission: "products-reports/productmovements-read",
            },
        ],
    },
    {
        key: "products-settings",
        id: "settings",
        title: "ProductsSideBar.Settings.title",
        permission: "products-settings",
        linkInfo: [
            {
                key: "products-settings-productCategories",
                id: "products-settings-product-categories",
                name: "ProductsSideBar.Settings.productCategories",
                link: PRODUCT_PATHS.PRODUCT_CATEGORIES,
                permission: "products-settings/productcategories-read"
            },
        ],
    },
];

export const salesPathConfig = [
    {
        key: "sales-sales",
        id: "sales",
        title: "SalesSideBar.Sales.title",
        permission: "sales-sales",
        linkInfo: [
            {
                key: "sales-new",
                id: "sales-new",
                name: "SalesSideBar.Sales.new",
                link: SALES_PATHS.NEW_SALES,
                permission: "sales-sales/new-read"
            },
            {
                key: "sales-search",
                id: "sales-search",
                name: "SalesSideBar.Sales.search",
                link: SALES_PATHS.SEARCH_SALES,
                permission: "sales-sales/search-read"
            },
        ],
    },
    {
        key: "client-payments",
        id: "client-payments",
        title: "SalesSideBar.ClientPayments.title",
        permission: "sales-clientpayments",
        linkInfo: [
            {
                key: "client-payments-new-general-write",
                id: "new-client-payments",
                name: "SalesSideBar.ClientPayments.new",
                link: SALES_PATHS.NEW_CLIENT_PAYMENT,
                permission: "sales-clientpayments/new-read",
            },
            {
                key: "client-payments-search-search",
                id: "search-client-payments",
                name: "SalesSideBar.ClientPayments.search",
                link: SALES_PATHS.SEARCH_CLIENT_PAYMENTS,
                permission: "sales-clientpayments/search-read"
            },
            {
                key: "primer-client-payments",
                id: "primer-client-payments",
                name: "Μαζ. Εισαγωγή",
                link: SALES_PATHS.PRIMER_CLIENT_PAYMENTS,
                permission: "sales-clientpayments/primer-read"
            },
            {
                key: "emron-client-payments",
                id: "emron-client-payments",
                name: "Μαζ. Εισαγωγή (E)",
                link: SALES_PATHS.EMRON_CLIENT_PAYMENTS,
                permission: "sales-clientpayments/emron-read",
            }
        ],
    },
    {
        key: "send-my-data-data",
        id: "my-data",
        title: "SalesSideBar.MyData.title",
        permission: "sales-mydata",
        linkInfo: [
            {
                key: "send-my-data-report",
                id: "mydata-report",
                name: "SalesSideBar.MyData.report",
                link: SALES_PATHS.MY_DATA_REPORT,
                permission: "sales-mydata/mydatareport-read",
            },
        ],
    },
    {
        key: "sales-reports",
        id: "reports",
        title: "SalesSideBar.Reports.title",
        permission: "sales-reports",
        linkInfo: [
            {
                key: "sales-report-balance-sheet",
                id: "sales-balance-sheet",
                name: "SalesSideBar.Reports.salesBalanceSheet",
                link: SALES_PATHS.SALES_BALANCE_SHEET,
                permission: "sales-reports/salesbalancesheet-read",
            },
            {
                key: "sales-reports-client-payment-report",
                id: "client-payments-report",
                name: "SalesSideBar.Reports.clientPaymentReport",
                link: SALES_PATHS.CLIENT_PAYMENT_REPORT,
                permission: "sales-reports/clientpaymentreport-read"
            },
            {
                key: "sales-reports-graphic-report",
                id: "sales-graphic-report",
                name: "SalesSideBar.Reports.salesGraphicReport",
                link: SALES_PATHS.SALES_GRAPHIC_REPORT,
                permission: "sales-reports/salesgraphicreport-read",
            }
        ],
    },
    {
        key: "sales-settings",
        id: "settings",
        title: "SalesSideBar.Settings.title",
        permission: "sales-settings",
        linkInfo: [
            {
                key: "sales-settings-general",
                id: "sales-general-settings",
                name: "SalesSideBar.Settings.SalesGeneralSettings",
                link: SALES_PATHS.GENERAL_SETTINGS,
                permission: "sales-settings/salesgeneralsettings-read",
            },
        ],
    },
];

// buy path config
export const buysPathConfig = [
    {
        key: "buys-buys",
        id: "buys",
        title: "BuysSideBar.Buys.title",
        permission: "buys-buy",
        linkInfo: [
            {
                key: "buys-new",
                id: "buys-new",
                name: "BuysSideBar.Buys.new",
                link: BUYS_PATHS.NEW_BUYS,
                permission: "buys-buy/new-read",
            },
            {
                key: "buys-search",
                id: "buys-search",
                name: "BuysSideBar.Buys.search",
                link: BUYS_PATHS.SEARCH_BUYS,
                permission: "buys-buy/search-read",
            },
        ],
    },
    {
        key: "buys-mydata-report",
        id: "buys-mydata-report",
        title: "BuysSideBar.MyData.title",
        permission: "buys-mydata",
        linkInfo: [
            {
                key: "buys-mydata-report",
                id: "buys-mydata-report",
                name: "BuysSideBar.MyData.report",
                link: BUYS_PATHS.MY_DATA_REPORT,
                permission: "buys-mydata/mydatareport-read",
            }
        ]
    },
    {
        key: "supplier-payments",
        id: "supplier-payments",
        title: "BuysSideBar.SupplierPayments.title",
        permission: "buys-supplierpayments",
        linkInfo: [
            {
                key: "supplier-payments-new-general-write",
                id: "new-supplier-payments",
                name: "BuysSideBar.SupplierPayments.new",
                link: BUYS_PATHS.NEW_SUPPLIER_PAYMENT,
                permission: "buys-supplierpayments/new-read",
            },
            {
                key: "supplier-payments-search-search",
                id: "search-supplier-payments",
                name: "BuysSideBar.SupplierPayments.search",
                link: BUYS_PATHS.SEARCH_SUPPLIER_PAYMENTS,
                permission: "buys-supplierpayments/search-read",
            },
        ],
    },
    {
        key: "buys-reports",
        id: "reports",
        title: "BuysSideBar.Reports.title",
        permission: "buys-reports",
        linkInfo: [
            {
                key: "buys-report-balance-sheet",
                id: "buys-balance-sheet",
                name: "BuysSideBar.Reports.buysBalanceSheet",
                link: BUYS_PATHS.BUYS_BALANCE_SHEET,
                permission: "buys-reports/buybalancesheet-read",
            },
            {
                key: "buys-reports-supplier-payment-report",
                id: "supplier-payments-report",
                name: "BuysSideBar.Reports.supplierPaymentsReport",
                link: BUYS_PATHS.SUPPLIER_PAYMENT_REPORT,
                permission: "buys-reports/supplierpaymentreport-read",
            },
            {
                key: "buys-reports-graphic-report",
                id: "buys-graphic-report",
                name: "BuysSideBar.Reports.buysGraphicReport",
                link: BUYS_PATHS.BUYS_GRAPHIC_REPORT,
                permission: "buys-reports/buygraphicreport-read",
            }
        ],
    },
    {
        key: "buys-settings",
        id: "settings",
        title: "BuysSideBar.Settings.title",
        permission: "buys-settings",
        linkInfo: [
            {
                key: "buys-settings-general",
                id: "buys-general-settings",
                name: "BuysSideBar.Settings.buysGeneralSettings",
                link: BUYS_PATHS.GENERAL_SETTINGS,
                permission: "buys-settings/buygeneralsettings-read"
            }
        ]
    },
]

// clients path config array
export const clientsPathConfig = [
    {
        key: "clients",
        id: "clients",
        title: "ClientSideBar.General.title",
        permission: "clients-clients",
        linkInfo: [
            {
                key: "new-client",
                id: "new-client",
                name: "ClientSideBar.General.general",
                link: CLIENT_PATHS.NEW_CLIENT,
                permission: "clients-clients/new-read",
            },
            {
                key: "search-client",
                id: "search-client",
                name: "ClientSideBar.General.search",
                link: CLIENT_PATHS.SEARCH_CLIENT,
                permission: "clients-clients/search-read",
            },
        ],
    },
    {
        key: "client-movements",
        id: "client-movements",
        title: "ClientSideBar.Movements.title",
        permission: "clients-clientmovements",
        linkInfo: [
            {
                key: "new-client-movements",
                id: "new-client-movements",
                name: "ClientSideBar.Movements.general",
                link: CLIENT_PATHS.NEW_CLIENT_MOVEMENTS,
                permission: "clients-clientmovements/new-read",
            },
            {
                key: "search-client-movements",
                id: "search-client-movements",
                name: "ClientSideBar.Movements.search",
                link: CLIENT_PATHS.SEARCH_CLIENT_MOVEMENTS,
                permission: "clients-clientmovements/search-read",
            },
        ],
    },
    {
        key: "client-reports",
        id: "reports",
        title: "ClientSideBar.Reports.title",
        permission: "clients-reports",
        linkInfo: [
            {
                key: "client-balance",
                id: "client-balance",
                name: "ClientSideBar.Reports.clientBalance",
                link: CLIENT_PATHS.CLIENT_BALANCE,
                permission: "clients-reports/clientbalance-read",
            },
            {
                key: "clients-movements",
                id: "clients-movements",
                name: "ClientSideBar.Reports.clientsMovements",
                link: CLIENT_PATHS.CLIENTS_MOVEMENTS,
                permission: "clients-reports/clientsmovements-read",
            },
            {
                key: "report-client-isozygio",
                id: "report-client-isozygio",
                name: "Ισοζύγιο Πελατών",
                link: CLIENT_PATHS.CLIENT_ISOZYGIO_REPORT,
                permission: "clients-reports/clientisozygio-read",
            },
            {
                key: "emron-payments",
                id: "emron-payments",
                name: "Μηνιαίες Χρεώσεις",
                link: CLIENT_PATHS.EMRON_PAYMENTS,
                permission: "clients-reports/emronpayments-read",
            },
            {
                key: "reports-client-subscriptions",
                id: "reports-client-subscriptions",
                name: "Συνδρομές Πελατών",
                link: CLIENT_PATHS.CLIENT_SUBSCRIPTIONS,
                permission: "clients-reports/reportsclientsubscriptions-read"
            },
            {
                key: "reports-primer-payments",
                id: "reports-primer-payments",
                name: "Πληρωμές Συνδρομών",
                link: CLIENT_PATHS.PRIMER_PAYMENTS,
                permission: "clients-reports/reportsprimerpayments-read"
            },
            {
                key: "reports-primer-payments-admin",
                id: "reports-primer-payments-admin",
                name: "Πράξεις Συνδρομών",
                link: CLIENT_PATHS.PRIMER_PAYMENTS,
                permission: "clients-reports/reportsprimerpaymentsadmin-read"
            },
            {
                key: "reports-emron-settlements",
                id: "reports-emron-settlements",
                name: "Ανάλυση Διακανονισμών",
                link: CLIENT_PATHS.EMRON_SETTLEMENTS_REPORT,
                permission: "clients-reports/reportsemronsettlements-read"
            }
        ],
    },
    {
        key: "clients-settings-main",
        id: "clients-settings-main",
        title: "ClientSideBar.Settings.title",
        permission: "clients-settings",
        linkInfo: [
            {
                key: "clients-settings",
                id: "clients-settings",
                name: "ClientSideBar.Settings.clientSettings",
                link: CLIENT_PATHS.CLIENTS_SETTINGS,
                permission: "clients-settings/clientsettings-read"
            },
        ],
    },
];

// suppliers path config array
export const suppliersPathConfig = [
    {
        key: "suppliers",
        id: "suppliers",
        title: "SupplierSideBar.General.title",
        permission: "suppliers-suppliers",
        linkInfo: [
            {
                key: "new-supplier",
                id: "new-supplier",
                name: "SupplierSideBar.General.new",
                link: SUPPLIER_PATHS.NEW_SUPPLIER,
                permission: "suppliers-suppliers/new-read",
            },
            {
                key: "search-supplier",
                id: "search-supplier",
                name: "SupplierSideBar.General.search",
                link: SUPPLIER_PATHS.SEARCH_SUPPLIER,
                permission: "suppliers-suppliers/search-read",
            },
        ],
    },
    {
        key: "supplier-movements",
        id: "supplier-movements",
        title: "SupplierSideBar.Movements.title",
        permission: "suppliers-suppliermovements",
        linkInfo: [
            {
                key: "new-supplier-movements",
                id: "new-supplier-movements",
                name: "SupplierSideBar.Movements.new",
                link: SUPPLIER_PATHS.NEW_SUPPLIER_MOVEMENTS,
                permission: "suppliers-suppliermovements/new-read",
            },
            {
                key: "search-supplier-movements",
                id: "search-supplier-movements",
                name: "SupplierSideBar.Movements.search",
                link: SUPPLIER_PATHS.SEARCH_SUPPLIER_MOVEMENTS,
                permission: "suppliers-suppliermovements/search-read",
            },
        ],
    },
    {
        key: "supplier-reports",
        id: "reports",
        title: "SupplierSideBar.Reports.title",
        permission: "suppliers-reports",
        linkInfo: [
            {
                key: "supplier-balance",
                id: "supplier-balance",
                name: "SupplierSideBar.Reports.supplierBalance",
                link: SUPPLIER_PATHS.SUPPLIER_BALANCE,
                permission: "suppliers-reports/supplierbalance-read"
            },
            {
                key: "suppliers-movements",
                id: "suppliers-movements",
                name: "SupplierSideBar.Reports.supplierMovements",
                link: SUPPLIER_PATHS.SUPPLIERS_MOVEMENTS,
                permission: "suppliers-reports/suppliersmovements-read",
            },
            {
                key: "report-supplier-isozygio",
                id: "report-supplier-isozygio",
                name: "Ισοζύγιο Προμηθευτών",
                link: SUPPLIER_PATHS.SUPPLIER_ISOZYGIO_REPORT,
                permission: "suppliers-reports/supplierisozygio-read",
            },
        ],
    },
    {
        key: "suppliers-settings-main",
        id: "suppliers-settings-main",
        title: "SupplierSideBar.Settings.title",
        permission: "suppliers-settings",
        linkInfo: [
            {
                key: "suppliers-settings",
                id: "suppliers-settings",
                name: "SupplierSideBar.Settings.supplierSettings",
                link: SUPPLIER_PATHS.SUPPLIERS_SETTINGS,
                permission: "suppliers-settings/suppliersettings-read",
            },
        ],
    },
];

// cash checks path config
// export const cashChecksConfig = [
//   {
//     id: "checks",
//     title: "Checks",
//     linkInfo: [
//       {
//         id: "cash-new",
//         name: "New",
//         link: CASH_CHECKS_PATHS.NEW_CHECKS,
//       },
//       {
//         id: "cash-search",
//         name: "New",
//         link: CASH_CHECKS_PATHS.SEARCH_CHECKS,
//       },
//     ],
//   },

//   {
//     id: "cash-checks",
//     title: "Cash Checks",
//     linkInfo: [
//       {
//         id: "cash-checks-new",
//         name: "New",
//         link: CASH_CHECKS_PATHS.NEW_CASH_CHECKS,
//       },
//       {
//         id: "cash-checks-search",
//         name: "New",
//         link: CASH_CHECKS_PATHS.SEARCH_CASH_CHECKS,
//       },
//     ],
//   },
//   {
//     id: "cash-checks-settings",
//     title: "Cash Checks",
//     linkInfo: [
//       {
//         id: "cash-checks-setting",
//         name: "Cash Checks",
//         link: CASH_CHECKS_PATHS.CASH_CHECKS_SETTINGS,
//       },
//     ],
//   },
// ];

// cash registers path config
export const cashRegistersConfig = [
    {
        key: "cash-registers",
        id: "cash-registers",
        title: "Cash.CashRegisters.title",
        permission: "cash-cashregisters",
        linkInfo: [
            {
                key: "cash-registers-new",
                id: "cash-registers-new",
                name: "Cash.CashRegisters.new",
                link: CASH_REGISTERS_PATHS.NEW_CASH_REGISTERS,
                permission: "cash-cashregisters/new-read",
            },
            {
                key: "cash-registers-search",
                id: "cash-registers-search",
                name: "Cash.CashRegisters.search",
                link: CASH_REGISTERS_PATHS.SEARCH_CASH_REGISTERS,
                permission: "cash-cashregisters/search-read",
            },
        ],
    },
    {
        key: "pos",
        id: "pos",
        title: "Cash.POS.title",
        permission: "cash-pos",
        linkInfo: [
            {
                key: "new-pos",
                id: "new-pos",
                name: "Cash.POS.new",
                link: CASH_REGISTERS_PATHS.NEW_POS,
                permission: "cash-pos/new-read"
            },
            {
                key: "search-pos",
                id: "search-pos",
                name: "Cash.POS.search",
                link: CASH_REGISTERS_PATHS.SEARCH_POS,
                permission: "cash-pos/search-read",
            },
        ],
    },
    {
        key: "bank-accounts",
        id: "bank-accounts",
        title: "Cash.bankAccounts.title",
        permission: "cash-bankaccounts",
        linkInfo: [
            {
                key: "new-bank-account",
                id: "new-bank-account",
                name: "Cash.bankAccounts.new",
                link: CASH_REGISTERS_PATHS.NEW_BANK_ACCOUNTS,
                permission: "cash-bankaccounts/new-read",
            },
            {
                key: "search-bank-account",
                id: "search-bank-account",
                name: "Cash.bankAccounts.search",
                link: CASH_REGISTERS_PATHS.SEARCH_BANK_ACCOUNTS,
                permission: "cash-bankaccounts/search-read",
            },
        ],
    },
    {
        key: "cash-registers-reports",
        id: "reports",
        title: "Cash.Reports.title",
        permission: "cash-reports",
        linkInfo: [
            {
                key: "cash-reports",
                id: "cash-reports",
                name: "Cash.Reports.cash",
                link: CASH_REGISTERS_PATHS.CASH_REPORTS,
                permission: "cash-reports/cash-read",
            },
        ],
    },
    {
        key: "cash-registers-settings",
        id: "settings",
        title: "Cash.Settings.title",
        permission: "cash-settings",
        linkInfo: [
            {
                key: "cash-registers-general-settings",
                id: "general-settings",
                name: "Cash.Settings.general",
                link: CASH_REGISTERS_PATHS.GENERAL_SETTINGS,
                permission: "cash-settings/general-read",
            },
            {
                key: "cash-settings-accounting",
                id: "cash-settings-accounting",
                name: "Λογιστική",
                link: CASH_REGISTERS_PATHS.ACCOUNTING_SETTINGS,
                permission: "cash-settings/accounting-read",
            }
        ],
    },
];

export const accountingPathConfig = [
    {
        id: "employee",
        title: "AccountingSideBar.Employee.title",
        key: "employee",
        permission: "accounting-employees",
        linkInfo: [
            {
                key: "employee-new",
                id: "employee-new",
                name: "AccountingSideBar.Employee.new",
                link: PAYROLL_PATHS.NEW_EMPLOYEE,
                permission: "accounting-employees/new-read",
            },
            {
                key: "employee-search",
                id: "employee-search",
                name: "AccountingSideBar.Employee.search",
                link: PAYROLL_PATHS.SEARCH_EMPLOYEE,
                permission: "accounting-employees/search-read"
            },
        ],
    },
    {
        id: "accounting-account",
        title: "AccountingSideBar.AccountingAccounts.title",
        key: "accounting-account",
        permission: "accounting-accountingaccounts",
        linkInfo: [
            {
                key: "accounting-account-new",
                id: "accounting-account-new",
                name: "AccountingSideBar.AccountingAccounts.new",
                link: ACCOUNTING_PATHS.NEW_ACCOUNTS,
                permission: "accounting-accountingaccounts/new-read",
            },
            {
                key: "accounting-account-search",
                id: "accounting-account-search",
                name: "AccountingSideBar.AccountingAccounts.search",
                link: ACCOUNTING_PATHS.SEARCH_ACCOUNTS,
                permission: "accounting-accountingaccounts/search-read"
            },
        ],
    },
    {
        key: "accounting-records",
        id: "accounting-records",
        title: "AccountingSideBar.AccountingRecords.title",
        permission: "accounting-accountingrecords",
        linkInfo: [
            {
                key: "accounting-record-new",
                id: "accounting-record-new",
                name: "AccountingSideBar.AccountingRecords.new",
                link: ACCOUNTING_PATHS.NEW_RECORDS,
                permission: "accounting-accountingrecords/new-read",
            },
            {
                key: "accounting-record-search",
                id: "accounting-record-search",
                name: "AccountingSideBar.AccountingRecords.search",
                link: ACCOUNTING_PATHS.SEARCH_RECORDS,
                permission: "accounting-accountingrecords/search-read",
            },
            {
                key: "accounting-closure",
                id: "accounting-closure",
                name: "Κλείσιμο Λογαριασμών",
                link: ACCOUNTING_PATHS.ACCOUNT_CLOSURE,
                permission: "accounting-accountingrecords/closure-read",
            }
        ],
    },
    {
        key: "accounting-reports",
        id: "accounting-reports",
        title: "AccountingSideBar.Reports.title",
        permission: "accounting-reports",
        linkInfo: [
            {
                key: "accounting-report-isozygio",
                id: "accounting-report-isozygio",
                name: "Ισοζύγιο",
                link: ACCOUNTING_PATHS.ISOZYGIO,
                permission: "accounting-reports/isozygio-read"
            },
            {
                key: "accounting-report-accounts",
                id: "accounting-report-accounts",
                name: "Καρτέλα Λογαριασμών",
                link: ACCOUNTING_PATHS.REPORT_ACCOUNTS,
                permission: "accounting-reports/accounts-read"
            },
            {
                key: "accounting-report-calendar",
                id: "accounting-report-calendar",
                name: "Ημερολόγιο Λογαριασμών",
                link: ACCOUNTING_PATHS.REPORT_CALENDAR,
                permission: "accounting-reports/calendar-read"
            },
            {
                key: "accounting-report-general-calendar",
                id: "accounting-report-general-calendar",
                name: "Γενικό Ημερολόγιο",
                link: ACCOUNTING_PATHS.REPORT_CALENDAR,
                permission: "accounting-reports/calendar-read"
            },
            {
                key: "accounting-operating-statement",
                id: "accounting-operating-statement",
                name: "Γενική Εκμετάλλευση",
                link: ACCOUNTING_PATHS.REPORT_OPERATING_STATEMENT,
                permission: "accounting-reports/operatingstatement-read",
            },
            {
                key: "accounting-year-report",
                id: "accounting-year-report",
                name: "Ετήσια Αποτελέσματα",
                link: ACCOUNTING_PATHS.YEAR_REPORT,
                permission: "accounting-reports/year-read",
            }
        ],
    },
    {
        key: "accounting-settings",
        id: "accounting-settings",
        title: "AccountingSideBar.Settings.title",
        permission: "accounting-settings",
        linkInfo: [
            {
                key: "accounting-settings",
                id: "accounting-settings",
                name: "AccountingSideBar.Settings.accounting",
                link: ACCOUNTING_PATHS.ACCOUNTING_SETTINGS,
                permission: "accounting-settings/accounting-read",
            },
            {
                id: "accounting-record-settings",
                key: "accounting-record-settings",
                name: "AccountingSideBar.Settings.recordTemplates",
                link: ACCOUNTING_PATHS.ACCOUNTING_RECORDS_TEMPLATES,
                permission: "accounting-settings/recordtemplates-read",
            },
            {
                id: "accounting-transfer",
                key: "accounting-transfer",
                name: "AccountingSideBar.Settings.accountTransfer",
                link: ACCOUNTING_PATHS.ACCOUNTING_TRANSFER,
                permission: "accounting-settings/accounttransfer-read",
            },
            {
                id: "accounting-bridge-settings",
                key: "accounting-bridge-settings",
                name: "Γέφυρα",
                link: ACCOUNTING_PATHS.ACCOUNTING_BRIDGE_SETTINGS,
                permission: "accounting-settings/bridge-read",
            }
        ],
    },
];

export const payrollPathConfig = [
    {
        id: "employee",
        title: "AccountingSideBar.Employee.title",
        key: "employee",
        permission: "payroll-employees",
        linkInfo: [
            {
                key: "employee-new",
                id: "employee-new",
                name: "AccountingSideBar.Employee.new",
                link: PAYROLL_PATHS.NEW_EMPLOYEE,
                permission: "payroll-employees/new-read",
            },
            {
                key: "employee-search",
                id: "employee-search",
                name: "AccountingSideBar.Employee.search",
                link: PAYROLL_PATHS.SEARCH_EMPLOYEE,
                permission: "payroll-employees/search-read"
            },
        ],
    },
    {
        id: "working-program",
        title: "Πρόγραμμα Εργασίας",
        key: "working-program",
        permission: "payroll-workingprogram",
        linkInfo: []
    },
    {
        id: "payroll-qr-scanner",
        title: "Σαρωτής QR",
        key: "payroll-qr-scanner",
        permission: "payroll-qrscanner",
        linkInfo: []
    },
    {
        id: "work-permits",
        title: "Άδειες Εργαζομένων",
        key: "work-permits",
        permission: "payroll-workpermits",
        linkInfo: [
            {
                key: "new-work-permit",
                id: "new-work-permit",
                name: "Εισαγωγή",
                link: PAYROLL_PATHS.NEW_WORK_PERMIT,
                permission: "payroll-workpermits/new-read",
            },
            {
                key: "search-work-permits",
                id: "search-work-permits",
                name: "Αναζήτηση",
                link: PAYROLL_PATHS.SEARCH_WORK_PERMITS,
                permission: "payroll-workpermits/search-read"
            },
        ],
    },
    {
        id: "workMovements",
        title: "Κινήσεις Εργαζομένων",
        key: "workMovements",
        permission: "payroll-workmovements",
        linkInfo: [
            {
                key: "new-work-movement",
                id: "new-work-movement",
                name: "Εισαγωγή",
                link: PAYROLL_PATHS.NEW_WORK_MOVEMENT,
                permission: "payroll-workmovements/new-read",
            },
            {
                key: "search-work-movements",
                id: "search-work-movements",
                name: "Αναζήτηση",
                link: PAYROLL_PATHS.SEARCH_WORK_MOVEMENTS,
                permission: "payroll-workmovements/search-read"
            },
        ],
    },
    {
        id: "ergani-submissions-report",
        title: "Αιτήματα Εργάνης",
        key: "ergani-submissions-report",
        permission: "payroll-erganisubmissions",
        linkInfo: []
    },
    {
        id: "payroll-hour-report",
        title: "Ωρομέτρηση",
        key: "payroll-hour-report",
        permission: "payroll-hourreport",
        linkInfo: []
    },
    {
        id: "payroll-settings",
        title: "Ρυθμίσεις",
        key: "payroll-settings",
        permission: "payroll-settings",
        linkInfo: [
            {
                key: "payroll-settings",
                id: "payroll-settings",
                name: "Ρυθμίσεις Μισθοδοσίας",
                link: PAYROLL_PATHS.SETTINGS,
                permission: "payroll-settings/settings-read",
            },
        ],
    },
]

// settings path config
export const settingsPathConfig = [
    {
        key: "primer-my-data",
        id: "primer-my-data",
        title: "SettingsSideBar.PrimerMyData.title",
        permission: "settings-primermydata",
        linkInfo: [
            {
                key: "primer-my-data",
                id: "primer-my-data",
                name: "SettingsSideBar.PrimerMyData.title",
                link: SETTINGS_PATHS.MY_DATA_PRIMER_MY_DATA,
                permission: "settings-primermydata/primermydata-read",
            },
            {
                key: "primer-my-data-data",
                id: "primer-my-data-data",
                name: "SettingsSideBar.PrimerMyData.data",
                link: SETTINGS_PATHS.MY_DATA_DATA,
                permission: "settings-primermydata/data-read",
            },
        ],
    },
    {
        key: "settings-document-types",
        id: "settings-document-types",
        title: "SettingsSideBar.DocumentType.title",
        permission: "settings-documenttype",
        linkInfo: [
            {
                key: "basic-types-new",
                id: "basic-types-new",
                name: "SettingsSideBar.DocumentType.basicTypesNew",
                link: SETTINGS_PATHS.DOCUMENT_TYPE_BASIC_TYPES_NEW,
                permission: "settings-documenttype/basictypesnew-read",
            },
            {
                key: "basic-types-search",
                id: "basic-types-search",
                name: "SettingsSideBar.DocumentType.basicTypesSearch",
                link: SETTINGS_PATHS.DOCUMENT_TYPE_BASIC_TYPES_SEARCH,
                permission: "settings-documenttype/basictypessearch-read",
            },
            {
                key: "advanced-types-new",
                id: "advanced-types-new",
                name: "SettingsSideBar.DocumentType.advancedTypesNew",
                link: SETTINGS_PATHS.DOCUMENT_TYPE_ADVANCE_TYPE_NEW,
                permission: "settings-documenttype/advancedtypesnew-read",
            },
            {
                key: "advanced-types-search",
                id: "advanced-types-search",
                name: "SettingsSideBar.DocumentType.advancedTypesSearch",
                link: SETTINGS_PATHS.DOCUMENT_TYPE_ADVANCE_TYPE_SEARCH,
                permission: "settings-documenttype/advancedtypessearch-read",
            },
        ],
    },
    {
        key: "general-settings",
        id: "general-settings",
        title: "SettingsSideBar.GeneralSettings.title",
        permission: "settings-generalsettings",
        linkInfo: [
            {
                key: "company-settings",
                id: "company-settings",
                name: "SettingsSideBar.GeneralSettings.companySettings",
                link: SETTINGS_PATHS.GENERAL_SETTINGS_COMPANY_SETTINGS,
                permission: "settings-generalsettings/companysettings-read",
            },
            {
                key: "system-tasks",
                id: "system-tasks",
                name: "SettingsSideBar.GeneralSettings.systemTasks",
                link: SETTINGS_PATHS.GENERAL_SETTINGS_SYSTEM_TASKS,
                permission: "settings-generalsettings/systemtasks-read",
            },
            {
                id: "email-smtp",
                key: "email-smtp",
                name: "SettingsSideBar.GeneralSettings.emailSTMP",
                link: SETTINGS_PATHS.EMAIL_SMTP,
                permission: "settings-generalsettings/emailsmtp-read",
            },
            {
                id: "subscriptions",
                key: "subscriptions",
                name: "SettingsSideBar.GeneralSettings.subscriptions",
                link: SETTINGS_PATHS.SETTINGS_SUBSCRIPTIONS,
                permission: "settings-generalsettings/subscriptions-read",
            },
            {
                id: "master-log-search",
                key: "master-log-search",
                name: "SettingsSideBar.GeneralSettings.logHistory",
                link: SETTINGS_PATHS.MASTER_LOG_SEARCH,
                permission: "settings-generalsettings/masterlogsearch-read"
            }
        ],
    },
    {
        key: "import-export",
        id: "import-export",
        title: "SettingsSideBar.ImportExport.title",
        permission: "settings-importexport",
        linkInfo: [
            {
                key: "settings-import",
                id: "settings-import",
                name: "SettingsSideBar.ImportExport.import",
                link: SETTINGS_PATHS.IMPORT_EXPORT_IMPORT,
                permission: "settings-importexport/import-read",
            },
            {
                key: "settings-export",
                id: "settings-export",
                name: "SettingsSideBar.ImportExport.export",
                link: SETTINGS_PATHS.IMPORT_EXPORT_EXPORT,
                permission: "settings-importexport/export-read",
            },
        ],
    },
    {
        key: "backup-restore",
        id: "backup-restore",
        title: "SettingsSideBar.BackupRestore.title",
        permission: "settings-backuprestore",
        linkInfo: [
            {
                key: "settings-backup",
                id: "settings-backup",
                name: "SettingsSideBar.BackupRestore.backup",
                link: SETTINGS_PATHS.BACKUP_RESTORE_BACKUP,
                permission: "settings-backuprestore/backup-read",
            },
            {
                key: "settings-restore",
                id: "settings-restore",
                name: "SettingsSideBar.BackupRestore.restore",
                link: SETTINGS_PATHS.BACKUP_RESTORE_RESTORE,
                permission: "settings-backuprestore/restore-read",
            },
            {
                key: "settings-emronExpenses",
                id: "settings-emronExpenses",
                name: "Emron Expenses",
                link: SETTINGS_PATHS.EMRON_EXPENSES,
                permission: "settings-backuprestore/emronExpenses-read"
            }
        ],
    },
];
