import React, {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchAccountingAdvancedTypes,
    fetchAccountingPaymentTypes,
    fetchAccountingRecordCategories,
    fetchAccountingRecordSubcategories,
    fetchBankAccounts,
    fetchPOS
} from "../../../../_apis/api";
import {Badge, Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import Dropdown from "../../../primer/Dropdown";
import {
    fixNetValueAndVat,
    getDefaultOptionByValue,
    getMultiSelectOptionsToArray,
    getOptionsByData,
    getRecordTypeOptions,
    isEmpty
} from "../../../../_helpers/commonFunctions";
import {setSaveNewAccountingRecord} from "../../../../_reducers/activitySlice";
import {
    setAccountingRecordSubcategories,
    setNewAccountingRecord,
} from "../../../../_reducers/DataPanel/Accounting/accountingSlice";
import {getLabelByValue, getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import DateBox from "../../../primer/DateBox";
import Input from "../../../common/Input";
import axios from "axios";
import {toast} from "react-toastify";
import TextArea from "../../../common/TextArea";
import Loader from "../../../primer/POPUP-COMPONENTS/Loader/PrimerLoader";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import AccountingTable from "../../../primer/AccountingTable/AccountingTable";
import moment from "moment";
import HourPicker from "../../../primer/HourPicker";
import EditableTable from "../../../primer/Editable-Table";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import {setBuysGraphicReportRequestData} from "../../../../_reducers/DataPanel/Buys/buysSlice";

export const NewAccountingRecord = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const ACCOUNTING_DATA = useSelector((state) => state.ACCOUNTING_DATA);
    const CASH_DATA = useSelector((state) => state.CASH_DATA);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const categories = [{ label: "Κενό", value: ""}, ...getOptionsByData(ACCOUNTING_DATA.accountingRecordCategories, "_id", "name")]
    const subCategories = [{ label: "Κενό", value: ""}, ...getOptionsByData(ACCOUNTING_DATA.accountingRecordSubcategories, "_id", "name")]
    const documentTypesOptions = useMemo(() => getOptionsByData(ACCOUNTING_DATA.accountingAdvancedTypes, "_id", "name"), [ACCOUNTING_DATA.accountingAdvancedTypes]);
    const installations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const [showModalConfirmation, setShowModalConfirmation] = useState(false);
    const [showCopyModal, setshowCopyModal] = useState(false);
    const [filterLoading, setFilterLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [recordsRequestData, setRecordsRequestData] = useState({
        company: company.id,
        year: company.year,
        page: 1,
        generalFilter: "",
        date_from: moment(company.year, "YYYY").startOf('year').format("DD/MM/YYYY"),
        date_to: moment(company.year, "YYYY").endOf('year').format("DD/MM/YYYY")
    });
    const [tempSubCategoryValue, setTempSubCategoryValue] = useState("");
    const [tempSubCategoryLabel, setTempSubCategoryLabel] = useState("");
    const [accountingRecords, setAccountingRecords] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [requiredPaymentType, setRequiredPaymentType] = useState(false);
    const installationOptions = getOptionsByData(installations, "installationMasterId", "address");
    const [accountsList, setAccountsList] = useState([]);
    const paymentTypeOptions = useMemo(() => getOptionsByData(ACCOUNTING_DATA.paymentTypes, "_id", "name"), [ACCOUNTING_DATA.paymentTypes]);
    const [defaultCorrelatedData, setDefaultCorrelatedData] = useState([]);
    const [documentToSwitch, setDocumentToSwitch] = useState({});
    const [copyPageChanged, setCopyPageChanged] = useState(false);

    const [incrKey, setIncrKey] = useState(0);
    useEffect(() => {
        setIncrKey(incrKey + 1);
    }, [ACCOUNTING_DATA.newAccountingRecord.accounts, accountsList])

    useEffect(() => {
        if (copyPageChanged) {
            setCopyPageChanged(false);
            fetchRecords();
        }
    }, [copyPageChanged])

    let disabledFields = false;
    if (isEmpty(ACCOUNTING_DATA.newAccountingRecord.category) || isEmpty(ACCOUNTING_DATA.newAccountingRecord.subCategory)) {
        disabledFields = true;
    }
    const posOptions = getOptionsByData(CASH_DATA.posList, '_id', 'name');
    const bankOptions = getOptionsByData(CASH_DATA.bankAccounts, '_id', 'name');
    const requestData2 = {
        company: company.id,
        year: company.year.toString(),
        isActive: "active"
    }
    useEffect(() => {
        dispatch(fetchAccountingPaymentTypes(company));
        dispatch(fetchPOS(requestData2));
        dispatch(fetchBankAccounts(requestData2));
        dispatch(fetchAccountingRecordCategories(company.id));
        dispatch(fetchAccountingRecordSubcategories(company.id));
        dispatch(fetchAccountingAdvancedTypes(company.id));

        if (ACCOUNTING_DATA.newAccountingRecord.paymentType) {
            const filter = ACCOUNTING_DATA.paymentTypes.filter((el) => el._id === ACCOUNTING_DATA.newAccountingRecord.paymentType);
            if (filter.length > 0) {
                let paymentType = filter[0];
                if (paymentType.type === "pos") {
                    setPosBankFieldOptions(posOptions);
                    setHidePosBankField(false);
                }
                if (paymentType.type === "bank-account") {
                    setPosBankFieldOptions(bankOptions);
                    setHidePosBankField(false);
                }
                if (paymentType.type === "cash" || paymentType.type === "credit" || paymentType.type === "cheque") {
                    setPosBankFieldOptions([]);
                    setHidePosBankField(true);
                }
            }
        }
        reloadAccountsFromServer();
        if (String(ACCOUNTING_DATA?.newAccountingRecord?.category).length === 24) {
            loadSubCategories(ACCOUNTING_DATA?.newAccountingRecord?.category);
        }
    }, []);

    useEffect(() => {
        if (ACCOUNTING_DATA.newAccountingRecord?.myDataType === "5.1") {
            axios.get(process.env.REACT_APP_API_URL2 + "/accounting/get-correlatable-records", {
                headers: {"Content-Type": "application/json"},
                params: {
                    company: company.id,
                    year: String(company.year),
                }
            }).then((res) => {
                if (res.data.status === "200") {
                    setDefaultCorrelatedData(res.data.data);
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [ACCOUNTING_DATA.newAccountingRecord.myDataType])

    const loadSubCategories = (categoryID) => {
        axios.post(process.env.REACT_APP_API_URL2 + `/accounting/accounting-record-category-list`, {id: categoryID}, {
            headers: {"Content-Type": "application/json"},
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                dispatch(setAccountingRecordSubcategories(res.data.data?.subCategories));
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            toast.error("Κάτι πήγε λάθος κατά την ανάκτηση της συγκεκριμένης κατηγορίας.");
            console.log(error);
        })
    }

    const reloadAccountsFromServer = (updateClassifications = false) => {
        if(ACCOUNTING_DATA.newAccountingRecord.accounts && ACCOUNTING_DATA.newAccountingRecord.accounts.length > 0 && !isEmpty(ACCOUNTING_DATA.newAccountingRecord.myDataType)) {
            let ids = [];
            ACCOUNTING_DATA.newAccountingRecord.accounts.map((obj) => ids.push(obj._id));
            axios.get(process.env.REACT_APP_API_URL2 + "/accounting/get-mydata-by-accounts", {
                headers: {"Content-Type": "application/json"},
                params: {
                    company: company.id,
                    accounts: ids,
                }
            }).then((res) => {
                if(res.data.status === "200") {
                    if(res.data.data) {
                        let accountsClone = ACCOUNTING_DATA.newAccountingRecord.accounts.slice();
                        accountsClone = accountsClone.map((a) => Object.assign({}, a));
                        accountsClone.forEach((acc) => {
                            acc.mydata = res.data.data[acc._id];
                            /* Update My Data classifications */
                            if(updateClassifications === true) {
                                const matchingMyData = acc.mydata.filter((myData) => String(myData.invoiceType) === String(ACCOUNTING_DATA.newAccountingRecord.myDataType));
                                if(matchingMyData.length > 0) {
                                    acc.classificationCategory = matchingMyData[0].category;
                                    acc.classificationType = matchingMyData[0].type;
                                    if(matchingMyData[0].vatExpenseType) acc.vatExpenseType = matchingMyData[0].vatExpenseType;
                                } else {
                                    acc.classificationCategory = "";
                                    acc.classificationType = "";
                                    acc.vatExpenseType = "";
                                }
                            }
                        })
                        dispatch(setNewAccountingRecord({...ACCOUNTING_DATA.newAccountingRecord, accounts: accountsClone}));
                    }
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    }

    const [hidePosBankField, setHidePosBankField] = useState(true);
    const [posBankFieldOptions, setPosBankFieldOptions] = useState();
    const accountsColumns = [
        {
            label: t("AccountingAccountNew.accountingCode.name"),
            name: "code",
        },
        {
            label: t("AccountingAccountNew.name.name"),
            name: "name",
        },
        {
            label: t("AccountingAccountNew.alternativeName.name"),
            name: "alternativeName",
        }
    ];

    const accountingAccountsTableColumns = [
        {
            name: t("AccountingAccountNew.accountingCode.name"),
            field: "code",
            width: "25%",
            editable: true,
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: accountsColumns,
                    data: accountsList,
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: t("AccountingAccountNew.accountingCode.placeholder"),
                },
                type: 'advanced-combobox',
            }
        },
        {
            name: t("AccountingAccountNew.name.name"),
            field: "name",
            editable: true,
            width: "25%",
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: accountsColumns,
                    data: accountsList,
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: t("AccountingAccountNew.name.placeholder"),
                },
                type: 'advanced-combobox',
            }
        },
        {
            name: t("AccountingAccountNew.subAccount.name"),
            field: "subAccount",
            editable: true,
            width: "20%",
            inputType: {
                config: {
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: t("AccountingAccountNew.subAccount.placeholder"),
                },
                type: 'advanced-combobox',
            }
        },
        {
            name: t("General.debit"),
            field: "debit",
            width: "10%",
            editable: true,
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    allowNegative: true,
                    maxLength: 7
                }
            }
        },
        {
            name: t("General.credit"),
            field: "credit",
            width: "10%",
            editable: true,
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    allowNegative: true,
                    suffix: "€",
                    maxLength: 7
                }
            }
        },
        {
            name: t("General.actions"),
            field: "actions",
            width: "10%",
            actions: {
                delete: true,
                editClassifications: true,
                warning: true,
            },
            inputType: {
                type: "actions",
            }
        }
    ]
    useEffect(() => {
        if (ACCOUNTING_DATA.newAccountingRecord?.documentType) {
            loadAccountListData(ACCOUNTING_DATA.newAccountingRecord.documentType);
        }
    }, [ACCOUNTING_DATA.newAccountingRecord.documentType]);

    const loadAccountListData = (value) => {
        let params = {
            id: value,
            company: company.id
        }
        axios.get(process.env.REACT_APP_API_URL2 + `/accounting/account-list-by-advanced-type-id`, {
            headers: {"Content-Type": "application/json"},
            params: params
        }).then(res => {
            if (res.data && res.data.status === '200') {
                if (res.data.data) {
                    setAccountsList(res.data.data);
                } else {
                    setAccountsList([]);
                }
            } else {
                setAccountsList([]);
            }
        })
    }

    useEffect(() => {
        const doFunc = async () => {
            let accounts = [...ACCOUNTING_DATA.newAccountingRecord.accounts];
            if (accounts?.length > 0) {
                accounts = accounts.map((o) => Object.assign({}, o));
                for (let acc of accounts) {
                    if (!isEmpty(acc.subAccountType) && (!acc?.subAccountData || acc?.subAccountData?.length === 0)) {
                        await loadInitialSubAccountListData(acc.subAccountType, acc.subAccountCategories, acc);
                    }
                }
            }
            dispatch(setNewAccountingRecord({...ACCOUNTING_DATA.newAccountingRecord, accounts: accounts}));
        }
        doFunc();
    }, [])

    const loadInitialSubAccountListData = async (subAccountType, subAccountCategories, tempRowAccount) => {
        let requestData = {
            isActive: "active", // Temporary solution
            company: company.id,
            subAccountType: subAccountType,
            subAccountCategories: subAccountCategories
        }
        await axios.get(process.env.REACT_APP_API_URL2 + `/accounting/get-subAccount-by-word`, {
            params: {
                ...requestData,
                keyword: "empty"
            }
        }).then(res => {
            if (res.data && res.data.status === '200') {
                if (res.data.data) {
                    tempRowAccount.subAccountData = res.data.data;
                } else {
                    tempRowAccount.subAccountData = [];
                }
            } else {
                tempRowAccount.subAccountData = [];
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const handleConfirmCategory = async () => {
        let accountData = Object.assign({}, ACCOUNTING_DATA.newAccountingRecord);
        let newRecordData = {};
        let value = tempSubCategoryValue;
        let foundCategory = null;
        //let category;
        setShowModalConfirmation(false);
        setShowLoader(true);
        newRecordData["subCategory"] = value;
        await axios.post(process.env.REACT_APP_API_URL2 + `/accounting/accounting-record-subcategories-list`, {
                name: tempSubCategoryLabel,
                id: value,
                company: company.id
            }, {
                headers: {"Content-Type": "application/json"},
            }).then((res) => {
                if (res.data && res.data.status === "200" && res.data?.data) {
                    foundCategory = res.data.data;
                    //category = res.data.category;
                }
            })
        if (foundCategory) {
            //newRecordData["category"] = category.category;
            newRecordData["subCategoryName"] = foundCategory.name;
            //newRecordData["categoryName"] = category.categoryName;
            //newRecordData["documentType"] = foundCategory?.documentType;
            newRecordData["recordType"] = foundCategory.recordType ? foundCategory.recordType : "normal";
            newRecordData["correlatedRecord"] = "";
            newRecordData["correlatedRecordLabel"] = "";
            const selectedDocumentTypeData = getObjectFromArrayOfObjects(ACCOUNTING_DATA.accountingAdvancedTypes, foundCategory?.documentType, "_id");
            newRecordData["installation"] = selectedDocumentTypeData[0]?.proposedInstallation;
            //newRecordData["myDataType"] = selectedDocumentTypeData[0]?.myDataType;
            if (selectedDocumentTypeData[0]?.enableMyDataRules === "active") setRequiredPaymentType(true);
            newRecordData["reason"] = foundCategory?.reason;
            newRecordData["accounts"] = [];
            if (foundCategory?.accounts && foundCategory.accounts.length > 0) {
                for (let account of foundCategory.accounts) {
                    const updatedAccount = Object.assign({}, account);
                    const myDataData = updatedAccount.mydata.filter(myData => String(myData.invoiceType) === String(selectedDocumentTypeData[0]?.myDataType));
                    if (myDataData.length > 0) {
                        updatedAccount.classificationCategory = myDataData[0].category;
                        updatedAccount.classificationType = myDataData[0].type;
                    } else {
                        updatedAccount.classificationCategory = '';
                        updatedAccount.classificationType = '';
                    }
                    await loadInitialSubAccountListData(updatedAccount.subAccountType, updatedAccount.subAccountCategories, updatedAccount);
                    newRecordData["accounts"].push(updatedAccount);
                }
            }
        }
        setShowLoader(false);
        dispatch(setNewAccountingRecord({...accountData, ...newRecordData}));
    }

    const [documentTypeChanged, setDocumentTypeChanged] = useState(false);
    const handleSwitchDocumentType = (toSwitch, forceDoc) => {
        let doc = forceDoc ? forceDoc : documentToSwitch;
        if(toSwitch) {
            let newRecordData = {};
            newRecordData["documentType"] = doc?._id;
            newRecordData["documentTypeName"] = doc?.name;
            newRecordData["installation"] = doc?.proposedInstallation;
            newRecordData["myDataType"] = doc?.myDataType;
            newRecordData["correlatedRecord"] = "";
            newRecordData["correlatedRecordLabel"] = "";
            if (doc?.enableMyDataRules === "active") setRequiredPaymentType(true);
            dispatch(setNewAccountingRecord({...ACCOUNTING_DATA.newAccountingRecord, ...newRecordData}));
            setDocumentTypeChanged(true);
        }
        setDocumentToSwitch({});
    }
    useEffect(() => {
        if(documentTypeChanged === true) {
            setDocumentTypeChanged(false);
            reloadAccountsFromServer(true);
        }
    }, [documentTypeChanged])

    const handleOnChange = async (e, type = "default", ddname = '') => {
        // Set if editing the form
        if (!ACTIVITY.saveNewAccountingRecord) {
            dispatch(setSaveNewAccountingRecord(true));
        }
        let name;
        let value;
        let newRecordData = {};
        if (type === 'dp') {
            value = e;
            name = ddname;
        } else if (type === 'ac') {
            value = e.target.value;
            name = ddname;
        } else if (type === 'dd') {
            name = ddname;
            value = e.value;
        } else if (type === 'msdd') {
            name = ddname;
            value = getMultiSelectOptionsToArray(e);
        } else if (type === 'tp') {
            value = e;
            name = ddname;
        } else if (type === "correlatedComboBox") {
            newRecordData["correlatedRecord"] = e.row._id;
            newRecordData["correlatedRecordLabel"] = e.row.date + " | " + e.row.documentTypeName + " | " + e.row.number
        } else {
            value = e.target.value;
            name = e.target.name;
        }
        let accountData = Object.assign({}, ACCOUNTING_DATA.newAccountingRecord);
        let updateRedux = true;
        if (name === "category") {
            if (isEmpty(value)) {
                newRecordData["category"] = "";
                newRecordData["categoryName"] = "";
            } else {
                newRecordData["category"] = e.value;
                newRecordData["categoryName"] = e.label;
                await axios.post(process.env.REACT_APP_API_URL2 + `/accounting/accounting-record-category-list`, {id: value}, {
                    headers: {"Content-Type": "application/json"},
                }).then((res) => {
                    if (res.data && res.data.status === "200") {
                        if (isEmpty(accountData.subCategory)) {
                            dispatch(setAccountingRecordSubcategories(res.data.data?.subCategories));
                        } else if (!res.data.data?.subCategories?.find((el) => el._id === accountData.subCategory)) {
                            let tmp = [{_id: accountData.subCategory, name: accountData.subCategoryName}];
                            tmp = [...tmp, ...res.data.data?.subCategories];
                            dispatch(setAccountingRecordSubcategories(tmp));
                        }
                    } else {
                        toast.error(res.data.message);
                    }
                }).catch((error) => {
                    toast.error("Κάτι πήγε λάθος κατά την ανάκτηση της συγκεκριμένης κατηγορίας");
                    console.log(error);
                })
            }
        } else if (name === "documentType") {
            updateRedux = false;
            const selectedDocumentTypeData = getObjectFromArrayOfObjects(ACCOUNTING_DATA.accountingAdvancedTypes, value, "_id");
            if(accountData.documentType) {
                setDocumentToSwitch(selectedDocumentTypeData[0]);
            } else {
                handleSwitchDocumentType(true, selectedDocumentTypeData[0]);
            }
        } else if (name === "subCategory") {
            if (isEmpty(value)) {
                newRecordData["subCategory"] = "";
                newRecordData["subCategoryName"] = "";
            } else if (accountData?.subCategory !== "" && accountData.subCategory !== value) {
                setTempSubCategoryValue(value);
                setTempSubCategoryLabel(e.label);
                setShowModalConfirmation(true);
                return;
            } else {
                setShowLoader(true);
                let foundCategory;
                //let category;
                await axios.post(process.env.REACT_APP_API_URL2 + `/accounting/accounting-record-subcategories-list`, {
                        name: e.label,
                        id: value,
                        company: company.id
                    }, {
                        headers: {"Content-Type": "application/json"},
                    }).then((res) => {
                        if (res.data && res.data.status === "200" && res.data?.data) {
                            foundCategory = res.data.data;
                            //category = res.data.category;
                        }
                    })
                if (foundCategory) {
                    newRecordData["subCategoryName"] = foundCategory.name;
                    //newRecordData["category"] = category.category;
                    //newRecordData["categoryName"] = category.categoryName;
                    //newRecordData["documentType"] = foundCategory?.documentType;
                    const selectedDocumentTypeData = getObjectFromArrayOfObjects(ACCOUNTING_DATA.accountingAdvancedTypes, foundCategory?.documentType, "_id");
                    //newRecordData["documentTypeName"] = selectedDocumentTypeData[0]?.name;
                    newRecordData["recordType"] = foundCategory.recordType ? foundCategory.recordType : "normal";
                    newRecordData["installation"] = selectedDocumentTypeData[0]?.proposedInstallation;
                    //newRecordData["myDataType"] = selectedDocumentTypeData[0]?.myDataType;
                    if (selectedDocumentTypeData[0]?.enableMyDataRules === "active") setRequiredPaymentType(true);

                    newRecordData["reason"] = foundCategory?.reason;
                    newRecordData["accounts"] = [];
                    if (foundCategory?.accounts && foundCategory.accounts.length > 0) {
                        for (let account of foundCategory.accounts) {
                            const updatedAccount = Object.assign({}, account);
                            const myDataData = updatedAccount.mydata.filter(myData => String(myData.invoiceType) === String(ACCOUNTING_DATA.newAccountingRecord?.myDataType));
                            if (myDataData.length > 0) {
                                updatedAccount.classificationCategory = myDataData[0].category;
                                updatedAccount.classificationType = myDataData[0].type;
                            } else {
                                updatedAccount.classificationCategory = '';
                                updatedAccount.classificationType = '';
                            }
                            await loadInitialSubAccountListData(updatedAccount.subAccountType, updatedAccount.subAccountCategories, updatedAccount);
                            newRecordData["accounts"].push(updatedAccount);
                        }
                    }
                }
            }
        } else if (name === 'paymentType') {
            newRecordData.paymentTypeName = getLabelByValue(paymentTypeOptions, value, 'value');
            // This will update the pre-selected POS / Bank
            let paymentTypesData = getObjectFromArrayOfObjects(ACCOUNTING_DATA.paymentTypes, value, '_id');
            if (paymentTypesData && paymentTypesData.length > 0) {
                let selectedPaymentType = paymentTypesData['0'];
                newRecordData.myDataPaymentId = selectedPaymentType?.myDataPaymentId;
                if (selectedPaymentType?.type === "pos") {
                    newRecordData.bankAccount = selectedPaymentType.options?.preSelectedPosBankAccount;
                } else if (selectedPaymentType?.type === "bank-account") {
                    newRecordData.bankAccount = selectedPaymentType.options?.preSelectedPosBankAccount;
                } else {
                    newRecordData.bankAccount = "";
                    newRecordData.bankAccountName = "";
                }
            }
            // This will update the list of pos / bank on type selection
            if (paymentTypesData['0']?.type === "pos") {
                setPosBankFieldOptions(posOptions);
                setHidePosBankField(false);
            }
            if (paymentTypesData['0']?.type === "bank-account") {
                setPosBankFieldOptions(bankOptions);
                setHidePosBankField(false);
            }
            if (paymentTypesData['0']?.type === "cash" || paymentTypesData['0']?.type === "credit" || paymentTypesData['0']?.type === "cheque") {
                setPosBankFieldOptions([]);
                setHidePosBankField(true);
            }
        } else if (name === "bankAccount") {
            newRecordData["bankAccountName"] = e.label;
        }
        setShowLoader(false);
        newRecordData[name] = value;
        if(updateRedux) dispatch(setNewAccountingRecord({...accountData, ...newRecordData}));
    }

    const fixDisabledAccounts = (allAccounts) => {
        if (ACCOUNTING_DATA.accountingRecordSubcategories) {
            const findSubCategory = ACCOUNTING_DATA.accountingRecordSubcategories.find((el) => el.name === ACCOUNTING_DATA.newAccountingRecord.subCategoryName);
            if (findSubCategory?.accounts?.length > 0) {
                allAccounts = allAccounts.map((a) => Object.assign({}, a));
                for (let acc of allAccounts) {
                    const findMatching = findSubCategory.accounts.find((el) => el.code === acc.code);
                    if (findMatching) {
                        acc.isCredit = findMatching.isCredit;
                        acc.isDebit = findMatching.isDebit;
                    }
                }
            }
        }
        return allAccounts;
    }
    const onChangeSearchCorrelatedRecord = (keyword, setData) => {
        axios.get(process.env.REACT_APP_API_URL2 + "/accounting/get-correlatable-records", {
            headers: {"Content-Type": "application/json"},
            params: {
                company: company.id,
                year: String(company.year),
                keyword: keyword,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                setData(res.data.data);
            }
        })
    }
    const clearCorrelated = () => {
        dispatch(setNewAccountingRecord({
            ...ACCOUNTING_DATA.newAccountingRecord,
            correlatedRecord: "",
            correlatedRecordLabel: ""
        }));
    }
    const handleAutoFillCorrelatedAccounts = () => {
        if (!ACTIVITY.saveNewAccountingRecord) dispatch(setSaveNewAccountingRecord(true));

        axios.get(process.env.REACT_APP_API_URL2 + "/accounting/get-auto-fill-correlated-accounts", {
            headers: {"Content-Type": "application/json"},
            params: {
                company: company.id,
                year: String(company.year),
                correlated: ACCOUNTING_DATA.newAccountingRecord.correlatedRecord,
            }
        }).then((res) => {
            if(res.data.status === "200") {
                let totalDebit = 0;
                let totalCredit = 0;
                for(let acc of res.data.data) {
                    totalDebit += Number(acc.debit);
                    totalCredit += Number(acc.credit);
                }
                dispatch(setNewAccountingRecord({
                    ...ACCOUNTING_DATA.newAccountingRecord,
                    accounts: res.data.data,
                    totalDebit: parseFloat(totalDebit).toFixed(2),
                    totalCredit: parseFloat(totalCredit).toFixed(2)
                }));
                toast.success("Επιτυχής συμπλήρωση.");
                reloadAccountsFromServer(true);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }
    const handleSwitchCreditAndDebit = () => {
        if (!ACTIVITY.saveNewAccountingRecord) dispatch(setSaveNewAccountingRecord(true));

        let accountsClone = ACCOUNTING_DATA.newAccountingRecord.accounts.slice();
        if(accountsClone?.length > 0) {
            accountsClone = accountsClone.map((a) => Object.assign({}, a));
            accountsClone.forEach((acc) => {
                let t = acc.debit;
                acc.debit = acc.credit;
                acc.credit = t;
            })
        }
        let totalDebit = 0;
        let totalCredit = 0;
        for(let acc of accountsClone) {
            totalDebit += Number(acc.debit);
            totalCredit += Number(acc.credit);
        }
        dispatch(setNewAccountingRecord({
            ...ACCOUNTING_DATA.newAccountingRecord,
            accounts: accountsClone,
            totalDebit: parseFloat(totalDebit).toFixed(2),
            totalCredit: parseFloat(totalCredit).toFixed(2)
        }))
    }

    const tableFunctions = {
        onInsert: async (field, value) => {
            if (!ACTIVITY.saveNewAccountingRecord) dispatch(setSaveNewAccountingRecord(true));
            let accountsClone = ACCOUNTING_DATA.newAccountingRecord.accounts.slice();
            accountsClone = accountsClone.map((a) => Object.assign({}, a));
            if ((field === "code" || field === "name") && value instanceof Object) {
                let tempNewAccount = Object.assign({}, ACCOUNTING_DATA.newAccountInstance);
                tempNewAccount["_id"] = value._id;
                tempNewAccount["no"] = accountsClone.length + 1;
                tempNewAccount["id"] = accountsClone.length + 1;
                tempNewAccount["code"] = value.code;
                tempNewAccount["name"] = value.name;
                tempNewAccount["isNetValue"] = value.isNetValue;
                tempNewAccount["vat"] = value.vat;
                tempNewAccount["vatreason"] = value.vatreason;
                tempNewAccount["subAccountType"] = value.subAccount;
                tempNewAccount["taxType"] = value.taxType;
                tempNewAccount["otherTax"] = value.otherTax;
                tempNewAccount["fee"] = value.fee;
                tempNewAccount["stamp"] = value.stamp;
                tempNewAccount["withHoldTax"] = value.withHoldTax;
                tempNewAccount["mydata"] = value.mydata;
                const myDataData = value.mydata.filter(myData => String(myData.invoiceType) === String(ACCOUNTING_DATA.newAccountingRecord.myDataType));
                if (myDataData.length > 0) {
                    tempNewAccount["classificationCategory"] = myDataData[0].category;
                    tempNewAccount["classificationType"] = myDataData[0].type;
                    if (myDataData[0].vatExpenseType) {
                        tempNewAccount["vatExpenseType"] = myDataData[0].vatExpenseType;
                    }
                } else {
                    tempNewAccount["classificationCategory"] = '';
                    tempNewAccount["classificationType"] = '';
                    tempNewAccount["vatExpenseType"] = '';
                }
                if (tempNewAccount["subAccount"] === "client") {
                    tempNewAccount.subAccountCategories = value.clientCategories;
                } else if (tempNewAccount["subAccount"] === "supplier") {
                    tempNewAccount.subAccountCategories = value.supplierCategories;
                } else if (tempNewAccount["subAccount"] === "employee") {
                    tempNewAccount.subAccountCategories = value.employeeCategories;
                } else {
                    tempNewAccount.subAccountCategories = [];
                }
                if (tempNewAccount.subAccountType) {
                    await loadInitialSubAccountListData(tempNewAccount.subAccountType, tempNewAccount.subAccountCategories, tempNewAccount);
                }
                tempNewAccount.subAccount = "";
                tempNewAccount.credit = 0;
                tempNewAccount.debit = 0;
                accountsClone.push(tempNewAccount);

                accountsClone = fixNetValueAndVat(accountsClone);
                accountsClone = fixNetValueAndVat(accountsClone);
                let totalDebit = 0;
                let totalCredit = 0;
                for(let acc of accountsClone) {
                    totalDebit += Number(acc.debit);
                    totalCredit += Number(acc.credit);
                }
                dispatch(setNewAccountingRecord({
                    ...ACCOUNTING_DATA.newAccountingRecord,
                    accounts: fixDisabledAccounts(accountsClone),
                    totalDebit: parseFloat(totalDebit).toFixed(2),
                    totalCredit: parseFloat(totalCredit).toFixed(2)
                }));
            }
        },
        onChange: async (row, field, value) => {
            if (!ACTIVITY.saveNewAccountingRecord) dispatch(setSaveNewAccountingRecord(true));
            //console.log(row, field, value);
            let accountsClone = ACCOUNTING_DATA.newAccountingRecord.accounts.slice();
            accountsClone = accountsClone.map((a) => Object.assign({}, a));
            if (field === "debit") {
                if (value > 0) accountsClone[row]["credit"] = 0;
                accountsClone[row]["debit"] = value;
            } else if (field === "credit") {
                accountsClone[row]["credit"] = value;
                if (value > 0) accountsClone[row]["debit"] = 0;
            } else if ((field === "code" || field === "name") && typeof value === "object") {
                // value is the new selected account
                accountsClone[row]["_id"] = value._id;
                accountsClone[row]["code"] = value.code;
                accountsClone[row]["name"] = value.name;
                accountsClone[row]["subAccountType"] = value.subAccount;
                accountsClone[row]["isNetValue"] = value.isNetValue;
                accountsClone[row]["vat"] = value.vat;
                accountsClone[row]["vatreason"] = value.vatreason;
                accountsClone[row]["subAccount"] = "";
                accountsClone[row]["subAccountId"] = "";
                accountsClone[row]["credit"] = 0;
                accountsClone[row]["debit"] = 0;
                accountsClone[row]["taxType"] = value.taxType;
                accountsClone[row]["otherTax"] = value.otherTax;
                accountsClone[row]["fee"] = value.fee;
                accountsClone[row]["stamp"] = value.stamp;
                accountsClone[row]["withHoldTax"] = value.withHoldTax;
                accountsClone[row]["mydata"] = value.mydata;
                const myDataData = value.mydata.filter(myData => String(myData.invoiceType) === String(ACCOUNTING_DATA.newAccountingRecord.myDataType));
                if (myDataData.length > 0) {
                    accountsClone[row]["classificationCategory"] = myDataData[0].category;
                    accountsClone[row]["classificationType"] = myDataData[0].type;
                    if (myDataData[0].vatExpenseType) {
                        accountsClone[row]["vatExpenseType"] = myDataData[0].vatExpenseType;
                    }
                } else {
                    accountsClone[row]["classificationCategory"] = "";
                    accountsClone[row]["classificationType"] = "";
                    accountsClone[row]["vatExpenseType"] = "";
                }
                if (value.subAccount === "client") {
                    accountsClone[row]["subAccountCategories"] = value.clientCategories;
                } else if (value.subAccount === "supplier") {
                    accountsClone[row]["subAccountCategories"] = value.supplierCategories;
                } else if (value.subAccount === "employee") {
                    accountsClone[row]["subAccountCategories"] = value.employeeCategories;
                } else {
                    accountsClone[row]["subAccountCategories"] = [];
                }
                if (value.subAccount !== "") {
                    await loadInitialSubAccountListData(value.subAccount, accountsClone[row]["subAccountCategories"], accountsClone[row]);
                }
            } else if (field === "subAccount" && typeof value === "object") {
                accountsClone[row]["subAccountId"] = value._id;
                if (accountsClone[row]["subAccountType"] === "client" || accountsClone[row]["subAccountType"] === "supplier") {
                    accountsClone[row]["subAccount"] = value.name;
                } else if (accountsClone[row]["subAccountType"] === "employee") {
                    accountsClone[row]["subAccount"] = value.firstName + " " + value.lastName;
                } else if (accountsClone[row]["subAccountType"] === "installation") {
                    accountsClone[row]["subAccount"] = value.address + ", " + value.postalCode + ", " + value.city;
                } else {
                    accountsClone[row]["subAccount"] = value.name;
                }
            }
            if(accountsClone[row]["vat"] === "") accountsClone = fixNetValueAndVat(accountsClone);

            let totalDebit = 0;
            let totalCredit = 0;
            for(let acc of accountsClone) {
                totalDebit += Number(acc.debit);
                totalCredit += Number(acc.credit);
            }
            dispatch(setNewAccountingRecord({
                ...ACCOUNTING_DATA.newAccountingRecord,
                accounts: fixDisabledAccounts(accountsClone),
                totalDebit: parseFloat(totalDebit).toFixed(2),
                totalCredit: parseFloat(totalCredit).toFixed(2)
            }));
        },
        onDelete: (rowIndex) => {
            if (!ACTIVITY.saveNewAccountingRecord) dispatch(setSaveNewAccountingRecord(true));
            let accounts = ACCOUNTING_DATA.newAccountingRecord.accounts.slice();
            accounts = accounts.map((a) => Object.assign({}, a));
            accounts.splice(rowIndex, 1);
            accounts = fixNetValueAndVat(accounts);
            let totalCredit = 0;
            let totalDebit = 0;
            for (let newAcc of accounts) {
                totalCredit = totalCredit + Number(newAcc.credit);
                totalDebit = totalDebit + Number(newAcc.debit);
            }
            dispatch(setNewAccountingRecord({
                ...ACCOUNTING_DATA.newAccountingRecord,
                accounts: accounts,
                totalCredit: parseFloat(totalCredit).toFixed(2),
                totalDebit: parseFloat(totalDebit).toFixed(2)
            }));
        }
    }

    const tableColumns = [
        {
            name: t("General.date"),
            field: "date",
            width: "10%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },{
            name: t("General.invoiceType"),
            field: "documentTypeName",
            hiddenColumn: false,
            editable: false,
            inputType: {
                type: 'text',
            }
        },{
            name: t("General.category"),
            field: "categoryName",
            width: "20%",
            hiddenColumn: false,
            editable: false,
            inputType: {
                type: 'text',
            }
        },{
            name: t("General.preNumber"),
            field: "preNumber",
            width: "10%",
            hiddenColumn: false,
            editable: false,
            inputType: {
                type: 'text',
            }
        },{
            name: t("General.number"),
            field: "number",
            width: "10%",
            hiddenColumn: false,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
    ];

    const fetchRecords = () => {
        setFilterLoading(true);
        axios.post(process.env.REACT_APP_API_URL2 + `/accounting/accounting-record-search`, {
            ...recordsRequestData
        }).then(res => {
            if (res.data && res.data.status === '200') {
                if (res.data.data?.data) {
                    const records = res.data.data.data;
                    records.forEach((el) => {
                        if (el.preNumber === 'noprefix') el.preNumber = '';
                    })
                    setTotalPages(res.data.data.totalPages);
                    setAccountingRecords(records);
                } else {
                    setAccountingRecords([]);
                }
            } else {
                setAccountingRecords([]);
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setFilterLoading(false);
        })
    }

    const handleCopyRecord = (e, rowData) => {
        dispatch(setNewAccountingRecord({
            date: moment(rowData.date).toDate(),
            time: moment().format("HH:mm"),
            year: company.year,
            company: company.id,
            accounts: rowData.accounts,
            totalCredit: rowData.totalCredit,
            totalDebit: rowData.totalDebit,
            category: rowData.category,
            categoryName: rowData.categoryName,
            subCategory: rowData.subCategory,
            subCategoryName: rowData.subCategoryName,
            documentType: rowData.documentType,
            documentTypeName: rowData.documentTypeName,
            myDataType: rowData.myDataType,
            recordType: rowData.recordType,
            installation: rowData.installation,
            paymentType: rowData.paymentType,
            paymentTypeName: rowData.paymentTypeName
        }));
        setshowCopyModal(false);
        if (!ACTIVITY.saveNewAccountingRecord) dispatch(setSaveNewAccountingRecord(true));
    }

    const toFirstPage = async () => {
        setRecordsRequestData({...recordsRequestData, page: 1});
        setCopyPageChanged(true);
    }
    const toNextPage = async () => {
        setRecordsRequestData({...recordsRequestData, page: recordsRequestData.page + 1});
        setCopyPageChanged(true);
    }
    const toPreviousPage = async () => {
        setRecordsRequestData({...recordsRequestData, page: recordsRequestData.page - 1});
        setCopyPageChanged(true);
    }
    const toLastPage = async () => {
        setRecordsRequestData({...recordsRequestData, page: totalPages});
        setCopyPageChanged(true);
    }

    const selectedPaymentType = getDefaultOptionByValue(paymentTypeOptions, ACCOUNTING_DATA.newAccountingRecord.paymentType, 'value', 'label', 'value');
    return (
        <React.Fragment>
            <div className="client-form product-form position-relative">
                {disabledFields && <div className="overlay_w">Επιλέξτε πρώτα κατηγορία και υποκατηγορία</div>}
                <Row className="border-bottom mb-3">
                    <Col sm={12} md={3} className={"mb-3"}>
                        <Dropdown
                            className={"showWithOverlay mb-0"}
                            label={t("General.category")}
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(categories, ACCOUNTING_DATA.newAccountingRecord.category, 'value', 'label', 'value')}
                            searchEnable={true}
                            required={true}
                            name="category"
                            options={categories}
                            onChange={(e) => handleOnChange(e, 'dd', 'category')}
                        />
                        {isEmpty(ACCOUNTING_DATA.newAccountingRecord.category) && (
                            <div className={"text-danger"}>Η κατηγορία είναι υποχρεωτική</div>
                        )}
                    </Col>
                    <Col sm={12} md={3}>
                        <Dropdown
                            className={"showWithOverlay"}
                            label={t("General.subCategory")}
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(subCategories, ACCOUNTING_DATA.newAccountingRecord.subCategory, 'value', 'label', 'value')}
                            searchEnable={true}
                            required={true}
                            name="subCategory"
                            options={subCategories}
                            onChange={(e) => handleOnChange(e, 'dd', 'subCategory')}
                        />
                        {isEmpty(ACCOUNTING_DATA.newAccountingRecord.subCategory) && (
                            <div className={"text-danger"}>Η υποκατηγορία είναι υποχρεωτική</div>
                        )}
                    </Col>
                    <Col sm={12} md={3}>
                        <Dropdown
                            className={"showWithOverlay"}
                            disabled={true}
                            label={t("General.logTypes")}
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(getRecordTypeOptions(), ACCOUNTING_DATA.newAccountingRecord.recordType, 'value', 'label', 'value')}
                            searchEnable={true}
                            name="recordType"
                            options={getRecordTypeOptions()}
                        />
                    </Col>
                    <Col sm={12} md={3} className={"d-flex align-items-center"}>
                        <Button className={"showWithOverlay mb-1"} size={"sm"} onClick={() => setshowCopyModal(true)}>
                            {t("General.copy")}
                        </Button>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col sm={12} md={3}>
                        <Dropdown
                            label={t('SalesNew.DocumentType')}
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(documentTypesOptions, ACCOUNTING_DATA.newAccountingRecord.documentType, 'value', 'label', 'value')}
                            required={true}
                            searchEnable={true}
                            name="documentType"
                            options={documentTypesOptions}
                            onChange={(e) => handleOnChange(e, 'dd', 'documentType')}
                        />
                    </Col>
                    {ACCOUNTING_DATA.newAccountingRecord?.myDataType === "5.1" && (
                        <React.Fragment>
                            <Col md={6}>
                                <AdvancedCombobox
                                    className="w-100 ac-select-dropdown"
                                    searchableDropdownEnable={false}
                                    label={t("General.correlatedΑrticle")}
                                    placeholder={t("Placeholders.correlatedΑrticle")}
                                    defaultValue={ACCOUNTING_DATA.newAccountingRecord?.correlatedRecordLabel}
                                    data={defaultCorrelatedData}
                                    onChange={(e) => handleOnChange(e, "correlatedComboBox")}
                                    excludeColumns={[]}
                                    columns={[
                                        {label: "Ημερομηνία", name: "date"},
                                        {label: "Τύπος Παραστατικού", name: "documentTypeName"},
                                        {label: "Αρίθμηση", name: "number"},
                                        {label: "Ποσό", name: "totalAmount"},
                                    ]}
                                    required={ACCOUNTING_DATA.newAccountingRecord?.myDataType === "5.1"}
                                    editEnable={false}
                                    deleteEnable={false}
                                    searchTableEnable={false}
                                    showTableOptionOnSelect={true}
                                    key={Math.random()}
                                    disabled={String(ACCOUNTING_DATA.newAccountingRecord?.correlatedRecord).length === 24}
                                    onChangeSearch={onChangeSearchCorrelatedRecord}
                                />
                            </Col>
                            <Col sm="12" md="1">
                                {String(ACCOUNTING_DATA.newAccountingRecord?.correlatedRecord).length === 24 &&
                                    <button style={{position: "absolute", bottom: "16px"}} className="btn btn-sm"
                                            title={t("General.clearCorrelatedΑrticle")} onClick={() => clearCorrelated()}>
                                        <i className="fas fa-xmark"></i></button>
                                }
                            </Col>
                            <Col md={2}>
                                {String(ACCOUNTING_DATA.newAccountingRecord?.correlatedRecord).length === 24 && (
                                    <Button size={"sm"} onClick={() => handleAutoFillCorrelatedAccounts()}>Αυτόματη συμπλήρωση λογαριασμών από συσχετιζόμενο άρθρο</Button>
                                )}
                            </Col>
                        </React.Fragment>
                    )}
                </Row>
                {showLoader ? <Loader/> : (
                    <>
                        <Row>
                            <Col sm="12" md="2" className={"mb-3"}>
                                {/* If "MyData rules" is enabled in "Settings-Document Types-Advanced Types", then FIELD IS READ ONLY. Select current date only. */}
                                <DateBox
                                    classes="w-100"
                                    label={t('SalesNew.Date')}
                                    name="date"
                                    selected={moment(ACCOUNTING_DATA.newAccountingRecord.date).isValid() ? new Date(ACCOUNTING_DATA.newAccountingRecord.date) : null}
                                    allowOlderDate={true}
                                    allowOlderDateValue={"999999"}
                                    onChange={(e) => handleOnChange(e, 'dp', 'date')}
                                />
                                {isEmpty(ACCOUNTING_DATA.newAccountingRecord.date) && <div className={"text-danger"}>Απαιτείται Ημερομηνία.</div> }
                            </Col>
                            <Col sm="12" md="1">
                                <HourPicker
                                    label={t('SalesNew.time')}
                                    selected={moment(ACCOUNTING_DATA.newAccountingRecord.time, "HH:mm").toDate()}
                                    onChange={(e) => handleOnChange(e, "tp", "time")}
                                />
                            </Col>
                            <Col sm="12" md="2">
                                <Dropdown
                                    label={t("General.installation")}
                                    options={installationOptions}
                                    value={getDefaultOptionByValue(installationOptions, ACCOUNTING_DATA.newAccountingRecord.installation, 'value', 'label', 'value')}
                                    enabledValue={true}
                                    searchEnable={true}
                                    required={true}
                                    name="installation"
                                    className={"mb-0"}
                                    onChange={(e) => handleOnChange(e, 'dd', 'installation')}
                                />
                                {ACTIVITY.saveNewAccountingRecord && ACCOUNTING_DATA.newAccountingRecord.installation === '' &&
                                    <div className="text-danger">Η εγκατάσταση είναι υποχρεωτική.</div>}
                            </Col>
                            <Col sm="12" md="1">
                                <Input
                                    className="mb-3 w-100"
                                    label={t("General.preNumber")}
                                    name="preNumber"
                                    type="text"
                                    value={ACCOUNTING_DATA.newAccountingRecord.preNumber}
                                    placeholder={t("General.preNumber")}
                                    onChange={(e) => handleOnChange(e)}
                                    disabled={false}
                                />
                            </Col>
                            <Col sm="12" md="1">
                                <Input
                                    className="mb-3 w-100"
                                    label={t("General.number")}
                                    name="number"
                                    type="number"
                                    required={ACCOUNTING_DATA.newAccountingRecord?.myDataType}
                                    value={ACCOUNTING_DATA.newAccountingRecord.number}
                                    placeholder={t("General.number")}
                                    onChange={(e) => handleOnChange(e)}
                                    disabled={false}
                                />
                                {ACTIVITY.saveNewAccountingRecord && ACCOUNTING_DATA.newAccountingRecord.number === '' && ACCOUNTING_DATA.newAccountingRecord?.myDataType !== '' &&
                                    <div className="text-danger">{t("Required.number")}</div>}
                            </Col>
                            <Col sm="12" md={hidePosBankField ? "5" : "3"}>
                                <Dropdown
                                    label={t('SalesNew.paymentType.paymentType')}
                                    options={paymentTypeOptions}
                                    value={Object.keys(selectedPaymentType).length !== 0 ? selectedPaymentType : ''}
                                    enabledValue={true}
                                    name="paymentType"
                                    searchEnable={true}
                                    onChange={(e) => handleOnChange(e, 'dd', 'paymentType')}
                                />
                                {requiredPaymentType && isEmpty(ACCOUNTING_DATA.newAccountingRecord.paymentType) && (
                                    <div className={"text-danger"}>Ο τρόπος πληρωμής είναι υποχρεωτικός</div>
                                )}
                            </Col>
                            {!hidePosBankField &&
                                <Col sm="12" md="2">
                                    <Dropdown
                                        label={t('SalesNew.posBankAccount')}
                                        options={posBankFieldOptions}
                                        defaultValue={getDefaultOptionByValue(posBankFieldOptions, ACCOUNTING_DATA.newAccountingRecord.bankAccount, 'value', 'label', 'value')}
                                        enabledValue={true}
                                        required={!hidePosBankField}
                                        name="bankAccount"
                                        searchEnable={true}
                                        onChange={(e) => handleOnChange(e, 'dd', 'bankAccount')}
                                    />
                                    {ACTIVITY.saveNewAccountingRecord && !hidePosBankField && ACCOUNTING_DATA.newAccountingRecord.bankAccount === "" &&
                                        <div className="text-danger">{t('SalesNew.posBankAccountRequired')}</div>}
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col sm="12" md="12">
                                <Input
                                    className="mb-3 w-100"
                                    label={t("General.reason")}
                                    name="reason"
                                    type="text"
                                    required={false}
                                    value={ACCOUNTING_DATA.newAccountingRecord.reason}
                                    placeholder={t("Placeholders.reason")}
                                    onChange={(e) => handleOnChange(e)}
                                    disabled={false}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <AccountingTable
                                functions={tableFunctions}
                                columns={accountingAccountsTableColumns}
                                arrayOfObjData={ACCOUNTING_DATA.newAccountingRecord.accounts}
                                totalDebit={parseFloat(ACCOUNTING_DATA.newAccountingRecord.totalDebit).toFixed(2)}
                                totalCredit={parseFloat(ACCOUNTING_DATA.newAccountingRecord.totalCredit).toFixed(2)}
                                switchCreditAndDebit={handleSwitchCreditAndDebit}
                            />
                        </Row>
                        <Row>
                            <div style={{width: "100%"}}>
                                <div className="text-muted mb-2"><i>{t("General.accountingRecordNotes")}</i></div>
                                <TextArea
                                    rows="4"
                                    placeholder={t("Placeholders.accountingRecordNotes")}
                                    name="notes"
                                    value={ACCOUNTING_DATA.newAccountingRecord.notes}
                                    onChange={(e) => handleOnChange(e)}
                                />
                            </div>
                        </Row>
                    </>)}
            </div>
            <Modal backdrop={"static"} className="themeModal" show={showModalConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('SalesNew.warning')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('SalesNew.warningMessage')}</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowModalConfirmation(false)}>{t('SalesNew.no')}</Button>
                    <Button variant="primary" onClick={() => handleConfirmCategory()}>{t('SalesNew.yes')}</Button>
                </Modal.Footer>
            </Modal>

            <Modal className="themeModal" show={Object.keys(documentToSwitch).length > 0}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("General.selectInvoiceType")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t("Confirm.changeInvoiceType")}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => handleSwitchDocumentType(false)}>Όχι</Button>
                    <Button variant="primary" onClick={() => handleSwitchDocumentType(true)}>Ναι</Button>
                </Modal.Footer>
            </Modal>

            <Modal className="themeModal" size={"xl"} show={showCopyModal} onHide={() => setshowCopyModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("General.copyAccountRecord")}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{minHeight: "80vh"}}>
                    <Row className={"d-flex align-items-center"}>
                        <Col md={3}>
                            <DateHelper dateFromAndToFunction={(from, to) => setRecordsRequestData({...recordsRequestData, date_from: from, date_to: to})}/>
                        </Col>
                        <Col md={2}>
                            <DateBox
                                name="date_from"
                                selected={isEmpty(recordsRequestData.date_from) ? "" : moment(recordsRequestData.date_from, "DD/MM/YYYY").toDate()}
                                label={t('SalesBalanceSheet.dateFrom')}
                                onChange={(e) => {
                                    if (moment(e).isValid()) {
                                        setRecordsRequestData({...recordsRequestData, date_from: moment(e).format("DD/MM/YYYY")});
                                    } else {
                                        setRecordsRequestData({...recordsRequestData, date_from: ""});
                                    }
                                }}
                            />
                        </Col>
                        <Col md={2}>
                            <DateBox
                                name="date_to"
                                selected={isEmpty(recordsRequestData.date_to) ? "" : moment(recordsRequestData.date_to, "DD/MM/YYYY").toDate()}
                                label={t('SalesBalanceSheet.dateTo')}
                                onChange={(e) => {
                                    if (moment(e).isValid()) {
                                        setRecordsRequestData({...recordsRequestData, date_to: moment(e).format("DD/MM/YYYY")})
                                    } else {
                                        setRecordsRequestData({...recordsRequestData, date_to: ""});
                                    }
                                }}
                            />
                        </Col>
                        <Col md={5}>
                            <Input
                                label={t("General.filter")}
                                name="recordFilter"
                                type="text"
                                required={false}
                                value={recordsRequestData.generalFilter}
                                onChange={(e) => setRecordsRequestData({...recordsRequestData, generalFilter: e.target.value})}
                                disabled={false}
                            />
                        </Col>
                    </Row>
                    <Row className={"d-flex justify-content-end"}>
                        <Col md={3} className={"d-flex justify-content-end mt-1"}>
                            <Button size={"lg"} variant="primary" disabled={filterLoading} onClick={() => fetchRecords()}>
                                {t("General.applyFilter")}
                                {filterLoading}
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                    {accountingRecords.length > 0 && (
                        <div className="d-flex justify-content-end mb-2">
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toFirstPage()}
                                disabled={recordsRequestData.page === 1 || filterLoading}
                            >
                                {t('SalesBalanceSheet.first')}
                            </Button>
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toPreviousPage()}
                                disabled={recordsRequestData.page === 1 || filterLoading}
                            >
                                {t('SalesBalanceSheet.previous')}
                            </Button>
                            <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1"
                                   bg="primary">{t('SalesBalanceSheet.page')} {recordsRequestData.page} / {totalPages}</Badge>
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toNextPage()}
                                disabled={recordsRequestData.page >= totalPages || filterLoading}
                            >
                                {t('SalesBalanceSheet.next')}
                            </Button>
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toLastPage()}
                                disabled={recordsRequestData.page >= totalPages || filterLoading}
                            >
                                {t('SalesBalanceSheet.last')}
                            </Button>
                        </div>
                    )}
                    <EditableTable
                        key = { Math.random() }
                        tableName="Accounting Records List"
                        allowToggleColumns = { false }
                        allowActions={ true }
                        actionButtons={{
                            edit: false,
                            print: false,
                            export: false,
                            delete: false,
                            select: true
                        }}
                        onDoubleClick={handleCopyRecord}
                        onSelect={(index, data) => handleCopyRecord(null, data)}
                        allowInsertRow={ false }
                        allowPagination={ false }
                        allowBulkDelete={ false }
                        allowSorting={ false }
                        allowSelectionCheckbox={ false }
                        enableNewRowCheckbox = { false }
                        columns={ tableColumns }
                        data={ accountingRecords }
                        perPage={20}
                    />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}
