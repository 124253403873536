import {Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import Dropdown from "../../Dropdown";
import Input from "../../../common/Input";
import React, {useEffect, useState} from "react";
import {
    getDocumentLabel,
    getDOYOptions,
    getGreekCurrencyFormat,
    getOptionsByData,
    isEmpty
} from "../../../../_helpers/commonFunctions";
import {useSelector} from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";
import {salesPaymentTypeData} from "../../../../_data/sales/sales-payment-types";
import EditableTable from "../../Editable-Table";
import {vatCategoryData} from "../../../../_data/mydata/vatCategory";
import {measurementUnitsData} from "../../../../_data/mydata/measurementUnits";
import {getBuysTaxesData, getBuysTotals, getTaxes} from "../../../../_helpers/product-buys-calculations";
import moment from "moment/moment";
import DateBox from "../../DateBox";
import {
    getECCategoryOptions,
    getECTypeOptions,
    getVatExpenseClassificationType, vat39ACategories
} from "../../../../_helpers/sharedmydata";

const NewBuyModal = ({show, setShow, classificationsRowData, refreshFunction, has39A}) => {
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const isEmronConnectFileERP = useSelector((state) => state.ACTIVITY.isEmronConnectFileERP);
    const BUYS = useSelector((state) => state.BUYS);
    const BUYS_SETTINGS = useSelector((state) => state.BUYS_SETTINGS);
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const [newBuy, setNewBuy] = useState({...BUYS.newBuysInitialValue});

    const [autoloadVatDetails, setAutoloadVatDetails] = useState(false);
    const [autoloadProducts, setAutoloadProducts] = useState(false);

    const [buysProductList, setBuysProductList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSupplier, setLoadingSupplier] = useState(false);
    const [supplierFound, setSupplierFound] = useState(false);

    const ecCategory = getECCategoryOptions();
    const ecType = getECTypeOptions();
    const vatExpenseTypes = getVatExpenseClassificationType();

    const calculateTaxesETC = (cloneBuy) => {
        let newInvLevelTax = {};
        let totalTaxes = 0;
        let totalWithheldTaxes = 0;
        if (classificationsRowData?.taxesTotals && classificationsRowData?.taxesTotals?.length > 0) {
            for (let tax of classificationsRowData.taxesTotals) {
                if (tax.taxType === "1") { // Withhold
                    cloneBuy.extraData.withHoldTax = tax.taxCategory;
                    if (!isEmpty(cloneBuy.extraData.withHoldTaxEuro)) {
                        cloneBuy.extraData.withHoldTaxEuro = Number(parseFloat(cloneBuy.extraData.withHoldTaxEuro) + parseFloat(tax.taxAmount)).toFixed(2);
                        newInvLevelTax.withholdTaxes = Number(parseFloat(newInvLevelTax.withholdTaxes) + parseFloat(tax.taxAmount)).toFixed(2);
                    } else {
                        cloneBuy.extraData.withHoldTaxEuro = tax.taxAmount;
                        newInvLevelTax.withholdTaxes = tax.taxAmount;
                    }
                    totalWithheldTaxes += Number(tax.taxAmount);
                } else if (tax.taxType === "2") { // Fee
                    cloneBuy.extraData.fee = tax.taxCategory;
                    if (!isEmpty(cloneBuy.extraData.feeEuro)) {
                        cloneBuy.extraData.feeEuro = Number(parseFloat(cloneBuy.extraData.feeEuro) + parseFloat(tax.taxAmount)).toFixed(2);
                        newInvLevelTax.fees = Number(parseFloat(newInvLevelTax.fees) + parseFloat(tax.taxAmount)).toFixed(2);
                    } else {
                        cloneBuy.extraData.feeEuro = tax.taxAmount;
                        newInvLevelTax.fees = tax.taxAmount;
                    }
                    totalTaxes += Number(tax.taxAmount);
                } else if (tax.taxType === "3") { // Other Tax
                    cloneBuy.extraData.otherTax = tax.taxCategory;
                    if (!isEmpty(cloneBuy.extraData.otherTaxEuro)) {
                        cloneBuy.extraData.otherTaxEuro = Number(parseFloat(cloneBuy.extraData.otherTaxEuro) + parseFloat(tax.taxAmount)).toFixed(2);
                        newInvLevelTax.otherTaxes = Number(parseFloat(newInvLevelTax.otherTaxes) + parseFloat(tax.taxAmount)).toFixed(2);
                    } else {
                        cloneBuy.extraData.otherTaxEuro = tax.taxAmount;
                        newInvLevelTax.otherTaxes = tax.taxAmount;
                    }
                    totalTaxes += Number(tax.taxAmount);
                } else if (tax.taxType === "4") { // Stamp
                    cloneBuy.extraData.stamp = tax.taxCategory;
                    if (!isEmpty(cloneBuy.extraData.stampEuro)) {
                        cloneBuy.extraData.stampEuro = Number(parseFloat(cloneBuy.extraData.stampEuro) + parseFloat(tax.taxAmount)).toFixed(2);
                        newInvLevelTax.stamp = Number(parseFloat(newInvLevelTax.stamp) + parseFloat(tax.taxAmount)).toFixed(2);
                    } else {
                        cloneBuy.extraData.stampEuro = tax.taxAmount;
                        newInvLevelTax.stamp = tax.taxAmount;
                    }
                    totalTaxes += Number(tax.taxAmount);
                }
            }
        }
        let productTotals = getBuysTotals(cloneBuy.products, SETTINGS);
        let productBuysTaxes = getBuysTaxesData(cloneBuy.products, cloneBuy.extraData, productTotals, SETTINGS);
        for (let obj of productBuysTaxes) {
            if (obj.vatEuro && typeof obj.vatEuro === "number") {
                obj.vatEuro = Number(Number(obj.vatEuro).toFixed(2));
            }
        }
        let findInvLevelTax = productBuysTaxes.find((el) => el.vatPercentage === "Invoice Level Tax");
        for (let key in newInvLevelTax) {
            findInvLevelTax[key] = newInvLevelTax[key];
        }
        // Correct Totals
        let findTotals = productBuysTaxes.find((el) => el.vatPercentage === "Totals");
        if (findTotals) {
            let totalFee = 0;
            let totalWithhold = 0;
            let totalOtherTax = 0;
            let totalStamp = 0;
            for (let prod of cloneBuy.products) {
                if (prod.fee) {
                    totalFee += prod.feeEuro;
                    totalTaxes += prod.feeEuro;
                }
                if (prod.stamp) {
                    totalStamp += prod.stampEuro;
                    totalTaxes += prod.stampEuro;
                }
                if (prod.withHoldTax) {
                    totalWithhold += prod.witholdTaxEuro;
                    totalWithheldTaxes += prod.witholdTaxEuro;
                }
                if (prod.otherTax) {
                    totalOtherTax += prod.otherTaxEuro;
                    totalTaxes += prod.otherTaxEuro;
                }
            }
            if (findInvLevelTax) {
                totalFee += Number(findInvLevelTax.fees);
                totalWithhold += Number(findInvLevelTax.withholdTaxes);
                totalOtherTax += Number(findInvLevelTax.otherTaxes);
                totalStamp += Number(findInvLevelTax.stamp);
            }
            findTotals.withholdTaxes = getGreekCurrencyFormat(totalWithhold);
            findTotals.otherTaxes = getGreekCurrencyFormat(totalOtherTax);
            findTotals.fees = getGreekCurrencyFormat(totalFee);
            findTotals.stamp = getGreekCurrencyFormat(totalStamp);
        }
        totalTaxes = Number(Number(totalTaxes).toFixed(2));
        totalWithheldTaxes = Number(Number(totalWithheldTaxes).toFixed(2));

        if (totalTaxes > 0 || totalWithheldTaxes > 0) {
            productTotals.withHoldTaxes = Number(Number(totalWithheldTaxes).toFixed(2));
            productTotals.totalTaxesAmount = Number(Number(totalTaxes).toFixed(2));
            productTotals.paymentAmount = Number(Number(productTotals.totalAmount) + totalTaxes - totalWithheldTaxes).toFixed(2);
            productTotals.totalAmount = Number(Number(productTotals.totalAmount) + totalTaxes).toFixed(2);
        }
        cloneBuy = {...cloneBuy, buysTaxes: productBuysTaxes, ...productTotals};

        return cloneBuy;
    }

    useEffect(() => {
        if (show === true) {
            console.log(classificationsRowData);
            let newBuyFields = { extraData: {...BUYS.newBuysInitialValue.extraData}, products: [], classifiedDataType: classificationsRowData.myDataType };
            newBuyFields.number = classificationsRowData.number;
            newBuyFields.preNumber = classificationsRowData.preNumber;
            newBuyFields.company = company.id;
            newBuyFields.year = String(company.year);
            newBuyFields.vat = classificationsRowData.vat;
            newBuyFields.installation = "0";
            newBuyFields.mark = classificationsRowData.mark;
            newBuyFields.date = moment(classificationsRowData.date, "DD/MM/YYYY").toDate();
            newBuyFields.time = moment().format("HH:mm");
            if (getNewBuyDocumentTypes().length >= 1) {
                newBuyFields.documentType = getNewBuyDocumentTypes()[0].value;
                newBuyFields.documentTypeName = getNewBuyDocumentTypes()[0].label;
            }
            if (getPaymentTypes().length >= 1) {
                newBuyFields.paymentType = getPaymentTypes()[0].value;
                newBuyFields.paymentTypeName = getPaymentTypes()[0].label;
                newBuyFields.paymentTypeType = salesPaymentTypeData.find((el) => String(el.myDataId) === classificationsRowData.myDataPaymentMethod)?.value;
                newBuyFields.myDataPaymentId = (classificationsRowData.myDataPaymentMethod === "2" || classificationsRowData.myDataPaymentMethod === "6") ? "1" : classificationsRowData.myDataPaymentMethod;
            }
            if (classificationsRowData?.classifications.length > 0) {
                for (let classi of classificationsRowData.classifications) {
                    let newProd = {...BUYS.newBuysNewProductInitialValue};
                    newProd.no = classi.lineNumber;
                    newProd.id = classi.lineNumber;
                    newProd.unitPrice = classi.netPrice;
                    newProd.vatId = classi.vatCategory;
                    newProd.vatPercentage = vatCategoryData.find((el) => String(el.code) === String(classi.vatCategory))?.percentage;
                    if (String(classi.vatCategory) === "8") {
                        newProd.vat = 0;
                        newProd.vatPercentage = 0;
                    } else {
                        newProd.vat = classi.vatAmount;
                    }
                    newProd.netValue = classi.netPrice;
                    newProd.totalValue = Number(Number(classi.netPrice) + Number(classi.vatAmount)).toFixed(2);
                    newProd.discount = 0;
                    newProd.isPerDiscount = false;
                    newProd.isDiscountAmount = true;
                    if (classi.quantity) newProd.quantity = Number(classi.quantity);
                    if (classi.measurementUnit)  {
                        newProd.mu = classi.measurementUnit;
                    } else {
                        newProd.mu = "4";
                    }
                    newProd.muLabel = measurementUnitsData.find((el) => el.code === newProd.mu)?.name;
                    newProd.muLabelShort = measurementUnitsData.find((el) => el.code === newProd.mu)?.shortName;
                    // Other stuff
                    classi?.withheldPercentCategory ? newProd.withHoldTax = String(classi?.withheldPercentCategory) : newProd.withHoldTax = null;
                    classi?.withheldAmount ? newProd.witholdTaxEuro = Number(classi?.withheldAmount) : newProd.witholdTaxEuro = 0;

                    classi?.feesPercentCategory ? newProd.fee = String(classi?.feesPercentCategory) : newProd.fee = null;
                    classi?.feesAmount ? newProd.feeEuro = Number(classi?.feesAmount) : newProd.feeEuro = 0;

                    classi?.stampDutyPercentCategory ? newProd.stamp = String(classi?.stampDutyPercentCategory) : newProd.stamp = null;
                    classi?.stampDutyAmount ? newProd.stampEuro = Number(classi?.stampDutyAmount) : newProd.stampEuro = 0;

                    classi?.otherTaxesPercentCategory ? newProd.otherTax = String(classi?.otherTaxesPercentCategory) : newProd.otherTax = null;
                    classi?.otherTaxesAmount ? newProd.otherTaxEuro = Number(classi?.otherTaxesAmount) : newProd.otherTaxEuro = 0;

                    if (!isEmpty(classi.classificationType)) {
                        newProd.prefilled = true; // Case it was classified but no buy exists
                        newProd.classificationType = classi.classificationType;
                    }
                    if (!isEmpty(classi.classificationCategory)) newProd.classificationCategory = classi.classificationCategory;
                    if (!isEmpty(classi.vatExpenseType)) newProd.vatExpenseType = classi.vatExpenseType;

                    newProd.taxes = "0.00";
                    newProd.taxes = getTaxes(newProd, SETTINGS);
                    if (has39A && classi.vatExemptionCategory === "16") {
                        newProd.vat39a = "0.00";
                        newProd.vat39aCategory = "1";
                        newProd.vatExpenseType = "VAT_366";
                    }
                    newBuyFields.products.push(newProd);
                }
            }
            newBuyFields = {...calculateTaxesETC(newBuyFields)};
            setNewBuy({...newBuy, ...newBuyFields});
            setAutoloadProducts(true);
        } else {
            // For modal smooth closing
            setTimeout(() => {
                setNewBuy({...BUYS.newBuysInitialValue});
                setSupplierFound(false);
                setLoading(false);
                setBuysProductList([]);
            }, 400)
        }
    }, [show])

    useEffect(() => {
        if (!isEmpty(newBuy.vat) && autoloadVatDetails === true) {
            setAutoloadVatDetails(false);
            fetchVatDetails(false, newBuy.vat);
        }
    }, [autoloadVatDetails])

    useEffect(() => {
        if (autoloadProducts === true) {
            setAutoloadProducts(false);
            loadProductListData(classificationsRowData.myDataType, null, null, true);
        }
    }, [autoloadProducts])

    const handleApplyProductToAll = (rowData) => {
        if(!isEmpty(rowData.classificationType) && !isEmpty(rowData.classificationCategory)) {
            let cloneBuy = structuredClone(newBuy);
            for (let prod of cloneBuy.products) {
                let cloneKeys = ["_id", "name", "code", "classificationType", "classificationCategory", "vatExpenseType", "accountingNonDeductibleVAT"]
                for (let key of cloneKeys) {
                    if (key === "vatExpenseType" && prod.vat39a) continue;
                    prod[key] = rowData[key];
                }
            }
            setNewBuy(cloneBuy);
        } else {
            toast.error("Παρακαλώ επιλέξτε κατηγορία ΚΑΙ τύπο MyData.");
        }
    }
    const handleCloseNewBuyModal = () => {
        setShow(false);
    }
    const getNewBuyDocumentTypes = () => {
        if (SETTINGS.settings.advancedTypesBuys) {
            return getOptionsByData(SETTINGS.settings.advancedTypesBuys?.filter((el) => el.classificationsArray?.includes(classificationsRowData.myDataType)), "_id", "name")
        } else {
            return [];
        }
    }
    const getPaymentTypes = () => {
        if (BUYS_SETTINGS.buysGeneralSettings.paymentTypes) {
            let searchMyDataId = classificationsRowData.myDataPaymentMethod;
            if (String(searchMyDataId) === "2" || String(searchMyDataId) === "6") {
                searchMyDataId = "1";
            }
            return getOptionsByData(BUYS_SETTINGS.buysGeneralSettings.paymentTypes?.filter((el) => String(el.myDataPaymentId) === searchMyDataId), "_id", "name");
        } else {
            return [];
        }
    }
    const handleNewBuyChange = (e, type, name) => {
        if (type === "dropdown") {
            if (name === "documentType") {
                setNewBuy({...newBuy, documentType: e.value, documentTypeName: e.label});
            } else if (name === "paymentType") {
                setNewBuy({...newBuy,
                    paymentType: e.value,
                    paymentTypeName: e.label,
                })
            } else if (name === "bankAccount") {
                setNewBuy({...newBuy,
                    bankAccount: e.value,
                    bankAccountName: e.label,
                })
            } else {
                setNewBuy({...newBuy, [name] : e.value})
            }
        }
    }
    const onProductSearch = (cellName, keyword, setData) => {
        if (keyword && String(keyword).length >= 2) {
            loadProductListData(classificationsRowData.myDataType, keyword, setData);
        }
    }
    const fetchVatDetails = async (fromButton = false, forceVAT) => {
        setLoadingSupplier(true);
        try {
            let vatToCheck = newBuy.vat;
            if (forceVAT) vatToCheck = forceVAT;
            if (vatToCheck && String(vatToCheck).length === 9) {
                const apiResponse = await axios.get(process.env.REACT_APP_API_URL2 + "/supplier/get-supplier-by-vat", {
                    headers: {'Content-Type': 'application/json'},
                    params: {
                        company: company.id,
                        vat: vatToCheck,
                        fromExpensesClassifications: "true",
                        classifiedDataType: newBuy.classifiedDataType,
                    }
                });
                if (apiResponse?.data?.status === "200" && apiResponse?.data?.data) {
                    const vatData = apiResponse.data.data;
                    let cloneBuy = {...newBuy};

                    cloneBuy = {...cloneBuy,
                        supplier: vatData._id,
                        supplierName: vatData.name,
                        smallName: vatData.smallName,
                        address: vatData.address,
                        city: vatData.city,
                        activity: vatData.activity,
                        pc: vatData.postalCode,
                        doy: vatData.DOY,
                        supplierInstallationType: "Κεντρικό",
                        supplierCode: vatData.systemCode,
                        email: vatData.email
                    }
                    if (apiResponse?.data?.productData) {
                        let prefillProd = apiResponse.data.productData;
                        for (let prod of cloneBuy.products) {
                            prod._id = prefillProd._id;
                            prod.code = prefillProd.code;
                            prod.name = prefillProd.name;
                            if (!prod.prefilled) prod.classificationCategory = prefillProd.classificationCategory;
                            if (!prod.prefilled) prod.classificationType = prefillProd.classificationType;
                            if (prefillProd.vatExpenseType && !prod.prefilled && !prod.vat39a) prod.vatExpenseType = prefillProd.vatExpenseType;
                            if (prefillProd.accountingNonDeductibleVAT) prod.accountingNonDeductibleVAT = prefillProd.accountingNonDeductibleVAT;
                        }
                    }
                    //cloneBuy = {...calculateTaxesETC(cloneBuy)};
                    setSupplierFound(true);
                    setLoadingSupplier(false);
                    setNewBuy(cloneBuy);
                } else {
                    axios.post(process.env.REACT_APP_API_URL2 + "/client/fetch-vat-details", {
                        vatNumber: vatToCheck,
                        onlyAADE: "true"
                    }, {
                        headers: {'Content-Type': 'application/json'}
                    }).then((res) => {
                        if (res.data && res.data.status === "200") {
                            const vatData = res.data.data;
                            setNewBuy({
                                ...newBuy,
                                supplierName: vatData?.name ? vatData.name : "",
                                smallName: vatData?.smallName ? vatData.smallName : "",
                                activity: vatData?.activity ? vatData.activity : "",
                                address: vatData?.address ? vatData.address : "",
                                doy: vatData?.doyCode ? vatData.doyCode : "",
                                city: vatData?.city ? vatData.city : "",
                                pc: vatData?.tk ? vatData.tk : "",
                                supplierInstallationType: "Κεντρικό",
                                supplierType: "New",
                            })
                            if (fromButton) toast.success(res.data.message);
                        } else {
                            if (fromButton) toast.error(res.data.message);
                        }
                        setLoadingSupplier(false);
                    }).catch((err) => {
                        if (fromButton) toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
                        console.log(err);
                        setLoadingSupplier(false);
                    })
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    const loadProductListData = (myDataType, keyword, setData, fromFirstLoad = false) => {
        let data = {
            myDataType: myDataType,
            company : company.id
        }
        if (!isEmpty(keyword)) data.keyword = keyword;
        axios.get(process.env.REACT_APP_API_URL2 + `/buy/product-list-by-mydatatype`,{
            params: data
        }).then(res => {
            if (res.data && res.data.status === '200') {
                if (res.data.data) {
                    if (setData) {
                        setData(res.data.data);
                    } else {
                        setBuysProductList(res.data.data);
                    }
                    if (fromFirstLoad && (res.data.data?.length === 0 || res.data.data?.length > 1)) {
                        setAutoloadVatDetails(true);
                    }
                    if (fromFirstLoad && res.data.data?.length === 1) {
                        const thatOneProduct = res.data.data[0];
                        setTimeout(() => {
                            let cloneBuy = {...newBuy};
                            let cloneProducts = [...cloneBuy.products];
                            cloneProducts = cloneProducts.map((o) => Object.assign({}, o));
                            for (let prod of cloneProducts) {
                                prod._id = thatOneProduct._id;
                                prod.name = thatOneProduct.name;
                                prod.code = thatOneProduct.code;
                                if (thatOneProduct.mydata && thatOneProduct.mydata?.length > 0) {
                                    const findMatching = thatOneProduct.mydata.find((el) => el.rowType === "expense" && String(el.invoiceType) === String(classificationsRowData.myDataType));
                                    if (findMatching && !prod.prefilled) {
                                        prod.classificationCategory = findMatching.category;
                                        prod.classificationType = findMatching.type;
                                        if (!prod.vat39a) prod.vatExpenseType = findMatching.vatExpenseType;
                                    }
                                }
                            }
                            //cloneBuy = {...calculateTaxesETC(cloneBuy)};
                            setNewBuy(cloneBuy);
                            setAutoloadVatDetails(true);
                        }, 100)
                    }
                } else {
                    if (setData) {
                        setData([]);
                    } else {
                        setBuysProductList([]);
                    }
                }
            } else {
                if (setData) {
                    setData([]);
                } else {
                    setBuysProductList([]);
                }
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const productTableFunctions = {
        update: (data, id, rowIndex) => {
            if (data.classificationCategory) {
                let cloneProducts = [...newBuy.products];
                cloneProducts = cloneProducts.map((o) => Object.assign({}, o));
                cloneProducts[rowIndex].classificationCategory = data.classificationCategory;
                setNewBuy({...newBuy, products: cloneProducts});
            } else if (data.classificationType) {
                let cloneProducts = [...newBuy.products];
                cloneProducts = cloneProducts.map((o) => Object.assign({}, o));
                cloneProducts[rowIndex].classificationType = data.classificationType;
                setNewBuy({...newBuy, products: cloneProducts});
            } else if (data.vatExpenseType !== undefined) {
                let cloneProducts = [...newBuy.products];
                cloneProducts = cloneProducts.map((o) => Object.assign({}, o));
                cloneProducts[rowIndex].vatExpenseType = data.vatExpenseType;
                setNewBuy({...newBuy, products: cloneProducts});
            } else if (data.name) {
                data = {...data.name};
                if (data && Object.keys(data).length > 0) {
                    let cloneProducts = [...newBuy.products];
                    cloneProducts = cloneProducts.map((o) => Object.assign({}, o));
                    cloneProducts[rowIndex]._id = data._id;
                    cloneProducts[rowIndex].name = data.name;
                    cloneProducts[rowIndex].code = data.code;
                    if (data.mydata && data.mydata?.length > 0) {
                        const findMatching = data.mydata.find((el) => el.rowType === "expense" && String(el.invoiceType) === String(classificationsRowData.myDataType));
                        if (findMatching) {
                            cloneProducts[rowIndex].classificationCategory = findMatching.category;
                            cloneProducts[rowIndex].classificationType = findMatching.type;
                            if (!cloneProducts[rowIndex].vat39a) cloneProducts[rowIndex].vatExpenseType = findMatching.vatExpenseType;
                        }
                    }
                    setNewBuy({...newBuy, products: cloneProducts});
                }
            } else if (data.accountingNonDeductibleVAT) {
                if (data.accountingNonDeductibleVAT === "true") {
                    let cloneProducts = [...newBuy.products];
                    cloneProducts = cloneProducts.map((o) => Object.assign({}, o));
                    cloneProducts[rowIndex].accountingNonDeductibleVAT = "inactive";
                    setNewBuy({...newBuy, products: cloneProducts});
                } else if (data.accountingNonDeductibleVAT === "false") {
                    let cloneProducts = [...newBuy.products];
                    cloneProducts = cloneProducts.map((o) => Object.assign({}, o));
                    cloneProducts[rowIndex].accountingNonDeductibleVAT = "active";
                    setNewBuy({...newBuy, products: cloneProducts});
                }
            } else if (data.vat39a) {
                let cloneProducts = [...newBuy.products];
                cloneProducts = cloneProducts.map((o) => Object.assign({}, o));
                cloneProducts[rowIndex].vat39a = data.vat39a;
                setNewBuy({...newBuy, products: cloneProducts});
            } else if (data.vat39aCategory) {
                let cloneProducts = [...newBuy.products];
                cloneProducts = cloneProducts.map((o) => Object.assign({}, o));
                cloneProducts[rowIndex].vat39aCategory = data.vat39aCategory;
                setNewBuy({...newBuy, products: cloneProducts});
            }
        },
        selected: () => {}
    }

    const handleSaveNewBuy = () => {
        setLoading(true);
        let expensesClassifications = [];
        for (let line of newBuy.products) {
            if (has39A) {
                if (line.vat39a) {
                    expensesClassifications.push({
                        "ecls:lineNumber": String(line.no),
                        "ecls:expensesClassificationDetailData": [
                            {
                                "ecls:classificationType": line.classificationType,
                                "ecls:classificationCategory": line.classificationCategory,
                                "ecls:amount": Number(line.netValue).toFixed(2),
                            },
                            {
                                "ecls:classificationType": "VAT_366",
                                "ecls:amount": Number(line.netValue).toFixed(2),
                                "ecls:vatAmount": Number(line.vat39a).toFixed(2),
                                "ecls:vatCategory": line.vat39aCategory,
                                "ecls:vatExemptionCategory": "16",
                            },
                        ],
                    })
                } else {
                    if (line.vatExpenseType && line.vatExpenseType !== "") {
                        expensesClassifications.push({
                            "ecls:lineNumber":  String(line.no),
                            "ecls:expensesClassificationDetailData": [
                                {
                                    "ecls:classificationType": line.classificationType,
                                    "ecls:classificationCategory": line.classificationCategory,
                                    "ecls:amount": Number(line.netValue).toFixed(2),
                                },
                                {
                                    "ecls:classificationType": line.vatExpenseType,
                                    "ecls:amount": Number(line.netValue).toFixed(2),
                                    "ecls:vatAmount": Number(line.vat).toFixed(2),
                                    "ecls:vatCategory": line.vatId,
                                },
                            ],
                        })
                    } else { // Throws 330 error on My Data (Used when no vatExpenseType, mostly for category2_5...)
                        expensesClassifications.push({
                            "ecls:lineNumber":  String(line.no),
                            "ecls:expensesClassificationDetailData": [
                                {
                                    "ecls:classificationType": line.classificationType,
                                    "ecls:classificationCategory": line.classificationCategory,
                                    "ecls:amount": Number(+line.netValue + +line.vat).toFixed(2),
                                },
                            ],
                        })
                    }
                }
            } else {
                if (line.vatExpenseType && line.vatExpenseType !== "") {
                    expensesClassifications.push({
                        "ecls:lineNumber": String(line.no),
                        "ecls:expensesClassificationDetailData": [
                            {
                                "ecls:classificationType": line.classificationType,
                                "ecls:classificationCategory": line.classificationCategory,
                                "ecls:amount": line.netValue,
                            },
                            {
                                "ecls:classificationType": line.vatExpenseType,
                                "ecls:amount": line.netValue,
                            }
                        ]
                    })
                } else {
                    expensesClassifications.push({
                        "ecls:lineNumber": String(line.no),
                        "ecls:expensesClassificationDetailData": {
                            "ecls:classificationType": line.classificationType,
                            "ecls:classificationCategory": line.classificationCategory,
                            "ecls:amount": Number(Number(line.netValue) + Number(line.vat)).toFixed(2),
                        }
                    })
                }
            }
        }
        const sendClassificationsRequest = {
            company: company.id,
            "ecls:invoiceMark": classificationsRowData.mark,
            "ecls:invoicesExpensesClassificationDetails": expensesClassifications,
            reportType: "buys",
            postPerInvoice: has39A ? "true" : "false",
        }
        // Send the request
        let cloneBuy = {...newBuy};
        cloneBuy.date = moment(cloneBuy.date).set({hour: 10, minute: 0}).toDate();
        axios.post(process.env.REACT_APP_API_URL2 + "/buy/add-buy-from-mydatareport", {buy: cloneBuy, classifications: sendClassificationsRequest}, {
            headers: {'Content-Type': 'application/json'}
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                if (isEmronConnectFileERP) {
                    localStorage.setItem(`${isEmronConnectFileERP}-status`, `200|${res.data.data._id}|${getDocumentLabel(res.data.data)}`);
                    return window.close();
                }
                toast.success(res.data.message);
                handleCloseNewBuyModal();
                refreshFunction();
            } else {
                toast.error(res.data.message, {autoClose: 7000});
            }
        }).catch((err) => {
            setLoading(false);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            console.log(err);
        })
    }

    const buyIsInvalid = () => {
        return isEmpty(newBuy.documentType) || isEmpty(newBuy.paymentType) || isEmpty(newBuy.vat)
            || newBuy.products.find((el) => isEmpty(el.name) && isEmpty(el._id))
            || newBuy.products.find((el) => isEmpty(el.classificationCategory) || isEmpty(el.classificationType))
            || Number(Number(newBuy.paymentAmount).toFixed(2)) !== Number(Number(classificationsRowData.totalAmount).toFixed(2))
            || !vatIsValid();
    }
    const productCodeColumns = [
        {
            label: "Κωδικός",
            name: "code",
        },
        {
            label: "Εναλλακτικός Κωδικός",
            name: "alternateCode",
        },
        {
            label: "Όνομα Είδους",
            name: "name",
        },
        {
            label: "Εναλλακτικό Όνομα",
            name: "alternateName",
        },
    ];
    let productTableColumns = [
        {
            name: "Αρ.",
            field: "no",
            width: "2%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Είδος",
            field: "name",
            width: "15%",
            editable: true,
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: productCodeColumns,
                    data: buysProductList,
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: 'Επιλέξτε είδος',
                    onChangeSearchCell: onProductSearch,
                },
                type: 'advanced-combobox',
            }
        },
        {
            name: "Ποσότητα",
            field: "quantity",
            editable: false,
            width: "5%",
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Μον. Μέτρησης",
            field: "muLabelShort",
            editable: false,
            width: "5%",
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Φ.Π.Α. %",
            field: "vatPercentage",
            editable: false,
            width: "5%",
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Καθαρή Αξία",
            field: "netValue",
            editable: false,
            width: "6%",
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 7
                }
            }
        },
        {
            name: "Αξία Φ.Π.Α.",
            field: "vat",
            editable: false,
            width: "6%",
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 7
                }
            }
        },
        {
            name: "Τελική Αξία",
            field: "totalValue",
            editable: false,
            width: "6%",
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 7
                }
            }
        },
        {
            name: "Κατηγορία MyData",
            field: "classificationCategory",
            editable: true,
            width: "17%",
            inputType: {
                type: 'select',
                options: ecCategory,
            }
        },
        {
            name: "Τύπος MyData",
            field: "classificationType",
            editable: true,
            width: "15%",
            inputType: {
                type: 'select',
                options: ecType,
            }
        },
        {
            name: "Τύπος Εξόδων για Φ.Π.Α.",
            field: "vatExpenseType",
            editable: true,
            disabledCallback: (rowData) => has39A && !isEmpty(rowData.vat39a),
            width: "13%",
            inputType: {
                type: 'select',
                options: vatExpenseTypes,
            }
        },
        {
            name: "Εκπίπτει Φ.Π.Α.",
            field: "accountingNonDeductibleVAT",
            editable: true,
            width: "4%",
            inputType: {
                type: "checkbox",
            }
        },
        {
            name: "Ενέργειες",
            field: "buttonActions",
            editable: true,
            width: "6%",
            inputType: {
                config: {
                    disabled: false,
                    onClick: handleApplyProductToAll,
                    text: "Όμοιο παντού"
                },
                type: 'button-sm',
            }
        },
    ]

    if (has39A) {
        productTableColumns = [...productTableColumns.map((obj) => {
            return {
                ...obj,
                width: String(parseFloat(obj.width) - 2) + "%",
            }
        }), {
            name: "Φ.Π.Α. Άρθρου 39Α",
            field: "vat39a",
            editable: false,
            editableCallback: (rowData) => has39A && !isEmpty(rowData.vat39a),
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 7
                }
            }
        }, {
            name: "Φ.Π.Α. Άθρ. 39Α %",
            field: "vat39aCategory",
            editable: false,
            editableCallback: (rowData) => has39A && !isEmpty(rowData.vat39a),
            inputType: {
                type: "select",
                options: vat39ACategories,
            }
        }]
    }

    const buysTaxesColumns = [
        {
            name: "Ποσοστό Φ.Π.Α. %",
            field: "vatPercentage",
            editable: false,
        },
        {
            name: "Καθαρή Αξία €",
            field: "netValue",
            editable: false,
        },
        {
            name: "Αξία Φ.Π.Α. €",
            field: "vatEuro",
            editable: false,
        },
        {
            name: "Παρακρατούμενοι Φόροι",
            field: "withholdTaxes",
            editable: false,
        },
        {
            name: "Λοιποί Φόροι",
            field: "otherTaxes",
            editable: false,
        },
        {
            name: "Τέλη",
            field: "fees",
            editable: false,
        },
        {
            name: "Χαρτόσημο",
            field: "stamp",
            editable: false,
        }
    ];

    const getTotalVAT = () => {
        let sum = 0;
        if (newBuy.buysTaxes) {
            for (let line of newBuy.buysTaxes) {
                if (line.vatPercentage !== "Totals" && !isNaN(line.vatEuro)) {
                    sum += Number(line.vatEuro);
                }
            }
            sum = Number(Number(sum).toFixed(2));
            return Number(sum).toFixed(2);
        }
        return 0;
    }

    const vatIsValid = () => {
        return Number(newBuy?.totalVatValue) >= Number(getTotalVAT()) - 1 && Number(newBuy?.totalVatValue) <= Number(getTotalVAT()) + 1;
    }

    return(
        <Modal backdrop={"static"} dialogClassName={"buysModalBig"} show={show} onHide={() => handleCloseNewBuyModal()}>
            <Modal.Header closeButton>
                <Modal.Title>Δημιουργία Αγοράς</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {Object.keys(classificationsRowData).length > 0 && (
                    <React.Fragment>
                        <Row>
                            <Col md={3}>
                                <Dropdown
                                    name={"documentType"}
                                    label={"Τύπος Παραστατικού"}
                                    options={getNewBuyDocumentTypes()}
                                    defaultValue={getNewBuyDocumentTypes().find((el) => el.value === newBuy.documentType) ? getNewBuyDocumentTypes().find((el) => el.value === newBuy.documentType) : ""}
                                    key={Math.random()}
                                    required={true}
                                    onChange={(e) => handleNewBuyChange(e, "dropdown", "documentType")}
                                />
                            </Col>
                            <Col md={2}>
                                <Input
                                    name={"preNumber"}
                                    label={"Σειρά"}
                                    value={newBuy.preNumber === "noprefix" ? "Κενή" : newBuy.preNumber}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={2}>
                                <Input
                                    name={"number"}
                                    label={"Αριθμός"}
                                    value={newBuy.number}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={2}>
                                <DateBox
                                    label={"Ημερομηνία"}
                                    name={"date"}
                                    selected={new Date(newBuy.date)}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={3}>
                                <Input
                                    name={"time"}
                                    label={"Ώρα"}
                                    value={newBuy.time}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <Input
                                    name={"vat"}
                                    label={`ΑΦΜ Προμηθευτή`}
                                    value={newBuy.vat}
                                    disabled={true}
                                />
                                {loadingSupplier && <Spinner style={{position: "absolute", right: "30px", top: "35px"}} size={"sm"} animation="border" variant="dark" />}
                            </Col>
                            <Col md={3}>
                                <Input
                                    name={"name"}
                                    label={"Όνομα"}
                                    value={newBuy.supplierName}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={6}>
                                <Input
                                    name={"activity"}
                                    label={"Δραστηριότητα"}
                                    value={newBuy.activity}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-3"}>
                            <Col md={3}>
                                <Input
                                    name={"address"}
                                    label={"Διεύθυνση"}
                                    value={newBuy.address}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={3}>
                                <Input
                                    name={"doy"}
                                    label={"ΔΟΥ"}
                                    value={getDOYOptions().find((el) => String(el.value) === newBuy.doy) ? getDOYOptions().find((el) => String(el.value) === newBuy.doy)?.label : newBuy.doy}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={3}>
                                <Input
                                    name={"city"}
                                    label={"Πόλη"}
                                    value={newBuy.city}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={3}>
                                <Input
                                    name={"pc"}
                                    label={"Ταχυδρομικός Κωδικός"}
                                    value={newBuy.pc}
                                    disabled={true}
                                />
                            </Col>
                            {/*
                            <Col md={1}>
                                <Button size={"sm"} onClick={() => fetchVatDetails(true)}>Συμπλήρωση στοιχείων από το μητρώο</Button>
                            </Col>
                            */}
                        </Row>
                        <Row className={"mt-3"}>
                            <Col md={3}>
                                <Dropdown
                                    name={"paymentType"}
                                    label={"Τρόπος Πληρωμής"}
                                    options={getPaymentTypes()}
                                    defaultValue={getPaymentTypes().find((el) => el.value === newBuy.paymentType) ? getPaymentTypes().find((el) => el.value === newBuy.paymentType) : ""}
                                    key={Math.random()}
                                    required={true}
                                    onChange={(e) => handleNewBuyChange(e, "dropdown", "paymentType")}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-3"}>
                            <EditableTable
                                tableName={"Buys Modal Products List"}
                                key={Math.random()}
                                actionButtons={{
                                    print: false,
                                    delete: false,
                                    export: false,
                                    edit: false,
                                }}
                                columns={productTableColumns}
                                onUpdate={productTableFunctions}
                                data={newBuy.products}
                                allowInsertRow={false}
                                allowActions={false}
                                allowToggleColumns={false}
                                allowPrintResults={false}
                            />
                        </Row>
                        <Row className="d-flex justify-content-end">
                            <Col md={8} style={{position: "inherit"}}>
                                <EditableTable
                                    className="buys-taxes-calculations"
                                    allowActions={false}
                                    key={Math.random()}
                                    data={newBuy.buysTaxes}
                                    columns={buysTaxesColumns}
                                    actionButtons={{
                                        print: false,
                                        delete: false,
                                        export: false,
                                        edit: false,
                                    }}
                                />
                            </Col>
                            <Col md={4}>
                                <table className="table table-bordered mb-3">
                                    <tbody>
                                    <tr>
                                        <td className="fw-bold">Συνολική Ποσότητα</td>
                                        <td>{newBuy.totalQuantity}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">Συνολική Καθαρή Αξία</td>
                                        <td>{getGreekCurrencyFormat(newBuy.totalNetValue)}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">Συνολική Αξία Φ.Π.Α.</td>
                                        <td>{getGreekCurrencyFormat(newBuy.totalVatValue)}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">Σύνολο Παρακρατούμενων Φόρων</td>
                                        <td>{getGreekCurrencyFormat(newBuy.withHoldTaxes)}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">Σύνολο Φόρων</td>
                                        <td>{getGreekCurrencyFormat(newBuy.totalTaxesAmount)}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">Συνολική Αξία</td>
                                        <td>{getGreekCurrencyFormat(newBuy.totalAmount)}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">Πληρωτέο Ποσό</td>
                                        <td>{getGreekCurrencyFormat(newBuy.paymentAmount)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-end">
                            <Col md={4}>
                                {supplierFound === false && <span className={"mt-2"}>Ο προμηθευτής δεν βρέθηκε και θα δημιουργηθεί αυτόματα κατά την αποθήκευση</span>}
                            </Col>
                        </Row>
                        {Number(Number(newBuy.paymentAmount).toFixed(2)) !== Number(Number(classificationsRowData.totalAmount).toFixed(2)) && (
                            <Row className="d-flex justify-content-end mt-2">
                                <Col md={4} style={{textAlign: "center", fontWeight: "700", fontSize: "18px"}}>
                                    <div style={{color: "red"}}>Προσοχή! Τα νούμερα δεν συμφωνούν με το My Data! Επικοινωνήστε με την Primer Software.</div>
                                </Col>
                            </Row>
                        )}
                        {!vatIsValid() && (
                            <Row className="d-flex justify-content-end mt-2">
                                <Col md={4} style={{textAlign: "center", fontWeight: "700", fontSize: "18px"}}>
                                    <div style={{color: "red"}}>Προσοχή! Τα ποσά του Φ.Π.Α. δεν συμφωνούν. Το άθροισμα τους είναι διαφορετικό από το σύνολο που παρουσιάζει το My Data. Παρακαλώ επικοινωνήστε με τον λογιστή σας.</div>
                                </Col>
                            </Row>
                        )}
                    </React.Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={() => handleCloseNewBuyModal()}>Κλείσιμο</Button>
                <Button variant="primary" onClick={() => handleSaveNewBuy()} disabled={loadingSupplier || loading || buyIsInvalid()}>
                    Χαρακτηρισμός & Αποθήκευση Αγοράς {loading && <Spinner animation="border" variant="dark" />}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default NewBuyModal