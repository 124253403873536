import React, {useRef} from "react";
import {isEmpty} from "../../../_helpers/commonFunctions";
import {epayLogoBase64, vivaLogoBase64} from "./print-constants";

const ClientPaymentPrintTemplate1Greek = React.forwardRef((props, ref) => {
    let templateData = [];
    require("./scss/clientpayment-print-template1.css");
    if (props.data) {
        templateData = props.data;
    }
    let showWaterMark = templateData.MYDATAENVIRONMENT === "test";
    const waterMarkStyle = {
        position: "absolute",
        transform: "translate(90px, -30%) rotate(-45deg)",
        left: "-30px",
        bottom: "50%",
        fontSize: "222px",
        margin: "0 0 -250px 0",
        color: "#cdcdcd",
        textTransform: "uppercase",
        opacity: "0.7",
    }

    const pageStyle = {
        width: "210mm",
        height: "297mm",
    }

    const skinBoldStyle = {
        color: "#555",
        fontWeight: "bold",
        lineHeight: "1.2",
    }

    const customerInformationStyle = {
        fontSize: "16px",
        textDecoration: "underline",
        color: "#555",
        fontWeight: "bold",
        textAlign: "center",
    }

    const informationCustomerIdStyle = {
        width: "23%",
        color: "#555",
        fontWeight: "bold",
    }

    const invoiceBoxStyle = {
        fontSize: "16px",
        //fontFamily: "'Helvetica Neue', Helvetica, Helvetica, Arial, sans-serif",
        fontFamily: "Arial",
        color: "#555",
        width: "100%",
        height: "100%",
        position: "relative",
    }

    const invoiceBoxTableTdStyle = {
        padding: "1px",
        border: "unset",
    }

    const logoContainerStyle = {
        fontSize: "30px",
        width: "40%",
        color: "#333",
        border: "none",
    }

    const totalTdBlock = {
        verticalAlign: "top",
        textAlign: "center",
        fontWeight: "700",
        fontSize: "16px",
        height: "100%",
    }

    const mainInfoTableTr = {
        // border: "1px solid #ddd",
        fontWeight: "700",
        textAlign: "center",
    }

    const productsStyle = {
        border: "1px solid black",
        textAlign: "center",
        fontSize: "16px",
    }

    const mainInfoTableHeadingPStyle = {
        margin: "4px",
        fontSize: "16px",
        lineHeight: 1,
    }

    const productContainerStyle = {
        marginTop: "4px",
        border: "2px solid #555",
        borderRadius: "4px",
        overflow: "hidden",
        height: "500px",
        padding: "2px",
    }

    const productTableHeadingStyle = {
        backgroundColor: "#555",
        color: "white",
    }

    const headingStyle = {
        backgroundColor: "#555",
        color: "white",
        border: "1px solid #ddd",
        fontWeight: "700",
        textAlign: "center",
    }

    const headingTdStyle = {
        margin: "4px",
        borderTop: "unset",
        borderBottom: "unset",
        borderRight: "2px solid #fff",
        borderLeft: "2px solid #fff",
    }

    const mainInfoStyle = {
        border: "1px solid #555",
        borderRadius: "4px",
        overflow: "hidden",
        padding: "3px 3px 0 3px",
    }

    const footerContainerStyle = {
        position: "absolute !important",
        bottom: "0px",
        width: "100%",
        margin: "20px auto auto auto",
        paddingBottom: "10",
    }

    const headerTableStyle = {
        width: "100%",
        textAlign: "left",
    }

    const issuerContainerStyle = {
        textAlign: "center",
        marginTop: "6px",
        border: "none",
        lineHeight: 1.2,
        padding: "10px",
    }

    const IssuerName = {
        fontSize: "15px",
        fontWeight: "bold",
        color: "#555",
        lineHeight: 1.2,
    }

    const issuerSubjectField = {
        fontWeight: "normal",
        fontStyle: "italic",
        fontSize: "15px",
        color: "#555",
        lineHeight: 1.2,
    }

    const issuerAddressStyle = {
        fontSize: "13px",
        color: "#555",
        lineHeight: 1.2,
    }

    const issuerContainerPStyle = {
        margin: "0px",
        fontSize: "14px",
        lineHeight: 1.2,
    }

    const informationTableStyle = {
        marginTop: "2px",
        width: "100%",
        tableLayout: "fixed",
    }

    const quantityHeadTd = {
        fontSize: "16px",
        textAlign: "center",
        borderRight: "1px solid #ddd",
    }

    const descriptionHeadTd = {
        fontSize: "16px",
        textAlign: "center",
        borderRight: "1px solid #ddd",
        width: "34%",
    }

    const priceHeadTdStyle = {
        fontSize: "16px",
        textAlign: "center",
        borderRight: "1px solid #ddd",
        width: "8%",
    }

    const infoValueStyle = {
        color: "#555",
        fontWeight: "normal",
        lineHeight: "1.2",
    }

    const contNotationStyle = {
        border: "1px solid #555",
        padding: "8px",
        borderRadius: "8px",
        fontSize: "13px",
        overflow: "hidden",
        overflowWrap: "break-word",
        height: "100%"
    }
    const contSignsStyle = {
        border: "1px solid #555",
        borderRadius: "8px",
        margin: "10px auto 0 auto",
        boxSizing: "border-box",
        textAlign: "center",
        width: "200px",
        height: "200px",
        overflow: "hidden",
        padding: "5px 0",
    }

    const footerTableTdStyle = {
        verticalAlign: "bottom",
        maxWidth: "300px",
        height: "100%",
    }

    const informationTdLeftStyle = {
        fontSize: "16px",
        border: 'none',
        padding: "0 0px",
    }

    const informationLeftStyle = {
        border: "1px solid #555",
        height: "330px",
        borderRadius: "4px",
        overflow: "hidden",
        padding: "1px",
    }

    const invoiceTableValues = {
        fontSize: "16px",
    }

    const descriptionValues = {
        fontSize: "16px",
    }
    const marginzero = {
        margin: "0"
    }

    const refHeight = useRef(null);

    return(
        <React.Fragment>
            <div style={marginzero} ref={ref}>
                <div style={pageStyle} className="clientpayment-print-template">
                    <div style={invoiceBoxStyle}>
                        {showWaterMark && <div style={waterMarkStyle}>ΑΚΥΡΟ</div>}
                        <div className="top_table">
                            <table style={{ width: "100%", textAlign: "left" }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <table style={headerTableStyle}>
                                                <tbody>
                                                <tr>
                                                    <td style={logoContainerStyle}>
                                                        {templateData.ISSUER_LOGO && templateData.ISSUER_LOGO !== "" && <img alt="Company_Logo" src={templateData.ISSUER_LOGO}></img>}
                                                    </td>
                                                    {templateData.BRANCH === "" &&
                                                        <td style={issuerContainerStyle}>
                                                            {templateData.ISSUER_NAME && templateData.ISSUER_NAME !== "" && <span style={IssuerName}>{templateData.ISSUER_NAME}</span>}
                                                            {templateData.ISSUER_SMALL_NAME && templateData.ISSUER_SMALL_NAME !== "" && <p style={issuerContainerPStyle}><span style={issuerSubjectField} >{templateData.ISSUER_SMALL_NAME}</span></p>}
                                                            {templateData.ISSUER_SUBJECTFIELD && templateData.ISSUER_SUBJECTFIELD !== "" && <p style={issuerContainerPStyle}><span style={issuerSubjectField}>{templateData.ISSUER_SUBJECTFIELD}</span></p>}
                                                            {templateData.ISSUER_ADDRESS && templateData.ISSUER_ADDRESS !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle} >{templateData.ISSUER_ADDRESS}, {templateData.ISSUER_CITY}, {templateData.ISSUER_TK}, ΤΗΛ: {templateData.ISSUER_PHONE}, ΔΟΥ: {templateData.ISSUER_DOY}</span></p>}
                                                            {templateData.ISSUER_VAT && templateData.ISSUER_VAT !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>ΑΦΜ: {templateData.ISSUER_VAT}, {!isEmpty(templateData.ISSUER_GEMH) && `ΓΕΜΗ: ${templateData.ISSUER_GEMH}, `} EMAIL: {templateData.ISSUER_EMAIL}</span></p>}
                                                            {!isEmpty(templateData.ISSUER_WEBPAGE) && (
                                                                <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>Ηλεκ. Ιστοσελίδα: {templateData.ISSUER_WEBPAGE}</span></p>
                                                            )}
                                                        </td>
                                                    }
                                                    {templateData.BRANCH !== "" &&
                                                        <td style={issuerContainerStyle}>
                                                            {templateData.ISSUER_NAME && templateData.ISSUER_NAME !== "" && <span style={IssuerName}>{templateData.ISSUER_NAME}</span>}
                                                            {templateData.ISSUER_SMALL_NAME && templateData.ISSUER_SMALL_NAME !== "" && <p style={issuerContainerPStyle}><span style={issuerSubjectField} >{templateData.ISSUER_SMALL_NAME}</span></p>}
                                                            {templateData.ISSUER_SUBJECTFIELD && templateData.ISSUER_SUBJECTFIELD !== "" && <p style={issuerContainerPStyle}><span style={issuerSubjectField}>{templateData.ISSUER_SUBJECTFIELD}</span></p>}
                                                            {templateData.ISSUER_ADDRESS && templateData.ISSUER_ADDRESS !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle} >Υποκατάστημα: {templateData.BRANCH?.address}, {templateData.BRANCH?.city}, {templateData.BRANCH?.postalCode}</span></p>}
                                                            {templateData.ISSUER_PHONE && templateData.ISSUER_PHONE !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>ΤΗΛΕΦΩΝΟ: {templateData.BRANCH?.phone}</span></p>}
                                                            {templateData.ISSUER_DOY && templateData.ISSUER_DOY !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>ΔΟΥ: {templateData.BRANCH?.doy}</span></p>}
                                                            {templateData.ISSUER_VAT && templateData.ISSUER_VAT !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>ΑΦΜ: {templateData.ISSUER_VAT}</span></p>}
                                                            {templateData.ISSUER_GEMH && templateData.ISSUER_GEMH !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>ΓΕΜΗ: {templateData.ISSUER_GEMH}</span></p>}
                                                            {templateData.ISSUER_EMAIL && templateData.ISSUER_EMAIL !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>EMAIL: {templateData.ISSUER_EMAIL}</span></p>}
                                                            {!isEmpty(templateData.ISSUER_WEBPAGE) && (
                                                                <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>Ηλεκ. Ιστοσελίδα: {templateData.ISSUER_WEBPAGE}</span></p>
                                                            )}
                                                        </td>
                                                    }
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={mainInfoStyle}>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <table style={{ width: "100%" }}>
                                                <tbody>
                                                <tr style={headingStyle}>
                                                    <td style={headingTdStyle}><p style={mainInfoTableHeadingPStyle}>ΕΙΔΟΣ ΠΑΡΑΣΤΑΤΙΚΟΥ</p></td>
                                                    <td style={headingTdStyle}><p style={mainInfoTableHeadingPStyle}>ΣΕΙΡΑ</p></td>
                                                    <td style={headingTdStyle}><p style={mainInfoTableHeadingPStyle}>ΑΡΙΘΜΟΣ</p></td>
                                                    <td style={headingTdStyle}><p style={mainInfoTableHeadingPStyle}>ΗΜΕΡ/ΝΙΑ</p></td>
                                                    <td style={headingTdStyle}><p style={mainInfoTableHeadingPStyle}>ΩΡΑ</p></td>
                                                </tr>
                                                <tr style={mainInfoTableTr}>
                                                    <td style={invoiceBoxTableTdStyle}><span style={invoiceTableValues}>{templateData.INVOICE_TYPE ? templateData.INVOICE_TYPE : '-'}</span></td>
                                                    <td style={invoiceBoxTableTdStyle}><span style={invoiceTableValues}>{templateData.SERIES ? String(templateData.SERIES).replace("noprefix", "Κενή") : '-'}</span></td>
                                                    <td style={invoiceBoxTableTdStyle}><span style={invoiceTableValues}>{templateData.NUMBER ? templateData.NUMBER : '-'}</span></td>
                                                    <td style={invoiceBoxTableTdStyle}><span style={invoiceTableValues}>{templateData.INVOICE_DATE ? templateData.INVOICE_DATE : '-'}</span></td>
                                                    <td style={invoiceBoxTableTdStyle}><span style={invoiceTableValues}>{templateData.INVOICE_TIME ? templateData.INVOICE_TIME : '-'}</span></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table style={informationTableStyle} className="information_table">
                                <tbody>
                                <tr>
                                    <td style={informationTdLeftStyle}>
                                        <div style={informationLeftStyle}>
                                            <table style={{width: "100%"}}>
                                                <tbody>
                                                <tr>
                                                    <td colSpan="2" style={customerInformationStyle}>
                                                        <span>ΣΤΟΙΧΕΙΑ ΠΕΛΑΤΗ</span>
                                                    </td>
                                                </tr>
                                                {
                                                    templateData.CUSTOMER_CODE !== '' && templateData.CUSTOMER_CODE &&
                                                    <tr>
                                                        <td style={informationCustomerIdStyle}>
                                                            <span style={descriptionValues}>ΚΩΔΙΚΟΣ:</span>
                                                        </td>
                                                        <td style={infoValueStyle}>
                                                            <span
                                                                style={descriptionValues}>{templateData.CUSTOMER_CODE}</span>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    templateData.CUSTOMER_NAME !== '' && templateData.CUSTOMER_NAME &&
                                                    <tr>
                                                        <td style={informationCustomerIdStyle}>
                                                            <span style={descriptionValues}>ΟΝΟΜΑ:</span>
                                                        </td>
                                                        <td style={infoValueStyle}>
                                                            <span
                                                                style={descriptionValues}>{templateData.CUSTOMER_NAME}</span>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    templateData.CUSTOMER_COMPANY !== '' && templateData.CUSTOMER_COMPANY &&
                                                    <tr>
                                                        <td style={informationCustomerIdStyle}>
                                                            <span style={descriptionValues}>ΕΠΩΝΥΜΙΑ:</span>
                                                        </td>
                                                        <td style={infoValueStyle}>
                                                            <span
                                                                style={descriptionValues}>{templateData.CUSTOMER_COMPANY}</span>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    templateData.CUSTOMER_ACTIVITY !== '' && templateData.CUSTOMER_ACTIVITY &&
                                                    <tr>
                                                        <td style={skinBoldStyle}><span
                                                            style={descriptionValues}>ΕΠΑΓΓΕΛΜΑ:</span></td>
                                                        <td style={infoValueStyle}>
                                                            <span
                                                                style={descriptionValues}>{templateData.CUSTOMER_ACTIVITY}</span>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    templateData.CUSTOMER_EMAIL !== '' && templateData.CUSTOMER_EMAIL &&
                                                    <tr>
                                                        <td style={skinBoldStyle}>
                                                            <span style={descriptionValues}>EMAIL:</span>
                                                        </td>
                                                        <td style={infoValueStyle}>
                                                            <span
                                                                style={descriptionValues}>{templateData.CUSTOMER_EMAIL}</span>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    templateData.CUSTOMER_PHONE !== '' && templateData.CUSTOMER_PHONE &&
                                                    <tr>
                                                        <td style={skinBoldStyle}>
                                                            <span style={descriptionValues}>ΤΗΛΕΦΩΝΟ:</span>
                                                        </td>
                                                        <td style={infoValueStyle}>
                                                            <span
                                                                style={descriptionValues}>{templateData.CUSTOMER_PHONE}</span>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    templateData.CUSTOMER_VAT !== '' && templateData.CUSTOMER_VAT &&
                                                    <tr>
                                                        <td style={skinBoldStyle}>
                                                            <span style={descriptionValues}>Α.Φ.Μ.:</span>
                                                        </td>
                                                        <td style={infoValueStyle}>
                                                            <span
                                                                style={descriptionValues}>{templateData.CUSTOMER_VAT}</span>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    templateData.CUSTOMER_DOY !== '' && templateData.CUSTOMER_DOY &&
                                                    <tr>
                                                        <td style={skinBoldStyle}>
                                                            <span style={descriptionValues}>Δ.Ο.Υ.:</span>
                                                        </td>
                                                        <td style={infoValueStyle}>
                                                            <span
                                                                style={descriptionValues}>{templateData.CUSTOMER_DOY}</span>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    templateData.CUSTOMER_ADDRESS !== '' && templateData.CUSTOMER_ADDRESS &&
                                                    <tr>
                                                        <td style={skinBoldStyle}>
                                                            <span style={descriptionValues}>ΔΙΕΥΘΥΝΣΗ:</span>
                                                        </td>
                                                        <td style={infoValueStyle}>
                                                            <span
                                                                style={descriptionValues}>{templateData.CUSTOMER_ADDRESS}, </span>
                                                            {templateData.CUSTOMER_CITY !== '' && templateData.CUSTOMER_CITY &&
                                                                <span
                                                                    style={descriptionValues}>{templateData.CUSTOMER_CITY}, </span>
                                                            }
                                                            {templateData.CUSTOMER_ZIP !== '' && templateData.CUSTOMER_ZIP &&
                                                                <span
                                                                    style={descriptionValues}>{templateData.CUSTOMER_ZIP}</span>
                                                            }
                                                        </td>
                                                    </tr>
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                    {templateData?.TRANSACTION_DETAILS && (
                                        <td style={informationTdLeftStyle}>
                                            <div style={{...informationLeftStyle, textAlign: "center", paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px"}}>
                                                {templateData.TRANSACTION_DETAILS?.isEuronet === "true" && (
                                                    <React.Fragment>
                                                        <img style={{margin: "0", width: "20mm"}} className={"mb-2"}
                                                             src={epayLogoBase64} alt={"ePay Logo"}/>
                                                        <br/>
                                                        <span style={{fontSize: "12px"}}>EURONET MERCHANT SERVICES A.E. GEMH: 159277401000</span>
                                                    </React.Fragment>
                                                )}
                                                {templateData.TRANSACTION_DETAILS?.isEuronet === "viva" && (
                                                    <React.Fragment>
                                                        <img style={{margin: "0", width: "15mm"}} src={vivaLogoBase64} alt={"Viva Logo"}/>
                                                        <br/>
                                                    </React.Fragment>
                                                )}
                                                <table className={"transactionDetailsTable mt-2"} style={{width: "100%", tableLayout: "fixed"}}>
                                                    <tbody>
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>{templateData.TRANSACTION_DETAILS?.transactionDate}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>TID/MID:</td>
                                                        <td>{templateData.TRANSACTION_DETAILS?.terminalId} / {templateData.TRANSACTION_DETAILS?.merchantId}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>ΑΡ.
                                                            ΣΥΝ.: {templateData.TRANSACTION_DETAILS?.transactionNumber}</td>
                                                        <td>ΕΓΚΡΙΣΗ: {templateData.TRANSACTION_DETAILS?.authorizationCode}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>RRN.:</td>
                                                        <td>{templateData.TRANSACTION_DETAILS?.rnn}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>ΜΕΣΩ:</td>
                                                        <td>
                                                            {templateData.TRANSACTION_DETAILS?.isEuronet === "true" && `EPAY`}
                                                            {templateData.TRANSACTION_DETAILS?.isEuronet === "viva" && `VIVA`}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{templateData.TRANSACTION_DETAILS?.cardType}</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{templateData.TRANSACTION_DETAILS?.aid}</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{templateData.TRANSACTION_DETAILS?.panCard} - {templateData.TRANSACTION_DETAILS?.finalCode}</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td>ΠΟΣΟ:</td>
                                                        <td>{templateData.TRANSACTION_DETAILS?.amountTotalString} €</td>
                                                    </tr>
                                                    <tr>
                                                        <td>ΦΙΛΟΔΩΡΗΜΑ:</td>
                                                        <td>{templateData.TRANSACTION_DETAILS?.tipAmountString} €</td>
                                                    </tr>
                                                    <tr>
                                                        <td>ΥΠΟΓΡΑΦΗ ΠΑΡΟΧΟΥ</td>
                                                        <td style={{wordBreak: "break-all"}}>{templateData.TRANSACTION_DETAILS?.providerSignature}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>ΜΟΝ. ΤΑΥΤ. ΠΛΗΡΩΜΗΣ</td>
                                                        <td>{templateData.TRANSACTION_DETAILS?.providerTransactionId}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    )}
                                </tr>
                                </tbody>
                            </table>
                            <div style={productContainerStyle}>
                                <table style={{width: "100%"}} ref={refHeight}>
                                    <tbody>
                                    <tr style={productTableHeadingStyle}>
                                        <td style={quantityHeadTd}><p style={{
                                            fontSize: "14px",
                                            lineHeight: "1",
                                            margin: "10px 0",
                                        }}> ΗΜΕΡΟΜΗΝΙΑ</p></td>
                                        <td style={quantityHeadTd}><p
                                            style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> ΤΡΟΠΟΣ
                                            ΠΛΗΡΩΜΗΣ</p></td>
                                        <td style={descriptionHeadTd}><p style={{
                                            fontSize: "14px",
                                            lineHeight: "1",
                                            margin: "10px 0",
                                        }}> ΠΕΡΙΓΡΑΦΗ</p></td>
                                        <td style={priceHeadTdStyle}><p
                                            style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> ΑΞΙΑ</p>
                                        </td>
                                    </tr>
                                    <tr style={productsStyle}>
                                        <td>{templateData.PAYMENT_DATE}</td>
                                        <td>{templateData.PAYMENT_TYPE}</td>
                                        <td>{""}</td>
                                        <td>{templateData.PAYMENT_AMOUNT}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={footerContainerStyle}>
                                <table
                                    style={{height: "100%", minHeight: "200px", width: "100%", tableLayout: "fixed"}}>
                                    {(() => {
                                        if (!(templateData.AUTHCODE && templateData.QR_CODE)) {
                                            return (
                                                <colgroup>
                                                    <col span={1} style={{width: "70%"}}></col>
                                                    <col span={1} style={{width: "30%"}}></col>
                                                </colgroup>
                                            )
                                        } else {
                                            return (
                                                <colgroup>
                                                    <col span={1} style={{width: "40%"}}></col>
                                                    <col span={1} style={{width: "30%"}}></col>
                                                    <col span={1} style={{width: "30%"}}></col>
                                                </colgroup>
                                            )
                                        }
                                    })()}
                                    <tbody>
                                    <tr style={{height: "100%"}}>
                                        <td style={footerTableTdStyle}>
                                            <div style={contNotationStyle}>
                                                <span style={skinBoldStyle}>ΠΑΡΑΤΗΡΗΣΕΙΣ:</span>
                                                <br />
                                                <span>{templateData?.INVOICE_NOTES}</span>
                                            </div>
                                        </td>
                                        {(templateData.AUTHCODE && templateData.QR_CODE) && (
                                            <td>
                                                <div style={contSignsStyle}>
                                                    <img src={templateData?.QR_CODE} alt="Qr code"/>
                                                </div>
                                            </td>
                                        )}
                                        <td style={totalTdBlock}>
                                            <div style={{
                                                backgroundColor: "#555",
                                                color: "white",
                                                padding: "10px",
                                                width: "100%",
                                                borderTopLeftRadius: "8px",
                                                borderTopRightRadius: "8px"
                                            }}>ΠΛΗΡΩΤΕΟ ΠΟΣΟ
                                            </div>
                                            <div style={{
                                                width: "100%",
                                                height: "60%",
                                                backgroundColor: "white",
                                                color: "black",
                                                paddingTop: "15%",
                                                fontSize: "20px",
                                                border: "2px solid #555",
                                                borderBottomLeftRadius: "6px",
                                                borderBottomRightRadius: "6px",
                                                verticalAlign: "middle"
                                            }}>{templateData.PAYMENT_AMOUNT}</div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={"mt-1"}>
                                {(templateData.MYDATA_DESTINATION && templateData.MYDATA_DESTINATION === "provider") && (
                                    <React.Fragment>
                                        {((templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "1") || templateData.AUTHCODE) && (
                                            <div>
                                                <span>www.primer.gr/search-invoice Provided by Primer Software P.C.</span>
                                                <br/>
                                                <span>Άδεια ΥΠΑΗΕΣ: 2021_01_107Primer Software ΙΚΕ_001_ Primer MyData_V1_21012021</span>
                                            </div>
                                        )}
                                        {templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "1" &&
                                            <span>Απώλεια Διασύνδεσης Οντότητας - Παρόχου - Transmission Failure_1 <br/></span>}
                                        {templateData.MARK && <span>MARK: {templateData?.MARK} <br/></span>}
                                        {templateData.UID && <span>UID: {templateData?.UID} <br/></span>}
                                        {templateData.AUTHCODE && <span>AUTHCODE: {templateData?.AUTHCODE} <br/></span>}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
})

export default ClientPaymentPrintTemplate1Greek
