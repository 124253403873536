import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {Badge, Button, Col, Row, Spinner} from "react-bootstrap";
import DateBox from "../../../primer/DateBox";
import moment from "moment";
import {
    setClientMovementsReportRequestData,
    setClientMovementsReportResponseData,
    setEditClientMovements,
    setEditClientPayment,
} from "../../../../_reducers/DataPanel/Clients/clientSlice";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import axios from "axios";
import {useTranslation} from "react-i18next";
import Loader from "../../../primer/POPUP-COMPONENTS/Loader/PrimerLoader";
import {checkPermission, getGreekCurrencyFormat, isEmpty} from "../../../../_helpers/commonFunctions";
import BetterCheckBox from "../../../common/BetterCheckBox";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import BootstrapDropdown from "react-bootstrap/Dropdown";
import "./tableStyle.css";
import {addTab, updateActiveMainTab} from "../../../../_reducers/TabsSlice";
import {setEditSales} from "../../../../_reducers/DataPanel/Sales/salesSlice";
import * as XLSX from "xlsx";
import {jsPDF} from "jspdf";
import {liberationSerif} from "../../../../fonts/liberationSerif";
import {dejavuSans} from "../../../../fonts/DejaVuSans-normal";
import {dejavuSansBold} from "../../../../fonts/DejaVuSans-Bold-bold";
import {classicStyleBelowNavbar} from "../Statics";

const ClientsMovementsReport = () => {
    const [t] = useTranslation("common");
    const dispatch = useDispatch();
    const CLIENTS_DATA = useSelector((state) => state.CLIENTS_DATA);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const requestData = useSelector((state) => state.CLIENTS_DATA.clientMovementsReportRequestData);
    const responseData = useSelector((state) => state.CLIENTS_DATA.clientMovementsReportResponseData);
    const [pageChanged, setPageChanged] = useState(false);
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));

    const [clientsListData, setClientsListData] = useState([]);
    const sourceRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [reportLoader, setReportLoader] = useState(false);

    const [iframeData, setIFrameData] = useState("");
    const iframeRef = useRef();

    useEffect(() => {
        loadClientsListData(null, setClientsListData);
    }, []);

    useEffect(() => {
        let clientData = CLIENTS_DATA.clientMovementsSearchByBalance;
        if (clientData && clientData?._id) {
            axios.get(process.env.REACT_APP_API_URL2 + `/client/get-client-id-by/${clientData?._id}`)
            .then((res) => {
                if (res.data && res.data.status === "200") {
                    dispatch(setClientMovementsReportRequestData({...requestData, clientName: clientData?.clientName, client_inactive: "active", client_zero: "active", page: 1}));
                    setPageChanged(true);
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [CLIENTS_DATA.clientMovementsSearchByBalance])

    useEffect(() => {
        if (pageChanged) {
            setPageChanged(false);
            fetchClientMovements(true);
        }
    }, [pageChanged])

    const loadClientsListData = (name, setData) => {
        let reqData = {
            company: company.id,
        }
        if (!isEmpty(name)) reqData.name = name;

        axios.post(process.env.REACT_APP_API_URL2 + `/client/list-client`, reqData, {
            headers: { "Content-Type": "application/json" }
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                if (res.data.data) {
                    let filteredData = res.data.data;
                    if (setData) setData(filteredData);
                } else {
                    if (setData) setData([]);
                }
            } else {
                if (setData) setData([]);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const clientDropdownColumns = [
        {
            label: t('ClientsReportsClientMovement.tableClient.clientName'),
            name: "name",
        },
        {
            label: t('ClientsReportsClientMovement.tableClient.vatNumber'),
            name: "vatNumber",
        },
        {
            label: t('ClientsReportsClientMovement.tableClient.phone'),
            name: "phone",
        },
    ];

    const handleOnChange = (e, type = "default", name = "") => {
        if (type === "datebox") {
            if (moment(e).isValid()) {
                dispatch(setClientMovementsReportRequestData({...requestData, [name]: moment(e).format("DD/MM/YYYY")}))
            } else {
                dispatch(setClientMovementsReportRequestData({...requestData, [name]: ""}))
            }
        } else if (type === "acdd") {
            if (name === "clientName") {
                dispatch(setClientMovementsReportRequestData({...requestData, clientName: e.row.name}));
            }
        } else if (type === "checkbox") {
            dispatch(setClientMovementsReportRequestData({...requestData, [name]: e.target.checked === true ? "active" : "inactive"}))
        } else if (type === "input") {
            dispatch(setClientMovementsReportRequestData({...requestData, [name] : e.target.value}));
        }
    }

    const fetchClientMovements = (fromPagination = false) => {
        setLoading(true);
        let reqData = {
            ...requestData,
            company: company.id,
            year: company.year
        }
        if (!fromPagination) {
            setReportLoader(true);
            dispatch(setClientMovementsReportRequestData({...requestData, page: 1}));
            reqData.page = 1;
        }
        sourceRef.current = axios.CancelToken.source();
        axios.post(process.env.REACT_APP_API_URL2 + `/report/client-movements-report`, reqData, {
            headers: {"Content-Type": "application/json"},
            cancelToken: sourceRef.current.token
        }).then((res) => {
            setLoading(false);
            setReportLoader(false);
            if (res.data.status === "200") {
                dispatch(setClientMovementsReportResponseData({...res.data.data}));
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            setLoading(false);
            setReportLoader(false);
            console.log(error);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const toFirstPage = () => {
        dispatch(setClientMovementsReportRequestData({...requestData, page: 1}));
        setPageChanged(true);
    }
    const toLastPage = () => {
        dispatch(setClientMovementsReportRequestData({...requestData, page: responseData.totalPages}));
        setPageChanged(true);
    }
    const toPreviousPage = () => {
        dispatch(setClientMovementsReportRequestData({...requestData, page: requestData.page - 1}));
        setPageChanged(true);
    }
    const toNextPage = () => {
        dispatch(setClientMovementsReportRequestData({...requestData, page: requestData.page + 1}));
        setPageChanged(true);
    }

    const onChangeSearch = (keyword, setData) => {
        if (keyword.length >= 2) {
            loadClientsListData(keyword, setData);
        }
    }
    const handleCancel = () => {
        if (sourceRef.current) {
            sourceRef.current.cancel('Request cancelled by user');
        }
        setReportLoader(false);
    }
    const handlePDFExport = (printer = false) => {
        let headers = ["Αρ.", "Ημερομηνία", "Τύπος Παραστατικού", "Αρίθμηση", "Πώληση", "Πληρωμή", "Υπόλοιπο"];
        if (requestData.showNotes === "active") {
            headers = ["Αρ.", "Ημερομηνία", "Τύπος Παραστατικού", "Αρίθμηση", "Σημειώσεις", "Πώληση", "Πληρωμή", "Υπόλοιπο"];
        }
        let dateFrom = requestData.date_from;
        let dateTo = requestData.date_to;
        if (isEmpty(dateFrom)) dateFrom = moment(company.year, "YYYY", true).startOf("year").format("DD/MM/YYYY");
        if (isEmpty(dateTo)) dateTo = moment(company.year, "YYYY", true).endOf("year").format("DD/MM/YYYY");
        const dateExported = moment().format("DD/MM/YYYY HH:mm:ss");

        // Get all data
        setReportLoader(true);
        let reqData = {
            ...requestData,
            company: company.id,
            year: company.year,
            page: -1,
        }
        axios.post(process.env.REACT_APP_API_URL2 + `/report/client-movements-report`, reqData, {
            headers: {"Content-Type": "application/json"},
            cancelToken: sourceRef?.current?.token
        }).then((res) => {
            setReportLoader(false);
            if (res.data.status === "200") {
                if (res.data.data?.length > 0) {
                    const doc = new jsPDF('landscape');
                    let y = 20;
                    doc.addFileToVFS("arialuni.ttf", liberationSerif);
                    doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
                    doc.addFileToVFS("dejavusans.ttf", dejavuSans);
                    doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
                    doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
                    doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
                    doc.setFont("DejaVu Sans Bold");
                    doc.setFontSize(12);
                    let cnt = 0;
                    for (let clientData of res.data.data) {
                        if (clientData.rows.length === 0) continue;
                        let rows = [];
                        if (cnt > 0) {
                            doc.addPage();
                            y = 20;
                        }
                        cnt++;
                        for (let dRow of clientData.rows) {
                            let prepRow = [];
                            let varRows = ["no", "date", "documentTypeName", "numbering", "sale", "payment", "balance"];
                            if (requestData.showNotes === "active") {
                                varRows = ["no", "date", "documentTypeName", "numbering", "notes", "sale", "payment", "balance"];
                            }
                            if (dRow.type === "clientTotals" || dRow.type === "settledAmount") {
                                for (let key of varRows) {
                                    let content = isEmpty(dRow[key]) ? "" : dRow[key];
                                    if (["sale", "payment", "balance"].includes(key)) content = getGreekCurrencyFormat(content);
                                    prepRow.push({content: content, styles: {fillColor: "#f1fdff"}});
                                }
                                rows.push(prepRow);
                            } else {
                                for (let key of varRows) {
                                    let content = isEmpty(dRow[key]) ? "" : dRow[key];
                                    if (["sale", "payment", "balance"].includes(key)) content = getGreekCurrencyFormat(content);
                                    prepRow.push({content: content});
                                }
                                rows.push(prepRow);
                            }
                        }
                        doc.setFont("DejaVu Sans Bold");
                        doc.setFontSize(17);
                        const header = doc.splitTextToSize("Καρτέλα Πελάτη", 200);
                        const headerString = header.join("\n"); // Join the array elements into a single string with line breaks
                        doc.text(headerString, doc.internal.pageSize.getWidth() / 2.5, y, {alignment: "center"});
                        y += 7;
                        doc.setFont("DejaVu Sans Normal");
                        const imgData = 'assets/images/prrimer-logo.png';
                        const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
                        const imgY = 23; // adjust the position as needed
                        doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
                        doc.setFontSize(10);
                        const clientCodeLines = doc.splitTextToSize(`${clientData.clientCode} - ${clientData.name}`, 200);
                        doc.text(clientCodeLines, 15, y, {alignment: "center"});
                        y += 7;
                        const lines = doc.splitTextToSize(`${!isEmpty(clientData.address) ? `Διεύθυνση ${clientData.address}, ` : ""}ΑΦΜ: ${clientData.vatNumber}`, 200);
                        doc.text(lines, 15, y, {alignment: 'center'});
                        y += 7;
                        const clientDetailRange = doc.splitTextToSize(`Εύρος Ημερομηνίας: ${dateFrom} - ${dateTo}, Ημερομηνία Εξαγωγής: ${dateExported}`, 200);
                        doc.text(`${clientDetailRange}`, 15, y, {alignment: "center"});
                        y += 4;
                        doc.autoTable({
                            startY: y,
                            head: [headers],
                            body: rows,
                            rowPageBreak: "avoid",
                            theme: "striped",
                            styles: {
                                font: "DejaVu Sans Normal",
                                fontSize: 10,
                                halign: "center",
                            },
                        })
                        y += rows.length * 10 + 10;
                        rows = [];
                    }
                    const pageCount = doc.getNumberOfPages();
                    // Add the pagination to each page
                    for (let i = 1; i <= pageCount; i++) {
                        doc.setPage(i);
                        doc.setFontSize(10);
                        doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
                    }
                    if (printer) {
                        setIFrameData(`${doc.output('bloburl')}`);
                        setTimeout(() => {
                            const iframeWindow = iframeRef.current.contentWindow;
                            iframeWindow.print();
                        }, 200)
                    } else {
                        doc.save("Καρτέλες Πελατών.pdf");
                    }
                } else {
                    toast.info("Δεν βρέθηκαν κινήσεις με αυτά τα φίλτρα.");
                }
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            setReportLoader(false);
            console.log(error);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }
    const handleExcelExport = () => {
        let rows = [];
        let headers = ["Αρ.", "Ημερομηνία", "Τύπος Παραστατικού", "Αρίθμηση", "Πώληση", "Πληρωμή", "Υπόλοιπο"];
        if (requestData.showNotes === "active") {
            headers = ["Αρ.", "Ημερομηνία", "Τύπος Παραστατικού", "Αρίθμηση", "Σημειώσεις", "Πώληση", "Πληρωμή", "Υπόλοιπο"];
        }
        let dateFrom = requestData.date_from;
        let dateTo = requestData.date_to;
        if (isEmpty(dateFrom)) dateFrom = moment(company.year, "YYYY", true).startOf("year").format("DD/MM/YYYY");
        if (isEmpty(dateTo)) dateTo = moment(company.year, "YYYY", true).endOf("year").format("DD/MM/YYYY");
        const dateExported = moment().format("DD/MM/YYYY HH:mm:ss");

        // Get all data
        setReportLoader(true);
        let reqData = {
            ...requestData,
            company: company.id,
            year: company.year,
            page: -1,
        }
        axios.post(process.env.REACT_APP_API_URL2 + `/report/client-movements-report`, reqData, {
            headers: {"Content-Type": "application/json"},
            cancelToken: sourceRef?.current?.token
        }).then((res) => {
            setReportLoader(false);
            if (res.data.status === "200") {
                if (res.data.data?.length > 0) {
                    const workBook = XLSX.utils.book_new();
                    for (let clientData of res.data.data) {
                        rows.push([`Κωδικός: ${clientData.clientCode}`]);
                        rows.push([`Όνομα: ${clientData.name}`]);
                        rows.push([`ΑΦΜ: ${clientData.vatNumber}`]);
                        rows.push([`Εύρος ημερομηνίας: ${dateFrom} - ${dateTo}`]);
                        rows.push([`Ημερομηνία Εξαγωγής: ${dateExported}`]);
                        rows.push([]);
                        rows.push(headers);
                        for (let dRow of clientData.rows) {
                            if (requestData.showNotes === "active") {
                                rows.push([dRow.no, dRow.date, dRow.documentTypeName, dRow.numbering, dRow.notes, dRow.sale, dRow.payment, dRow.balance]);
                            } else {
                                rows.push([dRow.no, dRow.date, dRow.documentTypeName, dRow.numbering, dRow.sale, dRow.payment, dRow.balance]);
                            }
                        }
                        const workSheet = XLSX.utils.aoa_to_sheet(rows);
                        XLSX.utils.book_append_sheet(workBook, workSheet, String(`${clientData.clientCode} ${clientData.name}`).slice(0, 30));
                        rows = [];
                    }
                    XLSX.writeFile(workBook, 'Καρτέλες Πελατών.xlsx');
                } else {
                    toast.info("Δεν βρέθηκαν κινήσεις με αυτά τα φίλτρα.");
                }
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            setReportLoader(false);
            console.log(error);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }
    const handleRowDoubleClick = (row) => {
        if (row._id) {
            const docMapping = {
                "sale" : "Sales",
                "clientPayment": "Client Payments",
                "clientMovement": "Client Movements",
            }
            const tabMapping = {
                "sale": "view-sales",
                "clientPayment": "edit-client-payments",
                "clientMovement": "edit-client-movements",
            }
            const functionMapping = {
                "sale": setEditSales,
                "clientPayment": setEditClientPayment,
                "clientMovement": setEditClientMovements,
            }
            if (row.type in docMapping) {
                axios.get(`${process.env.REACT_APP_API_URL2}/company/get-document-by-id`, {
                    headers: { "Content-Type": "application/json" },
                    params: {
                        company: company.id,
                        docId: row._id,
                        section: docMapping[row.type],
                    }
                }).then((res) => {
                    if (res.data.status === "200") {
                        dispatch(functionMapping[row.type](res.data.data));
                        const idx = TABS_DATA.findIndex((item) => tabMapping[row.type] === item);
                        if(idx === -1) {
                            dispatch(addTab(tabMapping[row.type]));
                            dispatch(updateActiveMainTab(TABS_DATA.length));
                        } else {
                            dispatch(updateActiveMainTab(idx));
                        }
                    } else {
                        toast.error(res.data.message);
                    }
                }).catch((error) => {
                    console.log(error);
                    toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
                })
            }
        }
    }

    return (
        <React.Fragment>
            <div style={classicStyleBelowNavbar}>
                {reportLoader ? <Loader cancel={handleCancel}/> :
                    <div>
                        <div className="no-print">
                            <Row>
                                <Col md={3}>
                                    <DateHelper
                                        dateFromAndToFunction={(from, to) => dispatch(setClientMovementsReportRequestData({...requestData, date_from: from, date_to: to}))}
                                    />
                                </Col>
                                <Col md={2}>
                                    <DateBox
                                        name="date_from"
                                        selected={moment(requestData.date_from, "DD/MM/YYYY").isValid() ? moment(requestData.date_from, "DD/MM/YYYY").toDate() : ""}
                                        label={t('ClientsReportsClientMovement.dateFrom')}
                                        onChange={(e) => handleOnChange(e, "datebox", "date_from")}
                                    />
                                </Col>
                                <Col md={2}>
                                    <DateBox
                                        name="date_to"
                                        selected={moment(requestData.date_to, "DD/MM/YYYY").isValid() ? moment(requestData.date_to, "DD/MM/YYYY").toDate() : ""}
                                        label={t('ClientsReportsClientMovement.dateTo')}
                                        onChange={(e) => handleOnChange(e, "datebox", "date_to")}
                                    />
                                </Col>
                                <Col md={5}>
                                    <AdvancedCombobox
                                        ACName="Sales Client" // Do not change
                                        className="w-100 ac-select-dropdown"
                                        searchableDropdownEnable={false}
                                        defaultValue={requestData.clientName}
                                        label={t("General.clientName")}
                                        data={clientsListData}
                                        onChange={(e) => handleOnChange(e, "acdd", "clientName")}
                                        resetFilter={() => dispatch(setClientMovementsReportRequestData({...requestData, clientName: ""}))}
                                        onChangeSearch={onChangeSearch}
                                        excludeColumns={[]}
                                        columns={clientDropdownColumns}
                                        editEnable={false}
                                        deleteEnable={false}
                                        searchTableEnable={false}
                                        showTableOptionOnSelect={true}
                                        placeholder={t("Placeholders.client")}
                                        required={true}
                                        name="clientName"
                                        showInsertAddButton={true}
                                        hideSaveButton={true}
                                        hideInsertNewRow={true}
                                        inputFieldClasses="form-control"
                                    />
                                    {isEmpty(requestData.clientName) && (
                                        <div className={"text-muted"}>(*) Για την γρηγορότερη προβολή αποτελεσμάτων, επιλέξτε συγκεκριμένο πελάτη</div>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <BetterCheckBox
                                        name={"client_zero"}
                                        text={t('ClientsReportsClientMovement.includeClientsWithZeroBalance')}
                                        className="mt-3"
                                        checked={requestData.client_zero === "active"}
                                        onChange={(e) => handleOnChange(e, "checkbox", "client_zero")}
                                    />
                                </Col>
                                <Col md={3}>
                                    <BetterCheckBox
                                        name={"client_inactive"}
                                        text={t('ClientsReportsClientMovement.includeInactiveClients')}
                                        className="mt-3"
                                        checked={requestData.client_inactive === "active"}
                                        onChange={(e) => handleOnChange(e, "checkbox", "client_inactive")}
                                    />
                                </Col>
                                <Col md={3}>
                                    <BetterCheckBox
                                        name={"showNotes"}
                                        text={"Προβολή σημειώσεων παραστατικών"}
                                        className={"mt-3"}
                                        checked={requestData.showNotes === "active"}
                                        onChange={(e) => handleOnChange(e, "checkbox", "showNotes")}
                                    />
                                </Col>
                            </Row>
                            {checkPermission("clients-reports/clientsmovements-read", permissionsData) &&
                                <Row>
                                    <Col md={12}>
                                        <Button className="float-right" onClick={() => fetchClientMovements(false)} disabled={loading}>
                                            {t('ClientsReportsClientMovement.applyFilter')}
                                        </Button>
                                    </Col>
                                </Row>
                            }
                            <hr style={{color: "gray", height: 1}} />
                        </div>
                        <div className="no-print">
                            {responseData.totalPages > 0 ? (
                                <React.Fragment>
                                    <Row>
                                        <h3 style={{
                                            textAlign: "center",
                                            alignItems: "center",
                                            fontWeight: "bold"
                                        }}>Κινήσεις Πελατών</h3>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="mt-3">
                                                    <h5>{responseData.pageClientData.clientCode} - {responseData.pageClientData.name}</h5>
                                                    <h5>ΑΦΜ: {responseData.pageClientData.vatNumber}</h5>
                                                    {!isEmpty(responseData.pageClientData.address) ? (
                                                        <h5>Διεύθυνση: {responseData.pageClientData.address}</h5>
                                                    ) : (
                                                        <h5>-</h5>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3 justify-content-end">
                                        <Col md={9} style={{display: "flex", justifyContent: "right"}}>
                                            {loading && <Spinner animation="border" variant="dark" className={"mr-2 mt-2"} size={"sm"} />}
                                            <Button size="sm" className="mr-1" onClick={() => toFirstPage()}
                                                    disabled={requestData.page === 1 || loading}>{"<< Πρώτη"}</Button>
                                            <Button size="sm" className="mr-1" onClick={() => toPreviousPage()}
                                                    disabled={requestData.page === 1 || loading}>{"< Προηγούμενη"}</Button>
                                            <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1"
                                                   bg="primary">Σελίδα {requestData.page} / {responseData.totalPages}</Badge>
                                            <Button size="sm" className="mr-1" onClick={() => toNextPage()}
                                                    disabled={requestData.page === responseData.totalPages || loading}>{"Επόμενη >"}</Button>
                                            <Button size="sm" className="mr-1" onClick={() => toLastPage()}
                                                    disabled={requestData.page === responseData.totalPages || loading}>{"Τελευταία >>"}</Button>
                                        </Col>
                                    </Row>
                                    <Row className={"mb-4"}>
                                        <Col md={12}>
                                            <table style={{
                                                tableLayout: "fixed",
                                                textAlign: "center",
                                                width: "100%",
                                                fontSize: "16px"
                                            }} className={"clientMovementsReportTable"}>
                                                <colgroup>
                                                    <col style={{width: "3%"}}></col>
                                                    <col style={{width: "15%"}}></col>
                                                    <col style={{width: "30%"}}></col>
                                                    <col style={{width: "10%"}}></col>
                                                    {requestData.showNotes === "active" && (
                                                        <col style={{width: "15%"}}></col>
                                                    )}
                                                    <col style={{width: "9%"}}></col>
                                                    <col style={{width: "9%"}}></col>
                                                    <col style={{width: "9%"}}></col>
                                                </colgroup>
                                                <thead>
                                                <tr style={{backgroundColor: "#f1fdff"}}>
                                                    <th>Αρ.</th>
                                                    <th>Ημερομηνία</th>
                                                    <th>Τύπος Παραστατικού</th>
                                                    <th>Αρίθμηση</th>
                                                    {requestData.showNotes === "active" && (
                                                        <th>Σημειώσεις Παραστατικού</th>
                                                    )}
                                                    <th colSpan={2}>Ποσό</th>
                                                    <th>Υπόλοιπο</th>
                                                </tr>
                                                <tr style={{backgroundColor: "#f1fdff"}}>
                                                    <th colSpan={requestData.showNotes === "active" ? 5 : 4}></th>
                                                    <th>Πώληση</th>
                                                    <th>Πληρωμή</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {responseData.pageData?.length > 0 && responseData.pageData.map((row, idx) => (
                                                    <tr key={`cmRow${idx}`}
                                                        style={{backgroundColor: ["transfer", "pageTotals", "clientTotals", "settledAmount"].includes(row["type"]) ? "#e1e3e1" : ""}}
                                                        onDoubleClick={() => handleRowDoubleClick(row)}>
                                                        <td>{row["no"]}</td>
                                                        <td>{row["date"]}</td>
                                                        <td>{["transfer", "pageTotals", "clientTotals", "settledAmount"].includes(row["type"]) ?
                                                            <i>***{" "}{row["documentTypeName"]}{" "}***</i> : row["documentTypeName"]}</td>
                                                        <td>{row["numbering"]}</td>
                                                        {requestData.showNotes === "active" && (
                                                            <td>{row["notes"]}</td>
                                                        )}
                                                        <td style={{fontWeight: "700"}}>{row["sale"] === 0 && ["sale", "clientPayment", "clientMovement", "transfer", "settledAmount"].includes(row["type"]) ? "" : getGreekCurrencyFormat(row["sale"])}</td>
                                                        <td style={{fontWeight: "700"}}>{row["payment"] === 0 && ["sale", "clientPayment", "clientMovement", "transfer", "settledAmount"].includes(row["type"]) ? "" : getGreekCurrencyFormat(row["payment"])}</td>
                                                        <td style={{fontWeight: "700"}}>{getGreekCurrencyFormat(row["balance"])}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                    <div style={{display: "none"}} className="print-me" id="printReport">
                                        <iframe ref={iframeRef} style={{width: "100%"}} src={iframeData} title={"Print Report"}></iframe>
                                    </div>
                                    {checkPermission("clients-reports/clientsmovements-general-print", permissionsData) === true && (
                                        <Row>
                                            <BootstrapDropdown className="mb-3 mt-3">
                                                <BootstrapDropdown.Toggle variant="primary" id="dropdown-basic">
                                                    Αποθήκευση αποτελεσμάτων
                                                </BootstrapDropdown.Toggle>
                                                <BootstrapDropdown.Menu>
                                                    <BootstrapDropdown.Item onClick={() => handlePDFExport(true)}>
                                                        Εκτυπωτής
                                                    </BootstrapDropdown.Item>
                                                    <BootstrapDropdown.Item onClick={() => handlePDFExport()}>
                                                        Αποθήκευση ως PDF
                                                    </BootstrapDropdown.Item>
                                                    <BootstrapDropdown.Item onClick={() => handleExcelExport()}>
                                                        Αποθήκευση ως XLSX
                                                    </BootstrapDropdown.Item>
                                                </BootstrapDropdown.Menu>
                                            </BootstrapDropdown>
                                        </Row>
                                    )}
                                </React.Fragment>
                            ) : (
                                <Row>
                                    <h3
                                        style={{
                                            textAlign: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        {t('ClientsReportsClientMovement.pleaseSelectFiltersFirstAndPressApplyFilters')}
                                    </h3>
                                </Row>
                            )}
                        </div>
                    </div>}
            </div>
        </React.Fragment>
    )
}

export default ClientsMovementsReport;