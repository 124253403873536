import { vatCategoryData } from '../_data/mydata/vatCategory';
import { vatZeroPerData } from '../_data/mydata/vatZeroPer';
import {  otherTaxesCols } from '../_data/mydata/otherTaxesCategories';
import { withHoldingTaxesData } from '../_data/mydata/withHoldingTaxes';
import { taxStampCategoryData } from '../_data/mydata/taxStampCategory';
import { feesData } from '../_data/mydata/fees';
import { measurementUnitsData } from '../_data/mydata/measurementUnits';
import {
  allInvoiceTypesData,
  buysInvoiceTypesData, buysInvoiceTypesDataProvider, clientPaymentInvoiceTypesProvider,
  salesInvoiceTypesData,
  salesInvoiceTypesDataProvider
} from '../_data/mydata/salesInvoiceTypes';
import { incomeCharacterizationCategory } from '../_data/mydata/incomeCharacterizationCategory';
import { incomeCharacterizationType } from '../_data/mydata/incomeCharacterizationType';
import { salesPurposeData } from "../_data/mydata/salesPurpose";
import { highlightData } from "../_data/mydata/highlight";
import {otherTaxesData} from "../_data/mydata/otherTaxes";
import {expensesCharacterizationTypeCode} from "../_data/mydata/expensesCharacterizationTypeCode";
import {expensesCharacterizationCategory} from "../_data/mydata/expensesCharacterizationCategory";

// My Data Export
const getMyData = (props) => {

  const data = props?.settings?.primerMyDataData;

  const getVatOptions = (data) => {
    let options = [];
    let vatData;
    if (data?.vat !== '' && data !== undefined) {
      vatData = data?.vat ? JSON.parse(data?.vat) : [];
    } else {
      vatData = vatCategoryData;
    }
    vatData.forEach((item) => {
      let obj = {};
      if((item.percentage === "0" || item.percentage === "No VAT No VAT (π.χ. Μισθοδοσία, Αποσβέσεις κλπ)") && props.option === "doNotShowZero"){
        return;
      }
      obj.label = item.description;
      obj.value = item.percentage;
      if (item.deactivated !== "true") {
        options.push(obj);
      }
    });
    return options;
  }

  const getZeroVatOptions = () => {
    let options = [];
    vatZeroPerData.forEach((item) => {
      let obj = {};
      obj.label = item.description;
      obj.value = item.code;
        options.push(obj);
    });
    return options;
  }

  const getOtherTaxesOptions = (data) => {
    let wData;
    if (data?.otherTaxes !== '' && data !== undefined) {
      wData = data?.otherTaxes ? (data?.otherTaxes) : [];
    } else {
      wData = otherTaxesData;
    }
    return wData;
  }

  const getWithHoldOptions = (data) => {
    let wData;
    if (data?.withHoldTaxes !== '' && data !== undefined) {
      wData = data?.withHoldTaxes ? (data?.withHoldTaxes) : [];
    } else {
      wData = withHoldingTaxesData;
    }
    return wData;
  }

  const getStampOptions = () => {
    let wData;
    if (data?.stamp !== '' && data !== undefined) {
      wData = data?.stamp ? (data?.stamp) : [];
    } else {
      wData = taxStampCategoryData;
    }
    return wData;
  }

  const getFeesOptions = () => {
    let wData;
    if (data?.fees !== '' && data !== undefined) {
      wData = data?.fees ? (data?.fees) : [];
    } else {
      wData = feesData;
    }
    return wData;
  }

  const getMuOptions =  (data) => {
    let options = [];
    let muData;
    if (data?.measurementUnits !== '' && data !== undefined) {
      muData = data?.measurementUnits ? JSON.parse(data?.measurementUnits) : [];
    } else {
      muData = measurementUnitsData;
    }
    muData.forEach((item) => {
      let obj = {};
      obj.label = item.name;
      obj.value = item.code;
      if (item.isActive === "true") {
        options.push(obj);
      }
    });

    return options;
  }

  const getSalePurposeOptions = (data) => {
    let options = [];
    let spData;
    if (data?.salePurpose !== '' && data !== undefined) {
      spData = data?.salePurpose ? JSON.parse(data?.salePurpose) : [];
    } else {
      spData = salesPurposeData;
    }
    spData.forEach((item) => {
      let obj = {};
      obj.label = item.name;
      obj.value = item.code;
      if (item.isActive === "true") {
        options.push(obj);
      }
    });

    return options;
  }

  // Handle Data Options Requests
  switch(props.type) {
    case 'vat':
      return getVatOptions(data);

    case 'zerovatreason':
      return getZeroVatOptions();

    case 'othertaxes':
      return getOtherTaxesOptions(data);

    case 'othertaxescols':
      return otherTaxesCols;

    case 'withhold':
      return getWithHoldOptions(data);

    case 'stamp':
      return getStampOptions();

    case 'fees':
      return getFeesOptions(data);

    case 'mu':
      return getMuOptions(data);

    case 'salePurpose':
      return getSalePurposeOptions(data);

    default:
      return [];
  }
}

export const getInvoiceTypesOptions = (data, type = 'sale') => {
  let mydataTable;
  if (data === "provider") {
    mydataTable = salesInvoiceTypesDataProvider;
  } else if (data === "buys") {
    mydataTable = buysInvoiceTypesData;
  } else if (data === "all") {
    mydataTable = allInvoiceTypesData;
  } else {
    mydataTable = salesInvoiceTypesData;
  }
  if (data === "provider" && type === "Buys") {
    mydataTable = buysInvoiceTypesDataProvider;
  }
  if (data === "provider" && type === "Client Payments") {
    mydataTable = clientPaymentInvoiceTypesProvider;
  }
  let options = [];
  mydataTable.length > 0 && mydataTable.forEach((item) => {
    let obj = {};
    if (item.name && item.myCodeData) {
      obj.label = item.myCodeData + ' ' + item.name;
      obj.value = item.myCodeData;
      options.push(obj);
    }
  });
  return options;
}

export const getInvoiceTypesOptionList = (salesInvoiceTypes) => {
  let options = [];
  salesInvoiceTypes.length > 0 && salesInvoiceTypes.forEach((item) => {
    let obj = {};
    if (item.name && item._id) {
      obj.label = item.name;
      obj.value = item._id;
      options.push(obj);
    }
  });
  return options;
}

export const getICCategoryOptions = () => {
  let options = [];
  incomeCharacterizationCategory.length > 0 && incomeCharacterizationCategory.forEach((item) => {
    let obj = {};
    if (item.code && item.description) {
      obj.label = item.description;
      obj.value = item.code;
      options.push(obj);
    }
  });
  return options;
}

export const getECCategoryOptions = () => {
  let options = [];
  expensesCharacterizationCategory.length > 0 && expensesCharacterizationCategory.forEach((item) => {
    let obj = {};
    if (item.code && item.description) {
      obj.label = item.description;
      obj.value = item.code;
      options.push(obj);
    }
  });
  return options;
}

export const getICTypeOptions = () => {
  let options = [];
  incomeCharacterizationType.length > 0 && incomeCharacterizationType.forEach((item) => {
    let obj = {};
    if (item.code && item.description) {
      obj.label = item.description;
      obj.value = item.code;
      options.push(obj);
    }
  });
  return options;
}

export const getECTypeOptions = () => {
  let options = [];
  expensesCharacterizationTypeCode.length > 0 && expensesCharacterizationTypeCode.forEach((item) => {
    let obj = {};
    if (item.code && item.description) {
      obj.label = item.description;
      obj.value = item.code;
      options.push(obj);
    }
  });
  return options;
}

export const getVatExpenseClassificationType = () => {
  return [
    {
      label: "Αγορές & δαπάνες στο εσωτερικό της χώρας",
      value: "VAT_361"
    },
    {
      label: "Αγορές & εισαγωγές επενδ. Αγαθών (πάγια)",
      value: "VAT_362"
    },
    {
      label: "Λοιπές εισαγωγές εκτός επενδ. Αγαθών (πάγια)",
      value: "VAT_363"
    },
    {
      label: "Ενδοκοινοτικές αποκτήσεις αγαθών",
      value: "VAT_364"
    },
    {
      label: "Ενδοκοινοτικές λήψεις υπηρεσιών άρθρ. 14.2.α",
      value: "VAT_365"
    },
    {
      label: "Λοιπές πράξεις λήπτη",
      value: "VAT_366"
    },
    {
      label: "Μη συμμετοχή στο ΦΠΑ (έξοδα – εισροές)",
      value: "NOT_VAT_295"
    }
  ]
}

export const getMyDataRowTypes = () => {
  return [
    {
      value: "income",
      label: "Έσοδα"
    },
    {
      value: "expense",
      label: "Έξοδα"
    }
  ]
}

export const getSalesPurposeData = (data) => {
  let options = [];
  let spData;
  if (data !== undefined && data?.salePurpose !== '') {
    spData = data?.salePurpose ? JSON.parse(data?.salePurpose) : [];
  } else {
    spData = salesPurposeData;
  }
  if (spData.length > 0) {
    spData.forEach((item) => {
      let obj = {};
      if (item.code && item.name) {
        obj.label = item.code + '. ' + item.name;
        obj.value = item.code;
        if (item.isActive === 'true') {
          options.push(obj);
        }
      }
    });
  }

  return options;
}

export const getHighlightData = (data) => {
  let options = [];
  let hlData;
  if (data?.highlight !== '') {
    hlData = data?.highlight ? JSON.parse(data?.highlight) : [];
  } else {
    hlData = highlightData;
  }
  if (hlData.length > 0) {
    hlData.forEach((item) => {
      let obj = {};
      if (item.code && item.name) {
        obj.label = item.code + '. ' + item.name;
        obj.value = item.code;
        if (item.isActive === 'true') {
          options.push(obj);
        }
      }
    });
  }

  return options;
}

export const vat39ACategories = [
  { label: "24%", value: "1" },
  { label: "17%", value: "4" },
]

export default getMyData;
