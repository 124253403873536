import React, {useEffect, useState} from "react";
import {Badge, Button, Col, Modal, Row, Stack} from "react-bootstrap";
import "./styles/settings-subscriptions.css"
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import Dropdown from "../../../primer/Dropdown";
import Input from "../../../common/Input";
import {resetNewSubscription, setNewSubscription} from "../../../../_reducers/subscriptionsSlice";
import axios from "axios";
import BetterCheckBox from "../../../common/BetterCheckBox";
import {deleteSubscription, getSubscriptions} from "../../../../_apis/api";
import {checkPermission} from "../../../../_helpers/commonFunctions";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import Alert from "react-bootstrap/Alert";

export const SettingsSubscriptions = () => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const SUBSCRIPTIONS = useSelector((state) => state.SUBSCRIPTIONS);
    const [firstLoad, setFirstLoad] = useState(true);
    const [mappedProductName, setMappedProductName] = useState("");
    const [mappedConnectedProductName, setMappedConnectedProductName] = useState("");
    const permissionsData = JSON.parse(localStorage.getItem("user")).permissions;

    const [showNewModal, setShowNewModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteSubName, setDeleteSubName] = useState("");
    const [deleteSubInput, setDeleteSubInput] = useState("");

    const [defaultProductData, setDefaultProductData] = useState([]);
    const [defaultProductChargingData, setDefaultProductChargingData] = useState([]);

    const [showEditModal, setShowEditModal] = useState(false);
    const [editModalData, setEditModalData] = useState({});

    const [showSubscribersModal, setShowSubscribersModal] = useState(false);
    const [showSubscribersData, setShowSubscribersData] = useState([]);
    const [showSubscribersLoading, setShowSubscribersLoading] = useState(false);

    const [searchingSubscribers, setSearchingSubscribers] = useState("");

    const apiLoginURL = process.env.REACT_APP_LOGIN_URL;
    const errorAuth = "You are not Authorized!";

    const typeOptions = [
        {label: "Συνδρομή", value: "subscription"},
        {label: "Χρονοχρέωση", value: "timeCharge"}
    ]
    /*
    newSubscription has:
    - type: "subscription" or "timeCharge"

    - case subscription:
    -- subscriptionName: just some name
    -- mappedProduct: _id of the product that is mapped
    -- monthsDuration: number (as string)
    -- subscriptionUniqueness: checkbox

    - case timeCharge:
    -- subscriptionName: just some name
    -- mappedProductForCharging: _id of the product that is mapped for charging (should have subscription mapped to this in order to show)
    -- mappedProduct: _id of the product that is mapped
    -- monthsDuration: number (as string)
    -- minimumCharge: minutes (number as string)
    -- minutesTime: minutes (number as string)     the total support time

     */

    useEffect(() => {
        if(firstLoad === true) {
            setFirstLoad(false);
            dispatch(getSubscriptions({company: company.id}));
            axios.get(process.env.REACT_APP_API_URL2 + `/settings/list-products`, {params: {company: company}})
            .then(res => {
                if (res.data && res.data.data && res.data.status === '200') {
                    let tArr = [];
                    res.data.data.forEach((prod) => {
                        tArr.push({
                            name: String(prod.name),
                            value: String(prod._id)
                        })
                    })
                    setDefaultProductData(tArr);
                }
            }).catch((err) => {
                console.log(err);
            })
            axios.get(process.env.REACT_APP_API_URL2 + `/settings/list-connected-products`, {params: {company: company}})
            .then(res => {
                if (res.data && res.data.data && res.data.status === '200') {
                    let tArr = [];
                    res.data.data.forEach((prod) => {
                        tArr.push({
                            name: String(prod.name),
                            value: String(prod._id)
                        })
                    })
                    setDefaultProductChargingData(tArr);
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    })
    const resetModalsAndData = () => {
        dispatch(resetNewSubscription());
        setShowNewModal(false);
        setShowDeleteModal(false);
        setDeleteSubName("");
        setDeleteSubInput("");
        setMappedProductName("");
        setMappedConnectedProductName("");
        closeEditModal();
    }

    const saveSubscription = () => {
        const data = {...SUBSCRIPTIONS.newSubscription, company: company.id}
        axios.post(process.env.REACT_APP_API_URL2 + `/settings/post-subscription`, JSON.stringify(data), {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            if (res.data.status === "401") {
                localStorage.clear();
                window.location.replace(`${apiLoginURL}?redirect=ERP&error=${errorAuth}`);
            } else if (res.data.status === "200") {
                toast.success("Subscription saved!");
                resetModalsAndData();
                dispatch(getSubscriptions({company: data.company}));
            } else if (res.data.status === "400") {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    const handleDelete = (subName) => {
        setShowDeleteModal(true);
        setDeleteSubInput("");
        setDeleteSubName(subName);
    }
    const deleteSub = (subName) => {
        dispatch(deleteSubscription({name: String(subName), company: company.id}))
        resetModalsAndData();
        toast.success("Η διαγραφή συνδρομής πραγματοποιήθηκε.");
        closeEditModal();
    }
    const handleOnChange = (e, type, typeName) => {
        if(type === "input") {
            if(typeName === "subscriptionName") {
                dispatch(setNewSubscription({...SUBSCRIPTIONS.newSubscription, subscriptionName: e.target.value}))
            } else if (typeName === "monthsDuration") {
                dispatch(setNewSubscription({...SUBSCRIPTIONS.newSubscription, monthsDuration: String(e.target.value)}))
            } else if (typeName === "minimumCharge") {
                dispatch(setNewSubscription({...SUBSCRIPTIONS.newSubscription, minimumCharge: String(e.target.value)}))
            } else if (typeName === "deleteSubInput") {
                setDeleteSubInput(e.target.value);
            } else if (typeName === "minutesTime") {
                dispatch(setNewSubscription({...SUBSCRIPTIONS.newSubscription, minutesTime: String(e.target.value)}));
            }
        } else if (type === "dropdown") {
            if(typeName === "type") {
                if(SUBSCRIPTIONS.newSubscription?.type !== String(e.value)) {
                    dispatch(setNewSubscription({type: e.value}));
                    setMappedProductName("");
                    setMappedConnectedProductName("");
                }
            } else if (typeName === "mappedProduct") { // Dropdown from advanced combo box
                setMappedProductName(e.row.name);
                dispatch(setNewSubscription({...SUBSCRIPTIONS.newSubscription, mappedProduct: e.row.value}));
            } else if (typeName === "mappedProductForCharging") { // Dropdown from advanced combo box
                setMappedConnectedProductName(e.row.name);
                dispatch(setNewSubscription({...SUBSCRIPTIONS.newSubscription, mappedProductForCharging: e.row.value}));
            }
        } else if (type === "checkbox") {
            if(typeName === "subscriptionUniqueness") {
                if(e.target.checked === false) {
                    dispatch(setNewSubscription({...SUBSCRIPTIONS.newSubscription, subscriptionUniqueness: "inactive"}))
                } else {
                    dispatch(setNewSubscription({...SUBSCRIPTIONS.newSubscription, subscriptionUniqueness: "active"}))
                }
            } else if(typeName === "isConstructor") {
                if(e.target.checked === false) {
                    dispatch(setNewSubscription({...SUBSCRIPTIONS.newSubscription, isConstructor: "inactive"}))
                } else {
                    dispatch(setNewSubscription({...SUBSCRIPTIONS.newSubscription, isConstructor: "active"}))
                }
            }
        }
    }
    const loadProductsDataOnChange = (keyword, setData) => {
        axios.get(process.env.REACT_APP_API_URL2 + `/settings/list-products`, {params: {company: company, name: keyword}})
        .then(res => {
            if (res.data && res.data.data && res.data.status === '200') {
                let tArr = [];
                res.data.data.forEach((prod) => {
                    tArr.push({
                        name: String(prod.name),
                        value: String(prod._id)
                    })
                })
                setData(tArr);
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    const loadConnectedMappedProductsOnChange = (keyword, setData) => {
        axios.get(process.env.REACT_APP_API_URL2 + `/settings/list-connected-products`, {params: {company: company, name: keyword}})
        .then(res => {
            if (res.data && res.data.data && res.data.status === '200') {
                let tArr = [];
                res.data.data.forEach((prod) => {
                    tArr.push({
                        name: String(prod.name),
                        value: String(prod._id)
                    })
                })
                setData(tArr);
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    const onChangeSearchProduct = (keyword, setData) => {
        if(String(keyword).trim().length >= 2) {
            loadProductsDataOnChange(keyword, setData);
        }
    }
    const onChangeSearchProductCharging = (keyword, setData) => {
        if(String(keyword).trim().length >= 2) {
            loadConnectedMappedProductsOnChange(keyword, setData);
        }
    }
    const isEmpty = (o) => {
        return String(o).trim() === "" || o === null || o === undefined;
    }
    const subscriptionValid = () => {
        return SUBSCRIPTIONS?.newSubscription
            && (
            (SUBSCRIPTIONS?.newSubscription?.type === "subscription"
                && !isEmpty(SUBSCRIPTIONS?.newSubscription?.subscriptionName)
                && !isEmpty(SUBSCRIPTIONS?.newSubscription?.mappedProduct)
                && !isEmpty(SUBSCRIPTIONS?.newSubscription?.monthsDuration))
            ||
            (SUBSCRIPTIONS?.newSubscription?.type === "timeCharge"
                && !isEmpty(SUBSCRIPTIONS?.newSubscription?.subscriptionName)
                && !isEmpty(SUBSCRIPTIONS?.newSubscription?.mappedProductForCharging)
                && !isEmpty(SUBSCRIPTIONS?.newSubscription?.mappedProduct)
                && !isEmpty(SUBSCRIPTIONS?.newSubscription?.monthsDuration)
                && !isEmpty(SUBSCRIPTIONS?.newSubscription?.minimumCharge)
                && !isEmpty(SUBSCRIPTIONS?.newSubscription?.minutesTime)));
    }
    const editSubscriptionValid = () => {
        return editModalData
            && (
                (editModalData.type === "subscription"
                    && !isEmpty(editModalData?.subscriptionName)
                    && !isEmpty(editModalData?.mappedProduct)
                    && !isEmpty(editModalData?.monthsDuration))
                ||
                (editModalData.type === "timeCharge"
                && !isEmpty(editModalData?.subscriptionName)
                && !isEmpty(editModalData?.mappedProductForCharging)
                && !isEmpty(editModalData?.mappedProduct)
                && !isEmpty(editModalData?.monthsDuration)
                && !isEmpty(editModalData?.minimumCharge)
                && !isEmpty(editModalData?.minutesTime)));
    }
    const editSubscriptionClicked = (sub) => {
        if(checkPermission("settings-generalsettings/subscriptions-write", permissionsData) === true) {
            setShowEditModal(true);
            setEditModalData(sub);
        }
    }
    const closeEditModal = () => {
        setShowEditModal(false);
        setEditModalData({});
    }
    const handleOnChangeEdit = (e, type, typeName) => {
        if(type === "input") {
            if(typeName === "subscriptionName") {
                setEditModalData({...editModalData, subscriptionName: e.target.value})
            } else if (typeName === "monthsDuration") {
                setEditModalData({...editModalData, monthsDuration: e.target.value})
            } else if (typeName === "minimumCharge") {
                setEditModalData({...editModalData, minimumCharge: e.target.value})
            } else if (typeName === "minutesTime") {
                setEditModalData({...editModalData, minutesTime: e.target.value})
            }
        } else if(typeName === "isConstructor") {
            if(e.target.checked === false) {
                setEditModalData({...editModalData, isConstructor: "inactive"});
            } else {
                setEditModalData({...editModalData,  isConstructor: "active"});
            }
        }
    }
    const saveEditingSub = () => {
        axios.post(process.env.REACT_APP_API_URL2 + `/settings/edit-subscription`, {company: editModalData.company, sub: editModalData}, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            if (res.data.status === "401") {
                localStorage.clear();
                window.location.replace(`${apiLoginURL}?redirect=ERP&error=${errorAuth}`);
            } else if (res.data.status === "200") {
                toast.success("Subscription updated!");
                dispatch(getSubscriptions({company: editModalData.company}));
                resetModalsAndData();
            } else if (res.data.status === "400") {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    const showSubscribers = (sub) => {
        setShowSubscribersModal(true);
        setShowSubscribersLoading(true);
        setShowSubscribersData([]);
        //axios call
        axios.get(process.env.REACT_APP_API_URL2 + `/settings/get-subscribers`, {
            headers: { "Content-Type": "application/json" },
            params: {
                sub: sub,
            }
        }).then((res) => {
            if(res.data.status === "200") {
                setShowSubscribersLoading(false);
                console.log(res.data.data);
                setShowSubscribersData(res.data.data);
            } else if (res.data.status === "401") {
                localStorage.clear();
                window.location.replace(`${apiLoginURL}?redirect=ERP&error=${errorAuth}`);
            } else if (res.data.status === "400") {
                setShowSubscribersLoading(false);
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    const closeSubscribersModal = () => {
        closeEditModal();
        setShowSubscribersModal(false);
        setShowSubscribersLoading(false);
        setShowSubscribersData([]);
    }
    return(
        <React.Fragment>
            {checkPermission("settings-generalsettings/subscriptions-write", permissionsData) && (
                <Row className="mb-3">
                    <Col md={3} key={Math.random()}>
                        <Button onClick={() => setShowNewModal(true)}>Προσθήκη νέας</Button>
                    </Col>
                </Row>
            )}
            <Row className="mb-3">
                {SUBSCRIPTIONS.subscriptions.length > 0 && SUBSCRIPTIONS.subscriptions.map((sub) => (
                    <Col md={4} className="mb-3" key={Math.random()}>
                        <div className={checkPermission("settings-generalsettings/subscriptions-write", permissionsData) === true ? "subscription-box subscription-box-permission" : "subscription-box"}
                             onClick={() => editSubscriptionClicked(sub)}>
                            <h4 className="subscription-box-header">{sub.subscriptionName}</h4>
                            {checkPermission("settings-generalsettings/subscriptions-delete", permissionsData) && (
                                <><Badge bg="primary" style={{position: "absolute", top: "5px", right: "15px", padding: "4px", cursor: "pointer"}}
                                         onClick={() => { handleDelete(sub.subscriptionName) }} className="mt-1"><img title="Διαγραφή" src={"assets/images/callcenter/bin.png"} alt={"Bin"} /></Badge></>
                            )}
                            <><Badge bg="primary" style={{position: "absolute", top: "10px", left: "15px", padding: "4px", cursor: "pointer"}} onClick={() => showSubscribers(sub)}>
                                <img title="Εμφάνιση Συνδρομητών" src={"assets/images/callcenter/list.png"} alt={"List"} /></Badge></>
                            <hr style={{width: "98%", marginLeft: "auto", marginRight: "auto"}}/>
                            <div className="subscription-box-inner">
                                {sub.type === "subscription" ? (
                                    <React.Fragment>
                                        <><span className="dLabel">Είδος:</span> Συνδρομή<br/></>
                                        <><span className="dLabel">Είδος αποθήκης:</span> {sub.mappedProduct}<br/></>
                                        <><span className="dLabel">Διάρκεια σε μήνες:</span> {sub.monthsDuration}<br/></>
                                        <><span className="dLabel">Μοναδικότητα συνδρομής:</span> {sub.subscriptionUniqueness === "active" ? "Ναι" : "Όχι"}<br/></>
                                        {company.vatNumber === "800566476" && (<><span className="dLabel">Συνδρομή Κατασκευαστή:</span> {sub.isConstructor === "active" ? "Ναι" : "Όχι"}<br/></>)}
                                    </React.Fragment>
                                ):(
                                    <React.Fragment>
                                        <><span className="dLabel">Είδος:</span> Χρονοχρέωση<br/></>
                                        <><span className="dLabel">Είδος αποθήκης που αφορά η χρονοχρέωση:</span> {sub.mappedProductForCharging}<br/></>
                                        <><span className="dLabel">Είδος αποθήκης:</span> {sub.mappedProduct}<br/></>
                                        <><span className="dLabel">Διάρκεια σε μήνες:</span> {sub.monthsDuration}<br/></>
                                        <><span className="dLabel">Ελάχιστη χρέωση ανά τηλεφώνημα (λεπτά):</span> {sub.minimumCharge}<br/></>
                                        <><span className="dLabel">Χρόνος αγοράς (λεπτά):</span> {sub.minutesTime}<br/></>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>

            <Modal backdrop={"static"} show={showNewModal} onHide={() => resetModalsAndData()}>
                <Modal.Header closeButton>
                    <Modal.Title>Νέα Συνδρομή</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Dropdown
                        key={Math.random()}
                        name="type"
                        label="Είδος Συνδρομής"
                        options={typeOptions}
                        className="mb-2"
                        defaultValue={typeOptions.filter((el) => el.value === SUBSCRIPTIONS.newSubscription.type)}
                        onChange={(e) => handleOnChange(e, "dropdown", "type")}
                    />
                    {SUBSCRIPTIONS?.newSubscription?.type === "subscription" && (
                        <React.Fragment>
                            <Input
                                name="subscriptionName"
                                label="Όνομα Συνδρομής"
                                autoComplete="off"
                                className="mb-2"
                                value={SUBSCRIPTIONS.newSubscription.subscriptionName ? SUBSCRIPTIONS.newSubscription.subscriptionName : ""}
                                onChange={(e) => handleOnChange(e, "input", "subscriptionName")}
                            />
                            <AdvancedCombobox
                                className="w-100 ac-select-dropdown"
                                searchableDropdownEnable={false}
                                label={"Είδος αποθήκης"}
                                defaultValue={mappedProductName}
                                data={defaultProductData}
                                onChange={(e) => handleOnChange(e, "dropdown", "mappedProduct")}
                                excludeColumns={[]}
                                columns={[
                                    {label: "Product Name", name: "name"}
                                ]}
                                editEnable={false}
                                deleteEnable={false}
                                searchTableEnable={false}
                                showTableOptionOnSelect={true}
                                key={Math.random()}
                                onChangeSearch={onChangeSearchProduct}
                            />
                            <Input
                                name="monthsDuration"
                                label="Διάρκεια Συνδρομής σε μήνες"
                                autoComplete="off"
                                type="number"
                                className="mb-2"
                                value={SUBSCRIPTIONS.newSubscription.monthsDuration ? SUBSCRIPTIONS.newSubscription.monthsDuration : ""}
                                onChange={(e) => handleOnChange(e, "input", "monthsDuration")}
                            />
                            <Row>
                            <Col md={6}>
                            <BetterCheckBox
                                name="subscriptionUniqueness"
                                text="Μοναδικότητα συνδρομής"
                                className="mt-1"
                                checked={SUBSCRIPTIONS.newSubscription?.subscriptionUniqueness === "active"}
                                onChange={(e) => handleOnChange(e, "checkbox", "subscriptionUniqueness")}
                            />
                            </Col>
                            {company.vatNumber === "800566476" && (
                                <Col md={6}>
                                <BetterCheckBox
                                    name="isConstructor"
                                    text="Συνδρομή Κατασκευαστή"
                                    className="mt-1"
                                    checked={SUBSCRIPTIONS.newSubscription?.isConstructor === "active"}
                                    onChange={(e) => handleOnChange(e, "checkbox", "isConstructor")}
                                />
                                </Col>
                            )}
                            </Row>
                        </React.Fragment>
                    )}
                    {SUBSCRIPTIONS?.newSubscription?.type === "timeCharge" && (
                        <React.Fragment>
                            <Input
                                name="subscriptionName"
                                label="Όνομα Συνδρομής Χρονοχρέωσης"
                                autoComplete="off"
                                className="mb-3"
                                value={SUBSCRIPTIONS.newSubscription.subscriptionName ? SUBSCRIPTIONS.newSubscription.subscriptionName : ""}
                                onChange={(e) => handleOnChange(e, "input", "subscriptionName")}
                            />
                            <AdvancedCombobox
                                className="w-100 ac-select-dropdown"
                                searchableDropdownEnable={false}
                                label={"Είδος αποθήκης που αφορά η χρονοχρέωση"}
                                placeholder={"Προσθέστε Είδος αποθήκης που αφορά η χρονοχρέωση"}
                                defaultValue={mappedConnectedProductName}
                                data={defaultProductChargingData}
                                onChange={(e) => handleOnChange(e, "dropdown", "mappedProductForCharging")}
                                excludeColumns={[]}
                                columns={[
                                    {label: "Product Name", name: "name"}
                                ]}
                                editEnable={false}
                                deleteEnable={false}
                                searchTableEnable={false}
                                showTableOptionOnSelect={true}
                                key={Math.random()}
                                onChangeSearch={onChangeSearchProductCharging}
                            />
                            <AdvancedCombobox
                                className="w-100 ac-select-dropdown"
                                searchableDropdownEnable={false}
                                label={"Είδος αποθήκης"}
                                placeholder={"Προσθέστε Είδος αποθήκης"}
                                defaultValue={mappedProductName}
                                data={defaultProductData}
                                onChange={(e) => handleOnChange(e, "dropdown", "mappedProduct")}
                                excludeColumns={[]}
                                columns={[
                                    {label: "Product Name", name: "name"}
                                ]}
                                editEnable={false}
                                deleteEnable={false}
                                searchTableEnable={false}
                                showTableOptionOnSelect={true}
                                key={Math.random()}
                                onChangeSearch={onChangeSearchProduct}
                            />
                            <Input
                                name="monthsDuration"
                                label="Διάρκεια Συνδρομής σε μήνες"
                                autoComplete="off"
                                type="number"
                                className="mb-2"
                                value={SUBSCRIPTIONS.newSubscription.monthsDuration ? SUBSCRIPTIONS.newSubscription.monthsDuration : ""}
                                onChange={(e) => handleOnChange(e, "input", "monthsDuration")}
                            />
                            <Input
                                name="minimumCharge"
                                label="Ελάχιστη χρέωση ανά τηλεφώνημα (λεπτά)"
                                autoComplete="off"
                                type="number"
                                className="mb-2"
                                value={SUBSCRIPTIONS.newSubscription.minimumCharge ? SUBSCRIPTIONS.newSubscription.minimumCharge : ""}
                                onChange={(e) => handleOnChange(e, "input", "minimumCharge")}
                            />
                            <Input
                                name="minutesTime"
                                label="Χρόνος αγοράς (λεπτά)"
                                autoComplete="off"
                                type="number"
                                className="mb-2"
                                value={SUBSCRIPTIONS.newSubscription.minutesTime ? SUBSCRIPTIONS.newSubscription.minutesTime : ""}
                                onChange={(e) => handleOnChange(e, "input", "minutesTime")}
                            />
                        </React.Fragment>
                    )}
                    {!subscriptionValid() && SUBSCRIPTIONS?.newSubscription?.type && <span style={{color: "red"}}>(*) Παρακαλώ συμπληρώστε όλα τα πεδία</span>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => resetModalsAndData()}>
                        Κλείσιμο
                    </Button>
                    <Button variant="primary" onClick={() => saveSubscription()} disabled={!subscriptionValid()}>
                        Αποθήκευση
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop={"static"} show={showDeleteModal} onHide={() => resetModalsAndData()}>
                <Modal.Header closeButton>
                    <Modal.Title>Διαγραφή συνδρομής: {deleteSubName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Παρακαλώ γράψτε το όνομα της συνδρομής παρακάτω, έτσι ώστε να ενεργοποιηθεί η διαγραφή συνδρομής</p>
                    <Input
                        name="deleteSubInput"
                        unlabbled={true}
                        placeholder="Όνομα συνδρομής"
                        value={deleteSubInput}
                        autoComplete="off"
                        onChange={(e) => handleOnChange(e, "input", "deleteSubInput")}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => resetModalsAndData()}>
                        Κλείσιμο
                    </Button>
                    <Button variant="primary" onClick={() => deleteSub(deleteSubName)} disabled={deleteSubInput !== deleteSubName}>
                        Διαγραφή
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop={"static"} key={"editModal"} show={showEditModal && !showDeleteModal && !showSubscribersModal}>
                <Modal.Header>
                    <Modal.Title>Τροποποίηση συνδρομής</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Dropdown
                        key={Math.random()}
                        name="type"
                        label="Είδος Συνδρομής (Μη επεξεργάσιμο)"
                        options={typeOptions}
                        className="mb-2"
                        disabled={true}
                        defaultValue={typeOptions.filter((el) => el.value === editModalData.type)}
                    />
                    {editModalData.type === "subscription" && (
                        <React.Fragment>
                            <Input
                                name="subscriptionName"
                                label="Όνομα Συνδρομής"
                                autoComplete="off"
                                className="mb-2"
                                value={editModalData.subscriptionName}
                                onChange={(e) => handleOnChangeEdit(e, "input", "subscriptionName")}
                            />
                            <Input
                                name="mappedProduct"
                                label="Είδος Αποθήκης (Μη επεξεργάσιμο)"
                                autoComplete="off"
                                className="mb-2"
                                disabled={true}
                                value={editModalData.mappedProduct}
                            />
                            <Input
                                name="monthsDuration"
                                label="Διάρκεια Συνδρομής σε μήνες"
                                autoComplete="off"
                                type="number"
                                className="mb-2"
                                value={editModalData.monthsDuration}
                                onChange={(e) => handleOnChangeEdit(e, "input", "monthsDuration")}
                            />
                            <BetterCheckBox
                                name="subscriptionUniqueness"
                                text="Μοναδικότητα συνδρομής (Μη επεξεργάσιμη)"
                                className="mt-1"
                                disabled={true}
                                checked={editModalData.subscriptionUniqueness === "active"}
                                onChange={(e) => handleOnChange(e, "checkbox", "subscriptionUniqueness")}
                            />
                            {company.vatNumber === "800566476" && (
                                <BetterCheckBox
                                    name="isConstructor"
                                    text="Συνδρομή Κατασκευαστή"
                                    className="mt-1"
                                    checked={editModalData?.isConstructor === "active"}
                                    onChange={(e) => handleOnChangeEdit(e, "checkbox", "isConstructor")}
                                />
                            )}
                            <Alert key={Math.random()} variant={"info"}>Κατά την αποθήκευση της συνδρομής, θα γίνει τροποποίηση όλων των συνδρομητών, καθώς θα αλλάξει μόνο το όνομα της. Τα νέα στοιχεία θα ισχύουν από τις καινούργιες συνδρομές και μετά.</Alert>
                        </React.Fragment>
                    )}
                    {editModalData.type === "timeCharge" && (
                        <React.Fragment>
                            <Input
                                name="subscriptionName"
                                label="Όνομα Συνδρομής Χρονοχρέωσης"
                                autoComplete="off"
                                className="mb-2"
                                value={editModalData.subscriptionName}
                                onChange={(e) => handleOnChangeEdit(e, "input", "subscriptionName")}
                            />
                            <Input
                                name="mappedProductForCharging"
                                label="Είδος αποθήκης που αφορά η χρονοχρέωση (Μη επεξεργάσιμο)"
                                autoComplete="off"
                                className="mb-2"
                                disabled={true}
                                value={editModalData.mappedProductForCharging}
                            />
                            <Input
                                name="mappedProduct"
                                label="Είδος αποθήκης (Μη επεξεργάσιμο)"
                                autoComplete="off"
                                className="mb-2"
                                disabled={true}
                                value={editModalData.mappedProduct}
                            />
                            <Input
                                name="monthsDuration"
                                label="Διάρκεια Συνδρομής σε μήνες"
                                autoComplete="off"
                                type="number"
                                className="mb-2"
                                value={editModalData.monthsDuration}
                                onChange={(e) => handleOnChangeEdit(e, "input", "monthsDuration")}
                            />
                            <Input
                                name="minimumCharge"
                                label="Ελάχιστη χρέωση ανά τηλεφώνημα (λεπτά)"
                                autoComplete="off"
                                type="number"
                                className="mb-2"
                                value={editModalData.minimumCharge}
                                onChange={(e) => handleOnChangeEdit(e, "input", "minimumCharge")}
                            />
                            <Input
                                name="minutesTime"
                                label="Χρόνος αγοράς (λεπτά)"
                                autoComplete="off"
                                type="number"
                                className="mb-2"
                                value={editModalData.minutesTime}
                                onChange={(e) => handleOnChangeEdit(e, "input", "minutesTime")}
                            />
                            <Alert key={Math.random()} variant={"info"}>Κατά την αποθήκευση της χρονοχρέωσης, θα γίνει τροποποίηση όλων των συνδρομητών, καθώς θα αλλάξει μόνο το όνομα της και η ελάχιστη χρέωση της. Τα νέα στοιχεία θα ισχύουν από τις καινούργιες συνδρομές χρονοχρέωσης και μετά.</Alert>
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => closeEditModal(false)}>
                        Κλείσιμο
                    </Button>
                    <Button variant="primary" onClick={() => saveEditingSub()} disabled={!editSubscriptionValid()}>
                        Αποθήκευση
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal key={"subscribersModal"} show={showSubscribersModal} onHide={() => closeSubscribersModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Εμφάνιση Συνδρομητών</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        name="searchSubscribers"
                        label="Εύρεση Συνδρομητή"
                        placeholder="Αναζήτηση"
                        className="mb-2"
                        value={searchingSubscribers}
                        onChange={(e) => setSearchingSubscribers(e.target.value)}
                    />
                    {showSubscribersLoading === true ? (
                        <span>Γίνεται αναζήτηση, παρακαλώ περιμένετε...</span>
                    ) : (
                        <Stack gap={1} key={Math.random()}>
                            {showSubscribersData.length > 0 && showSubscribersData.map((suber) => {
                                if (String(suber.clientName.toLowerCase()).includes(searchingSubscribers.toLowerCase())) {
                                    return (
                                        <Badge bg="primary" style={{fontSize: "12px"}}>{suber.clientName}<br/></Badge>
                                    )
                                }
                            })}
                            {showSubscribersData.length === 0 && (
                                <span>Η συνδρομή αυτή δεν έχει κανέναν συνδρομητή</span>
                            )}
                        </Stack>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => closeSubscribersModal()}>
                        Κλείσιμο
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default SettingsSubscriptions
