// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-field {
    position: relative;
    i {
        position: absolute;
        bottom: 10px;
        right: 10px;
        color: #666;
    }
}`, "",{"version":3,"sources":["webpack://./src/_components/layouts/data-panel/Settings-MyData/password.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB;QACI,kBAAkB;QAClB,YAAY;QACZ,WAAW;QACX,WAAW;IACf;AACJ","sourcesContent":[".password-field {\r\n    position: relative;\r\n    i {\r\n        position: absolute;\r\n        bottom: 10px;\r\n        right: 10px;\r\n        color: #666;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
