import React, {useEffect, useState} from "react";
import {classicStyleBelowNavbar} from "../Statics";
import {Badge, Button, Col, Row, Spinner} from "react-bootstrap";
import {Popover} from "antd";
import Input from "../../../common/Input";
import Dropdown from "../../../primer/Dropdown";
import {useSelector} from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";
import Modal from "react-bootstrap/Modal";

const PrescriptionSettings = () => {
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const [credentials, setCredentials] = useState({
        username: "",
        password: "",
        environment: "test",
    })
    const [doctorUnits, setDoctorUnits] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState("");
    const [loading, setLoading] = useState(false);

    const [showChangeUnitModal, setShowChangeUnitModal] = useState(false);
    const [toSetUnitId, setToSetUnitId] = useState("");

    const envOptions = [
        { label: "Δοκιμή", value: "test" },
        { label: "Παραγωγικό", value: "production" }
    ]

    useEffect(() => {
        fetchPrescriptionCredentials();
    }, [])

    const fetchPrescriptionCredentials = () => {
        axios.get(`${process.env.REACT_APP_API_URL2}/prescription/get-credentials`, {
            headers: { "Content-Type": "application/json" },
            params: {
                company: company.id,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                setCredentials(res.data.credentials);
                if (res.data.doctorUnits) setDoctorUnits(res.data.doctorUnits);
                if (res.data.selectedUnit) setSelectedUnit(res.data.selectedUnit);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την άντληση στοιχείων.")
        })
    }

    const savePrescriptionCredentials = () => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/prescription/save-credentials`, {
            company: company.id,
            credentials: credentials,
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                toast.success("Επιτυχής ενεργοποίηση.");
                fetchPrescriptionCredentials();
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleSaveChangeUnit = () => {
        axios.post(`${process.env.REACT_APP_API_URL2}/prescription/change-unit`, {
            company: company.id,
            unitId: toSetUnitId,
        }).then((res) => {
            if (res.data.status === "200") {
                setShowChangeUnitModal(false);
                toast.success("Επιτυχής αλλαγή μονάδος συνταγογράφησης.");
                fetchPrescriptionCredentials();
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row className={"mb-2"}>
                <Col md={4} className={"border-right"}>
                    <div className={"text-muted mb-1"}><i>Στοιχεία e-Prescription</i></div>
                    <Popover placement={"right"} content={
                        <div>
                            Το όνομα χρήστη που χρησιμοποιείτε για να συνδεθείτε στο e-prescription.gr
                        </div>
                    }>
                        <>
                            <Input
                                className={"mb-2"}
                                name={"prescriptionUsername"}
                                label={"Όνομα Χρήστη"}
                                value={credentials.username}
                                autoComplete={"off"}
                                onChange={(e) => setCredentials({...credentials, username: e.target.value})}
                            />
                        </>
                    </Popover>

                    <Popover placement={"right"} content={
                        <div>
                            Ο κωδικός που χρησιμοποιείτε για να συνδεθείτε στο e-prescription.gr
                        </div>
                    }>
                        <>
                            <Input
                                className={"mb-2"}
                                name={"prescriptionPassword"}
                                label={"Κωδικός Χρήστη"}
                                type={"password"}
                                value={credentials.password}
                                autoComplete={"off"}
                                onChange={(e) => setCredentials({...credentials, password: e.target.value})}
                            />
                        </>
                    </Popover>
                    <Dropdown
                        name={"erganiEnvironment"}
                        options={envOptions}
                        label={"Περιβάλλον"}
                        defaultValue={envOptions.find((el) => el.value === credentials.environment)}
                        key={Math.random()}
                        onChange={(e) => setCredentials({...credentials, environment: e.value})}
                    />
                    <div style={{textAlign: "center"}}>
                        <Button onClick={() => savePrescriptionCredentials()} disabled={loading}>
                            Ενεργοποίηση {loading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"}/>}
                        </Button>
                    </div>
                </Col>
                <Col md={8}>
                    {doctorUnits?.length > 0 && (
                        <React.Fragment>
                            <div className={"text-muted mb-1"}><i>Στοιχεία Μονάδων Συνταγογράφησης</i></div>
                            <table className={"simpleClassicTable"}>
                                <colgroup>
                                    <col span={1} style={{width: "20%"}}></col>
                                    <col span={1} style={{width: "60%"}}></col>
                                    <col span={1} style={{width: "20%"}}></col>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th>Κωδικός</th>
                                    <th>Στοιχεία μονάδος</th>
                                    <th>Επιλογή μονάδος</th>
                                </tr>
                                </thead>
                                <tbody>
                                {doctorUnits.map((unit, idx) => (
                                    <tr key={`dUnit-${idx}`}>
                                        <td>{unit.id}</td>
                                        <td>{unit.healthCareUnitDescription} ({unit.description})</td>
                                        <td>{selectedUnit === unit.id ? (
                                            <Badge bg={"success"}>Επιλεγμένο</Badge>
                                        ) : (
                                            <Badge bg={"primary"} style={{cursor: "pointer"}} onClick={() => {
                                                setToSetUnitId(unit.id);
                                                setShowChangeUnitModal(true);
                                            }}>Επιλογή</Badge>
                                        )}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>

                            <Modal show={showChangeUnitModal} onHide={() => setShowChangeUnitModal(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Αλλαγή μονάδος συνταγογράφησης</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Είσαστε σίγουροι ότι θέλετε να αλλάξετε μονάδα συνταγογράφησης;</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={() => handleSaveChangeUnit()}>
                                        Ναι, αλλαγή
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </React.Fragment>
                    )}
                </Col>
            </Row>
        </div>
    )
}

export default PrescriptionSettings
