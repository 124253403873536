import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import EditableTable from '../../../primer/Editable-Table';
import {deleteObjectFromArrayOfObjects} from '../../../../_helpers/helperFunctions';
import {
  getDOYOptions,
  getInstallationsTypes,
  getVatCategoryAbroadOptions,
  getVatCategoryOptions
} from '../../../../_helpers/commonFunctions';
import {
  resetNewInstallations,
  setNewClient,
  setNewInstallations
} from '../../../../_reducers/DataPanel/Clients/clientSlice';
import {setSaveNewClient} from '../../../../_reducers/activitySlice';
import getMyData from "../../../../_helpers/sharedmydata";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

const NewClientInstallations = () => {
  const [t, i18n] = useTranslation('common');
  const dispatch = useDispatch();
  const ACTIVITY = useSelector( (state) => state.ACTIVITY );
  const CLIENTS_DATA = useSelector( (state) => state.CLIENTS_DATA );
  let vatCategoryList = CLIENTS_DATA.newClient.categoryName === "Client within E.U." || CLIENTS_DATA.newClient.categoryName === "Client outside E.U." ? getVatCategoryAbroadOptions() : getVatCategoryOptions();
  let doyList = CLIENTS_DATA.newClient.categoryName === "Client within E.U." || CLIENTS_DATA.newClient.categoryName === "Client outside E.U." ? [] : getDOYOptions();
  const zeroVatReasonOptions = getMyData({type: 'zerovatreason'});
  const clientInstallationsColumns = [
    {
      name: "Αριθμός Εγκατάστασης",
      field: "no",
      width: "7%",
      editable: true,
      inputType: {
        type: 'number',
      }
    },
    {
      name: t('ClientsNewInstallations.type'),
      field: "type",
      editable: true,
      width: "15%",
      inputType: {
        options: getInstallationsTypes(),
        type: 'dropdown',
        config: {
          searchEnable: true
        }
      }
    },
    {
      name: t("ClientsNewInstallations.address"),
      field: "address",
      width: "15%",
      editable:true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t("ClientsNewInstallations.installationDoy"),
      field: "DOY",
      editable: true,
      width: "15%",
      inputType: {
        options: doyList,
        type: 'dropdown',
        config: {
          searchEnable: true
        }
      }
    },
    {
      name: t("ClientsNewInstallations.city"),
      field: "city",
      width: "20%",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t("ClientsNewInstallations.postalCode"),
      field: "postalCode",
      width: "10%",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t("ClientsNewInstallations.InstallationVatCategory"),
      field: "vatCategory",
      editable: true,
      width: "15%",
      inputType: {
        options: vatCategoryList,
        type: 'dropdown',
        config: {
          searchEnable: true
        }
      }
    },
    {
      name: t("ClientsNewInstallations.0%vatReason"),
      field: "vatReason",
      editable: true,
      disabled: true,
      width: "20%",
      inputType: {
        options: zeroVatReasonOptions,
        type: 'dropdown',
        config: {
          searchEnable: true
        }
      }
    },
    {
      name: t("ClientsNewInstallations.customVat0"),
      field: "customVat",
      editable: true,
      disabled: true,
      width: "10%",
      inputType: {
        type: 'number',
      }
    },
  ]
  useEffect( () => {
    setTableColumns(clientInstallationsColumns.slice());
  },[i18n.language])
  const [tableColumns, setTableColumns] = useState(clientInstallationsColumns.slice());
  const parentMydataActions = {
    insert: (e, data) => {
      let tempInstallations = Object.assign({},  CLIENTS_DATA.newInstallation);
      if (e.key !== "Enter") {
        tempInstallations[data.field] = data.value;
        dispatch(setNewInstallations(tempInstallations)); // Update redux
        let oldInstallations = CLIENTS_DATA.newClient.installations;
        if(data.field === "no"){
          const findDuplicate = oldInstallations.filter(installation => installation.no.toString() === data.value);
          if(findDuplicate.length > 0){
            toast.error("Ο Αριθμός Εγκατάστασης υπάρχει ηδη.");
            dispatch(resetNewInstallations());
            return;
          }
        }
        let updatedInstallation = Object.assign({},  tempInstallations);
        if((updatedInstallation && updatedInstallation.vatCategory === 'Zero VAT' && updatedInstallation.vatReason === '') || (updatedInstallation && updatedInstallation.vatCategory === 'Custom VAT' && updatedInstallation.no !== '' && updatedInstallation.customVat === '')){
          dispatch(setSaveNewClient(false));
        }
        if (updatedInstallation && (updatedInstallation.address !== '' && updatedInstallation.DOY !== '' && updatedInstallation.city !== '' && updatedInstallation.no !== '' && updatedInstallation.postalCode !== '' && updatedInstallation.vatCategory !== '')) {
          updatedInstallation.id = oldInstallations.length;
          dispatch(setNewClient({...CLIENTS_DATA.newClient, installations: [...oldInstallations, updatedInstallation]}));
          dispatch(resetNewInstallations()); // Reset
          dispatch(setSaveNewClient(true));
          toast.success('Please press Save in order to save the installation changes');
        } else if(updatedInstallation && (CLIENTS_DATA.newClient.categoryName === "Client within E.U." || CLIENTS_DATA.newClient.categoryName === "Client outside E.U.") && (updatedInstallation.address !== '' && updatedInstallation.postalCode !== '' && updatedInstallation.city !== '' && updatedInstallation.vatCategory !== '')){
          updatedInstallation.no = oldInstallations.length;
          updatedInstallation.id = oldInstallations.length;
          dispatch(setNewClient({...CLIENTS_DATA.newClient, installations: [...oldInstallations, updatedInstallation]}));
          dispatch(resetNewInstallations()); // Reset
          dispatch(setSaveNewClient(true));
          toast.success('Please press Save in order to save the installation changes');
        }
      } else {
        let updatedInstallation = Object.assign({},  CLIENTS_DATA.newInstallation);
        let oldInstallations = CLIENTS_DATA.newClient.installations;
        if(data.field === "no"){
          const findDuplicate = oldInstallations.filter(installation => installation.no.toString() === data.value);
          if(findDuplicate.length > 0){
            toast.error("Ο Αριθμός Εγκατάστασης υπάρχει ηδη.");
            dispatch(resetNewInstallations());
            return;
          }
        }
        if (data.field === 'add' || e.key === 'Enter') {
          if (updatedInstallation && (updatedInstallation.address !== '' && updatedInstallation.DOY !== '' && updatedInstallation.city !== '' && updatedInstallation.vatCategory !== '' && updatedInstallation.no !== '')) {
            updatedInstallation.id = oldInstallations.length;
            dispatch(setNewClient({...CLIENTS_DATA.newClient, installations: [...oldInstallations, updatedInstallation]}));
            dispatch(resetNewInstallations()); // Reset
            dispatch(setSaveNewClient(true));
            toast.success('Please press Save in order to save the installation changes');
          } else if(updatedInstallation && (CLIENTS_DATA.newClient.categoryName === "Client within E.U." || CLIENTS_DATA.newClient.categoryName === "Client outside E.U.") && (updatedInstallation.address !== '' && updatedInstallation.postalCode !== '' && updatedInstallation.city !== '' && updatedInstallation.no !== '' && updatedInstallation.vatCategory !== '')){
            updatedInstallation.id = oldInstallations.length;
            dispatch(setNewClient({...CLIENTS_DATA.newClient, installations: [...oldInstallations, updatedInstallation]}));
            dispatch(resetNewInstallations()); // Reset
            dispatch(setSaveNewClient(true));
            toast.success('Please press Save in order to save the installation changes');
          }
        }
      }
    },
    update: (data, id) => {

      // Set if editing the form
      if (!ACTIVITY.saveNewClient) {
        dispatch(setSaveNewClient(true));
      }
      let installations = CLIENTS_DATA.newClient.installations.slice();
      if(data?.no){
        const findDuplicate = installations.filter(installation => installation.no.toString() === data.no);
        if(findDuplicate.length > 0){
          toast.error("Ο Αριθμός Εγκατάστασης υπάρχει ηδη.");
          return;
        }
      }
      if (installations.find(item => item.id === id) !== undefined) {
        installations[id] = {...installations[id], ...data};
        dispatch(setNewClient({...CLIENTS_DATA.newClient, installations: installations})); // update data
      }
    },
    selected: () => {
      console.log('selected mydata row clicked');
    },
    delete: (id) => {
      // Set if editing the form
      if (!ACTIVITY.saveNewClient) {
        dispatch(setSaveNewClient(true));
      }
      let installations = CLIENTS_DATA.newClient.installations.slice();
      if (id !== '' && !Number.isInteger(id)) {
        installations = deleteObjectFromArrayOfObjects(installations, id, 'no');
        dispatch(setNewClient({...CLIENTS_DATA.newClient, installations: installations }));
      }

    }
  }

  return (
    <div className="new-client-general">
      <p className="text-muted"><i>{t('ClientsNewInstallations.installations')}</i></p>
      <EditableTable
        tableName="Client New Installations"
        key={Math.random()}
        allowActions={true}
        allowToggleColumns = {false}
        columns={tableColumns}
        allowInsertRow={true}
        enableNewRowCheckbox = {true}
        data={ CLIENTS_DATA.newClient.installations }
        onUpdate={parentMydataActions}
      />
    </div>
  )
}

export default NewClientInstallations;
