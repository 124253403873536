import React from "react";
import {useTranslation} from "react-i18next";
import {isEmpty} from "../../../_helpers/commonFunctions";

const Input = ({type, id, required, placeholder, label, unlabbled, charLimit,
                   onChange, onBlur, className, name, value, onKeyDown, disabled, autoComplete, defaultValue, style}) => {
    const [t] = useTranslation("common");
    let maxLength = charLimit ? charLimit : 250;

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        if (type !== "password") {
            e.target.value = inputValue.replace(/[$^*()\[\]=+]/g, "");
        }
        if (onChange) onChange(e);
    }
    const handleOnBlur = (e) => {
        if (onBlur) onBlur(e);
    }

    return (
        <React.Fragment>
            <div className={className ? className : "w-100"}>
                {!unlabbled && (
                    <label htmlFor={id}>
                        {label}
                        {required && <span className="text-danger"> *</span>}
                    </label>
                )}
                <input
                    maxLength={maxLength}
                    className="form-control"
                    type={type}
                    disabled={disabled}
                    name={name}
                    id={id}
                    value={value}
                    defaultValue={defaultValue}
                    onChange={handleInputChange}
                    onBlur={handleOnBlur}
                    autoComplete={autoComplete}
                    onKeyDown={onKeyDown}
                    style={style}
                    placeholder={isEmpty(placeholder) ? t('insertPlaceholders.add') + label : placeholder}
                />
                {!isEmpty(value) && (String(value).length === maxLength) &&
                    <small className="text-info">Max length reached.</small>}
            </div>
        </React.Fragment>
    )
}

export default Input;
