// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.themeModal {
  .modal-header .modal-title{
    text-align: center;
    width: 100%;
    margin-right: -30px;
  }
  .modal-content {
    border-radius: 5px;
    border:unset;
  }
}

.themeModalBig {
  .modal-dialog {
    max-width: 1300px;
    margin: 30px auto;
  }
  .modal-header .modal-title{
    text-align: center;
    width: 100%;
    margin-right: -10px;
  }
  .modal-content {
    border-radius: 5px;
    width: 1300px;
    border:unset;
    left: 0;
    right: 0;
  }
  .modal-body {
    white-space: pre-line;
  }
}

.themeModalMedium {
  .modal-dialog {
    max-width: 900px;
    margin: 30px auto;
  }
  .modal-header .modal-title{
    text-align: center;
    width: 100%;
    margin-right: -10px;
  }
  .modal-content {
    border-radius: 5px;
    width: 900px;
    border:unset;
    left: 0;
    right: 0;
  }
  .modal-body {
    white-space: pre-line;
  }
}

.modalTriggerIcon {
  cursor: pointer;
  font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/_components/primer/POPUP-COMPONENTS/Instructions/modalui.css"],"names":[],"mappings":"AAAA;EACE;IACE,kBAAkB;IAClB,WAAW;IACX,mBAAmB;EACrB;EACA;IACE,kBAAkB;IAClB,YAAY;EACd;AACF;;AAEA;EACE;IACE,iBAAiB;IACjB,iBAAiB;EACnB;EACA;IACE,kBAAkB;IAClB,WAAW;IACX,mBAAmB;EACrB;EACA;IACE,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,OAAO;IACP,QAAQ;EACV;EACA;IACE,qBAAqB;EACvB;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,iBAAiB;EACnB;EACA;IACE,kBAAkB;IAClB,WAAW;IACX,mBAAmB;EACrB;EACA;IACE,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,OAAO;IACP,QAAQ;EACV;EACA;IACE,qBAAqB;EACvB;AACF;;AAEA;EACE,eAAe;EACf,eAAe;AACjB","sourcesContent":[".themeModal {\r\n  .modal-header .modal-title{\r\n    text-align: center;\r\n    width: 100%;\r\n    margin-right: -30px;\r\n  }\r\n  .modal-content {\r\n    border-radius: 5px;\r\n    border:unset;\r\n  }\r\n}\r\n\r\n.themeModalBig {\r\n  .modal-dialog {\r\n    max-width: 1300px;\r\n    margin: 30px auto;\r\n  }\r\n  .modal-header .modal-title{\r\n    text-align: center;\r\n    width: 100%;\r\n    margin-right: -10px;\r\n  }\r\n  .modal-content {\r\n    border-radius: 5px;\r\n    width: 1300px;\r\n    border:unset;\r\n    left: 0;\r\n    right: 0;\r\n  }\r\n  .modal-body {\r\n    white-space: pre-line;\r\n  }\r\n}\r\n\r\n.themeModalMedium {\r\n  .modal-dialog {\r\n    max-width: 900px;\r\n    margin: 30px auto;\r\n  }\r\n  .modal-header .modal-title{\r\n    text-align: center;\r\n    width: 100%;\r\n    margin-right: -10px;\r\n  }\r\n  .modal-content {\r\n    border-radius: 5px;\r\n    width: 900px;\r\n    border:unset;\r\n    left: 0;\r\n    right: 0;\r\n  }\r\n  .modal-body {\r\n    white-space: pre-line;\r\n  }\r\n}\r\n\r\n.modalTriggerIcon {\r\n  cursor: pointer;\r\n  font-size: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
