import React from "react";
import SearchTable from "../../../primer/Search-Table";
import {useTranslation} from "react-i18next";
import {classicStyleBelowNavbar} from "../Statics";

function POSSearch() {
    const [t] = useTranslation('common');

    const scColumns = [
        {
            name: t('PosSearch.table.name'),
            field: "name",
            width: "10%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('PosSearch.table.serialNumber'),
            field: "serialNumber",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('PosSearch.table.date'),
            field: "date",
            editable: false,
            inputType: {
                type: 'date',
            }
        },
        {
            name: "Είναι eΤαμειακή",
            field: "isETameiaki",
            editable: false,
            inputType: {
                type: 'dropdownOption',
                options: [{
                    value: 'active',
                    label: 'Ενεργό'
                }, {
                    value: 'inactive',
                    label: 'Ανενεργό'
                }, {
                    value: undefined,
                    label: 'Ανενεργό'
                }]
            }
        },
    ]

    const searchPosFilters = {
        filters: [
            {
                name: "name",
                label: t('PosSearch.name'),
                type: "input",
            },
            {
                name: "serialNumber",
                label: t('PosSearch.serialNumber'),
                type: "input",
            },
            {
                name: "showInactive",
                label: t('General.includeInactive'),
                type: "showInactiveCheckbox",
            },
        ],
        actions: ["quick-edit", "delete"],
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName="POS Search"
                key={"POS Search"}
                hideYearFilter={true}
                allowToggleColumns={true}
                columns={scColumns}
                data={[]}  // Empty by default
                filtersConfig={searchPosFilters}
            />
        </div>
    )
}

export default POSSearch;
