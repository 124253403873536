import React, {useEffect, useState} from "react";
import SearchTable from "../../../primer/Search-Table";
import {useSelector, useDispatch} from "react-redux";
import {getCurrentUser, getOptionsByData} from '../../../../_helpers/commonFunctions';
import {getObjectFromArrayOfObjects} from '../../../../_helpers/helperFunctions';
import {
    fetchAdvancedTypes,
    fetchSalesGeneralSettings,
} from "../../../../_apis/api";
import {useTranslation} from "react-i18next";
import {classicStyleBelowNavbar} from "../Statics";

function ClientPaymentsSearch() {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const SALES_SETTINGS = useSelector((state) => state.SALES_SETTINGS.salesGeneralSettings.paymentTypes);
    const allDocumentTypes = useSelector((state) => state.SETTINGS.settings.advancedTypes);
    const clientPaymentsDT = getObjectFromArrayOfObjects(allDocumentTypes, 'Client Payments', 'section');
    const documentTypesOptions = getOptionsByData(clientPaymentsDT, '_id', 'name')
    const paymentTypeOptions = getOptionsByData(SALES_SETTINGS, "_id", "name");

    const requestData = {
        company: company.id,
        year: company.year.toString(),
    }

    const currentUser = getCurrentUser();

    const requestData2 = {
        company: company.id,
        year: company.year.toString(),
        isActive: "active",
        userId: currentUser.id
    }

    useEffect(() => {
        dispatch(fetchAdvancedTypes(requestData));
        dispatch(fetchSalesGeneralSettings(requestData2))
    }, [dispatch]);

    const ssColumns = [
        {
            name: t('SalesClientPaymentsSearch.table.date'),
            field: "date",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesClientPaymentsSearch.table.client'),
            field: "clientName",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesClientPaymentsSearch.table.documentType'),
            field: "documentTypeName",
            width: "20%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesClientPaymentsSearch.table.preNumberAndNumber'),
            field: "preNumberAndNumber",
            width: "10%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesClientPaymentsSearch.table.paymentType'),
            field: "paymentTypeName",
            width: "20%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesClientPaymentsSearch.table.amount'),
            field: "amount",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
    ]

    const searchSaleFilters = {
        filters: [
            {
                name: "documentType",
                label: t('SalesClientPaymentsSearch.tableFilter.documentType'),
                type: "multiselect",
                options: documentTypesOptions
            },
            {
                name: "clientName",
                label: t('SalesClientPaymentsSearch.tableFilter.clientNameFrom'),
                type: "input",
            },
            {
                name: "paymentTypes",
                label: t('SalesClientPaymentsSearch.tableFilter.paymentTypes'),
                type: "multiselect",
                options: paymentTypeOptions
            },
            {
                name: "amount_from",
                label: t('SalesClientPaymentsSearch.tableFilter.amountFrom'),
                type: "input",
            },
            {
                name: "amount_to",
                label: t('SalesClientPaymentsSearch.tableFilter.amountTo'),
                type: "input",
            },
            {
                name: "numberFrom",
                label: t('General.numberFrom'),
                type: "number",
            },
            {
                name: "numberTo",
                label: t('General.numberTo'),
                type: "number",
            },
        ],
        actions: ["quick-edit", "delete"],
    };

    const [tableColumns, setTableColumns] = useState(ssColumns.slice());
    const handleColumnShowHide = (field) => {
        let tempTc = tableColumns.slice();
        let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
        const index = tempTc.findIndex((item) => field === item.field);
        if (object.length > 0) {
            tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
            setTableColumns(tempTc);
        }
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName="Client Payments Search"
                key={"Client Payments Search"}
                allowPagination={true}
                hideYearFilter={false}
                allowToggleColumns={true}
                toggleColumns={handleColumnShowHide}
                columns={tableColumns}
                data={[]}
                filtersConfig={searchSaleFilters}
            />
        </div>
    )
}

export default ClientPaymentsSearch;
